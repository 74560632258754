import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccessLevelName: string;
  AccessLevelRemoteId: string;
  AccessReviewConnectionId: string;
  AccessReviewConnectionUserId: string;
  AccessReviewConnectionUserReviewerId: string;
  AccessReviewEntityId: string;
  AccessReviewGroupId: string;
  AccessReviewGroupResourceId: string;
  AccessReviewGroupUserId: string;
  AccessReviewGroupUserReviewerId: string;
  AccessReviewId: string;
  AccessReviewPrincipalId: string;
  AccessReviewResourceId: string;
  AccessReviewResourceUserId: string;
  AccessReviewResourceUserReviewerId: string;
  AccessReviewRoleAssignmentId: string;
  AccessReviewRoleAssignmentReviewerId: string;
  AccessReviewTemplateId: string;
  AuditReportId: string;
  AuthorizedAction: string;
  BundleId: string;
  BundleVisibilityGroupID: string;
  ConfigTemplateTagId: string;
  ConfigurationId: string;
  ConnectionId: string;
  EntityId: string;
  EventFilterId: string;
  EventId: string;
  EventStreamId: string;
  FirstPartyTokenId: string;
  GroupBindingId: string;
  GroupBindingSuggestionId: string;
  GroupFolderId: string;
  GroupGroupId: string;
  GroupId: string;
  IdpConnectionId: string;
  IdpConnectionUserAttributeImportMappingId: string;
  MessageChannelId: string;
  MessageChannelRemoteId: string;
  OidcClientId: string;
  OnCallScheduleId: string;
  OnCallScheduleRemoteId: string;
  OrganizationId: string;
  OwnerId: string;
  PrincipalId: string;
  PubSubPublishConnectionId: string;
  PubsubPublishMessageId: string;
  PushTaskId: string;
  RemoteGroupId: string;
  RemoteResourceId: string;
  RequestCommentId: string;
  RequestConfigurationId: string;
  RequestId: string;
  RequestTemplateCustomFieldId: string;
  RequestTemplateId: string;
  ResourceCustomAccessLevelId: string;
  ResourceFolderId: string;
  ResourceId: string;
  ResourceProposalId: string;
  ResourceTagId: string;
  ReviewerStageId: string;
  RoleAssignmentId: string;
  RolePermissionId: string;
  SessionId: string;
  SubEventId: string;
  SupportTicketId: string;
  SupportTicketRemoteId: string;
  SyncErrorId: string;
  SyncTaskId: string;
  TagId: string;
  ThirdPartyTokenId: string;
  TicketProjectId: string;
  Time: string;
  ToxicSetItemId: string;
  UINotificationId: string;
  Upload: File;
  UserFactorRemoteId: string;
  UserId: string;
  UserTagId: string;
  UUID: string;
};

export type Access = {
  __typename?: "Access";
  accessLevel?: Maybe<AccessLevel>;
  /**
   * Describes attributes of `principalId`'s direct access to `entityId`. If unset, this user does not have
   * direct access to the resource.
   */
  directAccessPoint?: Maybe<AccessPoint>;
  entityId: Scalars["EntityId"];
  entityType: EntityType;
  /**
   * Describes attributes of `principalId`'s inherited access to `entityId`. If empty, this user does not have
   * inherited access to the resource.
   */
  inheritedAccessPoints: Array<AccessPoint>;
  /**
   * Describes attributes of `principalId`'s access to `entityId` for the path with the latest expiration.
   * This access might be direct or indirect.
   */
  latestExpiringAccessPoint: AccessPoint;
  principalId: Scalars["PrincipalId"];
  principalType: EntityType;
};

export enum AccessChangeType {
  ConvertedToJit = "CONVERTED_TO_JIT",
  Other = "OTHER",
  Revoked = "REVOKED",
}

export type AccessExpiringNotificationsInvalidError = Error & {
  __typename?: "AccessExpiringNotificationsInvalidError";
  message: Scalars["String"];
};

export type AccessGrantedTimesResult = {
  __typename?: "AccessGrantedTimesResult";
  lineData: Array<LineGraphData>;
};

export type AccessLevel = {
  __typename?: "AccessLevel";
  accessLevelMetadata?: Maybe<AccessLevelMetadata>;
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type AccessLevelInput = {
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type AccessLevelMetadata = ImpersonationAccessLevelMetadata;

export type AccessLevelNode = {
  __typename?: "AccessLevelNode";
  accessLevel: ResourceAccessLevel;
  resourceId: Scalars["ResourceId"];
};

export enum AccessOption {
  All = "ALL",
  Mine = "MINE",
  Unmanaged = "UNMANAGED",
}

export type AccessPoint = {
  __typename?: "AccessPoint";
  accessLevel?: Maybe<AccessLevel>;
  createdAt: Scalars["Time"];
  entityId: Scalars["EntityId"];
  entityItemType: Scalars["String"];
  entityName: Scalars["String"];
  entityType: EntityType;
  eventId?: Maybe<Scalars["EventId"]>;
  expiration?: Maybe<Scalars["Time"]>;
  principalId: Scalars["PrincipalId"];
  principalName: Scalars["String"];
  principalType: EntityType;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
};

export type AccessRequestEscalationPeriodInvalidError = Error & {
  __typename?: "AccessRequestEscalationPeriodInvalidError";
  message: Scalars["String"];
};

export type AccessReview = {
  __typename?: "AccessReview";
  accessReviewTemplate?: Maybe<AccessReviewTemplate>;
  accessReviewTemplateId?: Maybe<Scalars["AccessReviewTemplateId"]>;
  createdAt: Scalars["Time"];
  deadline: Scalars["Time"];
  filters?: Maybe<AccessReviewFilters>;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  id: Scalars["AccessReviewId"];
  includeGroupBindings: Scalars["Boolean"];
  itemsToReviewStats?: Maybe<OngoingAccessReviewTabStats>;
  lastReminderAt?: Maybe<Scalars["Time"]>;
  name: Scalars["String"];
  reminderIncludeManager: Scalars["Boolean"];
  reminderSchedule: Array<Scalars["Int"]>;
  reminderTimeOfDay: Scalars["Time"];
  reportId?: Maybe<Scalars["AuditReportId"]>;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  reviewers?: Maybe<Array<AccessReviewReviewer>>;
  selfReviewAllowed: Scalars["Boolean"];
  sendReviewerAssignmentNotification: Scalars["Boolean"];
  startedByUser?: Maybe<User>;
  startedByUserId: Scalars["UserId"];
  stats: AccessReviewStats;
  stoppedByUser?: Maybe<User>;
  stoppedByUserId?: Maybe<Scalars["UserId"]>;
  stoppedDate?: Maybe<Scalars["Time"]>;
  timeZone: Scalars["String"];
};

export enum AccessReviewAction {
  Accept = "ACCEPT",
  NoAction = "NO_ACTION",
  Revoke = "REVOKE",
  Update = "UPDATE",
}

export type AccessReviewAlreadyStoppedError = Error & {
  __typename?: "AccessReviewAlreadyStoppedError";
  message: Scalars["String"];
};

export enum AccessReviewAssignedStatus {
  Assigned = "ASSIGNED",
  Unassigned = "UNASSIGNED",
}

export type AccessReviewChangeInput = {
  accessReviewId: Scalars["AccessReviewId"];
  entityId: EntityIdTupleInput;
};

export type AccessReviewChangeItem = {
  __typename?: "AccessReviewChangeItem";
  groupResources?: Maybe<Array<AccessReviewGroupResource>>;
  item: AccessReviewItem;
  nhis?: Maybe<AccessReviewChangeNhIs>;
  users?: Maybe<AccessReviewChangeUsers>;
};

export type AccessReviewChangeNhIs = {
  __typename?: "AccessReviewChangeNHIs";
  resourceResources?: Maybe<Array<AccessReviewResourceResource>>;
};

export type AccessReviewChangeOutput = {
  __typename?: "AccessReviewChangeOutput";
  users: AccessReviewChangeUsers;
};

export type AccessReviewChangesInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type AccessReviewChangesOutput = {
  __typename?: "AccessReviewChangesOutput";
  items: Array<AccessReviewChangeItem>;
};

export type AccessReviewChangeUsers = {
  __typename?: "AccessReviewChangeUsers";
  connectionUsers?: Maybe<Array<AccessReviewConnectionUser>>;
  groupUsers?: Maybe<Array<AccessReviewGroupUser>>;
  resourceUsers?: Maybe<Array<AccessReviewResourceUser>>;
};

export type AccessReviewConnection = {
  __typename?: "AccessReviewConnection";
  accessReview?: Maybe<AccessReview>;
  accessReviewId: Scalars["AccessReviewId"];
  canEditReviewers: Scalars["Boolean"];
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  connectionUsers?: Maybe<Array<AccessReviewConnectionUser>>;
  createdAt: Scalars["Time"];
  currentUserReviewerStatus?: Maybe<AccessReviewConnectionReviewerStatus>;
  eventId?: Maybe<Scalars["EventId"]>;
  id: Scalars["AccessReviewConnectionId"];
  numConnectionUsers: Scalars["Int"];
  reviewerUsers: Array<ReviewerUser>;
  status: AccessReviewSummaryStatus;
  updatedAt: Scalars["Time"];
  warnings?: Maybe<Array<AccessReviewConnectionUserWarning>>;
};

export type AccessReviewConnectionInput = {
  id: Scalars["AccessReviewConnectionId"];
};

export type AccessReviewConnectionNotFoundError = Error & {
  __typename?: "AccessReviewConnectionNotFoundError";
  message: Scalars["String"];
};

export type AccessReviewConnectionOutput =
  | AccessReviewConnectionNotFoundError
  | AccessReviewConnectionResult;

export type AccessReviewConnectionResult = {
  __typename?: "AccessReviewConnectionResult";
  accessReviewConnection: AccessReviewConnection;
};

export type AccessReviewConnectionReviewerStatus = {
  __typename?: "AccessReviewConnectionReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numUsersAssigned: Scalars["Int"];
  numUsersReviewed: Scalars["Int"];
};

export type AccessReviewConnectionsInput = {
  id: Scalars["AccessReviewId"];
};

export type AccessReviewConnectionsOutput =
  | AccessReviewConnectionsResult
  | AccessReviewNotFoundError;

export type AccessReviewConnectionsResult = {
  __typename?: "AccessReviewConnectionsResult";
  accessReviewConnections: Array<AccessReviewConnection>;
};

export type AccessReviewConnectionUser = {
  __typename?: "AccessReviewConnectionUser";
  accessReviewConnectionId: Scalars["AccessReviewConnectionId"];
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  createdAt: Scalars["Time"];
  errorMessage?: Maybe<Scalars["String"]>;
  eventId?: Maybe<Scalars["EventId"]>;
  id: Scalars["AccessReviewConnectionUserId"];
  lastSynced?: Maybe<Scalars["Time"]>;
  reviewerUsers?: Maybe<Array<ReviewerUser>>;
  statusAndOutcome: AccessReviewItemStatusAndOutcome;
  statusCode?: Maybe<PropagationStatusCode>;
  taskType?: Maybe<PropagationTaskType>;
  updatedAt: Scalars["Time"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
  warnings?: Maybe<Array<AccessReviewConnectionUserWarning>>;
};

export type AccessReviewConnectionUsersOutput =
  | AccessReviewConnectionUsersResult
  | AccessReviewNotFoundError;

export type AccessReviewConnectionUsersResult = {
  __typename?: "AccessReviewConnectionUsersResult";
  accessReviewConnectionUsers?: Maybe<Array<AccessReviewConnectionUser>>;
};

export type AccessReviewConnectionUserWarning = {
  __typename?: "AccessReviewConnectionUserWarning";
  accessReviewConnectionId: Scalars["AccessReviewConnectionId"];
  accessReviewConnectionUserId: Scalars["AccessReviewConnectionUserId"];
  accessReviewConnectionUserReviewerId: Scalars["AccessReviewConnectionUserReviewerId"];
  accessReviewUserWarningType: AccessReviewUserWarningType;
  connection?: Maybe<ConnectionSmall>;
  reviewer?: Maybe<User>;
  reviewerId?: Maybe<Scalars["UserId"]>;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type AccessReviewFilters = {
  __typename?: "AccessReviewFilters";
  adminIDs: Array<Scalars["OwnerId"]>;
  connectionIDs: Array<Scalars["ConnectionId"]>;
  entityIDs: Array<Scalars["String"]>;
  groupTypes: Array<GroupType>;
  matchMode: FiltersMatchMode;
  names: Array<Scalars["String"]>;
  resourceTypes: Array<ResourceType>;
  tags: Array<TagKeyValue>;
  userIDs: Array<Scalars["UserId"]>;
};

export type AccessReviewGroup = {
  __typename?: "AccessReviewGroup";
  accessReview?: Maybe<AccessReview>;
  accessReviewId: Scalars["AccessReviewId"];
  allGroupResources?: Maybe<Array<GroupResource>>;
  canEditReviewers: Scalars["Boolean"];
  createdAt: Scalars["Time"];
  currentUserReviewerStatus?: Maybe<AccessReviewGroupReviewerStatus>;
  eventId?: Maybe<Scalars["EventId"]>;
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  groupResources?: Maybe<Array<AccessReviewGroupResource>>;
  groupUsers?: Maybe<Array<AccessReviewGroupUser>>;
  id: Scalars["AccessReviewGroupId"];
  isGroupDeleted: Scalars["Boolean"];
  numGroupResources: Scalars["Int"];
  numGroupUsers: Scalars["Int"];
  reviewerUsers: Array<ReviewerUser>;
  status: AccessReviewSummaryStatus;
  updatedAt: Scalars["Time"];
  warnings?: Maybe<Array<AccessReviewGroupUserWarning>>;
};

export type AccessReviewGroupInput = {
  id: Scalars["AccessReviewGroupId"];
};

export type AccessReviewGroupNotFoundError = Error & {
  __typename?: "AccessReviewGroupNotFoundError";
  message: Scalars["String"];
};

export type AccessReviewGroupOutput =
  | AccessReviewGroupNotFoundError
  | AccessReviewGroupResult;

export type AccessReviewGroupProposedChange = {
  __typename?: "AccessReviewGroupProposedChange";
  currentReviewer: ReviewerUser;
  group: GroupSmall;
  proposedReviewer: ReviewerUser;
  user: UserSmall;
};

export type AccessReviewGroupResource = {
  __typename?: "AccessReviewGroupResource";
  accessLevel: ResourceAccessLevel;
  accessReviewGroupId: Scalars["AccessReviewGroupId"];
  accessReviewId: Scalars["AccessReviewId"];
  accessReviewResource?: Maybe<AccessReviewResource>;
  /** This is nullable because this access review may not always include this resource. */
  accessReviewResourceId?: Maybe<Scalars["AccessReviewResourceId"]>;
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  expiration?: Maybe<Scalars["Time"]>;
  groupId: Scalars["GroupId"];
  groupResourceAccess?: Maybe<GroupResourceAccess>;
  id: Scalars["AccessReviewGroupResourceId"];
  isGroupDeleted: Scalars["Boolean"];
  isResourceDeleted: Scalars["Boolean"];
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  reviewerUsers?: Maybe<Array<ReviewerUser>>;
  statusAndOutcome: AccessReviewItemStatusAndOutcome;
  updatedAt: Scalars["Time"];
};

export type AccessReviewGroupResourceAssignment = {
  __typename?: "AccessReviewGroupResourceAssignment";
  accessLevelName?: Maybe<Scalars["AccessLevelName"]>;
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  accessReviewGroupId: Scalars["AccessReviewGroupId"];
  groupId: Scalars["GroupId"];
  groupName: Scalars["String"];
  groupType: GroupType;
  id: Scalars["UUID"];
  outcome: AccessReviewItemOutcome;
  resourceId: Scalars["ResourceId"];
  resourceName: Scalars["String"];
  resourceType: ResourceType;
  reviewerUsers?: Maybe<Array<User>>;
  status: AccessReviewItemStatus;
};

export type AccessReviewGroupResourceAssignmentsInput = {
  accessReviewId: Scalars["AccessReviewId"];
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  assignedStatus?: InputMaybe<AccessReviewAssignedStatus>;
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  groupId?: InputMaybe<Scalars["GroupId"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  outcome?: InputMaybe<AccessReviewItemOutcome>;
  resourceType?: InputMaybe<ResourceType>;
  reviewerId?: InputMaybe<Scalars["UserId"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AssignmentsSortBy>;
  status?: InputMaybe<AccessReviewItemStatus>;
};

export type AccessReviewGroupResourceAssignmentsResult = {
  __typename?: "AccessReviewGroupResourceAssignmentsResult";
  accessReviewGroupResourceAssignments: Array<
    AccessReviewGroupResourceAssignment
  >;
  cursor?: Maybe<Scalars["String"]>;
  totalNumGroupAssignments: Scalars["Int"];
};

export enum AccessReviewGroupResourceVisibilityPolicy {
  Strict = "STRICT",
  ViewAll = "VIEW_ALL",
  ViewVisibleAndAssigned = "VIEW_VISIBLE_AND_ASSIGNED",
}

export type AccessReviewGroupResult = {
  __typename?: "AccessReviewGroupResult";
  accessReviewGroup: AccessReviewGroup;
};

export type AccessReviewGroupReviewerStatus = {
  __typename?: "AccessReviewGroupReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numResourcesAssigned: Scalars["Int"];
  numResourcesReviewed: Scalars["Int"];
  numUsersAssigned: Scalars["Int"];
  numUsersReviewed: Scalars["Int"];
};

export type AccessReviewGroupsInput = {
  id: Scalars["AccessReviewId"];
};

export type AccessReviewGroupsOutput =
  | AccessReviewGroupsResult
  | AccessReviewNotFoundError;

export type AccessReviewGroupsResult = {
  __typename?: "AccessReviewGroupsResult";
  accessReviewGroups: Array<AccessReviewGroup>;
};

export type AccessReviewGroupUser = {
  __typename?: "AccessReviewGroupUser";
  accessLevel: GroupAccessLevel;
  accessReviewGroupId: Scalars["AccessReviewGroupId"];
  createdAt: Scalars["Time"];
  errorMessage?: Maybe<Scalars["String"]>;
  eventId: Scalars["EventId"];
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  groupUser?: Maybe<GroupUser>;
  id: Scalars["AccessReviewGroupUserId"];
  isGroupDeleted: Scalars["Boolean"];
  lastSynced?: Maybe<Scalars["Time"]>;
  requestId?: Maybe<Scalars["RequestId"]>;
  reviewerUsers?: Maybe<Array<ReviewerUser>>;
  statusAndOutcome: AccessReviewItemStatusAndOutcome;
  statusCode?: Maybe<PropagationStatusCode>;
  taskType?: Maybe<PropagationTaskType>;
  updatedAt: Scalars["Time"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
  warnings?: Maybe<Array<AccessReviewGroupUserWarning>>;
};

export type AccessReviewGroupUsersOutput =
  | AccessReviewGroupUsersResult
  | AccessReviewNotFoundError;

export type AccessReviewGroupUsersResult = {
  __typename?: "AccessReviewGroupUsersResult";
  accessReviewGroupUsers?: Maybe<Array<AccessReviewGroupUser>>;
};

export type AccessReviewGroupUserWarning = {
  __typename?: "AccessReviewGroupUserWarning";
  accessReviewGroupId: Scalars["AccessReviewGroupId"];
  accessReviewGroupUserId: Scalars["AccessReviewGroupUserId"];
  accessReviewGroupUserReviewerId: Scalars["AccessReviewGroupUserReviewerId"];
  accessReviewUserWarningType: AccessReviewUserWarningType;
  group?: Maybe<GroupSmall>;
  reviewer?: Maybe<User>;
  reviewerId?: Maybe<Scalars["UserId"]>;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type AccessReviewInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type AccessReviewItem = {
  __typename?: "AccessReviewItem";
  connection?: Maybe<AccessReviewConnection>;
  group?: Maybe<AccessReviewGroup>;
  key: Scalars["String"];
  resource?: Maybe<AccessReviewResource>;
};

export enum AccessReviewItemOutcome {
  Accepted = "ACCEPTED",
  None = "NONE",
  Revoked = "REVOKED",
  Updated = "UPDATED",
}

export type AccessReviewItemsInput = {
  accessReviewId: Scalars["AccessReviewId"];
  cursor?: InputMaybe<Scalars["String"]>;
  reviewerFilter?: InputMaybe<AccessReviewReviewerFilter>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AccessReviewItemsSortBy>;
};

export type AccessReviewItemsResult = {
  __typename?: "AccessReviewItemsResult";
  cursor?: Maybe<Scalars["String"]>;
  items: Array<AccessReviewItem>;
};

export type AccessReviewItemsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AccessReviewItemsSortByField>;
};

export enum AccessReviewItemsSortByField {
  Name = "NAME",
  Status = "STATUS",
}

export enum AccessReviewItemStatus {
  Completed = "COMPLETED",
  NeedsAttention = "NEEDS_ATTENTION",
  NeedsEndSystemRevocation = "NEEDS_END_SYSTEM_REVOCATION",
  NeedsUpdateRequestApproval = "NEEDS_UPDATE_REQUEST_APPROVAL",
  NoReviewNeeded = "NO_REVIEW_NEEDED",
  NotStarted = "NOT_STARTED",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
}

export type AccessReviewItemStatusAndOutcome = {
  __typename?: "AccessReviewItemStatusAndOutcome";
  outcome: AccessReviewItemOutcome;
  status: AccessReviewItemStatus;
};

export type AccessReviewNotFoundError = Error & {
  __typename?: "AccessReviewNotFoundError";
  message: Scalars["String"];
};

export type AccessReviewNotStoppedError = Error & {
  __typename?: "AccessReviewNotStoppedError";
  message: Scalars["String"];
};

export type AccessReviewOutput = AccessReviewNotFoundError | AccessReviewResult;

export type AccessReviewProposedChange =
  | AccessReviewGroupProposedChange
  | AccessReviewResourceProposedChange;

export type AccessReviewProposedChangeInput = {
  accessReviewId: Scalars["AccessReviewId"];
  groupUserReviewerIds?: InputMaybe<
    Array<Scalars["AccessReviewGroupUserReviewerId"]>
  >;
  resourceUserReviewerIds?: InputMaybe<
    Array<Scalars["AccessReviewResourceUserReviewerId"]>
  >;
};

export type AccessReviewProposedChangesOutput =
  | AccessReviewProposedChangesResult
  | NoProposedChangesError;

export type AccessReviewProposedChangesResult = {
  __typename?: "AccessReviewProposedChangesResult";
  proposedChanges?: Maybe<Array<AccessReviewProposedChange>>;
};

export type AccessReviewResource = {
  __typename?: "AccessReviewResource";
  accessReview?: Maybe<AccessReview>;
  accessReviewId: Scalars["AccessReviewId"];
  canEditReviewers: Scalars["Boolean"];
  createdAt: Scalars["Time"];
  currentUserReviewerStatus?: Maybe<AccessReviewResourceReviewerStatus>;
  eventId?: Maybe<Scalars["EventId"]>;
  id: Scalars["AccessReviewResourceId"];
  isResourceDeleted: Scalars["Boolean"];
  numResourceUsers: Scalars["Int"];
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  resourceResources?: Maybe<Array<AccessReviewResourceResource>>;
  resourceUsers?: Maybe<Array<AccessReviewResourceUser>>;
  reviewerUsers: Array<ReviewerUser>;
  status: AccessReviewSummaryStatus;
  updatedAt: Scalars["Time"];
  warnings?: Maybe<Array<AccessReviewResourceUserWarning>>;
};

export type AccessReviewResourceInput = {
  id: Scalars["AccessReviewResourceId"];
};

export type AccessReviewResourceNotFoundError = Error & {
  __typename?: "AccessReviewResourceNotFoundError";
  message: Scalars["String"];
};

export type AccessReviewResourceOutput =
  | AccessReviewResourceNotFoundError
  | AccessReviewResourceResult;

export type AccessReviewResourcePrincipalAssignment = {
  __typename?: "AccessReviewResourcePrincipalAssignment";
  accessLevelName?: Maybe<Scalars["AccessLevelName"]>;
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  entityId: Scalars["EntityId"];
  entityItemType: Scalars["String"];
  entityName: Scalars["String"];
  entityType: EntityType;
  id: Scalars["UUID"];
  outcome: AccessReviewItemOutcome;
  resourcePrincipalId: Scalars["ResourceId"];
  resourcePrincipalName: Scalars["String"];
  resourcePrincipalType: ResourceType;
  reviewerUsers?: Maybe<Array<User>>;
  status: AccessReviewItemStatus;
};

export type AccessReviewResourcePrincipalAssignmentsInput = {
  accessReviewId: Scalars["AccessReviewId"];
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  assignedStatus?: InputMaybe<AccessReviewAssignedStatus>;
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  outcome?: InputMaybe<AccessReviewItemOutcome>;
  resourcePrincipalID?: InputMaybe<Scalars["ResourceId"]>;
  reviewerId?: InputMaybe<Scalars["UserId"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AssignmentsSortBy>;
  status?: InputMaybe<AccessReviewItemStatus>;
};

export type AccessReviewResourcePrincipalAssignmentsResult = {
  __typename?: "AccessReviewResourcePrincipalAssignmentsResult";
  accessReviewResourcePrincipalAssignments: Array<
    AccessReviewResourcePrincipalAssignment
  >;
  cursor?: Maybe<Scalars["String"]>;
  totalNumResourcePrincipalAssignments: Scalars["Int"];
};

export type AccessReviewResourceProposedChange = {
  __typename?: "AccessReviewResourceProposedChange";
  currentReviewer: ReviewerUser;
  proposedReviewer: ReviewerUser;
  resource: ResourceSmall;
  user: UserSmall;
};

export type AccessReviewResourceResource = {
  __typename?: "AccessReviewResourceResource";
  accessLevel: ResourceAccessLevel;
  accessReviewId: Scalars["AccessReviewId"];
  accessReviewResourceEntityId?: Maybe<Scalars["AccessReviewResourceId"]>;
  accessReviewResourcePrincipalId?: Maybe<Scalars["AccessReviewResourceId"]>;
  createdAt: Scalars["Time"];
  eventId?: Maybe<Scalars["EventId"]>;
  id: Scalars["AccessReviewRoleAssignmentId"];
  isResourceEntityDeleted: Scalars["Boolean"];
  isResourcePrincipalDeleted: Scalars["Boolean"];
  resourceEntity?: Maybe<Resource>;
  resourceEntityId: Scalars["ResourceId"];
  resourcePrincipal?: Maybe<Resource>;
  resourcePrincipalId: Scalars["ResourceId"];
  resourceResourceAccess?: Maybe<Access>;
  reviewerUsers?: Maybe<Array<ReviewerUser>>;
  statusAndOutcome: AccessReviewItemStatusAndOutcome;
  updatedAt: Scalars["Time"];
};

export type AccessReviewResourceResult = {
  __typename?: "AccessReviewResourceResult";
  accessReviewResource: AccessReviewResource;
};

export type AccessReviewResourceReviewerStatus = {
  __typename?: "AccessReviewResourceReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numNHIsAssigned: Scalars["Int"];
  numNHIsReviewed: Scalars["Int"];
  numResourcesAssigned: Scalars["Int"];
  numResourcesReviewed: Scalars["Int"];
  numUsersAssigned: Scalars["Int"];
  numUsersReviewed: Scalars["Int"];
};

export type AccessReviewResourcesInput = {
  id: Scalars["AccessReviewId"];
};

export type AccessReviewResourcesOutput =
  | AccessReviewNotFoundError
  | AccessReviewResourcesResult;

export type AccessReviewResourcesResult = {
  __typename?: "AccessReviewResourcesResult";
  accessReviewResources: Array<AccessReviewResource>;
};

export type AccessReviewResourceUser = {
  __typename?: "AccessReviewResourceUser";
  accessLevel: ResourceAccessLevel;
  accessReviewResourceId: Scalars["AccessReviewResourceId"];
  createdAt: Scalars["Time"];
  errorMessage?: Maybe<Scalars["String"]>;
  eventId: Scalars["EventId"];
  id: Scalars["AccessReviewResourceUserId"];
  isResourceDeleted: Scalars["Boolean"];
  lastSynced?: Maybe<Scalars["Time"]>;
  requestId?: Maybe<Scalars["RequestId"]>;
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  resourceUser?: Maybe<ResourceUser>;
  reviewerUsers?: Maybe<Array<ReviewerUser>>;
  statusAndOutcome: AccessReviewItemStatusAndOutcome;
  statusCode?: Maybe<PropagationStatusCode>;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
  taskType?: Maybe<PropagationTaskType>;
  updatedAt: Scalars["Time"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
  warnings?: Maybe<Array<AccessReviewResourceUserWarning>>;
};

export type AccessReviewResourceUsersOutput =
  | AccessReviewNotFoundError
  | AccessReviewResourceUsersResult;

export type AccessReviewResourceUsersResult = {
  __typename?: "AccessReviewResourceUsersResult";
  accessReviewResourceUsers?: Maybe<Array<AccessReviewResourceUser>>;
};

export type AccessReviewResourceUserWarning = {
  __typename?: "AccessReviewResourceUserWarning";
  accessReviewResourceId: Scalars["AccessReviewResourceId"];
  accessReviewResourceUserId: Scalars["AccessReviewResourceUserId"];
  accessReviewResourceUserReviewerId: Scalars["AccessReviewResourceUserReviewerId"];
  accessReviewUserWarningType: AccessReviewUserWarningType;
  resource?: Maybe<ResourceSmall>;
  reviewer?: Maybe<User>;
  reviewerId?: Maybe<Scalars["UserId"]>;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type AccessReviewResult = {
  __typename?: "AccessReviewResult";
  accessReview: AccessReview;
};

export type AccessReviewReviewer = {
  __typename?: "AccessReviewReviewer";
  completedGroupResourcesCount: Scalars["Int"];
  completedItemsCount: Scalars["Int"];
  completedNHIsCount: Scalars["Int"];
  completedUsersCount: Scalars["Int"];
  items?: Maybe<Array<AccessReviewReviewerItem>>;
  totalGroupResourcesCount: Scalars["Int"];
  totalItemsCount: Scalars["Int"];
  totalNHIsCount: Scalars["Int"];
  totalUsersCount: Scalars["Int"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export enum AccessReviewReviewerAssignmentPolicy {
  ByApprovers = "BY_APPROVERS",
  ByApproversAll = "BY_APPROVERS_ALL",
  ByManager = "BY_MANAGER",
  ByOwningTeamAdmin = "BY_OWNING_TEAM_ADMIN",
  ByOwningTeamAdminAll = "BY_OWNING_TEAM_ADMIN_ALL",
  Manually = "MANUALLY",
}

export type AccessReviewReviewerFilter = {
  userId?: InputMaybe<Scalars["UserId"]>;
};

export type AccessReviewReviewerInput = {
  accessReviewId: Scalars["AccessReviewId"];
  userId: Scalars["UserId"];
};

export type AccessReviewReviewerItem = {
  __typename?: "AccessReviewReviewerItem";
  item: AccessReviewItem;
  reviewStatus: AccessReviewItemStatus;
};

export type AccessReviewReviewerOutput = {
  __typename?: "AccessReviewReviewerOutput";
  reviewer: AccessReviewReviewer;
};

export type AccessReviewsInput = {
  ongoingOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type AccessReviewsOutput = AccessReviewsResult;

export type AccessReviewsResult = {
  __typename?: "AccessReviewsResult";
  accessReviews: Array<AccessReview>;
};

export type AccessReviewStats = {
  __typename?: "AccessReviewStats";
  incompleteReviewers: Array<IncompleteAccessReviewer>;
  numConnectionsReviewed: Scalars["Int"];
  numConnectionsTotal: Scalars["Int"];
  numGroupResourceAssignmentsCompleted: Scalars["Int"];
  numGroupResourceAssignmentsInReview: Scalars["Int"];
  numGroupResourceAssignmentsTotal: Scalars["Int"];
  numGroupsReviewed: Scalars["Int"];
  numGroupsTotal: Scalars["Int"];
  numNHIAssignmentsCompleted: Scalars["Int"];
  numNHIAssignmentsInReview: Scalars["Int"];
  numNHIAssignmentsTotal: Scalars["Int"];
  numResourcesReviewed: Scalars["Int"];
  numResourcesTotal: Scalars["Int"];
  numReviewersIncomplete: Scalars["Int"];
  numReviewersTotal: Scalars["Int"];
  numUserAssignmentsCompleted: Scalars["Int"];
  numUserAssignmentsInReview: Scalars["Int"];
  numUserAssignmentsTotal: Scalars["Int"];
};

export type AccessReviewStatsInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type AccessReviewStatsOutput =
  | AccessReviewNotFoundError
  | AccessReviewStatsResult;

export type AccessReviewStatsResult = {
  __typename?: "AccessReviewStatsResult";
  accessReviewStats?: Maybe<AccessReviewStats>;
};

export enum AccessReviewStatus {
  Completed = "COMPLETED",
  Started = "STARTED",
}

export enum AccessReviewSummaryStatus {
  Completed = "COMPLETED",
  NeedsAttention = "NEEDS_ATTENTION",
  NeedsEndSystemRevocation = "NEEDS_END_SYSTEM_REVOCATION",
  NeedsUpdateRequestApproval = "NEEDS_UPDATE_REQUEST_APPROVAL",
  NoReviewNeeded = "NO_REVIEW_NEEDED",
  NotStarted = "NOT_STARTED",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
}

export enum AccessReviewTab {
  AppsTab = "APPS_TAB",
  GroupsTab = "GROUPS_TAB",
  ResourceTab = "RESOURCE_TAB",
  UsersTab = "USERS_TAB",
}

export type AccessReviewTemplate = {
  __typename?: "AccessReviewTemplate";
  accessReviewDuration: Scalars["Int"];
  createdAt: Scalars["Time"];
  createdByUser?: Maybe<User>;
  createdByUserId: Scalars["UserId"];
  filters?: Maybe<AccessReviewFilters>;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  id: Scalars["AccessReviewTemplateId"];
  includeGroupBindings: Scalars["Boolean"];
  monthSchedule: Array<Scalars["Int"]>;
  name: Scalars["String"];
  nextScheduledRun: Scalars["Time"];
  reminderIncludeManager: Scalars["Boolean"];
  reminderSchedule: Array<Scalars["Int"]>;
  reminderTimeOfDay: Scalars["Time"];
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  scopeAppsCount: Scalars["Int"];
  scopeGroupsCount: Scalars["Int"];
  scopeResourcesCount: Scalars["Int"];
  selfReviewAllowed: Scalars["Boolean"];
  sendReviewerAssignmentNotification: Scalars["Boolean"];
  startDayOfMonth: Scalars["Int"];
  timeZone: Scalars["String"];
  updatedAt: Scalars["Time"];
};

export type AccessReviewTemplateInput = {
  accessReviewTemplateId: Scalars["AccessReviewTemplateId"];
};

export type AccessReviewTemplateNotFound = Error & {
  __typename?: "AccessReviewTemplateNotFound";
  message: Scalars["String"];
};

export type AccessReviewTemplateOutput =
  | AccessReviewTemplateNotFound
  | AccessReviewTemplateResult;

export type AccessReviewTemplateResult = {
  __typename?: "AccessReviewTemplateResult";
  accessReviewTemplate: AccessReviewTemplate;
};

export type AccessReviewTemplatesOutput = AccessReviewTemplatesResult;

export type AccessReviewTemplatesResult = {
  __typename?: "AccessReviewTemplatesResult";
  accessReviewTemplates: Array<AccessReviewTemplate>;
};

export enum AccessReviewType {
  ConnectionUser = "CONNECTION_USER",
  GroupResource = "GROUP_RESOURCE",
  GroupUser = "GROUP_USER",
  ResourceResource = "RESOURCE_RESOURCE",
  ResourceUser = "RESOURCE_USER",
}

export type AccessReviewUser = {
  __typename?: "AccessReviewUser";
  accessReview?: Maybe<AccessReview>;
  accessReviewId: Scalars["AccessReviewId"];
  canEditReviewers: Scalars["Boolean"];
  groupUsers: Array<AccessReviewGroupUser>;
  groupWarnings: Array<AccessReviewGroupUserWarning>;
  resourceUsers: Array<AccessReviewResourceUser>;
  resourceWarnings: Array<AccessReviewResourceUserWarning>;
  reviewerUsers: Array<ReviewerUser>;
  status: AccessReviewSummaryStatus;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type AccessReviewUserAssignment = {
  __typename?: "AccessReviewUserAssignment";
  accessLevelName?: Maybe<Scalars["AccessLevelName"]>;
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  accessReviewObjectId: Scalars["UUID"];
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  connectionType?: Maybe<ConnectionType>;
  entityName: Scalars["String"];
  groupId?: Maybe<Scalars["GroupId"]>;
  groupType?: Maybe<GroupType>;
  id: Scalars["UUID"];
  outcome: AccessReviewItemOutcome;
  parentResourceName?: Maybe<Scalars["String"]>;
  resourceId?: Maybe<Scalars["ResourceId"]>;
  resourceType?: Maybe<ResourceType>;
  reviewerUsers?: Maybe<Array<User>>;
  status: AccessReviewItemStatus;
  userAvatarUrl: Scalars["String"];
  userFullname: Scalars["String"];
  userId: Scalars["UserId"];
};

export type AccessReviewUserAssignmentsInput = {
  accessReviewId: Scalars["AccessReviewId"];
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  assignedStatus?: InputMaybe<AccessReviewAssignedStatus>;
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  outcome?: InputMaybe<AccessReviewItemOutcome>;
  reviewerId?: InputMaybe<Scalars["UserId"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AssignmentsSortBy>;
  status?: InputMaybe<AccessReviewItemStatus>;
  userId?: InputMaybe<Scalars["UserId"]>;
};

export type AccessReviewUserAssignmentsResult = {
  __typename?: "AccessReviewUserAssignmentsResult";
  accessReviewUserAssignments: Array<AccessReviewUserAssignment>;
  cursor?: Maybe<Scalars["String"]>;
  totalNumUserAssignments: Scalars["Int"];
};

export type AccessReviewUserInput = {
  accessReviewId: Scalars["AccessReviewId"];
  userId: Scalars["UserId"];
};

export type AccessReviewUserNotFoundError = Error & {
  __typename?: "AccessReviewUserNotFoundError";
  message: Scalars["String"];
};

export type AccessReviewUserOutput =
  | AccessReviewUserNotFoundError
  | AccessReviewUserResult;

export type AccessReviewUserResult = {
  __typename?: "AccessReviewUserResult";
  accessReviewUser: AccessReviewUser;
};

export type AccessReviewUsersInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type AccessReviewUsersOutput =
  | AccessReviewNotFoundError
  | AccessReviewUsersResult;

export type AccessReviewUsersResult = {
  __typename?: "AccessReviewUsersResult";
  accessReviewUsers: Array<AccessReviewUser>;
};

export type AccessReviewUserSupportTicketAlreadyLinkedError = Error & {
  __typename?: "AccessReviewUserSupportTicketAlreadyLinkedError";
  message: Scalars["String"];
};

export enum AccessReviewUserWarningType {
  ReviewerDeleted = "REVIEWER_DELETED",
  ReviewerNoVisibility = "REVIEWER_NO_VISIBILITY",
  SelfReviewNotAllowed = "SELF_REVIEW_NOT_ALLOWED",
}

export enum AccessType {
  Birthright = "BIRTHRIGHT",
  Expiring = "EXPIRING",
  Longstanding = "LONGSTANDING",
}

export type AccessTypeData = {
  __typename?: "AccessTypeData";
  expiration?: Maybe<Scalars["Time"]>;
  type: AccessType;
};

export type ActiveGroupRequestConfigurationInput = {
  groupInputs: Array<RequestConfigurationGroupInput>;
  targetUserId?: InputMaybe<Scalars["UserId"]>;
};

export type ActiveResourceRequestConfigurationInput = {
  resourceInputs: Array<RequestConfigurationResourceInput>;
  targetUserId?: InputMaybe<Scalars["UserId"]>;
};

export type ActorFilter = {
  userId: Scalars["UserId"];
};

export type AdConnectionCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUsername: Scalars["String"];
};

export type AdConnectionMetadata = {
  __typename?: "ADConnectionMetadata";
  groupsDistinguishedName: Scalars["String"];
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  usersDistinguishedName: Scalars["String"];
};

export type AdConnectionMetadataInput = {
  groupsDistinguishedName: Scalars["String"];
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  usersDistinguishedName: Scalars["String"];
};

export type AddBigDataInput = {
  appsCount: Scalars["Int"];
  appsVisibilityGroupsCount?: InputMaybe<Scalars["Int"]>;
  appUsersCount: Scalars["Int"];
  bundleGroupsCount: Scalars["Int"];
  bundleResourcesCount: Scalars["Int"];
  bundlesCount: Scalars["Int"];
  groupResourcesCount: Scalars["Int"];
  groupsCount: Scalars["Int"];
  groupsVisibilityGroupsCount?: InputMaybe<Scalars["Int"]>;
  groupUsersCount: Scalars["Int"];
  ownersCount: Scalars["Int"];
  ownerUsersCount: Scalars["Int"];
  requestsCount: Scalars["Int"];
  resourcesCount: Scalars["Int"];
  resourcesVisibilityGroupsCount?: InputMaybe<Scalars["Int"]>;
  resourceUsersCount: Scalars["Int"];
  sessionsCount: Scalars["Int"];
  tagsCount: Scalars["Int"];
  usersCount: Scalars["Int"];
};

export type AddBigDataOutput = AddBigDataResult;

export type AddBigDataResult = {
  __typename?: "AddBigDataResult";
  success: Scalars["Boolean"];
};

export type AddConnectionUserInput = {
  connectionId: Scalars["ConnectionId"];
  userId: Scalars["UserId"];
};

export type AddConnectionUsersInput = {
  connectionUsers: Array<AddConnectionUserInput>;
};

export type AddConnectionUsersOutput =
  | AddConnectionUsersResult
  | ConnectionNotFoundError
  | ConnectionUserAlreadyExists;

export type AddConnectionUsersResult = {
  __typename?: "AddConnectionUsersResult";
  success: Scalars["Boolean"];
  taskId: Scalars["PushTaskId"];
};

export type AddGroupGroupInput = {
  accessLevel?: InputMaybe<GroupAccessLevelInput>;
  containingGroupId: Scalars["GroupId"];
  memberGroupId: Scalars["GroupId"];
};

export type AddGroupGroupsEntryOutput = AddGroupGroupsEntryResult;

export type AddGroupGroupsEntryResult = {
  __typename?: "AddGroupGroupsEntryResult";
  groupGroup: GroupGroup;
};

export type AddGroupGroupsInput = {
  groupGroups: Array<AddGroupGroupInput>;
};

export type AddGroupGroupsOutput =
  | AddGroupGroupsResult
  | CallToWebhookFailedError
  | GroupGroupAlreadyExists
  | GroupNestingNotAllowedError
  | GroupNotFoundError
  | UserFacingError;

export type AddGroupGroupsResult = {
  __typename?: "AddGroupGroupsResult";
  entries: Array<AddGroupGroupsEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type AddGroupResourceInput = {
  accessLevel: ResourceAccessLevelInput;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  groupId: Scalars["GroupId"];
  resourceId: Scalars["ResourceId"];
};

export type AddGroupResourcesEntryOutput = AddGroupResourcesEntryResult;

export type AddGroupResourcesEntryResult = {
  __typename?: "AddGroupResourcesEntryResult";
  groupResource: GroupResource;
};

export type AddGroupResourcesInput = {
  groupResources: Array<AddGroupResourceInput>;
};

export type AddGroupResourcesOutput =
  | AddGroupResourcesResult
  | CallToWebhookFailedError
  | GroupNotFoundError
  | GroupResourceAlreadyExists
  | UserFacingError;

export type AddGroupResourcesResult = {
  __typename?: "AddGroupResourcesResult";
  entries: Array<AddGroupResourcesEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type AddGroupTagInput = {
  groupId: Scalars["GroupId"];
  tagId: Scalars["TagId"];
};

export type AddGroupTagsEntryOutput = AddGroupTagsEntryResult;

export type AddGroupTagsEntryResult = {
  __typename?: "AddGroupTagsEntryResult";
  groupTag: GroupTag;
};

export type AddGroupTagsInput = {
  groupTags: Array<AddGroupTagInput>;
};

export type AddGroupTagsOutput =
  | AddGroupTagsResult
  | GroupNotFoundError
  | TagNotFoundError;

export type AddGroupTagsResult = {
  __typename?: "AddGroupTagsResult";
  entries: Array<AddGroupTagsEntryOutput>;
};

export type AddGroupUserInput = {
  accessLevel?: InputMaybe<GroupAccessLevelInput>;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  groupId: Scalars["GroupId"];
  userId: Scalars["UserId"];
};

export type AddGroupUsersEntryOutput = AddGroupUsersEntryResult;

export type AddGroupUsersEntryResult = {
  __typename?: "AddGroupUsersEntryResult";
  groupUser: GroupUser;
};

export type AddGroupUsersInput = {
  groupUsers: Array<AddGroupUserInput>;
};

export type AddGroupUsersOutput =
  | AddGroupUsersResult
  | CallToWebhookFailedError
  | CannotAddSystemUserToGroupError
  | GroupNotFoundError
  | GroupUserAlreadyExists;

export type AddGroupUsersResult = {
  __typename?: "AddGroupUsersResult";
  entries: Array<AddGroupUsersEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type AddResourceTagInput = {
  resourceId: Scalars["ResourceId"];
  tagId: Scalars["TagId"];
};

export type AddResourceTagsEntryOutput = AddResourceTagsEntryResult;

export type AddResourceTagsEntryResult = {
  __typename?: "AddResourceTagsEntryResult";
  resourceTag: ResourceTag;
};

export type AddResourceTagsInput = {
  resourceTags: Array<AddResourceTagInput>;
};

export type AddResourceTagsOutput =
  | AddResourceTagsResult
  | ResourceNotFoundError
  | TagNotFoundError;

export type AddResourceTagsResult = {
  __typename?: "AddResourceTagsResult";
  entries: Array<AddResourceTagsEntryOutput>;
};

export type AddResourceUserInput = {
  accessLevel: ResourceAccessLevelInput;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type AddResourceUsersEntryOutput = AddResourceUsersEntryResult;

export type AddResourceUsersEntryResult = {
  __typename?: "AddResourceUsersEntryResult";
  resourceUser: ResourceUser;
};

export type AddResourceUsersInput = {
  resourceUsers: Array<AddResourceUserInput>;
};

export type AddResourceUsersOutput =
  | AddResourceUsersResult
  | CallToWebhookFailedError
  | CannotAddSystemUserToResourceError
  | OpalGlobalImpersonationResourceDirectAddNotAllowedError
  | ResourceNotFoundError
  | ResourceUserAlreadyExists;

export type AddResourceUsersResult = {
  __typename?: "AddResourceUsersResult";
  entries: Array<AddResourceUsersEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type AddRoleAssignmentInput = {
  accessLevel?: InputMaybe<AccessLevelInput>;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  entityID: Scalars["EntityId"];
  entityType: EntityType;
  principalID: Scalars["PrincipalId"];
  principalType: EntityType;
};

export type AddRoleAssignmentsEntryOutput = AddRoleAssignmentsEntryResult;

export type AddRoleAssignmentsEntryResult = {
  __typename?: "AddRoleAssignmentsEntryResult";
  roleAssignment: RoleAssignment;
};

export type AddRoleAssignmentsInput = {
  roleAssignments: Array<AddRoleAssignmentInput>;
};

export type AddRoleAssignmentsOutput =
  | AddRoleAssignmentsResult
  | UserFacingError;

export type AddRoleAssignmentsResult = {
  __typename?: "AddRoleAssignmentsResult";
  entries: Array<AddRoleAssignmentsEntryOutput>;
  taskIds: Array<Scalars["PushTaskId"]>;
};

export type AddUserTagInput = {
  tagId: Scalars["TagId"];
  userId: Scalars["UserId"];
};

export type AddUserTagsEntryOutput = AddUserTagsEntryResult;

export type AddUserTagsEntryResult = {
  __typename?: "AddUserTagsEntryResult";
  userTag: UserTag;
};

export type AddUserTagsInput = {
  userTags: Array<AddUserTagInput>;
};

export type AddUserTagsOutput =
  | AddUserTagsResult
  | TagNotFoundError
  | UserNotFoundError;

export type AddUserTagsResult = {
  __typename?: "AddUserTagsResult";
  entries: Array<AddUserTagsEntryOutput>;
};

export type AdminApproveRequiresReasonError = Error & {
  __typename?: "AdminApproveRequiresReasonError";
  message: Scalars["String"];
};

export type AdminsFilterInput = {
  adminIDs: Array<Scalars["OwnerId"]>;
};

export enum AldwinRole {
  Admin = "ADMIN",
  Auditor = "AUDITOR",
  GlobalRequester = "GLOBAL_REQUESTER",
  ReadOnlyAdmin = "READ_ONLY_ADMIN",
}

export type AllowedToCreateOrganizationsOutput = AllowedToCreateOrganizationsResult;

export type AllowedToCreateOrganizationsResult = {
  __typename?: "AllowedToCreateOrganizationsResult";
  allowed: Scalars["Boolean"];
};

export type AllowedToUseAldwinOutput = AllowedToUseAldwinResult;

export type AllowedToUseAldwinResult = {
  __typename?: "AllowedToUseAldwinResult";
  allowed: Scalars["Boolean"];
};

export enum ApiAccessLevel {
  FullAccess = "FULL_ACCESS",
  ReadOnly = "READ_ONLY",
}

export enum ApiAuthType {
  Admin = "ADMIN",
  AdminDangerouslyAllowImpersonation = "ADMIN_DANGEROUSLY_ALLOW_IMPERSONATION",
  Auth = "AUTH",
  OnPrem = "ON_PREM",
  Presigned = "PRESIGNED",
  ReadOnlyAdmin = "READ_ONLY_ADMIN",
  Unauth = "UNAUTH",
}

export type ApiTokenFilter = {
  apiTokenLabel: Scalars["String"];
  apiTokenPreview: Scalars["String"];
};

export type App = {
  __typename?: "App";
  app: AppData;
  groupAccessCount: Scalars["Int"];
  id: Scalars["UUID"];
  items: AppItemsOutput;
  itemTypes: Array<AppItemType>;
  name: Scalars["String"];
  remoteItems: RemoteAppItemsOutput;
  resourceCount: Scalars["Int"];
  threats: Array<RecommendationsEntityMetadata>;
  userAccessCount: Scalars["Int"];
  visibility: Visibility;
};

export type AppItemsArgs = {
  input: AppItemsInput;
};

export type AppItemTypesArgs = {
  access: AccessOption;
  ancestorResourceId?: InputMaybe<NullableResourceId>;
  parentResourceId?: InputMaybe<NullableResourceId>;
};

export type AppRemoteItemsArgs = {
  input: RemoteAppItemsInput;
};

export enum AppCategory {
  All = "ALL",
  CloudProviders = "CLOUD_PROVIDERS",
  Custom = "CUSTOM",
  Databases = "DATABASES",
  DeveloperTools = "DEVELOPER_TOOLS",
  Identity = "IDENTITY",
  OktaApp = "OKTA_APP",
}

export type AppData = ConnectionApp | OktaResourceApp;

export type AppIdInput = {
  id: Scalars["UUID"];
  type: AppType;
};

export type AppInfo = {
  __typename?: "AppInfo";
  environment: Scalars["String"];
  isOnPrem: Scalars["Boolean"];
  isRemoteLoggingEnabled: Scalars["Boolean"];
  version: Scalars["String"];
};

export type AppInfoOutput = AppInfoResult;

export type AppInfoResult = {
  __typename?: "AppInfoResult";
  appInfo: AppInfo;
};

export type AppItem = {
  __typename?: "AppItem";
  group?: Maybe<Group>;
  key: Scalars["String"];
  resource?: Maybe<Resource>;
};

export type AppItemsFilters = {
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type AppItemsInput = {
  access: AccessOption;
  ancestorResourceId?: InputMaybe<NullableResourceId>;
  cursor?: InputMaybe<Scalars["String"]>;
  hasV3?: InputMaybe<Scalars["Boolean"]>;
  includeGroups?: InputMaybe<Scalars["Boolean"]>;
  includeOnlyRequestable?: InputMaybe<Scalars["Boolean"]>;
  includeOnlyUnmanaged?: InputMaybe<Scalars["Boolean"]>;
  includeUnmanagedItems?: InputMaybe<Scalars["Boolean"]>;
  itemType?: InputMaybe<Scalars["String"]>;
  parentResourceId?: InputMaybe<NullableResourceId>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
};

export type AppItemsOutput = {
  __typename?: "AppItemsOutput";
  cursor?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<AppItem>>;
  totalNumItems: Scalars["Int"];
};

export type AppItemsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AppItemsSortByField>;
};

export enum AppItemsSortByField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  RemoteId = "REMOTE_ID",
}

export type AppItemType = {
  __typename?: "AppItemType";
  count: Scalars["Int"];
  displayText: Scalars["String"];
  itemType: Scalars["String"];
};

export type AppNotFoundError = Error & {
  __typename?: "AppNotFoundError";
  message: Scalars["String"];
};

export type AppOutput = App | AppNotFoundError;

export type ApproveRequestInput = {
  comment?: InputMaybe<Scalars["String"]>;
  id: Scalars["RequestId"];
  level?: InputMaybe<RequestDecisionLevel>;
};

export type ApproveRequestOutput =
  | AdminApproveRequiresReasonError
  | ApproveRequestResult
  | AwsRoleCreationError
  | AwsRolePolicyInvalidError
  | GroupNestingNotAllowedError
  | MfaInvalidError
  | OrganizationInReadOnlyModeError
  | RequestAlreadyActionedError
  | RequestNotFoundError;

export type ApproveRequestResult = {
  __typename?: "ApproveRequestResult";
  request: Request;
  taskId?: Maybe<Scalars["PushTaskId"]>;
};

export type AppsOutput = {
  __typename?: "AppsOutput";
  apps: Array<App>;
  cursor?: Maybe<Scalars["String"]>;
  totalNumApps: Scalars["Int"];
};

export type AppsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AppsSortByField>;
};

export enum AppsSortByField {
  Name = "NAME",
  ResourceCount = "RESOURCE_COUNT",
  Source = "SOURCE",
  Visiblity = "VISIBLITY",
}

export enum AppType {
  Native = "NATIVE",
  Okta = "OKTA",
}

export type AssignmentsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<AssignmentsSortByField>;
};

export enum AssignmentsSortByField {
  EntityName = "ENTITY_NAME",
  PrincipalName = "PRINCIPAL_NAME",
  Type = "TYPE",
}

export type AssumeImpersonationInput = {
  userId: Scalars["UserId"];
};

export type AssumeImpersonationOutput = AssumeImpersonationResult;

export type AssumeImpersonationResult = {
  __typename?: "AssumeImpersonationResult";
  success: Scalars["Boolean"];
};

export type AuthCodeCallbackInput = {
  code: Scalars["String"];
  state: Scalars["String"];
};

export type AuthCodeCallbackOutput =
  | AuthCodeCallbackResult
  | SamlLoginRequiredError
  | UserAlreadyExistsInAnotherOrg
  | UserNotFoundError;

export type AuthCodeCallbackResult = {
  __typename?: "AuthCodeCallbackResult";
  user: AuthUser;
};

export enum AuthFlowType {
  Code = "CODE",
  Link = "LINK",
  Manual = "MANUAL",
}

export type AuthProviderInfo = {
  __typename?: "AuthProviderInfo";
  audience: Scalars["String"];
  clientId: Scalars["String"];
  domain: Scalars["String"];
  logoutUri: Scalars["String"];
  redirectUri: Scalars["String"];
};

export type AuthProviderInfoOutput =
  | AuthProviderInfoResult
  | ThirdPartyClientNotFoundError;

export type AuthProviderInfoResult = {
  __typename?: "AuthProviderInfoResult";
  providerInfo: AuthProviderInfo;
};

export type AuthSessionExpirationInvalidError = Error & {
  __typename?: "AuthSessionExpirationInvalidError";
  message: Scalars["String"];
};

export enum AuthSessionStatus {
  SessionExpired = "SESSION_EXPIRED",
  SessionNotFound = "SESSION_NOT_FOUND",
  SessionValid = "SESSION_VALID",
}

export type AuthSessionStatusOutput = AuthSessionStatusResult;

export type AuthSessionStatusResult = {
  __typename?: "AuthSessionStatusResult";
  status: AuthSessionStatus;
  user?: Maybe<AuthUser>;
  userImpersonator?: Maybe<User>;
};

export enum AuthType {
  ActiveDirectory = "ACTIVE_DIRECTORY",
  Aws = "AWS",
  AwsSso = "AWS_SSO",
  AzureAd = "AZURE_AD",
  Custom = "CUSTOM",
  CustomConnector = "CUSTOM_CONNECTOR",
  Duo = "DUO",
  Gcp = "GCP",
  GitHub = "GIT_HUB",
  GitLab = "GIT_LAB",
  GoogleGroups = "GOOGLE_GROUPS",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Ldap = "LDAP",
  Mongo = "MONGO",
  MongoAtlas = "MONGO_ATLAS",
  Mysqlmariadb = "MYSQLMARIADB",
  OktaDirectory = "OKTA_DIRECTORY",
  Pagerduty = "PAGERDUTY",
  Postgres = "POSTGRES",
  Salesforce = "SALESFORCE",
  Snowflake = "SNOWFLAKE",
  Tailscale = "TAILSCALE",
  Teleport = "TELEPORT",
  Workday = "WORKDAY",
}

export type AuthUser = {
  __typename?: "AuthUser";
  isAdmin: Scalars["Boolean"];
  isAuditor: Scalars["Boolean"];
  isGlobalRequester: Scalars["Boolean"];
  isReadOnlyAdmin: Scalars["Boolean"];
  opalAdminExpirationTime?: Maybe<Scalars["Time"]>;
  organization: Organization;
  organizationAuthorizedActions?: Maybe<Array<Scalars["AuthorizedAction"]>>;
  user: User;
};

export type AwsConnectionCredentialsInput = {
  accessKeyId: Scalars["String"];
  secretAccessKey: Scalars["String"];
};

export type AwsConnectionMetadata = {
  __typename?: "AwsConnectionMetadata";
  success?: Maybe<Scalars["Boolean"]>;
};

export type AwsConnectionMetadataInput = {
  success?: InputMaybe<Scalars["Boolean"]>;
};

export type AwsIamFederatedEksSession = AwsIamFederatedSession & {
  __typename?: "AwsIamFederatedEksSession";
  awsAccessKeyId: Scalars["String"];
  awsSecretAccessKey: Scalars["String"];
  awsSessionToken: Scalars["String"];
  clusterName: Scalars["String"];
  clusterRegion: Scalars["String"];
  federatedArn: Scalars["String"];
  id: Scalars["ResourceId"];
};

export type AwsIamFederatedRdsSession = AwsIamFederatedSession & {
  __typename?: "AwsIamFederatedRdsSession";
  awsAccessKeyId: Scalars["String"];
  awsSecretAccessKey: Scalars["String"];
  awsSessionToken: Scalars["String"];
  dbEngine: Scalars["String"];
  dbHostname: Scalars["String"];
  dbInstanceId: Scalars["String"];
  dbName: Scalars["String"];
  dbPassword: Scalars["String"];
  dbPort: Scalars["Int"];
  dbUser: Scalars["String"];
  federatedArn: Scalars["String"];
  id: Scalars["ResourceId"];
};

export type AwsIamFederatedRoleSession = AwsIamFederatedSession & {
  __typename?: "AwsIamFederatedRoleSession";
  awsAccessKeyId: Scalars["String"];
  awsLoginUrl: Scalars["String"];
  awsSecretAccessKey: Scalars["String"];
  awsSessionToken: Scalars["String"];
  federatedArn: Scalars["String"];
  id: Scalars["ResourceId"];
};

export type AwsIamFederatedSession = {
  awsAccessKeyId: Scalars["String"];
  awsSecretAccessKey: Scalars["String"];
  awsSessionToken: Scalars["String"];
};

export type AwsIamFederatedSsmSession = AwsIamFederatedSession & {
  __typename?: "AwsIamFederatedSSMSession";
  awsAccessKeyId: Scalars["String"];
  awsLoginUrl: Scalars["String"];
  awsSecretAccessKey: Scalars["String"];
  awsSessionToken: Scalars["String"];
  ec2InstanceId: Scalars["String"];
  ec2Region: Scalars["String"];
  federatedArn: Scalars["String"];
  id: Scalars["ResourceId"];
};

export enum AwsIdentityCenterImportSetting {
  All = "ALL",
  Manual = "MANUAL",
  Provisioned = "PROVISIONED",
}

export type AwsRoleCreationError = Error & {
  __typename?: "AWSRoleCreationError";
  message: Scalars["String"];
};

export type AwsRoleCreationReviewerNotSetError = Error & {
  __typename?: "AWSRoleCreationReviewerNotSetError";
  message: Scalars["String"];
};

export type AwsRoleMetadata = {
  __typename?: "AwsRoleMetadata";
  arn: Scalars["String"];
};

export type AwsRolePolicyInvalidError = Error & {
  __typename?: "AWSRolePolicyInvalidError";
  message: Scalars["String"];
};

export type AwsRoleProposalMetadata = {
  __typename?: "AWSRoleProposalMetadata";
  assumeRolePolicyDocument: Scalars["String"];
  policyDescription: Scalars["String"];
  policyDocument: Scalars["String"];
  policyName: Scalars["String"];
  roleDescription: Scalars["String"];
  roleName: Scalars["String"];
  tags: Array<AwsTag>;
};

export type AwsRoleProposalMetadataInput = {
  assumeRolePolicyDocument: Scalars["String"];
  policyDescription: Scalars["String"];
  policyDocument: Scalars["String"];
  policyName: Scalars["String"];
  roleDescription: Scalars["String"];
  roleName: Scalars["String"];
  tags: Array<AwsTagInput>;
};

export type AwsssoConnectionCredentialsInput = {
  accessKeyId?: InputMaybe<Scalars["String"]>;
  secretAccessKey?: InputMaybe<Scalars["String"]>;
};

export type AwsssoConnectionMetadata = {
  __typename?: "AWSSSOConnectionMetadata";
  accessPortalUrl: Scalars["String"];
  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
  awsOrganizationEnabled: Scalars["Boolean"];
  awsOrganizationImportSetting: ImportSetting;
  awsRegion: Scalars["String"];
  awsSsoEnabled: Scalars["Boolean"];
  cloudtrailEventsSqsUrl: Scalars["String"];
  ec2RoleChainingEnabled: Scalars["Boolean"];
  identityStoreId: Scalars["String"];
  managementAccountId: Scalars["String"];
  podRoleChainingEnabled: Scalars["Boolean"];
  ssoInstanceArn: Scalars["String"];
};

export type AwsssoConnectionMetadataInput = {
  accessPortalUrl: Scalars["String"];
  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
  awsOrganizationEnabled: Scalars["Boolean"];
  awsOrganizationImportSetting: ImportSetting;
  awsRegion: Scalars["String"];
  awsSsoEnabled: Scalars["Boolean"];
  cloudtrailEventsSqsUrl: Scalars["String"];
  ec2RoleChainingEnabled: Scalars["Boolean"];
  identityStoreId: Scalars["String"];
  managementAccountId: Scalars["String"];
  podRoleChainingEnabled: Scalars["Boolean"];
  ssoInstanceArn: Scalars["String"];
};

export type AwsTag = {
  __typename?: "AWSTag";
  key: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type AwsTagInput = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type AzureAdConnectionCredentialsInput = {
  clientSecret: Scalars["String"];
};

export type AzureAdConnectionMetadata = {
  __typename?: "AzureADConnectionMetadata";
  azureInfraEnabled: Scalars["Boolean"];
  clientId: Scalars["String"];
  eventHub: Scalars["String"];
  eventHubNamespace: Scalars["String"];
  tenantId: Scalars["String"];
};

export type AzureAdConnectionMetadataInput = {
  azureInfraEnabled: Scalars["Boolean"];
  clientId: Scalars["String"];
  eventHub: Scalars["String"];
  eventHubNamespace: Scalars["String"];
  tenantId: Scalars["String"];
};

export type BarGraphData = {
  __typename?: "BarGraphData";
  data: Array<LineGraphData>;
  label: Scalars["String"];
};

export type BooleanValue = {
  __typename?: "BooleanValue";
  value: Scalars["Boolean"];
};

export type BooleanValueInput = {
  value: Scalars["Boolean"];
};

export type BreakGlassUsersByGroupResult = {
  __typename?: "BreakGlassUsersByGroupResult";
  barData: Array<BarGraphData>;
};

export type BreakGlassUsersCountsResult = {
  __typename?: "BreakGlassUsersCountsResult";
  lineData: Array<LineGraphData>;
};

export type BulkImportRemoteItemsInput = {
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  breakGlassUsersIds?: InputMaybe<Array<Scalars["UserId"]>>;
  childrenDefaultConfigTemplateId?: InputMaybe<NullableConfigurationId>;
  configurationId?: InputMaybe<Scalars["ConfigurationId"]>;
  customRequestNotification?: InputMaybe<NullableString>;
  description?: InputMaybe<Scalars["String"]>;
  maxDurationInMinutes?: InputMaybe<Scalars["Int"]>;
  messageChannelIds?: InputMaybe<Array<Scalars["MessageChannelId"]>>;
  onCallSchedules?: InputMaybe<Array<OnCallScheduleInput>>;
  recommendedDurationInMinutes?: InputMaybe<Scalars["Int"]>;
  remoteGroups?: InputMaybe<Array<ImportRemoteGroupInfo>>;
  remoteResources?: InputMaybe<Array<ImportRemoteResourceInfo>>;
  requestConfigs?: InputMaybe<Array<RequestConfigInput>>;
  requestTemplateId?: InputMaybe<Scalars["RequestTemplateId"]>;
  requireMfaToApprove?: InputMaybe<Scalars["Boolean"]>;
  requireMfaToConnect?: InputMaybe<Scalars["Boolean"]>;
  requireMfaToRequest?: InputMaybe<Scalars["Boolean"]>;
  requireSupportTicket?: InputMaybe<Scalars["Boolean"]>;
  reviewerStages?: InputMaybe<BulkReviewerStagesInput>;
  tagIds?: InputMaybe<Array<Scalars["TagId"]>>;
  ticketPropagation?: InputMaybe<TicketPropagationInput>;
  visibility?: InputMaybe<Visibility>;
  visibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
};

export type BulkImportRemoteItemsOutput =
  | BulkImportRemoteItemsResult
  | GroupMaxDurationTooLargeError
  | GroupNotFoundError
  | InvalidReviewerSettingsError
  | InvalidUpdateGroupVisibilityGroupError
  | InvalidUpdateResourceVisibilityGroupError
  | ResourceMaxDurationTooLargeError
  | TagNotFoundError;

export type BulkImportRemoteItemsResult = {
  __typename?: "BulkImportRemoteItemsResult";
  importedGroups?: Maybe<Array<Group>>;
  importedResources?: Maybe<Array<Resource>>;
  syncTask: SyncTask;
};

export type BulkRequestTooLargeError = Error & {
  __typename?: "BulkRequestTooLargeError";
  message: Scalars["String"];
};

export type BulkReviewerInputStage = {
  operator: ReviewStageOperator;
  ownerIds: Array<Scalars["OwnerId"]>;
  requireAdminApproval: Scalars["Boolean"];
  requireManagerApproval: Scalars["Boolean"];
};

export type BulkReviewerStagesInput = {
  autoApprove?: InputMaybe<Scalars["Boolean"]>;
  isRequestable?: InputMaybe<Scalars["Boolean"]>;
  reasonOptional?: InputMaybe<Scalars["Boolean"]>;
  reviewerStages?: InputMaybe<Array<BulkReviewerInputStage>>;
};

export type BulkUpdateItemFilter = {
  connectionId: Scalars["ConnectionId"];
  itemTypes?: InputMaybe<Array<Scalars["String"]>>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type BulkUpdateItemsInput = {
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  breakGlassUsersIds?: InputMaybe<Array<Scalars["UserId"]>>;
  childrenDefaultConfigTemplateId?: InputMaybe<NullableConfigurationId>;
  commonMetadata?: InputMaybe<CommonMetadataInput>;
  configurationId?: InputMaybe<NullableConfigurationId>;
  customRequestNotification?: InputMaybe<NullableString>;
  description?: InputMaybe<Scalars["String"]>;
  forkConfigurationTemplates?: InputMaybe<Scalars["Boolean"]>;
  fromSetup?: InputMaybe<Scalars["Boolean"]>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  groupLeaderUserIds?: InputMaybe<Array<Scalars["UserId"]>>;
  importUnmanagedItems?: InputMaybe<Scalars["Boolean"]>;
  importUnmanagedItemsFilter?: InputMaybe<BulkUpdateItemFilter>;
  maxDurationInMinutes?: InputMaybe<NullableInt>;
  messageChannelIds?: InputMaybe<Array<Scalars["MessageChannelId"]>>;
  name?: InputMaybe<Scalars["String"]>;
  onCallSchedules?: InputMaybe<Array<OnCallScheduleInput>>;
  recommendedDurationInMinutes?: InputMaybe<NullableInt>;
  requestConfigs?: InputMaybe<Array<RequestConfigInput>>;
  requestTemplateId?: InputMaybe<NullableRequestTemplateId>;
  requireMfaToApprove?: InputMaybe<Scalars["Boolean"]>;
  requireMfaToConnect?: InputMaybe<Scalars["Boolean"]>;
  requireMfaToRequest?: InputMaybe<Scalars["Boolean"]>;
  requireSupportTicket?: InputMaybe<Scalars["Boolean"]>;
  resourceIds?: InputMaybe<Array<Scalars["ResourceId"]>>;
  reviewerStages?: InputMaybe<BulkReviewerStagesInput>;
  riskSensitivity?: InputMaybe<RiskLevel>;
  tagIds?: InputMaybe<Array<Scalars["TagId"]>>;
  ticketPropagation?: InputMaybe<TicketPropagationInput>;
  useParentConfig?: InputMaybe<Scalars["Boolean"]>;
  visibility?: InputMaybe<Visibility>;
  visibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
};

export type BulkUpdateItemsOutput =
  | BulkUpdateItemsResult
  | CannotUpdateConfigurationTemplateError
  | GroupMaxDurationTooLargeError
  | GroupNotFoundError
  | InvalidReviewerSettingsError
  | InvalidUpdateGroupVisibilityGroupError
  | InvalidUpdateResourceVisibilityGroupError
  | ResourceMaxDurationTooLargeError
  | ResourceNotFoundError
  | TagNotFoundError
  | TooManyGroupLeadersError;

export type BulkUpdateItemsResult = {
  __typename?: "BulkUpdateItemsResult";
  syncTask?: Maybe<SyncTask>;
  updatedGroups?: Maybe<Array<Group>>;
  updatedResources?: Maybe<Array<Resource>>;
};

export type Bundle = {
  __typename?: "Bundle";
  adminOwner: Owner;
  adminOwnerId: Scalars["OwnerId"];
  authorizedActions?: Maybe<Array<Scalars["AuthorizedAction"]>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["BundleId"];
  name: Scalars["String"];
  serviceTypes?: Maybe<Array<ServiceType>>;
  totalNumGroups: Scalars["Int"];
  totalNumItems: Scalars["Int"];
  totalNumResources: Scalars["Int"];
  visibility: Visibility;
  visibilityGroups: Array<BundleVisibilityGroup>;
};

export type BundleGroup = {
  __typename?: "BundleGroup";
  accessLevelName: Scalars["String"];
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  groupId: Scalars["GroupId"];
};

export type BundleGroupInput = {
  accessLevelName: Scalars["String"];
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  groupId: Scalars["GroupId"];
};

export type BundleGroupsInput = {
  inputs?: InputMaybe<Array<BundleGroupInput>>;
};

export type BundleInput = {
  id: Scalars["BundleId"];
};

export type BundleItem = {
  __typename?: "BundleItem";
  accessLevelName: Scalars["String"];
  accessLevelRemoteId: Scalars["String"];
  group?: Maybe<Group>;
  key: Scalars["String"];
  resource?: Maybe<Resource>;
};

export type BundleItemsInput = {
  bundleId: Scalars["BundleId"];
  cursor?: InputMaybe<Scalars["String"]>;
  itemType?: InputMaybe<Scalars["String"]>;
  maxPageSize?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<BundleItemsSortBy>;
};

export type BundleItemsOutput = {
  __typename?: "BundleItemsOutput";
  cursor?: Maybe<Scalars["String"]>;
  items?: Maybe<Array<BundleItem>>;
  itemTypes?: Maybe<Array<AppItemType>>;
  totalNumGroups: Scalars["Int"];
  totalNumItems: Scalars["Int"];
  totalNumResources: Scalars["Int"];
};

export type BundleItemsSortBy = {
  direction: SortDirection;
  field: BundleItemsSortByField;
};

export enum BundleItemsSortByField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  Role = "ROLE",
  Type = "TYPE",
}

export type BundleNameExistsError = Error & {
  __typename?: "BundleNameExistsError";
  message: Scalars["String"];
};

export type BundleNotAuthorizedError = Error & {
  __typename?: "BundleNotAuthorizedError";
  message: Scalars["String"];
};

export type BundleNotFoundError = Error & {
  __typename?: "BundleNotFoundError";
  message: Scalars["String"];
};

export type BundleOutput = BundleNotFoundError | BundleResult;

export type BundleResource = {
  __typename?: "BundleResource";
  accessLevelName: Scalars["String"];
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  resourceId: Scalars["ResourceId"];
};

export type BundleResourceInput = {
  accessLevelName: Scalars["String"];
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  resourceId: Scalars["ResourceId"];
};

export type BundleResourcesInput = {
  inputs?: InputMaybe<Array<BundleResourceInput>>;
};

export type BundleResult = {
  __typename?: "BundleResult";
  bundle: Bundle;
};

export type BundlesInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<BundlesSortBy>;
};

export type BundlesOutput = {
  __typename?: "BundlesOutput";
  bundles?: Maybe<Array<Bundle>>;
  cursor?: Maybe<Scalars["String"]>;
  totalNumBundles: Scalars["Int"];
};

export type BundlesSortBy = {
  direction: SortDirection;
  field: BundlesSortByField;
};

export enum BundlesSortByField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
}

export type BundleVisibilityGroup = {
  __typename?: "BundleVisibilityGroup";
  bundleId: Scalars["BundleId"];
  createdAt: Scalars["Time"];
  id: Scalars["BundleVisibilityGroupID"];
  organizationId: Scalars["OrganizationId"];
  updatedAt: Scalars["Time"];
  visibilityGroupId: Scalars["GroupId"];
};

export type BundleVisibilityGroupNotFoundError = Error & {
  __typename?: "BundleVisibilityGroupNotFoundError";
  message: Scalars["String"];
};

export type BundleVisibilityGroupOutput =
  | BundleVisibilityGroupNotFoundError
  | BundleVisibilityGroupResult;

export type BundleVisibilityGroupQueryInput = {
  id: Scalars["BundleVisibilityGroupID"];
};

export type BundleVisibilityGroupResult = {
  __typename?: "BundleVisibilityGroupResult";
  bundleVisibilityGroup: BundleVisibilityGroup;
};

export type CallToWebhookFailedError = Error & {
  __typename?: "CallToWebhookFailedError";
  message: Scalars["String"];
};

export type CancelRequestInput = {
  id: Scalars["RequestId"];
};

export type CancelRequestOutput =
  | CancelRequestResult
  | RequestAlreadyActionedError
  | RequestNotFoundError;

export type CancelRequestResult = {
  __typename?: "CancelRequestResult";
  request: Request;
};

export type CannotAddSystemUserToGroupError = Error & {
  __typename?: "CannotAddSystemUserToGroupError";
  message: Scalars["String"];
};

export type CannotAddSystemUserToOwnerError = Error & {
  __typename?: "CannotAddSystemUserToOwnerError";
  message: Scalars["String"];
};

export type CannotAddSystemUserToResourceError = Error & {
  __typename?: "CannotAddSystemUserToResourceError";
  message: Scalars["String"];
};

export type CannotAddUserToSyncedOwnerError = Error & {
  __typename?: "CannotAddUserToSyncedOwnerError";
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars["GroupId"]>;
  message: Scalars["String"];
};

export type CannotUpdateConfigurationTemplateError = Error & {
  __typename?: "CannotUpdateConfigurationTemplateError";
  message: Scalars["String"];
};

export type CertIsTooLargeError = Error & {
  __typename?: "CertIsTooLargeError";
  message: Scalars["String"];
};

export type ClearResourceUserPropagationStatusInput = {
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type ClearResourceUserPropagationStatusOutput = ClearResourceUserPropagationStatusResult;

export type ClearResourceUserPropagationStatusResult = {
  __typename?: "ClearResourceUserPropagationStatusResult";
  success: Scalars["Boolean"];
};

export type ClosedRequestMetricsResult = {
  __typename?: "ClosedRequestMetricsResult";
  numAdminApproved: Scalars["Int"];
  numAutoApproved: Scalars["Int"];
  numCanceled: Scalars["Int"];
  numDenied: Scalars["Int"];
  numManualApproved: Scalars["Int"];
};

export type CodeFlowInput = {
  code: Scalars["String"];
  state: Scalars["String"];
};

export type CommonMetadata = {
  __typename?: "CommonMetadata";
  matchRemoteDescription: Scalars["Boolean"];
  matchRemoteName: Scalars["Boolean"];
};

export type CommonMetadataInput = {
  matchRemoteDescription?: InputMaybe<Scalars["Boolean"]>;
  matchRemoteName?: InputMaybe<Scalars["Boolean"]>;
};

export type ConfigTemplateTag = {
  __typename?: "ConfigTemplateTag";
  configTemplate?: Maybe<ConfigurationTemplate>;
  id: Scalars["ConfigTemplateTagId"];
  priority: Scalars["Int"];
  tag: Tag;
};

export type ConfigTemplateTagInput = {
  configTemplateId: Scalars["ConfigurationId"];
  priority: Scalars["Int"];
  tagId: Scalars["TagId"];
};

export type ConfigTemplateTagsOutput = ConfigTemplateTagsResult;

export type ConfigTemplateTagsResult = {
  __typename?: "ConfigTemplateTagsResult";
  configTemplateTags: Array<ConfigTemplateTag>;
};

export type ConfigurationInUseError = Error & {
  __typename?: "ConfigurationInUseError";
  message: Scalars["String"];
};

export type ConfigurationTemplate = {
  __typename?: "ConfigurationTemplate";
  adminOwner: Owner;
  adminOwnerId: Scalars["OwnerId"];
  auditMessageChannels: Array<MessageChannel>;
  breakGlassUsers: Array<User>;
  customRequestNotification?: Maybe<Scalars["String"]>;
  id: Scalars["ConfigurationId"];
  name: Scalars["String"];
  onCallSchedules: Array<OnCallSchedule>;
  requestConfigs: Array<RequestConfiguration>;
  requireMfaToApprove: Scalars["Boolean"];
  requireMfaToConnect: Scalars["Boolean"];
  ticketPropagation?: Maybe<TicketPropagation>;
  visibility: Visibility;
  visibilityGroups: Array<Group>;
};

export type ConfigurationTemplateInput = {
  id: Scalars["ConfigurationId"];
};

export type ConfigurationTemplateNameExistsError = Error & {
  __typename?: "ConfigurationTemplateNameExistsError";
  message: Scalars["String"];
};

export type ConfigurationTemplateNotFoundError = Error & {
  __typename?: "ConfigurationTemplateNotFoundError";
  message: Scalars["String"];
};

export type ConfigurationTemplateOutput =
  | ConfigurationTemplateNotFoundError
  | ConfigurationTemplateResult;

export type ConfigurationTemplateResult = {
  __typename?: "ConfigurationTemplateResult";
  configurationTemplate?: Maybe<ConfigurationTemplate>;
};

export type ConfigurationTemplatesOutput = ConfigurationTemplatesResult;

export type ConfigurationTemplatesResult = {
  __typename?: "ConfigurationTemplatesResult";
  configurationTemplates: Array<ConfigurationTemplate>;
};

export type ConfigurationVisibilityGroup = {
  __typename?: "ConfigurationVisibilityGroup";
  configurationId: Scalars["ConfigurationId"];
  visibilityGroup: Group;
  visibilityGroupId: Scalars["GroupId"];
};

export type ConfigurationVisibilityGroupNotFoundError = {
  __typename?: "ConfigurationVisibilityGroupNotFoundError";
  message: Scalars["String"];
};

export type ConfirmEndSystemRevocationInput = {
  linkedUARResourceUserIds?: InputMaybe<
    Array<Scalars["AccessReviewResourceUserId"]>
  >;
  revokedUARResourceUserIds: Array<Scalars["AccessReviewResourceUserId"]>;
  ticketAssigneeUserId?: InputMaybe<Scalars["UserId"]>;
  ticketProjectId?: InputMaybe<Scalars["TicketProjectId"]>;
  ticketProvider?: InputMaybe<ThirdPartyProvider>;
};

export type ConfirmEndSystemRevocationOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewUserSupportTicketAlreadyLinkedError
  | ConfirmEndSystemRevocationResult
  | RemoteUserNotFoundError;

export type ConfirmEndSystemRevocationResult = {
  __typename?: "ConfirmEndSystemRevocationResult";
  accessReviewResourceUsers?: Maybe<Array<AccessReviewResourceUser>>;
};

export type Connection = {
  __typename?: "Connection";
  accessStats?: Maybe<ConnectionAccessStats>;
  adminOwner?: Maybe<Owner>;
  adminOwnerId: Scalars["OwnerId"];
  autoImportGroupResources: Scalars["Boolean"];
  childrenDefaultConfigTemplate?: Maybe<ConfigurationTemplate>;
  connectionType: ConnectionType;
  connectionUsers: Array<ConnectionUser>;
  description: Scalars["String"];
  groups: Array<Group>;
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ConnectionId"];
  importNotificationOwner?: Maybe<Owner>;
  importNotificationOwnerId?: Maybe<Scalars["OwnerId"]>;
  importSetting: ImportSetting;
  importVisibility: Visibility;
  importVisibilityGroups: Array<ConnectionVisibilityGroup>;
  isDeleted: Scalars["Boolean"];
  metadata?: Maybe<ConnectionMetadataOutput>;
  name: Scalars["String"];
  numGroups: Scalars["Int"];
  numResources: Scalars["Int"];
  numUsers: Scalars["Int"];
  numVisibleGroups: Scalars["Int"];
  numVisibleResources: Scalars["Int"];
  resources: Array<Resource>;
  siblingConnections: Array<Connection>;
  visibility: Visibility;
  visibilityGroups: Array<ConnectionVisibilityGroup>;
  webhookEnabled: Scalars["Boolean"];
};

export type ConnectionAccessAlreadyReviewedError = Error & {
  __typename?: "ConnectionAccessAlreadyReviewedError";
  message: Scalars["String"];
};

export type ConnectionAccessStats = {
  __typename?: "ConnectionAccessStats";
  connectionId: Scalars["ConnectionId"];
  teamAccessCount?: Maybe<Scalars["Int"]>;
  titleAccessCount?: Maybe<Scalars["Int"]>;
  totalAccessCount?: Maybe<Scalars["Int"]>;
};

export type ConnectionApp = {
  __typename?: "ConnectionApp";
  connection?: Maybe<Connection>;
  connectionIconUrl?: Maybe<Scalars["String"]>;
  connectionId: Scalars["ConnectionId"];
  connectionType: ConnectionType;
};

export type ConnectionBadMetadataError = Error & {
  __typename?: "ConnectionBadMetadataError";
  message: Scalars["String"];
};

export type ConnectionCredentialsInput = {
  ad?: InputMaybe<AdConnectionCredentialsInput>;
  authType: AuthType;
  aws?: InputMaybe<AwsConnectionCredentialsInput>;
  awsSso?: InputMaybe<AwsssoConnectionCredentialsInput>;
  azureAd?: InputMaybe<AzureAdConnectionCredentialsInput>;
  customConnector?: InputMaybe<CustomConnectorCredentials>;
  duo?: InputMaybe<DuoCredentialsInput>;
  gcp?: InputMaybe<GcpServiceAccountCredentialsInput>;
  gitHub?: InputMaybe<GitHubConnectionCredentialsInput>;
  gitLab?: InputMaybe<GitLabConnectionCredentialsInput>;
  googleGroups?: InputMaybe<GcpServiceAccountCredentialsInput>;
  googleWorkspace?: InputMaybe<GcpServiceAccountCredentialsInput>;
  ldap?: InputMaybe<LdapConnectionCredentialsInput>;
  mongo?: InputMaybe<MongoConnectionCredentialsInput>;
  mongoAtlas?: InputMaybe<MongoAtlasConnectionCredentialsInput>;
  mySqlMariadb?: InputMaybe<MySqlMariaDbConnectionCredentialsInput>;
  oktaDirectory?: InputMaybe<OktaDirectoryCredentialsInput>;
  pagerDuty?: InputMaybe<PagerDutyCredentialsInput>;
  postgres?: InputMaybe<PostgresConnectionCredentialsInput>;
  salesforce?: InputMaybe<SalesforceCredentialsInput>;
  snowflake?: InputMaybe<SnowflakeConnectionCredentialsInput>;
  tailscale?: InputMaybe<TailscaleCredentialsInput>;
  teleport?: InputMaybe<TeleportConnectionCredentialsInput>;
  workday?: InputMaybe<WorkdayCredentialsInput>;
};

export type ConnectionExistsError = Error & {
  __typename?: "ConnectionExistsError";
  message: Scalars["String"];
};

export type ConnectionImportNotificationInput = {
  recipientOwnerId?: InputMaybe<Scalars["OwnerId"]>;
};

export type ConnectionInput = {
  id: Scalars["ConnectionId"];
};

export type ConnectionMetadataInput = {
  ad?: InputMaybe<AdConnectionMetadataInput>;
  aws?: InputMaybe<AwsConnectionMetadataInput>;
  awsSso?: InputMaybe<AwsssoConnectionMetadataInput>;
  azureAd?: InputMaybe<AzureAdConnectionMetadataInput>;
  connectionType: ConnectionType;
  customConnector?: InputMaybe<CustomConnectorMetadataInput>;
  duo?: InputMaybe<DuoConnectionMetadataInput>;
  gcp?: InputMaybe<GcpConnectionMetadataInput>;
  gitHub?: InputMaybe<GitHubConnectionMetadataInput>;
  gitLab?: InputMaybe<GitLabConnectionMetadataInput>;
  googleGroups?: InputMaybe<GoogleGroupsConnectionMetadataInput>;
  googleWorkspace?: InputMaybe<GoogleWorkspaceConnectionMetadataInput>;
  ldap?: InputMaybe<LdapConnectionMetadataInput>;
  mongo?: InputMaybe<MongoConnectionMetadataInput>;
  mongoAtlas?: InputMaybe<MongoAtlasConnectionMetadataInput>;
  mySqlMariadb?: InputMaybe<MySqlMariaDbConnectionMetadataInput>;
  oktaDirectory?: InputMaybe<OktaDirectoryConnectionMetadataInput>;
  postgres?: InputMaybe<PostgresConnectionMetadataInput>;
  propagationTicket?: InputMaybe<PropagationTicketConnectionMetadataInput>;
  salesforce?: InputMaybe<SalesforceMetadataInput>;
  snowflake?: InputMaybe<SnowflakeConnectionMetadataInput>;
  tailscale?: InputMaybe<TailscaleConnectionMetadataInput>;
  teleport?: InputMaybe<TeleportConnectionMetadataInput>;
  workday?: InputMaybe<WorkdayConnectionMetadataInput>;
};

export type ConnectionMetadataOutput =
  | AdConnectionMetadata
  | AwsConnectionMetadata
  | AwsssoConnectionMetadata
  | AzureAdConnectionMetadata
  | CustomConnectorConnectionMetadata
  | DuoConnectionMetadata
  | GcpConnectionMetadata
  | GitHubConnectionMetadata
  | GitLabConnectionMetadata
  | GoogleGroupsConnectionMetadata
  | GoogleWorkspaceConnectionMetadata
  | LdapConnectionMetadata
  | MongoAtlasConnectionMetadata
  | MongoConnectionMetadata
  | MySqlMariaDbConnectionMetadata
  | OktaDirectoryConnectionMetadata
  | PagerDutyConnectionMetadata
  | PostgresConnectionMetadata
  | PropagationTicketConnectionMetadata
  | SalesforceConnectionMetadata
  | SnowflakeConnectionMetadata
  | TailscaleConnectionMetadata
  | TeleportConnectionMetadata
  | WorkdayConnectionMetadata;

export type ConnectionNotFoundError = Error & {
  __typename?: "ConnectionNotFoundError";
  message: Scalars["String"];
};

export type ConnectionOutput = ConnectionNotFoundError | ConnectionResult;

export type ConnectionResult = {
  __typename?: "ConnectionResult";
  connection: Connection;
};

export type ConnectionsFilterInput = {
  connectionIDs: Array<Scalars["ConnectionId"]>;
};

export type ConnectionsInput = {
  connectionTypes?: InputMaybe<Array<ConnectionType>>;
};

export type ConnectionSmall = {
  __typename?: "ConnectionSmall";
  id: Scalars["ConnectionId"];
  name: Scalars["String"];
};

export type ConnectionsOutput = ConnectionsResult;

export type ConnectionsResult = {
  __typename?: "ConnectionsResult";
  connections: Array<Connection>;
};

export enum ConnectionType {
  ActiveDirectory = "ACTIVE_DIRECTORY",
  /**
   * Deprecated. Legacy integration no longer offered - use AWS_SSO instead.
   * @deprecated Legacy integration no longer offered - use AWS_SSO instead.
   */
  Aws = "AWS",
  AwsSso = "AWS_SSO",
  AzureAd = "AZURE_AD",
  Custom = "CUSTOM",
  CustomConnector = "CUSTOM_CONNECTOR",
  Duo = "DUO",
  Gcp = "GCP",
  GitHub = "GIT_HUB",
  GitLab = "GIT_LAB",
  GoogleGroups = "GOOGLE_GROUPS",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Ldap = "LDAP",
  Mariadb = "MARIADB",
  Mongo = "MONGO",
  MongoAtlas = "MONGO_ATLAS",
  Mysql = "MYSQL",
  OktaDirectory = "OKTA_DIRECTORY",
  Opal = "OPAL",
  Pagerduty = "PAGERDUTY",
  Postgres = "POSTGRES",
  Salesforce = "SALESFORCE",
  Snowflake = "SNOWFLAKE",
  Tailscale = "TAILSCALE",
  Teleport = "TELEPORT",
  Workday = "WORKDAY",
}

export type ConnectionUser = {
  __typename?: "ConnectionUser";
  connectionId: Scalars["ConnectionId"];
  propagationStatus?: Maybe<ConnectionUserPropagationStatus>;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type ConnectionUserAlreadyExists = Error & {
  __typename?: "ConnectionUserAlreadyExists";
  message: Scalars["String"];
};

export type ConnectionUserNotFound = Error & {
  __typename?: "ConnectionUserNotFound";
  message: Scalars["String"];
};

export type ConnectionUserPropagationStatus = {
  __typename?: "ConnectionUserPropagationStatus";
  connectionId: Scalars["ConnectionId"];
  errorMessage?: Maybe<Scalars["String"]>;
  lastSynced: Scalars["Time"];
  statusCode: PropagationStatusCode;
  taskType: PropagationTaskType;
  userId: Scalars["UserId"];
};

export type ConnectionUserPushTaskResult = {
  __typename?: "ConnectionUserPushTaskResult";
  propStatuses: Array<ConnectionUserPropagationStatus>;
};

export type ConnectionVisibilityGroup = {
  __typename?: "ConnectionVisibilityGroup";
  connectionId: Scalars["ConnectionId"];
  createdAt: Scalars["Time"];
  visibilityGroup?: Maybe<Group>;
  visibilityGroupId: Scalars["GroupId"];
};

export type ConnectionVisibilityGroupNotFoundError = Error & {
  __typename?: "ConnectionVisibilityGroupNotFoundError";
  message: Scalars["String"];
};

export type CountFilteredConnectionsInput = {
  filters?: InputMaybe<FiltersInput>;
};

export type CountFilteredConnectionsOutput = {
  __typename?: "CountFilteredConnectionsOutput";
  totalNumConnections: Scalars["Int"];
};

export type CountFilteredGroupsInput = {
  filters?: InputMaybe<FiltersInput>;
  includeSourceGroups?: InputMaybe<Scalars["Boolean"]>;
};

export type CountFilteredGroupsOutput = CountFilteredGroupsResult;

export type CountFilteredGroupsResult = {
  __typename?: "CountFilteredGroupsResult";
  totalNumGroups: Scalars["Int"];
};

export type CountFilteredResourcesInput = {
  filters?: InputMaybe<FiltersInput>;
};

export type CountFilteredResourcesOutput = CountFilteredResourcesResult;

export type CountFilteredResourcesResult = {
  __typename?: "CountFilteredResourcesResult";
  totalNumResources: Scalars["Int"];
};

export type CreateAccessReviewTemplateInput = {
  accessReviewDuration: Scalars["Int"];
  filters?: InputMaybe<FiltersInput>;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  includeGroupBindings: Scalars["Boolean"];
  monthSchedule?: InputMaybe<Array<Scalars["Int"]>>;
  name: Scalars["String"];
  reminderIncludeManager: Scalars["Boolean"];
  reminderSchedule?: InputMaybe<Array<Scalars["Int"]>>;
  reminderTimeOfDay: Scalars["Time"];
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  selfReviewAllowed: Scalars["Boolean"];
  sendReviewerAssignmentNotification: Scalars["Boolean"];
  startDayOfMonth: Scalars["Int"];
  timeZone: Scalars["String"];
};

export type CreateAccessReviewTemplateOutput = CreateAccessReviewTemplateResult;

export type CreateAccessReviewTemplateResult = {
  __typename?: "CreateAccessReviewTemplateResult";
  accessReviewTemplate: AccessReviewTemplate;
};

export type CreateAuthProviderApplicationInput = {
  hostname: Scalars["String"];
  organizationName: Scalars["String"];
};

export type CreateAuthProviderApplicationOutput =
  | CreateAuthProviderApplicationResult
  | HttpsHostnameRequiredError;

export type CreateAuthProviderApplicationResult = {
  __typename?: "CreateAuthProviderApplicationResult";
  clientSecret: Scalars["String"];
  providerInfo: AuthProviderInfo;
};

export type CreateBundleGroupsOutput = {
  __typename?: "CreateBundleGroupsOutput";
  bundleGroups: Array<BundleGroup>;
};

export type CreateBundleInput = {
  adminOwnerId: Scalars["OwnerId"];
  description: Scalars["String"];
  name: Scalars["String"];
  visibility?: InputMaybe<Visibility>;
  visibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
};

export type CreateBundleOutput = BundleNameExistsError | CreateBundleResult;

export type CreateBundleResourcesOutput = {
  __typename?: "CreateBundleResourcesOutput";
  bundleResources: Array<BundleResource>;
};

export type CreateBundleResult = {
  __typename?: "CreateBundleResult";
  bundle: Bundle;
};

export type CreateConfigurationTemplateInput = {
  adminOwnerId: Scalars["OwnerId"];
  breakGlassUsersIds: Array<Scalars["UserId"]>;
  customRequestNotification?: InputMaybe<NullableString>;
  messageChannelIds: Array<Scalars["MessageChannelId"]>;
  name: Scalars["String"];
  onCallSchedules: Array<OnCallScheduleInput>;
  requestConfigs: Array<RequestConfigInput>;
  requireMfaToApprove: Scalars["Boolean"];
  requireMfaToConnect: Scalars["Boolean"];
  ticketPropagation: TicketPropagationInput;
  visibility: Visibility;
  visibilityGroupsIds: Array<Scalars["GroupId"]>;
};

export type CreateConfigurationTemplateOutput =
  | ConfigurationTemplateNameExistsError
  | CreateConfigurationTemplateResult;

export type CreateConfigurationTemplateResult = {
  __typename?: "CreateConfigurationTemplateResult";
  configurationTemplate: ConfigurationTemplate;
};

export type CreateConnectionForIdpInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionImage?: InputMaybe<Scalars["Upload"]>;
  connectionType: ConnectionType;
  credentials?: InputMaybe<ConnectionCredentialsInput>;
  description: Scalars["String"];
  idpConnectionType: IdpConnectionType;
  idpMetadata?: InputMaybe<IdpConnectionMetadataInput>;
  importVisibility: Visibility;
  metadata?: InputMaybe<ConnectionMetadataInput>;
  name: Scalars["String"];
  visibility: Visibility;
  visibilityGroupIds: Array<Scalars["GroupId"]>;
};

export type CreateConnectionInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionImage?: InputMaybe<Scalars["Upload"]>;
  connectionType: ConnectionType;
  credentials?: InputMaybe<ConnectionCredentialsInput>;
  description: Scalars["String"];
  importVisibility: Visibility;
  metadata?: InputMaybe<ConnectionMetadataInput>;
  name: Scalars["String"];
  visibility: Visibility;
  visibilityGroupIds: Array<Scalars["GroupId"]>;
};

export type CreateConnectionOutput =
  | ConnectionBadMetadataError
  | ConnectionExistsError
  | CreateConnectionResult
  | UserFacingError;

export type CreateConnectionResult = {
  __typename?: "CreateConnectionResult";
  connection: Connection;
};

export type CreateEventFilterInput = {
  actorId?: InputMaybe<Scalars["UserId"]>;
  apiToken?: InputMaybe<ApiTokenFilter>;
  endDate?: InputMaybe<Scalars["String"]>;
  eventTypes?: InputMaybe<Array<EventType>>;
  name: Scalars["String"];
  objectId?: InputMaybe<Scalars["UUID"]>;
  startDate?: InputMaybe<Scalars["String"]>;
  systemEvents?: InputMaybe<Scalars["Boolean"]>;
};

export type CreateEventFilterOutput =
  | CreateEventFilterResult
  | EventFilterNotFoundError
  | InvalidEventFilterNameError;

export type CreateEventFilterResult = {
  __typename?: "CreateEventFilterResult";
  eventFilter: EventFilter;
};

export type CreateEventStreamInput = {
  connectionType: PubSubPublishConnectionType;
  credentials?: InputMaybe<PubSubCredentialsInput>;
  metadata?: InputMaybe<PubSubPublishConnectionMetadataInput>;
  name: Scalars["String"];
};

export type CreateEventStreamOutput = CreateEventStreamResult;

export type CreateEventStreamResult = {
  __typename?: "CreateEventStreamResult";
  eventStream: EventStream;
};

export type CreateFirstPartyTokenInput = {
  accessLevel: ApiAccessLevel;
  expiresAt?: InputMaybe<Scalars["Time"]>;
  tokenLabel: Scalars["String"];
};

export type CreateFirstPartyTokenOutput = CreateFirstPartyTokenResult;

export type CreateFirstPartyTokenResult = {
  __typename?: "CreateFirstPartyTokenResult";
  signedToken: Scalars["String"];
  token: FirstPartyToken;
};

export type CreateGroupBindingInput = {
  groupIds: Array<Scalars["GroupId"]>;
  sourceGroupId: Scalars["GroupId"];
  suggestionId?: InputMaybe<Scalars["GroupBindingSuggestionId"]>;
};

export type CreateGroupBindingsInput = {
  groupBindings: Array<CreateGroupBindingInput>;
};

export type CreateGroupBindingsOutput =
  | CreateGroupBindingsResult
  | GroupAlreadyBelongsToBindingError
  | GroupBindingHasNoGroupsError;

export type CreateGroupBindingsResult = {
  __typename?: "CreateGroupBindingsResult";
  groupBindings: Array<GroupBinding>;
};

export type CreateGroupInfo = {
  adminOwnerId: Scalars["OwnerId"];
  commonMetadata?: InputMaybe<CommonMetadataInput>;
  connectionId: Scalars["ConnectionId"];
  description: Scalars["String"];
  groupType: GroupType;
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  remoteId?: InputMaybe<Scalars["RemoteGroupId"]>;
  serviceType: ServiceType;
  visibility: Visibility;
};

export type CreateGroupInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionId: Scalars["ConnectionId"];
  description: Scalars["String"];
  groupType: GroupType;
  metadata?: InputMaybe<GroupMetadataInput>;
  name: Scalars["String"];
  serviceType: ServiceType;
  visibility: Visibility;
};

export type CreateGroupOutput =
  | CreateGroupResult
  | GoogleGroupError
  | OktaGroupError;

export type CreateGroupResult = {
  __typename?: "CreateGroupResult";
  group: Group;
};

export type CreateHrisProviderKeyInput = {
  organizationName: Scalars["String"];
};

export type CreateHrisProviderKeyOutput = CreateHrisProviderKeyResult;

export type CreateHrisProviderKeyResult = {
  __typename?: "CreateHRISProviderKeyResult";
  organizationName: Scalars["String"];
  remoteAccessKey: Scalars["String"];
};

export type CreateIdpConnectionInput = {
  existingConnectionId: Scalars["ConnectionId"];
  idpConnectionType: IdpConnectionType;
};

export type CreateIdpConnectionOutput =
  | ConnectionBadMetadataError
  | CreateIdpConnectionResult
  | IdpConnectionExistsError
  | UserFacingError;

export type CreateIdpConnectionResult = {
  __typename?: "CreateIdpConnectionResult";
  idpConnection: IdpConnection;
};

export type CreateIdpConnectionUserAttributeImportMappingInput = {
  idpConnectionId: Scalars["IdpConnectionId"];
  key: Scalars["String"];
  useAs: IdpConnectionUserAttributeUseAs;
};

export type CreateIdpConnectionUserAttributeImportMappingOutput =
  | CreateIdpConnectionUserAttributeImportMappingResult
  | IdpConnectionNotFoundError;

export type CreateIdpConnectionUserAttributeImportMappingResult = {
  __typename?: "CreateIdpConnectionUserAttributeImportMappingResult";
  mapping: IdpConnectionUserAttributeImportMapping;
};

export type CreateOpalScopedRoleInput = {
  adminOwnerId: Scalars["OwnerId"];
  description: Scalars["String"];
  name: Scalars["String"];
  permissions: Array<SetRolePermissionInput>;
  visibility: Visibility;
};

export type CreateOpalScopedRoleOutput = {
  __typename?: "CreateOpalScopedRoleOutput";
  resourceID: Scalars["ResourceId"];
};

export type CreateOrganizationInput = {
  onPremOrganizationID?: InputMaybe<Scalars["OrganizationId"]>;
  organizationName: Scalars["String"];
  userAdminEmail: Scalars["String"];
  userAdminFirstName: Scalars["String"];
  userAdminLastName: Scalars["String"];
};

export type CreateOrganizationOutput =
  | CreateOrganizationResult
  | OrganizationExistsError;

export type CreateOrganizationResult = {
  __typename?: "CreateOrganizationResult";
  onPremToken?: Maybe<Scalars["String"]>;
  organization: Organization;
};

export type CreateOrUpdateOidcProviderInput = {
  clientId: Scalars["OidcClientId"];
  clientSecret: Scalars["String"];
  issuerUrl: Scalars["String"];
  oidcProviderType: OidcProviderType;
};

export type CreateOrUpdateOidcProviderOutput =
  | CreateOrUpdateOidcProviderResult
  | OidcIssuerNotValidError;

export type CreateOrUpdateOidcProviderResult = {
  __typename?: "CreateOrUpdateOidcProviderResult";
  oidcProvider: OidcProvider;
};

export type CreateOwnerInfo = {
  accessRequestEscalationPeriodInMinutes?: InputMaybe<Scalars["Int"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  reviewerMessageChannelId?: InputMaybe<Scalars["MessageChannelId"]>;
  setUserIds: Array<Scalars["UserId"]>;
  sourceGroupId?: InputMaybe<Scalars["GroupId"]>;
};

export type CreateOwnerInput = {
  accessRequestEscalationPeriodInMinutes?: InputMaybe<Scalars["Int"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  reviewerMessageChannelId?: InputMaybe<Scalars["MessageChannelId"]>;
  setUserIds: Array<Scalars["UserId"]>;
  sourceGroupId?: InputMaybe<Scalars["GroupId"]>;
};

export type CreateOwnerOutput =
  | CannotAddUserToSyncedOwnerError
  | CreateOwnerResult
  | GroupNotFoundError
  | MessageChannelNotFoundError
  | OwnerNameAlreadyExists;

export type CreateOwnerResult = {
  __typename?: "CreateOwnerResult";
  owner: Owner;
};

export type CreateOwnersInput = {
  createInfos: Array<CreateOwnerInfo>;
};

export type CreateOwnersOutput =
  | CannotAddUserToSyncedOwnerError
  | CreateOwnersResult
  | GroupNotFoundError
  | MessageChannelNotFoundError
  | OwnerNameAlreadyExists;

export type CreateOwnersResult = {
  __typename?: "CreateOwnersResult";
  owners: Array<Owner>;
};

export type CreateRemoteMessageChannelInput = {
  customName: Scalars["String"];
  groupId?: InputMaybe<Scalars["GroupId"]>;
  isPrivate: Scalars["Boolean"];
  itemName: Scalars["String"];
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
  workspaceId?: InputMaybe<Scalars["String"]>;
};

export type CreateRemoteMessageChannelOutput =
  | CreateRemoteMessageChannelResult
  | MessageChannelInvalidNameError
  | MessageChannelMissingOAuthScopeError
  | MessageChannelNameTakenError
  | MessageChannelUserNotInWorkspaceError;

export type CreateRemoteMessageChannelResult = {
  __typename?: "CreateRemoteMessageChannelResult";
  messageChannel: MessageChannel;
};

export type CreateRequestCommentInput = {
  comment: Scalars["String"];
  requestId: Scalars["RequestId"];
};

export type CreateRequestCommentOutput = CreateRequestCommentResult;

export type CreateRequestCommentResult = {
  __typename?: "CreateRequestCommentResult";
  request: Request;
};

export type CreateRequestInput = {
  customMetadata?: InputMaybe<Array<RequestCustomMetadataInput>>;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  reason: Scalars["String"];
  requestedGroups: Array<RequestedGroupInput>;
  requestedResources: Array<RequestedResourceInput>;
  supportTicket?: InputMaybe<SupportTicketInput>;
  targetGroupId?: InputMaybe<Scalars["GroupId"]>;
  targetUserId?: InputMaybe<Scalars["UserId"]>;
};

export type CreateRequestOutput =
  | BulkRequestTooLargeError
  | CreateRequestResult
  | GroupNestingNotAllowedError
  | ItemCannotBeRequestedError
  | MfaInvalidError
  | NoManagerSetForRequestingUserError
  | NoReviewersSetForGroupError
  | NoReviewersSetForOwnerError
  | NoReviewersSetForResourceError
  | RequestDurationTooLargeError
  | RequestFieldValueMissingError
  | RequestReasonMissingError
  | RequestRequiresUserAuthTokenForConnectionError
  | TargetUserHasNestedAccessError
  | UserCannotRequestAccessForTargetGroupError;

export type CreateRequestProposalInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionId: Scalars["ConnectionId"];
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  proposalMetadata: ResourceProposalMetadataInput;
  reason: Scalars["String"];
  resourceType: ResourceType;
  serviceType: ServiceType;
};

export type CreateRequestProposalOutput =
  | AwsRoleCreationError
  | AwsRoleCreationReviewerNotSetError
  | AwsRolePolicyInvalidError
  | CreateRequestProposalResult
  | NoManagerSetForRequestingUserError
  | NoReviewersSetForGroupError
  | NoReviewersSetForOwnerError
  | NoReviewersSetForResourceError;

export type CreateRequestProposalResult = {
  __typename?: "CreateRequestProposalResult";
  request: Request;
};

export type CreateRequestResult = {
  __typename?: "CreateRequestResult";
  request: Request;
};

export type CreateRequestTemplateInput = {
  customFields?: InputMaybe<Array<RequestTemplateCustomFieldInput>>;
  name: Scalars["String"];
};

export type CreateRequestTemplateOutput =
  | CreateRequestTemplateResult
  | RequestTemplateNameExistsError;

export type CreateRequestTemplateResult = {
  __typename?: "CreateRequestTemplateResult";
  requestTemplate: RequestTemplateSimple;
};

export type CreateResourceCustomAccessLevelEntryOutput = CreateResourceCustomAccessLevelEntryResult;

export type CreateResourceCustomAccessLevelEntryResult = {
  __typename?: "CreateResourceCustomAccessLevelEntryResult";
  resourceCustomAccessLevel: ResourceCustomAccessLevel;
};

export type CreateResourceCustomAccessLevelInfo = {
  accessLevel: ResourceAccessLevelInput;
  policy?: InputMaybe<Scalars["String"]>;
  resourceId: Scalars["ResourceId"];
};

export type CreateResourceCustomAccessLevelInput = {
  accessLevel: ResourceAccessLevelInput;
  policy?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["ResourceId"];
};

export type CreateResourceCustomAccessLevelOutput =
  | CreateResourceCustomAccessLevelResult
  | ResourceCustomAccessLevelAlreadyExistsError
  | ResourceCustomAccessLevelPriorityError;

export type CreateResourceCustomAccessLevelResult = {
  __typename?: "CreateResourceCustomAccessLevelResult";
  resourceCustomAccessLevel: ResourceCustomAccessLevel;
};

export type CreateResourceCustomAccessLevelsInput = {
  createInfos: Array<CreateResourceCustomAccessLevelInfo>;
};

export type CreateResourceCustomAccessLevelsOutput = CreateResourceCustomAccessLevelsResult;

export type CreateResourceCustomAccessLevelsResult = {
  __typename?: "CreateResourceCustomAccessLevelsResult";
  entries: Array<CreateResourceCustomAccessLevelEntryOutput>;
};

export type CreateResourceEntryOutput = CreateResourceEntryResult;

export type CreateResourceEntryResult = {
  __typename?: "CreateResourceEntryResult";
  resource: Resource;
};

export type CreateResourceInfo = {
  adminOwnerId: Scalars["OwnerId"];
  commonMetadata?: InputMaybe<CommonMetadataInput>;
  connectionId: Scalars["ConnectionId"];
  description: Scalars["String"];
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  parentResourceId?: InputMaybe<Scalars["ResourceId"]>;
  remoteId?: InputMaybe<Scalars["RemoteResourceId"]>;
  resourceType: ResourceType;
  serviceType: ServiceType;
  visibility: Visibility;
};

export type CreateResourceInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionId: Scalars["ConnectionId"];
  customRequestNotification?: InputMaybe<NullableString>;
  description: Scalars["String"];
  name: Scalars["String"];
  parentResourceId?: InputMaybe<Scalars["ResourceId"]>;
  remoteId?: InputMaybe<Scalars["RemoteResourceId"]>;
  resourceType: ResourceType;
  serviceType: ServiceType;
  ticketPropagation?: InputMaybe<TicketPropagationInput>;
  visibility: Visibility;
};

export type CreateResourceOutput =
  | AwsRoleCreationError
  | AwsRolePolicyInvalidError
  | CreateResourceResult;

export type CreateResourceResult = {
  __typename?: "CreateResourceResult";
  resource: Resource;
};

export type CreateResourcesInput = {
  createInfos: Array<CreateResourceInfo>;
};

export type CreateResourcesOutput = CreateResourcesResult;

export type CreateResourcesResult = {
  __typename?: "CreateResourcesResult";
  entries: Array<CreateResourceEntryOutput>;
  syncTask: SyncTask;
};

export type CreateSamlConnectionInput = {
  certFile?: InputMaybe<Scalars["Upload"]>;
  restrictLoginToSaml?: InputMaybe<Scalars["Boolean"]>;
  signInEndpointURL?: InputMaybe<Scalars["String"]>;
};

export type CreateSamlConnectionOutput =
  | CertIsTooLargeError
  | CreateSamlConnectionResult
  | InvalidEmailError;

export type CreateSamlConnectionProxyInput = {
  auth0ClientId: Scalars["String"];
  certificate: Scalars["String"];
  domain: Scalars["String"];
  idpInitiatedRedirectUri: Scalars["String"];
  organizationId: Scalars["OrganizationId"];
  organizationName: Scalars["String"];
  providerID?: InputMaybe<Scalars["String"]>;
  signInEndpointURL: Scalars["String"];
};

export type CreateSamlConnectionProxyOutput = CreateSamlConnectionProxyResult;

export type CreateSamlConnectionProxyResult = {
  __typename?: "CreateSamlConnectionProxyResult";
  onPremOrganizationSamlInfo: OnPremOrganizationSamlInfo;
};

export type CreateSamlConnectionResult = {
  __typename?: "CreateSamlConnectionResult";
  organizationSamlInfo: OrganizationSamlInfo;
};

export type CreateSessionInput = {
  accessLevel?: InputMaybe<ResourceAccessLevelInput>;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
  sessionId?: InputMaybe<Scalars["SessionId"]>;
};

export type CreateSessionOutput =
  | CreateSessionResult
  | EndSystemAuthorizationError
  | GroupNotFoundError
  | ImpersonationSessionLengthError
  | MfaInvalidError
  | NotSessionableError
  | OidcIdTokenNotFoundError
  | ResourceNotFoundError
  | SessionNotFoundError
  | UserFacingError
  | UserImpersonationDisabledError
  | VaultClientNotFoundError
  | VaultSessionError;

export type CreateSessionResult = {
  __typename?: "CreateSessionResult";
  session: Session;
};

export type CreateTagInput = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type CreateTagOutput = CreateTagResult | TagExistsError;

export type CreateTagResult = {
  __typename?: "CreateTagResult";
  tag: Tag;
};

export type CreateThirdPartyIntegrationInput = {
  codeFlow?: InputMaybe<CodeFlowInput>;
  metadata?: InputMaybe<ThirdPartyIntegrationMetadataInput>;
  orgName?: InputMaybe<Scalars["String"]>;
  thirdPartyProvider: ThirdPartyProvider;
  token?: InputMaybe<Scalars["String"]>;
};

export type CreateThirdPartyIntegrationOutput =
  | CreateThirdPartyIntegrationResult
  | SlackOrganizationAlreadyExistsError
  | ThirdPartyConnectionNotMatchedError
  | ThirdPartyUserNotMatchedError
  | UserFacingError;

export type CreateThirdPartyIntegrationResult = {
  __typename?: "CreateThirdPartyIntegrationResult";
  thirdPartyIntegration: ThirdPartyIntegration;
};

export type CreateWebhookInfoInput = {
  webhookEndpoint?: InputMaybe<Scalars["String"]>;
};

export type CreateWebhookInfoOutput = CreateWebhookInfoResult;

export type CreateWebhookInfoResult = {
  __typename?: "CreateWebhookInfoResult";
  webhookInfo: OrganizationWebhookInfo;
};

export type CurrentUserGroupAccess = {
  __typename?: "CurrentUserGroupAccess";
  groupId: Scalars["GroupId"];
  groupUser?: Maybe<GroupUser>;
  hasBreakGlass: Scalars["Boolean"];
  pendingRequest?: Maybe<Request>;
};

export type CurrentUserResourceAccess = {
  __typename?: "CurrentUserResourceAccess";
  activeSessions: Array<Session>;
  numAvailableAccessLevels: Scalars["Int"];
  pendingRequests: Array<Request>;
  resourceId: Scalars["ResourceId"];
  resourceUsers: Array<ResourceUser>;
};

export type CurrentUserStats = {
  __typename?: "CurrentUserStats";
  numGroupsAdmined: Scalars["Int"];
  numRequestsSent: Scalars["Int"];
  numRequestsSentOnBehalfOfUser: Scalars["Int"];
  numRequestsSentPending: Scalars["Int"];
  numRequestsSentPendingOnBehalfOfUser: Scalars["Int"];
  numResourcesAdmined: Scalars["Int"];
  reviewsItems: Scalars["Boolean"];
};

export type CurrentUserStatsOutput = CurrentUserStatsResult;

export type CurrentUserStatsResult = {
  __typename?: "CurrentUserStatsResult";
  currentUserStats?: Maybe<CurrentUserStats>;
};

export type CustomConnectorConnectionMetadata = {
  __typename?: "CustomConnectorConnectionMetadata";
  baseUrl: Scalars["String"];
  identifier: Scalars["String"];
  supportsGroups: Scalars["Boolean"];
  supportsNestedResources: Scalars["Boolean"];
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type CustomConnectorCredentials = {
  signingSecret: Scalars["String"];
};

export type CustomConnectorMetadataInput = {
  baseUrl: Scalars["String"];
  identifier: Scalars["String"];
  supportsGroups: Scalars["Boolean"];
  supportsNestedResources: Scalars["Boolean"];
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type CustomerSupportSettingsInvalid = Error & {
  __typename?: "CustomerSupportSettingsInvalid";
  message: Scalars["String"];
};

export type CustomFieldExistsError = Error & {
  __typename?: "CustomFieldExistsError";
  message: Scalars["String"];
};

export type DeleteAccessReviewTemplatesInput = {
  ids: Array<Scalars["AccessReviewTemplateId"]>;
};

export type DeleteAccessReviewTemplatesOutput =
  | AccessReviewTemplateNotFound
  | DeleteAccessReviewTemplatesResult;

export type DeleteAccessReviewTemplatesResult = {
  __typename?: "DeleteAccessReviewTemplatesResult";
  accessReviewTemplates: Array<AccessReviewTemplate>;
};

export type DeleteBundleGroupInput = {
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  groupId: Scalars["GroupId"];
};

export type DeleteBundleGroupsInput = {
  inputs?: InputMaybe<Array<DeleteBundleGroupInput>>;
};

export type DeleteBundleInput = {
  id: Scalars["BundleId"];
};

export type DeleteBundleOutput = {
  __typename?: "DeleteBundleOutput";
  bundle: Bundle;
};

export type DeleteBundleResourceInput = {
  accessLevelRemoteId: Scalars["String"];
  bundleId: Scalars["BundleId"];
  resourceId: Scalars["ResourceId"];
};

export type DeleteBundleResourcesInput = {
  inputs?: InputMaybe<Array<DeleteBundleResourceInput>>;
};

export type DeleteConfigurationTemplateInput = {
  id: Scalars["ConfigurationId"];
};

export type DeleteConfigurationTemplateOutput =
  | ConfigurationInUseError
  | DeleteConfigurationTemplateResult;

export type DeleteConfigurationTemplateResult = {
  __typename?: "DeleteConfigurationTemplateResult";
  id: Scalars["ConfigurationId"];
};

export type DeleteConnectionInput = {
  id: Scalars["ConnectionId"];
};

export type DeleteConnectionOutput =
  | ConnectionNotFoundError
  | DeleteConnectionResult;

export type DeleteConnectionResult = {
  __typename?: "DeleteConnectionResult";
  id: Scalars["ConnectionId"];
};

export type DeleteEventFilterInput = {
  id: Scalars["EventFilterId"];
};

export type DeleteEventFilterOutput =
  | DeleteEventFilterResult
  | EventFilterInUseError
  | EventFilterNotFoundError;

export type DeleteEventFilterResult = {
  __typename?: "DeleteEventFilterResult";
  deletedEventFilter: EventFilter;
};

export type DeleteFirstPartyTokenEntryOutput = DeleteFirstPartyTokenEntryResult;

export type DeleteFirstPartyTokenEntryResult = {
  __typename?: "DeleteFirstPartyTokenEntryResult";
  id: Scalars["FirstPartyTokenId"];
};

export type DeleteFirstPartyTokensInput = {
  ids: Array<Scalars["FirstPartyTokenId"]>;
};

export type DeleteFirstPartyTokensOutput = DeleteFirstPartyTokensResult;

export type DeleteFirstPartyTokensResult = {
  __typename?: "DeleteFirstPartyTokensResult";
  entries: Array<DeleteFirstPartyTokenEntryOutput>;
};

export type DeleteGroupBindingsInput = {
  ids: Array<Scalars["GroupBindingId"]>;
};

export type DeleteGroupBindingsOutput =
  | DeleteGroupBindingsResult
  | GroupBindingNotFoundError;

export type DeleteGroupBindingsResult = {
  __typename?: "DeleteGroupBindingsResult";
  success: Scalars["Boolean"];
};

export type DeleteGroupInput = {
  id: Scalars["GroupId"];
};

export type DeleteGroupOutput =
  | DeleteGroupResult
  | GroupHasChildrenError
  | GroupNotFoundError;

export type DeleteGroupResult = {
  __typename?: "DeleteGroupResult";
  success: Scalars["Boolean"];
};

export type DeleteGroupsInput = {
  ids: Array<Scalars["GroupId"]>;
};

export type DeleteGroupsOutput =
  | DeleteGroupsResult
  | GroupHasChildrenError
  | GroupNotFoundError;

export type DeleteGroupsResult = {
  __typename?: "DeleteGroupsResult";
  success: Scalars["Boolean"];
};

export type DeleteGroupUserPropStatusResult = {
  __typename?: "DeleteGroupUserPropStatusResult";
  success: Scalars["Boolean"];
};

export type DeleteIdpConnectionInput = {
  id: Scalars["IdpConnectionId"];
};

export type DeleteIdpConnectionOutput =
  | DeleteIdpConnectionResult
  | IdpConnectionNotFoundError;

export type DeleteIdpConnectionResult = {
  __typename?: "DeleteIdpConnectionResult";
  id: Scalars["IdpConnectionId"];
};

export type DeleteIdpConnectionUserAttributeImportMappingInput = {
  id: Scalars["IdpConnectionUserAttributeImportMappingId"];
};

export type DeleteIdpConnectionUserAttributeImportMappingOutput =
  | DeleteIdpConnectionUserAttributeImportMappingResult
  | IdpConnectionUserAttributeImportMappingNotFoundError;

export type DeleteIdpConnectionUserAttributeImportMappingResult = {
  __typename?: "DeleteIdpConnectionUserAttributeImportMappingResult";
  id: Scalars["IdpConnectionUserAttributeImportMappingId"];
};

export type DeleteOrganizationOutput = DeleteOrganizationResult;

export type DeleteOrganizationResult = {
  __typename?: "DeleteOrganizationResult";
  success?: Maybe<Scalars["Boolean"]>;
};

export type DeleteOwnerInput = {
  id: Scalars["OwnerId"];
};

export type DeleteOwnerOutput =
  | DeleteOwnerResult
  | OwnerNotFoundError
  | OwnerOwnsItemsError
  | OwnerReviewsItemsError;

export type DeleteOwnerResult = {
  __typename?: "DeleteOwnerResult";
  owner: Owner;
};

export type DeleteOwnersInput = {
  ids: Array<Scalars["OwnerId"]>;
};

export type DeleteOwnersOutput =
  | DeleteOwnersResult
  | OwnerNotFoundError
  | OwnerOwnsItemsError
  | OwnerReviewsItemsError;

export type DeleteOwnersResult = {
  __typename?: "DeleteOwnersResult";
  owners: Array<Owner>;
};

export type DeleteRequestTemplateInput = {
  id: Scalars["RequestTemplateId"];
};

export type DeleteRequestTemplateOutput = DeleteRequestTemplateResult;

export type DeleteRequestTemplateResult = {
  __typename?: "DeleteRequestTemplateResult";
  requestTemplate: RequestTemplateSimple;
};

export type DeleteResourceCustomAccessLevelsEntryResult = {
  __typename?: "DeleteResourceCustomAccessLevelsEntryResult";
  resourceCustomAccessLevel: ResourceCustomAccessLevel;
};

export type DeleteResourceCustomAccessLevelsInput = {
  ids?: InputMaybe<Array<Scalars["ResourceCustomAccessLevelId"]>>;
};

export type DeleteResourceCustomAccessLevelsOutput =
  | DeleteResourceCustomAccessLevelsResult
  | ResourceCustomAccessLevelNotFoundError;

export type DeleteResourceCustomAccessLevelsResult = {
  __typename?: "DeleteResourceCustomAccessLevelsResult";
  entries: Array<DeleteResourceCustomAccessLevelsEntryResult>;
};

export type DeleteResourcesInput = {
  resourceIds: Array<Scalars["ResourceId"]>;
};

export type DeleteResourcesOutput =
  | DeleteResourcesResult
  | OpalResourceImmutableError
  | ResourceNotFoundError;

export type DeleteResourcesResult = {
  __typename?: "DeleteResourcesResult";
  success: Scalars["Boolean"];
};

export type DeleteSamlConnectionOutput = DeleteSamlConnectionResult;

export type DeleteSamlConnectionProxyInput = {
  id: Scalars["OrganizationId"];
  providerID: Scalars["String"];
};

export type DeleteSamlConnectionProxyOutput = DeleteSamlConnectionProxyResult;

export type DeleteSamlConnectionProxyResult = {
  __typename?: "DeleteSamlConnectionProxyResult";
  onPremOrganizationSamlInfo: OnPremOrganizationSamlInfo;
};

export type DeleteSamlConnectionResult = {
  __typename?: "DeleteSamlConnectionResult";
  organizationSamlInfo: OrganizationSamlInfo;
};

export type DeleteTagsEntryResult = {
  __typename?: "DeleteTagsEntryResult";
  tag: Tag;
};

export type DeleteTagsInput = {
  ids: Array<Scalars["TagId"]>;
};

export type DeleteTagsOutput = DeleteTagsResult | TagNotFoundError;

export type DeleteTagsResult = {
  __typename?: "DeleteTagsResult";
  entries: Array<DeleteTagsEntryResult>;
};

export type DeleteThirdPartyIntegrationInput = {
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  thirdPartyProvider: ThirdPartyProvider;
};

export type DeleteThirdPartyIntegrationOutput = DeleteThirdPartyIntegrationResult;

export type DeleteThirdPartyIntegrationResult = {
  __typename?: "DeleteThirdPartyIntegrationResult";
  thirdPartyIntegration: ThirdPartyIntegration;
};

export type DeleteUserInput = {
  id: Scalars["UserId"];
};

export type DeleteUserOutput =
  | DeleteUserResult
  | OpalAdminRoleMustHaveAtLeastOneDirectUser
  | SystemUserIsImmutableError
  | UserNotFoundError;

export type DeleteUserResult = {
  __typename?: "DeleteUserResult";
  user: User;
};

export type DeleteWebhookInfoOutput = DeleteWebhookInfoResult;

export type DeleteWebhookInfoResult = {
  __typename?: "DeleteWebhookInfoResult";
  success: Scalars["Boolean"];
};

export type DenyRequestInput = {
  comment: Scalars["String"];
  id: Scalars["RequestId"];
  level?: InputMaybe<RequestDecisionLevel>;
};

export type DenyRequestOutput =
  | DenyRequestResult
  | RequestAlreadyActionedError
  | RequestNotFoundError;

export type DenyRequestResult = {
  __typename?: "DenyRequestResult";
  request: Request;
};

export type DismissGroupBindingSuggestionsInput = {
  ids: Array<Scalars["GroupBindingSuggestionId"]>;
};

export type DismissGroupBindingSuggestionsOutput =
  | DismissGroupBindingSuggestionsResult
  | GroupBindingSuggestionNotFoundError;

export type DismissGroupBindingSuggestionsResult = {
  __typename?: "DismissGroupBindingSuggestionsResult";
  success: Scalars["Boolean"];
};

export type DismissRecommendationsSubscoresInput = {
  entityKeys: Array<RecommendationsEntityKey>;
  type: RecommendationsSubscoreType;
};

export type DismissRecommendationsSubscoresOutput = {
  __typename?: "DismissRecommendationsSubscoresOutput";
  success: Scalars["Boolean"];
};

export type DismissSyncErrorsInput = {
  ids: Array<Scalars["SyncErrorId"]>;
};

export type DismissSyncErrorsOutput =
  | DismissSyncErrorsResult
  | SyncErrorNotFoundError;

export type DismissSyncErrorsResult = {
  __typename?: "DismissSyncErrorsResult";
  ids: Array<Scalars["SyncErrorId"]>;
};

export type DuoConnectionMetadata = {
  __typename?: "DuoConnectionMetadata";
  apiHostname: Scalars["String"];
};

export type DuoConnectionMetadataInput = {
  apiHostname: Scalars["String"];
};

export type DuoCredentialsInput = {
  integrationKey: Scalars["String"];
  secretKey: Scalars["String"];
};

export type DurationOption = {
  __typename?: "DurationOption";
  durationInMinutes: Scalars["Int"];
  label: Scalars["String"];
};

export type EdgeMetadata = {
  __typename?: "EdgeMetadata";
  accessType?: Maybe<AccessTypeData>;
  lastUsageTime?: Maybe<Scalars["Time"]>;
};

export type EndDateFilter = {
  date: Scalars["String"];
};

export type EndSystemAuthorizationError = Error & {
  __typename?: "EndSystemAuthorizationError";
  message: Scalars["String"];
};

export type EntitiesForRiskScoreRangeResult = {
  __typename?: "EntitiesForRiskScoreRangeResult";
  entities: Array<RecommendationsEntity>;
};

export type Entity = Group | Resource;

export type EntityDisplayInfo = {
  __typename?: "EntityDisplayInfo";
  avatarURL?: Maybe<Scalars["String"]>;
  connectionType?: Maybe<ConnectionType>;
  groupType?: Maybe<GroupType>;
  provider?: Maybe<ThirdPartyProvider>;
  resourceType?: Maybe<ResourceType>;
};

export type EntityIDsFilterInput = {
  entityIDs: Array<Scalars["String"]>;
};

export type EntityIdTuple = {
  __typename?: "EntityIdTuple";
  entityId: Scalars["UUID"];
  entityType: EntityType;
};

export type EntityIdTupleInput = {
  entityId: Scalars["UUID"];
  entityType: EntityType;
};

export enum EntityType {
  AccessLevel = "ACCESS_LEVEL",
  AccessReview = "ACCESS_REVIEW",
  AccessReviewConnection = "ACCESS_REVIEW_CONNECTION",
  AccessReviewConnectionUser = "ACCESS_REVIEW_CONNECTION_USER",
  AccessReviewGroup = "ACCESS_REVIEW_GROUP",
  AccessReviewGroupResource = "ACCESS_REVIEW_GROUP_RESOURCE",
  AccessReviewGroupUser = "ACCESS_REVIEW_GROUP_USER",
  AccessReviewResource = "ACCESS_REVIEW_RESOURCE",
  AccessReviewResourceResource = "ACCESS_REVIEW_RESOURCE_RESOURCE",
  AccessReviewResourceUser = "ACCESS_REVIEW_RESOURCE_USER",
  AccessReviewTemplate = "ACCESS_REVIEW_TEMPLATE",
  Activity = "ACTIVITY",
  AuthToken = "AUTH_TOKEN",
  Bundle = "BUNDLE",
  ConfigurationTemplate = "CONFIGURATION_TEMPLATE",
  Connection = "CONNECTION",
  Event = "EVENT",
  EventFilter = "EVENT_FILTER",
  EventStreamConnection = "EVENT_STREAM_CONNECTION",
  Group = "GROUP",
  GroupBinding = "GROUP_BINDING",
  GroupBindingSuggestion = "GROUP_BINDING_SUGGESTION",
  GroupFolder = "GROUP_FOLDER",
  IdpConnection = "IDP_CONNECTION",
  MessageChannel = "MESSAGE_CHANNEL",
  OnCallGroup = "ON_CALL_GROUP",
  OnCallSchedule = "ON_CALL_SCHEDULE",
  OrgSetting = "ORG_SETTING",
  Owner = "OWNER",
  Request = "REQUEST",
  RequestConfiguration = "REQUEST_CONFIGURATION",
  RequestTemplate = "REQUEST_TEMPLATE",
  Resource = "RESOURCE",
  ResourceFolder = "RESOURCE_FOLDER",
  ResourceProposal = "RESOURCE_PROPOSAL",
  ReviewerStage = "REVIEWER_STAGE",
  SearchQuery = "SEARCH_QUERY",
  Session = "SESSION",
  SupportTicket = "SUPPORT_TICKET",
  Tag = "TAG",
  User = "USER",
}

export type Error = {
  message: Scalars["String"];
};

export type ErrorEntity = {
  __typename?: "ErrorEntity";
  id: EntityIdTuple;
  name: Scalars["String"];
};

export enum ErrorNotificationSettingType {
  Disabled = "DISABLED",
  NotifyAdmins = "NOTIFY_ADMINS",
  NotifyOwner = "NOTIFY_OWNER",
}

export type EscalateRequestToSkipManagerInput = {
  id: Scalars["RequestId"];
};

export type EscalateRequestToSkipManagerOutput =
  | EscalateRequestToSkipManagerResult
  | ManagerHasAlreadyApprovedError
  | NoManagerSetForRequestingUserError
  | NoSkipManagerSetForTargetUserError
  | RequestAlreadyEscalatedToSkipManagerError
  | RequestNotFoundError;

export type EscalateRequestToSkipManagerResult = {
  __typename?: "EscalateRequestToSkipManagerResult";
  success: Scalars["Boolean"];
};

export type Event = {
  __typename?: "Event";
  actorIP?: Maybe<Scalars["String"]>;
  actorUser?: Maybe<User>;
  actorUserAgent?: Maybe<Scalars["String"]>;
  actorUserId: Scalars["UserId"];
  apiTokenLabel?: Maybe<Scalars["String"]>;
  apiTokenPreview?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
  eventType: EventType;
  id: Scalars["EventId"];
  numErrorSubEvents: Scalars["Int"];
  numSubEvents: Scalars["Int"];
  paginatedSubEvents: PaginatedSubEventsOutput;
  source: ConnectionType;
  /** @deprecated Use paginatedSubEvents instead */
  subEvents: Array<SubEvent>;
};

export type EventPaginatedSubEventsArgs = {
  input?: InputMaybe<PaginatedSubEventsInput>;
};

export type EventFilter = {
  __typename?: "EventFilter";
  actor?: Maybe<User>;
  actorId?: Maybe<Scalars["UserId"]>;
  apiTokenLabel?: Maybe<Scalars["String"]>;
  apiTokenPreview?: Maybe<Scalars["String"]>;
  createdAt: Scalars["Time"];
  creator: User;
  creatorId: Scalars["UserId"];
  endDate?: Maybe<Scalars["String"]>;
  eventTypes?: Maybe<Array<EventType>>;
  id: Scalars["EventFilterId"];
  name: Scalars["String"];
  objectId?: Maybe<Scalars["UUID"]>;
  organizationId: Scalars["OrganizationId"];
  startDate?: Maybe<Scalars["String"]>;
  systemEvents?: Maybe<Scalars["Boolean"]>;
};

export type EventFilterFields = {
  actors?: InputMaybe<ActorFilter>;
  apiTokens?: InputMaybe<ApiTokenFilter>;
  endDate?: InputMaybe<EndDateFilter>;
  eventTypes?: InputMaybe<EventTypeFilter>;
  objects?: InputMaybe<ObjectFilter>;
  startDate?: InputMaybe<StartDateFilter>;
  systemEvents?: InputMaybe<Scalars["Boolean"]>;
};

export type EventFilterInput = {
  id: Scalars["EventFilterId"];
};

export type EventFilterInUseError = Error & {
  __typename?: "EventFilterInUseError";
  entities: Array<ErrorEntity>;
  message: Scalars["String"];
};

export type EventFilterNotFoundError = Error & {
  __typename?: "EventFilterNotFoundError";
  message: Scalars["String"];
};

export type EventFilterOutput = EventFilterNotFoundError | EventFilterResult;

export type EventFilterResult = {
  __typename?: "EventFilterResult";
  eventFilter: EventFilter;
};

export type EventFiltersOutput = EventFiltersResult;

export type EventFiltersResult = {
  __typename?: "EventFiltersResult";
  eventFilters: Array<EventFilter>;
};

export type EventInput = {
  id: Scalars["EventId"];
};

export type EventNotFoundError = Error & {
  __typename?: "EventNotFoundError";
  message: Scalars["String"];
};

export type EventOutput = EventNotFoundError | EventResult;

export type EventResult = {
  __typename?: "EventResult";
  event: Event;
};

export enum EventSeverity {
  Alert = "ALERT",
  Info = "INFO",
  Warning = "WARNING",
}

export type EventsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<EventFilterFields>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
};

export type EventsOutput = EventsResult | InvalidObjectIdFilterError;

export type EventsResult = {
  __typename?: "EventsResult";
  cursor?: Maybe<Scalars["String"]>;
  events: Array<Event>;
};

export type EventStream = {
  __typename?: "EventStream";
  connection: PubSubPublishConnection;
  id: Scalars["EventStreamId"];
};

export enum EventType {
  AccessReviewAutoAssignReviewerByApprovers = "ACCESS_REVIEW_AUTO_ASSIGN_REVIEWER_BY_APPROVERS",
  AccessReviewAutoAssignReviewerByManager = "ACCESS_REVIEW_AUTO_ASSIGN_REVIEWER_BY_MANAGER",
  AccessReviewAutoAssignReviewerByOwningTeamAdmin = "ACCESS_REVIEW_AUTO_ASSIGN_REVIEWER_BY_OWNING_TEAM_ADMIN",
  AccessReviewConnectionReviewed = "ACCESS_REVIEW_CONNECTION_REVIEWED",
  AccessReviewConnectionReviewersUpdated = "ACCESS_REVIEW_CONNECTION_REVIEWERS_UPDATED",
  AccessReviewGroupReviewed = "ACCESS_REVIEW_GROUP_REVIEWED",
  AccessReviewGroupReviewersUpdated = "ACCESS_REVIEW_GROUP_REVIEWERS_UPDATED",
  AccessReviewNhiAccessToResourceAccepted = "ACCESS_REVIEW_NHI_ACCESS_TO_RESOURCE_ACCEPTED",
  AccessReviewNhiAccessToResourceRevoked = "ACCESS_REVIEW_NHI_ACCESS_TO_RESOURCE_REVOKED",
  AccessReviewNhiAccessToResourceRevokedDecision = "ACCESS_REVIEW_NHI_ACCESS_TO_RESOURCE_REVOKED_DECISION",
  AccessReviewResourceAccessToGroupAccepted = "ACCESS_REVIEW_RESOURCE_ACCESS_TO_GROUP_ACCEPTED",
  AccessReviewResourceAccessToGroupRevoked = "ACCESS_REVIEW_RESOURCE_ACCESS_TO_GROUP_REVOKED",
  AccessReviewResourceAccessToGroupRevokedDecision = "ACCESS_REVIEW_RESOURCE_ACCESS_TO_GROUP_REVOKED_DECISION",
  AccessReviewResourceReviewed = "ACCESS_REVIEW_RESOURCE_REVIEWED",
  AccessReviewResourceReviewersUpdated = "ACCESS_REVIEW_RESOURCE_REVIEWERS_UPDATED",
  AccessReviewResourceUserSupportTicketLinked = "ACCESS_REVIEW_RESOURCE_USER_SUPPORT_TICKET_LINKED",
  AccessReviewResourceUserSupportTicketUnlinked = "ACCESS_REVIEW_RESOURCE_USER_SUPPORT_TICKET_UNLINKED",
  AccessReviewReviewPerformed = "ACCESS_REVIEW_REVIEW_PERFORMED",
  AccessReviewReviewerForConnectionUserSet = "ACCESS_REVIEW_REVIEWER_FOR_CONNECTION_USER_SET",
  AccessReviewReviewerForGroupResourceSet = "ACCESS_REVIEW_REVIEWER_FOR_GROUP_RESOURCE_SET",
  AccessReviewReviewerForGroupUserSet = "ACCESS_REVIEW_REVIEWER_FOR_GROUP_USER_SET",
  AccessReviewReviewerForResourceResourceSet = "ACCESS_REVIEW_REVIEWER_FOR_RESOURCE_RESOURCE_SET",
  AccessReviewReviewerForResourceUserSet = "ACCESS_REVIEW_REVIEWER_FOR_RESOURCE_USER_SET",
  AccessReviewStopped = "ACCESS_REVIEW_STOPPED",
  AccessReviewTemplatesCreated = "ACCESS_REVIEW_TEMPLATES_CREATED",
  AccessReviewTemplatesDeleted = "ACCESS_REVIEW_TEMPLATES_DELETED",
  AccessReviewTemplatesUpdated = "ACCESS_REVIEW_TEMPLATES_UPDATED",
  AccessReviewUserAccessToConnectionAccepted = "ACCESS_REVIEW_USER_ACCESS_TO_CONNECTION_ACCEPTED",
  AccessReviewUserAccessToConnectionRevoked = "ACCESS_REVIEW_USER_ACCESS_TO_CONNECTION_REVOKED",
  AccessReviewUserAccessToConnectionRevokedDecision = "ACCESS_REVIEW_USER_ACCESS_TO_CONNECTION_REVOKED_DECISION",
  AccessReviewUserAccessToGroupAccepted = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_ACCEPTED",
  AccessReviewUserAccessToGroupChanged = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_CHANGED",
  AccessReviewUserAccessToGroupChangedDecision = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_CHANGED_DECISION",
  AccessReviewUserAccessToGroupChangedFailure = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_CHANGED_FAILURE",
  AccessReviewUserAccessToGroupRevoked = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_REVOKED",
  AccessReviewUserAccessToGroupRevokedDecision = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_REVOKED_DECISION",
  AccessReviewUserAccessToGroupRevokedFromEndSystem = "ACCESS_REVIEW_USER_ACCESS_TO_GROUP_REVOKED_FROM_END_SYSTEM",
  AccessReviewUserAccessToResourceAccepted = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_ACCEPTED",
  AccessReviewUserAccessToResourceChanged = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_CHANGED",
  AccessReviewUserAccessToResourceChangedDecision = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_CHANGED_DECISION",
  AccessReviewUserAccessToResourceChangedFailure = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_CHANGED_FAILURE",
  AccessReviewUserAccessToResourceRevoked = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_REVOKED",
  AccessReviewUserAccessToResourceRevokedDecision = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_REVOKED_DECISION",
  AccessReviewUserAccessToResourceRevokedFromEndSystem = "ACCESS_REVIEW_USER_ACCESS_TO_RESOURCE_REVOKED_FROM_END_SYSTEM",
  AccessReviewsCreated = "ACCESS_REVIEWS_CREATED",
  AccessReviewsUpdated = "ACCESS_REVIEWS_UPDATED",
  ApiTokenCreated = "API_TOKEN_CREATED",
  ApiTokenDeleted = "API_TOKEN_DELETED",
  BreakGlassUsed = "BREAK_GLASS_USED",
  BreakGlassUsersAddedToGroups = "BREAK_GLASS_USERS_ADDED_TO_GROUPS",
  BreakGlassUsersRemovedFromGroups = "BREAK_GLASS_USERS_REMOVED_FROM_GROUPS",
  BundleGroupsAdded = "BUNDLE_GROUPS_ADDED",
  BundleGroupsRemoved = "BUNDLE_GROUPS_REMOVED",
  BundleResourcesAdded = "BUNDLE_RESOURCES_ADDED",
  BundleResourcesRemoved = "BUNDLE_RESOURCES_REMOVED",
  BundlesCreated = "BUNDLES_CREATED",
  BundlesDeleted = "BUNDLES_DELETED",
  BundlesUpdated = "BUNDLES_UPDATED",
  ConfigurationTemplateAdminOwnerUpdated = "CONFIGURATION_TEMPLATE_ADMIN_OWNER_UPDATED",
  ConfigurationTemplateAuditMessageChannelUpdated = "CONFIGURATION_TEMPLATE_AUDIT_MESSAGE_CHANNEL_UPDATED",
  ConfigurationTemplateBreakGlassUsersUpdated = "CONFIGURATION_TEMPLATE_BREAK_GLASS_USERS_UPDATED",
  ConfigurationTemplateCreated = "CONFIGURATION_TEMPLATE_CREATED",
  ConfigurationTemplateCustomRequestNotificationUpdated = "CONFIGURATION_TEMPLATE_CUSTOM_REQUEST_NOTIFICATION_UPDATED",
  ConfigurationTemplateDeleted = "CONFIGURATION_TEMPLATE_DELETED",
  ConfigurationTemplateNameUpdated = "CONFIGURATION_TEMPLATE_NAME_UPDATED",
  ConfigurationTemplateOncallSchedulesUpdated = "CONFIGURATION_TEMPLATE_ONCALL_SCHEDULES_UPDATED",
  ConfigurationTemplateRequestConfigDeleted = "CONFIGURATION_TEMPLATE_REQUEST_CONFIG_DELETED",
  ConfigurationTemplateRequestConfigUpdated = "CONFIGURATION_TEMPLATE_REQUEST_CONFIG_UPDATED",
  ConfigurationTemplateRequireMfaToApproveUpdated = "CONFIGURATION_TEMPLATE_REQUIRE_MFA_TO_APPROVE_UPDATED",
  ConfigurationTemplateRequireMfaToConnectUpdated = "CONFIGURATION_TEMPLATE_REQUIRE_MFA_TO_CONNECT_UPDATED",
  ConfigurationTemplateTicketPropagationUpdated = "CONFIGURATION_TEMPLATE_TICKET_PROPAGATION_UPDATED",
  ConfigurationTemplateUpdated = "CONFIGURATION_TEMPLATE_UPDATED",
  ConfigurationTemplateVisibilityGroupsUpdated = "CONFIGURATION_TEMPLATE_VISIBILITY_GROUPS_UPDATED",
  ConfigurationTemplateVisibilityUpdated = "CONFIGURATION_TEMPLATE_VISIBILITY_UPDATED",
  ConnectionAdminOwnerUpdated = "CONNECTION_ADMIN_OWNER_UPDATED",
  ConnectionAppVisibilityGroupsAdded = "CONNECTION_APP_VISIBILITY_GROUPS_ADDED",
  ConnectionAppVisibilityGroupsChanged = "CONNECTION_APP_VISIBILITY_GROUPS_CHANGED",
  ConnectionAppVisibilityGroupsRemoved = "CONNECTION_APP_VISIBILITY_GROUPS_REMOVED",
  ConnectionAutoImportConfigTemplateUpdated = "CONNECTION_AUTO_IMPORT_CONFIG_TEMPLATE_UPDATED",
  ConnectionAutoImportGroupResourcesUpdated = "CONNECTION_AUTO_IMPORT_GROUP_RESOURCES_UPDATED",
  ConnectionCredentialsUpdated = "CONNECTION_CREDENTIALS_UPDATED",
  ConnectionDescriptionUpdated = "CONNECTION_DESCRIPTION_UPDATED",
  ConnectionImportSettingUpdated = "CONNECTION_IMPORT_SETTING_UPDATED",
  ConnectionImportVisibilityUpdated = "CONNECTION_IMPORT_VISIBILITY_UPDATED",
  ConnectionMetadataUpdated = "CONNECTION_METADATA_UPDATED",
  ConnectionNameUpdated = "CONNECTION_NAME_UPDATED",
  ConnectionUsersUpdated = "CONNECTION_USERS_UPDATED",
  ConnectionVisibilityGroupsAdded = "CONNECTION_VISIBILITY_GROUPS_ADDED",
  ConnectionVisibilityGroupsRemoved = "CONNECTION_VISIBILITY_GROUPS_REMOVED",
  ConnectionVisibilityUpdated = "CONNECTION_VISIBILITY_UPDATED",
  ConnectionWebhookEnabledUpdated = "CONNECTION_WEBHOOK_ENABLED_UPDATED",
  ConnectionsCreated = "CONNECTIONS_CREATED",
  ConnectionsDeleted = "CONNECTIONS_DELETED",
  ConnectionsUpdated = "CONNECTIONS_UPDATED",
  EventMonitorEventsDetected = "EVENT_MONITOR_EVENTS_DETECTED",
  EventStreamConnectionsCreated = "EVENT_STREAM_CONNECTIONS_CREATED",
  EventStreamConnectionsDeleted = "EVENT_STREAM_CONNECTIONS_DELETED",
  EventStreamConnectionsTested = "EVENT_STREAM_CONNECTIONS_TESTED",
  EventStreamConnectionsUpdated = "EVENT_STREAM_CONNECTIONS_UPDATED",
  GroupAccessRequestEscalationPeriodUpdated = "GROUP_ACCESS_REQUEST_ESCALATION_PERIOD_UPDATED",
  GroupAddedToBinding = "GROUP_ADDED_TO_BINDING",
  GroupAdminOwnerUpdated = "GROUP_ADMIN_OWNER_UPDATED",
  GroupAutoApprovalUpdated = "GROUP_AUTO_APPROVAL_UPDATED",
  GroupBindingCreated = "GROUP_BINDING_CREATED",
  GroupBindingDeleted = "GROUP_BINDING_DELETED",
  GroupBindingSourceGroupUpdated = "GROUP_BINDING_SOURCE_GROUP_UPDATED",
  GroupBindingUpdated = "GROUP_BINDING_UPDATED",
  GroupConfigurationTemplateUpdated = "GROUP_CONFIGURATION_TEMPLATE_UPDATED",
  GroupCustomRequestNotificationUpdated = "GROUP_CUSTOM_REQUEST_NOTIFICATION_UPDATED",
  GroupFoldersCreated = "GROUP_FOLDERS_CREATED",
  GroupFoldersDeleted = "GROUP_FOLDERS_DELETED",
  GroupFunctionUpdated = "GROUP_FUNCTION_UPDATED",
  GroupGroupsUpdated = "GROUP_GROUPS_UPDATED",
  GroupIsRequestableUpdated = "GROUP_IS_REQUESTABLE_UPDATED",
  GroupLeadersCreated = "GROUP_LEADERS_CREATED",
  GroupLeadersRemoved = "GROUP_LEADERS_REMOVED",
  GroupMaxDurationUpdated = "GROUP_MAX_DURATION_UPDATED",
  GroupNameUpdated = "GROUP_NAME_UPDATED",
  GroupRecommendedDurationUpdated = "GROUP_RECOMMENDED_DURATION_UPDATED",
  GroupRemovedFromBinding = "GROUP_REMOVED_FROM_BINDING",
  GroupRequestRequireMfaUpdated = "GROUP_REQUEST_REQUIRE_MFA_UPDATED",
  GroupRequireManagerApprovalUpdated = "GROUP_REQUIRE_MANAGER_APPROVAL_UPDATED",
  GroupRequireMfaUpdated = "GROUP_REQUIRE_MFA_UPDATED",
  GroupRequireSupportTicketUpdated = "GROUP_REQUIRE_SUPPORT_TICKET_UPDATED",
  GroupResourcesUpdated = "GROUP_RESOURCES_UPDATED",
  GroupReviewerStageCreated = "GROUP_REVIEWER_STAGE_CREATED",
  GroupReviewerStageDeleted = "GROUP_REVIEWER_STAGE_DELETED",
  GroupReviewerStageUpdated = "GROUP_REVIEWER_STAGE_UPDATED",
  GroupUsersUpdated = "GROUP_USERS_UPDATED",
  GroupVisibilityGroupsAdded = "GROUP_VISIBILITY_GROUPS_ADDED",
  GroupVisibilityGroupsRemoved = "GROUP_VISIBILITY_GROUPS_REMOVED",
  GroupVisibilityUpdated = "GROUP_VISIBILITY_UPDATED",
  GroupsAddedToFolders = "GROUPS_ADDED_TO_FOLDERS",
  GroupsAddedToGroups = "GROUPS_ADDED_TO_GROUPS",
  GroupsCreated = "GROUPS_CREATED",
  GroupsDeleted = "GROUPS_DELETED",
  GroupsRemovedFromFolders = "GROUPS_REMOVED_FROM_FOLDERS",
  GroupsRemovedFromGroups = "GROUPS_REMOVED_FROM_GROUPS",
  GroupsUpdated = "GROUPS_UPDATED",
  HrisStatusActive = "HRIS_STATUS_ACTIVE",
  HrisStatusDeleted = "HRIS_STATUS_DELETED",
  HrisStatusDeprovisioned = "HRIS_STATUS_DEPROVISIONED",
  HrisStatusInactive = "HRIS_STATUS_INACTIVE",
  HrisStatusNotFound = "HRIS_STATUS_NOT_FOUND",
  IdpConnectionUserAttributeMappingCreated = "IDP_CONNECTION_USER_ATTRIBUTE_MAPPING_CREATED",
  IdpConnectionUserAttributeMappingDeleted = "IDP_CONNECTION_USER_ATTRIBUTE_MAPPING_DELETED",
  IdpConnectionsCreated = "IDP_CONNECTIONS_CREATED",
  IdpConnectionsDeleted = "IDP_CONNECTIONS_DELETED",
  IdpConnectionsUpdated = "IDP_CONNECTIONS_UPDATED",
  IdpStatusActive = "IDP_STATUS_ACTIVE",
  IdpStatusDeleted = "IDP_STATUS_DELETED",
  IdpStatusDeprovisioned = "IDP_STATUS_DEPROVISIONED",
  IdpStatusInactive = "IDP_STATUS_INACTIVE",
  IdpStatusNotFound = "IDP_STATUS_NOT_FOUND",
  ImpersonationSessionStarted = "IMPERSONATION_SESSION_STARTED",
  ImpersonationSessionStopped = "IMPERSONATION_SESSION_STOPPED",
  MessageChannelsAddedToGroups = "MESSAGE_CHANNELS_ADDED_TO_GROUPS",
  MessageChannelsAddedToResources = "MESSAGE_CHANNELS_ADDED_TO_RESOURCES",
  MessageChannelsCreated = "MESSAGE_CHANNELS_CREATED",
  MessageChannelsDeleted = "MESSAGE_CHANNELS_DELETED",
  MessageChannelsRemovedFromGroups = "MESSAGE_CHANNELS_REMOVED_FROM_GROUPS",
  MessageChannelsRemovedFromResources = "MESSAGE_CHANNELS_REMOVED_FROM_RESOURCES",
  OnCallSchedulesAddedToGroups = "ON_CALL_SCHEDULES_ADDED_TO_GROUPS",
  OnCallSchedulesCreated = "ON_CALL_SCHEDULES_CREATED",
  OnCallSchedulesDeleted = "ON_CALL_SCHEDULES_DELETED",
  OnCallSchedulesRemovedFromGroups = "ON_CALL_SCHEDULES_REMOVED_FROM_GROUPS",
  OnCallSchedulesUpdated = "ON_CALL_SCHEDULES_UPDATED",
  OnCallSchedulesUpdatedForGroups = "ON_CALL_SCHEDULES_UPDATED_FOR_GROUPS",
  OrgSettingsUpdated = "ORG_SETTINGS_UPDATED",
  OwnerReviewerChannelRemoved = "OWNER_REVIEWER_CHANNEL_REMOVED",
  OwnerReviewerChannelUpdated = "OWNER_REVIEWER_CHANNEL_UPDATED",
  OwnerSourceGroupRemoved = "OWNER_SOURCE_GROUP_REMOVED",
  OwnerSourceGroupUpdated = "OWNER_SOURCE_GROUP_UPDATED",
  OwnerUsersAdded = "OWNER_USERS_ADDED",
  OwnerUsersRemoved = "OWNER_USERS_REMOVED",
  OwnerUsersUpdated = "OWNER_USERS_UPDATED",
  OwnersCreated = "OWNERS_CREATED",
  OwnersDeleted = "OWNERS_DELETED",
  OwnersUpdated = "OWNERS_UPDATED",
  PropagatedAddResourceToGroup = "PROPAGATED_ADD_RESOURCE_TO_GROUP",
  PropagatedAddUserToGroup = "PROPAGATED_ADD_USER_TO_GROUP",
  PropagatedAddUserToResource = "PROPAGATED_ADD_USER_TO_RESOURCE",
  PropagatedRemoveResourceFromGroup = "PROPAGATED_REMOVE_RESOURCE_FROM_GROUP",
  PropagatedRemoveUserFromConnection = "PROPAGATED_REMOVE_USER_FROM_CONNECTION",
  PropagatedRemoveUserFromGroup = "PROPAGATED_REMOVE_USER_FROM_GROUP",
  PropagatedRemoveUserFromResource = "PROPAGATED_REMOVE_USER_FROM_RESOURCE",
  PropagationComputeRemoteUserPushTasks = "PROPAGATION_COMPUTE_REMOTE_USER_PUSH_TASKS",
  PropagationFailureAddResourceToGroup = "PROPAGATION_FAILURE_ADD_RESOURCE_TO_GROUP",
  PropagationFailureAddUserToGroup = "PROPAGATION_FAILURE_ADD_USER_TO_GROUP",
  PropagationFailureAddUserToResource = "PROPAGATION_FAILURE_ADD_USER_TO_RESOURCE",
  PropagationFailureRemoveResourceFromGroup = "PROPAGATION_FAILURE_REMOVE_RESOURCE_FROM_GROUP",
  PropagationFailureRemoveUserFromConnection = "PROPAGATION_FAILURE_REMOVE_USER_FROM_CONNECTION",
  PropagationFailureRemoveUserFromGroup = "PROPAGATION_FAILURE_REMOVE_USER_FROM_GROUP",
  PropagationFailureRemoveUserFromResource = "PROPAGATION_FAILURE_REMOVE_USER_FROM_RESOURCE",
  PropagationFailureRoleAssignmentCreated = "PROPAGATION_FAILURE_ROLE_ASSIGNMENT_CREATED",
  PropagationFailureRoleAssignmentDeleted = "PROPAGATION_FAILURE_ROLE_ASSIGNMENT_DELETED",
  PropagationManualAddUserToResource = "PROPAGATION_MANUAL_ADD_USER_TO_RESOURCE",
  PropagationManualRemoveUserFromResource = "PROPAGATION_MANUAL_REMOVE_USER_FROM_RESOURCE",
  PropagationSuccessAddResourceToGroup = "PROPAGATION_SUCCESS_ADD_RESOURCE_TO_GROUP",
  PropagationSuccessAddUserToGroup = "PROPAGATION_SUCCESS_ADD_USER_TO_GROUP",
  PropagationSuccessAddUserToResource = "PROPAGATION_SUCCESS_ADD_USER_TO_RESOURCE",
  PropagationSuccessRemoveResourceFromGroup = "PROPAGATION_SUCCESS_REMOVE_RESOURCE_FROM_GROUP",
  PropagationSuccessRemoveUserFromConnection = "PROPAGATION_SUCCESS_REMOVE_USER_FROM_CONNECTION",
  PropagationSuccessRemoveUserFromGroup = "PROPAGATION_SUCCESS_REMOVE_USER_FROM_GROUP",
  PropagationSuccessRemoveUserFromResource = "PROPAGATION_SUCCESS_REMOVE_USER_FROM_RESOURCE",
  PropagationSuccessRoleAssignmentCreated = "PROPAGATION_SUCCESS_ROLE_ASSIGNMENT_CREATED",
  PropagationSuccessRoleAssignmentDeleted = "PROPAGATION_SUCCESS_ROLE_ASSIGNMENT_DELETED",
  PropagationTicketCreatedRemotely = "PROPAGATION_TICKET_CREATED_REMOTELY",
  PropagationTicketUpdatedRemotely = "PROPAGATION_TICKET_UPDATED_REMOTELY",
  PropagationTimedOut = "PROPAGATION_TIMED_OUT",
  /** Deprecated. Please use REMOTE_EVENT_USER_ADDED_TO_GROUP */
  RemoteEventGroupAccessAdded = "REMOTE_EVENT_GROUP_ACCESS_ADDED",
  /** Deprecated. Please use REMOTE_EVENT_USER_REMOVED_FROM_GROUP */
  RemoteEventGroupAccessRemoved = "REMOTE_EVENT_GROUP_ACCESS_REMOVED",
  RemoteEventGroupAddedToResource = "REMOTE_EVENT_GROUP_ADDED_TO_RESOURCE",
  RemoteEventGroupRemovedFromResource = "REMOTE_EVENT_GROUP_REMOVED_FROM_RESOURCE",
  RemoteEventGroupsCreated = "REMOTE_EVENT_GROUPS_CREATED",
  RemoteEventGroupsDeleted = "REMOTE_EVENT_GROUPS_DELETED",
  RemoteEventLoginSuccess = "REMOTE_EVENT_LOGIN_SUCCESS",
  /** Deprecated. Please use REMOTE_EVENT_USER_ADDED_TO_RESOURCE */
  RemoteEventResourceAccessAdded = "REMOTE_EVENT_RESOURCE_ACCESS_ADDED",
  /** Deprecated. Please use REMOTE_EVENT_USER_REMOVED_FROM_RESOURCE */
  RemoteEventResourceAccessRemoved = "REMOTE_EVENT_RESOURCE_ACCESS_REMOVED",
  RemoteEventResourceRead = "REMOTE_EVENT_RESOURCE_READ",
  RemoteEventResourcesCreated = "REMOTE_EVENT_RESOURCES_CREATED",
  RemoteEventResourcesDeleted = "REMOTE_EVENT_RESOURCES_DELETED",
  RemoteEventUserAddedToGroup = "REMOTE_EVENT_USER_ADDED_TO_GROUP",
  RemoteEventUserAddedToResource = "REMOTE_EVENT_USER_ADDED_TO_RESOURCE",
  RemoteEventUserPasswordExpired = "REMOTE_EVENT_USER_PASSWORD_EXPIRED",
  RemoteEventUserPasswordReset = "REMOTE_EVENT_USER_PASSWORD_RESET",
  RemoteEventUserPasswordUpdated = "REMOTE_EVENT_USER_PASSWORD_UPDATED",
  RemoteEventUserRemovedFromGroup = "REMOTE_EVENT_USER_REMOVED_FROM_GROUP",
  RemoteEventUserRemovedFromResource = "REMOTE_EVENT_USER_REMOVED_FROM_RESOURCE",
  RequestCommentAdded = "REQUEST_COMMENT_ADDED",
  RequestGroupRequested = "REQUEST_GROUP_REQUESTED",
  RequestResourceRequested = "REQUEST_RESOURCE_REQUESTED",
  RequestReviewersAddedToRequests = "REQUEST_REVIEWERS_ADDED_TO_REQUESTS",
  RequestReviewersApproved = "REQUEST_REVIEWERS_APPROVED",
  RequestReviewersDenied = "REQUEST_REVIEWERS_DENIED",
  RequestSkipManagerAddedToRequests = "REQUEST_SKIP_MANAGER_ADDED_TO_REQUESTS",
  RequestSupportTicketAdded = "REQUEST_SUPPORT_TICKET_ADDED",
  RequestSyncTicketClosed = "REQUEST_SYNC_TICKET_CLOSED",
  RequestSyncTicketCommented = "REQUEST_SYNC_TICKET_COMMENTED",
  RequestSyncTicketCreated = "REQUEST_SYNC_TICKET_CREATED",
  RequestSyncTicketError = "REQUEST_SYNC_TICKET_ERROR",
  RequestSyncTicketUpdated = "REQUEST_SYNC_TICKET_UPDATED",
  RequestsAdminApproved = "REQUESTS_ADMIN_APPROVED",
  RequestsAdminDenied = "REQUESTS_ADMIN_DENIED",
  RequestsApproved = "REQUESTS_APPROVED",
  RequestsCanceled = "REQUESTS_CANCELED",
  RequestsCreated = "REQUESTS_CREATED",
  RequestsDenied = "REQUESTS_DENIED",
  ResourceAdminOwnerUpdated = "RESOURCE_ADMIN_OWNER_UPDATED",
  ResourceApproveRequireMfaUpdated = "RESOURCE_APPROVE_REQUIRE_MFA_UPDATED",
  ResourceAutoApprovalUpdated = "RESOURCE_AUTO_APPROVAL_UPDATED",
  ResourceAutoImportConfigTemplateUpdated = "RESOURCE_AUTO_IMPORT_CONFIG_TEMPLATE_UPDATED",
  ResourceConfigurationTemplateUpdated = "RESOURCE_CONFIGURATION_TEMPLATE_UPDATED",
  ResourceConnectRequireMfaUpdated = "RESOURCE_CONNECT_REQUIRE_MFA_UPDATED",
  ResourceCustomRequestNotificationUpdated = "RESOURCE_CUSTOM_REQUEST_NOTIFICATION_UPDATED",
  ResourceFoldersCreated = "RESOURCE_FOLDERS_CREATED",
  ResourceFoldersDeleted = "RESOURCE_FOLDERS_DELETED",
  ResourceIsRequestableUpdated = "RESOURCE_IS_REQUESTABLE_UPDATED",
  ResourceMaxDurationUpdated = "RESOURCE_MAX_DURATION_UPDATED",
  ResourceNameUpdated = "RESOURCE_NAME_UPDATED",
  ResourceRecommendedDurationUpdated = "RESOURCE_RECOMMENDED_DURATION_UPDATED",
  ResourceRequestRequireMfaUpdated = "RESOURCE_REQUEST_REQUIRE_MFA_UPDATED",
  ResourceRequireManagerApprovalUpdated = "RESOURCE_REQUIRE_MANAGER_APPROVAL_UPDATED",
  ResourceRequireSupportTicketUpdated = "RESOURCE_REQUIRE_SUPPORT_TICKET_UPDATED",
  ResourceReviewerStageCreated = "RESOURCE_REVIEWER_STAGE_CREATED",
  ResourceReviewerStageDeleted = "RESOURCE_REVIEWER_STAGE_DELETED",
  ResourceReviewerStageUpdated = "RESOURCE_REVIEWER_STAGE_UPDATED",
  ResourceTicketPropagationUpdated = "RESOURCE_TICKET_PROPAGATION_UPDATED",
  ResourceUsersUpdated = "RESOURCE_USERS_UPDATED",
  ResourceVisibilityGroupsAdded = "RESOURCE_VISIBILITY_GROUPS_ADDED",
  ResourceVisibilityGroupsRemoved = "RESOURCE_VISIBILITY_GROUPS_REMOVED",
  ResourceVisibilityUpdated = "RESOURCE_VISIBILITY_UPDATED",
  ResourcesAddedToFolders = "RESOURCES_ADDED_TO_FOLDERS",
  ResourcesAddedToGroups = "RESOURCES_ADDED_TO_GROUPS",
  ResourcesCreated = "RESOURCES_CREATED",
  ResourcesDeleted = "RESOURCES_DELETED",
  ResourcesRemovedFromFolders = "RESOURCES_REMOVED_FROM_FOLDERS",
  ResourcesRemovedFromGroups = "RESOURCES_REMOVED_FROM_GROUPS",
  ResourcesUpdated = "RESOURCES_UPDATED",
  ReviewersAddedToGroups = "REVIEWERS_ADDED_TO_GROUPS",
  ReviewersAddedToResources = "REVIEWERS_ADDED_TO_RESOURCES",
  ReviewersEscalated = "REVIEWERS_ESCALATED",
  ReviewersReminded = "REVIEWERS_REMINDED",
  ReviewersRemovedFromGroups = "REVIEWERS_REMOVED_FROM_GROUPS",
  ReviewersRemovedFromResources = "REVIEWERS_REMOVED_FROM_RESOURCES",
  RoleAssignmentsCreated = "ROLE_ASSIGNMENTS_CREATED",
  RoleAssignmentsDeleted = "ROLE_ASSIGNMENTS_DELETED",
  RoleAssignmentsUpdated = "ROLE_ASSIGNMENTS_UPDATED",
  SessionsCreatedForResources = "SESSIONS_CREATED_FOR_RESOURCES",
  ThirdPartyIntegrationCreated = "THIRD_PARTY_INTEGRATION_CREATED",
  ThirdPartyIntegrationDeleted = "THIRD_PARTY_INTEGRATION_DELETED",
  ToxicSetViolationsCreated = "TOXIC_SET_VIOLATIONS_CREATED",
  ToxicSetViolationsRemediated = "TOXIC_SET_VIOLATIONS_REMEDIATED",
  ToxicSetViolationsUpdated = "TOXIC_SET_VIOLATIONS_UPDATED",
  UserEmailUpdated = "USER_EMAIL_UPDATED",
  UserLoggedInOauth = "USER_LOGGED_IN_OAUTH",
  UserLoggedInSaml = "USER_LOGGED_IN_SAML",
  UserManagerUpdated = "USER_MANAGER_UPDATED",
  UserMerged = "USER_MERGED",
  UserMfaReset = "USER_MFA_RESET",
  UserNameUpdated = "USER_NAME_UPDATED",
  UserNotNotified = "USER_NOT_NOTIFIED",
  UserNotificationFailed = "USER_NOTIFICATION_FAILED",
  UserNotified = "USER_NOTIFIED",
  UserPositionUpdated = "USER_POSITION_UPDATED",
  UserRemoteIdUpdated = "USER_REMOTE_ID_UPDATED",
  UserTeamAttrUpdated = "USER_TEAM_ATTR_UPDATED",
  UserTeamUpdated = "USER_TEAM_UPDATED",
  UsersAddedToConnections = "USERS_ADDED_TO_CONNECTIONS",
  UsersAddedToGroups = "USERS_ADDED_TO_GROUPS",
  UsersAddedToResources = "USERS_ADDED_TO_RESOURCES",
  UsersCreated = "USERS_CREATED",
  UsersDeleted = "USERS_DELETED",
  UsersRemovedFromConnections = "USERS_REMOVED_FROM_CONNECTIONS",
  UsersRemovedFromGroups = "USERS_REMOVED_FROM_GROUPS",
  UsersRemovedFromResources = "USERS_REMOVED_FROM_RESOURCES",
  UsersUpdated = "USERS_UPDATED",
}

export type EventTypeFilter = {
  eventTypes?: InputMaybe<Array<EventType>>;
};

export type Factor = {
  __typename?: "Factor";
  factorRemoteId: Scalars["UserFactorRemoteId"];
  factorType: FactorType;
};

export type FactorNotFoundError = Error & {
  __typename?: "FactorNotFoundError";
  message: Scalars["String"];
};

export enum FactorType {
  OktaPush = "OKTA_PUSH",
  OktaTotp = "OKTA_TOTP",
}

export type FilteredConnectionsInput = {
  filters?: InputMaybe<FiltersInput>;
};

export type FilteredConnectionsOutput = {
  __typename?: "FilteredConnectionsOutput";
  connections: Array<Connection>;
};

export type FilteredGroupsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<FiltersInput>;
  includeSourceGroups?: InputMaybe<Scalars["Boolean"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SortDirection>;
};

export type FilteredGroupsOutput = FilteredGroupsResult;

export type FilteredGroupsResult = {
  __typename?: "FilteredGroupsResult";
  cursor?: Maybe<Scalars["String"]>;
  groups: Array<Group>;
};

export type FilteredResourcesInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<FiltersInput>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SortDirection>;
};

export type FilteredResourcesOutput = FilteredResourcesResult;

export type FilteredResourcesResult = {
  __typename?: "FilteredResourcesResult";
  cursor?: Maybe<Scalars["String"]>;
  resources: Array<Resource>;
};

export type FilterInput = {
  adminsFilter?: InputMaybe<AdminsFilterInput>;
  connectionsFilter?: InputMaybe<ConnectionsFilterInput>;
  entityIDsFilter?: InputMaybe<EntityIDsFilterInput>;
  filtersFilter?: InputMaybe<FiltersInput>;
  groupTypesFilter?: InputMaybe<GroupTypesFilterInput>;
  namesFilter?: InputMaybe<NamesFilterInput>;
  resourceTypesFilter?: InputMaybe<ResourceTypesFilterInput>;
  tagFilter?: InputMaybe<TagFilterInput>;
  usersFilter?: InputMaybe<UsersFilterInput>;
};

export type FiltersInput = {
  filters: Array<FilterInput>;
  matchMode: FiltersMatchMode;
};

export enum FiltersMatchMode {
  All = "ALL",
  Any = "ANY",
}

export type FirstPartyToken = {
  __typename?: "FirstPartyToken";
  accessLevel: ApiAccessLevel;
  createdAt: Scalars["Time"];
  creatorUser?: Maybe<User>;
  creatorUserId: Scalars["UserId"];
  expiresAt?: Maybe<Scalars["Time"]>;
  id: Scalars["FirstPartyTokenId"];
  lastUsedAt?: Maybe<Scalars["Time"]>;
  tokenLabel: Scalars["String"];
  tokenPreview: Scalars["String"];
};

export type FirstPartyTokensOutput = FirstPartyTokensResult;

export type FirstPartyTokensResult = {
  __typename?: "FirstPartyTokensResult";
  tokens: Array<FirstPartyToken>;
};

export type ForfeitGroupInput = {
  groupId: Scalars["GroupId"];
};

export type ForfeitGroupOutput = ForfeitGroupResult | GroupNotFoundError;

export type ForfeitGroupResult = {
  __typename?: "ForfeitGroupResult";
  group: Group;
  taskId: Scalars["PushTaskId"];
};

export type ForfeitResourceInput = {
  accessLevel: ResourceAccessLevelInput;
  resourceId: Scalars["ResourceId"];
};

export type ForfeitResourceOutput =
  | ForfeitResourceResult
  | OidcIdTokenNotFoundError
  | OpalAdminRoleMustHaveAtLeastOneDirectUser
  | ResourceNotFoundError;

export type ForfeitResourceResult = {
  __typename?: "ForfeitResourceResult";
  resource: Resource;
  taskId: Scalars["PushTaskId"];
};

export type GcpConnectionMetadata = {
  __typename?: "GCPConnectionMetadata";
  pubSubProjectId: Scalars["String"];
  serviceAccount: GcpServiceAccount;
  subscriptionId: Scalars["String"];
};

export type GcpConnectionMetadataInput = {
  pubSubProjectId: Scalars["String"];
  serviceAccount: GcpServiceAccountInput;
  subscriptionId: Scalars["String"];
};

export type GcpServiceAccount = {
  __typename?: "GCPServiceAccount";
  authProviderX509CertUrl: Scalars["String"];
  authUri: Scalars["String"];
  clientEmail: Scalars["String"];
  clientId: Scalars["String"];
  clientX509CertUrl: Scalars["String"];
  projectId: Scalars["String"];
  tokenUri: Scalars["String"];
  type: Scalars["String"];
};

export type GcpServiceAccountCredentialsInput = {
  privateKey: Scalars["String"];
  privateKeyId: Scalars["String"];
};

export type GcpServiceAccountInput = {
  authProviderX509CertUrl: Scalars["String"];
  authUri: Scalars["String"];
  clientEmail: Scalars["String"];
  clientId: Scalars["String"];
  clientX509CertUrl: Scalars["String"];
  projectId: Scalars["String"];
  tokenUri: Scalars["String"];
  type: Scalars["String"];
};

export enum GeneralSettingType {
  AccessExpiringNotifications = "ACCESS_EXPIRING_NOTIFICATIONS",
  AccessReviewReminders = "ACCESS_REVIEW_REMINDERS",
  AutoMergeUsersByEmail = "AUTO_MERGE_USERS_BY_EMAIL",
  DisableNonAdminLogins = "DISABLE_NON_ADMIN_LOGINS",
  GlobalRequesterRole = "GLOBAL_REQUESTER_ROLE",
  LogRocketDisabled = "LOG_ROCKET_DISABLED",
  NestedGroups = "NESTED_GROUPS",
  RequireManagerCc = "REQUIRE_MANAGER_CC",
  RequireOpalMfaForLogins = "REQUIRE_OPAL_MFA_FOR_LOGINS",
  RequireSupportTicket = "REQUIRE_SUPPORT_TICKET",
  SlackApprovals = "SLACK_APPROVALS",
  ToxicSetBlockRequest = "TOXIC_SET_BLOCK_REQUEST",
  UseOidcMfaForGatingOpalActions = "USE_OIDC_MFA_FOR_GATING_OPAL_ACTIONS",
  UseOktaMfaForGatingOpalActions = "USE_OKTA_MFA_FOR_GATING_OPAL_ACTIONS",
  UserDeprovisionDeleteDeprovisioned = "USER_DEPROVISION_DELETE_DEPROVISIONED",
  UserImpersonation = "USER_IMPERSONATION",
}

export type GenerateChannelNameInput = {
  channelType: MessageChannelType;
  itemName: Scalars["String"];
};

export type GitHubConnectionCredentialsInput = {
  adminToken: Scalars["String"];
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
};

export type GitHubConnectionMetadata = {
  __typename?: "GitHubConnectionMetadata";
  orgName: Scalars["String"];
};

export type GitHubConnectionMetadataInput = {
  orgName: Scalars["String"];
};

export type GitLabConnectionCredentialsInput = {
  appSecret: Scalars["String"];
};

export type GitLabConnectionMetadata = {
  __typename?: "GitLabConnectionMetadata";
  appId: Scalars["String"];
  hostName?: Maybe<Scalars["String"]>;
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type GitLabConnectionMetadataInput = {
  appId: Scalars["String"];
  hostName?: InputMaybe<Scalars["String"]>;
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type GoogleGroupError = Error & {
  __typename?: "GoogleGroupError";
  message: Scalars["String"];
};

export type GoogleGroupsConnectionMetadata = {
  __typename?: "GoogleGroupsConnectionMetadata";
  adminUserEmail: Scalars["String"];
  domain: Scalars["String"];
  opalGroupEmail: Scalars["String"];
  serviceAccount: GcpServiceAccount;
};

export type GoogleGroupsConnectionMetadataInput = {
  adminUserEmail: Scalars["String"];
  domain?: InputMaybe<Scalars["String"]>;
  opalGroupEmail: Scalars["String"];
  serviceAccount: GcpServiceAccountInput;
};

export type GoogleIdpConnectionMetadataInput = {
  adminUserEmail: Scalars["String"];
  customerId: Scalars["String"];
};

export type GoogleWorkspaceConnectionMetadata = {
  __typename?: "GoogleWorkspaceConnectionMetadata";
  adminUserEmail: Scalars["String"];
  customerId: Scalars["String"];
  serviceAccount: GcpServiceAccount;
};

export type GoogleWorkspaceConnectionMetadataInput = {
  adminUserEmail: Scalars["String"];
  customerId: Scalars["String"];
  serviceAccount: GcpServiceAccountInput;
};

export type Group = {
  __typename?: "Group";
  accessLevels?: Maybe<Array<GroupAccessLevel>>;
  accessStats?: Maybe<GroupAccessStats>;
  adminOwner?: Maybe<Owner>;
  adminOwnerId: Scalars["OwnerId"];
  auditMessageChannels?: Maybe<Array<MessageChannel>>;
  authorizedActions?: Maybe<Array<Scalars["AuthorizedAction"]>>;
  autoApproval: Scalars["Boolean"];
  breakGlassUsers: Array<GroupBreakGlassUser>;
  commonMetadata: CommonMetadata;
  configTemplate?: Maybe<ConfigurationTemplate>;
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  containingGroups: Array<GroupGroup>;
  createdAt: Scalars["Time"];
  currentUserAccess: CurrentUserGroupAccess;
  customRequestNotification?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  groupBinding?: Maybe<GroupBinding>;
  groupBindingId?: Maybe<Scalars["GroupBindingId"]>;
  /** @deprecated Do not use groupGroups, use containingGroups instead. */
  groupGroups: Array<GroupGroup>;
  groupLeaders: Array<User>;
  groupResources: Array<GroupResource>;
  groupType: GroupType;
  /** @deprecated Use paginatedGroupUsers instead. */
  groupUsers: Array<GroupUser>;
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["GroupId"];
  isDeleted: Scalars["Boolean"];
  isManaged: Scalars["Boolean"];
  isOnCallSynced: Scalars["Boolean"];
  isRequestable: Scalars["Boolean"];
  maxDurationInMinutes?: Maybe<Scalars["Int"]>;
  memberGroups: Array<GroupGroup>;
  name: Scalars["String"];
  numGroupUsers: Scalars["Int"];
  onCallSchedules?: Maybe<Array<GroupOnCallSchedule>>;
  paginatedGroupUsers: PaginatedGroupUsersOutput;
  reasonOptional: Scalars["Boolean"];
  recommendedDurationInMinutes?: Maybe<Scalars["Int"]>;
  remoteId?: Maybe<Scalars["RemoteGroupId"]>;
  remoteName: Scalars["String"];
  requestConfigs: Array<RequestConfiguration>;
  requestTemplate?: Maybe<RequestTemplate>;
  requestTemplateId?: Maybe<Scalars["RequestTemplateId"]>;
  requireMfaToApprove: Scalars["Boolean"];
  requireMfaToRequest: Scalars["Boolean"];
  requireSupportTicket: Scalars["Boolean"];
  reviewerMessageChannel?: Maybe<MessageChannel>;
  reviewerMessageChannelId?: Maybe<Scalars["MessageChannelId"]>;
  reviewerStages: Array<ReviewerStage>;
  riskScore?: Maybe<Scalars["Int"]>;
  serviceType: ServiceType;
  tags: Array<GroupTag>;
  updatedAt: Scalars["Time"];
  visibility: Visibility;
  visibilityGroups: Array<ConfigurationVisibilityGroup>;
};

export type GroupPaginatedGroupUsersArgs = {
  input?: InputMaybe<PaginatedGroupUsersInput>;
};

export type GroupAccessAlreadyReviewedError = Error & {
  __typename?: "GroupAccessAlreadyReviewedError";
  message: Scalars["String"];
};

export type GroupAccessLevel = {
  __typename?: "GroupAccessLevel";
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type GroupAccessLevelInput = {
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type GroupAccessLevelsInput = {
  groupId: Scalars["GroupId"];
  onlyRequestableTargetUser?: InputMaybe<Scalars["UserId"]>;
};

export type GroupAccessLevelsOutput =
  | GroupAccessLevelsResult
  | GroupNotFoundError;

export type GroupAccessLevelsResult = {
  __typename?: "GroupAccessLevelsResult";
  accessLevels?: Maybe<Array<GroupAccessLevel>>;
  groupId: Scalars["GroupId"];
};

export type GroupAccessRequestFrequencyReminderOutOfBoundsError = Error & {
  __typename?: "GroupAccessRequestFrequencyReminderOutOfBoundsError";
  message: Scalars["String"];
};

export type GroupAccessStats = {
  __typename?: "GroupAccessStats";
  groupId: Scalars["GroupId"];
  teamAccessCount?: Maybe<Scalars["Int"]>;
  titleAccessCount?: Maybe<Scalars["Int"]>;
  totalAccessCount?: Maybe<Scalars["Int"]>;
};

export type GroupAlreadyBelongsToBindingError = {
  __typename?: "GroupAlreadyBelongsToBindingError";
  group?: Maybe<Group>;
  groupBinding?: Maybe<GroupBinding>;
  groupBindingId?: Maybe<Scalars["GroupBindingId"]>;
  groupId: Scalars["GroupId"];
  message: Scalars["String"];
};

export type GroupBinding = {
  __typename?: "GroupBinding";
  createdAt: Scalars["Time"];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars["UserId"]>;
  groups: Array<Group>;
  id: Scalars["GroupBindingId"];
  sourceGroup?: Maybe<Group>;
  sourceGroupId: Scalars["GroupId"];
  updatedAt: Scalars["Time"];
};

export type GroupBindingHasNoGroupsError = {
  __typename?: "GroupBindingHasNoGroupsError";
  message: Scalars["String"];
};

export type GroupBindingNotFoundError = {
  __typename?: "GroupBindingNotFoundError";
  message: Scalars["String"];
};

export type GroupBindingOutput = GroupBinding | GroupBindingNotFoundError;

export type GroupBindingsFiltersInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  groupBindingIds?: InputMaybe<Array<Scalars["GroupBindingId"]>>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  groupTypes?: InputMaybe<Array<GroupType>>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type GroupBindingsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<GroupBindingsFiltersInput>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<GroupBindingsSortBy>;
};

export type GroupBindingsOutput = {
  __typename?: "GroupBindingsOutput";
  cursor?: Maybe<Scalars["String"]>;
  groupBindings: Array<GroupBinding>;
  totalNumItems: Scalars["Int"];
};

export type GroupBindingsSortBy = {
  direction: SortDirection;
  field: GroupBindingsSortByField;
};

export enum GroupBindingsSortByField {
  CreatedAt = "CREATED_AT",
  CreatedBy = "CREATED_BY",
  SourceGroupName = "SOURCE_GROUP_NAME",
  SourceGroupType = "SOURCE_GROUP_TYPE",
}

export type GroupBindingSuggestion = {
  __typename?: "GroupBindingSuggestion";
  id: Scalars["GroupBindingSuggestionId"];
  primaryGroup?: Maybe<Group>;
  primaryGroupId: Scalars["GroupId"];
  secondaryGroup?: Maybe<Group>;
  secondaryGroupId: Scalars["GroupId"];
  updatedAt: Scalars["Time"];
};

export type GroupBindingSuggestionNotFoundError = Error & {
  __typename?: "GroupBindingSuggestionNotFoundError";
  message: Scalars["String"];
};

export type GroupBindingSuggestionOutput =
  | GroupBindingSuggestion
  | GroupBindingSuggestionNotFoundError;

export type GroupBindingSuggestionsFiltersInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  groupTypes?: InputMaybe<Array<GroupType>>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type GroupBindingSuggestionsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<GroupBindingSuggestionsFiltersInput>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<GroupBindingSuggestionsSortBy>;
};

export type GroupBindingSuggestionsOutput = {
  __typename?: "GroupBindingSuggestionsOutput";
  cursor?: Maybe<Scalars["String"]>;
  groupBindingSuggestions: Array<GroupBindingSuggestion>;
  totalNumItems: Scalars["Int"];
};

export type GroupBindingSuggestionsSortBy = {
  direction: SortDirection;
  field: GroupBindingSuggestionsSortByField;
};

export enum GroupBindingSuggestionsSortByField {
  PrimaryGroupName = "PRIMARY_GROUP_NAME",
  SecondaryGroupName = "SECONDARY_GROUP_NAME",
  UpdatedAt = "UPDATED_AT",
}

export type GroupBreakGlassUser = {
  __typename?: "GroupBreakGlassUser";
  groupId: Scalars["GroupId"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type GroupGroup = {
  __typename?: "GroupGroup";
  accessLevel: GroupAccessLevel;
  containingGroup?: Maybe<Group>;
  containingGroupId: Scalars["GroupId"];
  id: Scalars["GroupGroupId"];
  memberGroup?: Maybe<Group>;
  memberGroupId: Scalars["GroupId"];
  quickAccess?: Maybe<GroupGroupQuickAccess>;
};

export type GroupGroupAccess = {
  __typename?: "GroupGroupAccess";
  accessLevel: GroupAccessLevel;
  containingGroupId: Scalars["GroupId"];
  /**
   * Describes attributes of `containingGroupId`'s direct access to `memberGroupId`. If unset, this containingGroup does not have
   * direct access to the memberGroup.
   */
  directAccessPoint?: Maybe<GroupGroupAccessPoint>;
  /**
   * Each item in this list describes one indirect path by which `containingGroupId` has access to `memberGroupId`.
   * If empty, this containingGroup does not have indirect access to the memberGroup.
   */
  indirectAccessPointPaths: Array<IndirectGroupGroupAccessPointPath>;
  /**
   * Each item in this list describes one inherited path by which `containingGroupId` has access to `memberGroupId`.
   * If empty, this containingGroup does not have inherited access to the memberGroup.
   */
  inheritedAccessPoints: Array<GroupGroupAccessPoint>;
  /**
   * Describes attributes of `memberGroupId`'s access to `containingGroupId` for the path with the latest expiration.
   * This access might be direct or indirect.
   */
  latestExpiringAccessPoint: GroupGroupAccessPoint;
  memberGroupId: Scalars["GroupId"];
};

export type GroupGroupAccessInput = {
  groupGroupId: Scalars["GroupGroupId"];
};

export type GroupGroupAccessOutput =
  | GroupGroupAccessResult
  | GroupGroupNotFound;

export type GroupGroupAccessPoint = {
  __typename?: "GroupGroupAccessPoint";
  accessLevel: GroupAccessLevel;
  containingGroupId: Scalars["GroupId"];
  containingGroupName: Scalars["String"];
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  expiration?: Maybe<Scalars["Time"]>;
  memberGroupId: Scalars["GroupId"];
  memberGroupName: Scalars["String"];
  memberGroupType: GroupType;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
};

export type GroupGroupAccessResult = {
  __typename?: "GroupGroupAccessResult";
  access: GroupGroupAccess;
};

export type GroupGroupAlreadyExists = Error & {
  __typename?: "GroupGroupAlreadyExists";
  message: Scalars["String"];
};

export type GroupGroupNotFound = Error & {
  __typename?: "GroupGroupNotFound";
  message: Scalars["String"];
};

export type GroupGroupPushTaskResult = {
  __typename?: "GroupGroupPushTaskResult";
  propStatuses: Array<GroupPropagationStatus>;
};

export type GroupGroupQuickAccess = {
  __typename?: "GroupGroupQuickAccess";
  expiration?: Maybe<Scalars["Time"]>;
  hasDirectAccess: Scalars["Boolean"];
  numInheritedAccess: Scalars["Int"];
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
};

export type GroupHasChildrenError = Error & {
  __typename?: "GroupHasChildrenError";
  message: Scalars["String"];
};

export type GroupInput = {
  id?: InputMaybe<Scalars["GroupId"]>;
};

export type GroupMaxDurationTooLargeError = Error & {
  __typename?: "GroupMaxDurationTooLargeError";
  message: Scalars["String"];
};

export type GroupMetadataInput = {
  onCallMetadata?: InputMaybe<OnCallMetadataInput>;
};

export type GroupMustForfeitOwnershipError = Error & {
  __typename?: "GroupMustForfeitOwnershipError";
  entities: Array<ErrorEntity>;
  message: Scalars["String"];
};

export type GroupNestingNotAllowedError = Error & {
  __typename?: "GroupNestingNotAllowedError";
  fromGroup?: Maybe<Group>;
  fromGroupId: Scalars["GroupId"];
  message: Scalars["String"];
  toGroup?: Maybe<Group>;
  toGroupId: Scalars["GroupId"];
};

export type GroupNotFoundError = Error & {
  __typename?: "GroupNotFoundError";
  message: Scalars["String"];
};

export type GroupOnCallSchedule = {
  __typename?: "GroupOnCallSchedule";
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  onCallSchedule: OnCallSchedule;
  priority: Scalars["Int"];
};

export type GroupOnCallSchedulePriorityError = Error & {
  __typename?: "GroupOnCallSchedulePriorityError";
  message: Scalars["String"];
};

export type GroupOnCallSchedulesOutput = GroupOnCallSchedulesResult;

export type GroupOnCallSchedulesResult = {
  __typename?: "GroupOnCallSchedulesResult";
  groupOnCallSchedules: Array<GroupOnCallSchedule>;
};

export type GroupOutput = GroupNotFoundError | GroupResult;

export type GroupPropagationStatus = {
  __typename?: "GroupPropagationStatus";
  errorMessage?: Maybe<Scalars["String"]>;
  groupId: Scalars["GroupId"];
  lastSynced: Scalars["Time"];
  statusCode: PropagationStatusCode;
  taskType: PropagationTaskType;
  ticketPropagation?: Maybe<GroupUserTicketPropagation>;
  userId: Scalars["UserId"];
};

export type GroupRequestConfigurationOutput = {
  __typename?: "GroupRequestConfigurationOutput";
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  groupId: Scalars["GroupId"];
  requestConfiguration: RequestConfiguration;
};

export type GroupRequestStatusCountsInput = {
  groupId: Scalars["GroupId"];
};

export type GroupRequestStatusCountsOutput =
  | GroupNotFoundError
  | GroupRequestStatusCountsResult;

export type GroupRequestStatusCountsResult = {
  __typename?: "GroupRequestStatusCountsResult";
  requestStatusCounts: RequestStatusCounts;
};

export type GroupResource = {
  __typename?: "GroupResource";
  access: GroupResourceAccess;
  accessLevel: ResourceAccessLevel;
  durationInMinutes?: Maybe<Scalars["Int"]>;
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  lastUsedAt?: Maybe<Scalars["Time"]>;
  propagationStatus?: Maybe<GroupResourcePropagationStatus>;
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  source: GroupResourceSource;
};

export type GroupResourceAccess = {
  __typename?: "GroupResourceAccess";
  accessLevel: ResourceAccessLevel;
  /**
   * Describes attributes of `groupId`'s direct access to `resourceId`. If unset, this group does not have
   * direct access to the resource.
   */
  directAccessPoint?: Maybe<GroupResourceAccessPoint>;
  groupId: Scalars["GroupId"];
  /**
   * Each item in this list describes one indirect path by which `groupId` has access to `resourceId`.
   * If empty, this group does not have indirect access to the resource.
   */
  indirectAccessPointPaths: Array<IndirectGroupResourceAccessPointPath>;
  /**
   * Each item in this list describes one inherited path by which `groupId` has access to `resourceId`.
   * If empty, this group does not have inherited access to the resource.
   */
  inheritedAccessPoints: Array<GroupResourceAccessPoint>;
  /**
   * Describes attributes of `resourceId`'s access to `groupId` for the path with the latest expiration.
   * This access might be direct or indirect.
   */
  latestExpiringAccessPoint: GroupResourceAccessPoint;
  resourceId: Scalars["ResourceId"];
};

export type GroupResourceAccessPoint = {
  __typename?: "GroupResourceAccessPoint";
  accessLevel: ResourceAccessLevel;
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  expiration?: Maybe<Scalars["Time"]>;
  groupId: Scalars["GroupId"];
  groupName: Scalars["String"];
  resourceId: Scalars["ResourceId"];
  resourceName: Scalars["String"];
  resourceType: ResourceType;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
};

export type GroupResourceAlreadyExists = Error & {
  __typename?: "GroupResourceAlreadyExists";
  message: Scalars["String"];
};

export type GroupResourceEdge = {
  __typename?: "GroupResourceEdge";
  accessLevel: ResourceAccessLevel;
  groupId: Scalars["GroupId"];
  metadata?: Maybe<EdgeMetadata>;
  resourceId: Scalars["ResourceId"];
};

export type GroupResourcePropagationStatus = {
  __typename?: "GroupResourcePropagationStatus";
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
  errorMessage?: Maybe<Scalars["String"]>;
  groupId: Scalars["GroupId"];
  lastSynced: Scalars["Time"];
  resourceId: Scalars["ResourceId"];
  statusCode: PropagationStatusCode;
  taskType: PropagationTaskType;
};

export type GroupResourcePushTaskResult = {
  __typename?: "GroupResourcePushTaskResult";
  propStatuses: Array<GroupResourcePropagationStatus>;
};

export enum GroupResourceSource {
  /** GroupResource that's inherited from another remote resource */
  Inherited = "INHERITED",
  Regular = "REGULAR",
}

export type GroupResult = {
  __typename?: "GroupResult";
  group: Group;
};

export type GroupsInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  connectionType?: InputMaybe<ConnectionType>;
  cursor?: InputMaybe<Scalars["String"]>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  groupType?: InputMaybe<GroupType>;
  leaderOfGroupsOnly?: InputMaybe<Scalars["Boolean"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<ServiceType>;
  tag?: InputMaybe<TagFilter>;
  unmanagedOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type GroupSmall = {
  __typename?: "GroupSmall";
  id: Scalars["GroupId"];
  name: Scalars["String"];
};

export type GroupsOutput = GroupsResult;

export type GroupsResult = {
  __typename?: "GroupsResult";
  cursor?: Maybe<Scalars["String"]>;
  groups: Array<Group>;
  totalNumGroups: Scalars["Int"];
};

export type GroupSuggestion = {
  __typename?: "GroupSuggestion";
  groupId: Scalars["GroupId"];
  groupType: GroupType;
  name: Scalars["String"];
};

export type GroupTag = {
  __typename?: "GroupTag";
  createdAt: Scalars["Time"];
  creatorUserId: Scalars["UserId"];
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  source: ServiceType;
  tag?: Maybe<Tag>;
  tagId: Scalars["TagId"];
  updatedAt: Scalars["Time"];
};

export type GroupTagKeyExistsError = Error & {
  __typename?: "GroupTagKeyExistsError";
  message: Scalars["String"];
};

export enum GroupType {
  ActiveDirectoryGroup = "ACTIVE_DIRECTORY_GROUP",
  AwsSsoGroup = "AWS_SSO_GROUP",
  AzureAdMicrosoft_365Group = "AZURE_AD_MICROSOFT_365_GROUP",
  AzureAdSecurityGroup = "AZURE_AD_SECURITY_GROUP",
  ConnectorGroup = "CONNECTOR_GROUP",
  DuoGroup = "DUO_GROUP",
  GitHubTeam = "GIT_HUB_TEAM",
  GitLabGroup = "GIT_LAB_GROUP",
  GoogleGroupsGkeGroup = "GOOGLE_GROUPS_GKE_GROUP",
  GoogleGroupsGroup = "GOOGLE_GROUPS_GROUP",
  LdapGroup = "LDAP_GROUP",
  OktaGroup = "OKTA_GROUP",
  OpalGroup = "OPAL_GROUP",
  SnowflakeRole = "SNOWFLAKE_ROLE",
  TailscaleGroup = "TAILSCALE_GROUP",
  WorkdayUserSecurityGroup = "WORKDAY_USER_SECURITY_GROUP",
}

export type GroupTypesFilterInput = {
  groupTypes: Array<GroupType>;
};

export type GroupTypesWithCountsInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  query?: InputMaybe<Scalars["String"]>;
};

export type GroupTypesWithCountsOutput = GroupTypesWithCountsResult;

export type GroupTypesWithCountsResult = {
  __typename?: "GroupTypesWithCountsResult";
  groupTypesWithCounts: Array<GroupTypeWithCount>;
};

export type GroupTypeWithCount = {
  __typename?: "GroupTypeWithCount";
  connection?: Maybe<Connection>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  groupType: GroupType;
  numGroups: Scalars["Int"];
};

export type GroupUser = {
  __typename?: "GroupUser";
  access?: Maybe<GroupUserAccess>;
  accessLevel?: Maybe<GroupAccessLevel>;
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  lastUsedAt?: Maybe<Scalars["Time"]>;
  propagationStatus?: Maybe<GroupPropagationStatus>;
  provisionSource: GroupUserProvisionSource;
  source: GroupUserSource;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type GroupUserAccess = {
  __typename?: "GroupUserAccess";
  /**
   * Describes attributes of `userId`'s direct access to `groupId`. If unset, this user does not have
   * direct access to the group.
   */
  directAccessPoint?: Maybe<GroupUserAccessPoint>;
  groupId: Scalars["GroupId"];
  /**
   * Each item in this list describes one indirect path by which `userId` has access to `groupId`.
   * If empty, this user does not have indirect access to the group.
   */
  indirectAccessPointPaths: Array<IndirectGroupUserAccessPointPath>;
  isGroupManaged: Scalars["Boolean"];
  /**
   * Describes attributes of `userId`'s access to `groupId` for the path with the latest expiration.
   * This access might be direct or indirect.
   */
  latestExpiringAccessPoint: GroupUserAccessPoint;
  userId: Scalars["UserId"];
};

export type GroupUserAccessPoint = {
  __typename?: "GroupUserAccessPoint";
  accessLevel?: Maybe<GroupAccessLevel>;
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  expiration?: Maybe<Scalars["Time"]>;
  groupId: Scalars["GroupId"];
  groupName: Scalars["String"];
  groupType: GroupType;
  source: GroupUserSource;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
  userId: Scalars["UserId"];
};

export type GroupUserAlreadyExists = Error & {
  __typename?: "GroupUserAlreadyExists";
  message: Scalars["String"];
};

export type GroupUserFiltersInput = {
  accessLevelRemoteIds?: InputMaybe<Array<Scalars["AccessLevelRemoteId"]>>;
  propagationStatuses?: InputMaybe<Array<PropagationStatusCode>>;
  userFullName?: InputMaybe<Scalars["String"]>;
};

export enum GroupUserProvisionSource {
  External = "EXTERNAL",
  Opal = "OPAL",
  Unknown = "UNKNOWN",
}

export type GroupUserPushTaskResult = {
  __typename?: "GroupUserPushTaskResult";
  propStatuses: Array<GroupPropagationStatus>;
};

export enum GroupUserSortByField {
  AccessLevelName = "ACCESS_LEVEL_NAME",
  ExpiresAt = "EXPIRES_AT",
  PropagationStatus = "PROPAGATION_STATUS",
  SourceOfAccess = "SOURCE_OF_ACCESS",
  UserEmail = "USER_EMAIL",
  UserFullName = "USER_FULL_NAME",
}

export enum GroupUserSource {
  BreakGlass = "BREAK_GLASS",
  OnCall = "ON_CALL",
  Regular = "REGULAR",
  /** Regular GroupUser derived from a containing group in Opal. */
  RegularIndirect = "REGULAR_INDIRECT",
  /** Regular GroupUser that's imported from a nested group. */
  RegularNested = "REGULAR_NESTED",
}

export type GroupUserTicketPropagation = {
  __typename?: "GroupUserTicketPropagation";
  resourceTickets: Array<ResourceTicketPropagation>;
};

/** User status pulled from an HR/IDP provider. */
export enum HrIdpStatus {
  /** User is a regular, active user. */
  Active = "ACTIVE",
  /**
   * User is indefinitely deleted (usually because they left the company). Their access
   * should be deleted.
   */
  Deleted = "DELETED",
  /** User is deprovisioned (usually because they left the company). Their access should be deleted. */
  Deprovisioned = "DEPROVISIONED",
  /** User does not exist in the HR/IDP system. */
  NotFound = "NOT_FOUND",
  /**
   * User is temporarily suspended (e.g. due to leave of absence). Their access should be
   * frozen but not deleted.
   */
  Suspended = "SUSPENDED",
}

export type HttpsHostnameRequiredError = Error & {
  __typename?: "HttpsHostnameRequiredError";
  message: Scalars["String"];
};

export type IdpConnection = {
  __typename?: "IdpConnection";
  customAttributesAllowlist: Array<Scalars["String"]>;
  id: Scalars["IdpConnectionId"];
  idpConnectionType: IdpConnectionType;
  isDeleted: Scalars["Boolean"];
  userAttributeImportMappings: Array<IdpConnectionUserAttributeImportMapping>;
};

export type IdpConnectionExistsError = Error & {
  __typename?: "IdpConnectionExistsError";
  message: Scalars["String"];
};

export type IdpConnectionMetadataInput = {
  google?: InputMaybe<GoogleIdpConnectionMetadataInput>;
};

export type IdpConnectionNotFoundError = Error & {
  __typename?: "IdpConnectionNotFoundError";
  message: Scalars["String"];
};

export type IdpConnectionOutput =
  | IdpConnectionNotFoundError
  | IdpConnectionResult;

export type IdpConnectionResult = {
  __typename?: "IdpConnectionResult";
  idpConnection?: Maybe<IdpConnection>;
};

export enum IdpConnectionType {
  AzureAd = "AZURE_AD",
  Google = "GOOGLE",
  Okta = "OKTA",
  Workday = "WORKDAY",
}

export type IdpConnectionUserAttributeImportMapping = {
  __typename?: "IdpConnectionUserAttributeImportMapping";
  id: Scalars["IdpConnectionUserAttributeImportMappingId"];
  key: Scalars["String"];
  useAs: IdpConnectionUserAttributeUseAs;
};

export type IdpConnectionUserAttributeImportMappingNotFoundError = Error & {
  __typename?: "IdpConnectionUserAttributeImportMappingNotFoundError";
  message: Scalars["String"];
};

export enum IdpConnectionUserAttributeUseAs {
  Custom = "CUSTOM",
  Email = "EMAIL",
  FirstName = "FIRST_NAME",
  GithubUsername = "GITHUB_USERNAME",
  LastName = "LAST_NAME",
  ManagerEmail = "MANAGER_EMAIL",
  ProfileUrl = "PROFILE_URL",
  SecondaryEmail = "SECONDARY_EMAIL",
  Team = "TEAM",
  TeleportUsername = "TELEPORT_USERNAME",
  Title = "TITLE",
}

export type IdpStatusFilter = {
  statuses?: InputMaybe<Array<HrIdpStatus>>;
  statusUnset?: InputMaybe<Scalars["Boolean"]>;
};

export type ImpersonationAccessLevelMetadata = {
  __typename?: "ImpersonationAccessLevelMetadata";
  avatarUrl: Scalars["String"];
};

export type ImpersonationSessionLengthError = Error & {
  __typename?: "ImpersonationSessionLengthError";
  message: Scalars["String"];
};

export type ImportAllInput = {
  connectionId: Scalars["ConnectionId"];
};

export type ImportAllOutput =
  | BulkUpdateItemsResult
  | GroupNotFoundError
  | ResourceNotFoundError;

export type ImportCustomResourceInfo = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  /**
   * List of all users with some sort of access to the resource. Any users in `userEmails`
   * that aren't in this list will have their access removed.
   */
  usersWithAccess: Array<ImportCustomResourceUserInfo>;
};

export type ImportCustomResourcesDryRunOutput =
  | ImportCustomResourcesDryRunResult
  | ImportCustomResourcesInputValidationError;

export type ImportCustomResourcesDryRunResult = {
  __typename?: "ImportCustomResourcesDryRunResult";
  /** List of names of existing resources */
  existingResources: Array<Scalars["String"]>;
  /** List of names of resources to create */
  resourcesToCreate: Array<Scalars["String"]>;
};

export type ImportCustomResourcesInput = {
  adminOwnerId: Scalars["OwnerId"];
  connectionId: Scalars["ConnectionId"];
  filename?: InputMaybe<Scalars["String"]>;
  /**
   * List of all resources that we're importing permissions for. Resources in this list that
   * don't exist for the connection will be created.
   */
  resources: Array<ImportCustomResourceInfo>;
  /** List of all user emails that we're importing permissions for. Resource users */
  userEmails: Array<Scalars["String"]>;
  visibility: Visibility;
};

export type ImportCustomResourcesInputValidationError = Error & {
  __typename?: "ImportCustomResourcesInputValidationError";
  message: Scalars["String"];
  reasons: Array<Scalars["String"]>;
};

export type ImportCustomResourcesOutput =
  | ImportCustomResourcesInputValidationError
  | ImportCustomResourcesResult;

export type ImportCustomResourcesResult = {
  __typename?: "ImportCustomResourcesResult";
  success: Scalars["Boolean"];
};

export type ImportCustomResourceUserInfo = {
  /**
   * Describes the nature of this user's access to the resource. Currently, since import
   * functionality is only implemented for resources with no access levels, only 'Y' is
   * permitted.
   * (TODO: we can support import functionality for resources with access levels by
   * allowing other descriptors)
   */
  cellContent: Scalars["String"];
  email: Scalars["String"];
};

export type ImportRemoteGroupInfo = {
  connectionId: Scalars["ConnectionId"];
  groupType: GroupType;
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  remoteId: Scalars["RemoteGroupId"];
  serviceType: ServiceType;
};

export type ImportRemoteResourceInfo = {
  connectionId: Scalars["ConnectionId"];
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  parentResourceId?: InputMaybe<Scalars["ResourceId"]>;
  remoteId: Scalars["RemoteResourceId"];
  resourceType: ResourceType;
  serviceType: ServiceType;
};

export enum ImportSetting {
  All = "ALL",
  Manual = "MANUAL",
  None = "NONE",
  Tagged = "TAGGED",
}

export type IncompleteAccessReviewer = {
  __typename?: "IncompleteAccessReviewer";
  incompleteConnections: Scalars["Int"];
  incompleteGroups: Scalars["Int"];
  incompleteResources: Scalars["Int"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type IndirectGroupAccessPoint = {
  __typename?: "IndirectGroupAccessPoint";
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  groupId: Scalars["GroupId"];
  groupName: Scalars["String"];
  groupType: GroupType;
};

export type IndirectGroupGroupAccessPointPath = {
  __typename?: "IndirectGroupGroupAccessPointPath";
  accessLevel: GroupAccessLevel;
  /** The final group in `indirectAccessPointPath`. This group has direct access to `memberGroupId`. */
  endGroupId: Scalars["GroupId"];
  /** The path of groups leading from `startGroupId` to `endGroupId`, inclusive. */
  indirectAccessPointPath: Array<IndirectGroupAccessPoint>;
  /**
   * Describes attributes of `startGroupId`'s indirect access to `endGroupId`. We call this "resolved"
   * because these attributes are computed based on following the path from start to end.
   */
  resolvedAccessPoint: GroupGroupAccessPoint;
  /**
   * The first group in `indirectAccessPointPath`. This is the first group in the chain that has
   * access to `resourceId`.
   */
  startGroupId: Scalars["GroupId"];
};

export type IndirectGroupResourceAccessPointPath = {
  __typename?: "IndirectGroupResourceAccessPointPath";
  accessLevel: ResourceAccessLevel;
  /** The final group in `indirectAccessPointPath`. This group has direct access to `resourceId`. */
  endGroupId: Scalars["GroupId"];
  /** The path of groups leading from `startGroupId` to `endGroupId`, inclusive. */
  indirectAccessPointPath?: Maybe<Array<IndirectGroupAccessPoint>>;
  /**
   * Describes attributes of `startGroupId`'s indirect access to `endGroupId`. We call this "resolved"
   * because these attributes are computed based on following the path from start to end.
   */
  resolvedAccessPoint?: Maybe<GroupResourceAccessPoint>;
  resourceId: Scalars["ResourceId"];
  /**
   * The first group in `indirectAccessPointPath`. This is the first group in the chain that has
   * access to `resourceId`.
   */
  startGroupId: Scalars["GroupId"];
};

export type IndirectGroupUserAccessPointPath = {
  __typename?: "IndirectGroupUserAccessPointPath";
  /**
   * The final group in `indirectAccessPointPath`. This is at the end of the chain of groups that
   * `userId` has access to.
   */
  endGroupId: Scalars["GroupId"];
  /** The path of groups leading from `startGroupId` to `endGroupId`, inclusive. */
  indirectAccessPointPath: Array<IndirectGroupAccessPoint>;
  /**
   * Describes attributes of `userId`'s indirect access to `endGroupId`. We call this "resolved"
   * because these attributes are computed based on following the path from start to end.
   */
  resolvedAccessPoint: GroupUserAccessPoint;
  /**
   * The first group in `indirectAccessPointPath`. This is also the group that `userId` has direct
   * access to.
   */
  startGroupId: Scalars["GroupId"];
  userId: Scalars["UserId"];
};

export type InitOidcAuthFlowInput = {
  oidcProviderType: OidcProviderType;
  state: Scalars["String"];
};

export type InitOidcAuthFlowOutput =
  | InitOidcAuthFlowResult
  | OidcProviderNotFoundError;

export type InitOidcAuthFlowResult = {
  __typename?: "InitOidcAuthFlowResult";
  authorizeUrl: Scalars["String"];
};

export type InitThirdPartyIntegrationAuthFlowInput = {
  clientInfo?: InputMaybe<ThirdPartyClientInput>;
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  orgName?: InputMaybe<Scalars["String"]>;
  thirdPartyIntegrationType: IntegrationType;
  thirdPartyProvider: ThirdPartyProvider;
};

export type InitThirdPartyIntegrationAuthFlowOutput =
  | ConnectionNotFoundError
  | InitThirdPartyIntegrationAuthFlowResult
  | ThirdPartyIntegrationNotFoundError;

export type InitThirdPartyIntegrationAuthFlowResult = {
  __typename?: "InitThirdPartyIntegrationAuthFlowResult";
  url: Scalars["String"];
};

export type InitThirdPartyIntegrationLinkAuthFlowOutput = InitThirdPartyIntegrationLinkAuthFlowResult;

export type InitThirdPartyIntegrationLinkAuthFlowResult = {
  __typename?: "InitThirdPartyIntegrationLinkAuthFlowResult";
  linkToken: Scalars["String"];
};

export enum IntegrationType {
  Connection = "CONNECTION",
  Org = "ORG",
  User = "USER",
}

export type InvalidEmailError = Error & {
  __typename?: "InvalidEmailError";
  message: Scalars["String"];
};

export type InvalidEventFilterNameError = Error & {
  __typename?: "InvalidEventFilterNameError";
  message: Scalars["String"];
};

export type InvalidObjectIdFilterError = Error & {
  __typename?: "InvalidObjectIDFilterError";
  message: Scalars["String"];
};

export type InvalidReviewerSettingsError = Error & {
  __typename?: "InvalidReviewerSettingsError";
  message: Scalars["String"];
};

export type InvalidSyncTypeError = Error & {
  __typename?: "InvalidSyncTypeError";
  message: Scalars["String"];
};

export type InvalidUpdateGroupVisibilityGroupError = Error & {
  __typename?: "InvalidUpdateGroupVisibilityGroupError";
  message: Scalars["String"];
};

export type InvalidUpdateResourceVisibilityGroupError = Error & {
  __typename?: "InvalidUpdateResourceVisibilityGroupError";
  message: Scalars["String"];
};

export type InviteUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  role: UserProductRole;
  teamId?: InputMaybe<Scalars["GroupId"]>;
};

export type InviteUsersInput = {
  invites: Array<InviteUserInput>;
};

export type InviteUsersOutput =
  | InviteUsersResult
  | UserAlreadyExistsInAnotherOrg;

export type InviteUsersResult = {
  __typename?: "InviteUsersResult";
  invitedUsers: Array<User>;
};

export type ItemAccessAlreadyReviewedError = Error & {
  __typename?: "ItemAccessAlreadyReviewedError";
  message: Scalars["String"];
};

export type ItemCannotBeRequestedError = Error & {
  __typename?: "ItemCannotBeRequestedError";
  message: Scalars["String"];
};

export type JiraMetadataInput = {
  email: Scalars["String"];
  orgUrl: Scalars["String"];
  projectName: Scalars["String"];
};

export type LdapConnectionCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUserDistinguishedName: Scalars["String"];
};

export type LdapConnectionMetadata = {
  __typename?: "LDAPConnectionMetadata";
  groupsDistinguishedName: Scalars["String"];
  groupsUidKey: Scalars["String"];
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  usersDistinguishedName: Scalars["String"];
};

export type LdapConnectionMetadataInput = {
  groupsDistinguishedName: Scalars["String"];
  groupsUidKey: Scalars["String"];
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  usersDistinguishedName: Scalars["String"];
};

export type LeftSidebarRoutes = {
  __typename?: "LeftSidebarRoutes";
  hasOwners: Scalars["Boolean"];
  isGroupLeader: Scalars["Boolean"];
};

export type LineGraphData = {
  __typename?: "LineGraphData";
  timestamp: Scalars["Time"];
  value: Scalars["Int"];
};

export type LinkExistingRemoteMessageChannelInput = {
  groupId?: InputMaybe<Scalars["GroupId"]>;
  remoteChannelId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
};

export type LinkExistingRemoteMessageChannelOutput =
  | LinkExistingRemoteMessageChannelResult
  | MessageChannelMissingOAuthScopeError
  | RemoteMessageChannelAlreadyLinked
  | RemoteMessageChannelNotFound;

export type LinkExistingRemoteMessageChannelResult = {
  __typename?: "LinkExistingRemoteMessageChannelResult";
  messageChannel: MessageChannel;
};

export type ListEventStreamsOutput = ListEventStreamsResult;

export type ListEventStreamsResult = {
  __typename?: "ListEventStreamsResult";
  eventStreams: Array<EventStream>;
};

export type ListFactorsOutput =
  | ConnectionUserNotFound
  | ListFactorsResult
  | MissingTokenPermissionError
  | NoIdpConfiguredError;

export type ListFactorsResult = {
  __typename?: "ListFactorsResult";
  factors: Array<Factor>;
};

export type LogEntityRemediationInput = {
  entityKey: RecommendationsEntityKey;
  jitCount: Scalars["Int"];
  jitCountFromSuggestions: Scalars["Int"];
  remediationType: RecommendationsSubscoreType;
  revokeCount: Scalars["Int"];
  revokeCountFromSuggestions: Scalars["Int"];
  totalSuggestionsCount: Scalars["Int"];
};

export type LogEntityRemediationOutput = {
  __typename?: "LogEntityRemediationOutput";
  success: Scalars["Boolean"];
};

export type LongTextValue = {
  __typename?: "LongTextValue";
  value: Scalars["String"];
};

export type LongTextValueInput = {
  value: Scalars["String"];
};

export type ManagedHrDataIsImmutableError = Error & {
  __typename?: "ManagedHRDataIsImmutableError";
  message: Scalars["String"];
};

export type ManagerFilter = {
  managerId?: InputMaybe<Scalars["UserId"]>;
  managerUnset?: InputMaybe<Scalars["Boolean"]>;
};

export type ManagerHasAlreadyApprovedError = Error & {
  __typename?: "ManagerHasAlreadyApprovedError";
  message: Scalars["String"];
};

export type MessageChannel = {
  __typename?: "MessageChannel";
  id: Scalars["MessageChannelId"];
  isPrivate: Scalars["Boolean"];
  name: Scalars["String"];
  remoteId: Scalars["MessageChannelRemoteId"];
  thirdPartyProvider: ThirdPartyProvider;
};

export type MessageChannelInvalidNameError = Error & {
  __typename?: "MessageChannelInvalidNameError";
  message: Scalars["String"];
};

export type MessageChannelMissingOAuthScopeError = Error & {
  __typename?: "MessageChannelMissingOAuthScopeError";
  message: Scalars["String"];
};

export type MessageChannelNameTakenError = Error & {
  __typename?: "MessageChannelNameTakenError";
  message: Scalars["String"];
};

export type MessageChannelNotFoundError = Error & {
  __typename?: "MessageChannelNotFoundError";
  message: Scalars["String"];
};

export type MessageChannelRateLimitedError = Error & {
  __typename?: "MessageChannelRateLimitedError";
  message: Scalars["String"];
};

export type MessageChannelsInput = {
  generateChannelName?: InputMaybe<GenerateChannelNameInput>;
};

export type MessageChannelsOutput =
  | MessageChannelMissingOAuthScopeError
  | MessageChannelRateLimitedError
  | MessageChannelsResult;

export type MessageChannelsResult = {
  __typename?: "MessageChannelsResult";
  generatedChannelName?: Maybe<Scalars["String"]>;
  messageChannels: Array<MessageChannel>;
};

export enum MessageChannelType {
  Audit = "AUDIT",
  Monitor = "MONITOR",
  Reviewer = "REVIEWER",
}

export type MessageChannelUserNotInWorkspaceError = Error & {
  __typename?: "MessageChannelUserNotInWorkspaceError";
  message: Scalars["String"];
};

export type MetricsInput = {
  bucketSize?: InputMaybe<TimeBucket>;
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  endDate?: InputMaybe<Scalars["Time"]>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  resourceIds?: InputMaybe<Array<Scalars["ResourceId"]>>;
  startDate: Scalars["Time"];
  tagIds?: InputMaybe<Array<Scalars["TagId"]>>;
  timezone: Scalars["String"];
};

export type MfaCallbackInput = {
  code: Scalars["String"];
  state: Scalars["String"];
};

export type MfaCallbackOutput = MfaCallbackResult;

export type MfaCallbackResult = {
  __typename?: "MfaCallbackResult";
  mfaTime?: Maybe<Scalars["Time"]>;
};

export type MfaInvalidError = Error & {
  __typename?: "MfaInvalidError";
  message: Scalars["String"];
};

export type MissingIamPermissionError = Error & {
  __typename?: "MissingIAMPermissionError";
  message: Scalars["String"];
};

export type MissingTokenPermissionError = Error & {
  __typename?: "MissingTokenPermissionError";
  message: Scalars["String"];
};

export type MongoAtlasConnectionCredentialsInput = {
  privateKey: Scalars["String"];
  projectID: Scalars["String"];
  publicKey: Scalars["String"];
};

export type MongoAtlasConnectionMetadata = {
  __typename?: "MongoAtlasConnectionMetadata";
  serverHostname: Scalars["String"];
};

export type MongoAtlasConnectionMetadataInput = {
  serverHostname: Scalars["String"];
};

export type MongoConnectionCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUserName: Scalars["String"];
};

export type MongoConnectionMetadata = {
  __typename?: "MongoConnectionMetadata";
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type MongoConnectionMetadataInput = {
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type MostRequestedItemsResult = {
  __typename?: "MostRequestedItemsResult";
  barData: Array<BarGraphData>;
};

export type MultiChoiceOption = {
  __typename?: "MultiChoiceOption";
  label: Scalars["String"];
  value: Scalars["String"];
};

export type MultiChoiceOptionInput = {
  label: Scalars["String"];
  value: Scalars["String"];
};

export type MultiChoiceValue = {
  __typename?: "MultiChoiceValue";
  value: Scalars["String"];
};

export type MultiChoiceValueInput = {
  value: Scalars["String"];
};

export type MultiGroupSelection = {
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  groupType: GroupType;
};

export type MultipleGroupAccessLevelsInput = {
  groupIds: Array<Scalars["GroupId"]>;
  onlyRequestableTargetUser?: InputMaybe<Scalars["UserId"]>;
};

export type MultipleGroupAccessLevelsOutput =
  | GroupNotFoundError
  | MultipleGroupAccessLevelsResult;

export type MultipleGroupAccessLevelsResult = {
  __typename?: "MultipleGroupAccessLevelsResult";
  results: Array<GroupAccessLevelsResult>;
};

export type MultipleResourceAccessLevelsInput = {
  ignoreRemoteAccessLevels?: Scalars["Boolean"];
  onlyRequestableTargetUser?: InputMaybe<Scalars["UserId"]>;
  resourceIds: Array<Scalars["ResourceId"]>;
};

export type MultipleResourceAccessLevelsOutput =
  | MultipleResourceAccessLevelsResult
  | ResourceNotFoundError;

export type MultipleResourceAccessLevelsResult = {
  __typename?: "MultipleResourceAccessLevelsResult";
  results: Array<ResourceAccessLevelsResult>;
};

export type MultiResourceSelection = {
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  parentResourceId?: InputMaybe<ParentResourceInput>;
  resourceType: ResourceType;
};

export type Mutation = {
  __typename?: "Mutation";
  addBigData: AddBigDataOutput;
  addConnectionUsers: AddConnectionUsersOutput;
  addGroupGroups: AddGroupGroupsOutput;
  addGroupResources: AddGroupResourcesOutput;
  addGroupTags: AddGroupTagsOutput;
  addGroupUsers: AddGroupUsersOutput;
  addResourceTags: AddResourceTagsOutput;
  addResourceUsers: AddResourceUsersOutput;
  addRoleAssignments: AddRoleAssignmentsOutput;
  addUserTags: AddUserTagsOutput;
  approveRequest: ApproveRequestOutput;
  assumeImpersonation: AssumeImpersonationOutput;
  authCodeCallback: AuthCodeCallbackOutput;
  bulkImportRemoteItems: BulkImportRemoteItemsOutput;
  bulkUpdateItems: BulkUpdateItemsOutput;
  cancelRequest: CancelRequestOutput;
  clearResourceUserPropagationStatus: ClearResourceUserPropagationStatusOutput;
  confirmEndSystemRevocation: ConfirmEndSystemRevocationOutput;
  createAuthProviderApplication: CreateAuthProviderApplicationOutput;
  createBundle: CreateBundleOutput;
  createBundleGroups: CreateBundleGroupsOutput;
  createBundleResources: CreateBundleResourcesOutput;
  createConfigurationTemplate: CreateConfigurationTemplateOutput;
  createConnection: CreateConnectionOutput;
  createConnectionForIdp: CreateIdpConnectionOutput;
  createEventFilter: CreateEventFilterOutput;
  createEventStream: CreateEventStreamOutput;
  createFirstPartyToken: CreateFirstPartyTokenOutput;
  createGroup: CreateGroupOutput;
  createGroupBindings: CreateGroupBindingsOutput;
  createHRISProviderKey: CreateHrisProviderKeyOutput;
  createIdpConnection: CreateIdpConnectionOutput;
  createIdpConnectionUserAttributeImportMapping: CreateIdpConnectionUserAttributeImportMappingOutput;
  createOpalScopedRole?: Maybe<CreateOpalScopedRoleOutput>;
  createOrganization: CreateOrganizationOutput;
  createOrUpdateOidcProvider: CreateOrUpdateOidcProviderOutput;
  createOwner: CreateOwnerOutput;
  createOwners: CreateOwnersOutput;
  createRemoteMessageChannel: CreateRemoteMessageChannelOutput;
  createRequest: CreateRequestOutput;
  createRequestComment: CreateRequestCommentOutput;
  createRequestProposal: CreateRequestProposalOutput;
  createRequestTemplate: CreateRequestTemplateOutput;
  createResource: CreateResourceOutput;
  createResourceCustomAccessLevel: CreateResourceCustomAccessLevelOutput;
  createResourceCustomAccessLevels: CreateResourceCustomAccessLevelsOutput;
  createResources: CreateResourcesOutput;
  createSamlConnection: CreateSamlConnectionOutput;
  createSamlConnectionProxy: CreateSamlConnectionProxyOutput;
  createSession: CreateSessionOutput;
  createTag: CreateTagOutput;
  createThirdPartyIntegration: CreateThirdPartyIntegrationOutput;
  createWebhookInfo: CreateWebhookInfoOutput;
  deleteAccessReviewTemplates: DeleteAccessReviewTemplatesOutput;
  deleteBundle: DeleteBundleOutput;
  deleteBundleGroups: Scalars["Boolean"];
  deleteBundleResources: Scalars["Boolean"];
  deleteConfigurationTemplate: DeleteConfigurationTemplateOutput;
  deleteConnection: DeleteConnectionOutput;
  deleteEventFilter: DeleteEventFilterOutput;
  deleteFirstPartyTokens: DeleteFirstPartyTokensOutput;
  deleteGroup: DeleteGroupOutput;
  deleteGroupBindings: DeleteGroupBindingsOutput;
  deleteGroups: DeleteGroupsOutput;
  deleteGroupUserPropStatus: DeleteGroupUserPropStatusResult;
  deleteIdpConnection: DeleteIdpConnectionOutput;
  deleteIdpConnectionUserAttributeImportMapping: DeleteIdpConnectionUserAttributeImportMappingOutput;
  deleteOrganization: DeleteOrganizationOutput;
  deleteOwner: DeleteOwnerOutput;
  deleteOwners: DeleteOwnersOutput;
  deleteRequestTemplate: DeleteRequestTemplateOutput;
  deleteResourceCustomAccessLevels: DeleteResourceCustomAccessLevelsOutput;
  deleteResources: DeleteResourcesOutput;
  deleteSamlConnection: DeleteSamlConnectionOutput;
  deleteSamlConnectionProxy: DeleteSamlConnectionProxyOutput;
  deleteTags: DeleteTagsOutput;
  deleteThirdPartyIntegration: DeleteThirdPartyIntegrationOutput;
  deleteUser: DeleteUserOutput;
  deleteWebhookInfo: DeleteWebhookInfoOutput;
  denyRequest: DenyRequestOutput;
  dismissGroupBindingSuggestions: DismissGroupBindingSuggestionsOutput;
  dismissRecommendationsSubscores: DismissRecommendationsSubscoresOutput;
  dismissSyncErrors: DismissSyncErrorsOutput;
  escalateRequestToSkipManager: EscalateRequestToSkipManagerOutput;
  forfeitGroup: ForfeitGroupOutput;
  forfeitResource: ForfeitResourceOutput;
  importAll: ImportAllOutput;
  /** Import resources and their resource users for a custom connection */
  importCustomResources: ImportCustomResourcesOutput;
  /** Import resources and their resource users for a custom connection (dry run) */
  importCustomResourcesDryRun: ImportCustomResourcesDryRunOutput;
  initOidcAuthFlow: InitOidcAuthFlowOutput;
  initOidcAuthFlowPresigned: InitOidcAuthFlowOutput;
  initThirdPartyIntegrationAuthFlow: InitThirdPartyIntegrationAuthFlowOutput;
  initThirdPartyIntegrationLinkAuthFlow: InitThirdPartyIntegrationLinkAuthFlowOutput;
  inviteUsers: InviteUsersOutput;
  linkExistingRemoteMessageChannel: LinkExistingRemoteMessageChannelOutput;
  logEntityRemediation: LogEntityRemediationOutput;
  mfaCallback: MfaCallbackOutput;
  performReview: PerformReviewOutput;
  readUINotification: ReadUiNotificationOutput;
  readUINotifications: ReadUiNotificationsOutput;
  recordGroupView: RecordGroupViewOutput;
  recordResourceView: RecordResourceViewOutput;
  regenerateAccessReviewReport: RegenerateAccessReviewReportOutput;
  regenerateSigningSecret: RegenerateSigningSecretOutput;
  remediateAddGroupResources: RemediateAddGroupResourcesOutput;
  remediateAddGroupUsers: RemediateAddGroupUsersOutput;
  remediateAddResourceUsers: RemediateAddResourceUsersOutput;
  remediateAddRoleAssignments: RemediateAddRoleAssignmentsOutput;
  removeConnectionUsers: RemoveConnectionUsersOutput;
  removeGroupGroups: RemoveGroupGroupsOutput;
  removeGroupResources: RemoveGroupResourcesOutput;
  removeGroupTags: RemoveGroupTagsOutput;
  removeGroupUsers: RemoveGroupUsersOutput;
  removeResourceTags: RemoveResourceTagsOutput;
  removeResourceUsers: RemoveResourceUsersOutput;
  removeRoleAssignments: RemoveRoleAssignmentsOutput;
  removeUserTags: RemoveUserTagsOutput;
  rescoreEntity: RescoreEntityOutput;
  rescoreRecommendations: RescoreRecommendationsOutput;
  resetDataseed: ResetDataseedOutput;
  resetRemediations: ResetRemediationsOutput;
  resetUserMFA: ResetUserMfaOutput;
  saveAccessReviewTemplate: CreateAccessReviewTemplateOutput;
  sendAccessReviewReminder: SendAccessReviewReminderOutput;
  sendManualRequestReminder: SendManualRequestReminderOutput;
  setNote: SetNoteOutput;
  setOwnerUsers: SetOwnerUsersOutput;
  setRoles: SetRolesOutput;
  signIn: SignInOutput;
  signOut: SignOutOutput;
  startAccessReview: StartAccessReviewOutput;
  startSync: StartSyncOutput;
  stopAccessReview: StopAccessReviewOutput;
  stopImpersonation: StopImpersonationOutput;
  updateAccessReview: UpdateAccessReviewOutput;
  updateAccessReviewGroupReviewers: UpdateAccessReviewGroupReviewersOutput;
  updateAccessReviewResourceReviewers: UpdateAccessReviewResourceReviewersOutput;
  updateAccessReviewReviewers: UpdateAccessReviewReviewersOutput;
  updateAccessReviewTemplate: UpdateAccessReviewTemplateOutput;
  updateBundle: UpdateBundleOutput;
  updateConfigTemplateTags: UpdateConfigTemplateTagsOutput;
  updateConfigurationTemplate: UpdateConfigurationTemplateOutput;
  updateConnection: UpdateConnectionOutput;
  updateConnectionReviewers: UpdateConnectionReviewersOutput;
  updateConnectionUserReviewers: UpdateConnectionUserReviewersOutput;
  updateGroup: UpdateGroupOutput;
  updateGroupBindings: UpdateGroupBindingsOutput;
  updateGroupResourceReviewers: UpdateGroupResourceReviewersOutput;
  updateGroupUserReviewers: UpdateGroupUserReviewersOutput;
  updateGroupUsers: UpdateGroupUsersOutput;
  updateIdpConnection: UpdateIdpConnectionOutput;
  updateOpalScopedRole?: Maybe<UpdateOpalScopedRoleOutput>;
  updateOrganizationSettings: UpdateOrganizationSettingsOutput;
  updateOwner: UpdateOwnerOutput;
  updateRequestTemplate: UpdateRequestTemplateOutput;
  updateResourceCustomAccessLevel: UpdateResourceCustomAccessLevelOutput;
  updateResources: UpdateResourcesOutput;
  updateResourceUserReviewers: UpdateResourceUserReviewersOutput;
  updateResourceUsers: UpdateResourceUsersOutput;
  updateResourceVisibilityGroups: UpdateResourceVisibilityGroupsOutput;
  updateSamlConnection: UpdateSamlConnectionOutput;
  updateUser: UpdateUserOutput;
  updateUserManager: UpdateUserManagerOutput;
  updateUserSettings: UpdateUserSettingsOutput;
  updateWebhookInfo: UpdateWebhookInfoOutput;
  useGroupBreakGlass: UseGroupBreakGlassOutput;
  validateCodeAndSaveIdToken?: Maybe<Scalars["Boolean"]>;
  validateCodeAndSaveIdTokenPresigned?: Maybe<Scalars["Boolean"]>;
  verifyFactorAsync: VerifyFactorAsyncOutput;
  verifyFactorSync: VerifyFactorSyncOutput;
};

export type MutationAddBigDataArgs = {
  input: AddBigDataInput;
};

export type MutationAddConnectionUsersArgs = {
  input: AddConnectionUsersInput;
};

export type MutationAddGroupGroupsArgs = {
  input: AddGroupGroupsInput;
};

export type MutationAddGroupResourcesArgs = {
  input: AddGroupResourcesInput;
};

export type MutationAddGroupTagsArgs = {
  input: AddGroupTagsInput;
};

export type MutationAddGroupUsersArgs = {
  input: AddGroupUsersInput;
};

export type MutationAddResourceTagsArgs = {
  input: AddResourceTagsInput;
};

export type MutationAddResourceUsersArgs = {
  input: AddResourceUsersInput;
};

export type MutationAddRoleAssignmentsArgs = {
  input: AddRoleAssignmentsInput;
};

export type MutationAddUserTagsArgs = {
  input: AddUserTagsInput;
};

export type MutationApproveRequestArgs = {
  input: ApproveRequestInput;
};

export type MutationAssumeImpersonationArgs = {
  input: AssumeImpersonationInput;
};

export type MutationAuthCodeCallbackArgs = {
  input: AuthCodeCallbackInput;
};

export type MutationBulkImportRemoteItemsArgs = {
  input: BulkImportRemoteItemsInput;
};

export type MutationBulkUpdateItemsArgs = {
  input: BulkUpdateItemsInput;
};

export type MutationCancelRequestArgs = {
  input: CancelRequestInput;
};

export type MutationClearResourceUserPropagationStatusArgs = {
  input: ClearResourceUserPropagationStatusInput;
};

export type MutationConfirmEndSystemRevocationArgs = {
  input: ConfirmEndSystemRevocationInput;
};

export type MutationCreateAuthProviderApplicationArgs = {
  input: CreateAuthProviderApplicationInput;
};

export type MutationCreateBundleArgs = {
  input: CreateBundleInput;
};

export type MutationCreateBundleGroupsArgs = {
  input: BundleGroupsInput;
};

export type MutationCreateBundleResourcesArgs = {
  input: BundleResourcesInput;
};

export type MutationCreateConfigurationTemplateArgs = {
  input: CreateConfigurationTemplateInput;
};

export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};

export type MutationCreateConnectionForIdpArgs = {
  input: CreateConnectionForIdpInput;
};

export type MutationCreateEventFilterArgs = {
  input: CreateEventFilterInput;
};

export type MutationCreateEventStreamArgs = {
  input: CreateEventStreamInput;
};

export type MutationCreateFirstPartyTokenArgs = {
  input: CreateFirstPartyTokenInput;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateGroupBindingsArgs = {
  input: CreateGroupBindingsInput;
};

export type MutationCreateHrisProviderKeyArgs = {
  input: CreateHrisProviderKeyInput;
};

export type MutationCreateIdpConnectionArgs = {
  input: CreateIdpConnectionInput;
};

export type MutationCreateIdpConnectionUserAttributeImportMappingArgs = {
  input: CreateIdpConnectionUserAttributeImportMappingInput;
};

export type MutationCreateOpalScopedRoleArgs = {
  input: CreateOpalScopedRoleInput;
};

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationCreateOrUpdateOidcProviderArgs = {
  input: CreateOrUpdateOidcProviderInput;
};

export type MutationCreateOwnerArgs = {
  input: CreateOwnerInput;
};

export type MutationCreateOwnersArgs = {
  input: CreateOwnersInput;
};

export type MutationCreateRemoteMessageChannelArgs = {
  input: CreateRemoteMessageChannelInput;
};

export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};

export type MutationCreateRequestCommentArgs = {
  input: CreateRequestCommentInput;
};

export type MutationCreateRequestProposalArgs = {
  input: CreateRequestProposalInput;
};

export type MutationCreateRequestTemplateArgs = {
  input: CreateRequestTemplateInput;
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateResourceCustomAccessLevelArgs = {
  input: CreateResourceCustomAccessLevelInput;
};

export type MutationCreateResourceCustomAccessLevelsArgs = {
  input: CreateResourceCustomAccessLevelsInput;
};

export type MutationCreateResourcesArgs = {
  input: CreateResourcesInput;
};

export type MutationCreateSamlConnectionArgs = {
  input: CreateSamlConnectionInput;
};

export type MutationCreateSamlConnectionProxyArgs = {
  input: CreateSamlConnectionProxyInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationCreateThirdPartyIntegrationArgs = {
  input: CreateThirdPartyIntegrationInput;
};

export type MutationCreateWebhookInfoArgs = {
  input: CreateWebhookInfoInput;
};

export type MutationDeleteAccessReviewTemplatesArgs = {
  input: DeleteAccessReviewTemplatesInput;
};

export type MutationDeleteBundleArgs = {
  input: DeleteBundleInput;
};

export type MutationDeleteBundleGroupsArgs = {
  input: DeleteBundleGroupsInput;
};

export type MutationDeleteBundleResourcesArgs = {
  input: DeleteBundleResourcesInput;
};

export type MutationDeleteConfigurationTemplateArgs = {
  input: DeleteConfigurationTemplateInput;
};

export type MutationDeleteConnectionArgs = {
  input: DeleteConnectionInput;
};

export type MutationDeleteEventFilterArgs = {
  input: DeleteEventFilterInput;
};

export type MutationDeleteFirstPartyTokensArgs = {
  input: DeleteFirstPartyTokensInput;
};

export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};

export type MutationDeleteGroupBindingsArgs = {
  input: DeleteGroupBindingsInput;
};

export type MutationDeleteGroupsArgs = {
  input: DeleteGroupsInput;
};

export type MutationDeleteGroupUserPropStatusArgs = {
  accessReviewGroupUserId: Scalars["AccessReviewGroupUserId"];
};

export type MutationDeleteIdpConnectionArgs = {
  input: DeleteIdpConnectionInput;
};

export type MutationDeleteIdpConnectionUserAttributeImportMappingArgs = {
  input: DeleteIdpConnectionUserAttributeImportMappingInput;
};

export type MutationDeleteOwnerArgs = {
  input: DeleteOwnerInput;
};

export type MutationDeleteOwnersArgs = {
  input: DeleteOwnersInput;
};

export type MutationDeleteRequestTemplateArgs = {
  input: DeleteRequestTemplateInput;
};

export type MutationDeleteResourceCustomAccessLevelsArgs = {
  input: DeleteResourceCustomAccessLevelsInput;
};

export type MutationDeleteResourcesArgs = {
  input: DeleteResourcesInput;
};

export type MutationDeleteSamlConnectionProxyArgs = {
  input: DeleteSamlConnectionProxyInput;
};

export type MutationDeleteTagsArgs = {
  input: DeleteTagsInput;
};

export type MutationDeleteThirdPartyIntegrationArgs = {
  input: DeleteThirdPartyIntegrationInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDenyRequestArgs = {
  input: DenyRequestInput;
};

export type MutationDismissGroupBindingSuggestionsArgs = {
  input: DismissGroupBindingSuggestionsInput;
};

export type MutationDismissRecommendationsSubscoresArgs = {
  input: DismissRecommendationsSubscoresInput;
};

export type MutationDismissSyncErrorsArgs = {
  input: DismissSyncErrorsInput;
};

export type MutationEscalateRequestToSkipManagerArgs = {
  input: EscalateRequestToSkipManagerInput;
};

export type MutationForfeitGroupArgs = {
  input: ForfeitGroupInput;
};

export type MutationForfeitResourceArgs = {
  input: ForfeitResourceInput;
};

export type MutationImportAllArgs = {
  input: ImportAllInput;
};

export type MutationImportCustomResourcesArgs = {
  input: ImportCustomResourcesInput;
};

export type MutationImportCustomResourcesDryRunArgs = {
  input: ImportCustomResourcesInput;
};

export type MutationInitOidcAuthFlowArgs = {
  input: InitOidcAuthFlowInput;
};

export type MutationInitOidcAuthFlowPresignedArgs = {
  input: InitOidcAuthFlowInput;
};

export type MutationInitThirdPartyIntegrationAuthFlowArgs = {
  input: InitThirdPartyIntegrationAuthFlowInput;
};

export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};

export type MutationLinkExistingRemoteMessageChannelArgs = {
  input: LinkExistingRemoteMessageChannelInput;
};

export type MutationLogEntityRemediationArgs = {
  input: LogEntityRemediationInput;
};

export type MutationMfaCallbackArgs = {
  input: MfaCallbackInput;
};

export type MutationPerformReviewArgs = {
  input: PerformReviewInput;
};

export type MutationReadUiNotificationArgs = {
  input: ReadUiNotificationInput;
};

export type MutationReadUiNotificationsArgs = {
  input: ReadUiNotificationsInput;
};

export type MutationRecordGroupViewArgs = {
  input: RecordGroupViewInput;
};

export type MutationRecordResourceViewArgs = {
  input: RecordResourceViewInput;
};

export type MutationRegenerateAccessReviewReportArgs = {
  input: RegenerateAccessReviewReportInput;
};

export type MutationRemediateAddGroupResourcesArgs = {
  input: AddGroupResourcesInput;
};

export type MutationRemediateAddGroupUsersArgs = {
  input: AddGroupUsersInput;
};

export type MutationRemediateAddResourceUsersArgs = {
  input: AddResourceUsersInput;
};

export type MutationRemediateAddRoleAssignmentsArgs = {
  input: AddRoleAssignmentsInput;
};

export type MutationRemoveConnectionUsersArgs = {
  input: RemoveConnectionUsersInput;
};

export type MutationRemoveGroupGroupsArgs = {
  input: RemoveGroupGroupsInput;
};

export type MutationRemoveGroupResourcesArgs = {
  input: RemoveGroupResourcesInput;
};

export type MutationRemoveGroupTagsArgs = {
  input: RemoveGroupTagsInput;
};

export type MutationRemoveGroupUsersArgs = {
  input: RemoveGroupUsersInput;
};

export type MutationRemoveResourceTagsArgs = {
  input: RemoveResourceTagsInput;
};

export type MutationRemoveResourceUsersArgs = {
  input: RemoveResourceUsersInput;
};

export type MutationRemoveRoleAssignmentsArgs = {
  input: RemoveRoleAssignmentsInput;
};

export type MutationRemoveUserTagsArgs = {
  input: RemoveUserTagsInput;
};

export type MutationRescoreEntityArgs = {
  input: RecommendationsEntityKey;
};

export type MutationResetUserMfaArgs = {
  input: ResetUserMfaInput;
};

export type MutationSaveAccessReviewTemplateArgs = {
  input: CreateAccessReviewTemplateInput;
};

export type MutationSendAccessReviewReminderArgs = {
  input: SendAccessReviewReminderInput;
};

export type MutationSendManualRequestReminderArgs = {
  input: SendManualRequestReminderInput;
};

export type MutationSetNoteArgs = {
  input?: InputMaybe<SetNoteInput>;
};

export type MutationSetOwnerUsersArgs = {
  input: SetOwnerUsersInput;
};

export type MutationSetRolesArgs = {
  input: SetRolesInput;
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationStartAccessReviewArgs = {
  input: StartAccessReviewInput;
};

export type MutationStartSyncArgs = {
  input: StartSyncInput;
};

export type MutationStopAccessReviewArgs = {
  input: StopAccessReviewInput;
};

export type MutationUpdateAccessReviewArgs = {
  input: UpdateAccessReviewInput;
};

export type MutationUpdateAccessReviewGroupReviewersArgs = {
  input: UpdateAccessReviewGroupReviewersInput;
};

export type MutationUpdateAccessReviewResourceReviewersArgs = {
  input: UpdateAccessReviewResourceReviewersInput;
};

export type MutationUpdateAccessReviewReviewersArgs = {
  input: UpdateAccessReviewReviewersInput;
};

export type MutationUpdateAccessReviewTemplateArgs = {
  input: UpdateAccessReviewTemplateInput;
};

export type MutationUpdateBundleArgs = {
  input: UpdateBundleInput;
};

export type MutationUpdateConfigTemplateTagsArgs = {
  input: UpdateConfigTemplateTagsInput;
};

export type MutationUpdateConfigurationTemplateArgs = {
  input: UpdateConfigurationTemplateInput;
};

export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};

export type MutationUpdateConnectionReviewersArgs = {
  input: UpdateConnectionReviewersInput;
};

export type MutationUpdateConnectionUserReviewersArgs = {
  input: UpdateConnectionUserReviewersInput;
};

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};

export type MutationUpdateGroupBindingsArgs = {
  input: UpdateGroupBindingsInput;
};

export type MutationUpdateGroupResourceReviewersArgs = {
  input: UpdateGroupResourceReviewersInput;
};

export type MutationUpdateGroupUserReviewersArgs = {
  input: UpdateGroupUserReviewersInput;
};

export type MutationUpdateGroupUsersArgs = {
  input: UpdateGroupUsersInput;
};

export type MutationUpdateIdpConnectionArgs = {
  input: UpdateIdpConnectionInput;
};

export type MutationUpdateOpalScopedRoleArgs = {
  input: UpdateOpalScopedRoleInput;
};

export type MutationUpdateOrganizationSettingsArgs = {
  input: UpdateOrganizationSettingsInput;
};

export type MutationUpdateOwnerArgs = {
  input: UpdateOwnerInput;
};

export type MutationUpdateRequestTemplateArgs = {
  input: UpdateRequestTemplateInput;
};

export type MutationUpdateResourceCustomAccessLevelArgs = {
  input: UpdateResourceCustomAccessLevelInput;
};

export type MutationUpdateResourcesArgs = {
  input: UpdateResourcesInput;
};

export type MutationUpdateResourceUserReviewersArgs = {
  input: UpdateResourceUserReviewersInput;
};

export type MutationUpdateResourceUsersArgs = {
  input: UpdateResourceUsersInput;
};

export type MutationUpdateResourceVisibilityGroupsArgs = {
  input: UpdateResourceVisibilityGroupsInput;
};

export type MutationUpdateSamlConnectionArgs = {
  input: UpdateSamlConnectionInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserManagerArgs = {
  input: UpdateUserManagerInput;
};

export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type MutationUpdateWebhookInfoArgs = {
  input: UpdateWebhookInfoInput;
};

export type MutationUseGroupBreakGlassArgs = {
  input: UseGroupBreakGlassInput;
};

export type MutationValidateCodeAndSaveIdTokenArgs = {
  input: ValidateCodeAndSaveIdTokenInput;
};

export type MutationValidateCodeAndSaveIdTokenPresignedArgs = {
  input: ValidateCodeAndSaveIdTokenInput;
};

export type MutationVerifyFactorAsyncArgs = {
  input: VerifyFactorAsyncInput;
};

export type MutationVerifyFactorSyncArgs = {
  input: VerifyFactorSyncInput;
};

export type MyAccessReviewItemsInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type MyAccessReviewItemsOutput =
  | AccessReviewNotFoundError
  | MyAccessReviewItemsResult;

export type MyAccessReviewItemsResult = {
  __typename?: "MyAccessReviewItemsResult";
  connections: Array<AccessReviewConnection>;
  groups: Array<AccessReviewGroup>;
  resources: Array<AccessReviewResource>;
};

export type MySqlMariaDbConnectionCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUserName: Scalars["String"];
};

export type MySqlMariaDbConnectionMetadata = {
  __typename?: "MySQLMariaDBConnectionMetadata";
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type MySqlMariaDbConnectionMetadataInput = {
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type NamesFilterInput = {
  names: Array<Scalars["String"]>;
};

export type NoAccessReviewInProgressError = Error & {
  __typename?: "NoAccessReviewInProgressError";
  message: Scalars["String"];
};

export type NoBreakGlassAccessError = Error & {
  __typename?: "NoBreakGlassAccessError";
  message: Scalars["String"];
};

export type NoIdpConfiguredError = Error & {
  __typename?: "NoIDPConfiguredError";
  message: Scalars["String"];
};

export type NoManagerSetForRequestingUserError = Error & {
  __typename?: "NoManagerSetForRequestingUserError";
  message: Scalars["String"];
};

export type NoProposedChangesError = Error & {
  __typename?: "NoProposedChangesError";
  message: Scalars["String"];
};

export type NoReviewersSetForGroupError = Error & {
  __typename?: "NoReviewersSetForGroupError";
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  message: Scalars["String"];
};

export type NoReviewersSetForOwnerError = Error & {
  __typename?: "NoReviewersSetForOwnerError";
  message: Scalars["String"];
  owner?: Maybe<Owner>;
  ownerId: Scalars["OwnerId"];
};

export type NoReviewersSetForResourceError = Error & {
  __typename?: "NoReviewersSetForResourceError";
  message: Scalars["String"];
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
};

export type NoSkipManagerSetForTargetUserError = Error & {
  __typename?: "NoSkipManagerSetForTargetUserError";
  message: Scalars["String"];
};

export enum NotificationType {
  Email = "EMAIL",
  Slack = "SLACK",
}

export type NotSessionableError = Error & {
  __typename?: "NotSessionableError";
  message: Scalars["String"];
};

export type NullableConfigurationId = {
  configurationId?: InputMaybe<Scalars["ConfigurationId"]>;
};

export type NullableGroupId = {
  groupId?: InputMaybe<Scalars["GroupId"]>;
};

export type NullableInt = {
  int?: InputMaybe<Scalars["Int"]>;
};

export type NullableMessageChannelId = {
  channelId?: InputMaybe<Scalars["MessageChannelId"]>;
};

export type NullableOwnerId = {
  ownerId?: InputMaybe<Scalars["OwnerId"]>;
};

export type NullableRequestTemplateId = {
  requestTemplateId?: InputMaybe<Scalars["RequestTemplateId"]>;
};

export type NullableResourceId = {
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
};

export type NullableString = {
  string?: InputMaybe<Scalars["String"]>;
};

export type NullableStrings = {
  strings?: InputMaybe<Array<Scalars["String"]>>;
};

export type NullableTicketProvider = {
  ticketProviderInfo?: InputMaybe<TicketProviderInput>;
};

export type NullableUserId = {
  userId?: InputMaybe<Scalars["UserId"]>;
};

export type ObjectFilter = {
  objectId: Scalars["String"];
};

export type OidcIdTokenNotFoundError = Error & {
  __typename?: "OidcIDTokenNotFoundError";
  message: Scalars["String"];
};

export type OidcIssuerNotValidError = {
  __typename?: "OidcIssuerNotValidError";
  message: Scalars["String"];
};

export type OidcProvider = {
  __typename?: "OidcProvider";
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  issuerUrl: Scalars["String"];
};

export type OidcProviderInput = {
  oidcProviderType: OidcProviderType;
};

export type OidcProviderNotFoundError = {
  __typename?: "OidcProviderNotFoundError";
  message: Scalars["String"];
};

export type OidcProviderOutput = OidcProviderNotFoundError | OidcProviderResult;

export type OidcProviderResult = {
  __typename?: "OidcProviderResult";
  oidcProvider: OidcProvider;
};

export enum OidcProviderType {
  AwsSession = "AWS_SESSION",
  Mfa = "MFA",
}

export type OidcSession = {
  __typename?: "OidcSession";
  id: Scalars["ConnectionId"];
  idToken: Scalars["String"];
};

export type OktaDirectoryAppMetadata = {
  __typename?: "OktaDirectoryAppMetadata";
  appId: Scalars["String"];
  appName: Scalars["String"];
  logoUrl?: Maybe<Scalars["String"]>;
};

export type OktaDirectoryConnectionMetadata = {
  __typename?: "OktaDirectoryConnectionMetadata";
  orgUrl: Scalars["String"];
};

export type OktaDirectoryConnectionMetadataInput = {
  orgUrl: Scalars["String"];
};

export type OktaDirectoryCredentialsInput = {
  adminToken: Scalars["String"];
};

export type OktaGroupError = Error & {
  __typename?: "OktaGroupError";
  message: Scalars["String"];
};

export type OktaResourceApp = {
  __typename?: "OktaResourceApp";
  iconUrl?: Maybe<Scalars["String"]>;
  isVisible: Scalars["Boolean"];
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
};

export type OktaRoleMetadata = {
  __typename?: "OktaRoleMetadata";
  roleId: Scalars["String"];
  roleType: Scalars["String"];
};

export type OnCallMetadataInput = {
  scheduleInputs: Array<OnCallScheduleInput>;
};

export type OnCallSchedule = {
  __typename?: "OnCallSchedule";
  existsInRemote: Scalars["Boolean"];
  name: Scalars["String"];
  remoteId: Scalars["OnCallScheduleRemoteId"];
  thirdPartyProvider: ThirdPartyProvider;
};

export type OnCallScheduleInput = {
  remoteId: Scalars["OnCallScheduleRemoteId"];
  scheduleName: Scalars["String"];
  thirdPartyProvider: ThirdPartyProvider;
};

export type OnCallSchedulesOutput = OnCallSchedulesResult | UserFacingError;

export type OnCallSchedulesResult = {
  __typename?: "OnCallSchedulesResult";
  onCallSchedules: Array<OnCallSchedule>;
};

export type OngoingAccessReviewSubtabStats = {
  __typename?: "OngoingAccessReviewSubtabStats";
  numGroupsToReview: Scalars["Int"];
  numResourcesToReview: Scalars["Int"];
  numUsersToReview: Scalars["Int"];
};

export type OngoingAccessReviewSubtabStatsInput = {
  accessReviewId: Scalars["AccessReviewId"];
  itemId: Scalars["UUID"];
  tab: AccessReviewTab;
};

export type OngoingAccessReviewSubtabStatsOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | OngoingAccessReviewSubtabStatsResult;

export type OngoingAccessReviewSubtabStatsResult = {
  __typename?: "OngoingAccessReviewSubtabStatsResult";
  ongoingAccessReviewSubtabStats?: Maybe<OngoingAccessReviewSubtabStats>;
};

export type OngoingAccessReviewTabStats = {
  __typename?: "OngoingAccessReviewTabStats";
  numAppsToReview: Scalars["Int"];
  numGroupsToReview: Scalars["Int"];
  numResourcesToReview: Scalars["Int"];
  numUsersToReview: Scalars["Int"];
};

export type OngoingAccessReviewTabStatsInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type OngoingAccessReviewTabStatsOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | OngoingAccessReviewTabStatsResult;

export type OngoingAccessReviewTabStatsResult = {
  __typename?: "OngoingAccessReviewTabStatsResult";
  ongoingAccessReviewTabStats?: Maybe<OngoingAccessReviewTabStats>;
};

export type OnPremOrganizationSamlInfo = {
  __typename?: "OnPremOrganizationSamlInfo";
  id: Scalars["OrganizationId"];
  providerID: Scalars["String"];
};

export type OpalAdminRoleMustHaveAtLeastOneDirectUser = Error & {
  __typename?: "OpalAdminRoleMustHaveAtLeastOneDirectUser";
  message: Scalars["String"];
};

export type OpalGlobalImpersonationResourceDirectAddNotAllowedError = Error & {
  __typename?: "OpalGlobalImpersonationResourceDirectAddNotAllowedError";
  message: Scalars["String"];
};

export type OpalImpersonationSession = {
  __typename?: "OpalImpersonationSession";
  success?: Maybe<Scalars["Boolean"]>;
};

export type OpalResourceImmutableError = Error & {
  __typename?: "OpalResourceImmutableError";
  message: Scalars["String"];
};

export type Organization = {
  __typename?: "Organization";
  createdAt: Scalars["Time"];
  id: Scalars["OrganizationId"];
  isDeleted: Scalars["Boolean"];
  name: Scalars["String"];
  organizationType: OrganizationType;
  updatedAt: Scalars["Time"];
};

export type OrganizationExistsError = Error & {
  __typename?: "OrganizationExistsError";
  message: Scalars["String"];
};

export type OrganizationInReadOnlyModeError = Error & {
  __typename?: "OrganizationInReadOnlyModeError";
  message: Scalars["String"];
};

export type OrganizationSamlInfo = {
  __typename?: "OrganizationSamlInfo";
  certExpiration: Scalars["Time"];
  email: Scalars["String"];
  endpoint: Scalars["String"];
  id: Scalars["OrganizationId"];
  restrictLoginToSaml: Scalars["Boolean"];
  samlBreakGlassUsers: Array<User>;
};

export type OrganizationSettings = {
  __typename?: "OrganizationSettings";
  accessExpiringNotifications?: Maybe<Array<Scalars["Int"]>>;
  auditTicketProvider?: Maybe<TicketProvider>;
  authSessionExpirationInMinutes: Scalars["Int"];
  createdAt: Scalars["Time"];
  customerSupportExpiresAt: Scalars["Time"];
  customerSupportGatingAllowed: Scalars["Boolean"];
  dryRunModeEnabled?: Maybe<Scalars["Boolean"]>;
  errorNotificationSetting: ErrorNotificationSettingType;
  generalSettings: Array<GeneralSettingType>;
  id: Scalars["OrganizationId"];
  isRemoteReadOnly?: Maybe<Scalars["Boolean"]>;
  maxGroupDurationInMinutes?: Maybe<Scalars["Int"]>;
  maxResourceDurationInMinutes?: Maybe<Scalars["Int"]>;
  roleCreationReviewerOwner?: Maybe<Owner>;
  roleCreationReviewerOwnerId?: Maybe<Scalars["OwnerId"]>;
  syncErrorHandlerOwnerId?: Maybe<Scalars["OwnerId"]>;
  updatedAt: Scalars["Time"];
};

export type OrganizationSettingsNotFoundError = Error & {
  __typename?: "OrganizationSettingsNotFoundError";
  message: Scalars["String"];
};

export type OrganizationSettingsOutput =
  | OrganizationSettingsNotFoundError
  | OrganizationSettingsResult;

export type OrganizationSettingsResult = {
  __typename?: "OrganizationSettingsResult";
  settings: OrganizationSettings;
};

export enum OrganizationType {
  Cloud = "CLOUD",
  OnPrem = "ON_PREM",
}

export type OrganizationWebhookInfo = {
  __typename?: "OrganizationWebhookInfo";
  enableWebhook: Scalars["Boolean"];
  id: Scalars["OrganizationId"];
  signingSecret?: Maybe<Scalars["String"]>;
  signingSecretPreview?: Maybe<Scalars["String"]>;
  webhookEndpointUrl: Scalars["String"];
};

export type OrgMaxGroupDurationInvalidError = Error & {
  __typename?: "OrgMaxGroupDurationInvalidError";
  message: Scalars["String"];
};

export type OrgMaxResourceDurationInvalidError = Error & {
  __typename?: "OrgMaxResourceDurationInvalidError";
  message: Scalars["String"];
};

export type OwnedConfigurationTemplate = {
  __typename?: "OwnedConfigurationTemplate";
  configurationTemplate: ConfigurationTemplate;
  isAdmin: Scalars["Boolean"];
  isReviewer: Scalars["Boolean"];
};

export type OwnedGroup = {
  __typename?: "OwnedGroup";
  group: Group;
  isAdmin: Scalars["Boolean"];
  isReviewer: Scalars["Boolean"];
};

export type OwnedResource = {
  __typename?: "OwnedResource";
  isAdmin: Scalars["Boolean"];
  isReviewer: Scalars["Boolean"];
  resource: Resource;
};

export type Owner = {
  __typename?: "Owner";
  accessRequestEscalationPeriodInMinutes?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Time"];
  description: Scalars["String"];
  id: Scalars["OwnerId"];
  isDeleted: Scalars["Boolean"];
  name: Scalars["String"];
  ownedConfigurationTemplates: Array<OwnedConfigurationTemplate>;
  ownedGroups: Array<OwnedGroup>;
  ownedResources: Array<OwnedResource>;
  ownerUsers: Array<OwnerUser>;
  reviewerMessageChannel?: Maybe<MessageChannel>;
  reviewerMessageChannelId?: Maybe<Scalars["MessageChannelId"]>;
  sourceGroup?: Maybe<Group>;
  sourceGroupId?: Maybe<Scalars["GroupId"]>;
};

export type OwnerAccessRequestFrequencyReminderOutOfBoundsError = Error & {
  __typename?: "OwnerAccessRequestFrequencyReminderOutOfBoundsError";
  message: Scalars["String"];
};

export type OwnerInput = {
  id: Scalars["OwnerId"];
};

export type OwnerNameAlreadyExists = Error & {
  __typename?: "OwnerNameAlreadyExists";
  message: Scalars["String"];
};

export type OwnerNotFoundError = Error & {
  __typename?: "OwnerNotFoundError";
  message: Scalars["String"];
};

export type OwnerOutput = OwnerNotFoundError | OwnerResult;

export type OwnerOwnsItemsError = Error & {
  __typename?: "OwnerOwnsItemsError";
  entities: Array<ErrorEntity>;
  message: Scalars["String"];
};

export type OwnerResult = {
  __typename?: "OwnerResult";
  owner: Owner;
};

export type OwnerReviewsItemsError = Error & {
  __typename?: "OwnerReviewsItemsError";
  entities: Array<ErrorEntity>;
  message: Scalars["String"];
};

export type OwnersInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  includeAll?: InputMaybe<Scalars["Boolean"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<OwnersSortBy>;
};

export type OwnersOutput = OwnersResult;

export type OwnersResult = {
  __typename?: "OwnersResult";
  cursor?: Maybe<Scalars["String"]>;
  owners: Array<Owner>;
  totalNumOwners: Scalars["Int"];
};

export type OwnersSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<OwnersSortByField>;
};

export enum OwnersSortByField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
}

export type OwnerUser = {
  __typename?: "OwnerUser";
  owner?: Maybe<Owner>;
  ownerId: Scalars["OwnerId"];
  reviewerPriority: Scalars["Int"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type OwnerUserAlreadyExists = Error & {
  __typename?: "OwnerUserAlreadyExists";
  message: Scalars["String"];
};

export type PagerDutyConnectionMetadata = {
  __typename?: "PagerDutyConnectionMetadata";
  placeholder?: Maybe<Scalars["Boolean"]>;
};

export type PagerDutyCredentialsInput = {
  generalAccessToken: Scalars["String"];
};

export type PaginatedGroupUsersInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<GroupUserFiltersInput>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PaginatedGroupUsersSortBy>;
};

export type PaginatedGroupUsersOutput = {
  __typename?: "PaginatedGroupUsersOutput";
  cursor?: Maybe<Scalars["String"]>;
  groupUsers: Array<GroupUser>;
  totalNumGroupUsers: Scalars["Int"];
  uniqueAccessLevels: Array<GroupAccessLevel>;
};

export type PaginatedGroupUsersSortBy = {
  direction: SortDirection;
  field: GroupUserSortByField;
};

export type PaginatedResourceUsersInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ResourceUserFiltersInput>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PaginatedResourceUsersSortBy>;
};

export type PaginatedResourceUsersOutput = {
  __typename?: "PaginatedResourceUsersOutput";
  cursor?: Maybe<Scalars["String"]>;
  resourceUsers: Array<ResourceUser>;
  totalNumResourceUsers: Scalars["Int"];
  totalNumUsers: Scalars["Int"];
  uniqueAccessLevels: Array<ResourceAccessLevel>;
};

export type PaginatedResourceUsersSortBy = {
  direction: SortDirection;
  field: ResourceUserSortByField;
};

export type PaginatedSubEventsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PaginatedSubEventsSortBy>;
};

export type PaginatedSubEventsOutput = {
  __typename?: "PaginatedSubEventsOutput";
  cursor?: Maybe<Scalars["String"]>;
  subEvents: Array<SubEvent>;
  totalNumSubEvents: Scalars["Int"];
};

export type PaginatedSubEventsSortBy = {
  direction: SortDirection;
  field: SubEventsSortByField;
};

export type ParentResourceInput = {
  parentResourceId?: InputMaybe<Scalars["ResourceId"]>;
};

export type PerformReviewInput = {
  accessReviewId: Scalars["AccessReviewId"];
  connectionUserActions?: InputMaybe<Array<ReviewConnectionUserAction>>;
  groupResourceActions?: InputMaybe<Array<ReviewGroupResourceAction>>;
  groupUserActions?: InputMaybe<Array<ReviewGroupUserAction>>;
  resourceResourceActions?: InputMaybe<Array<ReviewResourceResourceAction>>;
  resourceUserActions?: InputMaybe<Array<ReviewResourceUserAction>>;
};

export type PerformReviewOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | ItemCannotBeRequestedError
  | OidcIdTokenNotFoundError
  | PerformReviewResult;

export type PerformReviewResult = {
  __typename?: "PerformReviewResult";
  success: Scalars["Boolean"];
};

export type PostgresConnectionCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUserName: Scalars["String"];
};

export type PostgresConnectionMetadata = {
  __typename?: "PostgresConnectionMetadata";
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type PostgresConnectionMetadataInput = {
  serverHostname: Scalars["String"];
  serverPort: Scalars["Int"];
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type Principal = Group | Resource | User;

export type PrincipalsInput = {
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type PrincipalsOutput = PrincipalsResult;

export type PrincipalsResult = {
  __typename?: "PrincipalsResult";
  principals: Array<Principal>;
};

export type PropagationStatus = {
  __typename?: "PropagationStatus";
  errorMessage?: Maybe<Scalars["String"]>;
  lastSynced: Scalars["Time"];
  roleAssignmentId: Scalars["RoleAssignmentId"];
  statusCode: PropagationStatusCode;
  taskType: PropagationTaskType;
};

export enum PropagationStatusCode {
  ErrDryRunModeEnabled = "ERR_DRY_RUN_MODE_ENABLED",
  ErrHrIdpProviderNotLinked = "ERR_HR_IDP_PROVIDER_NOT_LINKED",
  ErrIdpEmailUpdateConflict = "ERR_IDP_EMAIL_UPDATE_CONFLICT",
  ErrNotAuthorizedToQueryResource = "ERR_NOT_AUTHORIZED_TO_QUERY_RESOURCE",
  ErrOpalInternalError = "ERR_OPAL_INTERNAL_ERROR",
  ErrOperationUnsupported = "ERR_OPERATION_UNSUPPORTED",
  ErrOrgReadOnly = "ERR_ORG_READ_ONLY",
  ErrRemoteInternalError = "ERR_REMOTE_INTERNAL_ERROR",
  ErrRemoteProvisioningViaIdpFailed = "ERR_REMOTE_PROVISIONING_VIA_IDP_FAILED",
  ErrRemoteResourceNotFound = "ERR_REMOTE_RESOURCE_NOT_FOUND",
  ErrRemoteThrottle = "ERR_REMOTE_THROTTLE",
  ErrRemoteUserNotFound = "ERR_REMOTE_USER_NOT_FOUND",
  ErrRemoteUserNotLinked = "ERR_REMOTE_USER_NOT_LINKED",
  ErrTicketCreationSkipped = "ERR_TICKET_CREATION_SKIPPED",
  ErrTimeout = "ERR_TIMEOUT",
  ErrUnknown = "ERR_UNKNOWN",
  Pending = "PENDING",
  PendingManualPropagation = "PENDING_MANUAL_PROPAGATION",
  PendingTicketCreation = "PENDING_TICKET_CREATION",
  Success = "SUCCESS",
}

export enum PropagationTaskType {
  ConnectionsDeleteUsers = "CONNECTIONS_DELETE_USERS",
  GroupsCreateGroups = "GROUPS_CREATE_GROUPS",
  GroupsCreateResources = "GROUPS_CREATE_RESOURCES",
  GroupsCreateUsers = "GROUPS_CREATE_USERS",
  GroupsDeleteGroups = "GROUPS_DELETE_GROUPS",
  GroupsDeleteResources = "GROUPS_DELETE_RESOURCES",
  GroupsDeleteUsers = "GROUPS_DELETE_USERS",
  GroupsUpdateResources = "GROUPS_UPDATE_RESOURCES",
  GroupsUpdateUsers = "GROUPS_UPDATE_USERS",
  ResourcesCreateResources = "RESOURCES_CREATE_RESOURCES",
  ResourcesCreateUsers = "RESOURCES_CREATE_USERS",
  ResourcesDeleteResources = "RESOURCES_DELETE_RESOURCES",
  ResourcesDeleteUsers = "RESOURCES_DELETE_USERS",
  ResourcesUpdateUsers = "RESOURCES_UPDATE_USERS",
}

export type PropagationTicketConnectionMetadata = {
  __typename?: "PropagationTicketConnectionMetadata";
  ticketProvider?: Maybe<TicketProvider>;
};

export type PropagationTicketConnectionMetadataInput = {
  enableTicketPropagation: Scalars["Boolean"];
  ticketProvider?: InputMaybe<TicketProviderInput>;
};

export type PropagationTicketMetadata = {
  __typename?: "PropagationTicketMetadata";
  ticket?: Maybe<SupportTicket>;
  ticketId?: Maybe<Scalars["SupportTicketId"]>;
};

export type PropagationTicketOwnerMetadata = {
  __typename?: "PropagationTicketOwnerMetadata";
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars["OwnerId"]>;
};

export enum ProvisionSource {
  External = "EXTERNAL",
  Opal = "OPAL",
  Unknown = "UNKNOWN",
}

export type PubSubCredentialsInput = {
  connectionType: PubSubPublishConnectionType;
  webhook?: InputMaybe<WebhookPubSubPublishConnectionCredentialsInput>;
};

export type PubSubPublishConnection = {
  __typename?: "PubSubPublishConnection";
  connectionType: PubSubPublishConnectionType;
  credentials: PubsubPublishConnectionCredentialOutput;
  enabled: Scalars["Boolean"];
  failedDeliveryCount: Scalars["Int"];
  id: Scalars["PubSubPublishConnectionId"];
  metadata: PubSubPublishConnectionMetadataOutput;
  name: Scalars["String"];
};

export type PubsubPublishConnectionCredentialOutput = WebhookPubsubPublishConnectionCredentialsOutput;

export type PubSubPublishConnectionMetadataInput = {
  connectionType: PubSubPublishConnectionType;
  webhook?: InputMaybe<WebhookPubSubPublishConnectionMetadataInput>;
};

export type PubSubPublishConnectionMetadataOutput = WebhookPubSubPublishConnectionMetadata;

export enum PubSubPublishConnectionType {
  Webhook = "WEBHOOK",
}

export enum PubsubPublishMessageStatusCode {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Retry = "RETRY",
}

export enum PubsubPublishMessageType {
  EventStream = "EVENT_STREAM",
}

export type PushTask = PushTaskComplete | PushTaskPending;

export type PushTaskComplete = {
  __typename?: "PushTaskComplete";
  authUser?: Maybe<AuthUser>;
  id: Scalars["PushTaskId"];
  result: PushTaskCompleteResult;
  taskType: PropagationTaskType;
};

export type PushTaskCompleteResult =
  | ConnectionUserPushTaskResult
  | GroupGroupPushTaskResult
  | GroupResourcePushTaskResult
  | GroupUserPushTaskResult
  | ResourceUserPushTaskResult
  | RoleAssignmentPushTaskResult;

export type PushTaskInput = {
  id: Scalars["PushTaskId"];
};

export type PushTaskNotFoundError = Error & {
  __typename?: "PushTaskNotFoundError";
  message: Scalars["String"];
};

export type PushTaskOutput = PushTaskNotFoundError | PushTaskResult;

export type PushTaskPending = {
  __typename?: "PushTaskPending";
  id: Scalars["PushTaskId"];
  nextProcessAt?: Maybe<Scalars["Time"]>;
  taskType: PropagationTaskType;
};

export type PushTaskResult = {
  __typename?: "PushTaskResult";
  pushTask: PushTask;
};

export type Query = {
  __typename?: "Query";
  accessGrantedTimes: AccessGrantedTimesResult;
  /** Gets access levels for a single resource. */
  accessLevels: ResourceAccessLevelsOutput;
  accessReview: AccessReviewOutput;
  accessReviewChange: AccessReviewChangeOutput;
  accessReviewChanges: AccessReviewChangesOutput;
  accessReviewConnection: AccessReviewConnectionOutput;
  accessReviewConnections: AccessReviewConnectionsOutput;
  accessReviewConnectionUsers: AccessReviewConnectionUsersOutput;
  accessReviewGroup: AccessReviewGroupOutput;
  accessReviewGroupResourceAssignments: AccessReviewGroupResourceAssignmentsResult;
  accessReviewGroups: AccessReviewGroupsOutput;
  accessReviewGroupUsers: AccessReviewGroupUsersOutput;
  accessReviewItems: AccessReviewItemsResult;
  accessReviewProposedChanges: AccessReviewProposedChangesOutput;
  accessReviewResource: AccessReviewResourceOutput;
  accessReviewResourcePrincipalAssignments: AccessReviewResourcePrincipalAssignmentsResult;
  accessReviewResources: AccessReviewResourcesOutput;
  accessReviewResourceUsers: AccessReviewResourceUsersOutput;
  accessReviewReviewer: AccessReviewReviewerOutput;
  accessReviews: AccessReviewsOutput;
  accessReviewStats: AccessReviewStatsOutput;
  accessReviewTemplate: AccessReviewTemplateOutput;
  accessReviewTemplates: AccessReviewTemplatesOutput;
  accessReviewUser: AccessReviewUserOutput;
  accessReviewUserAssignments: AccessReviewUserAssignmentsResult;
  accessReviewUsers: AccessReviewUsersOutput;
  activeGroupRequestConfigurations: Array<GroupRequestConfigurationOutput>;
  activeResourceRequestConfigurations: Array<
    ResourceRequestConfigurationOutput
  >;
  allowedToCreateOrganizations: AllowedToCreateOrganizationsOutput;
  allowedToUseAldwin: AllowedToUseAldwinOutput;
  app: AppOutput;
  appInfo: AppInfoOutput;
  apps: AppsOutput;
  authProviderInfo: AuthProviderInfoOutput;
  authSessionStatus: AuthSessionStatusOutput;
  breakGlassUsersByGroup: BreakGlassUsersByGroupResult;
  breakGlassUsersCounts: BreakGlassUsersCountsResult;
  bundle: BundleOutput;
  bundleItems: BundleItemsOutput;
  bundles: BundlesOutput;
  bundleVisibilityGroup: BundleVisibilityGroupOutput;
  closedRequestMetrics: ClosedRequestMetricsResult;
  configTemplateTags: ConfigTemplateTagsOutput;
  configurationTemplate: ConfigurationTemplateOutput;
  configurationTemplates: ConfigurationTemplatesOutput;
  connection: ConnectionOutput;
  connections: ConnectionsOutput;
  countFilteredConnections: CountFilteredConnectionsOutput;
  countFilteredGroups: CountFilteredGroupsOutput;
  countFilteredResources: CountFilteredResourcesOutput;
  currentUserStats: CurrentUserStatsOutput;
  entitiesForRiskScoreRange: EntitiesForRiskScoreRangeResult;
  entityForRemediation: RecommendationsEntity;
  event: EventOutput;
  eventFilter: EventFilterOutput;
  eventFilters: EventFiltersOutput;
  events: EventsOutput;
  filteredConnections: FilteredConnectionsOutput;
  filteredGroups: FilteredGroupsOutput;
  filteredResources: FilteredResourcesOutput;
  firstPartyTokens: FirstPartyTokensOutput;
  group: GroupOutput;
  /** Gets access levels for a single group. */
  groupAccessLevels: GroupAccessLevelsOutput;
  groupBinding: GroupBindingOutput;
  groupBindings: GroupBindingsOutput;
  groupBindingSuggestion: GroupBindingSuggestionOutput;
  groupBindingSuggestions: GroupBindingSuggestionsOutput;
  groupGroupAccess: GroupGroupAccessOutput;
  groupOnCallSchedules: GroupOnCallSchedulesOutput;
  groupRequestStatusCounts: GroupRequestStatusCountsOutput;
  groups: GroupsOutput;
  groupTypesWithCounts: GroupTypesWithCountsOutput;
  idpConnection: IdpConnectionOutput;
  leftSidebarRoutes: LeftSidebarRoutes;
  listEventStreams: ListEventStreamsOutput;
  listFactors: ListFactorsOutput;
  messageChannels: MessageChannelsOutput;
  mostRequestedGroups: MostRequestedItemsResult;
  mostRequestedResources: MostRequestedItemsResult;
  /** Gets access levels for multiple resources. */
  multipleAccessLevels: MultipleResourceAccessLevelsOutput;
  /** Gets access levels for multiple groups. */
  multipleGroupAccessLevels: MultipleGroupAccessLevelsOutput;
  myAccessReviewItems: MyAccessReviewItemsOutput;
  numRequestsToReview: Scalars["Int"];
  oidcProvider: OidcProviderOutput;
  onCallSchedules: OnCallSchedulesOutput;
  ongoingAccessReviewSubtabStats: OngoingAccessReviewSubtabStatsOutput;
  ongoingAccessReviewTabStats: OngoingAccessReviewTabStatsOutput;
  organizationSettings: OrganizationSettingsOutput;
  owner: OwnerOutput;
  owners: OwnersOutput;
  principals: PrincipalsOutput;
  pushTask: PushTaskOutput;
  recommendationsMetricsGraph: RecommendationsMetricsGraph;
  remoteResources: RemoteResourcesOutput;
  request: RequestOutput;
  requestApprovalTimes: RequestApprovalTimesResult;
  requestDefaults: RequestDefaults;
  requestMetrics: RequestMetricsResult;
  requests: RequestsOutput;
  requestTemplate: RequestTemplateOutput;
  requestTemplates: RequestTemplatesOutput;
  requestTemplatesWithFields: RequestTemplatesWithFieldsOutput;
  resource: ResourceOutput;
  resourceAncestors: ResourcesOutput;
  resourceCustomAccessLevel: ResourceCustomAccessLevelOutput;
  resourceCustomAccessLevels: ResourceCustomAccessLevelsOutput;
  resourceRequestStatusCounts: ResourceRequestStatusCountsOutput;
  resources: ResourcesOutput;
  resourceTags: ResourceTagsOutput;
  resourceTypesWithCounts: ResourceTypesWithCountsOutput;
  resourceUsage: ResourceUsageOutput;
  riskScoreGraphData: RiskScoreGraphDataResult;
  samlConnection: SamlConnectionOutput;
  search: SearchOutput;
  sessions: SessionsOutput;
  setupState: SetupStateResult;
  signInMethod?: Maybe<SignInMethodOutput>;
  startAccessReviewStats: StartAccessReviewStatsOutput;
  suggestedAccessToRemediateForEntity: SuggestedAccessList;
  suggestions: SuggestionsOutput;
  supportTicket: SupportTicketOutput;
  supportTickets: SupportTicketsOutput;
  syncStatus: SyncStatusOutput;
  syncTask: SyncTaskOutput;
  tag: TagOutput;
  tags: TagsOutput;
  teams: TeamsOutput;
  thirdPartyIntegration: ThirdPartyIntegrationOutput;
  thirdPartyIntegrations: ThirdPartyIntegrationsOutput;
  ticketProjects: TicketProjectsOutput;
  titles: TitlesOutput;
  topBulkSuggestions: RecommendationsBulkSuggestionsResult;
  uiNotifications: Array<UiNotification>;
  user: UserOutput;
  users: UsersOutput;
  userSettings: UserSettingsOutput;
  userTags: UserTagsOutput;
  vaultSessions: VaultSessionsOutput;
  visualizationData: VisualizationOutput;
  webhookInfo: WebhookInfoOutput;
};

export type QueryAccessGrantedTimesArgs = {
  input: MetricsInput;
};

export type QueryAccessLevelsArgs = {
  input: ResourceAccessLevelsInput;
};

export type QueryAccessReviewArgs = {
  input: AccessReviewInput;
};

export type QueryAccessReviewChangeArgs = {
  input: AccessReviewChangeInput;
};

export type QueryAccessReviewChangesArgs = {
  input: AccessReviewChangesInput;
};

export type QueryAccessReviewConnectionArgs = {
  input: AccessReviewConnectionInput;
};

export type QueryAccessReviewConnectionsArgs = {
  input: AccessReviewConnectionsInput;
};

export type QueryAccessReviewConnectionUsersArgs = {
  input: AccessReviewInput;
};

export type QueryAccessReviewGroupArgs = {
  input: AccessReviewGroupInput;
};

export type QueryAccessReviewGroupResourceAssignmentsArgs = {
  input: AccessReviewGroupResourceAssignmentsInput;
};

export type QueryAccessReviewGroupsArgs = {
  input: AccessReviewGroupsInput;
};

export type QueryAccessReviewGroupUsersArgs = {
  input: AccessReviewInput;
};

export type QueryAccessReviewItemsArgs = {
  input: AccessReviewItemsInput;
};

export type QueryAccessReviewProposedChangesArgs = {
  input: AccessReviewProposedChangeInput;
};

export type QueryAccessReviewResourceArgs = {
  input: AccessReviewResourceInput;
};

export type QueryAccessReviewResourcePrincipalAssignmentsArgs = {
  input: AccessReviewResourcePrincipalAssignmentsInput;
};

export type QueryAccessReviewResourcesArgs = {
  input: AccessReviewResourcesInput;
};

export type QueryAccessReviewResourceUsersArgs = {
  input: AccessReviewInput;
};

export type QueryAccessReviewReviewerArgs = {
  input: AccessReviewReviewerInput;
};

export type QueryAccessReviewsArgs = {
  input: AccessReviewsInput;
};

export type QueryAccessReviewStatsArgs = {
  input: AccessReviewStatsInput;
};

export type QueryAccessReviewTemplateArgs = {
  input: AccessReviewTemplateInput;
};

export type QueryAccessReviewUserArgs = {
  input: AccessReviewUserInput;
};

export type QueryAccessReviewUserAssignmentsArgs = {
  input: AccessReviewUserAssignmentsInput;
};

export type QueryAccessReviewUsersArgs = {
  input: AccessReviewUsersInput;
};

export type QueryActiveGroupRequestConfigurationsArgs = {
  input: ActiveGroupRequestConfigurationInput;
};

export type QueryActiveResourceRequestConfigurationsArgs = {
  input: ActiveResourceRequestConfigurationInput;
};

export type QueryAppArgs = {
  id: Scalars["UUID"];
};

export type QueryAppsArgs = {
  access: AccessOption;
  appCategory?: InputMaybe<AppCategory>;
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppsSortBy>;
};

export type QueryBreakGlassUsersByGroupArgs = {
  input: MetricsInput;
};

export type QueryBreakGlassUsersCountsArgs = {
  input: MetricsInput;
};

export type QueryBundleArgs = {
  input: BundleInput;
};

export type QueryBundleItemsArgs = {
  input: BundleItemsInput;
};

export type QueryBundlesArgs = {
  input: BundlesInput;
};

export type QueryBundleVisibilityGroupArgs = {
  input: BundleVisibilityGroupQueryInput;
};

export type QueryClosedRequestMetricsArgs = {
  input: MetricsInput;
};

export type QueryConfigurationTemplateArgs = {
  input: ConfigurationTemplateInput;
};

export type QueryConnectionArgs = {
  input: ConnectionInput;
};

export type QueryConnectionsArgs = {
  input: ConnectionsInput;
};

export type QueryCountFilteredConnectionsArgs = {
  input: CountFilteredConnectionsInput;
};

export type QueryCountFilteredGroupsArgs = {
  input: CountFilteredGroupsInput;
};

export type QueryCountFilteredResourcesArgs = {
  input: CountFilteredResourcesInput;
};

export type QueryEntitiesForRiskScoreRangeArgs = {
  maxRiskScore: Scalars["Int"];
  minRiskScore: Scalars["Int"];
};

export type QueryEntityForRemediationArgs = {
  entityKey: RecommendationsEntityKey;
};

export type QueryEventArgs = {
  input: EventInput;
};

export type QueryEventFilterArgs = {
  input: EventFilterInput;
};

export type QueryEventsArgs = {
  input: EventsInput;
};

export type QueryFilteredConnectionsArgs = {
  input: FilteredConnectionsInput;
};

export type QueryFilteredGroupsArgs = {
  input: FilteredGroupsInput;
};

export type QueryFilteredResourcesArgs = {
  input: FilteredResourcesInput;
};

export type QueryGroupArgs = {
  input: GroupInput;
};

export type QueryGroupAccessLevelsArgs = {
  input: GroupAccessLevelsInput;
};

export type QueryGroupBindingArgs = {
  input: Scalars["GroupBindingId"];
};

export type QueryGroupBindingsArgs = {
  input: GroupBindingsInput;
};

export type QueryGroupBindingSuggestionArgs = {
  input: Scalars["GroupBindingSuggestionId"];
};

export type QueryGroupBindingSuggestionsArgs = {
  input: GroupBindingSuggestionsInput;
};

export type QueryGroupGroupAccessArgs = {
  input: GroupGroupAccessInput;
};

export type QueryGroupRequestStatusCountsArgs = {
  input: GroupRequestStatusCountsInput;
};

export type QueryGroupsArgs = {
  input: GroupsInput;
};

export type QueryGroupTypesWithCountsArgs = {
  input: GroupTypesWithCountsInput;
};

export type QueryMessageChannelsArgs = {
  input: MessageChannelsInput;
};

export type QueryMostRequestedGroupsArgs = {
  input: MetricsInput;
};

export type QueryMostRequestedResourcesArgs = {
  input: MetricsInput;
};

export type QueryMultipleAccessLevelsArgs = {
  input: MultipleResourceAccessLevelsInput;
};

export type QueryMultipleGroupAccessLevelsArgs = {
  input: MultipleGroupAccessLevelsInput;
};

export type QueryMyAccessReviewItemsArgs = {
  input: MyAccessReviewItemsInput;
};

export type QueryOidcProviderArgs = {
  input: OidcProviderInput;
};

export type QueryOngoingAccessReviewSubtabStatsArgs = {
  input: OngoingAccessReviewSubtabStatsInput;
};

export type QueryOngoingAccessReviewTabStatsArgs = {
  input: OngoingAccessReviewTabStatsInput;
};

export type QueryOwnerArgs = {
  input: OwnerInput;
};

export type QueryOwnersArgs = {
  input: OwnersInput;
};

export type QueryPrincipalsArgs = {
  input: PrincipalsInput;
};

export type QueryPushTaskArgs = {
  input: PushTaskInput;
};

export type QueryRemoteResourcesArgs = {
  input: RemoteResourcesInput;
};

export type QueryRequestArgs = {
  input: RequestInput;
};

export type QueryRequestApprovalTimesArgs = {
  input: MetricsInput;
};

export type QueryRequestDefaultsArgs = {
  input: RequestDefaultsInput;
};

export type QueryRequestMetricsArgs = {
  input: MetricsInput;
};

export type QueryRequestsArgs = {
  input: RequestsInput;
};

export type QueryRequestTemplateArgs = {
  input: RequestTemplateInput;
};

export type QueryRequestTemplatesWithFieldsArgs = {
  input: RequestTemplatesWithFieldsInput;
};

export type QueryResourceArgs = {
  input: ResourceInput;
};

export type QueryResourceAncestorsArgs = {
  input: ResourceInput;
};

export type QueryResourceCustomAccessLevelArgs = {
  input: ResourceCustomAccessLevelInput;
};

export type QueryResourceCustomAccessLevelsArgs = {
  input: ResourceCustomAccessLevelsInput;
};

export type QueryResourceRequestStatusCountsArgs = {
  input: ResourceRequestStatusCountsInput;
};

export type QueryResourcesArgs = {
  input: ResourcesInput;
};

export type QueryResourceTagsArgs = {
  input: ResourceTagsInput;
};

export type QueryResourceTypesWithCountsArgs = {
  input: ResourceTypesWithCountsInput;
};

export type QueryResourceUsageArgs = {
  input: ResourceUsageInput;
};

export type QuerySearchArgs = {
  input: SearchInput;
};

export type QuerySessionsArgs = {
  input: SessionsInput;
};

export type QuerySignInMethodArgs = {
  input: SignInMethodInput;
};

export type QueryStartAccessReviewStatsArgs = {
  input: StartAccessReviewStatsInput;
};

export type QuerySuggestedAccessToRemediateForEntityArgs = {
  entityKey: RecommendationsEntityKey;
  type: RecommendationsSubscoreType;
};

export type QuerySupportTicketArgs = {
  input: SupportTicketByIdInput;
};

export type QuerySupportTicketsArgs = {
  input: SupportTicketsInput;
};

export type QuerySyncStatusArgs = {
  input: SyncStatusInput;
};

export type QuerySyncTaskArgs = {
  input: SyncTaskInput;
};

export type QueryTagArgs = {
  input: TagInput;
};

export type QueryTagsArgs = {
  input: TagsInput;
};

export type QueryTeamsArgs = {
  input: TeamsInput;
};

export type QueryThirdPartyIntegrationArgs = {
  input: ThirdPartyIntegrationInput;
};

export type QueryThirdPartyIntegrationsArgs = {
  input: ThirdPartyIntegrationsInput;
};

export type QueryTicketProjectsArgs = {
  input: SupportTicketsInput;
};

export type QueryTitlesArgs = {
  input: TitlesInput;
};

export type QueryTopBulkSuggestionsArgs = {
  appId?: InputMaybe<AppIdInput>;
  groupType?: InputMaybe<GroupType>;
  maxRiskScore?: InputMaybe<Scalars["Int"]>;
  minRiskScore?: InputMaybe<Scalars["Int"]>;
  resourceType?: InputMaybe<ResourceType>;
  riskTypes?: InputMaybe<Array<InputMaybe<RecommendationsSubscoreType>>>;
  tagID?: InputMaybe<TagInput>;
};

export type QueryUserArgs = {
  input: UserInput;
};

export type QueryUsersArgs = {
  input: UsersInput;
};

export type QueryUserTagsArgs = {
  input: UserTagsInput;
};

export type QueryVaultSessionsArgs = {
  input?: InputMaybe<VaultSessionsInput>;
};

export type QueryVisualizationDataArgs = {
  input: VisualizationFilter;
};

export type ReadUiNotificationInput = {
  trackingId: Scalars["UUID"];
};

export type ReadUiNotificationOutput = ReadUiNotificationResult;

export type ReadUiNotificationResult = {
  __typename?: "ReadUINotificationResult";
  success: Scalars["Boolean"];
};

export type ReadUiNotificationsInput = {
  markAllAsRead: Scalars["Boolean"];
  notificationIds: Array<Scalars["UUID"]>;
};

export type ReadUiNotificationsOutput = ReadUiNotificationsResult;

export type ReadUiNotificationsResult = {
  __typename?: "ReadUINotificationsResult";
  success: Scalars["Boolean"];
};

export type RecommendationsBulkSuggestion = {
  __typename?: "RecommendationsBulkSuggestion";
  entity: RecommendationsEntity;
  targetUserCount: Scalars["Int"];
  totalUserCount: Scalars["Int"];
  type: RecommendationsSubscoreType;
};

export type RecommendationsBulkSuggestionsResult = {
  __typename?: "RecommendationsBulkSuggestionsResult";
  suggestions: Array<RecommendationsBulkSuggestion>;
};

export type RecommendationsEntity = Group | Resource;

export type RecommendationsEntityKey = {
  entityId: Scalars["UUID"];
  entityType: RecommendationsEntityType;
};

export type RecommendationsEntityKeyOutput = {
  __typename?: "RecommendationsEntityKeyOutput";
  entityId: Scalars["UUID"];
  entityType: RecommendationsEntityType;
};

export type RecommendationsEntityMetadata = {
  __typename?: "RecommendationsEntityMetadata";
  entityKey: RecommendationsEntityKeyOutput;
  riskScore: Scalars["Int"];
  suggestionType: RecommendationsSubscoreType;
};

export enum RecommendationsEntityType {
  App = "APP",
  Group = "GROUP",
  Organization = "ORGANIZATION",
  Resource = "RESOURCE",
  User = "USER",
}

export enum RecommendationsFeedbackType {
  DismissSuggestionNotUsingAccess = "DISMISS_SUGGESTION_NOT_USING_ACCESS",
  DismissSuggestionPerpetualAndUnusedAccess = "DISMISS_SUGGESTION_PERPETUAL_AND_UNUSED_ACCESS",
  DismissSuggestionUnmanagedAccess = "DISMISS_SUGGESTION_UNMANAGED_ACCESS",
  DismissSuggestionUnusedAccess = "DISMISS_SUGGESTION_UNUSED_ACCESS",
  RemediateSuggestionNotUsingAccess = "REMEDIATE_SUGGESTION_NOT_USING_ACCESS",
  RemediateSuggestionPerpetualAndUnusedAccess = "REMEDIATE_SUGGESTION_PERPETUAL_AND_UNUSED_ACCESS",
  RemediateSuggestionUnmanagedAccess = "REMEDIATE_SUGGESTION_UNMANAGED_ACCESS",
  RemediateSuggestionUnusedAccess = "REMEDIATE_SUGGESTION_UNUSED_ACCESS",
}

export type RecommendationsMetricData = {
  __typename?: "RecommendationsMetricData";
  date: Scalars["Time"];
  metricType: RecommendationsMetricType;
  value: Scalars["Int"];
};

export type RecommendationsMetricsGraph = {
  __typename?: "RecommendationsMetricsGraph";
  perpetualAccessGraph: Array<RecommendationsMetricData>;
  timeboundAccessGraph: Array<RecommendationsMetricData>;
};

export enum RecommendationsMetricsType {
  TimeBoundAccess = "TIME_BOUND_ACCESS",
  UnboundAccess = "UNBOUND_ACCESS",
}

export enum RecommendationsMetricType {
  AccessPointsConvertedToJit = "ACCESS_POINTS_CONVERTED_TO_JIT",
  AccessPointsConvertedToJitByThreatCenter = "ACCESS_POINTS_CONVERTED_TO_JIT_BY_THREAT_CENTER",
  AccessPointsConvertedToJitFromSuggestions = "ACCESS_POINTS_CONVERTED_TO_JIT_FROM_SUGGESTIONS",
  AccessPointsRevoked = "ACCESS_POINTS_REVOKED",
  AccessPointsRevokedByThreatCenter = "ACCESS_POINTS_REVOKED_BY_THREAT_CENTER",
  AccessPointsRevokedFromSuggestions = "ACCESS_POINTS_REVOKED_FROM_SUGGESTIONS",
  EntitiesWithCriticalRiskScore = "ENTITIES_WITH_CRITICAL_RISK_SCORE",
  EntitiesWithHighRiskScore = "ENTITIES_WITH_HIGH_RISK_SCORE",
  EntitiesWithLowRiskScore = "ENTITIES_WITH_LOW_RISK_SCORE",
  EntitiesWithMediumRiskScore = "ENTITIES_WITH_MEDIUM_RISK_SCORE",
  LicensesRevoked = "LICENSES_REVOKED",
  LicensesRevokedByThreatCenter = "LICENSES_REVOKED_BY_THREAT_CENTER",
  NotUsingAccessPointsConvertedToJit = "NOT_USING_ACCESS_POINTS_CONVERTED_TO_JIT",
  NotUsingAccessPointsRevoked = "NOT_USING_ACCESS_POINTS_REVOKED",
  PerpetualAndUnusedAccessPointsConvertedToJit = "PERPETUAL_AND_UNUSED_ACCESS_POINTS_CONVERTED_TO_JIT",
  PerpetualAndUnusedAccessPointsRevoked = "PERPETUAL_AND_UNUSED_ACCESS_POINTS_REVOKED",
  RemediationsPerformedSuggestionPerpetualAndUnusedAccess = "REMEDIATIONS_PERFORMED_SUGGESTION_PERPETUAL_AND_UNUSED_ACCESS",
  RemediationsPerformedSuggestionSuggestionNotUsingAccess = "REMEDIATIONS_PERFORMED_SUGGESTION_SUGGESTION_NOT_USING_ACCESS",
  RemediationsPerformedSuggestionSuggestionUnusedAccess = "REMEDIATIONS_PERFORMED_SUGGESTION_SUGGESTION_UNUSED_ACCESS",
  RemediationsPerformedSuggestionUnmanagedAccess = "REMEDIATIONS_PERFORMED_SUGGESTION_UNMANAGED_ACCESS",
  SumPerpetualAccess = "SUM_PERPETUAL_ACCESS",
  SumTimeBoundAccess = "SUM_TIME_BOUND_ACCESS",
  SumUnusedAccess = "SUM_UNUSED_ACCESS",
  TotalThreatCount = "TOTAL_THREAT_COUNT",
  UnmanagedAccessPointsConvertedToJit = "UNMANAGED_ACCESS_POINTS_CONVERTED_TO_JIT",
  UnmanagedAccessPointsRevoked = "UNMANAGED_ACCESS_POINTS_REVOKED",
  UnusedAccessPointsConvertedToJit = "UNUSED_ACCESS_POINTS_CONVERTED_TO_JIT",
  UnusedAccessPointsRevoked = "UNUSED_ACCESS_POINTS_REVOKED",
}

export type RecommendationsSubscoreTaskPayload = {
  __typename?: "RecommendationsSubscoreTaskPayload";
  organizationId: Scalars["OrganizationId"];
  updatedAt: Scalars["Time"];
};

export enum RecommendationsSubscoreType {
  IrregularAccess = "IRREGULAR_ACCESS",
  PerpetualAccess = "PERPETUAL_ACCESS",
  ResourceVulnerability = "RESOURCE_VULNERABILITY",
  SuggestionNotUsingAccess = "SUGGESTION_NOT_USING_ACCESS",
  SuggestionPerpetualAndUnusedAccess = "SUGGESTION_PERPETUAL_AND_UNUSED_ACCESS",
  SuggestionUnmanagedAccess = "SUGGESTION_UNMANAGED_ACCESS",
  SuggestionUnusedAccess = "SUGGESTION_UNUSED_ACCESS",
  UnmanagedAccess = "UNMANAGED_ACCESS",
  UnusedAccess = "UNUSED_ACCESS",
}

export type RecordGroupViewInput = {
  groupId: Scalars["GroupId"];
};

export type RecordGroupViewOutput = RecordGroupViewResult;

export type RecordGroupViewResult = {
  __typename?: "RecordGroupViewResult";
  success: Scalars["Boolean"];
};

export type RecordResourceViewInput = {
  resourceId: Scalars["ResourceId"];
};

export type RecordResourceViewOutput = RecordResourceViewResult;

export type RecordResourceViewResult = {
  __typename?: "RecordResourceViewResult";
  success: Scalars["Boolean"];
};

export type RegenerateAccessReviewReportInput = {
  accessReviewId: Scalars["AccessReviewId"];
};

export type RegenerateAccessReviewReportOutput =
  | AccessReviewNotFoundError
  | AccessReviewNotStoppedError
  | RegenerateAccessReviewReportResult;

export type RegenerateAccessReviewReportResult = {
  __typename?: "RegenerateAccessReviewReportResult";
  accessReview: AccessReview;
};

export type RegenerateSigningSecretOutput = RegenerateSigningSecretResult;

export type RegenerateSigningSecretResult = {
  __typename?: "RegenerateSigningSecretResult";
  webhookInfo: OrganizationWebhookInfo;
};

export type RemediateAddGroupResourcesOutput =
  | AddGroupResourcesResult
  | CallToWebhookFailedError
  | GroupNotFoundError
  | GroupResourceAlreadyExists
  | UserFacingError;

export type RemediateAddGroupUsersOutput =
  | AddGroupUsersResult
  | CallToWebhookFailedError
  | CannotAddSystemUserToGroupError
  | GroupNotFoundError
  | GroupUserAlreadyExists;

export type RemediateAddResourceUsersOutput =
  | AddResourceUsersResult
  | CallToWebhookFailedError
  | CannotAddSystemUserToResourceError
  | OpalAdminRoleMustHaveAtLeastOneDirectUser
  | OpalGlobalImpersonationResourceDirectAddNotAllowedError
  | ResourceNotFoundError
  | ResourceUserAlreadyExists;

export type RemediateAddRoleAssignmentsOutput =
  | AddRoleAssignmentsResult
  | RoleAssignmentNotFoundError;

export type RemoteAppItem = {
  __typename?: "RemoteAppItem";
  connectionId: Scalars["ConnectionId"];
  connectionType: ConnectionType;
  groupType?: Maybe<GroupType>;
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  metadata?: Maybe<Scalars["String"]>;
  remoteId: Scalars["String"];
  remoteName: Scalars["String"];
  resourceType?: Maybe<ResourceType>;
};

export type RemoteAppItemsInput = {
  filters?: InputMaybe<AppItemsFilters>;
  groupType?: InputMaybe<GroupType>;
  resourceType?: InputMaybe<ResourceType>;
};

export type RemoteAppItemsOutput = RemoteAppItemsResult | UserFacingError;

export type RemoteAppItemsResult = {
  __typename?: "RemoteAppItemsResult";
  items?: Maybe<Array<RemoteAppItem>>;
};

export type RemoteGroup = {
  __typename?: "RemoteGroup";
  groupType: GroupType;
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["RemoteGroupId"];
  metadata: Scalars["String"];
  name: Scalars["String"];
};

export type RemoteMessageChannelAlreadyLinked = Error & {
  __typename?: "RemoteMessageChannelAlreadyLinked";
  message: Scalars["String"];
};

export type RemoteMessageChannelNotFound = Error & {
  __typename?: "RemoteMessageChannelNotFound";
  message: Scalars["String"];
};

export type RemoteResource = {
  __typename?: "RemoteResource";
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["RemoteResourceId"];
  metadata: Scalars["String"];
  name: Scalars["String"];
  resourceType: ResourceType;
};

export type RemoteResourcesInput = {
  connectionId: Scalars["ConnectionId"];
  resourceTypes: Array<ResourceType>;
};

export type RemoteResourcesOutput =
  | ConnectionNotFoundError
  | MissingIamPermissionError
  | RemoteResourcesResult
  | UserFacingError;

export type RemoteResourcesResult = {
  __typename?: "RemoteResourcesResult";
  remoteResources: Array<RemoteResource>;
};

export type RemoteUserNotFound = Error & {
  __typename?: "RemoteUserNotFound";
  message: Scalars["String"];
};

export type RemoteUserNotFoundError = Error & {
  __typename?: "RemoteUserNotFoundError";
  message: Scalars["String"];
};

export type RemoveConnectionUserInput = {
  connectionId: Scalars["ConnectionId"];
  userId: Scalars["UserId"];
};

export type RemoveConnectionUsersInput = {
  connectionUsers: Array<RemoveConnectionUserInput>;
};

export type RemoveConnectionUsersOutput =
  | ConnectionNotFoundError
  | ConnectionUserNotFound
  | RemoveConnectionUsersResult;

export type RemoveConnectionUsersResult = {
  __typename?: "RemoveConnectionUsersResult";
  success: Scalars["Boolean"];
  taskId: Scalars["PushTaskId"];
};

export type RemoveGroupGroupsEntryOutput = RemoveGroupGroupsEntryResult;

export type RemoveGroupGroupsEntryResult = {
  __typename?: "RemoveGroupGroupsEntryResult";
  groupGroup: GroupGroup;
};

export type RemoveGroupGroupsInput = {
  groupGroupsIds: Array<Scalars["GroupGroupId"]>;
  noPush?: InputMaybe<Scalars["Boolean"]>;
};

export type RemoveGroupGroupsOutput =
  | CallToWebhookFailedError
  | GroupNotFoundError
  | RemoveGroupGroupsResult
  | UserFacingError;

export type RemoveGroupGroupsResult = {
  __typename?: "RemoveGroupGroupsResult";
  entries: Array<RemoveGroupGroupsEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type RemoveGroupResourceInput = {
  accessLevel: ResourceAccessLevelInput;
  groupId: Scalars["GroupId"];
  resourceId: Scalars["ResourceId"];
};

export type RemoveGroupResourcesEntryOutput = RemoveGroupResourcesEntryResult;

export type RemoveGroupResourcesEntryResult = {
  __typename?: "RemoveGroupResourcesEntryResult";
  groupResource: GroupResource;
};

export type RemoveGroupResourcesInput = {
  groupResources: Array<RemoveGroupResourceInput>;
  noPush?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<UiSource>;
};

export type RemoveGroupResourcesOutput =
  | CallToWebhookFailedError
  | GroupNotFoundError
  | RemoveGroupResourcesResult
  | UserFacingError;

export type RemoveGroupResourcesResult = {
  __typename?: "RemoveGroupResourcesResult";
  entries: Array<RemoveGroupResourcesEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type RemoveGroupTagInput = {
  groupId: Scalars["GroupId"];
  tagId: Scalars["TagId"];
};

export type RemoveGroupTagsInput = {
  groupTags: Array<RemoveGroupTagInput>;
};

export type RemoveGroupTagsOutput = RemoveGroupTagsResult;

export type RemoveGroupTagsResult = {
  __typename?: "RemoveGroupTagsResult";
  success: Scalars["Boolean"];
};

export type RemoveGroupUserInput = {
  groupId: Scalars["GroupId"];
  userId: Scalars["UserId"];
};

export type RemoveGroupUsersEntryOutput = RemoveGroupUsersEntryResult;

export type RemoveGroupUsersEntryResult = {
  __typename?: "RemoveGroupUsersEntryResult";
  groupUser: GroupUser;
};

export type RemoveGroupUsersInput = {
  groupUsers: Array<RemoveGroupUserInput>;
  noPush?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<UiSource>;
};

export type RemoveGroupUsersOutput =
  | CallToWebhookFailedError
  | GroupNotFoundError
  | RemoveGroupUsersResult;

export type RemoveGroupUsersResult = {
  __typename?: "RemoveGroupUsersResult";
  entries: Array<RemoveGroupUsersEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type RemoveResourceTagInput = {
  resourceTagId: Scalars["ResourceTagId"];
};

export type RemoveResourceTagsEntryOutput = RemoveResourceTagsEntryResult;

export type RemoveResourceTagsEntryResult = {
  __typename?: "RemoveResourceTagsEntryResult";
  resourceTag: ResourceTag;
};

export type RemoveResourceTagsInput = {
  resourceTags: Array<RemoveResourceTagInput>;
};

export type RemoveResourceTagsOutput =
  | RemoveResourceTagsResult
  | ResourceTagNotFoundError;

export type RemoveResourceTagsResult = {
  __typename?: "RemoveResourceTagsResult";
  entries: Array<RemoveResourceTagsEntryOutput>;
};

export type RemoveResourceUserInput = {
  accessLevel: ResourceAccessLevelInput;
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type RemoveResourceUsersEntryOutput = RemoveResourceUsersEntryResult;

export type RemoveResourceUsersEntryResult = {
  __typename?: "RemoveResourceUsersEntryResult";
  resourceUser: ResourceUser;
};

export type RemoveResourceUsersInput = {
  noPush?: InputMaybe<Scalars["Boolean"]>;
  resourceUsers: Array<RemoveResourceUserInput>;
  source?: InputMaybe<UiSource>;
};

export type RemoveResourceUsersOutput =
  | CallToWebhookFailedError
  | OidcIdTokenNotFoundError
  | OpalAdminRoleMustHaveAtLeastOneDirectUser
  | RemoveResourceUsersResult
  | ResourceNotFoundError;

export type RemoveResourceUsersResult = {
  __typename?: "RemoveResourceUsersResult";
  entries: Array<RemoveResourceUsersEntryOutput>;
  taskId: Scalars["PushTaskId"];
};

export type RemoveRoleAssignmentInput = {
  accessLevel?: InputMaybe<AccessLevelInput>;
  entityID: Scalars["EntityId"];
  entityType: EntityType;
  principalID: Scalars["PrincipalId"];
  principalType: EntityType;
};

export type RemoveRoleAssignmentsEntryOutput = RemoveRoleAssignmentsEntryResult;

export type RemoveRoleAssignmentsEntryResult = {
  __typename?: "RemoveRoleAssignmentsEntryResult";
  roleAssignment: RoleAssignment;
};

export type RemoveRoleAssignmentsInput = {
  noPush?: InputMaybe<Scalars["Boolean"]>;
  roleAssignments: Array<RemoveRoleAssignmentInput>;
};

export type RemoveRoleAssignmentsOutput = RemoveRoleAssignmentsResult;

export type RemoveRoleAssignmentsResult = {
  __typename?: "RemoveRoleAssignmentsResult";
  entries: Array<RemoveRoleAssignmentsEntryOutput>;
  taskIds: Array<Scalars["PushTaskId"]>;
};

export type RemoveUserTagInput = {
  userTagId: Scalars["UserTagId"];
};

export type RemoveUserTagsEntryOutput = RemoveUserTagsEntryResult;

export type RemoveUserTagsEntryResult = {
  __typename?: "RemoveUserTagsEntryResult";
  userTag: UserTag;
};

export type RemoveUserTagsInput = {
  userTags: Array<RemoveUserTagInput>;
};

export type RemoveUserTagsOutput = RemoveUserTagsResult | UserTagNotFoundError;

export type RemoveUserTagsResult = {
  __typename?: "RemoveUserTagsResult";
  entries: Array<RemoveUserTagsEntryOutput>;
};

export type Request = {
  __typename?: "Request";
  createdAt: Scalars["Time"];
  customMetadata?: Maybe<Array<RequestCustomMetadata>>;
  durationInMinutes?: Maybe<Scalars["Int"]>;
  escalatedToSkipManager: Scalars["Boolean"];
  id: Scalars["RequestId"];
  reason: Scalars["String"];
  requestComments: Array<RequestComment>;
  requestedGroups: Array<RequestedGroup>;
  requestedResources: Array<RequestedResource>;
  requester?: Maybe<User>;
  requesterId: Scalars["UserId"];
  requestTicket?: Maybe<SupportTicket>;
  requestTicketId?: Maybe<Scalars["SupportTicketId"]>;
  resourceProposal?: Maybe<ResourceProposal>;
  resourceProposalId?: Maybe<Scalars["ResourceProposalId"]>;
  reviewersError?: Maybe<Scalars["String"]>;
  stages: Array<RequestItemStages>;
  status: RequestStatus;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
  targetGroup?: Maybe<Group>;
  targetGroupId?: Maybe<Scalars["GroupId"]>;
  targetUser?: Maybe<User>;
  targetUserId?: Maybe<Scalars["UserId"]>;
  updatedAt: Scalars["Time"];
};

export type RequestAlreadyActionedError = Error & {
  __typename?: "RequestAlreadyActionedError";
  message: Scalars["String"];
};

export type RequestAlreadyEscalatedToSkipManagerError = Error & {
  __typename?: "RequestAlreadyEscalatedToSkipManagerError";
  message: Scalars["String"];
};

export type RequestApprovalTimesResult = {
  __typename?: "RequestApprovalTimesResult";
  lineData: Array<LineGraphData>;
};

export enum RequestApprovalType {
  Manager = "MANAGER",
  Owner = "OWNER",
  SkipManager = "SKIP_MANAGER",
}

export type RequestComment = {
  __typename?: "RequestComment";
  comment: Scalars["String"];
  createdAt: Scalars["Time"];
  id: Scalars["RequestCommentId"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type RequestConfigInput = {
  autoApprove?: InputMaybe<Scalars["Boolean"]>;
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  id?: InputMaybe<Scalars["RequestConfigurationId"]>;
  isRequestable?: InputMaybe<Scalars["Boolean"]>;
  maxDurationInMinutes?: InputMaybe<NullableInt>;
  priority?: InputMaybe<Scalars["Int"]>;
  reasonOptional?: InputMaybe<Scalars["Boolean"]>;
  recommendedDurationInMinutes?: InputMaybe<NullableInt>;
  requestTemplateId?: InputMaybe<NullableRequestTemplateId>;
  requireMfaToRequest?: InputMaybe<Scalars["Boolean"]>;
  requireSupportTicket?: InputMaybe<Scalars["Boolean"]>;
  reviewerStages?: InputMaybe<Array<BulkReviewerInputStage>>;
  roleIds?: InputMaybe<Array<Scalars["AccessLevelRemoteId"]>>;
};

export type RequestConfiguration = {
  __typename?: "RequestConfiguration";
  autoApproval: Scalars["Boolean"];
  groupId?: Maybe<Scalars["GroupId"]>;
  groupIds?: Maybe<Array<Scalars["GroupId"]>>;
  id: Scalars["RequestConfigurationId"];
  isRequestable: Scalars["Boolean"];
  maxDurationInMinutes?: Maybe<Scalars["Int"]>;
  priority: Scalars["Int"];
  reasonOptional: Scalars["Boolean"];
  recommendedDurationInMinutes?: Maybe<Scalars["Int"]>;
  requestTemplate?: Maybe<RequestTemplate>;
  requestTemplateId?: Maybe<Scalars["RequestTemplateId"]>;
  requireMfaToRequest: Scalars["Boolean"];
  requireSupportTicket: Scalars["Boolean"];
  reviewerStages: Array<ReviewerStage>;
  roleIds?: Maybe<Array<Scalars["AccessLevelRemoteId"]>>;
};

export type RequestConfigurationGroupInput = {
  accessLevelRemoteId?: InputMaybe<Scalars["AccessLevelRemoteId"]>;
  groupId: Scalars["GroupId"];
};

export type RequestConfigurationResourceInput = {
  accessLevelRemoteId?: InputMaybe<Scalars["AccessLevelRemoteId"]>;
  resourceId: Scalars["ResourceId"];
};

export type RequestCustomMetadata = {
  __typename?: "RequestCustomMetadata";
  fieldName: Scalars["String"];
  fieldType: RequestTemplateCustomFieldType;
  metadataValue?: Maybe<RequestCustomMetadataValue>;
};

export type RequestCustomMetadataInput = {
  fieldName: Scalars["String"];
  fieldType: RequestTemplateCustomFieldType;
  metadataValue: RequestCustomMetadataValueInput;
};

export type RequestCustomMetadataValue = {
  __typename?: "RequestCustomMetadataValue";
  booleanValue?: Maybe<BooleanValue>;
  longTextValue?: Maybe<LongTextValue>;
  multiChoiceValue?: Maybe<MultiChoiceValue>;
  shortTextValue?: Maybe<ShortTextValue>;
};

export type RequestCustomMetadataValueInput = {
  booleanValue?: InputMaybe<BooleanValueInput>;
  longTextValue?: InputMaybe<LongTextValueInput>;
  multiChoiceValue?: InputMaybe<MultiChoiceValueInput>;
  shortTextValue?: InputMaybe<ShortTextValueInput>;
};

export enum RequestDecisionLevel {
  Admin = "ADMIN",
  Regular = "REGULAR",
}

export type RequestDefaults = {
  __typename?: "RequestDefaults";
  defaultDurationInMinutes: Scalars["Int"];
  durationOptions: Array<DurationOption>;
  maxDurationInMinutes?: Maybe<Scalars["Int"]>;
  messages: Array<RequestMessage>;
  reasonOptional: Scalars["Boolean"];
  recommendedDurationInMinutes?: Maybe<Scalars["Int"]>;
  requireSupportTicket: Scalars["Boolean"];
};

export type RequestDefaultsInput = {
  requestedGroups: Array<RequestConfigurationGroupInput>;
  requestedResources: Array<RequestConfigurationResourceInput>;
  targetUserId: Scalars["UserId"];
};

export type RequestDurationTooLargeError = Error & {
  __typename?: "RequestDurationTooLargeError";
  message: Scalars["String"];
};

export type RequestedGroup = {
  __typename?: "RequestedGroup";
  accessLevel?: Maybe<GroupAccessLevel>;
  group?: Maybe<Group>;
  groupId: Scalars["GroupId"];
  propagationStatus?: Maybe<GroupPropagationStatus>;
  targetUserAccess?: Maybe<UserGroupAccess>;
};

export type RequestedGroupInput = {
  accessLevel?: InputMaybe<GroupAccessLevelInput>;
  groupId: Scalars["GroupId"];
};

export type RequestedResource = {
  __typename?: "RequestedResource";
  accessLevel: ResourceAccessLevel;
  propagationStatus?: Maybe<ResourcePropagationStatus>;
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  targetUserAccess?: Maybe<UserResourceAccess>;
};

export type RequestedResourceInput = {
  accessLevel: ResourceAccessLevelInput;
  resourceId: Scalars["ResourceId"];
};

export type RequestFieldValueMissingError = Error & {
  __typename?: "RequestFieldValueMissingError";
  fieldName: Scalars["String"];
  groupIds?: Maybe<Array<Scalars["GroupId"]>>;
  message: Scalars["String"];
  requestTemplateID: Scalars["RequestTemplateId"];
  resourceIds?: Maybe<Array<Scalars["ResourceId"]>>;
};

export type RequestInput = {
  id: Scalars["RequestId"];
};

export type RequestItemStages = {
  __typename?: "RequestItemStages";
  requestedItemName: Scalars["String"];
  requestedRoleName?: Maybe<Scalars["String"]>;
  stages: Array<RequestStage>;
};

export type RequestMessage = {
  __typename?: "RequestMessage";
  code: RequestMessageCode;
  entityId?: Maybe<Scalars["UUID"]>;
  level: RequestMessageLevel;
};

export enum RequestMessageCode {
  NestedGroupAccessNotAllowed = "NESTED_GROUP_ACCESS_NOT_ALLOWED",
  RequestResetsAccessDuration = "REQUEST_RESETS_ACCESS_DURATION",
  RequireUserAuthToken = "REQUIRE_USER_AUTH_TOKEN",
}

export enum RequestMessageLevel {
  Error = "ERROR",
  Info = "INFO",
  Warning = "WARNING",
}

export type RequestMetricsResult = {
  __typename?: "RequestMetricsResult";
  approvedLineData: Array<LineGraphData>;
  canceledLineData: Array<LineGraphData>;
  deniedLineData: Array<LineGraphData>;
  totalApproved: Scalars["Int"];
  totalCanceled: Scalars["Int"];
  totalDenied: Scalars["Int"];
};

export type RequestNotFoundError = Error & {
  __typename?: "RequestNotFoundError";
  message: Scalars["String"];
};

export type RequestOutput = RequestNotFoundError | RequestResult;

export type RequestReasonMissingError = Error & {
  __typename?: "RequestReasonMissingError";
  groupIds?: Maybe<Array<Scalars["GroupId"]>>;
  message: Scalars["String"];
  resourceIds?: Maybe<Array<Scalars["ResourceId"]>>;
};

export type RequestRequiresUserAuthTokenForConnectionError = Error & {
  __typename?: "RequestRequiresUserAuthTokenForConnectionError";
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  message: Scalars["String"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type RequestResult = {
  __typename?: "RequestResult";
  request: Request;
};

export type RequestReviewer = {
  __typename?: "RequestReviewer";
  owner?: Maybe<Owner>;
  ownerId?: Maybe<Scalars["OwnerId"]>;
  reviewerAction?: Maybe<ReviewerAction>;
  reviewerType: RequestApprovalType;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type RequestsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  requestType?: InputMaybe<RequestType>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  showPendingOnly?: InputMaybe<Scalars["Boolean"]>;
  sortBy?: InputMaybe<RequestsSortBy>;
};

export type RequestsOutput = RequestsResult;

export type RequestsResult = {
  __typename?: "RequestsResult";
  cursor?: Maybe<Scalars["String"]>;
  requests: Array<Request>;
  requestType?: Maybe<RequestType>;
};

export type RequestsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<RequestsSortByField>;
};

export enum RequestsSortByField {
  CreatedAt = "CREATED_AT",
  UpdatedAt = "UPDATED_AT",
}

export type RequestStage = {
  __typename?: "RequestStage";
  operator: ReviewStageOperator;
  reviewers: Array<RequestReviewer>;
  stage: Scalars["Int"];
};

export enum RequestStatus {
  Approved = "APPROVED",
  Canceled = "CANCELED",
  Denied = "DENIED",
  Pending = "PENDING",
}

export type RequestStatusCounts = {
  __typename?: "RequestStatusCounts";
  approved: Scalars["Int"];
  canceled: Scalars["Int"];
  denied: Scalars["Int"];
  pending: Scalars["Int"];
};

export type RequestTemplate = {
  __typename?: "RequestTemplate";
  customFields?: Maybe<Array<RequestTemplateCustomField>>;
  id: Scalars["RequestTemplateId"];
  isDeleted?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type RequestTemplateCustomField = {
  __typename?: "RequestTemplateCustomField";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["RequestTemplateCustomFieldId"];
  metadata?: Maybe<RequestTemplateCustomFieldMetadata>;
  name: Scalars["String"];
  required?: Maybe<Scalars["Boolean"]>;
  type: RequestTemplateCustomFieldType;
};

export type RequestTemplateCustomFieldInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["RequestTemplateCustomFieldId"]>;
  metadata?: InputMaybe<RequestTemplateCustomFieldMetadataInput>;
  name: Scalars["String"];
  required?: InputMaybe<Scalars["Boolean"]>;
  type: RequestTemplateCustomFieldType;
};

export type RequestTemplateCustomFieldMetadata = {
  __typename?: "RequestTemplateCustomFieldMetadata";
  multiChoiceData?: Maybe<RequestTemplateCustomFieldMultiChoiceMetadata>;
};

export type RequestTemplateCustomFieldMetadataInput = {
  multiChoiceData?: InputMaybe<
    RequestTemplateCustomFieldMultiChoiceMetadataInput
  >;
};

export type RequestTemplateCustomFieldMultiChoiceMetadata = {
  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
  options?: Maybe<Array<MultiChoiceOption>>;
};

export type RequestTemplateCustomFieldMultiChoiceMetadataInput = {
  options?: InputMaybe<Array<MultiChoiceOptionInput>>;
};

export enum RequestTemplateCustomFieldType {
  Boolean = "BOOLEAN",
  LongText = "LONG_TEXT",
  MultiChoice = "MULTI_CHOICE",
  ShortText = "SHORT_TEXT",
}

export type RequestTemplateInput = {
  id: Scalars["RequestTemplateId"];
};

export type RequestTemplateNameExistsError = Error & {
  __typename?: "RequestTemplateNameExistsError";
  message: Scalars["String"];
};

export type RequestTemplateOutput = RequestTemplateResult;

export type RequestTemplateResult = {
  __typename?: "RequestTemplateResult";
  requestTemplate?: Maybe<RequestTemplate>;
};

export type RequestTemplateSimple = {
  __typename?: "RequestTemplateSimple";
  id: Scalars["RequestTemplateId"];
  name: Scalars["String"];
};

export type RequestTemplatesOutput = RequestTemplatesResult;

export type RequestTemplatesResult = {
  __typename?: "RequestTemplatesResult";
  requestTemplates: Array<RequestTemplateSimple>;
};

export type RequestTemplatesWithFieldsInput = {
  fetchAll?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<Scalars["RequestTemplateId"]>>;
};

export type RequestTemplatesWithFieldsOutput = RequestTemplatesWithFieldsResult;

export type RequestTemplatesWithFieldsResult = {
  __typename?: "RequestTemplatesWithFieldsResult";
  requestTemplates: Array<RequestTemplate>;
};

export enum RequestType {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export type RescoreEntityOutput = {
  __typename?: "RescoreEntityOutput";
  success: Scalars["Boolean"];
};

export type RescoreRecommendationsOutput = RescoreRecommendationsResult;

export type RescoreRecommendationsResult = {
  __typename?: "RescoreRecommendationsResult";
  success: Scalars["Boolean"];
};

export type ResetAuth0MfaError = Error & {
  __typename?: "ResetAuth0MFAError";
  message: Scalars["String"];
};

export type ResetDataseedOutput = ResetDataseedResult;

export type ResetDataseedResult = {
  __typename?: "ResetDataseedResult";
  success: Scalars["Boolean"];
};

export type ResetRemediationsOutput = ResetRemediationsResult;

export type ResetRemediationsResult = {
  __typename?: "ResetRemediationsResult";
  success: Scalars["Boolean"];
};

export type ResetUserMfaInput = {
  id: Scalars["UserId"];
};

export type ResetUserMfaOutput =
  | ResetAuth0MfaError
  | ResetUserMfaResult
  | SystemUserIsImmutableError
  | UserNotFoundError;

export type ResetUserMfaResult = {
  __typename?: "ResetUserMFAResult";
  ok: Scalars["Boolean"];
};

export type Resource = {
  __typename?: "Resource";
  accessLevels?: Maybe<Array<ResourceAccessLevel>>;
  accessStats?: Maybe<ResourceAccessStats>;
  adminOwner?: Maybe<Owner>;
  adminOwnerId: Scalars["OwnerId"];
  ancestorPathToResource?: Maybe<Scalars["String"]>;
  auditMessageChannels?: Maybe<Array<MessageChannel>>;
  authorizedActions?: Maybe<Array<Scalars["AuthorizedAction"]>>;
  autoApproval: Scalars["Boolean"];
  childrenDefaultConfigTemplate?: Maybe<ConfigurationTemplate>;
  childResources?: Maybe<Array<Resource>>;
  commonMetadata: CommonMetadata;
  configTemplate?: Maybe<ConfigurationTemplate>;
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  containingGroups: Array<GroupResource>;
  createdAt: Scalars["Time"];
  currentUserAccess: CurrentUserResourceAccess;
  customRequestNotification?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  entityAssignmentsForPrincipal: Array<RoleAssignment>;
  hasVisibleChildren: Scalars["Boolean"];
  iconUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ResourceId"];
  isDeleted: Scalars["Boolean"];
  isManaged: Scalars["Boolean"];
  isRequestable: Scalars["Boolean"];
  maxDurationInMinutes?: Maybe<Scalars["Int"]>;
  metadata?: Maybe<ResourceMetadata>;
  name: Scalars["String"];
  numChildResources: Scalars["Int"];
  numDescendantResources: Scalars["Int"];
  numEntityAssignmentsForPrincipal: Scalars["Int"];
  numResourceUsers: Scalars["Int"];
  ownerTeam?: Maybe<Group>;
  paginatedResourceUsers: PaginatedResourceUsersOutput;
  parentResource?: Maybe<Resource>;
  parentResourceId?: Maybe<Scalars["ResourceId"]>;
  principalAssignmentsForEntity: Array<RoleAssignment>;
  reasonOptional: Scalars["Boolean"];
  recommendedDurationInMinutes?: Maybe<Scalars["Int"]>;
  remoteId: Scalars["RemoteResourceId"];
  remoteName: Scalars["String"];
  requestConfigs: Array<RequestConfiguration>;
  requestTemplate?: Maybe<RequestTemplate>;
  requestTemplateId?: Maybe<Scalars["RequestTemplateId"]>;
  requireMfaToApprove: Scalars["Boolean"];
  requireMfaToConnect: Scalars["Boolean"];
  requireMfaToRequest: Scalars["Boolean"];
  requireSupportTicket: Scalars["Boolean"];
  resourceType: ResourceType;
  /** @deprecated Use paginatedResourceUsers instead. */
  resourceUsers: Array<ResourceUser>;
  reviewerStages: Array<ReviewerStage>;
  riskScore?: Maybe<Scalars["Int"]>;
  riskSensitivity?: Maybe<RiskSensitivityDetails>;
  serviceType: ServiceType;
  tags: Array<ResourceTag>;
  ticketPropagation?: Maybe<TicketPropagation>;
  updatedAt: Scalars["Time"];
  useParentConfig: Scalars["Boolean"];
  visibility: Visibility;
  visibilityGroups: Array<ConfigurationVisibilityGroup>;
};

export type ResourcePaginatedResourceUsersArgs = {
  input?: InputMaybe<PaginatedResourceUsersInput>;
};

export type ResourceAccessAlreadyReviewedError = Error & {
  __typename?: "ResourceAccessAlreadyReviewedError";
  message: Scalars["String"];
};

export type ResourceAccessLevel = {
  __typename?: "ResourceAccessLevel";
  accessLevelMetadata?: Maybe<AccessLevelMetadata>;
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type ResourceAccessLevelInput = {
  accessLevelName: Scalars["AccessLevelName"];
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
};

export type ResourceAccessLevelsInput = {
  onlyMine?: InputMaybe<Scalars["Boolean"]>;
  onlyRequestableTargetUser?: InputMaybe<Scalars["UserId"]>;
  resourceId: Scalars["ResourceId"];
  skipCustomAccessLevels?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceAccessLevelsOutput =
  | ResourceAccessLevelsResult
  | ResourceNotFoundError;

export type ResourceAccessLevelsResult = {
  __typename?: "ResourceAccessLevelsResult";
  accessLevels: Array<ResourceAccessLevel>;
  resourceId: Scalars["ResourceId"];
};

export type ResourceAccessStats = {
  __typename?: "ResourceAccessStats";
  resourceId: Scalars["ResourceId"];
  teamAccessCount?: Maybe<Scalars["Int"]>;
  titleAccessCount?: Maybe<Scalars["Int"]>;
  totalAccessCount?: Maybe<Scalars["Int"]>;
};

export type ResourceCustomAccessLevel = {
  __typename?: "ResourceCustomAccessLevel";
  accessLevel: ResourceAccessLevel;
  id: Scalars["ResourceCustomAccessLevelId"];
  policy?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["Int"]>;
  resourceId: Scalars["ResourceId"];
  updatedAt: Scalars["Time"];
};

export type ResourceCustomAccessLevelAlreadyExistsError = Error & {
  __typename?: "ResourceCustomAccessLevelAlreadyExistsError";
  message: Scalars["String"];
};

export type ResourceCustomAccessLevelInput = {
  id: Scalars["ResourceCustomAccessLevelId"];
};

export type ResourceCustomAccessLevelNotFoundError = Error & {
  __typename?: "ResourceCustomAccessLevelNotFoundError";
  message: Scalars["String"];
};

export type ResourceCustomAccessLevelOutput =
  | ResourceCustomAccessLevelNotFoundError
  | ResourceCustomAccessLevelResult;

export type ResourceCustomAccessLevelPriorityError = Error & {
  __typename?: "ResourceCustomAccessLevelPriorityError";
  message: Scalars["String"];
};

export type ResourceCustomAccessLevelResult = {
  __typename?: "ResourceCustomAccessLevelResult";
  resourceCustomAccessLevel: ResourceCustomAccessLevel;
};

export type ResourceCustomAccessLevelsInput = {
  resourceId: Scalars["ResourceId"];
};

export type ResourceCustomAccessLevelsOutput = ResourceCustomAccessLevelsResult;

export type ResourceCustomAccessLevelsResult = {
  __typename?: "ResourceCustomAccessLevelsResult";
  resourceCustomAccessLevels: Array<ResourceCustomAccessLevel>;
};

export type ResourceInput = {
  id?: InputMaybe<Scalars["ResourceId"]>;
};

export type ResourceMaxDurationTooLargeError = Error & {
  __typename?: "ResourceMaxDurationTooLargeError";
  message: Scalars["String"];
};

export type ResourceMetadata =
  | AwsRoleMetadata
  | OktaDirectoryAppMetadata
  | OktaRoleMetadata
  | PropagationTicketOwnerMetadata;

export type ResourceNotFoundError = Error & {
  __typename?: "ResourceNotFoundError";
  message: Scalars["String"];
};

export type ResourceOutput = ResourceNotFoundError | ResourceResult;

export type ResourcePropagationStatus = {
  __typename?: "ResourcePropagationStatus";
  accessLevelRemoteId: Scalars["AccessLevelRemoteId"];
  errorMessage?: Maybe<Scalars["String"]>;
  lastSynced: Scalars["Time"];
  metadata?: Maybe<ResourceUserPropagationMetadata>;
  resourceId: Scalars["ResourceId"];
  statusCode: PropagationStatusCode;
  taskType: PropagationTaskType;
  userId: Scalars["UserId"];
};

export type ResourceProposal = {
  __typename?: "ResourceProposal";
  adminOwnerId: Scalars["OwnerId"];
  connectionId: Scalars["ConnectionId"];
  id: Scalars["ResourceProposalId"];
  metadata?: Maybe<ResourceProposalMetadata>;
  resourceType: ResourceType;
  serviceType: ServiceType;
};

export type ResourceProposalMetadata = AwsRoleProposalMetadata;

export type ResourceProposalMetadataInput = {
  awsRole?: InputMaybe<AwsRoleProposalMetadataInput>;
};

export type ResourceRequestConfigurationOutput = {
  __typename?: "ResourceRequestConfigurationOutput";
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  requestConfiguration: RequestConfiguration;
  resourceId: Scalars["ResourceId"];
};

export type ResourceRequestStatusCountsInput = {
  resourceId: Scalars["ResourceId"];
};

export type ResourceRequestStatusCountsOutput =
  | ResourceNotFoundError
  | ResourceRequestStatusCountsResult;

export type ResourceRequestStatusCountsResult = {
  __typename?: "ResourceRequestStatusCountsResult";
  requestStatusCounts: RequestStatusCounts;
};

export type ResourceResult = {
  __typename?: "ResourceResult";
  resource: Resource;
};

export type ResourceRoleKey = {
  __typename?: "ResourceRoleKey";
  accessLevelRemoteId?: Maybe<Scalars["String"]>;
  resourceId: Scalars["ResourceId"];
};

export type ResourcesInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  connectionType?: InputMaybe<ConnectionType>;
  cursor?: InputMaybe<Scalars["String"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  nonHumanIdentitiesOnly?: InputMaybe<Scalars["Boolean"]>;
  onlyMine?: InputMaybe<Scalars["Boolean"]>;
  parentResourceId?: InputMaybe<ParentResourceInput>;
  resourceIds?: InputMaybe<Array<Scalars["ResourceId"]>>;
  resourceTypes?: InputMaybe<Array<ResourceType>>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  serviceType?: InputMaybe<ServiceType>;
  tag?: InputMaybe<TagFilter>;
  unmanagedOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceSmall = {
  __typename?: "ResourceSmall";
  id: Scalars["ResourceId"];
  name: Scalars["String"];
};

export type ResourcesOutput = ResourcesResult;

export type ResourcesResult = {
  __typename?: "ResourcesResult";
  cursor?: Maybe<Scalars["String"]>;
  resources: Array<Resource>;
  totalNumResources: Scalars["Int"];
};

export type ResourceSuggestion = {
  __typename?: "ResourceSuggestion";
  name: Scalars["String"];
  parentResourceName?: Maybe<Scalars["String"]>;
  resourceId: Scalars["ResourceId"];
  resourceType: ResourceType;
};

export type ResourceTag = {
  __typename?: "ResourceTag";
  createdAt: Scalars["Time"];
  creatorUserId: Scalars["UserId"];
  id: Scalars["ResourceTagId"];
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  source: ServiceType;
  tag?: Maybe<Tag>;
  tagId: Scalars["TagId"];
  updatedAt: Scalars["Time"];
};

export type ResourceTagKeyExistsError = Error & {
  __typename?: "ResourceTagKeyExistsError";
  message: Scalars["String"];
};

export type ResourceTagNotFoundError = Error & {
  __typename?: "ResourceTagNotFoundError";
  message: Scalars["String"];
};

export type ResourceTagsInput = {
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
};

export type ResourceTagsOutput = ResourceTagsResult;

export type ResourceTagsResult = {
  __typename?: "ResourceTagsResult";
  resourceTags: Array<ResourceTag>;
};

export type ResourceTicketPropagation = {
  __typename?: "ResourceTicketPropagation";
  resourceId: Scalars["ResourceId"];
  ticket?: Maybe<SupportTicket>;
  ticketId: Scalars["SupportTicketId"];
};

export enum ResourceType {
  AwsAccount = "AWS_ACCOUNT",
  AwsEc2Instance = "AWS_EC2_INSTANCE",
  AwsEksCluster = "AWS_EKS_CLUSTER",
  AwsIamRole = "AWS_IAM_ROLE",
  AwsRdsMysqlCluster = "AWS_RDS_MYSQL_CLUSTER",
  AwsRdsMysqlInstance = "AWS_RDS_MYSQL_INSTANCE",
  AwsRdsPostgresCluster = "AWS_RDS_POSTGRES_CLUSTER",
  AwsRdsPostgresInstance = "AWS_RDS_POSTGRES_INSTANCE",
  AwsSsoPermissionSet = "AWS_SSO_PERMISSION_SET",
  AzureManagementGroup = "AZURE_MANAGEMENT_GROUP",
  AzureResourceGroup = "AZURE_RESOURCE_GROUP",
  AzureSubscription = "AZURE_SUBSCRIPTION",
  AzureVirtualMachine = "AZURE_VIRTUAL_MACHINE",
  Custom = "CUSTOM",
  CustomConnector = "CUSTOM_CONNECTOR",
  GcpBigQueryDataset = "GCP_BIG_QUERY_DATASET",
  GcpBigQueryTable = "GCP_BIG_QUERY_TABLE",
  GcpBucket = "GCP_BUCKET",
  GcpCloudSqlMysqlInstance = "GCP_CLOUD_SQL_MYSQL_INSTANCE",
  GcpCloudSqlPostgresInstance = "GCP_CLOUD_SQL_POSTGRES_INSTANCE",
  GcpComputeInstance = "GCP_COMPUTE_INSTANCE",
  GcpFolder = "GCP_FOLDER",
  GcpGkeCluster = "GCP_GKE_CLUSTER",
  GcpOrganization = "GCP_ORGANIZATION",
  GcpProject = "GCP_PROJECT",
  GcpServiceAccount = "GCP_SERVICE_ACCOUNT",
  GitHubRepo = "GIT_HUB_REPO",
  GitLabProject = "GIT_LAB_PROJECT",
  GoogleWorkspaceRole = "GOOGLE_WORKSPACE_ROLE",
  MariadbInstance = "MARIADB_INSTANCE",
  MongoAtlasInstance = "MONGO_ATLAS_INSTANCE",
  MongoInstance = "MONGO_INSTANCE",
  MysqlInstance = "MYSQL_INSTANCE",
  OktaApp = "OKTA_APP",
  OktaRole = "OKTA_ROLE",
  OpalRole = "OPAL_ROLE",
  OpalScopedRole = "OPAL_SCOPED_ROLE",
  PagerdutyRole = "PAGERDUTY_ROLE",
  PostgresInstance = "POSTGRES_INSTANCE",
  SalesforcePermissionSet = "SALESFORCE_PERMISSION_SET",
  SalesforceProfile = "SALESFORCE_PROFILE",
  SalesforceRole = "SALESFORCE_ROLE",
  SnowflakeDatabase = "SNOWFLAKE_DATABASE",
  SnowflakeSchema = "SNOWFLAKE_SCHEMA",
  SnowflakeTable = "SNOWFLAKE_TABLE",
  TailscaleSsh = "TAILSCALE_SSH",
  TeleportRole = "TELEPORT_ROLE",
  WorkdayRole = "WORKDAY_ROLE",
}

export type ResourceTypesFilterInput = {
  resourceTypes: Array<ResourceType>;
};

export type ResourceTypesWithCountsInput = {
  connectionIds?: InputMaybe<Array<Scalars["ConnectionId"]>>;
  parentResourceId?: InputMaybe<ParentResourceInput>;
  query?: InputMaybe<Scalars["String"]>;
};

export type ResourceTypesWithCountsOutput = ResourceTypesWithCountsResult;

export type ResourceTypesWithCountsResult = {
  __typename?: "ResourceTypesWithCountsResult";
  resourceTypesWithCounts: Array<ResourceTypeWithCount>;
};

export type ResourceTypeWithCount = {
  __typename?: "ResourceTypeWithCount";
  connection?: Maybe<Connection>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  numResources: Scalars["Int"];
  resourceType: ResourceType;
};

export type ResourceUsageInput = {
  id: Scalars["ResourceId"];
};

export type ResourceUsageLog = {
  __typename?: "ResourceUsageLog";
  details?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["Time"]>;
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  startDate: Scalars["Time"];
  user?: Maybe<User>;
};

export type ResourceUsageOutput =
  | ResourceNotFoundError
  | ResourceUsageResult
  | UserFacingError;

export type ResourceUsageResult = {
  __typename?: "ResourceUsageResult";
  usageLogs?: Maybe<Array<ResourceUsageLog>>;
};

export type ResourceUser = {
  __typename?: "ResourceUser";
  access?: Maybe<ResourceUserAccess>;
  accessLevel: ResourceAccessLevel;
  mostRecentSessionEnded?: Maybe<Scalars["Time"]>;
  propagationStatus?: Maybe<ResourcePropagationStatus>;
  provisionSource: ResourceUserProvisionSource;
  resource?: Maybe<Resource>;
  resourceId: Scalars["ResourceId"];
  source: ResourceUserSource;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type ResourceUserAccess = {
  __typename?: "ResourceUserAccess";
  accessLevel: ResourceAccessLevel;
  /**
   * Describes attributes of `userId`'s direct access to `resourceId`. If unset, this user does not have
   * direct access to the resource.
   */
  directAccessPoint?: Maybe<ResourceUserAccessPoint>;
  /**
   * Each item in this list describes one indirect path (via groups) by which `userId` has access
   * to `resourceId`. Each `GroupUserAccess` describes a group that has direct access to this resource,
   * and that this user has either direct or indirect access to.
   * If empty, this user does not have indirect access to the resource.
   */
  groupUserAccesses: Array<GroupUserAccess>;
  /**
   * Describes attributes of `userId`'s inherited access to `resourceId`. If empty, this user does not have
   * inherited access to the resource.
   */
  inheritedAccessPoints: Array<ResourceUserAccessPoint>;
  /**
   * Describes attributes of `userId`'s access to `resourceId` for the path with the latest expiration.
   * This access might be direct or indirect.
   */
  latestExpiringAccessPoint: ResourceUserAccessPoint;
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type ResourceUserAccessPoint = {
  __typename?: "ResourceUserAccessPoint";
  accessLevel: ResourceAccessLevel;
  createdAt: Scalars["Time"];
  eventId: Scalars["EventId"];
  expiration?: Maybe<Scalars["Time"]>;
  resourceId: Scalars["ResourceId"];
  resourceName: Scalars["String"];
  resourceType: ResourceType;
  source: ResourceUserSource;
  supportTicket?: Maybe<SupportTicket>;
  supportTicketId?: Maybe<Scalars["SupportTicketId"]>;
  userId: Scalars["UserId"];
};

export type ResourceUserAlreadyExists = Error & {
  __typename?: "ResourceUserAlreadyExists";
  message: Scalars["String"];
};

export type ResourceUserFiltersInput = {
  accessLevelRemoteIds?: InputMaybe<Array<Scalars["AccessLevelRemoteId"]>>;
  directAccessOnly: Scalars["Boolean"];
  propagationStatuses?: InputMaybe<Array<PropagationStatusCode>>;
  userFullName?: InputMaybe<Scalars["String"]>;
};

export type ResourceUserPropagationMetadata = PropagationTicketMetadata;

export enum ResourceUserProvisionSource {
  External = "EXTERNAL",
  Opal = "OPAL",
  Unknown = "UNKNOWN",
}

export type ResourceUserPushTaskResult = {
  __typename?: "ResourceUserPushTaskResult";
  propStatuses: Array<ResourcePropagationStatus>;
};

export enum ResourceUserSortByField {
  AccessLevelName = "ACCESS_LEVEL_NAME",
  ExpiresAt = "EXPIRES_AT",
  PropagationStatus = "PROPAGATION_STATUS",
  SourceOfAccess = "SOURCE_OF_ACCESS",
  UserEmail = "USER_EMAIL",
  UserFullName = "USER_FULL_NAME",
}

export enum ResourceUserSource {
  /** ResourceUser that's inherited from another remote resource */
  Inherited = "INHERITED",
  Regular = "REGULAR",
}

export type ReviewConnectionUserAction = {
  accessReviewConnectionUserId: Scalars["AccessReviewConnectionUserId"];
  action: AccessReviewAction;
  note?: InputMaybe<Scalars["String"]>;
};

export type Reviewer = {
  __typename?: "Reviewer";
  createdAt: Scalars["Time"];
  isDeleted: Scalars["Boolean"];
  owner?: Maybe<Owner>;
  ownerId: Scalars["OwnerId"];
  stageId: Scalars["ReviewerStageId"];
};

export enum ReviewerAction {
  Approved = "APPROVED",
  Denied = "DENIED",
}

export type ReviewerInputStage = {
  operator: ReviewStageOperator;
  ownerIds: Array<Scalars["OwnerId"]>;
  requireAdminApproval: Scalars["Boolean"];
  requireManagerApproval: Scalars["Boolean"];
};

export type ReviewersAlreadyRemindedError = Error & {
  __typename?: "ReviewersAlreadyRemindedError";
  message: Scalars["String"];
};

export type ReviewerStage = {
  __typename?: "ReviewerStage";
  id: Scalars["ReviewerStageId"];
  operator: ReviewStageOperator;
  owners: Array<Owner>;
  requireAdminApproval: Scalars["Boolean"];
  requireManagerApproval: Scalars["Boolean"];
  stage: Scalars["Int"];
};

export type ReviewerUser = {
  __typename?: "ReviewerUser";
  accessReviewEntityId?: Maybe<Scalars["AccessReviewEntityId"]>;
  accessReviewPrincipalId?: Maybe<Scalars["AccessReviewPrincipalId"]>;
  avatarUrl: Scalars["String"];
  entityId: Scalars["UUID"];
  entityType: EntityType;
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
  status: ReviewerUserStatus;
  updatedAccessLevel?: Maybe<ResourceAccessLevel>;
  updatedResource?: Maybe<Resource>;
  updatedResourceId?: Maybe<Scalars["ResourceId"]>;
  userId: Scalars["UserId"];
};

export type ReviewerUserInput = {
  entityIds?: InputMaybe<Array<EntityIdTupleInput>>;
  userId: Scalars["UserId"];
};

export enum ReviewerUserStatus {
  Accepted = "ACCEPTED",
  NeedsEndSystemRevocation = "NEEDS_END_SYSTEM_REVOCATION",
  NeedsUpdateRequestApproval = "NEEDS_UPDATE_REQUEST_APPROVAL",
  NotRequired = "NOT_REQUIRED",
  NotStarted = "NOT_STARTED",
  Revoked = "REVOKED",
  Updated = "UPDATED",
}

export type ReviewGroupResourceAction = {
  accessReviewGroupResourceId: Scalars["AccessReviewGroupResourceId"];
  action: AccessReviewAction;
  note?: InputMaybe<Scalars["String"]>;
  updatedAccessLevel?: InputMaybe<ResourceAccessLevelInput>;
};

export type ReviewGroupUserAction = {
  accessReviewGroupUserId: Scalars["AccessReviewGroupUserId"];
  action: AccessReviewAction;
  note?: InputMaybe<Scalars["String"]>;
  updatedAccessLevel?: InputMaybe<GroupAccessLevelInput>;
};

export type ReviewResourceResourceAction = {
  accessReviewResourceResourceId: Scalars["AccessReviewRoleAssignmentId"];
  action: AccessReviewAction;
  isAccessReviewResourceActingAsPrincipal: Scalars["Boolean"];
  note?: InputMaybe<Scalars["String"]>;
};

export type ReviewResourceUserAction = {
  accessReviewResourceUserId: Scalars["AccessReviewResourceUserId"];
  action: AccessReviewAction;
  note?: InputMaybe<Scalars["String"]>;
  updatedAccessLevel?: InputMaybe<ResourceAccessLevelInput>;
  updatedResource?: InputMaybe<ReviewResourceUserActionUpdatedResourceInput>;
};

export type ReviewResourceUserActionUpdatedResourceInput = {
  id: Scalars["ResourceId"];
  name?: InputMaybe<Scalars["String"]>;
};

export enum ReviewStageOperator {
  And = "AND",
  Or = "OR",
}

export enum RiskLevel {
  Critical = "CRITICAL",
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  None = "NONE",
  Unknown = "UNKNOWN",
}

export type RiskScoreGraphDataResult = {
  __typename?: "RiskScoreGraphDataResult";
  granularity: Scalars["Int"];
  numEntitiesPerScore: Array<Scalars["Int"]>;
  overallRiskScore: Scalars["Int"];
};

export type RiskSensitivityDetails = {
  __typename?: "RiskSensitivityDetails";
  isDefault: Scalars["Boolean"];
  value: RiskLevel;
};

export type RoleAssignment = {
  __typename?: "RoleAssignment";
  access?: Maybe<Access>;
  accessLevel?: Maybe<AccessLevel>;
  entity?: Maybe<Entity>;
  entityID: Scalars["EntityId"];
  entityType: EntityType;
  id: Scalars["RoleAssignmentId"];
  principal?: Maybe<Principal>;
  principalID: Scalars["PrincipalId"];
  principalType: EntityType;
  propagationStatus?: Maybe<PropagationStatus>;
  provisionSource: ProvisionSource;
};

export type RoleAssignmentAlreadyExists = Error & {
  __typename?: "RoleAssignmentAlreadyExists";
  message: Scalars["String"];
};

export type RoleAssignmentNotFoundError = Error & {
  __typename?: "RoleAssignmentNotFoundError";
  message: Scalars["String"];
};

export type RoleAssignmentPushTaskResult = {
  __typename?: "RoleAssignmentPushTaskResult";
  propStatuses: Array<PropagationStatus>;
};

export enum RolePermission {
  AddGroup = "ADD_GROUP",
  AddResource = "ADD_RESOURCE",
  AssignReviewers = "ASSIGN_REVIEWERS",
  Create = "CREATE",
  Delete = "DELETE",
  EditGroupMembership = "EDIT_GROUP_MEMBERSHIP",
  EditRequestConfigurations = "EDIT_REQUEST_CONFIGURATIONS",
  EditResourceMembership = "EDIT_RESOURCE_MEMBERSHIP",
  EditSettings = "EDIT_SETTINGS",
  EditTags = "EDIT_TAGS",
  EditUserMembership = "EDIT_USER_MEMBERSHIP",
  Export = "EXPORT",
  Read = "READ",
  SendReminders = "SEND_REMINDERS",
  Stop = "STOP",
  Sync = "SYNC",
}

export enum RolePermissionTargetEntityType {
  AccessReview = "ACCESS_REVIEW",
  AccessReviewTemplate = "ACCESS_REVIEW_TEMPLATE",
  Application = "APPLICATION",
  Bundle = "BUNDLE",
  ConfigurationTemplate = "CONFIGURATION_TEMPLATE",
  CustomTemplate = "CUSTOM_TEMPLATE",
  Group = "GROUP",
  Resource = "RESOURCE",
  User = "USER",
}

export type SalesforceConnectionMetadata = {
  __typename?: "SalesforceConnectionMetadata";
  email: Scalars["String"];
  hostname: Scalars["String"];
};

export type SalesforceCredentialsInput = {
  clientID: Scalars["String"];
  clientSecret: Scalars["String"];
  password: Scalars["String"];
};

export type SalesforceMetadataInput = {
  email: Scalars["String"];
  hostname: Scalars["String"];
};

export type SamlConnectionOutput = SamlConnectionResult;

export type SamlConnectionResult = {
  __typename?: "SamlConnectionResult";
  organizationSamlInfo?: Maybe<OrganizationSamlInfo>;
};

export type SamlLoginRequiredError = Error & {
  __typename?: "SamlLoginRequiredError";
  message: Scalars["String"];
};

export type SamlNotConfiguredForOrgError = Error & {
  __typename?: "SamlNotConfiguredForOrgError";
  message: Scalars["String"];
};

export type SearchInput = {
  query: Scalars["String"];
};

export type SearchOutput = SearchResult;

export type SearchResult = {
  __typename?: "SearchResult";
  entries: Array<SearchResultEntry>;
};

export type SearchResultEntry = {
  __typename?: "SearchResultEntry";
  annotationText?: Maybe<Scalars["String"]>;
  avatarUrl?: Maybe<Scalars["String"]>;
  connection?: Maybe<Connection>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  email?: Maybe<Scalars["String"]>;
  groupType: GroupType;
  name: Scalars["String"];
  objectId: EntityIdTuple;
  resourceType: ResourceType;
  searchType: SearchType;
};

export enum SearchType {
  Global = "GLOBAL",
  Local = "LOCAL",
}

export type SendAccessReviewReminderInput = {
  accessReviewId: Scalars["AccessReviewId"];
  message?: InputMaybe<Scalars["String"]>;
  notifyReviewerManager: Scalars["Boolean"];
};

export type SendAccessReviewReminderOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | SendAccessReviewReminderResult;

export type SendAccessReviewReminderResult = {
  __typename?: "SendAccessReviewReminderResult";
  accessReview: AccessReview;
};

export type SendManualRequestReminderInput = {
  id: Scalars["RequestId"];
};

export type SendManualRequestReminderOutput =
  | RequestNotFoundError
  | ReviewersAlreadyRemindedError
  | SendManualRequestReminderResult;

export type SendManualRequestReminderResult = {
  __typename?: "SendManualRequestReminderResult";
  success?: Maybe<Scalars["Boolean"]>;
};

export type ServiceNowCredentials = {
  __typename?: "ServiceNowCredentials";
  clientId: Scalars["String"];
  clientSecret: Scalars["String"];
  userId: Scalars["String"];
  userPassword: Scalars["String"];
};

export type ServiceNowMetadataInput = {
  catalogItemId: Scalars["String"];
  hostname: Scalars["String"];
};

export enum ServiceType {
  ActiveDirectory = "ACTIVE_DIRECTORY",
  AwsIam = "AWS_IAM",
  AwsSso = "AWS_SSO",
  AzureAd = "AZURE_AD",
  Custom = "CUSTOM",
  CustomConnector = "CUSTOM_CONNECTOR",
  Duo = "DUO",
  GcpBigQuery = "GCP_BIG_QUERY",
  GcpIam = "GCP_IAM",
  GcpStorage = "GCP_STORAGE",
  GitHub = "GIT_HUB",
  GitLab = "GIT_LAB",
  GoogleGroups = "GOOGLE_GROUPS",
  GoogleWorkspace = "GOOGLE_WORKSPACE",
  Kubernetes = "KUBERNETES",
  Ldap = "LDAP",
  Mariadb = "MARIADB",
  Mongo = "MONGO",
  MongoAtlas = "MONGO_ATLAS",
  Mysql = "MYSQL",
  OktaDirectory = "OKTA_DIRECTORY",
  Opal = "OPAL",
  Pagerduty = "PAGERDUTY",
  Postgres = "POSTGRES",
  Salesforce = "SALESFORCE",
  Snowflake = "SNOWFLAKE",
  Ssh = "SSH",
  Tailscale = "TAILSCALE",
  Teleport = "TELEPORT",
  Unknown = "UNKNOWN",
  Workday = "WORKDAY",
}

export type Session = {
  __typename?: "Session";
  accessLevel?: Maybe<Scalars["AccessLevelName"]>;
  accessLevelRemoteId?: Maybe<Scalars["AccessLevelRemoteId"]>;
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  createdAt: Scalars["Time"];
  durationInMinutes?: Maybe<Scalars["Int"]>;
  endTime: Scalars["Time"];
  id: Scalars["SessionId"];
  metadata: SessionMetadata;
  organizationId: Scalars["OrganizationId"];
  resource?: Maybe<Resource>;
  resourceId?: Maybe<Scalars["ResourceId"]>;
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type SessionMetadata =
  | AwsIamFederatedEksSession
  | AwsIamFederatedRdsSession
  | AwsIamFederatedRoleSession
  | AwsIamFederatedSsmSession
  | OidcSession
  | OpalImpersonationSession
  | VaultMongoAtlasSession
  | VaultMongoSession
  | VaultMySqlMariaDbSession
  | VaultPostgresSession;

export type SessionNotFoundError = Error & {
  __typename?: "SessionNotFoundError";
  message: Scalars["String"];
};

export type SessionsInput = {
  resourceId: Scalars["ResourceId"];
};

export type SessionsOutput = SessionsResult;

export type SessionsResult = {
  __typename?: "SessionsResult";
  sessions: Array<Session>;
};

export type SetNoteInput = {
  accessReviewID: Scalars["AccessReviewId"];
  note: Scalars["String"];
  targetIDs?: InputMaybe<Array<Scalars["String"]>>;
  targetType: AccessReviewType;
};

export type SetNoteOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | SetNoteResult;

export type SetNoteResult = {
  __typename?: "SetNoteResult";
  success: Scalars["Boolean"];
};

export type SetOwnerUsersInput = {
  ownerId: Scalars["OwnerId"];
  userIds: Array<Scalars["UserId"]>;
};

export type SetOwnerUsersOutput =
  | CannotAddSystemUserToOwnerError
  | CannotAddUserToSyncedOwnerError
  | OwnerNotFoundError
  | UpdateOwnerUsersForOwnerResult
  | UserNotFoundError;

export type SetRolePermissionInput = {
  allowAll: Scalars["Boolean"];
  entityIds?: InputMaybe<Array<Scalars["UUID"]>>;
  permissionName: RolePermission;
  targetEntityType: RolePermissionTargetEntityType;
};

export type SetRolesInput = {
  roles: Array<AldwinRole>;
  shouldAddToOwners: Scalars["Boolean"];
  shouldRemoveFromOwners: Scalars["Boolean"];
};

export type SetRolesOutput = SetRolesResult;

export type SetRolesResult = {
  __typename?: "SetRolesResult";
  success: Scalars["Boolean"];
};

export type SetupEntityCounts = {
  __typename?: "SetupEntityCounts";
  numGroups: Scalars["Int"];
  numUsers: Scalars["Int"];
  resourceCounts: Array<SetupResourceCount>;
};

export type SetupResourceCount = {
  __typename?: "SetupResourceCount";
  count: Scalars["Int"];
  resourceType: ResourceType;
};

export type SetupState = {
  __typename?: "SetupState";
  connection?: Maybe<Connection>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  entityCounts?: Maybe<SetupEntityCounts>;
  idpConnectionId?: Maybe<Scalars["IdpConnectionId"]>;
  idpConnectionType?: Maybe<IdpConnectionType>;
  importEntityCounts?: Maybe<SetupEntityCounts>;
  importStatus?: Maybe<SyncTaskStatus>;
  minStep: Scalars["Int"];
};

export type SetupStateResult = {
  __typename?: "SetupStateResult";
  state: SetupState;
};

export type ShortTextValue = {
  __typename?: "ShortTextValue";
  value: Scalars["String"];
};

export type ShortTextValueInput = {
  value: Scalars["String"];
};

export type SignInInput = {
  organizationId: Scalars["OrganizationId"];
};

export type SignInMethodInput = {
  email: Scalars["String"];
};

export type SignInMethodOutput = InvalidEmailError | SignInMethodResult;

export type SignInMethodResult = {
  __typename?: "SignInMethodResult";
  signInOrganizations: Array<SignInOrganization>;
};

export type SignInOrganization = {
  __typename?: "SignInOrganization";
  cliClientId?: Maybe<Scalars["String"]>;
  enableSAMLSignIn: Scalars["Boolean"];
  enableSocialSignIn: Scalars["Boolean"];
  organizationId: Scalars["OrganizationId"];
  organizationName: Scalars["String"];
};

export type SignInOutput = SignInResult;

export type SignInResult = {
  __typename?: "SignInResult";
  forceExtraStep: Scalars["Boolean"];
  state: Scalars["String"];
};

export type SignOutOutput = SignOutResult;

export type SignOutResult = {
  __typename?: "SignOutResult";
  success?: Maybe<Scalars["Boolean"]>;
};

export type SlackOrganizationAlreadyExistsError = Error & {
  __typename?: "SlackOrganizationAlreadyExistsError";
  message: Scalars["String"];
};

export type SnowflakeConnectionCredentialsInput = {
  privateKey: Scalars["String"];
  privateKeyPassword: Scalars["String"];
  publicKey: Scalars["String"];
};

export type SnowflakeConnectionMetadata = {
  __typename?: "SnowflakeConnectionMetadata";
  account: Scalars["String"];
  accountIdentifier: Scalars["String"];
  accountUrl: Scalars["String"];
  locator: Scalars["String"];
  organization: Scalars["String"];
  serviceAccountName: Scalars["String"];
  serviceAccountRole: Scalars["String"];
};

export type SnowflakeConnectionMetadataInput = {
  account: Scalars["String"];
  accountIdentifier: Scalars["String"];
  accountUrl: Scalars["String"];
  locator: Scalars["String"];
  organization: Scalars["String"];
  serviceAccountName: Scalars["String"];
  serviceAccountRole: Scalars["String"];
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type StartAccessReviewInput = {
  deadline: Scalars["Time"];
  filters?: InputMaybe<FiltersInput>;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  includeGroupBindings: Scalars["Boolean"];
  name: Scalars["String"];
  reminderIncludeManager: Scalars["Boolean"];
  reminderSchedule: Array<Scalars["Int"]>;
  reminderTimeOfDay: Scalars["Time"];
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  selfReviewAllowed: Scalars["Boolean"];
  sendReviewerAssignmentNotification: Scalars["Boolean"];
  timeZone: Scalars["String"];
};

export type StartAccessReviewOutput = StartAccessReviewResult;

export type StartAccessReviewResult = {
  __typename?: "StartAccessReviewResult";
  accessReview: AccessReview;
};

export type StartAccessReviewStats = {
  __typename?: "StartAccessReviewStats";
  numAccessReviewsThisYear: Scalars["Int"];
  numAccessReviewsTotal: Scalars["Int"];
  numGroups: Scalars["Int"];
  numGroupsInTagScope: Scalars["Int"];
  numResources: Scalars["Int"];
  numResourcesInTagScope: Scalars["Int"];
  prevAccessReview?: Maybe<AccessReview>;
};

export type StartAccessReviewStatsInput = {
  tagFilters?: InputMaybe<Array<TagFilter>>;
};

export type StartAccessReviewStatsOutput = StartAccessReviewStatsResult;

export type StartAccessReviewStatsResult = {
  __typename?: "StartAccessReviewStatsResult";
  startAccessReviewStats?: Maybe<StartAccessReviewStats>;
};

export type StartDateFilter = {
  date: Scalars["String"];
};

export type StartSyncInput = {
  accessReviewId?: InputMaybe<Scalars["AccessReviewId"]>;
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  groupId?: InputMaybe<Scalars["GroupId"]>;
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
  syncType: SyncType;
};

export type StartSyncOutput =
  | AccessReviewNotFoundError
  | ConnectionNotFoundError
  | GroupNotFoundError
  | InvalidSyncTypeError
  | ResourceNotFoundError
  | StartSyncResult;

export type StartSyncResult = {
  __typename?: "StartSyncResult";
  syncTask: SyncTask;
};

export type StopAccessReviewInput = {
  id: Scalars["AccessReviewId"];
};

export type StopAccessReviewOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | StopAccessReviewResult;

export type StopAccessReviewResult = {
  __typename?: "StopAccessReviewResult";
  accessReview: AccessReview;
};

export type StopImpersonationOutput = StopImpersonationResult;

export type StopImpersonationResult = {
  __typename?: "StopImpersonationResult";
  success: Scalars["Boolean"];
};

export enum StringFormatType {
  Email = "EMAIL",
}

export type SubEvent = {
  __typename?: "SubEvent";
  createdAt: Scalars["Time"];
  id: Scalars["SubEventId"];
  metadata?: Maybe<Scalars["String"]>;
  object?: Maybe<EntityDisplayInfo>;
  object4?: Maybe<EntityDisplayInfo>;
  object4Id?: Maybe<EntityIdTuple>;
  object4Name?: Maybe<Scalars["String"]>;
  objectId: EntityIdTuple;
  objectName?: Maybe<Scalars["String"]>;
  secondaryObject?: Maybe<EntityDisplayInfo>;
  secondaryObjectId?: Maybe<EntityIdTuple>;
  secondaryObjectName?: Maybe<Scalars["String"]>;
  subEventType: EventType;
  tertiaryObject?: Maybe<EntityDisplayInfo>;
  tertiaryObjectId?: Maybe<EntityIdTuple>;
  tertiaryObjectName?: Maybe<Scalars["String"]>;
};

export enum SubEventsSortByField {
  CreatedAt = "CREATED_AT",
  Entity_1Name = "ENTITY_1_NAME",
  Entity_2Name = "ENTITY_2_NAME",
  Entity_3Name = "ENTITY_3_NAME",
  Entity_4Name = "ENTITY_4_NAME",
}

export type SuggestedAccessList = {
  __typename?: "SuggestedAccessList";
  resourceRoleKeys?: Maybe<Array<ResourceRoleKey>>;
  type: RecommendationsSubscoreType;
  userRoleKeys?: Maybe<Array<UserRoleKey>>;
};

export type Suggestion = GroupSuggestion | ResourceSuggestion;

export type SuggestionsOutput = SuggestionsResult;

export type SuggestionsResult = {
  __typename?: "SuggestionsResult";
  suggestions: Array<Suggestion>;
};

export type SupportTicket = {
  __typename?: "SupportTicket";
  id?: Maybe<Scalars["SupportTicketId"]>;
  identifier: Scalars["String"];
  issueType: Scalars["String"];
  projectName: Scalars["String"];
  remoteId: Scalars["SupportTicketRemoteId"];
  summary: Scalars["String"];
  thirdPartyProvider: ThirdPartyProvider;
  url: Scalars["String"];
};

export type SupportTicketApiKeyNotFoundError = Error & {
  __typename?: "SupportTicketAPIKeyNotFoundError";
  message: Scalars["String"];
};

export type SupportTicketByIdInput = {
  id: Scalars["SupportTicketId"];
};

export type SupportTicketInput = {
  identifier: Scalars["String"];
  remoteId: Scalars["SupportTicketRemoteId"];
  thirdPartyProvider: ThirdPartyProvider;
  url: Scalars["String"];
};

export type SupportTicketNotFoundError = Error & {
  __typename?: "SupportTicketNotFoundError";
  message: Scalars["String"];
};

export type SupportTicketOutput =
  | SupportTicketNotFoundError
  | SupportTicketResult;

export type SupportTicketResult = {
  __typename?: "SupportTicketResult";
  ticket: SupportTicket;
};

export type SupportTicketsInput = {
  thirdPartyProvider: ThirdPartyProvider;
};

export type SupportTicketsOutput =
  | SupportTicketApiKeyNotFoundError
  | SupportTicketsResult
  | UserFacingError;

export type SupportTicketsResult = {
  __typename?: "SupportTicketsResult";
  tickets: Array<SupportTicket>;
};

export type SyncError = {
  __typename?: "SyncError";
  connection?: Maybe<Connection>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  createdAt: Scalars["Time"];
  firstSeenAt: Scalars["Time"];
  id: Scalars["SyncErrorId"];
  message: Scalars["String"];
  stackTrace: Scalars["String"];
};

export type SyncErrorNotFoundError = Error & {
  __typename?: "SyncErrorNotFoundError";
  message: Scalars["String"];
};

export type SyncStatusInput = {
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  groupId?: InputMaybe<Scalars["GroupId"]>;
  resourceId?: InputMaybe<Scalars["ResourceId"]>;
  syncType: SyncType;
};

export type SyncStatusOutput =
  | AccessReviewNotFoundError
  | ConnectionNotFoundError
  | GroupNotFoundError
  | InvalidSyncTypeError
  | ResourceNotFoundError
  | SyncStatusResult;

export type SyncStatusResult = {
  __typename?: "SyncStatusResult";
  lastSuccessfulSyncTask?: Maybe<SyncTask>;
  ongoingSyncTask?: Maybe<SyncTask>;
  syncErrors: Array<SyncError>;
};

export type SyncTask = {
  __typename?: "SyncTask";
  createdAt: Scalars["Time"];
  id: Scalars["SyncTaskId"];
  progress?: Maybe<SyncTaskProgress>;
  startedByUser?: Maybe<User>;
  startedByUserId: Scalars["UserId"];
  status: SyncTaskStatus;
  syncType: SyncType;
  updatedAt: Scalars["Time"];
};

export type SyncTaskInput = {
  id: Scalars["SyncTaskId"];
};

export type SyncTaskNotFoundError = Error & {
  __typename?: "SyncTaskNotFoundError";
  message: Scalars["String"];
};

export type SyncTaskOutput = SyncTaskNotFoundError | SyncTaskResult;

export type SyncTaskProgress = {
  __typename?: "SyncTaskProgress";
  steps: Array<SyncTaskStep>;
};

export type SyncTaskResult = {
  __typename?: "SyncTaskResult";
  syncTask: SyncTask;
};

export enum SyncTaskStatus {
  Completed = "COMPLETED",
  CompletedWithErrors = "COMPLETED_WITH_ERRORS",
  Started = "STARTED",
  TimedOut = "TIMED_OUT",
}

export type SyncTaskStep = {
  __typename?: "SyncTaskStep";
  name: Scalars["String"];
  numItemsCompleted: Scalars["Int"];
  totalNumItems: Scalars["Int"];
};

export enum SyncType {
  PullConnectionSetupImport = "PULL_CONNECTION_SETUP_IMPORT",
  PullConnectionsAll = "PULL_CONNECTIONS_ALL",
  PullConnectionsAllGroups = "PULL_CONNECTIONS_ALL_GROUPS",
  PullConnectionsAllIncludeUnmanagedItems = "PULL_CONNECTIONS_ALL_INCLUDE_UNMANAGED_ITEMS",
  PullConnectionsAllResources = "PULL_CONNECTIONS_ALL_RESOURCES",
  PullConnectionsCustom = "PULL_CONNECTIONS_CUSTOM",
  PullConnectionsEventsOnly = "PULL_CONNECTIONS_EVENTS_ONLY",
  PullConnectionsPrefetch = "PULL_CONNECTIONS_PREFETCH",
  PullConnectionsSingleConnection = "PULL_CONNECTIONS_SINGLE_CONNECTION",
  PullConnectionsSingleConnectionFast = "PULL_CONNECTIONS_SINGLE_CONNECTION_FAST",
  PullConnectionsSingleGroup = "PULL_CONNECTIONS_SINGLE_GROUP",
  PullConnectionsSingleResource = "PULL_CONNECTIONS_SINGLE_RESOURCE",
  PullHrIdpData = "PULL_HR_IDP_DATA",
  PullHrIdpDataPartial = "PULL_HR_IDP_DATA_PARTIAL",
  PullIdpConnection = "PULL_IDP_CONNECTION",
  PullOnCallGroups = "PULL_ON_CALL_GROUPS",
  PullPropagationTickets = "PULL_PROPAGATION_TICKETS",
  PullUarRemoteTickets = "PULL_UAR_REMOTE_TICKETS",
}

export type SystemUserIsImmutableError = Error & {
  __typename?: "SystemUserIsImmutableError";
  message: Scalars["String"];
};

export type Tag = {
  __typename?: "Tag";
  createdAt: Scalars["Time"];
  creator?: Maybe<User>;
  creatorUserId: Scalars["UserId"];
  id: Scalars["TagId"];
  isStandardAttribute: Scalars["Boolean"];
  key: Scalars["String"];
  tagGroups: Array<GroupTag>;
  tagResources: Array<ResourceTag>;
  tagUsers: Array<UserTag>;
  updatedAt: Scalars["Time"];
  value?: Maybe<Scalars["String"]>;
};

export type TagExistsError = Error & {
  __typename?: "TagExistsError";
  message: Scalars["String"];
};

export type TagFilter = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type TagFilterInput = {
  matchMode: TagFilterMatchMode;
  tags: Array<TagFilter>;
};

export enum TagFilterMatchMode {
  All = "ALL",
  Any = "ANY",
  None = "NONE",
  NotAll = "NOT_ALL",
}

export type TagInput = {
  id: Scalars["TagId"];
};

export type TagKeyValue = {
  __typename?: "TagKeyValue";
  key: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type TagNotFoundError = Error & {
  __typename?: "TagNotFoundError";
  message: Scalars["String"];
};

export type TagOutput = TagNotFoundError | TagResult;

export type TagResult = {
  __typename?: "TagResult";
  tag: Tag;
};

export type TagsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<TagsSortBy>;
  tagIds?: InputMaybe<Array<Scalars["TagId"]>>;
};

export type TagsOutput = TagsResult;

export type TagsResult = {
  __typename?: "TagsResult";
  cursor?: Maybe<Scalars["String"]>;
  tags: Array<Tag>;
  totalNumTags: Scalars["Int"];
};

export type TagsSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<TagsSortByField>;
};

export enum TagsSortByField {
  CreatedAt = "CREATED_AT",
  Key = "KEY",
}

export type TailscaleConnectionMetadata = {
  __typename?: "TailscaleConnectionMetadata";
  tailnet: Scalars["String"];
};

export type TailscaleConnectionMetadataInput = {
  tailnet: Scalars["String"];
};

export type TailscaleCredentialsInput = {
  apiToken: Scalars["String"];
};

export type TargetUserHasNestedAccessError = Error & {
  __typename?: "TargetUserHasNestedAccessError";
  groupIds?: Maybe<Array<Scalars["GroupId"]>>;
  message: Scalars["String"];
};

export enum TaskTrigger {
  AccessExpired = "ACCESS_EXPIRED",
  AccessReview = "ACCESS_REVIEW",
  AdminAction = "ADMIN_ACTION",
  BreakGlassUsed = "BREAK_GLASS_USED",
  ConnectionDeleted = "CONNECTION_DELETED",
  ConnectionPull = "CONNECTION_PULL",
  CustomAccessLevelDeleted = "CUSTOM_ACCESS_LEVEL_DELETED",
  CustomResourceImport = "CUSTOM_RESOURCE_IMPORT",
  Forfeit = "FORFEIT",
  GroupConverted = "GROUP_CONVERTED",
  GroupCreated = "GROUP_CREATED",
  GroupDeleted = "GROUP_DELETED",
  GroupGroupCreated = "GROUP_GROUP_CREATED",
  GroupGroupDeleted = "GROUP_GROUP_DELETED",
  GroupResourceCreated = "GROUP_RESOURCE_CREATED",
  GroupResourceDeleted = "GROUP_RESOURCE_DELETED",
  GroupSessionCreated = "GROUP_SESSION_CREATED",
  GroupUserCreated = "GROUP_USER_CREATED",
  GroupUserDeleted = "GROUP_USER_DELETED",
  HrisPull = "HRIS_PULL",
  IdpPull = "IDP_PULL",
  OnCallPull = "ON_CALL_PULL",
  Remediation = "REMEDIATION",
  RemoteTicketApproved = "REMOTE_TICKET_APPROVED",
  RequestApproved = "REQUEST_APPROVED",
  RequestAutoApproved = "REQUEST_AUTO_APPROVED",
  ResourceCreated = "RESOURCE_CREATED",
  ResourceDeleted = "RESOURCE_DELETED",
  ScimPush = "SCIM_PUSH",
  ThirdPartyProviderPull = "THIRD_PARTY_PROVIDER_PULL",
  UserDeleted = "USER_DELETED",
}

export type Team = {
  __typename?: "Team";
  name: Scalars["String"];
  numUsers: Scalars["Int"];
};

export type TeamFilter = {
  teams?: InputMaybe<Array<Scalars["String"]>>;
  teamUnset?: InputMaybe<Scalars["Boolean"]>;
};

export type TeamsInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type TeamsOutput = TeamsResult;

export type TeamsResult = {
  __typename?: "TeamsResult";
  cursor?: Maybe<Scalars["String"]>;
  teams: Array<Team>;
};

export type TeleportConnectionCredentialsInput = {
  credentialsFile: Scalars["String"];
};

export type TeleportConnectionMetadata = {
  __typename?: "TeleportConnectionMetadata";
  host: Scalars["String"];
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type TeleportConnectionMetadataInput = {
  host: Scalars["String"];
  tlsCaCertContent?: InputMaybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type ThirdPartyClientInput = {
  clientId: Scalars["String"];
  clientSecondaryToken?: InputMaybe<Scalars["String"]>;
  clientSecret: Scalars["String"];
  signingSecret?: InputMaybe<Scalars["String"]>;
};

export type ThirdPartyClientNotFoundError = Error & {
  __typename?: "ThirdPartyClientNotFoundError";
  message: Scalars["String"];
};

export type ThirdPartyConnectionNotFoundError = Error & {
  __typename?: "ThirdPartyConnectionNotFoundError";
  message: Scalars["String"];
};

export type ThirdPartyConnectionNotMatchedError = Error & {
  __typename?: "ThirdPartyConnectionNotMatchedError";
  message: Scalars["String"];
};

export type ThirdPartyIntegration = {
  __typename?: "ThirdPartyIntegration";
  authFlowType: AuthFlowType;
  connectionActive?: Maybe<Scalars["Boolean"]>;
  connectionId?: Maybe<Scalars["ConnectionId"]>;
  id: Scalars["ThirdPartyTokenId"];
  integrationType: IntegrationType;
  slackMetadata?: Maybe<ThirdPartyIntegrationSlackMetadata>;
  thirdPartyProvider: ThirdPartyProvider;
};

export type ThirdPartyIntegrationInput = {
  connectionId?: InputMaybe<Scalars["ConnectionId"]>;
  thirdPartyProvider: ThirdPartyProvider;
  userId?: InputMaybe<Scalars["UserId"]>;
};

export type ThirdPartyIntegrationMetadataInput = {
  jira?: InputMaybe<JiraMetadataInput>;
  servicenow?: InputMaybe<ServiceNowMetadataInput>;
};

export type ThirdPartyIntegrationNotFoundError = Error & {
  __typename?: "ThirdPartyIntegrationNotFoundError";
  message: Scalars["String"];
};

export type ThirdPartyIntegrationOutput =
  | ConnectionNotFoundError
  | ThirdPartyIntegrationNotFoundError
  | ThirdPartyIntegrationResult;

export type ThirdPartyIntegrationResult = {
  __typename?: "ThirdPartyIntegrationResult";
  thirdPartyIntegration: ThirdPartyIntegration;
};

export type ThirdPartyIntegrationsInput = {
  integrationType: IntegrationType;
};

export type ThirdPartyIntegrationSlackMetadata = {
  __typename?: "ThirdPartyIntegrationSlackMetadata";
  appId: Scalars["String"];
  enterpriseId?: Maybe<Scalars["String"]>;
  enterpriseName?: Maybe<Scalars["String"]>;
  workspaces: Array<ThirdPartyIntegrationSlackWorkspace>;
};

export type ThirdPartyIntegrationSlackWorkspace = {
  __typename?: "ThirdPartyIntegrationSlackWorkspace";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ThirdPartyIntegrationsOutput = ThirdPartyIntegrationsResult;

export type ThirdPartyIntegrationsResult = {
  __typename?: "ThirdPartyIntegrationsResult";
  thirdPartyIntegrations: Array<ThirdPartyIntegration>;
};

export enum ThirdPartyProvider {
  Auth0 = "AUTH0",
  GitHub = "GIT_HUB",
  GitLab = "GIT_LAB",
  GitLabConnection = "GIT_LAB_CONNECTION",
  Jira = "JIRA",
  Linear = "LINEAR",
  Merge = "MERGE",
  Opsgenie = "OPSGENIE",
  PagerDuty = "PAGER_DUTY",
  ServiceNow = "SERVICE_NOW",
  Slack = "SLACK",
  Teleport = "TELEPORT",
}

export type ThirdPartyUserNotMatchedError = Error & {
  __typename?: "ThirdPartyUserNotMatchedError";
  message: Scalars["String"];
};

export type TicketProject = {
  __typename?: "TicketProject";
  avatarUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  key: Scalars["String"];
  name: Scalars["String"];
};

export type TicketProjectsOutput =
  | SupportTicketApiKeyNotFoundError
  | TicketProjectsResult;

export type TicketProjectsResult = {
  __typename?: "TicketProjectsResult";
  ticketProjects: Array<TicketProject>;
};

export type TicketPropagation = {
  __typename?: "TicketPropagation";
  onGrant: Scalars["Boolean"];
  onRevocation: Scalars["Boolean"];
  ticketProvider?: Maybe<TicketProvider>;
};

export type TicketPropagationInput = {
  onGrant: Scalars["Boolean"];
  onRevocation: Scalars["Boolean"];
  ticketProvider?: InputMaybe<TicketProviderInput>;
};

export type TicketProvider = {
  __typename?: "TicketProvider";
  ticketProjectId: Scalars["TicketProjectId"];
  ticketProjectName: Scalars["String"];
  ticketProvider: ThirdPartyProvider;
};

export type TicketProviderInput = {
  ticketProjectId: Scalars["TicketProjectId"];
  ticketProjectName: Scalars["String"];
  ticketProvider: ThirdPartyProvider;
};

export enum TimeBucket {
  Day = "DAY",
  Month = "MONTH",
  Week = "WEEK",
}

export enum TimePeriod {
  Day = "DAY",
  Hour = "HOUR",
  Month = "MONTH",
  Second = "SECOND",
  Week = "WEEK",
  Year = "YEAR",
}

export type Title = {
  __typename?: "Title";
  name: Scalars["String"];
  numUsers: Scalars["Int"];
};

export type TitleFilter = {
  titles?: InputMaybe<Array<Scalars["String"]>>;
  titleUnset?: InputMaybe<Scalars["Boolean"]>;
};

export type TitlesInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
};

export type TitlesOutput = TitlesResult;

export type TitlesResult = {
  __typename?: "TitlesResult";
  cursor?: Maybe<Scalars["String"]>;
  titles: Array<Title>;
};

export type TlsConfig = {
  __typename?: "TlsConfig";
  tlsCaCertContent?: Maybe<Scalars["String"]>;
  tlsMode: Scalars["Boolean"];
};

export type TooManyGroupLeadersError = Error & {
  __typename?: "TooManyGroupLeadersError";
  message: Scalars["String"];
};

export type UiNotification = {
  __typename?: "UINotification";
  body: Scalars["String"];
  createdAt: Scalars["Time"];
  id: Scalars["UINotificationId"];
  readAt?: Maybe<Scalars["Time"]>;
  trackingId?: Maybe<Scalars["UUID"]>;
  url: Scalars["String"];
  userId: Scalars["UserId"];
};

export enum UiSource {
  ThreatCenter = "THREAT_CENTER",
  Unknown = "UNKNOWN",
}

export type UpdateAccessReviewGroupReviewersInput = {
  accessReviewGroupId: Scalars["AccessReviewGroupId"];
  reviewers: Array<ReviewerUserInput>;
  updateOnlyForEntities: Scalars["Boolean"];
};

export type UpdateAccessReviewGroupReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateAccessReviewGroupReviewersResult;

export type UpdateAccessReviewGroupReviewersResult = {
  __typename?: "UpdateAccessReviewGroupReviewersResult";
  accessReviewGroup: AccessReviewGroup;
};

export type UpdateAccessReviewInput = {
  deadline?: InputMaybe<Scalars["Time"]>;
  id: Scalars["AccessReviewId"];
  name?: InputMaybe<Scalars["String"]>;
  reminderIncludeManager?: InputMaybe<Scalars["Boolean"]>;
  reminderSchedule?: InputMaybe<Array<Scalars["Int"]>>;
  reminderTimeOfDay?: InputMaybe<Scalars["Time"]>;
  sendReviewerAssignmentNotification?: InputMaybe<Scalars["Boolean"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type UpdateAccessReviewOutput =
  | AccessReviewAlreadyStoppedError
  | AccessReviewNotFoundError
  | UpdateAccessReviewResult;

export type UpdateAccessReviewResourceReviewersInput = {
  accessReviewResourceId: Scalars["AccessReviewResourceId"];
  reviewers: Array<ReviewerUserInput>;
  updateOnlyForEntities: Scalars["Boolean"];
};

export type UpdateAccessReviewResourceReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateAccessReviewResourceReviewersResult;

export type UpdateAccessReviewResourceReviewersResult = {
  __typename?: "UpdateAccessReviewResourceReviewersResult";
  accessReviewResource: AccessReviewResource;
};

export type UpdateAccessReviewResult = {
  __typename?: "UpdateAccessReviewResult";
  accessReview: AccessReview;
};

export type UpdateAccessReviewReviewersInput = {
  accessReviewConnectionUserIds?: InputMaybe<
    Array<Scalars["AccessReviewConnectionUserId"]>
  >;
  accessReviewGroupResourceIds?: InputMaybe<
    Array<Scalars["AccessReviewGroupResourceId"]>
  >;
  accessReviewGroupUserIds?: InputMaybe<
    Array<Scalars["AccessReviewGroupUserId"]>
  >;
  accessReviewId: Scalars["AccessReviewId"];
  accessReviewResourceResourceIds?: InputMaybe<
    Array<Scalars["AccessReviewRoleAssignmentId"]>
  >;
  accessReviewResourceUserIds?: InputMaybe<
    Array<Scalars["AccessReviewResourceUserId"]>
  >;
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateAccessReviewReviewersOutput =
  | UpdateAccessReviewReviewersResult
  | UpdateAccessReviewReviewersValidationError;

export type UpdateAccessReviewReviewersResult = {
  __typename?: "UpdateAccessReviewReviewersResult";
  success?: Maybe<Scalars["Boolean"]>;
};

export type UpdateAccessReviewReviewersValidationError = {
  __typename?: "UpdateAccessReviewReviewersValidationError";
  message: Scalars["String"];
};

export type UpdateAccessReviewTemplateInput = {
  accessReviewDuration?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<FiltersInput>;
  groupResourceVisibilityPolicy?: InputMaybe<
    AccessReviewGroupResourceVisibilityPolicy
  >;
  id: Scalars["AccessReviewTemplateId"];
  includeGroupBindings?: InputMaybe<Scalars["Boolean"]>;
  monthSchedule?: InputMaybe<Array<Scalars["Int"]>>;
  name?: InputMaybe<Scalars["String"]>;
  reminderIncludeManager?: InputMaybe<Scalars["Boolean"]>;
  reminderSchedule?: InputMaybe<Array<Scalars["Int"]>>;
  reminderTimeOfDay?: InputMaybe<Scalars["Time"]>;
  reviewerAssignmentPolicy?: InputMaybe<AccessReviewReviewerAssignmentPolicy>;
  selfReviewAllowed?: InputMaybe<Scalars["Boolean"]>;
  sendReviewerAssignmentNotification?: InputMaybe<Scalars["Boolean"]>;
  startDayOfMonth?: InputMaybe<Scalars["Int"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type UpdateAccessReviewTemplateOutput =
  | AccessReviewTemplateNotFound
  | UpdateAccessReviewTemplateResult;

export type UpdateAccessReviewTemplateResult = {
  __typename?: "UpdateAccessReviewTemplateResult";
  accessReviewTemplate: AccessReviewTemplate;
};

export type UpdateBundleInput = {
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["BundleId"];
  name?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<Visibility>;
  visibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
};

export type UpdateBundleOutput =
  | BundleNameExistsError
  | BundleNotAuthorizedError
  | UpdateBundleResult;

export type UpdateBundleResult = {
  __typename?: "UpdateBundleResult";
  bundle: Bundle;
};

export type UpdateConfigTemplateTagsInput = {
  configTemplateTags: Array<ConfigTemplateTagInput>;
};

export type UpdateConfigTemplateTagsOutput = UpdateConfigTemplateTagsResult;

export type UpdateConfigTemplateTagsResult = {
  __typename?: "UpdateConfigTemplateTagsResult";
  success: Scalars["Boolean"];
};

export type UpdateConfigurationTemplateInput = {
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  breakGlassUsersIds?: InputMaybe<Array<Scalars["UserId"]>>;
  customRequestNotification?: InputMaybe<NullableString>;
  id: Scalars["ConfigurationId"];
  messageChannelIds?: InputMaybe<Array<Scalars["MessageChannelId"]>>;
  name?: InputMaybe<Scalars["String"]>;
  onCallScheduleIds?: InputMaybe<Array<Scalars["OnCallScheduleId"]>>;
  onCallSchedules?: InputMaybe<Array<OnCallScheduleInput>>;
  requestConfigs?: InputMaybe<Array<RequestConfigInput>>;
  requireMfaToApprove?: InputMaybe<Scalars["Boolean"]>;
  requireMfaToConnect?: InputMaybe<Scalars["Boolean"]>;
  ticketPropagation?: InputMaybe<TicketPropagationInput>;
  visibility?: InputMaybe<Visibility>;
  visibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
};

export type UpdateConfigurationTemplateOutput =
  | ConfigurationTemplateNameExistsError
  | UpdateConfigurationTemplateResult;

export type UpdateConfigurationTemplateResult = {
  __typename?: "UpdateConfigurationTemplateResult";
  configurationTemplate: ConfigurationTemplate;
};

export type UpdateConnectionInput = {
  adminOwnerId?: InputMaybe<Scalars["OwnerId"]>;
  autoImportGroupResources?: InputMaybe<Scalars["Boolean"]>;
  childrenDefaultConfigTemplateId?: InputMaybe<NullableConfigurationId>;
  connectionImage?: InputMaybe<Scalars["Upload"]>;
  credentials?: InputMaybe<ConnectionCredentialsInput>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["ConnectionId"];
  importNotification?: InputMaybe<ConnectionImportNotificationInput>;
  importSetting?: InputMaybe<ImportSetting>;
  importVisibility?: InputMaybe<Visibility>;
  importVisibilityGroupsIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  metadata?: InputMaybe<ConnectionMetadataInput>;
  name?: InputMaybe<Scalars["String"]>;
  visibility?: InputMaybe<Visibility>;
  visibilityGroupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  webhookEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateConnectionOutput =
  | ConnectionBadMetadataError
  | ConnectionNotFoundError
  | ConnectionVisibilityGroupNotFoundError
  | UpdateConnectionResult
  | UserFacingError;

export type UpdateConnectionResult = {
  __typename?: "UpdateConnectionResult";
  connection: Connection;
};

export type UpdateConnectionReviewersInput = {
  accessReviewConnectionId: Scalars["AccessReviewConnectionId"];
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateConnectionReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateConnectionReviewersResult;

export type UpdateConnectionReviewersResult = {
  __typename?: "UpdateConnectionReviewersResult";
  accessReviewConnection: AccessReviewConnection;
};

export type UpdateConnectionUserReviewersInput = {
  accessReviewConnectionUserId: Scalars["AccessReviewConnectionUserId"];
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateConnectionUserReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateAccessReviewReviewersValidationError
  | UpdateConnectionUserReviewersResult;

export type UpdateConnectionUserReviewersResult = {
  __typename?: "UpdateConnectionUserReviewersResult";
  accessReviewConnection: AccessReviewConnection;
};

export type UpdateGroupBindingInput = {
  groupIds: Array<Scalars["GroupId"]>;
  id: Scalars["GroupBindingId"];
  sourceGroupId: Scalars["GroupId"];
};

export type UpdateGroupBindingsInput = {
  groupBindings: Array<UpdateGroupBindingInput>;
};

export type UpdateGroupBindingsOutput =
  | GroupAlreadyBelongsToBindingError
  | GroupBindingHasNoGroupsError
  | GroupBindingNotFoundError
  | UpdateGroupBindingsResult;

export type UpdateGroupBindingsResult = {
  __typename?: "UpdateGroupBindingsResult";
  groupBindings: Array<GroupBinding>;
};

export type UpdateGroupInput = {
  commonMetadata?: InputMaybe<CommonMetadataInput>;
  description?: InputMaybe<Scalars["String"]>;
  groupId: Scalars["GroupId"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateGroupOutput = GroupNotFoundError | UpdateGroupResult;

export type UpdateGroupResourceReviewersInput = {
  accessReviewGroupResourceId: Scalars["AccessReviewGroupResourceId"];
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateGroupResourceReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateGroupResourceReviewersResult;

export type UpdateGroupResourceReviewersResult = {
  __typename?: "UpdateGroupResourceReviewersResult";
  accessReviewGroup: AccessReviewGroup;
  accessReviewGroupResource: AccessReviewGroupResource;
};

export type UpdateGroupResult = {
  __typename?: "UpdateGroupResult";
  group: Group;
};

export type UpdateGroupUserInput = {
  accessLevel?: InputMaybe<GroupAccessLevelInput>;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  groupId: Scalars["GroupId"];
  userId: Scalars["UserId"];
};

export type UpdateGroupUserReviewersInput = {
  accessReviewGroupUserId: Scalars["AccessReviewGroupUserId"];
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateGroupUserReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateAccessReviewReviewersValidationError
  | UpdateGroupUserReviewersResult;

export type UpdateGroupUserReviewersResult = {
  __typename?: "UpdateGroupUserReviewersResult";
  accessReviewGroup: AccessReviewGroup;
  accessReviewGroupUser: AccessReviewGroupUser;
};

export type UpdateGroupUsersEntryOutput = UpdateGroupUsersEntryResult;

export type UpdateGroupUsersEntryResult = {
  __typename?: "UpdateGroupUsersEntryResult";
  groupUser: GroupUser;
};

export type UpdateGroupUsersInput = {
  groupUsers: Array<UpdateGroupUserInput>;
  source?: InputMaybe<UiSource>;
};

export type UpdateGroupUsersOutput =
  | GroupNotFoundError
  | UpdateGroupUsersResult;

export type UpdateGroupUsersResult = {
  __typename?: "UpdateGroupUsersResult";
  entries: Array<UpdateGroupUsersEntryOutput>;
  taskIds: Array<Scalars["PushTaskId"]>;
};

export type UpdateIdpConnectionInput = {
  customAttributesAllowlist?: InputMaybe<NullableStrings>;
  id: Scalars["IdpConnectionId"];
};

export type UpdateIdpConnectionOutput =
  | IdpConnectionNotFoundError
  | UpdateIdpConnectionResult;

export type UpdateIdpConnectionResult = {
  __typename?: "UpdateIdpConnectionResult";
  idpConnection: IdpConnection;
};

export type UpdateOpalScopedRoleInput = {
  permissions: Array<SetRolePermissionInput>;
  resourceID: Scalars["ResourceId"];
};

export type UpdateOpalScopedRoleOutput = {
  __typename?: "UpdateOpalScopedRoleOutput";
  resourceID: Scalars["ResourceId"];
};

export type UpdateOrganizationSettingsInput = {
  accessExpiringNotifications?: InputMaybe<Array<Scalars["Int"]>>;
  auditTicketProvider?: InputMaybe<NullableTicketProvider>;
  authSessionExpirationInMinutes?: InputMaybe<Scalars["Int"]>;
  createOrUpdateOidcProviderInput?: InputMaybe<CreateOrUpdateOidcProviderInput>;
  customerSupportExpiresAt?: InputMaybe<Scalars["Time"]>;
  dryRunModeEnabled?: InputMaybe<Scalars["Boolean"]>;
  errorNotificationSetting?: InputMaybe<ErrorNotificationSettingType>;
  isRemoteReadOnly?: InputMaybe<Scalars["Boolean"]>;
  maxGroupDurationInMinutes?: InputMaybe<NullableInt>;
  maxResourceDurationInMinutes?: InputMaybe<NullableInt>;
  roleCreationReviewerOwnerId?: InputMaybe<NullableOwnerId>;
  settings?: InputMaybe<Array<GeneralSettingType>>;
  syncErrorHandlerOwnerId?: InputMaybe<NullableOwnerId>;
};

export type UpdateOrganizationSettingsOutput =
  | AccessExpiringNotificationsInvalidError
  | AccessRequestEscalationPeriodInvalidError
  | AuthSessionExpirationInvalidError
  | CustomerSupportSettingsInvalid
  | OidcIssuerNotValidError
  | OrganizationSettingsNotFoundError
  | OrgMaxGroupDurationInvalidError
  | OrgMaxResourceDurationInvalidError
  | UpdateOrganizationSettingsResult;

export type UpdateOrganizationSettingsResult = {
  __typename?: "UpdateOrganizationSettingsResult";
  settings: OrganizationSettings;
};

export type UpdateOwnerInput = {
  accessRequestEscalationPeriodInMinutes?: InputMaybe<NullableInt>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId: Scalars["OwnerId"];
  reviewerMessageChannelId?: InputMaybe<NullableMessageChannelId>;
  sourceGroupId?: InputMaybe<NullableGroupId>;
};

export type UpdateOwnerOutput =
  | CannotAddUserToSyncedOwnerError
  | GroupNotFoundError
  | MessageChannelNotFoundError
  | OwnerAccessRequestFrequencyReminderOutOfBoundsError
  | OwnerNotFoundError
  | UpdateOwnerResult;

export type UpdateOwnerResult = {
  __typename?: "UpdateOwnerResult";
  owner: Owner;
};

export type UpdateOwnerUsersForOwnerResult = {
  __typename?: "UpdateOwnerUsersForOwnerResult";
  ownerUsers: Array<OwnerUser>;
};

export type UpdateRequestTemplateInput = {
  customFields?: InputMaybe<Array<RequestTemplateCustomFieldInput>>;
  id: Scalars["RequestTemplateId"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateRequestTemplateOutput =
  | CustomFieldExistsError
  | RequestTemplateNameExistsError
  | UpdateRequestTemplateResult;

export type UpdateRequestTemplateResult = {
  __typename?: "UpdateRequestTemplateResult";
  requestTemplate: RequestTemplateSimple;
};

export type UpdateResourceCustomAccessLevelInput = {
  id: Scalars["ResourceCustomAccessLevelId"];
  policy?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["ResourceId"];
};

export type UpdateResourceCustomAccessLevelOutput =
  | ResourceCustomAccessLevelNotFoundError
  | ResourceCustomAccessLevelPriorityError
  | UpdateResourceCustomAccessLevelResult;

export type UpdateResourceCustomAccessLevelResult = {
  __typename?: "UpdateResourceCustomAccessLevelResult";
  resourceCustomAccessLevel: ResourceCustomAccessLevel;
};

export type UpdateResourcesInput = {
  commonMetadata?: InputMaybe<CommonMetadataInput>;
  customRequestNotification?: InputMaybe<NullableString>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  resourceIds: Array<Scalars["ResourceId"]>;
};

export type UpdateResourcesOutput =
  | ConfigurationVisibilityGroupNotFoundError
  | InvalidUpdateResourceVisibilityGroupError
  | OpalResourceImmutableError
  | ResourceNotFoundError
  | UpdateResourcesResult;

export type UpdateResourcesResult = {
  __typename?: "UpdateResourcesResult";
  resources: Array<Resource>;
};

export type UpdateResourceUserInput = {
  accessLevel: ResourceAccessLevelInput;
  durationInMinutes?: InputMaybe<Scalars["Int"]>;
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type UpdateResourceUserReviewersInput = {
  accessReviewResourceUserId: Scalars["AccessReviewResourceUserId"];
  reviewers: Array<ReviewerUserInput>;
};

export type UpdateResourceUserReviewersOutput =
  | AccessReviewAlreadyStoppedError
  | UpdateAccessReviewReviewersValidationError
  | UpdateResourceUserReviewersResult;

export type UpdateResourceUserReviewersResult = {
  __typename?: "UpdateResourceUserReviewersResult";
  accessReviewResource: AccessReviewResource;
};

export type UpdateResourceUsersInput = {
  resourceUsers?: InputMaybe<Array<UpdateResourceUserInput>>;
  source?: InputMaybe<UiSource>;
};

export type UpdateResourceUsersOutput =
  | ResourceNotFoundError
  | UpdateResourceUsersResult;

export type UpdateResourceUsersResult = {
  __typename?: "UpdateResourceUsersResult";
  resourceUsers?: Maybe<Array<ResourceUser>>;
};

export type UpdateResourceVisibilityGroupsInput = {
  resourceId: Scalars["ResourceId"];
  visibility: Visibility;
  visibilityGroupsIds: Array<Scalars["GroupId"]>;
};

export type UpdateResourceVisibilityGroupsOutput =
  | ConfigurationVisibilityGroupNotFoundError
  | GroupNotFoundError
  | InvalidUpdateResourceVisibilityGroupError
  | ResourceNotFoundError
  | UpdateResourceVisibilityGroupsResult;

export type UpdateResourceVisibilityGroupsResult = {
  __typename?: "UpdateResourceVisibilityGroupsResult";
  resource: Resource;
};

export type UpdateReviewerStagesInput = {
  autoApprove?: InputMaybe<Scalars["Boolean"]>;
  isRequestable?: InputMaybe<Scalars["Boolean"]>;
  requestConfigurationID: Scalars["RequestConfigurationId"];
  stages?: InputMaybe<Array<ReviewerInputStage>>;
};

export type UpdateSamlBreakGlassUserInput = {
  userId: Scalars["UserId"];
};

export type UpdateSamlConnectionInput = {
  restrictLoginToSaml?: InputMaybe<Scalars["Boolean"]>;
  samlBreakGlassUsers?: InputMaybe<Array<UpdateSamlBreakGlassUserInput>>;
};

export type UpdateSamlConnectionOutput = UpdateSamlConnectionResult;

export type UpdateSamlConnectionResult = {
  __typename?: "UpdateSamlConnectionResult";
  organizationSamlInfo: OrganizationSamlInfo;
};

export type UpdateUserInput = {
  id: Scalars["UserId"];
  managerId?: InputMaybe<NullableUserId>;
  position?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserManagerInput = {
  id: Scalars["UserId"];
  managerId?: InputMaybe<NullableUserId>;
};

export type UpdateUserManagerOutput =
  | ManagedHrDataIsImmutableError
  | SystemUserIsImmutableError
  | UpdateUserManagerResult
  | UserNotFoundError;

export type UpdateUserManagerResult = {
  __typename?: "UpdateUserManagerResult";
  user: User;
};

export type UpdateUserOutput =
  | ManagedHrDataIsImmutableError
  | SystemUserIsImmutableError
  | UpdateUserResult
  | UserNotFoundError;

export type UpdateUserResult = {
  __typename?: "UpdateUserResult";
  user: User;
};

export type UpdateUserSettingsInput = {
  allNotifications: Array<NotificationType>;
};

export type UpdateUserSettingsOutput =
  | UpdateUserSettingsResult
  | UserSettingsInvalidError
  | UserSettingsNotFoundError;

export type UpdateUserSettingsResult = {
  __typename?: "UpdateUserSettingsResult";
  userSettings: UserSettings;
};

export type UpdateWebhookInfoInput = {
  enableWebhook?: InputMaybe<Scalars["Boolean"]>;
  webhookEndpoint?: InputMaybe<Scalars["String"]>;
};

export type UpdateWebhookInfoOutput = UpdateWebhookInfoResult;

export type UpdateWebhookInfoResult = {
  __typename?: "UpdateWebhookInfoResult";
  webhookInfo: OrganizationWebhookInfo;
};

export type UseGroupBreakGlassInput = {
  groupId: Scalars["GroupId"];
};

export type UseGroupBreakGlassOutput =
  | GroupNotFoundError
  | GroupUserAlreadyExists
  | NoBreakGlassAccessError
  | UseGroupBreakGlassResult;

export type UseGroupBreakGlassResult = {
  __typename?: "UseGroupBreakGlassResult";
  group: Group;
};

export type User = {
  __typename?: "User";
  avatarUrl: Scalars["String"];
  createdAt: Scalars["Time"];
  directReports: Array<User>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  hrIdpStatus?: Maybe<HrIdpStatus>;
  id: Scalars["UserId"];
  identities?: Maybe<UserIdentities>;
  isDeleted: Scalars["Boolean"];
  isSystemUser: Scalars["Boolean"];
  lastName: Scalars["String"];
  leaderGroups?: Maybe<Array<Group>>;
  manager?: Maybe<User>;
  managerId?: Maybe<Scalars["UserId"]>;
  numGroups: Scalars["Int"];
  numResources: Scalars["Int"];
  organization: Organization;
  organizationId: Scalars["OrganizationId"];
  position: Scalars["String"];
  secondaryEmails: Array<Scalars["String"]>;
  teamAttr?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Time"];
  userGroups: Array<GroupUser>;
  userResources: Array<ResourceUser>;
};

export type UserAlreadyExistsInAnotherOrg = Error & {
  __typename?: "UserAlreadyExistsInAnotherOrg";
  message: Scalars["String"];
};

export type UserCannotRequestAccessForTargetGroupError = Error & {
  __typename?: "UserCannotRequestAccessForTargetGroupError";
  groupId: Scalars["GroupId"];
  message: Scalars["String"];
  userId: Scalars["UserId"];
};

export enum UserErrorType {
  SupportTicketNotFound = "SUPPORT_TICKET_NOT_FOUND",
}

export type UserFacingError = Error & {
  __typename?: "UserFacingError";
  message: Scalars["String"];
  type?: Maybe<UserErrorType>;
};

export type UserGroupAccess = {
  __typename?: "UserGroupAccess";
  groupId: Scalars["GroupId"];
  groupUser?: Maybe<GroupUser>;
  userId: Scalars["UserId"];
};

export type UserGroupEdge = {
  __typename?: "UserGroupEdge";
  groupId: Scalars["GroupId"];
  metadata?: Maybe<EdgeMetadata>;
  userId: Scalars["UserId"];
};

export type UserIdentities = {
  __typename?: "UserIdentities";
  gitHubUsername?: Maybe<UserIdentity>;
  gitLabUserId?: Maybe<UserIdentity>;
  teleportUsername?: Maybe<UserIdentity>;
};

export type UserIdentity = {
  __typename?: "UserIdentity";
  shouldShow: Scalars["Boolean"];
  value: Scalars["String"];
};

export type UserImpersonationDisabledError = Error & {
  __typename?: "UserImpersonationDisabledError";
  message: Scalars["String"];
};

export type UserInput = {
  id: Scalars["UserId"];
};

export type UserNotFoundError = Error & {
  __typename?: "UserNotFoundError";
  email?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type UserOutput = UserNotFoundError | UserResult;

export enum UserProductRole {
  Admin = "ADMIN",
  Member = "MEMBER",
}

export type UserResourceAccess = {
  __typename?: "UserResourceAccess";
  numAvailableAccessLevels: Scalars["Int"];
  resourceId: Scalars["ResourceId"];
  resourceUsers: Array<ResourceUser>;
  userId: Scalars["UserId"];
};

export type UserResourceEdge = {
  __typename?: "UserResourceEdge";
  accessLevel: ResourceAccessLevel;
  metadata?: Maybe<EdgeMetadata>;
  resourceId: Scalars["ResourceId"];
  userId: Scalars["UserId"];
};

export type UserResult = {
  __typename?: "UserResult";
  user: User;
};

export type UserRoleKey = {
  __typename?: "UserRoleKey";
  accessLevelRemoteId?: Maybe<Scalars["String"]>;
  userId: Scalars["UserId"];
};

export type UserSettings = {
  __typename?: "UserSettings";
  allNotifications: Array<NotificationType>;
  createdAt: Scalars["Time"];
  id: Scalars["UserId"];
  updatedAt: Scalars["Time"];
};

export type UserSettingsInvalidError = Error & {
  __typename?: "UserSettingsInvalidError";
  message: Scalars["String"];
};

export type UserSettingsNotFoundError = Error & {
  __typename?: "UserSettingsNotFoundError";
  message: Scalars["String"];
};

export type UserSettingsOutput =
  | UserSettingsInvalidError
  | UserSettingsNotFoundError
  | UserSettingsResult;

export type UserSettingsResult = {
  __typename?: "UserSettingsResult";
  userSettings: UserSettings;
};

export type UsersFilterInput = {
  userIDs: Array<Scalars["UserId"]>;
};

export type UsersInput = {
  cursor?: InputMaybe<Scalars["String"]>;
  idpStatusFilter?: InputMaybe<IdpStatusFilter>;
  includeOnlyManagers?: InputMaybe<Scalars["Boolean"]>;
  includeSystemUser?: InputMaybe<Scalars["Boolean"]>;
  managerFilter?: InputMaybe<ManagerFilter>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<UsersSortBy>;
  teamFilter?: InputMaybe<TeamFilter>;
  titleFilter?: InputMaybe<TitleFilter>;
  userEmails?: InputMaybe<Array<Scalars["String"]>>;
  userIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type UserSmall = {
  __typename?: "UserSmall";
  avatarUrl: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["UserId"];
};

export type UsersOutput = UsersResult;

export type UsersResult = {
  __typename?: "UsersResult";
  cursor?: Maybe<Scalars["String"]>;
  totalNumUsers: Scalars["Int"];
  users: Array<User>;
};

export type UsersSortBy = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<UsersSortByField>;
};

export enum UsersSortByField {
  CreatedAt = "CREATED_AT",
  Email = "EMAIL",
  FirstName = "FIRST_NAME",
  LastName = "LAST_NAME",
  Manager = "MANAGER",
  Team = "TEAM",
  Title = "TITLE",
}

export type UserTag = {
  __typename?: "UserTag";
  createdAt: Scalars["Time"];
  creatorUserId: Scalars["UserId"];
  id: Scalars["UserTagId"];
  source: ServiceType;
  tag?: Maybe<Tag>;
  tagId: Scalars["TagId"];
  updatedAt: Scalars["Time"];
  user?: Maybe<User>;
  userId: Scalars["UserId"];
};

export type UserTagKeyExistsError = Error & {
  __typename?: "UserTagKeyExistsError";
  message: Scalars["String"];
};

export type UserTagNotFoundError = Error & {
  __typename?: "UserTagNotFoundError";
  message: Scalars["String"];
};

export type UserTagsInput = {
  source?: InputMaybe<ServiceType>;
  userId?: InputMaybe<Scalars["UserId"]>;
};

export type UserTagsOutput = UserTagsResult;

export type UserTagsResult = {
  __typename?: "UserTagsResult";
  userTags: Array<UserTag>;
};

export type ValidateCodeAndSaveIdTokenInput = {
  code: Scalars["String"];
  oidcProviderType: OidcProviderType;
  state: Scalars["String"];
};

export type VaultClientNotFoundError = Error & {
  __typename?: "VaultClientNotFoundError";
  message: Scalars["String"];
};

export type VaultMongoAtlasSession = {
  __typename?: "VaultMongoAtlasSession";
  authenticationDbName: Scalars["String"];
  dbHostname: Scalars["String"];
  dbPassword: Scalars["String"];
  dbPort: Scalars["Int"];
  dbUser: Scalars["String"];
  id: Scalars["ResourceId"];
  initDbName: Scalars["String"];
  tlsConfig: TlsConfig;
};

export type VaultMongoSession = {
  __typename?: "VaultMongoSession";
  authenticationDbName: Scalars["String"];
  dbHostname: Scalars["String"];
  dbPassword: Scalars["String"];
  dbPort: Scalars["Int"];
  dbUser: Scalars["String"];
  id: Scalars["ResourceId"];
  tlsConfig: TlsConfig;
};

export type VaultMySqlMariaDbSession = {
  __typename?: "VaultMySQLMariaDBSession";
  dbHostname: Scalars["String"];
  dbPassword: Scalars["String"];
  dbPort: Scalars["Int"];
  dbUser: Scalars["String"];
  id: Scalars["ResourceId"];
  tlsConfig: TlsConfig;
};

export type VaultPostgresSession = {
  __typename?: "VaultPostgresSession";
  dbHostname: Scalars["String"];
  dbPassword: Scalars["String"];
  dbPort: Scalars["Int"];
  dbUser: Scalars["String"];
  id: Scalars["ResourceId"];
  tlsConfig: TlsConfig;
};

export type VaultSessionError = Error & {
  __typename?: "VaultSessionError";
  connection?: Maybe<Connection>;
  connectionId: Scalars["ConnectionId"];
  message: Scalars["String"];
};

export type VaultSessionsInput = {
  resourceId: Scalars["ResourceId"];
};

export type VaultSessionsOutput = VaultSessionsResult;

export type VaultSessionsResult = {
  __typename?: "VaultSessionsResult";
  sessions: Array<Session>;
};

export type VerifyFactorAsyncInput = {
  factorRemoteId: Scalars["UserFactorRemoteId"];
  transactionId?: InputMaybe<Scalars["String"]>;
};

export type VerifyFactorAsyncOutput =
  | ConnectionUserNotFound
  | FactorNotFoundError
  | MissingTokenPermissionError
  | VerifyFactorAsyncResult;

export type VerifyFactorAsyncResult = {
  __typename?: "VerifyFactorAsyncResult";
  status: VerifyFactorStatus;
  transactionId: Scalars["String"];
};

export enum VerifyFactorStatus {
  Failed = "FAILED",
  Rejected = "REJECTED",
  Success = "SUCCESS",
  Timeout = "TIMEOUT",
  Waiting = "WAITING",
}

export type VerifyFactorSyncInput = {
  factorRemoteId: Scalars["UserFactorRemoteId"];
  passCode: Scalars["String"];
};

export type VerifyFactorSyncOutput =
  | ConnectionUserNotFound
  | FactorNotFoundError
  | MissingTokenPermissionError
  | VerifyFactorSyncResult;

export type VerifyFactorSyncResult = {
  __typename?: "VerifyFactorSyncResult";
  status: VerifyFactorStatus;
};

export enum Visibility {
  Global = "GLOBAL",
  Team = "TEAM",
}

export type VisualizationFilter = {
  hideGroups?: InputMaybe<Scalars["Boolean"]>;
  includeUnmanagedItems?: InputMaybe<Scalars["Boolean"]>;
  selection: VisualizationFilterSelection;
  showUsage?: InputMaybe<Scalars["Boolean"]>;
};

export type VisualizationFilterSelection = {
  groupIds?: InputMaybe<Array<Scalars["GroupId"]>>;
  multiGroupSelections?: InputMaybe<Array<MultiGroupSelection>>;
  multiResourceSelections?: InputMaybe<Array<MultiResourceSelection>>;
  resourceIds?: InputMaybe<Array<Scalars["ResourceId"]>>;
  userIds?: InputMaybe<Array<Scalars["UserId"]>>;
};

export type VisualizationOutput =
  | UserFacingError
  | VisualizationResult
  | VisualizationTimeoutError;

export type VisualizationResult = {
  __typename?: "VisualizationResult";
  accessLevels: Array<AccessLevelNode>;
  groupResourceEdges: Array<GroupResourceEdge>;
  groups: Array<Group>;
  resources: Array<Resource>;
  userGroupEdges: Array<UserGroupEdge>;
  userResourceEdges: Array<UserResourceEdge>;
  users: Array<User>;
};

export type VisualizationTimeoutError = Error & {
  __typename?: "VisualizationTimeoutError";
  message: Scalars["String"];
};

export type WebhookApiKeyPubsubPublishConnectionCredentialsInput = {
  credentials: Array<WebhookPubsubPublishConnectionApiKeyCredentialsInput>;
};

export type WebhookInfoOutput = WebhookInfoResult;

export type WebhookInfoResult = {
  __typename?: "WebhookInfoResult";
  webhookInfo?: Maybe<OrganizationWebhookInfo>;
};

export type WebhookPubsubPublishConnectionApiKeyCredentialOutput = {
  __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialOutput";
  location: WebhookPubsubPublishConnectionApiKeyLocation;
  name: Scalars["String"];
  valuePreview: Scalars["String"];
};

export type WebhookPubsubPublishConnectionApiKeyCredentialsInput = {
  location: WebhookPubsubPublishConnectionApiKeyLocation;
  name: Scalars["String"];
  value: Scalars["String"];
};

export type WebhookPubsubPublishConnectionApiKeyCredentialsOutput = {
  __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialsOutput";
  credentials: Array<WebhookPubsubPublishConnectionApiKeyCredentialOutput>;
};

export enum WebhookPubsubPublishConnectionApiKeyLocation {
  Header = "HEADER",
  QueryParam = "QUERY_PARAM",
}

export enum WebhookPubsubPublishConnectionAuthType {
  ApiKey = "API_KEY",
  Hmac = "HMAC",
  None = "NONE",
}

export type WebhookPubSubPublishConnectionCredentialsInput = {
  apiKey?: InputMaybe<WebhookApiKeyPubsubPublishConnectionCredentialsInput>;
  authType: WebhookPubsubPublishConnectionAuthType;
};

export type WebhookPubsubPublishConnectionCredentialsOutput = {
  __typename?: "WebhookPubsubPublishConnectionCredentialsOutput";
  apiKey?: Maybe<WebhookPubsubPublishConnectionApiKeyCredentialsOutput>;
  authType: WebhookPubsubPublishConnectionAuthType;
  hmac?: Maybe<WebhookPubsubPublishConnectionHmacCredentialsOutput>;
};

export type WebhookPubsubPublishConnectionHmacCredentialOutput = {
  __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
  createdAt: Scalars["Time"];
  secret?: Maybe<Scalars["String"]>;
  secretPreview: Scalars["String"];
};

export type WebhookPubsubPublishConnectionHmacCredentialsOutput = {
  __typename?: "WebhookPubsubPublishConnectionHmacCredentialsOutput";
  credential1?: Maybe<WebhookPubsubPublishConnectionHmacCredentialOutput>;
  credential2?: Maybe<WebhookPubsubPublishConnectionHmacCredentialOutput>;
};

export type WebhookPubSubPublishConnectionMetadata = {
  __typename?: "WebhookPubSubPublishConnectionMetadata";
  url: Scalars["String"];
};

export type WebhookPubSubPublishConnectionMetadataInput = {
  url: Scalars["String"];
};

export type WorkdayConnectionMetadata = {
  __typename?: "WorkdayConnectionMetadata";
  tenantName: Scalars["String"];
  tenantUrl: Scalars["String"];
};

export type WorkdayConnectionMetadataInput = {
  tenantName: Scalars["String"];
  tenantUrl: Scalars["String"];
};

export type WorkdayCredentialsInput = {
  rootPassword: Scalars["String"];
  rootUserName: Scalars["String"];
};

export type AccessFragment = {
  __typename?: "Access";
  entityId: string;
  entityType: EntityType;
  principalId: string;
  principalType: EntityType;
  accessLevel?: {
    __typename: "AccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  } | null;
  latestExpiringAccessPoint: {
    __typename?: "AccessPoint";
    entityId: string;
    entityType: EntityType;
    entityName: string;
    entityItemType: string;
    principalId: string;
    principalType: EntityType;
    principalName: string;
    eventId?: string | null;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
  directAccessPoint?: {
    __typename?: "AccessPoint";
    entityId: string;
    entityType: EntityType;
    entityName: string;
    entityItemType: string;
    principalId: string;
    principalType: EntityType;
    principalName: string;
    eventId?: string | null;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  inheritedAccessPoints: Array<{
    __typename?: "AccessPoint";
    entityId: string;
    entityType: EntityType;
    entityName: string;
    entityItemType: string;
    principalId: string;
    principalType: EntityType;
    principalName: string;
    eventId?: string | null;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  }>;
};

export type AccessPointFragment = {
  __typename?: "AccessPoint";
  entityId: string;
  entityType: EntityType;
  entityName: string;
  entityItemType: string;
  principalId: string;
  principalType: EntityType;
  principalName: string;
  eventId?: string | null;
  createdAt: string;
  expiration?: string | null;
  supportTicketId?: string | null;
  accessLevel?: {
    __typename: "AccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  } | null;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type AccessLevelFragment = {
  __typename: "AccessLevel";
  accessLevelName: string;
  accessLevelRemoteId: string;
  accessLevelMetadata?: {
    __typename: "ImpersonationAccessLevelMetadata";
    avatarUrl: string;
  } | null;
};

export type UpdateConnectionReviewersMutationVariables = Exact<{
  input: UpdateConnectionReviewersInput;
}>;

export type UpdateConnectionReviewersMutation = {
  __typename?: "Mutation";
  updateConnectionReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateConnectionReviewersResult";
        accessReviewConnection: {
          __typename?: "AccessReviewConnection";
          id: string;
          createdAt: string;
          updatedAt: string;
          connectionId: string;
          accessReviewId: string;
          numConnectionUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          connectionUsers?: Array<{
            __typename?: "AccessReviewConnectionUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            connectionId: string;
            accessReviewConnectionId: string;
            eventId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewConnectionUserWarning";
              accessReviewConnectionUserReviewerId: string;
              accessReviewConnectionUserId: string;
              accessReviewConnectionId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              connection?: {
                __typename?: "ConnectionSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        };
      };
};

export type UpdateConnectionUserReviewersMutationVariables = Exact<{
  input: UpdateConnectionUserReviewersInput;
}>;

export type UpdateConnectionUserReviewersMutation = {
  __typename?: "Mutation";
  updateConnectionUserReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateAccessReviewReviewersValidationError";
        message: string;
      }
    | {
        __typename: "UpdateConnectionUserReviewersResult";
        accessReviewConnection: {
          __typename?: "AccessReviewConnection";
          id: string;
          createdAt: string;
          updatedAt: string;
          connectionId: string;
          accessReviewId: string;
          numConnectionUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          connectionUsers?: Array<{
            __typename?: "AccessReviewConnectionUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            connectionId: string;
            accessReviewConnectionId: string;
            eventId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewConnectionUserWarning";
              accessReviewConnectionUserReviewerId: string;
              accessReviewConnectionUserId: string;
              accessReviewConnectionId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              connection?: {
                __typename?: "ConnectionSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        };
      };
};

export type AccessReviewConnectionUserFragment = {
  __typename?: "AccessReviewConnectionUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  accessReviewConnectionId: string;
  userId: string;
  connectionId: string;
  eventId?: string | null;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewConnectionUserWarning";
    accessReviewConnectionUserReviewerId: string;
    accessReviewConnectionUserId: string;
    accessReviewConnectionId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    connection?: {
      __typename?: "ConnectionSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
};

export type AccessReviewConnectionUserPreviewFragment = {
  __typename?: "AccessReviewConnectionUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  connectionId: string;
  accessReviewConnectionId: string;
  eventId?: string | null;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewConnectionUserWarning";
    accessReviewConnectionUserReviewerId: string;
    accessReviewConnectionUserId: string;
    accessReviewConnectionId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    connection?: {
      __typename?: "ConnectionSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
};

export type AccessReviewUserConnectionPreviewFragment = {
  __typename?: "AccessReviewConnectionUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  connectionId: string;
  accessReviewConnectionId: string;
  eventId?: string | null;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewConnectionUserWarning";
    accessReviewConnectionUserReviewerId: string;
    accessReviewConnectionUserId: string;
    accessReviewConnectionId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    connection?: {
      __typename?: "ConnectionSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
};

export type AccessReviewConnectionUserWarningFragment = {
  __typename?: "AccessReviewConnectionUserWarning";
  accessReviewConnectionUserReviewerId: string;
  accessReviewConnectionUserId: string;
  accessReviewConnectionId: string;
  accessReviewUserWarningType: AccessReviewUserWarningType;
  userId: string;
  reviewerId?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  reviewer?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  connection?: {
    __typename?: "ConnectionSmall";
    id: string;
    name: string;
  } | null;
};

export type AccessReviewConnectionPreviewFragment = {
  __typename?: "AccessReviewConnection";
  id: string;
  createdAt: string;
  updatedAt: string;
  connectionId: string;
  accessReviewId: string;
  numConnectionUsers: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  warnings?: Array<{
    __typename?: "AccessReviewConnectionUserWarning";
    accessReviewConnectionUserReviewerId: string;
    accessReviewConnectionUserId: string;
    accessReviewConnectionId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    connection?: {
      __typename?: "ConnectionSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type AccessReviewConnectionReviewerStatusFragment = {
  __typename?: "AccessReviewConnectionReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numUsersReviewed: number;
  numUsersAssigned: number;
};

export type AccessReviewConnectionFragment = {
  __typename?: "AccessReviewConnection";
  id: string;
  createdAt: string;
  updatedAt: string;
  connectionId: string;
  accessReviewId: string;
  numConnectionUsers: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  accessReview?: {
    __typename?: "AccessReview";
    id: string;
    createdAt: string;
    startedByUserId: string;
    stoppedByUserId?: string | null;
    stoppedDate?: string | null;
    deadline: string;
    timeZone: string;
    selfReviewAllowed: boolean;
    name: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    lastReminderAt?: string | null;
    sendReviewerAssignmentNotification: boolean;
    reportId?: string | null;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    accessReviewTemplateId?: string | null;
    includeGroupBindings: boolean;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  connectionUsers?: Array<{
    __typename?: "AccessReviewConnectionUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    connectionId: string;
    accessReviewConnectionId: string;
    eventId?: string | null;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewConnectionUserWarning";
      accessReviewConnectionUserReviewerId: string;
      accessReviewConnectionUserId: string;
      accessReviewConnectionId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      connection?: {
        __typename?: "ConnectionSmall";
        id: string;
        name: string;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewConnectionUserWarning";
    accessReviewConnectionUserReviewerId: string;
    accessReviewConnectionUserId: string;
    accessReviewConnectionId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    connection?: {
      __typename?: "ConnectionSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewConnectionReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
  } | null;
};

export type AccessReviewConnectionQueryVariables = Exact<{
  input: AccessReviewConnectionInput;
}>;

export type AccessReviewConnectionQuery = {
  __typename?: "Query";
  accessReviewConnection:
    | { __typename: "AccessReviewConnectionNotFoundError"; message: string }
    | {
        __typename: "AccessReviewConnectionResult";
        accessReviewConnection: {
          __typename?: "AccessReviewConnection";
          id: string;
          createdAt: string;
          updatedAt: string;
          connectionId: string;
          accessReviewId: string;
          numConnectionUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          connectionUsers?: Array<{
            __typename?: "AccessReviewConnectionUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            connectionId: string;
            accessReviewConnectionId: string;
            eventId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewConnectionUserWarning";
              accessReviewConnectionUserReviewerId: string;
              accessReviewConnectionUserId: string;
              accessReviewConnectionId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              connection?: {
                __typename?: "ConnectionSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        };
      };
};

export type AccessReviewConnectionsQueryVariables = Exact<{
  input: AccessReviewConnectionsInput;
}>;

export type AccessReviewConnectionsQuery = {
  __typename?: "Query";
  accessReviewConnections:
    | {
        __typename: "AccessReviewConnectionsResult";
        accessReviewConnections: Array<{
          __typename?: "AccessReviewConnection";
          id: string;
          createdAt: string;
          updatedAt: string;
          connectionId: string;
          accessReviewId: string;
          numConnectionUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | { __typename: "AccessReviewNotFoundError"; message: string };
};

export type AccessReviewFilteredConnectionsQueryVariables = Exact<{
  input: FilteredConnectionsInput;
}>;

export type AccessReviewFilteredConnectionsQuery = {
  __typename?: "Query";
  filteredConnections: {
    __typename?: "FilteredConnectionsOutput";
    connections: Array<{
      __typename: "Connection";
      id: string;
      name: string;
      description: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      visibility: Visibility;
      importVisibility: Visibility;
      importSetting: ImportSetting;
      autoImportGroupResources: boolean;
      numUsers: number;
      webhookEnabled: boolean;
      iconUrl?: string | null;
      adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
      visibilityGroups: Array<{
        __typename?: "ConnectionVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup?: { __typename?: "Group"; name: string } | null;
      }>;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
      childrenDefaultConfigTemplate?: {
        __typename?: "ConfigurationTemplate";
        id: string;
        name: string;
      } | null;
      importNotificationOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type UpdateGroupResourceReviewersMutationVariables = Exact<{
  input: UpdateGroupResourceReviewersInput;
}>;

export type UpdateGroupResourceReviewersMutation = {
  __typename?: "Mutation";
  updateGroupResourceReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateGroupResourceReviewersResult";
        accessReviewGroupResource: {
          __typename?: "AccessReviewGroupResource";
          id: string;
        };
        accessReviewGroup: { __typename?: "AccessReviewGroup"; id: string };
      };
};

export type AccessReviewGroupResourceFragment = {
  __typename?: "AccessReviewGroupResource";
  id: string;
  createdAt: string;
  updatedAt: string;
  resourceId: string;
  accessReviewResourceId?: string | null;
  eventId: string;
  expiration?: string | null;
  accessReviewResource?: {
    __typename?: "AccessReviewResource";
    id: string;
    createdAt: string;
    updatedAt: string;
    resourceId: string;
    accessReviewId: string;
    numResourceUsers: number;
    eventId?: string | null;
    canEditReviewers: boolean;
    status: AccessReviewSummaryStatus;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    } | null;
    reviewerUsers: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }>;
    warnings?: Array<{
      __typename?: "AccessReviewResourceUserWarning";
      accessReviewResourceUserReviewerId: string;
      accessReviewResourceUserId: string;
      accessReviewResourceId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      resource?: {
        __typename?: "ResourceSmall";
        id: string;
        name: string;
      } | null;
    }> | null;
  } | null;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  groupResourceAccess?: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  } | null;
};

export type UpdateAccessReviewGroupReviewersMutationVariables = Exact<{
  input: UpdateAccessReviewGroupReviewersInput;
}>;

export type UpdateAccessReviewGroupReviewersMutation = {
  __typename?: "Mutation";
  updateAccessReviewGroupReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateAccessReviewGroupReviewersResult";
        accessReviewGroup: {
          __typename?: "AccessReviewGroup";
          id: string;
          createdAt: string;
          updatedAt: string;
          groupId: string;
          accessReviewId: string;
          numGroupUsers: number;
          numGroupResources: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            groupType: GroupType;
            serviceType: ServiceType;
            iconUrl?: string | null;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            updatedAt: string;
            description: string;
            numGroupUsers: number;
            connectionId: string;
            isOnCallSynced: boolean;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            groupBindingId?: string | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserGroupAccess";
              groupId: string;
              hasBreakGlass: boolean;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
              pendingRequest?: {
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              } | null;
            };
            tags: Array<{
              __typename?: "GroupTag";
              groupId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              createdAt: string;
              sourceGroup?: {
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              } | null;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              }>;
              createdByUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            groupLeaders: Array<{
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            }>;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          groupUsers?: Array<{
            __typename?: "AccessReviewGroupUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            groupId: string;
            accessReviewGroupId: string;
            eventId: string;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewGroupUserWarning";
              accessReviewGroupUserReviewerId: string;
              accessReviewGroupUserId: string;
              accessReviewGroupId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              group?: {
                __typename?: "GroupSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
            } | null;
          }> | null;
          groupResources?: Array<{
            __typename?: "AccessReviewGroupResource";
            id: string;
            createdAt: string;
            updatedAt: string;
            resourceId: string;
            accessReviewResourceId?: string | null;
            eventId: string;
            expiration?: string | null;
            accessReviewResource?: {
              __typename?: "AccessReviewResource";
              id: string;
              createdAt: string;
              updatedAt: string;
              resourceId: string;
              accessReviewId: string;
              numResourceUsers: number;
              eventId?: string | null;
              canEditReviewers: boolean;
              status: AccessReviewSummaryStatus;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                recommendedDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                isManaged: boolean;
                autoApproval: boolean;
                reasonOptional: boolean;
                requestTemplateId?: string | null;
                parentResourceId?: string | null;
                authorizedActions?: Array<string> | null;
                customRequestNotification?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                childResources?: Array<{
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  createdAt: string;
                  description: string;
                  connectionId: string;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  maxDurationInMinutes?: number | null;
                  requireMfaToConnect: boolean;
                  requireMfaToApprove: boolean;
                  requireMfaToRequest: boolean;
                  requireSupportTicket: boolean;
                  isRequestable: boolean;
                  autoApproval: boolean;
                  parentResourceId?: string | null;
                  adminOwner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                    ownerUsers: Array<{
                      __typename?: "OwnerUser";
                      user?: { __typename?: "User"; id: string } | null;
                    }>;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  currentUserAccess: {
                    __typename?: "CurrentUserResourceAccess";
                    resourceId: string;
                    numAvailableAccessLevels: number;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    activeSessions: Array<{
                      __typename?: "Session";
                      id: string;
                      durationInMinutes?: number | null;
                      endTime: string;
                      accessLevel?: string | null;
                      accessLevelRemoteId?: string | null;
                      metadata:
                        | {
                            __typename: "AwsIamFederatedEksSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            clusterName: string;
                            clusterRegion: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRdsSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            dbInstanceId: string;
                            dbPassword: string;
                            dbEngine: string;
                            dbUser: string;
                            dbHostname: string;
                            dbPort: number;
                            dbName: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRoleSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                          }
                        | {
                            __typename: "AwsIamFederatedSSMSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                            ec2InstanceId: string;
                            ec2Region: string;
                          }
                        | { __typename: "OidcSession" }
                        | { __typename: "OpalImpersonationSession" }
                        | {
                            __typename: "VaultMongoAtlasSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            initDbName: string;
                          }
                        | {
                            __typename: "VaultMongoSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            authenticationDbName: string;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultMySQLMariaDBSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultPostgresSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          };
                    }>;
                    pendingRequests: Array<{
                      __typename?: "Request";
                      id: string;
                      requesterId: string;
                      targetUserId?: string | null;
                      targetGroupId?: string | null;
                      resourceProposalId?: string | null;
                      createdAt: string;
                      updatedAt: string;
                      status: RequestStatus;
                      requester?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetUser?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetGroup?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      requestedResources: Array<{
                        __typename?: "RequestedResource";
                        resourceId: string;
                        resource?: {
                          __typename: "Resource";
                          id: string;
                          name: string;
                          remoteName: string;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isDeleted: boolean;
                          isManaged: boolean;
                          resourceType: ResourceType;
                          remoteId: string;
                          iconUrl?: string | null;
                          parentResourceId?: string | null;
                          connectionId: string;
                          createdAt: string;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          parentResource?: {
                            __typename?: "Resource";
                            id: string;
                            name: string;
                          } | null;
                          connection?: {
                            __typename: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                            isDeleted: boolean;
                            metadata?:
                              | {
                                  __typename: "ADConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                }
                              | {
                                  __typename: "AwsConnectionMetadata";
                                  success?: boolean | null;
                                }
                              | {
                                  __typename: "AWSSSOConnectionMetadata";
                                  managementAccountId: string;
                                  ssoInstanceArn: string;
                                  identityStoreId: string;
                                  accessPortalUrl: string;
                                  awsRegion: string;
                                  awsOrganizationEnabled: boolean;
                                  awsSsoEnabled: boolean;
                                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                  awsOrganizationImportSetting: ImportSetting;
                                  ec2RoleChainingEnabled: boolean;
                                  podRoleChainingEnabled: boolean;
                                  cloudtrailEventsSqsUrl: string;
                                }
                              | {
                                  __typename: "AzureADConnectionMetadata";
                                  tenantId: string;
                                  clientId: string;
                                  azureInfraEnabled: boolean;
                                  eventHubNamespace: string;
                                  eventHub: string;
                                }
                              | {
                                  __typename: "CustomConnectorConnectionMetadata";
                                  identifier: string;
                                  baseUrl: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                  supportsGroups: boolean;
                                  supportsNestedResources: boolean;
                                }
                              | {
                                  __typename: "DuoConnectionMetadata";
                                  apiHostname: string;
                                }
                              | {
                                  __typename: "GCPConnectionMetadata";
                                  pubSubProjectId: string;
                                  subscriptionId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GitHubConnectionMetadata";
                                  orgName: string;
                                }
                              | {
                                  __typename: "GitLabConnectionMetadata";
                                  hostName?: string | null;
                                  appId: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "GoogleGroupsConnectionMetadata";
                                  opalGroupEmail: string;
                                  adminUserEmail: string;
                                  domain: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GoogleWorkspaceConnectionMetadata";
                                  adminUserEmail: string;
                                  customerId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "LDAPConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                  groupsUidKey: string;
                                }
                              | {
                                  __typename: "MongoAtlasConnectionMetadata";
                                  serverHostname: string;
                                }
                              | {
                                  __typename: "MongoConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "MySQLMariaDBConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "OktaDirectoryConnectionMetadata";
                                  orgUrl: string;
                                }
                              | { __typename: "PagerDutyConnectionMetadata" }
                              | {
                                  __typename: "PostgresConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "PropagationTicketConnectionMetadata";
                                  ticketProvider?: {
                                    __typename?: "TicketProvider";
                                    ticketProvider: ThirdPartyProvider;
                                    ticketProjectId: string;
                                    ticketProjectName: string;
                                  } | null;
                                }
                              | {
                                  __typename: "SalesforceConnectionMetadata";
                                  email: string;
                                  hostname: string;
                                }
                              | {
                                  __typename: "SnowflakeConnectionMetadata";
                                  organization: string;
                                  account: string;
                                  locator: string;
                                  accountIdentifier: string;
                                  accountUrl: string;
                                  serviceAccountName: string;
                                  serviceAccountRole: string;
                                }
                              | {
                                  __typename: "TailscaleConnectionMetadata";
                                  tailnet: string;
                                }
                              | {
                                  __typename: "TeleportConnectionMetadata";
                                  host: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "WorkdayConnectionMetadata";
                                  tenantName: string;
                                  tenantUrl: string;
                                }
                              | null;
                          } | null;
                          metadata?:
                            | { __typename: "AwsRoleMetadata"; arn: string }
                            | {
                                __typename: "OktaDirectoryAppMetadata";
                                appId: string;
                                appName: string;
                                logoUrl?: string | null;
                              }
                            | {
                                __typename: "OktaRoleMetadata";
                                roleId: string;
                                roleType: string;
                              }
                            | {
                                __typename: "PropagationTicketOwnerMetadata";
                                ownerId?: string | null;
                                owner?: {
                                  __typename?: "Owner";
                                  id: string;
                                  name: string;
                                } | null;
                              }
                            | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        targetUserAccess?: {
                          __typename?: "UserResourceAccess";
                          resourceId: string;
                          userId: string;
                          resourceUsers: Array<{
                            __typename: "ResourceUser";
                            resourceId: string;
                            userId: string;
                            mostRecentSessionEnded?: string | null;
                            source: ResourceUserSource;
                            user?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              createdAt: string;
                              updatedAt: string;
                              organizationId: string;
                              position: string;
                              teamAttr?: string | null;
                              managerId?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                              manager?: {
                                __typename?: "User";
                                id: string;
                                email: string;
                                secondaryEmails: Array<string>;
                                firstName: string;
                                lastName: string;
                                fullName: string;
                                avatarUrl: string;
                                isDeleted: boolean;
                                isSystemUser: boolean;
                                position: string;
                                teamAttr?: string | null;
                                hrIdpStatus?: HrIdpStatus | null;
                              } | null;
                            } | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            access?: {
                              __typename?: "ResourceUserAccess";
                              resourceId: string;
                              userId: string;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              latestExpiringAccessPoint: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              inheritedAccessPoints: Array<{
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                              groupUserAccesses: Array<{
                                __typename?: "GroupUserAccess";
                                groupId: string;
                                userId: string;
                                isGroupManaged: boolean;
                                latestExpiringAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                directAccessPoint?: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                } | null;
                                indirectAccessPointPaths: Array<{
                                  __typename?: "IndirectGroupUserAccessPointPath";
                                  startGroupId: string;
                                  endGroupId: string;
                                  userId: string;
                                  resolvedAccessPoint: {
                                    __typename?: "GroupUserAccessPoint";
                                    groupId: string;
                                    userId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                    expiration?: string | null;
                                    supportTicketId?: string | null;
                                    source: GroupUserSource;
                                    accessLevel?: {
                                      __typename: "GroupAccessLevel";
                                      accessLevelName: string;
                                      accessLevelRemoteId: string;
                                    } | null;
                                    supportTicket?: {
                                      __typename?: "SupportTicket";
                                      id?: string | null;
                                      remoteId: string;
                                      url: string;
                                      identifier: string;
                                      thirdPartyProvider: ThirdPartyProvider;
                                      projectName: string;
                                    } | null;
                                  };
                                  indirectAccessPointPath: Array<{
                                    __typename?: "IndirectGroupAccessPoint";
                                    groupId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                  }>;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "ResourcePropagationStatus";
                              resourceId: string;
                              userId: string;
                              accessLevelRemoteId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              metadata?: {
                                __typename: "PropagationTicketMetadata";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                      }>;
                      requestedGroups: Array<{
                        __typename?: "RequestedGroup";
                        groupId: string;
                        group?: {
                          __typename?: "Group";
                          id: string;
                          remoteId?: string | null;
                          name: string;
                          remoteName: string;
                          numGroupUsers: number;
                          groupType: GroupType;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isManaged: boolean;
                          isDeleted: boolean;
                          iconUrl?: string | null;
                          isOnCallSynced: boolean;
                          connectionId: string;
                          groupBindingId?: string | null;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          connection?: {
                            __typename?: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                          } | null;
                          groupBinding?: {
                            __typename?: "GroupBinding";
                            id: string;
                            sourceGroupId: string;
                            groups: Array<{
                              __typename?: "Group";
                              id: string;
                              name: string;
                              groupType: GroupType;
                              connectionId: string;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        targetUserAccess?: {
                          __typename?: "UserGroupAccess";
                          groupId: string;
                          userId: string;
                          groupUser?: {
                            __typename?: "GroupUser";
                            groupId: string;
                            userId: string;
                            source: GroupUserSource;
                            access?: {
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "GroupPropagationStatus";
                              groupId: string;
                              userId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              ticketPropagation?: {
                                __typename: "GroupUserTicketPropagation";
                                resourceTickets: Array<{
                                  __typename?: "ResourceTicketPropagation";
                                  ticket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                      resourceProposal?: {
                        __typename?: "ResourceProposal";
                        id: string;
                        connectionId: string;
                        serviceType: ServiceType;
                        resourceType: ResourceType;
                        metadata?: {
                          __typename: "AWSRoleProposalMetadata";
                          policyName: string;
                          policyDocument: string;
                          policyDescription: string;
                          assumeRolePolicyDocument: string;
                          roleName: string;
                          roleDescription: string;
                          tags: Array<{
                            __typename?: "AWSTag";
                            key: string;
                            value?: string | null;
                          }>;
                        } | null;
                      } | null;
                    }>;
                  };
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                  commonMetadata: {
                    __typename?: "CommonMetadata";
                    matchRemoteName: boolean;
                    matchRemoteDescription: boolean;
                  };
                  tags: Array<{
                    __typename?: "ResourceTag";
                    id: string;
                    resourceId: string;
                    tagId: string;
                    source: ServiceType;
                    updatedAt: string;
                    createdAt: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    tag?: {
                      __typename: "Tag";
                      id: string;
                      createdAt: string;
                      creatorUserId: string;
                      key: string;
                      value?: string | null;
                      isStandardAttribute: boolean;
                    } | null;
                  }>;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                }> | null;
                requestTemplate?: {
                  __typename?: "RequestTemplate";
                  id: string;
                  name: string;
                  isDeleted?: boolean | null;
                  customFields?: Array<{
                    __typename?: "RequestTemplateCustomField";
                    name: string;
                    type: RequestTemplateCustomFieldType;
                    required?: boolean | null;
                    description?: string | null;
                    metadata?: {
                      __typename?: "RequestTemplateCustomFieldMetadata";
                      multiChoiceData?: {
                        __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                        options?: Array<{
                          __typename?: "MultiChoiceOption";
                          value: string;
                          label: string;
                        }> | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
              } | null;
              reviewerUsers: Array<{
                __typename?: "ReviewerUser";
                userId: string;
                entityId: string;
                entityType: EntityType;
                name: string;
                avatarUrl: string;
                status: ReviewerUserStatus;
                note?: string | null;
                updatedResourceId?: string | null;
                updatedAccessLevel?: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                updatedResource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
              }>;
              warnings?: Array<{
                __typename?: "AccessReviewResourceUserWarning";
                accessReviewResourceUserReviewerId: string;
                accessReviewResourceUserId: string;
                accessReviewResourceId: string;
                accessReviewUserWarningType: AccessReviewUserWarningType;
                userId: string;
                reviewerId?: string | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                reviewer?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                resource?: {
                  __typename?: "ResourceSmall";
                  id: string;
                  name: string;
                } | null;
              }> | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupResourceAccess?: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        };
      };
};

export type UpdateGroupUserReviewersMutationVariables = Exact<{
  input: UpdateGroupUserReviewersInput;
}>;

export type UpdateGroupUserReviewersMutation = {
  __typename?: "Mutation";
  updateGroupUserReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateAccessReviewReviewersValidationError";
        message: string;
      }
    | { __typename: "UpdateGroupUserReviewersResult" };
};

export type AccessReviewGroupUserFragment = {
  __typename?: "AccessReviewGroupUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  accessReviewGroupId: string;
  userId: string;
  groupId: string;
  eventId: string;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  group?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  accessLevel: {
    __typename?: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type AccessReviewGroupUserPreviewFragment = {
  __typename?: "AccessReviewGroupUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  groupId: string;
  accessReviewGroupId: string;
  eventId: string;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  accessLevel: {
    __typename?: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type AccessReviewUserGroupPreviewFragment = {
  __typename?: "AccessReviewGroupUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  groupId: string;
  accessReviewGroupId: string;
  eventId: string;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  group?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  accessLevel: {
    __typename?: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type AccessReviewGroupUserWarningFragment = {
  __typename?: "AccessReviewGroupUserWarning";
  accessReviewGroupUserReviewerId: string;
  accessReviewGroupUserId: string;
  accessReviewGroupId: string;
  accessReviewUserWarningType: AccessReviewUserWarningType;
  userId: string;
  reviewerId?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  reviewer?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
};

export type AccessReviewGroupPreviewFragment = {
  __typename?: "AccessReviewGroup";
  id: string;
  createdAt: string;
  updatedAt: string;
  groupId: string;
  accessReviewId: string;
  numGroupUsers: number;
  numGroupResources: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  warnings?: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }> | null;
};

export type AccessReviewGroupReviewerStatusFragment = {
  __typename?: "AccessReviewGroupReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numUsersReviewed: number;
  numUsersAssigned: number;
  numResourcesReviewed: number;
  numResourcesAssigned: number;
};

export type AccessReviewGroupFragment = {
  __typename?: "AccessReviewGroup";
  id: string;
  createdAt: string;
  updatedAt: string;
  groupId: string;
  accessReviewId: string;
  numGroupUsers: number;
  numGroupResources: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  accessReview?: {
    __typename?: "AccessReview";
    id: string;
    createdAt: string;
    startedByUserId: string;
    stoppedByUserId?: string | null;
    stoppedDate?: string | null;
    deadline: string;
    timeZone: string;
    selfReviewAllowed: boolean;
    name: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    lastReminderAt?: string | null;
    sendReviewerAssignmentNotification: boolean;
    reportId?: string | null;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    accessReviewTemplateId?: string | null;
    includeGroupBindings: boolean;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  groupUsers?: Array<{
    __typename?: "AccessReviewGroupUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    groupId: string;
    accessReviewGroupId: string;
    eventId: string;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewGroupUserWarning";
      accessReviewGroupUserReviewerId: string;
      accessReviewGroupUserId: string;
      accessReviewGroupId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
    } | null;
  }> | null;
  groupResources?: Array<{
    __typename?: "AccessReviewGroupResource";
    id: string;
    createdAt: string;
    updatedAt: string;
    resourceId: string;
    accessReviewResourceId?: string | null;
    eventId: string;
    expiration?: string | null;
    accessReviewResource?: {
      __typename?: "AccessReviewResource";
      id: string;
      createdAt: string;
      updatedAt: string;
      resourceId: string;
      accessReviewId: string;
      numResourceUsers: number;
      eventId?: string | null;
      canEditReviewers: boolean;
      status: AccessReviewSummaryStatus;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        recommendedDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        isManaged: boolean;
        autoApproval: boolean;
        reasonOptional: boolean;
        requestTemplateId?: string | null;
        parentResourceId?: string | null;
        authorizedActions?: Array<string> | null;
        customRequestNotification?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        childResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          autoApproval: boolean;
          parentResourceId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
        }> | null;
        requestTemplate?: {
          __typename?: "RequestTemplate";
          id: string;
          name: string;
          isDeleted?: boolean | null;
          customFields?: Array<{
            __typename?: "RequestTemplateCustomField";
            name: string;
            type: RequestTemplateCustomFieldType;
            required?: boolean | null;
            description?: string | null;
            metadata?: {
              __typename?: "RequestTemplateCustomFieldMetadata";
              multiChoiceData?: {
                __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                options?: Array<{
                  __typename?: "MultiChoiceOption";
                  value: string;
                  label: string;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      } | null;
      reviewerUsers: Array<{
        __typename?: "ReviewerUser";
        userId: string;
        entityId: string;
        entityType: EntityType;
        name: string;
        avatarUrl: string;
        status: ReviewerUserStatus;
        note?: string | null;
        updatedResourceId?: string | null;
        updatedAccessLevel?: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        updatedResource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
      }>;
      warnings?: Array<{
        __typename?: "AccessReviewResourceUserWarning";
        accessReviewResourceUserReviewerId: string;
        accessReviewResourceUserId: string;
        accessReviewResourceId: string;
        accessReviewUserWarningType: AccessReviewUserWarningType;
        userId: string;
        reviewerId?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        reviewer?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        resource?: {
          __typename?: "ResourceSmall";
          id: string;
          name: string;
        } | null;
      }> | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    groupResourceAccess?: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }> | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewGroupReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewGroupQueryVariables = Exact<{
  input: AccessReviewGroupInput;
}>;

export type AccessReviewGroupQuery = {
  __typename?: "Query";
  accessReviewGroup:
    | { __typename: "AccessReviewGroupNotFoundError"; message: string }
    | {
        __typename: "AccessReviewGroupResult";
        accessReviewGroup: {
          __typename?: "AccessReviewGroup";
          id: string;
          createdAt: string;
          updatedAt: string;
          groupId: string;
          accessReviewId: string;
          numGroupUsers: number;
          numGroupResources: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            groupType: GroupType;
            serviceType: ServiceType;
            iconUrl?: string | null;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            updatedAt: string;
            description: string;
            numGroupUsers: number;
            connectionId: string;
            isOnCallSynced: boolean;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            groupBindingId?: string | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserGroupAccess";
              groupId: string;
              hasBreakGlass: boolean;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
              pendingRequest?: {
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              } | null;
            };
            tags: Array<{
              __typename?: "GroupTag";
              groupId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              createdAt: string;
              sourceGroup?: {
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              } | null;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              }>;
              createdByUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            groupLeaders: Array<{
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            }>;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          groupUsers?: Array<{
            __typename?: "AccessReviewGroupUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            groupId: string;
            accessReviewGroupId: string;
            eventId: string;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewGroupUserWarning";
              accessReviewGroupUserReviewerId: string;
              accessReviewGroupUserId: string;
              accessReviewGroupId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              group?: {
                __typename?: "GroupSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
            } | null;
          }> | null;
          groupResources?: Array<{
            __typename?: "AccessReviewGroupResource";
            id: string;
            createdAt: string;
            updatedAt: string;
            resourceId: string;
            accessReviewResourceId?: string | null;
            eventId: string;
            expiration?: string | null;
            accessReviewResource?: {
              __typename?: "AccessReviewResource";
              id: string;
              createdAt: string;
              updatedAt: string;
              resourceId: string;
              accessReviewId: string;
              numResourceUsers: number;
              eventId?: string | null;
              canEditReviewers: boolean;
              status: AccessReviewSummaryStatus;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                recommendedDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                isManaged: boolean;
                autoApproval: boolean;
                reasonOptional: boolean;
                requestTemplateId?: string | null;
                parentResourceId?: string | null;
                authorizedActions?: Array<string> | null;
                customRequestNotification?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                childResources?: Array<{
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  createdAt: string;
                  description: string;
                  connectionId: string;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  maxDurationInMinutes?: number | null;
                  requireMfaToConnect: boolean;
                  requireMfaToApprove: boolean;
                  requireMfaToRequest: boolean;
                  requireSupportTicket: boolean;
                  isRequestable: boolean;
                  autoApproval: boolean;
                  parentResourceId?: string | null;
                  adminOwner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                    ownerUsers: Array<{
                      __typename?: "OwnerUser";
                      user?: { __typename?: "User"; id: string } | null;
                    }>;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  currentUserAccess: {
                    __typename?: "CurrentUserResourceAccess";
                    resourceId: string;
                    numAvailableAccessLevels: number;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    activeSessions: Array<{
                      __typename?: "Session";
                      id: string;
                      durationInMinutes?: number | null;
                      endTime: string;
                      accessLevel?: string | null;
                      accessLevelRemoteId?: string | null;
                      metadata:
                        | {
                            __typename: "AwsIamFederatedEksSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            clusterName: string;
                            clusterRegion: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRdsSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            dbInstanceId: string;
                            dbPassword: string;
                            dbEngine: string;
                            dbUser: string;
                            dbHostname: string;
                            dbPort: number;
                            dbName: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRoleSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                          }
                        | {
                            __typename: "AwsIamFederatedSSMSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                            ec2InstanceId: string;
                            ec2Region: string;
                          }
                        | { __typename: "OidcSession" }
                        | { __typename: "OpalImpersonationSession" }
                        | {
                            __typename: "VaultMongoAtlasSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            initDbName: string;
                          }
                        | {
                            __typename: "VaultMongoSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            authenticationDbName: string;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultMySQLMariaDBSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultPostgresSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          };
                    }>;
                    pendingRequests: Array<{
                      __typename?: "Request";
                      id: string;
                      requesterId: string;
                      targetUserId?: string | null;
                      targetGroupId?: string | null;
                      resourceProposalId?: string | null;
                      createdAt: string;
                      updatedAt: string;
                      status: RequestStatus;
                      requester?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetUser?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetGroup?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      requestedResources: Array<{
                        __typename?: "RequestedResource";
                        resourceId: string;
                        resource?: {
                          __typename: "Resource";
                          id: string;
                          name: string;
                          remoteName: string;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isDeleted: boolean;
                          isManaged: boolean;
                          resourceType: ResourceType;
                          remoteId: string;
                          iconUrl?: string | null;
                          parentResourceId?: string | null;
                          connectionId: string;
                          createdAt: string;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          parentResource?: {
                            __typename?: "Resource";
                            id: string;
                            name: string;
                          } | null;
                          connection?: {
                            __typename: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                            isDeleted: boolean;
                            metadata?:
                              | {
                                  __typename: "ADConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                }
                              | {
                                  __typename: "AwsConnectionMetadata";
                                  success?: boolean | null;
                                }
                              | {
                                  __typename: "AWSSSOConnectionMetadata";
                                  managementAccountId: string;
                                  ssoInstanceArn: string;
                                  identityStoreId: string;
                                  accessPortalUrl: string;
                                  awsRegion: string;
                                  awsOrganizationEnabled: boolean;
                                  awsSsoEnabled: boolean;
                                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                  awsOrganizationImportSetting: ImportSetting;
                                  ec2RoleChainingEnabled: boolean;
                                  podRoleChainingEnabled: boolean;
                                  cloudtrailEventsSqsUrl: string;
                                }
                              | {
                                  __typename: "AzureADConnectionMetadata";
                                  tenantId: string;
                                  clientId: string;
                                  azureInfraEnabled: boolean;
                                  eventHubNamespace: string;
                                  eventHub: string;
                                }
                              | {
                                  __typename: "CustomConnectorConnectionMetadata";
                                  identifier: string;
                                  baseUrl: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                  supportsGroups: boolean;
                                  supportsNestedResources: boolean;
                                }
                              | {
                                  __typename: "DuoConnectionMetadata";
                                  apiHostname: string;
                                }
                              | {
                                  __typename: "GCPConnectionMetadata";
                                  pubSubProjectId: string;
                                  subscriptionId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GitHubConnectionMetadata";
                                  orgName: string;
                                }
                              | {
                                  __typename: "GitLabConnectionMetadata";
                                  hostName?: string | null;
                                  appId: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "GoogleGroupsConnectionMetadata";
                                  opalGroupEmail: string;
                                  adminUserEmail: string;
                                  domain: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GoogleWorkspaceConnectionMetadata";
                                  adminUserEmail: string;
                                  customerId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "LDAPConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                  groupsUidKey: string;
                                }
                              | {
                                  __typename: "MongoAtlasConnectionMetadata";
                                  serverHostname: string;
                                }
                              | {
                                  __typename: "MongoConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "MySQLMariaDBConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "OktaDirectoryConnectionMetadata";
                                  orgUrl: string;
                                }
                              | { __typename: "PagerDutyConnectionMetadata" }
                              | {
                                  __typename: "PostgresConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "PropagationTicketConnectionMetadata";
                                  ticketProvider?: {
                                    __typename?: "TicketProvider";
                                    ticketProvider: ThirdPartyProvider;
                                    ticketProjectId: string;
                                    ticketProjectName: string;
                                  } | null;
                                }
                              | {
                                  __typename: "SalesforceConnectionMetadata";
                                  email: string;
                                  hostname: string;
                                }
                              | {
                                  __typename: "SnowflakeConnectionMetadata";
                                  organization: string;
                                  account: string;
                                  locator: string;
                                  accountIdentifier: string;
                                  accountUrl: string;
                                  serviceAccountName: string;
                                  serviceAccountRole: string;
                                }
                              | {
                                  __typename: "TailscaleConnectionMetadata";
                                  tailnet: string;
                                }
                              | {
                                  __typename: "TeleportConnectionMetadata";
                                  host: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "WorkdayConnectionMetadata";
                                  tenantName: string;
                                  tenantUrl: string;
                                }
                              | null;
                          } | null;
                          metadata?:
                            | { __typename: "AwsRoleMetadata"; arn: string }
                            | {
                                __typename: "OktaDirectoryAppMetadata";
                                appId: string;
                                appName: string;
                                logoUrl?: string | null;
                              }
                            | {
                                __typename: "OktaRoleMetadata";
                                roleId: string;
                                roleType: string;
                              }
                            | {
                                __typename: "PropagationTicketOwnerMetadata";
                                ownerId?: string | null;
                                owner?: {
                                  __typename?: "Owner";
                                  id: string;
                                  name: string;
                                } | null;
                              }
                            | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        targetUserAccess?: {
                          __typename?: "UserResourceAccess";
                          resourceId: string;
                          userId: string;
                          resourceUsers: Array<{
                            __typename: "ResourceUser";
                            resourceId: string;
                            userId: string;
                            mostRecentSessionEnded?: string | null;
                            source: ResourceUserSource;
                            user?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              createdAt: string;
                              updatedAt: string;
                              organizationId: string;
                              position: string;
                              teamAttr?: string | null;
                              managerId?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                              manager?: {
                                __typename?: "User";
                                id: string;
                                email: string;
                                secondaryEmails: Array<string>;
                                firstName: string;
                                lastName: string;
                                fullName: string;
                                avatarUrl: string;
                                isDeleted: boolean;
                                isSystemUser: boolean;
                                position: string;
                                teamAttr?: string | null;
                                hrIdpStatus?: HrIdpStatus | null;
                              } | null;
                            } | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            access?: {
                              __typename?: "ResourceUserAccess";
                              resourceId: string;
                              userId: string;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              latestExpiringAccessPoint: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              inheritedAccessPoints: Array<{
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                              groupUserAccesses: Array<{
                                __typename?: "GroupUserAccess";
                                groupId: string;
                                userId: string;
                                isGroupManaged: boolean;
                                latestExpiringAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                directAccessPoint?: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                } | null;
                                indirectAccessPointPaths: Array<{
                                  __typename?: "IndirectGroupUserAccessPointPath";
                                  startGroupId: string;
                                  endGroupId: string;
                                  userId: string;
                                  resolvedAccessPoint: {
                                    __typename?: "GroupUserAccessPoint";
                                    groupId: string;
                                    userId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                    expiration?: string | null;
                                    supportTicketId?: string | null;
                                    source: GroupUserSource;
                                    accessLevel?: {
                                      __typename: "GroupAccessLevel";
                                      accessLevelName: string;
                                      accessLevelRemoteId: string;
                                    } | null;
                                    supportTicket?: {
                                      __typename?: "SupportTicket";
                                      id?: string | null;
                                      remoteId: string;
                                      url: string;
                                      identifier: string;
                                      thirdPartyProvider: ThirdPartyProvider;
                                      projectName: string;
                                    } | null;
                                  };
                                  indirectAccessPointPath: Array<{
                                    __typename?: "IndirectGroupAccessPoint";
                                    groupId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                  }>;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "ResourcePropagationStatus";
                              resourceId: string;
                              userId: string;
                              accessLevelRemoteId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              metadata?: {
                                __typename: "PropagationTicketMetadata";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                      }>;
                      requestedGroups: Array<{
                        __typename?: "RequestedGroup";
                        groupId: string;
                        group?: {
                          __typename?: "Group";
                          id: string;
                          remoteId?: string | null;
                          name: string;
                          remoteName: string;
                          numGroupUsers: number;
                          groupType: GroupType;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isManaged: boolean;
                          isDeleted: boolean;
                          iconUrl?: string | null;
                          isOnCallSynced: boolean;
                          connectionId: string;
                          groupBindingId?: string | null;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          connection?: {
                            __typename?: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                          } | null;
                          groupBinding?: {
                            __typename?: "GroupBinding";
                            id: string;
                            sourceGroupId: string;
                            groups: Array<{
                              __typename?: "Group";
                              id: string;
                              name: string;
                              groupType: GroupType;
                              connectionId: string;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        targetUserAccess?: {
                          __typename?: "UserGroupAccess";
                          groupId: string;
                          userId: string;
                          groupUser?: {
                            __typename?: "GroupUser";
                            groupId: string;
                            userId: string;
                            source: GroupUserSource;
                            access?: {
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "GroupPropagationStatus";
                              groupId: string;
                              userId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              ticketPropagation?: {
                                __typename: "GroupUserTicketPropagation";
                                resourceTickets: Array<{
                                  __typename?: "ResourceTicketPropagation";
                                  ticket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                      resourceProposal?: {
                        __typename?: "ResourceProposal";
                        id: string;
                        connectionId: string;
                        serviceType: ServiceType;
                        resourceType: ResourceType;
                        metadata?: {
                          __typename: "AWSRoleProposalMetadata";
                          policyName: string;
                          policyDocument: string;
                          policyDescription: string;
                          assumeRolePolicyDocument: string;
                          roleName: string;
                          roleDescription: string;
                          tags: Array<{
                            __typename?: "AWSTag";
                            key: string;
                            value?: string | null;
                          }>;
                        } | null;
                      } | null;
                    }>;
                  };
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                  commonMetadata: {
                    __typename?: "CommonMetadata";
                    matchRemoteName: boolean;
                    matchRemoteDescription: boolean;
                  };
                  tags: Array<{
                    __typename?: "ResourceTag";
                    id: string;
                    resourceId: string;
                    tagId: string;
                    source: ServiceType;
                    updatedAt: string;
                    createdAt: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    tag?: {
                      __typename: "Tag";
                      id: string;
                      createdAt: string;
                      creatorUserId: string;
                      key: string;
                      value?: string | null;
                      isStandardAttribute: boolean;
                    } | null;
                  }>;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                }> | null;
                requestTemplate?: {
                  __typename?: "RequestTemplate";
                  id: string;
                  name: string;
                  isDeleted?: boolean | null;
                  customFields?: Array<{
                    __typename?: "RequestTemplateCustomField";
                    name: string;
                    type: RequestTemplateCustomFieldType;
                    required?: boolean | null;
                    description?: string | null;
                    metadata?: {
                      __typename?: "RequestTemplateCustomFieldMetadata";
                      multiChoiceData?: {
                        __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                        options?: Array<{
                          __typename?: "MultiChoiceOption";
                          value: string;
                          label: string;
                        }> | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
              } | null;
              reviewerUsers: Array<{
                __typename?: "ReviewerUser";
                userId: string;
                entityId: string;
                entityType: EntityType;
                name: string;
                avatarUrl: string;
                status: ReviewerUserStatus;
                note?: string | null;
                updatedResourceId?: string | null;
                updatedAccessLevel?: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                updatedResource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
              }>;
              warnings?: Array<{
                __typename?: "AccessReviewResourceUserWarning";
                accessReviewResourceUserReviewerId: string;
                accessReviewResourceUserId: string;
                accessReviewResourceId: string;
                accessReviewUserWarningType: AccessReviewUserWarningType;
                userId: string;
                reviewerId?: string | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                reviewer?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                resource?: {
                  __typename?: "ResourceSmall";
                  id: string;
                  name: string;
                } | null;
              }> | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupResourceAccess?: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        };
      };
};

export type AccessReviewGroupsQueryVariables = Exact<{
  input: AccessReviewGroupsInput;
}>;

export type AccessReviewGroupsQuery = {
  __typename?: "Query";
  accessReviewGroups:
    | {
        __typename: "AccessReviewGroupsResult";
        accessReviewGroups: Array<{
          __typename?: "AccessReviewGroup";
          id: string;
          createdAt: string;
          updatedAt: string;
          groupId: string;
          accessReviewId: string;
          numGroupUsers: number;
          numGroupResources: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            groupType: GroupType;
            serviceType: ServiceType;
            iconUrl?: string | null;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            updatedAt: string;
            description: string;
            numGroupUsers: number;
            connectionId: string;
            isOnCallSynced: boolean;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            groupBindingId?: string | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserGroupAccess";
              groupId: string;
              hasBreakGlass: boolean;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
              pendingRequest?: {
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              } | null;
            };
            tags: Array<{
              __typename?: "GroupTag";
              groupId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              createdAt: string;
              sourceGroup?: {
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              } | null;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              }>;
              createdByUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            groupLeaders: Array<{
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            }>;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          warnings?: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      }
    | { __typename: "AccessReviewNotFoundError"; message: string };
};

export type AccessReviewResourceResourceFragment = {
  __typename?: "AccessReviewResourceResource";
  id: string;
  createdAt: string;
  updatedAt: string;
  accessReviewId: string;
  accessReviewResourceEntityId?: string | null;
  resourceEntityId: string;
  isResourceEntityDeleted: boolean;
  accessReviewResourcePrincipalId?: string | null;
  resourcePrincipalId: string;
  isResourcePrincipalDeleted: boolean;
  eventId?: string | null;
  resourceResourceAccess?: {
    __typename?: "Access";
    entityId: string;
    entityType: EntityType;
    principalId: string;
    principalType: EntityType;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    latestExpiringAccessPoint: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
  } | null;
  resourcePrincipal?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  resourceEntity?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
};

export type UpdateAccessReviewResourceReviewersMutationVariables = Exact<{
  input: UpdateAccessReviewResourceReviewersInput;
}>;

export type UpdateAccessReviewResourceReviewersMutation = {
  __typename?: "Mutation";
  updateAccessReviewResourceReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateAccessReviewResourceReviewersResult";
        accessReviewResource: {
          __typename?: "AccessReviewResource";
          id: string;
          createdAt: string;
          updatedAt: string;
          resourceId: string;
          accessReviewId: string;
          numResourceUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            parentResourceId?: string | null;
            authorizedActions?: Array<string> | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            childResources?: Array<{
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              autoApproval: boolean;
              parentResourceId?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
            }> | null;
            requestTemplate?: {
              __typename?: "RequestTemplate";
              id: string;
              name: string;
              isDeleted?: boolean | null;
              customFields?: Array<{
                __typename?: "RequestTemplateCustomField";
                name: string;
                type: RequestTemplateCustomFieldType;
                required?: boolean | null;
                description?: string | null;
                metadata?: {
                  __typename?: "RequestTemplateCustomFieldMetadata";
                  multiChoiceData?: {
                    __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                    options?: Array<{
                      __typename?: "MultiChoiceOption";
                      value: string;
                      label: string;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          resourceUsers?: Array<{
            __typename?: "AccessReviewResourceUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            accessReviewResourceId: string;
            resourceId: string;
            eventId: string;
            supportTicketId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            requestId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewResourceUserWarning";
              accessReviewResourceUserReviewerId: string;
              accessReviewResourceUserId: string;
              accessReviewResourceId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              resource?: {
                __typename?: "ResourceSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            resourceUser?: {
              __typename?: "ResourceUser";
              resourceId: string;
              userId: string;
              source: ResourceUserSource;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              summary: string;
              issueType: string;
              projectName: string;
            } | null;
          }> | null;
          resourceResources?: Array<{
            __typename?: "AccessReviewResourceResource";
            id: string;
            createdAt: string;
            updatedAt: string;
            accessReviewId: string;
            accessReviewResourceEntityId?: string | null;
            resourceEntityId: string;
            isResourceEntityDeleted: boolean;
            accessReviewResourcePrincipalId?: string | null;
            resourcePrincipalId: string;
            isResourcePrincipalDeleted: boolean;
            eventId?: string | null;
            resourceResourceAccess?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            resourcePrincipal?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            resourceEntity?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        };
      };
};

export type UpdateResourceUserReviewersMutationVariables = Exact<{
  input: UpdateResourceUserReviewersInput;
}>;

export type UpdateResourceUserReviewersMutation = {
  __typename?: "Mutation";
  updateResourceUserReviewers:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "UpdateAccessReviewReviewersValidationError";
        message: string;
      }
    | {
        __typename: "UpdateResourceUserReviewersResult";
        accessReviewResource: {
          __typename?: "AccessReviewResource";
          id: string;
          createdAt: string;
          updatedAt: string;
          resourceId: string;
          accessReviewId: string;
          numResourceUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            parentResourceId?: string | null;
            authorizedActions?: Array<string> | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            childResources?: Array<{
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              autoApproval: boolean;
              parentResourceId?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
            }> | null;
            requestTemplate?: {
              __typename?: "RequestTemplate";
              id: string;
              name: string;
              isDeleted?: boolean | null;
              customFields?: Array<{
                __typename?: "RequestTemplateCustomField";
                name: string;
                type: RequestTemplateCustomFieldType;
                required?: boolean | null;
                description?: string | null;
                metadata?: {
                  __typename?: "RequestTemplateCustomFieldMetadata";
                  multiChoiceData?: {
                    __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                    options?: Array<{
                      __typename?: "MultiChoiceOption";
                      value: string;
                      label: string;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          resourceUsers?: Array<{
            __typename?: "AccessReviewResourceUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            accessReviewResourceId: string;
            resourceId: string;
            eventId: string;
            supportTicketId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            requestId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewResourceUserWarning";
              accessReviewResourceUserReviewerId: string;
              accessReviewResourceUserId: string;
              accessReviewResourceId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              resource?: {
                __typename?: "ResourceSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            resourceUser?: {
              __typename?: "ResourceUser";
              resourceId: string;
              userId: string;
              source: ResourceUserSource;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              summary: string;
              issueType: string;
              projectName: string;
            } | null;
          }> | null;
          resourceResources?: Array<{
            __typename?: "AccessReviewResourceResource";
            id: string;
            createdAt: string;
            updatedAt: string;
            accessReviewId: string;
            accessReviewResourceEntityId?: string | null;
            resourceEntityId: string;
            isResourceEntityDeleted: boolean;
            accessReviewResourcePrincipalId?: string | null;
            resourcePrincipalId: string;
            isResourcePrincipalDeleted: boolean;
            eventId?: string | null;
            resourceResourceAccess?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            resourcePrincipal?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            resourceEntity?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        };
      };
};

export type ConfirmEndSystemRevocationMutationVariables = Exact<{
  input: ConfirmEndSystemRevocationInput;
}>;

export type ConfirmEndSystemRevocationMutation = {
  __typename?: "Mutation";
  confirmEndSystemRevocation:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | {
        __typename: "AccessReviewUserSupportTicketAlreadyLinkedError";
        message: string;
      }
    | {
        __typename: "ConfirmEndSystemRevocationResult";
        accessReviewResourceUsers?: Array<{
          __typename?: "AccessReviewResourceUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          userId: string;
          accessReviewResourceId: string;
          resourceId: string;
          eventId: string;
          supportTicketId?: string | null;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          requestId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename?: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
          resourceUser?: {
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            summary: string;
            issueType: string;
            projectName: string;
          } | null;
        }> | null;
      }
    | { __typename: "RemoteUserNotFoundError"; message: string };
};

export type AccessReviewResourceUserFragment = {
  __typename?: "AccessReviewResourceUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  accessReviewResourceId: string;
  userId: string;
  resourceId: string;
  eventId: string;
  supportTicketId?: string | null;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  requestId?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  resourceUser?: {
    __typename?: "ResourceUser";
    resourceId: string;
    userId: string;
    source: ResourceUserSource;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
  } | null;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    summary: string;
    issueType: string;
    projectName: string;
  } | null;
};

export type AccessReviewResourceUserPreviewFragment = {
  __typename?: "AccessReviewResourceUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  accessReviewResourceId: string;
  resourceId: string;
  eventId: string;
  supportTicketId?: string | null;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  requestId?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  resourceUser?: {
    __typename?: "ResourceUser";
    resourceId: string;
    userId: string;
    source: ResourceUserSource;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
  } | null;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    summary: string;
    issueType: string;
    projectName: string;
  } | null;
};

export type AccessReviewUserResourcePreviewFragment = {
  __typename?: "AccessReviewResourceUser";
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  resourceId: string;
  accessReviewResourceId: string;
  eventId: string;
  errorMessage?: string | null;
  statusCode?: PropagationStatusCode | null;
  taskType?: PropagationTaskType | null;
  lastSynced?: string | null;
  requestId?: string | null;
  resource?: {
    __typename: "Resource";
    connectionId: string;
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    createdAt: string;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  resourceUser?: {
    __typename?: "ResourceUser";
    resourceId: string;
    userId: string;
    source: ResourceUserSource;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
  } | null;
};

export type AccessReviewResourceUserWarningFragment = {
  __typename?: "AccessReviewResourceUserWarning";
  accessReviewResourceUserReviewerId: string;
  accessReviewResourceUserId: string;
  accessReviewResourceId: string;
  accessReviewUserWarningType: AccessReviewUserWarningType;
  userId: string;
  reviewerId?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  reviewer?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  resource?: { __typename?: "ResourceSmall"; id: string; name: string } | null;
};

export type ReviewerUserFragment = {
  __typename?: "ReviewerUser";
  userId: string;
  entityId: string;
  entityType: EntityType;
  name: string;
  avatarUrl: string;
  status: ReviewerUserStatus;
  note?: string | null;
  updatedResourceId?: string | null;
  updatedAccessLevel?: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  } | null;
  updatedResource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
};

export type AccessReviewResourcePreviewFragment = {
  __typename?: "AccessReviewResource";
  id: string;
  createdAt: string;
  updatedAt: string;
  resourceId: string;
  accessReviewId: string;
  numResourceUsers: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  warnings?: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
};

export type AccessReviewResourceReviewerStatusFragment = {
  __typename?: "AccessReviewResourceReviewerStatus";
  itemStatus: AccessReviewItemStatus;
  numUsersReviewed: number;
  numUsersAssigned: number;
  numNHIsReviewed: number;
  numNHIsAssigned: number;
  numResourcesReviewed: number;
  numResourcesAssigned: number;
};

export type AccessReviewResourceFragment = {
  __typename?: "AccessReviewResource";
  id: string;
  createdAt: string;
  updatedAt: string;
  resourceId: string;
  accessReviewId: string;
  numResourceUsers: number;
  eventId?: string | null;
  canEditReviewers: boolean;
  status: AccessReviewSummaryStatus;
  accessReview?: {
    __typename?: "AccessReview";
    id: string;
    createdAt: string;
    startedByUserId: string;
    stoppedByUserId?: string | null;
    stoppedDate?: string | null;
    deadline: string;
    timeZone: string;
    selfReviewAllowed: boolean;
    name: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    lastReminderAt?: string | null;
    sendReviewerAssignmentNotification: boolean;
    reportId?: string | null;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    accessReviewTemplateId?: string | null;
    includeGroupBindings: boolean;
  } | null;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  resourceUsers?: Array<{
    __typename?: "AccessReviewResourceUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    accessReviewResourceId: string;
    resourceId: string;
    eventId: string;
    supportTicketId?: string | null;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    requestId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewResourceUserWarning";
      accessReviewResourceUserReviewerId: string;
      accessReviewResourceUserId: string;
      accessReviewResourceId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      resource?: {
        __typename?: "ResourceSmall";
        id: string;
        name: string;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    resourceUser?: {
      __typename?: "ResourceUser";
      resourceId: string;
      userId: string;
      source: ResourceUserSource;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      summary: string;
      issueType: string;
      projectName: string;
    } | null;
  }> | null;
  resourceResources?: Array<{
    __typename?: "AccessReviewResourceResource";
    id: string;
    createdAt: string;
    updatedAt: string;
    accessReviewId: string;
    accessReviewResourceEntityId?: string | null;
    resourceEntityId: string;
    isResourceEntityDeleted: boolean;
    accessReviewResourcePrincipalId?: string | null;
    resourcePrincipalId: string;
    isResourcePrincipalDeleted: boolean;
    eventId?: string | null;
    resourceResourceAccess?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    resourcePrincipal?: {
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    } | null;
    resourceEntity?: {
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
  }> | null;
  warnings?: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }> | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewResourceReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numNHIsReviewed: number;
    numNHIsAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewResourceQueryVariables = Exact<{
  input: AccessReviewResourceInput;
}>;

export type AccessReviewResourceQuery = {
  __typename?: "Query";
  accessReviewResource:
    | { __typename: "AccessReviewResourceNotFoundError"; message: string }
    | {
        __typename: "AccessReviewResourceResult";
        accessReviewResource: {
          __typename?: "AccessReviewResource";
          id: string;
          createdAt: string;
          updatedAt: string;
          resourceId: string;
          accessReviewId: string;
          numResourceUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            parentResourceId?: string | null;
            authorizedActions?: Array<string> | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            childResources?: Array<{
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              autoApproval: boolean;
              parentResourceId?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
            }> | null;
            requestTemplate?: {
              __typename?: "RequestTemplate";
              id: string;
              name: string;
              isDeleted?: boolean | null;
              customFields?: Array<{
                __typename?: "RequestTemplateCustomField";
                name: string;
                type: RequestTemplateCustomFieldType;
                required?: boolean | null;
                description?: string | null;
                metadata?: {
                  __typename?: "RequestTemplateCustomFieldMetadata";
                  multiChoiceData?: {
                    __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                    options?: Array<{
                      __typename?: "MultiChoiceOption";
                      value: string;
                      label: string;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          resourceUsers?: Array<{
            __typename?: "AccessReviewResourceUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            accessReviewResourceId: string;
            resourceId: string;
            eventId: string;
            supportTicketId?: string | null;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            requestId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewResourceUserWarning";
              accessReviewResourceUserReviewerId: string;
              accessReviewResourceUserId: string;
              accessReviewResourceId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              resource?: {
                __typename?: "ResourceSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            resourceUser?: {
              __typename?: "ResourceUser";
              resourceId: string;
              userId: string;
              source: ResourceUserSource;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              summary: string;
              issueType: string;
              projectName: string;
            } | null;
          }> | null;
          resourceResources?: Array<{
            __typename?: "AccessReviewResourceResource";
            id: string;
            createdAt: string;
            updatedAt: string;
            accessReviewId: string;
            accessReviewResourceEntityId?: string | null;
            resourceEntityId: string;
            isResourceEntityDeleted: boolean;
            accessReviewResourcePrincipalId?: string | null;
            resourcePrincipalId: string;
            isResourcePrincipalDeleted: boolean;
            eventId?: string | null;
            resourceResourceAccess?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            resourcePrincipal?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            resourceEntity?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        };
      };
};

export type AccessReviewResourcesQueryVariables = Exact<{
  input: AccessReviewResourcesInput;
}>;

export type AccessReviewResourcesQuery = {
  __typename?: "Query";
  accessReviewResources:
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "AccessReviewResourcesResult";
        accessReviewResources: Array<{
          __typename?: "AccessReviewResource";
          id: string;
          createdAt: string;
          updatedAt: string;
          resourceId: string;
          accessReviewId: string;
          numResourceUsers: number;
          eventId?: string | null;
          canEditReviewers: boolean;
          status: AccessReviewSummaryStatus;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            parentResourceId?: string | null;
            authorizedActions?: Array<string> | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            childResources?: Array<{
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              autoApproval: boolean;
              parentResourceId?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
            }> | null;
            requestTemplate?: {
              __typename?: "RequestTemplate";
              id: string;
              name: string;
              isDeleted?: boolean | null;
              customFields?: Array<{
                __typename?: "RequestTemplateCustomField";
                name: string;
                type: RequestTemplateCustomFieldType;
                required?: boolean | null;
                description?: string | null;
                metadata?: {
                  __typename?: "RequestTemplateCustomFieldMetadata";
                  multiChoiceData?: {
                    __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                    options?: Array<{
                      __typename?: "MultiChoiceOption";
                      value: string;
                      label: string;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
        }>;
      };
};

export type AccessReviewStatsFragment = {
  __typename?: "AccessReviewStats";
  numResourcesReviewed: number;
  numResourcesTotal: number;
  numGroupsReviewed: number;
  numGroupsTotal: number;
  numConnectionsReviewed: number;
  numConnectionsTotal: number;
  numReviewersTotal: number;
  numReviewersIncomplete: number;
  numUserAssignmentsTotal: number;
  numUserAssignmentsInReview: number;
  numUserAssignmentsCompleted: number;
  numGroupResourceAssignmentsTotal: number;
  numGroupResourceAssignmentsInReview: number;
  numGroupResourceAssignmentsCompleted: number;
  numNHIAssignmentsTotal: number;
  numNHIAssignmentsInReview: number;
  numNHIAssignmentsCompleted: number;
  incompleteReviewers: Array<{
    __typename?: "IncompleteAccessReviewer";
    userId: string;
    incompleteResources: number;
    incompleteGroups: number;
    incompleteConnections: number;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
};

export type IncompleteAccessReviewerFragment = {
  __typename?: "IncompleteAccessReviewer";
  userId: string;
  incompleteResources: number;
  incompleteGroups: number;
  incompleteConnections: number;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type OngoingAccessReviewTabStatsFragment = {
  __typename?: "OngoingAccessReviewTabStats";
  numResourcesToReview: number;
  numGroupsToReview: number;
  numAppsToReview: number;
  numUsersToReview: number;
};

export type OngoingAccessReviewSubtabStatsFragment = {
  __typename?: "OngoingAccessReviewSubtabStats";
  numResourcesToReview: number;
  numGroupsToReview: number;
  numUsersToReview: number;
};

export type AccessReviewStatsQueryVariables = Exact<{
  input: AccessReviewStatsInput;
}>;

export type AccessReviewStatsQuery = {
  __typename?: "Query";
  accessReviewStats:
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "AccessReviewStatsResult";
        accessReviewStats?: {
          __typename?: "AccessReviewStats";
          numResourcesReviewed: number;
          numResourcesTotal: number;
          numGroupsReviewed: number;
          numGroupsTotal: number;
          numConnectionsReviewed: number;
          numConnectionsTotal: number;
          numReviewersTotal: number;
          numReviewersIncomplete: number;
          numUserAssignmentsTotal: number;
          numUserAssignmentsInReview: number;
          numUserAssignmentsCompleted: number;
          numGroupResourceAssignmentsTotal: number;
          numGroupResourceAssignmentsInReview: number;
          numGroupResourceAssignmentsCompleted: number;
          numNHIAssignmentsTotal: number;
          numNHIAssignmentsInReview: number;
          numNHIAssignmentsCompleted: number;
          incompleteReviewers: Array<{
            __typename?: "IncompleteAccessReviewer";
            userId: string;
            incompleteResources: number;
            incompleteGroups: number;
            incompleteConnections: number;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
        } | null;
      };
};

export type OngoingAccessReviewTabStatsQueryVariables = Exact<{
  input: OngoingAccessReviewTabStatsInput;
}>;

export type OngoingAccessReviewTabStatsQuery = {
  __typename?: "Query";
  ongoingAccessReviewTabStats:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "OngoingAccessReviewTabStatsResult";
        ongoingAccessReviewTabStats?: {
          __typename?: "OngoingAccessReviewTabStats";
          numResourcesToReview: number;
          numGroupsToReview: number;
          numAppsToReview: number;
          numUsersToReview: number;
        } | null;
      };
};

export type OngoingAccessReviewSubtabStatsQueryVariables = Exact<{
  input: OngoingAccessReviewSubtabStatsInput;
}>;

export type OngoingAccessReviewSubtabStatsQuery = {
  __typename?: "Query";
  ongoingAccessReviewSubtabStats:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "OngoingAccessReviewSubtabStatsResult";
        ongoingAccessReviewSubtabStats?: {
          __typename?: "OngoingAccessReviewSubtabStats";
          numResourcesToReview: number;
          numGroupsToReview: number;
          numUsersToReview: number;
        } | null;
      };
};

export type AccessReviewTemplateFragment = {
  __typename?: "AccessReviewTemplate";
  id: string;
  createdAt: string;
  createdByUserId: string;
  updatedAt: string;
  name: string;
  accessReviewDuration: number;
  monthSchedule: Array<number>;
  nextScheduledRun: string;
  startDayOfMonth: number;
  timeZone: string;
  reminderSchedule: Array<number>;
  reminderTimeOfDay: string;
  reminderIncludeManager: boolean;
  sendReviewerAssignmentNotification: boolean;
  selfReviewAllowed: boolean;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  scopeResourcesCount: number;
  scopeGroupsCount: number;
  scopeAppsCount: number;
  includeGroupBindings: boolean;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  createdByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  filters?: {
    __typename?: "AccessReviewFilters";
    names: Array<string>;
    adminIDs: Array<string>;
    resourceTypes: Array<ResourceType>;
    groupTypes: Array<GroupType>;
    entityIDs: Array<string>;
    connectionIDs: Array<string>;
    userIDs: Array<string>;
    matchMode: FiltersMatchMode;
    tags: Array<{
      __typename?: "TagKeyValue";
      key: string;
      value?: string | null;
    }>;
  } | null;
};

export type AccessReviewTemplatePreviewFragment = {
  __typename?: "AccessReviewTemplate";
  id: string;
  createdAt: string;
  createdByUserId: string;
  updatedAt: string;
  name: string;
  accessReviewDuration: number;
  monthSchedule: Array<number>;
  nextScheduledRun: string;
  startDayOfMonth: number;
  timeZone: string;
  reminderSchedule: Array<number>;
  reminderTimeOfDay: string;
  reminderIncludeManager: boolean;
  sendReviewerAssignmentNotification: boolean;
  selfReviewAllowed: boolean;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  includeGroupBindings: boolean;
};

export type AccessReviewTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccessReviewTemplatesQuery = {
  __typename?: "Query";
  accessReviewTemplates: {
    __typename: "AccessReviewTemplatesResult";
    accessReviewTemplates: Array<{
      __typename?: "AccessReviewTemplate";
      id: string;
      createdAt: string;
      createdByUserId: string;
      updatedAt: string;
      name: string;
      accessReviewDuration: number;
      monthSchedule: Array<number>;
      nextScheduledRun: string;
      startDayOfMonth: number;
      timeZone: string;
      reminderSchedule: Array<number>;
      reminderTimeOfDay: string;
      reminderIncludeManager: boolean;
      sendReviewerAssignmentNotification: boolean;
      selfReviewAllowed: boolean;
      reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
      scopeResourcesCount: number;
      scopeGroupsCount: number;
      scopeAppsCount: number;
      includeGroupBindings: boolean;
      groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      filters?: {
        __typename?: "AccessReviewFilters";
        names: Array<string>;
        adminIDs: Array<string>;
        resourceTypes: Array<ResourceType>;
        groupTypes: Array<GroupType>;
        entityIDs: Array<string>;
        connectionIDs: Array<string>;
        userIDs: Array<string>;
        matchMode: FiltersMatchMode;
        tags: Array<{
          __typename?: "TagKeyValue";
          key: string;
          value?: string | null;
        }>;
      } | null;
    }>;
  };
};

export type AccessReviewUserPreviewFragment = {
  __typename?: "AccessReviewUser";
  userId: string;
  accessReviewId: string;
  status: AccessReviewSummaryStatus;
  canEditReviewers: boolean;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  resourceUsers: Array<{ __typename?: "AccessReviewResourceUser"; id: string }>;
  groupUsers: Array<{ __typename?: "AccessReviewGroupUser"; id: string }>;
  resourceWarnings: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }>;
  groupWarnings: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }>;
};

export type AccessReviewUserFragment = {
  __typename?: "AccessReviewUser";
  userId: string;
  accessReviewId: string;
  status: AccessReviewSummaryStatus;
  canEditReviewers: boolean;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  accessReview?: {
    __typename?: "AccessReview";
    id: string;
    createdAt: string;
    startedByUserId: string;
    stoppedByUserId?: string | null;
    stoppedDate?: string | null;
    deadline: string;
    timeZone: string;
    selfReviewAllowed: boolean;
    name: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    lastReminderAt?: string | null;
    sendReviewerAssignmentNotification: boolean;
    reportId?: string | null;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    accessReviewTemplateId?: string | null;
    includeGroupBindings: boolean;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  resourceUsers: Array<{
    __typename?: "AccessReviewResourceUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    resourceId: string;
    accessReviewResourceId: string;
    eventId: string;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    requestId?: string | null;
    resource?: {
      __typename: "Resource";
      connectionId: string;
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      createdAt: string;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewResourceUserWarning";
      accessReviewResourceUserReviewerId: string;
      accessReviewResourceUserId: string;
      accessReviewResourceId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      resource?: {
        __typename?: "ResourceSmall";
        id: string;
        name: string;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    resourceUser?: {
      __typename?: "ResourceUser";
      resourceId: string;
      userId: string;
      source: ResourceUserSource;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
    } | null;
  }>;
  groupUsers: Array<{
    __typename?: "AccessReviewGroupUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    groupId: string;
    accessReviewGroupId: string;
    eventId: string;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    group?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewGroupUserWarning";
      accessReviewGroupUserReviewerId: string;
      accessReviewGroupUserId: string;
      accessReviewGroupId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
    } | null;
  }>;
  resourceWarnings: Array<{
    __typename?: "AccessReviewResourceUserWarning";
    accessReviewResourceUserReviewerId: string;
    accessReviewResourceUserId: string;
    accessReviewResourceId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    resource?: {
      __typename?: "ResourceSmall";
      id: string;
      name: string;
    } | null;
  }>;
  groupWarnings: Array<{
    __typename?: "AccessReviewGroupUserWarning";
    accessReviewGroupUserReviewerId: string;
    accessReviewGroupUserId: string;
    accessReviewGroupId: string;
    accessReviewUserWarningType: AccessReviewUserWarningType;
    userId: string;
    reviewerId?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    reviewer?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
  }>;
};

export type AccessReviewUserQueryVariables = Exact<{
  input: AccessReviewUserInput;
}>;

export type AccessReviewUserQuery = {
  __typename?: "Query";
  accessReviewUser:
    | { __typename: "AccessReviewUserNotFoundError"; message: string }
    | {
        __typename: "AccessReviewUserResult";
        accessReviewUser: {
          __typename?: "AccessReviewUser";
          userId: string;
          accessReviewId: string;
          status: AccessReviewSummaryStatus;
          canEditReviewers: boolean;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            id: string;
            createdAt: string;
            startedByUserId: string;
            stoppedByUserId?: string | null;
            stoppedDate?: string | null;
            deadline: string;
            timeZone: string;
            selfReviewAllowed: boolean;
            name: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            lastReminderAt?: string | null;
            sendReviewerAssignmentNotification: boolean;
            reportId?: string | null;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            accessReviewTemplateId?: string | null;
            includeGroupBindings: boolean;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          resourceUsers: Array<{
            __typename?: "AccessReviewResourceUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            resourceId: string;
            accessReviewResourceId: string;
            eventId: string;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            requestId?: string | null;
            resource?: {
              __typename: "Resource";
              connectionId: string;
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              createdAt: string;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewResourceUserWarning";
              accessReviewResourceUserReviewerId: string;
              accessReviewResourceUserId: string;
              accessReviewResourceId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              resource?: {
                __typename?: "ResourceSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            resourceUser?: {
              __typename?: "ResourceUser";
              resourceId: string;
              userId: string;
              source: ResourceUserSource;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
            } | null;
          }>;
          groupUsers: Array<{
            __typename?: "AccessReviewGroupUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            groupId: string;
            accessReviewGroupId: string;
            eventId: string;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewGroupUserWarning";
              accessReviewGroupUserReviewerId: string;
              accessReviewGroupUserId: string;
              accessReviewGroupId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              group?: {
                __typename?: "GroupSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
            } | null;
          }>;
          resourceWarnings: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }>;
          groupWarnings: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }>;
        };
      };
};

export type AccessReviewUsersQueryVariables = Exact<{
  input: AccessReviewUsersInput;
}>;

export type AccessReviewUsersQuery = {
  __typename?: "Query";
  accessReviewUsers:
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "AccessReviewUsersResult";
        accessReviewUsers: Array<{
          __typename?: "AccessReviewUser";
          userId: string;
          accessReviewId: string;
          status: AccessReviewSummaryStatus;
          canEditReviewers: boolean;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          resourceUsers: Array<{
            __typename?: "AccessReviewResourceUser";
            id: string;
          }>;
          groupUsers: Array<{
            __typename?: "AccessReviewGroupUser";
            id: string;
          }>;
          resourceWarnings: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }>;
          groupWarnings: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }>;
        }>;
      };
};

export type AccessReviewBaseFragment = {
  __typename?: "AccessReview";
  id: string;
  createdAt: string;
  startedByUserId: string;
  stoppedByUserId?: string | null;
  stoppedDate?: string | null;
  reportId?: string | null;
  deadline: string;
  selfReviewAllowed: boolean;
  name: string;
  timeZone: string;
  reminderSchedule: Array<number>;
  reminderTimeOfDay: string;
  reminderIncludeManager: boolean;
  sendReviewerAssignmentNotification: boolean;
  lastReminderAt?: string | null;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  accessReviewTemplateId?: string | null;
  includeGroupBindings: boolean;
  startedByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  stoppedByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  filters?: {
    __typename?: "AccessReviewFilters";
    names: Array<string>;
    adminIDs: Array<string>;
    resourceTypes: Array<ResourceType>;
    groupTypes: Array<GroupType>;
    entityIDs: Array<string>;
    connectionIDs: Array<string>;
    userIDs: Array<string>;
    matchMode: FiltersMatchMode;
    tags: Array<{
      __typename?: "TagKeyValue";
      key: string;
      value?: string | null;
    }>;
  } | null;
  accessReviewTemplate?: {
    __typename?: "AccessReviewTemplate";
    id: string;
    createdAt: string;
    createdByUserId: string;
    updatedAt: string;
    name: string;
    accessReviewDuration: number;
    monthSchedule: Array<number>;
    nextScheduledRun: string;
    startDayOfMonth: number;
    timeZone: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    sendReviewerAssignmentNotification: boolean;
    selfReviewAllowed: boolean;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    scopeResourcesCount: number;
    scopeGroupsCount: number;
    scopeAppsCount: number;
    includeGroupBindings: boolean;
    groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
    createdByUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    filters?: {
      __typename?: "AccessReviewFilters";
      names: Array<string>;
      adminIDs: Array<string>;
      resourceTypes: Array<ResourceType>;
      groupTypes: Array<GroupType>;
      entityIDs: Array<string>;
      connectionIDs: Array<string>;
      userIDs: Array<string>;
      matchMode: FiltersMatchMode;
      tags: Array<{
        __typename?: "TagKeyValue";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
  itemsToReviewStats?: {
    __typename?: "OngoingAccessReviewTabStats";
    numResourcesToReview: number;
    numGroupsToReview: number;
    numAppsToReview: number;
    numUsersToReview: number;
  } | null;
};

export type AccessReviewFragment = {
  __typename?: "AccessReview";
  id: string;
  createdAt: string;
  startedByUserId: string;
  stoppedByUserId?: string | null;
  stoppedDate?: string | null;
  reportId?: string | null;
  deadline: string;
  selfReviewAllowed: boolean;
  name: string;
  timeZone: string;
  reminderSchedule: Array<number>;
  reminderTimeOfDay: string;
  reminderIncludeManager: boolean;
  sendReviewerAssignmentNotification: boolean;
  lastReminderAt?: string | null;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  accessReviewTemplateId?: string | null;
  includeGroupBindings: boolean;
  stats: {
    __typename?: "AccessReviewStats";
    numResourcesReviewed: number;
    numResourcesTotal: number;
    numGroupsReviewed: number;
    numGroupsTotal: number;
    numConnectionsReviewed: number;
    numConnectionsTotal: number;
    numReviewersTotal: number;
    numReviewersIncomplete: number;
    numUserAssignmentsTotal: number;
    numUserAssignmentsInReview: number;
    numUserAssignmentsCompleted: number;
    numGroupResourceAssignmentsTotal: number;
    numGroupResourceAssignmentsInReview: number;
    numGroupResourceAssignmentsCompleted: number;
    numNHIAssignmentsTotal: number;
    numNHIAssignmentsInReview: number;
    numNHIAssignmentsCompleted: number;
    incompleteReviewers: Array<{
      __typename?: "IncompleteAccessReviewer";
      userId: string;
      incompleteResources: number;
      incompleteGroups: number;
      incompleteConnections: number;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
  startedByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  stoppedByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  filters?: {
    __typename?: "AccessReviewFilters";
    names: Array<string>;
    adminIDs: Array<string>;
    resourceTypes: Array<ResourceType>;
    groupTypes: Array<GroupType>;
    entityIDs: Array<string>;
    connectionIDs: Array<string>;
    userIDs: Array<string>;
    matchMode: FiltersMatchMode;
    tags: Array<{
      __typename?: "TagKeyValue";
      key: string;
      value?: string | null;
    }>;
  } | null;
  accessReviewTemplate?: {
    __typename?: "AccessReviewTemplate";
    id: string;
    createdAt: string;
    createdByUserId: string;
    updatedAt: string;
    name: string;
    accessReviewDuration: number;
    monthSchedule: Array<number>;
    nextScheduledRun: string;
    startDayOfMonth: number;
    timeZone: string;
    reminderSchedule: Array<number>;
    reminderTimeOfDay: string;
    reminderIncludeManager: boolean;
    sendReviewerAssignmentNotification: boolean;
    selfReviewAllowed: boolean;
    reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
    scopeResourcesCount: number;
    scopeGroupsCount: number;
    scopeAppsCount: number;
    includeGroupBindings: boolean;
    groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
    createdByUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    filters?: {
      __typename?: "AccessReviewFilters";
      names: Array<string>;
      adminIDs: Array<string>;
      resourceTypes: Array<ResourceType>;
      groupTypes: Array<GroupType>;
      entityIDs: Array<string>;
      connectionIDs: Array<string>;
      userIDs: Array<string>;
      matchMode: FiltersMatchMode;
      tags: Array<{
        __typename?: "TagKeyValue";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
  itemsToReviewStats?: {
    __typename?: "OngoingAccessReviewTabStats";
    numResourcesToReview: number;
    numGroupsToReview: number;
    numAppsToReview: number;
    numUsersToReview: number;
  } | null;
};

export type AccessReviewPreviewFragment = {
  __typename?: "AccessReview";
  id: string;
  createdAt: string;
  startedByUserId: string;
  stoppedByUserId?: string | null;
  stoppedDate?: string | null;
  deadline: string;
  timeZone: string;
  selfReviewAllowed: boolean;
  name: string;
  reminderSchedule: Array<number>;
  reminderTimeOfDay: string;
  reminderIncludeManager: boolean;
  lastReminderAt?: string | null;
  sendReviewerAssignmentNotification: boolean;
  reportId?: string | null;
  reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  accessReviewTemplateId?: string | null;
  includeGroupBindings: boolean;
};

export type TagKeyValueFragment = {
  __typename?: "TagKeyValue";
  key: string;
  value?: string | null;
};

export type AccessReviewItemStatusAndOutcomeFragment = {
  __typename?: "AccessReviewItemStatusAndOutcome";
  status: AccessReviewItemStatus;
  outcome: AccessReviewItemOutcome;
};

export type AccessReviewFiltersFragment = {
  __typename?: "AccessReviewFilters";
  names: Array<string>;
  adminIDs: Array<string>;
  resourceTypes: Array<ResourceType>;
  groupTypes: Array<GroupType>;
  entityIDs: Array<string>;
  connectionIDs: Array<string>;
  userIDs: Array<string>;
  matchMode: FiltersMatchMode;
  tags: Array<{
    __typename?: "TagKeyValue";
    key: string;
    value?: string | null;
  }>;
};

export type AccessReviewQueryVariables = Exact<{
  input: AccessReviewInput;
}>;

export type AccessReviewQuery = {
  __typename?: "Query";
  accessReview:
    | { __typename: "AccessReviewNotFoundError" }
    | {
        __typename: "AccessReviewResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          createdAt: string;
          startedByUserId: string;
          stoppedByUserId?: string | null;
          stoppedDate?: string | null;
          reportId?: string | null;
          deadline: string;
          selfReviewAllowed: boolean;
          name: string;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          lastReminderAt?: string | null;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          accessReviewTemplateId?: string | null;
          includeGroupBindings: boolean;
          stats: {
            __typename?: "AccessReviewStats";
            numResourcesReviewed: number;
            numResourcesTotal: number;
            numGroupsReviewed: number;
            numGroupsTotal: number;
            numConnectionsReviewed: number;
            numConnectionsTotal: number;
            numReviewersTotal: number;
            numReviewersIncomplete: number;
            numUserAssignmentsTotal: number;
            numUserAssignmentsInReview: number;
            numUserAssignmentsCompleted: number;
            numGroupResourceAssignmentsTotal: number;
            numGroupResourceAssignmentsInReview: number;
            numGroupResourceAssignmentsCompleted: number;
            numNHIAssignmentsTotal: number;
            numNHIAssignmentsInReview: number;
            numNHIAssignmentsCompleted: number;
            incompleteReviewers: Array<{
              __typename?: "IncompleteAccessReviewer";
              userId: string;
              incompleteResources: number;
              incompleteGroups: number;
              incompleteConnections: number;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
          };
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          stoppedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
          accessReviewTemplate?: {
            __typename?: "AccessReviewTemplate";
            id: string;
            createdAt: string;
            createdByUserId: string;
            updatedAt: string;
            name: string;
            accessReviewDuration: number;
            monthSchedule: Array<number>;
            nextScheduledRun: string;
            startDayOfMonth: number;
            timeZone: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            sendReviewerAssignmentNotification: boolean;
            selfReviewAllowed: boolean;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            scopeResourcesCount: number;
            scopeGroupsCount: number;
            scopeAppsCount: number;
            includeGroupBindings: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            filters?: {
              __typename?: "AccessReviewFilters";
              names: Array<string>;
              adminIDs: Array<string>;
              resourceTypes: Array<ResourceType>;
              groupTypes: Array<GroupType>;
              entityIDs: Array<string>;
              connectionIDs: Array<string>;
              userIDs: Array<string>;
              matchMode: FiltersMatchMode;
              tags: Array<{
                __typename?: "TagKeyValue";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          itemsToReviewStats?: {
            __typename?: "OngoingAccessReviewTabStats";
            numResourcesToReview: number;
            numGroupsToReview: number;
            numAppsToReview: number;
            numUsersToReview: number;
          } | null;
        };
      };
};

export type AccessReviewsQueryVariables = Exact<{
  input: AccessReviewsInput;
}>;

export type AccessReviewsQuery = {
  __typename?: "Query";
  accessReviews: {
    __typename: "AccessReviewsResult";
    accessReviews: Array<{
      __typename?: "AccessReview";
      id: string;
      createdAt: string;
      startedByUserId: string;
      stoppedByUserId?: string | null;
      stoppedDate?: string | null;
      reportId?: string | null;
      deadline: string;
      selfReviewAllowed: boolean;
      name: string;
      timeZone: string;
      reminderSchedule: Array<number>;
      reminderTimeOfDay: string;
      reminderIncludeManager: boolean;
      sendReviewerAssignmentNotification: boolean;
      lastReminderAt?: string | null;
      groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
      reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
      accessReviewTemplateId?: string | null;
      includeGroupBindings: boolean;
      stats: {
        __typename?: "AccessReviewStats";
        numResourcesReviewed: number;
        numResourcesTotal: number;
        numGroupsReviewed: number;
        numGroupsTotal: number;
        numConnectionsReviewed: number;
        numConnectionsTotal: number;
        numReviewersTotal: number;
        numReviewersIncomplete: number;
        numUserAssignmentsTotal: number;
        numUserAssignmentsInReview: number;
        numUserAssignmentsCompleted: number;
        numGroupResourceAssignmentsTotal: number;
        numGroupResourceAssignmentsInReview: number;
        numGroupResourceAssignmentsCompleted: number;
        numNHIAssignmentsTotal: number;
        numNHIAssignmentsInReview: number;
        numNHIAssignmentsCompleted: number;
        incompleteReviewers: Array<{
          __typename?: "IncompleteAccessReviewer";
          userId: string;
          incompleteResources: number;
          incompleteGroups: number;
          incompleteConnections: number;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }>;
      };
      startedByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      stoppedByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      filters?: {
        __typename?: "AccessReviewFilters";
        names: Array<string>;
        adminIDs: Array<string>;
        resourceTypes: Array<ResourceType>;
        groupTypes: Array<GroupType>;
        entityIDs: Array<string>;
        connectionIDs: Array<string>;
        userIDs: Array<string>;
        matchMode: FiltersMatchMode;
        tags: Array<{
          __typename?: "TagKeyValue";
          key: string;
          value?: string | null;
        }>;
      } | null;
      accessReviewTemplate?: {
        __typename?: "AccessReviewTemplate";
        id: string;
        createdAt: string;
        createdByUserId: string;
        updatedAt: string;
        name: string;
        accessReviewDuration: number;
        monthSchedule: Array<number>;
        nextScheduledRun: string;
        startDayOfMonth: number;
        timeZone: string;
        reminderSchedule: Array<number>;
        reminderTimeOfDay: string;
        reminderIncludeManager: boolean;
        sendReviewerAssignmentNotification: boolean;
        selfReviewAllowed: boolean;
        reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
        scopeResourcesCount: number;
        scopeGroupsCount: number;
        scopeAppsCount: number;
        includeGroupBindings: boolean;
        groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        filters?: {
          __typename?: "AccessReviewFilters";
          names: Array<string>;
          adminIDs: Array<string>;
          resourceTypes: Array<ResourceType>;
          groupTypes: Array<GroupType>;
          entityIDs: Array<string>;
          connectionIDs: Array<string>;
          userIDs: Array<string>;
          matchMode: FiltersMatchMode;
          tags: Array<{
            __typename?: "TagKeyValue";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
      itemsToReviewStats?: {
        __typename?: "OngoingAccessReviewTabStats";
        numResourcesToReview: number;
        numGroupsToReview: number;
        numAppsToReview: number;
        numUsersToReview: number;
      } | null;
    }>;
  };
};

export type AccessReviewsNoStatsQueryVariables = Exact<{
  input: AccessReviewsInput;
}>;

export type AccessReviewsNoStatsQuery = {
  __typename?: "Query";
  accessReviews: {
    __typename: "AccessReviewsResult";
    accessReviews: Array<{
      __typename?: "AccessReview";
      id: string;
      createdAt: string;
      startedByUserId: string;
      stoppedByUserId?: string | null;
      stoppedDate?: string | null;
      reportId?: string | null;
      deadline: string;
      selfReviewAllowed: boolean;
      name: string;
      timeZone: string;
      reminderSchedule: Array<number>;
      reminderTimeOfDay: string;
      reminderIncludeManager: boolean;
      sendReviewerAssignmentNotification: boolean;
      lastReminderAt?: string | null;
      groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
      reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
      accessReviewTemplateId?: string | null;
      includeGroupBindings: boolean;
      startedByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      stoppedByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      filters?: {
        __typename?: "AccessReviewFilters";
        names: Array<string>;
        adminIDs: Array<string>;
        resourceTypes: Array<ResourceType>;
        groupTypes: Array<GroupType>;
        entityIDs: Array<string>;
        connectionIDs: Array<string>;
        userIDs: Array<string>;
        matchMode: FiltersMatchMode;
        tags: Array<{
          __typename?: "TagKeyValue";
          key: string;
          value?: string | null;
        }>;
      } | null;
      accessReviewTemplate?: {
        __typename?: "AccessReviewTemplate";
        id: string;
        createdAt: string;
        createdByUserId: string;
        updatedAt: string;
        name: string;
        accessReviewDuration: number;
        monthSchedule: Array<number>;
        nextScheduledRun: string;
        startDayOfMonth: number;
        timeZone: string;
        reminderSchedule: Array<number>;
        reminderTimeOfDay: string;
        reminderIncludeManager: boolean;
        sendReviewerAssignmentNotification: boolean;
        selfReviewAllowed: boolean;
        reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
        scopeResourcesCount: number;
        scopeGroupsCount: number;
        scopeAppsCount: number;
        includeGroupBindings: boolean;
        groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        filters?: {
          __typename?: "AccessReviewFilters";
          names: Array<string>;
          adminIDs: Array<string>;
          resourceTypes: Array<ResourceType>;
          groupTypes: Array<GroupType>;
          entityIDs: Array<string>;
          connectionIDs: Array<string>;
          userIDs: Array<string>;
          matchMode: FiltersMatchMode;
          tags: Array<{
            __typename?: "TagKeyValue";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
      itemsToReviewStats?: {
        __typename?: "OngoingAccessReviewTabStats";
        numResourcesToReview: number;
        numGroupsToReview: number;
        numAppsToReview: number;
        numUsersToReview: number;
      } | null;
    }>;
  };
};

export type AccessReviewRevocationsQueryVariables = Exact<{
  input: AccessReviewInput;
}>;

export type AccessReviewRevocationsQuery = {
  __typename?: "Query";
  accessReviewResourceUsers:
    | { __typename: "AccessReviewNotFoundError" }
    | {
        __typename: "AccessReviewResourceUsersResult";
        accessReviewResourceUsers?: Array<{
          __typename?: "AccessReviewResourceUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewResourceId: string;
          userId: string;
          resourceId: string;
          eventId: string;
          supportTicketId?: string | null;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          requestId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename?: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
          resourceUser?: {
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            summary: string;
            issueType: string;
            projectName: string;
          } | null;
        }> | null;
      };
  accessReviewGroupUsers:
    | {
        __typename: "AccessReviewGroupUsersResult";
        accessReviewGroupUsers?: Array<{
          __typename?: "AccessReviewGroupUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewGroupId: string;
          userId: string;
          groupId: string;
          eventId: string;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel: {
            __typename?: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          } | null;
        }> | null;
      }
    | { __typename: "AccessReviewNotFoundError" };
  accessReviewConnectionUsers:
    | {
        __typename: "AccessReviewConnectionUsersResult";
        accessReviewConnectionUsers?: Array<{
          __typename?: "AccessReviewConnectionUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewConnectionId: string;
          userId: string;
          connectionId: string;
          eventId?: string | null;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
        }> | null;
      }
    | { __typename: "AccessReviewNotFoundError" };
};

export type UpdateAccessReviewMutationVariables = Exact<{
  input: UpdateAccessReviewInput;
}>;

export type UpdateAccessReviewMutation = {
  __typename?: "Mutation";
  updateAccessReview:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "UpdateAccessReviewResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          createdAt: string;
          startedByUserId: string;
          stoppedByUserId?: string | null;
          stoppedDate?: string | null;
          reportId?: string | null;
          deadline: string;
          selfReviewAllowed: boolean;
          name: string;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          lastReminderAt?: string | null;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          accessReviewTemplateId?: string | null;
          includeGroupBindings: boolean;
          stats: {
            __typename?: "AccessReviewStats";
            numResourcesReviewed: number;
            numResourcesTotal: number;
            numGroupsReviewed: number;
            numGroupsTotal: number;
            numConnectionsReviewed: number;
            numConnectionsTotal: number;
            numReviewersTotal: number;
            numReviewersIncomplete: number;
            numUserAssignmentsTotal: number;
            numUserAssignmentsInReview: number;
            numUserAssignmentsCompleted: number;
            numGroupResourceAssignmentsTotal: number;
            numGroupResourceAssignmentsInReview: number;
            numGroupResourceAssignmentsCompleted: number;
            numNHIAssignmentsTotal: number;
            numNHIAssignmentsInReview: number;
            numNHIAssignmentsCompleted: number;
            incompleteReviewers: Array<{
              __typename?: "IncompleteAccessReviewer";
              userId: string;
              incompleteResources: number;
              incompleteGroups: number;
              incompleteConnections: number;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
          };
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          stoppedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
          accessReviewTemplate?: {
            __typename?: "AccessReviewTemplate";
            id: string;
            createdAt: string;
            createdByUserId: string;
            updatedAt: string;
            name: string;
            accessReviewDuration: number;
            monthSchedule: Array<number>;
            nextScheduledRun: string;
            startDayOfMonth: number;
            timeZone: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            sendReviewerAssignmentNotification: boolean;
            selfReviewAllowed: boolean;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            scopeResourcesCount: number;
            scopeGroupsCount: number;
            scopeAppsCount: number;
            includeGroupBindings: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            filters?: {
              __typename?: "AccessReviewFilters";
              names: Array<string>;
              adminIDs: Array<string>;
              resourceTypes: Array<ResourceType>;
              groupTypes: Array<GroupType>;
              entityIDs: Array<string>;
              connectionIDs: Array<string>;
              userIDs: Array<string>;
              matchMode: FiltersMatchMode;
              tags: Array<{
                __typename?: "TagKeyValue";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          itemsToReviewStats?: {
            __typename?: "OngoingAccessReviewTabStats";
            numResourcesToReview: number;
            numGroupsToReview: number;
            numAppsToReview: number;
            numUsersToReview: number;
          } | null;
        };
      };
};

export type StopAccessReviewMutationVariables = Exact<{
  input: StopAccessReviewInput;
}>;

export type StopAccessReviewMutation = {
  __typename?: "Mutation";
  stopAccessReview:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "StopAccessReviewResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          createdAt: string;
          startedByUserId: string;
          stoppedByUserId?: string | null;
          stoppedDate?: string | null;
          reportId?: string | null;
          deadline: string;
          selfReviewAllowed: boolean;
          name: string;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          lastReminderAt?: string | null;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          accessReviewTemplateId?: string | null;
          includeGroupBindings: boolean;
          stats: {
            __typename?: "AccessReviewStats";
            numResourcesReviewed: number;
            numResourcesTotal: number;
            numGroupsReviewed: number;
            numGroupsTotal: number;
            numConnectionsReviewed: number;
            numConnectionsTotal: number;
            numReviewersTotal: number;
            numReviewersIncomplete: number;
            numUserAssignmentsTotal: number;
            numUserAssignmentsInReview: number;
            numUserAssignmentsCompleted: number;
            numGroupResourceAssignmentsTotal: number;
            numGroupResourceAssignmentsInReview: number;
            numGroupResourceAssignmentsCompleted: number;
            numNHIAssignmentsTotal: number;
            numNHIAssignmentsInReview: number;
            numNHIAssignmentsCompleted: number;
            incompleteReviewers: Array<{
              __typename?: "IncompleteAccessReviewer";
              userId: string;
              incompleteResources: number;
              incompleteGroups: number;
              incompleteConnections: number;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
          };
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          stoppedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
          accessReviewTemplate?: {
            __typename?: "AccessReviewTemplate";
            id: string;
            createdAt: string;
            createdByUserId: string;
            updatedAt: string;
            name: string;
            accessReviewDuration: number;
            monthSchedule: Array<number>;
            nextScheduledRun: string;
            startDayOfMonth: number;
            timeZone: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            sendReviewerAssignmentNotification: boolean;
            selfReviewAllowed: boolean;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            scopeResourcesCount: number;
            scopeGroupsCount: number;
            scopeAppsCount: number;
            includeGroupBindings: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            filters?: {
              __typename?: "AccessReviewFilters";
              names: Array<string>;
              adminIDs: Array<string>;
              resourceTypes: Array<ResourceType>;
              groupTypes: Array<GroupType>;
              entityIDs: Array<string>;
              connectionIDs: Array<string>;
              userIDs: Array<string>;
              matchMode: FiltersMatchMode;
              tags: Array<{
                __typename?: "TagKeyValue";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          itemsToReviewStats?: {
            __typename?: "OngoingAccessReviewTabStats";
            numResourcesToReview: number;
            numGroupsToReview: number;
            numAppsToReview: number;
            numUsersToReview: number;
          } | null;
        };
      };
};

export type SetNoteMutationVariables = Exact<{
  input: SetNoteInput;
}>;

export type SetNoteMutation = {
  __typename?: "Mutation";
  setNote:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | { __typename: "SetNoteResult"; success: boolean };
};

export type PerformReviewMutationVariables = Exact<{
  input: PerformReviewInput;
}>;

export type PerformReviewMutation = {
  __typename?: "Mutation";
  performReview:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | { __typename: "ItemCannotBeRequestedError"; message: string }
    | { __typename: "OidcIDTokenNotFoundError" }
    | { __typename: "PerformReviewResult"; success: boolean };
};

export type SendAccessReviewReminderMutationVariables = Exact<{
  input: SendAccessReviewReminderInput;
}>;

export type SendAccessReviewReminderMutation = {
  __typename?: "Mutation";
  sendAccessReviewReminder:
    | { __typename: "AccessReviewAlreadyStoppedError"; message: string }
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "SendAccessReviewReminderResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          createdAt: string;
          startedByUserId: string;
          stoppedByUserId?: string | null;
          stoppedDate?: string | null;
          reportId?: string | null;
          deadline: string;
          selfReviewAllowed: boolean;
          name: string;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          lastReminderAt?: string | null;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          accessReviewTemplateId?: string | null;
          includeGroupBindings: boolean;
          stats: {
            __typename?: "AccessReviewStats";
            numResourcesReviewed: number;
            numResourcesTotal: number;
            numGroupsReviewed: number;
            numGroupsTotal: number;
            numConnectionsReviewed: number;
            numConnectionsTotal: number;
            numReviewersTotal: number;
            numReviewersIncomplete: number;
            numUserAssignmentsTotal: number;
            numUserAssignmentsInReview: number;
            numUserAssignmentsCompleted: number;
            numGroupResourceAssignmentsTotal: number;
            numGroupResourceAssignmentsInReview: number;
            numGroupResourceAssignmentsCompleted: number;
            numNHIAssignmentsTotal: number;
            numNHIAssignmentsInReview: number;
            numNHIAssignmentsCompleted: number;
            incompleteReviewers: Array<{
              __typename?: "IncompleteAccessReviewer";
              userId: string;
              incompleteResources: number;
              incompleteGroups: number;
              incompleteConnections: number;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
          };
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          stoppedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
          accessReviewTemplate?: {
            __typename?: "AccessReviewTemplate";
            id: string;
            createdAt: string;
            createdByUserId: string;
            updatedAt: string;
            name: string;
            accessReviewDuration: number;
            monthSchedule: Array<number>;
            nextScheduledRun: string;
            startDayOfMonth: number;
            timeZone: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            sendReviewerAssignmentNotification: boolean;
            selfReviewAllowed: boolean;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            scopeResourcesCount: number;
            scopeGroupsCount: number;
            scopeAppsCount: number;
            includeGroupBindings: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            filters?: {
              __typename?: "AccessReviewFilters";
              names: Array<string>;
              adminIDs: Array<string>;
              resourceTypes: Array<ResourceType>;
              groupTypes: Array<GroupType>;
              entityIDs: Array<string>;
              connectionIDs: Array<string>;
              userIDs: Array<string>;
              matchMode: FiltersMatchMode;
              tags: Array<{
                __typename?: "TagKeyValue";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          itemsToReviewStats?: {
            __typename?: "OngoingAccessReviewTabStats";
            numResourcesToReview: number;
            numGroupsToReview: number;
            numAppsToReview: number;
            numUsersToReview: number;
          } | null;
        };
      };
};

export type RegenerateAccessReviewReportMutationVariables = Exact<{
  input: RegenerateAccessReviewReportInput;
}>;

export type RegenerateAccessReviewReportMutation = {
  __typename?: "Mutation";
  regenerateAccessReviewReport:
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | { __typename: "AccessReviewNotStoppedError"; message: string }
    | {
        __typename: "RegenerateAccessReviewReportResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          createdAt: string;
          startedByUserId: string;
          stoppedByUserId?: string | null;
          stoppedDate?: string | null;
          reportId?: string | null;
          deadline: string;
          selfReviewAllowed: boolean;
          name: string;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          lastReminderAt?: string | null;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          accessReviewTemplateId?: string | null;
          includeGroupBindings: boolean;
          stats: {
            __typename?: "AccessReviewStats";
            numResourcesReviewed: number;
            numResourcesTotal: number;
            numGroupsReviewed: number;
            numGroupsTotal: number;
            numConnectionsReviewed: number;
            numConnectionsTotal: number;
            numReviewersTotal: number;
            numReviewersIncomplete: number;
            numUserAssignmentsTotal: number;
            numUserAssignmentsInReview: number;
            numUserAssignmentsCompleted: number;
            numGroupResourceAssignmentsTotal: number;
            numGroupResourceAssignmentsInReview: number;
            numGroupResourceAssignmentsCompleted: number;
            numNHIAssignmentsTotal: number;
            numNHIAssignmentsInReview: number;
            numNHIAssignmentsCompleted: number;
            incompleteReviewers: Array<{
              __typename?: "IncompleteAccessReviewer";
              userId: string;
              incompleteResources: number;
              incompleteGroups: number;
              incompleteConnections: number;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
          };
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          stoppedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
          accessReviewTemplate?: {
            __typename?: "AccessReviewTemplate";
            id: string;
            createdAt: string;
            createdByUserId: string;
            updatedAt: string;
            name: string;
            accessReviewDuration: number;
            monthSchedule: Array<number>;
            nextScheduledRun: string;
            startDayOfMonth: number;
            timeZone: string;
            reminderSchedule: Array<number>;
            reminderTimeOfDay: string;
            reminderIncludeManager: boolean;
            sendReviewerAssignmentNotification: boolean;
            selfReviewAllowed: boolean;
            reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
            scopeResourcesCount: number;
            scopeGroupsCount: number;
            scopeAppsCount: number;
            includeGroupBindings: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            filters?: {
              __typename?: "AccessReviewFilters";
              names: Array<string>;
              adminIDs: Array<string>;
              resourceTypes: Array<ResourceType>;
              groupTypes: Array<GroupType>;
              entityIDs: Array<string>;
              connectionIDs: Array<string>;
              userIDs: Array<string>;
              matchMode: FiltersMatchMode;
              tags: Array<{
                __typename?: "TagKeyValue";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          itemsToReviewStats?: {
            __typename?: "OngoingAccessReviewTabStats";
            numResourcesToReview: number;
            numGroupsToReview: number;
            numAppsToReview: number;
            numUsersToReview: number;
          } | null;
        };
      };
};

export type ResetDataseedMutationVariables = Exact<{ [key: string]: never }>;

export type ResetDataseedMutation = {
  __typename?: "Mutation";
  resetDataseed: { __typename: "ResetDataseedResult"; success: boolean };
};

export type RescoreRecommendationsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RescoreRecommendationsMutation = {
  __typename?: "Mutation";
  rescoreRecommendations: {
    __typename: "RescoreRecommendationsResult";
    success: boolean;
  };
};

export type ResetRemediationsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResetRemediationsMutation = {
  __typename?: "Mutation";
  resetRemediations: {
    __typename: "ResetRemediationsResult";
    success: boolean;
  };
};

export type SetRolesMutationVariables = Exact<{
  input: SetRolesInput;
}>;

export type SetRolesMutation = {
  __typename?: "Mutation";
  setRoles: { __typename: "SetRolesResult"; success: boolean };
};

export type AddBigDataMutationVariables = Exact<{
  input: AddBigDataInput;
}>;

export type AddBigDataMutation = {
  __typename?: "Mutation";
  addBigData: { __typename: "AddBigDataResult"; success: boolean };
};

export type AppInfoFragment = {
  __typename?: "AppInfo";
  isOnPrem: boolean;
  version: string;
  environment: string;
  isRemoteLoggingEnabled: boolean;
};

export type InitUnauthedAppQueryVariables = Exact<{ [key: string]: never }>;

export type InitUnauthedAppQuery = {
  __typename?: "Query";
  appInfo: {
    __typename: "AppInfoResult";
    appInfo: {
      __typename?: "AppInfo";
      isOnPrem: boolean;
      version: string;
      environment: string;
      isRemoteLoggingEnabled: boolean;
    };
  };
  authProviderInfo:
    | {
        __typename: "AuthProviderInfoResult";
        providerInfo: {
          __typename?: "AuthProviderInfo";
          domain: string;
          audience: string;
          clientId: string;
          redirectUri: string;
          logoutUri: string;
        };
      }
    | { __typename: "ThirdPartyClientNotFoundError"; message: string };
  authSessionStatus: {
    __typename: "AuthSessionStatusResult";
    status: AuthSessionStatus;
    user?: {
      __typename?: "AuthUser";
      isAdmin: boolean;
      isReadOnlyAdmin: boolean;
      isAuditor: boolean;
      isGlobalRequester: boolean;
      organizationAuthorizedActions?: Array<string> | null;
      opalAdminExpirationTime?: string | null;
      user: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        organization: {
          __typename?: "Organization";
          id: string;
          name: string;
          createdAt: string;
        };
        directReports: Array<{
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }>;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      };
    } | null;
    userImpersonator?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  };
};

export type AuthUserFragment = {
  __typename?: "AuthUser";
  isAdmin: boolean;
  isReadOnlyAdmin: boolean;
  isAuditor: boolean;
  isGlobalRequester: boolean;
  organizationAuthorizedActions?: Array<string> | null;
  opalAdminExpirationTime?: string | null;
  user: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    organization: {
      __typename?: "Organization";
      id: string;
      name: string;
      createdAt: string;
    };
    directReports: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  };
};

export type FirstPartyTokenFragment = {
  __typename?: "FirstPartyToken";
  id: string;
  createdAt: string;
  tokenPreview: string;
  tokenLabel: string;
  creatorUserId: string;
  lastUsedAt?: string | null;
  expiresAt?: string | null;
  accessLevel: ApiAccessLevel;
  creatorUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type SamlConnectionQueryVariables = Exact<{ [key: string]: never }>;

export type SamlConnectionQuery = {
  __typename?: "Query";
  samlConnection: {
    __typename: "SamlConnectionResult";
    organizationSamlInfo?: {
      __typename?: "OrganizationSamlInfo";
      id: string;
      email: string;
      endpoint: string;
      certExpiration: string;
      restrictLoginToSaml: boolean;
      samlBreakGlassUsers: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
  };
};

export type SignInMethodQueryVariables = Exact<{
  input: SignInMethodInput;
}>;

export type SignInMethodQuery = {
  __typename?: "Query";
  signInMethod?:
    | { __typename: "InvalidEmailError"; message: string }
    | {
        __typename: "SignInMethodResult";
        signInOrganizations: Array<{
          __typename?: "SignInOrganization";
          organizationId: string;
          organizationName: string;
          enableSocialSignIn: boolean;
          enableSAMLSignIn: boolean;
        }>;
      }
    | null;
};

export type FirstPartyTokensQueryVariables = Exact<{ [key: string]: never }>;

export type FirstPartyTokensQuery = {
  __typename?: "Query";
  firstPartyTokens: {
    __typename: "FirstPartyTokensResult";
    tokens: Array<{
      __typename?: "FirstPartyToken";
      id: string;
      createdAt: string;
      tokenPreview: string;
      tokenLabel: string;
      creatorUserId: string;
      lastUsedAt?: string | null;
      expiresAt?: string | null;
      accessLevel: ApiAccessLevel;
      creatorUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type AuthSessionStatusQueryVariables = Exact<{ [key: string]: never }>;

export type AuthSessionStatusQuery = {
  __typename?: "Query";
  authSessionStatus: {
    __typename: "AuthSessionStatusResult";
    status: AuthSessionStatus;
  };
};

export type AuthCodeCallbackMutationVariables = Exact<{
  input: AuthCodeCallbackInput;
}>;

export type AuthCodeCallbackMutation = {
  __typename?: "Mutation";
  authCodeCallback:
    | {
        __typename: "AuthCodeCallbackResult";
        user: {
          __typename?: "AuthUser";
          isAdmin: boolean;
          isReadOnlyAdmin: boolean;
          isAuditor: boolean;
          isGlobalRequester: boolean;
          organizationAuthorizedActions?: Array<string> | null;
          opalAdminExpirationTime?: string | null;
          user: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            organization: {
              __typename?: "Organization";
              id: string;
              name: string;
              createdAt: string;
            };
            directReports: Array<{
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            }>;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          };
        };
      }
    | { __typename: "SamlLoginRequiredError"; message: string }
    | { __typename: "UserAlreadyExistsInAnotherOrg"; message: string }
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      };
};

export type MfaCallbackMutationVariables = Exact<{
  input: MfaCallbackInput;
}>;

export type MfaCallbackMutation = {
  __typename?: "Mutation";
  mfaCallback: { __typename: "MfaCallbackResult"; mfaTime?: string | null };
};

export type SignInMutationVariables = Exact<{
  input: SignInInput;
}>;

export type SignInMutation = {
  __typename?: "Mutation";
  signIn: {
    __typename: "SignInResult";
    state: string;
    forceExtraStep: boolean;
  };
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: "Mutation";
  signOut: { __typename: "SignOutResult"; success?: boolean | null };
};

export type CreateSamlConnectionMutationVariables = Exact<{
  input: CreateSamlConnectionInput;
}>;

export type CreateSamlConnectionMutation = {
  __typename?: "Mutation";
  createSamlConnection:
    | { __typename: "CertIsTooLargeError" }
    | {
        __typename: "CreateSamlConnectionResult";
        organizationSamlInfo: {
          __typename?: "OrganizationSamlInfo";
          id: string;
          email: string;
          endpoint: string;
          certExpiration: string;
          restrictLoginToSaml: boolean;
          samlBreakGlassUsers: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        };
      }
    | { __typename: "InvalidEmailError"; message: string };
};

export type UpdateSamlConnectionMutationVariables = Exact<{
  input: UpdateSamlConnectionInput;
}>;

export type UpdateSamlConnectionMutation = {
  __typename?: "Mutation";
  updateSamlConnection: {
    __typename: "UpdateSamlConnectionResult";
    organizationSamlInfo: {
      __typename?: "OrganizationSamlInfo";
      id: string;
      email: string;
      endpoint: string;
      certExpiration: string;
      restrictLoginToSaml: boolean;
      samlBreakGlassUsers: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    };
  };
};

export type DeleteSamlConnectionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteSamlConnectionMutation = {
  __typename?: "Mutation";
  deleteSamlConnection: {
    __typename: "DeleteSamlConnectionResult";
    organizationSamlInfo: {
      __typename?: "OrganizationSamlInfo";
      id: string;
      email: string;
      endpoint: string;
      certExpiration: string;
      restrictLoginToSaml: boolean;
      samlBreakGlassUsers: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    };
  };
};

export type CreateFirstPartyTokenMutationVariables = Exact<{
  input: CreateFirstPartyTokenInput;
}>;

export type CreateFirstPartyTokenMutation = {
  __typename?: "Mutation";
  createFirstPartyToken: {
    __typename: "CreateFirstPartyTokenResult";
    signedToken: string;
    token: {
      __typename?: "FirstPartyToken";
      id: string;
      createdAt: string;
      tokenPreview: string;
      tokenLabel: string;
      creatorUserId: string;
      lastUsedAt?: string | null;
      expiresAt?: string | null;
      accessLevel: ApiAccessLevel;
      creatorUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    };
  };
};

export type DeleteFirstPartyTokensMutationVariables = Exact<{
  input: DeleteFirstPartyTokensInput;
}>;

export type DeleteFirstPartyTokensMutation = {
  __typename?: "Mutation";
  deleteFirstPartyTokens: {
    __typename: "DeleteFirstPartyTokensResult";
    entries: Array<{
      __typename?: "DeleteFirstPartyTokenEntryResult";
      id: string;
    }>;
  };
};

export type AssumeImpersonationMutationVariables = Exact<{
  input: AssumeImpersonationInput;
}>;

export type AssumeImpersonationMutation = {
  __typename?: "Mutation";
  assumeImpersonation: {
    __typename?: "AssumeImpersonationResult";
    success: boolean;
  };
};

export type StopImpersonationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StopImpersonationMutation = {
  __typename?: "Mutation";
  stopImpersonation: {
    __typename?: "StopImpersonationResult";
    success: boolean;
  };
};

export type AuthProviderInfoFragment = {
  __typename?: "AuthProviderInfo";
  domain: string;
  audience: string;
  clientId: string;
  redirectUri: string;
  logoutUri: string;
};

export type CreateAuthProviderApplicationMutationVariables = Exact<{
  input: CreateAuthProviderApplicationInput;
}>;

export type CreateAuthProviderApplicationMutation = {
  __typename?: "Mutation";
  createAuthProviderApplication:
    | {
        __typename: "CreateAuthProviderApplicationResult";
        providerInfo: {
          __typename?: "AuthProviderInfo";
          domain: string;
          audience: string;
          clientId: string;
          redirectUri: string;
          logoutUri: string;
        };
      }
    | { __typename: "HttpsHostnameRequiredError"; message: string };
};

export type EntityIdTupleFragment = {
  __typename?: "EntityIdTuple";
  entityId: string;
  entityType: EntityType;
};

export type ErrorEntityFragment = {
  __typename?: "ErrorEntity";
  name: string;
  id: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  };
};

export type AddConnectionUsersMutationVariables = Exact<{
  input: AddConnectionUsersInput;
}>;

export type AddConnectionUsersMutation = {
  __typename?: "Mutation";
  addConnectionUsers:
    | {
        __typename: "AddConnectionUsersResult";
        taskId: string;
        success: boolean;
      }
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "ConnectionUserAlreadyExists"; message: string };
};

export type RemoveConnectionUsersMutationVariables = Exact<{
  input: RemoveConnectionUsersInput;
}>;

export type RemoveConnectionUsersMutation = {
  __typename?: "Mutation";
  removeConnectionUsers:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "ConnectionUserNotFound"; message: string }
    | {
        __typename: "RemoveConnectionUsersResult";
        taskId: string;
        success: boolean;
      };
};

export type ConnectionVisibilityGroupFragment = {
  __typename?: "ConnectionVisibilityGroup";
  visibilityGroupId: string;
  visibilityGroup?: { __typename?: "Group"; name: string } | null;
};

export type ConnectionPreviewTinyFragment = {
  __typename?: "Connection";
  id: string;
  name: string;
  connectionType: ConnectionType;
};

export type ConnectionPreviewSmallFragment = {
  __typename: "Connection";
  id: string;
  name: string;
  connectionType: ConnectionType;
  isDeleted: boolean;
  metadata?:
    | {
        __typename: "ADConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
      }
    | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
    | {
        __typename: "AWSSSOConnectionMetadata";
        managementAccountId: string;
        ssoInstanceArn: string;
        identityStoreId: string;
        accessPortalUrl: string;
        awsRegion: string;
        awsOrganizationEnabled: boolean;
        awsSsoEnabled: boolean;
        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
        awsOrganizationImportSetting: ImportSetting;
        ec2RoleChainingEnabled: boolean;
        podRoleChainingEnabled: boolean;
        cloudtrailEventsSqsUrl: string;
      }
    | {
        __typename: "AzureADConnectionMetadata";
        tenantId: string;
        clientId: string;
        azureInfraEnabled: boolean;
        eventHubNamespace: string;
        eventHub: string;
      }
    | {
        __typename: "CustomConnectorConnectionMetadata";
        identifier: string;
        baseUrl: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
        supportsGroups: boolean;
        supportsNestedResources: boolean;
      }
    | { __typename: "DuoConnectionMetadata"; apiHostname: string }
    | {
        __typename: "GCPConnectionMetadata";
        pubSubProjectId: string;
        subscriptionId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | { __typename: "GitHubConnectionMetadata"; orgName: string }
    | {
        __typename: "GitLabConnectionMetadata";
        hostName?: string | null;
        appId: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "GoogleGroupsConnectionMetadata";
        opalGroupEmail: string;
        adminUserEmail: string;
        domain: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "GoogleWorkspaceConnectionMetadata";
        adminUserEmail: string;
        customerId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "LDAPConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
        groupsUidKey: string;
      }
    | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
    | {
        __typename: "MongoConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "MySQLMariaDBConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
    | { __typename: "PagerDutyConnectionMetadata" }
    | {
        __typename: "PostgresConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "PropagationTicketConnectionMetadata";
        ticketProvider?: {
          __typename?: "TicketProvider";
          ticketProvider: ThirdPartyProvider;
          ticketProjectId: string;
          ticketProjectName: string;
        } | null;
      }
    | {
        __typename: "SalesforceConnectionMetadata";
        email: string;
        hostname: string;
      }
    | {
        __typename: "SnowflakeConnectionMetadata";
        organization: string;
        account: string;
        locator: string;
        accountIdentifier: string;
        accountUrl: string;
        serviceAccountName: string;
        serviceAccountRole: string;
      }
    | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
    | {
        __typename: "TeleportConnectionMetadata";
        host: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "WorkdayConnectionMetadata";
        tenantName: string;
        tenantUrl: string;
      }
    | null;
};

export type ConnectionPreviewLargeFragment = {
  __typename: "Connection";
  id: string;
  name: string;
  description: string;
  connectionType: ConnectionType;
  isDeleted: boolean;
  visibility: Visibility;
  importVisibility: Visibility;
  importSetting: ImportSetting;
  autoImportGroupResources: boolean;
  numUsers: number;
  webhookEnabled: boolean;
  iconUrl?: string | null;
  adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
  visibilityGroups: Array<{
    __typename?: "ConnectionVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup?: { __typename?: "Group"; name: string } | null;
  }>;
  metadata?:
    | {
        __typename: "ADConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
      }
    | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
    | {
        __typename: "AWSSSOConnectionMetadata";
        managementAccountId: string;
        ssoInstanceArn: string;
        identityStoreId: string;
        accessPortalUrl: string;
        awsRegion: string;
        awsOrganizationEnabled: boolean;
        awsSsoEnabled: boolean;
        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
        awsOrganizationImportSetting: ImportSetting;
        ec2RoleChainingEnabled: boolean;
        podRoleChainingEnabled: boolean;
        cloudtrailEventsSqsUrl: string;
      }
    | {
        __typename: "AzureADConnectionMetadata";
        tenantId: string;
        clientId: string;
        azureInfraEnabled: boolean;
        eventHubNamespace: string;
        eventHub: string;
      }
    | {
        __typename: "CustomConnectorConnectionMetadata";
        identifier: string;
        baseUrl: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
        supportsGroups: boolean;
        supportsNestedResources: boolean;
      }
    | { __typename: "DuoConnectionMetadata"; apiHostname: string }
    | {
        __typename: "GCPConnectionMetadata";
        pubSubProjectId: string;
        subscriptionId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | { __typename: "GitHubConnectionMetadata"; orgName: string }
    | {
        __typename: "GitLabConnectionMetadata";
        hostName?: string | null;
        appId: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "GoogleGroupsConnectionMetadata";
        opalGroupEmail: string;
        adminUserEmail: string;
        domain: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "GoogleWorkspaceConnectionMetadata";
        adminUserEmail: string;
        customerId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "LDAPConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
        groupsUidKey: string;
      }
    | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
    | {
        __typename: "MongoConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "MySQLMariaDBConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
    | { __typename: "PagerDutyConnectionMetadata" }
    | {
        __typename: "PostgresConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "PropagationTicketConnectionMetadata";
        ticketProvider?: {
          __typename?: "TicketProvider";
          ticketProvider: ThirdPartyProvider;
          ticketProjectId: string;
          ticketProjectName: string;
        } | null;
      }
    | {
        __typename: "SalesforceConnectionMetadata";
        email: string;
        hostname: string;
      }
    | {
        __typename: "SnowflakeConnectionMetadata";
        organization: string;
        account: string;
        locator: string;
        accountIdentifier: string;
        accountUrl: string;
        serviceAccountName: string;
        serviceAccountRole: string;
      }
    | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
    | {
        __typename: "TeleportConnectionMetadata";
        host: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "WorkdayConnectionMetadata";
        tenantName: string;
        tenantUrl: string;
      }
    | null;
  childrenDefaultConfigTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  importNotificationOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
  } | null;
};

export type ConnectionUserFragment = {
  __typename: "ConnectionUser";
  connectionId: string;
  userId: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  propagationStatus?: {
    __typename?: "ConnectionUserPropagationStatus";
    connectionId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type AdConnectionMetadataFragment = {
  __typename?: "ADConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  groupsDistinguishedName: string;
  usersDistinguishedName: string;
};

export type AwsConnectionMetadataFragment = {
  __typename?: "AwsConnectionMetadata";
  success?: boolean | null;
};

export type AwsssoConnectionMetadataFragment = {
  __typename?: "AWSSSOConnectionMetadata";
  managementAccountId: string;
  ssoInstanceArn: string;
  identityStoreId: string;
  accessPortalUrl: string;
  awsRegion: string;
  awsOrganizationEnabled: boolean;
  awsSsoEnabled: boolean;
  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
  awsOrganizationImportSetting: ImportSetting;
  ec2RoleChainingEnabled: boolean;
  podRoleChainingEnabled: boolean;
  cloudtrailEventsSqsUrl: string;
};

export type GcpConnectionMetadataFragment = {
  __typename?: "GCPConnectionMetadata";
  pubSubProjectId: string;
  subscriptionId: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

export type GitHubConnectionMetadataFragment = {
  __typename?: "GitHubConnectionMetadata";
  orgName: string;
};

export type GitLabConnectionMetadataFragment = {
  __typename?: "GitLabConnectionMetadata";
  hostName?: string | null;
  appId: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
};

export type GoogleGroupsConnectionMetadataFragment = {
  __typename?: "GoogleGroupsConnectionMetadata";
  opalGroupEmail: string;
  adminUserEmail: string;
  domain: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

export type GoogleWorkspaceConnectionMetadataFragment = {
  __typename?: "GoogleWorkspaceConnectionMetadata";
  adminUserEmail: string;
  customerId: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

export type LdapConnectionMetadataFragment = {
  __typename?: "LDAPConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  groupsDistinguishedName: string;
  usersDistinguishedName: string;
  groupsUidKey: string;
};

export type MongoConnectionMetadataFragment = {
  __typename?: "MongoConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

export type MySqlMariaDbConnectionMetadataFragment = {
  __typename?: "MySQLMariaDBConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

export type PostgresConnectionMetadataFragment = {
  __typename?: "PostgresConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

export type MongoAtlasConnectionMetadataFragment = {
  __typename?: "MongoAtlasConnectionMetadata";
  serverHostname: string;
};

export type OktaDirectoryConnectionMetadataFragment = {
  __typename?: "OktaDirectoryConnectionMetadata";
  orgUrl: string;
};

export type SalesforceConnectionMetadataFragment = {
  __typename?: "SalesforceConnectionMetadata";
  email: string;
  hostname: string;
};

export type TailscaleConnectionMetadataFragment = {
  __typename?: "TailscaleConnectionMetadata";
  tailnet: string;
};

export type TeleportConnectionMetadataFragment = {
  __typename?: "TeleportConnectionMetadata";
  host: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
};

export type DuoConnectionMetadataFragment = {
  __typename?: "DuoConnectionMetadata";
  apiHostname: string;
};

export type PropagationTicketConnectionMetadataFragment = {
  __typename?: "PropagationTicketConnectionMetadata";
  ticketProvider?: {
    __typename?: "TicketProvider";
    ticketProvider: ThirdPartyProvider;
    ticketProjectId: string;
    ticketProjectName: string;
  } | null;
};

export type CustomConnectorConnectionMetadataFragment = {
  __typename?: "CustomConnectorConnectionMetadata";
  identifier: string;
  baseUrl: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
  supportsGroups: boolean;
  supportsNestedResources: boolean;
};

export type AzureAdConnectionMetadataFragment = {
  __typename?: "AzureADConnectionMetadata";
  tenantId: string;
  clientId: string;
  azureInfraEnabled: boolean;
  eventHubNamespace: string;
  eventHub: string;
};

export type SnowflakeConnectionMetadataFragment = {
  __typename?: "SnowflakeConnectionMetadata";
  organization: string;
  account: string;
  locator: string;
  accountIdentifier: string;
  accountUrl: string;
  serviceAccountName: string;
  serviceAccountRole: string;
};

export type WorkdayConnectionMetadataFragment = {
  __typename?: "WorkdayConnectionMetadata";
  tenantName: string;
  tenantUrl: string;
};

type ConnectionMetadataOutput_AdConnectionMetadata_Fragment = {
  __typename: "ADConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  groupsDistinguishedName: string;
  usersDistinguishedName: string;
};

type ConnectionMetadataOutput_AwsConnectionMetadata_Fragment = {
  __typename: "AwsConnectionMetadata";
  success?: boolean | null;
};

type ConnectionMetadataOutput_AwsssoConnectionMetadata_Fragment = {
  __typename: "AWSSSOConnectionMetadata";
  managementAccountId: string;
  ssoInstanceArn: string;
  identityStoreId: string;
  accessPortalUrl: string;
  awsRegion: string;
  awsOrganizationEnabled: boolean;
  awsSsoEnabled: boolean;
  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
  awsOrganizationImportSetting: ImportSetting;
  ec2RoleChainingEnabled: boolean;
  podRoleChainingEnabled: boolean;
  cloudtrailEventsSqsUrl: string;
};

type ConnectionMetadataOutput_AzureAdConnectionMetadata_Fragment = {
  __typename: "AzureADConnectionMetadata";
  tenantId: string;
  clientId: string;
  azureInfraEnabled: boolean;
  eventHubNamespace: string;
  eventHub: string;
};

type ConnectionMetadataOutput_CustomConnectorConnectionMetadata_Fragment = {
  __typename: "CustomConnectorConnectionMetadata";
  identifier: string;
  baseUrl: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
  supportsGroups: boolean;
  supportsNestedResources: boolean;
};

type ConnectionMetadataOutput_DuoConnectionMetadata_Fragment = {
  __typename: "DuoConnectionMetadata";
  apiHostname: string;
};

type ConnectionMetadataOutput_GcpConnectionMetadata_Fragment = {
  __typename: "GCPConnectionMetadata";
  pubSubProjectId: string;
  subscriptionId: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

type ConnectionMetadataOutput_GitHubConnectionMetadata_Fragment = {
  __typename: "GitHubConnectionMetadata";
  orgName: string;
};

type ConnectionMetadataOutput_GitLabConnectionMetadata_Fragment = {
  __typename: "GitLabConnectionMetadata";
  hostName?: string | null;
  appId: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
};

type ConnectionMetadataOutput_GoogleGroupsConnectionMetadata_Fragment = {
  __typename: "GoogleGroupsConnectionMetadata";
  opalGroupEmail: string;
  adminUserEmail: string;
  domain: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

type ConnectionMetadataOutput_GoogleWorkspaceConnectionMetadata_Fragment = {
  __typename: "GoogleWorkspaceConnectionMetadata";
  adminUserEmail: string;
  customerId: string;
  serviceAccount: {
    __typename?: "GCPServiceAccount";
    type: string;
    projectId: string;
    clientEmail: string;
    clientId: string;
    authUri: string;
    tokenUri: string;
    authProviderX509CertUrl: string;
    clientX509CertUrl: string;
  };
};

type ConnectionMetadataOutput_LdapConnectionMetadata_Fragment = {
  __typename: "LDAPConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  groupsDistinguishedName: string;
  usersDistinguishedName: string;
  groupsUidKey: string;
};

type ConnectionMetadataOutput_MongoAtlasConnectionMetadata_Fragment = {
  __typename: "MongoAtlasConnectionMetadata";
  serverHostname: string;
};

type ConnectionMetadataOutput_MongoConnectionMetadata_Fragment = {
  __typename: "MongoConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

type ConnectionMetadataOutput_MySqlMariaDbConnectionMetadata_Fragment = {
  __typename: "MySQLMariaDBConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

type ConnectionMetadataOutput_OktaDirectoryConnectionMetadata_Fragment = {
  __typename: "OktaDirectoryConnectionMetadata";
  orgUrl: string;
};

type ConnectionMetadataOutput_PagerDutyConnectionMetadata_Fragment = {
  __typename: "PagerDutyConnectionMetadata";
};

type ConnectionMetadataOutput_PostgresConnectionMetadata_Fragment = {
  __typename: "PostgresConnectionMetadata";
  serverHostname: string;
  serverPort: number;
  tlsMode: boolean;
  tlsCertContent?: string | null;
  tlsCaCertContent?: string | null;
};

type ConnectionMetadataOutput_PropagationTicketConnectionMetadata_Fragment = {
  __typename: "PropagationTicketConnectionMetadata";
  ticketProvider?: {
    __typename?: "TicketProvider";
    ticketProvider: ThirdPartyProvider;
    ticketProjectId: string;
    ticketProjectName: string;
  } | null;
};

type ConnectionMetadataOutput_SalesforceConnectionMetadata_Fragment = {
  __typename: "SalesforceConnectionMetadata";
  email: string;
  hostname: string;
};

type ConnectionMetadataOutput_SnowflakeConnectionMetadata_Fragment = {
  __typename: "SnowflakeConnectionMetadata";
  organization: string;
  account: string;
  locator: string;
  accountIdentifier: string;
  accountUrl: string;
  serviceAccountName: string;
  serviceAccountRole: string;
};

type ConnectionMetadataOutput_TailscaleConnectionMetadata_Fragment = {
  __typename: "TailscaleConnectionMetadata";
  tailnet: string;
};

type ConnectionMetadataOutput_TeleportConnectionMetadata_Fragment = {
  __typename: "TeleportConnectionMetadata";
  host: string;
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
};

type ConnectionMetadataOutput_WorkdayConnectionMetadata_Fragment = {
  __typename: "WorkdayConnectionMetadata";
  tenantName: string;
  tenantUrl: string;
};

export type ConnectionMetadataOutputFragment =
  | ConnectionMetadataOutput_AdConnectionMetadata_Fragment
  | ConnectionMetadataOutput_AwsConnectionMetadata_Fragment
  | ConnectionMetadataOutput_AwsssoConnectionMetadata_Fragment
  | ConnectionMetadataOutput_AzureAdConnectionMetadata_Fragment
  | ConnectionMetadataOutput_CustomConnectorConnectionMetadata_Fragment
  | ConnectionMetadataOutput_DuoConnectionMetadata_Fragment
  | ConnectionMetadataOutput_GcpConnectionMetadata_Fragment
  | ConnectionMetadataOutput_GitHubConnectionMetadata_Fragment
  | ConnectionMetadataOutput_GitLabConnectionMetadata_Fragment
  | ConnectionMetadataOutput_GoogleGroupsConnectionMetadata_Fragment
  | ConnectionMetadataOutput_GoogleWorkspaceConnectionMetadata_Fragment
  | ConnectionMetadataOutput_LdapConnectionMetadata_Fragment
  | ConnectionMetadataOutput_MongoAtlasConnectionMetadata_Fragment
  | ConnectionMetadataOutput_MongoConnectionMetadata_Fragment
  | ConnectionMetadataOutput_MySqlMariaDbConnectionMetadata_Fragment
  | ConnectionMetadataOutput_OktaDirectoryConnectionMetadata_Fragment
  | ConnectionMetadataOutput_PagerDutyConnectionMetadata_Fragment
  | ConnectionMetadataOutput_PostgresConnectionMetadata_Fragment
  | ConnectionMetadataOutput_PropagationTicketConnectionMetadata_Fragment
  | ConnectionMetadataOutput_SalesforceConnectionMetadata_Fragment
  | ConnectionMetadataOutput_SnowflakeConnectionMetadata_Fragment
  | ConnectionMetadataOutput_TailscaleConnectionMetadata_Fragment
  | ConnectionMetadataOutput_TeleportConnectionMetadata_Fragment
  | ConnectionMetadataOutput_WorkdayConnectionMetadata_Fragment;

export type ConnectionPreviewFragment = {
  __typename: "Connection";
  id: string;
  name: string;
  description: string;
  connectionType: ConnectionType;
  isDeleted: boolean;
  visibility: Visibility;
  importVisibility: Visibility;
  importSetting: ImportSetting;
  autoImportGroupResources: boolean;
  numUsers: number;
  webhookEnabled: boolean;
  iconUrl?: string | null;
  importVisibilityGroups: Array<{
    __typename?: "ConnectionVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup?: { __typename?: "Group"; name: string } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConnectionVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup?: { __typename?: "Group"; name: string } | null;
  }>;
  adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
  metadata?:
    | {
        __typename: "ADConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
      }
    | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
    | {
        __typename: "AWSSSOConnectionMetadata";
        managementAccountId: string;
        ssoInstanceArn: string;
        identityStoreId: string;
        accessPortalUrl: string;
        awsRegion: string;
        awsOrganizationEnabled: boolean;
        awsSsoEnabled: boolean;
        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
        awsOrganizationImportSetting: ImportSetting;
        ec2RoleChainingEnabled: boolean;
        podRoleChainingEnabled: boolean;
        cloudtrailEventsSqsUrl: string;
      }
    | {
        __typename: "AzureADConnectionMetadata";
        tenantId: string;
        clientId: string;
        azureInfraEnabled: boolean;
        eventHubNamespace: string;
        eventHub: string;
      }
    | {
        __typename: "CustomConnectorConnectionMetadata";
        identifier: string;
        baseUrl: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
        supportsGroups: boolean;
        supportsNestedResources: boolean;
      }
    | { __typename: "DuoConnectionMetadata"; apiHostname: string }
    | {
        __typename: "GCPConnectionMetadata";
        pubSubProjectId: string;
        subscriptionId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | { __typename: "GitHubConnectionMetadata"; orgName: string }
    | {
        __typename: "GitLabConnectionMetadata";
        hostName?: string | null;
        appId: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "GoogleGroupsConnectionMetadata";
        opalGroupEmail: string;
        adminUserEmail: string;
        domain: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "GoogleWorkspaceConnectionMetadata";
        adminUserEmail: string;
        customerId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "LDAPConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
        groupsUidKey: string;
      }
    | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
    | {
        __typename: "MongoConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "MySQLMariaDBConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
    | { __typename: "PagerDutyConnectionMetadata" }
    | {
        __typename: "PostgresConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "PropagationTicketConnectionMetadata";
        ticketProvider?: {
          __typename?: "TicketProvider";
          ticketProvider: ThirdPartyProvider;
          ticketProjectId: string;
          ticketProjectName: string;
        } | null;
      }
    | {
        __typename: "SalesforceConnectionMetadata";
        email: string;
        hostname: string;
      }
    | {
        __typename: "SnowflakeConnectionMetadata";
        organization: string;
        account: string;
        locator: string;
        accountIdentifier: string;
        accountUrl: string;
        serviceAccountName: string;
        serviceAccountRole: string;
      }
    | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
    | {
        __typename: "TeleportConnectionMetadata";
        host: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "WorkdayConnectionMetadata";
        tenantName: string;
        tenantUrl: string;
      }
    | null;
  childrenDefaultConfigTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  importNotificationOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
  } | null;
};

export type ConnectionsQueryVariables = Exact<{
  input: ConnectionsInput;
}>;

export type ConnectionsQuery = {
  __typename?: "Query";
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename: "Connection";
      id: string;
      name: string;
      description: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      visibility: Visibility;
      importVisibility: Visibility;
      importSetting: ImportSetting;
      autoImportGroupResources: boolean;
      numUsers: number;
      webhookEnabled: boolean;
      iconUrl?: string | null;
      adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
      visibilityGroups: Array<{
        __typename?: "ConnectionVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup?: { __typename?: "Group"; name: string } | null;
      }>;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
      childrenDefaultConfigTemplate?: {
        __typename?: "ConfigurationTemplate";
        id: string;
        name: string;
      } | null;
      importNotificationOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type ConnectionPreviewQueryVariables = Exact<{
  connectionId: Scalars["ConnectionId"];
  fetchCounts?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ConnectionPreviewQuery = {
  __typename?: "Query";
  connection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | {
        __typename: "ConnectionResult";
        connection: {
          __typename: "Connection";
          numVisibleResources?: number;
          numVisibleGroups?: number;
          id: string;
          name: string;
          description: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          visibility: Visibility;
          importVisibility: Visibility;
          importSetting: ImportSetting;
          autoImportGroupResources: boolean;
          numUsers: number;
          webhookEnabled: boolean;
          iconUrl?: string | null;
          importVisibilityGroups: Array<{
            __typename?: "ConnectionVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup?: { __typename?: "Group"; name: string } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConnectionVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup?: { __typename?: "Group"; name: string } | null;
          }>;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
          childrenDefaultConfigTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          importNotificationOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
        };
      };
};

export type CreateConnectionMutationVariables = Exact<{
  input: CreateConnectionInput;
}>;

export type CreateConnectionMutation = {
  __typename?: "Mutation";
  createConnection:
    | { __typename: "ConnectionBadMetadataError"; message: string }
    | { __typename: "ConnectionExistsError"; message: string }
    | {
        __typename: "CreateConnectionResult";
        connection: { __typename?: "Connection"; id: string; name: string };
      }
    | { __typename: "UserFacingError"; message: string };
};

export type CreateConnectionForIdpMutationVariables = Exact<{
  input: CreateConnectionForIdpInput;
}>;

export type CreateConnectionForIdpMutation = {
  __typename?: "Mutation";
  createConnectionForIdp:
    | { __typename: "ConnectionBadMetadataError"; message: string }
    | {
        __typename: "CreateIdpConnectionResult";
        idpConnection: {
          __typename: "IdpConnection";
          id: string;
          idpConnectionType: IdpConnectionType;
          customAttributesAllowlist: Array<string>;
          isDeleted: boolean;
          userAttributeImportMappings: Array<{
            __typename?: "IdpConnectionUserAttributeImportMapping";
            id: string;
            key: string;
            useAs: IdpConnectionUserAttributeUseAs;
          }>;
        };
      }
    | { __typename: "IdpConnectionExistsError"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type UpdateConnectionMutationVariables = Exact<{
  input: UpdateConnectionInput;
}>;

export type UpdateConnectionMutation = {
  __typename?: "Mutation";
  updateConnection:
    | { __typename: "ConnectionBadMetadataError"; message: string }
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "ConnectionVisibilityGroupNotFoundError"; message: string }
    | {
        __typename: "UpdateConnectionResult";
        connection: { __typename?: "Connection"; id: string; name: string };
      }
    | { __typename: "UserFacingError"; message: string };
};

export type DeleteConnectionMutationVariables = Exact<{
  input: DeleteConnectionInput;
}>;

export type DeleteConnectionMutation = {
  __typename?: "Mutation";
  deleteConnection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "DeleteConnectionResult"; id: string };
};

export type EventFilterFragment = {
  __typename?: "EventFilter";
  id: string;
  organizationId: string;
  name: string;
  creatorId: string;
  startDate?: string | null;
  endDate?: string | null;
  actorId?: string | null;
  eventTypes?: Array<EventType> | null;
  objectId?: string | null;
  createdAt: string;
  systemEvents?: boolean | null;
  apiTokenLabel?: string | null;
  apiTokenPreview?: string | null;
  creator: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  };
  actor?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type EventFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type EventFiltersQuery = {
  __typename?: "Query";
  eventFilters: {
    __typename: "EventFiltersResult";
    eventFilters: Array<{
      __typename?: "EventFilter";
      id: string;
      organizationId: string;
      name: string;
      creatorId: string;
      startDate?: string | null;
      endDate?: string | null;
      actorId?: string | null;
      eventTypes?: Array<EventType> | null;
      objectId?: string | null;
      createdAt: string;
      systemEvents?: boolean | null;
      apiTokenLabel?: string | null;
      apiTokenPreview?: string | null;
      creator: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      };
      actor?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type EventFilterQueryVariables = Exact<{
  input: EventFilterInput;
}>;

export type EventFilterQuery = {
  __typename?: "Query";
  eventFilter:
    | { __typename: "EventFilterNotFoundError"; message: string }
    | {
        __typename: "EventFilterResult";
        eventFilter: {
          __typename?: "EventFilter";
          id: string;
          organizationId: string;
          name: string;
          creatorId: string;
          startDate?: string | null;
          endDate?: string | null;
          actorId?: string | null;
          eventTypes?: Array<EventType> | null;
          objectId?: string | null;
          createdAt: string;
          systemEvents?: boolean | null;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          creator: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          };
          actor?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        };
      };
};

export type CreateEventFilterMutationVariables = Exact<{
  input: CreateEventFilterInput;
}>;

export type CreateEventFilterMutation = {
  __typename?: "Mutation";
  createEventFilter:
    | {
        __typename: "CreateEventFilterResult";
        eventFilter: {
          __typename?: "EventFilter";
          id: string;
          organizationId: string;
          name: string;
          creatorId: string;
          startDate?: string | null;
          endDate?: string | null;
          actorId?: string | null;
          eventTypes?: Array<EventType> | null;
          objectId?: string | null;
          createdAt: string;
          systemEvents?: boolean | null;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          creator: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          };
          actor?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        };
      }
    | { __typename: "EventFilterNotFoundError"; message: string }
    | { __typename: "InvalidEventFilterNameError"; message: string };
};

export type DeleteEventFilterMutationVariables = Exact<{
  input: DeleteEventFilterInput;
}>;

export type DeleteEventFilterMutation = {
  __typename?: "Mutation";
  deleteEventFilter:
    | {
        __typename: "DeleteEventFilterResult";
        deletedEventFilter: {
          __typename?: "EventFilter";
          id: string;
          organizationId: string;
          name: string;
          creatorId: string;
          startDate?: string | null;
          endDate?: string | null;
          actorId?: string | null;
          eventTypes?: Array<EventType> | null;
          objectId?: string | null;
          createdAt: string;
          systemEvents?: boolean | null;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          creator: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          };
          actor?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        };
      }
    | {
        __typename: "EventFilterInUseError";
        message: string;
        entities: Array<{
          __typename?: "ErrorEntity";
          name: string;
          id: {
            __typename?: "EntityIdTuple";
            entityId: string;
            entityType: EntityType;
          };
        }>;
      }
    | { __typename: "EventFilterNotFoundError"; message: string };
};

export type EntityDisplayInfoFragment = {
  __typename?: "EntityDisplayInfo";
  resourceType?: ResourceType | null;
  groupType?: GroupType | null;
  connectionType?: ConnectionType | null;
  avatarURL?: string | null;
  provider?: ThirdPartyProvider | null;
};

export type EventFragment = {
  __typename?: "Event";
  id: string;
  createdAt: string;
  actorUserId: string;
  actorIP?: string | null;
  actorUserAgent?: string | null;
  eventType: EventType;
  source: ConnectionType;
  apiTokenLabel?: string | null;
  apiTokenPreview?: string | null;
  actorUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  subEvents: Array<{
    __typename?: "SubEvent";
    id: string;
    createdAt: string;
    subEventType: EventType;
    objectName?: string | null;
    secondaryObjectName?: string | null;
    tertiaryObjectName?: string | null;
    object4Name?: string | null;
    metadata?: string | null;
    objectId: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    };
    object?: {
      __typename?: "EntityDisplayInfo";
      resourceType?: ResourceType | null;
      groupType?: GroupType | null;
      connectionType?: ConnectionType | null;
      avatarURL?: string | null;
      provider?: ThirdPartyProvider | null;
    } | null;
    secondaryObjectId?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
    secondaryObject?: {
      __typename?: "EntityDisplayInfo";
      resourceType?: ResourceType | null;
      groupType?: GroupType | null;
      connectionType?: ConnectionType | null;
      avatarURL?: string | null;
      provider?: ThirdPartyProvider | null;
    } | null;
    tertiaryObjectId?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
    tertiaryObject?: {
      __typename?: "EntityDisplayInfo";
      resourceType?: ResourceType | null;
      groupType?: GroupType | null;
      connectionType?: ConnectionType | null;
      avatarURL?: string | null;
      provider?: ThirdPartyProvider | null;
    } | null;
    object4Id?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
    object4?: {
      __typename?: "EntityDisplayInfo";
      resourceType?: ResourceType | null;
      groupType?: GroupType | null;
      connectionType?: ConnectionType | null;
      avatarURL?: string | null;
      provider?: ThirdPartyProvider | null;
    } | null;
  }>;
};

export type EventPreviewFragment = {
  __typename?: "Event";
  id: string;
  createdAt: string;
  actorUserId: string;
  actorIP?: string | null;
  actorUserAgent?: string | null;
  eventType: EventType;
  source: ConnectionType;
  apiTokenLabel?: string | null;
  apiTokenPreview?: string | null;
  actorUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type SubEventFragment = {
  __typename?: "SubEvent";
  id: string;
  createdAt: string;
  subEventType: EventType;
  objectName?: string | null;
  secondaryObjectName?: string | null;
  tertiaryObjectName?: string | null;
  object4Name?: string | null;
  metadata?: string | null;
  objectId: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  };
  object?: {
    __typename?: "EntityDisplayInfo";
    resourceType?: ResourceType | null;
    groupType?: GroupType | null;
    connectionType?: ConnectionType | null;
    avatarURL?: string | null;
    provider?: ThirdPartyProvider | null;
  } | null;
  secondaryObjectId?: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  } | null;
  secondaryObject?: {
    __typename?: "EntityDisplayInfo";
    resourceType?: ResourceType | null;
    groupType?: GroupType | null;
    connectionType?: ConnectionType | null;
    avatarURL?: string | null;
    provider?: ThirdPartyProvider | null;
  } | null;
  tertiaryObjectId?: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  } | null;
  tertiaryObject?: {
    __typename?: "EntityDisplayInfo";
    resourceType?: ResourceType | null;
    groupType?: GroupType | null;
    connectionType?: ConnectionType | null;
    avatarURL?: string | null;
    provider?: ThirdPartyProvider | null;
  } | null;
  object4Id?: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  } | null;
  object4?: {
    __typename?: "EntityDisplayInfo";
    resourceType?: ResourceType | null;
    groupType?: GroupType | null;
    connectionType?: ConnectionType | null;
    avatarURL?: string | null;
    provider?: ThirdPartyProvider | null;
  } | null;
};

export type EventsQueryVariables = Exact<{
  input: EventsInput;
}>;

export type EventsQuery = {
  __typename?: "Query";
  events:
    | {
        __typename: "EventsResult";
        cursor?: string | null;
        events: Array<{
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          actorUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }>;
      }
    | { __typename: "InvalidObjectIDFilterError" };
};

export type EventsFullQueryVariables = Exact<{
  input: EventsInput;
}>;

export type EventsFullQuery = {
  __typename?: "Query";
  events:
    | {
        __typename: "EventsResult";
        cursor?: string | null;
        events: Array<{
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          actorUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          subEvents: Array<{
            __typename?: "SubEvent";
            id: string;
            createdAt: string;
            subEventType: EventType;
            objectName?: string | null;
            secondaryObjectName?: string | null;
            tertiaryObjectName?: string | null;
            object4Name?: string | null;
            metadata?: string | null;
            objectId: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            };
            object?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            secondaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            secondaryObject?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            tertiaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            tertiaryObject?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            object4Id?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            object4?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
          }>;
        }>;
      }
    | { __typename: "InvalidObjectIDFilterError" };
};

export type EventQueryVariables = Exact<{
  input: EventInput;
}>;

export type EventQuery = {
  __typename?: "Query";
  event:
    | { __typename: "EventNotFoundError"; message: string }
    | {
        __typename: "EventResult";
        event: {
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          apiTokenLabel?: string | null;
          apiTokenPreview?: string | null;
          actorUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          subEvents: Array<{
            __typename?: "SubEvent";
            id: string;
            createdAt: string;
            subEventType: EventType;
            objectName?: string | null;
            secondaryObjectName?: string | null;
            tertiaryObjectName?: string | null;
            object4Name?: string | null;
            metadata?: string | null;
            objectId: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            };
            object?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            secondaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            secondaryObject?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            tertiaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            tertiaryObject?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
            object4Id?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            object4?: {
              __typename?: "EntityDisplayInfo";
              resourceType?: ResourceType | null;
              groupType?: GroupType | null;
              connectionType?: ConnectionType | null;
              avatarURL?: string | null;
              provider?: ThirdPartyProvider | null;
            } | null;
          }>;
        };
      };
};

export type CurrentUserGroupAccessTinyFragment = {
  __typename?: "CurrentUserGroupAccess";
  groupId: string;
  hasBreakGlass: boolean;
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  } | null;
  pendingRequest?: {
    __typename?: "Request";
    id: string;
    requesterId: string;
    targetUserId?: string | null;
    targetGroupId?: string | null;
    createdAt: string;
    updatedAt: string;
    status: RequestStatus;
  } | null;
};

export type CurrentUserGroupAccessFragment = {
  __typename?: "CurrentUserGroupAccess";
  groupId: string;
  hasBreakGlass: boolean;
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  } | null;
  pendingRequest?: {
    __typename?: "Request";
    id: string;
    requesterId: string;
    targetUserId?: string | null;
    targetGroupId?: string | null;
    resourceProposalId?: string | null;
    createdAt: string;
    updatedAt: string;
    status: RequestStatus;
    requester?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    targetUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    targetGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    requestedResources: Array<{
      __typename?: "RequestedResource";
      resourceId: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      targetUserAccess?: {
        __typename?: "UserResourceAccess";
        resourceId: string;
        userId: string;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
    requestedGroups: Array<{
      __typename?: "RequestedGroup";
      groupId: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      targetUserAccess?: {
        __typename?: "UserGroupAccess";
        groupId: string;
        userId: string;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
      } | null;
    }>;
    resourceProposal?: {
      __typename?: "ResourceProposal";
      id: string;
      connectionId: string;
      serviceType: ServiceType;
      resourceType: ResourceType;
      metadata?: {
        __typename: "AWSRoleProposalMetadata";
        policyName: string;
        policyDocument: string;
        policyDescription: string;
        assumeRolePolicyDocument: string;
        roleName: string;
        roleDescription: string;
        tags: Array<{
          __typename?: "AWSTag";
          key: string;
          value?: string | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UserGroupAccessFragment = {
  __typename?: "UserGroupAccess";
  groupId: string;
  userId: string;
  groupUser?: {
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  } | null;
};

export type GroupUserAccessFragment = {
  __typename?: "GroupUserAccess";
  groupId: string;
  userId: string;
  isGroupManaged: boolean;
  latestExpiringAccessPoint: {
    __typename?: "GroupUserAccessPoint";
    groupId: string;
    userId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    source: GroupUserSource;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
  directAccessPoint?: {
    __typename?: "GroupUserAccessPoint";
    groupId: string;
    userId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    source: GroupUserSource;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  indirectAccessPointPaths: Array<{
    __typename?: "IndirectGroupUserAccessPointPath";
    startGroupId: string;
    endGroupId: string;
    userId: string;
    resolvedAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    indirectAccessPointPath: Array<{
      __typename?: "IndirectGroupAccessPoint";
      groupId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
    }>;
  }>;
};

export type GroupUserAccessPointFragment = {
  __typename?: "GroupUserAccessPoint";
  groupId: string;
  userId: string;
  groupName: string;
  groupType: GroupType;
  eventId: string;
  createdAt: string;
  expiration?: string | null;
  supportTicketId?: string | null;
  source: GroupUserSource;
  accessLevel?: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  } | null;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type IndirectGroupUserAccessPointPathFragment = {
  __typename?: "IndirectGroupUserAccessPointPath";
  startGroupId: string;
  endGroupId: string;
  userId: string;
  resolvedAccessPoint: {
    __typename?: "GroupUserAccessPoint";
    groupId: string;
    userId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    source: GroupUserSource;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
  indirectAccessPointPath: Array<{
    __typename?: "IndirectGroupAccessPoint";
    groupId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
  }>;
};

export type GroupResourceAccessFragment = {
  __typename?: "GroupResourceAccess";
  groupId: string;
  resourceId: string;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  directAccessPoint?: {
    __typename?: "GroupResourceAccessPoint";
    groupId: string;
    resourceId: string;
    resourceName: string;
    resourceType: ResourceType;
    groupName: string;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  inheritedAccessPoints: Array<{
    __typename?: "GroupResourceAccessPoint";
    groupId: string;
    resourceId: string;
    resourceName: string;
    resourceType: ResourceType;
    groupName: string;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  }>;
  indirectAccessPointPaths: Array<{
    __typename?: "IndirectGroupResourceAccessPointPath";
    startGroupId: string;
    endGroupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    resolvedAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPath?: Array<{
      __typename?: "IndirectGroupAccessPoint";
      groupId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
    }> | null;
  }>;
  latestExpiringAccessPoint: {
    __typename?: "GroupResourceAccessPoint";
    groupId: string;
    resourceId: string;
    resourceName: string;
    resourceType: ResourceType;
    groupName: string;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
};

export type GroupResourceAccessPointFragment = {
  __typename?: "GroupResourceAccessPoint";
  groupId: string;
  resourceId: string;
  resourceName: string;
  resourceType: ResourceType;
  groupName: string;
  eventId: string;
  createdAt: string;
  expiration?: string | null;
  supportTicketId?: string | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type IndirectGroupResourceAccessPointPathFragment = {
  __typename?: "IndirectGroupResourceAccessPointPath";
  startGroupId: string;
  endGroupId: string;
  resourceId: string;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  resolvedAccessPoint?: {
    __typename?: "GroupResourceAccessPoint";
    groupId: string;
    resourceId: string;
    resourceName: string;
    resourceType: ResourceType;
    groupName: string;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  indirectAccessPointPath?: Array<{
    __typename?: "IndirectGroupAccessPoint";
    groupId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
  }> | null;
};

export type IndirectGroupAccessPointFragment = {
  __typename?: "IndirectGroupAccessPoint";
  groupId: string;
  groupName: string;
  groupType: GroupType;
  eventId: string;
  createdAt: string;
};

export type GroupGroupAccessFragment = {
  __typename?: "GroupGroupAccess";
  containingGroupId: string;
  memberGroupId: string;
  accessLevel: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  directAccessPoint?: {
    __typename?: "GroupGroupAccessPoint";
    containingGroupId: string;
    memberGroupId: string;
    containingGroupName: string;
    memberGroupName: string;
    memberGroupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  inheritedAccessPoints: Array<{
    __typename?: "GroupGroupAccessPoint";
    containingGroupId: string;
    memberGroupId: string;
    containingGroupName: string;
    memberGroupName: string;
    memberGroupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  }>;
  indirectAccessPointPaths: Array<{
    __typename?: "IndirectGroupGroupAccessPointPath";
    startGroupId: string;
    endGroupId: string;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    resolvedAccessPoint: {
      __typename?: "GroupGroupAccessPoint";
      containingGroupId: string;
      memberGroupId: string;
      containingGroupName: string;
      memberGroupName: string;
      memberGroupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    indirectAccessPointPath: Array<{
      __typename?: "IndirectGroupAccessPoint";
      groupId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
    }>;
  }>;
  latestExpiringAccessPoint: {
    __typename?: "GroupGroupAccessPoint";
    containingGroupId: string;
    memberGroupId: string;
    containingGroupName: string;
    memberGroupName: string;
    memberGroupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
};

export type GroupGroupQuickAccessFragment = {
  __typename?: "GroupGroupQuickAccess";
  numInheritedAccess: number;
  hasDirectAccess: boolean;
  expiration?: string | null;
  supportTicketId?: string | null;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type GroupGroupAccessPointFragment = {
  __typename?: "GroupGroupAccessPoint";
  containingGroupId: string;
  memberGroupId: string;
  containingGroupName: string;
  memberGroupName: string;
  memberGroupType: GroupType;
  eventId: string;
  createdAt: string;
  expiration?: string | null;
  supportTicketId?: string | null;
  accessLevel: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type IndirectGroupGroupAccessPointPathFragment = {
  __typename?: "IndirectGroupGroupAccessPointPath";
  startGroupId: string;
  endGroupId: string;
  accessLevel: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  resolvedAccessPoint: {
    __typename?: "GroupGroupAccessPoint";
    containingGroupId: string;
    memberGroupId: string;
    containingGroupName: string;
    memberGroupName: string;
    memberGroupType: GroupType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
  indirectAccessPointPath: Array<{
    __typename?: "IndirectGroupAccessPoint";
    groupId: string;
    groupName: string;
    groupType: GroupType;
    eventId: string;
    createdAt: string;
  }>;
};

export type GroupAccessLevelFragment = {
  __typename: "GroupAccessLevel";
  accessLevelName: string;
  accessLevelRemoteId: string;
};

export type GroupAccessLevelsQueryVariables = Exact<{
  input: GroupAccessLevelsInput;
}>;

export type GroupAccessLevelsQuery = {
  __typename?: "Query";
  groupAccessLevels:
    | {
        __typename: "GroupAccessLevelsResult";
        accessLevels?: Array<{
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        }> | null;
      }
    | { __typename: "GroupNotFoundError"; message: string };
};

export type MultipleGroupAccessLevelsQueryVariables = Exact<{
  input: MultipleGroupAccessLevelsInput;
}>;

export type MultipleGroupAccessLevelsQuery = {
  __typename?: "Query";
  multipleGroupAccessLevels:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "MultipleGroupAccessLevelsResult";
        results: Array<{
          __typename?: "GroupAccessLevelsResult";
          groupId: string;
          accessLevels?: Array<{
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          }> | null;
        }>;
      };
};

export type GroupBreakGlassUserFragment = {
  __typename: "GroupBreakGlassUser";
  userId: string;
  groupId: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type UseGroupBreakGlassMutationVariables = Exact<{
  input: UseGroupBreakGlassInput;
}>;

export type UseGroupBreakGlassMutation = {
  __typename?: "Mutation";
  useGroupBreakGlass:
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "GroupUserAlreadyExists"; message: string }
    | { __typename: "NoBreakGlassAccessError"; message: string }
    | {
        __typename: "UseGroupBreakGlassResult";
        group: {
          __typename: "Group";
          id: string;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          }>;
        };
      };
};

export type GroupGroupFragment = {
  __typename?: "GroupGroup";
  id: string;
  containingGroupId: string;
  memberGroupId: string;
  memberGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  containingGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  accessLevel: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  quickAccess?: {
    __typename?: "GroupGroupQuickAccess";
    numInheritedAccess: number;
    hasDirectAccess: boolean;
    expiration?: string | null;
    supportTicketId?: string | null;
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
};

export type AddGroupGroupsMutationVariables = Exact<{
  input: AddGroupGroupsInput;
}>;

export type AddGroupGroupsMutation = {
  __typename?: "Mutation";
  addGroupGroups:
    | { __typename: "AddGroupGroupsResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupGroupAlreadyExists"; message: string }
    | {
        __typename: "GroupNestingNotAllowedError";
        message: string;
        fromGroup?: { __typename?: "Group"; id: string; name: string } | null;
        toGroup?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type RemoveGroupGroupsMutationVariables = Exact<{
  input: RemoveGroupGroupsInput;
}>;

export type RemoveGroupGroupsMutation = {
  __typename?: "Mutation";
  removeGroupGroups:
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "RemoveGroupGroupsResult"; taskId: string }
    | { __typename: "UserFacingError"; message: string };
};

export type GroupResourcePreviewFragment = {
  __typename?: "GroupResource";
  groupId: string;
  resourceId: string;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  };
  propagationStatus?: {
    __typename?: "GroupResourcePropagationStatus";
    groupId: string;
    resourceId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type GroupResourceFragment = {
  __typename?: "GroupResource";
  groupId: string;
  resourceId: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  };
  propagationStatus?: {
    __typename?: "GroupResourcePropagationStatus";
    groupId: string;
    resourceId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type ResourceGroupFragment = {
  __typename?: "GroupResource";
  groupId: string;
  resourceId: string;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  };
  propagationStatus?: {
    __typename?: "GroupResourcePropagationStatus";
    groupId: string;
    resourceId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type AddGroupResourcesMutationVariables = Exact<{
  input: AddGroupResourcesInput;
}>;

export type AddGroupResourcesMutation = {
  __typename?: "Mutation";
  addGroupResources:
    | { __typename: "AddGroupResourcesResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "GroupResourceAlreadyExists"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type RemoveGroupResourcesMutationVariables = Exact<{
  input: RemoveGroupResourcesInput;
}>;

export type RemoveGroupResourcesMutation = {
  __typename?: "Mutation";
  removeGroupResources:
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "RemoveGroupResourcesResult"; taskId: string }
    | { __typename: "UserFacingError"; message: string };
};

export type GroupTagPreviewFragment = {
  __typename?: "GroupTag";
  groupId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  group?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type GroupTagFragment = {
  __typename?: "GroupTag";
  groupId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type AddGroupTagsMutationVariables = Exact<{
  input: AddGroupTagsInput;
}>;

export type AddGroupTagsMutation = {
  __typename?: "Mutation";
  addGroupTags:
    | {
        __typename: "AddGroupTagsResult";
        entries: Array<{
          __typename: "AddGroupTagsEntryResult";
          groupTag: { __typename?: "GroupTag"; groupId: string; tagId: string };
        }>;
      }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "TagNotFoundError"; message: string };
};

export type RemoveGroupTagsMutationVariables = Exact<{
  input: RemoveGroupTagsInput;
}>;

export type RemoveGroupTagsMutation = {
  __typename?: "Mutation";
  removeGroupTags: { __typename: "RemoveGroupTagsResult"; success: boolean };
};

export type GroupUserFragment = {
  __typename?: "GroupUser";
  groupId: string;
  userId: string;
  source: GroupUserSource;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  access?: {
    __typename?: "GroupUserAccess";
    groupId: string;
    userId: string;
    isGroupManaged: boolean;
    latestExpiringAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupUserAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      userId: string;
      resolvedAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      indirectAccessPointPath: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  } | null;
  accessLevel?: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  } | null;
};

export type GroupUserPreviewFragment = {
  __typename?: "GroupUser";
  groupId: string;
  userId: string;
  source: GroupUserSource;
  access?: {
    __typename?: "GroupUserAccess";
    groupId: string;
    userId: string;
    isGroupManaged: boolean;
    latestExpiringAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupUserAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      userId: string;
      resolvedAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      indirectAccessPointPath: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  } | null;
  accessLevel?: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  } | null;
};

export type UserGroupFragment = {
  __typename?: "GroupUser";
  groupId: string;
  userId: string;
  source: GroupUserSource;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  access?: {
    __typename?: "GroupUserAccess";
    groupId: string;
    userId: string;
    isGroupManaged: boolean;
    latestExpiringAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupUserAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      userId: string;
      resolvedAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      indirectAccessPointPath: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GroupUserUarPreviewFragment = {
  __typename?: "GroupUser";
  groupId: string;
  userId: string;
  source: GroupUserSource;
  access?: {
    __typename?: "GroupUserAccess";
    groupId: string;
    userId: string;
    isGroupManaged: boolean;
    latestExpiringAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupUserAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      userId: string;
      resolvedAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      indirectAccessPointPath: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  } | null;
};

export type AddGroupUsersMutationVariables = Exact<{
  input: AddGroupUsersInput;
}>;

export type AddGroupUsersMutation = {
  __typename?: "Mutation";
  addGroupUsers:
    | { __typename: "AddGroupUsersResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "CannotAddSystemUserToGroupError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "GroupUserAlreadyExists"; message: string };
};

export type UpdateGroupUsersMutationVariables = Exact<{
  input: UpdateGroupUsersInput;
}>;

export type UpdateGroupUsersMutation = {
  __typename?: "Mutation";
  updateGroupUsers:
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "UpdateGroupUsersResult"; taskIds: Array<string> };
};

export type RemoveGroupUsersMutationVariables = Exact<{
  input: RemoveGroupUsersInput;
}>;

export type RemoveGroupUsersMutation = {
  __typename?: "Mutation";
  removeGroupUsers:
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "RemoveGroupUsersResult"; taskId: string };
};

export type ForfeitGroupMutationVariables = Exact<{
  input: ForfeitGroupInput;
}>;

export type ForfeitGroupMutation = {
  __typename?: "Mutation";
  forfeitGroup:
    | { __typename: "ForfeitGroupResult"; taskId: string }
    | { __typename: "GroupNotFoundError"; message: string };
};

export type GroupVisibilityGroupFragment = {
  __typename?: "ConfigurationVisibilityGroup";
  visibilityGroupId: string;
  visibilityGroup: { __typename?: "Group"; name: string };
};

export type GroupPreviewTinyFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  connectionId: string;
};

export type GroupPreviewSmallFragment = {
  __typename?: "Group";
  id: string;
  remoteId?: string | null;
  name: string;
  remoteName: string;
  numGroupUsers: number;
  groupType: GroupType;
  serviceType: ServiceType;
  visibility: Visibility;
  isManaged: boolean;
  isDeleted: boolean;
  iconUrl?: string | null;
  isOnCallSynced: boolean;
  connectionId: string;
  groupBindingId?: string | null;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
  groupBinding?: {
    __typename?: "GroupBinding";
    id: string;
    sourceGroupId: string;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
    }>;
  } | null;
};

export type GroupPreviewWithAccessLevelsFragment = {
  __typename?: "Group";
  id: string;
  remoteId?: string | null;
  name: string;
  remoteName: string;
  numGroupUsers: number;
  groupType: GroupType;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  iconUrl?: string | null;
  isOnCallSynced: boolean;
  connectionId: string;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  accessLevels?: Array<{
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  }> | null;
};

export type GroupPreviewLargeFragment = {
  __typename: "Group";
  id: string;
  remoteId?: string | null;
  name: string;
  remoteName: string;
  groupType: GroupType;
  serviceType: ServiceType;
  iconUrl?: string | null;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  numGroupUsers: number;
  connectionId: string;
  isOnCallSynced: boolean;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  groupBindingId?: string | null;
  customRequestNotification?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserGroupAccess";
    groupId: string;
    hasBreakGlass: boolean;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
    pendingRequest?: {
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    } | null;
  };
  tags: Array<{
    __typename?: "GroupTag";
    groupId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    group?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  onCallSchedules?: Array<{
    __typename?: "GroupOnCallSchedule";
    onCallSchedule: {
      __typename?: "OnCallSchedule";
      remoteId: string;
      thirdPartyProvider: ThirdPartyProvider;
      name: string;
      existsInRemote: boolean;
    };
  }> | null;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  groupBinding?: {
    __typename?: "GroupBinding";
    id: string;
    sourceGroupId: string;
    createdAt: string;
    sourceGroup?: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    } | null;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    }>;
    createdByUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  groupLeaders: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  }>;
};

export type ReviewerStageFragment = {
  __typename?: "ReviewerStage";
  id: string;
  stage: number;
  requireManagerApproval: boolean;
  requireAdminApproval: boolean;
  operator: ReviewStageOperator;
  owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
};

export type GroupFragment = {
  __typename: "Group";
  id: string;
  remoteId?: string | null;
  name: string;
  remoteName: string;
  groupType: GroupType;
  iconUrl?: string | null;
  numGroupUsers: number;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  connectionId: string;
  isOnCallSynced: boolean;
  requireMfaToApprove: boolean;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  authorizedActions?: Array<string> | null;
  requestTemplateId?: string | null;
  customRequestNotification?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserGroupAccess";
    groupId: string;
    hasBreakGlass: boolean;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
    pendingRequest?: {
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    } | null;
  };
  groupResources: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    resource?: {
      __typename: "Resource";
      authorizedActions?: Array<string> | null;
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupGroup";
    id: string;
    containingGroupId: string;
    memberGroupId: string;
    containingGroup?: {
      __typename?: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    memberGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    quickAccess?: {
      __typename?: "GroupGroupQuickAccess";
      numInheritedAccess: number;
      hasDirectAccess: boolean;
      expiration?: string | null;
      supportTicketId?: string | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  }>;
  memberGroups: Array<{
    __typename?: "GroupGroup";
    id: string;
    containingGroupId: string;
    memberGroupId: string;
    memberGroup?: {
      __typename?: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    containingGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    quickAccess?: {
      __typename?: "GroupGroupQuickAccess";
      numInheritedAccess: number;
      hasDirectAccess: boolean;
      expiration?: string | null;
      supportTicketId?: string | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  }>;
  groupUsers: Array<{
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  }>;
  breakGlassUsers: Array<{
    __typename: "GroupBreakGlassUser";
    userId: string;
    groupId: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
  onCallSchedules?: Array<{
    __typename?: "GroupOnCallSchedule";
    onCallSchedule: {
      __typename?: "OnCallSchedule";
      remoteId: string;
      thirdPartyProvider: ThirdPartyProvider;
      name: string;
      existsInRemote: boolean;
    };
  }> | null;
  auditMessageChannels?: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }> | null;
  reviewerMessageChannel?: {
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  } | null;
  tags: Array<{
    __typename?: "GroupTag";
    groupId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    group?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  reviewerStages: Array<{
    __typename?: "ReviewerStage";
    id: string;
    stage: number;
    requireManagerApproval: boolean;
    requireAdminApproval: boolean;
    operator: ReviewStageOperator;
    owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
  }>;
  requestConfigs: Array<{
    __typename?: "RequestConfiguration";
    id: string;
    priority: number;
    groupId?: string | null;
    groupIds?: Array<string> | null;
    roleIds?: Array<string> | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    reviewerStages: Array<{
      __typename?: "ReviewerStage";
      id: string;
      stage: number;
      requireManagerApproval: boolean;
      requireAdminApproval: boolean;
      operator: ReviewStageOperator;
      owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
    }>;
  }>;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  configTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  groupBinding?: {
    __typename?: "GroupBinding";
    id: string;
    sourceGroupId: string;
    createdAt: string;
    sourceGroup?: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    } | null;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    }>;
    createdByUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  groupLeaders: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  }>;
};

export type RemoteGroupFragment = {
  __typename: "RemoteGroup";
  id: string;
  name: string;
  metadata: string;
  iconUrl?: string | null;
  groupType: GroupType;
};

export type GroupsHomeQueryVariables = Exact<{
  input: GroupsInput;
}>;

export type GroupsHomeQuery = {
  __typename?: "Query";
  groups: {
    __typename: "GroupsResult";
    cursor?: string | null;
    totalNumGroups: number;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
    }>;
  };
};

export type GroupQueryVariables = Exact<{
  input: GroupInput;
}>;

export type GroupQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          groupType: GroupType;
          iconUrl?: string | null;
          numGroupUsers: number;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          updatedAt: string;
          description: string;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          connectionId: string;
          isOnCallSynced: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          authorizedActions?: Array<string> | null;
          requestTemplateId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          groupResources: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            resource?: {
              __typename: "Resource";
              authorizedActions?: Array<string> | null;
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            containingGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            memberGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          memberGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            memberGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            containingGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          }>;
          breakGlassUsers: Array<{
            __typename: "GroupBreakGlassUser";
            userId: string;
            groupId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          reviewerMessageChannel?: {
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          } | null;
          tags: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          groupLeaders: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        };
      };
};

export type GroupPreviewQueryVariables = Exact<{
  input: GroupInput;
}>;

export type GroupPreviewQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        };
      };
};

export type GroupPreviewWithAccessLevelsQueryVariables = Exact<{
  input: GroupInput;
}>;

export type GroupPreviewWithAccessLevelsQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          accessLevels?: Array<{
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          }> | null;
        };
      };
};

export type FilteredGroupsLargeQueryVariables = Exact<{
  input: FilteredGroupsInput;
}>;

export type FilteredGroupsLargeQuery = {
  __typename?: "Query";
  filteredGroups: {
    __typename: "FilteredGroupsResult";
    cursor?: string | null;
    groups: Array<{
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    }>;
  };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: "Mutation";
  createGroup:
    | {
        __typename: "CreateGroupResult";
        group: {
          __typename: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          groupType: GroupType;
          iconUrl?: string | null;
          numGroupUsers: number;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          updatedAt: string;
          description: string;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          connectionId: string;
          isOnCallSynced: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          authorizedActions?: Array<string> | null;
          requestTemplateId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          groupResources: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            resource?: {
              __typename: "Resource";
              authorizedActions?: Array<string> | null;
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            containingGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            memberGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          memberGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            memberGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            containingGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          }>;
          breakGlassUsers: Array<{
            __typename: "GroupBreakGlassUser";
            userId: string;
            groupId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          reviewerMessageChannel?: {
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          } | null;
          tags: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          groupLeaders: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        };
      }
    | { __typename: "GoogleGroupError"; message: string }
    | { __typename: "OktaGroupError"; message: string };
};

export type UpdateGroupMutationVariables = Exact<{
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = {
  __typename?: "Mutation";
  updateGroup:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "UpdateGroupResult";
        group: {
          __typename: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          groupType: GroupType;
          iconUrl?: string | null;
          numGroupUsers: number;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          updatedAt: string;
          description: string;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          connectionId: string;
          isOnCallSynced: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          authorizedActions?: Array<string> | null;
          requestTemplateId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          groupResources: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            resource?: {
              __typename: "Resource";
              authorizedActions?: Array<string> | null;
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            containingGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            memberGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          memberGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            memberGroup?: {
              __typename?: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            containingGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          }>;
          breakGlassUsers: Array<{
            __typename: "GroupBreakGlassUser";
            userId: string;
            groupId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          reviewerMessageChannel?: {
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          } | null;
          tags: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          groupLeaders: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        };
      };
};

export type DeleteGroupMutationVariables = Exact<{
  input: DeleteGroupInput;
}>;

export type DeleteGroupMutation = {
  __typename?: "Mutation";
  deleteGroup:
    | { __typename: "DeleteGroupResult"; success: boolean }
    | { __typename: "GroupHasChildrenError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string };
};

export type DeleteGroupsMutationVariables = Exact<{
  input: DeleteGroupsInput;
}>;

export type DeleteGroupsMutation = {
  __typename?: "Mutation";
  deleteGroups:
    | { __typename: "DeleteGroupsResult"; success: boolean }
    | { __typename: "GroupHasChildrenError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string };
};

export type RecordGroupViewMutationVariables = Exact<{
  input: RecordGroupViewInput;
}>;

export type RecordGroupViewMutation = {
  __typename?: "Mutation";
  recordGroupView: { __typename: "RecordGroupViewResult"; success: boolean };
};

export type IdpConnectionFragment = {
  __typename: "IdpConnection";
  id: string;
  idpConnectionType: IdpConnectionType;
  customAttributesAllowlist: Array<string>;
  isDeleted: boolean;
  userAttributeImportMappings: Array<{
    __typename?: "IdpConnectionUserAttributeImportMapping";
    id: string;
    key: string;
    useAs: IdpConnectionUserAttributeUseAs;
  }>;
};

export type IdpConnectionQueryVariables = Exact<{ [key: string]: never }>;

export type IdpConnectionQuery = {
  __typename?: "Query";
  idpConnection:
    | { __typename: "IdpConnectionNotFoundError"; message: string }
    | {
        __typename: "IdpConnectionResult";
        idpConnection?: {
          __typename: "IdpConnection";
          id: string;
          idpConnectionType: IdpConnectionType;
          customAttributesAllowlist: Array<string>;
          isDeleted: boolean;
          userAttributeImportMappings: Array<{
            __typename?: "IdpConnectionUserAttributeImportMapping";
            id: string;
            key: string;
            useAs: IdpConnectionUserAttributeUseAs;
          }>;
        } | null;
      };
};

export type CreateIdpConnectionMutationVariables = Exact<{
  input: CreateIdpConnectionInput;
}>;

export type CreateIdpConnectionMutation = {
  __typename?: "Mutation";
  createIdpConnection:
    | { __typename: "ConnectionBadMetadataError"; message: string }
    | {
        __typename: "CreateIdpConnectionResult";
        idpConnection: {
          __typename: "IdpConnection";
          id: string;
          idpConnectionType: IdpConnectionType;
          customAttributesAllowlist: Array<string>;
          isDeleted: boolean;
          userAttributeImportMappings: Array<{
            __typename?: "IdpConnectionUserAttributeImportMapping";
            id: string;
            key: string;
            useAs: IdpConnectionUserAttributeUseAs;
          }>;
        };
      }
    | { __typename: "IdpConnectionExistsError"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type CreateIdpConnectionUserAttributeImportMappingMutationVariables = Exact<{
  input: CreateIdpConnectionUserAttributeImportMappingInput;
}>;

export type CreateIdpConnectionUserAttributeImportMappingMutation = {
  __typename?: "Mutation";
  createIdpConnectionUserAttributeImportMapping:
    | { __typename: "CreateIdpConnectionUserAttributeImportMappingResult" }
    | { __typename: "IdpConnectionNotFoundError"; message: string };
};

export type DeleteIdpConnectionUserAttributeImportMappingMutationVariables = Exact<{
  input: DeleteIdpConnectionUserAttributeImportMappingInput;
}>;

export type DeleteIdpConnectionUserAttributeImportMappingMutation = {
  __typename?: "Mutation";
  deleteIdpConnectionUserAttributeImportMapping:
    | { __typename: "DeleteIdpConnectionUserAttributeImportMappingResult" }
    | {
        __typename: "IdpConnectionUserAttributeImportMappingNotFoundError";
        message: string;
      };
};

export type DeleteIdpConnectionMutationVariables = Exact<{
  input: DeleteIdpConnectionInput;
}>;

export type DeleteIdpConnectionMutation = {
  __typename?: "Mutation";
  deleteIdpConnection:
    | { __typename: "DeleteIdpConnectionResult"; id: string }
    | { __typename: "IdpConnectionNotFoundError"; message: string };
};

export type MessageChannelFragment = {
  __typename?: "MessageChannel";
  id: string;
  remoteId: string;
  thirdPartyProvider: ThirdPartyProvider;
  isPrivate: boolean;
  name: string;
};

export type MessageChannelsQueryVariables = Exact<{
  input: MessageChannelsInput;
}>;

export type MessageChannelsQuery = {
  __typename?: "Query";
  messageChannels:
    | { __typename: "MessageChannelMissingOAuthScopeError"; message: string }
    | { __typename: "MessageChannelRateLimitedError"; message: string }
    | {
        __typename: "MessageChannelsResult";
        generatedChannelName?: string | null;
        messageChannels: Array<{
          __typename?: "MessageChannel";
          id: string;
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          isPrivate: boolean;
          name: string;
        }>;
      };
};

export type CreateRemoteMessageChannelMutationVariables = Exact<{
  input: CreateRemoteMessageChannelInput;
}>;

export type CreateRemoteMessageChannelMutation = {
  __typename?: "Mutation";
  createRemoteMessageChannel:
    | {
        __typename: "CreateRemoteMessageChannelResult";
        messageChannel: {
          __typename?: "MessageChannel";
          id: string;
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          isPrivate: boolean;
          name: string;
        };
      }
    | { __typename: "MessageChannelInvalidNameError"; message: string }
    | { __typename: "MessageChannelMissingOAuthScopeError"; message: string }
    | { __typename: "MessageChannelNameTakenError"; message: string }
    | { __typename: "MessageChannelUserNotInWorkspaceError"; message: string };
};

export type LinkExistingRemoteMessageChannelMutationVariables = Exact<{
  input: LinkExistingRemoteMessageChannelInput;
}>;

export type LinkExistingRemoteMessageChannelMutation = {
  __typename?: "Mutation";
  linkExistingRemoteMessageChannel:
    | {
        __typename: "LinkExistingRemoteMessageChannelResult";
        messageChannel: {
          __typename?: "MessageChannel";
          id: string;
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          isPrivate: boolean;
          name: string;
        };
      }
    | { __typename: "MessageChannelMissingOAuthScopeError"; message: string }
    | { __typename: "RemoteMessageChannelAlreadyLinked"; message: string }
    | { __typename: "RemoteMessageChannelNotFound"; message: string };
};

export type FactorFragment = {
  __typename?: "Factor";
  factorType: FactorType;
  factorRemoteId: string;
};

export type ListFactorsQueryVariables = Exact<{ [key: string]: never }>;

export type ListFactorsQuery = {
  __typename?: "Query";
  listFactors:
    | { __typename: "ConnectionUserNotFound"; message: string }
    | {
        __typename: "ListFactorsResult";
        factors: Array<{
          __typename?: "Factor";
          factorType: FactorType;
          factorRemoteId: string;
        }>;
      }
    | { __typename: "MissingTokenPermissionError"; message: string }
    | { __typename: "NoIDPConfiguredError"; message: string };
};

export type VerifyFactorSyncMutationVariables = Exact<{
  input: VerifyFactorSyncInput;
}>;

export type VerifyFactorSyncMutation = {
  __typename?: "Mutation";
  verifyFactorSync:
    | { __typename: "ConnectionUserNotFound"; message: string }
    | { __typename: "FactorNotFoundError"; message: string }
    | { __typename: "MissingTokenPermissionError"; message: string }
    | { __typename: "VerifyFactorSyncResult"; status: VerifyFactorStatus };
};

export type VerifyFactorAsyncMutationVariables = Exact<{
  input: VerifyFactorAsyncInput;
}>;

export type VerifyFactorAsyncMutation = {
  __typename?: "Mutation";
  verifyFactorAsync:
    | { __typename: "ConnectionUserNotFound"; message: string }
    | { __typename: "FactorNotFoundError"; message: string }
    | { __typename: "MissingTokenPermissionError"; message: string }
    | {
        __typename: "VerifyFactorAsyncResult";
        status: VerifyFactorStatus;
        transactionId: string;
      };
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: "Query";
  uiNotifications: Array<{
    __typename?: "UINotification";
    id: string;
    createdAt: string;
    body: string;
    url: string;
    readAt?: string | null;
    trackingId?: string | null;
  }>;
};

export type ReadUiNotificationMutationVariables = Exact<{
  input: ReadUiNotificationInput;
}>;

export type ReadUiNotificationMutation = {
  __typename?: "Mutation";
  readUINotification: {
    __typename?: "ReadUINotificationResult";
    success: boolean;
  };
};

export type ReadUiNotificationsMutationVariables = Exact<{
  input: ReadUiNotificationsInput;
}>;

export type ReadUiNotificationsMutation = {
  __typename?: "Mutation";
  readUINotifications: {
    __typename?: "ReadUINotificationsResult";
    success: boolean;
  };
};

export type OnCallScheduleFragment = {
  __typename?: "OnCallSchedule";
  remoteId: string;
  thirdPartyProvider: ThirdPartyProvider;
  name: string;
  existsInRemote: boolean;
};

export type GroupOnCallScheduleFragment = {
  __typename?: "GroupOnCallSchedule";
  onCallSchedule: {
    __typename?: "OnCallSchedule";
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    name: string;
    existsInRemote: boolean;
  };
};

export type OnCallSchedulesQueryVariables = Exact<{ [key: string]: never }>;

export type OnCallSchedulesQuery = {
  __typename?: "Query";
  onCallSchedules:
    | {
        __typename: "OnCallSchedulesResult";
        onCallSchedules: Array<{
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        }>;
      }
    | { __typename: "UserFacingError"; message: string };
};

export type OrganizationSettingsFragment = {
  __typename?: "OrganizationSettings";
  id: string;
  createdAt: string;
  generalSettings: Array<GeneralSettingType>;
  authSessionExpirationInMinutes: number;
  maxResourceDurationInMinutes?: number | null;
  maxGroupDurationInMinutes?: number | null;
  isRemoteReadOnly?: boolean | null;
  dryRunModeEnabled?: boolean | null;
  errorNotificationSetting: ErrorNotificationSettingType;
  syncErrorHandlerOwnerId?: string | null;
  accessExpiringNotifications?: Array<number> | null;
  customerSupportExpiresAt: string;
  customerSupportGatingAllowed: boolean;
  roleCreationReviewerOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
  } | null;
  auditTicketProvider?: {
    __typename?: "TicketProvider";
    ticketProvider: ThirdPartyProvider;
    ticketProjectId: string;
    ticketProjectName: string;
  } | null;
};

export type OrganizationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationSettingsQuery = {
  __typename?: "Query";
  organizationSettings:
    | { __typename: "OrganizationSettingsNotFoundError" }
    | {
        __typename: "OrganizationSettingsResult";
        settings: {
          __typename?: "OrganizationSettings";
          id: string;
          createdAt: string;
          generalSettings: Array<GeneralSettingType>;
          authSessionExpirationInMinutes: number;
          maxResourceDurationInMinutes?: number | null;
          maxGroupDurationInMinutes?: number | null;
          isRemoteReadOnly?: boolean | null;
          dryRunModeEnabled?: boolean | null;
          errorNotificationSetting: ErrorNotificationSettingType;
          syncErrorHandlerOwnerId?: string | null;
          accessExpiringNotifications?: Array<number> | null;
          customerSupportExpiresAt: string;
          customerSupportGatingAllowed: boolean;
          roleCreationReviewerOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
          auditTicketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        };
      };
};

export type UpdateOrganizationSettingsMutationVariables = Exact<{
  input: UpdateOrganizationSettingsInput;
}>;

export type UpdateOrganizationSettingsMutation = {
  __typename?: "Mutation";
  updateOrganizationSettings:
    | { __typename: "AccessExpiringNotificationsInvalidError"; message: string }
    | { __typename: "AccessRequestEscalationPeriodInvalidError" }
    | { __typename: "AuthSessionExpirationInvalidError" }
    | { __typename: "CustomerSupportSettingsInvalid"; message: string }
    | { __typename: "OidcIssuerNotValidError"; message: string }
    | { __typename: "OrganizationSettingsNotFoundError" }
    | { __typename: "OrgMaxGroupDurationInvalidError"; message: string }
    | { __typename: "OrgMaxResourceDurationInvalidError"; message: string }
    | {
        __typename: "UpdateOrganizationSettingsResult";
        settings: {
          __typename?: "OrganizationSettings";
          id: string;
          createdAt: string;
          generalSettings: Array<GeneralSettingType>;
          authSessionExpirationInMinutes: number;
          maxResourceDurationInMinutes?: number | null;
          maxGroupDurationInMinutes?: number | null;
          isRemoteReadOnly?: boolean | null;
          dryRunModeEnabled?: boolean | null;
          errorNotificationSetting: ErrorNotificationSettingType;
          syncErrorHandlerOwnerId?: string | null;
          accessExpiringNotifications?: Array<number> | null;
          customerSupportExpiresAt: string;
          customerSupportGatingAllowed: boolean;
          roleCreationReviewerOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
          auditTicketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        };
      };
};

export type OrganizationWebhookInfoFragment = {
  __typename?: "OrganizationWebhookInfo";
  id: string;
  webhookEndpointUrl: string;
  enableWebhook: boolean;
  signingSecretPreview?: string | null;
  signingSecret?: string | null;
};

export type WebhookInfoQueryVariables = Exact<{ [key: string]: never }>;

export type WebhookInfoQuery = {
  __typename?: "Query";
  webhookInfo: {
    __typename: "WebhookInfoResult";
    webhookInfo?: {
      __typename?: "OrganizationWebhookInfo";
      id: string;
      webhookEndpointUrl: string;
      enableWebhook: boolean;
      signingSecretPreview?: string | null;
      signingSecret?: string | null;
    } | null;
  };
};

export type CreateWebhookInfoMutationVariables = Exact<{
  input: CreateWebhookInfoInput;
}>;

export type CreateWebhookInfoMutation = {
  __typename?: "Mutation";
  createWebhookInfo: {
    __typename: "CreateWebhookInfoResult";
    webhookInfo: {
      __typename?: "OrganizationWebhookInfo";
      id: string;
      webhookEndpointUrl: string;
      enableWebhook: boolean;
      signingSecretPreview?: string | null;
      signingSecret?: string | null;
    };
  };
};

export type UpdateWebhookInfoMutationVariables = Exact<{
  input: UpdateWebhookInfoInput;
}>;

export type UpdateWebhookInfoMutation = {
  __typename?: "Mutation";
  updateWebhookInfo: {
    __typename: "UpdateWebhookInfoResult";
    webhookInfo: {
      __typename?: "OrganizationWebhookInfo";
      id: string;
      webhookEndpointUrl: string;
      enableWebhook: boolean;
      signingSecretPreview?: string | null;
      signingSecret?: string | null;
    };
  };
};

export type RegenerateSigningSecretMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RegenerateSigningSecretMutation = {
  __typename?: "Mutation";
  regenerateSigningSecret: {
    __typename: "RegenerateSigningSecretResult";
    webhookInfo: {
      __typename?: "OrganizationWebhookInfo";
      id: string;
      webhookEndpointUrl: string;
      enableWebhook: boolean;
      signingSecretPreview?: string | null;
      signingSecret?: string | null;
    };
  };
};

export type DeleteWebhookInfoMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteWebhookInfoMutation = {
  __typename?: "Mutation";
  deleteWebhookInfo: {
    __typename: "DeleteWebhookInfoResult";
    success: boolean;
  };
};

export type OrganizationFragment = {
  __typename?: "Organization";
  id: string;
  name: string;
  createdAt: string;
};

export type OrganizationSamlInfoFragment = {
  __typename?: "OrganizationSamlInfo";
  id: string;
  email: string;
  endpoint: string;
  certExpiration: string;
  restrictLoginToSaml: boolean;
  samlBreakGlassUsers: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  }>;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: "Mutation";
  createOrganization:
    | {
        __typename: "CreateOrganizationResult";
        organization: {
          __typename?: "Organization";
          id: string;
          name: string;
          createdAt: string;
        };
      }
    | { __typename: "OrganizationExistsError"; message: string };
};

export type DeleteOrganizationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteOrganizationMutation = {
  __typename?: "Mutation";
  deleteOrganization: {
    __typename: "DeleteOrganizationResult";
    success?: boolean | null;
  };
};

export type OwnerUserFragment = {
  __typename?: "OwnerUser";
  reviewerPriority: number;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type OwnerPreviewSmallFragment = {
  __typename?: "Owner";
  id: string;
  name: string;
  sourceGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
  } | null;
};

export type OwnerFragment = {
  __typename: "Owner";
  id: string;
  name: string;
  description: string;
  accessRequestEscalationPeriodInMinutes?: number | null;
  reviewerMessageChannel?: {
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  } | null;
  sourceGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  ownerUsers: Array<{
    __typename?: "OwnerUser";
    reviewerPriority: number;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
  ownedResources: Array<{
    __typename?: "OwnedResource";
    isReviewer: boolean;
    isAdmin: boolean;
    resource: {
      __typename?: "Resource";
      id: string;
      name: string;
      iconUrl?: string | null;
      resourceType: ResourceType;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    };
  }>;
  ownedGroups: Array<{
    __typename?: "OwnedGroup";
    isReviewer: boolean;
    isAdmin: boolean;
    group: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      isOnCallSynced: boolean;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    };
  }>;
  ownedConfigurationTemplates: Array<{
    __typename?: "OwnedConfigurationTemplate";
    isReviewer: boolean;
    isAdmin: boolean;
    configurationTemplate: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    };
  }>;
};

export type OwnedResourceFragment = {
  __typename?: "OwnedResource";
  isReviewer: boolean;
  isAdmin: boolean;
  resource: {
    __typename?: "Resource";
    id: string;
    name: string;
    iconUrl?: string | null;
    resourceType: ResourceType;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  };
};

export type OwnedGroupFragment = {
  __typename?: "OwnedGroup";
  isReviewer: boolean;
  isAdmin: boolean;
  group: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    isOnCallSynced: boolean;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  };
};

export type OwnedConfigurationTemplateFragment = {
  __typename?: "OwnedConfigurationTemplate";
  isReviewer: boolean;
  isAdmin: boolean;
  configurationTemplate: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  };
};

export type OwnersListQueryVariables = Exact<{
  input: OwnersInput;
}>;

export type OwnersListQuery = {
  __typename?: "Query";
  owners: {
    __typename: "OwnersResult";
    totalNumOwners: number;
    cursor?: string | null;
    owners: Array<{
      __typename?: "Owner";
      id: string;
      name: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
      } | null;
    }>;
  };
};

export type OwnerQueryVariables = Exact<{
  input: OwnerInput;
}>;

export type OwnerQuery = {
  __typename?: "Query";
  owner:
    | { __typename: "OwnerNotFoundError" }
    | {
        __typename: "OwnerResult";
        owner: {
          __typename: "Owner";
          id: string;
          name: string;
          description: string;
          accessRequestEscalationPeriodInMinutes?: number | null;
          reviewerMessageChannel?: {
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          } | null;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            reviewerPriority: number;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          ownedResources: Array<{
            __typename?: "OwnedResource";
            isReviewer: boolean;
            isAdmin: boolean;
            resource: {
              __typename?: "Resource";
              id: string;
              name: string;
              iconUrl?: string | null;
              resourceType: ResourceType;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            };
          }>;
          ownedGroups: Array<{
            __typename?: "OwnedGroup";
            isReviewer: boolean;
            isAdmin: boolean;
            group: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              isOnCallSynced: boolean;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            };
          }>;
          ownedConfigurationTemplates: Array<{
            __typename?: "OwnedConfigurationTemplate";
            isReviewer: boolean;
            isAdmin: boolean;
            configurationTemplate: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            };
          }>;
        };
      };
};

export type CreateOwnerMutationVariables = Exact<{
  input: CreateOwnerInput;
}>;

export type CreateOwnerMutation = {
  __typename?: "Mutation";
  createOwner:
    | {
        __typename: "CannotAddUserToSyncedOwnerError";
        message: string;
        group?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | {
        __typename: "CreateOwnerResult";
        owner: {
          __typename?: "Owner";
          id: string;
          name: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
          } | null;
        };
      }
    | { __typename: "GroupNotFoundError" }
    | { __typename: "MessageChannelNotFoundError" }
    | { __typename: "OwnerNameAlreadyExists"; message: string };
};

export type UpdateOwnerMutationVariables = Exact<{
  input: UpdateOwnerInput;
}>;

export type UpdateOwnerMutation = {
  __typename?: "Mutation";
  updateOwner:
    | {
        __typename: "CannotAddUserToSyncedOwnerError";
        message: string;
        group?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | { __typename: "GroupNotFoundError" }
    | { __typename: "MessageChannelNotFoundError" }
    | { __typename: "OwnerAccessRequestFrequencyReminderOutOfBoundsError" }
    | { __typename: "OwnerNotFoundError" }
    | {
        __typename: "UpdateOwnerResult";
        owner: {
          __typename?: "Owner";
          id: string;
          name: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
          } | null;
        };
      };
};

export type DeleteOwnerMutationVariables = Exact<{
  input: DeleteOwnerInput;
}>;

export type DeleteOwnerMutation = {
  __typename?: "Mutation";
  deleteOwner:
    | {
        __typename: "DeleteOwnerResult";
        owner: {
          __typename?: "Owner";
          id: string;
          name: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
          } | null;
        };
      }
    | { __typename: "OwnerNotFoundError" }
    | {
        __typename: "OwnerOwnsItemsError";
        message: string;
        entities: Array<{ __typename?: "ErrorEntity"; name: string }>;
      }
    | {
        __typename: "OwnerReviewsItemsError";
        message: string;
        entities: Array<{ __typename?: "ErrorEntity"; name: string }>;
      };
};

export type UpdateOwnerUsersForOwnerMutationVariables = Exact<{
  input: SetOwnerUsersInput;
}>;

export type UpdateOwnerUsersForOwnerMutation = {
  __typename?: "Mutation";
  setOwnerUsers:
    | { __typename: "CannotAddSystemUserToOwnerError" }
    | {
        __typename: "CannotAddUserToSyncedOwnerError";
        message: string;
        group?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | { __typename: "OwnerNotFoundError" }
    | {
        __typename: "UpdateOwnerUsersForOwnerResult";
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          reviewerPriority: number;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }>;
      }
    | { __typename: "UserNotFoundError" };
};

export type PushTaskPendingFragment = {
  __typename?: "PushTaskPending";
  id: string;
  taskType: PropagationTaskType;
  nextProcessAt?: string | null;
};

export type PushTaskCompleteFragment = {
  __typename?: "PushTaskComplete";
  id: string;
  taskType: PropagationTaskType;
  result:
    | {
        __typename: "ConnectionUserPushTaskResult";
        propStatuses: Array<{
          __typename?: "ConnectionUserPropagationStatus";
          connectionId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
        }>;
      }
    | {
        __typename: "GroupGroupPushTaskResult";
        propStatuses: Array<{
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        }>;
      }
    | {
        __typename: "GroupResourcePushTaskResult";
        propStatuses: Array<{
          __typename?: "GroupResourcePropagationStatus";
          groupId: string;
          resourceId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
        }>;
      }
    | {
        __typename: "GroupUserPushTaskResult";
        propStatuses: Array<{
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        }>;
      }
    | {
        __typename: "ResourceUserPushTaskResult";
        propStatuses: Array<{
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        }>;
      }
    | {
        __typename: "RoleAssignmentPushTaskResult";
        propStatuses: Array<{
          __typename?: "PropagationStatus";
          roleAssignmentId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
        }>;
      };
  authUser?: {
    __typename?: "AuthUser";
    isAdmin: boolean;
    isReadOnlyAdmin: boolean;
    isAuditor: boolean;
    isGlobalRequester: boolean;
    organizationAuthorizedActions?: Array<string> | null;
    opalAdminExpirationTime?: string | null;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      organization: {
        __typename?: "Organization";
        id: string;
        name: string;
        createdAt: string;
      };
      directReports: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      }>;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    };
  } | null;
};

export type ResourceUserPushTaskResultFragment = {
  __typename?: "ResourceUserPushTaskResult";
  propStatuses: Array<{
    __typename?: "ResourcePropagationStatus";
    resourceId: string;
    userId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    metadata?: {
      __typename: "PropagationTicketMetadata";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  }>;
};

export type GroupUserPushTaskResultFragment = {
  __typename?: "GroupUserPushTaskResult";
  propStatuses: Array<{
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  }>;
};

export type GroupResourcePushTaskResultFragment = {
  __typename?: "GroupResourcePushTaskResult";
  propStatuses: Array<{
    __typename?: "GroupResourcePropagationStatus";
    groupId: string;
    resourceId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  }>;
};

export type GroupGroupPushTaskResultFragment = {
  __typename?: "GroupGroupPushTaskResult";
  propStatuses: Array<{
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  }>;
};

export type ConnectionUserPushTaskResultFragment = {
  __typename?: "ConnectionUserPushTaskResult";
  propStatuses: Array<{
    __typename?: "ConnectionUserPropagationStatus";
    connectionId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  }>;
};

export type RoleAssignmentPushTaskResultFragment = {
  __typename?: "RoleAssignmentPushTaskResult";
  propStatuses: Array<{
    __typename?: "PropagationStatus";
    roleAssignmentId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  }>;
};

export type ResourceUserPropagationMetadataFragment = {
  __typename: "PropagationTicketMetadata";
  ticket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type ResourcePropagationStatusFragment = {
  __typename?: "ResourcePropagationStatus";
  resourceId: string;
  userId: string;
  accessLevelRemoteId: string;
  lastSynced: string;
  statusCode: PropagationStatusCode;
  errorMessage?: string | null;
  taskType: PropagationTaskType;
  metadata?: {
    __typename: "PropagationTicketMetadata";
    ticket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
};

export type GroupUserTicketPropagationFragment = {
  __typename: "GroupUserTicketPropagation";
  resourceTickets: Array<{
    __typename?: "ResourceTicketPropagation";
    ticket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  }>;
};

export type GroupPropagationStatusFragment = {
  __typename?: "GroupPropagationStatus";
  groupId: string;
  userId: string;
  lastSynced: string;
  statusCode: PropagationStatusCode;
  errorMessage?: string | null;
  taskType: PropagationTaskType;
  ticketPropagation?: {
    __typename: "GroupUserTicketPropagation";
    resourceTickets: Array<{
      __typename?: "ResourceTicketPropagation";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
  } | null;
};

export type GroupResourcePropagationStatusFragment = {
  __typename?: "GroupResourcePropagationStatus";
  groupId: string;
  resourceId: string;
  accessLevelRemoteId: string;
  lastSynced: string;
  statusCode: PropagationStatusCode;
  errorMessage?: string | null;
  taskType: PropagationTaskType;
};

export type ConnectionUserPropagationStatusFragment = {
  __typename?: "ConnectionUserPropagationStatus";
  connectionId: string;
  userId: string;
  lastSynced: string;
  statusCode: PropagationStatusCode;
  errorMessage?: string | null;
  taskType: PropagationTaskType;
};

export type PropagationStatusFragment = {
  __typename?: "PropagationStatus";
  roleAssignmentId: string;
  lastSynced: string;
  statusCode: PropagationStatusCode;
  errorMessage?: string | null;
  taskType: PropagationTaskType;
};

export type PushTaskQueryVariables = Exact<{
  input: PushTaskInput;
}>;

export type PushTaskQuery = {
  __typename?: "Query";
  pushTask:
    | { __typename: "PushTaskNotFoundError"; message: string }
    | {
        __typename: "PushTaskResult";
        pushTask:
          | {
              __typename: "PushTaskComplete";
              id: string;
              taskType: PropagationTaskType;
              result:
                | {
                    __typename: "ConnectionUserPushTaskResult";
                    propStatuses: Array<{
                      __typename?: "ConnectionUserPropagationStatus";
                      connectionId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                    }>;
                  }
                | {
                    __typename: "GroupGroupPushTaskResult";
                    propStatuses: Array<{
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    }>;
                  }
                | {
                    __typename: "GroupResourcePushTaskResult";
                    propStatuses: Array<{
                      __typename?: "GroupResourcePropagationStatus";
                      groupId: string;
                      resourceId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                    }>;
                  }
                | {
                    __typename: "GroupUserPushTaskResult";
                    propStatuses: Array<{
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    }>;
                  }
                | {
                    __typename: "ResourceUserPushTaskResult";
                    propStatuses: Array<{
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    }>;
                  }
                | {
                    __typename: "RoleAssignmentPushTaskResult";
                    propStatuses: Array<{
                      __typename?: "PropagationStatus";
                      roleAssignmentId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                    }>;
                  };
              authUser?: {
                __typename?: "AuthUser";
                isAdmin: boolean;
                isReadOnlyAdmin: boolean;
                isAuditor: boolean;
                isGlobalRequester: boolean;
                organizationAuthorizedActions?: Array<string> | null;
                opalAdminExpirationTime?: string | null;
                user: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  organization: {
                    __typename?: "Organization";
                    id: string;
                    name: string;
                    createdAt: string;
                  };
                  directReports: Array<{
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  }>;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                };
              } | null;
            }
          | {
              __typename: "PushTaskPending";
              id: string;
              taskType: PropagationTaskType;
              nextProcessAt?: string | null;
            };
      };
};

export type RemediateAddResourceUsersMutationVariables = Exact<{
  input: AddResourceUsersInput;
}>;

export type RemediateAddResourceUsersMutation = {
  __typename?: "Mutation";
  remediateAddResourceUsers:
    | { __typename: "AddResourceUsersResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "CannotAddSystemUserToResourceError"; message: string }
    | { __typename: "OpalAdminRoleMustHaveAtLeastOneDirectUser" }
    | {
        __typename: "OpalGlobalImpersonationResourceDirectAddNotAllowedError";
        message: string;
      }
    | { __typename: "ResourceNotFoundError"; message: string }
    | { __typename: "ResourceUserAlreadyExists"; message: string };
};

export type RemediateAddGroupResourcesMutationVariables = Exact<{
  input: AddGroupResourcesInput;
}>;

export type RemediateAddGroupResourcesMutation = {
  __typename?: "Mutation";
  remediateAddGroupResources:
    | { __typename: "AddGroupResourcesResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "GroupResourceAlreadyExists"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type RemediateAddGroupUsersMutationVariables = Exact<{
  input: AddGroupUsersInput;
}>;

export type RemediateAddGroupUsersMutation = {
  __typename?: "Mutation";
  remediateAddGroupUsers:
    | { __typename: "AddGroupUsersResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "CannotAddSystemUserToGroupError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "GroupUserAlreadyExists"; message: string };
};

export type RemediateAddRoleAssignmentsMutationVariables = Exact<{
  input: AddRoleAssignmentsInput;
}>;

export type RemediateAddRoleAssignmentsMutation = {
  __typename?: "Mutation";
  remediateAddRoleAssignments:
    | { __typename: "AddRoleAssignmentsResult"; taskIds: Array<string> }
    | { __typename: "RoleAssignmentNotFoundError"; message: string };
};

export type RequestTemplateSmallFragment = {
  __typename?: "RequestTemplateSimple";
  id: string;
  name: string;
};

export type RequestTemplateFragment = {
  __typename?: "RequestTemplate";
  id: string;
  name: string;
  isDeleted?: boolean | null;
  customFields?: Array<{
    __typename?: "RequestTemplateCustomField";
    name: string;
    type: RequestTemplateCustomFieldType;
    required?: boolean | null;
    description?: string | null;
    metadata?: {
      __typename?: "RequestTemplateCustomFieldMetadata";
      multiChoiceData?: {
        __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
        options?: Array<{
          __typename?: "MultiChoiceOption";
          value: string;
          label: string;
        }> | null;
      } | null;
    } | null;
  }> | null;
};

export type RequestTemplateCustomFieldFragment = {
  __typename?: "RequestTemplateCustomField";
  name: string;
  type: RequestTemplateCustomFieldType;
  required?: boolean | null;
  description?: string | null;
  metadata?: {
    __typename?: "RequestTemplateCustomFieldMetadata";
    multiChoiceData?: {
      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
      options?: Array<{
        __typename?: "MultiChoiceOption";
        value: string;
        label: string;
      }> | null;
    } | null;
  } | null;
};

export type RequestTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type RequestTemplatesQuery = {
  __typename?: "Query";
  requestTemplates: {
    __typename: "RequestTemplatesResult";
    requestTemplates: Array<{
      __typename?: "RequestTemplateSimple";
      id: string;
      name: string;
    }>;
  };
};

export type RequestTemplatesWithFieldsQueryVariables = Exact<{
  input: RequestTemplatesWithFieldsInput;
}>;

export type RequestTemplatesWithFieldsQuery = {
  __typename?: "Query";
  requestTemplatesWithFields: {
    __typename: "RequestTemplatesWithFieldsResult";
    requestTemplates: Array<{
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    }>;
  };
};

export type RequestTemplateQueryVariables = Exact<{
  input: RequestTemplateInput;
}>;

export type RequestTemplateQuery = {
  __typename?: "Query";
  requestTemplate: {
    __typename: "RequestTemplateResult";
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type CreateRequestTemplateMutationVariables = Exact<{
  input: CreateRequestTemplateInput;
}>;

export type CreateRequestTemplateMutation = {
  __typename?: "Mutation";
  createRequestTemplate:
    | {
        __typename: "CreateRequestTemplateResult";
        requestTemplate: {
          __typename?: "RequestTemplateSimple";
          id: string;
          name: string;
        };
      }
    | { __typename: "RequestTemplateNameExistsError"; message: string };
};

export type UpdateRequestTemplateMutationVariables = Exact<{
  input: UpdateRequestTemplateInput;
}>;

export type UpdateRequestTemplateMutation = {
  __typename?: "Mutation";
  updateRequestTemplate:
    | { __typename: "CustomFieldExistsError"; message: string }
    | { __typename: "RequestTemplateNameExistsError"; message: string }
    | {
        __typename: "UpdateRequestTemplateResult";
        requestTemplate: {
          __typename?: "RequestTemplateSimple";
          id: string;
          name: string;
        };
      };
};

export type DeleteRequestTemplateMutationVariables = Exact<{
  input: DeleteRequestTemplateInput;
}>;

export type DeleteRequestTemplateMutation = {
  __typename?: "Mutation";
  deleteRequestTemplate: {
    __typename: "DeleteRequestTemplateResult";
    requestTemplate: {
      __typename?: "RequestTemplateSimple";
      id: string;
      name: string;
    };
  };
};

export type RequestedGroupFragment = {
  __typename?: "RequestedGroup";
  groupId: string;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "GroupPropagationStatus";
    groupId: string;
    userId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    ticketPropagation?: {
      __typename: "GroupUserTicketPropagation";
      resourceTickets: Array<{
        __typename?: "ResourceTicketPropagation";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
  } | null;
  accessLevel?: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  } | null;
  targetUserAccess?: {
    __typename?: "UserGroupAccess";
    groupId: string;
    userId: string;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
  } | null;
};

export type ResourceProposalFragment = {
  __typename?: "ResourceProposal";
  id: string;
  connectionId: string;
  serviceType: ServiceType;
  resourceType: ResourceType;
  metadata?: {
    __typename: "AWSRoleProposalMetadata";
    policyName: string;
    policyDocument: string;
    policyDescription: string;
    assumeRolePolicyDocument: string;
    roleName: string;
    roleDescription: string;
    tags: Array<{ __typename?: "AWSTag"; key: string; value?: string | null }>;
  } | null;
};

export type RequestedResourceFragment = {
  __typename?: "RequestedResource";
  resourceId: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  propagationStatus?: {
    __typename?: "ResourcePropagationStatus";
    resourceId: string;
    userId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    metadata?: {
      __typename: "PropagationTicketMetadata";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  } | null;
  targetUserAccess?: {
    __typename?: "UserResourceAccess";
    resourceId: string;
    userId: string;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type RequestedGroupPreviewSmallFragment = {
  __typename?: "RequestedGroup";
  groupId: string;
  group?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  accessLevel?: {
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  } | null;
  targetUserAccess?: {
    __typename?: "UserGroupAccess";
    groupId: string;
    userId: string;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
  } | null;
};

export type RequestedResourcePreviewSmallFragment = {
  __typename?: "RequestedResource";
  resourceId: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  targetUserAccess?: {
    __typename?: "UserResourceAccess";
    resourceId: string;
    userId: string;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type RequestCommentFragment = {
  __typename?: "RequestComment";
  id: string;
  userId: string;
  createdAt: string;
  comment: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type RequestPreviewLargeFragment = {
  __typename?: "Request";
  id: string;
  requesterId: string;
  targetUserId?: string | null;
  targetGroupId?: string | null;
  resourceProposalId?: string | null;
  durationInMinutes?: number | null;
  createdAt: string;
  updatedAt: string;
  status: RequestStatus;
  requester?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  requestedResources: Array<{
    __typename?: "RequestedResource";
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    targetUserAccess?: {
      __typename?: "UserResourceAccess";
      resourceId: string;
      userId: string;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  }>;
  requestedGroups: Array<{
    __typename?: "RequestedGroup";
    groupId: string;
    group?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    targetUserAccess?: {
      __typename?: "UserGroupAccess";
      groupId: string;
      userId: string;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
    } | null;
  }>;
  resourceProposal?: {
    __typename?: "ResourceProposal";
    id: string;
    connectionId: string;
    serviceType: ServiceType;
    resourceType: ResourceType;
    metadata?: {
      __typename: "AWSRoleProposalMetadata";
      policyName: string;
      policyDocument: string;
      policyDescription: string;
      assumeRolePolicyDocument: string;
      roleName: string;
      roleDescription: string;
      tags: Array<{
        __typename?: "AWSTag";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
};

export type RequestPreviewSmallFragment = {
  __typename?: "Request";
  id: string;
  requesterId: string;
  targetUserId?: string | null;
  targetGroupId?: string | null;
  resourceProposalId?: string | null;
  createdAt: string;
  updatedAt: string;
  status: RequestStatus;
  requester?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  requestedResources: Array<{
    __typename?: "RequestedResource";
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    targetUserAccess?: {
      __typename?: "UserResourceAccess";
      resourceId: string;
      userId: string;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  }>;
  requestedGroups: Array<{
    __typename?: "RequestedGroup";
    groupId: string;
    group?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    targetUserAccess?: {
      __typename?: "UserGroupAccess";
      groupId: string;
      userId: string;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
    } | null;
  }>;
  resourceProposal?: {
    __typename?: "ResourceProposal";
    id: string;
    connectionId: string;
    serviceType: ServiceType;
    resourceType: ResourceType;
    metadata?: {
      __typename: "AWSRoleProposalMetadata";
      policyName: string;
      policyDocument: string;
      policyDescription: string;
      assumeRolePolicyDocument: string;
      roleName: string;
      roleDescription: string;
      tags: Array<{
        __typename?: "AWSTag";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
};

export type RequestStageFragment = {
  __typename?: "RequestStage";
  operator: ReviewStageOperator;
  stage: number;
  reviewers: Array<{
    __typename?: "RequestReviewer";
    userId: string;
    reviewerAction?: ReviewerAction | null;
    reviewerType: RequestApprovalType;
    owner?: { __typename?: "Owner"; id: string; name: string } | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
  }>;
};

export type RequestReviewerFragment = {
  __typename?: "RequestReviewer";
  userId: string;
  reviewerAction?: ReviewerAction | null;
  reviewerType: RequestApprovalType;
  owner?: { __typename?: "Owner"; id: string; name: string } | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
};

export type RequestCustomMetadataValueFragment = {
  __typename?: "RequestCustomMetadataValue";
  shortTextValue?: { __typename?: "ShortTextValue"; value: string } | null;
  longTextValue?: { __typename?: "LongTextValue"; value: string } | null;
  booleanValue?: { __typename?: "BooleanValue"; value: boolean } | null;
  multiChoiceValue?: { __typename?: "MultiChoiceValue"; value: string } | null;
};

export type RequestCustomMetadataFragment = {
  __typename?: "RequestCustomMetadata";
  fieldName: string;
  fieldType: RequestTemplateCustomFieldType;
  metadataValue?: {
    __typename?: "RequestCustomMetadataValue";
    shortTextValue?: { __typename?: "ShortTextValue"; value: string } | null;
    longTextValue?: { __typename?: "LongTextValue"; value: string } | null;
    booleanValue?: { __typename?: "BooleanValue"; value: boolean } | null;
    multiChoiceValue?: {
      __typename?: "MultiChoiceValue";
      value: string;
    } | null;
  } | null;
};

export type RequestFragment = {
  __typename?: "Request";
  id: string;
  requesterId: string;
  targetUserId?: string | null;
  targetGroupId?: string | null;
  createdAt: string;
  updatedAt: string;
  reason: string;
  durationInMinutes?: number | null;
  status: RequestStatus;
  reviewersError?: string | null;
  resourceProposalId?: string | null;
  escalatedToSkipManager: boolean;
  requester?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  targetUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  targetGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  requestedResources: Array<{
    __typename?: "RequestedResource";
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
    targetUserAccess?: {
      __typename?: "UserResourceAccess";
      resourceId: string;
      userId: string;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
    } | null;
  }>;
  requestedGroups: Array<{
    __typename?: "RequestedGroup";
    groupId: string;
    group?: {
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
    targetUserAccess?: {
      __typename?: "UserGroupAccess";
      groupId: string;
      userId: string;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
    } | null;
  }>;
  requestComments: Array<{
    __typename?: "RequestComment";
    id: string;
    userId: string;
    createdAt: string;
    comment: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
  requestTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
  stages: Array<{
    __typename?: "RequestItemStages";
    requestedItemName: string;
    requestedRoleName?: string | null;
    stages: Array<{
      __typename?: "RequestStage";
      operator: ReviewStageOperator;
      stage: number;
      reviewers: Array<{
        __typename?: "RequestReviewer";
        userId: string;
        reviewerAction?: ReviewerAction | null;
        reviewerType: RequestApprovalType;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
      }>;
    }>;
  }>;
  resourceProposal?: {
    __typename?: "ResourceProposal";
    id: string;
    connectionId: string;
    serviceType: ServiceType;
    resourceType: ResourceType;
    metadata?: {
      __typename: "AWSRoleProposalMetadata";
      policyName: string;
      policyDocument: string;
      policyDescription: string;
      assumeRolePolicyDocument: string;
      roleName: string;
      roleDescription: string;
      tags: Array<{
        __typename?: "AWSTag";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
  customMetadata?: Array<{
    __typename?: "RequestCustomMetadata";
    fieldName: string;
    fieldType: RequestTemplateCustomFieldType;
    metadataValue?: {
      __typename?: "RequestCustomMetadataValue";
      shortTextValue?: { __typename?: "ShortTextValue"; value: string } | null;
      longTextValue?: { __typename?: "LongTextValue"; value: string } | null;
      booleanValue?: { __typename?: "BooleanValue"; value: boolean } | null;
      multiChoiceValue?: {
        __typename?: "MultiChoiceValue";
        value: string;
      } | null;
    } | null;
  }> | null;
};

export type RequestsQueryVariables = Exact<{
  input: RequestsInput;
}>;

export type RequestsQuery = {
  __typename?: "Query";
  requests: {
    __typename: "RequestsResult";
    cursor?: string | null;
    requests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      durationInMinutes?: number | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type RequestQueryVariables = Exact<{
  input: RequestInput;
}>;

export type RequestQuery = {
  __typename?: "Query";
  request:
    | { __typename: "RequestNotFoundError"; message: string }
    | {
        __typename: "RequestResult";
        request: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          reason: string;
          durationInMinutes?: number | null;
          status: RequestStatus;
          reviewersError?: string | null;
          resourceProposalId?: string | null;
          escalatedToSkipManager: boolean;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          requestComments: Array<{
            __typename?: "RequestComment";
            id: string;
            userId: string;
            createdAt: string;
            comment: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          requestTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          stages: Array<{
            __typename?: "RequestItemStages";
            requestedItemName: string;
            requestedRoleName?: string | null;
            stages: Array<{
              __typename?: "RequestStage";
              operator: ReviewStageOperator;
              stage: number;
              reviewers: Array<{
                __typename?: "RequestReviewer";
                userId: string;
                reviewerAction?: ReviewerAction | null;
                reviewerType: RequestApprovalType;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
              }>;
            }>;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          customMetadata?: Array<{
            __typename?: "RequestCustomMetadata";
            fieldName: string;
            fieldType: RequestTemplateCustomFieldType;
            metadataValue?: {
              __typename?: "RequestCustomMetadataValue";
              shortTextValue?: {
                __typename?: "ShortTextValue";
                value: string;
              } | null;
              longTextValue?: {
                __typename?: "LongTextValue";
                value: string;
              } | null;
              booleanValue?: {
                __typename?: "BooleanValue";
                value: boolean;
              } | null;
              multiChoiceValue?: {
                __typename?: "MultiChoiceValue";
                value: string;
              } | null;
            } | null;
          }> | null;
        };
      };
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: "Mutation";
  createRequest:
    | { __typename: "BulkRequestTooLargeError"; message: string }
    | {
        __typename: "CreateRequestResult";
        request: { __typename?: "Request"; id: string; status: RequestStatus };
      }
    | {
        __typename: "GroupNestingNotAllowedError";
        message: string;
        fromGroup?: { __typename?: "Group"; id: string; name: string } | null;
        toGroup?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | { __typename: "ItemCannotBeRequestedError"; message: string }
    | { __typename: "MfaInvalidError"; message: string }
    | { __typename: "NoManagerSetForRequestingUserError"; message: string }
    | {
        __typename: "NoReviewersSetForGroupError";
        message: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
      }
    | { __typename: "NoReviewersSetForOwnerError" }
    | {
        __typename: "NoReviewersSetForResourceError";
        message: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
      }
    | { __typename: "RequestDurationTooLargeError"; message: string }
    | {
        __typename: "RequestFieldValueMissingError";
        message: string;
        fieldName: string;
        groupIds?: Array<string> | null;
        resourceIds?: Array<string> | null;
      }
    | {
        __typename: "RequestReasonMissingError";
        message: string;
        groupIds?: Array<string> | null;
        resourceIds?: Array<string> | null;
      }
    | {
        __typename: "RequestRequiresUserAuthTokenForConnectionError";
        message: string;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
      }
    | {
        __typename: "TargetUserHasNestedAccessError";
        message: string;
        groupIds?: Array<string> | null;
      }
    | {
        __typename: "UserCannotRequestAccessForTargetGroupError";
        message: string;
      };
};

export type ApproveRequestMutationVariables = Exact<{
  input: ApproveRequestInput;
}>;

export type ApproveRequestMutation = {
  __typename?: "Mutation";
  approveRequest:
    | { __typename: "AdminApproveRequiresReasonError"; message: string }
    | {
        __typename: "ApproveRequestResult";
        taskId?: string | null;
        request: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          reason: string;
          durationInMinutes?: number | null;
          status: RequestStatus;
          reviewersError?: string | null;
          resourceProposalId?: string | null;
          escalatedToSkipManager: boolean;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          requestComments: Array<{
            __typename?: "RequestComment";
            id: string;
            userId: string;
            createdAt: string;
            comment: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          requestTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          stages: Array<{
            __typename?: "RequestItemStages";
            requestedItemName: string;
            requestedRoleName?: string | null;
            stages: Array<{
              __typename?: "RequestStage";
              operator: ReviewStageOperator;
              stage: number;
              reviewers: Array<{
                __typename?: "RequestReviewer";
                userId: string;
                reviewerAction?: ReviewerAction | null;
                reviewerType: RequestApprovalType;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
              }>;
            }>;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          customMetadata?: Array<{
            __typename?: "RequestCustomMetadata";
            fieldName: string;
            fieldType: RequestTemplateCustomFieldType;
            metadataValue?: {
              __typename?: "RequestCustomMetadataValue";
              shortTextValue?: {
                __typename?: "ShortTextValue";
                value: string;
              } | null;
              longTextValue?: {
                __typename?: "LongTextValue";
                value: string;
              } | null;
              booleanValue?: {
                __typename?: "BooleanValue";
                value: boolean;
              } | null;
              multiChoiceValue?: {
                __typename?: "MultiChoiceValue";
                value: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename: "AWSRoleCreationError"; message: string }
    | { __typename: "AWSRolePolicyInvalidError"; message: string }
    | {
        __typename: "GroupNestingNotAllowedError";
        message: string;
        fromGroup?: { __typename?: "Group"; id: string; name: string } | null;
        toGroup?: { __typename?: "Group"; id: string; name: string } | null;
      }
    | { __typename: "MfaInvalidError"; message: string }
    | { __typename: "OrganizationInReadOnlyModeError"; message: string }
    | { __typename: "RequestAlreadyActionedError"; message: string }
    | { __typename: "RequestNotFoundError"; message: string };
};

export type DenyRequestMutationVariables = Exact<{
  input: DenyRequestInput;
}>;

export type DenyRequestMutation = {
  __typename?: "Mutation";
  denyRequest:
    | {
        __typename: "DenyRequestResult";
        request: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          reason: string;
          durationInMinutes?: number | null;
          status: RequestStatus;
          reviewersError?: string | null;
          resourceProposalId?: string | null;
          escalatedToSkipManager: boolean;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          requestComments: Array<{
            __typename?: "RequestComment";
            id: string;
            userId: string;
            createdAt: string;
            comment: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          requestTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          stages: Array<{
            __typename?: "RequestItemStages";
            requestedItemName: string;
            requestedRoleName?: string | null;
            stages: Array<{
              __typename?: "RequestStage";
              operator: ReviewStageOperator;
              stage: number;
              reviewers: Array<{
                __typename?: "RequestReviewer";
                userId: string;
                reviewerAction?: ReviewerAction | null;
                reviewerType: RequestApprovalType;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
              }>;
            }>;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          customMetadata?: Array<{
            __typename?: "RequestCustomMetadata";
            fieldName: string;
            fieldType: RequestTemplateCustomFieldType;
            metadataValue?: {
              __typename?: "RequestCustomMetadataValue";
              shortTextValue?: {
                __typename?: "ShortTextValue";
                value: string;
              } | null;
              longTextValue?: {
                __typename?: "LongTextValue";
                value: string;
              } | null;
              booleanValue?: {
                __typename?: "BooleanValue";
                value: boolean;
              } | null;
              multiChoiceValue?: {
                __typename?: "MultiChoiceValue";
                value: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename: "RequestAlreadyActionedError"; message: string }
    | { __typename: "RequestNotFoundError"; message: string };
};

export type CancelRequestMutationVariables = Exact<{
  input: CancelRequestInput;
}>;

export type CancelRequestMutation = {
  __typename?: "Mutation";
  cancelRequest:
    | {
        __typename: "CancelRequestResult";
        request: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          reason: string;
          durationInMinutes?: number | null;
          status: RequestStatus;
          reviewersError?: string | null;
          resourceProposalId?: string | null;
          escalatedToSkipManager: boolean;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          requestComments: Array<{
            __typename?: "RequestComment";
            id: string;
            userId: string;
            createdAt: string;
            comment: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          requestTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          stages: Array<{
            __typename?: "RequestItemStages";
            requestedItemName: string;
            requestedRoleName?: string | null;
            stages: Array<{
              __typename?: "RequestStage";
              operator: ReviewStageOperator;
              stage: number;
              reviewers: Array<{
                __typename?: "RequestReviewer";
                userId: string;
                reviewerAction?: ReviewerAction | null;
                reviewerType: RequestApprovalType;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
              }>;
            }>;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          customMetadata?: Array<{
            __typename?: "RequestCustomMetadata";
            fieldName: string;
            fieldType: RequestTemplateCustomFieldType;
            metadataValue?: {
              __typename?: "RequestCustomMetadataValue";
              shortTextValue?: {
                __typename?: "ShortTextValue";
                value: string;
              } | null;
              longTextValue?: {
                __typename?: "LongTextValue";
                value: string;
              } | null;
              booleanValue?: {
                __typename?: "BooleanValue";
                value: boolean;
              } | null;
              multiChoiceValue?: {
                __typename?: "MultiChoiceValue";
                value: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename: "RequestAlreadyActionedError"; message: string }
    | { __typename: "RequestNotFoundError"; message: string };
};

export type CreateRequestCommentMutationVariables = Exact<{
  input: CreateRequestCommentInput;
}>;

export type CreateRequestCommentMutation = {
  __typename?: "Mutation";
  createRequestComment: {
    __typename: "CreateRequestCommentResult";
    request: {
      __typename?: "Request";
      id: string;
      requestComments: Array<{
        __typename?: "RequestComment";
        id: string;
        userId: string;
        createdAt: string;
        comment: string;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      }>;
    };
  };
};

export type SendManualRequestReminderMutationVariables = Exact<{
  input: SendManualRequestReminderInput;
}>;

export type SendManualRequestReminderMutation = {
  __typename?: "Mutation";
  sendManualRequestReminder:
    | { __typename: "RequestNotFoundError" }
    | { __typename: "ReviewersAlreadyRemindedError" }
    | {
        __typename: "SendManualRequestReminderResult";
        success?: boolean | null;
      };
};

export type CurrentUserResourceAccessTinyFragment = {
  __typename?: "CurrentUserResourceAccess";
  resourceId: string;
  numAvailableAccessLevels: number;
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  activeSessions: Array<{
    __typename?: "Session";
    id: string;
    durationInMinutes?: number | null;
    endTime: string;
    accessLevel?: string | null;
    accessLevelRemoteId?: string | null;
    metadata:
      | {
          __typename: "AwsIamFederatedEksSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          federatedArn: string;
          clusterName: string;
          clusterRegion: string;
        }
      | {
          __typename: "AwsIamFederatedRdsSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          federatedArn: string;
          dbInstanceId: string;
          dbPassword: string;
          dbEngine: string;
          dbUser: string;
          dbHostname: string;
          dbPort: number;
          dbName: string;
        }
      | {
          __typename: "AwsIamFederatedRoleSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          awsLoginUrl: string;
          federatedArn: string;
        }
      | {
          __typename: "AwsIamFederatedSSMSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          awsLoginUrl: string;
          federatedArn: string;
          ec2InstanceId: string;
          ec2Region: string;
        }
      | { __typename: "OidcSession" }
      | { __typename: "OpalImpersonationSession" }
      | {
          __typename: "VaultMongoAtlasSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          initDbName: string;
        }
      | {
          __typename: "VaultMongoSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          authenticationDbName: string;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        }
      | {
          __typename: "VaultMySQLMariaDBSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        }
      | {
          __typename: "VaultPostgresSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        };
  }>;
  pendingRequests: Array<{
    __typename?: "Request";
    id: string;
    requesterId: string;
    targetUserId?: string | null;
    targetGroupId?: string | null;
    createdAt: string;
    updatedAt: string;
    status: RequestStatus;
    requestedResources: Array<{
      __typename?: "RequestedResource";
      resourceId: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      targetUserAccess?: {
        __typename?: "UserResourceAccess";
        resourceId: string;
        userId: string;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
  }>;
};

export type CurrentUserResourceAccessFragment = {
  __typename?: "CurrentUserResourceAccess";
  resourceId: string;
  numAvailableAccessLevels: number;
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  activeSessions: Array<{
    __typename?: "Session";
    id: string;
    durationInMinutes?: number | null;
    endTime: string;
    accessLevel?: string | null;
    accessLevelRemoteId?: string | null;
    metadata:
      | {
          __typename: "AwsIamFederatedEksSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          federatedArn: string;
          clusterName: string;
          clusterRegion: string;
        }
      | {
          __typename: "AwsIamFederatedRdsSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          federatedArn: string;
          dbInstanceId: string;
          dbPassword: string;
          dbEngine: string;
          dbUser: string;
          dbHostname: string;
          dbPort: number;
          dbName: string;
        }
      | {
          __typename: "AwsIamFederatedRoleSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          awsLoginUrl: string;
          federatedArn: string;
        }
      | {
          __typename: "AwsIamFederatedSSMSession";
          id: string;
          awsAccessKeyId: string;
          awsSecretAccessKey: string;
          awsSessionToken: string;
          awsLoginUrl: string;
          federatedArn: string;
          ec2InstanceId: string;
          ec2Region: string;
        }
      | { __typename: "OidcSession" }
      | { __typename: "OpalImpersonationSession" }
      | {
          __typename: "VaultMongoAtlasSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          initDbName: string;
        }
      | {
          __typename: "VaultMongoSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          authenticationDbName: string;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        }
      | {
          __typename: "VaultMySQLMariaDBSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        }
      | {
          __typename: "VaultPostgresSession";
          id: string;
          dbUser: string;
          dbPassword: string;
          dbHostname: string;
          dbPort: number;
          tlsConfig: {
            __typename?: "TlsConfig";
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          };
        };
  }>;
  pendingRequests: Array<{
    __typename?: "Request";
    id: string;
    requesterId: string;
    targetUserId?: string | null;
    targetGroupId?: string | null;
    resourceProposalId?: string | null;
    createdAt: string;
    updatedAt: string;
    status: RequestStatus;
    requester?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    targetUser?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    targetGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    requestedResources: Array<{
      __typename?: "RequestedResource";
      resourceId: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      targetUserAccess?: {
        __typename?: "UserResourceAccess";
        resourceId: string;
        userId: string;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
      } | null;
    }>;
    requestedGroups: Array<{
      __typename?: "RequestedGroup";
      groupId: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      targetUserAccess?: {
        __typename?: "UserGroupAccess";
        groupId: string;
        userId: string;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
      } | null;
    }>;
    resourceProposal?: {
      __typename?: "ResourceProposal";
      id: string;
      connectionId: string;
      serviceType: ServiceType;
      resourceType: ResourceType;
      metadata?: {
        __typename: "AWSRoleProposalMetadata";
        policyName: string;
        policyDocument: string;
        policyDescription: string;
        assumeRolePolicyDocument: string;
        roleName: string;
        roleDescription: string;
        tags: Array<{
          __typename?: "AWSTag";
          key: string;
          value?: string | null;
        }>;
      } | null;
    } | null;
  }>;
};

export type UserResourceAccessFragment = {
  __typename?: "UserResourceAccess";
  resourceId: string;
  userId: string;
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
};

export type ResourceUserAccessFragment = {
  __typename?: "ResourceUserAccess";
  resourceId: string;
  userId: string;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  latestExpiringAccessPoint: {
    __typename?: "ResourceUserAccessPoint";
    resourceId: string;
    userId: string;
    resourceName: string;
    resourceType: ResourceType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  };
  directAccessPoint?: {
    __typename?: "ResourceUserAccessPoint";
    resourceId: string;
    userId: string;
    resourceName: string;
    resourceType: ResourceType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  } | null;
  inheritedAccessPoints: Array<{
    __typename?: "ResourceUserAccessPoint";
    resourceId: string;
    userId: string;
    resourceName: string;
    resourceType: ResourceType;
    eventId: string;
    createdAt: string;
    expiration?: string | null;
    supportTicketId?: string | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    supportTicket?: {
      __typename?: "SupportTicket";
      id?: string | null;
      remoteId: string;
      url: string;
      identifier: string;
      thirdPartyProvider: ThirdPartyProvider;
      projectName: string;
    } | null;
  }>;
  groupUserAccesses: Array<{
    __typename?: "GroupUserAccess";
    groupId: string;
    userId: string;
    isGroupManaged: boolean;
    latestExpiringAccessPoint: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupUserAccessPoint";
      groupId: string;
      userId: string;
      groupName: string;
      groupType: GroupType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      source: GroupUserSource;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupUserAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      userId: string;
      resolvedAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      indirectAccessPointPath: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }>;
    }>;
  }>;
};

export type ResourceUserAccessPointFragment = {
  __typename?: "ResourceUserAccessPoint";
  resourceId: string;
  userId: string;
  resourceName: string;
  resourceType: ResourceType;
  eventId: string;
  createdAt: string;
  expiration?: string | null;
  supportTicketId?: string | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  supportTicket?: {
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    projectName: string;
  } | null;
};

export type ResourceAccessLevelFragment = {
  __typename: "ResourceAccessLevel";
  accessLevelName: string;
  accessLevelRemoteId: string;
  accessLevelMetadata?: {
    __typename: "ImpersonationAccessLevelMetadata";
    avatarUrl: string;
  } | null;
};

export type ResourceAccessLevelsQueryVariables = Exact<{
  input: ResourceAccessLevelsInput;
}>;

export type ResourceAccessLevelsQuery = {
  __typename?: "Query";
  accessLevels:
    | {
        __typename: "ResourceAccessLevelsResult";
        accessLevels: Array<{
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        }>;
      }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type MultipleResourceAccessLevelsQueryVariables = Exact<{
  input: MultipleResourceAccessLevelsInput;
}>;

export type MultipleResourceAccessLevelsQuery = {
  __typename?: "Query";
  multipleAccessLevels:
    | {
        __typename: "MultipleResourceAccessLevelsResult";
        results: Array<{
          __typename?: "ResourceAccessLevelsResult";
          resourceId: string;
          accessLevels: Array<{
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          }>;
        }>;
      }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type ResourceCustomAccessLevelFragment = {
  __typename?: "ResourceCustomAccessLevel";
  id: string;
  resourceId: string;
  policy?: string | null;
  updatedAt: string;
  priority?: number | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
};

export type ResourceCustomAccessLevelQueryVariables = Exact<{
  input: ResourceCustomAccessLevelInput;
}>;

export type ResourceCustomAccessLevelQuery = {
  __typename?: "Query";
  resourceCustomAccessLevel:
    | { __typename: "ResourceCustomAccessLevelNotFoundError" }
    | {
        __typename: "ResourceCustomAccessLevelResult";
        resourceCustomAccessLevel: {
          __typename?: "ResourceCustomAccessLevel";
          id: string;
          resourceId: string;
          policy?: string | null;
          updatedAt: string;
          priority?: number | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
        };
      };
};

export type ResourceCustomAccessLevelsQueryVariables = Exact<{
  input: ResourceCustomAccessLevelsInput;
}>;

export type ResourceCustomAccessLevelsQuery = {
  __typename?: "Query";
  resourceCustomAccessLevels: {
    __typename: "ResourceCustomAccessLevelsResult";
    resourceCustomAccessLevels: Array<{
      __typename?: "ResourceCustomAccessLevel";
      id: string;
      resourceId: string;
      policy?: string | null;
      updatedAt: string;
      priority?: number | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
    }>;
  };
};

export type CreateResourceCustomAccessLevelMutationVariables = Exact<{
  input: CreateResourceCustomAccessLevelInput;
}>;

export type CreateResourceCustomAccessLevelMutation = {
  __typename?: "Mutation";
  createResourceCustomAccessLevel:
    | {
        __typename: "CreateResourceCustomAccessLevelResult";
        resourceCustomAccessLevel: {
          __typename?: "ResourceCustomAccessLevel";
          id: string;
          resourceId: string;
          policy?: string | null;
          updatedAt: string;
          priority?: number | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
        };
      }
    | {
        __typename: "ResourceCustomAccessLevelAlreadyExistsError";
        message: string;
      }
    | { __typename: "ResourceCustomAccessLevelPriorityError"; message: string };
};

export type CreateResourceCustomAccessLevelsMutationVariables = Exact<{
  input: CreateResourceCustomAccessLevelsInput;
}>;

export type CreateResourceCustomAccessLevelsMutation = {
  __typename?: "Mutation";
  createResourceCustomAccessLevels: {
    __typename: "CreateResourceCustomAccessLevelsResult";
    entries: Array<{
      __typename: "CreateResourceCustomAccessLevelEntryResult";
      resourceCustomAccessLevel: {
        __typename?: "ResourceCustomAccessLevel";
        id: string;
        resourceId: string;
        policy?: string | null;
        updatedAt: string;
        priority?: number | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
      };
    }>;
  };
};

export type UpdateResourceCustomAccessLevelMutationVariables = Exact<{
  input: UpdateResourceCustomAccessLevelInput;
}>;

export type UpdateResourceCustomAccessLevelMutation = {
  __typename?: "Mutation";
  updateResourceCustomAccessLevel:
    | { __typename: "ResourceCustomAccessLevelNotFoundError"; message: string }
    | { __typename: "ResourceCustomAccessLevelPriorityError"; message: string }
    | {
        __typename: "UpdateResourceCustomAccessLevelResult";
        resourceCustomAccessLevel: {
          __typename?: "ResourceCustomAccessLevel";
          id: string;
          resourceId: string;
          policy?: string | null;
          updatedAt: string;
          priority?: number | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
        };
      };
};

export type DeleteResourceCustomAccessLevelsMutationVariables = Exact<{
  input: DeleteResourceCustomAccessLevelsInput;
}>;

export type DeleteResourceCustomAccessLevelsMutation = {
  __typename?: "Mutation";
  deleteResourceCustomAccessLevels:
    | {
        __typename: "DeleteResourceCustomAccessLevelsResult";
        entries: Array<{
          __typename?: "DeleteResourceCustomAccessLevelsEntryResult";
          resourceCustomAccessLevel: {
            __typename?: "ResourceCustomAccessLevel";
            id: string;
            resourceId: string;
            policy?: string | null;
            updatedAt: string;
            priority?: number | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
          };
        }>;
      }
    | { __typename: "ResourceCustomAccessLevelNotFoundError" };
};

export type ResourceTagPreviewFragment = {
  __typename?: "ResourceTag";
  id: string;
  resourceId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type ResourceTagFragment = {
  __typename?: "ResourceTag";
  id: string;
  resourceId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type ResourceTagsQueryVariables = Exact<{
  input: ResourceTagsInput;
}>;

export type ResourceTagsQuery = {
  __typename?: "Query";
  resourceTags: {
    __typename: "ResourceTagsResult";
    resourceTags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        recommendedDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        isManaged: boolean;
        autoApproval: boolean;
        reasonOptional: boolean;
        requestTemplateId?: string | null;
        parentResourceId?: string | null;
        authorizedActions?: Array<string> | null;
        customRequestNotification?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        childResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          autoApproval: boolean;
          parentResourceId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
        }> | null;
        requestTemplate?: {
          __typename?: "RequestTemplate";
          id: string;
          name: string;
          isDeleted?: boolean | null;
          customFields?: Array<{
            __typename?: "RequestTemplateCustomField";
            name: string;
            type: RequestTemplateCustomFieldType;
            required?: boolean | null;
            description?: string | null;
            metadata?: {
              __typename?: "RequestTemplateCustomFieldMetadata";
              multiChoiceData?: {
                __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                options?: Array<{
                  __typename?: "MultiChoiceOption";
                  value: string;
                  label: string;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
  };
};

export type AddResourceTagsMutationVariables = Exact<{
  input: AddResourceTagsInput;
}>;

export type AddResourceTagsMutation = {
  __typename?: "Mutation";
  addResourceTags:
    | {
        __typename: "AddResourceTagsResult";
        entries: Array<{
          __typename: "AddResourceTagsEntryResult";
          resourceTag: {
            __typename?: "ResourceTag";
            resourceId: string;
            tagId: string;
          };
        }>;
      }
    | { __typename: "ResourceNotFoundError"; message: string }
    | { __typename: "TagNotFoundError"; message: string };
};

export type RemoveResourceTagsMutationVariables = Exact<{
  input: RemoveResourceTagsInput;
}>;

export type RemoveResourceTagsMutation = {
  __typename?: "Mutation";
  removeResourceTags:
    | {
        __typename: "RemoveResourceTagsResult";
        entries: Array<{
          __typename: "RemoveResourceTagsEntryResult";
          resourceTag: {
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          };
        }>;
      }
    | { __typename: "ResourceTagNotFoundError"; message: string };
};

export type ResourceUsageLogFragment = {
  __typename?: "ResourceUsageLog";
  id: string;
  name?: string | null;
  startDate: string;
  endDate?: string | null;
  details?: string | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type ResourceUsageQueryVariables = Exact<{
  input: ResourceUsageInput;
}>;

export type ResourceUsageQuery = {
  __typename?: "Query";
  resourceUsage:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceUsageResult";
        usageLogs?: Array<{
          __typename?: "ResourceUsageLog";
          id: string;
          name?: string | null;
          startDate: string;
          endDate?: string | null;
          details?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }> | null;
      }
    | { __typename: "UserFacingError"; message: string };
};

export type ResourceUserFragment = {
  __typename: "ResourceUser";
  resourceId: string;
  userId: string;
  mostRecentSessionEnded?: string | null;
  source: ResourceUserSource;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access?: {
    __typename?: "ResourceUserAccess";
    resourceId: string;
    userId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    latestExpiringAccessPoint: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    groupUserAccesses: Array<{
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "ResourcePropagationStatus";
    resourceId: string;
    userId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    metadata?: {
      __typename: "PropagationTicketMetadata";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  } | null;
};

export type UserResourceFragment = {
  __typename?: "ResourceUser";
  resourceId: string;
  userId: string;
  source: ResourceUserSource;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access?: {
    __typename?: "ResourceUserAccess";
    resourceId: string;
    userId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    latestExpiringAccessPoint: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    groupUserAccesses: Array<{
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "ResourcePropagationStatus";
    resourceId: string;
    userId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    metadata?: {
      __typename: "PropagationTicketMetadata";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  } | null;
};

export type ResourceUserPreviewFragment = {
  __typename?: "ResourceUser";
  resourceId: string;
  userId: string;
  source: ResourceUserSource;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access?: {
    __typename?: "ResourceUserAccess";
    resourceId: string;
    userId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    latestExpiringAccessPoint: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    groupUserAccesses: Array<{
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    }>;
  } | null;
};

export type AddResourceUsersMutationVariables = Exact<{
  input: AddResourceUsersInput;
}>;

export type AddResourceUsersMutation = {
  __typename?: "Mutation";
  addResourceUsers:
    | { __typename: "AddResourceUsersResult"; taskId: string }
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "CannotAddSystemUserToResourceError"; message: string }
    | {
        __typename: "OpalGlobalImpersonationResourceDirectAddNotAllowedError";
        message: string;
      }
    | { __typename: "ResourceNotFoundError"; message: string }
    | { __typename: "ResourceUserAlreadyExists"; message: string };
};

export type RemoveResourceUsersMutationVariables = Exact<{
  input: RemoveResourceUsersInput;
}>;

export type RemoveResourceUsersMutation = {
  __typename?: "Mutation";
  removeResourceUsers:
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "OidcIDTokenNotFoundError"; message: string }
    | {
        __typename: "OpalAdminRoleMustHaveAtLeastOneDirectUser";
        message: string;
      }
    | { __typename: "RemoveResourceUsersResult"; taskId: string }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type UpdateUserResourcesMutationVariables = Exact<{
  input: UpdateResourceUsersInput;
}>;

export type UpdateUserResourcesMutation = {
  __typename?: "Mutation";
  updateResourceUsers:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "UpdateResourceUsersResult";
        resourceUsers?: Array<{
          __typename?: "ResourceUser";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename?: "ResourceAccessLevel";
            accessLevelRemoteId: string;
          };
        }> | null;
      };
};

export type RemoveUserResourcesMutationVariables = Exact<{
  input: RemoveResourceUsersInput;
}>;

export type RemoveUserResourcesMutation = {
  __typename?: "Mutation";
  removeResourceUsers:
    | { __typename: "CallToWebhookFailedError"; message: string }
    | { __typename: "OidcIDTokenNotFoundError"; message: string }
    | {
        __typename: "OpalAdminRoleMustHaveAtLeastOneDirectUser";
        message: string;
      }
    | { __typename: "RemoveResourceUsersResult"; taskId: string }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type ForfeitResourceMutationVariables = Exact<{
  input: ForfeitResourceInput;
}>;

export type ForfeitResourceMutation = {
  __typename?: "Mutation";
  forfeitResource:
    | { __typename: "ForfeitResourceResult"; taskId: string }
    | { __typename: "OidcIDTokenNotFoundError"; message: string }
    | {
        __typename: "OpalAdminRoleMustHaveAtLeastOneDirectUser";
        message: string;
      }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type AwsRoleMetadataFragment = {
  __typename?: "AwsRoleMetadata";
  arn: string;
};

export type OktaDirectoryAppMetadataFragment = {
  __typename?: "OktaDirectoryAppMetadata";
  appId: string;
  appName: string;
  logoUrl?: string | null;
};

export type ResourceVisibilityGroupFragment = {
  __typename?: "ConfigurationVisibilityGroup";
  visibilityGroupId: string;
  visibilityGroup: { __typename?: "Group"; name: string };
};

export type ResourcePreviewTinyFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  resourceType: ResourceType;
  parentResource?: { __typename?: "Resource"; id: string; name: string } | null;
};

export type ResourcePreviewSmallFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  isManaged: boolean;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  parentResourceId?: string | null;
  connectionId: string;
  createdAt: string;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  parentResource?: { __typename?: "Resource"; id: string; name: string } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
};

export type ResourcePreviewWithAccessLevelsFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  parentResourceId?: string | null;
  connectionId: string;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  parentResource?: { __typename?: "Resource"; id: string; name: string } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  accessLevels?: Array<{
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  }> | null;
};

export type ResourcePreviewLargeFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  parentResourceId?: string | null;
  authorizedActions?: Array<string> | null;
  customRequestNotification?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  childResources?: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    parentResourceId?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
  }> | null;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  parentResource?: { __typename?: "Resource"; id: string; name: string } | null;
};

export type ChildResourcePreviewFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  maxDurationInMinutes?: number | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  autoApproval: boolean;
  parentResourceId?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
};

export type ResourcePreviewWithContainingGroupsFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  maxDurationInMinutes?: number | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  autoApproval: boolean;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    group?: {
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
};

export type ResourceFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  authorizedActions?: Array<string> | null;
  requestTemplateId?: string | null;
  parentResourceId?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  principalAssignmentsForEntity: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  entityAssignmentsForPrincipal: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    group?: {
      __typename: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  auditMessageChannels?: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }> | null;
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  parentResource?: { __typename?: "Resource"; id: string; name: string } | null;
  childResources?: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    parentResourceId?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
  }> | null;
};

type ResourceMetadata_AwsRoleMetadata_Fragment = {
  __typename: "AwsRoleMetadata";
  arn: string;
};

type ResourceMetadata_OktaDirectoryAppMetadata_Fragment = {
  __typename: "OktaDirectoryAppMetadata";
  appId: string;
  appName: string;
  logoUrl?: string | null;
};

type ResourceMetadata_OktaRoleMetadata_Fragment = {
  __typename: "OktaRoleMetadata";
  roleId: string;
  roleType: string;
};

type ResourceMetadata_PropagationTicketOwnerMetadata_Fragment = {
  __typename: "PropagationTicketOwnerMetadata";
  ownerId?: string | null;
  owner?: { __typename?: "Owner"; id: string; name: string } | null;
};

export type ResourceMetadataFragment =
  | ResourceMetadata_AwsRoleMetadata_Fragment
  | ResourceMetadata_OktaDirectoryAppMetadata_Fragment
  | ResourceMetadata_OktaRoleMetadata_Fragment
  | ResourceMetadata_PropagationTicketOwnerMetadata_Fragment;

export type RemoteResourceFragment = {
  __typename: "RemoteResource";
  id: string;
  name: string;
  metadata: string;
  iconUrl?: string | null;
  resourceType: ResourceType;
};

export type ResourcesHomeQueryVariables = Exact<{
  input: ResourcesInput;
}>;

export type ResourcesHomeQuery = {
  __typename?: "Query";
  resources: {
    __typename: "ResourcesResult";
    cursor?: string | null;
    totalNumResources: number;
    resources: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type NonHumanIdentityResourcesQueryVariables = Exact<{
  input: ResourcesInput;
}>;

export type NonHumanIdentityResourcesQuery = {
  __typename?: "Query";
  resources: {
    __typename: "ResourcesResult";
    cursor?: string | null;
    totalNumResources: number;
    resources: Array<{
      __typename: "Resource";
      numEntityAssignmentsForPrincipal: number;
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    }>;
  };
};

export type FilteredResourcesLargeQueryVariables = Exact<{
  input: FilteredResourcesInput;
}>;

export type FilteredResourcesLargeQuery = {
  __typename?: "Query";
  filteredResources: {
    __typename: "FilteredResourcesResult";
    cursor?: string | null;
    resources: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type ResourceQueryVariables = Exact<{
  input: ResourceInput;
}>;

export type ResourceQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          authorizedActions?: Array<string> | null;
          requestTemplateId?: string | null;
          parentResourceId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          principalAssignmentsForEntity: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          entityAssignmentsForPrincipal: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            group?: {
              __typename: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          childResources?: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            parentResourceId?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
          }> | null;
        };
      };
};

export type ResourcePreviewQueryVariables = Exact<{
  input: ResourceInput;
}>;

export type ResourcePreviewQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
};

export type ResourcePreviewWithAccessLevelsQueryVariables = Exact<{
  input: ResourceInput;
}>;

export type ResourcePreviewWithAccessLevelsQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          accessLevels?: Array<{
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          }> | null;
        };
      };
};

export type RemoteResourcesQueryVariables = Exact<{
  input: RemoteResourcesInput;
}>;

export type RemoteResourcesQuery = {
  __typename?: "Query";
  remoteResources:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "MissingIAMPermissionError"; message: string }
    | {
        __typename: "RemoteResourcesResult";
        remoteResources: Array<{
          __typename: "RemoteResource";
          id: string;
          name: string;
          metadata: string;
          iconUrl?: string | null;
          resourceType: ResourceType;
        }>;
      }
    | { __typename: "UserFacingError"; message: string };
};

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;

export type CreateResourceMutation = {
  __typename?: "Mutation";
  createResource:
    | { __typename: "AWSRoleCreationError"; message: string }
    | { __typename: "AWSRolePolicyInvalidError"; message: string }
    | {
        __typename: "CreateResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
};

export type CreateResourcesMutationVariables = Exact<{
  input: CreateResourcesInput;
}>;

export type CreateResourcesMutation = {
  __typename?: "Mutation";
  createResources: {
    __typename: "CreateResourcesResult";
    entries: Array<{
      __typename: "CreateResourceEntryResult";
      resource: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        recommendedDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        isManaged: boolean;
        autoApproval: boolean;
        reasonOptional: boolean;
        requestTemplateId?: string | null;
        parentResourceId?: string | null;
        authorizedActions?: Array<string> | null;
        customRequestNotification?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        childResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          autoApproval: boolean;
          parentResourceId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
        }> | null;
        requestTemplate?: {
          __typename?: "RequestTemplate";
          id: string;
          name: string;
          isDeleted?: boolean | null;
          customFields?: Array<{
            __typename?: "RequestTemplateCustomField";
            name: string;
            type: RequestTemplateCustomFieldType;
            required?: boolean | null;
            description?: string | null;
            metadata?: {
              __typename?: "RequestTemplateCustomFieldMetadata";
              multiChoiceData?: {
                __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                options?: Array<{
                  __typename?: "MultiChoiceOption";
                  value: string;
                  label: string;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      };
    }>;
    syncTask: {
      __typename?: "SyncTask";
      id: string;
      createdAt: string;
      updatedAt: string;
      syncType: SyncType;
      startedByUserId: string;
      status: SyncTaskStatus;
      startedByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      progress?: {
        __typename?: "SyncTaskProgress";
        steps: Array<{
          __typename?: "SyncTaskStep";
          name: string;
          totalNumItems: number;
          numItemsCompleted: number;
        }>;
      } | null;
    };
  };
};

export type UpdateResourcesMutationVariables = Exact<{
  input: UpdateResourcesInput;
}>;

export type UpdateResourcesMutation = {
  __typename?: "Mutation";
  updateResources:
    | { __typename: "ConfigurationVisibilityGroupNotFoundError" }
    | { __typename: "InvalidUpdateResourceVisibilityGroupError" }
    | { __typename: "OpalResourceImmutableError"; message: string }
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "UpdateResourcesResult";
        resources: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        }>;
      };
};

export type DeleteResourcesMutationVariables = Exact<{
  input: DeleteResourcesInput;
}>;

export type DeleteResourcesMutation = {
  __typename?: "Mutation";
  deleteResources:
    | { __typename: "DeleteResourcesResult"; success: boolean }
    | { __typename: "OpalResourceImmutableError"; message: string }
    | { __typename: "ResourceNotFoundError"; message: string };
};

export type ImportCustomResourcesDryRunMutationVariables = Exact<{
  input: ImportCustomResourcesInput;
}>;

export type ImportCustomResourcesDryRunMutation = {
  __typename?: "Mutation";
  importCustomResourcesDryRun:
    | {
        __typename: "ImportCustomResourcesDryRunResult";
        resourcesToCreate: Array<string>;
        existingResources: Array<string>;
      }
    | {
        __typename: "ImportCustomResourcesInputValidationError";
        message: string;
        reasons: Array<string>;
      };
};

export type ImportCustomResourcesMutationVariables = Exact<{
  input: ImportCustomResourcesInput;
}>;

export type ImportCustomResourcesMutation = {
  __typename?: "Mutation";
  importCustomResources:
    | {
        __typename: "ImportCustomResourcesInputValidationError";
        message: string;
        reasons: Array<string>;
      }
    | { __typename: "ImportCustomResourcesResult"; success: boolean };
};

export type UpdateResourceVisibilityGroupsMutationVariables = Exact<{
  input: UpdateResourceVisibilityGroupsInput;
}>;

export type UpdateResourceVisibilityGroupsMutation = {
  __typename?: "Mutation";
  updateResourceVisibilityGroups:
    | {
        __typename: "ConfigurationVisibilityGroupNotFoundError";
        message: string;
      }
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "InvalidUpdateResourceVisibilityGroupError";
        message: string;
      }
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "UpdateResourceVisibilityGroupsResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
};

export type RecordResourceViewMutationVariables = Exact<{
  input: RecordResourceViewInput;
}>;

export type RecordResourceViewMutation = {
  __typename?: "Mutation";
  recordResourceView: {
    __typename: "RecordResourceViewResult";
    success: boolean;
  };
};

export type RoleAssignmentResourceFragment = {
  __typename?: "Resource";
  name: string;
  isManaged: boolean;
  resourceType: ResourceType;
  iconUrl?: string | null;
  ancestorPathToResource?: string | null;
  resourceId: string;
  resourceRemoteId: string;
};

export type RoleAssignmentGroupFragment = {
  __typename?: "Group";
  name: string;
  isManaged: boolean;
  groupType: GroupType;
  iconUrl?: string | null;
  groupId: string;
  groupRemoteId?: string | null;
};

export type RoleAssignmentUserFragment = {
  __typename?: "User";
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  avatarUrl: string;
  isSystemUser: boolean;
  userId: string;
  name: string;
};

type Entity_Group_Fragment = {
  __typename?: "Group";
  name: string;
  isManaged: boolean;
  groupType: GroupType;
  iconUrl?: string | null;
  groupId: string;
  groupRemoteId?: string | null;
};

type Entity_Resource_Fragment = {
  __typename?: "Resource";
  name: string;
  isManaged: boolean;
  resourceType: ResourceType;
  iconUrl?: string | null;
  ancestorPathToResource?: string | null;
  resourceId: string;
  resourceRemoteId: string;
};

export type EntityFragment = Entity_Group_Fragment | Entity_Resource_Fragment;

type Principal_Group_Fragment = {
  __typename?: "Group";
  name: string;
  isManaged: boolean;
  groupType: GroupType;
  iconUrl?: string | null;
  groupId: string;
  groupRemoteId?: string | null;
};

type Principal_Resource_Fragment = {
  __typename?: "Resource";
  name: string;
  isManaged: boolean;
  resourceType: ResourceType;
  iconUrl?: string | null;
  ancestorPathToResource?: string | null;
  resourceId: string;
  resourceRemoteId: string;
};

type Principal_User_Fragment = {
  __typename?: "User";
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  avatarUrl: string;
  isSystemUser: boolean;
  userId: string;
  name: string;
};

export type PrincipalFragment =
  | Principal_Group_Fragment
  | Principal_Resource_Fragment
  | Principal_User_Fragment;

export type RoleAssignmentFragment = {
  __typename: "RoleAssignment";
  entityID: string;
  entityType: EntityType;
  principalID: string;
  principalType: EntityType;
  provisionSource: ProvisionSource;
  entity?:
    | {
        __typename?: "Group";
        name: string;
        isManaged: boolean;
        groupType: GroupType;
        iconUrl?: string | null;
        groupId: string;
        groupRemoteId?: string | null;
      }
    | {
        __typename?: "Resource";
        name: string;
        isManaged: boolean;
        resourceType: ResourceType;
        iconUrl?: string | null;
        ancestorPathToResource?: string | null;
        resourceId: string;
        resourceRemoteId: string;
      }
    | null;
  principal?:
    | {
        __typename?: "Group";
        name: string;
        isManaged: boolean;
        groupType: GroupType;
        iconUrl?: string | null;
        groupId: string;
        groupRemoteId?: string | null;
      }
    | {
        __typename?: "Resource";
        name: string;
        isManaged: boolean;
        resourceType: ResourceType;
        iconUrl?: string | null;
        ancestorPathToResource?: string | null;
        resourceId: string;
        resourceRemoteId: string;
      }
    | {
        __typename?: "User";
        firstName: string;
        lastName: string;
        fullName: string;
        email: string;
        avatarUrl: string;
        isSystemUser: boolean;
        userId: string;
        name: string;
      }
    | null;
  accessLevel?: {
    __typename: "AccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  } | null;
  access?: {
    __typename?: "Access";
    entityId: string;
    entityType: EntityType;
    principalId: string;
    principalType: EntityType;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    latestExpiringAccessPoint: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "PropagationStatus";
    roleAssignmentId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type RoleAssignmentPreviewFragment = {
  __typename?: "RoleAssignment";
  entityID: string;
  entityType: EntityType;
  principalID: string;
  principalType: EntityType;
  provisionSource: ProvisionSource;
  accessLevel?: {
    __typename: "AccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  } | null;
  access?: {
    __typename?: "Access";
    entityId: string;
    entityType: EntityType;
    principalId: string;
    principalType: EntityType;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    latestExpiringAccessPoint: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "AccessPoint";
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      principalId: string;
      principalType: EntityType;
      principalName: string;
      eventId?: string | null;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "PropagationStatus";
    roleAssignmentId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
  } | null;
};

export type AddRoleAssignmentsMutationVariables = Exact<{
  input: AddRoleAssignmentsInput;
}>;

export type AddRoleAssignmentsMutation = {
  __typename?: "Mutation";
  addRoleAssignments:
    | { __typename: "AddRoleAssignmentsResult"; taskIds: Array<string> }
    | { __typename: "UserFacingError"; message: string };
};

export type RemoveRoleAssignmentsMutationVariables = Exact<{
  input: RemoveRoleAssignmentsInput;
}>;

export type RemoveRoleAssignmentsMutation = {
  __typename?: "Mutation";
  removeRoleAssignments: {
    __typename: "RemoveRoleAssignmentsResult";
    taskIds: Array<string>;
  };
};

export type SearchResultEntryFragment = {
  __typename?: "SearchResultEntry";
  connectionId?: string | null;
  name: string;
  searchType: SearchType;
  resourceType: ResourceType;
  groupType: GroupType;
  avatarUrl?: string | null;
  annotationText?: string | null;
  objectId: {
    __typename?: "EntityIdTuple";
    entityId: string;
    entityType: EntityType;
  };
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
};

export type SearchQueryVariables = Exact<{
  input: SearchInput;
}>;

export type SearchQuery = {
  __typename?: "Query";
  search: {
    __typename: "SearchResult";
    entries: Array<{
      __typename?: "SearchResultEntry";
      connectionId?: string | null;
      name: string;
      searchType: SearchType;
      resourceType: ResourceType;
      groupType: GroupType;
      avatarUrl?: string | null;
      annotationText?: string | null;
      objectId: {
        __typename?: "EntityIdTuple";
        entityId: string;
        entityType: EntityType;
      };
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
    }>;
  };
};

export type AwsIamFederatedRoleSessionFragment = {
  __typename?: "AwsIamFederatedRoleSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  awsLoginUrl: string;
  federatedArn: string;
};

export type AwsIamFederatedSsmSessionFragment = {
  __typename?: "AwsIamFederatedSSMSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  awsLoginUrl: string;
  federatedArn: string;
  ec2InstanceId: string;
  ec2Region: string;
};

export type AwsIamFederatedRdsSessionFragment = {
  __typename?: "AwsIamFederatedRdsSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  federatedArn: string;
  dbInstanceId: string;
  dbPassword: string;
  dbEngine: string;
  dbUser: string;
  dbHostname: string;
  dbPort: number;
  dbName: string;
};

export type AwsIamFederatedEksSessionFragment = {
  __typename?: "AwsIamFederatedEksSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  federatedArn: string;
  clusterName: string;
  clusterRegion: string;
};

export type TlsConfigFragment = {
  __typename?: "TlsConfig";
  tlsMode: boolean;
  tlsCaCertContent?: string | null;
};

export type VaultMongoSessionFragment = {
  __typename?: "VaultMongoSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  authenticationDbName: string;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

export type VaultMongoAtlasSessionFragment = {
  __typename?: "VaultMongoAtlasSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  initDbName: string;
};

export type VaultMySqlMariaDbSessionFragment = {
  __typename?: "VaultMySQLMariaDBSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

export type VaultPostgresSessionFragment = {
  __typename?: "VaultPostgresSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

type SessionMetadata_AwsIamFederatedEksSession_Fragment = {
  __typename: "AwsIamFederatedEksSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  federatedArn: string;
  clusterName: string;
  clusterRegion: string;
};

type SessionMetadata_AwsIamFederatedRdsSession_Fragment = {
  __typename: "AwsIamFederatedRdsSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  federatedArn: string;
  dbInstanceId: string;
  dbPassword: string;
  dbEngine: string;
  dbUser: string;
  dbHostname: string;
  dbPort: number;
  dbName: string;
};

type SessionMetadata_AwsIamFederatedRoleSession_Fragment = {
  __typename: "AwsIamFederatedRoleSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  awsLoginUrl: string;
  federatedArn: string;
};

type SessionMetadata_AwsIamFederatedSsmSession_Fragment = {
  __typename: "AwsIamFederatedSSMSession";
  id: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsSessionToken: string;
  awsLoginUrl: string;
  federatedArn: string;
  ec2InstanceId: string;
  ec2Region: string;
};

type SessionMetadata_OidcSession_Fragment = { __typename: "OidcSession" };

type SessionMetadata_OpalImpersonationSession_Fragment = {
  __typename: "OpalImpersonationSession";
};

type SessionMetadata_VaultMongoAtlasSession_Fragment = {
  __typename: "VaultMongoAtlasSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  initDbName: string;
};

type SessionMetadata_VaultMongoSession_Fragment = {
  __typename: "VaultMongoSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  authenticationDbName: string;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

type SessionMetadata_VaultMySqlMariaDbSession_Fragment = {
  __typename: "VaultMySQLMariaDBSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

type SessionMetadata_VaultPostgresSession_Fragment = {
  __typename: "VaultPostgresSession";
  id: string;
  dbUser: string;
  dbPassword: string;
  dbHostname: string;
  dbPort: number;
  tlsConfig: {
    __typename?: "TlsConfig";
    tlsMode: boolean;
    tlsCaCertContent?: string | null;
  };
};

export type SessionMetadataFragment =
  | SessionMetadata_AwsIamFederatedEksSession_Fragment
  | SessionMetadata_AwsIamFederatedRdsSession_Fragment
  | SessionMetadata_AwsIamFederatedRoleSession_Fragment
  | SessionMetadata_AwsIamFederatedSsmSession_Fragment
  | SessionMetadata_OidcSession_Fragment
  | SessionMetadata_OpalImpersonationSession_Fragment
  | SessionMetadata_VaultMongoAtlasSession_Fragment
  | SessionMetadata_VaultMongoSession_Fragment
  | SessionMetadata_VaultMySqlMariaDbSession_Fragment
  | SessionMetadata_VaultPostgresSession_Fragment;

export type SessionPreviewFragment = {
  __typename?: "Session";
  id: string;
  durationInMinutes?: number | null;
  endTime: string;
  accessLevel?: string | null;
  accessLevelRemoteId?: string | null;
  metadata:
    | {
        __typename: "AwsIamFederatedEksSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        federatedArn: string;
        clusterName: string;
        clusterRegion: string;
      }
    | {
        __typename: "AwsIamFederatedRdsSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        federatedArn: string;
        dbInstanceId: string;
        dbPassword: string;
        dbEngine: string;
        dbUser: string;
        dbHostname: string;
        dbPort: number;
        dbName: string;
      }
    | {
        __typename: "AwsIamFederatedRoleSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        awsLoginUrl: string;
        federatedArn: string;
      }
    | {
        __typename: "AwsIamFederatedSSMSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        awsLoginUrl: string;
        federatedArn: string;
        ec2InstanceId: string;
        ec2Region: string;
      }
    | { __typename: "OidcSession" }
    | { __typename: "OpalImpersonationSession" }
    | {
        __typename: "VaultMongoAtlasSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        initDbName: string;
      }
    | {
        __typename: "VaultMongoSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        authenticationDbName: string;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      }
    | {
        __typename: "VaultMySQLMariaDBSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      }
    | {
        __typename: "VaultPostgresSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      };
};

export type SessionFragment = {
  __typename?: "Session";
  id: string;
  resourceId?: string | null;
  connectionId: string;
  createdAt: string;
  userId: string;
  durationInMinutes?: number | null;
  endTime: string;
  accessLevel?: string | null;
  accessLevelRemoteId?: string | null;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  metadata:
    | {
        __typename: "AwsIamFederatedEksSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        federatedArn: string;
        clusterName: string;
        clusterRegion: string;
      }
    | {
        __typename: "AwsIamFederatedRdsSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        federatedArn: string;
        dbInstanceId: string;
        dbPassword: string;
        dbEngine: string;
        dbUser: string;
        dbHostname: string;
        dbPort: number;
        dbName: string;
      }
    | {
        __typename: "AwsIamFederatedRoleSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        awsLoginUrl: string;
        federatedArn: string;
      }
    | {
        __typename: "AwsIamFederatedSSMSession";
        id: string;
        awsAccessKeyId: string;
        awsSecretAccessKey: string;
        awsSessionToken: string;
        awsLoginUrl: string;
        federatedArn: string;
        ec2InstanceId: string;
        ec2Region: string;
      }
    | { __typename: "OidcSession" }
    | { __typename: "OpalImpersonationSession" }
    | {
        __typename: "VaultMongoAtlasSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        initDbName: string;
      }
    | {
        __typename: "VaultMongoSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        authenticationDbName: string;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      }
    | {
        __typename: "VaultMySQLMariaDBSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      }
    | {
        __typename: "VaultPostgresSession";
        id: string;
        dbUser: string;
        dbPassword: string;
        dbHostname: string;
        dbPort: number;
        tlsConfig: {
          __typename?: "TlsConfig";
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        };
      };
};

export type SessionsQueryVariables = Exact<{
  input: SessionsInput;
}>;

export type SessionsQuery = {
  __typename?: "Query";
  sessions: {
    __typename: "SessionsResult";
    sessions: Array<{
      __typename?: "Session";
      id: string;
      resourceId?: string | null;
      connectionId: string;
      createdAt: string;
      userId: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
  };
};

export type VaultSessionsQueryVariables = Exact<{
  input: VaultSessionsInput;
}>;

export type VaultSessionsQuery = {
  __typename?: "Query";
  vaultSessions: {
    __typename: "VaultSessionsResult";
    sessions: Array<{
      __typename?: "Session";
      id: string;
      resourceId?: string | null;
      connectionId: string;
      createdAt: string;
      userId: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
  };
};

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;

export type CreateSessionMutation = {
  __typename?: "Mutation";
  createSession:
    | {
        __typename: "CreateSessionResult";
        session: {
          __typename?: "Session";
          id: string;
          resourceId?: string | null;
          connectionId: string;
          createdAt: string;
          userId: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        };
      }
    | { __typename: "EndSystemAuthorizationError"; message: string }
    | { __typename: "GroupNotFoundError" }
    | { __typename: "ImpersonationSessionLengthError"; message: string }
    | { __typename: "MfaInvalidError"; message: string }
    | { __typename: "NotSessionableError"; message: string }
    | { __typename: "OidcIDTokenNotFoundError"; message: string }
    | { __typename: "ResourceNotFoundError"; message: string }
    | { __typename: "SessionNotFoundError"; message: string }
    | { __typename: "UserFacingError"; message: string }
    | { __typename: "UserImpersonationDisabledError"; message: string }
    | { __typename: "VaultClientNotFoundError"; message: string }
    | {
        __typename: "VaultSessionError";
        message: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
        } | null;
      };
};

export type SupportTicketFragment = {
  __typename?: "SupportTicket";
  id?: string | null;
  remoteId: string;
  url: string;
  identifier: string;
  thirdPartyProvider: ThirdPartyProvider;
  summary: string;
  issueType: string;
  projectName: string;
};

export type SupportTicketPreviewFragment = {
  __typename?: "SupportTicket";
  id?: string | null;
  remoteId: string;
  url: string;
  identifier: string;
  thirdPartyProvider: ThirdPartyProvider;
  projectName: string;
};

export type SupportTicketsResultFragment = {
  __typename?: "SupportTicketsResult";
  tickets: Array<{
    __typename?: "SupportTicket";
    id?: string | null;
    remoteId: string;
    url: string;
    identifier: string;
    thirdPartyProvider: ThirdPartyProvider;
    summary: string;
    issueType: string;
    projectName: string;
  }>;
};

export type TicketProjectFragment = {
  __typename?: "TicketProject";
  name: string;
  id: string;
  key: string;
  avatarUrl?: string | null;
};

export type TicketsQueryVariables = Exact<{
  input: SupportTicketsInput;
}>;

export type TicketsQuery = {
  __typename?: "Query";
  supportTickets:
    | { __typename: "SupportTicketAPIKeyNotFoundError"; message: string }
    | {
        __typename: "SupportTicketsResult";
        tickets: Array<{
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          summary: string;
          issueType: string;
          projectName: string;
        }>;
      }
    | { __typename: "UserFacingError"; message: string };
};

export type TicketProjectsQueryVariables = Exact<{
  input: SupportTicketsInput;
}>;

export type TicketProjectsQuery = {
  __typename?: "Query";
  ticketProjects:
    | { __typename: "SupportTicketAPIKeyNotFoundError"; message: string }
    | {
        __typename: "TicketProjectsResult";
        ticketProjects: Array<{
          __typename?: "TicketProject";
          name: string;
          id: string;
          key: string;
          avatarUrl?: string | null;
        }>;
      };
};

export type TicketQueryVariables = Exact<{
  input: SupportTicketByIdInput;
}>;

export type TicketQuery = {
  __typename?: "Query";
  supportTicket:
    | { __typename: "SupportTicketNotFoundError"; message: string }
    | {
        __typename: "SupportTicketResult";
        ticket: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          summary: string;
          issueType: string;
          projectName: string;
        };
      };
};

export type SyncTaskFragment = {
  __typename?: "SyncTask";
  id: string;
  createdAt: string;
  updatedAt: string;
  syncType: SyncType;
  startedByUserId: string;
  status: SyncTaskStatus;
  startedByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  progress?: {
    __typename?: "SyncTaskProgress";
    steps: Array<{
      __typename?: "SyncTaskStep";
      name: string;
      totalNumItems: number;
      numItemsCompleted: number;
    }>;
  } | null;
};

export type SyncErrorFragment = {
  __typename?: "SyncError";
  id: string;
  createdAt: string;
  firstSeenAt: string;
  connectionId?: string | null;
  message: string;
  stackTrace: string;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
  } | null;
};

export type SyncStepFragment = {
  __typename?: "SyncTaskStep";
  name: string;
  totalNumItems: number;
  numItemsCompleted: number;
};

export type SyncStatusQueryVariables = Exact<{
  input: SyncStatusInput;
}>;

export type SyncStatusQuery = {
  __typename?: "Query";
  syncStatus:
    | { __typename: "AccessReviewNotFoundError" }
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "InvalidSyncTypeError"; message: string }
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "SyncStatusResult";
        lastSuccessfulSyncTask?: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        } | null;
        ongoingSyncTask?: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        } | null;
        syncErrors: Array<{
          __typename?: "SyncError";
          id: string;
          createdAt: string;
          firstSeenAt: string;
          connectionId?: string | null;
          message: string;
          stackTrace: string;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
        }>;
      };
};

export type SyncTaskQueryVariables = Exact<{
  input: SyncTaskInput;
}>;

export type SyncTaskQuery = {
  __typename?: "Query";
  syncTask:
    | { __typename: "SyncTaskNotFoundError"; message: string }
    | {
        __typename: "SyncTaskResult";
        syncTask: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        };
      };
};

export type StartSyncMutationVariables = Exact<{
  input: StartSyncInput;
}>;

export type StartSyncMutation = {
  __typename?: "Mutation";
  startSync:
    | { __typename: "AccessReviewNotFoundError" }
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "GroupNotFoundError"; message: string }
    | { __typename: "InvalidSyncTypeError"; message: string }
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "StartSyncResult";
        syncTask: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        };
      };
};

export type DismissSyncErrorsMutationVariables = Exact<{
  input: DismissSyncErrorsInput;
}>;

export type DismissSyncErrorsMutation = {
  __typename?: "Mutation";
  dismissSyncErrors:
    | { __typename: "DismissSyncErrorsResult"; ids: Array<string> }
    | { __typename: "SyncErrorNotFoundError"; message: string };
};

export type TagPreviewSmallFragment = {
  __typename: "Tag";
  id: string;
  createdAt: string;
  creatorUserId: string;
  key: string;
  value?: string | null;
  isStandardAttribute: boolean;
};

export type TagPreviewLargeFragment = {
  __typename: "Tag";
  id: string;
  createdAt: string;
  creatorUserId: string;
  key: string;
  value?: string | null;
  isStandardAttribute: boolean;
  creator?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type TagFragment = {
  __typename: "Tag";
  id: string;
  createdAt: string;
  creatorUserId: string;
  key: string;
  value?: string | null;
  isStandardAttribute: boolean;
  creator?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  tagUsers: Array<{
    __typename?: "UserTag";
    id: string;
    userId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  tagResources: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  tagGroups: Array<{
    __typename?: "GroupTag";
    groupId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    group?: {
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
};

export type TagsQueryVariables = Exact<{
  input: TagsInput;
}>;

export type TagsQuery = {
  __typename?: "Query";
  tags: {
    __typename: "TagsResult";
    cursor?: string | null;
    tags: Array<{
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
      creator?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type TagQueryVariables = Exact<{
  input: TagInput;
}>;

export type TagQuery = {
  __typename?: "Query";
  tag:
    | { __typename: "TagNotFoundError"; message: string }
    | {
        __typename: "TagResult";
        tag: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
          creator?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          tagUsers: Array<{
            __typename?: "UserTag";
            id: string;
            userId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          tagResources: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          tagGroups: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
        };
      };
};

export type CreateTagMutationVariables = Exact<{
  input: CreateTagInput;
}>;

export type CreateTagMutation = {
  __typename?: "Mutation";
  createTag:
    | { __typename: "CreateTagResult"; tag: { __typename?: "Tag"; id: string } }
    | { __typename: "TagExistsError"; message: string };
};

export type DeleteTagsMutationVariables = Exact<{
  input: DeleteTagsInput;
}>;

export type DeleteTagsMutation = {
  __typename?: "Mutation";
  deleteTags:
    | {
        __typename: "DeleteTagsResult";
        entries: Array<{
          __typename?: "DeleteTagsEntryResult";
          tag: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
            creator?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            tagUsers: Array<{
              __typename?: "UserTag";
              id: string;
              userId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            tagResources: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                recommendedDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                isManaged: boolean;
                autoApproval: boolean;
                reasonOptional: boolean;
                requestTemplateId?: string | null;
                parentResourceId?: string | null;
                authorizedActions?: Array<string> | null;
                customRequestNotification?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                childResources?: Array<{
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  createdAt: string;
                  description: string;
                  connectionId: string;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  maxDurationInMinutes?: number | null;
                  requireMfaToConnect: boolean;
                  requireMfaToApprove: boolean;
                  requireMfaToRequest: boolean;
                  requireSupportTicket: boolean;
                  isRequestable: boolean;
                  autoApproval: boolean;
                  parentResourceId?: string | null;
                  adminOwner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                    ownerUsers: Array<{
                      __typename?: "OwnerUser";
                      user?: { __typename?: "User"; id: string } | null;
                    }>;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  currentUserAccess: {
                    __typename?: "CurrentUserResourceAccess";
                    resourceId: string;
                    numAvailableAccessLevels: number;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    activeSessions: Array<{
                      __typename?: "Session";
                      id: string;
                      durationInMinutes?: number | null;
                      endTime: string;
                      accessLevel?: string | null;
                      accessLevelRemoteId?: string | null;
                      metadata:
                        | {
                            __typename: "AwsIamFederatedEksSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            clusterName: string;
                            clusterRegion: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRdsSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            federatedArn: string;
                            dbInstanceId: string;
                            dbPassword: string;
                            dbEngine: string;
                            dbUser: string;
                            dbHostname: string;
                            dbPort: number;
                            dbName: string;
                          }
                        | {
                            __typename: "AwsIamFederatedRoleSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                          }
                        | {
                            __typename: "AwsIamFederatedSSMSession";
                            id: string;
                            awsAccessKeyId: string;
                            awsSecretAccessKey: string;
                            awsSessionToken: string;
                            awsLoginUrl: string;
                            federatedArn: string;
                            ec2InstanceId: string;
                            ec2Region: string;
                          }
                        | { __typename: "OidcSession" }
                        | { __typename: "OpalImpersonationSession" }
                        | {
                            __typename: "VaultMongoAtlasSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            initDbName: string;
                          }
                        | {
                            __typename: "VaultMongoSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            authenticationDbName: string;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultMySQLMariaDBSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          }
                        | {
                            __typename: "VaultPostgresSession";
                            id: string;
                            dbUser: string;
                            dbPassword: string;
                            dbHostname: string;
                            dbPort: number;
                            tlsConfig: {
                              __typename?: "TlsConfig";
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            };
                          };
                    }>;
                    pendingRequests: Array<{
                      __typename?: "Request";
                      id: string;
                      requesterId: string;
                      targetUserId?: string | null;
                      targetGroupId?: string | null;
                      resourceProposalId?: string | null;
                      createdAt: string;
                      updatedAt: string;
                      status: RequestStatus;
                      requester?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetUser?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                      targetGroup?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      requestedResources: Array<{
                        __typename?: "RequestedResource";
                        resourceId: string;
                        resource?: {
                          __typename: "Resource";
                          id: string;
                          name: string;
                          remoteName: string;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isDeleted: boolean;
                          isManaged: boolean;
                          resourceType: ResourceType;
                          remoteId: string;
                          iconUrl?: string | null;
                          parentResourceId?: string | null;
                          connectionId: string;
                          createdAt: string;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          parentResource?: {
                            __typename?: "Resource";
                            id: string;
                            name: string;
                          } | null;
                          connection?: {
                            __typename: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                            isDeleted: boolean;
                            metadata?:
                              | {
                                  __typename: "ADConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                }
                              | {
                                  __typename: "AwsConnectionMetadata";
                                  success?: boolean | null;
                                }
                              | {
                                  __typename: "AWSSSOConnectionMetadata";
                                  managementAccountId: string;
                                  ssoInstanceArn: string;
                                  identityStoreId: string;
                                  accessPortalUrl: string;
                                  awsRegion: string;
                                  awsOrganizationEnabled: boolean;
                                  awsSsoEnabled: boolean;
                                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                  awsOrganizationImportSetting: ImportSetting;
                                  ec2RoleChainingEnabled: boolean;
                                  podRoleChainingEnabled: boolean;
                                  cloudtrailEventsSqsUrl: string;
                                }
                              | {
                                  __typename: "AzureADConnectionMetadata";
                                  tenantId: string;
                                  clientId: string;
                                  azureInfraEnabled: boolean;
                                  eventHubNamespace: string;
                                  eventHub: string;
                                }
                              | {
                                  __typename: "CustomConnectorConnectionMetadata";
                                  identifier: string;
                                  baseUrl: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                  supportsGroups: boolean;
                                  supportsNestedResources: boolean;
                                }
                              | {
                                  __typename: "DuoConnectionMetadata";
                                  apiHostname: string;
                                }
                              | {
                                  __typename: "GCPConnectionMetadata";
                                  pubSubProjectId: string;
                                  subscriptionId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GitHubConnectionMetadata";
                                  orgName: string;
                                }
                              | {
                                  __typename: "GitLabConnectionMetadata";
                                  hostName?: string | null;
                                  appId: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "GoogleGroupsConnectionMetadata";
                                  opalGroupEmail: string;
                                  adminUserEmail: string;
                                  domain: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "GoogleWorkspaceConnectionMetadata";
                                  adminUserEmail: string;
                                  customerId: string;
                                  serviceAccount: {
                                    __typename?: "GCPServiceAccount";
                                    type: string;
                                    projectId: string;
                                    clientEmail: string;
                                    clientId: string;
                                    authUri: string;
                                    tokenUri: string;
                                    authProviderX509CertUrl: string;
                                    clientX509CertUrl: string;
                                  };
                                }
                              | {
                                  __typename: "LDAPConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  groupsDistinguishedName: string;
                                  usersDistinguishedName: string;
                                  groupsUidKey: string;
                                }
                              | {
                                  __typename: "MongoAtlasConnectionMetadata";
                                  serverHostname: string;
                                }
                              | {
                                  __typename: "MongoConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "MySQLMariaDBConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "OktaDirectoryConnectionMetadata";
                                  orgUrl: string;
                                }
                              | { __typename: "PagerDutyConnectionMetadata" }
                              | {
                                  __typename: "PostgresConnectionMetadata";
                                  serverHostname: string;
                                  serverPort: number;
                                  tlsMode: boolean;
                                  tlsCertContent?: string | null;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "PropagationTicketConnectionMetadata";
                                  ticketProvider?: {
                                    __typename?: "TicketProvider";
                                    ticketProvider: ThirdPartyProvider;
                                    ticketProjectId: string;
                                    ticketProjectName: string;
                                  } | null;
                                }
                              | {
                                  __typename: "SalesforceConnectionMetadata";
                                  email: string;
                                  hostname: string;
                                }
                              | {
                                  __typename: "SnowflakeConnectionMetadata";
                                  organization: string;
                                  account: string;
                                  locator: string;
                                  accountIdentifier: string;
                                  accountUrl: string;
                                  serviceAccountName: string;
                                  serviceAccountRole: string;
                                }
                              | {
                                  __typename: "TailscaleConnectionMetadata";
                                  tailnet: string;
                                }
                              | {
                                  __typename: "TeleportConnectionMetadata";
                                  host: string;
                                  tlsMode: boolean;
                                  tlsCaCertContent?: string | null;
                                }
                              | {
                                  __typename: "WorkdayConnectionMetadata";
                                  tenantName: string;
                                  tenantUrl: string;
                                }
                              | null;
                          } | null;
                          metadata?:
                            | { __typename: "AwsRoleMetadata"; arn: string }
                            | {
                                __typename: "OktaDirectoryAppMetadata";
                                appId: string;
                                appName: string;
                                logoUrl?: string | null;
                              }
                            | {
                                __typename: "OktaRoleMetadata";
                                roleId: string;
                                roleType: string;
                              }
                            | {
                                __typename: "PropagationTicketOwnerMetadata";
                                ownerId?: string | null;
                                owner?: {
                                  __typename?: "Owner";
                                  id: string;
                                  name: string;
                                } | null;
                              }
                            | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        targetUserAccess?: {
                          __typename?: "UserResourceAccess";
                          resourceId: string;
                          userId: string;
                          resourceUsers: Array<{
                            __typename: "ResourceUser";
                            resourceId: string;
                            userId: string;
                            mostRecentSessionEnded?: string | null;
                            source: ResourceUserSource;
                            user?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              createdAt: string;
                              updatedAt: string;
                              organizationId: string;
                              position: string;
                              teamAttr?: string | null;
                              managerId?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                              manager?: {
                                __typename?: "User";
                                id: string;
                                email: string;
                                secondaryEmails: Array<string>;
                                firstName: string;
                                lastName: string;
                                fullName: string;
                                avatarUrl: string;
                                isDeleted: boolean;
                                isSystemUser: boolean;
                                position: string;
                                teamAttr?: string | null;
                                hrIdpStatus?: HrIdpStatus | null;
                              } | null;
                            } | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            access?: {
                              __typename?: "ResourceUserAccess";
                              resourceId: string;
                              userId: string;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              latestExpiringAccessPoint: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              inheritedAccessPoints: Array<{
                                __typename?: "ResourceUserAccessPoint";
                                resourceId: string;
                                userId: string;
                                resourceName: string;
                                resourceType: ResourceType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                accessLevel: {
                                  __typename: "ResourceAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                  accessLevelMetadata?: {
                                    __typename: "ImpersonationAccessLevelMetadata";
                                    avatarUrl: string;
                                  } | null;
                                };
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                              groupUserAccesses: Array<{
                                __typename?: "GroupUserAccess";
                                groupId: string;
                                userId: string;
                                isGroupManaged: boolean;
                                latestExpiringAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                directAccessPoint?: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                } | null;
                                indirectAccessPointPaths: Array<{
                                  __typename?: "IndirectGroupUserAccessPointPath";
                                  startGroupId: string;
                                  endGroupId: string;
                                  userId: string;
                                  resolvedAccessPoint: {
                                    __typename?: "GroupUserAccessPoint";
                                    groupId: string;
                                    userId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                    expiration?: string | null;
                                    supportTicketId?: string | null;
                                    source: GroupUserSource;
                                    accessLevel?: {
                                      __typename: "GroupAccessLevel";
                                      accessLevelName: string;
                                      accessLevelRemoteId: string;
                                    } | null;
                                    supportTicket?: {
                                      __typename?: "SupportTicket";
                                      id?: string | null;
                                      remoteId: string;
                                      url: string;
                                      identifier: string;
                                      thirdPartyProvider: ThirdPartyProvider;
                                      projectName: string;
                                    } | null;
                                  };
                                  indirectAccessPointPath: Array<{
                                    __typename?: "IndirectGroupAccessPoint";
                                    groupId: string;
                                    groupName: string;
                                    groupType: GroupType;
                                    eventId: string;
                                    createdAt: string;
                                  }>;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "ResourcePropagationStatus";
                              resourceId: string;
                              userId: string;
                              accessLevelRemoteId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              metadata?: {
                                __typename: "PropagationTicketMetadata";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                            } | null;
                          }>;
                        } | null;
                      }>;
                      requestedGroups: Array<{
                        __typename?: "RequestedGroup";
                        groupId: string;
                        group?: {
                          __typename?: "Group";
                          id: string;
                          remoteId?: string | null;
                          name: string;
                          remoteName: string;
                          numGroupUsers: number;
                          groupType: GroupType;
                          serviceType: ServiceType;
                          visibility: Visibility;
                          isManaged: boolean;
                          isDeleted: boolean;
                          iconUrl?: string | null;
                          isOnCallSynced: boolean;
                          connectionId: string;
                          groupBindingId?: string | null;
                          visibilityGroups: Array<{
                            __typename?: "ConfigurationVisibilityGroup";
                            visibilityGroupId: string;
                            visibilityGroup: {
                              __typename?: "Group";
                              name: string;
                            };
                          }>;
                          connection?: {
                            __typename?: "Connection";
                            id: string;
                            name: string;
                            connectionType: ConnectionType;
                          } | null;
                          groupBinding?: {
                            __typename?: "GroupBinding";
                            id: string;
                            sourceGroupId: string;
                            groups: Array<{
                              __typename?: "Group";
                              id: string;
                              name: string;
                              groupType: GroupType;
                              connectionId: string;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        targetUserAccess?: {
                          __typename?: "UserGroupAccess";
                          groupId: string;
                          userId: string;
                          groupUser?: {
                            __typename?: "GroupUser";
                            groupId: string;
                            userId: string;
                            source: GroupUserSource;
                            access?: {
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            } | null;
                            propagationStatus?: {
                              __typename?: "GroupPropagationStatus";
                              groupId: string;
                              userId: string;
                              lastSynced: string;
                              statusCode: PropagationStatusCode;
                              errorMessage?: string | null;
                              taskType: PropagationTaskType;
                              ticketPropagation?: {
                                __typename: "GroupUserTicketPropagation";
                                resourceTickets: Array<{
                                  __typename?: "ResourceTicketPropagation";
                                  ticket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                }>;
                              } | null;
                            } | null;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                      resourceProposal?: {
                        __typename?: "ResourceProposal";
                        id: string;
                        connectionId: string;
                        serviceType: ServiceType;
                        resourceType: ResourceType;
                        metadata?: {
                          __typename: "AWSRoleProposalMetadata";
                          policyName: string;
                          policyDocument: string;
                          policyDescription: string;
                          assumeRolePolicyDocument: string;
                          roleName: string;
                          roleDescription: string;
                          tags: Array<{
                            __typename?: "AWSTag";
                            key: string;
                            value?: string | null;
                          }>;
                        } | null;
                      } | null;
                    }>;
                  };
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                  commonMetadata: {
                    __typename?: "CommonMetadata";
                    matchRemoteName: boolean;
                    matchRemoteDescription: boolean;
                  };
                  tags: Array<{
                    __typename?: "ResourceTag";
                    id: string;
                    resourceId: string;
                    tagId: string;
                    source: ServiceType;
                    updatedAt: string;
                    createdAt: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    tag?: {
                      __typename: "Tag";
                      id: string;
                      createdAt: string;
                      creatorUserId: string;
                      key: string;
                      value?: string | null;
                      isStandardAttribute: boolean;
                    } | null;
                  }>;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                }> | null;
                requestTemplate?: {
                  __typename?: "RequestTemplate";
                  id: string;
                  name: string;
                  isDeleted?: boolean | null;
                  customFields?: Array<{
                    __typename?: "RequestTemplateCustomField";
                    name: string;
                    type: RequestTemplateCustomFieldType;
                    required?: boolean | null;
                    description?: string | null;
                    metadata?: {
                      __typename?: "RequestTemplateCustomFieldMetadata";
                      multiChoiceData?: {
                        __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                        options?: Array<{
                          __typename?: "MultiChoiceOption";
                          value: string;
                          label: string;
                        }> | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            tagGroups: Array<{
              __typename?: "GroupTag";
              groupId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              group?: {
                __typename: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                groupType: GroupType;
                serviceType: ServiceType;
                iconUrl?: string | null;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                updatedAt: string;
                description: string;
                numGroupUsers: number;
                connectionId: string;
                isOnCallSynced: boolean;
                maxDurationInMinutes?: number | null;
                recommendedDurationInMinutes?: number | null;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                isManaged: boolean;
                autoApproval: boolean;
                reasonOptional: boolean;
                requestTemplateId?: string | null;
                groupBindingId?: string | null;
                customRequestNotification?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserGroupAccess";
                  groupId: string;
                  hasBreakGlass: boolean;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                  pendingRequest?: {
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  } | null;
                };
                tags: Array<{
                  __typename?: "GroupTag";
                  groupId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                onCallSchedules?: Array<{
                  __typename?: "GroupOnCallSchedule";
                  onCallSchedule: {
                    __typename?: "OnCallSchedule";
                    remoteId: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    name: string;
                    existsInRemote: boolean;
                  };
                }> | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  createdAt: string;
                  sourceGroup?: {
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                  } | null;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                  }>;
                  createdByUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                groupLeaders: Array<{
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                }>;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
          };
        }>;
      }
    | { __typename: "TagNotFoundError"; message: string };
};

export type ThirdPartyIntegrationFragment = {
  __typename?: "ThirdPartyIntegration";
  id: string;
  thirdPartyProvider: ThirdPartyProvider;
  authFlowType: AuthFlowType;
  connectionActive?: boolean | null;
  connectionId?: string | null;
  slackMetadata?: {
    __typename?: "ThirdPartyIntegrationSlackMetadata";
    enterpriseId?: string | null;
    enterpriseName?: string | null;
    appId: string;
    workspaces: Array<{
      __typename?: "ThirdPartyIntegrationSlackWorkspace";
      id: string;
      name: string;
    }>;
  } | null;
};

export type ThirdPartyIntegrationQueryVariables = Exact<{
  input: ThirdPartyIntegrationInput;
}>;

export type ThirdPartyIntegrationQuery = {
  __typename?: "Query";
  thirdPartyIntegration:
    | { __typename: "ConnectionNotFoundError" }
    | { __typename: "ThirdPartyIntegrationNotFoundError"; message: string }
    | {
        __typename: "ThirdPartyIntegrationResult";
        thirdPartyIntegration: {
          __typename?: "ThirdPartyIntegration";
          id: string;
          thirdPartyProvider: ThirdPartyProvider;
          authFlowType: AuthFlowType;
          connectionActive?: boolean | null;
          connectionId?: string | null;
          slackMetadata?: {
            __typename?: "ThirdPartyIntegrationSlackMetadata";
            enterpriseId?: string | null;
            enterpriseName?: string | null;
            appId: string;
            workspaces: Array<{
              __typename?: "ThirdPartyIntegrationSlackWorkspace";
              id: string;
              name: string;
            }>;
          } | null;
        };
      };
};

export type ThirdPartyIntegrationsQueryVariables = Exact<{
  input: ThirdPartyIntegrationsInput;
}>;

export type ThirdPartyIntegrationsQuery = {
  __typename?: "Query";
  thirdPartyIntegrations: {
    __typename: "ThirdPartyIntegrationsResult";
    thirdPartyIntegrations: Array<{
      __typename?: "ThirdPartyIntegration";
      id: string;
      thirdPartyProvider: ThirdPartyProvider;
      authFlowType: AuthFlowType;
      connectionActive?: boolean | null;
      connectionId?: string | null;
      slackMetadata?: {
        __typename?: "ThirdPartyIntegrationSlackMetadata";
        enterpriseId?: string | null;
        enterpriseName?: string | null;
        appId: string;
        workspaces: Array<{
          __typename?: "ThirdPartyIntegrationSlackWorkspace";
          id: string;
          name: string;
        }>;
      } | null;
    }>;
  };
};

export type InitThirdPartyIntegrationAuthFlowMutationVariables = Exact<{
  input: InitThirdPartyIntegrationAuthFlowInput;
}>;

export type InitThirdPartyIntegrationAuthFlowMutation = {
  __typename?: "Mutation";
  initThirdPartyIntegrationAuthFlow:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | { __typename: "InitThirdPartyIntegrationAuthFlowResult"; url: string }
    | { __typename: "ThirdPartyIntegrationNotFoundError"; message: string };
};

export type CreateThirdPartyIntegrationMutationVariables = Exact<{
  input: CreateThirdPartyIntegrationInput;
}>;

export type CreateThirdPartyIntegrationMutation = {
  __typename?: "Mutation";
  createThirdPartyIntegration:
    | {
        __typename: "CreateThirdPartyIntegrationResult";
        thirdPartyIntegration: {
          __typename?: "ThirdPartyIntegration";
          id: string;
          thirdPartyProvider: ThirdPartyProvider;
          authFlowType: AuthFlowType;
          connectionActive?: boolean | null;
          connectionId?: string | null;
          slackMetadata?: {
            __typename?: "ThirdPartyIntegrationSlackMetadata";
            enterpriseId?: string | null;
            enterpriseName?: string | null;
            appId: string;
            workspaces: Array<{
              __typename?: "ThirdPartyIntegrationSlackWorkspace";
              id: string;
              name: string;
            }>;
          } | null;
        };
      }
    | { __typename: "SlackOrganizationAlreadyExistsError"; message: string }
    | { __typename: "ThirdPartyConnectionNotMatchedError"; message: string }
    | { __typename: "ThirdPartyUserNotMatchedError"; message: string }
    | { __typename: "UserFacingError"; message: string };
};

export type DeleteThirdPartyIntegrationMutationVariables = Exact<{
  input: DeleteThirdPartyIntegrationInput;
}>;

export type DeleteThirdPartyIntegrationMutation = {
  __typename?: "Mutation";
  deleteThirdPartyIntegration: {
    __typename: "DeleteThirdPartyIntegrationResult";
    thirdPartyIntegration: {
      __typename?: "ThirdPartyIntegration";
      id: string;
      thirdPartyProvider: ThirdPartyProvider;
      authFlowType: AuthFlowType;
      connectionActive?: boolean | null;
      connectionId?: string | null;
      slackMetadata?: {
        __typename?: "ThirdPartyIntegrationSlackMetadata";
        enterpriseId?: string | null;
        enterpriseName?: string | null;
        appId: string;
        workspaces: Array<{
          __typename?: "ThirdPartyIntegrationSlackWorkspace";
          id: string;
          name: string;
        }>;
      } | null;
    };
  };
};

export type InitThirdPartyIntegrationLinkTokenMutationVariables = Exact<{
  [key: string]: never;
}>;

export type InitThirdPartyIntegrationLinkTokenMutation = {
  __typename?: "Mutation";
  initThirdPartyIntegrationLinkAuthFlow: {
    __typename: "InitThirdPartyIntegrationLinkAuthFlowResult";
    linkToken: string;
  };
};

export type UserSettingsFragment = {
  __typename?: "UserSettings";
  id: string;
  createdAt: string;
  allNotifications: Array<NotificationType>;
};

export type UserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type UserSettingsQuery = {
  __typename?: "Query";
  userSettings:
    | { __typename: "UserSettingsInvalidError" }
    | { __typename: "UserSettingsNotFoundError" }
    | {
        __typename: "UserSettingsResult";
        userSettings: {
          __typename?: "UserSettings";
          id: string;
          createdAt: string;
          allNotifications: Array<NotificationType>;
        };
      };
};

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: "Mutation";
  updateUserSettings:
    | {
        __typename: "UpdateUserSettingsResult";
        userSettings: {
          __typename?: "UserSettings";
          id: string;
          createdAt: string;
          allNotifications: Array<NotificationType>;
        };
      }
    | { __typename: "UserSettingsInvalidError" }
    | { __typename: "UserSettingsNotFoundError" };
};

export type UserTagPreviewFragment = {
  __typename?: "UserTag";
  id: string;
  userId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type UserTagFragment = {
  __typename?: "UserTag";
  id: string;
  userId: string;
  tagId: string;
  source: ServiceType;
  updatedAt: string;
  createdAt: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  } | null;
  tag?: {
    __typename: "Tag";
    id: string;
    createdAt: string;
    creatorUserId: string;
    key: string;
    value?: string | null;
    isStandardAttribute: boolean;
  } | null;
};

export type AddUserTagsMutationVariables = Exact<{
  input: AddUserTagsInput;
}>;

export type AddUserTagsMutation = {
  __typename?: "Mutation";
  addUserTags:
    | {
        __typename: "AddUserTagsResult";
        entries: Array<{
          __typename: "AddUserTagsEntryResult";
          userTag: { __typename?: "UserTag"; userId: string; tagId: string };
        }>;
      }
    | { __typename: "TagNotFoundError"; message: string }
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      };
};

export type RemoveUserTagsMutationVariables = Exact<{
  input: RemoveUserTagsInput;
}>;

export type RemoveUserTagsMutation = {
  __typename?: "Mutation";
  removeUserTags:
    | {
        __typename: "RemoveUserTagsResult";
        entries: Array<{
          __typename: "RemoveUserTagsEntryResult";
          userTag: { __typename?: "UserTag"; userId: string; tagId: string };
        }>;
      }
    | { __typename: "UserTagNotFoundError"; message: string };
};

export type UserPreviewSmallFragment = {
  __typename?: "User";
  id: string;
  email: string;
  secondaryEmails: Array<string>;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  isDeleted: boolean;
  isSystemUser: boolean;
  position: string;
  teamAttr?: string | null;
  hrIdpStatus?: HrIdpStatus | null;
};

export type UserPreviewLargeFragment = {
  __typename?: "User";
  id: string;
  email: string;
  secondaryEmails: Array<string>;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  isDeleted: boolean;
  isSystemUser: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  position: string;
  teamAttr?: string | null;
  managerId?: string | null;
  hrIdpStatus?: HrIdpStatus | null;
  manager?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type UserFragment = {
  __typename?: "User";
  id: string;
  email: string;
  secondaryEmails: Array<string>;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  isDeleted: boolean;
  isSystemUser: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  position: string;
  teamAttr?: string | null;
  managerId?: string | null;
  hrIdpStatus?: HrIdpStatus | null;
  organization: {
    __typename?: "Organization";
    id: string;
    name: string;
    createdAt: string;
  };
  manager?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  directReports: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
  userResources: Array<{
    __typename?: "ResourceUser";
    resourceId: string;
    userId: string;
    source: ResourceUserSource;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  userGroups: Array<{
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    group?: {
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
  }>;
};

export type UserIdentityFragment = {
  __typename?: "UserIdentity";
  shouldShow: boolean;
  value: string;
};

export type UserIdentitiesFragment = {
  __typename?: "UserIdentities";
  gitHubUsername?: {
    __typename?: "UserIdentity";
    shouldShow: boolean;
    value: string;
  } | null;
  gitLabUserId?: {
    __typename?: "UserIdentity";
    shouldShow: boolean;
    value: string;
  } | null;
  teleportUsername?: {
    __typename?: "UserIdentity";
    shouldShow: boolean;
    value: string;
  } | null;
};

export type UsersQueryVariables = Exact<{
  input: UsersInput;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    totalNumUsers: number;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type UsersFullQueryVariables = Exact<{
  input: UsersInput;
}>;

export type UsersFullQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    totalNumUsers: number;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      organization: {
        __typename?: "Organization";
        id: string;
        name: string;
        createdAt: string;
      };
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      directReports: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      }>;
      userResources: Array<{
        __typename?: "ResourceUser";
        resourceId: string;
        userId: string;
        source: ResourceUserSource;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      userGroups: Array<{
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        group?: {
          __typename: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          groupType: GroupType;
          serviceType: ServiceType;
          iconUrl?: string | null;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          updatedAt: string;
          description: string;
          numGroupUsers: number;
          connectionId: string;
          isOnCallSynced: boolean;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          groupBindingId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          tags: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          groupLeaders: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        } | null;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
      }>;
    }>;
  };
};

export type UserQueryVariables = Exact<{
  input: UserInput;
}>;

export type UserQuery = {
  __typename?: "Query";
  user:
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      }
    | {
        __typename: "UserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          organization: {
            __typename?: "Organization";
            id: string;
            name: string;
            createdAt: string;
          };
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          directReports: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          userResources: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          userGroups: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          }>;
        };
      };
};

export type UsersHomeQueryVariables = Exact<{
  input: UsersInput;
}>;

export type UsersHomeQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    totalNumUsers: number;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser:
    | {
        __typename: "DeleteUserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          organization: {
            __typename?: "Organization";
            id: string;
            name: string;
            createdAt: string;
          };
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          directReports: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          userResources: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          userGroups: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          }>;
        };
      }
    | {
        __typename: "OpalAdminRoleMustHaveAtLeastOneDirectUser";
        message: string;
      }
    | { __typename: "SystemUserIsImmutableError"; message: string }
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser:
    | { __typename: "ManagedHRDataIsImmutableError"; message: string }
    | { __typename: "SystemUserIsImmutableError"; message: string }
    | {
        __typename: "UpdateUserResult";
        user: { __typename?: "User"; id: string };
      }
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      };
};

export type UpdateUserManagerMutationVariables = Exact<{
  input: UpdateUserManagerInput;
}>;

export type UpdateUserManagerMutation = {
  __typename?: "Mutation";
  updateUserManager:
    | { __typename: "ManagedHRDataIsImmutableError"; message: string }
    | { __typename: "SystemUserIsImmutableError"; message: string }
    | {
        __typename: "UpdateUserManagerResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          organization: {
            __typename?: "Organization";
            id: string;
            name: string;
            createdAt: string;
          };
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          directReports: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          userResources: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          userGroups: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          }>;
        };
      }
    | {
        __typename: "UserNotFoundError";
        message: string;
        email?: string | null;
      };
};

export type InviteUsersMutationVariables = Exact<{
  input: InviteUsersInput;
}>;

export type InviteUsersMutation = {
  __typename?: "Mutation";
  inviteUsers:
    | {
        __typename: "InviteUsersResult";
        invitedUsers: Array<{
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        }>;
      }
    | { __typename: "UserAlreadyExistsInAnotherOrg"; message: string };
};

export type ResetUserMfaMutationVariables = Exact<{
  input: ResetUserMfaInput;
}>;

export type ResetUserMfaMutation = {
  __typename?: "Mutation";
  resetUserMFA:
    | { __typename: "ResetAuth0MFAError"; message: string }
    | { __typename: "ResetUserMFAResult"; ok: boolean }
    | { __typename: "SystemUserIsImmutableError"; message: string }
    | { __typename: "UserNotFoundError"; message: string };
};

export type AccessReviewGroupsStatsQueryVariables = Exact<{
  input: CountFilteredGroupsInput;
}>;

export type AccessReviewGroupsStatsQuery = {
  __typename?: "Query";
  countFilteredGroups: {
    __typename: "CountFilteredGroupsResult";
    totalNumGroups: number;
  };
};

export type AccessReviewResourcesStatsQueryVariables = Exact<{
  input: CountFilteredResourcesInput;
}>;

export type AccessReviewResourcesStatsQuery = {
  __typename?: "Query";
  countFilteredResources: {
    __typename: "CountFilteredResourcesResult";
    totalNumResources: number;
  };
};

export type AccessReviewConnectionsStatsQueryVariables = Exact<{
  input: CountFilteredConnectionsInput;
}>;

export type AccessReviewConnectionsStatsQuery = {
  __typename?: "Query";
  countFilteredConnections: {
    __typename?: "CountFilteredConnectionsOutput";
    totalNumConnections: number;
  };
};

export type AllowedToUseAldwinQueryVariables = Exact<{ [key: string]: never }>;

export type AllowedToUseAldwinQuery = {
  __typename?: "Query";
  allowedToUseAldwin: {
    __typename?: "AllowedToUseAldwinResult";
    allowed: boolean;
  };
};

export type AllowedToCreateOrgQueryVariables = Exact<{ [key: string]: never }>;

export type AllowedToCreateOrgQuery = {
  __typename?: "Query";
  allowedToCreateOrganizations: {
    __typename?: "AllowedToCreateOrganizationsResult";
    allowed: boolean;
  };
};

export type AwsRoleCreationQueryVariables = Exact<{ [key: string]: never }>;

export type AwsRoleCreationQuery = {
  __typename?: "Query";
  connections: {
    __typename?: "ConnectionsResult";
    connections: Array<{ __typename?: "Connection"; id: string; name: string }>;
  };
  tags: {
    __typename?: "TagsResult";
    tags: Array<{
      __typename?: "Tag";
      id: string;
      value?: string | null;
      key: string;
    }>;
  };
};

export type CreateRequestProposalMutationVariables = Exact<{
  input: CreateRequestProposalInput;
}>;

export type CreateRequestProposalMutation = {
  __typename?: "Mutation";
  createRequestProposal:
    | { __typename: "AWSRoleCreationError" }
    | { __typename: "AWSRoleCreationReviewerNotSetError"; message: string }
    | { __typename: "AWSRolePolicyInvalidError"; message: string }
    | {
        __typename: "CreateRequestProposalResult";
        request: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          reason: string;
          durationInMinutes?: number | null;
          status: RequestStatus;
          reviewersError?: string | null;
          resourceProposalId?: string | null;
          escalatedToSkipManager: boolean;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              description: string;
              connectionId: string;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToConnect: boolean;
              requireMfaToApprove: boolean;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              parentResourceId?: string | null;
              authorizedActions?: Array<string> | null;
              customRequestNotification?: string | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                }>;
              };
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              tags: Array<{
                __typename?: "ResourceTag";
                id: string;
                resourceId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              childResources?: Array<{
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                createdAt: string;
                description: string;
                connectionId: string;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                maxDurationInMinutes?: number | null;
                requireMfaToConnect: boolean;
                requireMfaToApprove: boolean;
                requireMfaToRequest: boolean;
                requireSupportTicket: boolean;
                isRequestable: boolean;
                autoApproval: boolean;
                parentResourceId?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: { __typename?: "User"; id: string } | null;
                  }>;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                currentUserAccess: {
                  __typename?: "CurrentUserResourceAccess";
                  resourceId: string;
                  numAvailableAccessLevels: number;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  activeSessions: Array<{
                    __typename?: "Session";
                    id: string;
                    durationInMinutes?: number | null;
                    endTime: string;
                    accessLevel?: string | null;
                    accessLevelRemoteId?: string | null;
                    metadata:
                      | {
                          __typename: "AwsIamFederatedEksSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          clusterName: string;
                          clusterRegion: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRdsSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          federatedArn: string;
                          dbInstanceId: string;
                          dbPassword: string;
                          dbEngine: string;
                          dbUser: string;
                          dbHostname: string;
                          dbPort: number;
                          dbName: string;
                        }
                      | {
                          __typename: "AwsIamFederatedRoleSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                        }
                      | {
                          __typename: "AwsIamFederatedSSMSession";
                          id: string;
                          awsAccessKeyId: string;
                          awsSecretAccessKey: string;
                          awsSessionToken: string;
                          awsLoginUrl: string;
                          federatedArn: string;
                          ec2InstanceId: string;
                          ec2Region: string;
                        }
                      | { __typename: "OidcSession" }
                      | { __typename: "OpalImpersonationSession" }
                      | {
                          __typename: "VaultMongoAtlasSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          initDbName: string;
                        }
                      | {
                          __typename: "VaultMongoSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          authenticationDbName: string;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultMySQLMariaDBSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        }
                      | {
                          __typename: "VaultPostgresSession";
                          id: string;
                          dbUser: string;
                          dbPassword: string;
                          dbHostname: string;
                          dbPort: number;
                          tlsConfig: {
                            __typename?: "TlsConfig";
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          };
                        };
                  }>;
                  pendingRequests: Array<{
                    __typename?: "Request";
                    id: string;
                    requesterId: string;
                    targetUserId?: string | null;
                    targetGroupId?: string | null;
                    resourceProposalId?: string | null;
                    createdAt: string;
                    updatedAt: string;
                    status: RequestStatus;
                    requester?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetUser?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                    targetGroup?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    requestedResources: Array<{
                      __typename?: "RequestedResource";
                      resourceId: string;
                      resource?: {
                        __typename: "Resource";
                        id: string;
                        name: string;
                        remoteName: string;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isDeleted: boolean;
                        isManaged: boolean;
                        resourceType: ResourceType;
                        remoteId: string;
                        iconUrl?: string | null;
                        parentResourceId?: string | null;
                        connectionId: string;
                        createdAt: string;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        parentResource?: {
                          __typename?: "Resource";
                          id: string;
                          name: string;
                        } | null;
                        connection?: {
                          __typename: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                          isDeleted: boolean;
                          metadata?:
                            | {
                                __typename: "ADConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                              }
                            | {
                                __typename: "AwsConnectionMetadata";
                                success?: boolean | null;
                              }
                            | {
                                __typename: "AWSSSOConnectionMetadata";
                                managementAccountId: string;
                                ssoInstanceArn: string;
                                identityStoreId: string;
                                accessPortalUrl: string;
                                awsRegion: string;
                                awsOrganizationEnabled: boolean;
                                awsSsoEnabled: boolean;
                                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                                awsOrganizationImportSetting: ImportSetting;
                                ec2RoleChainingEnabled: boolean;
                                podRoleChainingEnabled: boolean;
                                cloudtrailEventsSqsUrl: string;
                              }
                            | {
                                __typename: "AzureADConnectionMetadata";
                                tenantId: string;
                                clientId: string;
                                azureInfraEnabled: boolean;
                                eventHubNamespace: string;
                                eventHub: string;
                              }
                            | {
                                __typename: "CustomConnectorConnectionMetadata";
                                identifier: string;
                                baseUrl: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                                supportsGroups: boolean;
                                supportsNestedResources: boolean;
                              }
                            | {
                                __typename: "DuoConnectionMetadata";
                                apiHostname: string;
                              }
                            | {
                                __typename: "GCPConnectionMetadata";
                                pubSubProjectId: string;
                                subscriptionId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GitHubConnectionMetadata";
                                orgName: string;
                              }
                            | {
                                __typename: "GitLabConnectionMetadata";
                                hostName?: string | null;
                                appId: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "GoogleGroupsConnectionMetadata";
                                opalGroupEmail: string;
                                adminUserEmail: string;
                                domain: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "GoogleWorkspaceConnectionMetadata";
                                adminUserEmail: string;
                                customerId: string;
                                serviceAccount: {
                                  __typename?: "GCPServiceAccount";
                                  type: string;
                                  projectId: string;
                                  clientEmail: string;
                                  clientId: string;
                                  authUri: string;
                                  tokenUri: string;
                                  authProviderX509CertUrl: string;
                                  clientX509CertUrl: string;
                                };
                              }
                            | {
                                __typename: "LDAPConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                groupsDistinguishedName: string;
                                usersDistinguishedName: string;
                                groupsUidKey: string;
                              }
                            | {
                                __typename: "MongoAtlasConnectionMetadata";
                                serverHostname: string;
                              }
                            | {
                                __typename: "MongoConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "MySQLMariaDBConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "OktaDirectoryConnectionMetadata";
                                orgUrl: string;
                              }
                            | { __typename: "PagerDutyConnectionMetadata" }
                            | {
                                __typename: "PostgresConnectionMetadata";
                                serverHostname: string;
                                serverPort: number;
                                tlsMode: boolean;
                                tlsCertContent?: string | null;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "PropagationTicketConnectionMetadata";
                                ticketProvider?: {
                                  __typename?: "TicketProvider";
                                  ticketProvider: ThirdPartyProvider;
                                  ticketProjectId: string;
                                  ticketProjectName: string;
                                } | null;
                              }
                            | {
                                __typename: "SalesforceConnectionMetadata";
                                email: string;
                                hostname: string;
                              }
                            | {
                                __typename: "SnowflakeConnectionMetadata";
                                organization: string;
                                account: string;
                                locator: string;
                                accountIdentifier: string;
                                accountUrl: string;
                                serviceAccountName: string;
                                serviceAccountRole: string;
                              }
                            | {
                                __typename: "TailscaleConnectionMetadata";
                                tailnet: string;
                              }
                            | {
                                __typename: "TeleportConnectionMetadata";
                                host: string;
                                tlsMode: boolean;
                                tlsCaCertContent?: string | null;
                              }
                            | {
                                __typename: "WorkdayConnectionMetadata";
                                tenantName: string;
                                tenantUrl: string;
                              }
                            | null;
                        } | null;
                        metadata?:
                          | { __typename: "AwsRoleMetadata"; arn: string }
                          | {
                              __typename: "OktaDirectoryAppMetadata";
                              appId: string;
                              appName: string;
                              logoUrl?: string | null;
                            }
                          | {
                              __typename: "OktaRoleMetadata";
                              roleId: string;
                              roleType: string;
                            }
                          | {
                              __typename: "PropagationTicketOwnerMetadata";
                              ownerId?: string | null;
                              owner?: {
                                __typename?: "Owner";
                                id: string;
                                name: string;
                              } | null;
                            }
                          | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      targetUserAccess?: {
                        __typename?: "UserResourceAccess";
                        resourceId: string;
                        userId: string;
                        resourceUsers: Array<{
                          __typename: "ResourceUser";
                          resourceId: string;
                          userId: string;
                          mostRecentSessionEnded?: string | null;
                          source: ResourceUserSource;
                          user?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            createdAt: string;
                            updatedAt: string;
                            organizationId: string;
                            position: string;
                            teamAttr?: string | null;
                            managerId?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                            manager?: {
                              __typename?: "User";
                              id: string;
                              email: string;
                              secondaryEmails: Array<string>;
                              firstName: string;
                              lastName: string;
                              fullName: string;
                              avatarUrl: string;
                              isDeleted: boolean;
                              isSystemUser: boolean;
                              position: string;
                              teamAttr?: string | null;
                              hrIdpStatus?: HrIdpStatus | null;
                            } | null;
                          } | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          access?: {
                            __typename?: "ResourceUserAccess";
                            resourceId: string;
                            userId: string;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            latestExpiringAccessPoint: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            inheritedAccessPoints: Array<{
                              __typename?: "ResourceUserAccessPoint";
                              resourceId: string;
                              userId: string;
                              resourceName: string;
                              resourceType: ResourceType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              accessLevel: {
                                __typename: "ResourceAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                                accessLevelMetadata?: {
                                  __typename: "ImpersonationAccessLevelMetadata";
                                  avatarUrl: string;
                                } | null;
                              };
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                            groupUserAccesses: Array<{
                              __typename?: "GroupUserAccess";
                              groupId: string;
                              userId: string;
                              isGroupManaged: boolean;
                              latestExpiringAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              directAccessPoint?: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              } | null;
                              indirectAccessPointPaths: Array<{
                                __typename?: "IndirectGroupUserAccessPointPath";
                                startGroupId: string;
                                endGroupId: string;
                                userId: string;
                                resolvedAccessPoint: {
                                  __typename?: "GroupUserAccessPoint";
                                  groupId: string;
                                  userId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                  expiration?: string | null;
                                  supportTicketId?: string | null;
                                  source: GroupUserSource;
                                  accessLevel?: {
                                    __typename: "GroupAccessLevel";
                                    accessLevelName: string;
                                    accessLevelRemoteId: string;
                                  } | null;
                                  supportTicket?: {
                                    __typename?: "SupportTicket";
                                    id?: string | null;
                                    remoteId: string;
                                    url: string;
                                    identifier: string;
                                    thirdPartyProvider: ThirdPartyProvider;
                                    projectName: string;
                                  } | null;
                                };
                                indirectAccessPointPath: Array<{
                                  __typename?: "IndirectGroupAccessPoint";
                                  groupId: string;
                                  groupName: string;
                                  groupType: GroupType;
                                  eventId: string;
                                  createdAt: string;
                                }>;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "ResourcePropagationStatus";
                            resourceId: string;
                            userId: string;
                            accessLevelRemoteId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            metadata?: {
                              __typename: "PropagationTicketMetadata";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                          } | null;
                        }>;
                      } | null;
                    }>;
                    requestedGroups: Array<{
                      __typename?: "RequestedGroup";
                      groupId: string;
                      group?: {
                        __typename?: "Group";
                        id: string;
                        remoteId?: string | null;
                        name: string;
                        remoteName: string;
                        numGroupUsers: number;
                        groupType: GroupType;
                        serviceType: ServiceType;
                        visibility: Visibility;
                        isManaged: boolean;
                        isDeleted: boolean;
                        iconUrl?: string | null;
                        isOnCallSynced: boolean;
                        connectionId: string;
                        groupBindingId?: string | null;
                        visibilityGroups: Array<{
                          __typename?: "ConfigurationVisibilityGroup";
                          visibilityGroupId: string;
                          visibilityGroup: {
                            __typename?: "Group";
                            name: string;
                          };
                        }>;
                        connection?: {
                          __typename?: "Connection";
                          id: string;
                          name: string;
                          connectionType: ConnectionType;
                        } | null;
                        groupBinding?: {
                          __typename?: "GroupBinding";
                          id: string;
                          sourceGroupId: string;
                          groups: Array<{
                            __typename?: "Group";
                            id: string;
                            name: string;
                            groupType: GroupType;
                            connectionId: string;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      targetUserAccess?: {
                        __typename?: "UserGroupAccess";
                        groupId: string;
                        userId: string;
                        groupUser?: {
                          __typename?: "GroupUser";
                          groupId: string;
                          userId: string;
                          source: GroupUserSource;
                          access?: {
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          } | null;
                          propagationStatus?: {
                            __typename?: "GroupPropagationStatus";
                            groupId: string;
                            userId: string;
                            lastSynced: string;
                            statusCode: PropagationStatusCode;
                            errorMessage?: string | null;
                            taskType: PropagationTaskType;
                            ticketPropagation?: {
                              __typename: "GroupUserTicketPropagation";
                              resourceTickets: Array<{
                                __typename?: "ResourceTicketPropagation";
                                ticket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              }>;
                            } | null;
                          } | null;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                    resourceProposal?: {
                      __typename?: "ResourceProposal";
                      id: string;
                      connectionId: string;
                      serviceType: ServiceType;
                      resourceType: ResourceType;
                      metadata?: {
                        __typename: "AWSRoleProposalMetadata";
                        policyName: string;
                        policyDocument: string;
                        policyDescription: string;
                        assumeRolePolicyDocument: string;
                        roleName: string;
                        roleDescription: string;
                        tags: Array<{
                          __typename?: "AWSTag";
                          key: string;
                          value?: string | null;
                        }>;
                      } | null;
                    } | null;
                  }>;
                };
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
                commonMetadata: {
                  __typename?: "CommonMetadata";
                  matchRemoteName: boolean;
                  matchRemoteDescription: boolean;
                };
                tags: Array<{
                  __typename?: "ResourceTag";
                  id: string;
                  resourceId: string;
                  tagId: string;
                  source: ServiceType;
                  updatedAt: string;
                  createdAt: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  tag?: {
                    __typename: "Tag";
                    id: string;
                    createdAt: string;
                    creatorUserId: string;
                    key: string;
                    value?: string | null;
                    isStandardAttribute: boolean;
                  } | null;
                }>;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
              }> | null;
              requestTemplate?: {
                __typename?: "RequestTemplate";
                id: string;
                name: string;
                isDeleted?: boolean | null;
                customFields?: Array<{
                  __typename?: "RequestTemplateCustomField";
                  name: string;
                  type: RequestTemplateCustomFieldType;
                  required?: boolean | null;
                  description?: string | null;
                  metadata?: {
                    __typename?: "RequestTemplateCustomFieldMetadata";
                    multiChoiceData?: {
                      __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                      options?: Array<{
                        __typename?: "MultiChoiceOption";
                        value: string;
                        label: string;
                      }> | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          requestComments: Array<{
            __typename?: "RequestComment";
            id: string;
            userId: string;
            createdAt: string;
            comment: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          requestTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
          stages: Array<{
            __typename?: "RequestItemStages";
            requestedItemName: string;
            requestedRoleName?: string | null;
            stages: Array<{
              __typename?: "RequestStage";
              operator: ReviewStageOperator;
              stage: number;
              reviewers: Array<{
                __typename?: "RequestReviewer";
                userId: string;
                reviewerAction?: ReviewerAction | null;
                reviewerType: RequestApprovalType;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
              }>;
            }>;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
          customMetadata?: Array<{
            __typename?: "RequestCustomMetadata";
            fieldName: string;
            fieldType: RequestTemplateCustomFieldType;
            metadataValue?: {
              __typename?: "RequestCustomMetadataValue";
              shortTextValue?: {
                __typename?: "ShortTextValue";
                value: string;
              } | null;
              longTextValue?: {
                __typename?: "LongTextValue";
                value: string;
              } | null;
              booleanValue?: {
                __typename?: "BooleanValue";
                value: boolean;
              } | null;
              multiChoiceValue?: {
                __typename?: "MultiChoiceValue";
                value: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename: "NoManagerSetForRequestingUserError"; message: string }
    | {
        __typename: "NoReviewersSetForGroupError";
        message: string;
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        } | null;
      }
    | { __typename: "NoReviewersSetForOwnerError" }
    | {
        __typename: "NoReviewersSetForResourceError";
        message: string;
        resource?: {
          __typename?: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
        } | null;
      };
};

export type PaginatedAppDropdownQueryVariables = Exact<{
  access: AccessOption;
  searchQuery?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type PaginatedAppDropdownQuery = {
  __typename?: "Query";
  apps: {
    __typename: "AppsOutput";
    cursor?: string | null;
    apps: Array<{
      __typename?: "App";
      id: string;
      name: string;
      app:
        | {
            __typename?: "ConnectionApp";
            connectionId: string;
            connectionType: ConnectionType;
          }
        | {
            __typename?: "OktaResourceApp";
            resourceId: string;
            iconUrl?: string | null;
          };
    }>;
  };
};

export type AppDropdownPreviewFragment = {
  __typename?: "App";
  id: string;
  name: string;
  app:
    | {
        __typename?: "ConnectionApp";
        connectionId: string;
        connectionType: ConnectionType;
      }
    | {
        __typename?: "OktaResourceApp";
        resourceId: string;
        iconUrl?: string | null;
      };
};

export type PaginatedEntityDropdownQueryVariables = Exact<{
  id: Scalars["UUID"];
  cursor?: InputMaybe<Scalars["String"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  includeOnlyRequestable?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PaginatedEntityDropdownQuery = {
  __typename?: "Query";
  app:
    | {
        __typename: "App";
        id: string;
        items: {
          __typename?: "AppItemsOutput";
          cursor?: string | null;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename?: "Resource";
              id: string;
              name: string;
              description: string;
              isRequestable: boolean;
              resourceType: ResourceType;
              ancestorPathToResource?: string | null;
              connection?: {
                __typename?: "Connection";
                name: string;
                connectionType: ConnectionType;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                numAvailableAccessLevels: number;
                resourceId: string;
                resourceUsers: Array<{
                  __typename?: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename?: "ResourceAccessLevel";
                    accessLevelRemoteId: string;
                  };
                }>;
              };
            } | null;
            group?: {
              __typename?: "Group";
              id: string;
              name: string;
              description: string;
              isRequestable: boolean;
              groupType: GroupType;
              connection?: {
                __typename?: "Connection";
                name: string;
                connectionType: ConnectionType;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                sourceGroupId: string;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  userId: string;
                  groupId: string;
                } | null;
              };
            } | null;
          }> | null;
        };
      }
    | { __typename: "AppNotFoundError" };
};

export type PaginatedGroupBindingDropdownQueryVariables = Exact<{
  input: GroupBindingsInput;
}>;

export type PaginatedGroupBindingDropdownQuery = {
  __typename?: "Query";
  groupBindings: {
    __typename: "GroupBindingsOutput";
    cursor?: string | null;
    groupBindings: Array<{
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    }>;
  };
};

export type GroupBindingDropdownPreviewFragment = {
  __typename?: "GroupBinding";
  id: string;
  sourceGroupId: string;
  sourceGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
  groups: Array<{
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
  }>;
};

export type PaginatedGroupDropdownQueryVariables = Exact<{
  input: GroupsInput;
}>;

export type PaginatedGroupDropdownQuery = {
  __typename?: "Query";
  groups: {
    __typename: "GroupsResult";
    cursor?: string | null;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      numGroupUsers: number;
      isOnCallSynced: boolean;
      authorizedActions?: Array<string> | null;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        iconUrl?: string | null;
      } | null;
    }>;
  };
};

export type GroupDropdownPreviewFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  connectionId: string;
  numGroupUsers: number;
  isOnCallSynced: boolean;
  authorizedActions?: Array<string> | null;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    iconUrl?: string | null;
  } | null;
};

export type PaginatedResourceDropdownQueryVariables = Exact<{
  input: ResourcesInput;
}>;

export type PaginatedResourceDropdownQuery = {
  __typename?: "Query";
  resources: {
    __typename: "ResourcesResult";
    cursor?: string | null;
    resources: Array<{
      __typename?: "Resource";
      id: string;
      name: string;
      iconUrl?: string | null;
      resourceType: ResourceType;
      serviceType: ServiceType;
      remoteId: string;
      numChildResources: number;
      authorizedActions?: Array<string> | null;
      connection?: {
        __typename?: "Connection";
        id: string;
        connectionType: ConnectionType;
        name: string;
      } | null;
      parentResource?: { __typename?: "Resource"; name: string } | null;
    }>;
  };
};

export type ResourceDropdownPreviewFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  iconUrl?: string | null;
  resourceType: ResourceType;
  serviceType: ServiceType;
  remoteId: string;
  numChildResources: number;
  authorizedActions?: Array<string> | null;
  connection?: {
    __typename?: "Connection";
    id: string;
    connectionType: ConnectionType;
    name: string;
  } | null;
  parentResource?: { __typename?: "Resource"; name: string } | null;
};

export type PaginatedUserDropdownQueryVariables = Exact<{
  input: UsersInput;
}>;

export type PaginatedUserDropdownQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isSystemUser: boolean;
    }>;
  };
};

export type PaginatedUserOrGroupDropdownQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars["String"]>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
  includeSystemUser?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PaginatedUserOrGroupDropdownQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isSystemUser: boolean;
    }>;
  };
  groups: {
    __typename?: "GroupsResult";
    cursor?: string | null;
    groups: Array<{
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    }>;
  };
};

export type UserDropdownPreviewFragment = {
  __typename?: "User";
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  isSystemUser: boolean;
};

export type UserOrGroupDropdownQueryVariables = Exact<{ [key: string]: never }>;

export type UserOrGroupDropdownQuery = {
  __typename?: "Query";
  groups: {
    __typename?: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      numGroupUsers: number;
      isOnCallSynced: boolean;
      authorizedActions?: Array<string> | null;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        iconUrl?: string | null;
      } | null;
    }>;
  };
};

export type SearchUsersQueryVariables = Exact<{
  query: Scalars["String"];
  maxNumEntries: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type SearchUsersQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  };
};

export type SearchUserPreviewFragment = {
  __typename?: "User";
  id: string;
  fullName: string;
  avatarUrl: string;
  isSystemUser: boolean;
};

export type SearchResourcesQueryVariables = Exact<{
  query: Scalars["String"];
  maxNumEntries: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type SearchResourcesQuery = {
  __typename?: "Query";
  resources: {
    __typename: "ResourcesResult";
    cursor?: string | null;
    resources: Array<{
      __typename?: "Resource";
      id: string;
      name: string;
      iconUrl?: string | null;
      resourceType: ResourceType;
      serviceType: ServiceType;
      remoteId: string;
      numChildResources: number;
      authorizedActions?: Array<string> | null;
      parentResource?: { __typename?: "Resource"; name: string } | null;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    }>;
  };
};

export type SearchResourcePreviewFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  iconUrl?: string | null;
  resourceType: ResourceType;
  serviceType: ServiceType;
  remoteId: string;
  numChildResources: number;
  authorizedActions?: Array<string> | null;
  parentResource?: { __typename?: "Resource"; name: string } | null;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type SearchGroupsQueryVariables = Exact<{
  query: Scalars["String"];
  maxNumEntries: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type SearchGroupsQuery = {
  __typename?: "Query";
  groups: {
    __typename: "GroupsResult";
    cursor?: string | null;
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      isOnCallSynced: boolean;
      authorizedActions?: Array<string> | null;
      connectionId: string;
      numGroupUsers: number;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    }>;
  };
};

export type SearchGroupPreviewFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  isOnCallSynced: boolean;
  authorizedActions?: Array<string> | null;
  connectionId: string;
  numGroupUsers: number;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type SearchTypesQueryVariables = Exact<{
  query: Scalars["String"];
}>;

export type SearchTypesQuery = {
  __typename?: "Query";
  groupTypesWithCounts: {
    __typename?: "GroupTypesWithCountsResult";
    groupTypesWithCounts: Array<{
      __typename?: "GroupTypeWithCount";
      groupType: GroupType;
      numGroups: number;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
      } | null;
    }>;
  };
  resourceTypesWithCounts: {
    __typename?: "ResourceTypesWithCountsResult";
    resourceTypesWithCounts: Array<{
      __typename?: "ResourceTypeWithCount";
      resourceType: ResourceType;
      numResources: number;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type SearchGroupTypePreviewFragment = {
  __typename?: "GroupTypeWithCount";
  groupType: GroupType;
  numGroups: number;
  connection?: { __typename?: "Connection"; id: string; name: string } | null;
};

export type SearchResourceTypePreviewFragment = {
  __typename?: "ResourceTypeWithCount";
  resourceType: ResourceType;
  numResources: number;
  connection?: { __typename?: "Connection"; id: string; name: string } | null;
};

export type AppRowQueryVariables = Exact<{
  connectionId: Scalars["ConnectionId"];
}>;

export type AppRowQuery = {
  __typename?: "Query";
  connection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | {
        __typename: "ConnectionResult";
        connection: {
          __typename?: "Connection";
          name: string;
          connectionType: ConnectionType;
          iconUrl?: string | null;
        };
      };
};

export type GroupsPreviewQueryVariables = Exact<{
  input: GroupsInput;
}>;

export type GroupsPreviewQuery = {
  __typename?: "Query";
  groups: {
    __typename: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    }>;
  };
};

export type LeftSidebarRoutesQueryVariables = Exact<{ [key: string]: never }>;

export type LeftSidebarRoutesQuery = {
  __typename?: "Query";
  leftSidebarRoutes: {
    __typename?: "LeftSidebarRoutes";
    hasOwners: boolean;
    isGroupLeader: boolean;
  };
};

export type UserMenuQueryVariables = Exact<{ [key: string]: never }>;

export type UserMenuQuery = {
  __typename?: "Query";
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{ __typename?: "Connection"; id: string }>;
  };
};

export type SlackIntegrationWorkspacesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SlackIntegrationWorkspacesQuery = {
  __typename?: "Query";
  thirdPartyIntegrations: {
    __typename?: "ThirdPartyIntegrationsResult";
    thirdPartyIntegrations: Array<{
      __typename?: "ThirdPartyIntegration";
      thirdPartyProvider: ThirdPartyProvider;
      slackMetadata?: {
        __typename?: "ThirdPartyIntegrationSlackMetadata";
        enterpriseId?: string | null;
        workspaces: Array<{
          __typename?: "ThirdPartyIntegrationSlackWorkspace";
          id: string;
          name: string;
        }>;
      } | null;
    }>;
  };
};

export type GroupActiveRequestConfigurationQueryVariables = Exact<{
  groupId: Scalars["GroupId"];
  targetUserId?: InputMaybe<Scalars["UserId"]>;
  accessLevelRemoteId?: InputMaybe<Scalars["AccessLevelRemoteId"]>;
}>;

export type GroupActiveRequestConfigurationQuery = {
  __typename?: "Query";
  activeGroupRequestConfigurations: Array<{
    __typename?: "GroupRequestConfigurationOutput";
    groupId: string;
    requestConfiguration: {
      __typename?: "RequestConfiguration";
      isRequestable: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireSupportTicket: boolean;
      reasonOptional: boolean;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    };
  }>;
};

export type ActiveRequestConfigurationFragment = {
  __typename?: "RequestConfiguration";
  isRequestable: boolean;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireSupportTicket: boolean;
  reasonOptional: boolean;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
};

export type ResourceActiveRequestConfigurationQueryVariables = Exact<{
  resourceId: Scalars["ResourceId"];
  targetUserId?: InputMaybe<Scalars["UserId"]>;
  accessLevelRemoteId?: InputMaybe<Scalars["AccessLevelRemoteId"]>;
}>;

export type ResourceActiveRequestConfigurationQuery = {
  __typename?: "Query";
  activeResourceRequestConfigurations: Array<{
    __typename?: "ResourceRequestConfigurationOutput";
    resourceId: string;
    requestConfiguration: {
      __typename?: "RequestConfiguration";
      isRequestable: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireSupportTicket: boolean;
      reasonOptional: boolean;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    };
  }>;
};

export type GroupDetailsModalQueryVariables = Exact<{
  id: Scalars["GroupId"];
}>;

export type GroupDetailsModalQuery = {
  __typename?: "Query";
  group:
    | { __typename?: "GroupNotFoundError" }
    | {
        __typename?: "GroupResult";
        group: {
          __typename: "Group";
          id: string;
          name: string;
          description: string;
          groupType: GroupType;
          serviceType: ServiceType;
          isRequestable: boolean;
          numGroupUsers: number;
          connectionId: string;
          accessStats?: {
            __typename?: "GroupAccessStats";
            teamAccessCount?: number | null;
            titleAccessCount?: number | null;
            totalAccessCount?: number | null;
          } | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: {
                __typename?: "User";
                id: string;
                fullName: string;
                avatarUrl: string;
              } | null;
            }>;
          } | null;
          containingGroups: Array<{
            __typename?: "GroupGroup";
            containingGroupId: string;
            containingGroup?: {
              __typename?: "Group";
              name: string;
              description: string;
              iconUrl?: string | null;
              groupType: GroupType;
              connection?: {
                __typename?: "Connection";
                name: string;
                connectionType: ConnectionType;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                } | null;
              };
            } | null;
            accessLevel: {
              __typename?: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
          }>;
          groupResources: Array<{
            __typename?: "GroupResource";
            resourceId: string;
            groupId: string;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelRemoteId: string;
            };
            resource?: {
              __typename?: "Resource";
              name: string;
              description: string;
              resourceType: ResourceType;
              ancestorPathToResource?: string | null;
              connection?: { __typename?: "Connection"; name: string } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                }>;
              };
            } | null;
          }>;
          connection?: {
            __typename?: "Connection";
            name: string;
            connectionType: ConnectionType;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
            } | null;
          };
        };
      };
  groupRequestStatusCounts:
    | { __typename?: "GroupNotFoundError" }
    | {
        __typename?: "GroupRequestStatusCountsResult";
        requestStatusCounts: {
          __typename?: "RequestStatusCounts";
          pending: number;
          approved: number;
          denied: number;
          canceled: number;
        };
      };
};

export type EndUserGroupDetailsGroupResourcesFragment = {
  __typename?: "GroupResource";
  resourceId: string;
  groupId: string;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelRemoteId: string;
  };
  resource?: {
    __typename?: "Resource";
    name: string;
    description: string;
    resourceType: ResourceType;
    ancestorPathToResource?: string | null;
    connection?: { __typename?: "Connection"; name: string } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
      }>;
    };
  } | null;
};

export type EndUserGroupDetailsGroupGroupsFragment = {
  __typename?: "GroupGroup";
  containingGroupId: string;
  containingGroup?: {
    __typename?: "Group";
    name: string;
    description: string;
    iconUrl?: string | null;
    groupType: GroupType;
    connection?: {
      __typename?: "Connection";
      name: string;
      connectionType: ConnectionType;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
      } | null;
    };
  } | null;
  accessLevel: {
    __typename?: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
};

export type EndUserGroupDetailsFragment = {
  __typename: "Group";
  id: string;
  name: string;
  description: string;
  groupType: GroupType;
  serviceType: ServiceType;
  isRequestable: boolean;
  numGroupUsers: number;
  connectionId: string;
  accessStats?: {
    __typename?: "GroupAccessStats";
    teamAccessCount?: number | null;
    titleAccessCount?: number | null;
    totalAccessCount?: number | null;
  } | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
  } | null;
  containingGroups: Array<{
    __typename?: "GroupGroup";
    containingGroupId: string;
    containingGroup?: {
      __typename?: "Group";
      name: string;
      description: string;
      iconUrl?: string | null;
      groupType: GroupType;
      connection?: {
        __typename?: "Connection";
        name: string;
        connectionType: ConnectionType;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
        } | null;
      };
    } | null;
    accessLevel: {
      __typename?: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
  }>;
  groupResources: Array<{
    __typename?: "GroupResource";
    resourceId: string;
    groupId: string;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelRemoteId: string;
    };
    resource?: {
      __typename?: "Resource";
      name: string;
      description: string;
      resourceType: ResourceType;
      ancestorPathToResource?: string | null;
      connection?: { __typename?: "Connection"; name: string } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
        }>;
      };
    } | null;
  }>;
  connection?: {
    __typename?: "Connection";
    name: string;
    connectionType: ConnectionType;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserGroupAccess";
    groupId: string;
    hasBreakGlass: boolean;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
    pendingRequest?: {
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
    } | null;
  };
};

export type ResourceDetailsModalQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type ResourceDetailsModalQuery = {
  __typename?: "Query";
  resource:
    | { __typename?: "ResourceNotFoundError" }
    | {
        __typename?: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          description: string;
          resourceType: ResourceType;
          serviceType: ServiceType;
          isRequestable: boolean;
          remoteId: string;
          numResourceUsers: number;
          ancestorPathToResource?: string | null;
          connectionId: string;
          accessStats?: {
            __typename?: "ResourceAccessStats";
            teamAccessCount?: number | null;
            titleAccessCount?: number | null;
            totalAccessCount?: number | null;
          } | null;
          connection?: {
            __typename?: "Connection";
            name: string;
            connectionType: ConnectionType;
          } | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: {
                __typename?: "User";
                id: string;
                fullName: string;
                avatarUrl: string;
              } | null;
            }>;
          } | null;
          containingGroups: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            group?: {
              __typename?: "Group";
              name: string;
              iconUrl?: string | null;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
          }>;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
            }>;
          };
        };
      };
  resourceRequestStatusCounts:
    | { __typename?: "ResourceNotFoundError" }
    | {
        __typename?: "ResourceRequestStatusCountsResult";
        requestStatusCounts: {
          __typename?: "RequestStatusCounts";
          pending: number;
          approved: number;
          denied: number;
          canceled: number;
        };
      };
};

export type EndUserResourceDetailsFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  description: string;
  resourceType: ResourceType;
  serviceType: ServiceType;
  isRequestable: boolean;
  remoteId: string;
  numResourceUsers: number;
  ancestorPathToResource?: string | null;
  connectionId: string;
  accessStats?: {
    __typename?: "ResourceAccessStats";
    teamAccessCount?: number | null;
    titleAccessCount?: number | null;
    totalAccessCount?: number | null;
  } | null;
  connection?: {
    __typename?: "Connection";
    name: string;
    connectionType: ConnectionType;
  } | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
  } | null;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    group?: {
      __typename?: "Group";
      name: string;
      iconUrl?: string | null;
      connection?: {
        __typename?: "Connection";
        connectionType: ConnectionType;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
  }>;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
    }>;
  };
};

export type AccessRequestGroupCardQueryVariables = Exact<{
  id: Scalars["GroupId"];
  targetUserId: Scalars["UserId"];
}>;

export type AccessRequestGroupCardQuery = {
  __typename?: "Query";
  group:
    | { __typename?: "GroupNotFoundError" }
    | {
        __typename?: "GroupResult";
        group: {
          __typename: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          serviceType: ServiceType;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          containingGroups: Array<{ __typename?: "GroupGroup"; id: string }>;
          groupResources: Array<{
            __typename?: "GroupResource";
            resourceId: string;
            groupId: string;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelRemoteId: string;
            };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
            } | null;
          };
        };
      };
  groupAccessLevels:
    | {
        __typename?: "GroupAccessLevelsResult";
        groupId: string;
        accessLevels?: Array<{
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        }> | null;
      }
    | { __typename?: "GroupNotFoundError" };
};

export type AccessRequestResourceCardQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type AccessRequestResourceCardQuery = {
  __typename?: "Query";
  resource:
    | { __typename?: "ResourceNotFoundError" }
    | {
        __typename?: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          serviceType: ServiceType;
          remoteId: string;
          ancestorPathToResource?: string | null;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
            }>;
          };
        };
      };
};

export type AccessRequestOktaAppCardQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AccessRequestOktaAppCardQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        app:
          | { __typename?: "ConnectionApp" }
          | {
              __typename?: "OktaResourceApp";
              resourceId: string;
              resource?: {
                __typename?: "Resource";
                id: string;
                name: string;
                isRequestable: boolean;
              } | null;
            };
        items: {
          __typename?: "AppItemsOutput";
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            group?: { __typename?: "Group"; id: string; name: string } | null;
          }> | null;
        };
      }
    | { __typename?: "AppNotFoundError" };
};

export type ConnectionsSummaryQueryVariables = Exact<{
  input: ConnectionsInput;
}>;

export type ConnectionsSummaryQuery = {
  __typename?: "Query";
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      numResources: number;
      numGroups: number;
    }>;
  };
};

export type ConnectionSummaryFragment = {
  __typename?: "Connection";
  id: string;
  name: string;
  connectionType: ConnectionType;
  numResources: number;
  numGroups: number;
};

export type GroupTypesWithCountsQueryVariables = Exact<{
  input: GroupTypesWithCountsInput;
}>;

export type GroupTypesWithCountsQuery = {
  __typename?: "Query";
  groupTypesWithCounts: {
    __typename?: "GroupTypesWithCountsResult";
    groupTypesWithCounts: Array<{
      __typename?: "GroupTypeWithCount";
      connectionId?: string | null;
      groupType: GroupType;
      numGroups: number;
      connection?: { __typename?: "Connection"; name: string } | null;
    }>;
  };
};

export type GroupTypeWithCountFragment = {
  __typename?: "GroupTypeWithCount";
  connectionId?: string | null;
  groupType: GroupType;
  numGroups: number;
  connection?: { __typename?: "Connection"; name: string } | null;
};

export type ResourceTypesWithCountsQueryVariables = Exact<{
  input: ResourceTypesWithCountsInput;
}>;

export type ResourceTypesWithCountsQuery = {
  __typename?: "Query";
  resourceTypesWithCounts: {
    __typename?: "ResourceTypesWithCountsResult";
    resourceTypesWithCounts: Array<{
      __typename?: "ResourceTypeWithCount";
      connectionId?: string | null;
      resourceType: ResourceType;
      numResources: number;
      connection?: { __typename?: "Connection"; name: string } | null;
    }>;
  };
};

export type ResourceTypeWithCountFragment = {
  __typename?: "ResourceTypeWithCount";
  connectionId?: string | null;
  resourceType: ResourceType;
  numResources: number;
  connection?: { __typename?: "Connection"; name: string } | null;
};

export type AccessLevelNodeFragment = {
  __typename?: "AccessLevelNode";
  resourceId: string;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
};

export type EdgeMetadataFragment = {
  __typename?: "EdgeMetadata";
  lastUsageTime?: string | null;
  accessType?: {
    __typename?: "AccessTypeData";
    type: AccessType;
    expiration?: string | null;
  } | null;
};

export type VisualizationDataResultFragment = {
  __typename?: "VisualizationResult";
  userResourceEdges: Array<{
    __typename?: "UserResourceEdge";
    userId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    metadata?: {
      __typename?: "EdgeMetadata";
      lastUsageTime?: string | null;
      accessType?: {
        __typename?: "AccessTypeData";
        type: AccessType;
        expiration?: string | null;
      } | null;
    } | null;
  }>;
  groupResourceEdges: Array<{
    __typename?: "GroupResourceEdge";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    metadata?: {
      __typename?: "EdgeMetadata";
      lastUsageTime?: string | null;
      accessType?: {
        __typename?: "AccessTypeData";
        type: AccessType;
        expiration?: string | null;
      } | null;
    } | null;
  }>;
  userGroupEdges: Array<{
    __typename?: "UserGroupEdge";
    userId: string;
    groupId: string;
    metadata?: {
      __typename?: "EdgeMetadata";
      lastUsageTime?: string | null;
      accessType?: {
        __typename?: "AccessTypeData";
        type: AccessType;
        expiration?: string | null;
      } | null;
    } | null;
  }>;
  users: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  }>;
  groups: Array<{
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  }>;
  resources: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  }>;
  accessLevels: Array<{
    __typename?: "AccessLevelNode";
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
  }>;
};

export type VisualizationDataQueryVariables = Exact<{
  input: VisualizationFilter;
}>;

export type VisualizationDataQuery = {
  __typename?: "Query";
  visualizationData:
    | { __typename?: "UserFacingError" }
    | {
        __typename?: "VisualizationResult";
        userResourceEdges: Array<{
          __typename?: "UserResourceEdge";
          userId: string;
          resourceId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          metadata?: {
            __typename?: "EdgeMetadata";
            lastUsageTime?: string | null;
            accessType?: {
              __typename?: "AccessTypeData";
              type: AccessType;
              expiration?: string | null;
            } | null;
          } | null;
        }>;
        groupResourceEdges: Array<{
          __typename?: "GroupResourceEdge";
          groupId: string;
          resourceId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          metadata?: {
            __typename?: "EdgeMetadata";
            lastUsageTime?: string | null;
            accessType?: {
              __typename?: "AccessTypeData";
              type: AccessType;
              expiration?: string | null;
            } | null;
          } | null;
        }>;
        userGroupEdges: Array<{
          __typename?: "UserGroupEdge";
          userId: string;
          groupId: string;
          metadata?: {
            __typename?: "EdgeMetadata";
            lastUsageTime?: string | null;
            accessType?: {
              __typename?: "AccessTypeData";
              type: AccessType;
              expiration?: string | null;
            } | null;
          } | null;
        }>;
        users: Array<{
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        }>;
        groups: Array<{
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        }>;
        resources: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          parentResourceId?: string | null;
          authorizedActions?: Array<string> | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          childResources?: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            parentResourceId?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
          }> | null;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
        }>;
        accessLevels: Array<{
          __typename?: "AccessLevelNode";
          resourceId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
        }>;
      }
    | { __typename?: "VisualizationTimeoutError" };
};

export type ClearResourceUserPropagationStatusMutationVariables = Exact<{
  input: ClearResourceUserPropagationStatusInput;
}>;

export type ClearResourceUserPropagationStatusMutation = {
  __typename?: "Mutation";
  clearResourceUserPropagationStatus: {
    __typename?: "ClearResourceUserPropagationStatusResult";
    success: boolean;
  };
};

export type MarkGroupUserPropagationAsSuccessMutationVariables = Exact<{
  accessReviewGroupUserId: Scalars["AccessReviewGroupUserId"];
}>;

export type MarkGroupUserPropagationAsSuccessMutation = {
  __typename?: "Mutation";
  deleteGroupUserPropStatus: {
    __typename?: "DeleteGroupUserPropStatusResult";
    success: boolean;
  };
};

export type AppPreviewQueryVariables = Exact<{
  appId: Scalars["UUID"];
}>;

export type AppPreviewQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        app:
          | {
              __typename?: "ConnectionApp";
              connectionId: string;
              connectionType: ConnectionType;
            }
          | {
              __typename?: "OktaResourceApp";
              resourceId: string;
              iconUrl?: string | null;
            };
      }
    | { __typename?: "AppNotFoundError" };
};

export type AppPreviewFragment = {
  __typename?: "App";
  id: string;
  name: string;
  app:
    | {
        __typename?: "ConnectionApp";
        connectionId: string;
        connectionType: ConnectionType;
      }
    | {
        __typename?: "OktaResourceApp";
        resourceId: string;
        iconUrl?: string | null;
      };
};

export type RequestMessageFragment = {
  __typename?: "RequestMessage";
  entityId?: string | null;
  level: RequestMessageLevel;
  code: RequestMessageCode;
};

export type RequestDefaultsFragment = {
  __typename?: "RequestDefaults";
  recommendedDurationInMinutes?: number | null;
  defaultDurationInMinutes: number;
  maxDurationInMinutes?: number | null;
  requireSupportTicket: boolean;
  reasonOptional: boolean;
  durationOptions: Array<{
    __typename?: "DurationOption";
    durationInMinutes: number;
    label: string;
  }>;
  messages: Array<{
    __typename?: "RequestMessage";
    entityId?: string | null;
    level: RequestMessageLevel;
    code: RequestMessageCode;
  }>;
};

export type RequestDefaultsQueryVariables = Exact<{
  input: RequestDefaultsInput;
}>;

export type RequestDefaultsQuery = {
  __typename?: "Query";
  requestDefaults: {
    __typename?: "RequestDefaults";
    recommendedDurationInMinutes?: number | null;
    defaultDurationInMinutes: number;
    maxDurationInMinutes?: number | null;
    requireSupportTicket: boolean;
    reasonOptional: boolean;
    durationOptions: Array<{
      __typename?: "DurationOption";
      durationInMinutes: number;
      label: string;
    }>;
    messages: Array<{
      __typename?: "RequestMessage";
      entityId?: string | null;
      level: RequestMessageLevel;
      code: RequestMessageCode;
    }>;
  };
};

export type AccessReviewUserAssignmentsQueryVariables = Exact<{
  input: AccessReviewUserAssignmentsInput;
}>;

export type AccessReviewUserAssignmentsQuery = {
  __typename?: "Query";
  accessReviewUserAssignments: {
    __typename?: "AccessReviewUserAssignmentsResult";
    cursor?: string | null;
    totalNumUserAssignments: number;
    accessReviewUserAssignments: Array<{
      __typename?: "AccessReviewUserAssignment";
      id: string;
      userId: string;
      userFullname: string;
      userAvatarUrl: string;
      resourceId?: string | null;
      groupId?: string | null;
      connectionId?: string | null;
      resourceType?: ResourceType | null;
      groupType?: GroupType | null;
      connectionType?: ConnectionType | null;
      entityName: string;
      parentResourceName?: string | null;
      accessReviewObjectId: string;
      accessLevelRemoteId?: string | null;
      accessLevelName?: string | null;
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
      reviewerUsers?: Array<{
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      }> | null;
    }>;
  };
};

export type AccessReviewGroupResourceAssignmentsQueryVariables = Exact<{
  input: AccessReviewGroupResourceAssignmentsInput;
}>;

export type AccessReviewGroupResourceAssignmentsQuery = {
  __typename?: "Query";
  accessReviewGroupResourceAssignments: {
    __typename?: "AccessReviewGroupResourceAssignmentsResult";
    cursor?: string | null;
    totalNumGroupAssignments: number;
    accessReviewGroupResourceAssignments: Array<{
      __typename?: "AccessReviewGroupResourceAssignment";
      id: string;
      resourceId: string;
      resourceType: ResourceType;
      resourceName: string;
      groupId: string;
      groupType: GroupType;
      groupName: string;
      accessReviewGroupId: string;
      accessLevelRemoteId?: string | null;
      accessLevelName?: string | null;
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
      reviewerUsers?: Array<{
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      }> | null;
    }>;
  };
};

export type AccessReviewResourcePrincipalAssignmentsQueryVariables = Exact<{
  input: AccessReviewResourcePrincipalAssignmentsInput;
}>;

export type AccessReviewResourcePrincipalAssignmentsQuery = {
  __typename?: "Query";
  accessReviewResourcePrincipalAssignments: {
    __typename?: "AccessReviewResourcePrincipalAssignmentsResult";
    cursor?: string | null;
    totalNumResourcePrincipalAssignments: number;
    accessReviewResourcePrincipalAssignments: Array<{
      __typename?: "AccessReviewResourcePrincipalAssignment";
      id: string;
      resourcePrincipalId: string;
      resourcePrincipalName: string;
      resourcePrincipalType: ResourceType;
      entityId: string;
      entityType: EntityType;
      entityName: string;
      entityItemType: string;
      accessLevelRemoteId?: string | null;
      accessLevelName?: string | null;
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
      reviewerUsers?: Array<{
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      }> | null;
    }>;
  };
};

export type UpdateAccessReviewReviewersMutationVariables = Exact<{
  input: UpdateAccessReviewReviewersInput;
}>;

export type UpdateAccessReviewReviewersMutation = {
  __typename?: "Mutation";
  updateAccessReviewReviewers:
    | {
        __typename: "UpdateAccessReviewReviewersResult";
        success?: boolean | null;
      }
    | {
        __typename: "UpdateAccessReviewReviewersValidationError";
        message: string;
      };
};

export type AccessReviewUserAssignmentFragment = {
  __typename?: "AccessReviewUserAssignment";
  id: string;
  userId: string;
  userFullname: string;
  userAvatarUrl: string;
  resourceId?: string | null;
  groupId?: string | null;
  connectionId?: string | null;
  resourceType?: ResourceType | null;
  groupType?: GroupType | null;
  connectionType?: ConnectionType | null;
  entityName: string;
  parentResourceName?: string | null;
  accessReviewObjectId: string;
  accessLevelRemoteId?: string | null;
  accessLevelName?: string | null;
  status: AccessReviewItemStatus;
  outcome: AccessReviewItemOutcome;
  reviewerUsers?: Array<{
    __typename?: "User";
    id: string;
    fullName: string;
    avatarUrl: string;
  }> | null;
};

export type AccessReviewGroupResourceAssignmentFragment = {
  __typename?: "AccessReviewGroupResourceAssignment";
  id: string;
  resourceId: string;
  resourceType: ResourceType;
  resourceName: string;
  groupId: string;
  groupType: GroupType;
  groupName: string;
  accessReviewGroupId: string;
  accessLevelRemoteId?: string | null;
  accessLevelName?: string | null;
  status: AccessReviewItemStatus;
  outcome: AccessReviewItemOutcome;
  reviewerUsers?: Array<{
    __typename?: "User";
    id: string;
    fullName: string;
    avatarUrl: string;
  }> | null;
};

export type AccessReviewResourcePrincipalAssignmentFragment = {
  __typename?: "AccessReviewResourcePrincipalAssignment";
  id: string;
  resourcePrincipalId: string;
  resourcePrincipalName: string;
  resourcePrincipalType: ResourceType;
  entityId: string;
  entityType: EntityType;
  entityName: string;
  entityItemType: string;
  accessLevelRemoteId?: string | null;
  accessLevelName?: string | null;
  status: AccessReviewItemStatus;
  outcome: AccessReviewItemOutcome;
  reviewerUsers?: Array<{
    __typename?: "User";
    id: string;
    fullName: string;
    avatarUrl: string;
  }> | null;
};

export type AccessReviewChangesQueryVariables = Exact<{
  input: AccessReviewChangesInput;
}>;

export type AccessReviewChangesQuery = {
  __typename?: "Query";
  accessReviewChanges: {
    __typename?: "AccessReviewChangesOutput";
    items: Array<{
      __typename?: "AccessReviewChangeItem";
      item: {
        __typename?: "AccessReviewItem";
        key: string;
        resource?: {
          __typename?: "AccessReviewResource";
          id: string;
          status: AccessReviewSummaryStatus;
          resource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        } | null;
        group?: {
          __typename?: "AccessReviewGroup";
          id: string;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        } | null;
        connection?: {
          __typename?: "AccessReviewConnection";
          id: string;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        } | null;
      };
    }>;
  };
};

export type AccessReviewChangeDetailsQueryVariables = Exact<{
  input: AccessReviewChangeInput;
}>;

export type AccessReviewChangeDetailsQuery = {
  __typename?: "Query";
  accessReviewChange: {
    __typename?: "AccessReviewChangeOutput";
    users: {
      __typename?: "AccessReviewChangeUsers";
      resourceUsers?: Array<{
        __typename?: "AccessReviewResourceUser";
        id: string;
        createdAt: string;
        updatedAt: string;
        accessReviewResourceId: string;
        userId: string;
        resourceId: string;
        eventId: string;
        supportTicketId?: string | null;
        errorMessage?: string | null;
        statusCode?: PropagationStatusCode | null;
        taskType?: PropagationTaskType | null;
        lastSynced?: string | null;
        requestId?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename?: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        };
        reviewerUsers?: Array<{
          __typename?: "ReviewerUser";
          userId: string;
          entityId: string;
          entityType: EntityType;
          name: string;
          avatarUrl: string;
          status: ReviewerUserStatus;
          note?: string | null;
          updatedResourceId?: string | null;
          updatedAccessLevel?: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          } | null;
          updatedResource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
        }> | null;
        warnings?: Array<{
          __typename?: "AccessReviewResourceUserWarning";
          accessReviewResourceUserReviewerId: string;
          accessReviewResourceUserId: string;
          accessReviewResourceId: string;
          accessReviewUserWarningType: AccessReviewUserWarningType;
          userId: string;
          reviewerId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          reviewer?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          resource?: {
            __typename?: "ResourceSmall";
            id: string;
            name: string;
          } | null;
        }> | null;
        statusAndOutcome: {
          __typename?: "AccessReviewItemStatusAndOutcome";
          status: AccessReviewItemStatus;
          outcome: AccessReviewItemOutcome;
        };
        resourceUser?: {
          __typename?: "ResourceUser";
          resourceId: string;
          userId: string;
          source: ResourceUserSource;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          summary: string;
          issueType: string;
          projectName: string;
        } | null;
      }> | null;
      groupUsers?: Array<{
        __typename?: "AccessReviewGroupUser";
        id: string;
        createdAt: string;
        updatedAt: string;
        accessReviewGroupId: string;
        userId: string;
        groupId: string;
        eventId: string;
        errorMessage?: string | null;
        statusCode?: PropagationStatusCode | null;
        taskType?: PropagationTaskType | null;
        lastSynced?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel: {
          __typename?: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        };
        reviewerUsers?: Array<{
          __typename?: "ReviewerUser";
          userId: string;
          entityId: string;
          entityType: EntityType;
          name: string;
          avatarUrl: string;
          status: ReviewerUserStatus;
          note?: string | null;
          updatedResourceId?: string | null;
          updatedAccessLevel?: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          } | null;
          updatedResource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
        }> | null;
        warnings?: Array<{
          __typename?: "AccessReviewGroupUserWarning";
          accessReviewGroupUserReviewerId: string;
          accessReviewGroupUserId: string;
          accessReviewGroupId: string;
          accessReviewUserWarningType: AccessReviewUserWarningType;
          userId: string;
          reviewerId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          reviewer?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          group?: {
            __typename?: "GroupSmall";
            id: string;
            name: string;
          } | null;
        }> | null;
        statusAndOutcome: {
          __typename?: "AccessReviewItemStatusAndOutcome";
          status: AccessReviewItemStatus;
          outcome: AccessReviewItemOutcome;
        };
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
      }> | null;
      connectionUsers?: Array<{
        __typename?: "AccessReviewConnectionUser";
        id: string;
        createdAt: string;
        updatedAt: string;
        accessReviewConnectionId: string;
        userId: string;
        connectionId: string;
        eventId?: string | null;
        errorMessage?: string | null;
        statusCode?: PropagationStatusCode | null;
        taskType?: PropagationTaskType | null;
        lastSynced?: string | null;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        reviewerUsers?: Array<{
          __typename?: "ReviewerUser";
          userId: string;
          entityId: string;
          entityType: EntityType;
          name: string;
          avatarUrl: string;
          status: ReviewerUserStatus;
          note?: string | null;
          updatedResourceId?: string | null;
          updatedAccessLevel?: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          } | null;
          updatedResource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
        }> | null;
        warnings?: Array<{
          __typename?: "AccessReviewConnectionUserWarning";
          accessReviewConnectionUserReviewerId: string;
          accessReviewConnectionUserId: string;
          accessReviewConnectionId: string;
          accessReviewUserWarningType: AccessReviewUserWarningType;
          userId: string;
          reviewerId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          reviewer?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          connection?: {
            __typename?: "ConnectionSmall";
            id: string;
            name: string;
          } | null;
        }> | null;
        statusAndOutcome: {
          __typename?: "AccessReviewItemStatusAndOutcome";
          status: AccessReviewItemStatus;
          outcome: AccessReviewItemOutcome;
        };
      }> | null;
    };
  };
};

export type AccessReviewChangesWithDetailsQueryVariables = Exact<{
  input: AccessReviewChangesInput;
}>;

export type AccessReviewChangesWithDetailsQuery = {
  __typename?: "Query";
  accessReviewChanges: {
    __typename?: "AccessReviewChangesOutput";
    items: Array<{
      __typename?: "AccessReviewChangeItem";
      users?: {
        __typename?: "AccessReviewChangeUsers";
        resourceUsers?: Array<{
          __typename?: "AccessReviewResourceUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewResourceId: string;
          userId: string;
          resourceId: string;
          eventId: string;
          supportTicketId?: string | null;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          requestId?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename?: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewResourceUserWarning";
            accessReviewResourceUserReviewerId: string;
            accessReviewResourceUserId: string;
            accessReviewResourceId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            resource?: {
              __typename?: "ResourceSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
          resourceUser?: {
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            summary: string;
            issueType: string;
            projectName: string;
          } | null;
        }> | null;
        groupUsers?: Array<{
          __typename?: "AccessReviewGroupUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewGroupId: string;
          userId: string;
          groupId: string;
          eventId: string;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel: {
            __typename?: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewGroupUserWarning";
            accessReviewGroupUserReviewerId: string;
            accessReviewGroupUserId: string;
            accessReviewGroupId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            group?: {
              __typename?: "GroupSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          } | null;
        }> | null;
        connectionUsers?: Array<{
          __typename?: "AccessReviewConnectionUser";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewConnectionId: string;
          userId: string;
          connectionId: string;
          eventId?: string | null;
          errorMessage?: string | null;
          statusCode?: PropagationStatusCode | null;
          taskType?: PropagationTaskType | null;
          lastSynced?: string | null;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          warnings?: Array<{
            __typename?: "AccessReviewConnectionUserWarning";
            accessReviewConnectionUserReviewerId: string;
            accessReviewConnectionUserId: string;
            accessReviewConnectionId: string;
            accessReviewUserWarningType: AccessReviewUserWarningType;
            userId: string;
            reviewerId?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            reviewer?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            connection?: {
              __typename?: "ConnectionSmall";
              id: string;
              name: string;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
        }> | null;
      } | null;
      nhis?: {
        __typename?: "AccessReviewChangeNHIs";
        resourceResources?: Array<{
          __typename?: "AccessReviewResourceResource";
          id: string;
          createdAt: string;
          updatedAt: string;
          accessReviewId: string;
          accessReviewResourceEntityId?: string | null;
          resourceEntityId: string;
          isResourceEntityDeleted: boolean;
          accessReviewResourcePrincipalId?: string | null;
          resourcePrincipalId: string;
          isResourcePrincipalDeleted: boolean;
          eventId?: string | null;
          resourceResourceAccess?: {
            __typename?: "Access";
            entityId: string;
            entityType: EntityType;
            principalId: string;
            principalType: EntityType;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            latestExpiringAccessPoint: {
              __typename?: "AccessPoint";
              entityId: string;
              entityType: EntityType;
              entityName: string;
              entityItemType: string;
              principalId: string;
              principalType: EntityType;
              principalName: string;
              eventId?: string | null;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "AccessPoint";
              entityId: string;
              entityType: EntityType;
              entityName: string;
              entityItemType: string;
              principalId: string;
              principalType: EntityType;
              principalName: string;
              eventId?: string | null;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "AccessPoint";
              entityId: string;
              entityType: EntityType;
              entityName: string;
              entityItemType: string;
              principalId: string;
              principalType: EntityType;
              principalName: string;
              eventId?: string | null;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
          resourcePrincipal?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          resourceEntity?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          accessLevel: {
            __typename?: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          reviewerUsers?: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }> | null;
          statusAndOutcome: {
            __typename?: "AccessReviewItemStatusAndOutcome";
            status: AccessReviewItemStatus;
            outcome: AccessReviewItemOutcome;
          };
        }> | null;
      } | null;
      groupResources?: Array<{
        __typename?: "AccessReviewGroupResource";
        id: string;
        accessLevel: {
          __typename?: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        };
        reviewerUsers?: Array<{
          __typename?: "ReviewerUser";
          userId: string;
          entityId: string;
          entityType: EntityType;
          name: string;
          avatarUrl: string;
          status: ReviewerUserStatus;
          note?: string | null;
          updatedResourceId?: string | null;
          updatedAccessLevel?: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          } | null;
          updatedResource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
        }> | null;
        statusAndOutcome: {
          __typename?: "AccessReviewItemStatusAndOutcome";
          status: AccessReviewItemStatus;
          outcome: AccessReviewItemOutcome;
        };
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        groupResourceAccess?: {
          __typename?: "GroupResourceAccess";
          groupId: string;
          resourceId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupResourceAccessPoint";
            groupId: string;
            resourceId: string;
            resourceName: string;
            resourceType: ResourceType;
            groupName: string;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "GroupResourceAccessPoint";
            groupId: string;
            resourceId: string;
            resourceName: string;
            resourceType: ResourceType;
            groupName: string;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupResourceAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            resourceId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            resolvedAccessPoint?: {
              __typename?: "GroupResourceAccessPoint";
              groupId: string;
              resourceId: string;
              resourceName: string;
              resourceType: ResourceType;
              groupName: string;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPath?: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }> | null;
          }>;
          latestExpiringAccessPoint: {
            __typename?: "GroupResourceAccessPoint";
            groupId: string;
            resourceId: string;
            resourceName: string;
            resourceType: ResourceType;
            groupName: string;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
        } | null;
      }> | null;
      item: {
        __typename?: "AccessReviewItem";
        key: string;
        resource?: {
          __typename?: "AccessReviewResource";
          id: string;
          status: AccessReviewSummaryStatus;
          resource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        } | null;
        group?: {
          __typename?: "AccessReviewGroup";
          id: string;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        } | null;
        connection?: {
          __typename?: "AccessReviewConnection";
          id: string;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        } | null;
      };
    }>;
  };
};

export type AccessReviewChangeItemSmallFragment = {
  __typename?: "AccessReviewChangeItem";
  item: {
    __typename?: "AccessReviewItem";
    key: string;
    resource?: {
      __typename?: "AccessReviewResource";
      id: string;
      status: AccessReviewSummaryStatus;
      resource?: {
        __typename?: "Resource";
        id: string;
        name: string;
        resourceType: ResourceType;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewResourceReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
        numNHIsReviewed: number;
        numNHIsAssigned: number;
        numResourcesReviewed: number;
        numResourcesAssigned: number;
      } | null;
    } | null;
    group?: {
      __typename?: "AccessReviewGroup";
      id: string;
      status: AccessReviewSummaryStatus;
      group?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewGroupReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
        numResourcesReviewed: number;
        numResourcesAssigned: number;
      } | null;
    } | null;
    connection?: {
      __typename?: "AccessReviewConnection";
      id: string;
      status: AccessReviewSummaryStatus;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewConnectionReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
      } | null;
    } | null;
  };
};

export type AccessReviewEventsQueryVariables = Exact<{
  input: EventsInput;
}>;

export type AccessReviewEventsQuery = {
  __typename?: "Query";
  events:
    | {
        __typename: "EventsResult";
        cursor?: string | null;
        events: Array<{
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          numSubEvents: number;
          numErrorSubEvents: number;
          actorUser?: {
            __typename?: "User";
            fullName: string;
            avatarUrl: string;
          } | null;
          subEvents: Array<{
            __typename?: "SubEvent";
            id: string;
            subEventType: EventType;
            objectId: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            };
            secondaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            tertiaryObjectId?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
            object4Id?: {
              __typename?: "EntityIdTuple";
              entityId: string;
              entityType: EntityType;
            } | null;
          }>;
        }>;
      }
    | { __typename: "InvalidObjectIDFilterError" };
};

export type EventWithSubEventInfoFragment = {
  __typename?: "Event";
  id: string;
  createdAt: string;
  actorUserId: string;
  actorIP?: string | null;
  actorUserAgent?: string | null;
  eventType: EventType;
  source: ConnectionType;
  numSubEvents: number;
  numErrorSubEvents: number;
  actorUser?: {
    __typename?: "User";
    fullName: string;
    avatarUrl: string;
  } | null;
  subEvents: Array<{
    __typename?: "SubEvent";
    id: string;
    subEventType: EventType;
    objectId: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    };
    secondaryObjectId?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
    tertiaryObjectId?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
    object4Id?: {
      __typename?: "EntityIdTuple";
      entityId: string;
      entityType: EntityType;
    } | null;
  }>;
};

export type AccessReviewGroupDetailsQueryVariables = Exact<{
  input: AccessReviewGroupInput;
}>;

export type AccessReviewGroupDetailsQuery = {
  __typename?: "Query";
  accessReviewGroup:
    | { __typename: "AccessReviewGroupNotFoundError"; message: string }
    | {
        __typename: "AccessReviewGroupResult";
        accessReviewGroup: {
          __typename?: "AccessReviewGroup";
          id: string;
          createdAt: string;
          accessReviewId: string;
          numGroupUsers: number;
          numGroupResources: number;
          group?: {
            __typename: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            groupType: GroupType;
            serviceType: ServiceType;
            iconUrl?: string | null;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            updatedAt: string;
            description: string;
            numGroupUsers: number;
            connectionId: string;
            isOnCallSynced: boolean;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            isManaged: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            groupBindingId?: string | null;
            customRequestNotification?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserGroupAccess";
              groupId: string;
              hasBreakGlass: boolean;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
              pendingRequest?: {
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              } | null;
            };
            tags: Array<{
              __typename?: "GroupTag";
              groupId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            onCallSchedules?: Array<{
              __typename?: "GroupOnCallSchedule";
              onCallSchedule: {
                __typename?: "OnCallSchedule";
                remoteId: string;
                thirdPartyProvider: ThirdPartyProvider;
                name: string;
                existsInRemote: boolean;
              };
            }> | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              createdAt: string;
              sourceGroup?: {
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              } | null;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
              }>;
              createdByUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            groupLeaders: Array<{
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            }>;
          } | null;
          accessReview?: {
            __typename?: "AccessReview";
            stoppedDate?: string | null;
            selfReviewAllowed: boolean;
            groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          } | null;
          reviewerUsers: Array<{
            __typename?: "ReviewerUser";
            userId: string;
            entityId: string;
            entityType: EntityType;
            name: string;
            avatarUrl: string;
            status: ReviewerUserStatus;
            note?: string | null;
            updatedResourceId?: string | null;
            updatedAccessLevel?: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            updatedResource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
          }>;
          groupUsers?: Array<{
            __typename?: "AccessReviewGroupUser";
            id: string;
            createdAt: string;
            updatedAt: string;
            userId: string;
            groupId: string;
            accessReviewGroupId: string;
            eventId: string;
            errorMessage?: string | null;
            statusCode?: PropagationStatusCode | null;
            taskType?: PropagationTaskType | null;
            lastSynced?: string | null;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename?: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            warnings?: Array<{
              __typename?: "AccessReviewGroupUserWarning";
              accessReviewGroupUserReviewerId: string;
              accessReviewGroupUserId: string;
              accessReviewGroupId: string;
              accessReviewUserWarningType: AccessReviewUserWarningType;
              userId: string;
              reviewerId?: string | null;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              reviewer?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              group?: {
                __typename?: "GroupSmall";
                id: string;
                name: string;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
            } | null;
          }> | null;
          groupResources?: Array<{
            __typename?: "AccessReviewGroupResource";
            id: string;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            reviewerUsers?: Array<{
              __typename?: "ReviewerUser";
              userId: string;
              entityId: string;
              entityType: EntityType;
              name: string;
              avatarUrl: string;
              status: ReviewerUserStatus;
              note?: string | null;
              updatedResourceId?: string | null;
              updatedAccessLevel?: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              updatedResource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
            }> | null;
            statusAndOutcome: {
              __typename?: "AccessReviewItemStatusAndOutcome";
              status: AccessReviewItemStatus;
              outcome: AccessReviewItemOutcome;
            };
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            groupResourceAccess?: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            } | null;
          }> | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
          allGroupResources?: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            accessLevel: {
              __typename?: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
          }> | null;
        };
      };
};

export type AccessReviewGroupForDetailFragment = {
  __typename?: "AccessReviewGroup";
  id: string;
  createdAt: string;
  accessReviewId: string;
  numGroupUsers: number;
  numGroupResources: number;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
  accessReview?: {
    __typename?: "AccessReview";
    stoppedDate?: string | null;
    selfReviewAllowed: boolean;
    groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  } | null;
  reviewerUsers: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }>;
  groupUsers?: Array<{
    __typename?: "AccessReviewGroupUser";
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: string;
    groupId: string;
    accessReviewGroupId: string;
    eventId: string;
    errorMessage?: string | null;
    statusCode?: PropagationStatusCode | null;
    taskType?: PropagationTaskType | null;
    lastSynced?: string | null;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    warnings?: Array<{
      __typename?: "AccessReviewGroupUserWarning";
      accessReviewGroupUserReviewerId: string;
      accessReviewGroupUserId: string;
      accessReviewGroupId: string;
      accessReviewUserWarningType: AccessReviewUserWarningType;
      userId: string;
      reviewerId?: string | null;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      reviewer?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      group?: { __typename?: "GroupSmall"; id: string; name: string } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
    } | null;
  }> | null;
  groupResources?: Array<{
    __typename?: "AccessReviewGroupResource";
    id: string;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    reviewerUsers?: Array<{
      __typename?: "ReviewerUser";
      userId: string;
      entityId: string;
      entityType: EntityType;
      name: string;
      avatarUrl: string;
      status: ReviewerUserStatus;
      note?: string | null;
      updatedResourceId?: string | null;
      updatedAccessLevel?: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      updatedResource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
    }> | null;
    statusAndOutcome: {
      __typename?: "AccessReviewItemStatusAndOutcome";
      status: AccessReviewItemStatus;
      outcome: AccessReviewItemOutcome;
    };
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    groupResourceAccess?: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    } | null;
  }> | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewGroupReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
  allGroupResources?: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
  }> | null;
};

export type GroupResourceForContextFragment = {
  __typename?: "GroupResource";
  groupId: string;
  resourceId: string;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  access: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  };
};

export type AccessReviewGroupResourceForDetailFragment = {
  __typename?: "AccessReviewGroupResource";
  id: string;
  accessLevel: {
    __typename?: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  };
  reviewerUsers?: Array<{
    __typename?: "ReviewerUser";
    userId: string;
    entityId: string;
    entityType: EntityType;
    name: string;
    avatarUrl: string;
    status: ReviewerUserStatus;
    note?: string | null;
    updatedResourceId?: string | null;
    updatedAccessLevel?: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    updatedResource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
  }> | null;
  statusAndOutcome: {
    __typename?: "AccessReviewItemStatusAndOutcome";
    status: AccessReviewItemStatus;
    outcome: AccessReviewItemOutcome;
  };
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  } | null;
  groupResourceAccess?: {
    __typename?: "GroupResourceAccess";
    groupId: string;
    resourceId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    indirectAccessPointPaths: Array<{
      __typename?: "IndirectGroupResourceAccessPointPath";
      startGroupId: string;
      endGroupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      resolvedAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPath?: Array<{
        __typename?: "IndirectGroupAccessPoint";
        groupId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
      }> | null;
    }>;
    latestExpiringAccessPoint: {
      __typename?: "GroupResourceAccessPoint";
      groupId: string;
      resourceId: string;
      resourceName: string;
      resourceType: ResourceType;
      groupName: string;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
  } | null;
};

export type MyAccessReviewItemsV3QueryVariables = Exact<{
  input: MyAccessReviewItemsInput;
}>;

export type MyAccessReviewItemsV3Query = {
  __typename?: "Query";
  myAccessReviewItems:
    | { __typename?: "AccessReviewNotFoundError"; message: string }
    | {
        __typename?: "MyAccessReviewItemsResult";
        resources: Array<{
          __typename?: "AccessReviewResource";
          id: string;
          status: AccessReviewSummaryStatus;
          resource?: {
            __typename?: "Resource";
            description: string;
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        }>;
        groups: Array<{
          __typename?: "AccessReviewGroup";
          id: string;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename?: "Group";
            description: string;
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        }>;
        connections: Array<{
          __typename?: "AccessReviewConnection";
          id: string;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename?: "Connection";
            description: string;
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        }>;
      };
};

export type AccessReviewMyResourceV3Fragment = {
  __typename?: "AccessReviewResource";
  id: string;
  status: AccessReviewSummaryStatus;
  resource?: {
    __typename?: "Resource";
    description: string;
    id: string;
    name: string;
    resourceType: ResourceType;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewResourceReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numNHIsReviewed: number;
    numNHIsAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewMyGroupV3Fragment = {
  __typename?: "AccessReviewGroup";
  id: string;
  status: AccessReviewSummaryStatus;
  group?: {
    __typename?: "Group";
    description: string;
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewGroupReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewMyConnectionV3Fragment = {
  __typename?: "AccessReviewConnection";
  id: string;
  status: AccessReviewSummaryStatus;
  connection?: {
    __typename?: "Connection";
    description: string;
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewConnectionReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
  } | null;
};

export type AccessReviewReviewersQueryVariables = Exact<{
  accessReviewId: Scalars["AccessReviewId"];
}>;

export type AccessReviewReviewersQuery = {
  __typename?: "Query";
  accessReview:
    | { __typename: "AccessReviewNotFoundError"; message: string }
    | {
        __typename: "AccessReviewResult";
        accessReview: {
          __typename?: "AccessReview";
          id: string;
          name: string;
          reviewers?: Array<{
            __typename?: "AccessReviewReviewer";
            userId: string;
            totalItemsCount: number;
            completedItemsCount: number;
            totalUsersCount: number;
            completedUsersCount: number;
            totalGroupResourcesCount: number;
            completedGroupResourcesCount: number;
            totalNHIsCount: number;
            completedNHIsCount: number;
            user?: {
              __typename?: "User";
              id: string;
              fullName: string;
              email: string;
              avatarUrl: string;
            } | null;
          }> | null;
        };
      };
};

export type AccessReviewReviewerQueryVariables = Exact<{
  input: AccessReviewReviewerInput;
}>;

export type AccessReviewReviewerQuery = {
  __typename?: "Query";
  accessReviewReviewer: {
    __typename?: "AccessReviewReviewerOutput";
    reviewer: {
      __typename?: "AccessReviewReviewer";
      userId: string;
      totalItemsCount: number;
      completedItemsCount: number;
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        email: string;
        avatarUrl: string;
      } | null;
      items?: Array<{
        __typename?: "AccessReviewReviewerItem";
        reviewStatus: AccessReviewItemStatus;
        item: {
          __typename?: "AccessReviewItem";
          key: string;
          resource?: {
            __typename?: "AccessReviewResource";
            id: string;
            status: AccessReviewSummaryStatus;
            resource?: {
              __typename?: "Resource";
              id: string;
              name: string;
              resourceType: ResourceType;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
            } | null;
            currentUserReviewerStatus?: {
              __typename?: "AccessReviewResourceReviewerStatus";
              itemStatus: AccessReviewItemStatus;
              numUsersReviewed: number;
              numUsersAssigned: number;
              numNHIsReviewed: number;
              numNHIsAssigned: number;
              numResourcesReviewed: number;
              numResourcesAssigned: number;
            } | null;
          } | null;
          group?: {
            __typename?: "AccessReviewGroup";
            id: string;
            status: AccessReviewSummaryStatus;
            group?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            currentUserReviewerStatus?: {
              __typename?: "AccessReviewGroupReviewerStatus";
              itemStatus: AccessReviewItemStatus;
              numUsersReviewed: number;
              numUsersAssigned: number;
              numResourcesReviewed: number;
              numResourcesAssigned: number;
            } | null;
          } | null;
          connection?: {
            __typename?: "AccessReviewConnection";
            id: string;
            status: AccessReviewSummaryStatus;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            currentUserReviewerStatus?: {
              __typename?: "AccessReviewConnectionReviewerStatus";
              itemStatus: AccessReviewItemStatus;
              numUsersReviewed: number;
              numUsersAssigned: number;
            } | null;
          } | null;
        };
      }> | null;
    };
  };
};

export type AccessReviewReviewerFragment = {
  __typename?: "AccessReviewReviewer";
  userId: string;
  totalItemsCount: number;
  completedItemsCount: number;
  totalUsersCount: number;
  completedUsersCount: number;
  totalGroupResourcesCount: number;
  completedGroupResourcesCount: number;
  totalNHIsCount: number;
  completedNHIsCount: number;
  user?: {
    __typename?: "User";
    id: string;
    fullName: string;
    email: string;
    avatarUrl: string;
  } | null;
};

export type AccessReviewReviewerItemFragment = {
  __typename?: "AccessReviewReviewerItem";
  reviewStatus: AccessReviewItemStatus;
  item: {
    __typename?: "AccessReviewItem";
    key: string;
    resource?: {
      __typename?: "AccessReviewResource";
      id: string;
      status: AccessReviewSummaryStatus;
      resource?: {
        __typename?: "Resource";
        id: string;
        name: string;
        resourceType: ResourceType;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewResourceReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
        numNHIsReviewed: number;
        numNHIsAssigned: number;
        numResourcesReviewed: number;
        numResourcesAssigned: number;
      } | null;
    } | null;
    group?: {
      __typename?: "AccessReviewGroup";
      id: string;
      status: AccessReviewSummaryStatus;
      group?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewGroupReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
        numResourcesReviewed: number;
        numResourcesAssigned: number;
      } | null;
    } | null;
    connection?: {
      __typename?: "AccessReviewConnection";
      id: string;
      status: AccessReviewSummaryStatus;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      currentUserReviewerStatus?: {
        __typename?: "AccessReviewConnectionReviewerStatus";
        itemStatus: AccessReviewItemStatus;
        numUsersReviewed: number;
        numUsersAssigned: number;
      } | null;
    } | null;
  };
};

export type AccessReviewReviewerDetailFragment = {
  __typename?: "AccessReviewReviewer";
  userId: string;
  totalItemsCount: number;
  completedItemsCount: number;
  user?: {
    __typename?: "User";
    id: string;
    fullName: string;
    email: string;
    avatarUrl: string;
  } | null;
  items?: Array<{
    __typename?: "AccessReviewReviewerItem";
    reviewStatus: AccessReviewItemStatus;
    item: {
      __typename?: "AccessReviewItem";
      key: string;
      resource?: {
        __typename?: "AccessReviewResource";
        id: string;
        status: AccessReviewSummaryStatus;
        resource?: {
          __typename?: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewResourceReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
          numNHIsReviewed: number;
          numNHIsAssigned: number;
          numResourcesReviewed: number;
          numResourcesAssigned: number;
        } | null;
      } | null;
      group?: {
        __typename?: "AccessReviewGroup";
        id: string;
        status: AccessReviewSummaryStatus;
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewGroupReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
          numResourcesReviewed: number;
          numResourcesAssigned: number;
        } | null;
      } | null;
      connection?: {
        __typename?: "AccessReviewConnection";
        id: string;
        status: AccessReviewSummaryStatus;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewConnectionReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
        } | null;
      } | null;
    };
  }> | null;
};

export type AccessReviewResourceSmallFragment = {
  __typename?: "AccessReviewResource";
  id: string;
  status: AccessReviewSummaryStatus;
  resource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewResourceReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numNHIsReviewed: number;
    numNHIsAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewGroupSmallFragment = {
  __typename?: "AccessReviewGroup";
  id: string;
  status: AccessReviewSummaryStatus;
  group?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewGroupReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
    numResourcesReviewed: number;
    numResourcesAssigned: number;
  } | null;
};

export type AccessReviewConnectionSmallFragment = {
  __typename?: "AccessReviewConnection";
  id: string;
  status: AccessReviewSummaryStatus;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
  currentUserReviewerStatus?: {
    __typename?: "AccessReviewConnectionReviewerStatus";
    itemStatus: AccessReviewItemStatus;
    numUsersReviewed: number;
    numUsersAssigned: number;
  } | null;
};

export type AccessReviewItemFragment = {
  __typename?: "AccessReviewItem";
  key: string;
  resource?: {
    __typename?: "AccessReviewResource";
    id: string;
    status: AccessReviewSummaryStatus;
    resource?: {
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    } | null;
    currentUserReviewerStatus?: {
      __typename?: "AccessReviewResourceReviewerStatus";
      itemStatus: AccessReviewItemStatus;
      numUsersReviewed: number;
      numUsersAssigned: number;
      numNHIsReviewed: number;
      numNHIsAssigned: number;
      numResourcesReviewed: number;
      numResourcesAssigned: number;
    } | null;
  } | null;
  group?: {
    __typename?: "AccessReviewGroup";
    id: string;
    status: AccessReviewSummaryStatus;
    group?: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    currentUserReviewerStatus?: {
      __typename?: "AccessReviewGroupReviewerStatus";
      itemStatus: AccessReviewItemStatus;
      numUsersReviewed: number;
      numUsersAssigned: number;
      numResourcesReviewed: number;
      numResourcesAssigned: number;
    } | null;
  } | null;
  connection?: {
    __typename?: "AccessReviewConnection";
    id: string;
    status: AccessReviewSummaryStatus;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    currentUserReviewerStatus?: {
      __typename?: "AccessReviewConnectionReviewerStatus";
      itemStatus: AccessReviewItemStatus;
      numUsersReviewed: number;
      numUsersAssigned: number;
    } | null;
  } | null;
};

export type MyAccessReviewItemsQueryVariables = Exact<{
  input: MyAccessReviewItemsInput;
}>;

export type MyAccessReviewItemsQuery = {
  __typename?: "Query";
  myAccessReviewItems:
    | { __typename?: "AccessReviewNotFoundError"; message: string }
    | {
        __typename?: "MyAccessReviewItemsResult";
        resources: Array<{
          __typename?: "AccessReviewResource";
          id: string;
          status: AccessReviewSummaryStatus;
          resource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewResourceReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numNHIsReviewed: number;
            numNHIsAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        }>;
        groups: Array<{
          __typename?: "AccessReviewGroup";
          id: string;
          status: AccessReviewSummaryStatus;
          group?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewGroupReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
            numResourcesReviewed: number;
            numResourcesAssigned: number;
          } | null;
        }>;
        connections: Array<{
          __typename?: "AccessReviewConnection";
          id: string;
          status: AccessReviewSummaryStatus;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          currentUserReviewerStatus?: {
            __typename?: "AccessReviewConnectionReviewerStatus";
            itemStatus: AccessReviewItemStatus;
            numUsersReviewed: number;
            numUsersAssigned: number;
          } | null;
        }>;
      };
};

export type AccessReviewItemsQueryVariables = Exact<{
  input: AccessReviewItemsInput;
}>;

export type AccessReviewItemsQuery = {
  __typename?: "Query";
  accessReviewItems: {
    __typename?: "AccessReviewItemsResult";
    cursor?: string | null;
    items: Array<{
      __typename?: "AccessReviewItem";
      key: string;
      resource?: {
        __typename?: "AccessReviewResource";
        id: string;
        status: AccessReviewSummaryStatus;
        resource?: {
          __typename?: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewResourceReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
          numNHIsReviewed: number;
          numNHIsAssigned: number;
          numResourcesReviewed: number;
          numResourcesAssigned: number;
        } | null;
      } | null;
      group?: {
        __typename?: "AccessReviewGroup";
        id: string;
        status: AccessReviewSummaryStatus;
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewGroupReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
          numResourcesReviewed: number;
          numResourcesAssigned: number;
        } | null;
      } | null;
      connection?: {
        __typename?: "AccessReviewConnection";
        id: string;
        status: AccessReviewSummaryStatus;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        currentUserReviewerStatus?: {
          __typename?: "AccessReviewConnectionReviewerStatus";
          itemStatus: AccessReviewItemStatus;
          numUsersReviewed: number;
          numUsersAssigned: number;
        } | null;
      } | null;
    }>;
  };
};

export type ResourceMoreInfoQueryVariables = Exact<{
  input: ResourceInput;
}>;

export type ResourceMoreInfoQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError" }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
          } | null;
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
        };
      };
};

export type StartAccessReviewMutationVariables = Exact<{
  input: StartAccessReviewInput;
}>;

export type StartAccessReviewMutation = {
  __typename?: "Mutation";
  startAccessReview: {
    __typename: "StartAccessReviewResult";
    accessReview: { __typename?: "AccessReview"; id: string };
  };
};

export type AccessReviewTemplateQueryVariables = Exact<{
  input: AccessReviewTemplateInput;
}>;

export type AccessReviewTemplateQuery = {
  __typename?: "Query";
  accessReviewTemplate:
    | { __typename: "AccessReviewTemplateNotFound"; message: string }
    | {
        __typename: "AccessReviewTemplateResult";
        accessReviewTemplate: {
          __typename?: "AccessReviewTemplate";
          id: string;
          createdAt: string;
          createdByUserId: string;
          updatedAt: string;
          name: string;
          accessReviewDuration: number;
          monthSchedule: Array<number>;
          nextScheduledRun: string;
          startDayOfMonth: number;
          timeZone: string;
          reminderSchedule: Array<number>;
          reminderTimeOfDay: string;
          reminderIncludeManager: boolean;
          sendReviewerAssignmentNotification: boolean;
          selfReviewAllowed: boolean;
          reviewerAssignmentPolicy: AccessReviewReviewerAssignmentPolicy;
          scopeResourcesCount: number;
          scopeGroupsCount: number;
          scopeAppsCount: number;
          includeGroupBindings: boolean;
          groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
          createdByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          filters?: {
            __typename?: "AccessReviewFilters";
            names: Array<string>;
            adminIDs: Array<string>;
            resourceTypes: Array<ResourceType>;
            groupTypes: Array<GroupType>;
            entityIDs: Array<string>;
            connectionIDs: Array<string>;
            userIDs: Array<string>;
            matchMode: FiltersMatchMode;
            tags: Array<{
              __typename?: "TagKeyValue";
              key: string;
              value?: string | null;
            }>;
          } | null;
        };
      };
};

export type CreateAccessReviewTemplateMutationVariables = Exact<{
  input: CreateAccessReviewTemplateInput;
}>;

export type CreateAccessReviewTemplateMutation = {
  __typename?: "Mutation";
  saveAccessReviewTemplate: {
    __typename: "CreateAccessReviewTemplateResult";
    accessReviewTemplate: { __typename?: "AccessReviewTemplate"; id: string };
  };
};

export type UpdateAccessReviewTemplateMutationVariables = Exact<{
  input: UpdateAccessReviewTemplateInput;
}>;

export type UpdateAccessReviewTemplateMutation = {
  __typename?: "Mutation";
  updateAccessReviewTemplate:
    | { __typename: "AccessReviewTemplateNotFound"; message: string }
    | {
        __typename: "UpdateAccessReviewTemplateResult";
        accessReviewTemplate: {
          __typename?: "AccessReviewTemplate";
          id: string;
        };
      };
};

export type DeleteAccessReviewTemplatesMutationVariables = Exact<{
  input: DeleteAccessReviewTemplatesInput;
}>;

export type DeleteAccessReviewTemplatesMutation = {
  __typename?: "Mutation";
  deleteAccessReviewTemplates:
    | { __typename: "AccessReviewTemplateNotFound"; message: string }
    | {
        __typename: "DeleteAccessReviewTemplatesResult";
        accessReviewTemplates: Array<{
          __typename?: "AccessReviewTemplate";
          id: string;
        }>;
      };
};

export type AccessReviewProposedChangesQueryVariables = Exact<{
  input: AccessReviewProposedChangeInput;
}>;

export type AccessReviewProposedChangesQuery = {
  __typename?: "Query";
  accessReviewProposedChanges:
    | {
        __typename: "AccessReviewProposedChangesResult";
        proposedChanges?: Array<
          | {
              __typename?: "AccessReviewGroupProposedChange";
              user: {
                __typename?: "UserSmall";
                id: string;
                fullName: string;
                avatarUrl: string;
              };
              currentReviewer: {
                __typename?: "ReviewerUser";
                name: string;
                entityId: string;
                userId: string;
                avatarUrl: string;
              };
              proposedReviewer: {
                __typename?: "ReviewerUser";
                name: string;
                entityId: string;
                userId: string;
                avatarUrl: string;
              };
              group: { __typename?: "GroupSmall"; id: string; name: string };
            }
          | {
              __typename?: "AccessReviewResourceProposedChange";
              user: {
                __typename?: "UserSmall";
                id: string;
                fullName: string;
                avatarUrl: string;
              };
              currentReviewer: {
                __typename?: "ReviewerUser";
                name: string;
                entityId: string;
                userId: string;
                avatarUrl: string;
              };
              proposedReviewer: {
                __typename?: "ReviewerUser";
                name: string;
                entityId: string;
                userId: string;
                avatarUrl: string;
              };
              resource: {
                __typename?: "ResourceSmall";
                id: string;
                name: string;
              };
            }
        > | null;
      }
    | { __typename: "NoProposedChangesError" };
};

export type AccessReviewResourceProposedChangeFragment = {
  __typename?: "AccessReviewResourceProposedChange";
  user: {
    __typename?: "UserSmall";
    id: string;
    fullName: string;
    avatarUrl: string;
  };
  currentReviewer: {
    __typename?: "ReviewerUser";
    name: string;
    entityId: string;
    userId: string;
    avatarUrl: string;
  };
  proposedReviewer: {
    __typename?: "ReviewerUser";
    name: string;
    entityId: string;
    userId: string;
    avatarUrl: string;
  };
  resource: { __typename?: "ResourceSmall"; id: string; name: string };
};

export type AccessReviewGroupProposedChangeFragment = {
  __typename?: "AccessReviewGroupProposedChange";
  user: {
    __typename?: "UserSmall";
    id: string;
    fullName: string;
    avatarUrl: string;
  };
  currentReviewer: {
    __typename?: "ReviewerUser";
    name: string;
    entityId: string;
    userId: string;
    avatarUrl: string;
  };
  proposedReviewer: {
    __typename?: "ReviewerUser";
    name: string;
    entityId: string;
    userId: string;
    avatarUrl: string;
  };
  group: { __typename?: "GroupSmall"; id: string; name: string };
};

export type AwsAccountsColumnQueryVariables = Exact<{
  access: AccessOption;
  appId: Scalars["UUID"];
  cursor?: InputMaybe<Scalars["String"]>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
}>;

export type AwsAccountsColumnQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        items: {
          __typename?: "AppItemsOutput";
          cursor?: string | null;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              isRequestable: boolean;
              authorizedActions?: Array<string> | null;
              resourceType: ResourceType;
              isManaged: boolean;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
                configTemplate?: {
                  __typename?: "ConfigurationTemplate";
                  id: string;
                  name: string;
                } | null;
              } | null;
            } | null;
            group?: {
              __typename: "Group";
              id: string;
              name: string;
              isRequestable: boolean;
              authorizedActions?: Array<string> | null;
              groupType: GroupType;
              isManaged: boolean;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                sourceGroupId: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type AwsAllAccountsColumnQueryVariables = Exact<{
  id: Scalars["ConnectionId"];
}>;

export type AwsAllAccountsColumnQuery = {
  __typename?: "Query";
  connection:
    | { __typename?: "ConnectionNotFoundError"; message: string }
    | {
        __typename?: "ConnectionResult";
        connection: { __typename?: "Connection"; name: string };
      };
};

export type AccountDetailColumnQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type AccountDetailColumnQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          name: string;
          resourceType: ResourceType;
          authorizedActions?: Array<string> | null;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
          } | null;
        };
      };
};

export type AppDetailColumnHeaderQueryVariables = Exact<{
  connectionId: Scalars["ConnectionId"];
}>;

export type AppDetailColumnHeaderQuery = {
  __typename?: "Query";
  connection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | {
        __typename: "ConnectionResult";
        connection: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        };
      };
  organizationSettings:
    | { __typename: "OrganizationSettingsNotFoundError" }
    | {
        __typename: "OrganizationSettingsResult";
        settings: {
          __typename?: "OrganizationSettings";
          roleCreationReviewerOwner?: {
            __typename?: "Owner";
            id: string;
          } | null;
        };
      };
};

export type AppDetailColumnQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppDetailColumnQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        app:
          | {
              __typename?: "ConnectionApp";
              connectionId: string;
              connectionType: ConnectionType;
              connectionIconUrl?: string | null;
              connection?: {
                __typename?: "Connection";
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
            }
          | {
              __typename?: "OktaResourceApp";
              resourceId: string;
              iconUrl?: string | null;
              isVisible: boolean;
            };
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type AppDetailColumnForItemsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type AppDetailColumnForItemsQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        app:
          | { __typename?: "ConnectionApp"; connectionType: ConnectionType }
          | { __typename?: "OktaResourceApp" };
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type AppDetailColumnForResourceQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type AppDetailColumnForResourceQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          connection?: {
            __typename?: "Connection";
            id: string;
            connectionType: ConnectionType;
          } | null;
        };
      };
};

export type AppDetailColumnForGroupQueryVariables = Exact<{
  id: Scalars["GroupId"];
}>;

export type AppDetailColumnForGroupQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          connection?: {
            __typename?: "Connection";
            id: string;
            connectionType: ConnectionType;
          } | null;
        };
      };
};

export type AppResourceRowFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  isRequestable: boolean;
  hasVisibleChildren: boolean;
  authorizedActions?: Array<string> | null;
  resourceType: ResourceType;
  isManaged: boolean;
  ancestorPathToResource?: string | null;
  visibility: Visibility;
  description: string;
  createdAt: string;
  numResourceUsers: number;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  configTemplate?: { __typename?: "ConfigurationTemplate"; id: string } | null;
  parentResource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    configTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
  } | null;
  adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
  }>;
};

export type AppGroupRowFragment = {
  __typename: "Group";
  id: string;
  name: string;
  isRequestable: boolean;
  authorizedActions?: Array<string> | null;
  groupType: GroupType;
  isManaged: boolean;
  visibility: Visibility;
  description: string;
  createdAt: string;
  numGroupUsers: number;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  configTemplate?: { __typename?: "ConfigurationTemplate"; id: string } | null;
  adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
  }>;
};

export type AppResourcesQueryVariables = Exact<{
  id: Scalars["UUID"];
  itemType?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  access: AccessOption;
  parentResourceId?: InputMaybe<NullableResourceId>;
  ancestorResourceId?: InputMaybe<NullableResourceId>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
  includeGroups?: InputMaybe<Scalars["Boolean"]>;
  hasV3?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AppResourcesQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        items: {
          __typename?: "AppItemsOutput";
          cursor?: string | null;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              isRequestable: boolean;
              hasVisibleChildren: boolean;
              authorizedActions?: Array<string> | null;
              resourceType: ResourceType;
              isManaged: boolean;
              ancestorPathToResource?: string | null;
              visibility: Visibility;
              description: string;
              createdAt: string;
              numResourceUsers: number;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
                configTemplate?: {
                  __typename?: "ConfigurationTemplate";
                  id: string;
                  name: string;
                } | null;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
              }>;
            } | null;
            group?: {
              __typename: "Group";
              id: string;
              name: string;
              isRequestable: boolean;
              authorizedActions?: Array<string> | null;
              groupType: GroupType;
              isManaged: boolean;
              visibility: Visibility;
              description: string;
              createdAt: string;
              numGroupUsers: number;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
              }>;
            } | null;
          }> | null;
        };
        itemTypes: Array<{
          __typename?: "AppItemType";
          itemType: string;
          displayText: string;
        }>;
      }
    | { __typename?: "AppNotFoundError" };
};

export type AppsBulkEditQueryVariables = Exact<{
  resourceIds?: InputMaybe<
    Array<Scalars["ResourceId"]> | Scalars["ResourceId"]
  >;
  groupIds: Array<Scalars["GroupId"]> | Scalars["GroupId"];
}>;

export type AppsBulkEditQuery = {
  __typename?: "Query";
  resources: {
    __typename?: "ResourcesResult";
    resources: Array<{
      __typename?: "Resource";
      authorizedActions?: Array<string> | null;
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      configTemplate?: {
        __typename?: "ConfigurationTemplate";
        id: string;
      } | null;
      connection?: {
        __typename?: "Connection";
        connectionType: ConnectionType;
      } | null;
    }>;
  };
  groups: {
    __typename?: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      configTemplate?: {
        __typename?: "ConfigurationTemplate";
        id: string;
      } | null;
      connection?: {
        __typename?: "Connection";
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        sourceGroupId: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        } | null;
      } | null;
    }>;
  };
};

export type AppsListColumnQueryVariables = Exact<{
  access: AccessOption;
  searchQuery?: InputMaybe<Scalars["String"]>;
  appCategory?: InputMaybe<AppCategory>;
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AppsSortBy>;
  fetchCounts?: InputMaybe<Scalars["Boolean"]>;
  fetchAccessStats?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AppsListColumnQuery = {
  __typename?: "Query";
  apps: {
    __typename?: "AppsOutput";
    cursor?: string | null;
    totalNumApps: number;
    apps: Array<{
      __typename?: "App";
      id: string;
      name: string;
      visibility: Visibility;
      resourceCount: number;
      groupAccessCount?: number;
      userAccessCount?: number;
      app:
        | {
            __typename?: "ConnectionApp";
            connectionId: string;
            connectionType: ConnectionType;
            connectionIconUrl?: string | null;
            connection?: {
              __typename?: "Connection";
              accessStats?: {
                __typename?: "ConnectionAccessStats";
                teamAccessCount?: number | null;
                titleAccessCount?: number | null;
              } | null;
            } | null;
          }
        | {
            __typename?: "OktaResourceApp";
            resourceId: string;
            iconUrl?: string | null;
            isVisible: boolean;
            resource?: {
              __typename?: "Resource";
              accessStats?: {
                __typename?: "ResourceAccessStats";
                teamAccessCount?: number | null;
                titleAccessCount?: number | null;
              } | null;
            } | null;
          };
    }>;
  };
};

type AppData_ConnectionApp_Fragment = {
  __typename?: "ConnectionApp";
  connectionId: string;
  connectionType: ConnectionType;
  connectionIconUrl?: string | null;
  connection?: {
    __typename?: "Connection";
    accessStats?: {
      __typename?: "ConnectionAccessStats";
      teamAccessCount?: number | null;
      titleAccessCount?: number | null;
    } | null;
  } | null;
};

type AppData_OktaResourceApp_Fragment = {
  __typename?: "OktaResourceApp";
  resourceId: string;
  iconUrl?: string | null;
  isVisible: boolean;
  resource?: {
    __typename?: "Resource";
    accessStats?: {
      __typename?: "ResourceAccessStats";
      teamAccessCount?: number | null;
      titleAccessCount?: number | null;
    } | null;
  } | null;
};

export type AppDataFragment =
  | AppData_ConnectionApp_Fragment
  | AppData_OktaResourceApp_Fragment;

export type BulkActiveResourceRequestConfigsQueryVariables = Exact<{
  targetUserId?: InputMaybe<Scalars["UserId"]>;
  resourceInputs:
    | Array<RequestConfigurationResourceInput>
    | RequestConfigurationResourceInput;
}>;

export type BulkActiveResourceRequestConfigsQuery = {
  __typename?: "Query";
  activeResourceRequestConfigurations: Array<{
    __typename?: "ResourceRequestConfigurationOutput";
    resourceId: string;
    accessLevelRemoteId?: string | null;
    requestConfiguration: {
      __typename?: "RequestConfiguration";
      isRequestable: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireSupportTicket: boolean;
      reasonOptional: boolean;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    };
  }>;
};

export type BulkActiveGroupRequestConfigsQueryVariables = Exact<{
  targetUserId?: InputMaybe<Scalars["UserId"]>;
  groupInputs:
    | Array<RequestConfigurationGroupInput>
    | RequestConfigurationGroupInput;
}>;

export type BulkActiveGroupRequestConfigsQuery = {
  __typename?: "Query";
  activeGroupRequestConfigurations: Array<{
    __typename?: "GroupRequestConfigurationOutput";
    groupId: string;
    accessLevelRemoteId?: string | null;
    requestConfiguration: {
      __typename?: "RequestConfiguration";
      isRequestable: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireSupportTicket: boolean;
      reasonOptional: boolean;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    };
  }>;
};

export type BulkImportMutationVariables = Exact<{
  input: BulkImportRemoteItemsInput;
}>;

export type BulkImportMutation = {
  __typename?: "Mutation";
  bulkImportRemoteItems:
    | {
        __typename: "BulkImportRemoteItemsResult";
        importedResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        }> | null;
        importedGroups?: Array<{
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        }> | null;
        syncTask: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        };
      }
    | { __typename: "GroupMaxDurationTooLargeError" }
    | { __typename: "GroupNotFoundError" }
    | { __typename: "InvalidReviewerSettingsError" }
    | { __typename: "InvalidUpdateGroupVisibilityGroupError" }
    | { __typename: "InvalidUpdateResourceVisibilityGroupError" }
    | { __typename: "ResourceMaxDurationTooLargeError" }
    | { __typename: "TagNotFoundError" };
};

export type BulkRequestColumnResourcesQueryVariables = Exact<{
  resourceIds: Array<Scalars["ResourceId"]> | Scalars["ResourceId"];
}>;

export type BulkRequestColumnResourcesQuery = {
  __typename?: "Query";
  resources: {
    __typename: "ResourcesResult";
    resources: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      parentResourceId?: string | null;
      authorizedActions?: Array<string> | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      childResources?: Array<{
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        autoApproval: boolean;
        parentResourceId?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
      }> | null;
      requestTemplate?: {
        __typename?: "RequestTemplate";
        id: string;
        name: string;
        isDeleted?: boolean | null;
        customFields?: Array<{
          __typename?: "RequestTemplateCustomField";
          name: string;
          type: RequestTemplateCustomFieldType;
          required?: boolean | null;
          description?: string | null;
          metadata?: {
            __typename?: "RequestTemplateCustomFieldMetadata";
            multiChoiceData?: {
              __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
              options?: Array<{
                __typename?: "MultiChoiceOption";
                value: string;
                label: string;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type BulkRequestColumnGroupsQueryVariables = Exact<{
  groupIds: Array<Scalars["GroupId"]> | Scalars["GroupId"];
}>;

export type BulkRequestColumnGroupsQuery = {
  __typename?: "Query";
  groups: {
    __typename: "GroupsResult";
    groups: Array<{
      __typename: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    }>;
  };
};

export type BulkUpdateMutationVariables = Exact<{
  input: BulkUpdateItemsInput;
}>;

export type BulkUpdateMutation = {
  __typename?: "Mutation";
  bulkUpdateItems:
    | {
        __typename: "BulkUpdateItemsResult";
        updatedResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          parentResourceId?: string | null;
          authorizedActions?: Array<string> | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          childResources?: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            parentResourceId?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
          }> | null;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
        }> | null;
        updatedGroups?: Array<{
          __typename: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          groupType: GroupType;
          serviceType: ServiceType;
          iconUrl?: string | null;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          updatedAt: string;
          description: string;
          numGroupUsers: number;
          connectionId: string;
          isOnCallSynced: boolean;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          groupBindingId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserGroupAccess";
            groupId: string;
            hasBreakGlass: boolean;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
            pendingRequest?: {
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            } | null;
          };
          tags: Array<{
            __typename?: "GroupTag";
            groupId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          onCallSchedules?: Array<{
            __typename?: "GroupOnCallSchedule";
            onCallSchedule: {
              __typename?: "OnCallSchedule";
              remoteId: string;
              thirdPartyProvider: ThirdPartyProvider;
              name: string;
              existsInRemote: boolean;
            };
          }> | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            createdAt: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            } | null;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
            }>;
            createdByUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          groupLeaders: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          }>;
        }> | null;
        syncTask?: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        } | null;
      }
    | { __typename: "CannotUpdateConfigurationTemplateError" }
    | { __typename: "GroupMaxDurationTooLargeError" }
    | { __typename: "GroupNotFoundError" }
    | { __typename: "InvalidReviewerSettingsError" }
    | { __typename: "InvalidUpdateGroupVisibilityGroupError" }
    | { __typename: "InvalidUpdateResourceVisibilityGroupError" }
    | { __typename: "ResourceMaxDurationTooLargeError" }
    | { __typename: "ResourceNotFoundError" }
    | { __typename: "TagNotFoundError" }
    | { __typename: "TooManyGroupLeadersError" };
};

export type GroupPreviewWithGroupsFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  authorizedActions?: Array<string> | null;
  memberGroups: Array<{
    __typename?: "GroupGroup";
    id: string;
    containingGroupId: string;
    memberGroupId: string;
    memberGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    containingGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    quickAccess?: {
      __typename?: "GroupGroupQuickAccess";
      numInheritedAccess: number;
      hasDirectAccess: boolean;
      expiration?: string | null;
      supportTicketId?: string | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupGroup";
    id: string;
    containingGroupId: string;
    memberGroupId: string;
    memberGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    containingGroup?: {
      __typename?: "Group";
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      numGroupUsers: number;
      groupType: GroupType;
      serviceType: ServiceType;
      visibility: Visibility;
      isManaged: boolean;
      isDeleted: boolean;
      iconUrl?: string | null;
      isOnCallSynced: boolean;
      connectionId: string;
      groupBindingId?: string | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        }>;
      } | null;
    } | null;
    accessLevel: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
    quickAccess?: {
      __typename?: "GroupGroupQuickAccess";
      numInheritedAccess: number;
      hasDirectAccess: boolean;
      expiration?: string | null;
      supportTicketId?: string | null;
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  }>;
  accessLevels?: Array<{
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  }> | null;
};

export type GroupAddGroupsQueryVariables = Exact<{
  groupId: Scalars["GroupId"];
}>;

export type GroupAddGroupsQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          authorizedActions?: Array<string> | null;
          memberGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            memberGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            containingGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupGroup";
            id: string;
            containingGroupId: string;
            memberGroupId: string;
            memberGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            containingGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            quickAccess?: {
              __typename?: "GroupGroupQuickAccess";
              numInheritedAccess: number;
              hasDirectAccess: boolean;
              expiration?: string | null;
              supportTicketId?: string | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          }>;
          accessLevels?: Array<{
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          }> | null;
        };
      };
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      numResources: number;
      numGroups: number;
    }>;
  };
};

export type GroupPreviewWithResourcesFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  authorizedActions?: Array<string> | null;
  groupResources: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
};

export type GroupAddResourcesQueryVariables = Exact<{
  groupId: Scalars["GroupId"];
}>;

export type GroupAddResourcesQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          authorizedActions?: Array<string> | null;
          groupResources: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
        };
      };
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      numResources: number;
      numGroups: number;
    }>;
  };
};

export type GroupPreviewWithUsersFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  remoteId?: string | null;
  groupType: GroupType;
  serviceType: ServiceType;
  authorizedActions?: Array<string> | null;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  accessLevels?: Array<{
    __typename: "GroupAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
  }> | null;
  groupUsers: Array<{
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    source: GroupUserSource;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  }>;
};

export type GroupAddUsersQueryVariables = Exact<{
  id: Scalars["GroupId"];
  searchQuery?: InputMaybe<Scalars["String"]>;
}>;

export type GroupAddUsersQuery = {
  __typename?: "Query";
  group:
    | { __typename: "GroupNotFoundError"; message: string }
    | {
        __typename: "GroupResult";
        group: {
          __typename?: "Group";
          id: string;
          name: string;
          remoteId?: string | null;
          groupType: GroupType;
          serviceType: ServiceType;
          authorizedActions?: Array<string> | null;
          connection?: {
            __typename?: "Connection";
            connectionType: ConnectionType;
          } | null;
          accessLevels?: Array<{
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          }> | null;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          }>;
        };
      };
  users: {
    __typename: "UsersResult";
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  };
};

export type ImportItemsListColumnQueryVariables = Exact<{
  id: Scalars["UUID"];
  access: AccessOption;
}>;

export type ImportItemsListColumnQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        itemTypes: Array<{
          __typename?: "AppItemType";
          itemType: string;
          displayText: string;
        }>;
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type RemoteAppItemFragment = {
  __typename?: "RemoteAppItem";
  id: string;
  remoteId: string;
  remoteName: string;
  metadata?: string | null;
  resourceType?: ResourceType | null;
  groupType?: GroupType | null;
  connectionId: string;
  connectionType: ConnectionType;
  iconUrl?: string | null;
};

export type RemoteItemsListQueryVariables = Exact<{
  id: Scalars["UUID"];
  resourceType?: InputMaybe<ResourceType>;
  groupType?: InputMaybe<GroupType>;
  searchQuery?: InputMaybe<Scalars["String"]>;
}>;

export type RemoteItemsListQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        remoteItems:
          | {
              __typename?: "RemoteAppItemsResult";
              items?: Array<{
                __typename?: "RemoteAppItem";
                id: string;
                remoteId: string;
                remoteName: string;
                metadata?: string | null;
                resourceType?: ResourceType | null;
                groupType?: GroupType | null;
                connectionId: string;
                connectionType: ConnectionType;
                iconUrl?: string | null;
              }> | null;
            }
          | { __typename?: "UserFacingError"; message: string };
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type ResourceForSelectFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  isRequestable: boolean;
  authorizedActions?: Array<string> | null;
  resourceType: ResourceType;
  isManaged: boolean;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  configTemplate?: { __typename?: "ConfigurationTemplate"; id: string } | null;
  parentResource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    configTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
  } | null;
};

export type GroupForSelectFragment = {
  __typename: "Group";
  id: string;
  name: string;
  isRequestable: boolean;
  authorizedActions?: Array<string> | null;
  groupType: GroupType;
  isManaged: boolean;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  configTemplate?: { __typename?: "ConfigurationTemplate"; id: string } | null;
  groupBinding?: { __typename?: "GroupBinding"; sourceGroupId: string } | null;
};

export type ItemsListSectionQueryVariables = Exact<{
  id: Scalars["UUID"];
  itemType?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  access: AccessOption;
  parentResourceId?: InputMaybe<NullableResourceId>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
}>;

export type ItemsListSectionQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        itemTypes: Array<{
          __typename?: "AppItemType";
          itemType: string;
          displayText: string;
        }>;
        items: {
          __typename?: "AppItemsOutput";
          cursor?: string | null;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              isRequestable: boolean;
              authorizedActions?: Array<string> | null;
              resourceType: ResourceType;
              isManaged: boolean;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                resourceUsers: Array<{
                  __typename?: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename?: "ResourceAccessLevel";
                    accessLevelRemoteId: string;
                  };
                }>;
              };
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
                configTemplate?: {
                  __typename?: "ConfigurationTemplate";
                  id: string;
                  name: string;
                } | null;
              } | null;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
            } | null;
            group?: {
              __typename: "Group";
              id: string;
              name: string;
              isRequestable: boolean;
              authorizedActions?: Array<string> | null;
              groupType: GroupType;
              isManaged: boolean;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  userId: string;
                  groupId: string;
                } | null;
              };
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              configTemplate?: {
                __typename?: "ConfigurationTemplate";
                id: string;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                sourceGroupId: string;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename?: "AppNotFoundError" };
};

export type ResourceForImportFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  authorizedActions?: Array<string> | null;
  resourceType: ResourceType;
  remoteId: string;
  isManaged: boolean;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
};

export type GroupForImportFragment = {
  __typename: "Group";
  id: string;
  name: string;
  authorizedActions?: Array<string> | null;
  groupType: GroupType;
  remoteId?: string | null;
  isManaged: boolean;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
};

export type AppItemFragment = {
  __typename?: "AppItem";
  key: string;
  resource?: {
    __typename: "Resource";
    id: string;
    name: string;
    authorizedActions?: Array<string> | null;
    resourceType: ResourceType;
    remoteId: string;
    isManaged: boolean;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      resourceUsers: Array<{
        __typename?: "ResourceUser";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename?: "ResourceAccessLevel";
          accessLevelRemoteId: string;
        };
      }>;
    };
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename?: "Connection";
      connectionType: ConnectionType;
    } | null;
  } | null;
  group?: {
    __typename: "Group";
    id: string;
    name: string;
    authorizedActions?: Array<string> | null;
    groupType: GroupType;
    remoteId?: string | null;
    isManaged: boolean;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      groupUser?: {
        __typename?: "GroupUser";
        userId: string;
        groupId: string;
      } | null;
    };
    connection?: {
      __typename?: "Connection";
      connectionType: ConnectionType;
    } | null;
  } | null;
};

export type ItemsListSectionImportQueryVariables = Exact<{
  id: Scalars["UUID"];
  itemType?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  access: AccessOption;
  parentResourceId?: InputMaybe<NullableResourceId>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
}>;

export type ItemsListSectionImportQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        name: string;
        itemTypes: Array<{
          __typename?: "AppItemType";
          itemType: string;
          displayText: string;
        }>;
        items: {
          __typename?: "AppItemsOutput";
          totalNumItems: number;
          cursor?: string | null;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              authorizedActions?: Array<string> | null;
              resourceType: ResourceType;
              remoteId: string;
              isManaged: boolean;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                resourceUsers: Array<{
                  __typename?: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename?: "ResourceAccessLevel";
                    accessLevelRemoteId: string;
                  };
                }>;
              };
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
            } | null;
            group?: {
              __typename: "Group";
              id: string;
              name: string;
              authorizedActions?: Array<string> | null;
              groupType: GroupType;
              remoteId?: string | null;
              isManaged: boolean;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  userId: string;
                  groupId: string;
                } | null;
              };
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
            } | null;
          }> | null;
        };
      }
    | { __typename?: "AppNotFoundError" };
};

export type ResourcePreviewWithGroupsFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  resourceType: ResourceType;
  serviceType: ServiceType;
  authorizedActions?: Array<string> | null;
  accessLevels?: Array<{
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  }> | null;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
};

export type ResourceAddGroupsQueryVariables = Exact<{
  resourceId: Scalars["ResourceId"];
}>;

export type ResourceAddGroupsQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          serviceType: ServiceType;
          authorizedActions?: Array<string> | null;
          accessLevels?: Array<{
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          }> | null;
          containingGroups: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
        };
      };
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      numResources: number;
      numGroups: number;
    }>;
  };
};

export type ResourcePreviewWithRoleAssignmentsFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  remoteId: string;
  resourceType: ResourceType;
  serviceType: ServiceType;
  authorizedActions?: Array<string> | null;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  accessLevels?: Array<{
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  }> | null;
  principalAssignmentsForEntity: Array<{
    __typename?: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
};

export type ResourceAddPrincipalsQueryVariables = Exact<{
  id: Scalars["ResourceId"];
  searchQuery?: InputMaybe<Scalars["String"]>;
}>;

export type ResourceAddPrincipalsQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          remoteId: string;
          resourceType: ResourceType;
          serviceType: ServiceType;
          authorizedActions?: Array<string> | null;
          connection?: {
            __typename?: "Connection";
            connectionType: ConnectionType;
          } | null;
          accessLevels?: Array<{
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          }> | null;
          principalAssignmentsForEntity: Array<{
            __typename?: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
        };
      };
  principals: {
    __typename: "PrincipalsResult";
    principals: Array<
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
    >;
  };
};

export type ResourcePreviewWithUsersFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  remoteId: string;
  resourceType: ResourceType;
  serviceType: ServiceType;
  authorizedActions?: Array<string> | null;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  accessLevels?: Array<{
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  }> | null;
  resourceUsers: Array<{
    __typename?: "ResourceUser";
    resourceId: string;
    userId: string;
    source: ResourceUserSource;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
  }>;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
};

export type ResourceAddUsersQueryVariables = Exact<{
  id: Scalars["ResourceId"];
  searchQuery?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<UsersSortBy>;
  maxNumEntries?: InputMaybe<Scalars["Int"]>;
}>;

export type ResourceAddUsersQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          remoteId: string;
          resourceType: ResourceType;
          serviceType: ServiceType;
          authorizedActions?: Array<string> | null;
          connection?: {
            __typename?: "Connection";
            connectionType: ConnectionType;
          } | null;
          accessLevels?: Array<{
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          }> | null;
          resourceUsers: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
          }>;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    totalNumUsers: number;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  };
};

export type RequestConfigurationFragment = {
  __typename?: "RequestConfiguration";
  id: string;
  priority: number;
  groupId?: string | null;
  groupIds?: Array<string> | null;
  roleIds?: Array<string> | null;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  reviewerStages: Array<{
    __typename?: "ReviewerStage";
    id: string;
    stage: number;
    requireManagerApproval: boolean;
    requireAdminApproval: boolean;
    operator: ReviewStageOperator;
    owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
  }>;
};

export type ResourceTicketPropagationFragment = {
  __typename?: "TicketPropagation";
  onGrant: boolean;
  onRevocation: boolean;
  ticketProvider?: {
    __typename?: "TicketProvider";
    ticketProvider: ThirdPartyProvider;
    ticketProjectId: string;
    ticketProjectName: string;
  } | null;
};

export type ResourceDetailFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  useParentConfig: boolean;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  authorizedActions?: Array<string> | null;
  parentResourceId?: string | null;
  numDescendantResources: number;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  customRequestNotification?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  principalAssignmentsForEntity: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  entityAssignmentsForPrincipal: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    group?: {
      __typename: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  auditMessageChannels?: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }> | null;
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  parentResource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    visibility: Visibility;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    configTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
  } | null;
  childResources?: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    parentResourceId?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
  }> | null;
  requestConfigs: Array<{
    __typename?: "RequestConfiguration";
    id: string;
    priority: number;
    groupId?: string | null;
    groupIds?: Array<string> | null;
    roleIds?: Array<string> | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    reviewerStages: Array<{
      __typename?: "ReviewerStage";
      id: string;
      stage: number;
      requireManagerApproval: boolean;
      requireAdminApproval: boolean;
      operator: ReviewStageOperator;
      owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
    }>;
  }>;
  configTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  reviewerStages: Array<{
    __typename?: "ReviewerStage";
    id: string;
    stage: number;
    requireManagerApproval: boolean;
    requireAdminApproval: boolean;
    operator: ReviewStageOperator;
    owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
  }>;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  childrenDefaultConfigTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  ticketPropagation?: {
    __typename?: "TicketPropagation";
    onGrant: boolean;
    onRevocation: boolean;
    ticketProvider?: {
      __typename?: "TicketProvider";
      ticketProvider: ThirdPartyProvider;
      ticketProjectId: string;
      ticketProjectName: string;
    } | null;
  } | null;
  riskSensitivity?: {
    __typename?: "RiskSensitivityDetails";
    value: RiskLevel;
    isDefault: boolean;
  } | null;
};

export type ResourceDetailColumnQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type ResourceDetailColumnQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          useParentConfig: boolean;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          authorizedActions?: Array<string> | null;
          parentResourceId?: string | null;
          numDescendantResources: number;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          principalAssignmentsForEntity: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          entityAssignmentsForPrincipal: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            group?: {
              __typename: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            visibility: Visibility;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            configTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
          } | null;
          childResources?: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            parentResourceId?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
          }> | null;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          childrenDefaultConfigTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
          riskSensitivity?: {
            __typename?: "RiskSensitivityDetails";
            value: RiskLevel;
            isDefault: boolean;
          } | null;
        };
      };
};

export type ResourceDetailViewFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  useParentConfig: boolean;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  authorizedActions?: Array<string> | null;
  parentResourceId?: string | null;
  numDescendantResources: number;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      resourceProposalId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        groupId: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        targetUserAccess?: {
          __typename?: "UserGroupAccess";
          groupId: string;
          userId: string;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
  paginatedResourceUsers: {
    __typename?: "PaginatedResourceUsersOutput";
    totalNumUsers: number;
  };
  principalAssignmentsForEntity: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  entityAssignmentsForPrincipal: Array<{
    __typename: "RoleAssignment";
    entityID: string;
    entityType: EntityType;
    principalID: string;
    principalType: EntityType;
    provisionSource: ProvisionSource;
    entity?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | null;
    principal?:
      | {
          __typename?: "Group";
          name: string;
          isManaged: boolean;
          groupType: GroupType;
          iconUrl?: string | null;
          groupId: string;
          groupRemoteId?: string | null;
        }
      | {
          __typename?: "Resource";
          name: string;
          isManaged: boolean;
          resourceType: ResourceType;
          iconUrl?: string | null;
          ancestorPathToResource?: string | null;
          resourceId: string;
          resourceRemoteId: string;
        }
      | {
          __typename?: "User";
          firstName: string;
          lastName: string;
          fullName: string;
          email: string;
          avatarUrl: string;
          isSystemUser: boolean;
          userId: string;
          name: string;
        }
      | null;
    accessLevel?: {
      __typename: "AccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    } | null;
    access?: {
      __typename?: "Access";
      entityId: string;
      entityType: EntityType;
      principalId: string;
      principalType: EntityType;
      accessLevel?: {
        __typename: "AccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      } | null;
      latestExpiringAccessPoint: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "AccessPoint";
        entityId: string;
        entityType: EntityType;
        entityName: string;
        entityItemType: string;
        principalId: string;
        principalType: EntityType;
        principalName: string;
        eventId?: string | null;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel?: {
          __typename: "AccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "PropagationStatus";
      roleAssignmentId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  containingGroups: Array<{
    __typename?: "GroupResource";
    groupId: string;
    resourceId: string;
    group?: {
      __typename: "Group";
      authorizedActions?: Array<string> | null;
      id: string;
      remoteId?: string | null;
      name: string;
      remoteName: string;
      groupType: GroupType;
      serviceType: ServiceType;
      iconUrl?: string | null;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      updatedAt: string;
      description: string;
      numGroupUsers: number;
      connectionId: string;
      isOnCallSynced: boolean;
      maxDurationInMinutes?: number | null;
      recommendedDurationInMinutes?: number | null;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      isManaged: boolean;
      autoApproval: boolean;
      reasonOptional: boolean;
      requestTemplateId?: string | null;
      groupBindingId?: string | null;
      customRequestNotification?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserGroupAccess";
        groupId: string;
        hasBreakGlass: boolean;
        groupUser?: {
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          source: GroupUserSource;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        } | null;
        pendingRequest?: {
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        } | null;
      };
      tags: Array<{
        __typename?: "GroupTag";
        groupId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        group?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      onCallSchedules?: Array<{
        __typename?: "GroupOnCallSchedule";
        onCallSchedule: {
          __typename?: "OnCallSchedule";
          remoteId: string;
          thirdPartyProvider: ThirdPartyProvider;
          name: string;
          existsInRemote: boolean;
        };
      }> | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      groupBinding?: {
        __typename?: "GroupBinding";
        id: string;
        sourceGroupId: string;
        createdAt: string;
        sourceGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        groups: Array<{
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        }>;
        createdByUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      groupLeaders: Array<{
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      }>;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  auditMessageChannels?: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }> | null;
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  parentResource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    visibility: Visibility;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    configTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
  } | null;
  childResources?: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    parentResourceId?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
  }> | null;
  requestConfigs: Array<{
    __typename?: "RequestConfiguration";
    id: string;
    priority: number;
    groupId?: string | null;
    groupIds?: Array<string> | null;
    roleIds?: Array<string> | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    reviewerStages: Array<{
      __typename?: "ReviewerStage";
      id: string;
      stage: number;
      requireManagerApproval: boolean;
      requireAdminApproval: boolean;
      operator: ReviewStageOperator;
      owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
    }>;
  }>;
  configTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  reviewerStages: Array<{
    __typename?: "ReviewerStage";
    id: string;
    stage: number;
    requireManagerApproval: boolean;
    requireAdminApproval: boolean;
    operator: ReviewStageOperator;
    owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
  }>;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  childrenDefaultConfigTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  ticketPropagation?: {
    __typename?: "TicketPropagation";
    onGrant: boolean;
    onRevocation: boolean;
    ticketProvider?: {
      __typename?: "TicketProvider";
      ticketProvider: ThirdPartyProvider;
      ticketProjectId: string;
      ticketProjectName: string;
    } | null;
  } | null;
  riskSensitivity?: {
    __typename?: "RiskSensitivityDetails";
    value: RiskLevel;
    isDefault: boolean;
  } | null;
};

export type DetailResourceUserFragment = {
  __typename: "ResourceUser";
  resourceId: string;
  userId: string;
  mostRecentSessionEnded?: string | null;
  source: ResourceUserSource;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  accessLevel: {
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  };
  access?: {
    __typename?: "ResourceUserAccess";
    resourceId: string;
    userId: string;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    latestExpiringAccessPoint: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    };
    directAccessPoint?: {
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
    inheritedAccessPoints: Array<{
      __typename?: "ResourceUserAccessPoint";
      resourceId: string;
      userId: string;
      resourceName: string;
      resourceType: ResourceType;
      eventId: string;
      createdAt: string;
      expiration?: string | null;
      supportTicketId?: string | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      supportTicket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    }>;
    groupUserAccesses: Array<{
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    }>;
  } | null;
  propagationStatus?: {
    __typename?: "ResourcePropagationStatus";
    resourceId: string;
    userId: string;
    accessLevelRemoteId: string;
    lastSynced: string;
    statusCode: PropagationStatusCode;
    errorMessage?: string | null;
    taskType: PropagationTaskType;
    metadata?: {
      __typename: "PropagationTicketMetadata";
      ticket?: {
        __typename?: "SupportTicket";
        id?: string | null;
        remoteId: string;
        url: string;
        identifier: string;
        thirdPartyProvider: ThirdPartyProvider;
        projectName: string;
      } | null;
    } | null;
  } | null;
};

export type PaginatedResourceUsersFragment = {
  __typename?: "PaginatedResourceUsersOutput";
  cursor?: string | null;
  totalNumResourceUsers: number;
  totalNumUsers: number;
  resourceUsers: Array<{
    __typename: "ResourceUser";
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
  uniqueAccessLevels: Array<{
    __typename: "ResourceAccessLevel";
    accessLevelName: string;
    accessLevelRemoteId: string;
    accessLevelMetadata?: {
      __typename: "ImpersonationAccessLevelMetadata";
      avatarUrl: string;
    } | null;
  }>;
};

export type ResourceDetailViewQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type ResourceDetailViewQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          useParentConfig: boolean;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          authorizedActions?: Array<string> | null;
          parentResourceId?: string | null;
          numDescendantResources: number;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          paginatedResourceUsers: {
            __typename?: "PaginatedResourceUsersOutput";
            totalNumUsers: number;
          };
          principalAssignmentsForEntity: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          entityAssignmentsForPrincipal: Array<{
            __typename: "RoleAssignment";
            entityID: string;
            entityType: EntityType;
            principalID: string;
            principalType: EntityType;
            provisionSource: ProvisionSource;
            entity?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | null;
            principal?:
              | {
                  __typename?: "Group";
                  name: string;
                  isManaged: boolean;
                  groupType: GroupType;
                  iconUrl?: string | null;
                  groupId: string;
                  groupRemoteId?: string | null;
                }
              | {
                  __typename?: "Resource";
                  name: string;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  iconUrl?: string | null;
                  ancestorPathToResource?: string | null;
                  resourceId: string;
                  resourceRemoteId: string;
                }
              | {
                  __typename?: "User";
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  email: string;
                  avatarUrl: string;
                  isSystemUser: boolean;
                  userId: string;
                  name: string;
                }
              | null;
            accessLevel?: {
              __typename: "AccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            } | null;
            access?: {
              __typename?: "Access";
              entityId: string;
              entityType: EntityType;
              principalId: string;
              principalType: EntityType;
              accessLevel?: {
                __typename: "AccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              } | null;
              latestExpiringAccessPoint: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "AccessPoint";
                entityId: string;
                entityType: EntityType;
                entityName: string;
                entityItemType: string;
                principalId: string;
                principalType: EntityType;
                principalName: string;
                eventId?: string | null;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel?: {
                  __typename: "AccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "PropagationStatus";
              roleAssignmentId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          containingGroups: Array<{
            __typename?: "GroupResource";
            groupId: string;
            resourceId: string;
            group?: {
              __typename: "Group";
              authorizedActions?: Array<string> | null;
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access: {
              __typename?: "GroupResourceAccess";
              groupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupResourceAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                resourceId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                resolvedAccessPoint?: {
                  __typename?: "GroupResourceAccessPoint";
                  groupId: string;
                  resourceId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  groupName: string;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPath?: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }> | null;
              }>;
              latestExpiringAccessPoint: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
            };
            propagationStatus?: {
              __typename?: "GroupResourcePropagationStatus";
              groupId: string;
              resourceId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            visibility: Visibility;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            configTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
          } | null;
          childResources?: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            createdAt: string;
            description: string;
            connectionId: string;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            maxDurationInMinutes?: number | null;
            requireMfaToConnect: boolean;
            requireMfaToApprove: boolean;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            parentResourceId?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
              ownerUsers: Array<{
                __typename?: "OwnerUser";
                user?: { __typename?: "User"; id: string } | null;
              }>;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            currentUserAccess: {
              __typename?: "CurrentUserResourceAccess";
              resourceId: string;
              numAvailableAccessLevels: number;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              activeSessions: Array<{
                __typename?: "Session";
                id: string;
                durationInMinutes?: number | null;
                endTime: string;
                accessLevel?: string | null;
                accessLevelRemoteId?: string | null;
                metadata:
                  | {
                      __typename: "AwsIamFederatedEksSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      clusterName: string;
                      clusterRegion: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRdsSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      federatedArn: string;
                      dbInstanceId: string;
                      dbPassword: string;
                      dbEngine: string;
                      dbUser: string;
                      dbHostname: string;
                      dbPort: number;
                      dbName: string;
                    }
                  | {
                      __typename: "AwsIamFederatedRoleSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                    }
                  | {
                      __typename: "AwsIamFederatedSSMSession";
                      id: string;
                      awsAccessKeyId: string;
                      awsSecretAccessKey: string;
                      awsSessionToken: string;
                      awsLoginUrl: string;
                      federatedArn: string;
                      ec2InstanceId: string;
                      ec2Region: string;
                    }
                  | { __typename: "OidcSession" }
                  | { __typename: "OpalImpersonationSession" }
                  | {
                      __typename: "VaultMongoAtlasSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      initDbName: string;
                    }
                  | {
                      __typename: "VaultMongoSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      authenticationDbName: string;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultMySQLMariaDBSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    }
                  | {
                      __typename: "VaultPostgresSession";
                      id: string;
                      dbUser: string;
                      dbPassword: string;
                      dbHostname: string;
                      dbPort: number;
                      tlsConfig: {
                        __typename?: "TlsConfig";
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      };
                    };
              }>;
              pendingRequests: Array<{
                __typename?: "Request";
                id: string;
                requesterId: string;
                targetUserId?: string | null;
                targetGroupId?: string | null;
                resourceProposalId?: string | null;
                createdAt: string;
                updatedAt: string;
                status: RequestStatus;
                requester?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
                targetGroup?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                requestedResources: Array<{
                  __typename?: "RequestedResource";
                  resourceId: string;
                  resource?: {
                    __typename: "Resource";
                    id: string;
                    name: string;
                    remoteName: string;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isDeleted: boolean;
                    isManaged: boolean;
                    resourceType: ResourceType;
                    remoteId: string;
                    iconUrl?: string | null;
                    parentResourceId?: string | null;
                    connectionId: string;
                    createdAt: string;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    parentResource?: {
                      __typename?: "Resource";
                      id: string;
                      name: string;
                    } | null;
                    connection?: {
                      __typename: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                      isDeleted: boolean;
                      metadata?:
                        | {
                            __typename: "ADConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                          }
                        | {
                            __typename: "AwsConnectionMetadata";
                            success?: boolean | null;
                          }
                        | {
                            __typename: "AWSSSOConnectionMetadata";
                            managementAccountId: string;
                            ssoInstanceArn: string;
                            identityStoreId: string;
                            accessPortalUrl: string;
                            awsRegion: string;
                            awsOrganizationEnabled: boolean;
                            awsSsoEnabled: boolean;
                            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                            awsOrganizationImportSetting: ImportSetting;
                            ec2RoleChainingEnabled: boolean;
                            podRoleChainingEnabled: boolean;
                            cloudtrailEventsSqsUrl: string;
                          }
                        | {
                            __typename: "AzureADConnectionMetadata";
                            tenantId: string;
                            clientId: string;
                            azureInfraEnabled: boolean;
                            eventHubNamespace: string;
                            eventHub: string;
                          }
                        | {
                            __typename: "CustomConnectorConnectionMetadata";
                            identifier: string;
                            baseUrl: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                            supportsGroups: boolean;
                            supportsNestedResources: boolean;
                          }
                        | {
                            __typename: "DuoConnectionMetadata";
                            apiHostname: string;
                          }
                        | {
                            __typename: "GCPConnectionMetadata";
                            pubSubProjectId: string;
                            subscriptionId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GitHubConnectionMetadata";
                            orgName: string;
                          }
                        | {
                            __typename: "GitLabConnectionMetadata";
                            hostName?: string | null;
                            appId: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "GoogleGroupsConnectionMetadata";
                            opalGroupEmail: string;
                            adminUserEmail: string;
                            domain: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "GoogleWorkspaceConnectionMetadata";
                            adminUserEmail: string;
                            customerId: string;
                            serviceAccount: {
                              __typename?: "GCPServiceAccount";
                              type: string;
                              projectId: string;
                              clientEmail: string;
                              clientId: string;
                              authUri: string;
                              tokenUri: string;
                              authProviderX509CertUrl: string;
                              clientX509CertUrl: string;
                            };
                          }
                        | {
                            __typename: "LDAPConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            groupsDistinguishedName: string;
                            usersDistinguishedName: string;
                            groupsUidKey: string;
                          }
                        | {
                            __typename: "MongoAtlasConnectionMetadata";
                            serverHostname: string;
                          }
                        | {
                            __typename: "MongoConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "MySQLMariaDBConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "OktaDirectoryConnectionMetadata";
                            orgUrl: string;
                          }
                        | { __typename: "PagerDutyConnectionMetadata" }
                        | {
                            __typename: "PostgresConnectionMetadata";
                            serverHostname: string;
                            serverPort: number;
                            tlsMode: boolean;
                            tlsCertContent?: string | null;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "PropagationTicketConnectionMetadata";
                            ticketProvider?: {
                              __typename?: "TicketProvider";
                              ticketProvider: ThirdPartyProvider;
                              ticketProjectId: string;
                              ticketProjectName: string;
                            } | null;
                          }
                        | {
                            __typename: "SalesforceConnectionMetadata";
                            email: string;
                            hostname: string;
                          }
                        | {
                            __typename: "SnowflakeConnectionMetadata";
                            organization: string;
                            account: string;
                            locator: string;
                            accountIdentifier: string;
                            accountUrl: string;
                            serviceAccountName: string;
                            serviceAccountRole: string;
                          }
                        | {
                            __typename: "TailscaleConnectionMetadata";
                            tailnet: string;
                          }
                        | {
                            __typename: "TeleportConnectionMetadata";
                            host: string;
                            tlsMode: boolean;
                            tlsCaCertContent?: string | null;
                          }
                        | {
                            __typename: "WorkdayConnectionMetadata";
                            tenantName: string;
                            tenantUrl: string;
                          }
                        | null;
                    } | null;
                    metadata?:
                      | { __typename: "AwsRoleMetadata"; arn: string }
                      | {
                          __typename: "OktaDirectoryAppMetadata";
                          appId: string;
                          appName: string;
                          logoUrl?: string | null;
                        }
                      | {
                          __typename: "OktaRoleMetadata";
                          roleId: string;
                          roleType: string;
                        }
                      | {
                          __typename: "PropagationTicketOwnerMetadata";
                          ownerId?: string | null;
                          owner?: {
                            __typename?: "Owner";
                            id: string;
                            name: string;
                          } | null;
                        }
                      | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  targetUserAccess?: {
                    __typename?: "UserResourceAccess";
                    resourceId: string;
                    userId: string;
                    resourceUsers: Array<{
                      __typename: "ResourceUser";
                      resourceId: string;
                      userId: string;
                      mostRecentSessionEnded?: string | null;
                      source: ResourceUserSource;
                      user?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        createdAt: string;
                        updatedAt: string;
                        organizationId: string;
                        position: string;
                        teamAttr?: string | null;
                        managerId?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                        manager?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          position: string;
                          teamAttr?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                        } | null;
                      } | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      access?: {
                        __typename?: "ResourceUserAccess";
                        resourceId: string;
                        userId: string;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        latestExpiringAccessPoint: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        inheritedAccessPoints: Array<{
                          __typename?: "ResourceUserAccessPoint";
                          resourceId: string;
                          userId: string;
                          resourceName: string;
                          resourceType: ResourceType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                        groupUserAccesses: Array<{
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "ResourcePropagationStatus";
                        resourceId: string;
                        userId: string;
                        accessLevelRemoteId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        metadata?: {
                          __typename: "PropagationTicketMetadata";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                      } | null;
                    }>;
                  } | null;
                }>;
                requestedGroups: Array<{
                  __typename?: "RequestedGroup";
                  groupId: string;
                  group?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  targetUserAccess?: {
                    __typename?: "UserGroupAccess";
                    groupId: string;
                    userId: string;
                    groupUser?: {
                      __typename?: "GroupUser";
                      groupId: string;
                      userId: string;
                      source: GroupUserSource;
                      access?: {
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      } | null;
                      propagationStatus?: {
                        __typename?: "GroupPropagationStatus";
                        groupId: string;
                        userId: string;
                        lastSynced: string;
                        statusCode: PropagationStatusCode;
                        errorMessage?: string | null;
                        taskType: PropagationTaskType;
                        ticketPropagation?: {
                          __typename: "GroupUserTicketPropagation";
                          resourceTickets: Array<{
                            __typename?: "ResourceTicketPropagation";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                        } | null;
                      } | null;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                resourceProposal?: {
                  __typename?: "ResourceProposal";
                  id: string;
                  connectionId: string;
                  serviceType: ServiceType;
                  resourceType: ResourceType;
                  metadata?: {
                    __typename: "AWSRoleProposalMetadata";
                    policyName: string;
                    policyDocument: string;
                    policyDescription: string;
                    assumeRolePolicyDocument: string;
                    roleName: string;
                    roleDescription: string;
                    tags: Array<{
                      __typename?: "AWSTag";
                      key: string;
                      value?: string | null;
                    }>;
                  } | null;
                } | null;
              }>;
            };
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
            commonMetadata: {
              __typename?: "CommonMetadata";
              matchRemoteName: boolean;
              matchRemoteDescription: boolean;
            };
            tags: Array<{
              __typename?: "ResourceTag";
              id: string;
              resourceId: string;
              tagId: string;
              source: ServiceType;
              updatedAt: string;
              createdAt: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              tag?: {
                __typename: "Tag";
                id: string;
                createdAt: string;
                creatorUserId: string;
                key: string;
                value?: string | null;
                isStandardAttribute: boolean;
              } | null;
            }>;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
          }> | null;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          childrenDefaultConfigTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
          riskSensitivity?: {
            __typename?: "RiskSensitivityDetails";
            value: RiskLevel;
            isDefault: boolean;
          } | null;
        };
      };
};

export type ResourceDetailPaginatedResourceUsersQueryVariables = Exact<{
  id: Scalars["ResourceId"];
  resourceUsers: PaginatedResourceUsersInput;
}>;

export type ResourceDetailPaginatedResourceUsersQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          paginatedResourceUsers: {
            __typename?: "PaginatedResourceUsersOutput";
            cursor?: string | null;
            totalNumResourceUsers: number;
            totalNumUsers: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            uniqueAccessLevels: Array<{
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            }>;
          };
        };
      };
};

export type ResourceEditViewQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type ResourceEditViewQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          useParentConfig: boolean;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          authorizedActions?: Array<string> | null;
          parentResourceId?: string | null;
          numDescendantResources: number;
          maxDurationInMinutes?: number | null;
          recommendedDurationInMinutes?: number | null;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          isManaged: boolean;
          autoApproval: boolean;
          reasonOptional: boolean;
          requestTemplateId?: string | null;
          customRequestNotification?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            description: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            visibility: Visibility;
            importVisibility: Visibility;
            importSetting: ImportSetting;
            autoImportGroupResources: boolean;
            numUsers: number;
            webhookEnabled: boolean;
            iconUrl?: string | null;
            adminOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConnectionVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup?: { __typename?: "Group"; name: string } | null;
            }>;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
            childrenDefaultConfigTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
            importNotificationOwner?: {
              __typename?: "Owner";
              id: string;
              name: string;
            } | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          auditMessageChannels?: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }> | null;
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
            resourceType: ResourceType;
            visibility: Visibility;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            configTemplate?: {
              __typename?: "ConfigurationTemplate";
              id: string;
              name: string;
            } | null;
          } | null;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          configTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          reviewerStages: Array<{
            __typename?: "ReviewerStage";
            id: string;
            stage: number;
            requireManagerApproval: boolean;
            requireAdminApproval: boolean;
            operator: ReviewStageOperator;
            owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
          }>;
          requestTemplate?: {
            __typename?: "RequestTemplate";
            id: string;
            name: string;
            isDeleted?: boolean | null;
            customFields?: Array<{
              __typename?: "RequestTemplateCustomField";
              name: string;
              type: RequestTemplateCustomFieldType;
              required?: boolean | null;
              description?: string | null;
              metadata?: {
                __typename?: "RequestTemplateCustomFieldMetadata";
                multiChoiceData?: {
                  __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                  options?: Array<{
                    __typename?: "MultiChoiceOption";
                    value: string;
                    label: string;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
          childrenDefaultConfigTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
          riskSensitivity?: {
            __typename?: "RiskSensitivityDetails";
            value: RiskLevel;
            isDefault: boolean;
          } | null;
        };
      };
};

export type ResourceEditViewDetailFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteName: string;
  serviceType: ServiceType;
  visibility: Visibility;
  isDeleted: boolean;
  createdAt: string;
  description: string;
  useParentConfig: boolean;
  connectionId: string;
  resourceType: ResourceType;
  remoteId: string;
  iconUrl?: string | null;
  requireMfaToConnect: boolean;
  requireMfaToApprove: boolean;
  authorizedActions?: Array<string> | null;
  parentResourceId?: string | null;
  numDescendantResources: number;
  maxDurationInMinutes?: number | null;
  recommendedDurationInMinutes?: number | null;
  requireMfaToRequest: boolean;
  requireSupportTicket: boolean;
  isRequestable: boolean;
  isManaged: boolean;
  autoApproval: boolean;
  reasonOptional: boolean;
  requestTemplateId?: string | null;
  customRequestNotification?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: { __typename?: "User"; id: string } | null;
    }>;
  } | null;
  connection?: {
    __typename: "Connection";
    id: string;
    name: string;
    description: string;
    connectionType: ConnectionType;
    isDeleted: boolean;
    visibility: Visibility;
    importVisibility: Visibility;
    importSetting: ImportSetting;
    autoImportGroupResources: boolean;
    numUsers: number;
    webhookEnabled: boolean;
    iconUrl?: string | null;
    adminOwner?: { __typename?: "Owner"; id: string; name: string } | null;
    visibilityGroups: Array<{
      __typename?: "ConnectionVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup?: { __typename?: "Group"; name: string } | null;
    }>;
    metadata?:
      | {
          __typename: "ADConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
        }
      | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
      | {
          __typename: "AWSSSOConnectionMetadata";
          managementAccountId: string;
          ssoInstanceArn: string;
          identityStoreId: string;
          accessPortalUrl: string;
          awsRegion: string;
          awsOrganizationEnabled: boolean;
          awsSsoEnabled: boolean;
          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
          awsOrganizationImportSetting: ImportSetting;
          ec2RoleChainingEnabled: boolean;
          podRoleChainingEnabled: boolean;
          cloudtrailEventsSqsUrl: string;
        }
      | {
          __typename: "AzureADConnectionMetadata";
          tenantId: string;
          clientId: string;
          azureInfraEnabled: boolean;
          eventHubNamespace: string;
          eventHub: string;
        }
      | {
          __typename: "CustomConnectorConnectionMetadata";
          identifier: string;
          baseUrl: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
          supportsGroups: boolean;
          supportsNestedResources: boolean;
        }
      | { __typename: "DuoConnectionMetadata"; apiHostname: string }
      | {
          __typename: "GCPConnectionMetadata";
          pubSubProjectId: string;
          subscriptionId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | { __typename: "GitHubConnectionMetadata"; orgName: string }
      | {
          __typename: "GitLabConnectionMetadata";
          hostName?: string | null;
          appId: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "GoogleGroupsConnectionMetadata";
          opalGroupEmail: string;
          adminUserEmail: string;
          domain: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "GoogleWorkspaceConnectionMetadata";
          adminUserEmail: string;
          customerId: string;
          serviceAccount: {
            __typename?: "GCPServiceAccount";
            type: string;
            projectId: string;
            clientEmail: string;
            clientId: string;
            authUri: string;
            tokenUri: string;
            authProviderX509CertUrl: string;
            clientX509CertUrl: string;
          };
        }
      | {
          __typename: "LDAPConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          groupsDistinguishedName: string;
          usersDistinguishedName: string;
          groupsUidKey: string;
        }
      | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
      | {
          __typename: "MongoConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "MySQLMariaDBConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
      | { __typename: "PagerDutyConnectionMetadata" }
      | {
          __typename: "PostgresConnectionMetadata";
          serverHostname: string;
          serverPort: number;
          tlsMode: boolean;
          tlsCertContent?: string | null;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "PropagationTicketConnectionMetadata";
          ticketProvider?: {
            __typename?: "TicketProvider";
            ticketProvider: ThirdPartyProvider;
            ticketProjectId: string;
            ticketProjectName: string;
          } | null;
        }
      | {
          __typename: "SalesforceConnectionMetadata";
          email: string;
          hostname: string;
        }
      | {
          __typename: "SnowflakeConnectionMetadata";
          organization: string;
          account: string;
          locator: string;
          accountIdentifier: string;
          accountUrl: string;
          serviceAccountName: string;
          serviceAccountRole: string;
        }
      | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
      | {
          __typename: "TeleportConnectionMetadata";
          host: string;
          tlsMode: boolean;
          tlsCaCertContent?: string | null;
        }
      | {
          __typename: "WorkdayConnectionMetadata";
          tenantName: string;
          tenantUrl: string;
        }
      | null;
    childrenDefaultConfigTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
    importNotificationOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
    } | null;
  } | null;
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
  commonMetadata: {
    __typename?: "CommonMetadata";
    matchRemoteName: boolean;
    matchRemoteDescription: boolean;
  };
  auditMessageChannels?: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }> | null;
  tags: Array<{
    __typename?: "ResourceTag";
    id: string;
    resourceId: string;
    tagId: string;
    source: ServiceType;
    updatedAt: string;
    createdAt: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    tag?: {
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    } | null;
  }>;
  visibilityGroups: Array<{
    __typename?: "ConfigurationVisibilityGroup";
    visibilityGroupId: string;
    visibilityGroup: { __typename?: "Group"; name: string };
  }>;
  parentResource?: {
    __typename?: "Resource";
    id: string;
    name: string;
    resourceType: ResourceType;
    visibility: Visibility;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    configTemplate?: {
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    } | null;
  } | null;
  requestConfigs: Array<{
    __typename?: "RequestConfiguration";
    id: string;
    priority: number;
    groupId?: string | null;
    groupIds?: Array<string> | null;
    roleIds?: Array<string> | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    reviewerStages: Array<{
      __typename?: "ReviewerStage";
      id: string;
      stage: number;
      requireManagerApproval: boolean;
      requireAdminApproval: boolean;
      operator: ReviewStageOperator;
      owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
    }>;
  }>;
  configTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  reviewerStages: Array<{
    __typename?: "ReviewerStage";
    id: string;
    stage: number;
    requireManagerApproval: boolean;
    requireAdminApproval: boolean;
    operator: ReviewStageOperator;
    owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
  }>;
  requestTemplate?: {
    __typename?: "RequestTemplate";
    id: string;
    name: string;
    isDeleted?: boolean | null;
    customFields?: Array<{
      __typename?: "RequestTemplateCustomField";
      name: string;
      type: RequestTemplateCustomFieldType;
      required?: boolean | null;
      description?: string | null;
      metadata?: {
        __typename?: "RequestTemplateCustomFieldMetadata";
        multiChoiceData?: {
          __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
          options?: Array<{
            __typename?: "MultiChoiceOption";
            value: string;
            label: string;
          }> | null;
        } | null;
      } | null;
    }> | null;
  } | null;
  childrenDefaultConfigTemplate?: {
    __typename?: "ConfigurationTemplate";
    id: string;
    name: string;
  } | null;
  ticketPropagation?: {
    __typename?: "TicketPropagation";
    onGrant: boolean;
    onRevocation: boolean;
    ticketProvider?: {
      __typename?: "TicketProvider";
      ticketProvider: ThirdPartyProvider;
      ticketProjectId: string;
      ticketProjectName: string;
    } | null;
  } | null;
  riskSensitivity?: {
    __typename?: "RiskSensitivityDetails";
    value: RiskLevel;
    isDefault: boolean;
  } | null;
};

export type ResourceAncestorsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ResourceId"]>;
}>;

export type ResourceAncestorsQuery = {
  __typename?: "Query";
  resourceAncestors: {
    __typename?: "ResourcesResult";
    resources: Array<{
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type UserAddResourcesQueryVariables = Exact<{
  userId: Scalars["UserId"];
}>;

export type UserAddResourcesQuery = {
  __typename?: "Query";
  user:
    | { __typename?: "UserNotFoundError" }
    | {
        __typename?: "UserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          userResources: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        };
      };
  connections: {
    __typename: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      numResources: number;
      numGroups: number;
    }>;
  };
};

export type AppDetailsQueryVariables = Exact<{
  appId: Scalars["UUID"];
}>;

export type AppDetailsQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        id: string;
        app:
          | {
              __typename?: "ConnectionApp";
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                description: string;
                connectionType: ConnectionType;
                iconUrl?: string | null;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: {
                      __typename?: "User";
                      id: string;
                      fullName: string;
                      avatarUrl: string;
                    } | null;
                  }>;
                } | null;
                accessStats?: {
                  __typename?: "ConnectionAccessStats";
                  teamAccessCount?: number | null;
                  titleAccessCount?: number | null;
                } | null;
              } | null;
            }
          | {
              __typename?: "OktaResourceApp";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                description: string;
                resourceType: ResourceType;
                serviceType: ServiceType;
                iconUrl?: string | null;
                remoteId: string;
                isRequestable: boolean;
                adminOwner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                  ownerUsers: Array<{
                    __typename?: "OwnerUser";
                    user?: {
                      __typename?: "User";
                      id: string;
                      fullName: string;
                      avatarUrl: string;
                    } | null;
                  }>;
                } | null;
                accessStats?: {
                  __typename?: "ResourceAccessStats";
                  teamAccessCount?: number | null;
                  titleAccessCount?: number | null;
                } | null;
              } | null;
            };
      }
    | { __typename?: "AppNotFoundError"; message: string };
};

export type EndUserOktaResourceAppFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  description: string;
  resourceType: ResourceType;
  serviceType: ServiceType;
  iconUrl?: string | null;
  remoteId: string;
  isRequestable: boolean;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
  } | null;
  accessStats?: {
    __typename?: "ResourceAccessStats";
    teamAccessCount?: number | null;
    titleAccessCount?: number | null;
  } | null;
};

export type EndUserConnectionAppFragment = {
  __typename?: "Connection";
  id: string;
  name: string;
  description: string;
  connectionType: ConnectionType;
  iconUrl?: string | null;
  adminOwner?: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
  } | null;
  accessStats?: {
    __typename?: "ConnectionAccessStats";
    teamAccessCount?: number | null;
    titleAccessCount?: number | null;
  } | null;
};

export type AppEndUserResourcesQueryVariables = Exact<{
  id: Scalars["UUID"];
  itemType?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  access: AccessOption;
  parentResourceId?: InputMaybe<NullableResourceId>;
  ancestorResourceId?: InputMaybe<NullableResourceId>;
  searchQuery?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<AppItemsSortBy>;
  includeGroups?: InputMaybe<Scalars["Boolean"]>;
  hasV3?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AppEndUserResourcesQuery = {
  __typename?: "Query";
  app:
    | {
        __typename?: "App";
        items: {
          __typename?: "AppItemsOutput";
          cursor?: string | null;
          totalNumItems: number;
          items?: Array<{
            __typename?: "AppItem";
            key: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteId: string;
              connectionId: string;
              description: string;
              resourceType: ResourceType;
              parentResourceId?: string | null;
              hasVisibleChildren: boolean;
              isRequestable: boolean;
              ancestorPathToResource?: string | null;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserResourceAccess";
                resourceId: string;
                numAvailableAccessLevels: number;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
                activeSessions: Array<{
                  __typename?: "Session";
                  id: string;
                  durationInMinutes?: number | null;
                  endTime: string;
                  accessLevel?: string | null;
                  accessLevelRemoteId?: string | null;
                  metadata:
                    | {
                        __typename: "AwsIamFederatedEksSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        clusterName: string;
                        clusterRegion: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRdsSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        federatedArn: string;
                        dbInstanceId: string;
                        dbPassword: string;
                        dbEngine: string;
                        dbUser: string;
                        dbHostname: string;
                        dbPort: number;
                        dbName: string;
                      }
                    | {
                        __typename: "AwsIamFederatedRoleSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                      }
                    | {
                        __typename: "AwsIamFederatedSSMSession";
                        id: string;
                        awsAccessKeyId: string;
                        awsSecretAccessKey: string;
                        awsSessionToken: string;
                        awsLoginUrl: string;
                        federatedArn: string;
                        ec2InstanceId: string;
                        ec2Region: string;
                      }
                    | { __typename: "OidcSession" }
                    | { __typename: "OpalImpersonationSession" }
                    | {
                        __typename: "VaultMongoAtlasSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        initDbName: string;
                      }
                    | {
                        __typename: "VaultMongoSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        authenticationDbName: string;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultMySQLMariaDBSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      }
                    | {
                        __typename: "VaultPostgresSession";
                        id: string;
                        dbUser: string;
                        dbPassword: string;
                        dbHostname: string;
                        dbPort: number;
                        tlsConfig: {
                          __typename?: "TlsConfig";
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        };
                      };
                }>;
                pendingRequests: Array<{
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                }>;
              };
            } | null;
            group?: {
              __typename: "Group";
              id: string;
              name: string;
              description: string;
              groupType: GroupType;
              isRequestable: boolean;
              connectionId: string;
              connection?: {
                __typename?: "Connection";
                connectionType: ConnectionType;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                } | null;
              };
            } | null;
          }> | null;
        };
        itemTypes: Array<{
          __typename?: "AppItemType";
          itemType: string;
          displayText: string;
          count: number;
        }>;
      }
    | { __typename?: "AppNotFoundError" };
};

export type AppEndUserResourceRowFragment = {
  __typename: "Resource";
  id: string;
  name: string;
  remoteId: string;
  connectionId: string;
  description: string;
  resourceType: ResourceType;
  parentResourceId?: string | null;
  hasVisibleChildren: boolean;
  isRequestable: boolean;
  ancestorPathToResource?: string | null;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
    }>;
  };
};

export type AppEndUserGroupRowFragment = {
  __typename: "Group";
  id: string;
  name: string;
  description: string;
  groupType: GroupType;
  isRequestable: boolean;
  connectionId: string;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserGroupAccess";
    groupId: string;
    hasBreakGlass: boolean;
    groupUser?: {
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      source: GroupUserSource;
      access?: {
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "GroupPropagationStatus";
        groupId: string;
        userId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        ticketPropagation?: {
          __typename: "GroupUserTicketPropagation";
          resourceTickets: Array<{
            __typename?: "ResourceTicketPropagation";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
        } | null;
      } | null;
      accessLevel?: {
        __typename: "GroupAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
      } | null;
    } | null;
    pendingRequest?: {
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
    } | null;
  };
};

export type ResourceSuggestionFragment = {
  __typename?: "ResourceSuggestion";
  resourceId: string;
  name: string;
  resourceType: ResourceType;
  parentResourceName?: string | null;
};

export type GroupSuggestionFragment = {
  __typename?: "GroupSuggestion";
  groupId: string;
  name: string;
  groupType: GroupType;
};

type Suggestion_GroupSuggestion_Fragment = {
  __typename: "GroupSuggestion";
  groupId: string;
  name: string;
  groupType: GroupType;
};

type Suggestion_ResourceSuggestion_Fragment = {
  __typename: "ResourceSuggestion";
  resourceId: string;
  name: string;
  resourceType: ResourceType;
  parentResourceName?: string | null;
};

export type SuggestionFragment =
  | Suggestion_GroupSuggestion_Fragment
  | Suggestion_ResourceSuggestion_Fragment;

export type SuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestionsQuery = {
  __typename?: "Query";
  suggestions: {
    __typename: "SuggestionsResult";
    suggestions: Array<
      | {
          __typename: "GroupSuggestion";
          groupId: string;
          name: string;
          groupType: GroupType;
        }
      | {
          __typename: "ResourceSuggestion";
          resourceId: string;
          name: string;
          resourceType: ResourceType;
          parentResourceName?: string | null;
        }
    >;
  };
};

export type BundleFragment = {
  __typename?: "Bundle";
  id: string;
  name: string;
  description?: string | null;
  adminOwnerId: string;
  visibility: Visibility;
  authorizedActions?: Array<string> | null;
  totalNumItems: number;
  totalNumResources: number;
  totalNumGroups: number;
  serviceTypes?: Array<ServiceType> | null;
};

export type BundleDetailFragment = {
  __typename?: "Bundle";
  id: string;
  name: string;
  description?: string | null;
  adminOwnerId: string;
  visibility: Visibility;
  authorizedActions?: Array<string> | null;
  totalNumItems: number;
  totalNumResources: number;
  totalNumGroups: number;
  adminOwner: {
    __typename?: "Owner";
    id: string;
    name: string;
    ownerUsers: Array<{
      __typename?: "OwnerUser";
      user?: {
        __typename?: "User";
        id: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
  };
  visibilityGroups: Array<{
    __typename?: "BundleVisibilityGroup";
    id: string;
    visibilityGroupId: string;
  }>;
};

export type BundleDetailForCatalogFragment = {
  __typename?: "Bundle";
  id: string;
  name: string;
  description?: string | null;
  authorizedActions?: Array<string> | null;
  totalNumResources: number;
  totalNumGroups: number;
  serviceTypes?: Array<ServiceType> | null;
};

export type BundleResourceFragment = {
  __typename?: "BundleResource";
  bundleId: string;
  resourceId: string;
  accessLevelName: string;
  accessLevelRemoteId: string;
};

export type BundleGroupFragment = {
  __typename?: "BundleGroup";
  bundleId: string;
  groupId: string;
  accessLevelName: string;
  accessLevelRemoteId: string;
};

export type BundleItemFragment = {
  __typename?: "BundleItem";
  key: string;
  accessLevelName: string;
  accessLevelRemoteId: string;
  resource?: {
    __typename: "Resource";
    ancestorPathToResource?: string | null;
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    description: string;
    connectionId: string;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToConnect: boolean;
    requireMfaToApprove: boolean;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    parentResourceId?: string | null;
    authorizedActions?: Array<string> | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserResourceAccess";
      resourceId: string;
      numAvailableAccessLevels: number;
      resourceUsers: Array<{
        __typename: "ResourceUser";
        resourceId: string;
        userId: string;
        mostRecentSessionEnded?: string | null;
        source: ResourceUserSource;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        access?: {
          __typename?: "ResourceUserAccess";
          resourceId: string;
          userId: string;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          latestExpiringAccessPoint: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "ResourceUserAccessPoint";
            resourceId: string;
            userId: string;
            resourceName: string;
            resourceType: ResourceType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          groupUserAccesses: Array<{
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "ResourcePropagationStatus";
          resourceId: string;
          userId: string;
          accessLevelRemoteId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          metadata?: {
            __typename: "PropagationTicketMetadata";
            ticket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
        } | null;
      }>;
      activeSessions: Array<{
        __typename?: "Session";
        id: string;
        durationInMinutes?: number | null;
        endTime: string;
        accessLevel?: string | null;
        accessLevelRemoteId?: string | null;
        metadata:
          | {
              __typename: "AwsIamFederatedEksSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              clusterName: string;
              clusterRegion: string;
            }
          | {
              __typename: "AwsIamFederatedRdsSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              federatedArn: string;
              dbInstanceId: string;
              dbPassword: string;
              dbEngine: string;
              dbUser: string;
              dbHostname: string;
              dbPort: number;
              dbName: string;
            }
          | {
              __typename: "AwsIamFederatedRoleSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
            }
          | {
              __typename: "AwsIamFederatedSSMSession";
              id: string;
              awsAccessKeyId: string;
              awsSecretAccessKey: string;
              awsSessionToken: string;
              awsLoginUrl: string;
              federatedArn: string;
              ec2InstanceId: string;
              ec2Region: string;
            }
          | { __typename: "OidcSession" }
          | { __typename: "OpalImpersonationSession" }
          | {
              __typename: "VaultMongoAtlasSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              initDbName: string;
            }
          | {
              __typename: "VaultMongoSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              authenticationDbName: string;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultMySQLMariaDBSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            }
          | {
              __typename: "VaultPostgresSession";
              id: string;
              dbUser: string;
              dbPassword: string;
              dbHostname: string;
              dbPort: number;
              tlsConfig: {
                __typename?: "TlsConfig";
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              };
            };
      }>;
      pendingRequests: Array<{
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      }>;
    };
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    tags: Array<{
      __typename?: "ResourceTag";
      id: string;
      resourceId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      resource?: {
        __typename: "Resource";
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        isManaged: boolean;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        parentResourceId?: string | null;
        connectionId: string;
        createdAt: string;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    childResources?: Array<{
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      createdAt: string;
      description: string;
      connectionId: string;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      maxDurationInMinutes?: number | null;
      requireMfaToConnect: boolean;
      requireMfaToApprove: boolean;
      requireMfaToRequest: boolean;
      requireSupportTicket: boolean;
      isRequestable: boolean;
      autoApproval: boolean;
      parentResourceId?: string | null;
      adminOwner?: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: { __typename?: "User"; id: string } | null;
        }>;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      currentUserAccess: {
        __typename?: "CurrentUserResourceAccess";
        resourceId: string;
        numAvailableAccessLevels: number;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
        activeSessions: Array<{
          __typename?: "Session";
          id: string;
          durationInMinutes?: number | null;
          endTime: string;
          accessLevel?: string | null;
          accessLevelRemoteId?: string | null;
          metadata:
            | {
                __typename: "AwsIamFederatedEksSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                clusterName: string;
                clusterRegion: string;
              }
            | {
                __typename: "AwsIamFederatedRdsSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                federatedArn: string;
                dbInstanceId: string;
                dbPassword: string;
                dbEngine: string;
                dbUser: string;
                dbHostname: string;
                dbPort: number;
                dbName: string;
              }
            | {
                __typename: "AwsIamFederatedRoleSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
              }
            | {
                __typename: "AwsIamFederatedSSMSession";
                id: string;
                awsAccessKeyId: string;
                awsSecretAccessKey: string;
                awsSessionToken: string;
                awsLoginUrl: string;
                federatedArn: string;
                ec2InstanceId: string;
                ec2Region: string;
              }
            | { __typename: "OidcSession" }
            | { __typename: "OpalImpersonationSession" }
            | {
                __typename: "VaultMongoAtlasSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                initDbName: string;
              }
            | {
                __typename: "VaultMongoSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                authenticationDbName: string;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultMySQLMariaDBSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              }
            | {
                __typename: "VaultPostgresSession";
                id: string;
                dbUser: string;
                dbPassword: string;
                dbHostname: string;
                dbPort: number;
                tlsConfig: {
                  __typename?: "TlsConfig";
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                };
              };
        }>;
        pendingRequests: Array<{
          __typename?: "Request";
          id: string;
          requesterId: string;
          targetUserId?: string | null;
          targetGroupId?: string | null;
          resourceProposalId?: string | null;
          createdAt: string;
          updatedAt: string;
          status: RequestStatus;
          requester?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          targetGroup?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          requestedResources: Array<{
            __typename?: "RequestedResource";
            resourceId: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            targetUserAccess?: {
              __typename?: "UserResourceAccess";
              resourceId: string;
              userId: string;
              resourceUsers: Array<{
                __typename: "ResourceUser";
                resourceId: string;
                userId: string;
                mostRecentSessionEnded?: string | null;
                source: ResourceUserSource;
                user?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  createdAt: string;
                  updatedAt: string;
                  organizationId: string;
                  position: string;
                  teamAttr?: string | null;
                  managerId?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                  manager?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                access?: {
                  __typename?: "ResourceUserAccess";
                  resourceId: string;
                  userId: string;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  latestExpiringAccessPoint: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  inheritedAccessPoints: Array<{
                    __typename?: "ResourceUserAccessPoint";
                    resourceId: string;
                    userId: string;
                    resourceName: string;
                    resourceType: ResourceType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                  groupUserAccesses: Array<{
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "ResourcePropagationStatus";
                  resourceId: string;
                  userId: string;
                  accessLevelRemoteId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  metadata?: {
                    __typename: "PropagationTicketMetadata";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                } | null;
              }>;
            } | null;
          }>;
          requestedGroups: Array<{
            __typename?: "RequestedGroup";
            groupId: string;
            group?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            targetUserAccess?: {
              __typename?: "UserGroupAccess";
              groupId: string;
              userId: string;
              groupUser?: {
                __typename?: "GroupUser";
                groupId: string;
                userId: string;
                source: GroupUserSource;
                access?: {
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                } | null;
                propagationStatus?: {
                  __typename?: "GroupPropagationStatus";
                  groupId: string;
                  userId: string;
                  lastSynced: string;
                  statusCode: PropagationStatusCode;
                  errorMessage?: string | null;
                  taskType: PropagationTaskType;
                  ticketPropagation?: {
                    __typename: "GroupUserTicketPropagation";
                    resourceTickets: Array<{
                      __typename?: "ResourceTicketPropagation";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
              } | null;
            } | null;
          }>;
          resourceProposal?: {
            __typename?: "ResourceProposal";
            id: string;
            connectionId: string;
            serviceType: ServiceType;
            resourceType: ResourceType;
            metadata?: {
              __typename: "AWSRoleProposalMetadata";
              policyName: string;
              policyDocument: string;
              policyDescription: string;
              assumeRolePolicyDocument: string;
              roleName: string;
              roleDescription: string;
              tags: Array<{
                __typename?: "AWSTag";
                key: string;
                value?: string | null;
              }>;
            } | null;
          } | null;
        }>;
      };
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
      commonMetadata: {
        __typename?: "CommonMetadata";
        matchRemoteName: boolean;
        matchRemoteDescription: boolean;
      };
      tags: Array<{
        __typename?: "ResourceTag";
        id: string;
        resourceId: string;
        tagId: string;
        source: ServiceType;
        updatedAt: string;
        createdAt: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        tag?: {
          __typename: "Tag";
          id: string;
          createdAt: string;
          creatorUserId: string;
          key: string;
          value?: string | null;
          isStandardAttribute: boolean;
        } | null;
      }>;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
    }> | null;
    requestTemplate?: {
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      isDeleted?: boolean | null;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        name: string;
        type: RequestTemplateCustomFieldType;
        required?: boolean | null;
        description?: string | null;
        metadata?: {
          __typename?: "RequestTemplateCustomFieldMetadata";
          multiChoiceData?: {
            __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
            options?: Array<{
              __typename?: "MultiChoiceOption";
              value: string;
              label: string;
            }> | null;
          } | null;
        } | null;
      }> | null;
    } | null;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
  } | null;
  group?: {
    __typename: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    groupType: GroupType;
    serviceType: ServiceType;
    iconUrl?: string | null;
    visibility: Visibility;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    description: string;
    numGroupUsers: number;
    connectionId: string;
    isOnCallSynced: boolean;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    isManaged: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    groupBindingId?: string | null;
    customRequestNotification?: string | null;
    adminOwner?: {
      __typename?: "Owner";
      id: string;
      name: string;
      ownerUsers: Array<{
        __typename?: "OwnerUser";
        user?: { __typename?: "User"; id: string } | null;
      }>;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    currentUserAccess: {
      __typename?: "CurrentUserGroupAccess";
      groupId: string;
      hasBreakGlass: boolean;
      groupUser?: {
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        source: GroupUserSource;
        access?: {
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        } | null;
        propagationStatus?: {
          __typename?: "GroupPropagationStatus";
          groupId: string;
          userId: string;
          lastSynced: string;
          statusCode: PropagationStatusCode;
          errorMessage?: string | null;
          taskType: PropagationTaskType;
          ticketPropagation?: {
            __typename: "GroupUserTicketPropagation";
            resourceTickets: Array<{
              __typename?: "ResourceTicketPropagation";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
          } | null;
        } | null;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      } | null;
      pendingRequest?: {
        __typename?: "Request";
        id: string;
        requesterId: string;
        targetUserId?: string | null;
        targetGroupId?: string | null;
        resourceProposalId?: string | null;
        createdAt: string;
        updatedAt: string;
        status: RequestStatus;
        requester?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetUser?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
        targetGroup?: {
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        } | null;
        requestedResources: Array<{
          __typename?: "RequestedResource";
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          targetUserAccess?: {
            __typename?: "UserResourceAccess";
            resourceId: string;
            userId: string;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
          } | null;
        }>;
        requestedGroups: Array<{
          __typename?: "RequestedGroup";
          groupId: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          targetUserAccess?: {
            __typename?: "UserGroupAccess";
            groupId: string;
            userId: string;
            groupUser?: {
              __typename?: "GroupUser";
              groupId: string;
              userId: string;
              source: GroupUserSource;
              access?: {
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "GroupPropagationStatus";
                groupId: string;
                userId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                ticketPropagation?: {
                  __typename: "GroupUserTicketPropagation";
                  resourceTickets: Array<{
                    __typename?: "ResourceTicketPropagation";
                    ticket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
            } | null;
          } | null;
        }>;
        resourceProposal?: {
          __typename?: "ResourceProposal";
          id: string;
          connectionId: string;
          serviceType: ServiceType;
          resourceType: ResourceType;
          metadata?: {
            __typename: "AWSRoleProposalMetadata";
            policyName: string;
            policyDocument: string;
            policyDescription: string;
            assumeRolePolicyDocument: string;
            roleName: string;
            roleDescription: string;
            tags: Array<{
              __typename?: "AWSTag";
              key: string;
              value?: string | null;
            }>;
          } | null;
        } | null;
      } | null;
    };
    tags: Array<{
      __typename?: "GroupTag";
      groupId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      group?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
    onCallSchedules?: Array<{
      __typename?: "GroupOnCallSchedule";
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }> | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    commonMetadata: {
      __typename?: "CommonMetadata";
      matchRemoteName: boolean;
      matchRemoteDescription: boolean;
    };
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    groupLeaders: Array<{
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    }>;
  } | null;
};

export type CreateBundleMutationVariables = Exact<{
  input: CreateBundleInput;
}>;

export type CreateBundleMutation = {
  __typename?: "Mutation";
  createBundle:
    | { __typename: "BundleNameExistsError"; message: string }
    | {
        __typename: "CreateBundleResult";
        bundle: {
          __typename?: "Bundle";
          id: string;
          name: string;
          description?: string | null;
          adminOwnerId: string;
          visibility: Visibility;
          authorizedActions?: Array<string> | null;
          totalNumItems: number;
          totalNumResources: number;
          totalNumGroups: number;
          serviceTypes?: Array<ServiceType> | null;
        };
      };
};

export type UpdateBundleMutationVariables = Exact<{
  input: UpdateBundleInput;
}>;

export type UpdateBundleMutation = {
  __typename?: "Mutation";
  updateBundle:
    | { __typename: "BundleNameExistsError"; message: string }
    | { __typename: "BundleNotAuthorizedError" }
    | {
        __typename: "UpdateBundleResult";
        bundle: {
          __typename?: "Bundle";
          id: string;
          name: string;
          description?: string | null;
          adminOwnerId: string;
          visibility: Visibility;
          authorizedActions?: Array<string> | null;
          totalNumItems: number;
          totalNumResources: number;
          totalNumGroups: number;
          serviceTypes?: Array<ServiceType> | null;
        };
      };
};

export type DeleteBundleMutationVariables = Exact<{
  input: DeleteBundleInput;
}>;

export type DeleteBundleMutation = {
  __typename?: "Mutation";
  deleteBundle: {
    __typename: "DeleteBundleOutput";
    bundle: {
      __typename?: "Bundle";
      id: string;
      name: string;
      description?: string | null;
      adminOwnerId: string;
      visibility: Visibility;
      authorizedActions?: Array<string> | null;
      totalNumItems: number;
      totalNumResources: number;
      totalNumGroups: number;
      serviceTypes?: Array<ServiceType> | null;
    };
  };
};

export type CreateBundleResourcesMutationVariables = Exact<{
  input: BundleResourcesInput;
}>;

export type CreateBundleResourcesMutation = {
  __typename?: "Mutation";
  createBundleResources: {
    __typename: "CreateBundleResourcesOutput";
    bundleResources: Array<{
      __typename?: "BundleResource";
      bundleId: string;
      resourceId: string;
      accessLevelName: string;
      accessLevelRemoteId: string;
    }>;
  };
};

export type DeleteBundleResourcesMutationVariables = Exact<{
  input: DeleteBundleResourcesInput;
}>;

export type DeleteBundleResourcesMutation = {
  __typename?: "Mutation";
  deleteBundleResources: boolean;
};

export type CreateBundleGroupsMutationVariables = Exact<{
  input: BundleGroupsInput;
}>;

export type CreateBundleGroupsMutation = {
  __typename?: "Mutation";
  createBundleGroups: {
    __typename: "CreateBundleGroupsOutput";
    bundleGroups: Array<{
      __typename?: "BundleGroup";
      bundleId: string;
      groupId: string;
      accessLevelName: string;
      accessLevelRemoteId: string;
    }>;
  };
};

export type DeleteBundleGroupsMutationVariables = Exact<{
  input: DeleteBundleGroupsInput;
}>;

export type DeleteBundleGroupsMutation = {
  __typename?: "Mutation";
  deleteBundleGroups: boolean;
};

export type BundleQueryVariables = Exact<{
  input: BundleInput;
}>;

export type BundleQuery = {
  __typename?: "Query";
  bundle:
    | { __typename: "BundleNotFoundError"; message: string }
    | {
        __typename: "BundleResult";
        bundle: {
          __typename?: "Bundle";
          id: string;
          name: string;
          description?: string | null;
          adminOwnerId: string;
          visibility: Visibility;
          authorizedActions?: Array<string> | null;
          totalNumItems: number;
          totalNumResources: number;
          totalNumGroups: number;
          adminOwner: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: {
                __typename?: "User";
                id: string;
                fullName: string;
                avatarUrl: string;
              } | null;
            }>;
          };
          visibilityGroups: Array<{
            __typename?: "BundleVisibilityGroup";
            id: string;
            visibilityGroupId: string;
          }>;
        };
      };
};

export type BundlesQueryVariables = Exact<{
  input: BundlesInput;
}>;

export type BundlesQuery = {
  __typename?: "Query";
  bundles: {
    __typename: "BundlesOutput";
    totalNumBundles: number;
    cursor?: string | null;
    bundles?: Array<{
      __typename?: "Bundle";
      id: string;
      name: string;
      description?: string | null;
      adminOwnerId: string;
      visibility: Visibility;
      authorizedActions?: Array<string> | null;
      totalNumItems: number;
      totalNumResources: number;
      totalNumGroups: number;
      adminOwner: {
        __typename?: "Owner";
        id: string;
        name: string;
        ownerUsers: Array<{
          __typename?: "OwnerUser";
          user?: {
            __typename?: "User";
            id: string;
            fullName: string;
            avatarUrl: string;
          } | null;
        }>;
      };
      visibilityGroups: Array<{
        __typename?: "BundleVisibilityGroup";
        id: string;
        visibilityGroupId: string;
      }>;
    }> | null;
  };
};

export type BundleCatalogQueryVariables = Exact<{
  input: BundlesInput;
}>;

export type BundleCatalogQuery = {
  __typename?: "Query";
  bundles: {
    __typename: "BundlesOutput";
    totalNumBundles: number;
    cursor?: string | null;
    bundles?: Array<{
      __typename?: "Bundle";
      id: string;
      name: string;
      description?: string | null;
      authorizedActions?: Array<string> | null;
      totalNumResources: number;
      totalNumGroups: number;
      serviceTypes?: Array<ServiceType> | null;
    }> | null;
  };
};

export type BundleItemsQueryVariables = Exact<{
  input: BundleItemsInput;
}>;

export type BundleItemsQuery = {
  __typename?: "Query";
  bundleItems: {
    __typename: "BundleItemsOutput";
    totalNumItems: number;
    totalNumResources: number;
    totalNumGroups: number;
    cursor?: string | null;
    items?: Array<{
      __typename?: "BundleItem";
      key: string;
      accessLevelName: string;
      accessLevelRemoteId: string;
      resource?: {
        __typename: "Resource";
        ancestorPathToResource?: string | null;
        id: string;
        name: string;
        remoteName: string;
        serviceType: ServiceType;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        description: string;
        connectionId: string;
        resourceType: ResourceType;
        remoteId: string;
        iconUrl?: string | null;
        maxDurationInMinutes?: number | null;
        recommendedDurationInMinutes?: number | null;
        requireMfaToConnect: boolean;
        requireMfaToApprove: boolean;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        isManaged: boolean;
        autoApproval: boolean;
        reasonOptional: boolean;
        requestTemplateId?: string | null;
        parentResourceId?: string | null;
        authorizedActions?: Array<string> | null;
        customRequestNotification?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserResourceAccess";
          resourceId: string;
          numAvailableAccessLevels: number;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
          activeSessions: Array<{
            __typename?: "Session";
            id: string;
            durationInMinutes?: number | null;
            endTime: string;
            accessLevel?: string | null;
            accessLevelRemoteId?: string | null;
            metadata:
              | {
                  __typename: "AwsIamFederatedEksSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  clusterName: string;
                  clusterRegion: string;
                }
              | {
                  __typename: "AwsIamFederatedRdsSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  federatedArn: string;
                  dbInstanceId: string;
                  dbPassword: string;
                  dbEngine: string;
                  dbUser: string;
                  dbHostname: string;
                  dbPort: number;
                  dbName: string;
                }
              | {
                  __typename: "AwsIamFederatedRoleSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                }
              | {
                  __typename: "AwsIamFederatedSSMSession";
                  id: string;
                  awsAccessKeyId: string;
                  awsSecretAccessKey: string;
                  awsSessionToken: string;
                  awsLoginUrl: string;
                  federatedArn: string;
                  ec2InstanceId: string;
                  ec2Region: string;
                }
              | { __typename: "OidcSession" }
              | { __typename: "OpalImpersonationSession" }
              | {
                  __typename: "VaultMongoAtlasSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  initDbName: string;
                }
              | {
                  __typename: "VaultMongoSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  authenticationDbName: string;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultMySQLMariaDBSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                }
              | {
                  __typename: "VaultPostgresSession";
                  id: string;
                  dbUser: string;
                  dbPassword: string;
                  dbHostname: string;
                  dbPort: number;
                  tlsConfig: {
                    __typename?: "TlsConfig";
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  };
                };
          }>;
          pendingRequests: Array<{
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          }>;
        };
        metadata?:
          | { __typename: "AwsRoleMetadata"; arn: string }
          | {
              __typename: "OktaDirectoryAppMetadata";
              appId: string;
              appName: string;
              logoUrl?: string | null;
            }
          | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
          | {
              __typename: "PropagationTicketOwnerMetadata";
              ownerId?: string | null;
              owner?: { __typename?: "Owner"; id: string; name: string } | null;
            }
          | null;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        tags: Array<{
          __typename?: "ResourceTag";
          id: string;
          resourceId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        childResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          createdAt: string;
          description: string;
          connectionId: string;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          maxDurationInMinutes?: number | null;
          requireMfaToConnect: boolean;
          requireMfaToApprove: boolean;
          requireMfaToRequest: boolean;
          requireSupportTicket: boolean;
          isRequestable: boolean;
          autoApproval: boolean;
          parentResourceId?: string | null;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
            ownerUsers: Array<{
              __typename?: "OwnerUser";
              user?: { __typename?: "User"; id: string } | null;
            }>;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              resourceProposalId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requester?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetUser?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
              targetGroup?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
              requestedGroups: Array<{
                __typename?: "RequestedGroup";
                groupId: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                targetUserAccess?: {
                  __typename?: "UserGroupAccess";
                  groupId: string;
                  userId: string;
                  groupUser?: {
                    __typename?: "GroupUser";
                    groupId: string;
                    userId: string;
                    source: GroupUserSource;
                    access?: {
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "GroupPropagationStatus";
                      groupId: string;
                      userId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      ticketPropagation?: {
                        __typename: "GroupUserTicketPropagation";
                        resourceTickets: Array<{
                          __typename?: "ResourceTicketPropagation";
                          ticket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                  } | null;
                } | null;
              }>;
              resourceProposal?: {
                __typename?: "ResourceProposal";
                id: string;
                connectionId: string;
                serviceType: ServiceType;
                resourceType: ResourceType;
                metadata?: {
                  __typename: "AWSRoleProposalMetadata";
                  policyName: string;
                  policyDocument: string;
                  policyDescription: string;
                  assumeRolePolicyDocument: string;
                  roleName: string;
                  roleDescription: string;
                  tags: Array<{
                    __typename?: "AWSTag";
                    key: string;
                    value?: string | null;
                  }>;
                } | null;
              } | null;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
          commonMetadata: {
            __typename?: "CommonMetadata";
            matchRemoteName: boolean;
            matchRemoteDescription: boolean;
          };
          tags: Array<{
            __typename?: "ResourceTag";
            id: string;
            resourceId: string;
            tagId: string;
            source: ServiceType;
            updatedAt: string;
            createdAt: string;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            tag?: {
              __typename: "Tag";
              id: string;
              createdAt: string;
              creatorUserId: string;
              key: string;
              value?: string | null;
              isStandardAttribute: boolean;
            } | null;
          }>;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
        }> | null;
        requestTemplate?: {
          __typename?: "RequestTemplate";
          id: string;
          name: string;
          isDeleted?: boolean | null;
          customFields?: Array<{
            __typename?: "RequestTemplateCustomField";
            name: string;
            type: RequestTemplateCustomFieldType;
            required?: boolean | null;
            description?: string | null;
            metadata?: {
              __typename?: "RequestTemplateCustomFieldMetadata";
              multiChoiceData?: {
                __typename?: "RequestTemplateCustomFieldMultiChoiceMetadata";
                options?: Array<{
                  __typename?: "MultiChoiceOption";
                  value: string;
                  label: string;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
        parentResource?: {
          __typename?: "Resource";
          id: string;
          name: string;
        } | null;
      } | null;
      group?: {
        __typename: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        groupType: GroupType;
        serviceType: ServiceType;
        iconUrl?: string | null;
        visibility: Visibility;
        isDeleted: boolean;
        createdAt: string;
        updatedAt: string;
        description: string;
        numGroupUsers: number;
        connectionId: string;
        isOnCallSynced: boolean;
        maxDurationInMinutes?: number | null;
        recommendedDurationInMinutes?: number | null;
        requireMfaToRequest: boolean;
        requireSupportTicket: boolean;
        isRequestable: boolean;
        isManaged: boolean;
        autoApproval: boolean;
        reasonOptional: boolean;
        requestTemplateId?: string | null;
        groupBindingId?: string | null;
        customRequestNotification?: string | null;
        adminOwner?: {
          __typename?: "Owner";
          id: string;
          name: string;
          ownerUsers: Array<{
            __typename?: "OwnerUser";
            user?: { __typename?: "User"; id: string } | null;
          }>;
        } | null;
        connection?: {
          __typename: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        } | null;
        currentUserAccess: {
          __typename?: "CurrentUserGroupAccess";
          groupId: string;
          hasBreakGlass: boolean;
          groupUser?: {
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
          } | null;
          pendingRequest?: {
            __typename?: "Request";
            id: string;
            requesterId: string;
            targetUserId?: string | null;
            targetGroupId?: string | null;
            resourceProposalId?: string | null;
            createdAt: string;
            updatedAt: string;
            status: RequestStatus;
            requester?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetUser?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
            targetGroup?: {
              __typename?: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              numGroupUsers: number;
              groupType: GroupType;
              serviceType: ServiceType;
              visibility: Visibility;
              isManaged: boolean;
              isDeleted: boolean;
              iconUrl?: string | null;
              isOnCallSynced: boolean;
              connectionId: string;
              groupBindingId?: string | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              connection?: {
                __typename?: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
              } | null;
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                }>;
              } | null;
            } | null;
            requestedResources: Array<{
              __typename?: "RequestedResource";
              resourceId: string;
              resource?: {
                __typename: "Resource";
                id: string;
                name: string;
                remoteName: string;
                serviceType: ServiceType;
                visibility: Visibility;
                isDeleted: boolean;
                isManaged: boolean;
                resourceType: ResourceType;
                remoteId: string;
                iconUrl?: string | null;
                parentResourceId?: string | null;
                connectionId: string;
                createdAt: string;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                parentResource?: {
                  __typename?: "Resource";
                  id: string;
                  name: string;
                } | null;
                connection?: {
                  __typename: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                  isDeleted: boolean;
                  metadata?:
                    | {
                        __typename: "ADConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                      }
                    | {
                        __typename: "AwsConnectionMetadata";
                        success?: boolean | null;
                      }
                    | {
                        __typename: "AWSSSOConnectionMetadata";
                        managementAccountId: string;
                        ssoInstanceArn: string;
                        identityStoreId: string;
                        accessPortalUrl: string;
                        awsRegion: string;
                        awsOrganizationEnabled: boolean;
                        awsSsoEnabled: boolean;
                        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                        awsOrganizationImportSetting: ImportSetting;
                        ec2RoleChainingEnabled: boolean;
                        podRoleChainingEnabled: boolean;
                        cloudtrailEventsSqsUrl: string;
                      }
                    | {
                        __typename: "AzureADConnectionMetadata";
                        tenantId: string;
                        clientId: string;
                        azureInfraEnabled: boolean;
                        eventHubNamespace: string;
                        eventHub: string;
                      }
                    | {
                        __typename: "CustomConnectorConnectionMetadata";
                        identifier: string;
                        baseUrl: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                        supportsGroups: boolean;
                        supportsNestedResources: boolean;
                      }
                    | {
                        __typename: "DuoConnectionMetadata";
                        apiHostname: string;
                      }
                    | {
                        __typename: "GCPConnectionMetadata";
                        pubSubProjectId: string;
                        subscriptionId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GitHubConnectionMetadata";
                        orgName: string;
                      }
                    | {
                        __typename: "GitLabConnectionMetadata";
                        hostName?: string | null;
                        appId: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "GoogleGroupsConnectionMetadata";
                        opalGroupEmail: string;
                        adminUserEmail: string;
                        domain: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "GoogleWorkspaceConnectionMetadata";
                        adminUserEmail: string;
                        customerId: string;
                        serviceAccount: {
                          __typename?: "GCPServiceAccount";
                          type: string;
                          projectId: string;
                          clientEmail: string;
                          clientId: string;
                          authUri: string;
                          tokenUri: string;
                          authProviderX509CertUrl: string;
                          clientX509CertUrl: string;
                        };
                      }
                    | {
                        __typename: "LDAPConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        groupsDistinguishedName: string;
                        usersDistinguishedName: string;
                        groupsUidKey: string;
                      }
                    | {
                        __typename: "MongoAtlasConnectionMetadata";
                        serverHostname: string;
                      }
                    | {
                        __typename: "MongoConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "MySQLMariaDBConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "OktaDirectoryConnectionMetadata";
                        orgUrl: string;
                      }
                    | { __typename: "PagerDutyConnectionMetadata" }
                    | {
                        __typename: "PostgresConnectionMetadata";
                        serverHostname: string;
                        serverPort: number;
                        tlsMode: boolean;
                        tlsCertContent?: string | null;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "PropagationTicketConnectionMetadata";
                        ticketProvider?: {
                          __typename?: "TicketProvider";
                          ticketProvider: ThirdPartyProvider;
                          ticketProjectId: string;
                          ticketProjectName: string;
                        } | null;
                      }
                    | {
                        __typename: "SalesforceConnectionMetadata";
                        email: string;
                        hostname: string;
                      }
                    | {
                        __typename: "SnowflakeConnectionMetadata";
                        organization: string;
                        account: string;
                        locator: string;
                        accountIdentifier: string;
                        accountUrl: string;
                        serviceAccountName: string;
                        serviceAccountRole: string;
                      }
                    | {
                        __typename: "TailscaleConnectionMetadata";
                        tailnet: string;
                      }
                    | {
                        __typename: "TeleportConnectionMetadata";
                        host: string;
                        tlsMode: boolean;
                        tlsCaCertContent?: string | null;
                      }
                    | {
                        __typename: "WorkdayConnectionMetadata";
                        tenantName: string;
                        tenantUrl: string;
                      }
                    | null;
                } | null;
                metadata?:
                  | { __typename: "AwsRoleMetadata"; arn: string }
                  | {
                      __typename: "OktaDirectoryAppMetadata";
                      appId: string;
                      appName: string;
                      logoUrl?: string | null;
                    }
                  | {
                      __typename: "OktaRoleMetadata";
                      roleId: string;
                      roleType: string;
                    }
                  | {
                      __typename: "PropagationTicketOwnerMetadata";
                      ownerId?: string | null;
                      owner?: {
                        __typename?: "Owner";
                        id: string;
                        name: string;
                      } | null;
                    }
                  | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              targetUserAccess?: {
                __typename?: "UserResourceAccess";
                resourceId: string;
                userId: string;
                resourceUsers: Array<{
                  __typename: "ResourceUser";
                  resourceId: string;
                  userId: string;
                  mostRecentSessionEnded?: string | null;
                  source: ResourceUserSource;
                  user?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    createdAt: string;
                    updatedAt: string;
                    organizationId: string;
                    position: string;
                    teamAttr?: string | null;
                    managerId?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                    manager?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      position: string;
                      teamAttr?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                    } | null;
                  } | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  access?: {
                    __typename?: "ResourceUserAccess";
                    resourceId: string;
                    userId: string;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    latestExpiringAccessPoint: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    inheritedAccessPoints: Array<{
                      __typename?: "ResourceUserAccessPoint";
                      resourceId: string;
                      userId: string;
                      resourceName: string;
                      resourceType: ResourceType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    }>;
                    groupUserAccesses: Array<{
                      __typename?: "GroupUserAccess";
                      groupId: string;
                      userId: string;
                      isGroupManaged: boolean;
                      latestExpiringAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      indirectAccessPointPaths: Array<{
                        __typename?: "IndirectGroupUserAccessPointPath";
                        startGroupId: string;
                        endGroupId: string;
                        userId: string;
                        resolvedAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        indirectAccessPointPath: Array<{
                          __typename?: "IndirectGroupAccessPoint";
                          groupId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                        }>;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "ResourcePropagationStatus";
                    resourceId: string;
                    userId: string;
                    accessLevelRemoteId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    metadata?: {
                      __typename: "PropagationTicketMetadata";
                      ticket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                  } | null;
                }>;
              } | null;
            }>;
            requestedGroups: Array<{
              __typename?: "RequestedGroup";
              groupId: string;
              group?: {
                __typename?: "Group";
                id: string;
                remoteId?: string | null;
                name: string;
                remoteName: string;
                numGroupUsers: number;
                groupType: GroupType;
                serviceType: ServiceType;
                visibility: Visibility;
                isManaged: boolean;
                isDeleted: boolean;
                iconUrl?: string | null;
                isOnCallSynced: boolean;
                connectionId: string;
                groupBindingId?: string | null;
                visibilityGroups: Array<{
                  __typename?: "ConfigurationVisibilityGroup";
                  visibilityGroupId: string;
                  visibilityGroup: { __typename?: "Group"; name: string };
                }>;
                connection?: {
                  __typename?: "Connection";
                  id: string;
                  name: string;
                  connectionType: ConnectionType;
                } | null;
                groupBinding?: {
                  __typename?: "GroupBinding";
                  id: string;
                  sourceGroupId: string;
                  groups: Array<{
                    __typename?: "Group";
                    id: string;
                    name: string;
                    groupType: GroupType;
                    connectionId: string;
                  }>;
                } | null;
              } | null;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              targetUserAccess?: {
                __typename?: "UserGroupAccess";
                groupId: string;
                userId: string;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
              } | null;
            }>;
            resourceProposal?: {
              __typename?: "ResourceProposal";
              id: string;
              connectionId: string;
              serviceType: ServiceType;
              resourceType: ResourceType;
              metadata?: {
                __typename: "AWSRoleProposalMetadata";
                policyName: string;
                policyDocument: string;
                policyDescription: string;
                assumeRolePolicyDocument: string;
                roleName: string;
                roleDescription: string;
                tags: Array<{
                  __typename?: "AWSTag";
                  key: string;
                  value?: string | null;
                }>;
              } | null;
            } | null;
          } | null;
        };
        tags: Array<{
          __typename?: "GroupTag";
          groupId: string;
          tagId: string;
          source: ServiceType;
          updatedAt: string;
          createdAt: string;
          group?: {
            __typename?: "Group";
            id: string;
            remoteId?: string | null;
            name: string;
            remoteName: string;
            numGroupUsers: number;
            groupType: GroupType;
            serviceType: ServiceType;
            visibility: Visibility;
            isManaged: boolean;
            isDeleted: boolean;
            iconUrl?: string | null;
            isOnCallSynced: boolean;
            connectionId: string;
            groupBindingId?: string | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
            groupBinding?: {
              __typename?: "GroupBinding";
              id: string;
              sourceGroupId: string;
              groups: Array<{
                __typename?: "Group";
                id: string;
                name: string;
                groupType: GroupType;
                connectionId: string;
              }>;
            } | null;
          } | null;
          tag?: {
            __typename: "Tag";
            id: string;
            createdAt: string;
            creatorUserId: string;
            key: string;
            value?: string | null;
            isStandardAttribute: boolean;
          } | null;
        }>;
        onCallSchedules?: Array<{
          __typename?: "GroupOnCallSchedule";
          onCallSchedule: {
            __typename?: "OnCallSchedule";
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            name: string;
            existsInRemote: boolean;
          };
        }> | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        commonMetadata: {
          __typename?: "CommonMetadata";
          matchRemoteName: boolean;
          matchRemoteDescription: boolean;
        };
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          createdAt: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          } | null;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          }>;
          createdByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
        groupLeaders: Array<{
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        }>;
      } | null;
    }> | null;
    itemTypes?: Array<{
      __typename?: "AppItemType";
      itemType: string;
      displayText: string;
    }> | null;
  };
};

export type ConnectSessionViewQueryVariables = Exact<{
  id: Scalars["ResourceId"];
}>;

export type ConnectSessionViewQuery = {
  __typename?: "Query";
  resource:
    | { __typename: "ResourceNotFoundError"; message: string }
    | {
        __typename: "ResourceResult";
        resource: {
          __typename?: "Resource";
          id: string;
          name: string;
          remoteId: string;
          ancestorPathToResource?: string | null;
          resourceType: ResourceType;
          serviceType: ServiceType;
          requireMfaToConnect: boolean;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            metadata?:
              | { __typename?: "ADConnectionMetadata" }
              | { __typename?: "AwsConnectionMetadata" }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  accessPortalUrl: string;
                }
              | { __typename?: "AzureADConnectionMetadata" }
              | { __typename?: "CustomConnectorConnectionMetadata" }
              | { __typename?: "DuoConnectionMetadata" }
              | { __typename?: "GCPConnectionMetadata" }
              | { __typename?: "GitHubConnectionMetadata" }
              | { __typename?: "GitLabConnectionMetadata" }
              | { __typename?: "GoogleGroupsConnectionMetadata" }
              | { __typename?: "GoogleWorkspaceConnectionMetadata" }
              | { __typename?: "LDAPConnectionMetadata" }
              | { __typename?: "MongoAtlasConnectionMetadata" }
              | { __typename?: "MongoConnectionMetadata" }
              | { __typename?: "MySQLMariaDBConnectionMetadata" }
              | { __typename?: "OktaDirectoryConnectionMetadata" }
              | { __typename?: "PagerDutyConnectionMetadata" }
              | { __typename?: "PostgresConnectionMetadata" }
              | { __typename?: "PropagationTicketConnectionMetadata" }
              | { __typename?: "SalesforceConnectionMetadata" }
              | { __typename?: "SnowflakeConnectionMetadata" }
              | { __typename?: "TailscaleConnectionMetadata" }
              | { __typename?: "TeleportConnectionMetadata" }
              | { __typename?: "WorkdayConnectionMetadata" }
              | null;
          } | null;
          currentUserAccess: {
            __typename?: "CurrentUserResourceAccess";
            resourceId: string;
            numAvailableAccessLevels: number;
            resourceUsers: Array<{
              __typename: "ResourceUser";
              resourceId: string;
              userId: string;
              mostRecentSessionEnded?: string | null;
              source: ResourceUserSource;
              user?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                createdAt: string;
                updatedAt: string;
                organizationId: string;
                position: string;
                teamAttr?: string | null;
                managerId?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
                manager?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              access?: {
                __typename?: "ResourceUserAccess";
                resourceId: string;
                userId: string;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                latestExpiringAccessPoint: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                inheritedAccessPoints: Array<{
                  __typename?: "ResourceUserAccessPoint";
                  resourceId: string;
                  userId: string;
                  resourceName: string;
                  resourceType: ResourceType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  accessLevel: {
                    __typename: "ResourceAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                    accessLevelMetadata?: {
                      __typename: "ImpersonationAccessLevelMetadata";
                      avatarUrl: string;
                    } | null;
                  };
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
                groupUserAccesses: Array<{
                  __typename?: "GroupUserAccess";
                  groupId: string;
                  userId: string;
                  isGroupManaged: boolean;
                  latestExpiringAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  directAccessPoint?: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  } | null;
                  indirectAccessPointPaths: Array<{
                    __typename?: "IndirectGroupUserAccessPointPath";
                    startGroupId: string;
                    endGroupId: string;
                    userId: string;
                    resolvedAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    indirectAccessPointPath: Array<{
                      __typename?: "IndirectGroupAccessPoint";
                      groupId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                    }>;
                  }>;
                }>;
              } | null;
              propagationStatus?: {
                __typename?: "ResourcePropagationStatus";
                resourceId: string;
                userId: string;
                accessLevelRemoteId: string;
                lastSynced: string;
                statusCode: PropagationStatusCode;
                errorMessage?: string | null;
                taskType: PropagationTaskType;
                metadata?: {
                  __typename: "PropagationTicketMetadata";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
              } | null;
            }>;
            activeSessions: Array<{
              __typename?: "Session";
              id: string;
              durationInMinutes?: number | null;
              endTime: string;
              accessLevel?: string | null;
              accessLevelRemoteId?: string | null;
              metadata:
                | {
                    __typename: "AwsIamFederatedEksSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    clusterName: string;
                    clusterRegion: string;
                  }
                | {
                    __typename: "AwsIamFederatedRdsSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    federatedArn: string;
                    dbInstanceId: string;
                    dbPassword: string;
                    dbEngine: string;
                    dbUser: string;
                    dbHostname: string;
                    dbPort: number;
                    dbName: string;
                  }
                | {
                    __typename: "AwsIamFederatedRoleSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                  }
                | {
                    __typename: "AwsIamFederatedSSMSession";
                    id: string;
                    awsAccessKeyId: string;
                    awsSecretAccessKey: string;
                    awsSessionToken: string;
                    awsLoginUrl: string;
                    federatedArn: string;
                    ec2InstanceId: string;
                    ec2Region: string;
                  }
                | { __typename: "OidcSession" }
                | { __typename: "OpalImpersonationSession" }
                | {
                    __typename: "VaultMongoAtlasSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    initDbName: string;
                  }
                | {
                    __typename: "VaultMongoSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    authenticationDbName: string;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultMySQLMariaDBSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  }
                | {
                    __typename: "VaultPostgresSession";
                    id: string;
                    dbUser: string;
                    dbPassword: string;
                    dbHostname: string;
                    dbPort: number;
                    tlsConfig: {
                      __typename?: "TlsConfig";
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    };
                  };
            }>;
            pendingRequests: Array<{
              __typename?: "Request";
              id: string;
              requesterId: string;
              targetUserId?: string | null;
              targetGroupId?: string | null;
              createdAt: string;
              updatedAt: string;
              status: RequestStatus;
              requestedResources: Array<{
                __typename?: "RequestedResource";
                resourceId: string;
                resource?: {
                  __typename: "Resource";
                  id: string;
                  name: string;
                  remoteName: string;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isDeleted: boolean;
                  isManaged: boolean;
                  resourceType: ResourceType;
                  remoteId: string;
                  iconUrl?: string | null;
                  parentResourceId?: string | null;
                  connectionId: string;
                  createdAt: string;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  parentResource?: {
                    __typename?: "Resource";
                    id: string;
                    name: string;
                  } | null;
                  connection?: {
                    __typename: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                    isDeleted: boolean;
                    metadata?:
                      | {
                          __typename: "ADConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                        }
                      | {
                          __typename: "AwsConnectionMetadata";
                          success?: boolean | null;
                        }
                      | {
                          __typename: "AWSSSOConnectionMetadata";
                          managementAccountId: string;
                          ssoInstanceArn: string;
                          identityStoreId: string;
                          accessPortalUrl: string;
                          awsRegion: string;
                          awsOrganizationEnabled: boolean;
                          awsSsoEnabled: boolean;
                          awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                          awsOrganizationImportSetting: ImportSetting;
                          ec2RoleChainingEnabled: boolean;
                          podRoleChainingEnabled: boolean;
                          cloudtrailEventsSqsUrl: string;
                        }
                      | {
                          __typename: "AzureADConnectionMetadata";
                          tenantId: string;
                          clientId: string;
                          azureInfraEnabled: boolean;
                          eventHubNamespace: string;
                          eventHub: string;
                        }
                      | {
                          __typename: "CustomConnectorConnectionMetadata";
                          identifier: string;
                          baseUrl: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                          supportsGroups: boolean;
                          supportsNestedResources: boolean;
                        }
                      | {
                          __typename: "DuoConnectionMetadata";
                          apiHostname: string;
                        }
                      | {
                          __typename: "GCPConnectionMetadata";
                          pubSubProjectId: string;
                          subscriptionId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GitHubConnectionMetadata";
                          orgName: string;
                        }
                      | {
                          __typename: "GitLabConnectionMetadata";
                          hostName?: string | null;
                          appId: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "GoogleGroupsConnectionMetadata";
                          opalGroupEmail: string;
                          adminUserEmail: string;
                          domain: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "GoogleWorkspaceConnectionMetadata";
                          adminUserEmail: string;
                          customerId: string;
                          serviceAccount: {
                            __typename?: "GCPServiceAccount";
                            type: string;
                            projectId: string;
                            clientEmail: string;
                            clientId: string;
                            authUri: string;
                            tokenUri: string;
                            authProviderX509CertUrl: string;
                            clientX509CertUrl: string;
                          };
                        }
                      | {
                          __typename: "LDAPConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          groupsDistinguishedName: string;
                          usersDistinguishedName: string;
                          groupsUidKey: string;
                        }
                      | {
                          __typename: "MongoAtlasConnectionMetadata";
                          serverHostname: string;
                        }
                      | {
                          __typename: "MongoConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "MySQLMariaDBConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "OktaDirectoryConnectionMetadata";
                          orgUrl: string;
                        }
                      | { __typename: "PagerDutyConnectionMetadata" }
                      | {
                          __typename: "PostgresConnectionMetadata";
                          serverHostname: string;
                          serverPort: number;
                          tlsMode: boolean;
                          tlsCertContent?: string | null;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "PropagationTicketConnectionMetadata";
                          ticketProvider?: {
                            __typename?: "TicketProvider";
                            ticketProvider: ThirdPartyProvider;
                            ticketProjectId: string;
                            ticketProjectName: string;
                          } | null;
                        }
                      | {
                          __typename: "SalesforceConnectionMetadata";
                          email: string;
                          hostname: string;
                        }
                      | {
                          __typename: "SnowflakeConnectionMetadata";
                          organization: string;
                          account: string;
                          locator: string;
                          accountIdentifier: string;
                          accountUrl: string;
                          serviceAccountName: string;
                          serviceAccountRole: string;
                        }
                      | {
                          __typename: "TailscaleConnectionMetadata";
                          tailnet: string;
                        }
                      | {
                          __typename: "TeleportConnectionMetadata";
                          host: string;
                          tlsMode: boolean;
                          tlsCaCertContent?: string | null;
                        }
                      | {
                          __typename: "WorkdayConnectionMetadata";
                          tenantName: string;
                          tenantUrl: string;
                        }
                      | null;
                  } | null;
                  metadata?:
                    | { __typename: "AwsRoleMetadata"; arn: string }
                    | {
                        __typename: "OktaDirectoryAppMetadata";
                        appId: string;
                        appName: string;
                        logoUrl?: string | null;
                      }
                    | {
                        __typename: "OktaRoleMetadata";
                        roleId: string;
                        roleType: string;
                      }
                    | {
                        __typename: "PropagationTicketOwnerMetadata";
                        ownerId?: string | null;
                        owner?: {
                          __typename?: "Owner";
                          id: string;
                          name: string;
                        } | null;
                      }
                    | null;
                } | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                targetUserAccess?: {
                  __typename?: "UserResourceAccess";
                  resourceId: string;
                  userId: string;
                  resourceUsers: Array<{
                    __typename: "ResourceUser";
                    resourceId: string;
                    userId: string;
                    mostRecentSessionEnded?: string | null;
                    source: ResourceUserSource;
                    user?: {
                      __typename?: "User";
                      id: string;
                      email: string;
                      secondaryEmails: Array<string>;
                      firstName: string;
                      lastName: string;
                      fullName: string;
                      avatarUrl: string;
                      isDeleted: boolean;
                      isSystemUser: boolean;
                      createdAt: string;
                      updatedAt: string;
                      organizationId: string;
                      position: string;
                      teamAttr?: string | null;
                      managerId?: string | null;
                      hrIdpStatus?: HrIdpStatus | null;
                      manager?: {
                        __typename?: "User";
                        id: string;
                        email: string;
                        secondaryEmails: Array<string>;
                        firstName: string;
                        lastName: string;
                        fullName: string;
                        avatarUrl: string;
                        isDeleted: boolean;
                        isSystemUser: boolean;
                        position: string;
                        teamAttr?: string | null;
                        hrIdpStatus?: HrIdpStatus | null;
                      } | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    access?: {
                      __typename?: "ResourceUserAccess";
                      resourceId: string;
                      userId: string;
                      accessLevel: {
                        __typename: "ResourceAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                        accessLevelMetadata?: {
                          __typename: "ImpersonationAccessLevelMetadata";
                          avatarUrl: string;
                        } | null;
                      };
                      latestExpiringAccessPoint: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      directAccessPoint?: {
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                      inheritedAccessPoints: Array<{
                        __typename?: "ResourceUserAccessPoint";
                        resourceId: string;
                        userId: string;
                        resourceName: string;
                        resourceType: ResourceType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                      groupUserAccesses: Array<{
                        __typename?: "GroupUserAccess";
                        groupId: string;
                        userId: string;
                        isGroupManaged: boolean;
                        latestExpiringAccessPoint: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        };
                        directAccessPoint?: {
                          __typename?: "GroupUserAccessPoint";
                          groupId: string;
                          userId: string;
                          groupName: string;
                          groupType: GroupType;
                          eventId: string;
                          createdAt: string;
                          expiration?: string | null;
                          supportTicketId?: string | null;
                          source: GroupUserSource;
                          accessLevel?: {
                            __typename: "GroupAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                          } | null;
                          supportTicket?: {
                            __typename?: "SupportTicket";
                            id?: string | null;
                            remoteId: string;
                            url: string;
                            identifier: string;
                            thirdPartyProvider: ThirdPartyProvider;
                            projectName: string;
                          } | null;
                        } | null;
                        indirectAccessPointPaths: Array<{
                          __typename?: "IndirectGroupUserAccessPointPath";
                          startGroupId: string;
                          endGroupId: string;
                          userId: string;
                          resolvedAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          indirectAccessPointPath: Array<{
                            __typename?: "IndirectGroupAccessPoint";
                            groupId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                          }>;
                        }>;
                      }>;
                    } | null;
                    propagationStatus?: {
                      __typename?: "ResourcePropagationStatus";
                      resourceId: string;
                      userId: string;
                      accessLevelRemoteId: string;
                      lastSynced: string;
                      statusCode: PropagationStatusCode;
                      errorMessage?: string | null;
                      taskType: PropagationTaskType;
                      metadata?: {
                        __typename: "PropagationTicketMetadata";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                } | null;
              }>;
            }>;
          };
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
};

export type ConnectResourceSessionFragment = {
  __typename?: "Resource";
  id: string;
  name: string;
  remoteId: string;
  ancestorPathToResource?: string | null;
  resourceType: ResourceType;
  serviceType: ServiceType;
  requireMfaToConnect: boolean;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
    metadata?:
      | { __typename?: "ADConnectionMetadata" }
      | { __typename?: "AwsConnectionMetadata" }
      | { __typename: "AWSSSOConnectionMetadata"; accessPortalUrl: string }
      | { __typename?: "AzureADConnectionMetadata" }
      | { __typename?: "CustomConnectorConnectionMetadata" }
      | { __typename?: "DuoConnectionMetadata" }
      | { __typename?: "GCPConnectionMetadata" }
      | { __typename?: "GitHubConnectionMetadata" }
      | { __typename?: "GitLabConnectionMetadata" }
      | { __typename?: "GoogleGroupsConnectionMetadata" }
      | { __typename?: "GoogleWorkspaceConnectionMetadata" }
      | { __typename?: "LDAPConnectionMetadata" }
      | { __typename?: "MongoAtlasConnectionMetadata" }
      | { __typename?: "MongoConnectionMetadata" }
      | { __typename?: "MySQLMariaDBConnectionMetadata" }
      | { __typename?: "OktaDirectoryConnectionMetadata" }
      | { __typename?: "PagerDutyConnectionMetadata" }
      | { __typename?: "PostgresConnectionMetadata" }
      | { __typename?: "PropagationTicketConnectionMetadata" }
      | { __typename?: "SalesforceConnectionMetadata" }
      | { __typename?: "SnowflakeConnectionMetadata" }
      | { __typename?: "TailscaleConnectionMetadata" }
      | { __typename?: "TeleportConnectionMetadata" }
      | { __typename?: "WorkdayConnectionMetadata" }
      | null;
  } | null;
  currentUserAccess: {
    __typename?: "CurrentUserResourceAccess";
    resourceId: string;
    numAvailableAccessLevels: number;
    resourceUsers: Array<{
      __typename: "ResourceUser";
      resourceId: string;
      userId: string;
      mostRecentSessionEnded?: string | null;
      source: ResourceUserSource;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      access?: {
        __typename?: "ResourceUserAccess";
        resourceId: string;
        userId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        latestExpiringAccessPoint: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        inheritedAccessPoints: Array<{
          __typename?: "ResourceUserAccessPoint";
          resourceId: string;
          userId: string;
          resourceName: string;
          resourceType: ResourceType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
        groupUserAccesses: Array<{
          __typename?: "GroupUserAccess";
          groupId: string;
          userId: string;
          isGroupManaged: boolean;
          latestExpiringAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          directAccessPoint?: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupUserAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            userId: string;
            resolvedAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
        }>;
      } | null;
      propagationStatus?: {
        __typename?: "ResourcePropagationStatus";
        resourceId: string;
        userId: string;
        accessLevelRemoteId: string;
        lastSynced: string;
        statusCode: PropagationStatusCode;
        errorMessage?: string | null;
        taskType: PropagationTaskType;
        metadata?: {
          __typename: "PropagationTicketMetadata";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
      } | null;
    }>;
    activeSessions: Array<{
      __typename?: "Session";
      id: string;
      durationInMinutes?: number | null;
      endTime: string;
      accessLevel?: string | null;
      accessLevelRemoteId?: string | null;
      metadata:
        | {
            __typename: "AwsIamFederatedEksSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            clusterName: string;
            clusterRegion: string;
          }
        | {
            __typename: "AwsIamFederatedRdsSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            federatedArn: string;
            dbInstanceId: string;
            dbPassword: string;
            dbEngine: string;
            dbUser: string;
            dbHostname: string;
            dbPort: number;
            dbName: string;
          }
        | {
            __typename: "AwsIamFederatedRoleSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
          }
        | {
            __typename: "AwsIamFederatedSSMSession";
            id: string;
            awsAccessKeyId: string;
            awsSecretAccessKey: string;
            awsSessionToken: string;
            awsLoginUrl: string;
            federatedArn: string;
            ec2InstanceId: string;
            ec2Region: string;
          }
        | { __typename: "OidcSession" }
        | { __typename: "OpalImpersonationSession" }
        | {
            __typename: "VaultMongoAtlasSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            initDbName: string;
          }
        | {
            __typename: "VaultMongoSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            authenticationDbName: string;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultMySQLMariaDBSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          }
        | {
            __typename: "VaultPostgresSession";
            id: string;
            dbUser: string;
            dbPassword: string;
            dbHostname: string;
            dbPort: number;
            tlsConfig: {
              __typename?: "TlsConfig";
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            };
          };
    }>;
    pendingRequests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      createdAt: string;
      updatedAt: string;
      status: RequestStatus;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resourceId: string;
        resource?: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        } | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        targetUserAccess?: {
          __typename?: "UserResourceAccess";
          resourceId: string;
          userId: string;
          resourceUsers: Array<{
            __typename: "ResourceUser";
            resourceId: string;
            userId: string;
            mostRecentSessionEnded?: string | null;
            source: ResourceUserSource;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        } | null;
      }>;
    }>;
  };
  metadata?:
    | { __typename: "AwsRoleMetadata"; arn: string }
    | {
        __typename: "OktaDirectoryAppMetadata";
        appId: string;
        appName: string;
        logoUrl?: string | null;
      }
    | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
    | {
        __typename: "PropagationTicketOwnerMetadata";
        ownerId?: string | null;
        owner?: { __typename?: "Owner"; id: string; name: string } | null;
      }
    | null;
};

export type ConnectionAppIconQueryVariables = Exact<{
  connectionId: Scalars["ConnectionId"];
}>;

export type ConnectionAppIconQuery = {
  __typename?: "Query";
  connection:
    | { __typename?: "ConnectionNotFoundError"; message: string }
    | {
        __typename?: "ConnectionResult";
        connection: {
          __typename?: "Connection";
          id: string;
          iconUrl?: string | null;
        };
      };
};

export type ConnectionUsersRowQueryVariables = Exact<{
  input: ConnectionInput;
}>;

export type ConnectionUsersRowQuery = {
  __typename?: "Query";
  connection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | {
        __typename: "ConnectionResult";
        connection: {
          __typename: "Connection";
          id: string;
          name: string;
          description: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          visibility: Visibility;
          importVisibility: Visibility;
          importSetting: ImportSetting;
          autoImportGroupResources: boolean;
          numUsers: number;
          webhookEnabled: boolean;
          iconUrl?: string | null;
          connectionUsers: Array<{
            __typename: "ConnectionUser";
            connectionId: string;
            userId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              createdAt: string;
              updatedAt: string;
              organizationId: string;
              position: string;
              teamAttr?: string | null;
              managerId?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
              manager?: {
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              } | null;
            } | null;
            propagationStatus?: {
              __typename?: "ConnectionUserPropagationStatus";
              connectionId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
            } | null;
          }>;
          adminOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
          visibilityGroups: Array<{
            __typename?: "ConnectionVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup?: { __typename?: "Group"; name: string } | null;
          }>;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
          childrenDefaultConfigTemplate?: {
            __typename?: "ConfigurationTemplate";
            id: string;
            name: string;
          } | null;
          importNotificationOwner?: {
            __typename?: "Owner";
            id: string;
            name: string;
          } | null;
        };
      };
};

export type EventsTableQueryVariables = Exact<{
  input: EventsInput;
}>;

export type EventsTableQuery = {
  __typename?: "Query";
  events:
    | {
        __typename: "EventsResult";
        cursor?: string | null;
        events: Array<{
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          numSubEvents: number;
          numErrorSubEvents: number;
          actorUser?: {
            __typename?: "User";
            fullName: string;
            avatarUrl: string;
          } | null;
        }>;
      }
    | { __typename: "InvalidObjectIDFilterError" };
};

export type EventForTableFragment = {
  __typename?: "Event";
  id: string;
  createdAt: string;
  actorUserId: string;
  actorIP?: string | null;
  actorUserAgent?: string | null;
  eventType: EventType;
  source: ConnectionType;
  numSubEvents: number;
  numErrorSubEvents: number;
  actorUser?: {
    __typename?: "User";
    fullName: string;
    avatarUrl: string;
  } | null;
};

export type EventPreviewQueryVariables = Exact<{
  id: Scalars["EventId"];
}>;

export type EventPreviewQuery = {
  __typename?: "Query";
  event:
    | { __typename: "EventNotFoundError"; message: string }
    | {
        __typename: "EventResult";
        event: {
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          numSubEvents: number;
          numErrorSubEvents: number;
          actorUser?: {
            __typename?: "User";
            fullName: string;
            avatarUrl: string;
          } | null;
        };
      };
};

export type EventDetailFragment = {
  __typename?: "Event";
  id: string;
  createdAt: string;
  actorUserId: string;
  actorIP?: string | null;
  actorUserAgent?: string | null;
  eventType: EventType;
  source: ConnectionType;
  numSubEvents: number;
  actorUser?: {
    __typename?: "User";
    fullName: string;
    avatarUrl: string;
  } | null;
  paginatedSubEvents: {
    __typename?: "PaginatedSubEventsOutput";
    cursor?: string | null;
    totalNumSubEvents: number;
    subEvents: Array<{
      __typename?: "SubEvent";
      id: string;
      createdAt: string;
      subEventType: EventType;
      objectName?: string | null;
      secondaryObjectName?: string | null;
      tertiaryObjectName?: string | null;
      object4Name?: string | null;
      metadata?: string | null;
      objectId: {
        __typename?: "EntityIdTuple";
        entityId: string;
        entityType: EntityType;
      };
      object?: {
        __typename?: "EntityDisplayInfo";
        resourceType?: ResourceType | null;
        groupType?: GroupType | null;
        connectionType?: ConnectionType | null;
        avatarURL?: string | null;
        provider?: ThirdPartyProvider | null;
      } | null;
      secondaryObjectId?: {
        __typename?: "EntityIdTuple";
        entityId: string;
        entityType: EntityType;
      } | null;
      secondaryObject?: {
        __typename?: "EntityDisplayInfo";
        resourceType?: ResourceType | null;
        groupType?: GroupType | null;
        connectionType?: ConnectionType | null;
        avatarURL?: string | null;
        provider?: ThirdPartyProvider | null;
      } | null;
      tertiaryObjectId?: {
        __typename?: "EntityIdTuple";
        entityId: string;
        entityType: EntityType;
      } | null;
      tertiaryObject?: {
        __typename?: "EntityDisplayInfo";
        resourceType?: ResourceType | null;
        groupType?: GroupType | null;
        connectionType?: ConnectionType | null;
        avatarURL?: string | null;
        provider?: ThirdPartyProvider | null;
      } | null;
      object4Id?: {
        __typename?: "EntityIdTuple";
        entityId: string;
        entityType: EntityType;
      } | null;
      object4?: {
        __typename?: "EntityDisplayInfo";
        resourceType?: ResourceType | null;
        groupType?: GroupType | null;
        connectionType?: ConnectionType | null;
        avatarURL?: string | null;
        provider?: ThirdPartyProvider | null;
      } | null;
    }>;
  };
};

export type EventDetailQueryVariables = Exact<{
  id: Scalars["EventId"];
  subEvents?: InputMaybe<PaginatedSubEventsInput>;
}>;

export type EventDetailQuery = {
  __typename?: "Query";
  event:
    | { __typename: "EventNotFoundError"; message: string }
    | {
        __typename: "EventResult";
        event: {
          __typename?: "Event";
          id: string;
          createdAt: string;
          actorUserId: string;
          actorIP?: string | null;
          actorUserAgent?: string | null;
          eventType: EventType;
          source: ConnectionType;
          numSubEvents: number;
          actorUser?: {
            __typename?: "User";
            fullName: string;
            avatarUrl: string;
          } | null;
          paginatedSubEvents: {
            __typename?: "PaginatedSubEventsOutput";
            cursor?: string | null;
            totalNumSubEvents: number;
            subEvents: Array<{
              __typename?: "SubEvent";
              id: string;
              createdAt: string;
              subEventType: EventType;
              objectName?: string | null;
              secondaryObjectName?: string | null;
              tertiaryObjectName?: string | null;
              object4Name?: string | null;
              metadata?: string | null;
              objectId: {
                __typename?: "EntityIdTuple";
                entityId: string;
                entityType: EntityType;
              };
              object?: {
                __typename?: "EntityDisplayInfo";
                resourceType?: ResourceType | null;
                groupType?: GroupType | null;
                connectionType?: ConnectionType | null;
                avatarURL?: string | null;
                provider?: ThirdPartyProvider | null;
              } | null;
              secondaryObjectId?: {
                __typename?: "EntityIdTuple";
                entityId: string;
                entityType: EntityType;
              } | null;
              secondaryObject?: {
                __typename?: "EntityDisplayInfo";
                resourceType?: ResourceType | null;
                groupType?: GroupType | null;
                connectionType?: ConnectionType | null;
                avatarURL?: string | null;
                provider?: ThirdPartyProvider | null;
              } | null;
              tertiaryObjectId?: {
                __typename?: "EntityIdTuple";
                entityId: string;
                entityType: EntityType;
              } | null;
              tertiaryObject?: {
                __typename?: "EntityDisplayInfo";
                resourceType?: ResourceType | null;
                groupType?: GroupType | null;
                connectionType?: ConnectionType | null;
                avatarURL?: string | null;
                provider?: ThirdPartyProvider | null;
              } | null;
              object4Id?: {
                __typename?: "EntityIdTuple";
                entityId: string;
                entityType: EntityType;
              } | null;
              object4?: {
                __typename?: "EntityDisplayInfo";
                resourceType?: ResourceType | null;
                groupType?: GroupType | null;
                connectionType?: ConnectionType | null;
                avatarURL?: string | null;
                provider?: ThirdPartyProvider | null;
              } | null;
            }>;
          };
        };
      };
};

export type GroupBindingGroupWithUsersFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  connectionId: string;
  groupUsers: Array<{
    __typename?: "GroupUser";
    groupId: string;
    userId: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      fullName: string;
      avatarUrl: string;
    } | null;
  }>;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type GroupBindingDetailGroupUserFragment = {
  __typename?: "GroupUser";
  groupId: string;
  userId: string;
  user?: {
    __typename?: "User";
    id: string;
    email: string;
    fullName: string;
    avatarUrl: string;
  } | null;
};

export type GroupBindingDetailFragment = {
  __typename?: "GroupBinding";
  id: string;
  sourceGroupId: string;
  sourceGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupUsers: Array<{
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
  groups: Array<{
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupUsers: Array<{
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  }>;
};

export type GroupBindingDetailsQueryVariables = Exact<{
  input: Array<Scalars["GroupBindingId"]> | Scalars["GroupBindingId"];
}>;

export type GroupBindingDetailsQuery = {
  __typename?: "Query";
  groupBindings: {
    __typename?: "GroupBindingsOutput";
    groupBindings: Array<{
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        groupUsers: Array<{
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            fullName: string;
            avatarUrl: string;
          } | null;
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        groupUsers: Array<{
          __typename?: "GroupUser";
          groupId: string;
          userId: string;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            fullName: string;
            avatarUrl: string;
          } | null;
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
    }>;
  };
};

export type GroupBindingSuggestionFragment = {
  __typename?: "GroupBindingSuggestion";
  id: string;
  primaryGroupId: string;
  secondaryGroupId: string;
  primaryGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
  secondaryGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
};

export type GroupBindingSuggestionsTableQueryVariables = Exact<{
  input: GroupBindingSuggestionsInput;
}>;

export type GroupBindingSuggestionsTableQuery = {
  __typename?: "Query";
  groupBindingSuggestions: {
    __typename?: "GroupBindingSuggestionsOutput";
    totalNumItems: number;
    cursor?: string | null;
    groupBindingSuggestions: Array<{
      __typename?: "GroupBindingSuggestion";
      id: string;
      primaryGroupId: string;
      secondaryGroupId: string;
      primaryGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      secondaryGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
    }>;
  };
};

export type CreateGroupBindingsMutationVariables = Exact<{
  input: CreateGroupBindingsInput;
}>;

export type CreateGroupBindingsMutation = {
  __typename?: "Mutation";
  createGroupBindings:
    | {
        __typename?: "CreateGroupBindingsResult";
        groupBindings: Array<{
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          createdAt: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          } | null;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          }>;
          createdByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        }>;
      }
    | {
        __typename?: "GroupAlreadyBelongsToBindingError";
        message: string;
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          createdAt: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          } | null;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          }>;
          createdByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
      }
    | { __typename?: "GroupBindingHasNoGroupsError"; message: string };
};

export type DismissGroupBindingSuggestionsMutationVariables = Exact<{
  input: DismissGroupBindingSuggestionsInput;
}>;

export type DismissGroupBindingSuggestionsMutation = {
  __typename?: "Mutation";
  dismissGroupBindingSuggestions:
    | { __typename?: "DismissGroupBindingSuggestionsResult"; success: boolean }
    | { __typename?: "GroupBindingSuggestionNotFoundError"; message: string };
};

export type GroupBindingsCountsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupBindingsCountsQuery = {
  __typename?: "Query";
  groupBindings: { __typename?: "GroupBindingsOutput"; totalNumItems: number };
  groupBindingSuggestions: {
    __typename?: "GroupBindingSuggestionsOutput";
    totalNumItems: number;
  };
};

export type GroupBindingGroupPreviewFragment = {
  __typename?: "Group";
  id: string;
  name: string;
  groupType: GroupType;
  connectionId: string;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type GroupBindingFragment = {
  __typename?: "GroupBinding";
  id: string;
  sourceGroupId: string;
  createdAt: string;
  sourceGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
  groups: Array<{
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  }>;
  createdByUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
};

export type GroupBindingTinyFragment = {
  __typename?: "GroupBinding";
  id: string;
  sourceGroupId: string;
  groups: Array<{
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
  }>;
};

export type GroupBindingsTableQueryVariables = Exact<{
  input: GroupBindingsInput;
}>;

export type GroupBindingsTableQuery = {
  __typename?: "Query";
  groupBindings: {
    __typename?: "GroupBindingsOutput";
    totalNumItems: number;
    cursor?: string | null;
    groupBindings: Array<{
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      createdAt: string;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      } | null;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
      }>;
      createdByUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export type GroupBindingsFiltersQueryVariables = Exact<{
  groupIds: Array<Scalars["GroupId"]> | Scalars["GroupId"];
  connectionIds?: InputMaybe<
    Array<Scalars["ConnectionId"]> | Scalars["ConnectionId"]
  >;
}>;

export type GroupBindingsFiltersQuery = {
  __typename?: "Query";
  connections: {
    __typename?: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    }>;
  };
  groups: {
    __typename?: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
    }>;
  };
  groupTypesWithCounts: {
    __typename?: "GroupTypesWithCountsResult";
    groupTypesWithCounts: Array<{
      __typename?: "GroupTypeWithCount";
      connectionId?: string | null;
      groupType: GroupType;
      numGroups: number;
      connection?: { __typename?: "Connection"; name: string } | null;
    }>;
  };
};

export type GroupBindingSuggestionDetailFragment = {
  __typename?: "GroupBindingSuggestion";
  primaryGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupUsers: Array<{
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
  secondaryGroup?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
    connectionId: string;
    groupUsers: Array<{
      __typename?: "GroupUser";
      groupId: string;
      userId: string;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        fullName: string;
        avatarUrl: string;
      } | null;
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  } | null;
};

export type GroupBindingCompareModalQueryVariables = Exact<{
  input: Scalars["GroupBindingSuggestionId"];
}>;

export type GroupBindingCompareModalQuery = {
  __typename?: "Query";
  groupBindingSuggestion:
    | {
        __typename?: "GroupBindingSuggestion";
        primaryGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              fullName: string;
              avatarUrl: string;
            } | null;
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        secondaryGroup?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
          groupUsers: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            user?: {
              __typename?: "User";
              id: string;
              email: string;
              fullName: string;
              avatarUrl: string;
            } | null;
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
      }
    | { __typename?: "GroupBindingSuggestionNotFoundError"; message: string };
};

export type DeleteGroupBindingsMutationVariables = Exact<{
  input: DeleteGroupBindingsInput;
}>;

export type DeleteGroupBindingsMutation = {
  __typename?: "Mutation";
  deleteGroupBindings:
    | { __typename?: "DeleteGroupBindingsResult"; success: boolean }
    | { __typename?: "GroupBindingNotFoundError"; message: string };
};

export type GroupBindingGroupUserIdsQueryVariables = Exact<{
  input: Array<Scalars["GroupId"]> | Scalars["GroupId"];
}>;

export type GroupBindingGroupUserIdsQuery = {
  __typename?: "Query";
  groups: {
    __typename?: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
      groupUsers: Array<{
        __typename?: "GroupUser";
        groupId: string;
        userId: string;
        user?: {
          __typename?: "User";
          id: string;
          email: string;
          fullName: string;
          avatarUrl: string;
        } | null;
      }>;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    }>;
  };
};

export type UpdateGroupBindingsMutationVariables = Exact<{
  input: UpdateGroupBindingsInput;
}>;

export type UpdateGroupBindingsMutation = {
  __typename?: "Mutation";
  updateGroupBindings:
    | {
        __typename?: "GroupAlreadyBelongsToBindingError";
        message: string;
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connectionId: string;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          createdAt: string;
          sourceGroup?: {
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          } | null;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          }>;
          createdByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
        } | null;
      }
    | { __typename?: "GroupBindingHasNoGroupsError"; message: string }
    | { __typename?: "GroupBindingNotFoundError"; message: string }
    | {
        __typename?: "UpdateGroupBindingsResult";
        groupBindings: Array<{ __typename?: "GroupBinding"; id: string }>;
      };
};

export type RemoveGroupResourcesAndGroupsMutationVariables = Exact<{
  groupResourcesToRemove: RemoveGroupResourcesInput;
  groupGroupIdsToRemove:
    | Array<Scalars["GroupGroupId"]>
    | Scalars["GroupGroupId"];
}>;

export type RemoveGroupResourcesAndGroupsMutation = {
  __typename?: "Mutation";
  removeGroupGroups:
    | { __typename?: "CallToWebhookFailedError"; message: string }
    | { __typename?: "GroupNotFoundError"; message: string }
    | { __typename?: "RemoveGroupGroupsResult"; taskId: string }
    | { __typename?: "UserFacingError"; message: string };
  removeGroupResources:
    | { __typename?: "CallToWebhookFailedError"; message: string }
    | { __typename?: "GroupNotFoundError"; message: string }
    | { __typename?: "RemoveGroupResourcesResult"; taskId: string }
    | { __typename?: "UserFacingError"; message: string };
};

export type MetricsFiltersQueryVariables = Exact<{
  resourceIds: Array<Scalars["ResourceId"]> | Scalars["ResourceId"];
  groupIds: Array<Scalars["GroupId"]> | Scalars["GroupId"];
}>;

export type MetricsFiltersQuery = {
  __typename?: "Query";
  connections: {
    __typename?: "ConnectionsResult";
    connections: Array<{
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    }>;
  };
  tags: {
    __typename?: "TagsResult";
    tags: Array<{
      __typename: "Tag";
      id: string;
      createdAt: string;
      creatorUserId: string;
      key: string;
      value?: string | null;
      isStandardAttribute: boolean;
    }>;
  };
  resources: {
    __typename?: "ResourcesResult";
    resources: Array<{
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
    }>;
  };
  groups: {
    __typename?: "GroupsResult";
    groups: Array<{
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connectionId: string;
    }>;
  };
};

export type BreakGlassUsersCountsQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type BreakGlassUsersCountsQuery = {
  __typename?: "Query";
  breakGlassUsersCounts: {
    __typename?: "BreakGlassUsersCountsResult";
    lineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
  };
};

export type BreakGlassUsersByGroupQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type BreakGlassUsersByGroupQuery = {
  __typename?: "Query";
  breakGlassUsersByGroup: {
    __typename?: "BreakGlassUsersByGroupResult";
    barData: Array<{
      __typename?: "BarGraphData";
      label: string;
      data: Array<{
        __typename?: "LineGraphData";
        timestamp: string;
        value: number;
      }>;
    }>;
  };
};

export type BarGraphDataFragment = {
  __typename?: "BarGraphData";
  label: string;
  data: Array<{
    __typename?: "LineGraphData";
    timestamp: string;
    value: number;
  }>;
};

export type RequestMetricsQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type RequestMetricsQuery = {
  __typename?: "Query";
  requestMetrics: {
    __typename?: "RequestMetricsResult";
    totalApproved: number;
    totalCanceled: number;
    totalDenied: number;
    approvedLineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
    canceledLineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
    deniedLineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
  };
};

export type RequestApprovalTimesQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type RequestApprovalTimesQuery = {
  __typename?: "Query";
  requestApprovalTimes: {
    __typename?: "RequestApprovalTimesResult";
    lineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
  };
};

export type AccessGrantedTimesQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type AccessGrantedTimesQuery = {
  __typename?: "Query";
  accessGrantedTimes: {
    __typename?: "AccessGrantedTimesResult";
    lineData: Array<{
      __typename?: "LineGraphData";
      timestamp: string;
      value: number;
    }>;
  };
};

export type MostRequestedResourcesQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type MostRequestedResourcesQuery = {
  __typename?: "Query";
  mostRequestedResources: {
    __typename?: "MostRequestedItemsResult";
    barData: Array<{
      __typename?: "BarGraphData";
      label: string;
      data: Array<{
        __typename?: "LineGraphData";
        timestamp: string;
        value: number;
      }>;
    }>;
  };
};

export type MostRequestedGroupsQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type MostRequestedGroupsQuery = {
  __typename?: "Query";
  mostRequestedGroups: {
    __typename?: "MostRequestedItemsResult";
    barData: Array<{
      __typename?: "BarGraphData";
      label: string;
      data: Array<{
        __typename?: "LineGraphData";
        timestamp: string;
        value: number;
      }>;
    }>;
  };
};

export type ClosedRequestMetricsQueryVariables = Exact<{
  input: MetricsInput;
}>;

export type ClosedRequestMetricsQuery = {
  __typename?: "Query";
  closedRequestMetrics: {
    __typename?: "ClosedRequestMetricsResult";
    numAdminApproved: number;
    numAutoApproved: number;
    numManualApproved: number;
    numCanceled: number;
    numDenied: number;
  };
};

export type LineGraphDataFragment = {
  __typename?: "LineGraphData";
  timestamp: string;
  value: number;
};

export type OwnersTableQueryVariables = Exact<{
  input: OwnersInput;
}>;

export type OwnersTableQuery = {
  __typename?: "Query";
  owners: {
    __typename: "OwnersResult";
    totalNumOwners: number;
    cursor?: string | null;
    owners: Array<{
      __typename?: "Owner";
      id: string;
      name: string;
      createdAt: string;
      ownerUsers: Array<{ __typename?: "OwnerUser"; userId: string }>;
      sourceGroup?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
      } | null;
      ownedResources: Array<{
        __typename?: "OwnedResource";
        isAdmin: boolean;
        isReviewer: boolean;
      }>;
      ownedGroups: Array<{
        __typename?: "OwnedGroup";
        isAdmin: boolean;
        isReviewer: boolean;
      }>;
      ownedConfigurationTemplates: Array<{
        __typename?: "OwnedConfigurationTemplate";
        isAdmin: boolean;
        isReviewer: boolean;
      }>;
    }>;
  };
};

export type RiskScoreGraphDataQueryVariables = Exact<{ [key: string]: never }>;

export type RiskScoreGraphDataQuery = {
  __typename?: "Query";
  riskScoreGraphData: {
    __typename?: "RiskScoreGraphDataResult";
    numEntitiesPerScore: Array<number>;
    granularity: number;
    overallRiskScore: number;
  };
};

export type EntitiesForRiskScoreRangeQueryVariables = Exact<{
  minRiskScore: Scalars["Int"];
  maxRiskScore: Scalars["Int"];
}>;

export type EntitiesForRiskScoreRangeQuery = {
  __typename?: "Query";
  entitiesForRiskScoreRange: {
    __typename?: "EntitiesForRiskScoreRangeResult";
    entities: Array<
      | {
          __typename?: "Group";
          name: string;
          groupType: GroupType;
          groupId: string;
        }
      | {
          __typename?: "Resource";
          name: string;
          resourceType: ResourceType;
          resourceId: string;
        }
    >;
  };
};

export type RecommendationsMetricsGraphQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecommendationsMetricsGraphQuery = {
  __typename?: "Query";
  recommendationsMetricsGraph: {
    __typename?: "RecommendationsMetricsGraph";
    perpetualAccessGraph: Array<{
      __typename?: "RecommendationsMetricData";
      metricType: RecommendationsMetricType;
      value: number;
      date: string;
    }>;
    timeboundAccessGraph: Array<{
      __typename?: "RecommendationsMetricData";
      metricType: RecommendationsMetricType;
      value: number;
      date: string;
    }>;
  };
};

export type TopBulkSuggestionsQueryVariables = Exact<{
  appId?: InputMaybe<AppIdInput>;
  minRiskScore?: InputMaybe<Scalars["Int"]>;
  maxRiskScore?: InputMaybe<Scalars["Int"]>;
  tagID?: InputMaybe<TagInput>;
  riskTypes?: InputMaybe<
    | Array<InputMaybe<RecommendationsSubscoreType>>
    | InputMaybe<RecommendationsSubscoreType>
  >;
  groupType?: InputMaybe<GroupType>;
  resourceType?: InputMaybe<ResourceType>;
}>;

export type TopBulkSuggestionsQuery = {
  __typename?: "Query";
  topBulkSuggestions: {
    __typename?: "RecommendationsBulkSuggestionsResult";
    suggestions: Array<{
      __typename?: "RecommendationsBulkSuggestion";
      type: RecommendationsSubscoreType;
      targetUserCount: number;
      totalUserCount: number;
      entity:
        | {
            __typename?: "Group";
            name: string;
            groupType: GroupType;
            iconUrl?: string | null;
            groupId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          }
        | {
            __typename?: "Resource";
            name: string;
            resourceType: ResourceType;
            iconUrl?: string | null;
            resourceId: string;
            connection?: {
              __typename?: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
            } | null;
          };
    }>;
  };
};

export type AppThreatCountsQueryVariables = Exact<{
  appCategory?: InputMaybe<AppCategory>;
}>;

export type AppThreatCountsQuery = {
  __typename?: "Query";
  apps: {
    __typename?: "AppsOutput";
    apps: Array<{
      __typename?: "App";
      id: string;
      name: string;
      app:
        | {
            __typename?: "ConnectionApp";
            connectionId: string;
            connectionType: ConnectionType;
          }
        | {
            __typename?: "OktaResourceApp";
            resourceId: string;
            iconUrl?: string | null;
          };
      threats: Array<{
        __typename?: "RecommendationsEntityMetadata";
        riskScore: number;
        suggestionType: RecommendationsSubscoreType;
        entityKey: {
          __typename?: "RecommendationsEntityKeyOutput";
          entityId: string;
          entityType: RecommendationsEntityType;
        };
      }>;
    }>;
  };
};

export type AppThreatPreviewFragment = {
  __typename?: "App";
  id: string;
  name: string;
  app:
    | {
        __typename?: "ConnectionApp";
        connectionId: string;
        connectionType: ConnectionType;
      }
    | {
        __typename?: "OktaResourceApp";
        resourceId: string;
        iconUrl?: string | null;
      };
  threats: Array<{
    __typename?: "RecommendationsEntityMetadata";
    riskScore: number;
    suggestionType: RecommendationsSubscoreType;
    entityKey: {
      __typename?: "RecommendationsEntityKeyOutput";
      entityId: string;
      entityType: RecommendationsEntityType;
    };
  }>;
};

export type EntityForRemediationQueryVariables = Exact<{
  entityKey: RecommendationsEntityKey;
}>;

export type EntityForRemediationQuery = {
  __typename?: "Query";
  entityForRemediation:
    | {
        __typename?: "Group";
        name: string;
        groupType: GroupType;
        riskScore?: number | null;
        groupId: string;
        connection?: {
          __typename?: "Connection";
          connectionType: ConnectionType;
          name: string;
          id: string;
        } | null;
        groupUsers: Array<{
          __typename?: "GroupUser";
          lastUsedAt?: string | null;
          provisionSource: GroupUserProvisionSource;
          groupId: string;
          userId: string;
          source: GroupUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          access?: {
            __typename?: "GroupUserAccess";
            groupId: string;
            userId: string;
            isGroupManaged: boolean;
            latestExpiringAccessPoint: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupUserAccessPoint";
              groupId: string;
              userId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              source: GroupUserSource;
              accessLevel?: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              } | null;
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupUserAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              userId: string;
              resolvedAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              indirectAccessPointPath: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "GroupPropagationStatus";
            groupId: string;
            userId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            ticketPropagation?: {
              __typename: "GroupUserTicketPropagation";
              resourceTickets: Array<{
                __typename?: "ResourceTicketPropagation";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
            } | null;
          } | null;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
        }>;
        groupResources: Array<{
          __typename?: "GroupResource";
          lastUsedAt?: string | null;
          groupId: string;
          resourceId: string;
          resource?: {
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access: {
            __typename?: "GroupResourceAccess";
            groupId: string;
            resourceId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "GroupResourceAccessPoint";
              groupId: string;
              resourceId: string;
              resourceName: string;
              resourceType: ResourceType;
              groupName: string;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "GroupResourceAccessPoint";
              groupId: string;
              resourceId: string;
              resourceName: string;
              resourceType: ResourceType;
              groupName: string;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            indirectAccessPointPaths: Array<{
              __typename?: "IndirectGroupResourceAccessPointPath";
              startGroupId: string;
              endGroupId: string;
              resourceId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              resolvedAccessPoint?: {
                __typename?: "GroupResourceAccessPoint";
                groupId: string;
                resourceId: string;
                resourceName: string;
                resourceType: ResourceType;
                groupName: string;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPath?: Array<{
                __typename?: "IndirectGroupAccessPoint";
                groupId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
              }> | null;
            }>;
            latestExpiringAccessPoint: {
              __typename?: "GroupResourceAccessPoint";
              groupId: string;
              resourceId: string;
              resourceName: string;
              resourceType: ResourceType;
              groupName: string;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
          };
          propagationStatus?: {
            __typename?: "GroupResourcePropagationStatus";
            groupId: string;
            resourceId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
          } | null;
        }>;
      }
    | {
        __typename?: "Resource";
        name: string;
        resourceType: ResourceType;
        riskScore?: number | null;
        iconUrl?: string | null;
        resourceId: string;
        connection?: {
          __typename?: "Connection";
          connectionType: ConnectionType;
          id: string;
          name: string;
        } | null;
        resourceUsers: Array<{
          __typename: "ResourceUser";
          provisionSource: ResourceUserProvisionSource;
          resourceId: string;
          userId: string;
          mostRecentSessionEnded?: string | null;
          source: ResourceUserSource;
          user?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          } | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          access?: {
            __typename?: "ResourceUserAccess";
            resourceId: string;
            userId: string;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            latestExpiringAccessPoint: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            directAccessPoint?: {
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
            inheritedAccessPoints: Array<{
              __typename?: "ResourceUserAccessPoint";
              resourceId: string;
              userId: string;
              resourceName: string;
              resourceType: ResourceType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            }>;
            groupUserAccesses: Array<{
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            }>;
          } | null;
          propagationStatus?: {
            __typename?: "ResourcePropagationStatus";
            resourceId: string;
            userId: string;
            accessLevelRemoteId: string;
            lastSynced: string;
            statusCode: PropagationStatusCode;
            errorMessage?: string | null;
            taskType: PropagationTaskType;
            metadata?: {
              __typename: "PropagationTicketMetadata";
              ticket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            } | null;
          } | null;
        }>;
      };
};

export type SuggestedAccessToRemediateForEntityQueryVariables = Exact<{
  entityKey: RecommendationsEntityKey;
  type: RecommendationsSubscoreType;
}>;

export type SuggestedAccessToRemediateForEntityQuery = {
  __typename?: "Query";
  suggestedAccessToRemediateForEntity: {
    __typename?: "SuggestedAccessList";
    type: RecommendationsSubscoreType;
    userRoleKeys?: Array<{
      __typename?: "UserRoleKey";
      userId: string;
      accessLevelRemoteId?: string | null;
    }> | null;
    resourceRoleKeys?: Array<{
      __typename?: "ResourceRoleKey";
      resourceId: string;
      accessLevelRemoteId?: string | null;
    }> | null;
  };
};

export type ResourceForRemediationFragment = {
  __typename?: "Resource";
  name: string;
  resourceType: ResourceType;
  riskScore?: number | null;
  iconUrl?: string | null;
  resourceId: string;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
    id: string;
    name: string;
  } | null;
  resourceUsers: Array<{
    __typename: "ResourceUser";
    provisionSource: ResourceUserProvisionSource;
    resourceId: string;
    userId: string;
    mostRecentSessionEnded?: string | null;
    source: ResourceUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access?: {
      __typename?: "ResourceUserAccess";
      resourceId: string;
      userId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      latestExpiringAccessPoint: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "ResourceUserAccessPoint";
        resourceId: string;
        userId: string;
        resourceName: string;
        resourceType: ResourceType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      groupUserAccesses: Array<{
        __typename?: "GroupUserAccess";
        groupId: string;
        userId: string;
        isGroupManaged: boolean;
        latestExpiringAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        directAccessPoint?: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPaths: Array<{
          __typename?: "IndirectGroupUserAccessPointPath";
          startGroupId: string;
          endGroupId: string;
          userId: string;
          resolvedAccessPoint: {
            __typename?: "GroupUserAccessPoint";
            groupId: string;
            userId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            source: GroupUserSource;
            accessLevel?: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            } | null;
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
          indirectAccessPointPath: Array<{
            __typename?: "IndirectGroupAccessPoint";
            groupId: string;
            groupName: string;
            groupType: GroupType;
            eventId: string;
            createdAt: string;
          }>;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "ResourcePropagationStatus";
      resourceId: string;
      userId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      metadata?: {
        __typename: "PropagationTicketMetadata";
        ticket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
    } | null;
  }>;
};

export type GroupForRemediationFragment = {
  __typename?: "Group";
  name: string;
  groupType: GroupType;
  riskScore?: number | null;
  groupId: string;
  connection?: {
    __typename?: "Connection";
    connectionType: ConnectionType;
    name: string;
    id: string;
  } | null;
  groupUsers: Array<{
    __typename?: "GroupUser";
    lastUsedAt?: string | null;
    provisionSource: GroupUserProvisionSource;
    groupId: string;
    userId: string;
    source: GroupUserSource;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      updatedAt: string;
      organizationId: string;
      position: string;
      teamAttr?: string | null;
      managerId?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    } | null;
    access?: {
      __typename?: "GroupUserAccess";
      groupId: string;
      userId: string;
      isGroupManaged: boolean;
      latestExpiringAccessPoint: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupUserAccessPoint";
        groupId: string;
        userId: string;
        groupName: string;
        groupType: GroupType;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        source: GroupUserSource;
        accessLevel?: {
          __typename: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupUserAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        userId: string;
        resolvedAccessPoint: {
          __typename?: "GroupUserAccessPoint";
          groupId: string;
          userId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          source: GroupUserSource;
          accessLevel?: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          } | null;
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        };
        indirectAccessPointPath: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }>;
      }>;
    } | null;
    propagationStatus?: {
      __typename?: "GroupPropagationStatus";
      groupId: string;
      userId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
      ticketPropagation?: {
        __typename: "GroupUserTicketPropagation";
        resourceTickets: Array<{
          __typename?: "ResourceTicketPropagation";
          ticket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        }>;
      } | null;
    } | null;
    accessLevel?: {
      __typename: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  }>;
  groupResources: Array<{
    __typename?: "GroupResource";
    lastUsedAt?: string | null;
    groupId: string;
    resourceId: string;
    resource?: {
      __typename: "Resource";
      id: string;
      name: string;
      remoteName: string;
      serviceType: ServiceType;
      visibility: Visibility;
      isDeleted: boolean;
      isManaged: boolean;
      resourceType: ResourceType;
      remoteId: string;
      iconUrl?: string | null;
      parentResourceId?: string | null;
      connectionId: string;
      createdAt: string;
      parentResource?: {
        __typename?: "Resource";
        id: string;
        name: string;
      } | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      visibilityGroups: Array<{
        __typename?: "ConfigurationVisibilityGroup";
        visibilityGroupId: string;
        visibilityGroup: { __typename?: "Group"; name: string };
      }>;
      metadata?:
        | { __typename: "AwsRoleMetadata"; arn: string }
        | {
            __typename: "OktaDirectoryAppMetadata";
            appId: string;
            appName: string;
            logoUrl?: string | null;
          }
        | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
        | {
            __typename: "PropagationTicketOwnerMetadata";
            ownerId?: string | null;
            owner?: { __typename?: "Owner"; id: string; name: string } | null;
          }
        | null;
    } | null;
    accessLevel: {
      __typename: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
      accessLevelMetadata?: {
        __typename: "ImpersonationAccessLevelMetadata";
        avatarUrl: string;
      } | null;
    };
    access: {
      __typename?: "GroupResourceAccess";
      groupId: string;
      resourceId: string;
      accessLevel: {
        __typename: "ResourceAccessLevel";
        accessLevelName: string;
        accessLevelRemoteId: string;
        accessLevelMetadata?: {
          __typename: "ImpersonationAccessLevelMetadata";
          avatarUrl: string;
        } | null;
      };
      directAccessPoint?: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      } | null;
      inheritedAccessPoints: Array<{
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      }>;
      indirectAccessPointPaths: Array<{
        __typename?: "IndirectGroupResourceAccessPointPath";
        startGroupId: string;
        endGroupId: string;
        resourceId: string;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        resolvedAccessPoint?: {
          __typename?: "GroupResourceAccessPoint";
          groupId: string;
          resourceId: string;
          resourceName: string;
          resourceType: ResourceType;
          groupName: string;
          eventId: string;
          createdAt: string;
          expiration?: string | null;
          supportTicketId?: string | null;
          accessLevel: {
            __typename: "ResourceAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
            accessLevelMetadata?: {
              __typename: "ImpersonationAccessLevelMetadata";
              avatarUrl: string;
            } | null;
          };
          supportTicket?: {
            __typename?: "SupportTicket";
            id?: string | null;
            remoteId: string;
            url: string;
            identifier: string;
            thirdPartyProvider: ThirdPartyProvider;
            projectName: string;
          } | null;
        } | null;
        indirectAccessPointPath?: Array<{
          __typename?: "IndirectGroupAccessPoint";
          groupId: string;
          groupName: string;
          groupType: GroupType;
          eventId: string;
          createdAt: string;
        }> | null;
      }>;
      latestExpiringAccessPoint: {
        __typename?: "GroupResourceAccessPoint";
        groupId: string;
        resourceId: string;
        resourceName: string;
        resourceType: ResourceType;
        groupName: string;
        eventId: string;
        createdAt: string;
        expiration?: string | null;
        supportTicketId?: string | null;
        accessLevel: {
          __typename: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
          accessLevelMetadata?: {
            __typename: "ImpersonationAccessLevelMetadata";
            avatarUrl: string;
          } | null;
        };
        supportTicket?: {
          __typename?: "SupportTicket";
          id?: string | null;
          remoteId: string;
          url: string;
          identifier: string;
          thirdPartyProvider: ThirdPartyProvider;
          projectName: string;
        } | null;
      };
    };
    propagationStatus?: {
      __typename?: "GroupResourcePropagationStatus";
      groupId: string;
      resourceId: string;
      accessLevelRemoteId: string;
      lastSynced: string;
      statusCode: PropagationStatusCode;
      errorMessage?: string | null;
      taskType: PropagationTaskType;
    } | null;
  }>;
};

export type RiskyResourcePreviewTinyFragment = {
  __typename?: "Resource";
  name: string;
  resourceType: ResourceType;
  resourceId: string;
};

export type RiskyGroupPreviewTinyFragment = {
  __typename?: "Group";
  name: string;
  groupType: GroupType;
  groupId: string;
};

export type ResourceBulkSuggestionPreviewFragment = {
  __typename?: "Resource";
  name: string;
  resourceType: ResourceType;
  iconUrl?: string | null;
  resourceId: string;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type GroupBulkSuggestionPreviewFragment = {
  __typename?: "Group";
  name: string;
  groupType: GroupType;
  iconUrl?: string | null;
  groupId: string;
  connection?: {
    __typename?: "Connection";
    id: string;
    name: string;
    connectionType: ConnectionType;
  } | null;
};

export type DismissRecommendationsSubscoresMutationVariables = Exact<{
  input: DismissRecommendationsSubscoresInput;
}>;

export type DismissRecommendationsSubscoresMutation = {
  __typename?: "Mutation";
  dismissRecommendationsSubscores: {
    __typename?: "DismissRecommendationsSubscoresOutput";
    success: boolean;
  };
};

export type RescoreEntityMutationVariables = Exact<{
  input: RecommendationsEntityKey;
}>;

export type RescoreEntityMutation = {
  __typename?: "Mutation";
  rescoreEntity: { __typename?: "RescoreEntityOutput"; success: boolean };
};

export type LogEntityRemediationMutationVariables = Exact<{
  input: LogEntityRemediationInput;
}>;

export type LogEntityRemediationMutation = {
  __typename?: "Mutation";
  logEntityRemediation: {
    __typename?: "LogEntityRemediationOutput";
    success: boolean;
  };
};

export type NumRequestsToReviewQueryVariables = Exact<{ [key: string]: never }>;

export type NumRequestsToReviewQuery = {
  __typename?: "Query";
  numRequestsToReview: number;
};

export type RequestStatsQueryVariables = Exact<{ [key: string]: never }>;

export type RequestStatsQuery = {
  __typename?: "Query";
  currentUserStats: {
    __typename?: "CurrentUserStatsResult";
    currentUserStats?: {
      __typename?: "CurrentUserStats";
      numResourcesAdmined: number;
      numGroupsAdmined: number;
      reviewsItems: boolean;
      numRequestsSentPending: number;
      numRequestsSentPendingOnBehalfOfUser: number;
      numRequestsSent: number;
      numRequestsSentOnBehalfOfUser: number;
    } | null;
  };
};

export type RequestTableFragment = {
  __typename?: "Request";
  id: string;
  requesterId: string;
  targetUserId?: string | null;
  targetGroupId?: string | null;
  reason: string;
  resourceProposalId?: string | null;
  durationInMinutes?: number | null;
  createdAt: string;
  status: RequestStatus;
  requester?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetUser?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  targetGroup?: {
    __typename?: "Group";
    id: string;
    remoteId?: string | null;
    name: string;
    remoteName: string;
    numGroupUsers: number;
    groupType: GroupType;
    serviceType: ServiceType;
    visibility: Visibility;
    isManaged: boolean;
    isDeleted: boolean;
    iconUrl?: string | null;
    isOnCallSynced: boolean;
    connectionId: string;
    groupBindingId?: string | null;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    connection?: {
      __typename?: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
    groupBinding?: {
      __typename?: "GroupBinding";
      id: string;
      sourceGroupId: string;
      groups: Array<{
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
        connectionId: string;
      }>;
    } | null;
  } | null;
  requestedResources: Array<{
    __typename?: "RequestedResource";
    resource?: {
      __typename?: "Resource";
      id: string;
      name: string;
      resourceType: ResourceType;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    } | null;
    accessLevel: {
      __typename?: "ResourceAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    };
  }>;
  requestedGroups: Array<{
    __typename?: "RequestedGroup";
    group?: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
      connection?: {
        __typename?: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
      } | null;
    } | null;
    accessLevel?: {
      __typename?: "GroupAccessLevel";
      accessLevelName: string;
      accessLevelRemoteId: string;
    } | null;
  }>;
  resourceProposal?: {
    __typename?: "ResourceProposal";
    id: string;
    connectionId: string;
    serviceType: ServiceType;
    resourceType: ResourceType;
    metadata?: {
      __typename: "AWSRoleProposalMetadata";
      policyName: string;
      policyDocument: string;
      policyDescription: string;
      assumeRolePolicyDocument: string;
      roleName: string;
      roleDescription: string;
      tags: Array<{
        __typename?: "AWSTag";
        key: string;
        value?: string | null;
      }>;
    } | null;
  } | null;
};

export type RequestsTableQueryVariables = Exact<{
  input: RequestsInput;
}>;

export type RequestsTableQuery = {
  __typename?: "Query";
  requests: {
    __typename: "RequestsResult";
    cursor?: string | null;
    requests: Array<{
      __typename?: "Request";
      id: string;
      requesterId: string;
      targetUserId?: string | null;
      targetGroupId?: string | null;
      reason: string;
      resourceProposalId?: string | null;
      durationInMinutes?: number | null;
      createdAt: string;
      status: RequestStatus;
      requester?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetUser?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
      targetGroup?: {
        __typename?: "Group";
        id: string;
        remoteId?: string | null;
        name: string;
        remoteName: string;
        numGroupUsers: number;
        groupType: GroupType;
        serviceType: ServiceType;
        visibility: Visibility;
        isManaged: boolean;
        isDeleted: boolean;
        iconUrl?: string | null;
        isOnCallSynced: boolean;
        connectionId: string;
        groupBindingId?: string | null;
        visibilityGroups: Array<{
          __typename?: "ConfigurationVisibilityGroup";
          visibilityGroupId: string;
          visibilityGroup: { __typename?: "Group"; name: string };
        }>;
        connection?: {
          __typename?: "Connection";
          id: string;
          name: string;
          connectionType: ConnectionType;
        } | null;
        groupBinding?: {
          __typename?: "GroupBinding";
          id: string;
          sourceGroupId: string;
          groups: Array<{
            __typename?: "Group";
            id: string;
            name: string;
            groupType: GroupType;
            connectionId: string;
          }>;
        } | null;
      } | null;
      requestedResources: Array<{
        __typename?: "RequestedResource";
        resource?: {
          __typename?: "Resource";
          id: string;
          name: string;
          resourceType: ResourceType;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        accessLevel: {
          __typename?: "ResourceAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        };
      }>;
      requestedGroups: Array<{
        __typename?: "RequestedGroup";
        group?: {
          __typename?: "Group";
          id: string;
          name: string;
          groupType: GroupType;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
        } | null;
        accessLevel?: {
          __typename?: "GroupAccessLevel";
          accessLevelName: string;
          accessLevelRemoteId: string;
        } | null;
      }>;
      resourceProposal?: {
        __typename?: "ResourceProposal";
        id: string;
        connectionId: string;
        serviceType: ServiceType;
        resourceType: ResourceType;
        metadata?: {
          __typename: "AWSRoleProposalMetadata";
          policyName: string;
          policyDocument: string;
          policyDescription: string;
          assumeRolePolicyDocument: string;
          roleName: string;
          roleDescription: string;
          tags: Array<{
            __typename?: "AWSTag";
            key: string;
            value?: string | null;
          }>;
        } | null;
      } | null;
    }>;
  };
};

export type UserSkipManagerQueryVariables = Exact<{
  input: Scalars["UserId"];
}>;

export type UserSkipManagerQuery = {
  __typename?: "Query";
  user:
    | { __typename?: "UserNotFoundError" }
    | {
        __typename?: "UserResult";
        user: {
          __typename?: "User";
          manager?: { __typename?: "User"; managerId?: string | null } | null;
        };
      };
};

export type EscalateRequestToSkipManagerMutationVariables = Exact<{
  input: EscalateRequestToSkipManagerInput;
}>;

export type EscalateRequestToSkipManagerMutation = {
  __typename?: "Mutation";
  escalateRequestToSkipManager:
    | { __typename: "EscalateRequestToSkipManagerResult"; success: boolean }
    | { __typename: "ManagerHasAlreadyApprovedError"; message: string }
    | { __typename: "NoManagerSetForRequestingUserError"; message: string }
    | { __typename: "NoSkipManagerSetForTargetUserError"; message: string }
    | {
        __typename: "RequestAlreadyEscalatedToSkipManagerError";
        message: string;
      }
    | { __typename: "RequestNotFoundError"; message: string };
};

export type GroupGroupAccessQueryVariables = Exact<{
  groupGroupId: Scalars["GroupGroupId"];
}>;

export type GroupGroupAccessQuery = {
  __typename?: "Query";
  groupGroupAccess:
    | {
        __typename?: "GroupGroupAccessResult";
        access: {
          __typename?: "GroupGroupAccess";
          containingGroupId: string;
          memberGroupId: string;
          accessLevel: {
            __typename: "GroupAccessLevel";
            accessLevelName: string;
            accessLevelRemoteId: string;
          };
          directAccessPoint?: {
            __typename?: "GroupGroupAccessPoint";
            containingGroupId: string;
            memberGroupId: string;
            containingGroupName: string;
            memberGroupName: string;
            memberGroupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          } | null;
          inheritedAccessPoints: Array<{
            __typename?: "GroupGroupAccessPoint";
            containingGroupId: string;
            memberGroupId: string;
            containingGroupName: string;
            memberGroupName: string;
            memberGroupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          }>;
          indirectAccessPointPaths: Array<{
            __typename?: "IndirectGroupGroupAccessPointPath";
            startGroupId: string;
            endGroupId: string;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            resolvedAccessPoint: {
              __typename?: "GroupGroupAccessPoint";
              containingGroupId: string;
              memberGroupId: string;
              containingGroupName: string;
              memberGroupName: string;
              memberGroupType: GroupType;
              eventId: string;
              createdAt: string;
              expiration?: string | null;
              supportTicketId?: string | null;
              accessLevel: {
                __typename: "GroupAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
              };
              supportTicket?: {
                __typename?: "SupportTicket";
                id?: string | null;
                remoteId: string;
                url: string;
                identifier: string;
                thirdPartyProvider: ThirdPartyProvider;
                projectName: string;
              } | null;
            };
            indirectAccessPointPath: Array<{
              __typename?: "IndirectGroupAccessPoint";
              groupId: string;
              groupName: string;
              groupType: GroupType;
              eventId: string;
              createdAt: string;
            }>;
          }>;
          latestExpiringAccessPoint: {
            __typename?: "GroupGroupAccessPoint";
            containingGroupId: string;
            memberGroupId: string;
            containingGroupName: string;
            memberGroupName: string;
            memberGroupType: GroupType;
            eventId: string;
            createdAt: string;
            expiration?: string | null;
            supportTicketId?: string | null;
            accessLevel: {
              __typename: "GroupAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
            };
            supportTicket?: {
              __typename?: "SupportTicket";
              id?: string | null;
              remoteId: string;
              url: string;
              identifier: string;
              thirdPartyProvider: ThirdPartyProvider;
              projectName: string;
            } | null;
          };
        };
      }
    | { __typename?: "GroupGroupNotFound" };
};

export type ImportResourcesBodyQueryVariables = Exact<{
  input: ConnectionInput;
}>;

export type ImportResourcesBodyQuery = {
  __typename?: "Query";
  connection:
    | { __typename: "ConnectionNotFoundError"; message: string }
    | {
        __typename: "ConnectionResult";
        connection: {
          __typename: "Connection";
          adminOwnerId: string;
          importVisibility: Visibility;
          id: string;
          name: string;
          connectionType: ConnectionType;
          isDeleted: boolean;
          resources: Array<{
            __typename: "Resource";
            id: string;
            name: string;
            remoteName: string;
            serviceType: ServiceType;
            visibility: Visibility;
            isDeleted: boolean;
            isManaged: boolean;
            resourceType: ResourceType;
            remoteId: string;
            iconUrl?: string | null;
            parentResourceId?: string | null;
            connectionId: string;
            createdAt: string;
            visibilityGroups: Array<{
              __typename?: "ConfigurationVisibilityGroup";
              visibilityGroupId: string;
              visibilityGroup: { __typename?: "Group"; name: string };
            }>;
            parentResource?: {
              __typename?: "Resource";
              id: string;
              name: string;
            } | null;
            connection?: {
              __typename: "Connection";
              id: string;
              name: string;
              connectionType: ConnectionType;
              isDeleted: boolean;
              metadata?:
                | {
                    __typename: "ADConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                  }
                | {
                    __typename: "AwsConnectionMetadata";
                    success?: boolean | null;
                  }
                | {
                    __typename: "AWSSSOConnectionMetadata";
                    managementAccountId: string;
                    ssoInstanceArn: string;
                    identityStoreId: string;
                    accessPortalUrl: string;
                    awsRegion: string;
                    awsOrganizationEnabled: boolean;
                    awsSsoEnabled: boolean;
                    awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                    awsOrganizationImportSetting: ImportSetting;
                    ec2RoleChainingEnabled: boolean;
                    podRoleChainingEnabled: boolean;
                    cloudtrailEventsSqsUrl: string;
                  }
                | {
                    __typename: "AzureADConnectionMetadata";
                    tenantId: string;
                    clientId: string;
                    azureInfraEnabled: boolean;
                    eventHubNamespace: string;
                    eventHub: string;
                  }
                | {
                    __typename: "CustomConnectorConnectionMetadata";
                    identifier: string;
                    baseUrl: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                    supportsGroups: boolean;
                    supportsNestedResources: boolean;
                  }
                | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                | {
                    __typename: "GCPConnectionMetadata";
                    pubSubProjectId: string;
                    subscriptionId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | { __typename: "GitHubConnectionMetadata"; orgName: string }
                | {
                    __typename: "GitLabConnectionMetadata";
                    hostName?: string | null;
                    appId: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "GoogleGroupsConnectionMetadata";
                    opalGroupEmail: string;
                    adminUserEmail: string;
                    domain: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "GoogleWorkspaceConnectionMetadata";
                    adminUserEmail: string;
                    customerId: string;
                    serviceAccount: {
                      __typename?: "GCPServiceAccount";
                      type: string;
                      projectId: string;
                      clientEmail: string;
                      clientId: string;
                      authUri: string;
                      tokenUri: string;
                      authProviderX509CertUrl: string;
                      clientX509CertUrl: string;
                    };
                  }
                | {
                    __typename: "LDAPConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    groupsDistinguishedName: string;
                    usersDistinguishedName: string;
                    groupsUidKey: string;
                  }
                | {
                    __typename: "MongoAtlasConnectionMetadata";
                    serverHostname: string;
                  }
                | {
                    __typename: "MongoConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "MySQLMariaDBConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "OktaDirectoryConnectionMetadata";
                    orgUrl: string;
                  }
                | { __typename: "PagerDutyConnectionMetadata" }
                | {
                    __typename: "PostgresConnectionMetadata";
                    serverHostname: string;
                    serverPort: number;
                    tlsMode: boolean;
                    tlsCertContent?: string | null;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "PropagationTicketConnectionMetadata";
                    ticketProvider?: {
                      __typename?: "TicketProvider";
                      ticketProvider: ThirdPartyProvider;
                      ticketProjectId: string;
                      ticketProjectName: string;
                    } | null;
                  }
                | {
                    __typename: "SalesforceConnectionMetadata";
                    email: string;
                    hostname: string;
                  }
                | {
                    __typename: "SnowflakeConnectionMetadata";
                    organization: string;
                    account: string;
                    locator: string;
                    accountIdentifier: string;
                    accountUrl: string;
                    serviceAccountName: string;
                    serviceAccountRole: string;
                  }
                | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
                | {
                    __typename: "TeleportConnectionMetadata";
                    host: string;
                    tlsMode: boolean;
                    tlsCaCertContent?: string | null;
                  }
                | {
                    __typename: "WorkdayConnectionMetadata";
                    tenantName: string;
                    tenantUrl: string;
                  }
                | null;
            } | null;
            metadata?:
              | { __typename: "AwsRoleMetadata"; arn: string }
              | {
                  __typename: "OktaDirectoryAppMetadata";
                  appId: string;
                  appName: string;
                  logoUrl?: string | null;
                }
              | {
                  __typename: "OktaRoleMetadata";
                  roleId: string;
                  roleType: string;
                }
              | {
                  __typename: "PropagationTicketOwnerMetadata";
                  ownerId?: string | null;
                  owner?: {
                    __typename?: "Owner";
                    id: string;
                    name: string;
                  } | null;
                }
              | null;
          }>;
          metadata?:
            | {
                __typename: "ADConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
              }
            | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
            | {
                __typename: "AWSSSOConnectionMetadata";
                managementAccountId: string;
                ssoInstanceArn: string;
                identityStoreId: string;
                accessPortalUrl: string;
                awsRegion: string;
                awsOrganizationEnabled: boolean;
                awsSsoEnabled: boolean;
                awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                awsOrganizationImportSetting: ImportSetting;
                ec2RoleChainingEnabled: boolean;
                podRoleChainingEnabled: boolean;
                cloudtrailEventsSqsUrl: string;
              }
            | {
                __typename: "AzureADConnectionMetadata";
                tenantId: string;
                clientId: string;
                azureInfraEnabled: boolean;
                eventHubNamespace: string;
                eventHub: string;
              }
            | {
                __typename: "CustomConnectorConnectionMetadata";
                identifier: string;
                baseUrl: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
                supportsGroups: boolean;
                supportsNestedResources: boolean;
              }
            | { __typename: "DuoConnectionMetadata"; apiHostname: string }
            | {
                __typename: "GCPConnectionMetadata";
                pubSubProjectId: string;
                subscriptionId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | { __typename: "GitHubConnectionMetadata"; orgName: string }
            | {
                __typename: "GitLabConnectionMetadata";
                hostName?: string | null;
                appId: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "GoogleGroupsConnectionMetadata";
                opalGroupEmail: string;
                adminUserEmail: string;
                domain: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "GoogleWorkspaceConnectionMetadata";
                adminUserEmail: string;
                customerId: string;
                serviceAccount: {
                  __typename?: "GCPServiceAccount";
                  type: string;
                  projectId: string;
                  clientEmail: string;
                  clientId: string;
                  authUri: string;
                  tokenUri: string;
                  authProviderX509CertUrl: string;
                  clientX509CertUrl: string;
                };
              }
            | {
                __typename: "LDAPConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                groupsDistinguishedName: string;
                usersDistinguishedName: string;
                groupsUidKey: string;
              }
            | {
                __typename: "MongoAtlasConnectionMetadata";
                serverHostname: string;
              }
            | {
                __typename: "MongoConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "MySQLMariaDBConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
            | { __typename: "PagerDutyConnectionMetadata" }
            | {
                __typename: "PostgresConnectionMetadata";
                serverHostname: string;
                serverPort: number;
                tlsMode: boolean;
                tlsCertContent?: string | null;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "PropagationTicketConnectionMetadata";
                ticketProvider?: {
                  __typename?: "TicketProvider";
                  ticketProvider: ThirdPartyProvider;
                  ticketProjectId: string;
                  ticketProjectName: string;
                } | null;
              }
            | {
                __typename: "SalesforceConnectionMetadata";
                email: string;
                hostname: string;
              }
            | {
                __typename: "SnowflakeConnectionMetadata";
                organization: string;
                account: string;
                locator: string;
                accountIdentifier: string;
                accountUrl: string;
                serviceAccountName: string;
                serviceAccountRole: string;
              }
            | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
            | {
                __typename: "TeleportConnectionMetadata";
                host: string;
                tlsMode: boolean;
                tlsCaCertContent?: string | null;
              }
            | {
                __typename: "WorkdayConnectionMetadata";
                tenantName: string;
                tenantUrl: string;
              }
            | null;
        };
      };
};

export type ConnectionForResourceImportFragment = {
  __typename: "Connection";
  adminOwnerId: string;
  importVisibility: Visibility;
  id: string;
  name: string;
  connectionType: ConnectionType;
  isDeleted: boolean;
  resources: Array<{
    __typename: "Resource";
    id: string;
    name: string;
    remoteName: string;
    serviceType: ServiceType;
    visibility: Visibility;
    isDeleted: boolean;
    isManaged: boolean;
    resourceType: ResourceType;
    remoteId: string;
    iconUrl?: string | null;
    parentResourceId?: string | null;
    connectionId: string;
    createdAt: string;
    visibilityGroups: Array<{
      __typename?: "ConfigurationVisibilityGroup";
      visibilityGroupId: string;
      visibilityGroup: { __typename?: "Group"; name: string };
    }>;
    parentResource?: {
      __typename?: "Resource";
      id: string;
      name: string;
    } | null;
    connection?: {
      __typename: "Connection";
      id: string;
      name: string;
      connectionType: ConnectionType;
      isDeleted: boolean;
      metadata?:
        | {
            __typename: "ADConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
          }
        | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
        | {
            __typename: "AWSSSOConnectionMetadata";
            managementAccountId: string;
            ssoInstanceArn: string;
            identityStoreId: string;
            accessPortalUrl: string;
            awsRegion: string;
            awsOrganizationEnabled: boolean;
            awsSsoEnabled: boolean;
            awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
            awsOrganizationImportSetting: ImportSetting;
            ec2RoleChainingEnabled: boolean;
            podRoleChainingEnabled: boolean;
            cloudtrailEventsSqsUrl: string;
          }
        | {
            __typename: "AzureADConnectionMetadata";
            tenantId: string;
            clientId: string;
            azureInfraEnabled: boolean;
            eventHubNamespace: string;
            eventHub: string;
          }
        | {
            __typename: "CustomConnectorConnectionMetadata";
            identifier: string;
            baseUrl: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
            supportsGroups: boolean;
            supportsNestedResources: boolean;
          }
        | { __typename: "DuoConnectionMetadata"; apiHostname: string }
        | {
            __typename: "GCPConnectionMetadata";
            pubSubProjectId: string;
            subscriptionId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | { __typename: "GitHubConnectionMetadata"; orgName: string }
        | {
            __typename: "GitLabConnectionMetadata";
            hostName?: string | null;
            appId: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "GoogleGroupsConnectionMetadata";
            opalGroupEmail: string;
            adminUserEmail: string;
            domain: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "GoogleWorkspaceConnectionMetadata";
            adminUserEmail: string;
            customerId: string;
            serviceAccount: {
              __typename?: "GCPServiceAccount";
              type: string;
              projectId: string;
              clientEmail: string;
              clientId: string;
              authUri: string;
              tokenUri: string;
              authProviderX509CertUrl: string;
              clientX509CertUrl: string;
            };
          }
        | {
            __typename: "LDAPConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            groupsDistinguishedName: string;
            usersDistinguishedName: string;
            groupsUidKey: string;
          }
        | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
        | {
            __typename: "MongoConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "MySQLMariaDBConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
        | { __typename: "PagerDutyConnectionMetadata" }
        | {
            __typename: "PostgresConnectionMetadata";
            serverHostname: string;
            serverPort: number;
            tlsMode: boolean;
            tlsCertContent?: string | null;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "PropagationTicketConnectionMetadata";
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          }
        | {
            __typename: "SalesforceConnectionMetadata";
            email: string;
            hostname: string;
          }
        | {
            __typename: "SnowflakeConnectionMetadata";
            organization: string;
            account: string;
            locator: string;
            accountIdentifier: string;
            accountUrl: string;
            serviceAccountName: string;
            serviceAccountRole: string;
          }
        | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
        | {
            __typename: "TeleportConnectionMetadata";
            host: string;
            tlsMode: boolean;
            tlsCaCertContent?: string | null;
          }
        | {
            __typename: "WorkdayConnectionMetadata";
            tenantName: string;
            tenantUrl: string;
          }
        | null;
    } | null;
    metadata?:
      | { __typename: "AwsRoleMetadata"; arn: string }
      | {
          __typename: "OktaDirectoryAppMetadata";
          appId: string;
          appName: string;
          logoUrl?: string | null;
        }
      | { __typename: "OktaRoleMetadata"; roleId: string; roleType: string }
      | {
          __typename: "PropagationTicketOwnerMetadata";
          ownerId?: string | null;
          owner?: { __typename?: "Owner"; id: string; name: string } | null;
        }
      | null;
  }>;
  metadata?:
    | {
        __typename: "ADConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
      }
    | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
    | {
        __typename: "AWSSSOConnectionMetadata";
        managementAccountId: string;
        ssoInstanceArn: string;
        identityStoreId: string;
        accessPortalUrl: string;
        awsRegion: string;
        awsOrganizationEnabled: boolean;
        awsSsoEnabled: boolean;
        awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
        awsOrganizationImportSetting: ImportSetting;
        ec2RoleChainingEnabled: boolean;
        podRoleChainingEnabled: boolean;
        cloudtrailEventsSqsUrl: string;
      }
    | {
        __typename: "AzureADConnectionMetadata";
        tenantId: string;
        clientId: string;
        azureInfraEnabled: boolean;
        eventHubNamespace: string;
        eventHub: string;
      }
    | {
        __typename: "CustomConnectorConnectionMetadata";
        identifier: string;
        baseUrl: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
        supportsGroups: boolean;
        supportsNestedResources: boolean;
      }
    | { __typename: "DuoConnectionMetadata"; apiHostname: string }
    | {
        __typename: "GCPConnectionMetadata";
        pubSubProjectId: string;
        subscriptionId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | { __typename: "GitHubConnectionMetadata"; orgName: string }
    | {
        __typename: "GitLabConnectionMetadata";
        hostName?: string | null;
        appId: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "GoogleGroupsConnectionMetadata";
        opalGroupEmail: string;
        adminUserEmail: string;
        domain: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "GoogleWorkspaceConnectionMetadata";
        adminUserEmail: string;
        customerId: string;
        serviceAccount: {
          __typename?: "GCPServiceAccount";
          type: string;
          projectId: string;
          clientEmail: string;
          clientId: string;
          authUri: string;
          tokenUri: string;
          authProviderX509CertUrl: string;
          clientX509CertUrl: string;
        };
      }
    | {
        __typename: "LDAPConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        groupsDistinguishedName: string;
        usersDistinguishedName: string;
        groupsUidKey: string;
      }
    | { __typename: "MongoAtlasConnectionMetadata"; serverHostname: string }
    | {
        __typename: "MongoConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "MySQLMariaDBConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
    | { __typename: "PagerDutyConnectionMetadata" }
    | {
        __typename: "PostgresConnectionMetadata";
        serverHostname: string;
        serverPort: number;
        tlsMode: boolean;
        tlsCertContent?: string | null;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "PropagationTicketConnectionMetadata";
        ticketProvider?: {
          __typename?: "TicketProvider";
          ticketProvider: ThirdPartyProvider;
          ticketProjectId: string;
          ticketProjectName: string;
        } | null;
      }
    | {
        __typename: "SalesforceConnectionMetadata";
        email: string;
        hostname: string;
      }
    | {
        __typename: "SnowflakeConnectionMetadata";
        organization: string;
        account: string;
        locator: string;
        accountIdentifier: string;
        accountUrl: string;
        serviceAccountName: string;
        serviceAccountRole: string;
      }
    | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
    | {
        __typename: "TeleportConnectionMetadata";
        host: string;
        tlsMode: boolean;
        tlsCaCertContent?: string | null;
      }
    | {
        __typename: "WorkdayConnectionMetadata";
        tenantName: string;
        tenantUrl: string;
      }
    | null;
};

export type ResourceVisibilityRowQueryVariables = Exact<{
  input: ResourceInput;
}>;

export type ResourceVisibilityRowQuery = {
  __typename?: "Query";
  resource:
    | { __typename?: "ResourceNotFoundError"; message: string }
    | {
        __typename?: "ResourceResult";
        resource: {
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        };
      };
};

export type GroupOnCallSchedulePreviewSmallFragment = {
  __typename?: "GroupOnCallSchedule";
  group?: {
    __typename?: "Group";
    id: string;
    name: string;
    groupType: GroupType;
  } | null;
  onCallSchedule: {
    __typename?: "OnCallSchedule";
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    name: string;
    existsInRemote: boolean;
  };
};

export type GroupOnCallSchedulesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GroupOnCallSchedulesQuery = {
  __typename?: "Query";
  groupOnCallSchedules: {
    __typename: "GroupOnCallSchedulesResult";
    groupOnCallSchedules: Array<{
      __typename?: "GroupOnCallSchedule";
      group?: {
        __typename?: "Group";
        id: string;
        name: string;
        groupType: GroupType;
      } | null;
      onCallSchedule: {
        __typename?: "OnCallSchedule";
        remoteId: string;
        thirdPartyProvider: ThirdPartyProvider;
        name: string;
        existsInRemote: boolean;
      };
    }>;
  };
};

export type EventStreamFragment = {
  __typename?: "EventStream";
  id: string;
  connection: {
    __typename?: "PubSubPublishConnection";
    name: string;
    connectionType: PubSubPublishConnectionType;
    enabled: boolean;
    failedDeliveryCount?: number;
    metadata: {
      __typename: "WebhookPubSubPublishConnectionMetadata";
      url: string;
    };
    credentials: {
      __typename: "WebhookPubsubPublishConnectionCredentialsOutput";
      authType: WebhookPubsubPublishConnectionAuthType;
      apiKey?: {
        __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialsOutput";
        credentials: Array<{
          __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialOutput";
          name: string;
          valuePreview: string;
          location: WebhookPubsubPublishConnectionApiKeyLocation;
        }>;
      } | null;
      hmac?: {
        __typename?: "WebhookPubsubPublishConnectionHmacCredentialsOutput";
        credential1?: {
          __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
          secret?: string | null;
          secretPreview: string;
          createdAt: string;
        } | null;
        credential2?: {
          __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
          secret?: string | null;
          secretPreview: string;
          createdAt: string;
        } | null;
      } | null;
    };
  };
};

export type CreateEventStreamMutationVariables = Exact<{
  input: CreateEventStreamInput;
  fetchFailedDeliveryCounts?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CreateEventStreamMutation = {
  __typename?: "Mutation";
  createEventStream: {
    __typename: "CreateEventStreamResult";
    eventStream: {
      __typename?: "EventStream";
      id: string;
      connection: {
        __typename?: "PubSubPublishConnection";
        name: string;
        connectionType: PubSubPublishConnectionType;
        enabled: boolean;
        failedDeliveryCount?: number;
        metadata: {
          __typename: "WebhookPubSubPublishConnectionMetadata";
          url: string;
        };
        credentials: {
          __typename: "WebhookPubsubPublishConnectionCredentialsOutput";
          authType: WebhookPubsubPublishConnectionAuthType;
          apiKey?: {
            __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialsOutput";
            credentials: Array<{
              __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialOutput";
              name: string;
              valuePreview: string;
              location: WebhookPubsubPublishConnectionApiKeyLocation;
            }>;
          } | null;
          hmac?: {
            __typename?: "WebhookPubsubPublishConnectionHmacCredentialsOutput";
            credential1?: {
              __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
              secret?: string | null;
              secretPreview: string;
              createdAt: string;
            } | null;
            credential2?: {
              __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
              secret?: string | null;
              secretPreview: string;
              createdAt: string;
            } | null;
          } | null;
        };
      };
    };
  };
};

export type ListEventStreamsQueryVariables = Exact<{
  fetchFailedDeliveryCounts?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ListEventStreamsQuery = {
  __typename?: "Query";
  listEventStreams: {
    __typename: "ListEventStreamsResult";
    eventStreams: Array<{
      __typename?: "EventStream";
      id: string;
      connection: {
        __typename?: "PubSubPublishConnection";
        name: string;
        connectionType: PubSubPublishConnectionType;
        enabled: boolean;
        failedDeliveryCount?: number;
        metadata: {
          __typename: "WebhookPubSubPublishConnectionMetadata";
          url: string;
        };
        credentials: {
          __typename: "WebhookPubsubPublishConnectionCredentialsOutput";
          authType: WebhookPubsubPublishConnectionAuthType;
          apiKey?: {
            __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialsOutput";
            credentials: Array<{
              __typename?: "WebhookPubsubPublishConnectionApiKeyCredentialOutput";
              name: string;
              valuePreview: string;
              location: WebhookPubsubPublishConnectionApiKeyLocation;
            }>;
          } | null;
          hmac?: {
            __typename?: "WebhookPubsubPublishConnectionHmacCredentialsOutput";
            credential1?: {
              __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
              secret?: string | null;
              secretPreview: string;
              createdAt: string;
            } | null;
            credential2?: {
              __typename?: "WebhookPubsubPublishConnectionHmacCredentialOutput";
              secret?: string | null;
              secretPreview: string;
              createdAt: string;
            } | null;
          } | null;
        };
      };
    }>;
  };
};

export type OidcProviderQueryVariables = Exact<{
  input: OidcProviderInput;
}>;

export type OidcProviderQuery = {
  __typename?: "Query";
  oidcProvider:
    | { __typename: "OidcProviderNotFoundError"; message: string }
    | {
        __typename: "OidcProviderResult";
        oidcProvider: {
          __typename?: "OidcProvider";
          clientId: string;
          issuerUrl: string;
        };
      };
};

export type InitOidcAuthFlowMutationVariables = Exact<{
  input: InitOidcAuthFlowInput;
}>;

export type InitOidcAuthFlowMutation = {
  __typename?: "Mutation";
  initOidcAuthFlow:
    | { __typename: "InitOidcAuthFlowResult"; authorizeUrl: string }
    | { __typename: "OidcProviderNotFoundError" };
};

export type ValidateCodeAndSaveIdTokenMutationVariables = Exact<{
  input: ValidateCodeAndSaveIdTokenInput;
}>;

export type ValidateCodeAndSaveIdTokenMutation = {
  __typename?: "Mutation";
  validateCodeAndSaveIdToken?: boolean | null;
};

export type InitOidcAuthFlowPresignedMutationVariables = Exact<{
  input: InitOidcAuthFlowInput;
}>;

export type InitOidcAuthFlowPresignedMutation = {
  __typename?: "Mutation";
  initOidcAuthFlowPresigned:
    | { __typename: "InitOidcAuthFlowResult"; authorizeUrl: string }
    | { __typename: "OidcProviderNotFoundError" };
};

export type ValidateCodeAndSaveIdTokenPresignedMutationVariables = Exact<{
  input: ValidateCodeAndSaveIdTokenInput;
}>;

export type ValidateCodeAndSaveIdTokenPresignedMutation = {
  __typename?: "Mutation";
  validateCodeAndSaveIdTokenPresigned?: boolean | null;
};

export type CreateOrUpdateOidcProviderMutationVariables = Exact<{
  input: CreateOrUpdateOidcProviderInput;
}>;

export type CreateOrUpdateOidcProviderMutation = {
  __typename?: "Mutation";
  createOrUpdateOidcProvider:
    | {
        __typename: "CreateOrUpdateOidcProviderResult";
        oidcProvider: {
          __typename?: "OidcProvider";
          clientId: string;
          issuerUrl: string;
        };
      }
    | { __typename: "OidcIssuerNotValidError"; message: string };
};

export type SetupStateQueryVariables = Exact<{ [key: string]: never }>;

export type SetupStateQuery = {
  __typename?: "Query";
  setupState: {
    __typename?: "SetupStateResult";
    state: {
      __typename?: "SetupState";
      minStep: number;
      idpConnectionType?: IdpConnectionType | null;
      idpConnectionId?: string | null;
      connectionId?: string | null;
      importStatus?: SyncTaskStatus | null;
      connection?: {
        __typename: "Connection";
        id: string;
        name: string;
        connectionType: ConnectionType;
        isDeleted: boolean;
        metadata?:
          | {
              __typename: "ADConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
            }
          | { __typename: "AwsConnectionMetadata"; success?: boolean | null }
          | {
              __typename: "AWSSSOConnectionMetadata";
              managementAccountId: string;
              ssoInstanceArn: string;
              identityStoreId: string;
              accessPortalUrl: string;
              awsRegion: string;
              awsOrganizationEnabled: boolean;
              awsSsoEnabled: boolean;
              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
              awsOrganizationImportSetting: ImportSetting;
              ec2RoleChainingEnabled: boolean;
              podRoleChainingEnabled: boolean;
              cloudtrailEventsSqsUrl: string;
            }
          | {
              __typename: "AzureADConnectionMetadata";
              tenantId: string;
              clientId: string;
              azureInfraEnabled: boolean;
              eventHubNamespace: string;
              eventHub: string;
            }
          | {
              __typename: "CustomConnectorConnectionMetadata";
              identifier: string;
              baseUrl: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
              supportsGroups: boolean;
              supportsNestedResources: boolean;
            }
          | { __typename: "DuoConnectionMetadata"; apiHostname: string }
          | {
              __typename: "GCPConnectionMetadata";
              pubSubProjectId: string;
              subscriptionId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | { __typename: "GitHubConnectionMetadata"; orgName: string }
          | {
              __typename: "GitLabConnectionMetadata";
              hostName?: string | null;
              appId: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "GoogleGroupsConnectionMetadata";
              opalGroupEmail: string;
              adminUserEmail: string;
              domain: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "GoogleWorkspaceConnectionMetadata";
              adminUserEmail: string;
              customerId: string;
              serviceAccount: {
                __typename?: "GCPServiceAccount";
                type: string;
                projectId: string;
                clientEmail: string;
                clientId: string;
                authUri: string;
                tokenUri: string;
                authProviderX509CertUrl: string;
                clientX509CertUrl: string;
              };
            }
          | {
              __typename: "LDAPConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              groupsDistinguishedName: string;
              usersDistinguishedName: string;
              groupsUidKey: string;
            }
          | {
              __typename: "MongoAtlasConnectionMetadata";
              serverHostname: string;
            }
          | {
              __typename: "MongoConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "MySQLMariaDBConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | { __typename: "OktaDirectoryConnectionMetadata"; orgUrl: string }
          | { __typename: "PagerDutyConnectionMetadata" }
          | {
              __typename: "PostgresConnectionMetadata";
              serverHostname: string;
              serverPort: number;
              tlsMode: boolean;
              tlsCertContent?: string | null;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "PropagationTicketConnectionMetadata";
              ticketProvider?: {
                __typename?: "TicketProvider";
                ticketProvider: ThirdPartyProvider;
                ticketProjectId: string;
                ticketProjectName: string;
              } | null;
            }
          | {
              __typename: "SalesforceConnectionMetadata";
              email: string;
              hostname: string;
            }
          | {
              __typename: "SnowflakeConnectionMetadata";
              organization: string;
              account: string;
              locator: string;
              accountIdentifier: string;
              accountUrl: string;
              serviceAccountName: string;
              serviceAccountRole: string;
            }
          | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
          | {
              __typename: "TeleportConnectionMetadata";
              host: string;
              tlsMode: boolean;
              tlsCaCertContent?: string | null;
            }
          | {
              __typename: "WorkdayConnectionMetadata";
              tenantName: string;
              tenantUrl: string;
            }
          | null;
      } | null;
      entityCounts?: {
        __typename?: "SetupEntityCounts";
        numUsers: number;
        numGroups: number;
        resourceCounts: Array<{
          __typename?: "SetupResourceCount";
          resourceType: ResourceType;
          count: number;
        }>;
      } | null;
      importEntityCounts?: {
        __typename?: "SetupEntityCounts";
        numUsers: number;
        numGroups: number;
        resourceCounts: Array<{
          __typename?: "SetupResourceCount";
          resourceType: ResourceType;
          count: number;
        }>;
      } | null;
    };
  };
};

export type ImportAllMutationVariables = Exact<{
  input: ImportAllInput;
}>;

export type ImportAllMutation = {
  __typename?: "Mutation";
  importAll:
    | {
        __typename: "BulkUpdateItemsResult";
        updatedResources?: Array<{
          __typename: "Resource";
          id: string;
          name: string;
          remoteName: string;
          serviceType: ServiceType;
          visibility: Visibility;
          isDeleted: boolean;
          isManaged: boolean;
          resourceType: ResourceType;
          remoteId: string;
          iconUrl?: string | null;
          parentResourceId?: string | null;
          connectionId: string;
          createdAt: string;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          parentResource?: {
            __typename?: "Resource";
            id: string;
            name: string;
          } | null;
          connection?: {
            __typename: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
            isDeleted: boolean;
            metadata?:
              | {
                  __typename: "ADConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                }
              | {
                  __typename: "AwsConnectionMetadata";
                  success?: boolean | null;
                }
              | {
                  __typename: "AWSSSOConnectionMetadata";
                  managementAccountId: string;
                  ssoInstanceArn: string;
                  identityStoreId: string;
                  accessPortalUrl: string;
                  awsRegion: string;
                  awsOrganizationEnabled: boolean;
                  awsSsoEnabled: boolean;
                  awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                  awsOrganizationImportSetting: ImportSetting;
                  ec2RoleChainingEnabled: boolean;
                  podRoleChainingEnabled: boolean;
                  cloudtrailEventsSqsUrl: string;
                }
              | {
                  __typename: "AzureADConnectionMetadata";
                  tenantId: string;
                  clientId: string;
                  azureInfraEnabled: boolean;
                  eventHubNamespace: string;
                  eventHub: string;
                }
              | {
                  __typename: "CustomConnectorConnectionMetadata";
                  identifier: string;
                  baseUrl: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                  supportsGroups: boolean;
                  supportsNestedResources: boolean;
                }
              | { __typename: "DuoConnectionMetadata"; apiHostname: string }
              | {
                  __typename: "GCPConnectionMetadata";
                  pubSubProjectId: string;
                  subscriptionId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | { __typename: "GitHubConnectionMetadata"; orgName: string }
              | {
                  __typename: "GitLabConnectionMetadata";
                  hostName?: string | null;
                  appId: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "GoogleGroupsConnectionMetadata";
                  opalGroupEmail: string;
                  adminUserEmail: string;
                  domain: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "GoogleWorkspaceConnectionMetadata";
                  adminUserEmail: string;
                  customerId: string;
                  serviceAccount: {
                    __typename?: "GCPServiceAccount";
                    type: string;
                    projectId: string;
                    clientEmail: string;
                    clientId: string;
                    authUri: string;
                    tokenUri: string;
                    authProviderX509CertUrl: string;
                    clientX509CertUrl: string;
                  };
                }
              | {
                  __typename: "LDAPConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  groupsDistinguishedName: string;
                  usersDistinguishedName: string;
                  groupsUidKey: string;
                }
              | {
                  __typename: "MongoAtlasConnectionMetadata";
                  serverHostname: string;
                }
              | {
                  __typename: "MongoConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "MySQLMariaDBConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "OktaDirectoryConnectionMetadata";
                  orgUrl: string;
                }
              | { __typename: "PagerDutyConnectionMetadata" }
              | {
                  __typename: "PostgresConnectionMetadata";
                  serverHostname: string;
                  serverPort: number;
                  tlsMode: boolean;
                  tlsCertContent?: string | null;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "PropagationTicketConnectionMetadata";
                  ticketProvider?: {
                    __typename?: "TicketProvider";
                    ticketProvider: ThirdPartyProvider;
                    ticketProjectId: string;
                    ticketProjectName: string;
                  } | null;
                }
              | {
                  __typename: "SalesforceConnectionMetadata";
                  email: string;
                  hostname: string;
                }
              | {
                  __typename: "SnowflakeConnectionMetadata";
                  organization: string;
                  account: string;
                  locator: string;
                  accountIdentifier: string;
                  accountUrl: string;
                  serviceAccountName: string;
                  serviceAccountRole: string;
                }
              | { __typename: "TailscaleConnectionMetadata"; tailnet: string }
              | {
                  __typename: "TeleportConnectionMetadata";
                  host: string;
                  tlsMode: boolean;
                  tlsCaCertContent?: string | null;
                }
              | {
                  __typename: "WorkdayConnectionMetadata";
                  tenantName: string;
                  tenantUrl: string;
                }
              | null;
          } | null;
          metadata?:
            | { __typename: "AwsRoleMetadata"; arn: string }
            | {
                __typename: "OktaDirectoryAppMetadata";
                appId: string;
                appName: string;
                logoUrl?: string | null;
              }
            | {
                __typename: "OktaRoleMetadata";
                roleId: string;
                roleType: string;
              }
            | {
                __typename: "PropagationTicketOwnerMetadata";
                ownerId?: string | null;
                owner?: {
                  __typename?: "Owner";
                  id: string;
                  name: string;
                } | null;
              }
            | null;
        }> | null;
        updatedGroups?: Array<{
          __typename?: "Group";
          id: string;
          remoteId?: string | null;
          name: string;
          remoteName: string;
          numGroupUsers: number;
          groupType: GroupType;
          serviceType: ServiceType;
          visibility: Visibility;
          isManaged: boolean;
          isDeleted: boolean;
          iconUrl?: string | null;
          isOnCallSynced: boolean;
          connectionId: string;
          groupBindingId?: string | null;
          visibilityGroups: Array<{
            __typename?: "ConfigurationVisibilityGroup";
            visibilityGroupId: string;
            visibilityGroup: { __typename?: "Group"; name: string };
          }>;
          connection?: {
            __typename?: "Connection";
            id: string;
            name: string;
            connectionType: ConnectionType;
          } | null;
          groupBinding?: {
            __typename?: "GroupBinding";
            id: string;
            sourceGroupId: string;
            groups: Array<{
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
              connectionId: string;
            }>;
          } | null;
        }> | null;
        syncTask?: {
          __typename?: "SyncTask";
          id: string;
          createdAt: string;
          updatedAt: string;
          syncType: SyncType;
          startedByUserId: string;
          status: SyncTaskStatus;
          startedByUser?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          progress?: {
            __typename?: "SyncTaskProgress";
            steps: Array<{
              __typename?: "SyncTaskStep";
              name: string;
              totalNumItems: number;
              numItemsCompleted: number;
            }>;
          } | null;
        } | null;
      }
    | { __typename?: "GroupNotFoundError" }
    | { __typename?: "ResourceNotFoundError" };
};

export type SetupEntityCountsFragment = {
  __typename?: "SetupEntityCounts";
  numUsers: number;
  numGroups: number;
  resourceCounts: Array<{
    __typename?: "SetupResourceCount";
    resourceType: ResourceType;
    count: number;
  }>;
};

export type TagsTableQueryVariables = Exact<{
  input: TagsInput;
}>;

export type TagsTableQuery = {
  __typename?: "Query";
  tags: {
    __typename: "TagsResult";
    totalNumTags: number;
    cursor?: string | null;
    tags: Array<{
      __typename: "Tag";
      id: string;
      key: string;
      value?: string | null;
      createdAt: string;
      tagResources: Array<{ __typename: "ResourceTag"; resourceId: string }>;
      tagGroups: Array<{ __typename: "GroupTag"; groupId: string }>;
      tagUsers: Array<{ __typename: "UserTag"; userId: string }>;
    }>;
  };
};

export type ConfigurationTemplatesViewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ConfigurationTemplatesViewQuery = {
  __typename?: "Query";
  configurationTemplates: {
    __typename?: "ConfigurationTemplatesResult";
    configurationTemplates: Array<{
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
      visibility: Visibility;
      requireMfaToApprove: boolean;
      requireMfaToConnect: boolean;
      adminOwner: { __typename?: "Owner"; id: string; name: string };
    }>;
  };
};

export type GetConfigurationsTemplateQueryVariables = Exact<{
  ID: Scalars["ConfigurationId"];
}>;

export type GetConfigurationsTemplateQuery = {
  __typename?: "Query";
  configurationTemplate:
    | { __typename?: "ConfigurationTemplateNotFoundError" }
    | {
        __typename?: "ConfigurationTemplateResult";
        configurationTemplate?: {
          __typename?: "ConfigurationTemplate";
          id: string;
          name: string;
          requireMfaToApprove: boolean;
          requireMfaToConnect: boolean;
          visibility: Visibility;
          customRequestNotification?: string | null;
          adminOwner: {
            __typename?: "Owner";
            id: string;
            name: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
            } | null;
          };
          visibilityGroups: Array<{ __typename?: "Group"; id: string }>;
          auditMessageChannels: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }>;
          breakGlassUsers: Array<{
            __typename?: "User";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isSystemUser: boolean;
          }>;
          onCallSchedules: Array<{
            __typename?: "OnCallSchedule";
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            name: string;
            existsInRemote: boolean;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
        } | null;
      };
};

export type ConfigurationTemplateFragment = {
  __typename?: "ConfigurationTemplate";
  id: string;
  name: string;
  requireMfaToApprove: boolean;
  requireMfaToConnect: boolean;
  visibility: Visibility;
  customRequestNotification?: string | null;
  adminOwner: {
    __typename?: "Owner";
    id: string;
    name: string;
    sourceGroup?: {
      __typename?: "Group";
      id: string;
      name: string;
      groupType: GroupType;
    } | null;
  };
  visibilityGroups: Array<{ __typename?: "Group"; id: string }>;
  auditMessageChannels: Array<{
    __typename?: "MessageChannel";
    id: string;
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    isPrivate: boolean;
    name: string;
  }>;
  breakGlassUsers: Array<{
    __typename?: "User";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isSystemUser: boolean;
  }>;
  onCallSchedules: Array<{
    __typename?: "OnCallSchedule";
    remoteId: string;
    thirdPartyProvider: ThirdPartyProvider;
    name: string;
    existsInRemote: boolean;
  }>;
  requestConfigs: Array<{
    __typename?: "RequestConfiguration";
    id: string;
    priority: number;
    groupId?: string | null;
    groupIds?: Array<string> | null;
    roleIds?: Array<string> | null;
    maxDurationInMinutes?: number | null;
    recommendedDurationInMinutes?: number | null;
    requireMfaToRequest: boolean;
    requireSupportTicket: boolean;
    isRequestable: boolean;
    autoApproval: boolean;
    reasonOptional: boolean;
    requestTemplateId?: string | null;
    reviewerStages: Array<{
      __typename?: "ReviewerStage";
      id: string;
      stage: number;
      requireManagerApproval: boolean;
      requireAdminApproval: boolean;
      operator: ReviewStageOperator;
      owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
    }>;
  }>;
  ticketPropagation?: {
    __typename?: "TicketPropagation";
    onGrant: boolean;
    onRevocation: boolean;
    ticketProvider?: {
      __typename?: "TicketProvider";
      ticketProvider: ThirdPartyProvider;
      ticketProjectId: string;
      ticketProjectName: string;
    } | null;
  } | null;
};

export type ListConfigurationsTemplatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListConfigurationsTemplatesQuery = {
  __typename?: "Query";
  configurationTemplates: {
    __typename?: "ConfigurationTemplatesResult";
    configurationTemplates: Array<{
      __typename?: "ConfigurationTemplate";
      id: string;
      name: string;
    }>;
  };
};

export type RequestTemplatesViewQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RequestTemplatesViewQuery = {
  __typename?: "Query";
  requestTemplatesWithFields: {
    __typename: "RequestTemplatesWithFieldsResult";
    requestTemplates: Array<{
      __typename?: "RequestTemplate";
      id: string;
      name: string;
      customFields?: Array<{
        __typename?: "RequestTemplateCustomField";
        id: string;
        name: string;
      }> | null;
    }>;
  };
};

export type ConfigTemplateTagsQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigTemplateTagsQuery = {
  __typename?: "Query";
  configTemplateTags: {
    __typename?: "ConfigTemplateTagsResult";
    configTemplateTags: Array<{
      __typename?: "ConfigTemplateTag";
      id: string;
      priority: number;
      configTemplate?: {
        __typename?: "ConfigurationTemplate";
        id: string;
        name: string;
      } | null;
      tag: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      };
    }>;
  };
};

export type CreateConfigurationTemplateMutationVariables = Exact<{
  input: CreateConfigurationTemplateInput;
}>;

export type CreateConfigurationTemplateMutation = {
  __typename?: "Mutation";
  createConfigurationTemplate:
    | { __typename?: "ConfigurationTemplateNameExistsError"; message: string }
    | {
        __typename?: "CreateConfigurationTemplateResult";
        configurationTemplate: {
          __typename?: "ConfigurationTemplate";
          id: string;
          name: string;
          requireMfaToApprove: boolean;
          requireMfaToConnect: boolean;
          visibility: Visibility;
          customRequestNotification?: string | null;
          adminOwner: {
            __typename?: "Owner";
            id: string;
            name: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
            } | null;
          };
          visibilityGroups: Array<{ __typename?: "Group"; id: string }>;
          auditMessageChannels: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }>;
          breakGlassUsers: Array<{
            __typename?: "User";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isSystemUser: boolean;
          }>;
          onCallSchedules: Array<{
            __typename?: "OnCallSchedule";
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            name: string;
            existsInRemote: boolean;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
        };
      };
};

export type DeleteConfigurationTemplateMutationVariables = Exact<{
  id: Scalars["ConfigurationId"];
}>;

export type DeleteConfigurationTemplateMutation = {
  __typename?: "Mutation";
  deleteConfigurationTemplate:
    | { __typename?: "ConfigurationInUseError"; message: string }
    | { __typename?: "DeleteConfigurationTemplateResult"; id: string };
};

export type UpdateConfigTemplateTagsMutationVariables = Exact<{
  input: UpdateConfigTemplateTagsInput;
}>;

export type UpdateConfigTemplateTagsMutation = {
  __typename?: "Mutation";
  updateConfigTemplateTags: {
    __typename?: "UpdateConfigTemplateTagsResult";
    success: boolean;
  };
};

export type UpdateConfigurationTemplateMutationVariables = Exact<{
  input: UpdateConfigurationTemplateInput;
}>;

export type UpdateConfigurationTemplateMutation = {
  __typename?: "Mutation";
  updateConfigurationTemplate:
    | { __typename?: "ConfigurationTemplateNameExistsError"; message: string }
    | {
        __typename?: "UpdateConfigurationTemplateResult";
        configurationTemplate: {
          __typename?: "ConfigurationTemplate";
          id: string;
          name: string;
          requireMfaToApprove: boolean;
          requireMfaToConnect: boolean;
          visibility: Visibility;
          customRequestNotification?: string | null;
          adminOwner: {
            __typename?: "Owner";
            id: string;
            name: string;
            sourceGroup?: {
              __typename?: "Group";
              id: string;
              name: string;
              groupType: GroupType;
            } | null;
          };
          visibilityGroups: Array<{ __typename?: "Group"; id: string }>;
          auditMessageChannels: Array<{
            __typename?: "MessageChannel";
            id: string;
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            isPrivate: boolean;
            name: string;
          }>;
          breakGlassUsers: Array<{
            __typename?: "User";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isSystemUser: boolean;
          }>;
          onCallSchedules: Array<{
            __typename?: "OnCallSchedule";
            remoteId: string;
            thirdPartyProvider: ThirdPartyProvider;
            name: string;
            existsInRemote: boolean;
          }>;
          requestConfigs: Array<{
            __typename?: "RequestConfiguration";
            id: string;
            priority: number;
            groupId?: string | null;
            groupIds?: Array<string> | null;
            roleIds?: Array<string> | null;
            maxDurationInMinutes?: number | null;
            recommendedDurationInMinutes?: number | null;
            requireMfaToRequest: boolean;
            requireSupportTicket: boolean;
            isRequestable: boolean;
            autoApproval: boolean;
            reasonOptional: boolean;
            requestTemplateId?: string | null;
            reviewerStages: Array<{
              __typename?: "ReviewerStage";
              id: string;
              stage: number;
              requireManagerApproval: boolean;
              requireAdminApproval: boolean;
              operator: ReviewStageOperator;
              owners: Array<{ __typename?: "Owner"; id: string; name: string }>;
            }>;
          }>;
          ticketPropagation?: {
            __typename?: "TicketPropagation";
            onGrant: boolean;
            onRevocation: boolean;
            ticketProvider?: {
              __typename?: "TicketProvider";
              ticketProvider: ThirdPartyProvider;
              ticketProjectId: string;
              ticketProjectName: string;
            } | null;
          } | null;
        };
      };
};

export type ManagersQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type ManagersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersResult";
    cursor?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      fullName: string;
      avatarUrl: string;
    }>;
  };
};

export type TeamsQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type TeamsQuery = {
  __typename?: "Query";
  teams: {
    __typename?: "TeamsResult";
    cursor?: string | null;
    teams: Array<{ __typename?: "Team"; name: string; numUsers: number }>;
  };
};

export type TitlesQueryVariables = Exact<{
  searchQuery?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
}>;

export type TitlesQuery = {
  __typename?: "Query";
  titles: {
    __typename?: "TitlesResult";
    cursor?: string | null;
    titles: Array<{ __typename?: "Title"; name: string; numUsers: number }>;
  };
};

export type UserDetailQueryVariables = Exact<{
  id: Scalars["UserId"];
}>;

export type UserDetailQuery = {
  __typename?: "Query";
  user:
    | {
        __typename?: "UserNotFoundError";
        message: string;
        email?: string | null;
      }
    | {
        __typename?: "UserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          createdAt: string;
          updatedAt: string;
          organizationId: string;
          position: string;
          teamAttr?: string | null;
          managerId?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          numResources: number;
          numGroups: number;
          manager?: {
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            position: string;
            teamAttr?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
          } | null;
          identities?: {
            __typename?: "UserIdentities";
            gitHubUsername?: {
              __typename?: "UserIdentity";
              shouldShow: boolean;
              value: string;
            } | null;
            gitLabUserId?: {
              __typename?: "UserIdentity";
              shouldShow: boolean;
              value: string;
            } | null;
            teleportUsername?: {
              __typename?: "UserIdentity";
              shouldShow: boolean;
              value: string;
            } | null;
          } | null;
          directReports: Array<{
            __typename?: "User";
            id: string;
            email: string;
            secondaryEmails: Array<string>;
            firstName: string;
            lastName: string;
            fullName: string;
            avatarUrl: string;
            isDeleted: boolean;
            isSystemUser: boolean;
            createdAt: string;
            updatedAt: string;
            organizationId: string;
            position: string;
            teamAttr?: string | null;
            managerId?: string | null;
            hrIdpStatus?: HrIdpStatus | null;
            manager?: {
              __typename?: "User";
              id: string;
              email: string;
              secondaryEmails: Array<string>;
              firstName: string;
              lastName: string;
              fullName: string;
              avatarUrl: string;
              isDeleted: boolean;
              isSystemUser: boolean;
              position: string;
              teamAttr?: string | null;
              hrIdpStatus?: HrIdpStatus | null;
            } | null;
          }>;
        };
      };
  userAttributes: {
    __typename?: "UserTagsResult";
    userTags: Array<{
      __typename?: "UserTag";
      id: string;
      userId: string;
      tagId: string;
      source: ServiceType;
      updatedAt: string;
      createdAt: string;
      user?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        createdAt: string;
        updatedAt: string;
        organizationId: string;
        position: string;
        teamAttr?: string | null;
        managerId?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
        manager?: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
        } | null;
      } | null;
      tag?: {
        __typename: "Tag";
        id: string;
        createdAt: string;
        creatorUserId: string;
        key: string;
        value?: string | null;
        isStandardAttribute: boolean;
      } | null;
    }>;
  };
};

export type UserResourcesQueryVariables = Exact<{
  id: Scalars["UserId"];
}>;

export type UserResourcesQuery = {
  __typename?: "Query";
  user:
    | { __typename?: "UserNotFoundError" }
    | {
        __typename?: "UserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          userResources: Array<{
            __typename?: "ResourceUser";
            resourceId: string;
            userId: string;
            source: ResourceUserSource;
            resource?: {
              __typename: "Resource";
              id: string;
              name: string;
              remoteName: string;
              serviceType: ServiceType;
              visibility: Visibility;
              isDeleted: boolean;
              isManaged: boolean;
              resourceType: ResourceType;
              remoteId: string;
              iconUrl?: string | null;
              parentResourceId?: string | null;
              connectionId: string;
              createdAt: string;
              parentResource?: {
                __typename?: "Resource";
                id: string;
                name: string;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              metadata?:
                | { __typename: "AwsRoleMetadata"; arn: string }
                | {
                    __typename: "OktaDirectoryAppMetadata";
                    appId: string;
                    appName: string;
                    logoUrl?: string | null;
                  }
                | {
                    __typename: "OktaRoleMetadata";
                    roleId: string;
                    roleType: string;
                  }
                | {
                    __typename: "PropagationTicketOwnerMetadata";
                    ownerId?: string | null;
                    owner?: {
                      __typename?: "Owner";
                      id: string;
                      name: string;
                    } | null;
                  }
                | null;
            } | null;
            accessLevel: {
              __typename: "ResourceAccessLevel";
              accessLevelName: string;
              accessLevelRemoteId: string;
              accessLevelMetadata?: {
                __typename: "ImpersonationAccessLevelMetadata";
                avatarUrl: string;
              } | null;
            };
            access?: {
              __typename?: "ResourceUserAccess";
              resourceId: string;
              userId: string;
              accessLevel: {
                __typename: "ResourceAccessLevel";
                accessLevelName: string;
                accessLevelRemoteId: string;
                accessLevelMetadata?: {
                  __typename: "ImpersonationAccessLevelMetadata";
                  avatarUrl: string;
                } | null;
              };
              latestExpiringAccessPoint: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              inheritedAccessPoints: Array<{
                __typename?: "ResourceUserAccessPoint";
                resourceId: string;
                userId: string;
                resourceName: string;
                resourceType: ResourceType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                accessLevel: {
                  __typename: "ResourceAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                  accessLevelMetadata?: {
                    __typename: "ImpersonationAccessLevelMetadata";
                    avatarUrl: string;
                  } | null;
                };
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              }>;
              groupUserAccesses: Array<{
                __typename?: "GroupUserAccess";
                groupId: string;
                userId: string;
                isGroupManaged: boolean;
                latestExpiringAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                directAccessPoint?: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                } | null;
                indirectAccessPointPaths: Array<{
                  __typename?: "IndirectGroupUserAccessPointPath";
                  startGroupId: string;
                  endGroupId: string;
                  userId: string;
                  resolvedAccessPoint: {
                    __typename?: "GroupUserAccessPoint";
                    groupId: string;
                    userId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                    expiration?: string | null;
                    supportTicketId?: string | null;
                    source: GroupUserSource;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    supportTicket?: {
                      __typename?: "SupportTicket";
                      id?: string | null;
                      remoteId: string;
                      url: string;
                      identifier: string;
                      thirdPartyProvider: ThirdPartyProvider;
                      projectName: string;
                    } | null;
                  };
                  indirectAccessPointPath: Array<{
                    __typename?: "IndirectGroupAccessPoint";
                    groupId: string;
                    groupName: string;
                    groupType: GroupType;
                    eventId: string;
                    createdAt: string;
                  }>;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "ResourcePropagationStatus";
              resourceId: string;
              userId: string;
              accessLevelRemoteId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              metadata?: {
                __typename: "PropagationTicketMetadata";
                ticket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
            } | null;
          }>;
        };
      };
};

export type UserGroupsQueryVariables = Exact<{
  id: Scalars["UserId"];
}>;

export type UserGroupsQuery = {
  __typename?: "Query";
  user:
    | { __typename?: "UserNotFoundError" }
    | {
        __typename?: "UserResult";
        user: {
          __typename?: "User";
          id: string;
          email: string;
          secondaryEmails: Array<string>;
          firstName: string;
          lastName: string;
          fullName: string;
          avatarUrl: string;
          isDeleted: boolean;
          isSystemUser: boolean;
          position: string;
          teamAttr?: string | null;
          hrIdpStatus?: HrIdpStatus | null;
          userGroups: Array<{
            __typename?: "GroupUser";
            groupId: string;
            userId: string;
            source: GroupUserSource;
            group?: {
              __typename: "Group";
              id: string;
              remoteId?: string | null;
              name: string;
              remoteName: string;
              groupType: GroupType;
              serviceType: ServiceType;
              iconUrl?: string | null;
              visibility: Visibility;
              isDeleted: boolean;
              createdAt: string;
              updatedAt: string;
              description: string;
              numGroupUsers: number;
              connectionId: string;
              isOnCallSynced: boolean;
              maxDurationInMinutes?: number | null;
              recommendedDurationInMinutes?: number | null;
              requireMfaToRequest: boolean;
              requireSupportTicket: boolean;
              isRequestable: boolean;
              isManaged: boolean;
              autoApproval: boolean;
              reasonOptional: boolean;
              requestTemplateId?: string | null;
              groupBindingId?: string | null;
              customRequestNotification?: string | null;
              adminOwner?: {
                __typename?: "Owner";
                id: string;
                name: string;
                ownerUsers: Array<{
                  __typename?: "OwnerUser";
                  user?: { __typename?: "User"; id: string } | null;
                }>;
              } | null;
              connection?: {
                __typename: "Connection";
                id: string;
                name: string;
                connectionType: ConnectionType;
                isDeleted: boolean;
                metadata?:
                  | {
                      __typename: "ADConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                    }
                  | {
                      __typename: "AwsConnectionMetadata";
                      success?: boolean | null;
                    }
                  | {
                      __typename: "AWSSSOConnectionMetadata";
                      managementAccountId: string;
                      ssoInstanceArn: string;
                      identityStoreId: string;
                      accessPortalUrl: string;
                      awsRegion: string;
                      awsOrganizationEnabled: boolean;
                      awsSsoEnabled: boolean;
                      awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                      awsOrganizationImportSetting: ImportSetting;
                      ec2RoleChainingEnabled: boolean;
                      podRoleChainingEnabled: boolean;
                      cloudtrailEventsSqsUrl: string;
                    }
                  | {
                      __typename: "AzureADConnectionMetadata";
                      tenantId: string;
                      clientId: string;
                      azureInfraEnabled: boolean;
                      eventHubNamespace: string;
                      eventHub: string;
                    }
                  | {
                      __typename: "CustomConnectorConnectionMetadata";
                      identifier: string;
                      baseUrl: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                      supportsGroups: boolean;
                      supportsNestedResources: boolean;
                    }
                  | { __typename: "DuoConnectionMetadata"; apiHostname: string }
                  | {
                      __typename: "GCPConnectionMetadata";
                      pubSubProjectId: string;
                      subscriptionId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | { __typename: "GitHubConnectionMetadata"; orgName: string }
                  | {
                      __typename: "GitLabConnectionMetadata";
                      hostName?: string | null;
                      appId: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "GoogleGroupsConnectionMetadata";
                      opalGroupEmail: string;
                      adminUserEmail: string;
                      domain: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "GoogleWorkspaceConnectionMetadata";
                      adminUserEmail: string;
                      customerId: string;
                      serviceAccount: {
                        __typename?: "GCPServiceAccount";
                        type: string;
                        projectId: string;
                        clientEmail: string;
                        clientId: string;
                        authUri: string;
                        tokenUri: string;
                        authProviderX509CertUrl: string;
                        clientX509CertUrl: string;
                      };
                    }
                  | {
                      __typename: "LDAPConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      groupsDistinguishedName: string;
                      usersDistinguishedName: string;
                      groupsUidKey: string;
                    }
                  | {
                      __typename: "MongoAtlasConnectionMetadata";
                      serverHostname: string;
                    }
                  | {
                      __typename: "MongoConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "MySQLMariaDBConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "OktaDirectoryConnectionMetadata";
                      orgUrl: string;
                    }
                  | { __typename: "PagerDutyConnectionMetadata" }
                  | {
                      __typename: "PostgresConnectionMetadata";
                      serverHostname: string;
                      serverPort: number;
                      tlsMode: boolean;
                      tlsCertContent?: string | null;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "PropagationTicketConnectionMetadata";
                      ticketProvider?: {
                        __typename?: "TicketProvider";
                        ticketProvider: ThirdPartyProvider;
                        ticketProjectId: string;
                        ticketProjectName: string;
                      } | null;
                    }
                  | {
                      __typename: "SalesforceConnectionMetadata";
                      email: string;
                      hostname: string;
                    }
                  | {
                      __typename: "SnowflakeConnectionMetadata";
                      organization: string;
                      account: string;
                      locator: string;
                      accountIdentifier: string;
                      accountUrl: string;
                      serviceAccountName: string;
                      serviceAccountRole: string;
                    }
                  | {
                      __typename: "TailscaleConnectionMetadata";
                      tailnet: string;
                    }
                  | {
                      __typename: "TeleportConnectionMetadata";
                      host: string;
                      tlsMode: boolean;
                      tlsCaCertContent?: string | null;
                    }
                  | {
                      __typename: "WorkdayConnectionMetadata";
                      tenantName: string;
                      tenantUrl: string;
                    }
                  | null;
              } | null;
              currentUserAccess: {
                __typename?: "CurrentUserGroupAccess";
                groupId: string;
                hasBreakGlass: boolean;
                groupUser?: {
                  __typename?: "GroupUser";
                  groupId: string;
                  userId: string;
                  source: GroupUserSource;
                  access?: {
                    __typename?: "GroupUserAccess";
                    groupId: string;
                    userId: string;
                    isGroupManaged: boolean;
                    latestExpiringAccessPoint: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    };
                    directAccessPoint?: {
                      __typename?: "GroupUserAccessPoint";
                      groupId: string;
                      userId: string;
                      groupName: string;
                      groupType: GroupType;
                      eventId: string;
                      createdAt: string;
                      expiration?: string | null;
                      supportTicketId?: string | null;
                      source: GroupUserSource;
                      accessLevel?: {
                        __typename: "GroupAccessLevel";
                        accessLevelName: string;
                        accessLevelRemoteId: string;
                      } | null;
                      supportTicket?: {
                        __typename?: "SupportTicket";
                        id?: string | null;
                        remoteId: string;
                        url: string;
                        identifier: string;
                        thirdPartyProvider: ThirdPartyProvider;
                        projectName: string;
                      } | null;
                    } | null;
                    indirectAccessPointPaths: Array<{
                      __typename?: "IndirectGroupUserAccessPointPath";
                      startGroupId: string;
                      endGroupId: string;
                      userId: string;
                      resolvedAccessPoint: {
                        __typename?: "GroupUserAccessPoint";
                        groupId: string;
                        userId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                        expiration?: string | null;
                        supportTicketId?: string | null;
                        source: GroupUserSource;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                        supportTicket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      };
                      indirectAccessPointPath: Array<{
                        __typename?: "IndirectGroupAccessPoint";
                        groupId: string;
                        groupName: string;
                        groupType: GroupType;
                        eventId: string;
                        createdAt: string;
                      }>;
                    }>;
                  } | null;
                  propagationStatus?: {
                    __typename?: "GroupPropagationStatus";
                    groupId: string;
                    userId: string;
                    lastSynced: string;
                    statusCode: PropagationStatusCode;
                    errorMessage?: string | null;
                    taskType: PropagationTaskType;
                    ticketPropagation?: {
                      __typename: "GroupUserTicketPropagation";
                      resourceTickets: Array<{
                        __typename?: "ResourceTicketPropagation";
                        ticket?: {
                          __typename?: "SupportTicket";
                          id?: string | null;
                          remoteId: string;
                          url: string;
                          identifier: string;
                          thirdPartyProvider: ThirdPartyProvider;
                          projectName: string;
                        } | null;
                      }>;
                    } | null;
                  } | null;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                } | null;
                pendingRequest?: {
                  __typename?: "Request";
                  id: string;
                  requesterId: string;
                  targetUserId?: string | null;
                  targetGroupId?: string | null;
                  resourceProposalId?: string | null;
                  createdAt: string;
                  updatedAt: string;
                  status: RequestStatus;
                  requester?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetUser?: {
                    __typename?: "User";
                    id: string;
                    email: string;
                    secondaryEmails: Array<string>;
                    firstName: string;
                    lastName: string;
                    fullName: string;
                    avatarUrl: string;
                    isDeleted: boolean;
                    isSystemUser: boolean;
                    position: string;
                    teamAttr?: string | null;
                    hrIdpStatus?: HrIdpStatus | null;
                  } | null;
                  targetGroup?: {
                    __typename?: "Group";
                    id: string;
                    remoteId?: string | null;
                    name: string;
                    remoteName: string;
                    numGroupUsers: number;
                    groupType: GroupType;
                    serviceType: ServiceType;
                    visibility: Visibility;
                    isManaged: boolean;
                    isDeleted: boolean;
                    iconUrl?: string | null;
                    isOnCallSynced: boolean;
                    connectionId: string;
                    groupBindingId?: string | null;
                    visibilityGroups: Array<{
                      __typename?: "ConfigurationVisibilityGroup";
                      visibilityGroupId: string;
                      visibilityGroup: { __typename?: "Group"; name: string };
                    }>;
                    connection?: {
                      __typename?: "Connection";
                      id: string;
                      name: string;
                      connectionType: ConnectionType;
                    } | null;
                    groupBinding?: {
                      __typename?: "GroupBinding";
                      id: string;
                      sourceGroupId: string;
                      groups: Array<{
                        __typename?: "Group";
                        id: string;
                        name: string;
                        groupType: GroupType;
                        connectionId: string;
                      }>;
                    } | null;
                  } | null;
                  requestedResources: Array<{
                    __typename?: "RequestedResource";
                    resourceId: string;
                    resource?: {
                      __typename: "Resource";
                      id: string;
                      name: string;
                      remoteName: string;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isDeleted: boolean;
                      isManaged: boolean;
                      resourceType: ResourceType;
                      remoteId: string;
                      iconUrl?: string | null;
                      parentResourceId?: string | null;
                      connectionId: string;
                      createdAt: string;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      parentResource?: {
                        __typename?: "Resource";
                        id: string;
                        name: string;
                      } | null;
                      connection?: {
                        __typename: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                        isDeleted: boolean;
                        metadata?:
                          | {
                              __typename: "ADConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                            }
                          | {
                              __typename: "AwsConnectionMetadata";
                              success?: boolean | null;
                            }
                          | {
                              __typename: "AWSSSOConnectionMetadata";
                              managementAccountId: string;
                              ssoInstanceArn: string;
                              identityStoreId: string;
                              accessPortalUrl: string;
                              awsRegion: string;
                              awsOrganizationEnabled: boolean;
                              awsSsoEnabled: boolean;
                              awsIdentityCenterImportSetting: AwsIdentityCenterImportSetting;
                              awsOrganizationImportSetting: ImportSetting;
                              ec2RoleChainingEnabled: boolean;
                              podRoleChainingEnabled: boolean;
                              cloudtrailEventsSqsUrl: string;
                            }
                          | {
                              __typename: "AzureADConnectionMetadata";
                              tenantId: string;
                              clientId: string;
                              azureInfraEnabled: boolean;
                              eventHubNamespace: string;
                              eventHub: string;
                            }
                          | {
                              __typename: "CustomConnectorConnectionMetadata";
                              identifier: string;
                              baseUrl: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                              supportsGroups: boolean;
                              supportsNestedResources: boolean;
                            }
                          | {
                              __typename: "DuoConnectionMetadata";
                              apiHostname: string;
                            }
                          | {
                              __typename: "GCPConnectionMetadata";
                              pubSubProjectId: string;
                              subscriptionId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GitHubConnectionMetadata";
                              orgName: string;
                            }
                          | {
                              __typename: "GitLabConnectionMetadata";
                              hostName?: string | null;
                              appId: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "GoogleGroupsConnectionMetadata";
                              opalGroupEmail: string;
                              adminUserEmail: string;
                              domain: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "GoogleWorkspaceConnectionMetadata";
                              adminUserEmail: string;
                              customerId: string;
                              serviceAccount: {
                                __typename?: "GCPServiceAccount";
                                type: string;
                                projectId: string;
                                clientEmail: string;
                                clientId: string;
                                authUri: string;
                                tokenUri: string;
                                authProviderX509CertUrl: string;
                                clientX509CertUrl: string;
                              };
                            }
                          | {
                              __typename: "LDAPConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              groupsDistinguishedName: string;
                              usersDistinguishedName: string;
                              groupsUidKey: string;
                            }
                          | {
                              __typename: "MongoAtlasConnectionMetadata";
                              serverHostname: string;
                            }
                          | {
                              __typename: "MongoConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "MySQLMariaDBConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "OktaDirectoryConnectionMetadata";
                              orgUrl: string;
                            }
                          | { __typename: "PagerDutyConnectionMetadata" }
                          | {
                              __typename: "PostgresConnectionMetadata";
                              serverHostname: string;
                              serverPort: number;
                              tlsMode: boolean;
                              tlsCertContent?: string | null;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "PropagationTicketConnectionMetadata";
                              ticketProvider?: {
                                __typename?: "TicketProvider";
                                ticketProvider: ThirdPartyProvider;
                                ticketProjectId: string;
                                ticketProjectName: string;
                              } | null;
                            }
                          | {
                              __typename: "SalesforceConnectionMetadata";
                              email: string;
                              hostname: string;
                            }
                          | {
                              __typename: "SnowflakeConnectionMetadata";
                              organization: string;
                              account: string;
                              locator: string;
                              accountIdentifier: string;
                              accountUrl: string;
                              serviceAccountName: string;
                              serviceAccountRole: string;
                            }
                          | {
                              __typename: "TailscaleConnectionMetadata";
                              tailnet: string;
                            }
                          | {
                              __typename: "TeleportConnectionMetadata";
                              host: string;
                              tlsMode: boolean;
                              tlsCaCertContent?: string | null;
                            }
                          | {
                              __typename: "WorkdayConnectionMetadata";
                              tenantName: string;
                              tenantUrl: string;
                            }
                          | null;
                      } | null;
                      metadata?:
                        | { __typename: "AwsRoleMetadata"; arn: string }
                        | {
                            __typename: "OktaDirectoryAppMetadata";
                            appId: string;
                            appName: string;
                            logoUrl?: string | null;
                          }
                        | {
                            __typename: "OktaRoleMetadata";
                            roleId: string;
                            roleType: string;
                          }
                        | {
                            __typename: "PropagationTicketOwnerMetadata";
                            ownerId?: string | null;
                            owner?: {
                              __typename?: "Owner";
                              id: string;
                              name: string;
                            } | null;
                          }
                        | null;
                    } | null;
                    accessLevel: {
                      __typename: "ResourceAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                      accessLevelMetadata?: {
                        __typename: "ImpersonationAccessLevelMetadata";
                        avatarUrl: string;
                      } | null;
                    };
                    targetUserAccess?: {
                      __typename?: "UserResourceAccess";
                      resourceId: string;
                      userId: string;
                      resourceUsers: Array<{
                        __typename: "ResourceUser";
                        resourceId: string;
                        userId: string;
                        mostRecentSessionEnded?: string | null;
                        source: ResourceUserSource;
                        user?: {
                          __typename?: "User";
                          id: string;
                          email: string;
                          secondaryEmails: Array<string>;
                          firstName: string;
                          lastName: string;
                          fullName: string;
                          avatarUrl: string;
                          isDeleted: boolean;
                          isSystemUser: boolean;
                          createdAt: string;
                          updatedAt: string;
                          organizationId: string;
                          position: string;
                          teamAttr?: string | null;
                          managerId?: string | null;
                          hrIdpStatus?: HrIdpStatus | null;
                          manager?: {
                            __typename?: "User";
                            id: string;
                            email: string;
                            secondaryEmails: Array<string>;
                            firstName: string;
                            lastName: string;
                            fullName: string;
                            avatarUrl: string;
                            isDeleted: boolean;
                            isSystemUser: boolean;
                            position: string;
                            teamAttr?: string | null;
                            hrIdpStatus?: HrIdpStatus | null;
                          } | null;
                        } | null;
                        accessLevel: {
                          __typename: "ResourceAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                          accessLevelMetadata?: {
                            __typename: "ImpersonationAccessLevelMetadata";
                            avatarUrl: string;
                          } | null;
                        };
                        access?: {
                          __typename?: "ResourceUserAccess";
                          resourceId: string;
                          userId: string;
                          accessLevel: {
                            __typename: "ResourceAccessLevel";
                            accessLevelName: string;
                            accessLevelRemoteId: string;
                            accessLevelMetadata?: {
                              __typename: "ImpersonationAccessLevelMetadata";
                              avatarUrl: string;
                            } | null;
                          };
                          latestExpiringAccessPoint: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          inheritedAccessPoints: Array<{
                            __typename?: "ResourceUserAccessPoint";
                            resourceId: string;
                            userId: string;
                            resourceName: string;
                            resourceType: ResourceType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            accessLevel: {
                              __typename: "ResourceAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                              accessLevelMetadata?: {
                                __typename: "ImpersonationAccessLevelMetadata";
                                avatarUrl: string;
                              } | null;
                            };
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          }>;
                          groupUserAccesses: Array<{
                            __typename?: "GroupUserAccess";
                            groupId: string;
                            userId: string;
                            isGroupManaged: boolean;
                            latestExpiringAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            directAccessPoint?: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            } | null;
                            indirectAccessPointPaths: Array<{
                              __typename?: "IndirectGroupUserAccessPointPath";
                              startGroupId: string;
                              endGroupId: string;
                              userId: string;
                              resolvedAccessPoint: {
                                __typename?: "GroupUserAccessPoint";
                                groupId: string;
                                userId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                                expiration?: string | null;
                                supportTicketId?: string | null;
                                source: GroupUserSource;
                                accessLevel?: {
                                  __typename: "GroupAccessLevel";
                                  accessLevelName: string;
                                  accessLevelRemoteId: string;
                                } | null;
                                supportTicket?: {
                                  __typename?: "SupportTicket";
                                  id?: string | null;
                                  remoteId: string;
                                  url: string;
                                  identifier: string;
                                  thirdPartyProvider: ThirdPartyProvider;
                                  projectName: string;
                                } | null;
                              };
                              indirectAccessPointPath: Array<{
                                __typename?: "IndirectGroupAccessPoint";
                                groupId: string;
                                groupName: string;
                                groupType: GroupType;
                                eventId: string;
                                createdAt: string;
                              }>;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "ResourcePropagationStatus";
                          resourceId: string;
                          userId: string;
                          accessLevelRemoteId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          metadata?: {
                            __typename: "PropagationTicketMetadata";
                            ticket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                        } | null;
                      }>;
                    } | null;
                  }>;
                  requestedGroups: Array<{
                    __typename?: "RequestedGroup";
                    groupId: string;
                    group?: {
                      __typename?: "Group";
                      id: string;
                      remoteId?: string | null;
                      name: string;
                      remoteName: string;
                      numGroupUsers: number;
                      groupType: GroupType;
                      serviceType: ServiceType;
                      visibility: Visibility;
                      isManaged: boolean;
                      isDeleted: boolean;
                      iconUrl?: string | null;
                      isOnCallSynced: boolean;
                      connectionId: string;
                      groupBindingId?: string | null;
                      visibilityGroups: Array<{
                        __typename?: "ConfigurationVisibilityGroup";
                        visibilityGroupId: string;
                        visibilityGroup: { __typename?: "Group"; name: string };
                      }>;
                      connection?: {
                        __typename?: "Connection";
                        id: string;
                        name: string;
                        connectionType: ConnectionType;
                      } | null;
                      groupBinding?: {
                        __typename?: "GroupBinding";
                        id: string;
                        sourceGroupId: string;
                        groups: Array<{
                          __typename?: "Group";
                          id: string;
                          name: string;
                          groupType: GroupType;
                          connectionId: string;
                        }>;
                      } | null;
                    } | null;
                    accessLevel?: {
                      __typename: "GroupAccessLevel";
                      accessLevelName: string;
                      accessLevelRemoteId: string;
                    } | null;
                    targetUserAccess?: {
                      __typename?: "UserGroupAccess";
                      groupId: string;
                      userId: string;
                      groupUser?: {
                        __typename?: "GroupUser";
                        groupId: string;
                        userId: string;
                        source: GroupUserSource;
                        access?: {
                          __typename?: "GroupUserAccess";
                          groupId: string;
                          userId: string;
                          isGroupManaged: boolean;
                          latestExpiringAccessPoint: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          };
                          directAccessPoint?: {
                            __typename?: "GroupUserAccessPoint";
                            groupId: string;
                            userId: string;
                            groupName: string;
                            groupType: GroupType;
                            eventId: string;
                            createdAt: string;
                            expiration?: string | null;
                            supportTicketId?: string | null;
                            source: GroupUserSource;
                            accessLevel?: {
                              __typename: "GroupAccessLevel";
                              accessLevelName: string;
                              accessLevelRemoteId: string;
                            } | null;
                            supportTicket?: {
                              __typename?: "SupportTicket";
                              id?: string | null;
                              remoteId: string;
                              url: string;
                              identifier: string;
                              thirdPartyProvider: ThirdPartyProvider;
                              projectName: string;
                            } | null;
                          } | null;
                          indirectAccessPointPaths: Array<{
                            __typename?: "IndirectGroupUserAccessPointPath";
                            startGroupId: string;
                            endGroupId: string;
                            userId: string;
                            resolvedAccessPoint: {
                              __typename?: "GroupUserAccessPoint";
                              groupId: string;
                              userId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                              expiration?: string | null;
                              supportTicketId?: string | null;
                              source: GroupUserSource;
                              accessLevel?: {
                                __typename: "GroupAccessLevel";
                                accessLevelName: string;
                                accessLevelRemoteId: string;
                              } | null;
                              supportTicket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            };
                            indirectAccessPointPath: Array<{
                              __typename?: "IndirectGroupAccessPoint";
                              groupId: string;
                              groupName: string;
                              groupType: GroupType;
                              eventId: string;
                              createdAt: string;
                            }>;
                          }>;
                        } | null;
                        propagationStatus?: {
                          __typename?: "GroupPropagationStatus";
                          groupId: string;
                          userId: string;
                          lastSynced: string;
                          statusCode: PropagationStatusCode;
                          errorMessage?: string | null;
                          taskType: PropagationTaskType;
                          ticketPropagation?: {
                            __typename: "GroupUserTicketPropagation";
                            resourceTickets: Array<{
                              __typename?: "ResourceTicketPropagation";
                              ticket?: {
                                __typename?: "SupportTicket";
                                id?: string | null;
                                remoteId: string;
                                url: string;
                                identifier: string;
                                thirdPartyProvider: ThirdPartyProvider;
                                projectName: string;
                              } | null;
                            }>;
                          } | null;
                        } | null;
                        accessLevel?: {
                          __typename: "GroupAccessLevel";
                          accessLevelName: string;
                          accessLevelRemoteId: string;
                        } | null;
                      } | null;
                    } | null;
                  }>;
                  resourceProposal?: {
                    __typename?: "ResourceProposal";
                    id: string;
                    connectionId: string;
                    serviceType: ServiceType;
                    resourceType: ResourceType;
                    metadata?: {
                      __typename: "AWSRoleProposalMetadata";
                      policyName: string;
                      policyDocument: string;
                      policyDescription: string;
                      assumeRolePolicyDocument: string;
                      roleName: string;
                      roleDescription: string;
                      tags: Array<{
                        __typename?: "AWSTag";
                        key: string;
                        value?: string | null;
                      }>;
                    } | null;
                  } | null;
                } | null;
              };
              tags: Array<{
                __typename?: "GroupTag";
                groupId: string;
                tagId: string;
                source: ServiceType;
                updatedAt: string;
                createdAt: string;
                group?: {
                  __typename?: "Group";
                  id: string;
                  remoteId?: string | null;
                  name: string;
                  remoteName: string;
                  numGroupUsers: number;
                  groupType: GroupType;
                  serviceType: ServiceType;
                  visibility: Visibility;
                  isManaged: boolean;
                  isDeleted: boolean;
                  iconUrl?: string | null;
                  isOnCallSynced: boolean;
                  connectionId: string;
                  groupBindingId?: string | null;
                  visibilityGroups: Array<{
                    __typename?: "ConfigurationVisibilityGroup";
                    visibilityGroupId: string;
                    visibilityGroup: { __typename?: "Group"; name: string };
                  }>;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                  groupBinding?: {
                    __typename?: "GroupBinding";
                    id: string;
                    sourceGroupId: string;
                    groups: Array<{
                      __typename?: "Group";
                      id: string;
                      name: string;
                      groupType: GroupType;
                      connectionId: string;
                    }>;
                  } | null;
                } | null;
                tag?: {
                  __typename: "Tag";
                  id: string;
                  createdAt: string;
                  creatorUserId: string;
                  key: string;
                  value?: string | null;
                  isStandardAttribute: boolean;
                } | null;
              }>;
              onCallSchedules?: Array<{
                __typename?: "GroupOnCallSchedule";
                onCallSchedule: {
                  __typename?: "OnCallSchedule";
                  remoteId: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  name: string;
                  existsInRemote: boolean;
                };
              }> | null;
              visibilityGroups: Array<{
                __typename?: "ConfigurationVisibilityGroup";
                visibilityGroupId: string;
                visibilityGroup: { __typename?: "Group"; name: string };
              }>;
              commonMetadata: {
                __typename?: "CommonMetadata";
                matchRemoteName: boolean;
                matchRemoteDescription: boolean;
              };
              groupBinding?: {
                __typename?: "GroupBinding";
                id: string;
                sourceGroupId: string;
                createdAt: string;
                sourceGroup?: {
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                } | null;
                groups: Array<{
                  __typename?: "Group";
                  id: string;
                  name: string;
                  groupType: GroupType;
                  connectionId: string;
                  connection?: {
                    __typename?: "Connection";
                    id: string;
                    name: string;
                    connectionType: ConnectionType;
                  } | null;
                }>;
                createdByUser?: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  secondaryEmails: Array<string>;
                  firstName: string;
                  lastName: string;
                  fullName: string;
                  avatarUrl: string;
                  isDeleted: boolean;
                  isSystemUser: boolean;
                  position: string;
                  teamAttr?: string | null;
                  hrIdpStatus?: HrIdpStatus | null;
                } | null;
              } | null;
              groupLeaders: Array<{
                __typename?: "User";
                id: string;
                email: string;
                secondaryEmails: Array<string>;
                firstName: string;
                lastName: string;
                fullName: string;
                avatarUrl: string;
                isDeleted: boolean;
                isSystemUser: boolean;
                position: string;
                teamAttr?: string | null;
                hrIdpStatus?: HrIdpStatus | null;
              }>;
            } | null;
            access?: {
              __typename?: "GroupUserAccess";
              groupId: string;
              userId: string;
              isGroupManaged: boolean;
              latestExpiringAccessPoint: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              };
              directAccessPoint?: {
                __typename?: "GroupUserAccessPoint";
                groupId: string;
                userId: string;
                groupName: string;
                groupType: GroupType;
                eventId: string;
                createdAt: string;
                expiration?: string | null;
                supportTicketId?: string | null;
                source: GroupUserSource;
                accessLevel?: {
                  __typename: "GroupAccessLevel";
                  accessLevelName: string;
                  accessLevelRemoteId: string;
                } | null;
                supportTicket?: {
                  __typename?: "SupportTicket";
                  id?: string | null;
                  remoteId: string;
                  url: string;
                  identifier: string;
                  thirdPartyProvider: ThirdPartyProvider;
                  projectName: string;
                } | null;
              } | null;
              indirectAccessPointPaths: Array<{
                __typename?: "IndirectGroupUserAccessPointPath";
                startGroupId: string;
                endGroupId: string;
                userId: string;
                resolvedAccessPoint: {
                  __typename?: "GroupUserAccessPoint";
                  groupId: string;
                  userId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                  expiration?: string | null;
                  supportTicketId?: string | null;
                  source: GroupUserSource;
                  accessLevel?: {
                    __typename: "GroupAccessLevel";
                    accessLevelName: string;
                    accessLevelRemoteId: string;
                  } | null;
                  supportTicket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                };
                indirectAccessPointPath: Array<{
                  __typename?: "IndirectGroupAccessPoint";
                  groupId: string;
                  groupName: string;
                  groupType: GroupType;
                  eventId: string;
                  createdAt: string;
                }>;
              }>;
            } | null;
            propagationStatus?: {
              __typename?: "GroupPropagationStatus";
              groupId: string;
              userId: string;
              lastSynced: string;
              statusCode: PropagationStatusCode;
              errorMessage?: string | null;
              taskType: PropagationTaskType;
              ticketPropagation?: {
                __typename: "GroupUserTicketPropagation";
                resourceTickets: Array<{
                  __typename?: "ResourceTicketPropagation";
                  ticket?: {
                    __typename?: "SupportTicket";
                    id?: string | null;
                    remoteId: string;
                    url: string;
                    identifier: string;
                    thirdPartyProvider: ThirdPartyProvider;
                    projectName: string;
                  } | null;
                }>;
              } | null;
            } | null;
          }>;
        };
      };
};

export type UserOverviewFragment = {
  __typename?: "User";
  id: string;
  email: string;
  secondaryEmails: Array<string>;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  isDeleted: boolean;
  isSystemUser: boolean;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  position: string;
  teamAttr?: string | null;
  managerId?: string | null;
  hrIdpStatus?: HrIdpStatus | null;
  numResources: number;
  numGroups: number;
  manager?: {
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    position: string;
    teamAttr?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
  } | null;
  identities?: {
    __typename?: "UserIdentities";
    gitHubUsername?: {
      __typename?: "UserIdentity";
      shouldShow: boolean;
      value: string;
    } | null;
    gitLabUserId?: {
      __typename?: "UserIdentity";
      shouldShow: boolean;
      value: string;
    } | null;
    teleportUsername?: {
      __typename?: "UserIdentity";
      shouldShow: boolean;
      value: string;
    } | null;
  } | null;
  directReports: Array<{
    __typename?: "User";
    id: string;
    email: string;
    secondaryEmails: Array<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    avatarUrl: string;
    isDeleted: boolean;
    isSystemUser: boolean;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    position: string;
    teamAttr?: string | null;
    managerId?: string | null;
    hrIdpStatus?: HrIdpStatus | null;
    manager?: {
      __typename?: "User";
      id: string;
      email: string;
      secondaryEmails: Array<string>;
      firstName: string;
      lastName: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
    } | null;
  }>;
};

export type UsersTableQueryVariables = Exact<{
  input: UsersInput;
}>;

export type UsersTableQuery = {
  __typename?: "Query";
  users: {
    __typename: "UsersResult";
    cursor?: string | null;
    totalNumUsers: number;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      fullName: string;
      avatarUrl: string;
      isDeleted: boolean;
      isSystemUser: boolean;
      createdAt: string;
      position: string;
      teamAttr?: string | null;
      hrIdpStatus?: HrIdpStatus | null;
      numResources: number;
      numGroups: number;
      manager?: {
        __typename?: "User";
        id: string;
        email: string;
        secondaryEmails: Array<string>;
        firstName: string;
        lastName: string;
        fullName: string;
        avatarUrl: string;
        isDeleted: boolean;
        isSystemUser: boolean;
        position: string;
        teamAttr?: string | null;
        hrIdpStatus?: HrIdpStatus | null;
      } | null;
    }>;
  };
};

export const UserPreviewSmallFragmentDoc = gql`
  fragment UserPreviewSmall on User {
    id
    email
    secondaryEmails
    firstName
    lastName
    fullName
    avatarUrl
    isDeleted
    isSystemUser
    position
    teamAttr
    hrIdpStatus
  }
`;
export const UserPreviewLargeFragmentDoc = gql`
  fragment UserPreviewLarge on User {
    id
    email
    secondaryEmails
    firstName
    lastName
    fullName
    avatarUrl
    isDeleted
    isSystemUser
    createdAt
    updatedAt
    organizationId
    position
    teamAttr
    managerId
    manager {
      ...UserPreviewSmall
    }
    hrIdpStatus
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const AdConnectionMetadataFragmentDoc = gql`
  fragment ADConnectionMetadata on ADConnectionMetadata {
    serverHostname
    serverPort
    groupsDistinguishedName
    usersDistinguishedName
  }
`;
export const AwsConnectionMetadataFragmentDoc = gql`
  fragment AwsConnectionMetadata on AwsConnectionMetadata {
    success
  }
`;
export const AwsssoConnectionMetadataFragmentDoc = gql`
  fragment AWSSSOConnectionMetadata on AWSSSOConnectionMetadata {
    managementAccountId
    ssoInstanceArn
    identityStoreId
    accessPortalUrl
    awsRegion
    awsOrganizationEnabled
    awsSsoEnabled
    awsIdentityCenterImportSetting
    awsOrganizationImportSetting
    ec2RoleChainingEnabled
    podRoleChainingEnabled
    cloudtrailEventsSqsUrl
  }
`;
export const GcpConnectionMetadataFragmentDoc = gql`
  fragment GCPConnectionMetadata on GCPConnectionMetadata {
    serviceAccount {
      type
      projectId
      clientEmail
      clientId
      authUri
      tokenUri
      authProviderX509CertUrl
      clientX509CertUrl
    }
    pubSubProjectId
    subscriptionId
  }
`;
export const GitHubConnectionMetadataFragmentDoc = gql`
  fragment GitHubConnectionMetadata on GitHubConnectionMetadata {
    orgName
  }
`;
export const GitLabConnectionMetadataFragmentDoc = gql`
  fragment GitLabConnectionMetadata on GitLabConnectionMetadata {
    hostName
    appId
    tlsMode
    tlsCaCertContent
  }
`;
export const GoogleGroupsConnectionMetadataFragmentDoc = gql`
  fragment GoogleGroupsConnectionMetadata on GoogleGroupsConnectionMetadata {
    serviceAccount {
      type
      projectId
      clientEmail
      clientId
      authUri
      tokenUri
      authProviderX509CertUrl
      clientX509CertUrl
    }
    opalGroupEmail
    adminUserEmail
    domain
  }
`;
export const GoogleWorkspaceConnectionMetadataFragmentDoc = gql`
  fragment GoogleWorkspaceConnectionMetadata on GoogleWorkspaceConnectionMetadata {
    serviceAccount {
      type
      projectId
      clientEmail
      clientId
      authUri
      tokenUri
      authProviderX509CertUrl
      clientX509CertUrl
    }
    adminUserEmail
    customerId
  }
`;
export const LdapConnectionMetadataFragmentDoc = gql`
  fragment LDAPConnectionMetadata on LDAPConnectionMetadata {
    serverHostname
    serverPort
    groupsDistinguishedName
    usersDistinguishedName
    groupsUidKey
  }
`;
export const MongoConnectionMetadataFragmentDoc = gql`
  fragment MongoConnectionMetadata on MongoConnectionMetadata {
    serverHostname
    serverPort
    tlsMode
    tlsCertContent
    tlsCaCertContent
  }
`;
export const MongoAtlasConnectionMetadataFragmentDoc = gql`
  fragment MongoAtlasConnectionMetadata on MongoAtlasConnectionMetadata {
    serverHostname
  }
`;
export const MySqlMariaDbConnectionMetadataFragmentDoc = gql`
  fragment MySqlMariaDBConnectionMetadata on MySQLMariaDBConnectionMetadata {
    serverHostname
    serverPort
    tlsMode
    tlsCertContent
    tlsCaCertContent
  }
`;
export const PostgresConnectionMetadataFragmentDoc = gql`
  fragment PostgresConnectionMetadata on PostgresConnectionMetadata {
    serverHostname
    serverPort
    tlsMode
    tlsCertContent
    tlsCaCertContent
  }
`;
export const OktaDirectoryConnectionMetadataFragmentDoc = gql`
  fragment OktaDirectoryConnectionMetadata on OktaDirectoryConnectionMetadata {
    orgUrl
  }
`;
export const SalesforceConnectionMetadataFragmentDoc = gql`
  fragment SalesforceConnectionMetadata on SalesforceConnectionMetadata {
    email
    hostname
  }
`;
export const TailscaleConnectionMetadataFragmentDoc = gql`
  fragment TailscaleConnectionMetadata on TailscaleConnectionMetadata {
    tailnet
  }
`;
export const TeleportConnectionMetadataFragmentDoc = gql`
  fragment TeleportConnectionMetadata on TeleportConnectionMetadata {
    host
    tlsMode
    tlsCaCertContent
  }
`;
export const DuoConnectionMetadataFragmentDoc = gql`
  fragment DuoConnectionMetadata on DuoConnectionMetadata {
    apiHostname
  }
`;
export const PropagationTicketConnectionMetadataFragmentDoc = gql`
  fragment PropagationTicketConnectionMetadata on PropagationTicketConnectionMetadata {
    ticketProvider {
      ticketProvider
      ticketProjectId
      ticketProjectName
    }
  }
`;
export const CustomConnectorConnectionMetadataFragmentDoc = gql`
  fragment CustomConnectorConnectionMetadata on CustomConnectorConnectionMetadata {
    identifier
    baseUrl
    tlsMode
    tlsCaCertContent
    supportsGroups
    supportsNestedResources
  }
`;
export const AzureAdConnectionMetadataFragmentDoc = gql`
  fragment AzureADConnectionMetadata on AzureADConnectionMetadata {
    tenantId
    clientId
    azureInfraEnabled
    eventHubNamespace
    eventHub
  }
`;
export const SnowflakeConnectionMetadataFragmentDoc = gql`
  fragment SnowflakeConnectionMetadata on SnowflakeConnectionMetadata {
    organization
    account
    locator
    accountIdentifier
    accountUrl
    serviceAccountName
    serviceAccountRole
  }
`;
export const WorkdayConnectionMetadataFragmentDoc = gql`
  fragment WorkdayConnectionMetadata on WorkdayConnectionMetadata {
    tenantName
    tenantUrl
  }
`;
export const ConnectionMetadataOutputFragmentDoc = gql`
  fragment ConnectionMetadataOutput on ConnectionMetadataOutput {
    __typename
    ... on ADConnectionMetadata {
      ...ADConnectionMetadata
    }
    ... on AwsConnectionMetadata {
      ...AwsConnectionMetadata
    }
    ... on AWSSSOConnectionMetadata {
      ...AWSSSOConnectionMetadata
    }
    ... on GCPConnectionMetadata {
      ...GCPConnectionMetadata
    }
    ... on GitHubConnectionMetadata {
      ...GitHubConnectionMetadata
    }
    ... on GitLabConnectionMetadata {
      ...GitLabConnectionMetadata
    }
    ... on GoogleGroupsConnectionMetadata {
      ...GoogleGroupsConnectionMetadata
    }
    ... on GoogleWorkspaceConnectionMetadata {
      ...GoogleWorkspaceConnectionMetadata
    }
    ... on LDAPConnectionMetadata {
      ...LDAPConnectionMetadata
    }
    ... on MongoConnectionMetadata {
      ...MongoConnectionMetadata
    }
    ... on MongoAtlasConnectionMetadata {
      ...MongoAtlasConnectionMetadata
    }
    ... on MySQLMariaDBConnectionMetadata {
      ...MySqlMariaDBConnectionMetadata
    }
    ... on PostgresConnectionMetadata {
      ...PostgresConnectionMetadata
    }
    ... on OktaDirectoryConnectionMetadata {
      ...OktaDirectoryConnectionMetadata
    }
    ... on SalesforceConnectionMetadata {
      ...SalesforceConnectionMetadata
    }
    ... on TailscaleConnectionMetadata {
      ...TailscaleConnectionMetadata
    }
    ... on TeleportConnectionMetadata {
      ...TeleportConnectionMetadata
    }
    ... on DuoConnectionMetadata {
      ...DuoConnectionMetadata
    }
    ... on PropagationTicketConnectionMetadata {
      ...PropagationTicketConnectionMetadata
    }
    ... on CustomConnectorConnectionMetadata {
      ...CustomConnectorConnectionMetadata
    }
    ... on AzureADConnectionMetadata {
      ...AzureADConnectionMetadata
    }
    ... on SnowflakeConnectionMetadata {
      ...SnowflakeConnectionMetadata
    }
    ... on WorkdayConnectionMetadata {
      ...WorkdayConnectionMetadata
    }
  }
  ${AdConnectionMetadataFragmentDoc}
  ${AwsConnectionMetadataFragmentDoc}
  ${AwsssoConnectionMetadataFragmentDoc}
  ${GcpConnectionMetadataFragmentDoc}
  ${GitHubConnectionMetadataFragmentDoc}
  ${GitLabConnectionMetadataFragmentDoc}
  ${GoogleGroupsConnectionMetadataFragmentDoc}
  ${GoogleWorkspaceConnectionMetadataFragmentDoc}
  ${LdapConnectionMetadataFragmentDoc}
  ${MongoConnectionMetadataFragmentDoc}
  ${MongoAtlasConnectionMetadataFragmentDoc}
  ${MySqlMariaDbConnectionMetadataFragmentDoc}
  ${PostgresConnectionMetadataFragmentDoc}
  ${OktaDirectoryConnectionMetadataFragmentDoc}
  ${SalesforceConnectionMetadataFragmentDoc}
  ${TailscaleConnectionMetadataFragmentDoc}
  ${TeleportConnectionMetadataFragmentDoc}
  ${DuoConnectionMetadataFragmentDoc}
  ${PropagationTicketConnectionMetadataFragmentDoc}
  ${CustomConnectorConnectionMetadataFragmentDoc}
  ${AzureAdConnectionMetadataFragmentDoc}
  ${SnowflakeConnectionMetadataFragmentDoc}
  ${WorkdayConnectionMetadataFragmentDoc}
`;
export const ConnectionPreviewSmallFragmentDoc = gql`
  fragment ConnectionPreviewSmall on Connection {
    __typename
    id
    name
    connectionType
    isDeleted
    metadata {
      ...ConnectionMetadataOutput
    }
  }
  ${ConnectionMetadataOutputFragmentDoc}
`;
export const ResourceAccessLevelFragmentDoc = gql`
  fragment ResourceAccessLevel on ResourceAccessLevel {
    __typename
    accessLevelName
    accessLevelRemoteId
    accessLevelMetadata {
      __typename
      ... on ImpersonationAccessLevelMetadata {
        avatarUrl
      }
    }
  }
`;
export const ResourceVisibilityGroupFragmentDoc = gql`
  fragment ResourceVisibilityGroup on ConfigurationVisibilityGroup {
    visibilityGroupId
    visibilityGroup {
      name
    }
  }
`;
export const AwsRoleMetadataFragmentDoc = gql`
  fragment AwsRoleMetadata on AwsRoleMetadata {
    arn
  }
`;
export const OktaDirectoryAppMetadataFragmentDoc = gql`
  fragment OktaDirectoryAppMetadata on OktaDirectoryAppMetadata {
    appId
    appName
    logoUrl
  }
`;
export const ResourceMetadataFragmentDoc = gql`
  fragment ResourceMetadata on ResourceMetadata {
    __typename
    ... on AwsRoleMetadata {
      ...AwsRoleMetadata
    }
    ... on OktaDirectoryAppMetadata {
      ...OktaDirectoryAppMetadata
    }
    ... on OktaRoleMetadata {
      roleId
      roleType
    }
    ... on PropagationTicketOwnerMetadata {
      ownerId
      owner {
        id
        name
      }
    }
  }
  ${AwsRoleMetadataFragmentDoc}
  ${OktaDirectoryAppMetadataFragmentDoc}
`;
export const ResourcePreviewSmallFragmentDoc = gql`
  fragment ResourcePreviewSmall on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    isManaged
    resourceType
    remoteId
    iconUrl
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    parentResourceId
    parentResource {
      id
      name
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    metadata {
      ...ResourceMetadata
    }
    createdAt
  }
  ${ResourceVisibilityGroupFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${ResourceMetadataFragmentDoc}
`;
export const ReviewerUserFragmentDoc = gql`
  fragment ReviewerUser on ReviewerUser {
    userId
    entityId
    entityType
    name
    avatarUrl
    status
    note
    updatedAccessLevel {
      ...ResourceAccessLevel
    }
    updatedResourceId
    updatedResource {
      ...ResourcePreviewSmall
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${ResourcePreviewSmallFragmentDoc}
`;
export const AccessReviewConnectionUserWarningFragmentDoc = gql`
  fragment AccessReviewConnectionUserWarning on AccessReviewConnectionUserWarning {
    accessReviewConnectionUserReviewerId
    accessReviewConnectionUserId
    accessReviewConnectionId
    accessReviewUserWarningType
    userId
    user {
      ...UserPreviewSmall
    }
    reviewerId
    reviewer {
      ...UserPreviewSmall
    }
    connection {
      id
      name
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const AccessReviewItemStatusAndOutcomeFragmentDoc = gql`
  fragment AccessReviewItemStatusAndOutcome on AccessReviewItemStatusAndOutcome {
    status
    outcome
  }
`;
export const AccessReviewConnectionUserFragmentDoc = gql`
  fragment AccessReviewConnectionUser on AccessReviewConnectionUser {
    id
    createdAt
    updatedAt
    accessReviewConnectionId
    userId
    user {
      ...UserPreviewLarge
    }
    accessReviewConnectionId
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    eventId
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewConnectionUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${UserPreviewLargeFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewConnectionUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
`;
export const AccessReviewUserConnectionPreviewFragmentDoc = gql`
  fragment AccessReviewUserConnectionPreview on AccessReviewConnectionUser {
    id
    createdAt
    updatedAt
    userId
    connectionId
    connection {
      ...ConnectionPreviewSmall
      id
    }
    accessReviewConnectionId
    eventId
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewConnectionUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewConnectionUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
`;
export const ConnectionVisibilityGroupFragmentDoc = gql`
  fragment ConnectionVisibilityGroup on ConnectionVisibilityGroup {
    visibilityGroupId
    visibilityGroup {
      name
    }
  }
`;
export const ConnectionPreviewLargeFragmentDoc = gql`
  fragment ConnectionPreviewLarge on Connection {
    __typename
    id
    name
    description
    connectionType
    isDeleted
    adminOwner {
      id
      name
    }
    visibility
    visibilityGroups {
      ...ConnectionVisibilityGroup
    }
    importVisibility
    importSetting
    autoImportGroupResources
    metadata {
      ...ConnectionMetadataOutput
    }
    numUsers
    webhookEnabled
    childrenDefaultConfigTemplate {
      id
      name
    }
    iconUrl
    importNotificationOwner {
      id
      name
    }
  }
  ${ConnectionVisibilityGroupFragmentDoc}
  ${ConnectionMetadataOutputFragmentDoc}
`;
export const AccessReviewConnectionPreviewFragmentDoc = gql`
  fragment AccessReviewConnectionPreview on AccessReviewConnection {
    id
    createdAt
    updatedAt
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    accessReviewId
    reviewerUsers {
      ...ReviewerUser
    }
    numConnectionUsers
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewConnectionUserWarning
    }
    status
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewConnectionUserWarningFragmentDoc}
`;
export const AccessReviewPreviewFragmentDoc = gql`
  fragment AccessReviewPreview on AccessReview {
    id
    createdAt
    startedByUserId
    stoppedByUserId
    stoppedDate
    deadline
    timeZone
    selfReviewAllowed
    name
    reminderSchedule
    reminderTimeOfDay
    reminderIncludeManager
    lastReminderAt
    sendReviewerAssignmentNotification
    reportId
    reviewerAssignmentPolicy
    accessReviewTemplateId
    includeGroupBindings
  }
`;
export const AccessReviewConnectionUserPreviewFragmentDoc = gql`
  fragment AccessReviewConnectionUserPreview on AccessReviewConnectionUser {
    id
    createdAt
    updatedAt
    userId
    user {
      ...UserPreviewLarge
    }
    connectionId
    accessReviewConnectionId
    eventId
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewConnectionUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${UserPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewConnectionUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
`;
export const AccessReviewConnectionReviewerStatusFragmentDoc = gql`
  fragment AccessReviewConnectionReviewerStatus on AccessReviewConnectionReviewerStatus {
    itemStatus
    numUsersReviewed
    numUsersAssigned
  }
`;
export const AccessReviewConnectionFragmentDoc = gql`
  fragment AccessReviewConnection on AccessReviewConnection {
    id
    createdAt
    updatedAt
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    accessReviewId
    accessReview {
      ...AccessReviewPreview
    }
    reviewerUsers {
      ...ReviewerUser
    }
    connectionUsers {
      ...AccessReviewConnectionUserPreview
    }
    numConnectionUsers
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewConnectionUserWarning
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewConnectionReviewerStatus
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${AccessReviewPreviewFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewConnectionUserPreviewFragmentDoc}
  ${AccessReviewConnectionUserWarningFragmentDoc}
  ${AccessReviewConnectionReviewerStatusFragmentDoc}
`;
export const GroupVisibilityGroupFragmentDoc = gql`
  fragment GroupVisibilityGroup on ConfigurationVisibilityGroup {
    visibilityGroupId
    visibilityGroup {
      name
    }
  }
`;
export const ConnectionPreviewTinyFragmentDoc = gql`
  fragment ConnectionPreviewTiny on Connection {
    id
    name
    connectionType
  }
`;
export const GroupPreviewTinyFragmentDoc = gql`
  fragment GroupPreviewTiny on Group {
    id
    name
    groupType
    connectionId
  }
`;
export const GroupBindingTinyFragmentDoc = gql`
  fragment GroupBindingTiny on GroupBinding {
    id
    sourceGroupId
    groups {
      ...GroupPreviewTiny
    }
  }
  ${GroupPreviewTinyFragmentDoc}
`;
export const GroupPreviewSmallFragmentDoc = gql`
  fragment GroupPreviewSmall on Group {
    id
    remoteId
    name
    remoteName
    numGroupUsers
    groupType
    serviceType
    visibility
    isManaged
    isDeleted
    iconUrl
    visibilityGroups {
      ...GroupVisibilityGroup
    }
    isOnCallSynced
    connectionId
    connection {
      ...ConnectionPreviewTiny
    }
    groupBindingId
    groupBinding {
      ...GroupBindingTiny
    }
  }
  ${GroupVisibilityGroupFragmentDoc}
  ${ConnectionPreviewTinyFragmentDoc}
  ${GroupBindingTinyFragmentDoc}
`;
export const OnCallScheduleFragmentDoc = gql`
  fragment OnCallSchedule on OnCallSchedule {
    remoteId
    thirdPartyProvider
    name
    existsInRemote
  }
`;
export const GroupOnCallScheduleFragmentDoc = gql`
  fragment GroupOnCallSchedule on GroupOnCallSchedule {
    onCallSchedule {
      ...OnCallSchedule
    }
  }
  ${OnCallScheduleFragmentDoc}
`;
export const AccessReviewGroupUserWarningFragmentDoc = gql`
  fragment AccessReviewGroupUserWarning on AccessReviewGroupUserWarning {
    accessReviewGroupUserReviewerId
    accessReviewGroupUserId
    accessReviewGroupId
    accessReviewUserWarningType
    userId
    user {
      ...UserPreviewSmall
    }
    reviewerId
    reviewer {
      ...UserPreviewSmall
    }
    group {
      id
      name
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const GroupAccessLevelFragmentDoc = gql`
  fragment GroupAccessLevel on GroupAccessLevel {
    __typename
    accessLevelName
    accessLevelRemoteId
  }
`;
export const SupportTicketPreviewFragmentDoc = gql`
  fragment SupportTicketPreview on SupportTicket {
    id
    remoteId
    url
    identifier
    thirdPartyProvider
    projectName
  }
`;
export const GroupUserAccessPointFragmentDoc = gql`
  fragment GroupUserAccessPoint on GroupUserAccessPoint {
    groupId
    userId
    groupName
    groupType
    eventId
    createdAt
    expiration
    accessLevel {
      ...GroupAccessLevel
    }
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
    source
  }
  ${GroupAccessLevelFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
`;
export const IndirectGroupAccessPointFragmentDoc = gql`
  fragment IndirectGroupAccessPoint on IndirectGroupAccessPoint {
    groupId
    groupName
    groupType
    eventId
    createdAt
  }
`;
export const IndirectGroupUserAccessPointPathFragmentDoc = gql`
  fragment IndirectGroupUserAccessPointPath on IndirectGroupUserAccessPointPath {
    startGroupId
    endGroupId
    userId
    resolvedAccessPoint {
      ...GroupUserAccessPoint
    }
    indirectAccessPointPath {
      ...IndirectGroupAccessPoint
    }
  }
  ${GroupUserAccessPointFragmentDoc}
  ${IndirectGroupAccessPointFragmentDoc}
`;
export const GroupUserAccessFragmentDoc = gql`
  fragment GroupUserAccess on GroupUserAccess {
    groupId
    userId
    isGroupManaged
    latestExpiringAccessPoint {
      ...GroupUserAccessPoint
    }
    directAccessPoint {
      ...GroupUserAccessPoint
    }
    indirectAccessPointPaths {
      ...IndirectGroupUserAccessPointPath
    }
  }
  ${GroupUserAccessPointFragmentDoc}
  ${IndirectGroupUserAccessPointPathFragmentDoc}
`;
export const GroupUserTicketPropagationFragmentDoc = gql`
  fragment GroupUserTicketPropagation on GroupUserTicketPropagation {
    __typename
    resourceTickets {
      ticket {
        ...SupportTicketPreview
      }
    }
  }
  ${SupportTicketPreviewFragmentDoc}
`;
export const GroupPropagationStatusFragmentDoc = gql`
  fragment GroupPropagationStatus on GroupPropagationStatus {
    groupId
    userId
    lastSynced
    statusCode
    errorMessage
    taskType
    ticketPropagation {
      ...GroupUserTicketPropagation
    }
  }
  ${GroupUserTicketPropagationFragmentDoc}
`;
export const GroupUserUarPreviewFragmentDoc = gql`
  fragment GroupUserUARPreview on GroupUser {
    groupId
    userId
    access {
      ...GroupUserAccess
    }
    propagationStatus {
      ...GroupPropagationStatus
    }
    source
  }
  ${GroupUserAccessFragmentDoc}
  ${GroupPropagationStatusFragmentDoc}
`;
export const AccessReviewGroupUserFragmentDoc = gql`
  fragment AccessReviewGroupUser on AccessReviewGroupUser {
    id
    createdAt
    updatedAt
    accessReviewGroupId
    userId
    user {
      ...UserPreviewLarge
    }
    accessReviewGroupId
    groupId
    group {
      ...GroupPreviewSmall
      onCallSchedules {
        ...GroupOnCallSchedule
      }
    }
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    eventId
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewGroupUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    groupUser {
      ...GroupUserUARPreview
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${UserPreviewLargeFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${GroupOnCallScheduleFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${GroupUserUarPreviewFragmentDoc}
`;
export const GroupUserPreviewFragmentDoc = gql`
  fragment GroupUserPreview on GroupUser {
    groupId
    userId
    access {
      ...GroupUserAccess
    }
    propagationStatus {
      ...GroupPropagationStatus
    }
    accessLevel {
      ...GroupAccessLevel
    }
    source
  }
  ${GroupUserAccessFragmentDoc}
  ${GroupPropagationStatusFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
`;
export const ResourceUserAccessPointFragmentDoc = gql`
  fragment ResourceUserAccessPoint on ResourceUserAccessPoint {
    resourceId
    userId
    accessLevel {
      ...ResourceAccessLevel
    }
    resourceName
    resourceType
    eventId
    createdAt
    expiration
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
`;
export const ResourceUserAccessFragmentDoc = gql`
  fragment ResourceUserAccess on ResourceUserAccess {
    resourceId
    userId
    accessLevel {
      ...ResourceAccessLevel
    }
    latestExpiringAccessPoint {
      ...ResourceUserAccessPoint
    }
    directAccessPoint {
      ...ResourceUserAccessPoint
    }
    inheritedAccessPoints {
      ...ResourceUserAccessPoint
    }
    groupUserAccesses {
      ...GroupUserAccess
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserAccessPointFragmentDoc}
  ${GroupUserAccessFragmentDoc}
`;
export const ResourceUserPropagationMetadataFragmentDoc = gql`
  fragment ResourceUserPropagationMetadata on ResourceUserPropagationMetadata {
    __typename
    ... on PropagationTicketMetadata {
      ticket {
        ...SupportTicketPreview
      }
    }
  }
  ${SupportTicketPreviewFragmentDoc}
`;
export const ResourcePropagationStatusFragmentDoc = gql`
  fragment ResourcePropagationStatus on ResourcePropagationStatus {
    resourceId
    userId
    accessLevelRemoteId
    lastSynced
    statusCode
    errorMessage
    taskType
    metadata {
      ...ResourceUserPropagationMetadata
    }
  }
  ${ResourceUserPropagationMetadataFragmentDoc}
`;
export const ResourceUserFragmentDoc = gql`
  fragment ResourceUser on ResourceUser {
    __typename
    resourceId
    userId
    user {
      ...UserPreviewLarge
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...ResourceUserAccess
    }
    propagationStatus {
      ...ResourcePropagationStatus
    }
    mostRecentSessionEnded
    source
  }
  ${UserPreviewLargeFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserAccessFragmentDoc}
  ${ResourcePropagationStatusFragmentDoc}
`;
export const UserResourceAccessFragmentDoc = gql`
  fragment UserResourceAccess on UserResourceAccess {
    resourceId
    userId
    resourceUsers {
      ...ResourceUser
    }
  }
  ${ResourceUserFragmentDoc}
`;
export const RequestedResourcePreviewSmallFragmentDoc = gql`
  fragment RequestedResourcePreviewSmall on RequestedResource {
    resourceId
    resource {
      ...ResourcePreviewSmall
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    targetUserAccess {
      ...UserResourceAccess
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${UserResourceAccessFragmentDoc}
`;
export const UserGroupAccessFragmentDoc = gql`
  fragment UserGroupAccess on UserGroupAccess {
    groupId
    userId
    groupUser {
      ...GroupUserPreview
    }
  }
  ${GroupUserPreviewFragmentDoc}
`;
export const RequestedGroupPreviewSmallFragmentDoc = gql`
  fragment RequestedGroupPreviewSmall on RequestedGroup {
    groupId
    group {
      ...GroupPreviewSmall
    }
    accessLevel {
      ...GroupAccessLevel
    }
    targetUserAccess {
      ...UserGroupAccess
    }
  }
  ${GroupPreviewSmallFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
  ${UserGroupAccessFragmentDoc}
`;
export const ResourceProposalFragmentDoc = gql`
  fragment ResourceProposal on ResourceProposal {
    id
    metadata {
      __typename
      ... on AWSRoleProposalMetadata {
        policyName
        policyDocument
        policyDescription
        assumeRolePolicyDocument
        roleName
        roleDescription
        tags {
          key
          value
        }
      }
    }
    connectionId
    serviceType
    resourceType
  }
`;
export const RequestPreviewSmallFragmentDoc = gql`
  fragment RequestPreviewSmall on Request {
    id
    requesterId
    requester {
      ...UserPreviewSmall
    }
    targetUserId
    targetUser {
      ...UserPreviewSmall
    }
    targetGroupId
    targetGroup {
      ...GroupPreviewSmall
    }
    requestedResources {
      ...RequestedResourcePreviewSmall
    }
    requestedGroups {
      ...RequestedGroupPreviewSmall
    }
    resourceProposalId
    resourceProposal {
      ...ResourceProposal
    }
    createdAt
    updatedAt
    status
  }
  ${UserPreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${RequestedResourcePreviewSmallFragmentDoc}
  ${RequestedGroupPreviewSmallFragmentDoc}
  ${ResourceProposalFragmentDoc}
`;
export const CurrentUserGroupAccessFragmentDoc = gql`
  fragment CurrentUserGroupAccess on CurrentUserGroupAccess {
    groupId
    groupUser {
      ...GroupUserPreview
    }
    hasBreakGlass
    pendingRequest {
      ...RequestPreviewSmall
    }
  }
  ${GroupUserPreviewFragmentDoc}
  ${RequestPreviewSmallFragmentDoc}
`;
export const TagPreviewSmallFragmentDoc = gql`
  fragment TagPreviewSmall on Tag {
    __typename
    id
    createdAt
    creatorUserId
    key
    value
    isStandardAttribute
  }
`;
export const GroupTagPreviewFragmentDoc = gql`
  fragment GroupTagPreview on GroupTag {
    groupId
    group {
      ...GroupPreviewSmall
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${GroupPreviewSmallFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const GroupBindingGroupPreviewFragmentDoc = gql`
  fragment GroupBindingGroupPreview on Group {
    ...GroupPreviewTiny
    connection {
      ...ConnectionPreviewTiny
    }
  }
  ${GroupPreviewTinyFragmentDoc}
  ${ConnectionPreviewTinyFragmentDoc}
`;
export const GroupBindingFragmentDoc = gql`
  fragment GroupBinding on GroupBinding {
    id
    sourceGroupId
    sourceGroup {
      ...GroupBindingGroupPreview
    }
    groups {
      ...GroupBindingGroupPreview
    }
    createdAt
    createdByUser {
      ...UserPreviewSmall
    }
  }
  ${GroupBindingGroupPreviewFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;
export const GroupPreviewLargeFragmentDoc = gql`
  fragment GroupPreviewLarge on Group {
    __typename
    id
    remoteId
    name
    remoteName
    groupType
    serviceType
    iconUrl
    visibility
    isDeleted
    createdAt
    updatedAt
    description
    numGroupUsers
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    currentUserAccess {
      ...CurrentUserGroupAccess
    }
    tags {
      ...GroupTagPreview
    }
    isOnCallSynced
    onCallSchedules {
      ...GroupOnCallSchedule
    }
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    visibilityGroups {
      ...GroupVisibilityGroup
    }
    requestTemplateId
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    groupBindingId
    groupBinding {
      ...GroupBinding
    }
    groupLeaders {
      ...UserPreviewSmall
    }
    customRequestNotification
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${CurrentUserGroupAccessFragmentDoc}
  ${GroupTagPreviewFragmentDoc}
  ${GroupOnCallScheduleFragmentDoc}
  ${GroupVisibilityGroupFragmentDoc}
  ${GroupBindingFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;
export const AccessReviewGroupPreviewFragmentDoc = gql`
  fragment AccessReviewGroupPreview on AccessReviewGroup {
    id
    createdAt
    updatedAt
    groupId
    group {
      ...GroupPreviewLarge
    }
    accessReviewId
    reviewerUsers {
      ...ReviewerUser
    }
    numGroupUsers
    numGroupResources
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewGroupUserWarning
    }
    status
  }
  ${GroupPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
`;
export const AccessReviewGroupUserPreviewFragmentDoc = gql`
  fragment AccessReviewGroupUserPreview on AccessReviewGroupUser {
    id
    createdAt
    updatedAt
    userId
    user {
      ...UserPreviewLarge
    }
    groupId
    accessReviewGroupId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    eventId
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewGroupUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    groupUser {
      ...GroupUserUARPreview
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${UserPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${GroupUserUarPreviewFragmentDoc}
`;
export const AwsIamFederatedRoleSessionFragmentDoc = gql`
  fragment AwsIamFederatedRoleSession on AwsIamFederatedRoleSession {
    id
    awsAccessKeyId
    awsSecretAccessKey
    awsSessionToken
    awsLoginUrl
    federatedArn
  }
`;
export const AwsIamFederatedSsmSessionFragmentDoc = gql`
  fragment AwsIamFederatedSSMSession on AwsIamFederatedSSMSession {
    id
    awsAccessKeyId
    awsSecretAccessKey
    awsSessionToken
    awsLoginUrl
    federatedArn
    ec2InstanceId
    ec2Region
  }
`;
export const AwsIamFederatedRdsSessionFragmentDoc = gql`
  fragment AwsIamFederatedRdsSession on AwsIamFederatedRdsSession {
    id
    awsAccessKeyId
    awsSecretAccessKey
    awsSessionToken
    federatedArn
    dbInstanceId
    dbPassword
    dbEngine
    dbUser
    dbHostname
    dbPort
    dbName
  }
`;
export const AwsIamFederatedEksSessionFragmentDoc = gql`
  fragment AwsIamFederatedEksSession on AwsIamFederatedEksSession {
    id
    awsAccessKeyId
    awsSecretAccessKey
    awsSessionToken
    federatedArn
    clusterName
    clusterRegion
  }
`;
export const TlsConfigFragmentDoc = gql`
  fragment TlsConfig on TlsConfig {
    tlsMode
    tlsCaCertContent
  }
`;
export const VaultMongoSessionFragmentDoc = gql`
  fragment VaultMongoSession on VaultMongoSession {
    id
    dbUser
    dbPassword
    dbHostname
    dbPort
    tlsConfig {
      ...TlsConfig
    }
    authenticationDbName
  }
  ${TlsConfigFragmentDoc}
`;
export const VaultMongoAtlasSessionFragmentDoc = gql`
  fragment VaultMongoAtlasSession on VaultMongoAtlasSession {
    id
    dbUser
    dbPassword
    dbHostname
    initDbName
  }
`;
export const VaultMySqlMariaDbSessionFragmentDoc = gql`
  fragment VaultMySQLMariaDBSession on VaultMySQLMariaDBSession {
    id
    dbUser
    dbPassword
    dbHostname
    dbPort
    tlsConfig {
      ...TlsConfig
    }
  }
  ${TlsConfigFragmentDoc}
`;
export const VaultPostgresSessionFragmentDoc = gql`
  fragment VaultPostgresSession on VaultPostgresSession {
    id
    dbUser
    dbPassword
    dbHostname
    dbPort
    tlsConfig {
      ...TlsConfig
    }
  }
  ${TlsConfigFragmentDoc}
`;
export const SessionMetadataFragmentDoc = gql`
  fragment SessionMetadata on SessionMetadata {
    __typename
    ... on AwsIamFederatedRoleSession {
      ...AwsIamFederatedRoleSession
    }
    ... on AwsIamFederatedSSMSession {
      ...AwsIamFederatedSSMSession
    }
    ... on AwsIamFederatedRdsSession {
      ...AwsIamFederatedRdsSession
    }
    ... on AwsIamFederatedEksSession {
      ...AwsIamFederatedEksSession
    }
    ... on VaultMongoSession {
      ...VaultMongoSession
    }
    ... on VaultMongoAtlasSession {
      ...VaultMongoAtlasSession
    }
    ... on VaultMySQLMariaDBSession {
      ...VaultMySQLMariaDBSession
    }
    ... on VaultPostgresSession {
      ...VaultPostgresSession
    }
  }
  ${AwsIamFederatedRoleSessionFragmentDoc}
  ${AwsIamFederatedSsmSessionFragmentDoc}
  ${AwsIamFederatedRdsSessionFragmentDoc}
  ${AwsIamFederatedEksSessionFragmentDoc}
  ${VaultMongoSessionFragmentDoc}
  ${VaultMongoAtlasSessionFragmentDoc}
  ${VaultMySqlMariaDbSessionFragmentDoc}
  ${VaultPostgresSessionFragmentDoc}
`;
export const SessionPreviewFragmentDoc = gql`
  fragment SessionPreview on Session {
    id
    durationInMinutes
    metadata {
      ...SessionMetadata
    }
    endTime
    accessLevel
    accessLevelRemoteId
  }
  ${SessionMetadataFragmentDoc}
`;
export const CurrentUserResourceAccessFragmentDoc = gql`
  fragment CurrentUserResourceAccess on CurrentUserResourceAccess {
    resourceId
    resourceUsers {
      ...ResourceUser
    }
    activeSessions {
      ...SessionPreview
    }
    pendingRequests {
      ...RequestPreviewSmall
    }
    numAvailableAccessLevels
  }
  ${ResourceUserFragmentDoc}
  ${SessionPreviewFragmentDoc}
  ${RequestPreviewSmallFragmentDoc}
`;
export const ResourceTagPreviewFragmentDoc = gql`
  fragment ResourceTagPreview on ResourceTag {
    id
    resourceId
    resource {
      ...ResourcePreviewSmall
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const ChildResourcePreviewFragmentDoc = gql`
  fragment ChildResourcePreview on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    resourceType
    remoteId
    iconUrl
    tags {
      ...ResourceTagPreview
    }
    maxDurationInMinutes
    requireMfaToConnect
    requireMfaToApprove
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    autoApproval
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    parentResourceId
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
`;
export const RequestTemplateCustomFieldFragmentDoc = gql`
  fragment RequestTemplateCustomField on RequestTemplateCustomField {
    name
    type
    required
    description
    metadata {
      multiChoiceData {
        options {
          value
          label
        }
      }
    }
  }
`;
export const RequestTemplateFragmentDoc = gql`
  fragment RequestTemplate on RequestTemplate {
    id
    name
    isDeleted
    customFields {
      ...RequestTemplateCustomField
    }
  }
  ${RequestTemplateCustomFieldFragmentDoc}
`;
export const ResourcePreviewLargeFragmentDoc = gql`
  fragment ResourcePreviewLarge on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    resourceType
    remoteId
    iconUrl
    tags {
      ...ResourceTagPreview
    }
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToConnect
    requireMfaToApprove
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    requestTemplateId
    parentResourceId
    childResources {
      ...ChildResourcePreview
    }
    requestTemplate {
      ...RequestTemplate
    }
    parentResource {
      id
      name
    }
    authorizedActions
    customRequestNotification
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
  ${ChildResourcePreviewFragmentDoc}
  ${RequestTemplateFragmentDoc}
`;
export const AccessReviewResourceUserWarningFragmentDoc = gql`
  fragment AccessReviewResourceUserWarning on AccessReviewResourceUserWarning {
    accessReviewResourceUserReviewerId
    accessReviewResourceUserId
    accessReviewResourceId
    accessReviewUserWarningType
    userId
    user {
      ...UserPreviewSmall
    }
    reviewerId
    reviewer {
      ...UserPreviewSmall
    }
    resource {
      id
      name
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const AccessReviewResourcePreviewFragmentDoc = gql`
  fragment AccessReviewResourcePreview on AccessReviewResource {
    id
    createdAt
    updatedAt
    resourceId
    accessReviewId
    resource {
      ...ResourcePreviewLarge
    }
    reviewerUsers {
      ...ReviewerUser
    }
    numResourceUsers
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewResourceUserWarning
    }
    status
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
`;
export const GroupResourceAccessPointFragmentDoc = gql`
  fragment GroupResourceAccessPoint on GroupResourceAccessPoint {
    groupId
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    resourceName
    resourceType
    groupName
    eventId
    createdAt
    expiration
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
`;
export const IndirectGroupResourceAccessPointPathFragmentDoc = gql`
  fragment IndirectGroupResourceAccessPointPath on IndirectGroupResourceAccessPointPath {
    startGroupId
    endGroupId
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    resolvedAccessPoint {
      ...GroupResourceAccessPoint
    }
    indirectAccessPointPath {
      ...IndirectGroupAccessPoint
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceAccessPointFragmentDoc}
  ${IndirectGroupAccessPointFragmentDoc}
`;
export const GroupResourceAccessFragmentDoc = gql`
  fragment GroupResourceAccess on GroupResourceAccess {
    groupId
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    directAccessPoint {
      ...GroupResourceAccessPoint
    }
    inheritedAccessPoints {
      ...GroupResourceAccessPoint
    }
    indirectAccessPointPaths {
      ...IndirectGroupResourceAccessPointPath
    }
    latestExpiringAccessPoint {
      ...GroupResourceAccessPoint
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceAccessPointFragmentDoc}
  ${IndirectGroupResourceAccessPointPathFragmentDoc}
`;
export const AccessReviewGroupResourceFragmentDoc = gql`
  fragment AccessReviewGroupResource on AccessReviewGroupResource {
    id
    createdAt
    updatedAt
    resourceId
    accessReviewResourceId
    accessReviewResource {
      ...AccessReviewResourcePreview
    }
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    expiration
    reviewerUsers {
      ...ReviewerUser
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    groupResourceAccess {
      ...GroupResourceAccess
    }
  }
  ${AccessReviewResourcePreviewFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
`;
export const AccessReviewGroupReviewerStatusFragmentDoc = gql`
  fragment AccessReviewGroupReviewerStatus on AccessReviewGroupReviewerStatus {
    itemStatus
    numUsersReviewed
    numUsersAssigned
    numResourcesReviewed
    numResourcesAssigned
  }
`;
export const AccessReviewGroupFragmentDoc = gql`
  fragment AccessReviewGroup on AccessReviewGroup {
    id
    createdAt
    updatedAt
    groupId
    group {
      ...GroupPreviewLarge
    }
    accessReviewId
    accessReview {
      ...AccessReviewPreview
    }
    reviewerUsers {
      ...ReviewerUser
    }
    groupUsers {
      ...AccessReviewGroupUserPreview
    }
    numGroupUsers
    groupResources {
      ...AccessReviewGroupResource
    }
    numGroupResources
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewGroupUserWarning
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewGroupReviewerStatus
    }
  }
  ${GroupPreviewLargeFragmentDoc}
  ${AccessReviewPreviewFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserPreviewFragmentDoc}
  ${AccessReviewGroupResourceFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
  ${AccessReviewGroupReviewerStatusFragmentDoc}
`;
export const ResourceUserPreviewFragmentDoc = gql`
  fragment ResourceUserPreview on ResourceUser {
    resourceId
    userId
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...ResourceUserAccess
    }
    source
  }
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserAccessFragmentDoc}
`;
export const SupportTicketFragmentDoc = gql`
  fragment SupportTicket on SupportTicket {
    id
    remoteId
    url
    identifier
    thirdPartyProvider
    summary
    issueType
    projectName
  }
`;
export const AccessReviewResourceUserFragmentDoc = gql`
  fragment AccessReviewResourceUser on AccessReviewResourceUser {
    id
    createdAt
    updatedAt
    accessReviewResourceId
    userId
    resourceId
    user {
      ...UserPreviewLarge
    }
    resource {
      ...ResourcePreviewSmall
    }
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewResourceUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    resourceUser {
      ...ResourceUserPreview
    }
    supportTicketId
    supportTicket {
      ...SupportTicket
    }
    errorMessage
    statusCode
    taskType
    lastSynced
    requestId
  }
  ${UserPreviewLargeFragmentDoc}
  ${ResourcePreviewSmallFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${ResourceUserPreviewFragmentDoc}
  ${SupportTicketFragmentDoc}
`;
export const AccessReviewResourceUserPreviewFragmentDoc = gql`
  fragment AccessReviewResourceUserPreview on AccessReviewResourceUser {
    id
    createdAt
    updatedAt
    userId
    accessReviewResourceId
    resourceId
    user {
      ...UserPreviewLarge
    }
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewResourceUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    resourceUser {
      ...ResourceUserPreview
    }
    supportTicketId
    supportTicket {
      ...SupportTicket
    }
    errorMessage
    statusCode
    taskType
    lastSynced
    requestId
  }
  ${UserPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${ResourceUserPreviewFragmentDoc}
  ${SupportTicketFragmentDoc}
`;
export const AccessLevelFragmentDoc = gql`
  fragment AccessLevel on AccessLevel {
    __typename
    accessLevelName
    accessLevelRemoteId
    accessLevelMetadata {
      __typename
      ... on ImpersonationAccessLevelMetadata {
        avatarUrl
      }
    }
  }
`;
export const AccessPointFragmentDoc = gql`
  fragment AccessPoint on AccessPoint {
    entityId
    entityType
    entityName
    entityItemType
    principalId
    principalType
    principalName
    accessLevel {
      ...AccessLevel
    }
    eventId
    createdAt
    expiration
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
  }
  ${AccessLevelFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
`;
export const AccessFragmentDoc = gql`
  fragment Access on Access {
    entityId
    entityType
    principalId
    principalType
    accessLevel {
      ...AccessLevel
    }
    latestExpiringAccessPoint {
      ...AccessPoint
    }
    directAccessPoint {
      ...AccessPoint
    }
    inheritedAccessPoints {
      ...AccessPoint
    }
  }
  ${AccessLevelFragmentDoc}
  ${AccessPointFragmentDoc}
`;
export const ResourcePreviewTinyFragmentDoc = gql`
  fragment ResourcePreviewTiny on Resource {
    id
    name
    resourceType
    parentResource {
      id
      name
    }
  }
`;
export const AccessReviewResourceResourceFragmentDoc = gql`
  fragment AccessReviewResourceResource on AccessReviewResourceResource {
    id
    createdAt
    updatedAt
    accessReviewId
    resourceResourceAccess {
      ...Access
    }
    resourcePrincipal {
      ...ResourcePreviewTiny
    }
    resourceEntity {
      ...ResourcePreviewTiny
    }
    accessReviewResourceEntityId
    resourceEntityId
    isResourceEntityDeleted
    accessReviewResourcePrincipalId
    resourcePrincipalId
    isResourcePrincipalDeleted
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
  }
  ${AccessFragmentDoc}
  ${ResourcePreviewTinyFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
`;
export const AccessReviewResourceReviewerStatusFragmentDoc = gql`
  fragment AccessReviewResourceReviewerStatus on AccessReviewResourceReviewerStatus {
    itemStatus
    numUsersReviewed
    numUsersAssigned
    numNHIsReviewed
    numNHIsAssigned
    numResourcesReviewed
    numResourcesAssigned
  }
`;
export const AccessReviewResourceFragmentDoc = gql`
  fragment AccessReviewResource on AccessReviewResource {
    id
    createdAt
    updatedAt
    resourceId
    accessReviewId
    accessReview {
      ...AccessReviewPreview
    }
    resource {
      ...ResourcePreviewLarge
    }
    reviewerUsers {
      ...ReviewerUser
    }
    resourceUsers {
      ...AccessReviewResourceUserPreview
    }
    numResourceUsers
    resourceResources {
      ...AccessReviewResourceResource
    }
    eventId
    canEditReviewers
    warnings {
      ...AccessReviewResourceUserWarning
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewResourceReviewerStatus
    }
  }
  ${AccessReviewPreviewFragmentDoc}
  ${ResourcePreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserPreviewFragmentDoc}
  ${AccessReviewResourceResourceFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewResourceReviewerStatusFragmentDoc}
`;
export const OngoingAccessReviewSubtabStatsFragmentDoc = gql`
  fragment OngoingAccessReviewSubtabStats on OngoingAccessReviewSubtabStats {
    numResourcesToReview
    numGroupsToReview
    numUsersToReview
  }
`;
export const AccessReviewTemplatePreviewFragmentDoc = gql`
  fragment AccessReviewTemplatePreview on AccessReviewTemplate {
    id
    createdAt
    createdByUserId
    updatedAt
    name
    accessReviewDuration
    monthSchedule
    nextScheduledRun
    startDayOfMonth
    timeZone
    reminderSchedule
    reminderTimeOfDay
    reminderIncludeManager
    sendReviewerAssignmentNotification
    selfReviewAllowed
    reviewerAssignmentPolicy
    includeGroupBindings
  }
`;
export const AccessReviewUserPreviewFragmentDoc = gql`
  fragment AccessReviewUserPreview on AccessReviewUser {
    userId
    user {
      ...UserPreviewLarge
    }
    accessReviewId
    reviewerUsers {
      ...ReviewerUser
    }
    resourceUsers {
      id
    }
    groupUsers {
      id
    }
    resourceWarnings {
      ...AccessReviewResourceUserWarning
    }
    groupWarnings {
      ...AccessReviewGroupUserWarning
    }
    status
    canEditReviewers
  }
  ${UserPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
`;
export const AccessReviewUserResourcePreviewFragmentDoc = gql`
  fragment AccessReviewUserResourcePreview on AccessReviewResourceUser {
    id
    createdAt
    updatedAt
    userId
    resourceId
    resource {
      ...ResourcePreviewSmall
      connectionId
      connection {
        ...ConnectionPreviewSmall
      }
    }
    accessReviewResourceId
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewResourceUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    resourceUser {
      ...ResourceUserPreview
    }
    errorMessage
    statusCode
    taskType
    lastSynced
    requestId
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${ResourceUserPreviewFragmentDoc}
`;
export const AccessReviewUserGroupPreviewFragmentDoc = gql`
  fragment AccessReviewUserGroupPreview on AccessReviewGroupUser {
    id
    createdAt
    updatedAt
    userId
    groupId
    group {
      ...GroupPreviewSmall
      id
      connection {
        ...ConnectionPreviewSmall
      }
      onCallSchedules {
        ...GroupOnCallSchedule
      }
    }
    accessReviewGroupId
    eventId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    warnings {
      ...AccessReviewGroupUserWarning
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    groupUser {
      ...GroupUserUARPreview
    }
    errorMessage
    statusCode
    taskType
    lastSynced
  }
  ${GroupPreviewSmallFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${GroupOnCallScheduleFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${GroupUserUarPreviewFragmentDoc}
`;
export const AccessReviewUserFragmentDoc = gql`
  fragment AccessReviewUser on AccessReviewUser {
    userId
    user {
      ...UserPreviewLarge
    }
    accessReviewId
    accessReview {
      ...AccessReviewPreview
    }
    reviewerUsers {
      ...ReviewerUser
    }
    resourceUsers {
      ...AccessReviewUserResourcePreview
    }
    groupUsers {
      ...AccessReviewUserGroupPreview
    }
    resourceWarnings {
      ...AccessReviewResourceUserWarning
    }
    groupWarnings {
      ...AccessReviewGroupUserWarning
    }
    status
    canEditReviewers
  }
  ${UserPreviewLargeFragmentDoc}
  ${AccessReviewPreviewFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewUserResourcePreviewFragmentDoc}
  ${AccessReviewUserGroupPreviewFragmentDoc}
  ${AccessReviewResourceUserWarningFragmentDoc}
  ${AccessReviewGroupUserWarningFragmentDoc}
`;
export const TagKeyValueFragmentDoc = gql`
  fragment TagKeyValue on TagKeyValue {
    key
    value
  }
`;
export const AccessReviewFiltersFragmentDoc = gql`
  fragment AccessReviewFilters on AccessReviewFilters {
    tags {
      ...TagKeyValue
    }
    names
    adminIDs
    resourceTypes
    groupTypes
    entityIDs
    connectionIDs
    userIDs
    matchMode
  }
  ${TagKeyValueFragmentDoc}
`;
export const AccessReviewTemplateFragmentDoc = gql`
  fragment AccessReviewTemplate on AccessReviewTemplate {
    id
    createdAt
    createdByUserId
    createdByUser {
      ...UserPreviewSmall
    }
    updatedAt
    name
    accessReviewDuration
    monthSchedule
    nextScheduledRun
    startDayOfMonth
    timeZone
    reminderSchedule
    reminderTimeOfDay
    reminderIncludeManager
    sendReviewerAssignmentNotification
    selfReviewAllowed
    filters {
      ...AccessReviewFilters
    }
    reviewerAssignmentPolicy
    scopeResourcesCount
    scopeGroupsCount
    scopeAppsCount
    includeGroupBindings
    groupResourceVisibilityPolicy
  }
  ${UserPreviewSmallFragmentDoc}
  ${AccessReviewFiltersFragmentDoc}
`;
export const OngoingAccessReviewTabStatsFragmentDoc = gql`
  fragment OngoingAccessReviewTabStats on OngoingAccessReviewTabStats {
    numResourcesToReview
    numGroupsToReview
    numAppsToReview
    numUsersToReview
  }
`;
export const AccessReviewBaseFragmentDoc = gql`
  fragment AccessReviewBase on AccessReview {
    id
    createdAt
    startedByUserId
    startedByUser {
      ...UserPreviewSmall
    }
    stoppedByUserId
    stoppedByUser {
      ...UserPreviewSmall
    }
    stoppedDate
    reportId
    deadline
    selfReviewAllowed
    name
    timeZone
    reminderSchedule
    reminderTimeOfDay
    reminderIncludeManager
    sendReviewerAssignmentNotification
    lastReminderAt
    groupResourceVisibilityPolicy
    filters {
      ...AccessReviewFilters
    }
    reviewerAssignmentPolicy
    accessReviewTemplateId
    accessReviewTemplate {
      ...AccessReviewTemplate
    }
    itemsToReviewStats {
      ...OngoingAccessReviewTabStats
    }
    includeGroupBindings
  }
  ${UserPreviewSmallFragmentDoc}
  ${AccessReviewFiltersFragmentDoc}
  ${AccessReviewTemplateFragmentDoc}
  ${OngoingAccessReviewTabStatsFragmentDoc}
`;
export const IncompleteAccessReviewerFragmentDoc = gql`
  fragment IncompleteAccessReviewer on IncompleteAccessReviewer {
    userId
    user {
      ...UserPreviewSmall
    }
    incompleteResources
    incompleteGroups
    incompleteConnections
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const AccessReviewStatsFragmentDoc = gql`
  fragment AccessReviewStats on AccessReviewStats {
    numResourcesReviewed
    numResourcesTotal
    numGroupsReviewed
    numGroupsTotal
    numConnectionsReviewed
    numConnectionsTotal
    numReviewersTotal
    numReviewersIncomplete
    numUserAssignmentsTotal
    numUserAssignmentsInReview
    numUserAssignmentsCompleted
    numGroupResourceAssignmentsTotal
    numGroupResourceAssignmentsInReview
    numGroupResourceAssignmentsCompleted
    numNHIAssignmentsTotal
    numNHIAssignmentsInReview
    numNHIAssignmentsCompleted
    incompleteReviewers {
      ...IncompleteAccessReviewer
    }
  }
  ${IncompleteAccessReviewerFragmentDoc}
`;
export const AccessReviewFragmentDoc = gql`
  fragment AccessReview on AccessReview {
    ...AccessReviewBase
    stats {
      ...AccessReviewStats
    }
  }
  ${AccessReviewBaseFragmentDoc}
  ${AccessReviewStatsFragmentDoc}
`;
export const AppInfoFragmentDoc = gql`
  fragment AppInfo on AppInfo {
    isOnPrem
    version
    environment
    isRemoteLoggingEnabled
  }
`;
export const FirstPartyTokenFragmentDoc = gql`
  fragment FirstPartyToken on FirstPartyToken {
    id
    createdAt
    tokenPreview
    tokenLabel
    creatorUserId
    creatorUser {
      ...UserPreviewSmall
    }
    lastUsedAt
    expiresAt
    accessLevel
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const AuthProviderInfoFragmentDoc = gql`
  fragment AuthProviderInfo on AuthProviderInfo {
    domain
    audience
    clientId
    redirectUri
    logoutUri
  }
`;
export const EntityIdTupleFragmentDoc = gql`
  fragment EntityIdTuple on EntityIdTuple {
    entityId
    entityType
  }
`;
export const ErrorEntityFragmentDoc = gql`
  fragment ErrorEntity on ErrorEntity {
    id {
      ...EntityIdTuple
    }
    name
  }
  ${EntityIdTupleFragmentDoc}
`;
export const ConnectionUserPropagationStatusFragmentDoc = gql`
  fragment ConnectionUserPropagationStatus on ConnectionUserPropagationStatus {
    connectionId
    userId
    lastSynced
    statusCode
    errorMessage
    taskType
  }
`;
export const ConnectionUserFragmentDoc = gql`
  fragment ConnectionUser on ConnectionUser {
    __typename
    connectionId
    userId
    user {
      ...UserPreviewLarge
    }
    propagationStatus {
      ...ConnectionUserPropagationStatus
    }
  }
  ${UserPreviewLargeFragmentDoc}
  ${ConnectionUserPropagationStatusFragmentDoc}
`;
export const ConnectionPreviewFragmentDoc = gql`
  fragment ConnectionPreview on Connection {
    ...ConnectionPreviewLarge
    importVisibilityGroups {
      ...ConnectionVisibilityGroup
    }
    visibilityGroups {
      ...ConnectionVisibilityGroup
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${ConnectionVisibilityGroupFragmentDoc}
`;
export const EventFilterFragmentDoc = gql`
  fragment EventFilter on EventFilter {
    id
    organizationId
    name
    creatorId
    creator {
      ...UserPreviewSmall
    }
    startDate
    endDate
    actorId
    actor {
      ...UserPreviewSmall
    }
    eventTypes
    objectId
    createdAt
    systemEvents
    apiTokenLabel
    apiTokenPreview
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const EntityDisplayInfoFragmentDoc = gql`
  fragment EntityDisplayInfo on EntityDisplayInfo {
    resourceType
    groupType
    connectionType
    avatarURL
    provider
  }
`;
export const SubEventFragmentDoc = gql`
  fragment SubEvent on SubEvent {
    id
    createdAt
    subEventType
    objectId {
      ...EntityIdTuple
    }
    object {
      ...EntityDisplayInfo
    }
    objectName
    secondaryObjectId {
      ...EntityIdTuple
    }
    secondaryObject {
      ...EntityDisplayInfo
    }
    secondaryObjectName
    tertiaryObjectId {
      ...EntityIdTuple
    }
    tertiaryObject {
      ...EntityDisplayInfo
    }
    tertiaryObjectName
    object4Id {
      ...EntityIdTuple
    }
    object4 {
      ...EntityDisplayInfo
    }
    object4Name
    metadata
  }
  ${EntityIdTupleFragmentDoc}
  ${EntityDisplayInfoFragmentDoc}
`;
export const EventFragmentDoc = gql`
  fragment Event on Event {
    id
    createdAt
    actorUserId
    actorUser {
      ...UserPreviewSmall
    }
    actorIP
    actorUserAgent
    eventType
    subEvents {
      ...SubEvent
    }
    source
    apiTokenLabel
    apiTokenPreview
  }
  ${UserPreviewSmallFragmentDoc}
  ${SubEventFragmentDoc}
`;
export const EventPreviewFragmentDoc = gql`
  fragment EventPreview on Event {
    id
    createdAt
    actorUserId
    actorUser {
      ...UserPreviewSmall
    }
    actorIP
    actorUserAgent
    eventType
    source
    apiTokenLabel
    apiTokenPreview
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const GroupGroupAccessPointFragmentDoc = gql`
  fragment GroupGroupAccessPoint on GroupGroupAccessPoint {
    containingGroupId
    memberGroupId
    accessLevel {
      ...GroupAccessLevel
    }
    containingGroupName
    memberGroupName
    memberGroupType
    eventId
    createdAt
    expiration
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
  }
  ${GroupAccessLevelFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
`;
export const IndirectGroupGroupAccessPointPathFragmentDoc = gql`
  fragment IndirectGroupGroupAccessPointPath on IndirectGroupGroupAccessPointPath {
    startGroupId
    endGroupId
    accessLevel {
      ...GroupAccessLevel
    }
    resolvedAccessPoint {
      ...GroupGroupAccessPoint
    }
    indirectAccessPointPath {
      ...IndirectGroupAccessPoint
    }
  }
  ${GroupAccessLevelFragmentDoc}
  ${GroupGroupAccessPointFragmentDoc}
  ${IndirectGroupAccessPointFragmentDoc}
`;
export const GroupGroupAccessFragmentDoc = gql`
  fragment GroupGroupAccess on GroupGroupAccess {
    containingGroupId
    memberGroupId
    accessLevel {
      ...GroupAccessLevel
    }
    directAccessPoint {
      ...GroupGroupAccessPoint
    }
    inheritedAccessPoints {
      ...GroupGroupAccessPoint
    }
    indirectAccessPointPaths {
      ...IndirectGroupGroupAccessPointPath
    }
    latestExpiringAccessPoint {
      ...GroupGroupAccessPoint
    }
  }
  ${GroupAccessLevelFragmentDoc}
  ${GroupGroupAccessPointFragmentDoc}
  ${IndirectGroupGroupAccessPointPathFragmentDoc}
`;
export const GroupResourcePropagationStatusFragmentDoc = gql`
  fragment GroupResourcePropagationStatus on GroupResourcePropagationStatus {
    groupId
    resourceId
    accessLevelRemoteId
    lastSynced
    statusCode
    errorMessage
    taskType
  }
`;
export const GroupResourcePreviewFragmentDoc = gql`
  fragment GroupResourcePreview on GroupResource {
    groupId
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...GroupResourceAccess
    }
    propagationStatus {
      ...GroupResourcePropagationStatus
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
  ${GroupResourcePropagationStatusFragmentDoc}
`;
export const GroupPreviewWithAccessLevelsFragmentDoc = gql`
  fragment GroupPreviewWithAccessLevels on Group {
    id
    remoteId
    name
    remoteName
    numGroupUsers
    groupType
    serviceType
    visibility
    isDeleted
    iconUrl
    visibilityGroups {
      ...GroupVisibilityGroup
    }
    isOnCallSynced
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    accessLevels {
      ...GroupAccessLevel
    }
  }
  ${GroupVisibilityGroupFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
`;
export const GroupResourceFragmentDoc = gql`
  fragment GroupResource on GroupResource {
    groupId
    resourceId
    resource {
      ...ResourcePreviewSmall
      parentResource {
        id
        name
      }
      connection {
        id
        name
        connectionType
      }
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...GroupResourceAccess
    }
    propagationStatus {
      ...GroupResourcePropagationStatus
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
  ${GroupResourcePropagationStatusFragmentDoc}
`;
export const GroupGroupQuickAccessFragmentDoc = gql`
  fragment GroupGroupQuickAccess on GroupGroupQuickAccess {
    numInheritedAccess
    hasDirectAccess
    expiration
    supportTicketId
    supportTicket {
      ...SupportTicketPreview
    }
  }
  ${SupportTicketPreviewFragmentDoc}
`;
export const GroupGroupFragmentDoc = gql`
  fragment GroupGroup on GroupGroup {
    id
    containingGroupId
    memberGroupId
    memberGroup {
      ...GroupPreviewSmall
    }
    containingGroup {
      ...GroupPreviewSmall
    }
    accessLevel {
      ...GroupAccessLevel
    }
    quickAccess {
      ...GroupGroupQuickAccess
    }
  }
  ${GroupPreviewSmallFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
  ${GroupGroupQuickAccessFragmentDoc}
`;
export const GroupUserFragmentDoc = gql`
  fragment GroupUser on GroupUser {
    groupId
    userId
    user {
      ...UserPreviewLarge
    }
    access {
      ...GroupUserAccess
    }
    propagationStatus {
      ...GroupPropagationStatus
    }
    accessLevel {
      ...GroupAccessLevel
    }
    source
  }
  ${UserPreviewLargeFragmentDoc}
  ${GroupUserAccessFragmentDoc}
  ${GroupPropagationStatusFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
`;
export const GroupBreakGlassUserFragmentDoc = gql`
  fragment GroupBreakGlassUser on GroupBreakGlassUser {
    __typename
    userId
    groupId
    user {
      ...UserPreviewSmall
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const MessageChannelFragmentDoc = gql`
  fragment MessageChannel on MessageChannel {
    id
    remoteId
    thirdPartyProvider
    isPrivate
    name
  }
`;
export const ReviewerStageFragmentDoc = gql`
  fragment ReviewerStage on ReviewerStage {
    id
    stage
    requireManagerApproval
    requireAdminApproval
    operator
    owners {
      id
      name
    }
  }
`;
export const RequestConfigurationFragmentDoc = gql`
  fragment RequestConfiguration on RequestConfiguration {
    id
    priority
    groupId
    groupIds
    roleIds
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    autoApproval
    reasonOptional
    reviewerStages {
      ...ReviewerStage
    }
    requestTemplateId
  }
  ${ReviewerStageFragmentDoc}
`;
export const GroupFragmentDoc = gql`
  fragment Group on Group {
    __typename
    id
    remoteId
    name
    remoteName
    groupType
    iconUrl
    numGroupUsers
    serviceType
    visibility
    isDeleted
    createdAt
    updatedAt
    description
    maxDurationInMinutes
    recommendedDurationInMinutes
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    currentUserAccess {
      ...CurrentUserGroupAccess
    }
    groupResources {
      ...GroupResource
      resource {
        authorizedActions
      }
    }
    containingGroups {
      ...GroupGroup
      containingGroup {
        authorizedActions
      }
    }
    memberGroups {
      ...GroupGroup
      memberGroup {
        authorizedActions
      }
    }
    groupUsers {
      ...GroupUser
    }
    breakGlassUsers {
      ...GroupBreakGlassUser
    }
    isOnCallSynced
    onCallSchedules {
      ...GroupOnCallSchedule
    }
    auditMessageChannels {
      ...MessageChannel
    }
    reviewerMessageChannel {
      ...MessageChannel
    }
    tags {
      ...GroupTagPreview
    }
    requireMfaToApprove
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    visibilityGroups {
      ...GroupVisibilityGroup
    }
    authorizedActions
    requestTemplateId
    requestTemplate {
      ...RequestTemplate
    }
    reviewerStages {
      ...ReviewerStage
    }
    requestConfigs {
      ...RequestConfiguration
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    configTemplate {
      id
      name
    }
    groupBinding {
      ...GroupBinding
    }
    groupLeaders {
      ...UserPreviewSmall
    }
    customRequestNotification
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${CurrentUserGroupAccessFragmentDoc}
  ${GroupResourceFragmentDoc}
  ${GroupGroupFragmentDoc}
  ${GroupUserFragmentDoc}
  ${GroupBreakGlassUserFragmentDoc}
  ${GroupOnCallScheduleFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${GroupTagPreviewFragmentDoc}
  ${GroupVisibilityGroupFragmentDoc}
  ${RequestTemplateFragmentDoc}
  ${ReviewerStageFragmentDoc}
  ${RequestConfigurationFragmentDoc}
  ${GroupBindingFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;
export const RemoteGroupFragmentDoc = gql`
  fragment RemoteGroup on RemoteGroup {
    __typename
    id
    name
    metadata
    iconUrl
    groupType
  }
`;
export const IdpConnectionFragmentDoc = gql`
  fragment IdpConnection on IdpConnection {
    __typename
    id
    idpConnectionType
    customAttributesAllowlist
    userAttributeImportMappings {
      id
      key
      useAs
    }
    isDeleted
  }
`;
export const FactorFragmentDoc = gql`
  fragment Factor on Factor {
    factorType
    factorRemoteId
  }
`;
export const OrganizationSettingsFragmentDoc = gql`
  fragment OrganizationSettings on OrganizationSettings {
    id
    createdAt
    generalSettings
    authSessionExpirationInMinutes
    maxResourceDurationInMinutes
    maxGroupDurationInMinutes
    roleCreationReviewerOwner {
      id
      name
    }
    isRemoteReadOnly
    auditTicketProvider {
      ticketProvider
      ticketProjectId
      ticketProjectName
    }
    dryRunModeEnabled
    errorNotificationSetting
    syncErrorHandlerOwnerId
    accessExpiringNotifications
    customerSupportExpiresAt
    customerSupportGatingAllowed
  }
`;
export const OrganizationWebhookInfoFragmentDoc = gql`
  fragment OrganizationWebhookInfo on OrganizationWebhookInfo {
    id
    webhookEndpointUrl
    enableWebhook
    signingSecretPreview
    signingSecret
  }
`;
export const OrganizationSamlInfoFragmentDoc = gql`
  fragment OrganizationSamlInfo on OrganizationSamlInfo {
    id
    email
    endpoint
    certExpiration
    restrictLoginToSaml
    samlBreakGlassUsers {
      ...UserPreviewSmall
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const OwnerUserFragmentDoc = gql`
  fragment OwnerUser on OwnerUser {
    user {
      ...UserPreviewSmall
    }
    reviewerPriority
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const OwnedResourceFragmentDoc = gql`
  fragment OwnedResource on OwnedResource {
    resource {
      id
      name
      connection {
        id
        name
        connectionType
      }
      iconUrl
      resourceType
      parentResource {
        id
        name
      }
    }
    isReviewer
    isAdmin
  }
`;
export const OwnedGroupFragmentDoc = gql`
  fragment OwnedGroup on OwnedGroup {
    group {
      id
      name
      groupType
      connection {
        id
        name
        connectionType
      }
      isOnCallSynced
    }
    isReviewer
    isAdmin
  }
`;
export const OwnedConfigurationTemplateFragmentDoc = gql`
  fragment OwnedConfigurationTemplate on OwnedConfigurationTemplate {
    configurationTemplate {
      id
      name
    }
    isReviewer
    isAdmin
  }
`;
export const OwnerFragmentDoc = gql`
  fragment Owner on Owner {
    __typename
    id
    name
    description
    accessRequestEscalationPeriodInMinutes
    reviewerMessageChannel {
      ...MessageChannel
    }
    sourceGroup {
      ...GroupPreviewSmall
    }
    ownerUsers {
      ...OwnerUser
    }
    ownedResources {
      ...OwnedResource
    }
    ownedGroups {
      ...OwnedGroup
    }
    ownedConfigurationTemplates {
      ...OwnedConfigurationTemplate
    }
  }
  ${MessageChannelFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${OwnerUserFragmentDoc}
  ${OwnedResourceFragmentDoc}
  ${OwnedGroupFragmentDoc}
  ${OwnedConfigurationTemplateFragmentDoc}
`;
export const PushTaskPendingFragmentDoc = gql`
  fragment PushTaskPending on PushTaskPending {
    id
    taskType
    nextProcessAt
  }
`;
export const ResourceUserPushTaskResultFragmentDoc = gql`
  fragment ResourceUserPushTaskResult on ResourceUserPushTaskResult {
    propStatuses {
      ...ResourcePropagationStatus
    }
  }
  ${ResourcePropagationStatusFragmentDoc}
`;
export const GroupUserPushTaskResultFragmentDoc = gql`
  fragment GroupUserPushTaskResult on GroupUserPushTaskResult {
    propStatuses {
      ...GroupPropagationStatus
    }
  }
  ${GroupPropagationStatusFragmentDoc}
`;
export const GroupResourcePushTaskResultFragmentDoc = gql`
  fragment GroupResourcePushTaskResult on GroupResourcePushTaskResult {
    propStatuses {
      ...GroupResourcePropagationStatus
    }
  }
  ${GroupResourcePropagationStatusFragmentDoc}
`;
export const ConnectionUserPushTaskResultFragmentDoc = gql`
  fragment ConnectionUserPushTaskResult on ConnectionUserPushTaskResult {
    propStatuses {
      ...ConnectionUserPropagationStatus
    }
  }
  ${ConnectionUserPropagationStatusFragmentDoc}
`;
export const GroupGroupPushTaskResultFragmentDoc = gql`
  fragment GroupGroupPushTaskResult on GroupGroupPushTaskResult {
    propStatuses {
      ...GroupPropagationStatus
    }
  }
  ${GroupPropagationStatusFragmentDoc}
`;
export const PropagationStatusFragmentDoc = gql`
  fragment PropagationStatus on PropagationStatus {
    roleAssignmentId
    lastSynced
    statusCode
    errorMessage
    taskType
  }
`;
export const RoleAssignmentPushTaskResultFragmentDoc = gql`
  fragment RoleAssignmentPushTaskResult on RoleAssignmentPushTaskResult {
    propStatuses {
      ...PropagationStatus
    }
  }
  ${PropagationStatusFragmentDoc}
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    createdAt
  }
`;
export const AuthUserFragmentDoc = gql`
  fragment AuthUser on AuthUser {
    user {
      ...UserPreviewLarge
      organization {
        ...Organization
      }
      directReports {
        ...UserPreviewLarge
      }
    }
    isAdmin
    isReadOnlyAdmin
    isAuditor
    isGlobalRequester
    organizationAuthorizedActions
    opalAdminExpirationTime
  }
  ${UserPreviewLargeFragmentDoc}
  ${OrganizationFragmentDoc}
`;
export const PushTaskCompleteFragmentDoc = gql`
  fragment PushTaskComplete on PushTaskComplete {
    id
    taskType
    result {
      __typename
      ... on ResourceUserPushTaskResult {
        ...ResourceUserPushTaskResult
      }
      ... on GroupUserPushTaskResult {
        ...GroupUserPushTaskResult
      }
      ... on GroupResourcePushTaskResult {
        ...GroupResourcePushTaskResult
      }
      ... on ConnectionUserPushTaskResult {
        ...ConnectionUserPushTaskResult
      }
      ... on GroupGroupPushTaskResult {
        ...GroupGroupPushTaskResult
      }
      ... on RoleAssignmentPushTaskResult {
        ...RoleAssignmentPushTaskResult
      }
    }
    authUser {
      ...AuthUser
    }
  }
  ${ResourceUserPushTaskResultFragmentDoc}
  ${GroupUserPushTaskResultFragmentDoc}
  ${GroupResourcePushTaskResultFragmentDoc}
  ${ConnectionUserPushTaskResultFragmentDoc}
  ${GroupGroupPushTaskResultFragmentDoc}
  ${RoleAssignmentPushTaskResultFragmentDoc}
  ${AuthUserFragmentDoc}
`;
export const RequestTemplateSmallFragmentDoc = gql`
  fragment RequestTemplateSmall on RequestTemplateSimple {
    id
    name
  }
`;
export const RequestPreviewLargeFragmentDoc = gql`
  fragment RequestPreviewLarge on Request {
    id
    requesterId
    requester {
      ...UserPreviewSmall
    }
    targetUserId
    targetUser {
      ...UserPreviewSmall
    }
    targetGroupId
    targetGroup {
      ...GroupPreviewSmall
    }
    requestedResources {
      ...RequestedResourcePreviewSmall
    }
    requestedGroups {
      ...RequestedGroupPreviewSmall
    }
    resourceProposalId
    resourceProposal {
      ...ResourceProposal
    }
    durationInMinutes
    createdAt
    updatedAt
    status
  }
  ${UserPreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${RequestedResourcePreviewSmallFragmentDoc}
  ${RequestedGroupPreviewSmallFragmentDoc}
  ${ResourceProposalFragmentDoc}
`;
export const RequestedResourceFragmentDoc = gql`
  fragment RequestedResource on RequestedResource {
    resourceId
    resource {
      ...ResourcePreviewLarge
      parentResource {
        id
        name
      }
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    propagationStatus {
      ...ResourcePropagationStatus
    }
    targetUserAccess {
      ...UserResourceAccess
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${ResourcePropagationStatusFragmentDoc}
  ${UserResourceAccessFragmentDoc}
`;
export const RequestedGroupFragmentDoc = gql`
  fragment RequestedGroup on RequestedGroup {
    groupId
    group {
      ...GroupPreviewLarge
    }
    propagationStatus {
      ...GroupPropagationStatus
    }
    accessLevel {
      ...GroupAccessLevel
    }
    targetUserAccess {
      ...UserGroupAccess
    }
  }
  ${GroupPreviewLargeFragmentDoc}
  ${GroupPropagationStatusFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
  ${UserGroupAccessFragmentDoc}
`;
export const RequestCommentFragmentDoc = gql`
  fragment RequestComment on RequestComment {
    id
    userId
    user {
      ...UserPreviewSmall
    }
    createdAt
    comment
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const RequestReviewerFragmentDoc = gql`
  fragment RequestReviewer on RequestReviewer {
    owner {
      id
      name
    }
    userId
    user {
      ...UserPreviewLarge
    }
    reviewerAction
    reviewerType
  }
  ${UserPreviewLargeFragmentDoc}
`;
export const RequestStageFragmentDoc = gql`
  fragment RequestStage on RequestStage {
    operator
    stage
    reviewers {
      ...RequestReviewer
    }
  }
  ${RequestReviewerFragmentDoc}
`;
export const RequestCustomMetadataValueFragmentDoc = gql`
  fragment RequestCustomMetadataValue on RequestCustomMetadataValue {
    shortTextValue {
      value
    }
    longTextValue {
      value
    }
    booleanValue {
      value
    }
    multiChoiceValue {
      value
    }
  }
`;
export const RequestCustomMetadataFragmentDoc = gql`
  fragment RequestCustomMetadata on RequestCustomMetadata {
    fieldName
    fieldType
    metadataValue {
      ...RequestCustomMetadataValue
    }
  }
  ${RequestCustomMetadataValueFragmentDoc}
`;
export const RequestFragmentDoc = gql`
  fragment Request on Request {
    id
    requesterId
    requester {
      ...UserPreviewLarge
    }
    targetUserId
    targetUser {
      ...UserPreviewLarge
    }
    targetGroupId
    targetGroup {
      ...GroupPreviewSmall
    }
    requestedResources {
      ...RequestedResource
    }
    requestedGroups {
      ...RequestedGroup
    }
    createdAt
    updatedAt
    reason
    durationInMinutes
    status
    requestComments {
      ...RequestComment
    }
    supportTicket {
      ...SupportTicketPreview
    }
    requestTicket {
      ...SupportTicketPreview
    }
    stages {
      requestedItemName
      requestedRoleName
      stages {
        ...RequestStage
      }
    }
    reviewersError
    resourceProposalId
    resourceProposal {
      ...ResourceProposal
    }
    customMetadata {
      ...RequestCustomMetadata
    }
    escalatedToSkipManager
  }
  ${UserPreviewLargeFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${RequestedResourceFragmentDoc}
  ${RequestedGroupFragmentDoc}
  ${RequestCommentFragmentDoc}
  ${SupportTicketPreviewFragmentDoc}
  ${RequestStageFragmentDoc}
  ${ResourceProposalFragmentDoc}
  ${RequestCustomMetadataFragmentDoc}
`;
export const ResourceCustomAccessLevelFragmentDoc = gql`
  fragment ResourceCustomAccessLevel on ResourceCustomAccessLevel {
    id
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    policy
    updatedAt
    priority
  }
  ${ResourceAccessLevelFragmentDoc}
`;
export const ResourceUsageLogFragmentDoc = gql`
  fragment ResourceUsageLog on ResourceUsageLog {
    id
    user {
      ...UserPreviewSmall
    }
    name
    startDate
    endDate
    details
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const ResourcePreviewWithAccessLevelsFragmentDoc = gql`
  fragment ResourcePreviewWithAccessLevels on Resource {
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    resourceType
    remoteId
    iconUrl
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    parentResourceId
    parentResource {
      id
      name
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    accessLevels {
      ...ResourceAccessLevel
    }
  }
  ${ResourceVisibilityGroupFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
`;
export const ResourceGroupFragmentDoc = gql`
  fragment ResourceGroup on GroupResource {
    groupId
    group {
      ...GroupPreviewLarge
    }
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...GroupResourceAccess
    }
    propagationStatus {
      ...GroupResourcePropagationStatus
    }
  }
  ${GroupPreviewLargeFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
  ${GroupResourcePropagationStatusFragmentDoc}
`;
export const ResourcePreviewWithContainingGroupsFragmentDoc = gql`
  fragment ResourcePreviewWithContainingGroups on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    resourceType
    remoteId
    iconUrl
    tags {
      ...ResourceTagPreview
    }
    maxDurationInMinutes
    requireMfaToConnect
    requireMfaToApprove
    requireMfaToRequest
    requireSupportTicket
    autoApproval
    containingGroups {
      ...ResourceGroup
    }
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceGroupFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
`;
export const RoleAssignmentResourceFragmentDoc = gql`
  fragment RoleAssignmentResource on Resource {
    resourceId: id
    resourceRemoteId: remoteId
    name
    isManaged
    resourceType
    iconUrl
    ancestorPathToResource
  }
`;
export const RoleAssignmentGroupFragmentDoc = gql`
  fragment RoleAssignmentGroup on Group {
    groupId: id
    groupRemoteId: remoteId
    name
    isManaged
    groupType
    iconUrl
  }
`;
export const EntityFragmentDoc = gql`
  fragment Entity on Entity {
    ... on Resource {
      ...RoleAssignmentResource
    }
    ... on Group {
      ...RoleAssignmentGroup
    }
  }
  ${RoleAssignmentResourceFragmentDoc}
  ${RoleAssignmentGroupFragmentDoc}
`;
export const RoleAssignmentUserFragmentDoc = gql`
  fragment RoleAssignmentUser on User {
    userId: id
    name: fullName
    firstName
    lastName
    fullName
    email
    avatarUrl
    isSystemUser
  }
`;
export const PrincipalFragmentDoc = gql`
  fragment Principal on Principal {
    ... on Resource {
      ...RoleAssignmentResource
    }
    ... on Group {
      ...RoleAssignmentGroup
    }
    ... on User {
      ...RoleAssignmentUser
    }
  }
  ${RoleAssignmentResourceFragmentDoc}
  ${RoleAssignmentGroupFragmentDoc}
  ${RoleAssignmentUserFragmentDoc}
`;
export const RoleAssignmentFragmentDoc = gql`
  fragment RoleAssignment on RoleAssignment {
    __typename
    entityID
    entityType
    entity {
      ...Entity
    }
    principalID
    principalType
    principal {
      ...Principal
    }
    accessLevel {
      ...AccessLevel
    }
    access {
      ...Access
    }
    propagationStatus {
      ...PropagationStatus
    }
    provisionSource
  }
  ${EntityFragmentDoc}
  ${PrincipalFragmentDoc}
  ${AccessLevelFragmentDoc}
  ${AccessFragmentDoc}
  ${PropagationStatusFragmentDoc}
`;
export const ResourceFragmentDoc = gql`
  fragment Resource on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    maxDurationInMinutes
    recommendedDurationInMinutes
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    resourceUsers {
      ...ResourceUser
    }
    principalAssignmentsForEntity {
      ...RoleAssignment
    }
    entityAssignmentsForPrincipal {
      ...RoleAssignment
    }
    containingGroups {
      ...ResourceGroup
      group {
        authorizedActions
      }
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    auditMessageChannels {
      ...MessageChannel
    }
    resourceType
    remoteId
    iconUrl
    requireMfaToConnect
    requireMfaToApprove
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    tags {
      ...ResourceTagPreview
    }
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    authorizedActions
    requestTemplateId
    requestTemplate {
      ...RequestTemplate
    }
    parentResourceId
    parentResource {
      id
      name
    }
    childResources {
      ...ChildResourcePreview
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${ResourceUserFragmentDoc}
  ${RoleAssignmentFragmentDoc}
  ${ResourceGroupFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
  ${RequestTemplateFragmentDoc}
  ${ChildResourcePreviewFragmentDoc}
`;
export const RemoteResourceFragmentDoc = gql`
  fragment RemoteResource on RemoteResource {
    __typename
    id
    name
    metadata
    iconUrl
    resourceType
  }
`;
export const SearchResultEntryFragmentDoc = gql`
  fragment SearchResultEntry on SearchResultEntry {
    objectId {
      entityId
      entityType
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    name
    searchType
    resourceType
    groupType
    avatarUrl
    annotationText
  }
  ${ConnectionPreviewSmallFragmentDoc}
`;
export const SessionFragmentDoc = gql`
  fragment Session on Session {
    id
    resourceId
    resource {
      ...ResourcePreviewSmall
    }
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    createdAt
    userId
    user {
      ...UserPreviewSmall
    }
    durationInMinutes
    metadata {
      ...SessionMetadata
    }
    endTime
    accessLevel
    accessLevelRemoteId
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
  ${SessionMetadataFragmentDoc}
`;
export const SupportTicketsResultFragmentDoc = gql`
  fragment SupportTicketsResult on SupportTicketsResult {
    tickets {
      ...SupportTicket
    }
  }
  ${SupportTicketFragmentDoc}
`;
export const TicketProjectFragmentDoc = gql`
  fragment TicketProject on TicketProject {
    name
    id
    key
    avatarUrl
  }
`;
export const SyncStepFragmentDoc = gql`
  fragment SyncStep on SyncTaskStep {
    name
    totalNumItems
    numItemsCompleted
  }
`;
export const SyncTaskFragmentDoc = gql`
  fragment SyncTask on SyncTask {
    id
    createdAt
    updatedAt
    syncType
    startedByUserId
    startedByUser {
      ...UserPreviewSmall
    }
    status
    progress {
      steps {
        ...SyncStep
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
  ${SyncStepFragmentDoc}
`;
export const SyncErrorFragmentDoc = gql`
  fragment SyncError on SyncError {
    id
    createdAt
    firstSeenAt
    connectionId
    connection {
      ...ConnectionPreviewSmall
    }
    message
    stackTrace
  }
  ${ConnectionPreviewSmallFragmentDoc}
`;
export const TagPreviewLargeFragmentDoc = gql`
  fragment TagPreviewLarge on Tag {
    __typename
    id
    createdAt
    creatorUserId
    key
    value
    creator {
      ...UserPreviewSmall
    }
    isStandardAttribute
  }
  ${UserPreviewSmallFragmentDoc}
`;
export const UserTagFragmentDoc = gql`
  fragment UserTag on UserTag {
    id
    userId
    user {
      ...UserPreviewLarge
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${UserPreviewLargeFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const ResourceTagFragmentDoc = gql`
  fragment ResourceTag on ResourceTag {
    id
    resourceId
    resource {
      ...ResourcePreviewLarge
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const GroupTagFragmentDoc = gql`
  fragment GroupTag on GroupTag {
    groupId
    group {
      ...GroupPreviewLarge
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${GroupPreviewLargeFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    __typename
    id
    createdAt
    creatorUserId
    key
    value
    creator {
      ...UserPreviewSmall
    }
    tagUsers {
      ...UserTag
    }
    tagResources {
      ...ResourceTag
    }
    tagGroups {
      ...GroupTag
    }
    isStandardAttribute
  }
  ${UserPreviewSmallFragmentDoc}
  ${UserTagFragmentDoc}
  ${ResourceTagFragmentDoc}
  ${GroupTagFragmentDoc}
`;
export const ThirdPartyIntegrationFragmentDoc = gql`
  fragment ThirdPartyIntegration on ThirdPartyIntegration {
    id
    thirdPartyProvider
    authFlowType
    connectionActive
    connectionId
    slackMetadata {
      ... on ThirdPartyIntegrationSlackMetadata {
        enterpriseId
        enterpriseName
        appId
        workspaces {
          id
          name
        }
      }
    }
  }
`;
export const UserSettingsFragmentDoc = gql`
  fragment UserSettings on UserSettings {
    id
    createdAt
    allNotifications
  }
`;
export const UserTagPreviewFragmentDoc = gql`
  fragment UserTagPreview on UserTag {
    id
    userId
    user {
      ...UserPreviewSmall
    }
    tagId
    tag {
      ...TagPreviewSmall
    }
    source
    updatedAt
    createdAt
  }
  ${UserPreviewSmallFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
`;
export const UserResourceFragmentDoc = gql`
  fragment UserResource on ResourceUser {
    resourceId
    resource {
      ...ResourcePreviewSmall
      parentResource {
        id
        name
      }
      connection {
        id
        name
        connectionType
      }
    }
    userId
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...ResourceUserAccess
    }
    propagationStatus {
      ...ResourcePropagationStatus
    }
    source
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserAccessFragmentDoc}
  ${ResourcePropagationStatusFragmentDoc}
`;
export const UserGroupFragmentDoc = gql`
  fragment UserGroup on GroupUser {
    groupId
    group {
      ...GroupPreviewLarge
    }
    userId
    access {
      ...GroupUserAccess
    }
    propagationStatus {
      ...GroupPropagationStatus
    }
    source
  }
  ${GroupPreviewLargeFragmentDoc}
  ${GroupUserAccessFragmentDoc}
  ${GroupPropagationStatusFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    secondaryEmails
    firstName
    lastName
    fullName
    avatarUrl
    isDeleted
    isSystemUser
    createdAt
    updatedAt
    organizationId
    organization {
      ...Organization
    }
    position
    teamAttr
    managerId
    manager {
      ...UserPreviewSmall
    }
    directReports {
      ...UserPreviewLarge
    }
    userResources {
      ...UserResource
    }
    userGroups {
      ...UserGroup
    }
    hrIdpStatus
  }
  ${OrganizationFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
  ${UserPreviewLargeFragmentDoc}
  ${UserResourceFragmentDoc}
  ${UserGroupFragmentDoc}
`;
export const AppDropdownPreviewFragmentDoc = gql`
  fragment AppDropdownPreview on App {
    id
    name
    app {
      ... on OktaResourceApp {
        resourceId
        iconUrl: iconUrl
      }
      ... on ConnectionApp {
        connectionId
        connectionType
      }
    }
  }
`;
export const GroupBindingDropdownPreviewFragmentDoc = gql`
  fragment GroupBindingDropdownPreview on GroupBinding {
    id
    sourceGroupId
    sourceGroup {
      ...GroupBindingGroupPreview
    }
    groups {
      ...GroupPreviewTiny
    }
  }
  ${GroupBindingGroupPreviewFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
`;
export const GroupDropdownPreviewFragmentDoc = gql`
  fragment GroupDropdownPreview on Group {
    id
    name
    groupType
    connectionId
    numGroupUsers
    isOnCallSynced
    authorizedActions
    connection {
      id
      name
      connectionType
      iconUrl
    }
  }
`;
export const ResourceDropdownPreviewFragmentDoc = gql`
  fragment ResourceDropdownPreview on Resource {
    id
    name
    iconUrl
    resourceType
    serviceType
    remoteId
    numChildResources
    authorizedActions
    connection {
      id
      connectionType
      name
    }
    parentResource {
      name
    }
  }
`;
export const SearchUserPreviewFragmentDoc = gql`
  fragment SearchUserPreview on User {
    id
    fullName
    avatarUrl
    isSystemUser
  }
`;
export const SearchResourcePreviewFragmentDoc = gql`
  fragment SearchResourcePreview on Resource {
    id
    name
    iconUrl
    resourceType
    serviceType
    remoteId
    numChildResources
    parentResource {
      name
    }
    authorizedActions
    connection {
      id
      name
      connectionType
    }
  }
`;
export const SearchGroupPreviewFragmentDoc = gql`
  fragment SearchGroupPreview on Group {
    id
    name
    groupType
    isOnCallSynced
    authorizedActions
    connectionId
    connection {
      id
      name
      connectionType
    }
    numGroupUsers
  }
`;
export const SearchGroupTypePreviewFragmentDoc = gql`
  fragment SearchGroupTypePreview on GroupTypeWithCount {
    connection {
      id
      name
    }
    groupType
    numGroups
  }
`;
export const SearchResourceTypePreviewFragmentDoc = gql`
  fragment SearchResourceTypePreview on ResourceTypeWithCount {
    connection {
      id
      name
    }
    resourceType
    numResources
  }
`;
export const ActiveRequestConfigurationFragmentDoc = gql`
  fragment ActiveRequestConfiguration on RequestConfiguration {
    isRequestable
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireSupportTicket
    requestTemplate {
      ...RequestTemplate
    }
    reasonOptional
  }
  ${RequestTemplateFragmentDoc}
`;
export const CurrentUserGroupAccessTinyFragmentDoc = gql`
  fragment CurrentUserGroupAccessTiny on CurrentUserGroupAccess {
    groupId
    groupUser {
      ...GroupUserPreview
    }
    hasBreakGlass
    pendingRequest {
      id
      requesterId
      targetUserId
      targetGroupId
      createdAt
      updatedAt
      status
    }
  }
  ${GroupUserPreviewFragmentDoc}
`;
export const EndUserGroupDetailsGroupGroupsFragmentDoc = gql`
  fragment EndUserGroupDetailsGroupGroups on GroupGroup {
    containingGroupId
    containingGroup {
      name
      description
      iconUrl
      groupType
      connection {
        name
        connectionType
      }
      currentUserAccess {
        ...CurrentUserGroupAccessTiny
      }
    }
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
  }
  ${CurrentUserGroupAccessTinyFragmentDoc}
`;
export const CurrentUserResourceAccessTinyFragmentDoc = gql`
  fragment CurrentUserResourceAccessTiny on CurrentUserResourceAccess {
    resourceId
    resourceUsers {
      ...ResourceUser
    }
    activeSessions {
      ...SessionPreview
    }
    pendingRequests {
      id
      requesterId
      targetUserId
      targetGroupId
      createdAt
      updatedAt
      status
      requestedResources {
        ...RequestedResourcePreviewSmall
      }
    }
    numAvailableAccessLevels
  }
  ${ResourceUserFragmentDoc}
  ${SessionPreviewFragmentDoc}
  ${RequestedResourcePreviewSmallFragmentDoc}
`;
export const EndUserGroupDetailsGroupResourcesFragmentDoc = gql`
  fragment EndUserGroupDetailsGroupResources on GroupResource {
    resourceId
    groupId
    accessLevel {
      accessLevelRemoteId
    }
    resource {
      name
      description
      resourceType
      ancestorPathToResource
      connection {
        name
      }
      currentUserAccess {
        ...CurrentUserResourceAccessTiny
      }
    }
  }
  ${CurrentUserResourceAccessTinyFragmentDoc}
`;
export const EndUserGroupDetailsFragmentDoc = gql`
  fragment EndUserGroupDetails on Group {
    __typename
    id
    name
    description
    groupType
    serviceType
    isRequestable
    numGroupUsers
    connectionId
    accessStats {
      teamAccessCount
      titleAccessCount
      totalAccessCount
    }
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
          fullName
          avatarUrl
        }
      }
    }
    containingGroups {
      ...EndUserGroupDetailsGroupGroups
    }
    groupResources {
      ...EndUserGroupDetailsGroupResources
    }
    connection {
      name
      connectionType
    }
    currentUserAccess {
      ...CurrentUserGroupAccessTiny
    }
  }
  ${EndUserGroupDetailsGroupGroupsFragmentDoc}
  ${EndUserGroupDetailsGroupResourcesFragmentDoc}
  ${CurrentUserGroupAccessTinyFragmentDoc}
`;
export const EndUserResourceDetailsFragmentDoc = gql`
  fragment EndUserResourceDetails on Resource {
    __typename
    id
    name
    description
    resourceType
    serviceType
    isRequestable
    remoteId
    numResourceUsers
    ancestorPathToResource
    accessStats {
      teamAccessCount
      titleAccessCount
      totalAccessCount
    }
    connectionId
    connection {
      name
      connectionType
    }
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
          fullName
          avatarUrl
        }
      }
    }
    containingGroups {
      group {
        name
        iconUrl
        connection {
          connectionType
        }
      }
      groupId
      resourceId
      accessLevel {
        accessLevelName
        accessLevelRemoteId
      }
    }
    currentUserAccess {
      ...CurrentUserResourceAccessTiny
    }
  }
  ${CurrentUserResourceAccessTinyFragmentDoc}
`;
export const ConnectionSummaryFragmentDoc = gql`
  fragment ConnectionSummary on Connection {
    id
    name
    connectionType
    numResources
    numGroups
  }
`;
export const GroupTypeWithCountFragmentDoc = gql`
  fragment GroupTypeWithCount on GroupTypeWithCount {
    connectionId
    connection {
      name
    }
    groupType
    numGroups
  }
`;
export const ResourceTypeWithCountFragmentDoc = gql`
  fragment ResourceTypeWithCount on ResourceTypeWithCount {
    connectionId
    connection {
      name
    }
    resourceType
    numResources
  }
`;
export const EdgeMetadataFragmentDoc = gql`
  fragment EdgeMetadata on EdgeMetadata {
    accessType {
      type
      expiration
    }
    lastUsageTime
  }
`;
export const AccessLevelNodeFragmentDoc = gql`
  fragment AccessLevelNode on AccessLevelNode {
    resourceId
    accessLevel {
      ...ResourceAccessLevel
    }
  }
  ${ResourceAccessLevelFragmentDoc}
`;
export const VisualizationDataResultFragmentDoc = gql`
  fragment VisualizationDataResult on VisualizationResult {
    userResourceEdges {
      userId
      resourceId
      accessLevel {
        ...ResourceAccessLevel
      }
      metadata {
        ...EdgeMetadata
      }
    }
    groupResourceEdges {
      groupId
      resourceId
      accessLevel {
        ...ResourceAccessLevel
      }
      metadata {
        ...EdgeMetadata
      }
    }
    userGroupEdges {
      userId
      groupId
      metadata {
        ...EdgeMetadata
      }
    }
    users {
      ...UserPreviewSmall
    }
    groups {
      ...GroupPreviewSmall
    }
    resources {
      ...ResourcePreviewLarge
    }
    accessLevels {
      ...AccessLevelNode
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${EdgeMetadataFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${ResourcePreviewLargeFragmentDoc}
  ${AccessLevelNodeFragmentDoc}
`;
export const AppPreviewFragmentDoc = gql`
  fragment AppPreview on App {
    id
    name
    app {
      ... on OktaResourceApp {
        resourceId
        iconUrl
      }
      ... on ConnectionApp {
        connectionId
        connectionType
      }
    }
  }
`;
export const RequestMessageFragmentDoc = gql`
  fragment RequestMessage on RequestMessage {
    entityId
    level
    code
  }
`;
export const RequestDefaultsFragmentDoc = gql`
  fragment RequestDefaults on RequestDefaults {
    durationOptions {
      durationInMinutes
      label
    }
    recommendedDurationInMinutes
    defaultDurationInMinutes
    maxDurationInMinutes
    requireSupportTicket
    reasonOptional
    messages {
      ...RequestMessage
    }
  }
  ${RequestMessageFragmentDoc}
`;
export const AccessReviewUserAssignmentFragmentDoc = gql`
  fragment AccessReviewUserAssignment on AccessReviewUserAssignment {
    id
    userId
    userFullname
    userAvatarUrl
    resourceId
    groupId
    connectionId
    resourceType
    groupType
    connectionType
    entityName
    parentResourceName
    accessReviewObjectId
    accessLevelRemoteId
    accessLevelName
    status
    outcome
    reviewerUsers {
      id
      fullName
      avatarUrl
    }
  }
`;
export const AccessReviewGroupResourceAssignmentFragmentDoc = gql`
  fragment AccessReviewGroupResourceAssignment on AccessReviewGroupResourceAssignment {
    id
    resourceId
    resourceType
    resourceName
    groupId
    groupType
    groupName
    accessReviewGroupId
    accessLevelRemoteId
    accessLevelName
    status
    outcome
    reviewerUsers {
      id
      fullName
      avatarUrl
    }
  }
`;
export const AccessReviewResourcePrincipalAssignmentFragmentDoc = gql`
  fragment AccessReviewResourcePrincipalAssignment on AccessReviewResourcePrincipalAssignment {
    id
    resourcePrincipalId
    resourcePrincipalName
    resourcePrincipalType
    entityId
    entityType
    entityName
    entityItemType
    accessLevelRemoteId
    accessLevelName
    status
    outcome
    reviewerUsers {
      id
      fullName
      avatarUrl
    }
  }
`;
export const AccessReviewResourceSmallFragmentDoc = gql`
  fragment AccessReviewResourceSmall on AccessReviewResource {
    id
    resource {
      ...ResourcePreviewTiny
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewResourceReviewerStatus
    }
  }
  ${ResourcePreviewTinyFragmentDoc}
  ${AccessReviewResourceReviewerStatusFragmentDoc}
`;
export const AccessReviewGroupSmallFragmentDoc = gql`
  fragment AccessReviewGroupSmall on AccessReviewGroup {
    id
    group {
      ...GroupPreviewTiny
      groupBinding {
        ...GroupBindingTiny
      }
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewGroupReviewerStatus
    }
  }
  ${GroupPreviewTinyFragmentDoc}
  ${GroupBindingTinyFragmentDoc}
  ${AccessReviewGroupReviewerStatusFragmentDoc}
`;
export const AccessReviewConnectionSmallFragmentDoc = gql`
  fragment AccessReviewConnectionSmall on AccessReviewConnection {
    id
    connection {
      ...ConnectionPreviewTiny
    }
    status
    currentUserReviewerStatus {
      ...AccessReviewConnectionReviewerStatus
    }
  }
  ${ConnectionPreviewTinyFragmentDoc}
  ${AccessReviewConnectionReviewerStatusFragmentDoc}
`;
export const AccessReviewItemFragmentDoc = gql`
  fragment AccessReviewItem on AccessReviewItem {
    key
    resource {
      ...AccessReviewResourceSmall
    }
    group {
      ...AccessReviewGroupSmall
    }
    connection {
      ...AccessReviewConnectionSmall
    }
  }
  ${AccessReviewResourceSmallFragmentDoc}
  ${AccessReviewGroupSmallFragmentDoc}
  ${AccessReviewConnectionSmallFragmentDoc}
`;
export const AccessReviewChangeItemSmallFragmentDoc = gql`
  fragment AccessReviewChangeItemSmall on AccessReviewChangeItem {
    item {
      ...AccessReviewItem
    }
  }
  ${AccessReviewItemFragmentDoc}
`;
export const EventWithSubEventInfoFragmentDoc = gql`
  fragment EventWithSubEventInfo on Event {
    id
    createdAt
    actorUserId
    actorUser {
      fullName
      avatarUrl
    }
    actorIP
    actorUserAgent
    eventType
    source
    numSubEvents
    numErrorSubEvents
    subEvents {
      id
      subEventType
      objectId {
        ...EntityIdTuple
      }
      secondaryObjectId {
        ...EntityIdTuple
      }
      tertiaryObjectId {
        ...EntityIdTuple
      }
      object4Id {
        ...EntityIdTuple
      }
    }
  }
  ${EntityIdTupleFragmentDoc}
`;
export const AccessReviewGroupResourceForDetailFragmentDoc = gql`
  fragment AccessReviewGroupResourceForDetail on AccessReviewGroupResource {
    id
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    reviewerUsers {
      ...ReviewerUser
    }
    statusAndOutcome {
      ...AccessReviewItemStatusAndOutcome
    }
    resource {
      ...ResourcePreviewSmall
    }
    groupResourceAccess {
      ...GroupResourceAccess
    }
  }
  ${ReviewerUserFragmentDoc}
  ${AccessReviewItemStatusAndOutcomeFragmentDoc}
  ${ResourcePreviewSmallFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
`;
export const GroupResourceForContextFragmentDoc = gql`
  fragment GroupResourceForContext on GroupResource {
    groupId
    resourceId
    accessLevel {
      accessLevelName
      accessLevelRemoteId
    }
    resource {
      ...ResourcePreviewSmall
    }
    access {
      ...GroupResourceAccess
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${GroupResourceAccessFragmentDoc}
`;
export const AccessReviewGroupForDetailFragmentDoc = gql`
  fragment AccessReviewGroupForDetail on AccessReviewGroup {
    id
    createdAt
    group {
      ...GroupPreviewLarge
    }
    accessReviewId
    accessReview {
      stoppedDate
      selfReviewAllowed
      groupResourceVisibilityPolicy
    }
    reviewerUsers {
      ...ReviewerUser
    }
    groupUsers {
      ...AccessReviewGroupUserPreview
    }
    numGroupUsers
    groupResources {
      ...AccessReviewGroupResourceForDetail
    }
    numGroupResources
    currentUserReviewerStatus {
      ...AccessReviewGroupReviewerStatus
    }
    allGroupResources {
      ...GroupResourceForContext
    }
  }
  ${GroupPreviewLargeFragmentDoc}
  ${ReviewerUserFragmentDoc}
  ${AccessReviewGroupUserPreviewFragmentDoc}
  ${AccessReviewGroupResourceForDetailFragmentDoc}
  ${AccessReviewGroupReviewerStatusFragmentDoc}
  ${GroupResourceForContextFragmentDoc}
`;
export const AccessReviewMyResourceV3FragmentDoc = gql`
  fragment AccessReviewMyResourceV3 on AccessReviewResource {
    ...AccessReviewResourceSmall
    resource {
      description
    }
  }
  ${AccessReviewResourceSmallFragmentDoc}
`;
export const AccessReviewMyGroupV3FragmentDoc = gql`
  fragment AccessReviewMyGroupV3 on AccessReviewGroup {
    ...AccessReviewGroupSmall
    group {
      description
    }
  }
  ${AccessReviewGroupSmallFragmentDoc}
`;
export const AccessReviewMyConnectionV3FragmentDoc = gql`
  fragment AccessReviewMyConnectionV3 on AccessReviewConnection {
    ...AccessReviewConnectionSmall
    connection {
      description
    }
  }
  ${AccessReviewConnectionSmallFragmentDoc}
`;
export const AccessReviewReviewerFragmentDoc = gql`
  fragment AccessReviewReviewer on AccessReviewReviewer {
    userId
    user {
      id
      fullName
      email
      avatarUrl
    }
    totalItemsCount
    completedItemsCount
    totalUsersCount
    completedUsersCount
    totalGroupResourcesCount
    completedGroupResourcesCount
    totalNHIsCount
    completedNHIsCount
  }
`;
export const AccessReviewReviewerItemFragmentDoc = gql`
  fragment AccessReviewReviewerItem on AccessReviewReviewerItem {
    reviewStatus
    item {
      ...AccessReviewItem
    }
  }
  ${AccessReviewItemFragmentDoc}
`;
export const AccessReviewReviewerDetailFragmentDoc = gql`
  fragment AccessReviewReviewerDetail on AccessReviewReviewer {
    userId
    user {
      id
      fullName
      email
      avatarUrl
    }
    totalItemsCount
    completedItemsCount
    items {
      ...AccessReviewReviewerItem
    }
  }
  ${AccessReviewReviewerItemFragmentDoc}
`;
export const AccessReviewResourceProposedChangeFragmentDoc = gql`
  fragment AccessReviewResourceProposedChange on AccessReviewResourceProposedChange {
    user {
      id
      fullName
      avatarUrl
    }
    currentReviewer {
      name
      entityId
      userId
      avatarUrl
    }
    proposedReviewer {
      name
      entityId
      userId
      avatarUrl
    }
    resource {
      id
      name
    }
  }
`;
export const AccessReviewGroupProposedChangeFragmentDoc = gql`
  fragment AccessReviewGroupProposedChange on AccessReviewGroupProposedChange {
    user {
      id
      fullName
      avatarUrl
    }
    currentReviewer {
      name
      entityId
      userId
      avatarUrl
    }
    proposedReviewer {
      name
      entityId
      userId
      avatarUrl
    }
    group {
      id
      name
    }
  }
`;
export const AppResourceRowFragmentDoc = gql`
  fragment AppResourceRow on Resource {
    __typename
    id
    name
    connection {
      connectionType
    }
    isRequestable
    hasVisibleChildren
    authorizedActions
    resourceType
    isManaged
    configTemplate {
      id
    }
    parentResource {
      id
      name
      configTemplate {
        id
        name
      }
    }
    ancestorPathToResource
    adminOwner {
      id
      name
    }
    visibility
    visibilityGroups {
      visibilityGroupId
    }
    description
    createdAt
    numResourceUsers
  }
`;
export const AppGroupRowFragmentDoc = gql`
  fragment AppGroupRow on Group {
    __typename
    id
    name
    connection {
      connectionType
    }
    isRequestable
    authorizedActions
    groupType
    isManaged
    configTemplate {
      id
    }
    adminOwner {
      id
      name
    }
    visibility
    visibilityGroups {
      visibilityGroupId
    }
    description
    createdAt
    numGroupUsers
  }
`;
export const AppDataFragmentDoc = gql`
  fragment AppData on AppData {
    ... on ConnectionApp {
      connectionId
      connectionType
      connectionIconUrl
      connection @include(if: $fetchAccessStats) {
        accessStats {
          teamAccessCount
          titleAccessCount
        }
      }
    }
    ... on OktaResourceApp {
      resourceId
      iconUrl
      isVisible
      resource @include(if: $fetchAccessStats) {
        accessStats {
          teamAccessCount
          titleAccessCount
        }
      }
    }
  }
`;
export const GroupPreviewWithGroupsFragmentDoc = gql`
  fragment GroupPreviewWithGroups on Group {
    id
    name
    groupType
    authorizedActions
    memberGroups {
      ...GroupGroup
    }
    containingGroups {
      ...GroupGroup
    }
    accessLevels {
      ...GroupAccessLevel
    }
  }
  ${GroupGroupFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
`;
export const GroupPreviewWithResourcesFragmentDoc = gql`
  fragment GroupPreviewWithResources on Group {
    id
    name
    groupType
    authorizedActions
    groupResources {
      ...GroupResource
    }
  }
  ${GroupResourceFragmentDoc}
`;
export const GroupPreviewWithUsersFragmentDoc = gql`
  fragment GroupPreviewWithUsers on Group {
    id
    name
    remoteId
    groupType
    serviceType
    connection {
      connectionType
    }
    accessLevels {
      ...GroupAccessLevel
    }
    groupUsers {
      ...GroupUserPreview
    }
    authorizedActions
  }
  ${GroupAccessLevelFragmentDoc}
  ${GroupUserPreviewFragmentDoc}
`;
export const RemoteAppItemFragmentDoc = gql`
  fragment RemoteAppItem on RemoteAppItem {
    id
    remoteId
    remoteName
    metadata
    resourceType
    groupType
    connectionId
    connectionType
    iconUrl
  }
`;
export const ResourceForSelectFragmentDoc = gql`
  fragment ResourceForSelect on Resource {
    __typename
    id
    name
    connection {
      connectionType
    }
    isRequestable
    authorizedActions
    resourceType
    isManaged
    configTemplate {
      id
    }
    parentResource {
      id
      name
      configTemplate {
        id
        name
      }
    }
  }
`;
export const GroupForSelectFragmentDoc = gql`
  fragment GroupForSelect on Group {
    __typename
    id
    name
    connection {
      connectionType
    }
    isRequestable
    authorizedActions
    groupType
    isManaged
    configTemplate {
      id
    }
    groupBinding {
      sourceGroupId
    }
  }
`;
export const ResourceForImportFragmentDoc = gql`
  fragment ResourceForImport on Resource {
    __typename
    id
    name
    connection {
      connectionType
    }
    authorizedActions
    resourceType
    remoteId
    isManaged
  }
`;
export const GroupForImportFragmentDoc = gql`
  fragment GroupForImport on Group {
    __typename
    id
    name
    connection {
      connectionType
    }
    authorizedActions
    groupType
    remoteId
    isManaged
  }
`;
export const AppItemFragmentDoc = gql`
  fragment AppItem on AppItem {
    key
    resource {
      ...ResourceForImport
      currentUserAccess {
        resourceId
        resourceUsers {
          accessLevel {
            accessLevelRemoteId
          }
          resourceId
          userId
        }
      }
      parentResource {
        id
        name
      }
    }
    group {
      ...GroupForImport
      currentUserAccess {
        groupId
        groupUser {
          userId
          groupId
        }
      }
    }
  }
  ${ResourceForImportFragmentDoc}
  ${GroupForImportFragmentDoc}
`;
export const ResourcePreviewWithGroupsFragmentDoc = gql`
  fragment ResourcePreviewWithGroups on Resource {
    id
    name
    resourceType
    serviceType
    authorizedActions
    accessLevels {
      ...ResourceAccessLevel
    }
    containingGroups {
      ...GroupResource
    }
  }
  ${ResourceAccessLevelFragmentDoc}
  ${GroupResourceFragmentDoc}
`;
export const RoleAssignmentPreviewFragmentDoc = gql`
  fragment RoleAssignmentPreview on RoleAssignment {
    entityID
    entityType
    principalID
    principalType
    accessLevel {
      ...AccessLevel
    }
    access {
      ...Access
    }
    propagationStatus {
      ...PropagationStatus
    }
    provisionSource
  }
  ${AccessLevelFragmentDoc}
  ${AccessFragmentDoc}
  ${PropagationStatusFragmentDoc}
`;
export const ResourcePreviewWithRoleAssignmentsFragmentDoc = gql`
  fragment ResourcePreviewWithRoleAssignments on Resource {
    id
    name
    remoteId
    resourceType
    serviceType
    connection {
      connectionType
    }
    accessLevels {
      ...ResourceAccessLevel
    }
    principalAssignmentsForEntity {
      ...RoleAssignmentPreview
    }
    authorizedActions
  }
  ${ResourceAccessLevelFragmentDoc}
  ${RoleAssignmentPreviewFragmentDoc}
`;
export const ResourcePreviewWithUsersFragmentDoc = gql`
  fragment ResourcePreviewWithUsers on Resource {
    id
    name
    remoteId
    resourceType
    serviceType
    connection {
      connectionType
    }
    accessLevels {
      ...ResourceAccessLevel
    }
    resourceUsers {
      ...ResourceUserPreview
    }
    metadata {
      ...ResourceMetadata
    }
    authorizedActions
  }
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserPreviewFragmentDoc}
  ${ResourceMetadataFragmentDoc}
`;
export const ResourceTicketPropagationFragmentDoc = gql`
  fragment ResourceTicketPropagation on TicketPropagation {
    onGrant
    onRevocation
    ticketProvider {
      ticketProvider
      ticketProjectId
      ticketProjectName
    }
  }
`;
export const ResourceDetailFragmentDoc = gql`
  fragment ResourceDetail on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    useParentConfig
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    resourceUsers {
      ...ResourceUser
    }
    principalAssignmentsForEntity {
      ...RoleAssignment
    }
    entityAssignmentsForPrincipal {
      ...RoleAssignment
    }
    containingGroups {
      ...ResourceGroup
      group {
        authorizedActions
      }
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    auditMessageChannels {
      ...MessageChannel
    }
    resourceType
    remoteId
    iconUrl
    requireMfaToConnect
    requireMfaToApprove
    tags {
      ...ResourceTagPreview
    }
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    authorizedActions
    parentResourceId
    parentResource {
      id
      name
      resourceType
      visibility
      visibilityGroups {
        ...ResourceVisibilityGroup
      }
      configTemplate {
        id
        name
      }
    }
    childResources {
      ...ChildResourcePreview
    }
    numDescendantResources
    requestConfigs {
      ...RequestConfiguration
    }
    configTemplate {
      id
      name
    }
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    reviewerStages {
      ...ReviewerStage
    }
    requestTemplateId
    requestTemplate {
      ...RequestTemplate
    }
    childrenDefaultConfigTemplate {
      id
      name
    }
    ticketPropagation {
      ...ResourceTicketPropagation
    }
    riskSensitivity {
      value
      isDefault
    }
    customRequestNotification
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${ResourceUserFragmentDoc}
  ${RoleAssignmentFragmentDoc}
  ${ResourceGroupFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
  ${ChildResourcePreviewFragmentDoc}
  ${RequestConfigurationFragmentDoc}
  ${ReviewerStageFragmentDoc}
  ${RequestTemplateFragmentDoc}
  ${ResourceTicketPropagationFragmentDoc}
`;
export const ResourceDetailViewFragmentDoc = gql`
  fragment ResourceDetailView on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    useParentConfig
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    currentUserAccess {
      ...CurrentUserResourceAccess
    }
    paginatedResourceUsers(input: { maxNumEntries: 1 }) {
      totalNumUsers
    }
    principalAssignmentsForEntity {
      ...RoleAssignment
    }
    entityAssignmentsForPrincipal {
      ...RoleAssignment
    }
    containingGroups {
      ...ResourceGroup
      group {
        authorizedActions
      }
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    auditMessageChannels {
      ...MessageChannel
    }
    resourceType
    remoteId
    iconUrl
    requireMfaToConnect
    requireMfaToApprove
    tags {
      ...ResourceTagPreview
    }
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    authorizedActions
    parentResourceId
    parentResource {
      id
      name
      resourceType
      visibility
      visibilityGroups {
        ...ResourceVisibilityGroup
      }
      configTemplate {
        id
        name
      }
    }
    childResources {
      ...ChildResourcePreview
    }
    numDescendantResources
    requestConfigs {
      ...RequestConfiguration
    }
    configTemplate {
      id
      name
    }
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    reviewerStages {
      ...ReviewerStage
    }
    requestTemplateId
    requestTemplate {
      ...RequestTemplate
    }
    childrenDefaultConfigTemplate {
      id
      name
    }
    ticketPropagation {
      ...ResourceTicketPropagation
    }
    riskSensitivity {
      value
      isDefault
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${CurrentUserResourceAccessFragmentDoc}
  ${RoleAssignmentFragmentDoc}
  ${ResourceGroupFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
  ${ChildResourcePreviewFragmentDoc}
  ${RequestConfigurationFragmentDoc}
  ${ReviewerStageFragmentDoc}
  ${RequestTemplateFragmentDoc}
  ${ResourceTicketPropagationFragmentDoc}
`;
export const DetailResourceUserFragmentDoc = gql`
  fragment DetailResourceUser on ResourceUser {
    __typename
    resourceId
    userId
    user {
      ...UserPreviewSmall
    }
    accessLevel {
      ...ResourceAccessLevel
    }
    access {
      ...ResourceUserAccess
    }
    propagationStatus {
      ...ResourcePropagationStatus
    }
    mostRecentSessionEnded
    source
  }
  ${UserPreviewSmallFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
  ${ResourceUserAccessFragmentDoc}
  ${ResourcePropagationStatusFragmentDoc}
`;
export const PaginatedResourceUsersFragmentDoc = gql`
  fragment PaginatedResourceUsers on PaginatedResourceUsersOutput {
    resourceUsers {
      ...DetailResourceUser
    }
    cursor
    totalNumResourceUsers
    totalNumUsers
    uniqueAccessLevels {
      ...ResourceAccessLevel
    }
  }
  ${DetailResourceUserFragmentDoc}
  ${ResourceAccessLevelFragmentDoc}
`;
export const ResourceEditViewDetailFragmentDoc = gql`
  fragment ResourceEditViewDetail on Resource {
    __typename
    id
    name
    remoteName
    serviceType
    visibility
    isDeleted
    createdAt
    description
    useParentConfig
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
        }
      }
    }
    connectionId
    connection {
      ...ConnectionPreviewLarge
    }
    metadata {
      ...ResourceMetadata
    }
    commonMetadata {
      matchRemoteName
      matchRemoteDescription
    }
    auditMessageChannels {
      ...MessageChannel
    }
    resourceType
    remoteId
    iconUrl
    requireMfaToConnect
    requireMfaToApprove
    tags {
      ...ResourceTagPreview
    }
    visibilityGroups {
      ...ResourceVisibilityGroup
    }
    authorizedActions
    parentResourceId
    parentResource {
      id
      name
      resourceType
      visibility
      visibilityGroups {
        ...ResourceVisibilityGroup
      }
      configTemplate {
        id
        name
      }
    }
    numDescendantResources
    requestConfigs {
      ...RequestConfiguration
    }
    configTemplate {
      id
      name
    }
    maxDurationInMinutes
    recommendedDurationInMinutes
    requireMfaToRequest
    requireSupportTicket
    isRequestable
    isManaged
    autoApproval
    reasonOptional
    reviewerStages {
      ...ReviewerStage
    }
    requestTemplateId
    requestTemplate {
      ...RequestTemplate
    }
    childrenDefaultConfigTemplate {
      id
      name
    }
    ticketPropagation {
      ...ResourceTicketPropagation
    }
    riskSensitivity {
      value
      isDefault
    }
    customRequestNotification
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${ResourceMetadataFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${ResourceTagPreviewFragmentDoc}
  ${ResourceVisibilityGroupFragmentDoc}
  ${RequestConfigurationFragmentDoc}
  ${ReviewerStageFragmentDoc}
  ${RequestTemplateFragmentDoc}
  ${ResourceTicketPropagationFragmentDoc}
`;
export const EndUserOktaResourceAppFragmentDoc = gql`
  fragment EndUserOktaResourceApp on Resource {
    __typename
    id
    name
    description
    resourceType
    serviceType
    iconUrl
    remoteId
    isRequestable
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
          fullName
          avatarUrl
        }
      }
    }
    accessStats {
      teamAccessCount
      titleAccessCount
    }
  }
`;
export const EndUserConnectionAppFragmentDoc = gql`
  fragment EndUserConnectionApp on Connection {
    id
    name
    description
    connectionType
    iconUrl
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
          fullName
          avatarUrl
        }
      }
    }
    accessStats {
      teamAccessCount
      titleAccessCount
    }
  }
`;
export const AppEndUserResourceRowFragmentDoc = gql`
  fragment AppEndUserResourceRow on Resource {
    __typename
    id
    name
    remoteId
    connectionId
    description
    resourceType
    parentResourceId
    hasVisibleChildren
    isRequestable
    ancestorPathToResource
    connection {
      connectionType
    }
    currentUserAccess {
      ...CurrentUserResourceAccessTiny
    }
  }
  ${CurrentUserResourceAccessTinyFragmentDoc}
`;
export const AppEndUserGroupRowFragmentDoc = gql`
  fragment AppEndUserGroupRow on Group {
    __typename
    id
    name
    description
    groupType
    isRequestable
    connectionId
    connection {
      connectionType
    }
    currentUserAccess {
      ...CurrentUserGroupAccessTiny
    }
  }
  ${CurrentUserGroupAccessTinyFragmentDoc}
`;
export const ResourceSuggestionFragmentDoc = gql`
  fragment ResourceSuggestion on ResourceSuggestion {
    resourceId
    name
    resourceType
    parentResourceName
  }
`;
export const GroupSuggestionFragmentDoc = gql`
  fragment GroupSuggestion on GroupSuggestion {
    groupId
    name
    groupType
  }
`;
export const SuggestionFragmentDoc = gql`
  fragment Suggestion on Suggestion {
    __typename
    ... on ResourceSuggestion {
      ...ResourceSuggestion
    }
    ... on GroupSuggestion {
      ...GroupSuggestion
    }
  }
  ${ResourceSuggestionFragmentDoc}
  ${GroupSuggestionFragmentDoc}
`;
export const BundleFragmentDoc = gql`
  fragment Bundle on Bundle {
    id
    name
    description
    adminOwnerId
    visibility
    authorizedActions
    totalNumItems
    totalNumResources
    totalNumGroups
    serviceTypes
  }
`;
export const BundleDetailFragmentDoc = gql`
  fragment BundleDetail on Bundle {
    id
    name
    description
    adminOwnerId
    adminOwner {
      id
      name
      ownerUsers {
        user {
          id
          fullName
          avatarUrl
        }
      }
    }
    visibility
    visibilityGroups {
      id
      visibilityGroupId
    }
    authorizedActions
    totalNumItems
    totalNumResources
    totalNumGroups
  }
`;
export const BundleDetailForCatalogFragmentDoc = gql`
  fragment BundleDetailForCatalog on Bundle {
    id
    name
    description
    authorizedActions
    totalNumResources
    totalNumGroups
    serviceTypes
  }
`;
export const BundleResourceFragmentDoc = gql`
  fragment BundleResource on BundleResource {
    bundleId
    resourceId
    accessLevelName
    accessLevelRemoteId
  }
`;
export const BundleGroupFragmentDoc = gql`
  fragment BundleGroup on BundleGroup {
    bundleId
    groupId
    accessLevelName
    accessLevelRemoteId
  }
`;
export const BundleItemFragmentDoc = gql`
  fragment BundleItem on BundleItem {
    key
    resource {
      ...ResourcePreviewLarge
      ancestorPathToResource
    }
    group {
      ...GroupPreviewLarge
    }
    accessLevelName
    accessLevelRemoteId
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${GroupPreviewLargeFragmentDoc}
`;
export const ConnectResourceSessionFragmentDoc = gql`
  fragment ConnectResourceSession on Resource {
    id
    name
    remoteId
    ancestorPathToResource
    resourceType
    serviceType
    requireMfaToConnect
    connection {
      id
      name
      connectionType
      metadata {
        ... on AWSSSOConnectionMetadata {
          __typename
          accessPortalUrl
        }
      }
    }
    currentUserAccess {
      ...CurrentUserResourceAccessTiny
    }
    metadata {
      ...ResourceMetadata
    }
  }
  ${CurrentUserResourceAccessTinyFragmentDoc}
  ${ResourceMetadataFragmentDoc}
`;
export const EventForTableFragmentDoc = gql`
  fragment EventForTable on Event {
    id
    createdAt
    actorUserId
    actorUser {
      fullName
      avatarUrl
    }
    actorIP
    actorUserAgent
    eventType
    source
    numSubEvents
    numErrorSubEvents
  }
`;
export const EventDetailFragmentDoc = gql`
  fragment EventDetail on Event {
    id
    createdAt
    actorUserId
    actorUser {
      fullName
      avatarUrl
    }
    actorIP
    actorUserAgent
    eventType
    source
    numSubEvents
    paginatedSubEvents(input: $subEvents) {
      subEvents {
        ...SubEvent
      }
      cursor
      totalNumSubEvents
    }
  }
  ${SubEventFragmentDoc}
`;
export const GroupBindingDetailGroupUserFragmentDoc = gql`
  fragment GroupBindingDetailGroupUser on GroupUser {
    groupId
    userId
    user {
      id
      email
      fullName
      avatarUrl
    }
  }
`;
export const GroupBindingGroupWithUsersFragmentDoc = gql`
  fragment GroupBindingGroupWithUsers on Group {
    ...GroupBindingGroupPreview
    groupUsers {
      ...GroupBindingDetailGroupUser
    }
  }
  ${GroupBindingGroupPreviewFragmentDoc}
  ${GroupBindingDetailGroupUserFragmentDoc}
`;
export const GroupBindingDetailFragmentDoc = gql`
  fragment GroupBindingDetail on GroupBinding {
    id
    sourceGroupId
    sourceGroup {
      ...GroupBindingGroupWithUsers
    }
    groups {
      ...GroupBindingGroupWithUsers
    }
  }
  ${GroupBindingGroupWithUsersFragmentDoc}
`;
export const GroupBindingSuggestionFragmentDoc = gql`
  fragment GroupBindingSuggestion on GroupBindingSuggestion {
    id
    primaryGroupId
    primaryGroup {
      ...GroupBindingGroupPreview
    }
    secondaryGroupId
    secondaryGroup {
      ...GroupBindingGroupPreview
    }
  }
  ${GroupBindingGroupPreviewFragmentDoc}
`;
export const GroupBindingSuggestionDetailFragmentDoc = gql`
  fragment GroupBindingSuggestionDetail on GroupBindingSuggestion {
    primaryGroup {
      ...GroupBindingGroupWithUsers
    }
    secondaryGroup {
      ...GroupBindingGroupWithUsers
    }
  }
  ${GroupBindingGroupWithUsersFragmentDoc}
`;
export const LineGraphDataFragmentDoc = gql`
  fragment LineGraphData on LineGraphData {
    timestamp
    value
  }
`;
export const BarGraphDataFragmentDoc = gql`
  fragment BarGraphData on BarGraphData {
    label
    data {
      ...LineGraphData
    }
  }
  ${LineGraphDataFragmentDoc}
`;
export const AppThreatPreviewFragmentDoc = gql`
  fragment AppThreatPreview on App {
    app {
      ... on OktaResourceApp {
        resourceId
        iconUrl: iconUrl
      }
      ... on ConnectionApp {
        connectionId
        connectionType
      }
    }
    id
    name
    threats {
      entityKey {
        entityId
        entityType
      }
      riskScore
      suggestionType
    }
  }
`;
export const ResourceForRemediationFragmentDoc = gql`
  fragment ResourceForRemediation on Resource {
    resourceId: id
    name
    resourceType
    connection {
      connectionType
      id
      name
    }
    resourceUsers {
      ...ResourceUser
      provisionSource
    }
    riskScore
    iconUrl
  }
  ${ResourceUserFragmentDoc}
`;
export const GroupForRemediationFragmentDoc = gql`
  fragment GroupForRemediation on Group {
    groupId: id
    name
    groupType
    connection {
      connectionType
      name
      id
    }
    groupUsers {
      ...GroupUser
      lastUsedAt
      provisionSource
    }
    groupResources {
      ...GroupResource
      lastUsedAt
    }
    riskScore
  }
  ${GroupUserFragmentDoc}
  ${GroupResourceFragmentDoc}
`;
export const RiskyResourcePreviewTinyFragmentDoc = gql`
  fragment RiskyResourcePreviewTiny on Resource {
    resourceId: id
    name
    resourceType
  }
`;
export const RiskyGroupPreviewTinyFragmentDoc = gql`
  fragment RiskyGroupPreviewTiny on Group {
    groupId: id
    name
    groupType
  }
`;
export const ResourceBulkSuggestionPreviewFragmentDoc = gql`
  fragment ResourceBulkSuggestionPreview on Resource {
    resourceId: id
    name
    resourceType
    connection {
      id
      name
      connectionType
    }
    iconUrl
  }
`;
export const GroupBulkSuggestionPreviewFragmentDoc = gql`
  fragment GroupBulkSuggestionPreview on Group {
    groupId: id
    name
    groupType
    connection {
      id
      name
      connectionType
    }
    iconUrl
  }
`;
export const RequestTableFragmentDoc = gql`
  fragment RequestTable on Request {
    id
    requesterId
    requester {
      ...UserPreviewSmall
    }
    targetUserId
    targetUser {
      ...UserPreviewSmall
    }
    targetGroupId
    targetGroup {
      ...GroupPreviewSmall
    }
    reason
    requestedResources {
      resource {
        id
        name
        resourceType
        connection {
          id
          name
          connectionType
        }
      }
      accessLevel {
        accessLevelName
        accessLevelRemoteId
      }
    }
    requestedGroups {
      group {
        id
        name
        groupType
        connection {
          id
          name
          connectionType
        }
      }
      accessLevel {
        accessLevelName
        accessLevelRemoteId
      }
    }
    resourceProposalId
    resourceProposal {
      ...ResourceProposal
    }
    durationInMinutes
    createdAt
    status
  }
  ${UserPreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${ResourceProposalFragmentDoc}
`;
export const ConnectionForResourceImportFragmentDoc = gql`
  fragment ConnectionForResourceImport on Connection {
    ...ConnectionPreviewSmall
    adminOwnerId
    importVisibility
    resources {
      ...ResourcePreviewSmall
    }
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${ResourcePreviewSmallFragmentDoc}
`;
export const GroupOnCallSchedulePreviewSmallFragmentDoc = gql`
  fragment GroupOnCallSchedulePreviewSmall on GroupOnCallSchedule {
    group {
      id
      name
      groupType
    }
    onCallSchedule {
      ...OnCallSchedule
    }
  }
  ${OnCallScheduleFragmentDoc}
`;
export const EventStreamFragmentDoc = gql`
  fragment EventStream on EventStream {
    id
    connection {
      name
      connectionType
      enabled
      metadata {
        __typename
        ... on WebhookPubSubPublishConnectionMetadata {
          url
        }
      }
      credentials {
        __typename
        ... on WebhookPubsubPublishConnectionCredentialsOutput {
          authType
          apiKey {
            credentials {
              name
              valuePreview
              location
            }
          }
          hmac {
            credential1 {
              secret
              secretPreview
              createdAt
            }
            credential2 {
              secret
              secretPreview
              createdAt
            }
          }
        }
      }
      failedDeliveryCount @include(if: $fetchFailedDeliveryCounts)
    }
  }
`;
export const SetupEntityCountsFragmentDoc = gql`
  fragment SetupEntityCounts on SetupEntityCounts {
    numUsers
    numGroups
    resourceCounts {
      resourceType
      count
    }
  }
`;
export const OwnerPreviewSmallFragmentDoc = gql`
  fragment OwnerPreviewSmall on Owner {
    id
    name
    sourceGroup {
      id
      name
      groupType
    }
  }
`;
export const UserDropdownPreviewFragmentDoc = gql`
  fragment UserDropdownPreview on User {
    id
    email
    firstName
    lastName
    fullName
    avatarUrl
    isSystemUser
  }
`;
export const ConfigurationTemplateFragmentDoc = gql`
  fragment ConfigurationTemplate on ConfigurationTemplate {
    id
    name
    adminOwner {
      ...OwnerPreviewSmall
    }
    requireMfaToApprove
    requireMfaToConnect
    visibility
    visibilityGroups {
      id
    }
    auditMessageChannels {
      ...MessageChannel
    }
    breakGlassUsers {
      ...UserDropdownPreview
    }
    onCallSchedules {
      ...OnCallSchedule
    }
    requestConfigs {
      ...RequestConfiguration
    }
    ticketPropagation {
      ...ResourceTicketPropagation
    }
    customRequestNotification
  }
  ${OwnerPreviewSmallFragmentDoc}
  ${MessageChannelFragmentDoc}
  ${UserDropdownPreviewFragmentDoc}
  ${OnCallScheduleFragmentDoc}
  ${RequestConfigurationFragmentDoc}
  ${ResourceTicketPropagationFragmentDoc}
`;
export const UserIdentityFragmentDoc = gql`
  fragment UserIdentity on UserIdentity {
    shouldShow
    value
  }
`;
export const UserIdentitiesFragmentDoc = gql`
  fragment UserIdentities on UserIdentities {
    gitHubUsername {
      ...UserIdentity
    }
    gitLabUserId {
      ...UserIdentity
    }
    teleportUsername {
      ...UserIdentity
    }
  }
  ${UserIdentityFragmentDoc}
`;
export const UserOverviewFragmentDoc = gql`
  fragment UserOverview on User {
    id
    email
    secondaryEmails
    firstName
    lastName
    fullName
    avatarUrl
    isDeleted
    isSystemUser
    createdAt
    updatedAt
    organizationId
    position
    teamAttr
    managerId
    manager {
      ...UserPreviewSmall
    }
    hrIdpStatus
    identities {
      ...UserIdentities
    }
    directReports {
      ...UserPreviewLarge
    }
    numResources
    numGroups
  }
  ${UserPreviewSmallFragmentDoc}
  ${UserIdentitiesFragmentDoc}
  ${UserPreviewLargeFragmentDoc}
`;
export const UpdateConnectionReviewersDocument = gql`
  mutation UpdateConnectionReviewers($input: UpdateConnectionReviewersInput!) {
    updateConnectionReviewers(input: $input) {
      __typename
      ... on UpdateConnectionReviewersResult {
        accessReviewConnection {
          ...AccessReviewConnection
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewConnectionFragmentDoc}
`;
export type UpdateConnectionReviewersMutationFn = Apollo.MutationFunction<
  UpdateConnectionReviewersMutation,
  UpdateConnectionReviewersMutationVariables
>;

/**
 * __useUpdateConnectionReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionReviewersMutation, { data, loading, error }] = useUpdateConnectionReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectionReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConnectionReviewersMutation,
    UpdateConnectionReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConnectionReviewersMutation,
    UpdateConnectionReviewersMutationVariables
  >(UpdateConnectionReviewersDocument, options);
}
export type UpdateConnectionReviewersMutationHookResult = ReturnType<
  typeof useUpdateConnectionReviewersMutation
>;
export type UpdateConnectionReviewersMutationResult = Apollo.MutationResult<
  UpdateConnectionReviewersMutation
>;
export type UpdateConnectionReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateConnectionReviewersMutation,
  UpdateConnectionReviewersMutationVariables
>;
export const UpdateConnectionUserReviewersDocument = gql`
  mutation UpdateConnectionUserReviewers(
    $input: UpdateConnectionUserReviewersInput!
  ) {
    updateConnectionUserReviewers(input: $input) {
      __typename
      ... on UpdateConnectionUserReviewersResult {
        accessReviewConnection {
          ...AccessReviewConnection
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on UpdateAccessReviewReviewersValidationError {
        message
      }
    }
  }
  ${AccessReviewConnectionFragmentDoc}
`;
export type UpdateConnectionUserReviewersMutationFn = Apollo.MutationFunction<
  UpdateConnectionUserReviewersMutation,
  UpdateConnectionUserReviewersMutationVariables
>;

/**
 * __useUpdateConnectionUserReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionUserReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionUserReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionUserReviewersMutation, { data, loading, error }] = useUpdateConnectionUserReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectionUserReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConnectionUserReviewersMutation,
    UpdateConnectionUserReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConnectionUserReviewersMutation,
    UpdateConnectionUserReviewersMutationVariables
  >(UpdateConnectionUserReviewersDocument, options);
}
export type UpdateConnectionUserReviewersMutationHookResult = ReturnType<
  typeof useUpdateConnectionUserReviewersMutation
>;
export type UpdateConnectionUserReviewersMutationResult = Apollo.MutationResult<
  UpdateConnectionUserReviewersMutation
>;
export type UpdateConnectionUserReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateConnectionUserReviewersMutation,
  UpdateConnectionUserReviewersMutationVariables
>;
export const AccessReviewConnectionDocument = gql`
  query AccessReviewConnection($input: AccessReviewConnectionInput!) {
    accessReviewConnection(input: $input) {
      __typename
      ... on AccessReviewConnectionResult {
        accessReviewConnection {
          ...AccessReviewConnection
        }
      }
      ... on AccessReviewConnectionNotFoundError {
        message
      }
    }
  }
  ${AccessReviewConnectionFragmentDoc}
`;

/**
 * __useAccessReviewConnectionQuery__
 *
 * To run a query within a React component, call `useAccessReviewConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewConnectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewConnectionQuery,
    AccessReviewConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewConnectionQuery,
    AccessReviewConnectionQueryVariables
  >(AccessReviewConnectionDocument, options);
}
export function useAccessReviewConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewConnectionQuery,
    AccessReviewConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewConnectionQuery,
    AccessReviewConnectionQueryVariables
  >(AccessReviewConnectionDocument, options);
}
export type AccessReviewConnectionQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionQuery
>;
export type AccessReviewConnectionLazyQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionLazyQuery
>;
export type AccessReviewConnectionQueryResult = Apollo.QueryResult<
  AccessReviewConnectionQuery,
  AccessReviewConnectionQueryVariables
>;
export const AccessReviewConnectionsDocument = gql`
  query AccessReviewConnections($input: AccessReviewConnectionsInput!) {
    accessReviewConnections(input: $input) {
      __typename
      ... on AccessReviewConnectionsResult {
        accessReviewConnections {
          ...AccessReviewConnectionPreview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewConnectionPreviewFragmentDoc}
`;

/**
 * __useAccessReviewConnectionsQuery__
 *
 * To run a query within a React component, call `useAccessReviewConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewConnectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewConnectionsQuery,
    AccessReviewConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewConnectionsQuery,
    AccessReviewConnectionsQueryVariables
  >(AccessReviewConnectionsDocument, options);
}
export function useAccessReviewConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewConnectionsQuery,
    AccessReviewConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewConnectionsQuery,
    AccessReviewConnectionsQueryVariables
  >(AccessReviewConnectionsDocument, options);
}
export type AccessReviewConnectionsQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionsQuery
>;
export type AccessReviewConnectionsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionsLazyQuery
>;
export type AccessReviewConnectionsQueryResult = Apollo.QueryResult<
  AccessReviewConnectionsQuery,
  AccessReviewConnectionsQueryVariables
>;
export const AccessReviewFilteredConnectionsDocument = gql`
  query AccessReviewFilteredConnections($input: FilteredConnectionsInput!) {
    filteredConnections(input: $input) {
      connections {
        ...ConnectionPreviewLarge
      }
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
`;

/**
 * __useAccessReviewFilteredConnectionsQuery__
 *
 * To run a query within a React component, call `useAccessReviewFilteredConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewFilteredConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewFilteredConnectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewFilteredConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewFilteredConnectionsQuery,
    AccessReviewFilteredConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewFilteredConnectionsQuery,
    AccessReviewFilteredConnectionsQueryVariables
  >(AccessReviewFilteredConnectionsDocument, options);
}
export function useAccessReviewFilteredConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewFilteredConnectionsQuery,
    AccessReviewFilteredConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewFilteredConnectionsQuery,
    AccessReviewFilteredConnectionsQueryVariables
  >(AccessReviewFilteredConnectionsDocument, options);
}
export type AccessReviewFilteredConnectionsQueryHookResult = ReturnType<
  typeof useAccessReviewFilteredConnectionsQuery
>;
export type AccessReviewFilteredConnectionsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewFilteredConnectionsLazyQuery
>;
export type AccessReviewFilteredConnectionsQueryResult = Apollo.QueryResult<
  AccessReviewFilteredConnectionsQuery,
  AccessReviewFilteredConnectionsQueryVariables
>;
export const UpdateGroupResourceReviewersDocument = gql`
  mutation UpdateGroupResourceReviewers(
    $input: UpdateGroupResourceReviewersInput!
  ) {
    updateGroupResourceReviewers(input: $input) {
      __typename
      ... on UpdateGroupResourceReviewersResult {
        accessReviewGroupResource {
          id
        }
        accessReviewGroup {
          id
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
`;
export type UpdateGroupResourceReviewersMutationFn = Apollo.MutationFunction<
  UpdateGroupResourceReviewersMutation,
  UpdateGroupResourceReviewersMutationVariables
>;

/**
 * __useUpdateGroupResourceReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateGroupResourceReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupResourceReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupResourceReviewersMutation, { data, loading, error }] = useUpdateGroupResourceReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupResourceReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupResourceReviewersMutation,
    UpdateGroupResourceReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupResourceReviewersMutation,
    UpdateGroupResourceReviewersMutationVariables
  >(UpdateGroupResourceReviewersDocument, options);
}
export type UpdateGroupResourceReviewersMutationHookResult = ReturnType<
  typeof useUpdateGroupResourceReviewersMutation
>;
export type UpdateGroupResourceReviewersMutationResult = Apollo.MutationResult<
  UpdateGroupResourceReviewersMutation
>;
export type UpdateGroupResourceReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupResourceReviewersMutation,
  UpdateGroupResourceReviewersMutationVariables
>;
export const UpdateAccessReviewGroupReviewersDocument = gql`
  mutation UpdateAccessReviewGroupReviewers(
    $input: UpdateAccessReviewGroupReviewersInput!
  ) {
    updateAccessReviewGroupReviewers(input: $input) {
      __typename
      ... on UpdateAccessReviewGroupReviewersResult {
        accessReviewGroup {
          ...AccessReviewGroup
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewGroupFragmentDoc}
`;
export type UpdateAccessReviewGroupReviewersMutationFn = Apollo.MutationFunction<
  UpdateAccessReviewGroupReviewersMutation,
  UpdateAccessReviewGroupReviewersMutationVariables
>;

/**
 * __useUpdateAccessReviewGroupReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateAccessReviewGroupReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessReviewGroupReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessReviewGroupReviewersMutation, { data, loading, error }] = useUpdateAccessReviewGroupReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessReviewGroupReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessReviewGroupReviewersMutation,
    UpdateAccessReviewGroupReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessReviewGroupReviewersMutation,
    UpdateAccessReviewGroupReviewersMutationVariables
  >(UpdateAccessReviewGroupReviewersDocument, options);
}
export type UpdateAccessReviewGroupReviewersMutationHookResult = ReturnType<
  typeof useUpdateAccessReviewGroupReviewersMutation
>;
export type UpdateAccessReviewGroupReviewersMutationResult = Apollo.MutationResult<
  UpdateAccessReviewGroupReviewersMutation
>;
export type UpdateAccessReviewGroupReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessReviewGroupReviewersMutation,
  UpdateAccessReviewGroupReviewersMutationVariables
>;
export const UpdateGroupUserReviewersDocument = gql`
  mutation UpdateGroupUserReviewers($input: UpdateGroupUserReviewersInput!) {
    updateGroupUserReviewers(input: $input) {
      __typename
      ... on UpdateGroupUserReviewersResult {
        __typename
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on UpdateAccessReviewReviewersValidationError {
        message
      }
    }
  }
`;
export type UpdateGroupUserReviewersMutationFn = Apollo.MutationFunction<
  UpdateGroupUserReviewersMutation,
  UpdateGroupUserReviewersMutationVariables
>;

/**
 * __useUpdateGroupUserReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateGroupUserReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupUserReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupUserReviewersMutation, { data, loading, error }] = useUpdateGroupUserReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupUserReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupUserReviewersMutation,
    UpdateGroupUserReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupUserReviewersMutation,
    UpdateGroupUserReviewersMutationVariables
  >(UpdateGroupUserReviewersDocument, options);
}
export type UpdateGroupUserReviewersMutationHookResult = ReturnType<
  typeof useUpdateGroupUserReviewersMutation
>;
export type UpdateGroupUserReviewersMutationResult = Apollo.MutationResult<
  UpdateGroupUserReviewersMutation
>;
export type UpdateGroupUserReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupUserReviewersMutation,
  UpdateGroupUserReviewersMutationVariables
>;
export const AccessReviewGroupDocument = gql`
  query AccessReviewGroup($input: AccessReviewGroupInput!) {
    accessReviewGroup(input: $input) {
      __typename
      ... on AccessReviewGroupResult {
        accessReviewGroup {
          ...AccessReviewGroup
        }
      }
      ... on AccessReviewGroupNotFoundError {
        message
      }
    }
  }
  ${AccessReviewGroupFragmentDoc}
`;

/**
 * __useAccessReviewGroupQuery__
 *
 * To run a query within a React component, call `useAccessReviewGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewGroupQuery,
    AccessReviewGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewGroupQuery,
    AccessReviewGroupQueryVariables
  >(AccessReviewGroupDocument, options);
}
export function useAccessReviewGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewGroupQuery,
    AccessReviewGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewGroupQuery,
    AccessReviewGroupQueryVariables
  >(AccessReviewGroupDocument, options);
}
export type AccessReviewGroupQueryHookResult = ReturnType<
  typeof useAccessReviewGroupQuery
>;
export type AccessReviewGroupLazyQueryHookResult = ReturnType<
  typeof useAccessReviewGroupLazyQuery
>;
export type AccessReviewGroupQueryResult = Apollo.QueryResult<
  AccessReviewGroupQuery,
  AccessReviewGroupQueryVariables
>;
export const AccessReviewGroupsDocument = gql`
  query AccessReviewGroups($input: AccessReviewGroupsInput!) {
    accessReviewGroups(input: $input) {
      __typename
      ... on AccessReviewGroupsResult {
        accessReviewGroups {
          ...AccessReviewGroupPreview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewGroupPreviewFragmentDoc}
`;

/**
 * __useAccessReviewGroupsQuery__
 *
 * To run a query within a React component, call `useAccessReviewGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewGroupsQuery,
    AccessReviewGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewGroupsQuery,
    AccessReviewGroupsQueryVariables
  >(AccessReviewGroupsDocument, options);
}
export function useAccessReviewGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewGroupsQuery,
    AccessReviewGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewGroupsQuery,
    AccessReviewGroupsQueryVariables
  >(AccessReviewGroupsDocument, options);
}
export type AccessReviewGroupsQueryHookResult = ReturnType<
  typeof useAccessReviewGroupsQuery
>;
export type AccessReviewGroupsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewGroupsLazyQuery
>;
export type AccessReviewGroupsQueryResult = Apollo.QueryResult<
  AccessReviewGroupsQuery,
  AccessReviewGroupsQueryVariables
>;
export const UpdateAccessReviewResourceReviewersDocument = gql`
  mutation UpdateAccessReviewResourceReviewers(
    $input: UpdateAccessReviewResourceReviewersInput!
  ) {
    updateAccessReviewResourceReviewers(input: $input) {
      __typename
      ... on UpdateAccessReviewResourceReviewersResult {
        accessReviewResource {
          ...AccessReviewResource
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewResourceFragmentDoc}
`;
export type UpdateAccessReviewResourceReviewersMutationFn = Apollo.MutationFunction<
  UpdateAccessReviewResourceReviewersMutation,
  UpdateAccessReviewResourceReviewersMutationVariables
>;

/**
 * __useUpdateAccessReviewResourceReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateAccessReviewResourceReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessReviewResourceReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessReviewResourceReviewersMutation, { data, loading, error }] = useUpdateAccessReviewResourceReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessReviewResourceReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessReviewResourceReviewersMutation,
    UpdateAccessReviewResourceReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessReviewResourceReviewersMutation,
    UpdateAccessReviewResourceReviewersMutationVariables
  >(UpdateAccessReviewResourceReviewersDocument, options);
}
export type UpdateAccessReviewResourceReviewersMutationHookResult = ReturnType<
  typeof useUpdateAccessReviewResourceReviewersMutation
>;
export type UpdateAccessReviewResourceReviewersMutationResult = Apollo.MutationResult<
  UpdateAccessReviewResourceReviewersMutation
>;
export type UpdateAccessReviewResourceReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessReviewResourceReviewersMutation,
  UpdateAccessReviewResourceReviewersMutationVariables
>;
export const UpdateResourceUserReviewersDocument = gql`
  mutation UpdateResourceUserReviewers(
    $input: UpdateResourceUserReviewersInput!
  ) {
    updateResourceUserReviewers(input: $input) {
      __typename
      ... on UpdateResourceUserReviewersResult {
        accessReviewResource {
          ...AccessReviewResource
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on UpdateAccessReviewReviewersValidationError {
        message
      }
    }
  }
  ${AccessReviewResourceFragmentDoc}
`;
export type UpdateResourceUserReviewersMutationFn = Apollo.MutationFunction<
  UpdateResourceUserReviewersMutation,
  UpdateResourceUserReviewersMutationVariables
>;

/**
 * __useUpdateResourceUserReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateResourceUserReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceUserReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceUserReviewersMutation, { data, loading, error }] = useUpdateResourceUserReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceUserReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourceUserReviewersMutation,
    UpdateResourceUserReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourceUserReviewersMutation,
    UpdateResourceUserReviewersMutationVariables
  >(UpdateResourceUserReviewersDocument, options);
}
export type UpdateResourceUserReviewersMutationHookResult = ReturnType<
  typeof useUpdateResourceUserReviewersMutation
>;
export type UpdateResourceUserReviewersMutationResult = Apollo.MutationResult<
  UpdateResourceUserReviewersMutation
>;
export type UpdateResourceUserReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceUserReviewersMutation,
  UpdateResourceUserReviewersMutationVariables
>;
export const ConfirmEndSystemRevocationDocument = gql`
  mutation ConfirmEndSystemRevocation(
    $input: ConfirmEndSystemRevocationInput!
  ) {
    confirmEndSystemRevocation(input: $input) {
      __typename
      ... on ConfirmEndSystemRevocationResult {
        accessReviewResourceUsers {
          ...AccessReviewResourceUserPreview
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on AccessReviewUserSupportTicketAlreadyLinkedError {
        message
      }
      ... on RemoteUserNotFoundError {
        message
      }
    }
  }
  ${AccessReviewResourceUserPreviewFragmentDoc}
`;
export type ConfirmEndSystemRevocationMutationFn = Apollo.MutationFunction<
  ConfirmEndSystemRevocationMutation,
  ConfirmEndSystemRevocationMutationVariables
>;

/**
 * __useConfirmEndSystemRevocationMutation__
 *
 * To run a mutation, you first call `useConfirmEndSystemRevocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEndSystemRevocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEndSystemRevocationMutation, { data, loading, error }] = useConfirmEndSystemRevocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEndSystemRevocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEndSystemRevocationMutation,
    ConfirmEndSystemRevocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEndSystemRevocationMutation,
    ConfirmEndSystemRevocationMutationVariables
  >(ConfirmEndSystemRevocationDocument, options);
}
export type ConfirmEndSystemRevocationMutationHookResult = ReturnType<
  typeof useConfirmEndSystemRevocationMutation
>;
export type ConfirmEndSystemRevocationMutationResult = Apollo.MutationResult<
  ConfirmEndSystemRevocationMutation
>;
export type ConfirmEndSystemRevocationMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEndSystemRevocationMutation,
  ConfirmEndSystemRevocationMutationVariables
>;
export const AccessReviewResourceDocument = gql`
  query AccessReviewResource($input: AccessReviewResourceInput!) {
    accessReviewResource(input: $input) {
      __typename
      ... on AccessReviewResourceResult {
        accessReviewResource {
          ...AccessReviewResource
        }
      }
      ... on AccessReviewResourceNotFoundError {
        message
      }
    }
  }
  ${AccessReviewResourceFragmentDoc}
`;

/**
 * __useAccessReviewResourceQuery__
 *
 * To run a query within a React component, call `useAccessReviewResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewResourceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewResourceQuery,
    AccessReviewResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewResourceQuery,
    AccessReviewResourceQueryVariables
  >(AccessReviewResourceDocument, options);
}
export function useAccessReviewResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewResourceQuery,
    AccessReviewResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewResourceQuery,
    AccessReviewResourceQueryVariables
  >(AccessReviewResourceDocument, options);
}
export type AccessReviewResourceQueryHookResult = ReturnType<
  typeof useAccessReviewResourceQuery
>;
export type AccessReviewResourceLazyQueryHookResult = ReturnType<
  typeof useAccessReviewResourceLazyQuery
>;
export type AccessReviewResourceQueryResult = Apollo.QueryResult<
  AccessReviewResourceQuery,
  AccessReviewResourceQueryVariables
>;
export const AccessReviewResourcesDocument = gql`
  query AccessReviewResources($input: AccessReviewResourcesInput!) {
    accessReviewResources(input: $input) {
      __typename
      ... on AccessReviewResourcesResult {
        accessReviewResources {
          ...AccessReviewResourcePreview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewResourcePreviewFragmentDoc}
`;

/**
 * __useAccessReviewResourcesQuery__
 *
 * To run a query within a React component, call `useAccessReviewResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewResourcesQuery,
    AccessReviewResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewResourcesQuery,
    AccessReviewResourcesQueryVariables
  >(AccessReviewResourcesDocument, options);
}
export function useAccessReviewResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewResourcesQuery,
    AccessReviewResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewResourcesQuery,
    AccessReviewResourcesQueryVariables
  >(AccessReviewResourcesDocument, options);
}
export type AccessReviewResourcesQueryHookResult = ReturnType<
  typeof useAccessReviewResourcesQuery
>;
export type AccessReviewResourcesLazyQueryHookResult = ReturnType<
  typeof useAccessReviewResourcesLazyQuery
>;
export type AccessReviewResourcesQueryResult = Apollo.QueryResult<
  AccessReviewResourcesQuery,
  AccessReviewResourcesQueryVariables
>;
export const AccessReviewStatsDocument = gql`
  query AccessReviewStats($input: AccessReviewStatsInput!) {
    accessReviewStats(input: $input) {
      __typename
      ... on AccessReviewStatsResult {
        accessReviewStats {
          ...AccessReviewStats
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewStatsFragmentDoc}
`;

/**
 * __useAccessReviewStatsQuery__
 *
 * To run a query within a React component, call `useAccessReviewStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewStatsQuery,
    AccessReviewStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewStatsQuery,
    AccessReviewStatsQueryVariables
  >(AccessReviewStatsDocument, options);
}
export function useAccessReviewStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewStatsQuery,
    AccessReviewStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewStatsQuery,
    AccessReviewStatsQueryVariables
  >(AccessReviewStatsDocument, options);
}
export type AccessReviewStatsQueryHookResult = ReturnType<
  typeof useAccessReviewStatsQuery
>;
export type AccessReviewStatsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewStatsLazyQuery
>;
export type AccessReviewStatsQueryResult = Apollo.QueryResult<
  AccessReviewStatsQuery,
  AccessReviewStatsQueryVariables
>;
export const OngoingAccessReviewTabStatsDocument = gql`
  query OngoingAccessReviewTabStats($input: OngoingAccessReviewTabStatsInput!) {
    ongoingAccessReviewTabStats(input: $input) {
      __typename
      ... on OngoingAccessReviewTabStatsResult {
        ongoingAccessReviewTabStats {
          ...OngoingAccessReviewTabStats
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${OngoingAccessReviewTabStatsFragmentDoc}
`;

/**
 * __useOngoingAccessReviewTabStatsQuery__
 *
 * To run a query within a React component, call `useOngoingAccessReviewTabStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingAccessReviewTabStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingAccessReviewTabStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOngoingAccessReviewTabStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OngoingAccessReviewTabStatsQuery,
    OngoingAccessReviewTabStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OngoingAccessReviewTabStatsQuery,
    OngoingAccessReviewTabStatsQueryVariables
  >(OngoingAccessReviewTabStatsDocument, options);
}
export function useOngoingAccessReviewTabStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OngoingAccessReviewTabStatsQuery,
    OngoingAccessReviewTabStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OngoingAccessReviewTabStatsQuery,
    OngoingAccessReviewTabStatsQueryVariables
  >(OngoingAccessReviewTabStatsDocument, options);
}
export type OngoingAccessReviewTabStatsQueryHookResult = ReturnType<
  typeof useOngoingAccessReviewTabStatsQuery
>;
export type OngoingAccessReviewTabStatsLazyQueryHookResult = ReturnType<
  typeof useOngoingAccessReviewTabStatsLazyQuery
>;
export type OngoingAccessReviewTabStatsQueryResult = Apollo.QueryResult<
  OngoingAccessReviewTabStatsQuery,
  OngoingAccessReviewTabStatsQueryVariables
>;
export const OngoingAccessReviewSubtabStatsDocument = gql`
  query OngoingAccessReviewSubtabStats(
    $input: OngoingAccessReviewSubtabStatsInput!
  ) {
    ongoingAccessReviewSubtabStats(input: $input) {
      __typename
      ... on OngoingAccessReviewSubtabStatsResult {
        ongoingAccessReviewSubtabStats {
          ...OngoingAccessReviewSubtabStats
        }
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${OngoingAccessReviewSubtabStatsFragmentDoc}
`;

/**
 * __useOngoingAccessReviewSubtabStatsQuery__
 *
 * To run a query within a React component, call `useOngoingAccessReviewSubtabStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingAccessReviewSubtabStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingAccessReviewSubtabStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOngoingAccessReviewSubtabStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OngoingAccessReviewSubtabStatsQuery,
    OngoingAccessReviewSubtabStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OngoingAccessReviewSubtabStatsQuery,
    OngoingAccessReviewSubtabStatsQueryVariables
  >(OngoingAccessReviewSubtabStatsDocument, options);
}
export function useOngoingAccessReviewSubtabStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OngoingAccessReviewSubtabStatsQuery,
    OngoingAccessReviewSubtabStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OngoingAccessReviewSubtabStatsQuery,
    OngoingAccessReviewSubtabStatsQueryVariables
  >(OngoingAccessReviewSubtabStatsDocument, options);
}
export type OngoingAccessReviewSubtabStatsQueryHookResult = ReturnType<
  typeof useOngoingAccessReviewSubtabStatsQuery
>;
export type OngoingAccessReviewSubtabStatsLazyQueryHookResult = ReturnType<
  typeof useOngoingAccessReviewSubtabStatsLazyQuery
>;
export type OngoingAccessReviewSubtabStatsQueryResult = Apollo.QueryResult<
  OngoingAccessReviewSubtabStatsQuery,
  OngoingAccessReviewSubtabStatsQueryVariables
>;
export const AccessReviewTemplatesDocument = gql`
  query AccessReviewTemplates {
    accessReviewTemplates {
      __typename
      ... on AccessReviewTemplatesResult {
        accessReviewTemplates {
          ...AccessReviewTemplate
        }
      }
    }
  }
  ${AccessReviewTemplateFragmentDoc}
`;

/**
 * __useAccessReviewTemplatesQuery__
 *
 * To run a query within a React component, call `useAccessReviewTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessReviewTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccessReviewTemplatesQuery,
    AccessReviewTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewTemplatesQuery,
    AccessReviewTemplatesQueryVariables
  >(AccessReviewTemplatesDocument, options);
}
export function useAccessReviewTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewTemplatesQuery,
    AccessReviewTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewTemplatesQuery,
    AccessReviewTemplatesQueryVariables
  >(AccessReviewTemplatesDocument, options);
}
export type AccessReviewTemplatesQueryHookResult = ReturnType<
  typeof useAccessReviewTemplatesQuery
>;
export type AccessReviewTemplatesLazyQueryHookResult = ReturnType<
  typeof useAccessReviewTemplatesLazyQuery
>;
export type AccessReviewTemplatesQueryResult = Apollo.QueryResult<
  AccessReviewTemplatesQuery,
  AccessReviewTemplatesQueryVariables
>;
export const AccessReviewUserDocument = gql`
  query AccessReviewUser($input: AccessReviewUserInput!) {
    accessReviewUser(input: $input) {
      __typename
      ... on AccessReviewUserResult {
        accessReviewUser {
          ...AccessReviewUser
        }
      }
      ... on AccessReviewUserNotFoundError {
        message
      }
    }
  }
  ${AccessReviewUserFragmentDoc}
`;

/**
 * __useAccessReviewUserQuery__
 *
 * To run a query within a React component, call `useAccessReviewUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewUserQuery,
    AccessReviewUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessReviewUserQuery, AccessReviewUserQueryVariables>(
    AccessReviewUserDocument,
    options
  );
}
export function useAccessReviewUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewUserQuery,
    AccessReviewUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewUserQuery,
    AccessReviewUserQueryVariables
  >(AccessReviewUserDocument, options);
}
export type AccessReviewUserQueryHookResult = ReturnType<
  typeof useAccessReviewUserQuery
>;
export type AccessReviewUserLazyQueryHookResult = ReturnType<
  typeof useAccessReviewUserLazyQuery
>;
export type AccessReviewUserQueryResult = Apollo.QueryResult<
  AccessReviewUserQuery,
  AccessReviewUserQueryVariables
>;
export const AccessReviewUsersDocument = gql`
  query AccessReviewUsers($input: AccessReviewUsersInput!) {
    accessReviewUsers(input: $input) {
      __typename
      ... on AccessReviewUsersResult {
        accessReviewUsers {
          ...AccessReviewUserPreview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewUserPreviewFragmentDoc}
`;

/**
 * __useAccessReviewUsersQuery__
 *
 * To run a query within a React component, call `useAccessReviewUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewUsersQuery,
    AccessReviewUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewUsersQuery,
    AccessReviewUsersQueryVariables
  >(AccessReviewUsersDocument, options);
}
export function useAccessReviewUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewUsersQuery,
    AccessReviewUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewUsersQuery,
    AccessReviewUsersQueryVariables
  >(AccessReviewUsersDocument, options);
}
export type AccessReviewUsersQueryHookResult = ReturnType<
  typeof useAccessReviewUsersQuery
>;
export type AccessReviewUsersLazyQueryHookResult = ReturnType<
  typeof useAccessReviewUsersLazyQuery
>;
export type AccessReviewUsersQueryResult = Apollo.QueryResult<
  AccessReviewUsersQuery,
  AccessReviewUsersQueryVariables
>;
export const AccessReviewDocument = gql`
  query AccessReview($input: AccessReviewInput!) {
    accessReview(input: $input) {
      __typename
      ... on AccessReviewResult {
        accessReview {
          ...AccessReview
        }
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;

/**
 * __useAccessReviewQuery__
 *
 * To run a query within a React component, call `useAccessReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewQuery,
    AccessReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessReviewQuery, AccessReviewQueryVariables>(
    AccessReviewDocument,
    options
  );
}
export function useAccessReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewQuery,
    AccessReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessReviewQuery, AccessReviewQueryVariables>(
    AccessReviewDocument,
    options
  );
}
export type AccessReviewQueryHookResult = ReturnType<
  typeof useAccessReviewQuery
>;
export type AccessReviewLazyQueryHookResult = ReturnType<
  typeof useAccessReviewLazyQuery
>;
export type AccessReviewQueryResult = Apollo.QueryResult<
  AccessReviewQuery,
  AccessReviewQueryVariables
>;
export const AccessReviewsDocument = gql`
  query AccessReviews($input: AccessReviewsInput!) {
    accessReviews(input: $input) {
      __typename
      ... on AccessReviewsResult {
        accessReviews {
          ...AccessReview
        }
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;

/**
 * __useAccessReviewsQuery__
 *
 * To run a query within a React component, call `useAccessReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewsQuery,
    AccessReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccessReviewsQuery, AccessReviewsQueryVariables>(
    AccessReviewsDocument,
    options
  );
}
export function useAccessReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewsQuery,
    AccessReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccessReviewsQuery, AccessReviewsQueryVariables>(
    AccessReviewsDocument,
    options
  );
}
export type AccessReviewsQueryHookResult = ReturnType<
  typeof useAccessReviewsQuery
>;
export type AccessReviewsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewsLazyQuery
>;
export type AccessReviewsQueryResult = Apollo.QueryResult<
  AccessReviewsQuery,
  AccessReviewsQueryVariables
>;
export const AccessReviewsNoStatsDocument = gql`
  query AccessReviewsNoStats($input: AccessReviewsInput!) {
    accessReviews(input: $input) {
      __typename
      ... on AccessReviewsResult {
        accessReviews {
          ...AccessReviewBase
        }
      }
    }
  }
  ${AccessReviewBaseFragmentDoc}
`;

/**
 * __useAccessReviewsNoStatsQuery__
 *
 * To run a query within a React component, call `useAccessReviewsNoStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewsNoStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewsNoStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewsNoStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewsNoStatsQuery,
    AccessReviewsNoStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewsNoStatsQuery,
    AccessReviewsNoStatsQueryVariables
  >(AccessReviewsNoStatsDocument, options);
}
export function useAccessReviewsNoStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewsNoStatsQuery,
    AccessReviewsNoStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewsNoStatsQuery,
    AccessReviewsNoStatsQueryVariables
  >(AccessReviewsNoStatsDocument, options);
}
export type AccessReviewsNoStatsQueryHookResult = ReturnType<
  typeof useAccessReviewsNoStatsQuery
>;
export type AccessReviewsNoStatsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewsNoStatsLazyQuery
>;
export type AccessReviewsNoStatsQueryResult = Apollo.QueryResult<
  AccessReviewsNoStatsQuery,
  AccessReviewsNoStatsQueryVariables
>;
export const AccessReviewRevocationsDocument = gql`
  query AccessReviewRevocations($input: AccessReviewInput!) {
    accessReviewResourceUsers(input: $input) {
      __typename
      ... on AccessReviewResourceUsersResult {
        accessReviewResourceUsers {
          ...AccessReviewResourceUser
        }
      }
    }
    accessReviewGroupUsers(input: $input) {
      __typename
      ... on AccessReviewGroupUsersResult {
        accessReviewGroupUsers {
          ...AccessReviewGroupUser
        }
      }
    }
    accessReviewConnectionUsers(input: $input) {
      __typename
      ... on AccessReviewConnectionUsersResult {
        accessReviewConnectionUsers {
          ...AccessReviewConnectionUser
        }
      }
    }
  }
  ${AccessReviewResourceUserFragmentDoc}
  ${AccessReviewGroupUserFragmentDoc}
  ${AccessReviewConnectionUserFragmentDoc}
`;

/**
 * __useAccessReviewRevocationsQuery__
 *
 * To run a query within a React component, call `useAccessReviewRevocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewRevocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewRevocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewRevocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewRevocationsQuery,
    AccessReviewRevocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewRevocationsQuery,
    AccessReviewRevocationsQueryVariables
  >(AccessReviewRevocationsDocument, options);
}
export function useAccessReviewRevocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewRevocationsQuery,
    AccessReviewRevocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewRevocationsQuery,
    AccessReviewRevocationsQueryVariables
  >(AccessReviewRevocationsDocument, options);
}
export type AccessReviewRevocationsQueryHookResult = ReturnType<
  typeof useAccessReviewRevocationsQuery
>;
export type AccessReviewRevocationsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewRevocationsLazyQuery
>;
export type AccessReviewRevocationsQueryResult = Apollo.QueryResult<
  AccessReviewRevocationsQuery,
  AccessReviewRevocationsQueryVariables
>;
export const UpdateAccessReviewDocument = gql`
  mutation UpdateAccessReview($input: UpdateAccessReviewInput!) {
    updateAccessReview(input: $input) {
      __typename
      ... on UpdateAccessReviewResult {
        accessReview {
          ...AccessReview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;
export type UpdateAccessReviewMutationFn = Apollo.MutationFunction<
  UpdateAccessReviewMutation,
  UpdateAccessReviewMutationVariables
>;

/**
 * __useUpdateAccessReviewMutation__
 *
 * To run a mutation, you first call `useUpdateAccessReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessReviewMutation, { data, loading, error }] = useUpdateAccessReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessReviewMutation,
    UpdateAccessReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessReviewMutation,
    UpdateAccessReviewMutationVariables
  >(UpdateAccessReviewDocument, options);
}
export type UpdateAccessReviewMutationHookResult = ReturnType<
  typeof useUpdateAccessReviewMutation
>;
export type UpdateAccessReviewMutationResult = Apollo.MutationResult<
  UpdateAccessReviewMutation
>;
export type UpdateAccessReviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessReviewMutation,
  UpdateAccessReviewMutationVariables
>;
export const StopAccessReviewDocument = gql`
  mutation StopAccessReview($input: StopAccessReviewInput!) {
    stopAccessReview(input: $input) {
      __typename
      ... on StopAccessReviewResult {
        accessReview {
          ...AccessReview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;
export type StopAccessReviewMutationFn = Apollo.MutationFunction<
  StopAccessReviewMutation,
  StopAccessReviewMutationVariables
>;

/**
 * __useStopAccessReviewMutation__
 *
 * To run a mutation, you first call `useStopAccessReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopAccessReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopAccessReviewMutation, { data, loading, error }] = useStopAccessReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopAccessReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopAccessReviewMutation,
    StopAccessReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopAccessReviewMutation,
    StopAccessReviewMutationVariables
  >(StopAccessReviewDocument, options);
}
export type StopAccessReviewMutationHookResult = ReturnType<
  typeof useStopAccessReviewMutation
>;
export type StopAccessReviewMutationResult = Apollo.MutationResult<
  StopAccessReviewMutation
>;
export type StopAccessReviewMutationOptions = Apollo.BaseMutationOptions<
  StopAccessReviewMutation,
  StopAccessReviewMutationVariables
>;
export const SetNoteDocument = gql`
  mutation SetNote($input: SetNoteInput!) {
    setNote(input: $input) {
      __typename
      ... on SetNoteResult {
        success
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
`;
export type SetNoteMutationFn = Apollo.MutationFunction<
  SetNoteMutation,
  SetNoteMutationVariables
>;

/**
 * __useSetNoteMutation__
 *
 * To run a mutation, you first call `useSetNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNoteMutation, { data, loading, error }] = useSetNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNoteMutation,
    SetNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetNoteMutation, SetNoteMutationVariables>(
    SetNoteDocument,
    options
  );
}
export type SetNoteMutationHookResult = ReturnType<typeof useSetNoteMutation>;
export type SetNoteMutationResult = Apollo.MutationResult<SetNoteMutation>;
export type SetNoteMutationOptions = Apollo.BaseMutationOptions<
  SetNoteMutation,
  SetNoteMutationVariables
>;
export const PerformReviewDocument = gql`
  mutation PerformReview($input: PerformReviewInput!) {
    performReview(input: $input) {
      __typename
      ... on PerformReviewResult {
        success
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
      ... on ItemCannotBeRequestedError {
        message
      }
    }
  }
`;
export type PerformReviewMutationFn = Apollo.MutationFunction<
  PerformReviewMutation,
  PerformReviewMutationVariables
>;

/**
 * __usePerformReviewMutation__
 *
 * To run a mutation, you first call `usePerformReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performReviewMutation, { data, loading, error }] = usePerformReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PerformReviewMutation,
    PerformReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PerformReviewMutation,
    PerformReviewMutationVariables
  >(PerformReviewDocument, options);
}
export type PerformReviewMutationHookResult = ReturnType<
  typeof usePerformReviewMutation
>;
export type PerformReviewMutationResult = Apollo.MutationResult<
  PerformReviewMutation
>;
export type PerformReviewMutationOptions = Apollo.BaseMutationOptions<
  PerformReviewMutation,
  PerformReviewMutationVariables
>;
export const SendAccessReviewReminderDocument = gql`
  mutation SendAccessReviewReminder($input: SendAccessReviewReminderInput!) {
    sendAccessReviewReminder(input: $input) {
      __typename
      ... on SendAccessReviewReminderResult {
        accessReview {
          ...AccessReview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewAlreadyStoppedError {
        message
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;
export type SendAccessReviewReminderMutationFn = Apollo.MutationFunction<
  SendAccessReviewReminderMutation,
  SendAccessReviewReminderMutationVariables
>;

/**
 * __useSendAccessReviewReminderMutation__
 *
 * To run a mutation, you first call `useSendAccessReviewReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccessReviewReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccessReviewReminderMutation, { data, loading, error }] = useSendAccessReviewReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAccessReviewReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendAccessReviewReminderMutation,
    SendAccessReviewReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendAccessReviewReminderMutation,
    SendAccessReviewReminderMutationVariables
  >(SendAccessReviewReminderDocument, options);
}
export type SendAccessReviewReminderMutationHookResult = ReturnType<
  typeof useSendAccessReviewReminderMutation
>;
export type SendAccessReviewReminderMutationResult = Apollo.MutationResult<
  SendAccessReviewReminderMutation
>;
export type SendAccessReviewReminderMutationOptions = Apollo.BaseMutationOptions<
  SendAccessReviewReminderMutation,
  SendAccessReviewReminderMutationVariables
>;
export const RegenerateAccessReviewReportDocument = gql`
  mutation RegenerateAccessReviewReport(
    $input: RegenerateAccessReviewReportInput!
  ) {
    regenerateAccessReviewReport(input: $input) {
      __typename
      ... on RegenerateAccessReviewReportResult {
        accessReview {
          ...AccessReview
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
      ... on AccessReviewNotStoppedError {
        message
      }
    }
  }
  ${AccessReviewFragmentDoc}
`;
export type RegenerateAccessReviewReportMutationFn = Apollo.MutationFunction<
  RegenerateAccessReviewReportMutation,
  RegenerateAccessReviewReportMutationVariables
>;

/**
 * __useRegenerateAccessReviewReportMutation__
 *
 * To run a mutation, you first call `useRegenerateAccessReviewReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateAccessReviewReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateAccessReviewReportMutation, { data, loading, error }] = useRegenerateAccessReviewReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegenerateAccessReviewReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateAccessReviewReportMutation,
    RegenerateAccessReviewReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateAccessReviewReportMutation,
    RegenerateAccessReviewReportMutationVariables
  >(RegenerateAccessReviewReportDocument, options);
}
export type RegenerateAccessReviewReportMutationHookResult = ReturnType<
  typeof useRegenerateAccessReviewReportMutation
>;
export type RegenerateAccessReviewReportMutationResult = Apollo.MutationResult<
  RegenerateAccessReviewReportMutation
>;
export type RegenerateAccessReviewReportMutationOptions = Apollo.BaseMutationOptions<
  RegenerateAccessReviewReportMutation,
  RegenerateAccessReviewReportMutationVariables
>;
export const ResetDataseedDocument = gql`
  mutation ResetDataseed {
    resetDataseed {
      __typename
      ... on ResetDataseedResult {
        success
      }
    }
  }
`;
export type ResetDataseedMutationFn = Apollo.MutationFunction<
  ResetDataseedMutation,
  ResetDataseedMutationVariables
>;

/**
 * __useResetDataseedMutation__
 *
 * To run a mutation, you first call `useResetDataseedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDataseedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDataseedMutation, { data, loading, error }] = useResetDataseedMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetDataseedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetDataseedMutation,
    ResetDataseedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetDataseedMutation,
    ResetDataseedMutationVariables
  >(ResetDataseedDocument, options);
}
export type ResetDataseedMutationHookResult = ReturnType<
  typeof useResetDataseedMutation
>;
export type ResetDataseedMutationResult = Apollo.MutationResult<
  ResetDataseedMutation
>;
export type ResetDataseedMutationOptions = Apollo.BaseMutationOptions<
  ResetDataseedMutation,
  ResetDataseedMutationVariables
>;
export const RescoreRecommendationsDocument = gql`
  mutation RescoreRecommendations {
    rescoreRecommendations {
      __typename
      ... on RescoreRecommendationsResult {
        success
      }
    }
  }
`;
export type RescoreRecommendationsMutationFn = Apollo.MutationFunction<
  RescoreRecommendationsMutation,
  RescoreRecommendationsMutationVariables
>;

/**
 * __useRescoreRecommendationsMutation__
 *
 * To run a mutation, you first call `useRescoreRecommendationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescoreRecommendationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescoreRecommendationsMutation, { data, loading, error }] = useRescoreRecommendationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useRescoreRecommendationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescoreRecommendationsMutation,
    RescoreRecommendationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescoreRecommendationsMutation,
    RescoreRecommendationsMutationVariables
  >(RescoreRecommendationsDocument, options);
}
export type RescoreRecommendationsMutationHookResult = ReturnType<
  typeof useRescoreRecommendationsMutation
>;
export type RescoreRecommendationsMutationResult = Apollo.MutationResult<
  RescoreRecommendationsMutation
>;
export type RescoreRecommendationsMutationOptions = Apollo.BaseMutationOptions<
  RescoreRecommendationsMutation,
  RescoreRecommendationsMutationVariables
>;
export const ResetRemediationsDocument = gql`
  mutation ResetRemediations {
    resetRemediations {
      __typename
      ... on ResetRemediationsResult {
        success
      }
    }
  }
`;
export type ResetRemediationsMutationFn = Apollo.MutationFunction<
  ResetRemediationsMutation,
  ResetRemediationsMutationVariables
>;

/**
 * __useResetRemediationsMutation__
 *
 * To run a mutation, you first call `useResetRemediationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetRemediationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetRemediationsMutation, { data, loading, error }] = useResetRemediationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetRemediationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetRemediationsMutation,
    ResetRemediationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetRemediationsMutation,
    ResetRemediationsMutationVariables
  >(ResetRemediationsDocument, options);
}
export type ResetRemediationsMutationHookResult = ReturnType<
  typeof useResetRemediationsMutation
>;
export type ResetRemediationsMutationResult = Apollo.MutationResult<
  ResetRemediationsMutation
>;
export type ResetRemediationsMutationOptions = Apollo.BaseMutationOptions<
  ResetRemediationsMutation,
  ResetRemediationsMutationVariables
>;
export const SetRolesDocument = gql`
  mutation SetRoles($input: SetRolesInput!) {
    setRoles(input: $input) {
      __typename
      ... on SetRolesResult {
        success
      }
    }
  }
`;
export type SetRolesMutationFn = Apollo.MutationFunction<
  SetRolesMutation,
  SetRolesMutationVariables
>;

/**
 * __useSetRolesMutation__
 *
 * To run a mutation, you first call `useSetRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRolesMutation, { data, loading, error }] = useSetRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetRolesMutation,
    SetRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetRolesMutation, SetRolesMutationVariables>(
    SetRolesDocument,
    options
  );
}
export type SetRolesMutationHookResult = ReturnType<typeof useSetRolesMutation>;
export type SetRolesMutationResult = Apollo.MutationResult<SetRolesMutation>;
export type SetRolesMutationOptions = Apollo.BaseMutationOptions<
  SetRolesMutation,
  SetRolesMutationVariables
>;
export const AddBigDataDocument = gql`
  mutation AddBigData($input: AddBigDataInput!) {
    addBigData(input: $input) {
      __typename
      ... on AddBigDataResult {
        success
      }
    }
  }
`;
export type AddBigDataMutationFn = Apollo.MutationFunction<
  AddBigDataMutation,
  AddBigDataMutationVariables
>;

/**
 * __useAddBigDataMutation__
 *
 * To run a mutation, you first call `useAddBigDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBigDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBigDataMutation, { data, loading, error }] = useAddBigDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBigDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBigDataMutation,
    AddBigDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBigDataMutation, AddBigDataMutationVariables>(
    AddBigDataDocument,
    options
  );
}
export type AddBigDataMutationHookResult = ReturnType<
  typeof useAddBigDataMutation
>;
export type AddBigDataMutationResult = Apollo.MutationResult<
  AddBigDataMutation
>;
export type AddBigDataMutationOptions = Apollo.BaseMutationOptions<
  AddBigDataMutation,
  AddBigDataMutationVariables
>;
export const InitUnauthedAppDocument = gql`
  query InitUnauthedApp {
    appInfo {
      __typename
      ... on AppInfoResult {
        appInfo {
          ...AppInfo
        }
      }
    }
    authProviderInfo {
      __typename
      ... on AuthProviderInfoResult {
        providerInfo {
          ...AuthProviderInfo
        }
      }
      ... on ThirdPartyClientNotFoundError {
        message
      }
    }
    authSessionStatus {
      __typename
      ... on AuthSessionStatusResult {
        status
        user {
          ...AuthUser
        }
        userImpersonator {
          ...UserPreviewSmall
        }
      }
    }
  }
  ${AppInfoFragmentDoc}
  ${AuthProviderInfoFragmentDoc}
  ${AuthUserFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;

/**
 * __useInitUnauthedAppQuery__
 *
 * To run a query within a React component, call `useInitUnauthedAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitUnauthedAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitUnauthedAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitUnauthedAppQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InitUnauthedAppQuery,
    InitUnauthedAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InitUnauthedAppQuery, InitUnauthedAppQueryVariables>(
    InitUnauthedAppDocument,
    options
  );
}
export function useInitUnauthedAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InitUnauthedAppQuery,
    InitUnauthedAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InitUnauthedAppQuery,
    InitUnauthedAppQueryVariables
  >(InitUnauthedAppDocument, options);
}
export type InitUnauthedAppQueryHookResult = ReturnType<
  typeof useInitUnauthedAppQuery
>;
export type InitUnauthedAppLazyQueryHookResult = ReturnType<
  typeof useInitUnauthedAppLazyQuery
>;
export type InitUnauthedAppQueryResult = Apollo.QueryResult<
  InitUnauthedAppQuery,
  InitUnauthedAppQueryVariables
>;
export const SamlConnectionDocument = gql`
  query SamlConnection {
    samlConnection {
      __typename
      ... on SamlConnectionResult {
        organizationSamlInfo {
          ...OrganizationSamlInfo
        }
      }
    }
  }
  ${OrganizationSamlInfoFragmentDoc}
`;

/**
 * __useSamlConnectionQuery__
 *
 * To run a query within a React component, call `useSamlConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamlConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamlConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSamlConnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SamlConnectionQuery,
    SamlConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SamlConnectionQuery, SamlConnectionQueryVariables>(
    SamlConnectionDocument,
    options
  );
}
export function useSamlConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SamlConnectionQuery,
    SamlConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SamlConnectionQuery, SamlConnectionQueryVariables>(
    SamlConnectionDocument,
    options
  );
}
export type SamlConnectionQueryHookResult = ReturnType<
  typeof useSamlConnectionQuery
>;
export type SamlConnectionLazyQueryHookResult = ReturnType<
  typeof useSamlConnectionLazyQuery
>;
export type SamlConnectionQueryResult = Apollo.QueryResult<
  SamlConnectionQuery,
  SamlConnectionQueryVariables
>;
export const SignInMethodDocument = gql`
  query SignInMethod($input: SignInMethodInput!) {
    signInMethod(input: $input) {
      __typename
      ... on SignInMethodResult {
        signInOrganizations {
          organizationId
          organizationName
          enableSocialSignIn
          enableSAMLSignIn
        }
      }
      ... on InvalidEmailError {
        message
      }
    }
  }
`;

/**
 * __useSignInMethodQuery__
 *
 * To run a query within a React component, call `useSignInMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInMethodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    SignInMethodQuery,
    SignInMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignInMethodQuery, SignInMethodQueryVariables>(
    SignInMethodDocument,
    options
  );
}
export function useSignInMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignInMethodQuery,
    SignInMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignInMethodQuery, SignInMethodQueryVariables>(
    SignInMethodDocument,
    options
  );
}
export type SignInMethodQueryHookResult = ReturnType<
  typeof useSignInMethodQuery
>;
export type SignInMethodLazyQueryHookResult = ReturnType<
  typeof useSignInMethodLazyQuery
>;
export type SignInMethodQueryResult = Apollo.QueryResult<
  SignInMethodQuery,
  SignInMethodQueryVariables
>;
export const FirstPartyTokensDocument = gql`
  query FirstPartyTokens {
    firstPartyTokens {
      __typename
      ... on FirstPartyTokensResult {
        tokens {
          ...FirstPartyToken
        }
      }
    }
  }
  ${FirstPartyTokenFragmentDoc}
`;

/**
 * __useFirstPartyTokensQuery__
 *
 * To run a query within a React component, call `useFirstPartyTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstPartyTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstPartyTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useFirstPartyTokensQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FirstPartyTokensQuery,
    FirstPartyTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirstPartyTokensQuery, FirstPartyTokensQueryVariables>(
    FirstPartyTokensDocument,
    options
  );
}
export function useFirstPartyTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FirstPartyTokensQuery,
    FirstPartyTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FirstPartyTokensQuery,
    FirstPartyTokensQueryVariables
  >(FirstPartyTokensDocument, options);
}
export type FirstPartyTokensQueryHookResult = ReturnType<
  typeof useFirstPartyTokensQuery
>;
export type FirstPartyTokensLazyQueryHookResult = ReturnType<
  typeof useFirstPartyTokensLazyQuery
>;
export type FirstPartyTokensQueryResult = Apollo.QueryResult<
  FirstPartyTokensQuery,
  FirstPartyTokensQueryVariables
>;
export const AuthSessionStatusDocument = gql`
  query AuthSessionStatus {
    authSessionStatus {
      __typename
      ... on AuthSessionStatusResult {
        status
      }
    }
  }
`;

/**
 * __useAuthSessionStatusQuery__
 *
 * To run a query within a React component, call `useAuthSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthSessionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthSessionStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthSessionStatusQuery,
    AuthSessionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AuthSessionStatusQuery,
    AuthSessionStatusQueryVariables
  >(AuthSessionStatusDocument, options);
}
export function useAuthSessionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthSessionStatusQuery,
    AuthSessionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AuthSessionStatusQuery,
    AuthSessionStatusQueryVariables
  >(AuthSessionStatusDocument, options);
}
export type AuthSessionStatusQueryHookResult = ReturnType<
  typeof useAuthSessionStatusQuery
>;
export type AuthSessionStatusLazyQueryHookResult = ReturnType<
  typeof useAuthSessionStatusLazyQuery
>;
export type AuthSessionStatusQueryResult = Apollo.QueryResult<
  AuthSessionStatusQuery,
  AuthSessionStatusQueryVariables
>;
export const AuthCodeCallbackDocument = gql`
  mutation AuthCodeCallback($input: AuthCodeCallbackInput!) {
    authCodeCallback(input: $input) {
      __typename
      ... on AuthCodeCallbackResult {
        user {
          ...AuthUser
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
      ... on SamlLoginRequiredError {
        message
      }
      ... on UserAlreadyExistsInAnotherOrg {
        message
      }
    }
  }
  ${AuthUserFragmentDoc}
`;
export type AuthCodeCallbackMutationFn = Apollo.MutationFunction<
  AuthCodeCallbackMutation,
  AuthCodeCallbackMutationVariables
>;

/**
 * __useAuthCodeCallbackMutation__
 *
 * To run a mutation, you first call `useAuthCodeCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCodeCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCodeCallbackMutation, { data, loading, error }] = useAuthCodeCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthCodeCallbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthCodeCallbackMutation,
    AuthCodeCallbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthCodeCallbackMutation,
    AuthCodeCallbackMutationVariables
  >(AuthCodeCallbackDocument, options);
}
export type AuthCodeCallbackMutationHookResult = ReturnType<
  typeof useAuthCodeCallbackMutation
>;
export type AuthCodeCallbackMutationResult = Apollo.MutationResult<
  AuthCodeCallbackMutation
>;
export type AuthCodeCallbackMutationOptions = Apollo.BaseMutationOptions<
  AuthCodeCallbackMutation,
  AuthCodeCallbackMutationVariables
>;
export const MfaCallbackDocument = gql`
  mutation MfaCallback($input: MfaCallbackInput!) {
    mfaCallback(input: $input) {
      __typename
      ... on MfaCallbackResult {
        mfaTime
      }
    }
  }
`;
export type MfaCallbackMutationFn = Apollo.MutationFunction<
  MfaCallbackMutation,
  MfaCallbackMutationVariables
>;

/**
 * __useMfaCallbackMutation__
 *
 * To run a mutation, you first call `useMfaCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaCallbackMutation, { data, loading, error }] = useMfaCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMfaCallbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MfaCallbackMutation,
    MfaCallbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MfaCallbackMutation, MfaCallbackMutationVariables>(
    MfaCallbackDocument,
    options
  );
}
export type MfaCallbackMutationHookResult = ReturnType<
  typeof useMfaCallbackMutation
>;
export type MfaCallbackMutationResult = Apollo.MutationResult<
  MfaCallbackMutation
>;
export type MfaCallbackMutationOptions = Apollo.BaseMutationOptions<
  MfaCallbackMutation,
  MfaCallbackMutationVariables
>;
export const SignInDocument = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      __typename
      ... on SignInResult {
        state
        forceExtraStep
      }
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignOutDocument = gql`
  mutation SignOut {
    signOut {
      __typename
      ... on SignOutResult {
        success
      }
    }
  }
`;
export type SignOutMutationFn = Apollo.MutationFunction<
  SignOutMutation,
  SignOutMutationVariables
>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument,
    options
  );
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const CreateSamlConnectionDocument = gql`
  mutation CreateSamlConnection($input: CreateSamlConnectionInput!) {
    createSamlConnection(input: $input) {
      __typename
      ... on CreateSamlConnectionResult {
        organizationSamlInfo {
          ...OrganizationSamlInfo
        }
      }
      ... on InvalidEmailError {
        message
      }
    }
  }
  ${OrganizationSamlInfoFragmentDoc}
`;
export type CreateSamlConnectionMutationFn = Apollo.MutationFunction<
  CreateSamlConnectionMutation,
  CreateSamlConnectionMutationVariables
>;

/**
 * __useCreateSamlConnectionMutation__
 *
 * To run a mutation, you first call `useCreateSamlConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSamlConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSamlConnectionMutation, { data, loading, error }] = useCreateSamlConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSamlConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSamlConnectionMutation,
    CreateSamlConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSamlConnectionMutation,
    CreateSamlConnectionMutationVariables
  >(CreateSamlConnectionDocument, options);
}
export type CreateSamlConnectionMutationHookResult = ReturnType<
  typeof useCreateSamlConnectionMutation
>;
export type CreateSamlConnectionMutationResult = Apollo.MutationResult<
  CreateSamlConnectionMutation
>;
export type CreateSamlConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateSamlConnectionMutation,
  CreateSamlConnectionMutationVariables
>;
export const UpdateSamlConnectionDocument = gql`
  mutation UpdateSamlConnection($input: UpdateSamlConnectionInput!) {
    updateSamlConnection(input: $input) {
      __typename
      ... on UpdateSamlConnectionResult {
        organizationSamlInfo {
          ...OrganizationSamlInfo
        }
      }
    }
  }
  ${OrganizationSamlInfoFragmentDoc}
`;
export type UpdateSamlConnectionMutationFn = Apollo.MutationFunction<
  UpdateSamlConnectionMutation,
  UpdateSamlConnectionMutationVariables
>;

/**
 * __useUpdateSamlConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateSamlConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSamlConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSamlConnectionMutation, { data, loading, error }] = useUpdateSamlConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSamlConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSamlConnectionMutation,
    UpdateSamlConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSamlConnectionMutation,
    UpdateSamlConnectionMutationVariables
  >(UpdateSamlConnectionDocument, options);
}
export type UpdateSamlConnectionMutationHookResult = ReturnType<
  typeof useUpdateSamlConnectionMutation
>;
export type UpdateSamlConnectionMutationResult = Apollo.MutationResult<
  UpdateSamlConnectionMutation
>;
export type UpdateSamlConnectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateSamlConnectionMutation,
  UpdateSamlConnectionMutationVariables
>;
export const DeleteSamlConnectionDocument = gql`
  mutation DeleteSamlConnection {
    deleteSamlConnection {
      __typename
      ... on DeleteSamlConnectionResult {
        organizationSamlInfo {
          ...OrganizationSamlInfo
        }
      }
    }
  }
  ${OrganizationSamlInfoFragmentDoc}
`;
export type DeleteSamlConnectionMutationFn = Apollo.MutationFunction<
  DeleteSamlConnectionMutation,
  DeleteSamlConnectionMutationVariables
>;

/**
 * __useDeleteSamlConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteSamlConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSamlConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSamlConnectionMutation, { data, loading, error }] = useDeleteSamlConnectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteSamlConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSamlConnectionMutation,
    DeleteSamlConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSamlConnectionMutation,
    DeleteSamlConnectionMutationVariables
  >(DeleteSamlConnectionDocument, options);
}
export type DeleteSamlConnectionMutationHookResult = ReturnType<
  typeof useDeleteSamlConnectionMutation
>;
export type DeleteSamlConnectionMutationResult = Apollo.MutationResult<
  DeleteSamlConnectionMutation
>;
export type DeleteSamlConnectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteSamlConnectionMutation,
  DeleteSamlConnectionMutationVariables
>;
export const CreateFirstPartyTokenDocument = gql`
  mutation CreateFirstPartyToken($input: CreateFirstPartyTokenInput!) {
    createFirstPartyToken(input: $input) {
      __typename
      ... on CreateFirstPartyTokenResult {
        token {
          ...FirstPartyToken
        }
        signedToken
      }
    }
  }
  ${FirstPartyTokenFragmentDoc}
`;
export type CreateFirstPartyTokenMutationFn = Apollo.MutationFunction<
  CreateFirstPartyTokenMutation,
  CreateFirstPartyTokenMutationVariables
>;

/**
 * __useCreateFirstPartyTokenMutation__
 *
 * To run a mutation, you first call `useCreateFirstPartyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirstPartyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirstPartyTokenMutation, { data, loading, error }] = useCreateFirstPartyTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFirstPartyTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFirstPartyTokenMutation,
    CreateFirstPartyTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFirstPartyTokenMutation,
    CreateFirstPartyTokenMutationVariables
  >(CreateFirstPartyTokenDocument, options);
}
export type CreateFirstPartyTokenMutationHookResult = ReturnType<
  typeof useCreateFirstPartyTokenMutation
>;
export type CreateFirstPartyTokenMutationResult = Apollo.MutationResult<
  CreateFirstPartyTokenMutation
>;
export type CreateFirstPartyTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateFirstPartyTokenMutation,
  CreateFirstPartyTokenMutationVariables
>;
export const DeleteFirstPartyTokensDocument = gql`
  mutation DeleteFirstPartyTokens($input: DeleteFirstPartyTokensInput!) {
    deleteFirstPartyTokens(input: $input) {
      __typename
      ... on DeleteFirstPartyTokensResult {
        entries {
          ... on DeleteFirstPartyTokenEntryResult {
            id
          }
        }
      }
    }
  }
`;
export type DeleteFirstPartyTokensMutationFn = Apollo.MutationFunction<
  DeleteFirstPartyTokensMutation,
  DeleteFirstPartyTokensMutationVariables
>;

/**
 * __useDeleteFirstPartyTokensMutation__
 *
 * To run a mutation, you first call `useDeleteFirstPartyTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFirstPartyTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFirstPartyTokensMutation, { data, loading, error }] = useDeleteFirstPartyTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFirstPartyTokensMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFirstPartyTokensMutation,
    DeleteFirstPartyTokensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFirstPartyTokensMutation,
    DeleteFirstPartyTokensMutationVariables
  >(DeleteFirstPartyTokensDocument, options);
}
export type DeleteFirstPartyTokensMutationHookResult = ReturnType<
  typeof useDeleteFirstPartyTokensMutation
>;
export type DeleteFirstPartyTokensMutationResult = Apollo.MutationResult<
  DeleteFirstPartyTokensMutation
>;
export type DeleteFirstPartyTokensMutationOptions = Apollo.BaseMutationOptions<
  DeleteFirstPartyTokensMutation,
  DeleteFirstPartyTokensMutationVariables
>;
export const AssumeImpersonationDocument = gql`
  mutation AssumeImpersonation($input: AssumeImpersonationInput!) {
    assumeImpersonation(input: $input) {
      ... on AssumeImpersonationResult {
        success
      }
    }
  }
`;
export type AssumeImpersonationMutationFn = Apollo.MutationFunction<
  AssumeImpersonationMutation,
  AssumeImpersonationMutationVariables
>;

/**
 * __useAssumeImpersonationMutation__
 *
 * To run a mutation, you first call `useAssumeImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssumeImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assumeImpersonationMutation, { data, loading, error }] = useAssumeImpersonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssumeImpersonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssumeImpersonationMutation,
    AssumeImpersonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssumeImpersonationMutation,
    AssumeImpersonationMutationVariables
  >(AssumeImpersonationDocument, options);
}
export type AssumeImpersonationMutationHookResult = ReturnType<
  typeof useAssumeImpersonationMutation
>;
export type AssumeImpersonationMutationResult = Apollo.MutationResult<
  AssumeImpersonationMutation
>;
export type AssumeImpersonationMutationOptions = Apollo.BaseMutationOptions<
  AssumeImpersonationMutation,
  AssumeImpersonationMutationVariables
>;
export const StopImpersonationDocument = gql`
  mutation StopImpersonation {
    stopImpersonation {
      ... on StopImpersonationResult {
        success
      }
    }
  }
`;
export type StopImpersonationMutationFn = Apollo.MutationFunction<
  StopImpersonationMutation,
  StopImpersonationMutationVariables
>;

/**
 * __useStopImpersonationMutation__
 *
 * To run a mutation, you first call `useStopImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopImpersonationMutation, { data, loading, error }] = useStopImpersonationMutation({
 *   variables: {
 *   },
 * });
 */
export function useStopImpersonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopImpersonationMutation,
    StopImpersonationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopImpersonationMutation,
    StopImpersonationMutationVariables
  >(StopImpersonationDocument, options);
}
export type StopImpersonationMutationHookResult = ReturnType<
  typeof useStopImpersonationMutation
>;
export type StopImpersonationMutationResult = Apollo.MutationResult<
  StopImpersonationMutation
>;
export type StopImpersonationMutationOptions = Apollo.BaseMutationOptions<
  StopImpersonationMutation,
  StopImpersonationMutationVariables
>;
export const CreateAuthProviderApplicationDocument = gql`
  mutation CreateAuthProviderApplication(
    $input: CreateAuthProviderApplicationInput!
  ) {
    createAuthProviderApplication(input: $input) {
      __typename
      ... on CreateAuthProviderApplicationResult {
        providerInfo {
          ...AuthProviderInfo
        }
      }
      ... on HttpsHostnameRequiredError {
        message
      }
    }
  }
  ${AuthProviderInfoFragmentDoc}
`;
export type CreateAuthProviderApplicationMutationFn = Apollo.MutationFunction<
  CreateAuthProviderApplicationMutation,
  CreateAuthProviderApplicationMutationVariables
>;

/**
 * __useCreateAuthProviderApplicationMutation__
 *
 * To run a mutation, you first call `useCreateAuthProviderApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuthProviderApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuthProviderApplicationMutation, { data, loading, error }] = useCreateAuthProviderApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuthProviderApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAuthProviderApplicationMutation,
    CreateAuthProviderApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAuthProviderApplicationMutation,
    CreateAuthProviderApplicationMutationVariables
  >(CreateAuthProviderApplicationDocument, options);
}
export type CreateAuthProviderApplicationMutationHookResult = ReturnType<
  typeof useCreateAuthProviderApplicationMutation
>;
export type CreateAuthProviderApplicationMutationResult = Apollo.MutationResult<
  CreateAuthProviderApplicationMutation
>;
export type CreateAuthProviderApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateAuthProviderApplicationMutation,
  CreateAuthProviderApplicationMutationVariables
>;
export const AddConnectionUsersDocument = gql`
  mutation AddConnectionUsers($input: AddConnectionUsersInput!) {
    addConnectionUsers(input: $input) {
      __typename
      ... on AddConnectionUsersResult {
        taskId
        success
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ConnectionUserAlreadyExists {
        message
      }
    }
  }
`;
export type AddConnectionUsersMutationFn = Apollo.MutationFunction<
  AddConnectionUsersMutation,
  AddConnectionUsersMutationVariables
>;

/**
 * __useAddConnectionUsersMutation__
 *
 * To run a mutation, you first call `useAddConnectionUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConnectionUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConnectionUsersMutation, { data, loading, error }] = useAddConnectionUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConnectionUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddConnectionUsersMutation,
    AddConnectionUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddConnectionUsersMutation,
    AddConnectionUsersMutationVariables
  >(AddConnectionUsersDocument, options);
}
export type AddConnectionUsersMutationHookResult = ReturnType<
  typeof useAddConnectionUsersMutation
>;
export type AddConnectionUsersMutationResult = Apollo.MutationResult<
  AddConnectionUsersMutation
>;
export type AddConnectionUsersMutationOptions = Apollo.BaseMutationOptions<
  AddConnectionUsersMutation,
  AddConnectionUsersMutationVariables
>;
export const RemoveConnectionUsersDocument = gql`
  mutation RemoveConnectionUsers($input: RemoveConnectionUsersInput!) {
    removeConnectionUsers(input: $input) {
      __typename
      ... on RemoveConnectionUsersResult {
        taskId
        success
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ConnectionUserNotFound {
        message
      }
    }
  }
`;
export type RemoveConnectionUsersMutationFn = Apollo.MutationFunction<
  RemoveConnectionUsersMutation,
  RemoveConnectionUsersMutationVariables
>;

/**
 * __useRemoveConnectionUsersMutation__
 *
 * To run a mutation, you first call `useRemoveConnectionUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConnectionUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConnectionUsersMutation, { data, loading, error }] = useRemoveConnectionUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConnectionUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveConnectionUsersMutation,
    RemoveConnectionUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveConnectionUsersMutation,
    RemoveConnectionUsersMutationVariables
  >(RemoveConnectionUsersDocument, options);
}
export type RemoveConnectionUsersMutationHookResult = ReturnType<
  typeof useRemoveConnectionUsersMutation
>;
export type RemoveConnectionUsersMutationResult = Apollo.MutationResult<
  RemoveConnectionUsersMutation
>;
export type RemoveConnectionUsersMutationOptions = Apollo.BaseMutationOptions<
  RemoveConnectionUsersMutation,
  RemoveConnectionUsersMutationVariables
>;
export const ConnectionsDocument = gql`
  query Connections($input: ConnectionsInput!) {
    connections(input: $input) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionPreviewLarge
        }
      }
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
`;

/**
 * __useConnectionsQuery__
 *
 * To run a query within a React component, call `useConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionsQuery,
    ConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(
    ConnectionsDocument,
    options
  );
}
export function useConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionsQuery,
    ConnectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(
    ConnectionsDocument,
    options
  );
}
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>;
export type ConnectionsLazyQueryHookResult = ReturnType<
  typeof useConnectionsLazyQuery
>;
export type ConnectionsQueryResult = Apollo.QueryResult<
  ConnectionsQuery,
  ConnectionsQueryVariables
>;
export const ConnectionPreviewDocument = gql`
  query ConnectionPreview(
    $connectionId: ConnectionId!
    $fetchCounts: Boolean = false
  ) {
    connection(input: { id: $connectionId }) {
      __typename
      ... on ConnectionResult {
        connection {
          ...ConnectionPreview
          numVisibleResources @include(if: $fetchCounts)
          numVisibleGroups @include(if: $fetchCounts)
          importVisibilityGroups {
            ...ConnectionVisibilityGroup
          }
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
  ${ConnectionPreviewFragmentDoc}
  ${ConnectionVisibilityGroupFragmentDoc}
`;

/**
 * __useConnectionPreviewQuery__
 *
 * To run a query within a React component, call `useConnectionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionPreviewQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      fetchCounts: // value for 'fetchCounts'
 *   },
 * });
 */
export function useConnectionPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionPreviewQuery,
    ConnectionPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectionPreviewQuery,
    ConnectionPreviewQueryVariables
  >(ConnectionPreviewDocument, options);
}
export function useConnectionPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionPreviewQuery,
    ConnectionPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectionPreviewQuery,
    ConnectionPreviewQueryVariables
  >(ConnectionPreviewDocument, options);
}
export type ConnectionPreviewQueryHookResult = ReturnType<
  typeof useConnectionPreviewQuery
>;
export type ConnectionPreviewLazyQueryHookResult = ReturnType<
  typeof useConnectionPreviewLazyQuery
>;
export type ConnectionPreviewQueryResult = Apollo.QueryResult<
  ConnectionPreviewQuery,
  ConnectionPreviewQueryVariables
>;
export const CreateConnectionDocument = gql`
  mutation CreateConnection($input: CreateConnectionInput!) {
    createConnection(input: $input) {
      __typename
      ... on CreateConnectionResult {
        connection {
          id
          name
        }
      }
      ... on ConnectionExistsError {
        message
      }
      ... on ConnectionBadMetadataError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type CreateConnectionMutationFn = Apollo.MutationFunction<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>;

/**
 * __useCreateConnectionMutation__
 *
 * To run a mutation, you first call `useCreateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionMutation, { data, loading, error }] = useCreateConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectionMutation,
    CreateConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectionMutation,
    CreateConnectionMutationVariables
  >(CreateConnectionDocument, options);
}
export type CreateConnectionMutationHookResult = ReturnType<
  typeof useCreateConnectionMutation
>;
export type CreateConnectionMutationResult = Apollo.MutationResult<
  CreateConnectionMutation
>;
export type CreateConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>;
export const CreateConnectionForIdpDocument = gql`
  mutation CreateConnectionForIdp($input: CreateConnectionForIdpInput!) {
    createConnectionForIdp(input: $input) {
      __typename
      ... on CreateIdpConnectionResult {
        idpConnection {
          ...IdpConnection
        }
      }
      ... on IdpConnectionExistsError {
        message
      }
      ... on ConnectionBadMetadataError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${IdpConnectionFragmentDoc}
`;
export type CreateConnectionForIdpMutationFn = Apollo.MutationFunction<
  CreateConnectionForIdpMutation,
  CreateConnectionForIdpMutationVariables
>;

/**
 * __useCreateConnectionForIdpMutation__
 *
 * To run a mutation, you first call `useCreateConnectionForIdpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionForIdpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionForIdpMutation, { data, loading, error }] = useCreateConnectionForIdpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConnectionForIdpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConnectionForIdpMutation,
    CreateConnectionForIdpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConnectionForIdpMutation,
    CreateConnectionForIdpMutationVariables
  >(CreateConnectionForIdpDocument, options);
}
export type CreateConnectionForIdpMutationHookResult = ReturnType<
  typeof useCreateConnectionForIdpMutation
>;
export type CreateConnectionForIdpMutationResult = Apollo.MutationResult<
  CreateConnectionForIdpMutation
>;
export type CreateConnectionForIdpMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectionForIdpMutation,
  CreateConnectionForIdpMutationVariables
>;
export const UpdateConnectionDocument = gql`
  mutation UpdateConnection($input: UpdateConnectionInput!) {
    updateConnection(input: $input) {
      __typename
      ... on UpdateConnectionResult {
        connection {
          id
          name
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ConnectionBadMetadataError {
        message
      }
      ... on ConnectionVisibilityGroupNotFoundError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type UpdateConnectionMutationFn = Apollo.MutationFunction<
  UpdateConnectionMutation,
  UpdateConnectionMutationVariables
>;

/**
 * __useUpdateConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionMutation, { data, loading, error }] = useUpdateConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConnectionMutation,
    UpdateConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConnectionMutation,
    UpdateConnectionMutationVariables
  >(UpdateConnectionDocument, options);
}
export type UpdateConnectionMutationHookResult = ReturnType<
  typeof useUpdateConnectionMutation
>;
export type UpdateConnectionMutationResult = Apollo.MutationResult<
  UpdateConnectionMutation
>;
export type UpdateConnectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateConnectionMutation,
  UpdateConnectionMutationVariables
>;
export const DeleteConnectionDocument = gql`
  mutation DeleteConnection($input: DeleteConnectionInput!) {
    deleteConnection(input: $input) {
      __typename
      ... on DeleteConnectionResult {
        id
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
`;
export type DeleteConnectionMutationFn = Apollo.MutationFunction<
  DeleteConnectionMutation,
  DeleteConnectionMutationVariables
>;

/**
 * __useDeleteConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionMutation, { data, loading, error }] = useDeleteConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConnectionMutation,
    DeleteConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConnectionMutation,
    DeleteConnectionMutationVariables
  >(DeleteConnectionDocument, options);
}
export type DeleteConnectionMutationHookResult = ReturnType<
  typeof useDeleteConnectionMutation
>;
export type DeleteConnectionMutationResult = Apollo.MutationResult<
  DeleteConnectionMutation
>;
export type DeleteConnectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteConnectionMutation,
  DeleteConnectionMutationVariables
>;
export const EventFiltersDocument = gql`
  query EventFilters {
    eventFilters {
      __typename
      ... on EventFiltersResult {
        eventFilters {
          ...EventFilter
        }
      }
    }
  }
  ${EventFilterFragmentDoc}
`;

/**
 * __useEventFiltersQuery__
 *
 * To run a query within a React component, call `useEventFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventFiltersQuery,
    EventFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventFiltersQuery, EventFiltersQueryVariables>(
    EventFiltersDocument,
    options
  );
}
export function useEventFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventFiltersQuery,
    EventFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventFiltersQuery, EventFiltersQueryVariables>(
    EventFiltersDocument,
    options
  );
}
export type EventFiltersQueryHookResult = ReturnType<
  typeof useEventFiltersQuery
>;
export type EventFiltersLazyQueryHookResult = ReturnType<
  typeof useEventFiltersLazyQuery
>;
export type EventFiltersQueryResult = Apollo.QueryResult<
  EventFiltersQuery,
  EventFiltersQueryVariables
>;
export const EventFilterDocument = gql`
  query EventFilter($input: EventFilterInput!) {
    eventFilter(input: $input) {
      __typename
      ... on EventFilterResult {
        eventFilter {
          ...EventFilter
        }
      }
      ... on EventFilterNotFoundError {
        message
      }
    }
  }
  ${EventFilterFragmentDoc}
`;

/**
 * __useEventFilterQuery__
 *
 * To run a query within a React component, call `useEventFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventFilterQuery,
    EventFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventFilterQuery, EventFilterQueryVariables>(
    EventFilterDocument,
    options
  );
}
export function useEventFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventFilterQuery,
    EventFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventFilterQuery, EventFilterQueryVariables>(
    EventFilterDocument,
    options
  );
}
export type EventFilterQueryHookResult = ReturnType<typeof useEventFilterQuery>;
export type EventFilterLazyQueryHookResult = ReturnType<
  typeof useEventFilterLazyQuery
>;
export type EventFilterQueryResult = Apollo.QueryResult<
  EventFilterQuery,
  EventFilterQueryVariables
>;
export const CreateEventFilterDocument = gql`
  mutation CreateEventFilter($input: CreateEventFilterInput!) {
    createEventFilter(input: $input) {
      __typename
      ... on CreateEventFilterResult {
        eventFilter {
          ...EventFilter
        }
      }
      ... on InvalidEventFilterNameError {
        message
      }
      ... on EventFilterNotFoundError {
        message
      }
    }
  }
  ${EventFilterFragmentDoc}
`;
export type CreateEventFilterMutationFn = Apollo.MutationFunction<
  CreateEventFilterMutation,
  CreateEventFilterMutationVariables
>;

/**
 * __useCreateEventFilterMutation__
 *
 * To run a mutation, you first call `useCreateEventFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventFilterMutation, { data, loading, error }] = useCreateEventFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventFilterMutation,
    CreateEventFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventFilterMutation,
    CreateEventFilterMutationVariables
  >(CreateEventFilterDocument, options);
}
export type CreateEventFilterMutationHookResult = ReturnType<
  typeof useCreateEventFilterMutation
>;
export type CreateEventFilterMutationResult = Apollo.MutationResult<
  CreateEventFilterMutation
>;
export type CreateEventFilterMutationOptions = Apollo.BaseMutationOptions<
  CreateEventFilterMutation,
  CreateEventFilterMutationVariables
>;
export const DeleteEventFilterDocument = gql`
  mutation DeleteEventFilter($input: DeleteEventFilterInput!) {
    deleteEventFilter(input: $input) {
      __typename
      ... on DeleteEventFilterResult {
        deletedEventFilter {
          ...EventFilter
        }
      }
      ... on EventFilterNotFoundError {
        message
      }
      ... on EventFilterInUseError {
        message
        entities {
          ...ErrorEntity
        }
      }
    }
  }
  ${EventFilterFragmentDoc}
  ${ErrorEntityFragmentDoc}
`;
export type DeleteEventFilterMutationFn = Apollo.MutationFunction<
  DeleteEventFilterMutation,
  DeleteEventFilterMutationVariables
>;

/**
 * __useDeleteEventFilterMutation__
 *
 * To run a mutation, you first call `useDeleteEventFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventFilterMutation, { data, loading, error }] = useDeleteEventFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEventFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventFilterMutation,
    DeleteEventFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEventFilterMutation,
    DeleteEventFilterMutationVariables
  >(DeleteEventFilterDocument, options);
}
export type DeleteEventFilterMutationHookResult = ReturnType<
  typeof useDeleteEventFilterMutation
>;
export type DeleteEventFilterMutationResult = Apollo.MutationResult<
  DeleteEventFilterMutation
>;
export type DeleteEventFilterMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventFilterMutation,
  DeleteEventFilterMutationVariables
>;
export const EventsDocument = gql`
  query Events($input: EventsInput!) {
    events(input: $input) {
      __typename
      ... on EventsResult {
        events {
          ...EventPreview
        }
        cursor
      }
    }
  }
  ${EventPreviewFragmentDoc}
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options
  );
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options
  );
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>;
export const EventsFullDocument = gql`
  query EventsFull($input: EventsInput!) {
    events(input: $input) {
      __typename
      ... on EventsResult {
        events {
          ...Event
        }
        cursor
      }
    }
  }
  ${EventFragmentDoc}
`;

/**
 * __useEventsFullQuery__
 *
 * To run a query within a React component, call `useEventsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsFullQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsFullQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventsFullQuery,
    EventsFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsFullQuery, EventsFullQueryVariables>(
    EventsFullDocument,
    options
  );
}
export function useEventsFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsFullQuery,
    EventsFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsFullQuery, EventsFullQueryVariables>(
    EventsFullDocument,
    options
  );
}
export type EventsFullQueryHookResult = ReturnType<typeof useEventsFullQuery>;
export type EventsFullLazyQueryHookResult = ReturnType<
  typeof useEventsFullLazyQuery
>;
export type EventsFullQueryResult = Apollo.QueryResult<
  EventsFullQuery,
  EventsFullQueryVariables
>;
export const EventDocument = gql`
  query Event($input: EventInput!) {
    event(input: $input) {
      __typename
      ... on EventResult {
        event {
          ...Event
        }
      }
      ... on EventNotFoundError {
        message
      }
    }
  }
  ${EventFragmentDoc}
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options
  );
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options
  );
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<
  EventQuery,
  EventQueryVariables
>;
export const GroupAccessLevelsDocument = gql`
  query GroupAccessLevels($input: GroupAccessLevelsInput!) {
    groupAccessLevels(input: $input) {
      __typename
      ... on GroupAccessLevelsResult {
        accessLevels {
          __typename
          ... on GroupAccessLevel {
            ...GroupAccessLevel
          }
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupAccessLevelFragmentDoc}
`;

/**
 * __useGroupAccessLevelsQuery__
 *
 * To run a query within a React component, call `useGroupAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAccessLevelsQuery,
    GroupAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupAccessLevelsQuery,
    GroupAccessLevelsQueryVariables
  >(GroupAccessLevelsDocument, options);
}
export function useGroupAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAccessLevelsQuery,
    GroupAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupAccessLevelsQuery,
    GroupAccessLevelsQueryVariables
  >(GroupAccessLevelsDocument, options);
}
export type GroupAccessLevelsQueryHookResult = ReturnType<
  typeof useGroupAccessLevelsQuery
>;
export type GroupAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useGroupAccessLevelsLazyQuery
>;
export type GroupAccessLevelsQueryResult = Apollo.QueryResult<
  GroupAccessLevelsQuery,
  GroupAccessLevelsQueryVariables
>;
export const MultipleGroupAccessLevelsDocument = gql`
  query MultipleGroupAccessLevels($input: MultipleGroupAccessLevelsInput!) {
    multipleGroupAccessLevels(input: $input) {
      __typename
      ... on MultipleGroupAccessLevelsResult {
        results {
          groupId
          accessLevels {
            __typename
            ... on GroupAccessLevel {
              ...GroupAccessLevel
            }
          }
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupAccessLevelFragmentDoc}
`;

/**
 * __useMultipleGroupAccessLevelsQuery__
 *
 * To run a query within a React component, call `useMultipleGroupAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleGroupAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleGroupAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultipleGroupAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MultipleGroupAccessLevelsQuery,
    MultipleGroupAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MultipleGroupAccessLevelsQuery,
    MultipleGroupAccessLevelsQueryVariables
  >(MultipleGroupAccessLevelsDocument, options);
}
export function useMultipleGroupAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MultipleGroupAccessLevelsQuery,
    MultipleGroupAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MultipleGroupAccessLevelsQuery,
    MultipleGroupAccessLevelsQueryVariables
  >(MultipleGroupAccessLevelsDocument, options);
}
export type MultipleGroupAccessLevelsQueryHookResult = ReturnType<
  typeof useMultipleGroupAccessLevelsQuery
>;
export type MultipleGroupAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useMultipleGroupAccessLevelsLazyQuery
>;
export type MultipleGroupAccessLevelsQueryResult = Apollo.QueryResult<
  MultipleGroupAccessLevelsQuery,
  MultipleGroupAccessLevelsQueryVariables
>;
export const UseGroupBreakGlassDocument = gql`
  mutation UseGroupBreakGlass($input: UseGroupBreakGlassInput!) {
    useGroupBreakGlass(input: $input) {
      __typename
      ... on UseGroupBreakGlassResult {
        group {
          __typename
          id
          currentUserAccess {
            ...CurrentUserGroupAccess
          }
          groupUsers {
            ...GroupUser
          }
        }
      }
      ... on NoBreakGlassAccessError {
        message
      }
      ... on GroupUserAlreadyExists {
        message
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${CurrentUserGroupAccessFragmentDoc}
  ${GroupUserFragmentDoc}
`;
export type UseGroupBreakGlassMutationFn = Apollo.MutationFunction<
  UseGroupBreakGlassMutation,
  UseGroupBreakGlassMutationVariables
>;

/**
 * __useUseGroupBreakGlassMutation__
 *
 * To run a mutation, you first call `useUseGroupBreakGlassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseGroupBreakGlassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useGroupBreakGlassMutation, { data, loading, error }] = useUseGroupBreakGlassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUseGroupBreakGlassMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UseGroupBreakGlassMutation,
    UseGroupBreakGlassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UseGroupBreakGlassMutation,
    UseGroupBreakGlassMutationVariables
  >(UseGroupBreakGlassDocument, options);
}
export type UseGroupBreakGlassMutationHookResult = ReturnType<
  typeof useUseGroupBreakGlassMutation
>;
export type UseGroupBreakGlassMutationResult = Apollo.MutationResult<
  UseGroupBreakGlassMutation
>;
export type UseGroupBreakGlassMutationOptions = Apollo.BaseMutationOptions<
  UseGroupBreakGlassMutation,
  UseGroupBreakGlassMutationVariables
>;
export const AddGroupGroupsDocument = gql`
  mutation AddGroupGroups($input: AddGroupGroupsInput!) {
    addGroupGroups(input: $input) {
      __typename
      ... on AddGroupGroupsResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on GroupGroupAlreadyExists {
        message
      }
      ... on UserFacingError {
        message
      }
      ... on GroupNestingNotAllowedError {
        message
        fromGroup {
          id
          name
        }
        toGroup {
          id
          name
        }
      }
    }
  }
`;
export type AddGroupGroupsMutationFn = Apollo.MutationFunction<
  AddGroupGroupsMutation,
  AddGroupGroupsMutationVariables
>;

/**
 * __useAddGroupGroupsMutation__
 *
 * To run a mutation, you first call `useAddGroupGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupGroupsMutation, { data, loading, error }] = useAddGroupGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupGroupsMutation,
    AddGroupGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupGroupsMutation,
    AddGroupGroupsMutationVariables
  >(AddGroupGroupsDocument, options);
}
export type AddGroupGroupsMutationHookResult = ReturnType<
  typeof useAddGroupGroupsMutation
>;
export type AddGroupGroupsMutationResult = Apollo.MutationResult<
  AddGroupGroupsMutation
>;
export type AddGroupGroupsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupGroupsMutation,
  AddGroupGroupsMutationVariables
>;
export const RemoveGroupGroupsDocument = gql`
  mutation RemoveGroupGroups($input: RemoveGroupGroupsInput!) {
    removeGroupGroups(input: $input) {
      __typename
      ... on RemoveGroupGroupsResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type RemoveGroupGroupsMutationFn = Apollo.MutationFunction<
  RemoveGroupGroupsMutation,
  RemoveGroupGroupsMutationVariables
>;

/**
 * __useRemoveGroupGroupsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupGroupsMutation, { data, loading, error }] = useRemoveGroupGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupGroupsMutation,
    RemoveGroupGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupGroupsMutation,
    RemoveGroupGroupsMutationVariables
  >(RemoveGroupGroupsDocument, options);
}
export type RemoveGroupGroupsMutationHookResult = ReturnType<
  typeof useRemoveGroupGroupsMutation
>;
export type RemoveGroupGroupsMutationResult = Apollo.MutationResult<
  RemoveGroupGroupsMutation
>;
export type RemoveGroupGroupsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupGroupsMutation,
  RemoveGroupGroupsMutationVariables
>;
export const AddGroupResourcesDocument = gql`
  mutation AddGroupResources($input: AddGroupResourcesInput!) {
    addGroupResources(input: $input) {
      __typename
      ... on AddGroupResourcesResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on GroupResourceAlreadyExists {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type AddGroupResourcesMutationFn = Apollo.MutationFunction<
  AddGroupResourcesMutation,
  AddGroupResourcesMutationVariables
>;

/**
 * __useAddGroupResourcesMutation__
 *
 * To run a mutation, you first call `useAddGroupResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupResourcesMutation, { data, loading, error }] = useAddGroupResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupResourcesMutation,
    AddGroupResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupResourcesMutation,
    AddGroupResourcesMutationVariables
  >(AddGroupResourcesDocument, options);
}
export type AddGroupResourcesMutationHookResult = ReturnType<
  typeof useAddGroupResourcesMutation
>;
export type AddGroupResourcesMutationResult = Apollo.MutationResult<
  AddGroupResourcesMutation
>;
export type AddGroupResourcesMutationOptions = Apollo.BaseMutationOptions<
  AddGroupResourcesMutation,
  AddGroupResourcesMutationVariables
>;
export const RemoveGroupResourcesDocument = gql`
  mutation RemoveGroupResources($input: RemoveGroupResourcesInput!) {
    removeGroupResources(input: $input) {
      __typename
      ... on RemoveGroupResourcesResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type RemoveGroupResourcesMutationFn = Apollo.MutationFunction<
  RemoveGroupResourcesMutation,
  RemoveGroupResourcesMutationVariables
>;

/**
 * __useRemoveGroupResourcesMutation__
 *
 * To run a mutation, you first call `useRemoveGroupResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupResourcesMutation, { data, loading, error }] = useRemoveGroupResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupResourcesMutation,
    RemoveGroupResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupResourcesMutation,
    RemoveGroupResourcesMutationVariables
  >(RemoveGroupResourcesDocument, options);
}
export type RemoveGroupResourcesMutationHookResult = ReturnType<
  typeof useRemoveGroupResourcesMutation
>;
export type RemoveGroupResourcesMutationResult = Apollo.MutationResult<
  RemoveGroupResourcesMutation
>;
export type RemoveGroupResourcesMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupResourcesMutation,
  RemoveGroupResourcesMutationVariables
>;
export const AddGroupTagsDocument = gql`
  mutation AddGroupTags($input: AddGroupTagsInput!) {
    addGroupTags(input: $input) {
      __typename
      ... on AddGroupTagsResult {
        entries {
          __typename
          ... on AddGroupTagsEntryResult {
            groupTag {
              groupId
              tagId
            }
          }
        }
      }
      ... on GroupNotFoundError {
        message
      }
      ... on TagNotFoundError {
        message
      }
    }
  }
`;
export type AddGroupTagsMutationFn = Apollo.MutationFunction<
  AddGroupTagsMutation,
  AddGroupTagsMutationVariables
>;

/**
 * __useAddGroupTagsMutation__
 *
 * To run a mutation, you first call `useAddGroupTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupTagsMutation, { data, loading, error }] = useAddGroupTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupTagsMutation,
    AddGroupTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupTagsMutation,
    AddGroupTagsMutationVariables
  >(AddGroupTagsDocument, options);
}
export type AddGroupTagsMutationHookResult = ReturnType<
  typeof useAddGroupTagsMutation
>;
export type AddGroupTagsMutationResult = Apollo.MutationResult<
  AddGroupTagsMutation
>;
export type AddGroupTagsMutationOptions = Apollo.BaseMutationOptions<
  AddGroupTagsMutation,
  AddGroupTagsMutationVariables
>;
export const RemoveGroupTagsDocument = gql`
  mutation RemoveGroupTags($input: RemoveGroupTagsInput!) {
    removeGroupTags(input: $input) {
      __typename
      ... on RemoveGroupTagsResult {
        success
      }
    }
  }
`;
export type RemoveGroupTagsMutationFn = Apollo.MutationFunction<
  RemoveGroupTagsMutation,
  RemoveGroupTagsMutationVariables
>;

/**
 * __useRemoveGroupTagsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupTagsMutation, { data, loading, error }] = useRemoveGroupTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupTagsMutation,
    RemoveGroupTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupTagsMutation,
    RemoveGroupTagsMutationVariables
  >(RemoveGroupTagsDocument, options);
}
export type RemoveGroupTagsMutationHookResult = ReturnType<
  typeof useRemoveGroupTagsMutation
>;
export type RemoveGroupTagsMutationResult = Apollo.MutationResult<
  RemoveGroupTagsMutation
>;
export type RemoveGroupTagsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupTagsMutation,
  RemoveGroupTagsMutationVariables
>;
export const AddGroupUsersDocument = gql`
  mutation AddGroupUsers($input: AddGroupUsersInput!) {
    addGroupUsers(input: $input) {
      __typename
      ... on AddGroupUsersResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CannotAddSystemUserToGroupError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on GroupUserAlreadyExists {
        message
      }
    }
  }
`;
export type AddGroupUsersMutationFn = Apollo.MutationFunction<
  AddGroupUsersMutation,
  AddGroupUsersMutationVariables
>;

/**
 * __useAddGroupUsersMutation__
 *
 * To run a mutation, you first call `useAddGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupUsersMutation, { data, loading, error }] = useAddGroupUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupUsersMutation,
    AddGroupUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupUsersMutation,
    AddGroupUsersMutationVariables
  >(AddGroupUsersDocument, options);
}
export type AddGroupUsersMutationHookResult = ReturnType<
  typeof useAddGroupUsersMutation
>;
export type AddGroupUsersMutationResult = Apollo.MutationResult<
  AddGroupUsersMutation
>;
export type AddGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  AddGroupUsersMutation,
  AddGroupUsersMutationVariables
>;
export const UpdateGroupUsersDocument = gql`
  mutation UpdateGroupUsers($input: UpdateGroupUsersInput!) {
    updateGroupUsers(input: $input) {
      __typename
      ... on UpdateGroupUsersResult {
        taskIds
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
`;
export type UpdateGroupUsersMutationFn = Apollo.MutationFunction<
  UpdateGroupUsersMutation,
  UpdateGroupUsersMutationVariables
>;

/**
 * __useUpdateGroupUsersMutation__
 *
 * To run a mutation, you first call `useUpdateGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupUsersMutation, { data, loading, error }] = useUpdateGroupUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupUsersMutation,
    UpdateGroupUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupUsersMutation,
    UpdateGroupUsersMutationVariables
  >(UpdateGroupUsersDocument, options);
}
export type UpdateGroupUsersMutationHookResult = ReturnType<
  typeof useUpdateGroupUsersMutation
>;
export type UpdateGroupUsersMutationResult = Apollo.MutationResult<
  UpdateGroupUsersMutation
>;
export type UpdateGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupUsersMutation,
  UpdateGroupUsersMutationVariables
>;
export const RemoveGroupUsersDocument = gql`
  mutation RemoveGroupUsers($input: RemoveGroupUsersInput!) {
    removeGroupUsers(input: $input) {
      __typename
      ... on RemoveGroupUsersResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
    }
  }
`;
export type RemoveGroupUsersMutationFn = Apollo.MutationFunction<
  RemoveGroupUsersMutation,
  RemoveGroupUsersMutationVariables
>;

/**
 * __useRemoveGroupUsersMutation__
 *
 * To run a mutation, you first call `useRemoveGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupUsersMutation, { data, loading, error }] = useRemoveGroupUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupUsersMutation,
    RemoveGroupUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupUsersMutation,
    RemoveGroupUsersMutationVariables
  >(RemoveGroupUsersDocument, options);
}
export type RemoveGroupUsersMutationHookResult = ReturnType<
  typeof useRemoveGroupUsersMutation
>;
export type RemoveGroupUsersMutationResult = Apollo.MutationResult<
  RemoveGroupUsersMutation
>;
export type RemoveGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupUsersMutation,
  RemoveGroupUsersMutationVariables
>;
export const ForfeitGroupDocument = gql`
  mutation ForfeitGroup($input: ForfeitGroupInput!) {
    forfeitGroup(input: $input) {
      __typename
      ... on ForfeitGroupResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
`;
export type ForfeitGroupMutationFn = Apollo.MutationFunction<
  ForfeitGroupMutation,
  ForfeitGroupMutationVariables
>;

/**
 * __useForfeitGroupMutation__
 *
 * To run a mutation, you first call `useForfeitGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForfeitGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forfeitGroupMutation, { data, loading, error }] = useForfeitGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForfeitGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForfeitGroupMutation,
    ForfeitGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForfeitGroupMutation,
    ForfeitGroupMutationVariables
  >(ForfeitGroupDocument, options);
}
export type ForfeitGroupMutationHookResult = ReturnType<
  typeof useForfeitGroupMutation
>;
export type ForfeitGroupMutationResult = Apollo.MutationResult<
  ForfeitGroupMutation
>;
export type ForfeitGroupMutationOptions = Apollo.BaseMutationOptions<
  ForfeitGroupMutation,
  ForfeitGroupMutationVariables
>;
export const GroupsHomeDocument = gql`
  query GroupsHome($input: GroupsInput!) {
    groups(input: $input) {
      __typename
      ... on GroupsResult {
        groups {
          id
          name
          groupType
        }
        cursor
        totalNumGroups
      }
    }
  }
`;

/**
 * __useGroupsHomeQuery__
 *
 * To run a query within a React component, call `useGroupsHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsHomeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsHomeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupsHomeQuery,
    GroupsHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsHomeQuery, GroupsHomeQueryVariables>(
    GroupsHomeDocument,
    options
  );
}
export function useGroupsHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupsHomeQuery,
    GroupsHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsHomeQuery, GroupsHomeQueryVariables>(
    GroupsHomeDocument,
    options
  );
}
export type GroupsHomeQueryHookResult = ReturnType<typeof useGroupsHomeQuery>;
export type GroupsHomeLazyQueryHookResult = ReturnType<
  typeof useGroupsHomeLazyQuery
>;
export type GroupsHomeQueryResult = Apollo.QueryResult<
  GroupsHomeQuery,
  GroupsHomeQueryVariables
>;
export const GroupDocument = gql`
  query Group($input: GroupInput!) {
    group(input: $input) {
      __typename
      ... on GroupResult {
        group {
          ...Group
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupFragmentDoc}
`;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupQuery(
  baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupQuery, GroupQueryVariables>(
    GroupDocument,
    options
  );
}
export function useGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(
    GroupDocument,
    options
  );
}
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<
  GroupQuery,
  GroupQueryVariables
>;
export const GroupPreviewDocument = gql`
  query GroupPreview($input: GroupInput!) {
    group(input: $input) {
      __typename
      ... on GroupResult {
        group {
          ...GroupPreviewSmall
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupPreviewSmallFragmentDoc}
`;

/**
 * __useGroupPreviewQuery__
 *
 * To run a query within a React component, call `useGroupPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupPreviewQuery,
    GroupPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupPreviewQuery, GroupPreviewQueryVariables>(
    GroupPreviewDocument,
    options
  );
}
export function useGroupPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupPreviewQuery,
    GroupPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupPreviewQuery, GroupPreviewQueryVariables>(
    GroupPreviewDocument,
    options
  );
}
export type GroupPreviewQueryHookResult = ReturnType<
  typeof useGroupPreviewQuery
>;
export type GroupPreviewLazyQueryHookResult = ReturnType<
  typeof useGroupPreviewLazyQuery
>;
export type GroupPreviewQueryResult = Apollo.QueryResult<
  GroupPreviewQuery,
  GroupPreviewQueryVariables
>;
export const GroupPreviewWithAccessLevelsDocument = gql`
  query GroupPreviewWithAccessLevels($input: GroupInput!) {
    group(input: $input) {
      __typename
      ... on GroupResult {
        group {
          ...GroupPreviewWithAccessLevels
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupPreviewWithAccessLevelsFragmentDoc}
`;

/**
 * __useGroupPreviewWithAccessLevelsQuery__
 *
 * To run a query within a React component, call `useGroupPreviewWithAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPreviewWithAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPreviewWithAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupPreviewWithAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupPreviewWithAccessLevelsQuery,
    GroupPreviewWithAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupPreviewWithAccessLevelsQuery,
    GroupPreviewWithAccessLevelsQueryVariables
  >(GroupPreviewWithAccessLevelsDocument, options);
}
export function useGroupPreviewWithAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupPreviewWithAccessLevelsQuery,
    GroupPreviewWithAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupPreviewWithAccessLevelsQuery,
    GroupPreviewWithAccessLevelsQueryVariables
  >(GroupPreviewWithAccessLevelsDocument, options);
}
export type GroupPreviewWithAccessLevelsQueryHookResult = ReturnType<
  typeof useGroupPreviewWithAccessLevelsQuery
>;
export type GroupPreviewWithAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useGroupPreviewWithAccessLevelsLazyQuery
>;
export type GroupPreviewWithAccessLevelsQueryResult = Apollo.QueryResult<
  GroupPreviewWithAccessLevelsQuery,
  GroupPreviewWithAccessLevelsQueryVariables
>;
export const FilteredGroupsLargeDocument = gql`
  query FilteredGroupsLarge($input: FilteredGroupsInput!) {
    filteredGroups(input: $input) {
      __typename
      ... on FilteredGroupsResult {
        groups {
          ...GroupPreviewLarge
        }
        cursor
      }
    }
  }
  ${GroupPreviewLargeFragmentDoc}
`;

/**
 * __useFilteredGroupsLargeQuery__
 *
 * To run a query within a React component, call `useFilteredGroupsLargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredGroupsLargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredGroupsLargeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilteredGroupsLargeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilteredGroupsLargeQuery,
    FilteredGroupsLargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilteredGroupsLargeQuery,
    FilteredGroupsLargeQueryVariables
  >(FilteredGroupsLargeDocument, options);
}
export function useFilteredGroupsLargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredGroupsLargeQuery,
    FilteredGroupsLargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilteredGroupsLargeQuery,
    FilteredGroupsLargeQueryVariables
  >(FilteredGroupsLargeDocument, options);
}
export type FilteredGroupsLargeQueryHookResult = ReturnType<
  typeof useFilteredGroupsLargeQuery
>;
export type FilteredGroupsLargeLazyQueryHookResult = ReturnType<
  typeof useFilteredGroupsLargeLazyQuery
>;
export type FilteredGroupsLargeQueryResult = Apollo.QueryResult<
  FilteredGroupsLargeQuery,
  FilteredGroupsLargeQueryVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      __typename
      ... on CreateGroupResult {
        group {
          ...Group
        }
      }
      ... on GoogleGroupError {
        message
      }
      ... on OktaGroupError {
        message
      }
    }
  }
  ${GroupFragmentDoc}
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult = Apollo.MutationResult<
  CreateGroupMutation
>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      __typename
      ... on UpdateGroupResult {
        group {
          ...Group
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${GroupFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult = Apollo.MutationResult<
  UpdateGroupMutation
>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      __typename
      ... on DeleteGroupResult {
        success
      }
      ... on GroupNotFoundError {
        message
      }
      ... on GroupHasChildrenError {
        message
      }
    }
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult = Apollo.MutationResult<
  DeleteGroupMutation
>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const DeleteGroupsDocument = gql`
  mutation DeleteGroups($input: DeleteGroupsInput!) {
    deleteGroups(input: $input) {
      __typename
      ... on DeleteGroupsResult {
        success
      }
      ... on GroupNotFoundError {
        message
      }
      ... on GroupHasChildrenError {
        message
      }
    }
  }
`;
export type DeleteGroupsMutationFn = Apollo.MutationFunction<
  DeleteGroupsMutation,
  DeleteGroupsMutationVariables
>;

/**
 * __useDeleteGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupsMutation, { data, loading, error }] = useDeleteGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupsMutation,
    DeleteGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupsMutation,
    DeleteGroupsMutationVariables
  >(DeleteGroupsDocument, options);
}
export type DeleteGroupsMutationHookResult = ReturnType<
  typeof useDeleteGroupsMutation
>;
export type DeleteGroupsMutationResult = Apollo.MutationResult<
  DeleteGroupsMutation
>;
export type DeleteGroupsMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupsMutation,
  DeleteGroupsMutationVariables
>;
export const RecordGroupViewDocument = gql`
  mutation RecordGroupView($input: RecordGroupViewInput!) {
    recordGroupView(input: $input) {
      __typename
      ... on RecordGroupViewResult {
        success
      }
    }
  }
`;
export type RecordGroupViewMutationFn = Apollo.MutationFunction<
  RecordGroupViewMutation,
  RecordGroupViewMutationVariables
>;

/**
 * __useRecordGroupViewMutation__
 *
 * To run a mutation, you first call `useRecordGroupViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordGroupViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordGroupViewMutation, { data, loading, error }] = useRecordGroupViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordGroupViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecordGroupViewMutation,
    RecordGroupViewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RecordGroupViewMutation,
    RecordGroupViewMutationVariables
  >(RecordGroupViewDocument, options);
}
export type RecordGroupViewMutationHookResult = ReturnType<
  typeof useRecordGroupViewMutation
>;
export type RecordGroupViewMutationResult = Apollo.MutationResult<
  RecordGroupViewMutation
>;
export type RecordGroupViewMutationOptions = Apollo.BaseMutationOptions<
  RecordGroupViewMutation,
  RecordGroupViewMutationVariables
>;
export const IdpConnectionDocument = gql`
  query IdpConnection {
    idpConnection {
      __typename
      ... on IdpConnectionResult {
        idpConnection {
          ...IdpConnection
        }
      }
      ... on IdpConnectionNotFoundError {
        message
      }
    }
  }
  ${IdpConnectionFragmentDoc}
`;

/**
 * __useIdpConnectionQuery__
 *
 * To run a query within a React component, call `useIdpConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdpConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdpConnectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdpConnectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IdpConnectionQuery,
    IdpConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IdpConnectionQuery, IdpConnectionQueryVariables>(
    IdpConnectionDocument,
    options
  );
}
export function useIdpConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IdpConnectionQuery,
    IdpConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IdpConnectionQuery, IdpConnectionQueryVariables>(
    IdpConnectionDocument,
    options
  );
}
export type IdpConnectionQueryHookResult = ReturnType<
  typeof useIdpConnectionQuery
>;
export type IdpConnectionLazyQueryHookResult = ReturnType<
  typeof useIdpConnectionLazyQuery
>;
export type IdpConnectionQueryResult = Apollo.QueryResult<
  IdpConnectionQuery,
  IdpConnectionQueryVariables
>;
export const CreateIdpConnectionDocument = gql`
  mutation CreateIdpConnection($input: CreateIdpConnectionInput!) {
    createIdpConnection(input: $input) {
      __typename
      ... on CreateIdpConnectionResult {
        idpConnection {
          ...IdpConnection
        }
      }
      ... on IdpConnectionExistsError {
        message
      }
      ... on ConnectionBadMetadataError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${IdpConnectionFragmentDoc}
`;
export type CreateIdpConnectionMutationFn = Apollo.MutationFunction<
  CreateIdpConnectionMutation,
  CreateIdpConnectionMutationVariables
>;

/**
 * __useCreateIdpConnectionMutation__
 *
 * To run a mutation, you first call `useCreateIdpConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdpConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdpConnectionMutation, { data, loading, error }] = useCreateIdpConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIdpConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIdpConnectionMutation,
    CreateIdpConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIdpConnectionMutation,
    CreateIdpConnectionMutationVariables
  >(CreateIdpConnectionDocument, options);
}
export type CreateIdpConnectionMutationHookResult = ReturnType<
  typeof useCreateIdpConnectionMutation
>;
export type CreateIdpConnectionMutationResult = Apollo.MutationResult<
  CreateIdpConnectionMutation
>;
export type CreateIdpConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateIdpConnectionMutation,
  CreateIdpConnectionMutationVariables
>;
export const CreateIdpConnectionUserAttributeImportMappingDocument = gql`
  mutation CreateIdpConnectionUserAttributeImportMapping(
    $input: CreateIdpConnectionUserAttributeImportMappingInput!
  ) {
    createIdpConnectionUserAttributeImportMapping(input: $input) {
      __typename
      ... on IdpConnectionNotFoundError {
        message
      }
    }
  }
`;
export type CreateIdpConnectionUserAttributeImportMappingMutationFn = Apollo.MutationFunction<
  CreateIdpConnectionUserAttributeImportMappingMutation,
  CreateIdpConnectionUserAttributeImportMappingMutationVariables
>;

/**
 * __useCreateIdpConnectionUserAttributeImportMappingMutation__
 *
 * To run a mutation, you first call `useCreateIdpConnectionUserAttributeImportMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdpConnectionUserAttributeImportMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdpConnectionUserAttributeImportMappingMutation, { data, loading, error }] = useCreateIdpConnectionUserAttributeImportMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIdpConnectionUserAttributeImportMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIdpConnectionUserAttributeImportMappingMutation,
    CreateIdpConnectionUserAttributeImportMappingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIdpConnectionUserAttributeImportMappingMutation,
    CreateIdpConnectionUserAttributeImportMappingMutationVariables
  >(CreateIdpConnectionUserAttributeImportMappingDocument, options);
}
export type CreateIdpConnectionUserAttributeImportMappingMutationHookResult = ReturnType<
  typeof useCreateIdpConnectionUserAttributeImportMappingMutation
>;
export type CreateIdpConnectionUserAttributeImportMappingMutationResult = Apollo.MutationResult<
  CreateIdpConnectionUserAttributeImportMappingMutation
>;
export type CreateIdpConnectionUserAttributeImportMappingMutationOptions = Apollo.BaseMutationOptions<
  CreateIdpConnectionUserAttributeImportMappingMutation,
  CreateIdpConnectionUserAttributeImportMappingMutationVariables
>;
export const DeleteIdpConnectionUserAttributeImportMappingDocument = gql`
  mutation DeleteIdpConnectionUserAttributeImportMapping(
    $input: DeleteIdpConnectionUserAttributeImportMappingInput!
  ) {
    deleteIdpConnectionUserAttributeImportMapping(input: $input) {
      __typename
      ... on IdpConnectionUserAttributeImportMappingNotFoundError {
        message
      }
    }
  }
`;
export type DeleteIdpConnectionUserAttributeImportMappingMutationFn = Apollo.MutationFunction<
  DeleteIdpConnectionUserAttributeImportMappingMutation,
  DeleteIdpConnectionUserAttributeImportMappingMutationVariables
>;

/**
 * __useDeleteIdpConnectionUserAttributeImportMappingMutation__
 *
 * To run a mutation, you first call `useDeleteIdpConnectionUserAttributeImportMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIdpConnectionUserAttributeImportMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIdpConnectionUserAttributeImportMappingMutation, { data, loading, error }] = useDeleteIdpConnectionUserAttributeImportMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIdpConnectionUserAttributeImportMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIdpConnectionUserAttributeImportMappingMutation,
    DeleteIdpConnectionUserAttributeImportMappingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteIdpConnectionUserAttributeImportMappingMutation,
    DeleteIdpConnectionUserAttributeImportMappingMutationVariables
  >(DeleteIdpConnectionUserAttributeImportMappingDocument, options);
}
export type DeleteIdpConnectionUserAttributeImportMappingMutationHookResult = ReturnType<
  typeof useDeleteIdpConnectionUserAttributeImportMappingMutation
>;
export type DeleteIdpConnectionUserAttributeImportMappingMutationResult = Apollo.MutationResult<
  DeleteIdpConnectionUserAttributeImportMappingMutation
>;
export type DeleteIdpConnectionUserAttributeImportMappingMutationOptions = Apollo.BaseMutationOptions<
  DeleteIdpConnectionUserAttributeImportMappingMutation,
  DeleteIdpConnectionUserAttributeImportMappingMutationVariables
>;
export const DeleteIdpConnectionDocument = gql`
  mutation DeleteIdpConnection($input: DeleteIdpConnectionInput!) {
    deleteIdpConnection(input: $input) {
      __typename
      ... on DeleteIdpConnectionResult {
        id
      }
      ... on IdpConnectionNotFoundError {
        message
      }
    }
  }
`;
export type DeleteIdpConnectionMutationFn = Apollo.MutationFunction<
  DeleteIdpConnectionMutation,
  DeleteIdpConnectionMutationVariables
>;

/**
 * __useDeleteIdpConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteIdpConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIdpConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIdpConnectionMutation, { data, loading, error }] = useDeleteIdpConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteIdpConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIdpConnectionMutation,
    DeleteIdpConnectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteIdpConnectionMutation,
    DeleteIdpConnectionMutationVariables
  >(DeleteIdpConnectionDocument, options);
}
export type DeleteIdpConnectionMutationHookResult = ReturnType<
  typeof useDeleteIdpConnectionMutation
>;
export type DeleteIdpConnectionMutationResult = Apollo.MutationResult<
  DeleteIdpConnectionMutation
>;
export type DeleteIdpConnectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteIdpConnectionMutation,
  DeleteIdpConnectionMutationVariables
>;
export const MessageChannelsDocument = gql`
  query MessageChannels($input: MessageChannelsInput!) {
    messageChannels(input: $input) {
      __typename
      ... on MessageChannelsResult {
        messageChannels {
          ...MessageChannel
        }
        generatedChannelName
      }
      ... on MessageChannelMissingOAuthScopeError {
        message
      }
      ... on MessageChannelRateLimitedError {
        message
      }
    }
  }
  ${MessageChannelFragmentDoc}
`;

/**
 * __useMessageChannelsQuery__
 *
 * To run a query within a React component, call `useMessageChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageChannelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageChannelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessageChannelsQuery,
    MessageChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessageChannelsQuery, MessageChannelsQueryVariables>(
    MessageChannelsDocument,
    options
  );
}
export function useMessageChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessageChannelsQuery,
    MessageChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MessageChannelsQuery,
    MessageChannelsQueryVariables
  >(MessageChannelsDocument, options);
}
export type MessageChannelsQueryHookResult = ReturnType<
  typeof useMessageChannelsQuery
>;
export type MessageChannelsLazyQueryHookResult = ReturnType<
  typeof useMessageChannelsLazyQuery
>;
export type MessageChannelsQueryResult = Apollo.QueryResult<
  MessageChannelsQuery,
  MessageChannelsQueryVariables
>;
export const CreateRemoteMessageChannelDocument = gql`
  mutation CreateRemoteMessageChannel(
    $input: CreateRemoteMessageChannelInput!
  ) {
    createRemoteMessageChannel(input: $input) {
      __typename
      ... on CreateRemoteMessageChannelResult {
        messageChannel {
          ...MessageChannel
        }
      }
      ... on MessageChannelMissingOAuthScopeError {
        message
      }
      ... on MessageChannelUserNotInWorkspaceError {
        message
      }
      ... on MessageChannelNameTakenError {
        message
      }
      ... on MessageChannelInvalidNameError {
        message
      }
    }
  }
  ${MessageChannelFragmentDoc}
`;
export type CreateRemoteMessageChannelMutationFn = Apollo.MutationFunction<
  CreateRemoteMessageChannelMutation,
  CreateRemoteMessageChannelMutationVariables
>;

/**
 * __useCreateRemoteMessageChannelMutation__
 *
 * To run a mutation, you first call `useCreateRemoteMessageChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRemoteMessageChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRemoteMessageChannelMutation, { data, loading, error }] = useCreateRemoteMessageChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRemoteMessageChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRemoteMessageChannelMutation,
    CreateRemoteMessageChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRemoteMessageChannelMutation,
    CreateRemoteMessageChannelMutationVariables
  >(CreateRemoteMessageChannelDocument, options);
}
export type CreateRemoteMessageChannelMutationHookResult = ReturnType<
  typeof useCreateRemoteMessageChannelMutation
>;
export type CreateRemoteMessageChannelMutationResult = Apollo.MutationResult<
  CreateRemoteMessageChannelMutation
>;
export type CreateRemoteMessageChannelMutationOptions = Apollo.BaseMutationOptions<
  CreateRemoteMessageChannelMutation,
  CreateRemoteMessageChannelMutationVariables
>;
export const LinkExistingRemoteMessageChannelDocument = gql`
  mutation LinkExistingRemoteMessageChannel(
    $input: LinkExistingRemoteMessageChannelInput!
  ) {
    linkExistingRemoteMessageChannel(input: $input) {
      __typename
      ... on LinkExistingRemoteMessageChannelResult {
        messageChannel {
          ...MessageChannel
        }
      }
      ... on MessageChannelMissingOAuthScopeError {
        message
      }
      ... on RemoteMessageChannelNotFound {
        message
      }
      ... on RemoteMessageChannelAlreadyLinked {
        message
      }
    }
  }
  ${MessageChannelFragmentDoc}
`;
export type LinkExistingRemoteMessageChannelMutationFn = Apollo.MutationFunction<
  LinkExistingRemoteMessageChannelMutation,
  LinkExistingRemoteMessageChannelMutationVariables
>;

/**
 * __useLinkExistingRemoteMessageChannelMutation__
 *
 * To run a mutation, you first call `useLinkExistingRemoteMessageChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkExistingRemoteMessageChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkExistingRemoteMessageChannelMutation, { data, loading, error }] = useLinkExistingRemoteMessageChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkExistingRemoteMessageChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkExistingRemoteMessageChannelMutation,
    LinkExistingRemoteMessageChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkExistingRemoteMessageChannelMutation,
    LinkExistingRemoteMessageChannelMutationVariables
  >(LinkExistingRemoteMessageChannelDocument, options);
}
export type LinkExistingRemoteMessageChannelMutationHookResult = ReturnType<
  typeof useLinkExistingRemoteMessageChannelMutation
>;
export type LinkExistingRemoteMessageChannelMutationResult = Apollo.MutationResult<
  LinkExistingRemoteMessageChannelMutation
>;
export type LinkExistingRemoteMessageChannelMutationOptions = Apollo.BaseMutationOptions<
  LinkExistingRemoteMessageChannelMutation,
  LinkExistingRemoteMessageChannelMutationVariables
>;
export const ListFactorsDocument = gql`
  query ListFactors {
    listFactors {
      __typename
      ... on ListFactorsResult {
        factors {
          ...Factor
        }
      }
      ... on NoIDPConfiguredError {
        message
      }
      ... on ConnectionUserNotFound {
        message
      }
      ... on MissingTokenPermissionError {
        message
      }
    }
  }
  ${FactorFragmentDoc}
`;

/**
 * __useListFactorsQuery__
 *
 * To run a query within a React component, call `useListFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFactorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFactorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFactorsQuery,
    ListFactorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFactorsQuery, ListFactorsQueryVariables>(
    ListFactorsDocument,
    options
  );
}
export function useListFactorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFactorsQuery,
    ListFactorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFactorsQuery, ListFactorsQueryVariables>(
    ListFactorsDocument,
    options
  );
}
export type ListFactorsQueryHookResult = ReturnType<typeof useListFactorsQuery>;
export type ListFactorsLazyQueryHookResult = ReturnType<
  typeof useListFactorsLazyQuery
>;
export type ListFactorsQueryResult = Apollo.QueryResult<
  ListFactorsQuery,
  ListFactorsQueryVariables
>;
export const VerifyFactorSyncDocument = gql`
  mutation VerifyFactorSync($input: VerifyFactorSyncInput!) {
    verifyFactorSync(input: $input) {
      __typename
      ... on VerifyFactorSyncResult {
        status
      }
      ... on FactorNotFoundError {
        message
      }
      ... on ConnectionUserNotFound {
        message
      }
      ... on MissingTokenPermissionError {
        message
      }
    }
  }
`;
export type VerifyFactorSyncMutationFn = Apollo.MutationFunction<
  VerifyFactorSyncMutation,
  VerifyFactorSyncMutationVariables
>;

/**
 * __useVerifyFactorSyncMutation__
 *
 * To run a mutation, you first call `useVerifyFactorSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyFactorSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyFactorSyncMutation, { data, loading, error }] = useVerifyFactorSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyFactorSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyFactorSyncMutation,
    VerifyFactorSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyFactorSyncMutation,
    VerifyFactorSyncMutationVariables
  >(VerifyFactorSyncDocument, options);
}
export type VerifyFactorSyncMutationHookResult = ReturnType<
  typeof useVerifyFactorSyncMutation
>;
export type VerifyFactorSyncMutationResult = Apollo.MutationResult<
  VerifyFactorSyncMutation
>;
export type VerifyFactorSyncMutationOptions = Apollo.BaseMutationOptions<
  VerifyFactorSyncMutation,
  VerifyFactorSyncMutationVariables
>;
export const VerifyFactorAsyncDocument = gql`
  mutation VerifyFactorAsync($input: VerifyFactorAsyncInput!) {
    verifyFactorAsync(input: $input) {
      __typename
      ... on VerifyFactorAsyncResult {
        status
        transactionId
      }
      ... on FactorNotFoundError {
        message
      }
      ... on ConnectionUserNotFound {
        message
      }
      ... on MissingTokenPermissionError {
        message
      }
    }
  }
`;
export type VerifyFactorAsyncMutationFn = Apollo.MutationFunction<
  VerifyFactorAsyncMutation,
  VerifyFactorAsyncMutationVariables
>;

/**
 * __useVerifyFactorAsyncMutation__
 *
 * To run a mutation, you first call `useVerifyFactorAsyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyFactorAsyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyFactorAsyncMutation, { data, loading, error }] = useVerifyFactorAsyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyFactorAsyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyFactorAsyncMutation,
    VerifyFactorAsyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyFactorAsyncMutation,
    VerifyFactorAsyncMutationVariables
  >(VerifyFactorAsyncDocument, options);
}
export type VerifyFactorAsyncMutationHookResult = ReturnType<
  typeof useVerifyFactorAsyncMutation
>;
export type VerifyFactorAsyncMutationResult = Apollo.MutationResult<
  VerifyFactorAsyncMutation
>;
export type VerifyFactorAsyncMutationOptions = Apollo.BaseMutationOptions<
  VerifyFactorAsyncMutation,
  VerifyFactorAsyncMutationVariables
>;
export const NotificationsDocument = gql`
  query Notifications {
    uiNotifications {
      id
      createdAt
      body
      url
      readAt
      trackingId
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const ReadUiNotificationDocument = gql`
  mutation ReadUINotification($input: ReadUINotificationInput!) {
    readUINotification(input: $input) {
      ... on ReadUINotificationResult {
        success
      }
    }
  }
`;
export type ReadUiNotificationMutationFn = Apollo.MutationFunction<
  ReadUiNotificationMutation,
  ReadUiNotificationMutationVariables
>;

/**
 * __useReadUiNotificationMutation__
 *
 * To run a mutation, you first call `useReadUiNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadUiNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readUiNotificationMutation, { data, loading, error }] = useReadUiNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadUiNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadUiNotificationMutation,
    ReadUiNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadUiNotificationMutation,
    ReadUiNotificationMutationVariables
  >(ReadUiNotificationDocument, options);
}
export type ReadUiNotificationMutationHookResult = ReturnType<
  typeof useReadUiNotificationMutation
>;
export type ReadUiNotificationMutationResult = Apollo.MutationResult<
  ReadUiNotificationMutation
>;
export type ReadUiNotificationMutationOptions = Apollo.BaseMutationOptions<
  ReadUiNotificationMutation,
  ReadUiNotificationMutationVariables
>;
export const ReadUiNotificationsDocument = gql`
  mutation ReadUINotifications($input: ReadUINotificationsInput!) {
    readUINotifications(input: $input) {
      ... on ReadUINotificationsResult {
        success
      }
    }
  }
`;
export type ReadUiNotificationsMutationFn = Apollo.MutationFunction<
  ReadUiNotificationsMutation,
  ReadUiNotificationsMutationVariables
>;

/**
 * __useReadUiNotificationsMutation__
 *
 * To run a mutation, you first call `useReadUiNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadUiNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readUiNotificationsMutation, { data, loading, error }] = useReadUiNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadUiNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadUiNotificationsMutation,
    ReadUiNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadUiNotificationsMutation,
    ReadUiNotificationsMutationVariables
  >(ReadUiNotificationsDocument, options);
}
export type ReadUiNotificationsMutationHookResult = ReturnType<
  typeof useReadUiNotificationsMutation
>;
export type ReadUiNotificationsMutationResult = Apollo.MutationResult<
  ReadUiNotificationsMutation
>;
export type ReadUiNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ReadUiNotificationsMutation,
  ReadUiNotificationsMutationVariables
>;
export const OnCallSchedulesDocument = gql`
  query OnCallSchedules {
    onCallSchedules {
      __typename
      ... on OnCallSchedulesResult {
        onCallSchedules {
          ...OnCallSchedule
        }
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${OnCallScheduleFragmentDoc}
`;

/**
 * __useOnCallSchedulesQuery__
 *
 * To run a query within a React component, call `useOnCallSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnCallSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCallSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnCallSchedulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnCallSchedulesQuery,
    OnCallSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnCallSchedulesQuery, OnCallSchedulesQueryVariables>(
    OnCallSchedulesDocument,
    options
  );
}
export function useOnCallSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnCallSchedulesQuery,
    OnCallSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OnCallSchedulesQuery,
    OnCallSchedulesQueryVariables
  >(OnCallSchedulesDocument, options);
}
export type OnCallSchedulesQueryHookResult = ReturnType<
  typeof useOnCallSchedulesQuery
>;
export type OnCallSchedulesLazyQueryHookResult = ReturnType<
  typeof useOnCallSchedulesLazyQuery
>;
export type OnCallSchedulesQueryResult = Apollo.QueryResult<
  OnCallSchedulesQuery,
  OnCallSchedulesQueryVariables
>;
export const OrganizationSettingsDocument = gql`
  query OrganizationSettings {
    organizationSettings {
      __typename
      ... on OrganizationSettingsResult {
        settings {
          ...OrganizationSettings
        }
      }
    }
  }
  ${OrganizationSettingsFragmentDoc}
`;

/**
 * __useOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationSettingsQuery,
    OrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationSettingsQuery,
    OrganizationSettingsQueryVariables
  >(OrganizationSettingsDocument, options);
}
export function useOrganizationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationSettingsQuery,
    OrganizationSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationSettingsQuery,
    OrganizationSettingsQueryVariables
  >(OrganizationSettingsDocument, options);
}
export type OrganizationSettingsQueryHookResult = ReturnType<
  typeof useOrganizationSettingsQuery
>;
export type OrganizationSettingsLazyQueryHookResult = ReturnType<
  typeof useOrganizationSettingsLazyQuery
>;
export type OrganizationSettingsQueryResult = Apollo.QueryResult<
  OrganizationSettingsQuery,
  OrganizationSettingsQueryVariables
>;
export const UpdateOrganizationSettingsDocument = gql`
  mutation UpdateOrganizationSettings(
    $input: UpdateOrganizationSettingsInput!
  ) {
    updateOrganizationSettings(input: $input) {
      __typename
      ... on UpdateOrganizationSettingsResult {
        settings {
          ...OrganizationSettings
        }
      }
      ... on OrgMaxResourceDurationInvalidError {
        message
      }
      ... on OrgMaxGroupDurationInvalidError {
        message
      }
      ... on OidcIssuerNotValidError {
        message
      }
      ... on AccessExpiringNotificationsInvalidError {
        message
      }
      ... on CustomerSupportSettingsInvalid {
        message
      }
    }
  }
  ${OrganizationSettingsFragmentDoc}
`;
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSettingsMutation,
  UpdateOrganizationSettingsMutationVariables
>;

/**
 * __useUpdateOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingsMutation, { data, loading, error }] = useUpdateOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationSettingsMutation,
    UpdateOrganizationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrganizationSettingsMutation,
    UpdateOrganizationSettingsMutationVariables
  >(UpdateOrganizationSettingsDocument, options);
}
export type UpdateOrganizationSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationSettingsMutation
>;
export type UpdateOrganizationSettingsMutationResult = Apollo.MutationResult<
  UpdateOrganizationSettingsMutation
>;
export type UpdateOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSettingsMutation,
  UpdateOrganizationSettingsMutationVariables
>;
export const WebhookInfoDocument = gql`
  query WebhookInfo {
    webhookInfo {
      __typename
      ... on WebhookInfoResult {
        webhookInfo {
          ...OrganizationWebhookInfo
        }
      }
    }
  }
  ${OrganizationWebhookInfoFragmentDoc}
`;

/**
 * __useWebhookInfoQuery__
 *
 * To run a query within a React component, call `useWebhookInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebhookInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WebhookInfoQuery,
    WebhookInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebhookInfoQuery, WebhookInfoQueryVariables>(
    WebhookInfoDocument,
    options
  );
}
export function useWebhookInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebhookInfoQuery,
    WebhookInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebhookInfoQuery, WebhookInfoQueryVariables>(
    WebhookInfoDocument,
    options
  );
}
export type WebhookInfoQueryHookResult = ReturnType<typeof useWebhookInfoQuery>;
export type WebhookInfoLazyQueryHookResult = ReturnType<
  typeof useWebhookInfoLazyQuery
>;
export type WebhookInfoQueryResult = Apollo.QueryResult<
  WebhookInfoQuery,
  WebhookInfoQueryVariables
>;
export const CreateWebhookInfoDocument = gql`
  mutation CreateWebhookInfo($input: CreateWebhookInfoInput!) {
    createWebhookInfo(input: $input) {
      __typename
      ... on CreateWebhookInfoResult {
        webhookInfo {
          ...OrganizationWebhookInfo
        }
      }
    }
  }
  ${OrganizationWebhookInfoFragmentDoc}
`;
export type CreateWebhookInfoMutationFn = Apollo.MutationFunction<
  CreateWebhookInfoMutation,
  CreateWebhookInfoMutationVariables
>;

/**
 * __useCreateWebhookInfoMutation__
 *
 * To run a mutation, you first call `useCreateWebhookInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookInfoMutation, { data, loading, error }] = useCreateWebhookInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWebhookInfoMutation,
    CreateWebhookInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWebhookInfoMutation,
    CreateWebhookInfoMutationVariables
  >(CreateWebhookInfoDocument, options);
}
export type CreateWebhookInfoMutationHookResult = ReturnType<
  typeof useCreateWebhookInfoMutation
>;
export type CreateWebhookInfoMutationResult = Apollo.MutationResult<
  CreateWebhookInfoMutation
>;
export type CreateWebhookInfoMutationOptions = Apollo.BaseMutationOptions<
  CreateWebhookInfoMutation,
  CreateWebhookInfoMutationVariables
>;
export const UpdateWebhookInfoDocument = gql`
  mutation UpdateWebhookInfo($input: UpdateWebhookInfoInput!) {
    updateWebhookInfo(input: $input) {
      __typename
      ... on UpdateWebhookInfoResult {
        webhookInfo {
          ...OrganizationWebhookInfo
        }
      }
    }
  }
  ${OrganizationWebhookInfoFragmentDoc}
`;
export type UpdateWebhookInfoMutationFn = Apollo.MutationFunction<
  UpdateWebhookInfoMutation,
  UpdateWebhookInfoMutationVariables
>;

/**
 * __useUpdateWebhookInfoMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookInfoMutation, { data, loading, error }] = useUpdateWebhookInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebhookInfoMutation,
    UpdateWebhookInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWebhookInfoMutation,
    UpdateWebhookInfoMutationVariables
  >(UpdateWebhookInfoDocument, options);
}
export type UpdateWebhookInfoMutationHookResult = ReturnType<
  typeof useUpdateWebhookInfoMutation
>;
export type UpdateWebhookInfoMutationResult = Apollo.MutationResult<
  UpdateWebhookInfoMutation
>;
export type UpdateWebhookInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebhookInfoMutation,
  UpdateWebhookInfoMutationVariables
>;
export const RegenerateSigningSecretDocument = gql`
  mutation RegenerateSigningSecret {
    regenerateSigningSecret {
      __typename
      ... on RegenerateSigningSecretResult {
        webhookInfo {
          ...OrganizationWebhookInfo
        }
      }
    }
  }
  ${OrganizationWebhookInfoFragmentDoc}
`;
export type RegenerateSigningSecretMutationFn = Apollo.MutationFunction<
  RegenerateSigningSecretMutation,
  RegenerateSigningSecretMutationVariables
>;

/**
 * __useRegenerateSigningSecretMutation__
 *
 * To run a mutation, you first call `useRegenerateSigningSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateSigningSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateSigningSecretMutation, { data, loading, error }] = useRegenerateSigningSecretMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerateSigningSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateSigningSecretMutation,
    RegenerateSigningSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateSigningSecretMutation,
    RegenerateSigningSecretMutationVariables
  >(RegenerateSigningSecretDocument, options);
}
export type RegenerateSigningSecretMutationHookResult = ReturnType<
  typeof useRegenerateSigningSecretMutation
>;
export type RegenerateSigningSecretMutationResult = Apollo.MutationResult<
  RegenerateSigningSecretMutation
>;
export type RegenerateSigningSecretMutationOptions = Apollo.BaseMutationOptions<
  RegenerateSigningSecretMutation,
  RegenerateSigningSecretMutationVariables
>;
export const DeleteWebhookInfoDocument = gql`
  mutation DeleteWebhookInfo {
    deleteWebhookInfo {
      __typename
      ... on DeleteWebhookInfoResult {
        success
      }
    }
  }
`;
export type DeleteWebhookInfoMutationFn = Apollo.MutationFunction<
  DeleteWebhookInfoMutation,
  DeleteWebhookInfoMutationVariables
>;

/**
 * __useDeleteWebhookInfoMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookInfoMutation, { data, loading, error }] = useDeleteWebhookInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteWebhookInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWebhookInfoMutation,
    DeleteWebhookInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWebhookInfoMutation,
    DeleteWebhookInfoMutationVariables
  >(DeleteWebhookInfoDocument, options);
}
export type DeleteWebhookInfoMutationHookResult = ReturnType<
  typeof useDeleteWebhookInfoMutation
>;
export type DeleteWebhookInfoMutationResult = Apollo.MutationResult<
  DeleteWebhookInfoMutation
>;
export type DeleteWebhookInfoMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebhookInfoMutation,
  DeleteWebhookInfoMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      __typename
      ... on CreateOrganizationResult {
        organization {
          ...Organization
        }
      }
      ... on OrganizationExistsError {
        message
      }
    }
  }
  ${OrganizationFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrganizationMutation
>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<
  CreateOrganizationMutation
>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const DeleteOrganizationDocument = gql`
  mutation DeleteOrganization {
    deleteOrganization {
      __typename
      ... on DeleteOrganizationResult {
        success
      }
    }
  }
`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationMutation,
    DeleteOrganizationMutationVariables
  >(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationHookResult = ReturnType<
  typeof useDeleteOrganizationMutation
>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<
  DeleteOrganizationMutation
>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const OwnersListDocument = gql`
  query OwnersList($input: OwnersInput!) {
    owners(input: $input) {
      __typename
      ... on OwnersResult {
        owners {
          ...OwnerPreviewSmall
        }
        totalNumOwners
        cursor
      }
    }
  }
  ${OwnerPreviewSmallFragmentDoc}
`;

/**
 * __useOwnersListQuery__
 *
 * To run a query within a React component, call `useOwnersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnersListQuery,
    OwnersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnersListQuery, OwnersListQueryVariables>(
    OwnersListDocument,
    options
  );
}
export function useOwnersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnersListQuery,
    OwnersListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnersListQuery, OwnersListQueryVariables>(
    OwnersListDocument,
    options
  );
}
export type OwnersListQueryHookResult = ReturnType<typeof useOwnersListQuery>;
export type OwnersListLazyQueryHookResult = ReturnType<
  typeof useOwnersListLazyQuery
>;
export type OwnersListQueryResult = Apollo.QueryResult<
  OwnersListQuery,
  OwnersListQueryVariables
>;
export const OwnerDocument = gql`
  query Owner($input: OwnerInput!) {
    owner(input: $input) {
      __typename
      ... on OwnerResult {
        owner {
          ...Owner
        }
      }
    }
  }
  ${OwnerFragmentDoc}
`;

/**
 * __useOwnerQuery__
 *
 * To run a query within a React component, call `useOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<OwnerQuery, OwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnerQuery, OwnerQueryVariables>(
    OwnerDocument,
    options
  );
}
export function useOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OwnerQuery, OwnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnerQuery, OwnerQueryVariables>(
    OwnerDocument,
    options
  );
}
export type OwnerQueryHookResult = ReturnType<typeof useOwnerQuery>;
export type OwnerLazyQueryHookResult = ReturnType<typeof useOwnerLazyQuery>;
export type OwnerQueryResult = Apollo.QueryResult<
  OwnerQuery,
  OwnerQueryVariables
>;
export const CreateOwnerDocument = gql`
  mutation CreateOwner($input: CreateOwnerInput!) {
    createOwner(input: $input) {
      __typename
      ... on CreateOwnerResult {
        owner {
          ...OwnerPreviewSmall
        }
      }
      ... on OwnerNameAlreadyExists {
        message
      }
      ... on CannotAddUserToSyncedOwnerError {
        message
        group {
          id
          name
        }
      }
    }
  }
  ${OwnerPreviewSmallFragmentDoc}
`;
export type CreateOwnerMutationFn = Apollo.MutationFunction<
  CreateOwnerMutation,
  CreateOwnerMutationVariables
>;

/**
 * __useCreateOwnerMutation__
 *
 * To run a mutation, you first call `useCreateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOwnerMutation, { data, loading, error }] = useCreateOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOwnerMutation,
    CreateOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOwnerMutation, CreateOwnerMutationVariables>(
    CreateOwnerDocument,
    options
  );
}
export type CreateOwnerMutationHookResult = ReturnType<
  typeof useCreateOwnerMutation
>;
export type CreateOwnerMutationResult = Apollo.MutationResult<
  CreateOwnerMutation
>;
export type CreateOwnerMutationOptions = Apollo.BaseMutationOptions<
  CreateOwnerMutation,
  CreateOwnerMutationVariables
>;
export const UpdateOwnerDocument = gql`
  mutation UpdateOwner($input: UpdateOwnerInput!) {
    updateOwner(input: $input) {
      __typename
      ... on UpdateOwnerResult {
        owner {
          ...OwnerPreviewSmall
        }
      }
      ... on CannotAddUserToSyncedOwnerError {
        message
        group {
          id
          name
        }
      }
    }
  }
  ${OwnerPreviewSmallFragmentDoc}
`;
export type UpdateOwnerMutationFn = Apollo.MutationFunction<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;

/**
 * __useUpdateOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerMutation, { data, loading, error }] = useUpdateOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerMutation,
    UpdateOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOwnerMutation, UpdateOwnerMutationVariables>(
    UpdateOwnerDocument,
    options
  );
}
export type UpdateOwnerMutationHookResult = ReturnType<
  typeof useUpdateOwnerMutation
>;
export type UpdateOwnerMutationResult = Apollo.MutationResult<
  UpdateOwnerMutation
>;
export type UpdateOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables
>;
export const DeleteOwnerDocument = gql`
  mutation DeleteOwner($input: DeleteOwnerInput!) {
    deleteOwner(input: $input) {
      __typename
      ... on DeleteOwnerResult {
        owner {
          ...OwnerPreviewSmall
        }
      }
      ... on OwnerOwnsItemsError {
        message
        entities {
          name
        }
      }
      ... on OwnerReviewsItemsError {
        message
        entities {
          name
        }
      }
    }
  }
  ${OwnerPreviewSmallFragmentDoc}
`;
export type DeleteOwnerMutationFn = Apollo.MutationFunction<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;

/**
 * __useDeleteOwnerMutation__
 *
 * To run a mutation, you first call `useDeleteOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOwnerMutation, { data, loading, error }] = useDeleteOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOwnerMutation,
    DeleteOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOwnerMutation, DeleteOwnerMutationVariables>(
    DeleteOwnerDocument,
    options
  );
}
export type DeleteOwnerMutationHookResult = ReturnType<
  typeof useDeleteOwnerMutation
>;
export type DeleteOwnerMutationResult = Apollo.MutationResult<
  DeleteOwnerMutation
>;
export type DeleteOwnerMutationOptions = Apollo.BaseMutationOptions<
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables
>;
export const UpdateOwnerUsersForOwnerDocument = gql`
  mutation UpdateOwnerUsersForOwner($input: SetOwnerUsersInput!) {
    setOwnerUsers(input: $input) {
      __typename
      ... on UpdateOwnerUsersForOwnerResult {
        ownerUsers {
          ...OwnerUser
        }
      }
      ... on CannotAddUserToSyncedOwnerError {
        message
        group {
          id
          name
        }
      }
    }
  }
  ${OwnerUserFragmentDoc}
`;
export type UpdateOwnerUsersForOwnerMutationFn = Apollo.MutationFunction<
  UpdateOwnerUsersForOwnerMutation,
  UpdateOwnerUsersForOwnerMutationVariables
>;

/**
 * __useUpdateOwnerUsersForOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateOwnerUsersForOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOwnerUsersForOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOwnerUsersForOwnerMutation, { data, loading, error }] = useUpdateOwnerUsersForOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOwnerUsersForOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOwnerUsersForOwnerMutation,
    UpdateOwnerUsersForOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOwnerUsersForOwnerMutation,
    UpdateOwnerUsersForOwnerMutationVariables
  >(UpdateOwnerUsersForOwnerDocument, options);
}
export type UpdateOwnerUsersForOwnerMutationHookResult = ReturnType<
  typeof useUpdateOwnerUsersForOwnerMutation
>;
export type UpdateOwnerUsersForOwnerMutationResult = Apollo.MutationResult<
  UpdateOwnerUsersForOwnerMutation
>;
export type UpdateOwnerUsersForOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOwnerUsersForOwnerMutation,
  UpdateOwnerUsersForOwnerMutationVariables
>;
export const PushTaskDocument = gql`
  query PushTask($input: PushTaskInput!) {
    pushTask(input: $input) {
      __typename
      ... on PushTaskResult {
        pushTask {
          __typename
          ... on PushTaskPending {
            ...PushTaskPending
          }
          ... on PushTaskComplete {
            ...PushTaskComplete
          }
        }
      }
      ... on PushTaskNotFoundError {
        message
      }
    }
  }
  ${PushTaskPendingFragmentDoc}
  ${PushTaskCompleteFragmentDoc}
`;

/**
 * __usePushTaskQuery__
 *
 * To run a query within a React component, call `usePushTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushTaskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePushTaskQuery(
  baseOptions: Apollo.QueryHookOptions<PushTaskQuery, PushTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PushTaskQuery, PushTaskQueryVariables>(
    PushTaskDocument,
    options
  );
}
export function usePushTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PushTaskQuery,
    PushTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PushTaskQuery, PushTaskQueryVariables>(
    PushTaskDocument,
    options
  );
}
export type PushTaskQueryHookResult = ReturnType<typeof usePushTaskQuery>;
export type PushTaskLazyQueryHookResult = ReturnType<
  typeof usePushTaskLazyQuery
>;
export type PushTaskQueryResult = Apollo.QueryResult<
  PushTaskQuery,
  PushTaskQueryVariables
>;
export const RemediateAddResourceUsersDocument = gql`
  mutation RemediateAddResourceUsers($input: AddResourceUsersInput!) {
    remediateAddResourceUsers(input: $input) {
      __typename
      ... on AddResourceUsersResult {
        taskId
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on CannotAddSystemUserToResourceError {
        message
      }
      ... on OpalGlobalImpersonationResourceDirectAddNotAllowedError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on ResourceUserAlreadyExists {
        message
      }
    }
  }
`;
export type RemediateAddResourceUsersMutationFn = Apollo.MutationFunction<
  RemediateAddResourceUsersMutation,
  RemediateAddResourceUsersMutationVariables
>;

/**
 * __useRemediateAddResourceUsersMutation__
 *
 * To run a mutation, you first call `useRemediateAddResourceUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemediateAddResourceUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remediateAddResourceUsersMutation, { data, loading, error }] = useRemediateAddResourceUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemediateAddResourceUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemediateAddResourceUsersMutation,
    RemediateAddResourceUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemediateAddResourceUsersMutation,
    RemediateAddResourceUsersMutationVariables
  >(RemediateAddResourceUsersDocument, options);
}
export type RemediateAddResourceUsersMutationHookResult = ReturnType<
  typeof useRemediateAddResourceUsersMutation
>;
export type RemediateAddResourceUsersMutationResult = Apollo.MutationResult<
  RemediateAddResourceUsersMutation
>;
export type RemediateAddResourceUsersMutationOptions = Apollo.BaseMutationOptions<
  RemediateAddResourceUsersMutation,
  RemediateAddResourceUsersMutationVariables
>;
export const RemediateAddGroupResourcesDocument = gql`
  mutation RemediateAddGroupResources($input: AddGroupResourcesInput!) {
    remediateAddGroupResources(input: $input) {
      __typename
      ... on AddGroupResourcesResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on GroupResourceAlreadyExists {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type RemediateAddGroupResourcesMutationFn = Apollo.MutationFunction<
  RemediateAddGroupResourcesMutation,
  RemediateAddGroupResourcesMutationVariables
>;

/**
 * __useRemediateAddGroupResourcesMutation__
 *
 * To run a mutation, you first call `useRemediateAddGroupResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemediateAddGroupResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remediateAddGroupResourcesMutation, { data, loading, error }] = useRemediateAddGroupResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemediateAddGroupResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemediateAddGroupResourcesMutation,
    RemediateAddGroupResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemediateAddGroupResourcesMutation,
    RemediateAddGroupResourcesMutationVariables
  >(RemediateAddGroupResourcesDocument, options);
}
export type RemediateAddGroupResourcesMutationHookResult = ReturnType<
  typeof useRemediateAddGroupResourcesMutation
>;
export type RemediateAddGroupResourcesMutationResult = Apollo.MutationResult<
  RemediateAddGroupResourcesMutation
>;
export type RemediateAddGroupResourcesMutationOptions = Apollo.BaseMutationOptions<
  RemediateAddGroupResourcesMutation,
  RemediateAddGroupResourcesMutationVariables
>;
export const RemediateAddGroupUsersDocument = gql`
  mutation RemediateAddGroupUsers($input: AddGroupUsersInput!) {
    remediateAddGroupUsers(input: $input) {
      __typename
      ... on AddGroupUsersResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on CannotAddSystemUserToGroupError {
        message
      }
      ... on GroupUserAlreadyExists {
        message
      }
    }
  }
`;
export type RemediateAddGroupUsersMutationFn = Apollo.MutationFunction<
  RemediateAddGroupUsersMutation,
  RemediateAddGroupUsersMutationVariables
>;

/**
 * __useRemediateAddGroupUsersMutation__
 *
 * To run a mutation, you first call `useRemediateAddGroupUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemediateAddGroupUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remediateAddGroupUsersMutation, { data, loading, error }] = useRemediateAddGroupUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemediateAddGroupUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemediateAddGroupUsersMutation,
    RemediateAddGroupUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemediateAddGroupUsersMutation,
    RemediateAddGroupUsersMutationVariables
  >(RemediateAddGroupUsersDocument, options);
}
export type RemediateAddGroupUsersMutationHookResult = ReturnType<
  typeof useRemediateAddGroupUsersMutation
>;
export type RemediateAddGroupUsersMutationResult = Apollo.MutationResult<
  RemediateAddGroupUsersMutation
>;
export type RemediateAddGroupUsersMutationOptions = Apollo.BaseMutationOptions<
  RemediateAddGroupUsersMutation,
  RemediateAddGroupUsersMutationVariables
>;
export const RemediateAddRoleAssignmentsDocument = gql`
  mutation RemediateAddRoleAssignments($input: AddRoleAssignmentsInput!) {
    remediateAddRoleAssignments(input: $input) {
      __typename
      ... on AddRoleAssignmentsResult {
        taskIds
      }
      ... on RoleAssignmentNotFoundError {
        message
      }
    }
  }
`;
export type RemediateAddRoleAssignmentsMutationFn = Apollo.MutationFunction<
  RemediateAddRoleAssignmentsMutation,
  RemediateAddRoleAssignmentsMutationVariables
>;

/**
 * __useRemediateAddRoleAssignmentsMutation__
 *
 * To run a mutation, you first call `useRemediateAddRoleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemediateAddRoleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remediateAddRoleAssignmentsMutation, { data, loading, error }] = useRemediateAddRoleAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemediateAddRoleAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemediateAddRoleAssignmentsMutation,
    RemediateAddRoleAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemediateAddRoleAssignmentsMutation,
    RemediateAddRoleAssignmentsMutationVariables
  >(RemediateAddRoleAssignmentsDocument, options);
}
export type RemediateAddRoleAssignmentsMutationHookResult = ReturnType<
  typeof useRemediateAddRoleAssignmentsMutation
>;
export type RemediateAddRoleAssignmentsMutationResult = Apollo.MutationResult<
  RemediateAddRoleAssignmentsMutation
>;
export type RemediateAddRoleAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  RemediateAddRoleAssignmentsMutation,
  RemediateAddRoleAssignmentsMutationVariables
>;
export const RequestTemplatesDocument = gql`
  query RequestTemplates {
    requestTemplates {
      __typename
      ... on RequestTemplatesResult {
        requestTemplates {
          ...RequestTemplateSmall
        }
      }
    }
  }
  ${RequestTemplateSmallFragmentDoc}
`;

/**
 * __useRequestTemplatesQuery__
 *
 * To run a query within a React component, call `useRequestTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestTemplatesQuery,
    RequestTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestTemplatesQuery, RequestTemplatesQueryVariables>(
    RequestTemplatesDocument,
    options
  );
}
export function useRequestTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestTemplatesQuery,
    RequestTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestTemplatesQuery,
    RequestTemplatesQueryVariables
  >(RequestTemplatesDocument, options);
}
export type RequestTemplatesQueryHookResult = ReturnType<
  typeof useRequestTemplatesQuery
>;
export type RequestTemplatesLazyQueryHookResult = ReturnType<
  typeof useRequestTemplatesLazyQuery
>;
export type RequestTemplatesQueryResult = Apollo.QueryResult<
  RequestTemplatesQuery,
  RequestTemplatesQueryVariables
>;
export const RequestTemplatesWithFieldsDocument = gql`
  query RequestTemplatesWithFields($input: RequestTemplatesWithFieldsInput!) {
    requestTemplatesWithFields(input: $input) {
      __typename
      ... on RequestTemplatesWithFieldsResult {
        requestTemplates {
          ...RequestTemplate
        }
      }
    }
  }
  ${RequestTemplateFragmentDoc}
`;

/**
 * __useRequestTemplatesWithFieldsQuery__
 *
 * To run a query within a React component, call `useRequestTemplatesWithFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTemplatesWithFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTemplatesWithFieldsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTemplatesWithFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestTemplatesWithFieldsQuery,
    RequestTemplatesWithFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestTemplatesWithFieldsQuery,
    RequestTemplatesWithFieldsQueryVariables
  >(RequestTemplatesWithFieldsDocument, options);
}
export function useRequestTemplatesWithFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestTemplatesWithFieldsQuery,
    RequestTemplatesWithFieldsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestTemplatesWithFieldsQuery,
    RequestTemplatesWithFieldsQueryVariables
  >(RequestTemplatesWithFieldsDocument, options);
}
export type RequestTemplatesWithFieldsQueryHookResult = ReturnType<
  typeof useRequestTemplatesWithFieldsQuery
>;
export type RequestTemplatesWithFieldsLazyQueryHookResult = ReturnType<
  typeof useRequestTemplatesWithFieldsLazyQuery
>;
export type RequestTemplatesWithFieldsQueryResult = Apollo.QueryResult<
  RequestTemplatesWithFieldsQuery,
  RequestTemplatesWithFieldsQueryVariables
>;
export const RequestTemplateDocument = gql`
  query RequestTemplate($input: RequestTemplateInput!) {
    requestTemplate(input: $input) {
      __typename
      ... on RequestTemplateResult {
        requestTemplate {
          ...RequestTemplate
        }
      }
    }
  }
  ${RequestTemplateFragmentDoc}
`;

/**
 * __useRequestTemplateQuery__
 *
 * To run a query within a React component, call `useRequestTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestTemplateQuery,
    RequestTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestTemplateQuery, RequestTemplateQueryVariables>(
    RequestTemplateDocument,
    options
  );
}
export function useRequestTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestTemplateQuery,
    RequestTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestTemplateQuery,
    RequestTemplateQueryVariables
  >(RequestTemplateDocument, options);
}
export type RequestTemplateQueryHookResult = ReturnType<
  typeof useRequestTemplateQuery
>;
export type RequestTemplateLazyQueryHookResult = ReturnType<
  typeof useRequestTemplateLazyQuery
>;
export type RequestTemplateQueryResult = Apollo.QueryResult<
  RequestTemplateQuery,
  RequestTemplateQueryVariables
>;
export const CreateRequestTemplateDocument = gql`
  mutation CreateRequestTemplate($input: CreateRequestTemplateInput!) {
    createRequestTemplate(input: $input) {
      __typename
      ... on CreateRequestTemplateResult {
        requestTemplate {
          id
          name
        }
      }
      ... on RequestTemplateNameExistsError {
        message
      }
    }
  }
`;
export type CreateRequestTemplateMutationFn = Apollo.MutationFunction<
  CreateRequestTemplateMutation,
  CreateRequestTemplateMutationVariables
>;

/**
 * __useCreateRequestTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRequestTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestTemplateMutation, { data, loading, error }] = useCreateRequestTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestTemplateMutation,
    CreateRequestTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestTemplateMutation,
    CreateRequestTemplateMutationVariables
  >(CreateRequestTemplateDocument, options);
}
export type CreateRequestTemplateMutationHookResult = ReturnType<
  typeof useCreateRequestTemplateMutation
>;
export type CreateRequestTemplateMutationResult = Apollo.MutationResult<
  CreateRequestTemplateMutation
>;
export type CreateRequestTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestTemplateMutation,
  CreateRequestTemplateMutationVariables
>;
export const UpdateRequestTemplateDocument = gql`
  mutation UpdateRequestTemplate($input: UpdateRequestTemplateInput!) {
    updateRequestTemplate(input: $input) {
      __typename
      ... on UpdateRequestTemplateResult {
        requestTemplate {
          id
          name
        }
      }
      ... on RequestTemplateNameExistsError {
        message
      }
      ... on CustomFieldExistsError {
        message
      }
    }
  }
`;
export type UpdateRequestTemplateMutationFn = Apollo.MutationFunction<
  UpdateRequestTemplateMutation,
  UpdateRequestTemplateMutationVariables
>;

/**
 * __useUpdateRequestTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateRequestTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestTemplateMutation, { data, loading, error }] = useUpdateRequestTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRequestTemplateMutation,
    UpdateRequestTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRequestTemplateMutation,
    UpdateRequestTemplateMutationVariables
  >(UpdateRequestTemplateDocument, options);
}
export type UpdateRequestTemplateMutationHookResult = ReturnType<
  typeof useUpdateRequestTemplateMutation
>;
export type UpdateRequestTemplateMutationResult = Apollo.MutationResult<
  UpdateRequestTemplateMutation
>;
export type UpdateRequestTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateRequestTemplateMutation,
  UpdateRequestTemplateMutationVariables
>;
export const DeleteRequestTemplateDocument = gql`
  mutation DeleteRequestTemplate($input: DeleteRequestTemplateInput!) {
    deleteRequestTemplate(input: $input) {
      __typename
      ... on DeleteRequestTemplateResult {
        requestTemplate {
          id
          name
        }
      }
    }
  }
`;
export type DeleteRequestTemplateMutationFn = Apollo.MutationFunction<
  DeleteRequestTemplateMutation,
  DeleteRequestTemplateMutationVariables
>;

/**
 * __useDeleteRequestTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteRequestTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestTemplateMutation, { data, loading, error }] = useDeleteRequestTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRequestTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRequestTemplateMutation,
    DeleteRequestTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteRequestTemplateMutation,
    DeleteRequestTemplateMutationVariables
  >(DeleteRequestTemplateDocument, options);
}
export type DeleteRequestTemplateMutationHookResult = ReturnType<
  typeof useDeleteRequestTemplateMutation
>;
export type DeleteRequestTemplateMutationResult = Apollo.MutationResult<
  DeleteRequestTemplateMutation
>;
export type DeleteRequestTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteRequestTemplateMutation,
  DeleteRequestTemplateMutationVariables
>;
export const RequestsDocument = gql`
  query Requests($input: RequestsInput!) {
    requests(input: $input) {
      __typename
      ... on RequestsResult {
        requests {
          ...RequestPreviewLarge
        }
        cursor
      }
    }
  }
  ${RequestPreviewLargeFragmentDoc}
`;

/**
 * __useRequestsQuery__
 *
 * To run a query within a React component, call `useRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<RequestsQuery, RequestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestsQuery, RequestsQueryVariables>(
    RequestsDocument,
    options
  );
}
export function useRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestsQuery,
    RequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestsQuery, RequestsQueryVariables>(
    RequestsDocument,
    options
  );
}
export type RequestsQueryHookResult = ReturnType<typeof useRequestsQuery>;
export type RequestsLazyQueryHookResult = ReturnType<
  typeof useRequestsLazyQuery
>;
export type RequestsQueryResult = Apollo.QueryResult<
  RequestsQuery,
  RequestsQueryVariables
>;
export const RequestDocument = gql`
  query Request($input: RequestInput!) {
    request(input: $input) {
      __typename
      ... on RequestResult {
        request {
          ...Request
        }
      }
      ... on RequestNotFoundError {
        message
      }
    }
  }
  ${RequestFragmentDoc}
`;

/**
 * __useRequestQuery__
 *
 * To run a query within a React component, call `useRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestQuery(
  baseOptions: Apollo.QueryHookOptions<RequestQuery, RequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestQuery, RequestQueryVariables>(
    RequestDocument,
    options
  );
}
export function useRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RequestQuery, RequestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestQuery, RequestQueryVariables>(
    RequestDocument,
    options
  );
}
export type RequestQueryHookResult = ReturnType<typeof useRequestQuery>;
export type RequestLazyQueryHookResult = ReturnType<typeof useRequestLazyQuery>;
export type RequestQueryResult = Apollo.QueryResult<
  RequestQuery,
  RequestQueryVariables
>;
export const CreateRequestDocument = gql`
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      __typename
      ... on CreateRequestResult {
        request {
          id
          status
        }
      }
      ... on RequestDurationTooLargeError {
        message
      }
      ... on MfaInvalidError {
        message
      }
      ... on RequestRequiresUserAuthTokenForConnectionError {
        message
        user {
          ...UserPreviewSmall
        }
        connection {
          ...ConnectionPreviewSmall
        }
      }
      ... on NoReviewersSetForResourceError {
        message
        resource {
          ...ResourcePreviewSmall
        }
      }
      ... on NoReviewersSetForGroupError {
        message
        group {
          ...GroupPreviewSmall
        }
      }
      ... on NoManagerSetForRequestingUserError {
        message
      }
      ... on BulkRequestTooLargeError {
        message
      }
      ... on ItemCannotBeRequestedError {
        message
      }
      ... on UserCannotRequestAccessForTargetGroupError {
        message
      }
      ... on GroupNestingNotAllowedError {
        message
        fromGroup {
          id
          name
        }
        toGroup {
          id
          name
        }
      }
      ... on TargetUserHasNestedAccessError {
        message
        groupIds
      }
      ... on RequestReasonMissingError {
        message
        groupIds
        resourceIds
      }
      ... on RequestFieldValueMissingError {
        message
        fieldName
        groupIds
        resourceIds
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
  ${ConnectionPreviewSmallFragmentDoc}
  ${ResourcePreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
`;
export type CreateRequestMutationFn = Apollo.MutationFunction<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestMutation,
    CreateRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestMutation,
    CreateRequestMutationVariables
  >(CreateRequestDocument, options);
}
export type CreateRequestMutationHookResult = ReturnType<
  typeof useCreateRequestMutation
>;
export type CreateRequestMutationResult = Apollo.MutationResult<
  CreateRequestMutation
>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;
export const ApproveRequestDocument = gql`
  mutation ApproveRequest($input: ApproveRequestInput!) {
    approveRequest(input: $input) {
      __typename
      ... on ApproveRequestResult {
        taskId
        request {
          ...Request
        }
      }
      ... on RequestNotFoundError {
        message
      }
      ... on RequestAlreadyActionedError {
        message
      }
      ... on AWSRolePolicyInvalidError {
        message
      }
      ... on AWSRoleCreationError {
        message
      }
      ... on MfaInvalidError {
        message
      }
      ... on AdminApproveRequiresReasonError {
        message
      }
      ... on OrganizationInReadOnlyModeError {
        message
      }
      ... on GroupNestingNotAllowedError {
        message
        fromGroup {
          id
          name
        }
        toGroup {
          id
          name
        }
      }
    }
  }
  ${RequestFragmentDoc}
`;
export type ApproveRequestMutationFn = Apollo.MutationFunction<
  ApproveRequestMutation,
  ApproveRequestMutationVariables
>;

/**
 * __useApproveRequestMutation__
 *
 * To run a mutation, you first call `useApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRequestMutation, { data, loading, error }] = useApproveRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveRequestMutation,
    ApproveRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveRequestMutation,
    ApproveRequestMutationVariables
  >(ApproveRequestDocument, options);
}
export type ApproveRequestMutationHookResult = ReturnType<
  typeof useApproveRequestMutation
>;
export type ApproveRequestMutationResult = Apollo.MutationResult<
  ApproveRequestMutation
>;
export type ApproveRequestMutationOptions = Apollo.BaseMutationOptions<
  ApproveRequestMutation,
  ApproveRequestMutationVariables
>;
export const DenyRequestDocument = gql`
  mutation DenyRequest($input: DenyRequestInput!) {
    denyRequest(input: $input) {
      __typename
      ... on DenyRequestResult {
        request {
          ...Request
        }
      }
      ... on RequestNotFoundError {
        message
      }
      ... on RequestAlreadyActionedError {
        message
      }
    }
  }
  ${RequestFragmentDoc}
`;
export type DenyRequestMutationFn = Apollo.MutationFunction<
  DenyRequestMutation,
  DenyRequestMutationVariables
>;

/**
 * __useDenyRequestMutation__
 *
 * To run a mutation, you first call `useDenyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyRequestMutation, { data, loading, error }] = useDenyRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDenyRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DenyRequestMutation,
    DenyRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DenyRequestMutation, DenyRequestMutationVariables>(
    DenyRequestDocument,
    options
  );
}
export type DenyRequestMutationHookResult = ReturnType<
  typeof useDenyRequestMutation
>;
export type DenyRequestMutationResult = Apollo.MutationResult<
  DenyRequestMutation
>;
export type DenyRequestMutationOptions = Apollo.BaseMutationOptions<
  DenyRequestMutation,
  DenyRequestMutationVariables
>;
export const CancelRequestDocument = gql`
  mutation CancelRequest($input: CancelRequestInput!) {
    cancelRequest(input: $input) {
      __typename
      ... on CancelRequestResult {
        request {
          ...Request
        }
      }
      ... on RequestNotFoundError {
        message
      }
      ... on RequestAlreadyActionedError {
        message
      }
    }
  }
  ${RequestFragmentDoc}
`;
export type CancelRequestMutationFn = Apollo.MutationFunction<
  CancelRequestMutation,
  CancelRequestMutationVariables
>;

/**
 * __useCancelRequestMutation__
 *
 * To run a mutation, you first call `useCancelRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestMutation, { data, loading, error }] = useCancelRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRequestMutation,
    CancelRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRequestMutation,
    CancelRequestMutationVariables
  >(CancelRequestDocument, options);
}
export type CancelRequestMutationHookResult = ReturnType<
  typeof useCancelRequestMutation
>;
export type CancelRequestMutationResult = Apollo.MutationResult<
  CancelRequestMutation
>;
export type CancelRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelRequestMutation,
  CancelRequestMutationVariables
>;
export const CreateRequestCommentDocument = gql`
  mutation CreateRequestComment($input: CreateRequestCommentInput!) {
    createRequestComment(input: $input) {
      __typename
      ... on CreateRequestCommentResult {
        request {
          id
          requestComments {
            ...RequestComment
          }
        }
      }
    }
  }
  ${RequestCommentFragmentDoc}
`;
export type CreateRequestCommentMutationFn = Apollo.MutationFunction<
  CreateRequestCommentMutation,
  CreateRequestCommentMutationVariables
>;

/**
 * __useCreateRequestCommentMutation__
 *
 * To run a mutation, you first call `useCreateRequestCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestCommentMutation, { data, loading, error }] = useCreateRequestCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestCommentMutation,
    CreateRequestCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestCommentMutation,
    CreateRequestCommentMutationVariables
  >(CreateRequestCommentDocument, options);
}
export type CreateRequestCommentMutationHookResult = ReturnType<
  typeof useCreateRequestCommentMutation
>;
export type CreateRequestCommentMutationResult = Apollo.MutationResult<
  CreateRequestCommentMutation
>;
export type CreateRequestCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestCommentMutation,
  CreateRequestCommentMutationVariables
>;
export const SendManualRequestReminderDocument = gql`
  mutation SendManualRequestReminder($input: SendManualRequestReminderInput!) {
    sendManualRequestReminder(input: $input) {
      __typename
      ... on SendManualRequestReminderResult {
        success
      }
    }
  }
`;
export type SendManualRequestReminderMutationFn = Apollo.MutationFunction<
  SendManualRequestReminderMutation,
  SendManualRequestReminderMutationVariables
>;

/**
 * __useSendManualRequestReminderMutation__
 *
 * To run a mutation, you first call `useSendManualRequestReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManualRequestReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManualRequestReminderMutation, { data, loading, error }] = useSendManualRequestReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendManualRequestReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendManualRequestReminderMutation,
    SendManualRequestReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendManualRequestReminderMutation,
    SendManualRequestReminderMutationVariables
  >(SendManualRequestReminderDocument, options);
}
export type SendManualRequestReminderMutationHookResult = ReturnType<
  typeof useSendManualRequestReminderMutation
>;
export type SendManualRequestReminderMutationResult = Apollo.MutationResult<
  SendManualRequestReminderMutation
>;
export type SendManualRequestReminderMutationOptions = Apollo.BaseMutationOptions<
  SendManualRequestReminderMutation,
  SendManualRequestReminderMutationVariables
>;
export const ResourceAccessLevelsDocument = gql`
  query ResourceAccessLevels($input: ResourceAccessLevelsInput!) {
    accessLevels(input: $input) {
      __typename
      ... on ResourceAccessLevelsResult {
        accessLevels {
          __typename
          ... on ResourceAccessLevel {
            ...ResourceAccessLevel
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceAccessLevelFragmentDoc}
`;

/**
 * __useResourceAccessLevelsQuery__
 *
 * To run a query within a React component, call `useResourceAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceAccessLevelsQuery,
    ResourceAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceAccessLevelsQuery,
    ResourceAccessLevelsQueryVariables
  >(ResourceAccessLevelsDocument, options);
}
export function useResourceAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceAccessLevelsQuery,
    ResourceAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceAccessLevelsQuery,
    ResourceAccessLevelsQueryVariables
  >(ResourceAccessLevelsDocument, options);
}
export type ResourceAccessLevelsQueryHookResult = ReturnType<
  typeof useResourceAccessLevelsQuery
>;
export type ResourceAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useResourceAccessLevelsLazyQuery
>;
export type ResourceAccessLevelsQueryResult = Apollo.QueryResult<
  ResourceAccessLevelsQuery,
  ResourceAccessLevelsQueryVariables
>;
export const MultipleResourceAccessLevelsDocument = gql`
  query MultipleResourceAccessLevels(
    $input: MultipleResourceAccessLevelsInput!
  ) {
    multipleAccessLevels(input: $input) {
      __typename
      ... on MultipleResourceAccessLevelsResult {
        results {
          accessLevels {
            __typename
            ... on ResourceAccessLevel {
              ...ResourceAccessLevel
            }
          }
          resourceId
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceAccessLevelFragmentDoc}
`;

/**
 * __useMultipleResourceAccessLevelsQuery__
 *
 * To run a query within a React component, call `useMultipleResourceAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleResourceAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleResourceAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultipleResourceAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MultipleResourceAccessLevelsQuery,
    MultipleResourceAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MultipleResourceAccessLevelsQuery,
    MultipleResourceAccessLevelsQueryVariables
  >(MultipleResourceAccessLevelsDocument, options);
}
export function useMultipleResourceAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MultipleResourceAccessLevelsQuery,
    MultipleResourceAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MultipleResourceAccessLevelsQuery,
    MultipleResourceAccessLevelsQueryVariables
  >(MultipleResourceAccessLevelsDocument, options);
}
export type MultipleResourceAccessLevelsQueryHookResult = ReturnType<
  typeof useMultipleResourceAccessLevelsQuery
>;
export type MultipleResourceAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useMultipleResourceAccessLevelsLazyQuery
>;
export type MultipleResourceAccessLevelsQueryResult = Apollo.QueryResult<
  MultipleResourceAccessLevelsQuery,
  MultipleResourceAccessLevelsQueryVariables
>;
export const ResourceCustomAccessLevelDocument = gql`
  query ResourceCustomAccessLevel($input: ResourceCustomAccessLevelInput!) {
    resourceCustomAccessLevel(input: $input) {
      __typename
      ... on ResourceCustomAccessLevelResult {
        resourceCustomAccessLevel {
          ...ResourceCustomAccessLevel
        }
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;

/**
 * __useResourceCustomAccessLevelQuery__
 *
 * To run a query within a React component, call `useResourceCustomAccessLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCustomAccessLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCustomAccessLevelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceCustomAccessLevelQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceCustomAccessLevelQuery,
    ResourceCustomAccessLevelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceCustomAccessLevelQuery,
    ResourceCustomAccessLevelQueryVariables
  >(ResourceCustomAccessLevelDocument, options);
}
export function useResourceCustomAccessLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceCustomAccessLevelQuery,
    ResourceCustomAccessLevelQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceCustomAccessLevelQuery,
    ResourceCustomAccessLevelQueryVariables
  >(ResourceCustomAccessLevelDocument, options);
}
export type ResourceCustomAccessLevelQueryHookResult = ReturnType<
  typeof useResourceCustomAccessLevelQuery
>;
export type ResourceCustomAccessLevelLazyQueryHookResult = ReturnType<
  typeof useResourceCustomAccessLevelLazyQuery
>;
export type ResourceCustomAccessLevelQueryResult = Apollo.QueryResult<
  ResourceCustomAccessLevelQuery,
  ResourceCustomAccessLevelQueryVariables
>;
export const ResourceCustomAccessLevelsDocument = gql`
  query ResourceCustomAccessLevels($input: ResourceCustomAccessLevelsInput!) {
    resourceCustomAccessLevels(input: $input) {
      __typename
      ... on ResourceCustomAccessLevelsResult {
        resourceCustomAccessLevels {
          ...ResourceCustomAccessLevel
        }
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;

/**
 * __useResourceCustomAccessLevelsQuery__
 *
 * To run a query within a React component, call `useResourceCustomAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCustomAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCustomAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceCustomAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceCustomAccessLevelsQuery,
    ResourceCustomAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceCustomAccessLevelsQuery,
    ResourceCustomAccessLevelsQueryVariables
  >(ResourceCustomAccessLevelsDocument, options);
}
export function useResourceCustomAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceCustomAccessLevelsQuery,
    ResourceCustomAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceCustomAccessLevelsQuery,
    ResourceCustomAccessLevelsQueryVariables
  >(ResourceCustomAccessLevelsDocument, options);
}
export type ResourceCustomAccessLevelsQueryHookResult = ReturnType<
  typeof useResourceCustomAccessLevelsQuery
>;
export type ResourceCustomAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useResourceCustomAccessLevelsLazyQuery
>;
export type ResourceCustomAccessLevelsQueryResult = Apollo.QueryResult<
  ResourceCustomAccessLevelsQuery,
  ResourceCustomAccessLevelsQueryVariables
>;
export const CreateResourceCustomAccessLevelDocument = gql`
  mutation CreateResourceCustomAccessLevel(
    $input: CreateResourceCustomAccessLevelInput!
  ) {
    createResourceCustomAccessLevel(input: $input) {
      __typename
      ... on CreateResourceCustomAccessLevelResult {
        resourceCustomAccessLevel {
          ...ResourceCustomAccessLevel
        }
      }
      ... on ResourceCustomAccessLevelPriorityError {
        message
      }
      ... on ResourceCustomAccessLevelAlreadyExistsError {
        message
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;
export type CreateResourceCustomAccessLevelMutationFn = Apollo.MutationFunction<
  CreateResourceCustomAccessLevelMutation,
  CreateResourceCustomAccessLevelMutationVariables
>;

/**
 * __useCreateResourceCustomAccessLevelMutation__
 *
 * To run a mutation, you first call `useCreateResourceCustomAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceCustomAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceCustomAccessLevelMutation, { data, loading, error }] = useCreateResourceCustomAccessLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceCustomAccessLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourceCustomAccessLevelMutation,
    CreateResourceCustomAccessLevelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateResourceCustomAccessLevelMutation,
    CreateResourceCustomAccessLevelMutationVariables
  >(CreateResourceCustomAccessLevelDocument, options);
}
export type CreateResourceCustomAccessLevelMutationHookResult = ReturnType<
  typeof useCreateResourceCustomAccessLevelMutation
>;
export type CreateResourceCustomAccessLevelMutationResult = Apollo.MutationResult<
  CreateResourceCustomAccessLevelMutation
>;
export type CreateResourceCustomAccessLevelMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceCustomAccessLevelMutation,
  CreateResourceCustomAccessLevelMutationVariables
>;
export const CreateResourceCustomAccessLevelsDocument = gql`
  mutation CreateResourceCustomAccessLevels(
    $input: CreateResourceCustomAccessLevelsInput!
  ) {
    createResourceCustomAccessLevels(input: $input) {
      __typename
      ... on CreateResourceCustomAccessLevelsResult {
        entries {
          __typename
          ... on CreateResourceCustomAccessLevelEntryResult {
            resourceCustomAccessLevel {
              ...ResourceCustomAccessLevel
            }
          }
        }
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;
export type CreateResourceCustomAccessLevelsMutationFn = Apollo.MutationFunction<
  CreateResourceCustomAccessLevelsMutation,
  CreateResourceCustomAccessLevelsMutationVariables
>;

/**
 * __useCreateResourceCustomAccessLevelsMutation__
 *
 * To run a mutation, you first call `useCreateResourceCustomAccessLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceCustomAccessLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceCustomAccessLevelsMutation, { data, loading, error }] = useCreateResourceCustomAccessLevelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceCustomAccessLevelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourceCustomAccessLevelsMutation,
    CreateResourceCustomAccessLevelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateResourceCustomAccessLevelsMutation,
    CreateResourceCustomAccessLevelsMutationVariables
  >(CreateResourceCustomAccessLevelsDocument, options);
}
export type CreateResourceCustomAccessLevelsMutationHookResult = ReturnType<
  typeof useCreateResourceCustomAccessLevelsMutation
>;
export type CreateResourceCustomAccessLevelsMutationResult = Apollo.MutationResult<
  CreateResourceCustomAccessLevelsMutation
>;
export type CreateResourceCustomAccessLevelsMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceCustomAccessLevelsMutation,
  CreateResourceCustomAccessLevelsMutationVariables
>;
export const UpdateResourceCustomAccessLevelDocument = gql`
  mutation UpdateResourceCustomAccessLevel(
    $input: UpdateResourceCustomAccessLevelInput!
  ) {
    updateResourceCustomAccessLevel(input: $input) {
      __typename
      ... on UpdateResourceCustomAccessLevelResult {
        resourceCustomAccessLevel {
          ...ResourceCustomAccessLevel
        }
      }
      ... on ResourceCustomAccessLevelNotFoundError {
        message
      }
      ... on ResourceCustomAccessLevelPriorityError {
        message
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;
export type UpdateResourceCustomAccessLevelMutationFn = Apollo.MutationFunction<
  UpdateResourceCustomAccessLevelMutation,
  UpdateResourceCustomAccessLevelMutationVariables
>;

/**
 * __useUpdateResourceCustomAccessLevelMutation__
 *
 * To run a mutation, you first call `useUpdateResourceCustomAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceCustomAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceCustomAccessLevelMutation, { data, loading, error }] = useUpdateResourceCustomAccessLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceCustomAccessLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourceCustomAccessLevelMutation,
    UpdateResourceCustomAccessLevelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourceCustomAccessLevelMutation,
    UpdateResourceCustomAccessLevelMutationVariables
  >(UpdateResourceCustomAccessLevelDocument, options);
}
export type UpdateResourceCustomAccessLevelMutationHookResult = ReturnType<
  typeof useUpdateResourceCustomAccessLevelMutation
>;
export type UpdateResourceCustomAccessLevelMutationResult = Apollo.MutationResult<
  UpdateResourceCustomAccessLevelMutation
>;
export type UpdateResourceCustomAccessLevelMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceCustomAccessLevelMutation,
  UpdateResourceCustomAccessLevelMutationVariables
>;
export const DeleteResourceCustomAccessLevelsDocument = gql`
  mutation DeleteResourceCustomAccessLevels(
    $input: DeleteResourceCustomAccessLevelsInput!
  ) {
    deleteResourceCustomAccessLevels(input: $input) {
      __typename
      ... on DeleteResourceCustomAccessLevelsResult {
        entries {
          resourceCustomAccessLevel {
            ...ResourceCustomAccessLevel
          }
        }
      }
    }
  }
  ${ResourceCustomAccessLevelFragmentDoc}
`;
export type DeleteResourceCustomAccessLevelsMutationFn = Apollo.MutationFunction<
  DeleteResourceCustomAccessLevelsMutation,
  DeleteResourceCustomAccessLevelsMutationVariables
>;

/**
 * __useDeleteResourceCustomAccessLevelsMutation__
 *
 * To run a mutation, you first call `useDeleteResourceCustomAccessLevelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceCustomAccessLevelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceCustomAccessLevelsMutation, { data, loading, error }] = useDeleteResourceCustomAccessLevelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResourceCustomAccessLevelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResourceCustomAccessLevelsMutation,
    DeleteResourceCustomAccessLevelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteResourceCustomAccessLevelsMutation,
    DeleteResourceCustomAccessLevelsMutationVariables
  >(DeleteResourceCustomAccessLevelsDocument, options);
}
export type DeleteResourceCustomAccessLevelsMutationHookResult = ReturnType<
  typeof useDeleteResourceCustomAccessLevelsMutation
>;
export type DeleteResourceCustomAccessLevelsMutationResult = Apollo.MutationResult<
  DeleteResourceCustomAccessLevelsMutation
>;
export type DeleteResourceCustomAccessLevelsMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourceCustomAccessLevelsMutation,
  DeleteResourceCustomAccessLevelsMutationVariables
>;
export const ResourceTagsDocument = gql`
  query ResourceTags($input: ResourceTagsInput!) {
    resourceTags(input: $input) {
      __typename
      ... on ResourceTagsResult {
        resourceTags {
          ...ResourceTag
        }
      }
    }
  }
  ${ResourceTagFragmentDoc}
`;

/**
 * __useResourceTagsQuery__
 *
 * To run a query within a React component, call `useResourceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceTagsQuery,
    ResourceTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceTagsQuery, ResourceTagsQueryVariables>(
    ResourceTagsDocument,
    options
  );
}
export function useResourceTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceTagsQuery,
    ResourceTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceTagsQuery, ResourceTagsQueryVariables>(
    ResourceTagsDocument,
    options
  );
}
export type ResourceTagsQueryHookResult = ReturnType<
  typeof useResourceTagsQuery
>;
export type ResourceTagsLazyQueryHookResult = ReturnType<
  typeof useResourceTagsLazyQuery
>;
export type ResourceTagsQueryResult = Apollo.QueryResult<
  ResourceTagsQuery,
  ResourceTagsQueryVariables
>;
export const AddResourceTagsDocument = gql`
  mutation AddResourceTags($input: AddResourceTagsInput!) {
    addResourceTags(input: $input) {
      __typename
      ... on AddResourceTagsResult {
        entries {
          __typename
          ... on AddResourceTagsEntryResult {
            resourceTag {
              resourceId
              tagId
            }
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on TagNotFoundError {
        message
      }
    }
  }
`;
export type AddResourceTagsMutationFn = Apollo.MutationFunction<
  AddResourceTagsMutation,
  AddResourceTagsMutationVariables
>;

/**
 * __useAddResourceTagsMutation__
 *
 * To run a mutation, you first call `useAddResourceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResourceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResourceTagsMutation, { data, loading, error }] = useAddResourceTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddResourceTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddResourceTagsMutation,
    AddResourceTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddResourceTagsMutation,
    AddResourceTagsMutationVariables
  >(AddResourceTagsDocument, options);
}
export type AddResourceTagsMutationHookResult = ReturnType<
  typeof useAddResourceTagsMutation
>;
export type AddResourceTagsMutationResult = Apollo.MutationResult<
  AddResourceTagsMutation
>;
export type AddResourceTagsMutationOptions = Apollo.BaseMutationOptions<
  AddResourceTagsMutation,
  AddResourceTagsMutationVariables
>;
export const RemoveResourceTagsDocument = gql`
  mutation RemoveResourceTags($input: RemoveResourceTagsInput!) {
    removeResourceTags(input: $input) {
      __typename
      ... on RemoveResourceTagsResult {
        entries {
          __typename
          ... on RemoveResourceTagsEntryResult {
            resourceTag {
              ...ResourceTag
            }
          }
        }
      }
      ... on ResourceTagNotFoundError {
        message
      }
    }
  }
  ${ResourceTagFragmentDoc}
`;
export type RemoveResourceTagsMutationFn = Apollo.MutationFunction<
  RemoveResourceTagsMutation,
  RemoveResourceTagsMutationVariables
>;

/**
 * __useRemoveResourceTagsMutation__
 *
 * To run a mutation, you first call `useRemoveResourceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveResourceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeResourceTagsMutation, { data, loading, error }] = useRemoveResourceTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveResourceTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveResourceTagsMutation,
    RemoveResourceTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveResourceTagsMutation,
    RemoveResourceTagsMutationVariables
  >(RemoveResourceTagsDocument, options);
}
export type RemoveResourceTagsMutationHookResult = ReturnType<
  typeof useRemoveResourceTagsMutation
>;
export type RemoveResourceTagsMutationResult = Apollo.MutationResult<
  RemoveResourceTagsMutation
>;
export type RemoveResourceTagsMutationOptions = Apollo.BaseMutationOptions<
  RemoveResourceTagsMutation,
  RemoveResourceTagsMutationVariables
>;
export const ResourceUsageDocument = gql`
  query ResourceUsage($input: ResourceUsageInput!) {
    resourceUsage(input: $input) {
      __typename
      ... on ResourceUsageResult {
        usageLogs {
          ...ResourceUsageLog
        }
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${ResourceUsageLogFragmentDoc}
`;

/**
 * __useResourceUsageQuery__
 *
 * To run a query within a React component, call `useResourceUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceUsageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceUsageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceUsageQuery,
    ResourceUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceUsageQuery, ResourceUsageQueryVariables>(
    ResourceUsageDocument,
    options
  );
}
export function useResourceUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceUsageQuery,
    ResourceUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceUsageQuery, ResourceUsageQueryVariables>(
    ResourceUsageDocument,
    options
  );
}
export type ResourceUsageQueryHookResult = ReturnType<
  typeof useResourceUsageQuery
>;
export type ResourceUsageLazyQueryHookResult = ReturnType<
  typeof useResourceUsageLazyQuery
>;
export type ResourceUsageQueryResult = Apollo.QueryResult<
  ResourceUsageQuery,
  ResourceUsageQueryVariables
>;
export const AddResourceUsersDocument = gql`
  mutation AddResourceUsers($input: AddResourceUsersInput!) {
    addResourceUsers(input: $input) {
      __typename
      ... on AddResourceUsersResult {
        taskId
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on CannotAddSystemUserToResourceError {
        message
      }
      ... on OpalGlobalImpersonationResourceDirectAddNotAllowedError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on ResourceUserAlreadyExists {
        message
      }
    }
  }
`;
export type AddResourceUsersMutationFn = Apollo.MutationFunction<
  AddResourceUsersMutation,
  AddResourceUsersMutationVariables
>;

/**
 * __useAddResourceUsersMutation__
 *
 * To run a mutation, you first call `useAddResourceUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResourceUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResourceUsersMutation, { data, loading, error }] = useAddResourceUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddResourceUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddResourceUsersMutation,
    AddResourceUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddResourceUsersMutation,
    AddResourceUsersMutationVariables
  >(AddResourceUsersDocument, options);
}
export type AddResourceUsersMutationHookResult = ReturnType<
  typeof useAddResourceUsersMutation
>;
export type AddResourceUsersMutationResult = Apollo.MutationResult<
  AddResourceUsersMutation
>;
export type AddResourceUsersMutationOptions = Apollo.BaseMutationOptions<
  AddResourceUsersMutation,
  AddResourceUsersMutationVariables
>;
export const RemoveResourceUsersDocument = gql`
  mutation RemoveResourceUsers($input: RemoveResourceUsersInput!) {
    removeResourceUsers(input: $input) {
      __typename
      ... on RemoveResourceUsersResult {
        taskId
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on OpalAdminRoleMustHaveAtLeastOneDirectUser {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on OidcIDTokenNotFoundError {
        message
      }
    }
  }
`;
export type RemoveResourceUsersMutationFn = Apollo.MutationFunction<
  RemoveResourceUsersMutation,
  RemoveResourceUsersMutationVariables
>;

/**
 * __useRemoveResourceUsersMutation__
 *
 * To run a mutation, you first call `useRemoveResourceUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveResourceUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeResourceUsersMutation, { data, loading, error }] = useRemoveResourceUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveResourceUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveResourceUsersMutation,
    RemoveResourceUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveResourceUsersMutation,
    RemoveResourceUsersMutationVariables
  >(RemoveResourceUsersDocument, options);
}
export type RemoveResourceUsersMutationHookResult = ReturnType<
  typeof useRemoveResourceUsersMutation
>;
export type RemoveResourceUsersMutationResult = Apollo.MutationResult<
  RemoveResourceUsersMutation
>;
export type RemoveResourceUsersMutationOptions = Apollo.BaseMutationOptions<
  RemoveResourceUsersMutation,
  RemoveResourceUsersMutationVariables
>;
export const UpdateUserResourcesDocument = gql`
  mutation UpdateUserResources($input: UpdateResourceUsersInput!) {
    updateResourceUsers(input: $input) {
      __typename
      ... on UpdateResourceUsersResult {
        resourceUsers {
          resourceId
          userId
          accessLevel {
            accessLevelRemoteId
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
`;
export type UpdateUserResourcesMutationFn = Apollo.MutationFunction<
  UpdateUserResourcesMutation,
  UpdateUserResourcesMutationVariables
>;

/**
 * __useUpdateUserResourcesMutation__
 *
 * To run a mutation, you first call `useUpdateUserResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserResourcesMutation, { data, loading, error }] = useUpdateUserResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserResourcesMutation,
    UpdateUserResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserResourcesMutation,
    UpdateUserResourcesMutationVariables
  >(UpdateUserResourcesDocument, options);
}
export type UpdateUserResourcesMutationHookResult = ReturnType<
  typeof useUpdateUserResourcesMutation
>;
export type UpdateUserResourcesMutationResult = Apollo.MutationResult<
  UpdateUserResourcesMutation
>;
export type UpdateUserResourcesMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserResourcesMutation,
  UpdateUserResourcesMutationVariables
>;
export const RemoveUserResourcesDocument = gql`
  mutation RemoveUserResources($input: RemoveResourceUsersInput!) {
    removeResourceUsers(input: $input) {
      __typename
      ... on RemoveResourceUsersResult {
        taskId
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on OpalAdminRoleMustHaveAtLeastOneDirectUser {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on OidcIDTokenNotFoundError {
        message
      }
    }
  }
`;
export type RemoveUserResourcesMutationFn = Apollo.MutationFunction<
  RemoveUserResourcesMutation,
  RemoveUserResourcesMutationVariables
>;

/**
 * __useRemoveUserResourcesMutation__
 *
 * To run a mutation, you first call `useRemoveUserResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserResourcesMutation, { data, loading, error }] = useRemoveUserResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserResourcesMutation,
    RemoveUserResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUserResourcesMutation,
    RemoveUserResourcesMutationVariables
  >(RemoveUserResourcesDocument, options);
}
export type RemoveUserResourcesMutationHookResult = ReturnType<
  typeof useRemoveUserResourcesMutation
>;
export type RemoveUserResourcesMutationResult = Apollo.MutationResult<
  RemoveUserResourcesMutation
>;
export type RemoveUserResourcesMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserResourcesMutation,
  RemoveUserResourcesMutationVariables
>;
export const ForfeitResourceDocument = gql`
  mutation ForfeitResource($input: ForfeitResourceInput!) {
    forfeitResource(input: $input) {
      __typename
      ... on ForfeitResourceResult {
        taskId
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on OidcIDTokenNotFoundError {
        message
      }
      ... on OpalAdminRoleMustHaveAtLeastOneDirectUser {
        message
      }
    }
  }
`;
export type ForfeitResourceMutationFn = Apollo.MutationFunction<
  ForfeitResourceMutation,
  ForfeitResourceMutationVariables
>;

/**
 * __useForfeitResourceMutation__
 *
 * To run a mutation, you first call `useForfeitResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForfeitResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forfeitResourceMutation, { data, loading, error }] = useForfeitResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForfeitResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForfeitResourceMutation,
    ForfeitResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForfeitResourceMutation,
    ForfeitResourceMutationVariables
  >(ForfeitResourceDocument, options);
}
export type ForfeitResourceMutationHookResult = ReturnType<
  typeof useForfeitResourceMutation
>;
export type ForfeitResourceMutationResult = Apollo.MutationResult<
  ForfeitResourceMutation
>;
export type ForfeitResourceMutationOptions = Apollo.BaseMutationOptions<
  ForfeitResourceMutation,
  ForfeitResourceMutationVariables
>;
export const ResourcesHomeDocument = gql`
  query ResourcesHome($input: ResourcesInput!) {
    resources(input: $input) {
      __typename
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewLarge
        }
        cursor
        totalNumResources
      }
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
`;

/**
 * __useResourcesHomeQuery__
 *
 * To run a query within a React component, call `useResourcesHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesHomeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourcesHomeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourcesHomeQuery,
    ResourcesHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourcesHomeQuery, ResourcesHomeQueryVariables>(
    ResourcesHomeDocument,
    options
  );
}
export function useResourcesHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcesHomeQuery,
    ResourcesHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourcesHomeQuery, ResourcesHomeQueryVariables>(
    ResourcesHomeDocument,
    options
  );
}
export type ResourcesHomeQueryHookResult = ReturnType<
  typeof useResourcesHomeQuery
>;
export type ResourcesHomeLazyQueryHookResult = ReturnType<
  typeof useResourcesHomeLazyQuery
>;
export type ResourcesHomeQueryResult = Apollo.QueryResult<
  ResourcesHomeQuery,
  ResourcesHomeQueryVariables
>;
export const NonHumanIdentityResourcesDocument = gql`
  query NonHumanIdentityResources($input: ResourcesInput!) {
    resources(input: $input) {
      __typename
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewSmall
          numEntityAssignmentsForPrincipal
        }
        cursor
        totalNumResources
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;

/**
 * __useNonHumanIdentityResourcesQuery__
 *
 * To run a query within a React component, call `useNonHumanIdentityResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonHumanIdentityResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonHumanIdentityResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNonHumanIdentityResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonHumanIdentityResourcesQuery,
    NonHumanIdentityResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NonHumanIdentityResourcesQuery,
    NonHumanIdentityResourcesQueryVariables
  >(NonHumanIdentityResourcesDocument, options);
}
export function useNonHumanIdentityResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonHumanIdentityResourcesQuery,
    NonHumanIdentityResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NonHumanIdentityResourcesQuery,
    NonHumanIdentityResourcesQueryVariables
  >(NonHumanIdentityResourcesDocument, options);
}
export type NonHumanIdentityResourcesQueryHookResult = ReturnType<
  typeof useNonHumanIdentityResourcesQuery
>;
export type NonHumanIdentityResourcesLazyQueryHookResult = ReturnType<
  typeof useNonHumanIdentityResourcesLazyQuery
>;
export type NonHumanIdentityResourcesQueryResult = Apollo.QueryResult<
  NonHumanIdentityResourcesQuery,
  NonHumanIdentityResourcesQueryVariables
>;
export const FilteredResourcesLargeDocument = gql`
  query FilteredResourcesLarge($input: FilteredResourcesInput!) {
    filteredResources(input: $input) {
      __typename
      ... on FilteredResourcesResult {
        resources {
          ...ResourcePreviewLarge
        }
        cursor
      }
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
`;

/**
 * __useFilteredResourcesLargeQuery__
 *
 * To run a query within a React component, call `useFilteredResourcesLargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredResourcesLargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredResourcesLargeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilteredResourcesLargeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FilteredResourcesLargeQuery,
    FilteredResourcesLargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FilteredResourcesLargeQuery,
    FilteredResourcesLargeQueryVariables
  >(FilteredResourcesLargeDocument, options);
}
export function useFilteredResourcesLargeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FilteredResourcesLargeQuery,
    FilteredResourcesLargeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FilteredResourcesLargeQuery,
    FilteredResourcesLargeQueryVariables
  >(FilteredResourcesLargeDocument, options);
}
export type FilteredResourcesLargeQueryHookResult = ReturnType<
  typeof useFilteredResourcesLargeQuery
>;
export type FilteredResourcesLargeLazyQueryHookResult = ReturnType<
  typeof useFilteredResourcesLargeLazyQuery
>;
export type FilteredResourcesLargeQueryResult = Apollo.QueryResult<
  FilteredResourcesLargeQuery,
  FilteredResourcesLargeQueryVariables
>;
export const ResourceDocument = gql`
  query Resource($input: ResourceInput!) {
    resource(input: $input) {
      __typename
      ... on ResourceResult {
        resource {
          ...Resource
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceFragmentDoc}
`;

/**
 * __useResourceQuery__
 *
 * To run a query within a React component, call `useResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceQuery(
  baseOptions: Apollo.QueryHookOptions<ResourceQuery, ResourceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceQuery, ResourceQueryVariables>(
    ResourceDocument,
    options
  );
}
export function useResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceQuery,
    ResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceQuery, ResourceQueryVariables>(
    ResourceDocument,
    options
  );
}
export type ResourceQueryHookResult = ReturnType<typeof useResourceQuery>;
export type ResourceLazyQueryHookResult = ReturnType<
  typeof useResourceLazyQuery
>;
export type ResourceQueryResult = Apollo.QueryResult<
  ResourceQuery,
  ResourceQueryVariables
>;
export const ResourcePreviewDocument = gql`
  query ResourcePreview($input: ResourceInput!) {
    resource(input: $input) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourcePreviewSmall
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;

/**
 * __useResourcePreviewQuery__
 *
 * To run a query within a React component, call `useResourcePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourcePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourcePreviewQuery,
    ResourcePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourcePreviewQuery, ResourcePreviewQueryVariables>(
    ResourcePreviewDocument,
    options
  );
}
export function useResourcePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcePreviewQuery,
    ResourcePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourcePreviewQuery,
    ResourcePreviewQueryVariables
  >(ResourcePreviewDocument, options);
}
export type ResourcePreviewQueryHookResult = ReturnType<
  typeof useResourcePreviewQuery
>;
export type ResourcePreviewLazyQueryHookResult = ReturnType<
  typeof useResourcePreviewLazyQuery
>;
export type ResourcePreviewQueryResult = Apollo.QueryResult<
  ResourcePreviewQuery,
  ResourcePreviewQueryVariables
>;
export const ResourcePreviewWithAccessLevelsDocument = gql`
  query ResourcePreviewWithAccessLevels($input: ResourceInput!) {
    resource(input: $input) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourcePreviewWithAccessLevels
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourcePreviewWithAccessLevelsFragmentDoc}
`;

/**
 * __useResourcePreviewWithAccessLevelsQuery__
 *
 * To run a query within a React component, call `useResourcePreviewWithAccessLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcePreviewWithAccessLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcePreviewWithAccessLevelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourcePreviewWithAccessLevelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourcePreviewWithAccessLevelsQuery,
    ResourcePreviewWithAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourcePreviewWithAccessLevelsQuery,
    ResourcePreviewWithAccessLevelsQueryVariables
  >(ResourcePreviewWithAccessLevelsDocument, options);
}
export function useResourcePreviewWithAccessLevelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourcePreviewWithAccessLevelsQuery,
    ResourcePreviewWithAccessLevelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourcePreviewWithAccessLevelsQuery,
    ResourcePreviewWithAccessLevelsQueryVariables
  >(ResourcePreviewWithAccessLevelsDocument, options);
}
export type ResourcePreviewWithAccessLevelsQueryHookResult = ReturnType<
  typeof useResourcePreviewWithAccessLevelsQuery
>;
export type ResourcePreviewWithAccessLevelsLazyQueryHookResult = ReturnType<
  typeof useResourcePreviewWithAccessLevelsLazyQuery
>;
export type ResourcePreviewWithAccessLevelsQueryResult = Apollo.QueryResult<
  ResourcePreviewWithAccessLevelsQuery,
  ResourcePreviewWithAccessLevelsQueryVariables
>;
export const RemoteResourcesDocument = gql`
  query RemoteResources($input: RemoteResourcesInput!) {
    remoteResources(input: $input) {
      __typename
      ... on RemoteResourcesResult {
        remoteResources {
          ...RemoteResource
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on MissingIAMPermissionError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${RemoteResourceFragmentDoc}
`;

/**
 * __useRemoteResourcesQuery__
 *
 * To run a query within a React component, call `useRemoteResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoteResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemoteResourcesQuery,
    RemoteResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemoteResourcesQuery, RemoteResourcesQueryVariables>(
    RemoteResourcesDocument,
    options
  );
}
export function useRemoteResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemoteResourcesQuery,
    RemoteResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RemoteResourcesQuery,
    RemoteResourcesQueryVariables
  >(RemoteResourcesDocument, options);
}
export type RemoteResourcesQueryHookResult = ReturnType<
  typeof useRemoteResourcesQuery
>;
export type RemoteResourcesLazyQueryHookResult = ReturnType<
  typeof useRemoteResourcesLazyQuery
>;
export type RemoteResourcesQueryResult = Apollo.QueryResult<
  RemoteResourcesQuery,
  RemoteResourcesQueryVariables
>;
export const CreateResourceDocument = gql`
  mutation CreateResource($input: CreateResourceInput!) {
    createResource(input: $input) {
      __typename
      ... on CreateResourceResult {
        resource {
          ...ResourcePreviewSmall
        }
      }
      ... on AWSRolePolicyInvalidError {
        message
      }
      ... on AWSRoleCreationError {
        message
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;
export type CreateResourceMutationFn = Apollo.MutationFunction<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateResourceMutation,
    CreateResourceMutationVariables
  >(CreateResourceDocument, options);
}
export type CreateResourceMutationHookResult = ReturnType<
  typeof useCreateResourceMutation
>;
export type CreateResourceMutationResult = Apollo.MutationResult<
  CreateResourceMutation
>;
export type CreateResourceMutationOptions = Apollo.BaseMutationOptions<
  CreateResourceMutation,
  CreateResourceMutationVariables
>;
export const CreateResourcesDocument = gql`
  mutation CreateResources($input: CreateResourcesInput!) {
    createResources(input: $input) {
      __typename
      ... on CreateResourcesResult {
        entries {
          __typename
          ... on CreateResourceEntryResult {
            resource {
              ...ResourcePreviewLarge
            }
          }
        }
        syncTask {
          ...SyncTask
        }
      }
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${SyncTaskFragmentDoc}
`;
export type CreateResourcesMutationFn = Apollo.MutationFunction<
  CreateResourcesMutation,
  CreateResourcesMutationVariables
>;

/**
 * __useCreateResourcesMutation__
 *
 * To run a mutation, you first call `useCreateResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourcesMutation, { data, loading, error }] = useCreateResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateResourcesMutation,
    CreateResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateResourcesMutation,
    CreateResourcesMutationVariables
  >(CreateResourcesDocument, options);
}
export type CreateResourcesMutationHookResult = ReturnType<
  typeof useCreateResourcesMutation
>;
export type CreateResourcesMutationResult = Apollo.MutationResult<
  CreateResourcesMutation
>;
export type CreateResourcesMutationOptions = Apollo.BaseMutationOptions<
  CreateResourcesMutation,
  CreateResourcesMutationVariables
>;
export const UpdateResourcesDocument = gql`
  mutation UpdateResources($input: UpdateResourcesInput!) {
    updateResources(input: $input) {
      __typename
      ... on UpdateResourcesResult {
        resources {
          ...ResourcePreviewSmall
        }
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on OpalResourceImmutableError {
        message
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;
export type UpdateResourcesMutationFn = Apollo.MutationFunction<
  UpdateResourcesMutation,
  UpdateResourcesMutationVariables
>;

/**
 * __useUpdateResourcesMutation__
 *
 * To run a mutation, you first call `useUpdateResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourcesMutation, { data, loading, error }] = useUpdateResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourcesMutation,
    UpdateResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourcesMutation,
    UpdateResourcesMutationVariables
  >(UpdateResourcesDocument, options);
}
export type UpdateResourcesMutationHookResult = ReturnType<
  typeof useUpdateResourcesMutation
>;
export type UpdateResourcesMutationResult = Apollo.MutationResult<
  UpdateResourcesMutation
>;
export type UpdateResourcesMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourcesMutation,
  UpdateResourcesMutationVariables
>;
export const DeleteResourcesDocument = gql`
  mutation DeleteResources($input: DeleteResourcesInput!) {
    deleteResources(input: $input) {
      __typename
      ... on DeleteResourcesResult {
        success
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on OpalResourceImmutableError {
        message
      }
    }
  }
`;
export type DeleteResourcesMutationFn = Apollo.MutationFunction<
  DeleteResourcesMutation,
  DeleteResourcesMutationVariables
>;

/**
 * __useDeleteResourcesMutation__
 *
 * To run a mutation, you first call `useDeleteResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourcesMutation, { data, loading, error }] = useDeleteResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResourcesMutation,
    DeleteResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteResourcesMutation,
    DeleteResourcesMutationVariables
  >(DeleteResourcesDocument, options);
}
export type DeleteResourcesMutationHookResult = ReturnType<
  typeof useDeleteResourcesMutation
>;
export type DeleteResourcesMutationResult = Apollo.MutationResult<
  DeleteResourcesMutation
>;
export type DeleteResourcesMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourcesMutation,
  DeleteResourcesMutationVariables
>;
export const ImportCustomResourcesDryRunDocument = gql`
  mutation ImportCustomResourcesDryRun($input: ImportCustomResourcesInput!) {
    importCustomResourcesDryRun(input: $input) {
      __typename
      ... on ImportCustomResourcesDryRunResult {
        resourcesToCreate
        existingResources
      }
      ... on ImportCustomResourcesInputValidationError {
        message
        reasons
      }
    }
  }
`;
export type ImportCustomResourcesDryRunMutationFn = Apollo.MutationFunction<
  ImportCustomResourcesDryRunMutation,
  ImportCustomResourcesDryRunMutationVariables
>;

/**
 * __useImportCustomResourcesDryRunMutation__
 *
 * To run a mutation, you first call `useImportCustomResourcesDryRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomResourcesDryRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomResourcesDryRunMutation, { data, loading, error }] = useImportCustomResourcesDryRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCustomResourcesDryRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportCustomResourcesDryRunMutation,
    ImportCustomResourcesDryRunMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportCustomResourcesDryRunMutation,
    ImportCustomResourcesDryRunMutationVariables
  >(ImportCustomResourcesDryRunDocument, options);
}
export type ImportCustomResourcesDryRunMutationHookResult = ReturnType<
  typeof useImportCustomResourcesDryRunMutation
>;
export type ImportCustomResourcesDryRunMutationResult = Apollo.MutationResult<
  ImportCustomResourcesDryRunMutation
>;
export type ImportCustomResourcesDryRunMutationOptions = Apollo.BaseMutationOptions<
  ImportCustomResourcesDryRunMutation,
  ImportCustomResourcesDryRunMutationVariables
>;
export const ImportCustomResourcesDocument = gql`
  mutation ImportCustomResources($input: ImportCustomResourcesInput!) {
    importCustomResources(input: $input) {
      __typename
      ... on ImportCustomResourcesResult {
        success
      }
      ... on ImportCustomResourcesInputValidationError {
        message
        reasons
      }
    }
  }
`;
export type ImportCustomResourcesMutationFn = Apollo.MutationFunction<
  ImportCustomResourcesMutation,
  ImportCustomResourcesMutationVariables
>;

/**
 * __useImportCustomResourcesMutation__
 *
 * To run a mutation, you first call `useImportCustomResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportCustomResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importCustomResourcesMutation, { data, loading, error }] = useImportCustomResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportCustomResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportCustomResourcesMutation,
    ImportCustomResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportCustomResourcesMutation,
    ImportCustomResourcesMutationVariables
  >(ImportCustomResourcesDocument, options);
}
export type ImportCustomResourcesMutationHookResult = ReturnType<
  typeof useImportCustomResourcesMutation
>;
export type ImportCustomResourcesMutationResult = Apollo.MutationResult<
  ImportCustomResourcesMutation
>;
export type ImportCustomResourcesMutationOptions = Apollo.BaseMutationOptions<
  ImportCustomResourcesMutation,
  ImportCustomResourcesMutationVariables
>;
export const UpdateResourceVisibilityGroupsDocument = gql`
  mutation UpdateResourceVisibilityGroups(
    $input: UpdateResourceVisibilityGroupsInput!
  ) {
    updateResourceVisibilityGroups(input: $input) {
      __typename
      ... on UpdateResourceVisibilityGroupsResult {
        resource {
          ...ResourcePreviewSmall
        }
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on GroupNotFoundError {
        message
      }
      ... on InvalidUpdateResourceVisibilityGroupError {
        message
      }
      ... on ConfigurationVisibilityGroupNotFoundError {
        message
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;
export type UpdateResourceVisibilityGroupsMutationFn = Apollo.MutationFunction<
  UpdateResourceVisibilityGroupsMutation,
  UpdateResourceVisibilityGroupsMutationVariables
>;

/**
 * __useUpdateResourceVisibilityGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateResourceVisibilityGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceVisibilityGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceVisibilityGroupsMutation, { data, loading, error }] = useUpdateResourceVisibilityGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceVisibilityGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourceVisibilityGroupsMutation,
    UpdateResourceVisibilityGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourceVisibilityGroupsMutation,
    UpdateResourceVisibilityGroupsMutationVariables
  >(UpdateResourceVisibilityGroupsDocument, options);
}
export type UpdateResourceVisibilityGroupsMutationHookResult = ReturnType<
  typeof useUpdateResourceVisibilityGroupsMutation
>;
export type UpdateResourceVisibilityGroupsMutationResult = Apollo.MutationResult<
  UpdateResourceVisibilityGroupsMutation
>;
export type UpdateResourceVisibilityGroupsMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceVisibilityGroupsMutation,
  UpdateResourceVisibilityGroupsMutationVariables
>;
export const RecordResourceViewDocument = gql`
  mutation RecordResourceView($input: RecordResourceViewInput!) {
    recordResourceView(input: $input) {
      __typename
      ... on RecordResourceViewResult {
        success
      }
    }
  }
`;
export type RecordResourceViewMutationFn = Apollo.MutationFunction<
  RecordResourceViewMutation,
  RecordResourceViewMutationVariables
>;

/**
 * __useRecordResourceViewMutation__
 *
 * To run a mutation, you first call `useRecordResourceViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordResourceViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordResourceViewMutation, { data, loading, error }] = useRecordResourceViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordResourceViewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecordResourceViewMutation,
    RecordResourceViewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RecordResourceViewMutation,
    RecordResourceViewMutationVariables
  >(RecordResourceViewDocument, options);
}
export type RecordResourceViewMutationHookResult = ReturnType<
  typeof useRecordResourceViewMutation
>;
export type RecordResourceViewMutationResult = Apollo.MutationResult<
  RecordResourceViewMutation
>;
export type RecordResourceViewMutationOptions = Apollo.BaseMutationOptions<
  RecordResourceViewMutation,
  RecordResourceViewMutationVariables
>;
export const AddRoleAssignmentsDocument = gql`
  mutation AddRoleAssignments($input: AddRoleAssignmentsInput!) {
    addRoleAssignments(input: $input) {
      __typename
      ... on AddRoleAssignmentsResult {
        taskIds
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type AddRoleAssignmentsMutationFn = Apollo.MutationFunction<
  AddRoleAssignmentsMutation,
  AddRoleAssignmentsMutationVariables
>;

/**
 * __useAddRoleAssignmentsMutation__
 *
 * To run a mutation, you first call `useAddRoleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleAssignmentsMutation, { data, loading, error }] = useAddRoleAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRoleAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRoleAssignmentsMutation,
    AddRoleAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRoleAssignmentsMutation,
    AddRoleAssignmentsMutationVariables
  >(AddRoleAssignmentsDocument, options);
}
export type AddRoleAssignmentsMutationHookResult = ReturnType<
  typeof useAddRoleAssignmentsMutation
>;
export type AddRoleAssignmentsMutationResult = Apollo.MutationResult<
  AddRoleAssignmentsMutation
>;
export type AddRoleAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  AddRoleAssignmentsMutation,
  AddRoleAssignmentsMutationVariables
>;
export const RemoveRoleAssignmentsDocument = gql`
  mutation RemoveRoleAssignments($input: RemoveRoleAssignmentsInput!) {
    removeRoleAssignments(input: $input) {
      __typename
      ... on RemoveRoleAssignmentsResult {
        taskIds
      }
    }
  }
`;
export type RemoveRoleAssignmentsMutationFn = Apollo.MutationFunction<
  RemoveRoleAssignmentsMutation,
  RemoveRoleAssignmentsMutationVariables
>;

/**
 * __useRemoveRoleAssignmentsMutation__
 *
 * To run a mutation, you first call `useRemoveRoleAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleAssignmentsMutation, { data, loading, error }] = useRemoveRoleAssignmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRoleAssignmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRoleAssignmentsMutation,
    RemoveRoleAssignmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRoleAssignmentsMutation,
    RemoveRoleAssignmentsMutationVariables
  >(RemoveRoleAssignmentsDocument, options);
}
export type RemoveRoleAssignmentsMutationHookResult = ReturnType<
  typeof useRemoveRoleAssignmentsMutation
>;
export type RemoveRoleAssignmentsMutationResult = Apollo.MutationResult<
  RemoveRoleAssignmentsMutation
>;
export type RemoveRoleAssignmentsMutationOptions = Apollo.BaseMutationOptions<
  RemoveRoleAssignmentsMutation,
  RemoveRoleAssignmentsMutationVariables
>;
export const SearchDocument = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      __typename
      ... on SearchResult {
        entries {
          ...SearchResultEntry
        }
      }
    }
  }
  ${SearchResultEntryFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const SessionsDocument = gql`
  query Sessions($input: SessionsInput!) {
    sessions(input: $input) {
      __typename
      ... on SessionsResult {
        sessions {
          ...Session
        }
      }
    }
  }
  ${SessionFragmentDoc}
`;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    options
  );
}
export function useSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionsQuery,
    SessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(
    SessionsDocument,
    options
  );
}
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<
  typeof useSessionsLazyQuery
>;
export type SessionsQueryResult = Apollo.QueryResult<
  SessionsQuery,
  SessionsQueryVariables
>;
export const VaultSessionsDocument = gql`
  query VaultSessions($input: VaultSessionsInput!) {
    vaultSessions(input: $input) {
      __typename
      ... on VaultSessionsResult {
        sessions {
          ...Session
        }
      }
    }
  }
  ${SessionFragmentDoc}
`;

/**
 * __useVaultSessionsQuery__
 *
 * To run a query within a React component, call `useVaultSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVaultSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVaultSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVaultSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VaultSessionsQuery,
    VaultSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VaultSessionsQuery, VaultSessionsQueryVariables>(
    VaultSessionsDocument,
    options
  );
}
export function useVaultSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VaultSessionsQuery,
    VaultSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VaultSessionsQuery, VaultSessionsQueryVariables>(
    VaultSessionsDocument,
    options
  );
}
export type VaultSessionsQueryHookResult = ReturnType<
  typeof useVaultSessionsQuery
>;
export type VaultSessionsLazyQueryHookResult = ReturnType<
  typeof useVaultSessionsLazyQuery
>;
export type VaultSessionsQueryResult = Apollo.QueryResult<
  VaultSessionsQuery,
  VaultSessionsQueryVariables
>;
export const CreateSessionDocument = gql`
  mutation CreateSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      __typename
      ... on CreateSessionResult {
        session {
          ...Session
        }
      }
      ... on SessionNotFoundError {
        message
      }
      ... on NotSessionableError {
        message
      }
      ... on MfaInvalidError {
        message
      }
      ... on OidcIDTokenNotFoundError {
        message
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on UserImpersonationDisabledError {
        message
      }
      ... on ImpersonationSessionLengthError {
        message
      }
      ... on EndSystemAuthorizationError {
        message
      }
      ... on UserFacingError {
        message
      }
      ... on VaultClientNotFoundError {
        message
      }
      ... on VaultSessionError {
        message
        connection {
          id
          name
        }
      }
    }
  }
  ${SessionFragmentDoc}
`;
export type CreateSessionMutationFn = Apollo.MutationFunction<
  CreateSessionMutation,
  CreateSessionMutationVariables
>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSessionMutation,
    CreateSessionMutationVariables
  >(CreateSessionDocument, options);
}
export type CreateSessionMutationHookResult = ReturnType<
  typeof useCreateSessionMutation
>;
export type CreateSessionMutationResult = Apollo.MutationResult<
  CreateSessionMutation
>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateSessionMutation,
  CreateSessionMutationVariables
>;
export const TicketsDocument = gql`
  query Tickets($input: SupportTicketsInput!) {
    supportTickets(input: $input) {
      __typename
      ... on SupportTicketsResult {
        tickets {
          ...SupportTicket
        }
      }
      ... on SupportTicketAPIKeyNotFoundError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${SupportTicketFragmentDoc}
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketsQuery(
  baseOptions: Apollo.QueryHookOptions<TicketsQuery, TicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketsQuery, TicketsQueryVariables>(
    TicketsDocument,
    options
  );
}
export function useTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketsQuery, TicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketsQuery, TicketsQueryVariables>(
    TicketsDocument,
    options
  );
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<
  TicketsQuery,
  TicketsQueryVariables
>;
export const TicketProjectsDocument = gql`
  query TicketProjects($input: SupportTicketsInput!) {
    ticketProjects(input: $input) {
      __typename
      ... on TicketProjectsResult {
        ticketProjects {
          ...TicketProject
        }
      }
      ... on SupportTicketAPIKeyNotFoundError {
        message
      }
    }
  }
  ${TicketProjectFragmentDoc}
`;

/**
 * __useTicketProjectsQuery__
 *
 * To run a query within a React component, call `useTicketProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketProjectsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TicketProjectsQuery,
    TicketProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketProjectsQuery, TicketProjectsQueryVariables>(
    TicketProjectsDocument,
    options
  );
}
export function useTicketProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TicketProjectsQuery,
    TicketProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketProjectsQuery, TicketProjectsQueryVariables>(
    TicketProjectsDocument,
    options
  );
}
export type TicketProjectsQueryHookResult = ReturnType<
  typeof useTicketProjectsQuery
>;
export type TicketProjectsLazyQueryHookResult = ReturnType<
  typeof useTicketProjectsLazyQuery
>;
export type TicketProjectsQueryResult = Apollo.QueryResult<
  TicketProjectsQuery,
  TicketProjectsQueryVariables
>;
export const TicketDocument = gql`
  query Ticket($input: SupportTicketByIdInput!) {
    supportTicket(input: $input) {
      __typename
      ... on SupportTicketResult {
        ticket {
          ...SupportTicket
        }
      }
      ... on SupportTicketNotFoundError {
        message
      }
    }
  }
  ${SupportTicketFragmentDoc}
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketQuery(
  baseOptions: Apollo.QueryHookOptions<TicketQuery, TicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TicketQuery, TicketQueryVariables>(
    TicketDocument,
    options
  );
}
export function useTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TicketQuery, TicketQueryVariables>(
    TicketDocument,
    options
  );
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = Apollo.QueryResult<
  TicketQuery,
  TicketQueryVariables
>;
export const SyncStatusDocument = gql`
  query SyncStatus($input: SyncStatusInput!) {
    syncStatus(input: $input) {
      __typename
      ... on SyncStatusResult {
        lastSuccessfulSyncTask {
          ...SyncTask
        }
        ongoingSyncTask {
          ...SyncTask
        }
        syncErrors {
          ...SyncError
        }
      }
      ... on InvalidSyncTypeError {
        message
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${SyncTaskFragmentDoc}
  ${SyncErrorFragmentDoc}
`;

/**
 * __useSyncStatusQuery__
 *
 * To run a query within a React component, call `useSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    SyncStatusQuery,
    SyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyncStatusQuery, SyncStatusQueryVariables>(
    SyncStatusDocument,
    options
  );
}
export function useSyncStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyncStatusQuery,
    SyncStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyncStatusQuery, SyncStatusQueryVariables>(
    SyncStatusDocument,
    options
  );
}
export type SyncStatusQueryHookResult = ReturnType<typeof useSyncStatusQuery>;
export type SyncStatusLazyQueryHookResult = ReturnType<
  typeof useSyncStatusLazyQuery
>;
export type SyncStatusQueryResult = Apollo.QueryResult<
  SyncStatusQuery,
  SyncStatusQueryVariables
>;
export const SyncTaskDocument = gql`
  query SyncTask($input: SyncTaskInput!) {
    syncTask(input: $input) {
      __typename
      ... on SyncTaskResult {
        syncTask {
          ...SyncTask
        }
      }
      ... on SyncTaskNotFoundError {
        message
      }
    }
  }
  ${SyncTaskFragmentDoc}
`;

/**
 * __useSyncTaskQuery__
 *
 * To run a query within a React component, call `useSyncTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncTaskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncTaskQuery(
  baseOptions: Apollo.QueryHookOptions<SyncTaskQuery, SyncTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SyncTaskQuery, SyncTaskQueryVariables>(
    SyncTaskDocument,
    options
  );
}
export function useSyncTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SyncTaskQuery,
    SyncTaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SyncTaskQuery, SyncTaskQueryVariables>(
    SyncTaskDocument,
    options
  );
}
export type SyncTaskQueryHookResult = ReturnType<typeof useSyncTaskQuery>;
export type SyncTaskLazyQueryHookResult = ReturnType<
  typeof useSyncTaskLazyQuery
>;
export type SyncTaskQueryResult = Apollo.QueryResult<
  SyncTaskQuery,
  SyncTaskQueryVariables
>;
export const StartSyncDocument = gql`
  mutation StartSync($input: StartSyncInput!) {
    startSync(input: $input) {
      __typename
      ... on StartSyncResult {
        syncTask {
          ...SyncTask
        }
      }
      ... on InvalidSyncTypeError {
        message
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ResourceNotFoundError {
        message
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
  ${SyncTaskFragmentDoc}
`;
export type StartSyncMutationFn = Apollo.MutationFunction<
  StartSyncMutation,
  StartSyncMutationVariables
>;

/**
 * __useStartSyncMutation__
 *
 * To run a mutation, you first call `useStartSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSyncMutation, { data, loading, error }] = useStartSyncMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartSyncMutation,
    StartSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartSyncMutation, StartSyncMutationVariables>(
    StartSyncDocument,
    options
  );
}
export type StartSyncMutationHookResult = ReturnType<
  typeof useStartSyncMutation
>;
export type StartSyncMutationResult = Apollo.MutationResult<StartSyncMutation>;
export type StartSyncMutationOptions = Apollo.BaseMutationOptions<
  StartSyncMutation,
  StartSyncMutationVariables
>;
export const DismissSyncErrorsDocument = gql`
  mutation DismissSyncErrors($input: DismissSyncErrorsInput!) {
    dismissSyncErrors(input: $input) {
      __typename
      ... on DismissSyncErrorsResult {
        ids
      }
      ... on SyncErrorNotFoundError {
        message
      }
    }
  }
`;
export type DismissSyncErrorsMutationFn = Apollo.MutationFunction<
  DismissSyncErrorsMutation,
  DismissSyncErrorsMutationVariables
>;

/**
 * __useDismissSyncErrorsMutation__
 *
 * To run a mutation, you first call `useDismissSyncErrorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSyncErrorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSyncErrorsMutation, { data, loading, error }] = useDismissSyncErrorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissSyncErrorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissSyncErrorsMutation,
    DismissSyncErrorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissSyncErrorsMutation,
    DismissSyncErrorsMutationVariables
  >(DismissSyncErrorsDocument, options);
}
export type DismissSyncErrorsMutationHookResult = ReturnType<
  typeof useDismissSyncErrorsMutation
>;
export type DismissSyncErrorsMutationResult = Apollo.MutationResult<
  DismissSyncErrorsMutation
>;
export type DismissSyncErrorsMutationOptions = Apollo.BaseMutationOptions<
  DismissSyncErrorsMutation,
  DismissSyncErrorsMutationVariables
>;
export const TagsDocument = gql`
  query Tags($input: TagsInput!) {
    tags(input: $input) {
      __typename
      ... on TagsResult {
        tags {
          ...TagPreviewLarge
        }
        cursor
      }
    }
  }
  ${TagPreviewLargeFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsQuery(
  baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    options
  );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocument = gql`
  query Tag($input: TagInput!) {
    tag(input: $input) {
      __typename
      ... on TagResult {
        tag {
          ...Tag
        }
      }
      ... on TagNotFoundError {
        message
      }
    }
  }
  ${TagFragmentDoc}
`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagQuery(
  baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export function useTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const CreateTagDocument = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      __typename
      ... on CreateTagResult {
        tag {
          id
        }
      }
      ... on TagExistsError {
        message
      }
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options
  );
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const DeleteTagsDocument = gql`
  mutation DeleteTags($input: DeleteTagsInput!) {
    deleteTags(input: $input) {
      __typename
      ... on DeleteTagsResult {
        entries {
          tag {
            ...Tag
          }
        }
      }
      ... on TagNotFoundError {
        message
      }
    }
  }
  ${TagFragmentDoc}
`;
export type DeleteTagsMutationFn = Apollo.MutationFunction<
  DeleteTagsMutation,
  DeleteTagsMutationVariables
>;

/**
 * __useDeleteTagsMutation__
 *
 * To run a mutation, you first call `useDeleteTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagsMutation, { data, loading, error }] = useDeleteTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTagsMutation,
    DeleteTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagsMutation, DeleteTagsMutationVariables>(
    DeleteTagsDocument,
    options
  );
}
export type DeleteTagsMutationHookResult = ReturnType<
  typeof useDeleteTagsMutation
>;
export type DeleteTagsMutationResult = Apollo.MutationResult<
  DeleteTagsMutation
>;
export type DeleteTagsMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagsMutation,
  DeleteTagsMutationVariables
>;
export const ThirdPartyIntegrationDocument = gql`
  query ThirdPartyIntegration($input: ThirdPartyIntegrationInput!) {
    thirdPartyIntegration(input: $input) {
      __typename
      ... on ThirdPartyIntegrationResult {
        thirdPartyIntegration {
          ...ThirdPartyIntegration
        }
      }
      ... on ThirdPartyIntegrationNotFoundError {
        message
      }
    }
  }
  ${ThirdPartyIntegrationFragmentDoc}
`;

/**
 * __useThirdPartyIntegrationQuery__
 *
 * To run a query within a React component, call `useThirdPartyIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyIntegrationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThirdPartyIntegrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThirdPartyIntegrationQuery,
    ThirdPartyIntegrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ThirdPartyIntegrationQuery,
    ThirdPartyIntegrationQueryVariables
  >(ThirdPartyIntegrationDocument, options);
}
export function useThirdPartyIntegrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThirdPartyIntegrationQuery,
    ThirdPartyIntegrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ThirdPartyIntegrationQuery,
    ThirdPartyIntegrationQueryVariables
  >(ThirdPartyIntegrationDocument, options);
}
export type ThirdPartyIntegrationQueryHookResult = ReturnType<
  typeof useThirdPartyIntegrationQuery
>;
export type ThirdPartyIntegrationLazyQueryHookResult = ReturnType<
  typeof useThirdPartyIntegrationLazyQuery
>;
export type ThirdPartyIntegrationQueryResult = Apollo.QueryResult<
  ThirdPartyIntegrationQuery,
  ThirdPartyIntegrationQueryVariables
>;
export const ThirdPartyIntegrationsDocument = gql`
  query ThirdPartyIntegrations($input: ThirdPartyIntegrationsInput!) {
    thirdPartyIntegrations(input: $input) {
      __typename
      ... on ThirdPartyIntegrationsResult {
        thirdPartyIntegrations {
          ...ThirdPartyIntegration
        }
      }
    }
  }
  ${ThirdPartyIntegrationFragmentDoc}
`;

/**
 * __useThirdPartyIntegrationsQuery__
 *
 * To run a query within a React component, call `useThirdPartyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThirdPartyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThirdPartyIntegrationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThirdPartyIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThirdPartyIntegrationsQuery,
    ThirdPartyIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ThirdPartyIntegrationsQuery,
    ThirdPartyIntegrationsQueryVariables
  >(ThirdPartyIntegrationsDocument, options);
}
export function useThirdPartyIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThirdPartyIntegrationsQuery,
    ThirdPartyIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ThirdPartyIntegrationsQuery,
    ThirdPartyIntegrationsQueryVariables
  >(ThirdPartyIntegrationsDocument, options);
}
export type ThirdPartyIntegrationsQueryHookResult = ReturnType<
  typeof useThirdPartyIntegrationsQuery
>;
export type ThirdPartyIntegrationsLazyQueryHookResult = ReturnType<
  typeof useThirdPartyIntegrationsLazyQuery
>;
export type ThirdPartyIntegrationsQueryResult = Apollo.QueryResult<
  ThirdPartyIntegrationsQuery,
  ThirdPartyIntegrationsQueryVariables
>;
export const InitThirdPartyIntegrationAuthFlowDocument = gql`
  mutation InitThirdPartyIntegrationAuthFlow(
    $input: InitThirdPartyIntegrationAuthFlowInput!
  ) {
    initThirdPartyIntegrationAuthFlow(input: $input) {
      __typename
      ... on InitThirdPartyIntegrationAuthFlowResult {
        url
      }
      ... on ConnectionNotFoundError {
        message
      }
      ... on ThirdPartyIntegrationNotFoundError {
        message
      }
    }
  }
`;
export type InitThirdPartyIntegrationAuthFlowMutationFn = Apollo.MutationFunction<
  InitThirdPartyIntegrationAuthFlowMutation,
  InitThirdPartyIntegrationAuthFlowMutationVariables
>;

/**
 * __useInitThirdPartyIntegrationAuthFlowMutation__
 *
 * To run a mutation, you first call `useInitThirdPartyIntegrationAuthFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitThirdPartyIntegrationAuthFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initThirdPartyIntegrationAuthFlowMutation, { data, loading, error }] = useInitThirdPartyIntegrationAuthFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitThirdPartyIntegrationAuthFlowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitThirdPartyIntegrationAuthFlowMutation,
    InitThirdPartyIntegrationAuthFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitThirdPartyIntegrationAuthFlowMutation,
    InitThirdPartyIntegrationAuthFlowMutationVariables
  >(InitThirdPartyIntegrationAuthFlowDocument, options);
}
export type InitThirdPartyIntegrationAuthFlowMutationHookResult = ReturnType<
  typeof useInitThirdPartyIntegrationAuthFlowMutation
>;
export type InitThirdPartyIntegrationAuthFlowMutationResult = Apollo.MutationResult<
  InitThirdPartyIntegrationAuthFlowMutation
>;
export type InitThirdPartyIntegrationAuthFlowMutationOptions = Apollo.BaseMutationOptions<
  InitThirdPartyIntegrationAuthFlowMutation,
  InitThirdPartyIntegrationAuthFlowMutationVariables
>;
export const CreateThirdPartyIntegrationDocument = gql`
  mutation CreateThirdPartyIntegration(
    $input: CreateThirdPartyIntegrationInput!
  ) {
    createThirdPartyIntegration(input: $input) {
      __typename
      ... on CreateThirdPartyIntegrationResult {
        thirdPartyIntegration {
          ...ThirdPartyIntegration
        }
      }
      ... on ThirdPartyUserNotMatchedError {
        message
      }
      ... on ThirdPartyConnectionNotMatchedError {
        message
      }
      ... on SlackOrganizationAlreadyExistsError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
  ${ThirdPartyIntegrationFragmentDoc}
`;
export type CreateThirdPartyIntegrationMutationFn = Apollo.MutationFunction<
  CreateThirdPartyIntegrationMutation,
  CreateThirdPartyIntegrationMutationVariables
>;

/**
 * __useCreateThirdPartyIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateThirdPartyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThirdPartyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThirdPartyIntegrationMutation, { data, loading, error }] = useCreateThirdPartyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThirdPartyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateThirdPartyIntegrationMutation,
    CreateThirdPartyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateThirdPartyIntegrationMutation,
    CreateThirdPartyIntegrationMutationVariables
  >(CreateThirdPartyIntegrationDocument, options);
}
export type CreateThirdPartyIntegrationMutationHookResult = ReturnType<
  typeof useCreateThirdPartyIntegrationMutation
>;
export type CreateThirdPartyIntegrationMutationResult = Apollo.MutationResult<
  CreateThirdPartyIntegrationMutation
>;
export type CreateThirdPartyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateThirdPartyIntegrationMutation,
  CreateThirdPartyIntegrationMutationVariables
>;
export const DeleteThirdPartyIntegrationDocument = gql`
  mutation DeleteThirdPartyIntegration(
    $input: DeleteThirdPartyIntegrationInput!
  ) {
    deleteThirdPartyIntegration(input: $input) {
      __typename
      ... on DeleteThirdPartyIntegrationResult {
        thirdPartyIntegration {
          ...ThirdPartyIntegration
        }
      }
    }
  }
  ${ThirdPartyIntegrationFragmentDoc}
`;
export type DeleteThirdPartyIntegrationMutationFn = Apollo.MutationFunction<
  DeleteThirdPartyIntegrationMutation,
  DeleteThirdPartyIntegrationMutationVariables
>;

/**
 * __useDeleteThirdPartyIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteThirdPartyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThirdPartyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThirdPartyIntegrationMutation, { data, loading, error }] = useDeleteThirdPartyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteThirdPartyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThirdPartyIntegrationMutation,
    DeleteThirdPartyIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteThirdPartyIntegrationMutation,
    DeleteThirdPartyIntegrationMutationVariables
  >(DeleteThirdPartyIntegrationDocument, options);
}
export type DeleteThirdPartyIntegrationMutationHookResult = ReturnType<
  typeof useDeleteThirdPartyIntegrationMutation
>;
export type DeleteThirdPartyIntegrationMutationResult = Apollo.MutationResult<
  DeleteThirdPartyIntegrationMutation
>;
export type DeleteThirdPartyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DeleteThirdPartyIntegrationMutation,
  DeleteThirdPartyIntegrationMutationVariables
>;
export const InitThirdPartyIntegrationLinkTokenDocument = gql`
  mutation InitThirdPartyIntegrationLinkToken {
    initThirdPartyIntegrationLinkAuthFlow {
      __typename
      ... on InitThirdPartyIntegrationLinkAuthFlowResult {
        linkToken
      }
    }
  }
`;
export type InitThirdPartyIntegrationLinkTokenMutationFn = Apollo.MutationFunction<
  InitThirdPartyIntegrationLinkTokenMutation,
  InitThirdPartyIntegrationLinkTokenMutationVariables
>;

/**
 * __useInitThirdPartyIntegrationLinkTokenMutation__
 *
 * To run a mutation, you first call `useInitThirdPartyIntegrationLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitThirdPartyIntegrationLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initThirdPartyIntegrationLinkTokenMutation, { data, loading, error }] = useInitThirdPartyIntegrationLinkTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitThirdPartyIntegrationLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitThirdPartyIntegrationLinkTokenMutation,
    InitThirdPartyIntegrationLinkTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitThirdPartyIntegrationLinkTokenMutation,
    InitThirdPartyIntegrationLinkTokenMutationVariables
  >(InitThirdPartyIntegrationLinkTokenDocument, options);
}
export type InitThirdPartyIntegrationLinkTokenMutationHookResult = ReturnType<
  typeof useInitThirdPartyIntegrationLinkTokenMutation
>;
export type InitThirdPartyIntegrationLinkTokenMutationResult = Apollo.MutationResult<
  InitThirdPartyIntegrationLinkTokenMutation
>;
export type InitThirdPartyIntegrationLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  InitThirdPartyIntegrationLinkTokenMutation,
  InitThirdPartyIntegrationLinkTokenMutationVariables
>;
export const UserSettingsDocument = gql`
  query UserSettings {
    userSettings {
      __typename
      ... on UserSettingsResult {
        userSettings {
          ...UserSettings
        }
      }
    }
  }
  ${UserSettingsFragmentDoc}
`;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export function useUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export type UserSettingsQueryHookResult = ReturnType<
  typeof useUserSettingsQuery
>;
export type UserSettingsLazyQueryHookResult = ReturnType<
  typeof useUserSettingsLazyQuery
>;
export type UserSettingsQueryResult = Apollo.QueryResult<
  UserSettingsQuery,
  UserSettingsQueryVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      __typename
      ... on UpdateUserSettingsResult {
        userSettings {
          ...UserSettings
        }
      }
    }
  }
  ${UserSettingsFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, options);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<
  UpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const AddUserTagsDocument = gql`
  mutation AddUserTags($input: AddUserTagsInput!) {
    addUserTags(input: $input) {
      __typename
      ... on AddUserTagsResult {
        entries {
          __typename
          ... on AddUserTagsEntryResult {
            userTag {
              userId
              tagId
            }
          }
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
      ... on TagNotFoundError {
        message
      }
    }
  }
`;
export type AddUserTagsMutationFn = Apollo.MutationFunction<
  AddUserTagsMutation,
  AddUserTagsMutationVariables
>;

/**
 * __useAddUserTagsMutation__
 *
 * To run a mutation, you first call `useAddUserTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserTagsMutation, { data, loading, error }] = useAddUserTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserTagsMutation,
    AddUserTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserTagsMutation, AddUserTagsMutationVariables>(
    AddUserTagsDocument,
    options
  );
}
export type AddUserTagsMutationHookResult = ReturnType<
  typeof useAddUserTagsMutation
>;
export type AddUserTagsMutationResult = Apollo.MutationResult<
  AddUserTagsMutation
>;
export type AddUserTagsMutationOptions = Apollo.BaseMutationOptions<
  AddUserTagsMutation,
  AddUserTagsMutationVariables
>;
export const RemoveUserTagsDocument = gql`
  mutation RemoveUserTags($input: RemoveUserTagsInput!) {
    removeUserTags(input: $input) {
      __typename
      ... on RemoveUserTagsResult {
        entries {
          __typename
          ... on RemoveUserTagsEntryResult {
            userTag {
              userId
              tagId
            }
          }
        }
      }
      ... on UserTagNotFoundError {
        message
      }
    }
  }
`;
export type RemoveUserTagsMutationFn = Apollo.MutationFunction<
  RemoveUserTagsMutation,
  RemoveUserTagsMutationVariables
>;

/**
 * __useRemoveUserTagsMutation__
 *
 * To run a mutation, you first call `useRemoveUserTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserTagsMutation, { data, loading, error }] = useRemoveUserTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserTagsMutation,
    RemoveUserTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveUserTagsMutation,
    RemoveUserTagsMutationVariables
  >(RemoveUserTagsDocument, options);
}
export type RemoveUserTagsMutationHookResult = ReturnType<
  typeof useRemoveUserTagsMutation
>;
export type RemoveUserTagsMutationResult = Apollo.MutationResult<
  RemoveUserTagsMutation
>;
export type RemoveUserTagsMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserTagsMutation,
  RemoveUserTagsMutationVariables
>;
export const UsersDocument = gql`
  query Users($input: UsersInput!) {
    users(input: $input) {
      __typename
      ... on UsersResult {
        users {
          ...UserPreviewLarge
        }
        cursor
        totalNumUsers
      }
    }
  }
  ${UserPreviewLargeFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UsersFullDocument = gql`
  query UsersFull($input: UsersInput!) {
    users(input: $input) {
      __typename
      ... on UsersResult {
        users {
          ...User
        }
        cursor
        totalNumUsers
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUsersFullQuery__
 *
 * To run a query within a React component, call `useUsersFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersFullQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersFullQuery(
  baseOptions: Apollo.QueryHookOptions<UsersFullQuery, UsersFullQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersFullQuery, UsersFullQueryVariables>(
    UsersFullDocument,
    options
  );
}
export function useUsersFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersFullQuery,
    UsersFullQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersFullQuery, UsersFullQueryVariables>(
    UsersFullDocument,
    options
  );
}
export type UsersFullQueryHookResult = ReturnType<typeof useUsersFullQuery>;
export type UsersFullLazyQueryHookResult = ReturnType<
  typeof useUsersFullLazyQuery
>;
export type UsersFullQueryResult = Apollo.QueryResult<
  UsersFullQuery,
  UsersFullQueryVariables
>;
export const UserDocument = gql`
  query User($input: UserInput!) {
    user(input: $input) {
      __typename
      ... on UserResult {
        user {
          ...User
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersHomeDocument = gql`
  query UsersHome($input: UsersInput!) {
    users(input: $input) {
      __typename
      ... on UsersResult {
        users {
          ...UserPreviewLarge
        }
        cursor
        totalNumUsers
      }
    }
  }
  ${UserPreviewLargeFragmentDoc}
`;

/**
 * __useUsersHomeQuery__
 *
 * To run a query within a React component, call `useUsersHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersHomeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersHomeQuery(
  baseOptions: Apollo.QueryHookOptions<UsersHomeQuery, UsersHomeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersHomeQuery, UsersHomeQueryVariables>(
    UsersHomeDocument,
    options
  );
}
export function useUsersHomeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersHomeQuery,
    UsersHomeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersHomeQuery, UsersHomeQueryVariables>(
    UsersHomeDocument,
    options
  );
}
export type UsersHomeQueryHookResult = ReturnType<typeof useUsersHomeQuery>;
export type UsersHomeLazyQueryHookResult = ReturnType<
  typeof useUsersHomeLazyQuery
>;
export type UsersHomeQueryResult = Apollo.QueryResult<
  UsersHomeQuery,
  UsersHomeQueryVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      __typename
      ... on DeleteUserResult {
        user {
          ...User
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
      ... on OpalAdminRoleMustHaveAtLeastOneDirectUser {
        message
      }
      ... on SystemUserIsImmutableError {
        message
      }
    }
  }
  ${UserFragmentDoc}
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult = Apollo.MutationResult<
  DeleteUserMutation
>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      __typename
      ... on UpdateUserResult {
        user {
          id
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
      ... on SystemUserIsImmutableError {
        message
      }
      ... on ManagedHRDataIsImmutableError {
        message
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserManagerDocument = gql`
  mutation UpdateUserManager($input: UpdateUserManagerInput!) {
    updateUserManager(input: $input) {
      __typename
      ... on UpdateUserManagerResult {
        user {
          ...User
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
      ... on SystemUserIsImmutableError {
        message
      }
      ... on ManagedHRDataIsImmutableError {
        message
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserManagerMutationFn = Apollo.MutationFunction<
  UpdateUserManagerMutation,
  UpdateUserManagerMutationVariables
>;

/**
 * __useUpdateUserManagerMutation__
 *
 * To run a mutation, you first call `useUpdateUserManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserManagerMutation, { data, loading, error }] = useUpdateUserManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserManagerMutation,
    UpdateUserManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserManagerMutation,
    UpdateUserManagerMutationVariables
  >(UpdateUserManagerDocument, options);
}
export type UpdateUserManagerMutationHookResult = ReturnType<
  typeof useUpdateUserManagerMutation
>;
export type UpdateUserManagerMutationResult = Apollo.MutationResult<
  UpdateUserManagerMutation
>;
export type UpdateUserManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserManagerMutation,
  UpdateUserManagerMutationVariables
>;
export const InviteUsersDocument = gql`
  mutation InviteUsers($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      __typename
      ... on InviteUsersResult {
        invitedUsers {
          ...UserPreviewSmall
        }
      }
      ... on UserAlreadyExistsInAnotherOrg {
        message
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;
export type InviteUsersMutationFn = Apollo.MutationFunction<
  InviteUsersMutation,
  InviteUsersMutationVariables
>;

/**
 * __useInviteUsersMutation__
 *
 * To run a mutation, you first call `useInviteUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUsersMutation, { data, loading, error }] = useInviteUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUsersMutation,
    InviteUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(
    InviteUsersDocument,
    options
  );
}
export type InviteUsersMutationHookResult = ReturnType<
  typeof useInviteUsersMutation
>;
export type InviteUsersMutationResult = Apollo.MutationResult<
  InviteUsersMutation
>;
export type InviteUsersMutationOptions = Apollo.BaseMutationOptions<
  InviteUsersMutation,
  InviteUsersMutationVariables
>;
export const ResetUserMfaDocument = gql`
  mutation ResetUserMFA($input: ResetUserMFAInput!) {
    resetUserMFA(input: $input) {
      __typename
      ... on ResetUserMFAResult {
        ok
      }
      ... on UserNotFoundError {
        message
      }
      ... on SystemUserIsImmutableError {
        message
      }
      ... on ResetAuth0MFAError {
        message
      }
    }
  }
`;
export type ResetUserMfaMutationFn = Apollo.MutationFunction<
  ResetUserMfaMutation,
  ResetUserMfaMutationVariables
>;

/**
 * __useResetUserMfaMutation__
 *
 * To run a mutation, you first call `useResetUserMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserMfaMutation, { data, loading, error }] = useResetUserMfaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserMfaMutation,
    ResetUserMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetUserMfaMutation,
    ResetUserMfaMutationVariables
  >(ResetUserMfaDocument, options);
}
export type ResetUserMfaMutationHookResult = ReturnType<
  typeof useResetUserMfaMutation
>;
export type ResetUserMfaMutationResult = Apollo.MutationResult<
  ResetUserMfaMutation
>;
export type ResetUserMfaMutationOptions = Apollo.BaseMutationOptions<
  ResetUserMfaMutation,
  ResetUserMfaMutationVariables
>;
export const AccessReviewGroupsStatsDocument = gql`
  query AccessReviewGroupsStats($input: CountFilteredGroupsInput!) {
    countFilteredGroups(input: $input) {
      __typename
      ... on CountFilteredGroupsResult {
        totalNumGroups
      }
    }
  }
`;

/**
 * __useAccessReviewGroupsStatsQuery__
 *
 * To run a query within a React component, call `useAccessReviewGroupsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewGroupsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewGroupsStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewGroupsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewGroupsStatsQuery,
    AccessReviewGroupsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewGroupsStatsQuery,
    AccessReviewGroupsStatsQueryVariables
  >(AccessReviewGroupsStatsDocument, options);
}
export function useAccessReviewGroupsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewGroupsStatsQuery,
    AccessReviewGroupsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewGroupsStatsQuery,
    AccessReviewGroupsStatsQueryVariables
  >(AccessReviewGroupsStatsDocument, options);
}
export type AccessReviewGroupsStatsQueryHookResult = ReturnType<
  typeof useAccessReviewGroupsStatsQuery
>;
export type AccessReviewGroupsStatsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewGroupsStatsLazyQuery
>;
export type AccessReviewGroupsStatsQueryResult = Apollo.QueryResult<
  AccessReviewGroupsStatsQuery,
  AccessReviewGroupsStatsQueryVariables
>;
export const AccessReviewResourcesStatsDocument = gql`
  query AccessReviewResourcesStats($input: CountFilteredResourcesInput!) {
    countFilteredResources(input: $input) {
      __typename
      ... on CountFilteredResourcesResult {
        totalNumResources
      }
    }
  }
`;

/**
 * __useAccessReviewResourcesStatsQuery__
 *
 * To run a query within a React component, call `useAccessReviewResourcesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewResourcesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewResourcesStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewResourcesStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewResourcesStatsQuery,
    AccessReviewResourcesStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewResourcesStatsQuery,
    AccessReviewResourcesStatsQueryVariables
  >(AccessReviewResourcesStatsDocument, options);
}
export function useAccessReviewResourcesStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewResourcesStatsQuery,
    AccessReviewResourcesStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewResourcesStatsQuery,
    AccessReviewResourcesStatsQueryVariables
  >(AccessReviewResourcesStatsDocument, options);
}
export type AccessReviewResourcesStatsQueryHookResult = ReturnType<
  typeof useAccessReviewResourcesStatsQuery
>;
export type AccessReviewResourcesStatsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewResourcesStatsLazyQuery
>;
export type AccessReviewResourcesStatsQueryResult = Apollo.QueryResult<
  AccessReviewResourcesStatsQuery,
  AccessReviewResourcesStatsQueryVariables
>;
export const AccessReviewConnectionsStatsDocument = gql`
  query AccessReviewConnectionsStats($input: CountFilteredConnectionsInput!) {
    countFilteredConnections(input: $input) {
      totalNumConnections
    }
  }
`;

/**
 * __useAccessReviewConnectionsStatsQuery__
 *
 * To run a query within a React component, call `useAccessReviewConnectionsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewConnectionsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewConnectionsStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewConnectionsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewConnectionsStatsQuery,
    AccessReviewConnectionsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewConnectionsStatsQuery,
    AccessReviewConnectionsStatsQueryVariables
  >(AccessReviewConnectionsStatsDocument, options);
}
export function useAccessReviewConnectionsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewConnectionsStatsQuery,
    AccessReviewConnectionsStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewConnectionsStatsQuery,
    AccessReviewConnectionsStatsQueryVariables
  >(AccessReviewConnectionsStatsDocument, options);
}
export type AccessReviewConnectionsStatsQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionsStatsQuery
>;
export type AccessReviewConnectionsStatsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewConnectionsStatsLazyQuery
>;
export type AccessReviewConnectionsStatsQueryResult = Apollo.QueryResult<
  AccessReviewConnectionsStatsQuery,
  AccessReviewConnectionsStatsQueryVariables
>;
export const AllowedToUseAldwinDocument = gql`
  query AllowedToUseAldwin {
    allowedToUseAldwin {
      ... on AllowedToUseAldwinResult {
        allowed
      }
    }
  }
`;

/**
 * __useAllowedToUseAldwinQuery__
 *
 * To run a query within a React component, call `useAllowedToUseAldwinQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedToUseAldwinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedToUseAldwinQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedToUseAldwinQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowedToUseAldwinQuery,
    AllowedToUseAldwinQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowedToUseAldwinQuery,
    AllowedToUseAldwinQueryVariables
  >(AllowedToUseAldwinDocument, options);
}
export function useAllowedToUseAldwinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowedToUseAldwinQuery,
    AllowedToUseAldwinQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowedToUseAldwinQuery,
    AllowedToUseAldwinQueryVariables
  >(AllowedToUseAldwinDocument, options);
}
export type AllowedToUseAldwinQueryHookResult = ReturnType<
  typeof useAllowedToUseAldwinQuery
>;
export type AllowedToUseAldwinLazyQueryHookResult = ReturnType<
  typeof useAllowedToUseAldwinLazyQuery
>;
export type AllowedToUseAldwinQueryResult = Apollo.QueryResult<
  AllowedToUseAldwinQuery,
  AllowedToUseAldwinQueryVariables
>;
export const AllowedToCreateOrgDocument = gql`
  query AllowedToCreateOrg {
    allowedToCreateOrganizations {
      ... on AllowedToCreateOrganizationsResult {
        allowed
      }
    }
  }
`;

/**
 * __useAllowedToCreateOrgQuery__
 *
 * To run a query within a React component, call `useAllowedToCreateOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllowedToCreateOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllowedToCreateOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllowedToCreateOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllowedToCreateOrgQuery,
    AllowedToCreateOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllowedToCreateOrgQuery,
    AllowedToCreateOrgQueryVariables
  >(AllowedToCreateOrgDocument, options);
}
export function useAllowedToCreateOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllowedToCreateOrgQuery,
    AllowedToCreateOrgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllowedToCreateOrgQuery,
    AllowedToCreateOrgQueryVariables
  >(AllowedToCreateOrgDocument, options);
}
export type AllowedToCreateOrgQueryHookResult = ReturnType<
  typeof useAllowedToCreateOrgQuery
>;
export type AllowedToCreateOrgLazyQueryHookResult = ReturnType<
  typeof useAllowedToCreateOrgLazyQuery
>;
export type AllowedToCreateOrgQueryResult = Apollo.QueryResult<
  AllowedToCreateOrgQuery,
  AllowedToCreateOrgQueryVariables
>;
export const AwsRoleCreationDocument = gql`
  query AWSRoleCreation {
    connections(input: { connectionTypes: AWS }) {
      ... on ConnectionsResult {
        connections {
          id
          name
        }
      }
    }
    tags(input: {}) {
      ... on TagsResult {
        tags {
          id
          value
          key
        }
      }
    }
  }
`;

/**
 * __useAwsRoleCreationQuery__
 *
 * To run a query within a React component, call `useAwsRoleCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsRoleCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsRoleCreationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwsRoleCreationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AwsRoleCreationQuery,
    AwsRoleCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AwsRoleCreationQuery, AwsRoleCreationQueryVariables>(
    AwsRoleCreationDocument,
    options
  );
}
export function useAwsRoleCreationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AwsRoleCreationQuery,
    AwsRoleCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AwsRoleCreationQuery,
    AwsRoleCreationQueryVariables
  >(AwsRoleCreationDocument, options);
}
export type AwsRoleCreationQueryHookResult = ReturnType<
  typeof useAwsRoleCreationQuery
>;
export type AwsRoleCreationLazyQueryHookResult = ReturnType<
  typeof useAwsRoleCreationLazyQuery
>;
export type AwsRoleCreationQueryResult = Apollo.QueryResult<
  AwsRoleCreationQuery,
  AwsRoleCreationQueryVariables
>;
export const CreateRequestProposalDocument = gql`
  mutation CreateRequestProposal($input: CreateRequestProposalInput!) {
    createRequestProposal(input: $input) {
      __typename
      ... on CreateRequestProposalResult {
        request {
          ...Request
        }
      }
      ... on AWSRolePolicyInvalidError {
        message
      }
      ... on AWSRoleCreationReviewerNotSetError {
        message
      }
      ... on NoReviewersSetForResourceError {
        message
        resource {
          ...ResourcePreviewTiny
        }
      }
      ... on NoReviewersSetForGroupError {
        message
        group {
          ...GroupPreviewTiny
        }
      }
      ... on NoManagerSetForRequestingUserError {
        message
      }
    }
  }
  ${RequestFragmentDoc}
  ${ResourcePreviewTinyFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
`;
export type CreateRequestProposalMutationFn = Apollo.MutationFunction<
  CreateRequestProposalMutation,
  CreateRequestProposalMutationVariables
>;

/**
 * __useCreateRequestProposalMutation__
 *
 * To run a mutation, you first call `useCreateRequestProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestProposalMutation, { data, loading, error }] = useCreateRequestProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestProposalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRequestProposalMutation,
    CreateRequestProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRequestProposalMutation,
    CreateRequestProposalMutationVariables
  >(CreateRequestProposalDocument, options);
}
export type CreateRequestProposalMutationHookResult = ReturnType<
  typeof useCreateRequestProposalMutation
>;
export type CreateRequestProposalMutationResult = Apollo.MutationResult<
  CreateRequestProposalMutation
>;
export type CreateRequestProposalMutationOptions = Apollo.BaseMutationOptions<
  CreateRequestProposalMutation,
  CreateRequestProposalMutationVariables
>;
export const PaginatedAppDropdownDocument = gql`
  query PaginatedAppDropdown(
    $access: AccessOption!
    $searchQuery: String
    $limit: Int
    $cursor: String
  ) {
    apps(
      access: $access
      searchQuery: $searchQuery
      limit: $limit
      cursor: $cursor
    ) {
      __typename
      ... on AppsOutput {
        apps {
          ...AppDropdownPreview
        }
        cursor
      }
    }
  }
  ${AppDropdownPreviewFragmentDoc}
`;

/**
 * __usePaginatedAppDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedAppDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedAppDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedAppDropdownQuery({
 *   variables: {
 *      access: // value for 'access'
 *      searchQuery: // value for 'searchQuery'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePaginatedAppDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedAppDropdownQuery,
    PaginatedAppDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedAppDropdownQuery,
    PaginatedAppDropdownQueryVariables
  >(PaginatedAppDropdownDocument, options);
}
export function usePaginatedAppDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedAppDropdownQuery,
    PaginatedAppDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedAppDropdownQuery,
    PaginatedAppDropdownQueryVariables
  >(PaginatedAppDropdownDocument, options);
}
export type PaginatedAppDropdownQueryHookResult = ReturnType<
  typeof usePaginatedAppDropdownQuery
>;
export type PaginatedAppDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedAppDropdownLazyQuery
>;
export type PaginatedAppDropdownQueryResult = Apollo.QueryResult<
  PaginatedAppDropdownQuery,
  PaginatedAppDropdownQueryVariables
>;
export const PaginatedEntityDropdownDocument = gql`
  query PaginatedEntityDropdown(
    $id: UUID!
    $cursor: String
    $searchQuery: String
    $includeOnlyRequestable: Boolean
  ) {
    app(id: $id) {
      __typename
      ... on App {
        id
        items(
          input: {
            cursor: $cursor
            access: ALL
            searchQuery: $searchQuery
            includeOnlyRequestable: $includeOnlyRequestable
          }
        ) {
          items {
            key
            resource {
              id
              name
              description
              connection {
                name
                connectionType
                metadata {
                  ...ConnectionMetadataOutput
                }
              }
              isRequestable
              resourceType
              currentUserAccess {
                numAvailableAccessLevels
                resourceId
                resourceUsers {
                  accessLevel {
                    accessLevelRemoteId
                  }
                  resourceId
                  userId
                }
              }
              ancestorPathToResource
            }
            group {
              id
              name
              description
              connection {
                name
                connectionType
                metadata {
                  ...ConnectionMetadataOutput
                }
              }
              isRequestable
              groupType
              configTemplate {
                id
              }
              groupBinding {
                sourceGroupId
              }
              currentUserAccess {
                groupId
                groupUser {
                  userId
                  groupId
                }
              }
            }
          }
          cursor
        }
      }
    }
  }
  ${ConnectionMetadataOutputFragmentDoc}
`;

/**
 * __usePaginatedEntityDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedEntityDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedEntityDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedEntityDropdownQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *      searchQuery: // value for 'searchQuery'
 *      includeOnlyRequestable: // value for 'includeOnlyRequestable'
 *   },
 * });
 */
export function usePaginatedEntityDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedEntityDropdownQuery,
    PaginatedEntityDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedEntityDropdownQuery,
    PaginatedEntityDropdownQueryVariables
  >(PaginatedEntityDropdownDocument, options);
}
export function usePaginatedEntityDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedEntityDropdownQuery,
    PaginatedEntityDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedEntityDropdownQuery,
    PaginatedEntityDropdownQueryVariables
  >(PaginatedEntityDropdownDocument, options);
}
export type PaginatedEntityDropdownQueryHookResult = ReturnType<
  typeof usePaginatedEntityDropdownQuery
>;
export type PaginatedEntityDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedEntityDropdownLazyQuery
>;
export type PaginatedEntityDropdownQueryResult = Apollo.QueryResult<
  PaginatedEntityDropdownQuery,
  PaginatedEntityDropdownQueryVariables
>;
export const PaginatedGroupBindingDropdownDocument = gql`
  query PaginatedGroupBindingDropdown($input: GroupBindingsInput!) {
    groupBindings(input: $input) {
      __typename
      ... on GroupBindingsOutput {
        groupBindings {
          ...GroupBindingDropdownPreview
        }
        cursor
      }
    }
  }
  ${GroupBindingDropdownPreviewFragmentDoc}
`;

/**
 * __usePaginatedGroupBindingDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedGroupBindingDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedGroupBindingDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedGroupBindingDropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedGroupBindingDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedGroupBindingDropdownQuery,
    PaginatedGroupBindingDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedGroupBindingDropdownQuery,
    PaginatedGroupBindingDropdownQueryVariables
  >(PaginatedGroupBindingDropdownDocument, options);
}
export function usePaginatedGroupBindingDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedGroupBindingDropdownQuery,
    PaginatedGroupBindingDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedGroupBindingDropdownQuery,
    PaginatedGroupBindingDropdownQueryVariables
  >(PaginatedGroupBindingDropdownDocument, options);
}
export type PaginatedGroupBindingDropdownQueryHookResult = ReturnType<
  typeof usePaginatedGroupBindingDropdownQuery
>;
export type PaginatedGroupBindingDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedGroupBindingDropdownLazyQuery
>;
export type PaginatedGroupBindingDropdownQueryResult = Apollo.QueryResult<
  PaginatedGroupBindingDropdownQuery,
  PaginatedGroupBindingDropdownQueryVariables
>;
export const PaginatedGroupDropdownDocument = gql`
  query PaginatedGroupDropdown($input: GroupsInput!) {
    groups(input: $input) {
      __typename
      ... on GroupsResult {
        groups {
          ...GroupDropdownPreview
        }
        cursor
      }
    }
  }
  ${GroupDropdownPreviewFragmentDoc}
`;

/**
 * __usePaginatedGroupDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedGroupDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedGroupDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedGroupDropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedGroupDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedGroupDropdownQuery,
    PaginatedGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedGroupDropdownQuery,
    PaginatedGroupDropdownQueryVariables
  >(PaginatedGroupDropdownDocument, options);
}
export function usePaginatedGroupDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedGroupDropdownQuery,
    PaginatedGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedGroupDropdownQuery,
    PaginatedGroupDropdownQueryVariables
  >(PaginatedGroupDropdownDocument, options);
}
export type PaginatedGroupDropdownQueryHookResult = ReturnType<
  typeof usePaginatedGroupDropdownQuery
>;
export type PaginatedGroupDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedGroupDropdownLazyQuery
>;
export type PaginatedGroupDropdownQueryResult = Apollo.QueryResult<
  PaginatedGroupDropdownQuery,
  PaginatedGroupDropdownQueryVariables
>;
export const PaginatedResourceDropdownDocument = gql`
  query PaginatedResourceDropdown($input: ResourcesInput!) {
    resources(input: $input) {
      __typename
      ... on ResourcesResult {
        resources {
          ...ResourceDropdownPreview
        }
        cursor
      }
    }
  }
  ${ResourceDropdownPreviewFragmentDoc}
`;

/**
 * __usePaginatedResourceDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedResourceDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedResourceDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedResourceDropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedResourceDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedResourceDropdownQuery,
    PaginatedResourceDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedResourceDropdownQuery,
    PaginatedResourceDropdownQueryVariables
  >(PaginatedResourceDropdownDocument, options);
}
export function usePaginatedResourceDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedResourceDropdownQuery,
    PaginatedResourceDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedResourceDropdownQuery,
    PaginatedResourceDropdownQueryVariables
  >(PaginatedResourceDropdownDocument, options);
}
export type PaginatedResourceDropdownQueryHookResult = ReturnType<
  typeof usePaginatedResourceDropdownQuery
>;
export type PaginatedResourceDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedResourceDropdownLazyQuery
>;
export type PaginatedResourceDropdownQueryResult = Apollo.QueryResult<
  PaginatedResourceDropdownQuery,
  PaginatedResourceDropdownQueryVariables
>;
export const PaginatedUserDropdownDocument = gql`
  query PaginatedUserDropdown($input: UsersInput!) {
    users(input: $input) {
      __typename
      ... on UsersResult {
        users {
          ...UserDropdownPreview
        }
        cursor
      }
    }
  }
  ${UserDropdownPreviewFragmentDoc}
`;

/**
 * __usePaginatedUserDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedUserDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedUserDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedUserDropdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaginatedUserDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedUserDropdownQuery,
    PaginatedUserDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedUserDropdownQuery,
    PaginatedUserDropdownQueryVariables
  >(PaginatedUserDropdownDocument, options);
}
export function usePaginatedUserDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedUserDropdownQuery,
    PaginatedUserDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedUserDropdownQuery,
    PaginatedUserDropdownQueryVariables
  >(PaginatedUserDropdownDocument, options);
}
export type PaginatedUserDropdownQueryHookResult = ReturnType<
  typeof usePaginatedUserDropdownQuery
>;
export type PaginatedUserDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedUserDropdownLazyQuery
>;
export type PaginatedUserDropdownQueryResult = Apollo.QueryResult<
  PaginatedUserDropdownQuery,
  PaginatedUserDropdownQueryVariables
>;
export const PaginatedUserOrGroupDropdownDocument = gql`
  query PaginatedUserOrGroupDropdown(
    $searchQuery: String
    $maxNumEntries: Int
    $includeSystemUser: Boolean
  ) {
    users(
      input: {
        searchQuery: $searchQuery
        maxNumEntries: $maxNumEntries
        includeSystemUser: $includeSystemUser
      }
    ) {
      __typename
      ... on UsersResult {
        users {
          ...UserDropdownPreview
        }
        cursor
      }
    }
    groups(
      input: {
        searchQuery: $searchQuery
        maxNumEntries: $maxNumEntries
        leaderOfGroupsOnly: true
      }
    ) {
      ... on GroupsResult {
        groups {
          ...GroupPreviewSmall
        }
        cursor
      }
    }
  }
  ${UserDropdownPreviewFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
`;

/**
 * __usePaginatedUserOrGroupDropdownQuery__
 *
 * To run a query within a React component, call `usePaginatedUserOrGroupDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedUserOrGroupDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedUserOrGroupDropdownQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      maxNumEntries: // value for 'maxNumEntries'
 *      includeSystemUser: // value for 'includeSystemUser'
 *   },
 * });
 */
export function usePaginatedUserOrGroupDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PaginatedUserOrGroupDropdownQuery,
    PaginatedUserOrGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PaginatedUserOrGroupDropdownQuery,
    PaginatedUserOrGroupDropdownQueryVariables
  >(PaginatedUserOrGroupDropdownDocument, options);
}
export function usePaginatedUserOrGroupDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedUserOrGroupDropdownQuery,
    PaginatedUserOrGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PaginatedUserOrGroupDropdownQuery,
    PaginatedUserOrGroupDropdownQueryVariables
  >(PaginatedUserOrGroupDropdownDocument, options);
}
export type PaginatedUserOrGroupDropdownQueryHookResult = ReturnType<
  typeof usePaginatedUserOrGroupDropdownQuery
>;
export type PaginatedUserOrGroupDropdownLazyQueryHookResult = ReturnType<
  typeof usePaginatedUserOrGroupDropdownLazyQuery
>;
export type PaginatedUserOrGroupDropdownQueryResult = Apollo.QueryResult<
  PaginatedUserOrGroupDropdownQuery,
  PaginatedUserOrGroupDropdownQueryVariables
>;
export const UserOrGroupDropdownDocument = gql`
  query UserOrGroupDropdown {
    groups(input: { leaderOfGroupsOnly: true }) {
      ... on GroupsResult {
        groups {
          ...GroupDropdownPreview
        }
      }
    }
  }
  ${GroupDropdownPreviewFragmentDoc}
`;

/**
 * __useUserOrGroupDropdownQuery__
 *
 * To run a query within a React component, call `useUserOrGroupDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrGroupDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrGroupDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrGroupDropdownQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserOrGroupDropdownQuery,
    UserOrGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserOrGroupDropdownQuery,
    UserOrGroupDropdownQueryVariables
  >(UserOrGroupDropdownDocument, options);
}
export function useUserOrGroupDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOrGroupDropdownQuery,
    UserOrGroupDropdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserOrGroupDropdownQuery,
    UserOrGroupDropdownQueryVariables
  >(UserOrGroupDropdownDocument, options);
}
export type UserOrGroupDropdownQueryHookResult = ReturnType<
  typeof useUserOrGroupDropdownQuery
>;
export type UserOrGroupDropdownLazyQueryHookResult = ReturnType<
  typeof useUserOrGroupDropdownLazyQuery
>;
export type UserOrGroupDropdownQueryResult = Apollo.QueryResult<
  UserOrGroupDropdownQuery,
  UserOrGroupDropdownQueryVariables
>;
export const SearchUsersDocument = gql`
  query SearchUsers($query: String!, $maxNumEntries: Int!, $cursor: String) {
    users(
      input: {
        searchQuery: $query
        maxNumEntries: $maxNumEntries
        cursor: $cursor
      }
    ) {
      __typename
      ... on UsersResult {
        users {
          ...UserPreviewSmall
        }
        cursor
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      maxNumEntries: // value for 'maxNumEntries'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUsersQuery,
    SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export function useSearchUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUsersQuery,
    SearchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(
    SearchUsersDocument,
    options
  );
}
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<
  typeof useSearchUsersLazyQuery
>;
export type SearchUsersQueryResult = Apollo.QueryResult<
  SearchUsersQuery,
  SearchUsersQueryVariables
>;
export const SearchResourcesDocument = gql`
  query SearchResources(
    $query: String!
    $maxNumEntries: Int!
    $cursor: String
  ) {
    resources(
      input: {
        searchQuery: $query
        maxNumEntries: $maxNumEntries
        cursor: $cursor
      }
    ) {
      __typename
      ... on ResourcesResult {
        resources {
          ...SearchResourcePreview
        }
        cursor
      }
    }
  }
  ${SearchResourcePreviewFragmentDoc}
`;

/**
 * __useSearchResourcesQuery__
 *
 * To run a query within a React component, call `useSearchResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResourcesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      maxNumEntries: // value for 'maxNumEntries'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchResourcesQuery,
    SearchResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchResourcesQuery, SearchResourcesQueryVariables>(
    SearchResourcesDocument,
    options
  );
}
export function useSearchResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchResourcesQuery,
    SearchResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchResourcesQuery,
    SearchResourcesQueryVariables
  >(SearchResourcesDocument, options);
}
export type SearchResourcesQueryHookResult = ReturnType<
  typeof useSearchResourcesQuery
>;
export type SearchResourcesLazyQueryHookResult = ReturnType<
  typeof useSearchResourcesLazyQuery
>;
export type SearchResourcesQueryResult = Apollo.QueryResult<
  SearchResourcesQuery,
  SearchResourcesQueryVariables
>;
export const SearchGroupsDocument = gql`
  query SearchGroups($query: String!, $maxNumEntries: Int!, $cursor: String) {
    groups(
      input: {
        searchQuery: $query
        maxNumEntries: $maxNumEntries
        cursor: $cursor
      }
    ) {
      __typename
      ... on GroupsResult {
        groups {
          ...SearchGroupPreview
        }
        cursor
      }
    }
  }
  ${SearchGroupPreviewFragmentDoc}
`;

/**
 * __useSearchGroupsQuery__
 *
 * To run a query within a React component, call `useSearchGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGroupsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      maxNumEntries: // value for 'maxNumEntries'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchGroupsQuery,
    SearchGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchGroupsQuery, SearchGroupsQueryVariables>(
    SearchGroupsDocument,
    options
  );
}
export function useSearchGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchGroupsQuery,
    SearchGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchGroupsQuery, SearchGroupsQueryVariables>(
    SearchGroupsDocument,
    options
  );
}
export type SearchGroupsQueryHookResult = ReturnType<
  typeof useSearchGroupsQuery
>;
export type SearchGroupsLazyQueryHookResult = ReturnType<
  typeof useSearchGroupsLazyQuery
>;
export type SearchGroupsQueryResult = Apollo.QueryResult<
  SearchGroupsQuery,
  SearchGroupsQueryVariables
>;
export const SearchTypesDocument = gql`
  query SearchTypes($query: String!) {
    groupTypesWithCounts(input: { query: $query }) {
      ... on GroupTypesWithCountsResult {
        groupTypesWithCounts {
          ...SearchGroupTypePreview
        }
      }
    }
    resourceTypesWithCounts(input: { query: $query }) {
      ... on ResourceTypesWithCountsResult {
        resourceTypesWithCounts {
          ...SearchResourceTypePreview
        }
      }
    }
  }
  ${SearchGroupTypePreviewFragmentDoc}
  ${SearchResourceTypePreviewFragmentDoc}
`;

/**
 * __useSearchTypesQuery__
 *
 * To run a query within a React component, call `useSearchTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTypesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTypesQuery,
    SearchTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTypesQuery, SearchTypesQueryVariables>(
    SearchTypesDocument,
    options
  );
}
export function useSearchTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTypesQuery,
    SearchTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTypesQuery, SearchTypesQueryVariables>(
    SearchTypesDocument,
    options
  );
}
export type SearchTypesQueryHookResult = ReturnType<typeof useSearchTypesQuery>;
export type SearchTypesLazyQueryHookResult = ReturnType<
  typeof useSearchTypesLazyQuery
>;
export type SearchTypesQueryResult = Apollo.QueryResult<
  SearchTypesQuery,
  SearchTypesQueryVariables
>;
export const AppRowDocument = gql`
  query AppRow($connectionId: ConnectionId!) {
    connection(input: { id: $connectionId }) {
      __typename
      ... on ConnectionResult {
        connection {
          name
          connectionType
          iconUrl
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAppRowQuery__
 *
 * To run a query within a React component, call `useAppRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppRowQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useAppRowQuery(
  baseOptions: Apollo.QueryHookOptions<AppRowQuery, AppRowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppRowQuery, AppRowQueryVariables>(
    AppRowDocument,
    options
  );
}
export function useAppRowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppRowQuery, AppRowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppRowQuery, AppRowQueryVariables>(
    AppRowDocument,
    options
  );
}
export type AppRowQueryHookResult = ReturnType<typeof useAppRowQuery>;
export type AppRowLazyQueryHookResult = ReturnType<typeof useAppRowLazyQuery>;
export type AppRowQueryResult = Apollo.QueryResult<
  AppRowQuery,
  AppRowQueryVariables
>;
export const GroupsPreviewDocument = gql`
  query GroupsPreview($input: GroupsInput!) {
    groups(input: $input) {
      __typename
      ... on GroupsResult {
        groups {
          ...GroupPreviewSmall
        }
      }
    }
  }
  ${GroupPreviewSmallFragmentDoc}
`;

/**
 * __useGroupsPreviewQuery__
 *
 * To run a query within a React component, call `useGroupsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupsPreviewQuery,
    GroupsPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsPreviewQuery, GroupsPreviewQueryVariables>(
    GroupsPreviewDocument,
    options
  );
}
export function useGroupsPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupsPreviewQuery,
    GroupsPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsPreviewQuery, GroupsPreviewQueryVariables>(
    GroupsPreviewDocument,
    options
  );
}
export type GroupsPreviewQueryHookResult = ReturnType<
  typeof useGroupsPreviewQuery
>;
export type GroupsPreviewLazyQueryHookResult = ReturnType<
  typeof useGroupsPreviewLazyQuery
>;
export type GroupsPreviewQueryResult = Apollo.QueryResult<
  GroupsPreviewQuery,
  GroupsPreviewQueryVariables
>;
export const LeftSidebarRoutesDocument = gql`
  query LeftSidebarRoutes {
    leftSidebarRoutes {
      hasOwners
      isGroupLeader
    }
  }
`;

/**
 * __useLeftSidebarRoutesQuery__
 *
 * To run a query within a React component, call `useLeftSidebarRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeftSidebarRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeftSidebarRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeftSidebarRoutesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeftSidebarRoutesQuery,
    LeftSidebarRoutesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LeftSidebarRoutesQuery,
    LeftSidebarRoutesQueryVariables
  >(LeftSidebarRoutesDocument, options);
}
export function useLeftSidebarRoutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeftSidebarRoutesQuery,
    LeftSidebarRoutesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LeftSidebarRoutesQuery,
    LeftSidebarRoutesQueryVariables
  >(LeftSidebarRoutesDocument, options);
}
export type LeftSidebarRoutesQueryHookResult = ReturnType<
  typeof useLeftSidebarRoutesQuery
>;
export type LeftSidebarRoutesLazyQueryHookResult = ReturnType<
  typeof useLeftSidebarRoutesLazyQuery
>;
export type LeftSidebarRoutesQueryResult = Apollo.QueryResult<
  LeftSidebarRoutesQuery,
  LeftSidebarRoutesQueryVariables
>;
export const UserMenuDocument = gql`
  query UserMenu {
    connections(input: { connectionTypes: [OPAL] }) {
      __typename
      ... on ConnectionsResult {
        connections {
          id
        }
      }
    }
  }
`;

/**
 * __useUserMenuQuery__
 *
 * To run a query within a React component, call `useUserMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMenuQuery(
  baseOptions?: Apollo.QueryHookOptions<UserMenuQuery, UserMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserMenuQuery, UserMenuQueryVariables>(
    UserMenuDocument,
    options
  );
}
export function useUserMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMenuQuery,
    UserMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserMenuQuery, UserMenuQueryVariables>(
    UserMenuDocument,
    options
  );
}
export type UserMenuQueryHookResult = ReturnType<typeof useUserMenuQuery>;
export type UserMenuLazyQueryHookResult = ReturnType<
  typeof useUserMenuLazyQuery
>;
export type UserMenuQueryResult = Apollo.QueryResult<
  UserMenuQuery,
  UserMenuQueryVariables
>;
export const SlackIntegrationWorkspacesDocument = gql`
  query SlackIntegrationWorkspaces {
    thirdPartyIntegrations(input: { integrationType: ORG }) {
      ... on ThirdPartyIntegrationsResult {
        thirdPartyIntegrations {
          thirdPartyProvider
          slackMetadata {
            enterpriseId
            workspaces {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSlackIntegrationWorkspacesQuery__
 *
 * To run a query within a React component, call `useSlackIntegrationWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackIntegrationWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackIntegrationWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSlackIntegrationWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SlackIntegrationWorkspacesQuery,
    SlackIntegrationWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SlackIntegrationWorkspacesQuery,
    SlackIntegrationWorkspacesQueryVariables
  >(SlackIntegrationWorkspacesDocument, options);
}
export function useSlackIntegrationWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SlackIntegrationWorkspacesQuery,
    SlackIntegrationWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SlackIntegrationWorkspacesQuery,
    SlackIntegrationWorkspacesQueryVariables
  >(SlackIntegrationWorkspacesDocument, options);
}
export type SlackIntegrationWorkspacesQueryHookResult = ReturnType<
  typeof useSlackIntegrationWorkspacesQuery
>;
export type SlackIntegrationWorkspacesLazyQueryHookResult = ReturnType<
  typeof useSlackIntegrationWorkspacesLazyQuery
>;
export type SlackIntegrationWorkspacesQueryResult = Apollo.QueryResult<
  SlackIntegrationWorkspacesQuery,
  SlackIntegrationWorkspacesQueryVariables
>;
export const GroupActiveRequestConfigurationDocument = gql`
  query GroupActiveRequestConfiguration(
    $groupId: GroupId!
    $targetUserId: UserId
    $accessLevelRemoteId: AccessLevelRemoteId
  ) {
    activeGroupRequestConfigurations(
      input: {
        targetUserId: $targetUserId
        groupInputs: [
          { groupId: $groupId, accessLevelRemoteId: $accessLevelRemoteId }
        ]
      }
    ) {
      ... on GroupRequestConfigurationOutput {
        groupId
        requestConfiguration {
          ...ActiveRequestConfiguration
        }
      }
    }
  }
  ${ActiveRequestConfigurationFragmentDoc}
`;

/**
 * __useGroupActiveRequestConfigurationQuery__
 *
 * To run a query within a React component, call `useGroupActiveRequestConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupActiveRequestConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupActiveRequestConfigurationQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      targetUserId: // value for 'targetUserId'
 *      accessLevelRemoteId: // value for 'accessLevelRemoteId'
 *   },
 * });
 */
export function useGroupActiveRequestConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupActiveRequestConfigurationQuery,
    GroupActiveRequestConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupActiveRequestConfigurationQuery,
    GroupActiveRequestConfigurationQueryVariables
  >(GroupActiveRequestConfigurationDocument, options);
}
export function useGroupActiveRequestConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupActiveRequestConfigurationQuery,
    GroupActiveRequestConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupActiveRequestConfigurationQuery,
    GroupActiveRequestConfigurationQueryVariables
  >(GroupActiveRequestConfigurationDocument, options);
}
export type GroupActiveRequestConfigurationQueryHookResult = ReturnType<
  typeof useGroupActiveRequestConfigurationQuery
>;
export type GroupActiveRequestConfigurationLazyQueryHookResult = ReturnType<
  typeof useGroupActiveRequestConfigurationLazyQuery
>;
export type GroupActiveRequestConfigurationQueryResult = Apollo.QueryResult<
  GroupActiveRequestConfigurationQuery,
  GroupActiveRequestConfigurationQueryVariables
>;
export const ResourceActiveRequestConfigurationDocument = gql`
  query ResourceActiveRequestConfiguration(
    $resourceId: ResourceId!
    $targetUserId: UserId
    $accessLevelRemoteId: AccessLevelRemoteId
  ) {
    activeResourceRequestConfigurations(
      input: {
        targetUserId: $targetUserId
        resourceInputs: [
          { resourceId: $resourceId, accessLevelRemoteId: $accessLevelRemoteId }
        ]
      }
    ) {
      ... on ResourceRequestConfigurationOutput {
        resourceId
        requestConfiguration {
          ...ActiveRequestConfiguration
        }
      }
    }
  }
  ${ActiveRequestConfigurationFragmentDoc}
`;

/**
 * __useResourceActiveRequestConfigurationQuery__
 *
 * To run a query within a React component, call `useResourceActiveRequestConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceActiveRequestConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceActiveRequestConfigurationQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      targetUserId: // value for 'targetUserId'
 *      accessLevelRemoteId: // value for 'accessLevelRemoteId'
 *   },
 * });
 */
export function useResourceActiveRequestConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceActiveRequestConfigurationQuery,
    ResourceActiveRequestConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceActiveRequestConfigurationQuery,
    ResourceActiveRequestConfigurationQueryVariables
  >(ResourceActiveRequestConfigurationDocument, options);
}
export function useResourceActiveRequestConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceActiveRequestConfigurationQuery,
    ResourceActiveRequestConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceActiveRequestConfigurationQuery,
    ResourceActiveRequestConfigurationQueryVariables
  >(ResourceActiveRequestConfigurationDocument, options);
}
export type ResourceActiveRequestConfigurationQueryHookResult = ReturnType<
  typeof useResourceActiveRequestConfigurationQuery
>;
export type ResourceActiveRequestConfigurationLazyQueryHookResult = ReturnType<
  typeof useResourceActiveRequestConfigurationLazyQuery
>;
export type ResourceActiveRequestConfigurationQueryResult = Apollo.QueryResult<
  ResourceActiveRequestConfigurationQuery,
  ResourceActiveRequestConfigurationQueryVariables
>;
export const GroupDetailsModalDocument = gql`
  query GroupDetailsModal($id: GroupId!) {
    group(input: { id: $id }) {
      ... on GroupResult {
        group {
          ...EndUserGroupDetails
        }
      }
    }
    groupRequestStatusCounts(input: { groupId: $id }) {
      ... on GroupRequestStatusCountsResult {
        requestStatusCounts {
          pending
          approved
          denied
          canceled
        }
      }
    }
  }
  ${EndUserGroupDetailsFragmentDoc}
`;

/**
 * __useGroupDetailsModalQuery__
 *
 * To run a query within a React component, call `useGroupDetailsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailsModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupDetailsModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupDetailsModalQuery,
    GroupDetailsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupDetailsModalQuery,
    GroupDetailsModalQueryVariables
  >(GroupDetailsModalDocument, options);
}
export function useGroupDetailsModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupDetailsModalQuery,
    GroupDetailsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupDetailsModalQuery,
    GroupDetailsModalQueryVariables
  >(GroupDetailsModalDocument, options);
}
export type GroupDetailsModalQueryHookResult = ReturnType<
  typeof useGroupDetailsModalQuery
>;
export type GroupDetailsModalLazyQueryHookResult = ReturnType<
  typeof useGroupDetailsModalLazyQuery
>;
export type GroupDetailsModalQueryResult = Apollo.QueryResult<
  GroupDetailsModalQuery,
  GroupDetailsModalQueryVariables
>;
export const ResourceDetailsModalDocument = gql`
  query ResourceDetailsModal($id: ResourceId!) {
    resource(input: { id: $id }) {
      ... on ResourceResult {
        resource {
          ...EndUserResourceDetails
        }
      }
    }
    resourceRequestStatusCounts(input: { resourceId: $id }) {
      ... on ResourceRequestStatusCountsResult {
        requestStatusCounts {
          pending
          approved
          denied
          canceled
        }
      }
    }
  }
  ${EndUserResourceDetailsFragmentDoc}
`;

/**
 * __useResourceDetailsModalQuery__
 *
 * To run a query within a React component, call `useResourceDetailsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDetailsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDetailsModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceDetailsModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceDetailsModalQuery,
    ResourceDetailsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceDetailsModalQuery,
    ResourceDetailsModalQueryVariables
  >(ResourceDetailsModalDocument, options);
}
export function useResourceDetailsModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceDetailsModalQuery,
    ResourceDetailsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceDetailsModalQuery,
    ResourceDetailsModalQueryVariables
  >(ResourceDetailsModalDocument, options);
}
export type ResourceDetailsModalQueryHookResult = ReturnType<
  typeof useResourceDetailsModalQuery
>;
export type ResourceDetailsModalLazyQueryHookResult = ReturnType<
  typeof useResourceDetailsModalLazyQuery
>;
export type ResourceDetailsModalQueryResult = Apollo.QueryResult<
  ResourceDetailsModalQuery,
  ResourceDetailsModalQueryVariables
>;
export const AccessRequestGroupCardDocument = gql`
  query AccessRequestGroupCard($id: GroupId!, $targetUserId: UserId!) {
    group(input: { id: $id }) {
      ... on GroupResult {
        group {
          __typename
          id
          name
          groupType
          serviceType
          groupBinding {
            ...GroupBinding
          }
          containingGroups {
            id
          }
          groupResources {
            resourceId
            groupId
            accessLevel {
              accessLevelRemoteId
            }
          }
          connection {
            id
            name
            connectionType
            metadata {
              ...ConnectionMetadataOutput
            }
          }
          currentUserAccess {
            ...CurrentUserGroupAccessTiny
          }
        }
      }
    }
    groupAccessLevels(
      input: { groupId: $id, onlyRequestableTargetUser: $targetUserId }
    ) {
      ... on GroupAccessLevelsResult {
        groupId
        accessLevels {
          ...GroupAccessLevel
        }
      }
    }
  }
  ${GroupBindingFragmentDoc}
  ${ConnectionMetadataOutputFragmentDoc}
  ${CurrentUserGroupAccessTinyFragmentDoc}
  ${GroupAccessLevelFragmentDoc}
`;

/**
 * __useAccessRequestGroupCardQuery__
 *
 * To run a query within a React component, call `useAccessRequestGroupCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessRequestGroupCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessRequestGroupCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      targetUserId: // value for 'targetUserId'
 *   },
 * });
 */
export function useAccessRequestGroupCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessRequestGroupCardQuery,
    AccessRequestGroupCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessRequestGroupCardQuery,
    AccessRequestGroupCardQueryVariables
  >(AccessRequestGroupCardDocument, options);
}
export function useAccessRequestGroupCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessRequestGroupCardQuery,
    AccessRequestGroupCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessRequestGroupCardQuery,
    AccessRequestGroupCardQueryVariables
  >(AccessRequestGroupCardDocument, options);
}
export type AccessRequestGroupCardQueryHookResult = ReturnType<
  typeof useAccessRequestGroupCardQuery
>;
export type AccessRequestGroupCardLazyQueryHookResult = ReturnType<
  typeof useAccessRequestGroupCardLazyQuery
>;
export type AccessRequestGroupCardQueryResult = Apollo.QueryResult<
  AccessRequestGroupCardQuery,
  AccessRequestGroupCardQueryVariables
>;
export const AccessRequestResourceCardDocument = gql`
  query AccessRequestResourceCard($id: ResourceId!) {
    resource(input: { id: $id }) {
      ... on ResourceResult {
        resource {
          __typename
          id
          name
          resourceType
          serviceType
          remoteId
          connection {
            id
            name
            connectionType
            metadata {
              ...ConnectionMetadataOutput
            }
          }
          currentUserAccess {
            ...CurrentUserResourceAccessTiny
          }
          ancestorPathToResource
        }
      }
    }
  }
  ${ConnectionMetadataOutputFragmentDoc}
  ${CurrentUserResourceAccessTinyFragmentDoc}
`;

/**
 * __useAccessRequestResourceCardQuery__
 *
 * To run a query within a React component, call `useAccessRequestResourceCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessRequestResourceCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessRequestResourceCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccessRequestResourceCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessRequestResourceCardQuery,
    AccessRequestResourceCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessRequestResourceCardQuery,
    AccessRequestResourceCardQueryVariables
  >(AccessRequestResourceCardDocument, options);
}
export function useAccessRequestResourceCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessRequestResourceCardQuery,
    AccessRequestResourceCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessRequestResourceCardQuery,
    AccessRequestResourceCardQueryVariables
  >(AccessRequestResourceCardDocument, options);
}
export type AccessRequestResourceCardQueryHookResult = ReturnType<
  typeof useAccessRequestResourceCardQuery
>;
export type AccessRequestResourceCardLazyQueryHookResult = ReturnType<
  typeof useAccessRequestResourceCardLazyQuery
>;
export type AccessRequestResourceCardQueryResult = Apollo.QueryResult<
  AccessRequestResourceCardQuery,
  AccessRequestResourceCardQueryVariables
>;
export const AccessRequestOktaAppCardDocument = gql`
  query AccessRequestOktaAppCard($id: UUID!) {
    app(id: $id) {
      ... on App {
        id
        name
        app {
          ... on OktaResourceApp {
            resourceId
            resource {
              id
              name
              isRequestable
            }
          }
        }
        items(input: { access: ALL, includeOnlyRequestable: true }) {
          items {
            key
            group {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAccessRequestOktaAppCardQuery__
 *
 * To run a query within a React component, call `useAccessRequestOktaAppCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessRequestOktaAppCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessRequestOktaAppCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccessRequestOktaAppCardQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessRequestOktaAppCardQuery,
    AccessRequestOktaAppCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessRequestOktaAppCardQuery,
    AccessRequestOktaAppCardQueryVariables
  >(AccessRequestOktaAppCardDocument, options);
}
export function useAccessRequestOktaAppCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessRequestOktaAppCardQuery,
    AccessRequestOktaAppCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessRequestOktaAppCardQuery,
    AccessRequestOktaAppCardQueryVariables
  >(AccessRequestOktaAppCardDocument, options);
}
export type AccessRequestOktaAppCardQueryHookResult = ReturnType<
  typeof useAccessRequestOktaAppCardQuery
>;
export type AccessRequestOktaAppCardLazyQueryHookResult = ReturnType<
  typeof useAccessRequestOktaAppCardLazyQuery
>;
export type AccessRequestOktaAppCardQueryResult = Apollo.QueryResult<
  AccessRequestOktaAppCardQuery,
  AccessRequestOktaAppCardQueryVariables
>;
export const ConnectionsSummaryDocument = gql`
  query ConnectionsSummary($input: ConnectionsInput!) {
    connections(input: $input) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionSummary
        }
      }
    }
  }
  ${ConnectionSummaryFragmentDoc}
`;

/**
 * __useConnectionsSummaryQuery__
 *
 * To run a query within a React component, call `useConnectionsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectionsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionsSummaryQuery,
    ConnectionsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectionsSummaryQuery,
    ConnectionsSummaryQueryVariables
  >(ConnectionsSummaryDocument, options);
}
export function useConnectionsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionsSummaryQuery,
    ConnectionsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectionsSummaryQuery,
    ConnectionsSummaryQueryVariables
  >(ConnectionsSummaryDocument, options);
}
export type ConnectionsSummaryQueryHookResult = ReturnType<
  typeof useConnectionsSummaryQuery
>;
export type ConnectionsSummaryLazyQueryHookResult = ReturnType<
  typeof useConnectionsSummaryLazyQuery
>;
export type ConnectionsSummaryQueryResult = Apollo.QueryResult<
  ConnectionsSummaryQuery,
  ConnectionsSummaryQueryVariables
>;
export const GroupTypesWithCountsDocument = gql`
  query GroupTypesWithCounts($input: GroupTypesWithCountsInput!) {
    groupTypesWithCounts(input: $input) {
      ... on GroupTypesWithCountsResult {
        groupTypesWithCounts {
          ...GroupTypeWithCount
        }
      }
    }
  }
  ${GroupTypeWithCountFragmentDoc}
`;

/**
 * __useGroupTypesWithCountsQuery__
 *
 * To run a query within a React component, call `useGroupTypesWithCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupTypesWithCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupTypesWithCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupTypesWithCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupTypesWithCountsQuery,
    GroupTypesWithCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupTypesWithCountsQuery,
    GroupTypesWithCountsQueryVariables
  >(GroupTypesWithCountsDocument, options);
}
export function useGroupTypesWithCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupTypesWithCountsQuery,
    GroupTypesWithCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupTypesWithCountsQuery,
    GroupTypesWithCountsQueryVariables
  >(GroupTypesWithCountsDocument, options);
}
export type GroupTypesWithCountsQueryHookResult = ReturnType<
  typeof useGroupTypesWithCountsQuery
>;
export type GroupTypesWithCountsLazyQueryHookResult = ReturnType<
  typeof useGroupTypesWithCountsLazyQuery
>;
export type GroupTypesWithCountsQueryResult = Apollo.QueryResult<
  GroupTypesWithCountsQuery,
  GroupTypesWithCountsQueryVariables
>;
export const ResourceTypesWithCountsDocument = gql`
  query ResourceTypesWithCounts($input: ResourceTypesWithCountsInput!) {
    resourceTypesWithCounts(input: $input) {
      ... on ResourceTypesWithCountsResult {
        resourceTypesWithCounts {
          ...ResourceTypeWithCount
        }
      }
    }
  }
  ${ResourceTypeWithCountFragmentDoc}
`;

/**
 * __useResourceTypesWithCountsQuery__
 *
 * To run a query within a React component, call `useResourceTypesWithCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceTypesWithCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceTypesWithCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceTypesWithCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceTypesWithCountsQuery,
    ResourceTypesWithCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceTypesWithCountsQuery,
    ResourceTypesWithCountsQueryVariables
  >(ResourceTypesWithCountsDocument, options);
}
export function useResourceTypesWithCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceTypesWithCountsQuery,
    ResourceTypesWithCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceTypesWithCountsQuery,
    ResourceTypesWithCountsQueryVariables
  >(ResourceTypesWithCountsDocument, options);
}
export type ResourceTypesWithCountsQueryHookResult = ReturnType<
  typeof useResourceTypesWithCountsQuery
>;
export type ResourceTypesWithCountsLazyQueryHookResult = ReturnType<
  typeof useResourceTypesWithCountsLazyQuery
>;
export type ResourceTypesWithCountsQueryResult = Apollo.QueryResult<
  ResourceTypesWithCountsQuery,
  ResourceTypesWithCountsQueryVariables
>;
export const VisualizationDataDocument = gql`
  query VisualizationData($input: VisualizationFilter!) {
    visualizationData(input: $input) {
      ... on VisualizationResult {
        ...VisualizationDataResult
      }
    }
  }
  ${VisualizationDataResultFragmentDoc}
`;

/**
 * __useVisualizationDataQuery__
 *
 * To run a query within a React component, call `useVisualizationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisualizationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisualizationDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisualizationDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    VisualizationDataQuery,
    VisualizationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VisualizationDataQuery,
    VisualizationDataQueryVariables
  >(VisualizationDataDocument, options);
}
export function useVisualizationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VisualizationDataQuery,
    VisualizationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VisualizationDataQuery,
    VisualizationDataQueryVariables
  >(VisualizationDataDocument, options);
}
export type VisualizationDataQueryHookResult = ReturnType<
  typeof useVisualizationDataQuery
>;
export type VisualizationDataLazyQueryHookResult = ReturnType<
  typeof useVisualizationDataLazyQuery
>;
export type VisualizationDataQueryResult = Apollo.QueryResult<
  VisualizationDataQuery,
  VisualizationDataQueryVariables
>;
export const ClearResourceUserPropagationStatusDocument = gql`
  mutation ClearResourceUserPropagationStatus(
    $input: ClearResourceUserPropagationStatusInput!
  ) {
    clearResourceUserPropagationStatus(input: $input) {
      ... on ClearResourceUserPropagationStatusResult {
        success
      }
    }
  }
`;
export type ClearResourceUserPropagationStatusMutationFn = Apollo.MutationFunction<
  ClearResourceUserPropagationStatusMutation,
  ClearResourceUserPropagationStatusMutationVariables
>;

/**
 * __useClearResourceUserPropagationStatusMutation__
 *
 * To run a mutation, you first call `useClearResourceUserPropagationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearResourceUserPropagationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearResourceUserPropagationStatusMutation, { data, loading, error }] = useClearResourceUserPropagationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearResourceUserPropagationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearResourceUserPropagationStatusMutation,
    ClearResourceUserPropagationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearResourceUserPropagationStatusMutation,
    ClearResourceUserPropagationStatusMutationVariables
  >(ClearResourceUserPropagationStatusDocument, options);
}
export type ClearResourceUserPropagationStatusMutationHookResult = ReturnType<
  typeof useClearResourceUserPropagationStatusMutation
>;
export type ClearResourceUserPropagationStatusMutationResult = Apollo.MutationResult<
  ClearResourceUserPropagationStatusMutation
>;
export type ClearResourceUserPropagationStatusMutationOptions = Apollo.BaseMutationOptions<
  ClearResourceUserPropagationStatusMutation,
  ClearResourceUserPropagationStatusMutationVariables
>;
export const MarkGroupUserPropagationAsSuccessDocument = gql`
  mutation MarkGroupUserPropagationAsSuccess(
    $accessReviewGroupUserId: AccessReviewGroupUserId!
  ) {
    deleteGroupUserPropStatus(
      accessReviewGroupUserId: $accessReviewGroupUserId
    ) {
      success
    }
  }
`;
export type MarkGroupUserPropagationAsSuccessMutationFn = Apollo.MutationFunction<
  MarkGroupUserPropagationAsSuccessMutation,
  MarkGroupUserPropagationAsSuccessMutationVariables
>;

/**
 * __useMarkGroupUserPropagationAsSuccessMutation__
 *
 * To run a mutation, you first call `useMarkGroupUserPropagationAsSuccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkGroupUserPropagationAsSuccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markGroupUserPropagationAsSuccessMutation, { data, loading, error }] = useMarkGroupUserPropagationAsSuccessMutation({
 *   variables: {
 *      accessReviewGroupUserId: // value for 'accessReviewGroupUserId'
 *   },
 * });
 */
export function useMarkGroupUserPropagationAsSuccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkGroupUserPropagationAsSuccessMutation,
    MarkGroupUserPropagationAsSuccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkGroupUserPropagationAsSuccessMutation,
    MarkGroupUserPropagationAsSuccessMutationVariables
  >(MarkGroupUserPropagationAsSuccessDocument, options);
}
export type MarkGroupUserPropagationAsSuccessMutationHookResult = ReturnType<
  typeof useMarkGroupUserPropagationAsSuccessMutation
>;
export type MarkGroupUserPropagationAsSuccessMutationResult = Apollo.MutationResult<
  MarkGroupUserPropagationAsSuccessMutation
>;
export type MarkGroupUserPropagationAsSuccessMutationOptions = Apollo.BaseMutationOptions<
  MarkGroupUserPropagationAsSuccessMutation,
  MarkGroupUserPropagationAsSuccessMutationVariables
>;
export const AppPreviewDocument = gql`
  query AppPreview($appId: UUID!) {
    app(id: $appId) {
      ...AppPreview
    }
  }
  ${AppPreviewFragmentDoc}
`;

/**
 * __useAppPreviewQuery__
 *
 * To run a query within a React component, call `useAppPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppPreviewQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useAppPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppPreviewQuery,
    AppPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppPreviewQuery, AppPreviewQueryVariables>(
    AppPreviewDocument,
    options
  );
}
export function useAppPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppPreviewQuery,
    AppPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppPreviewQuery, AppPreviewQueryVariables>(
    AppPreviewDocument,
    options
  );
}
export type AppPreviewQueryHookResult = ReturnType<typeof useAppPreviewQuery>;
export type AppPreviewLazyQueryHookResult = ReturnType<
  typeof useAppPreviewLazyQuery
>;
export type AppPreviewQueryResult = Apollo.QueryResult<
  AppPreviewQuery,
  AppPreviewQueryVariables
>;
export const RequestDefaultsDocument = gql`
  query RequestDefaults($input: RequestDefaultsInput!) {
    requestDefaults(input: $input) {
      ...RequestDefaults
    }
  }
  ${RequestDefaultsFragmentDoc}
`;

/**
 * __useRequestDefaultsQuery__
 *
 * To run a query within a React component, call `useRequestDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestDefaultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestDefaultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestDefaultsQuery,
    RequestDefaultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestDefaultsQuery, RequestDefaultsQueryVariables>(
    RequestDefaultsDocument,
    options
  );
}
export function useRequestDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestDefaultsQuery,
    RequestDefaultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestDefaultsQuery,
    RequestDefaultsQueryVariables
  >(RequestDefaultsDocument, options);
}
export type RequestDefaultsQueryHookResult = ReturnType<
  typeof useRequestDefaultsQuery
>;
export type RequestDefaultsLazyQueryHookResult = ReturnType<
  typeof useRequestDefaultsLazyQuery
>;
export type RequestDefaultsQueryResult = Apollo.QueryResult<
  RequestDefaultsQuery,
  RequestDefaultsQueryVariables
>;
export const AccessReviewUserAssignmentsDocument = gql`
  query AccessReviewUserAssignments($input: AccessReviewUserAssignmentsInput!) {
    accessReviewUserAssignments(input: $input) {
      accessReviewUserAssignments {
        ...AccessReviewUserAssignment
      }
      cursor
      totalNumUserAssignments
    }
  }
  ${AccessReviewUserAssignmentFragmentDoc}
`;

/**
 * __useAccessReviewUserAssignmentsQuery__
 *
 * To run a query within a React component, call `useAccessReviewUserAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewUserAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewUserAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewUserAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewUserAssignmentsQuery,
    AccessReviewUserAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewUserAssignmentsQuery,
    AccessReviewUserAssignmentsQueryVariables
  >(AccessReviewUserAssignmentsDocument, options);
}
export function useAccessReviewUserAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewUserAssignmentsQuery,
    AccessReviewUserAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewUserAssignmentsQuery,
    AccessReviewUserAssignmentsQueryVariables
  >(AccessReviewUserAssignmentsDocument, options);
}
export type AccessReviewUserAssignmentsQueryHookResult = ReturnType<
  typeof useAccessReviewUserAssignmentsQuery
>;
export type AccessReviewUserAssignmentsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewUserAssignmentsLazyQuery
>;
export type AccessReviewUserAssignmentsQueryResult = Apollo.QueryResult<
  AccessReviewUserAssignmentsQuery,
  AccessReviewUserAssignmentsQueryVariables
>;
export const AccessReviewGroupResourceAssignmentsDocument = gql`
  query AccessReviewGroupResourceAssignments(
    $input: AccessReviewGroupResourceAssignmentsInput!
  ) {
    accessReviewGroupResourceAssignments(input: $input) {
      accessReviewGroupResourceAssignments {
        ...AccessReviewGroupResourceAssignment
      }
      cursor
      totalNumGroupAssignments
    }
  }
  ${AccessReviewGroupResourceAssignmentFragmentDoc}
`;

/**
 * __useAccessReviewGroupResourceAssignmentsQuery__
 *
 * To run a query within a React component, call `useAccessReviewGroupResourceAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewGroupResourceAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewGroupResourceAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewGroupResourceAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewGroupResourceAssignmentsQuery,
    AccessReviewGroupResourceAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewGroupResourceAssignmentsQuery,
    AccessReviewGroupResourceAssignmentsQueryVariables
  >(AccessReviewGroupResourceAssignmentsDocument, options);
}
export function useAccessReviewGroupResourceAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewGroupResourceAssignmentsQuery,
    AccessReviewGroupResourceAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewGroupResourceAssignmentsQuery,
    AccessReviewGroupResourceAssignmentsQueryVariables
  >(AccessReviewGroupResourceAssignmentsDocument, options);
}
export type AccessReviewGroupResourceAssignmentsQueryHookResult = ReturnType<
  typeof useAccessReviewGroupResourceAssignmentsQuery
>;
export type AccessReviewGroupResourceAssignmentsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewGroupResourceAssignmentsLazyQuery
>;
export type AccessReviewGroupResourceAssignmentsQueryResult = Apollo.QueryResult<
  AccessReviewGroupResourceAssignmentsQuery,
  AccessReviewGroupResourceAssignmentsQueryVariables
>;
export const AccessReviewResourcePrincipalAssignmentsDocument = gql`
  query AccessReviewResourcePrincipalAssignments(
    $input: AccessReviewResourcePrincipalAssignmentsInput!
  ) {
    accessReviewResourcePrincipalAssignments(input: $input) {
      accessReviewResourcePrincipalAssignments {
        ...AccessReviewResourcePrincipalAssignment
      }
      cursor
      totalNumResourcePrincipalAssignments
    }
  }
  ${AccessReviewResourcePrincipalAssignmentFragmentDoc}
`;

/**
 * __useAccessReviewResourcePrincipalAssignmentsQuery__
 *
 * To run a query within a React component, call `useAccessReviewResourcePrincipalAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewResourcePrincipalAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewResourcePrincipalAssignmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewResourcePrincipalAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewResourcePrincipalAssignmentsQuery,
    AccessReviewResourcePrincipalAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewResourcePrincipalAssignmentsQuery,
    AccessReviewResourcePrincipalAssignmentsQueryVariables
  >(AccessReviewResourcePrincipalAssignmentsDocument, options);
}
export function useAccessReviewResourcePrincipalAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewResourcePrincipalAssignmentsQuery,
    AccessReviewResourcePrincipalAssignmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewResourcePrincipalAssignmentsQuery,
    AccessReviewResourcePrincipalAssignmentsQueryVariables
  >(AccessReviewResourcePrincipalAssignmentsDocument, options);
}
export type AccessReviewResourcePrincipalAssignmentsQueryHookResult = ReturnType<
  typeof useAccessReviewResourcePrincipalAssignmentsQuery
>;
export type AccessReviewResourcePrincipalAssignmentsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewResourcePrincipalAssignmentsLazyQuery
>;
export type AccessReviewResourcePrincipalAssignmentsQueryResult = Apollo.QueryResult<
  AccessReviewResourcePrincipalAssignmentsQuery,
  AccessReviewResourcePrincipalAssignmentsQueryVariables
>;
export const UpdateAccessReviewReviewersDocument = gql`
  mutation UpdateAccessReviewReviewers(
    $input: UpdateAccessReviewReviewersInput!
  ) {
    updateAccessReviewReviewers(input: $input) {
      __typename
      ... on UpdateAccessReviewReviewersResult {
        success
      }
      ... on UpdateAccessReviewReviewersValidationError {
        message
      }
    }
  }
`;
export type UpdateAccessReviewReviewersMutationFn = Apollo.MutationFunction<
  UpdateAccessReviewReviewersMutation,
  UpdateAccessReviewReviewersMutationVariables
>;

/**
 * __useUpdateAccessReviewReviewersMutation__
 *
 * To run a mutation, you first call `useUpdateAccessReviewReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessReviewReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessReviewReviewersMutation, { data, loading, error }] = useUpdateAccessReviewReviewersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessReviewReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessReviewReviewersMutation,
    UpdateAccessReviewReviewersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessReviewReviewersMutation,
    UpdateAccessReviewReviewersMutationVariables
  >(UpdateAccessReviewReviewersDocument, options);
}
export type UpdateAccessReviewReviewersMutationHookResult = ReturnType<
  typeof useUpdateAccessReviewReviewersMutation
>;
export type UpdateAccessReviewReviewersMutationResult = Apollo.MutationResult<
  UpdateAccessReviewReviewersMutation
>;
export type UpdateAccessReviewReviewersMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessReviewReviewersMutation,
  UpdateAccessReviewReviewersMutationVariables
>;
export const AccessReviewChangesDocument = gql`
  query AccessReviewChanges($input: AccessReviewChangesInput!) {
    accessReviewChanges(input: $input) {
      items {
        ...AccessReviewChangeItemSmall
      }
    }
  }
  ${AccessReviewChangeItemSmallFragmentDoc}
`;

/**
 * __useAccessReviewChangesQuery__
 *
 * To run a query within a React component, call `useAccessReviewChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewChangesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewChangesQuery,
    AccessReviewChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewChangesQuery,
    AccessReviewChangesQueryVariables
  >(AccessReviewChangesDocument, options);
}
export function useAccessReviewChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewChangesQuery,
    AccessReviewChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewChangesQuery,
    AccessReviewChangesQueryVariables
  >(AccessReviewChangesDocument, options);
}
export type AccessReviewChangesQueryHookResult = ReturnType<
  typeof useAccessReviewChangesQuery
>;
export type AccessReviewChangesLazyQueryHookResult = ReturnType<
  typeof useAccessReviewChangesLazyQuery
>;
export type AccessReviewChangesQueryResult = Apollo.QueryResult<
  AccessReviewChangesQuery,
  AccessReviewChangesQueryVariables
>;
export const AccessReviewChangeDetailsDocument = gql`
  query AccessReviewChangeDetails($input: AccessReviewChangeInput!) {
    accessReviewChange(input: $input) {
      users {
        resourceUsers {
          ...AccessReviewResourceUser
        }
        groupUsers {
          ...AccessReviewGroupUser
        }
        connectionUsers {
          ...AccessReviewConnectionUser
        }
      }
    }
  }
  ${AccessReviewResourceUserFragmentDoc}
  ${AccessReviewGroupUserFragmentDoc}
  ${AccessReviewConnectionUserFragmentDoc}
`;

/**
 * __useAccessReviewChangeDetailsQuery__
 *
 * To run a query within a React component, call `useAccessReviewChangeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewChangeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewChangeDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewChangeDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewChangeDetailsQuery,
    AccessReviewChangeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewChangeDetailsQuery,
    AccessReviewChangeDetailsQueryVariables
  >(AccessReviewChangeDetailsDocument, options);
}
export function useAccessReviewChangeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewChangeDetailsQuery,
    AccessReviewChangeDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewChangeDetailsQuery,
    AccessReviewChangeDetailsQueryVariables
  >(AccessReviewChangeDetailsDocument, options);
}
export type AccessReviewChangeDetailsQueryHookResult = ReturnType<
  typeof useAccessReviewChangeDetailsQuery
>;
export type AccessReviewChangeDetailsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewChangeDetailsLazyQuery
>;
export type AccessReviewChangeDetailsQueryResult = Apollo.QueryResult<
  AccessReviewChangeDetailsQuery,
  AccessReviewChangeDetailsQueryVariables
>;
export const AccessReviewChangesWithDetailsDocument = gql`
  query AccessReviewChangesWithDetails($input: AccessReviewChangesInput!) {
    accessReviewChanges(input: $input) {
      items {
        ...AccessReviewChangeItemSmall
        users {
          resourceUsers {
            ...AccessReviewResourceUser
          }
          groupUsers {
            ...AccessReviewGroupUser
          }
          connectionUsers {
            ...AccessReviewConnectionUser
          }
        }
        nhis {
          resourceResources {
            ...AccessReviewResourceResource
          }
        }
        groupResources {
          ...AccessReviewGroupResourceForDetail
        }
      }
    }
  }
  ${AccessReviewChangeItemSmallFragmentDoc}
  ${AccessReviewResourceUserFragmentDoc}
  ${AccessReviewGroupUserFragmentDoc}
  ${AccessReviewConnectionUserFragmentDoc}
  ${AccessReviewResourceResourceFragmentDoc}
  ${AccessReviewGroupResourceForDetailFragmentDoc}
`;

/**
 * __useAccessReviewChangesWithDetailsQuery__
 *
 * To run a query within a React component, call `useAccessReviewChangesWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewChangesWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewChangesWithDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewChangesWithDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewChangesWithDetailsQuery,
    AccessReviewChangesWithDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewChangesWithDetailsQuery,
    AccessReviewChangesWithDetailsQueryVariables
  >(AccessReviewChangesWithDetailsDocument, options);
}
export function useAccessReviewChangesWithDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewChangesWithDetailsQuery,
    AccessReviewChangesWithDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewChangesWithDetailsQuery,
    AccessReviewChangesWithDetailsQueryVariables
  >(AccessReviewChangesWithDetailsDocument, options);
}
export type AccessReviewChangesWithDetailsQueryHookResult = ReturnType<
  typeof useAccessReviewChangesWithDetailsQuery
>;
export type AccessReviewChangesWithDetailsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewChangesWithDetailsLazyQuery
>;
export type AccessReviewChangesWithDetailsQueryResult = Apollo.QueryResult<
  AccessReviewChangesWithDetailsQuery,
  AccessReviewChangesWithDetailsQueryVariables
>;
export const AccessReviewEventsDocument = gql`
  query AccessReviewEvents($input: EventsInput!) {
    events(input: $input) {
      __typename
      ... on EventsResult {
        events {
          ...EventWithSubEventInfo
        }
        cursor
      }
    }
  }
  ${EventWithSubEventInfoFragmentDoc}
`;

/**
 * __useAccessReviewEventsQuery__
 *
 * To run a query within a React component, call `useAccessReviewEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewEventsQuery,
    AccessReviewEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewEventsQuery,
    AccessReviewEventsQueryVariables
  >(AccessReviewEventsDocument, options);
}
export function useAccessReviewEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewEventsQuery,
    AccessReviewEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewEventsQuery,
    AccessReviewEventsQueryVariables
  >(AccessReviewEventsDocument, options);
}
export type AccessReviewEventsQueryHookResult = ReturnType<
  typeof useAccessReviewEventsQuery
>;
export type AccessReviewEventsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewEventsLazyQuery
>;
export type AccessReviewEventsQueryResult = Apollo.QueryResult<
  AccessReviewEventsQuery,
  AccessReviewEventsQueryVariables
>;
export const AccessReviewGroupDetailsDocument = gql`
  query AccessReviewGroupDetails($input: AccessReviewGroupInput!) {
    accessReviewGroup(input: $input) {
      __typename
      ... on AccessReviewGroupResult {
        accessReviewGroup {
          ...AccessReviewGroupForDetail
        }
      }
      ... on AccessReviewGroupNotFoundError {
        message
      }
    }
  }
  ${AccessReviewGroupForDetailFragmentDoc}
`;

/**
 * __useAccessReviewGroupDetailsQuery__
 *
 * To run a query within a React component, call `useAccessReviewGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewGroupDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewGroupDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewGroupDetailsQuery,
    AccessReviewGroupDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewGroupDetailsQuery,
    AccessReviewGroupDetailsQueryVariables
  >(AccessReviewGroupDetailsDocument, options);
}
export function useAccessReviewGroupDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewGroupDetailsQuery,
    AccessReviewGroupDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewGroupDetailsQuery,
    AccessReviewGroupDetailsQueryVariables
  >(AccessReviewGroupDetailsDocument, options);
}
export type AccessReviewGroupDetailsQueryHookResult = ReturnType<
  typeof useAccessReviewGroupDetailsQuery
>;
export type AccessReviewGroupDetailsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewGroupDetailsLazyQuery
>;
export type AccessReviewGroupDetailsQueryResult = Apollo.QueryResult<
  AccessReviewGroupDetailsQuery,
  AccessReviewGroupDetailsQueryVariables
>;
export const MyAccessReviewItemsV3Document = gql`
  query MyAccessReviewItemsV3($input: MyAccessReviewItemsInput!) {
    myAccessReviewItems(input: $input) {
      ... on MyAccessReviewItemsResult {
        resources {
          ...AccessReviewMyResourceV3
        }
        groups {
          ...AccessReviewMyGroupV3
        }
        connections {
          ...AccessReviewMyConnectionV3
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewMyResourceV3FragmentDoc}
  ${AccessReviewMyGroupV3FragmentDoc}
  ${AccessReviewMyConnectionV3FragmentDoc}
`;

/**
 * __useMyAccessReviewItemsV3Query__
 *
 * To run a query within a React component, call `useMyAccessReviewItemsV3Query` and pass it any options that fit your needs.
 * When your component renders, `useMyAccessReviewItemsV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccessReviewItemsV3Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyAccessReviewItemsV3Query(
  baseOptions: Apollo.QueryHookOptions<
    MyAccessReviewItemsV3Query,
    MyAccessReviewItemsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyAccessReviewItemsV3Query,
    MyAccessReviewItemsV3QueryVariables
  >(MyAccessReviewItemsV3Document, options);
}
export function useMyAccessReviewItemsV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyAccessReviewItemsV3Query,
    MyAccessReviewItemsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyAccessReviewItemsV3Query,
    MyAccessReviewItemsV3QueryVariables
  >(MyAccessReviewItemsV3Document, options);
}
export type MyAccessReviewItemsV3QueryHookResult = ReturnType<
  typeof useMyAccessReviewItemsV3Query
>;
export type MyAccessReviewItemsV3LazyQueryHookResult = ReturnType<
  typeof useMyAccessReviewItemsV3LazyQuery
>;
export type MyAccessReviewItemsV3QueryResult = Apollo.QueryResult<
  MyAccessReviewItemsV3Query,
  MyAccessReviewItemsV3QueryVariables
>;
export const AccessReviewReviewersDocument = gql`
  query AccessReviewReviewers($accessReviewId: AccessReviewId!) {
    accessReview(input: { accessReviewId: $accessReviewId }) {
      __typename
      ... on AccessReviewResult {
        __typename
        accessReview {
          id
          name
          reviewers {
            ...AccessReviewReviewer
          }
        }
      }
      ... on AccessReviewNotFoundError {
        __typename
        message
      }
    }
  }
  ${AccessReviewReviewerFragmentDoc}
`;

/**
 * __useAccessReviewReviewersQuery__
 *
 * To run a query within a React component, call `useAccessReviewReviewersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewReviewersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewReviewersQuery({
 *   variables: {
 *      accessReviewId: // value for 'accessReviewId'
 *   },
 * });
 */
export function useAccessReviewReviewersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewReviewersQuery,
    AccessReviewReviewersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewReviewersQuery,
    AccessReviewReviewersQueryVariables
  >(AccessReviewReviewersDocument, options);
}
export function useAccessReviewReviewersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewReviewersQuery,
    AccessReviewReviewersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewReviewersQuery,
    AccessReviewReviewersQueryVariables
  >(AccessReviewReviewersDocument, options);
}
export type AccessReviewReviewersQueryHookResult = ReturnType<
  typeof useAccessReviewReviewersQuery
>;
export type AccessReviewReviewersLazyQueryHookResult = ReturnType<
  typeof useAccessReviewReviewersLazyQuery
>;
export type AccessReviewReviewersQueryResult = Apollo.QueryResult<
  AccessReviewReviewersQuery,
  AccessReviewReviewersQueryVariables
>;
export const AccessReviewReviewerDocument = gql`
  query AccessReviewReviewer($input: AccessReviewReviewerInput!) {
    accessReviewReviewer(input: $input) {
      reviewer {
        ...AccessReviewReviewerDetail
      }
    }
  }
  ${AccessReviewReviewerDetailFragmentDoc}
`;

/**
 * __useAccessReviewReviewerQuery__
 *
 * To run a query within a React component, call `useAccessReviewReviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewReviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewReviewerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewReviewerQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewReviewerQuery,
    AccessReviewReviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewReviewerQuery,
    AccessReviewReviewerQueryVariables
  >(AccessReviewReviewerDocument, options);
}
export function useAccessReviewReviewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewReviewerQuery,
    AccessReviewReviewerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewReviewerQuery,
    AccessReviewReviewerQueryVariables
  >(AccessReviewReviewerDocument, options);
}
export type AccessReviewReviewerQueryHookResult = ReturnType<
  typeof useAccessReviewReviewerQuery
>;
export type AccessReviewReviewerLazyQueryHookResult = ReturnType<
  typeof useAccessReviewReviewerLazyQuery
>;
export type AccessReviewReviewerQueryResult = Apollo.QueryResult<
  AccessReviewReviewerQuery,
  AccessReviewReviewerQueryVariables
>;
export const MyAccessReviewItemsDocument = gql`
  query MyAccessReviewItems($input: MyAccessReviewItemsInput!) {
    myAccessReviewItems(input: $input) {
      ... on MyAccessReviewItemsResult {
        resources {
          ...AccessReviewResourceSmall
        }
        groups {
          ...AccessReviewGroupSmall
        }
        connections {
          ...AccessReviewConnectionSmall
        }
      }
      ... on AccessReviewNotFoundError {
        message
      }
    }
  }
  ${AccessReviewResourceSmallFragmentDoc}
  ${AccessReviewGroupSmallFragmentDoc}
  ${AccessReviewConnectionSmallFragmentDoc}
`;

/**
 * __useMyAccessReviewItemsQuery__
 *
 * To run a query within a React component, call `useMyAccessReviewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccessReviewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccessReviewItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMyAccessReviewItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyAccessReviewItemsQuery,
    MyAccessReviewItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyAccessReviewItemsQuery,
    MyAccessReviewItemsQueryVariables
  >(MyAccessReviewItemsDocument, options);
}
export function useMyAccessReviewItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyAccessReviewItemsQuery,
    MyAccessReviewItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyAccessReviewItemsQuery,
    MyAccessReviewItemsQueryVariables
  >(MyAccessReviewItemsDocument, options);
}
export type MyAccessReviewItemsQueryHookResult = ReturnType<
  typeof useMyAccessReviewItemsQuery
>;
export type MyAccessReviewItemsLazyQueryHookResult = ReturnType<
  typeof useMyAccessReviewItemsLazyQuery
>;
export type MyAccessReviewItemsQueryResult = Apollo.QueryResult<
  MyAccessReviewItemsQuery,
  MyAccessReviewItemsQueryVariables
>;
export const AccessReviewItemsDocument = gql`
  query AccessReviewItems($input: AccessReviewItemsInput!) {
    accessReviewItems(input: $input) {
      items {
        ...AccessReviewItem
      }
      cursor
    }
  }
  ${AccessReviewItemFragmentDoc}
`;

/**
 * __useAccessReviewItemsQuery__
 *
 * To run a query within a React component, call `useAccessReviewItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewItemsQuery,
    AccessReviewItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewItemsQuery,
    AccessReviewItemsQueryVariables
  >(AccessReviewItemsDocument, options);
}
export function useAccessReviewItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewItemsQuery,
    AccessReviewItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewItemsQuery,
    AccessReviewItemsQueryVariables
  >(AccessReviewItemsDocument, options);
}
export type AccessReviewItemsQueryHookResult = ReturnType<
  typeof useAccessReviewItemsQuery
>;
export type AccessReviewItemsLazyQueryHookResult = ReturnType<
  typeof useAccessReviewItemsLazyQuery
>;
export type AccessReviewItemsQueryResult = Apollo.QueryResult<
  AccessReviewItemsQuery,
  AccessReviewItemsQueryVariables
>;
export const ResourceMoreInfoDocument = gql`
  query ResourceMoreInfo($input: ResourceInput!) {
    resource(input: $input) {
      __typename
      ... on ResourceResult {
        resource {
          id
          name
          adminOwner {
            id
            name
          }
          connection {
            id
            name
          }
          configTemplate {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useResourceMoreInfoQuery__
 *
 * To run a query within a React component, call `useResourceMoreInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceMoreInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceMoreInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceMoreInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceMoreInfoQuery,
    ResourceMoreInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceMoreInfoQuery, ResourceMoreInfoQueryVariables>(
    ResourceMoreInfoDocument,
    options
  );
}
export function useResourceMoreInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceMoreInfoQuery,
    ResourceMoreInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceMoreInfoQuery,
    ResourceMoreInfoQueryVariables
  >(ResourceMoreInfoDocument, options);
}
export type ResourceMoreInfoQueryHookResult = ReturnType<
  typeof useResourceMoreInfoQuery
>;
export type ResourceMoreInfoLazyQueryHookResult = ReturnType<
  typeof useResourceMoreInfoLazyQuery
>;
export type ResourceMoreInfoQueryResult = Apollo.QueryResult<
  ResourceMoreInfoQuery,
  ResourceMoreInfoQueryVariables
>;
export const StartAccessReviewDocument = gql`
  mutation StartAccessReview($input: StartAccessReviewInput!) {
    startAccessReview(input: $input) {
      __typename
      ... on StartAccessReviewResult {
        accessReview {
          id
        }
      }
    }
  }
`;
export type StartAccessReviewMutationFn = Apollo.MutationFunction<
  StartAccessReviewMutation,
  StartAccessReviewMutationVariables
>;

/**
 * __useStartAccessReviewMutation__
 *
 * To run a mutation, you first call `useStartAccessReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAccessReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAccessReviewMutation, { data, loading, error }] = useStartAccessReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartAccessReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartAccessReviewMutation,
    StartAccessReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartAccessReviewMutation,
    StartAccessReviewMutationVariables
  >(StartAccessReviewDocument, options);
}
export type StartAccessReviewMutationHookResult = ReturnType<
  typeof useStartAccessReviewMutation
>;
export type StartAccessReviewMutationResult = Apollo.MutationResult<
  StartAccessReviewMutation
>;
export type StartAccessReviewMutationOptions = Apollo.BaseMutationOptions<
  StartAccessReviewMutation,
  StartAccessReviewMutationVariables
>;
export const AccessReviewTemplateDocument = gql`
  query AccessReviewTemplate($input: AccessReviewTemplateInput!) {
    accessReviewTemplate(input: $input) {
      __typename
      ... on AccessReviewTemplateResult {
        accessReviewTemplate {
          ...AccessReviewTemplate
        }
      }
      ... on AccessReviewTemplateNotFound {
        message
      }
    }
  }
  ${AccessReviewTemplateFragmentDoc}
`;

/**
 * __useAccessReviewTemplateQuery__
 *
 * To run a query within a React component, call `useAccessReviewTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewTemplateQuery,
    AccessReviewTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewTemplateQuery,
    AccessReviewTemplateQueryVariables
  >(AccessReviewTemplateDocument, options);
}
export function useAccessReviewTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewTemplateQuery,
    AccessReviewTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewTemplateQuery,
    AccessReviewTemplateQueryVariables
  >(AccessReviewTemplateDocument, options);
}
export type AccessReviewTemplateQueryHookResult = ReturnType<
  typeof useAccessReviewTemplateQuery
>;
export type AccessReviewTemplateLazyQueryHookResult = ReturnType<
  typeof useAccessReviewTemplateLazyQuery
>;
export type AccessReviewTemplateQueryResult = Apollo.QueryResult<
  AccessReviewTemplateQuery,
  AccessReviewTemplateQueryVariables
>;
export const CreateAccessReviewTemplateDocument = gql`
  mutation CreateAccessReviewTemplate(
    $input: CreateAccessReviewTemplateInput!
  ) {
    saveAccessReviewTemplate(input: $input) {
      __typename
      ... on CreateAccessReviewTemplateResult {
        accessReviewTemplate {
          id
        }
      }
    }
  }
`;
export type CreateAccessReviewTemplateMutationFn = Apollo.MutationFunction<
  CreateAccessReviewTemplateMutation,
  CreateAccessReviewTemplateMutationVariables
>;

/**
 * __useCreateAccessReviewTemplateMutation__
 *
 * To run a mutation, you first call `useCreateAccessReviewTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessReviewTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessReviewTemplateMutation, { data, loading, error }] = useCreateAccessReviewTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccessReviewTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccessReviewTemplateMutation,
    CreateAccessReviewTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccessReviewTemplateMutation,
    CreateAccessReviewTemplateMutationVariables
  >(CreateAccessReviewTemplateDocument, options);
}
export type CreateAccessReviewTemplateMutationHookResult = ReturnType<
  typeof useCreateAccessReviewTemplateMutation
>;
export type CreateAccessReviewTemplateMutationResult = Apollo.MutationResult<
  CreateAccessReviewTemplateMutation
>;
export type CreateAccessReviewTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateAccessReviewTemplateMutation,
  CreateAccessReviewTemplateMutationVariables
>;
export const UpdateAccessReviewTemplateDocument = gql`
  mutation UpdateAccessReviewTemplate(
    $input: UpdateAccessReviewTemplateInput!
  ) {
    updateAccessReviewTemplate(input: $input) {
      __typename
      ... on UpdateAccessReviewTemplateResult {
        accessReviewTemplate {
          id
        }
      }
      ... on AccessReviewTemplateNotFound {
        message
      }
    }
  }
`;
export type UpdateAccessReviewTemplateMutationFn = Apollo.MutationFunction<
  UpdateAccessReviewTemplateMutation,
  UpdateAccessReviewTemplateMutationVariables
>;

/**
 * __useUpdateAccessReviewTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateAccessReviewTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessReviewTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessReviewTemplateMutation, { data, loading, error }] = useUpdateAccessReviewTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccessReviewTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccessReviewTemplateMutation,
    UpdateAccessReviewTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccessReviewTemplateMutation,
    UpdateAccessReviewTemplateMutationVariables
  >(UpdateAccessReviewTemplateDocument, options);
}
export type UpdateAccessReviewTemplateMutationHookResult = ReturnType<
  typeof useUpdateAccessReviewTemplateMutation
>;
export type UpdateAccessReviewTemplateMutationResult = Apollo.MutationResult<
  UpdateAccessReviewTemplateMutation
>;
export type UpdateAccessReviewTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccessReviewTemplateMutation,
  UpdateAccessReviewTemplateMutationVariables
>;
export const DeleteAccessReviewTemplatesDocument = gql`
  mutation DeleteAccessReviewTemplates(
    $input: DeleteAccessReviewTemplatesInput!
  ) {
    deleteAccessReviewTemplates(input: $input) {
      __typename
      ... on DeleteAccessReviewTemplatesResult {
        accessReviewTemplates {
          id
        }
      }
      ... on AccessReviewTemplateNotFound {
        message
      }
    }
  }
`;
export type DeleteAccessReviewTemplatesMutationFn = Apollo.MutationFunction<
  DeleteAccessReviewTemplatesMutation,
  DeleteAccessReviewTemplatesMutationVariables
>;

/**
 * __useDeleteAccessReviewTemplatesMutation__
 *
 * To run a mutation, you first call `useDeleteAccessReviewTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessReviewTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessReviewTemplatesMutation, { data, loading, error }] = useDeleteAccessReviewTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccessReviewTemplatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccessReviewTemplatesMutation,
    DeleteAccessReviewTemplatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccessReviewTemplatesMutation,
    DeleteAccessReviewTemplatesMutationVariables
  >(DeleteAccessReviewTemplatesDocument, options);
}
export type DeleteAccessReviewTemplatesMutationHookResult = ReturnType<
  typeof useDeleteAccessReviewTemplatesMutation
>;
export type DeleteAccessReviewTemplatesMutationResult = Apollo.MutationResult<
  DeleteAccessReviewTemplatesMutation
>;
export type DeleteAccessReviewTemplatesMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccessReviewTemplatesMutation,
  DeleteAccessReviewTemplatesMutationVariables
>;
export const AccessReviewProposedChangesDocument = gql`
  query AccessReviewProposedChanges($input: AccessReviewProposedChangeInput!) {
    accessReviewProposedChanges(input: $input) {
      __typename
      ... on AccessReviewProposedChangesResult {
        proposedChanges {
          ... on AccessReviewResourceProposedChange {
            ...AccessReviewResourceProposedChange
          }
          ... on AccessReviewGroupProposedChange {
            ...AccessReviewGroupProposedChange
          }
        }
      }
    }
  }
  ${AccessReviewResourceProposedChangeFragmentDoc}
  ${AccessReviewGroupProposedChangeFragmentDoc}
`;

/**
 * __useAccessReviewProposedChangesQuery__
 *
 * To run a query within a React component, call `useAccessReviewProposedChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessReviewProposedChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessReviewProposedChangesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessReviewProposedChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessReviewProposedChangesQuery,
    AccessReviewProposedChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessReviewProposedChangesQuery,
    AccessReviewProposedChangesQueryVariables
  >(AccessReviewProposedChangesDocument, options);
}
export function useAccessReviewProposedChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessReviewProposedChangesQuery,
    AccessReviewProposedChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessReviewProposedChangesQuery,
    AccessReviewProposedChangesQueryVariables
  >(AccessReviewProposedChangesDocument, options);
}
export type AccessReviewProposedChangesQueryHookResult = ReturnType<
  typeof useAccessReviewProposedChangesQuery
>;
export type AccessReviewProposedChangesLazyQueryHookResult = ReturnType<
  typeof useAccessReviewProposedChangesLazyQuery
>;
export type AccessReviewProposedChangesQueryResult = Apollo.QueryResult<
  AccessReviewProposedChangesQuery,
  AccessReviewProposedChangesQueryVariables
>;
export const AwsAccountsColumnDocument = gql`
  query AWSAccountsColumn(
    $access: AccessOption!
    $appId: UUID!
    $cursor: String
    $searchQuery: String
    $sortBy: AppItemsSortBy
  ) {
    app(id: $appId) {
      ... on App {
        id
        name
        items(
          input: {
            itemType: "AWS_ACCOUNT"
            access: $access
            cursor: $cursor
            searchQuery: $searchQuery
            sortBy: $sortBy
          }
        ) {
          items {
            key
            resource {
              ...ResourceForSelect
            }
            group {
              ...GroupForSelect
            }
          }
          cursor
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
  ${ResourceForSelectFragmentDoc}
  ${GroupForSelectFragmentDoc}
`;

/**
 * __useAwsAccountsColumnQuery__
 *
 * To run a query within a React component, call `useAwsAccountsColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsAccountsColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsAccountsColumnQuery({
 *   variables: {
 *      access: // value for 'access'
 *      appId: // value for 'appId'
 *      cursor: // value for 'cursor'
 *      searchQuery: // value for 'searchQuery'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAwsAccountsColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    AwsAccountsColumnQuery,
    AwsAccountsColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AwsAccountsColumnQuery,
    AwsAccountsColumnQueryVariables
  >(AwsAccountsColumnDocument, options);
}
export function useAwsAccountsColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AwsAccountsColumnQuery,
    AwsAccountsColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AwsAccountsColumnQuery,
    AwsAccountsColumnQueryVariables
  >(AwsAccountsColumnDocument, options);
}
export type AwsAccountsColumnQueryHookResult = ReturnType<
  typeof useAwsAccountsColumnQuery
>;
export type AwsAccountsColumnLazyQueryHookResult = ReturnType<
  typeof useAwsAccountsColumnLazyQuery
>;
export type AwsAccountsColumnQueryResult = Apollo.QueryResult<
  AwsAccountsColumnQuery,
  AwsAccountsColumnQueryVariables
>;
export const AwsAllAccountsColumnDocument = gql`
  query AWSAllAccountsColumn($id: ConnectionId!) {
    connection(input: { id: $id }) {
      ... on ConnectionResult {
        connection {
          name
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAwsAllAccountsColumnQuery__
 *
 * To run a query within a React component, call `useAwsAllAccountsColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsAllAccountsColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsAllAccountsColumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAwsAllAccountsColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    AwsAllAccountsColumnQuery,
    AwsAllAccountsColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AwsAllAccountsColumnQuery,
    AwsAllAccountsColumnQueryVariables
  >(AwsAllAccountsColumnDocument, options);
}
export function useAwsAllAccountsColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AwsAllAccountsColumnQuery,
    AwsAllAccountsColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AwsAllAccountsColumnQuery,
    AwsAllAccountsColumnQueryVariables
  >(AwsAllAccountsColumnDocument, options);
}
export type AwsAllAccountsColumnQueryHookResult = ReturnType<
  typeof useAwsAllAccountsColumnQuery
>;
export type AwsAllAccountsColumnLazyQueryHookResult = ReturnType<
  typeof useAwsAllAccountsColumnLazyQuery
>;
export type AwsAllAccountsColumnQueryResult = Apollo.QueryResult<
  AwsAllAccountsColumnQuery,
  AwsAllAccountsColumnQueryVariables
>;
export const AccountDetailColumnDocument = gql`
  query AccountDetailColumn($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          name
          connection {
            id
            name
          }
          resourceType
          authorizedActions
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAccountDetailColumnQuery__
 *
 * To run a query within a React component, call `useAccountDetailColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailColumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountDetailColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountDetailColumnQuery,
    AccountDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountDetailColumnQuery,
    AccountDetailColumnQueryVariables
  >(AccountDetailColumnDocument, options);
}
export function useAccountDetailColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountDetailColumnQuery,
    AccountDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountDetailColumnQuery,
    AccountDetailColumnQueryVariables
  >(AccountDetailColumnDocument, options);
}
export type AccountDetailColumnQueryHookResult = ReturnType<
  typeof useAccountDetailColumnQuery
>;
export type AccountDetailColumnLazyQueryHookResult = ReturnType<
  typeof useAccountDetailColumnLazyQuery
>;
export type AccountDetailColumnQueryResult = Apollo.QueryResult<
  AccountDetailColumnQuery,
  AccountDetailColumnQueryVariables
>;
export const AppDetailColumnHeaderDocument = gql`
  query AppDetailColumnHeader($connectionId: ConnectionId!) {
    connection(input: { id: $connectionId }) {
      __typename
      ... on ConnectionResult {
        connection {
          ...ConnectionPreviewSmall
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
    organizationSettings {
      __typename
      ... on OrganizationSettingsResult {
        settings {
          roleCreationReviewerOwner {
            id
          }
        }
      }
    }
  }
  ${ConnectionPreviewSmallFragmentDoc}
`;

/**
 * __useAppDetailColumnHeaderQuery__
 *
 * To run a query within a React component, call `useAppDetailColumnHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailColumnHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailColumnHeaderQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useAppDetailColumnHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailColumnHeaderQuery,
    AppDetailColumnHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppDetailColumnHeaderQuery,
    AppDetailColumnHeaderQueryVariables
  >(AppDetailColumnHeaderDocument, options);
}
export function useAppDetailColumnHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailColumnHeaderQuery,
    AppDetailColumnHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppDetailColumnHeaderQuery,
    AppDetailColumnHeaderQueryVariables
  >(AppDetailColumnHeaderDocument, options);
}
export type AppDetailColumnHeaderQueryHookResult = ReturnType<
  typeof useAppDetailColumnHeaderQuery
>;
export type AppDetailColumnHeaderLazyQueryHookResult = ReturnType<
  typeof useAppDetailColumnHeaderLazyQuery
>;
export type AppDetailColumnHeaderQueryResult = Apollo.QueryResult<
  AppDetailColumnHeaderQuery,
  AppDetailColumnHeaderQueryVariables
>;
export const AppDetailColumnDocument = gql`
  query AppDetailColumn($id: UUID!) {
    app(id: $id) {
      ... on App {
        id
        name
        app {
          ... on ConnectionApp {
            connectionId
            connectionType
            connectionIconUrl
            connection {
              metadata {
                ...ConnectionMetadataOutput
              }
            }
          }
          ... on OktaResourceApp {
            resourceId
            iconUrl
            isVisible
          }
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
  ${ConnectionMetadataOutputFragmentDoc}
`;

/**
 * __useAppDetailColumnQuery__
 *
 * To run a query within a React component, call `useAppDetailColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailColumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDetailColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailColumnQuery,
    AppDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppDetailColumnQuery, AppDetailColumnQueryVariables>(
    AppDetailColumnDocument,
    options
  );
}
export function useAppDetailColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailColumnQuery,
    AppDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppDetailColumnQuery,
    AppDetailColumnQueryVariables
  >(AppDetailColumnDocument, options);
}
export type AppDetailColumnQueryHookResult = ReturnType<
  typeof useAppDetailColumnQuery
>;
export type AppDetailColumnLazyQueryHookResult = ReturnType<
  typeof useAppDetailColumnLazyQuery
>;
export type AppDetailColumnQueryResult = Apollo.QueryResult<
  AppDetailColumnQuery,
  AppDetailColumnQueryVariables
>;
export const AppDetailColumnForItemsDocument = gql`
  query AppDetailColumnForItems($id: UUID!) {
    app(id: $id) {
      ... on App {
        id
        app {
          ... on ConnectionApp {
            connectionType
          }
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAppDetailColumnForItemsQuery__
 *
 * To run a query within a React component, call `useAppDetailColumnForItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailColumnForItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailColumnForItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDetailColumnForItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailColumnForItemsQuery,
    AppDetailColumnForItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppDetailColumnForItemsQuery,
    AppDetailColumnForItemsQueryVariables
  >(AppDetailColumnForItemsDocument, options);
}
export function useAppDetailColumnForItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailColumnForItemsQuery,
    AppDetailColumnForItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppDetailColumnForItemsQuery,
    AppDetailColumnForItemsQueryVariables
  >(AppDetailColumnForItemsDocument, options);
}
export type AppDetailColumnForItemsQueryHookResult = ReturnType<
  typeof useAppDetailColumnForItemsQuery
>;
export type AppDetailColumnForItemsLazyQueryHookResult = ReturnType<
  typeof useAppDetailColumnForItemsLazyQuery
>;
export type AppDetailColumnForItemsQueryResult = Apollo.QueryResult<
  AppDetailColumnForItemsQuery,
  AppDetailColumnForItemsQueryVariables
>;
export const AppDetailColumnForResourceDocument = gql`
  query AppDetailColumnForResource($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          connection {
            id
            connectionType
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAppDetailColumnForResourceQuery__
 *
 * To run a query within a React component, call `useAppDetailColumnForResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailColumnForResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailColumnForResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDetailColumnForResourceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailColumnForResourceQuery,
    AppDetailColumnForResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppDetailColumnForResourceQuery,
    AppDetailColumnForResourceQueryVariables
  >(AppDetailColumnForResourceDocument, options);
}
export function useAppDetailColumnForResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailColumnForResourceQuery,
    AppDetailColumnForResourceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppDetailColumnForResourceQuery,
    AppDetailColumnForResourceQueryVariables
  >(AppDetailColumnForResourceDocument, options);
}
export type AppDetailColumnForResourceQueryHookResult = ReturnType<
  typeof useAppDetailColumnForResourceQuery
>;
export type AppDetailColumnForResourceLazyQueryHookResult = ReturnType<
  typeof useAppDetailColumnForResourceLazyQuery
>;
export type AppDetailColumnForResourceQueryResult = Apollo.QueryResult<
  AppDetailColumnForResourceQuery,
  AppDetailColumnForResourceQueryVariables
>;
export const AppDetailColumnForGroupDocument = gql`
  query AppDetailColumnForGroup($id: GroupId!) {
    group(input: { id: $id }) {
      __typename
      ... on GroupResult {
        group {
          connection {
            id
            connectionType
          }
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useAppDetailColumnForGroupQuery__
 *
 * To run a query within a React component, call `useAppDetailColumnForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailColumnForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailColumnForGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppDetailColumnForGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailColumnForGroupQuery,
    AppDetailColumnForGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppDetailColumnForGroupQuery,
    AppDetailColumnForGroupQueryVariables
  >(AppDetailColumnForGroupDocument, options);
}
export function useAppDetailColumnForGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailColumnForGroupQuery,
    AppDetailColumnForGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppDetailColumnForGroupQuery,
    AppDetailColumnForGroupQueryVariables
  >(AppDetailColumnForGroupDocument, options);
}
export type AppDetailColumnForGroupQueryHookResult = ReturnType<
  typeof useAppDetailColumnForGroupQuery
>;
export type AppDetailColumnForGroupLazyQueryHookResult = ReturnType<
  typeof useAppDetailColumnForGroupLazyQuery
>;
export type AppDetailColumnForGroupQueryResult = Apollo.QueryResult<
  AppDetailColumnForGroupQuery,
  AppDetailColumnForGroupQueryVariables
>;
export const AppResourcesDocument = gql`
  query AppResources(
    $id: UUID!
    $itemType: String
    $cursor: String
    $access: AccessOption!
    $parentResourceId: NullableResourceId
    $ancestorResourceId: NullableResourceId
    $searchQuery: String
    $sortBy: AppItemsSortBy
    $includeGroups: Boolean
    $hasV3: Boolean
  ) {
    app(id: $id) {
      ... on App {
        items(
          input: {
            itemType: $itemType
            cursor: $cursor
            access: $access
            parentResourceId: $parentResourceId
            ancestorResourceId: $ancestorResourceId
            searchQuery: $searchQuery
            sortBy: $sortBy
            includeGroups: $includeGroups
            hasV3: $hasV3
          }
        ) {
          items {
            key
            resource {
              ...AppResourceRow
            }
            group {
              ...AppGroupRow
            }
          }
          cursor
        }
        itemTypes(access: $access, ancestorResourceId: $ancestorResourceId) {
          itemType
          displayText
        }
      }
    }
  }
  ${AppResourceRowFragmentDoc}
  ${AppGroupRowFragmentDoc}
`;

/**
 * __useAppResourcesQuery__
 *
 * To run a query within a React component, call `useAppResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppResourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemType: // value for 'itemType'
 *      cursor: // value for 'cursor'
 *      access: // value for 'access'
 *      parentResourceId: // value for 'parentResourceId'
 *      ancestorResourceId: // value for 'ancestorResourceId'
 *      searchQuery: // value for 'searchQuery'
 *      sortBy: // value for 'sortBy'
 *      includeGroups: // value for 'includeGroups'
 *      hasV3: // value for 'hasV3'
 *   },
 * });
 */
export function useAppResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppResourcesQuery,
    AppResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppResourcesQuery, AppResourcesQueryVariables>(
    AppResourcesDocument,
    options
  );
}
export function useAppResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppResourcesQuery,
    AppResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppResourcesQuery, AppResourcesQueryVariables>(
    AppResourcesDocument,
    options
  );
}
export type AppResourcesQueryHookResult = ReturnType<
  typeof useAppResourcesQuery
>;
export type AppResourcesLazyQueryHookResult = ReturnType<
  typeof useAppResourcesLazyQuery
>;
export type AppResourcesQueryResult = Apollo.QueryResult<
  AppResourcesQuery,
  AppResourcesQueryVariables
>;
export const AppsBulkEditDocument = gql`
  query AppsBulkEdit($resourceIds: [ResourceId!], $groupIds: [GroupId!]!) {
    resources(input: { resourceIds: $resourceIds }) {
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewTiny
          authorizedActions
          parentResource {
            id
          }
          configTemplate {
            id
          }
          connection {
            connectionType
          }
        }
      }
    }
    groups(input: { groupIds: $groupIds }) {
      ... on GroupsResult {
        groups {
          ...GroupPreviewTiny
          authorizedActions
          configTemplate {
            id
          }
          connection {
            connectionType
          }
          groupBinding {
            sourceGroupId
            sourceGroup {
              ...GroupPreviewTiny
            }
          }
        }
      }
    }
  }
  ${ResourcePreviewTinyFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
`;

/**
 * __useAppsBulkEditQuery__
 *
 * To run a query within a React component, call `useAppsBulkEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsBulkEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsBulkEditQuery({
 *   variables: {
 *      resourceIds: // value for 'resourceIds'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useAppsBulkEditQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppsBulkEditQuery,
    AppsBulkEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppsBulkEditQuery, AppsBulkEditQueryVariables>(
    AppsBulkEditDocument,
    options
  );
}
export function useAppsBulkEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppsBulkEditQuery,
    AppsBulkEditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppsBulkEditQuery, AppsBulkEditQueryVariables>(
    AppsBulkEditDocument,
    options
  );
}
export type AppsBulkEditQueryHookResult = ReturnType<
  typeof useAppsBulkEditQuery
>;
export type AppsBulkEditLazyQueryHookResult = ReturnType<
  typeof useAppsBulkEditLazyQuery
>;
export type AppsBulkEditQueryResult = Apollo.QueryResult<
  AppsBulkEditQuery,
  AppsBulkEditQueryVariables
>;
export const AppsListColumnDocument = gql`
  query AppsListColumn(
    $access: AccessOption!
    $searchQuery: String
    $appCategory: AppCategory
    $cursor: String
    $limit: Int
    $sortBy: AppsSortBy
    $fetchCounts: Boolean = false
    $fetchAccessStats: Boolean = false
  ) {
    apps(
      access: $access
      searchQuery: $searchQuery
      appCategory: $appCategory
      cursor: $cursor
      limit: $limit
      sortBy: $sortBy
    ) {
      apps {
        id
        name
        app {
          ...AppData
        }
        visibility
        resourceCount
        groupAccessCount @include(if: $fetchCounts)
        userAccessCount @include(if: $fetchCounts)
      }
      cursor
      totalNumApps
    }
  }
  ${AppDataFragmentDoc}
`;

/**
 * __useAppsListColumnQuery__
 *
 * To run a query within a React component, call `useAppsListColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsListColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsListColumnQuery({
 *   variables: {
 *      access: // value for 'access'
 *      searchQuery: // value for 'searchQuery'
 *      appCategory: // value for 'appCategory'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      fetchCounts: // value for 'fetchCounts'
 *      fetchAccessStats: // value for 'fetchAccessStats'
 *   },
 * });
 */
export function useAppsListColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppsListColumnQuery,
    AppsListColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppsListColumnQuery, AppsListColumnQueryVariables>(
    AppsListColumnDocument,
    options
  );
}
export function useAppsListColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppsListColumnQuery,
    AppsListColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppsListColumnQuery, AppsListColumnQueryVariables>(
    AppsListColumnDocument,
    options
  );
}
export type AppsListColumnQueryHookResult = ReturnType<
  typeof useAppsListColumnQuery
>;
export type AppsListColumnLazyQueryHookResult = ReturnType<
  typeof useAppsListColumnLazyQuery
>;
export type AppsListColumnQueryResult = Apollo.QueryResult<
  AppsListColumnQuery,
  AppsListColumnQueryVariables
>;
export const BulkActiveResourceRequestConfigsDocument = gql`
  query BulkActiveResourceRequestConfigs(
    $targetUserId: UserId
    $resourceInputs: [RequestConfigurationResourceInput!]!
  ) {
    activeResourceRequestConfigurations(
      input: { targetUserId: $targetUserId, resourceInputs: $resourceInputs }
    ) {
      ... on ResourceRequestConfigurationOutput {
        resourceId
        accessLevelRemoteId
        requestConfiguration {
          ...ActiveRequestConfiguration
        }
      }
    }
  }
  ${ActiveRequestConfigurationFragmentDoc}
`;

/**
 * __useBulkActiveResourceRequestConfigsQuery__
 *
 * To run a query within a React component, call `useBulkActiveResourceRequestConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkActiveResourceRequestConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkActiveResourceRequestConfigsQuery({
 *   variables: {
 *      targetUserId: // value for 'targetUserId'
 *      resourceInputs: // value for 'resourceInputs'
 *   },
 * });
 */
export function useBulkActiveResourceRequestConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkActiveResourceRequestConfigsQuery,
    BulkActiveResourceRequestConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BulkActiveResourceRequestConfigsQuery,
    BulkActiveResourceRequestConfigsQueryVariables
  >(BulkActiveResourceRequestConfigsDocument, options);
}
export function useBulkActiveResourceRequestConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkActiveResourceRequestConfigsQuery,
    BulkActiveResourceRequestConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BulkActiveResourceRequestConfigsQuery,
    BulkActiveResourceRequestConfigsQueryVariables
  >(BulkActiveResourceRequestConfigsDocument, options);
}
export type BulkActiveResourceRequestConfigsQueryHookResult = ReturnType<
  typeof useBulkActiveResourceRequestConfigsQuery
>;
export type BulkActiveResourceRequestConfigsLazyQueryHookResult = ReturnType<
  typeof useBulkActiveResourceRequestConfigsLazyQuery
>;
export type BulkActiveResourceRequestConfigsQueryResult = Apollo.QueryResult<
  BulkActiveResourceRequestConfigsQuery,
  BulkActiveResourceRequestConfigsQueryVariables
>;
export const BulkActiveGroupRequestConfigsDocument = gql`
  query BulkActiveGroupRequestConfigs(
    $targetUserId: UserId
    $groupInputs: [RequestConfigurationGroupInput!]!
  ) {
    activeGroupRequestConfigurations(
      input: { targetUserId: $targetUserId, groupInputs: $groupInputs }
    ) {
      ... on GroupRequestConfigurationOutput {
        groupId
        accessLevelRemoteId
        requestConfiguration {
          ...ActiveRequestConfiguration
        }
      }
    }
  }
  ${ActiveRequestConfigurationFragmentDoc}
`;

/**
 * __useBulkActiveGroupRequestConfigsQuery__
 *
 * To run a query within a React component, call `useBulkActiveGroupRequestConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkActiveGroupRequestConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkActiveGroupRequestConfigsQuery({
 *   variables: {
 *      targetUserId: // value for 'targetUserId'
 *      groupInputs: // value for 'groupInputs'
 *   },
 * });
 */
export function useBulkActiveGroupRequestConfigsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkActiveGroupRequestConfigsQuery,
    BulkActiveGroupRequestConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BulkActiveGroupRequestConfigsQuery,
    BulkActiveGroupRequestConfigsQueryVariables
  >(BulkActiveGroupRequestConfigsDocument, options);
}
export function useBulkActiveGroupRequestConfigsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkActiveGroupRequestConfigsQuery,
    BulkActiveGroupRequestConfigsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BulkActiveGroupRequestConfigsQuery,
    BulkActiveGroupRequestConfigsQueryVariables
  >(BulkActiveGroupRequestConfigsDocument, options);
}
export type BulkActiveGroupRequestConfigsQueryHookResult = ReturnType<
  typeof useBulkActiveGroupRequestConfigsQuery
>;
export type BulkActiveGroupRequestConfigsLazyQueryHookResult = ReturnType<
  typeof useBulkActiveGroupRequestConfigsLazyQuery
>;
export type BulkActiveGroupRequestConfigsQueryResult = Apollo.QueryResult<
  BulkActiveGroupRequestConfigsQuery,
  BulkActiveGroupRequestConfigsQueryVariables
>;
export const BulkImportDocument = gql`
  mutation BulkImport($input: BulkImportRemoteItemsInput!) {
    bulkImportRemoteItems(input: $input) {
      __typename
      ... on BulkImportRemoteItemsResult {
        importedResources {
          ...ResourcePreviewSmall
        }
        importedGroups {
          ...GroupPreviewSmall
        }
        syncTask {
          ...SyncTask
        }
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${SyncTaskFragmentDoc}
`;
export type BulkImportMutationFn = Apollo.MutationFunction<
  BulkImportMutation,
  BulkImportMutationVariables
>;

/**
 * __useBulkImportMutation__
 *
 * To run a mutation, you first call `useBulkImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkImportMutation, { data, loading, error }] = useBulkImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkImportMutation,
    BulkImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkImportMutation, BulkImportMutationVariables>(
    BulkImportDocument,
    options
  );
}
export type BulkImportMutationHookResult = ReturnType<
  typeof useBulkImportMutation
>;
export type BulkImportMutationResult = Apollo.MutationResult<
  BulkImportMutation
>;
export type BulkImportMutationOptions = Apollo.BaseMutationOptions<
  BulkImportMutation,
  BulkImportMutationVariables
>;
export const BulkRequestColumnResourcesDocument = gql`
  query BulkRequestColumnResources($resourceIds: [ResourceId!]!) {
    resources(input: { resourceIds: $resourceIds }) {
      __typename
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewLarge
        }
      }
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
`;

/**
 * __useBulkRequestColumnResourcesQuery__
 *
 * To run a query within a React component, call `useBulkRequestColumnResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkRequestColumnResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkRequestColumnResourcesQuery({
 *   variables: {
 *      resourceIds: // value for 'resourceIds'
 *   },
 * });
 */
export function useBulkRequestColumnResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkRequestColumnResourcesQuery,
    BulkRequestColumnResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BulkRequestColumnResourcesQuery,
    BulkRequestColumnResourcesQueryVariables
  >(BulkRequestColumnResourcesDocument, options);
}
export function useBulkRequestColumnResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkRequestColumnResourcesQuery,
    BulkRequestColumnResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BulkRequestColumnResourcesQuery,
    BulkRequestColumnResourcesQueryVariables
  >(BulkRequestColumnResourcesDocument, options);
}
export type BulkRequestColumnResourcesQueryHookResult = ReturnType<
  typeof useBulkRequestColumnResourcesQuery
>;
export type BulkRequestColumnResourcesLazyQueryHookResult = ReturnType<
  typeof useBulkRequestColumnResourcesLazyQuery
>;
export type BulkRequestColumnResourcesQueryResult = Apollo.QueryResult<
  BulkRequestColumnResourcesQuery,
  BulkRequestColumnResourcesQueryVariables
>;
export const BulkRequestColumnGroupsDocument = gql`
  query BulkRequestColumnGroups($groupIds: [GroupId!]!) {
    groups(input: { groupIds: $groupIds }) {
      __typename
      ... on GroupsResult {
        groups {
          ...GroupPreviewLarge
        }
      }
    }
  }
  ${GroupPreviewLargeFragmentDoc}
`;

/**
 * __useBulkRequestColumnGroupsQuery__
 *
 * To run a query within a React component, call `useBulkRequestColumnGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkRequestColumnGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkRequestColumnGroupsQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useBulkRequestColumnGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BulkRequestColumnGroupsQuery,
    BulkRequestColumnGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BulkRequestColumnGroupsQuery,
    BulkRequestColumnGroupsQueryVariables
  >(BulkRequestColumnGroupsDocument, options);
}
export function useBulkRequestColumnGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BulkRequestColumnGroupsQuery,
    BulkRequestColumnGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BulkRequestColumnGroupsQuery,
    BulkRequestColumnGroupsQueryVariables
  >(BulkRequestColumnGroupsDocument, options);
}
export type BulkRequestColumnGroupsQueryHookResult = ReturnType<
  typeof useBulkRequestColumnGroupsQuery
>;
export type BulkRequestColumnGroupsLazyQueryHookResult = ReturnType<
  typeof useBulkRequestColumnGroupsLazyQuery
>;
export type BulkRequestColumnGroupsQueryResult = Apollo.QueryResult<
  BulkRequestColumnGroupsQuery,
  BulkRequestColumnGroupsQueryVariables
>;
export const BulkUpdateDocument = gql`
  mutation BulkUpdate($input: BulkUpdateItemsInput!) {
    bulkUpdateItems(input: $input) {
      __typename
      ... on BulkUpdateItemsResult {
        updatedResources {
          ...ResourcePreviewLarge
        }
        updatedGroups {
          ...GroupPreviewLarge
        }
        syncTask {
          ...SyncTask
        }
      }
    }
  }
  ${ResourcePreviewLargeFragmentDoc}
  ${GroupPreviewLargeFragmentDoc}
  ${SyncTaskFragmentDoc}
`;
export type BulkUpdateMutationFn = Apollo.MutationFunction<
  BulkUpdateMutation,
  BulkUpdateMutationVariables
>;

/**
 * __useBulkUpdateMutation__
 *
 * To run a mutation, you first call `useBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateMutation, { data, loading, error }] = useBulkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkUpdateMutation,
    BulkUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BulkUpdateMutation, BulkUpdateMutationVariables>(
    BulkUpdateDocument,
    options
  );
}
export type BulkUpdateMutationHookResult = ReturnType<
  typeof useBulkUpdateMutation
>;
export type BulkUpdateMutationResult = Apollo.MutationResult<
  BulkUpdateMutation
>;
export type BulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  BulkUpdateMutation,
  BulkUpdateMutationVariables
>;
export const GroupAddGroupsDocument = gql`
  query GroupAddGroups($groupId: GroupId!) {
    group(input: { id: $groupId }) {
      __typename
      ... on GroupResult {
        group {
          ...GroupPreviewWithGroups
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
    connections(input: {}) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionSummary
        }
      }
    }
  }
  ${GroupPreviewWithGroupsFragmentDoc}
  ${ConnectionSummaryFragmentDoc}
`;

/**
 * __useGroupAddGroupsQuery__
 *
 * To run a query within a React component, call `useGroupAddGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAddGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAddGroupsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupAddGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAddGroupsQuery,
    GroupAddGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupAddGroupsQuery, GroupAddGroupsQueryVariables>(
    GroupAddGroupsDocument,
    options
  );
}
export function useGroupAddGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAddGroupsQuery,
    GroupAddGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupAddGroupsQuery, GroupAddGroupsQueryVariables>(
    GroupAddGroupsDocument,
    options
  );
}
export type GroupAddGroupsQueryHookResult = ReturnType<
  typeof useGroupAddGroupsQuery
>;
export type GroupAddGroupsLazyQueryHookResult = ReturnType<
  typeof useGroupAddGroupsLazyQuery
>;
export type GroupAddGroupsQueryResult = Apollo.QueryResult<
  GroupAddGroupsQuery,
  GroupAddGroupsQueryVariables
>;
export const GroupAddResourcesDocument = gql`
  query GroupAddResources($groupId: GroupId!) {
    group(input: { id: $groupId }) {
      __typename
      ... on GroupResult {
        group {
          ...GroupPreviewWithResources
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
    connections(input: {}) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionSummary
        }
      }
    }
  }
  ${GroupPreviewWithResourcesFragmentDoc}
  ${ConnectionSummaryFragmentDoc}
`;

/**
 * __useGroupAddResourcesQuery__
 *
 * To run a query within a React component, call `useGroupAddResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAddResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAddResourcesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupAddResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAddResourcesQuery,
    GroupAddResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupAddResourcesQuery,
    GroupAddResourcesQueryVariables
  >(GroupAddResourcesDocument, options);
}
export function useGroupAddResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAddResourcesQuery,
    GroupAddResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupAddResourcesQuery,
    GroupAddResourcesQueryVariables
  >(GroupAddResourcesDocument, options);
}
export type GroupAddResourcesQueryHookResult = ReturnType<
  typeof useGroupAddResourcesQuery
>;
export type GroupAddResourcesLazyQueryHookResult = ReturnType<
  typeof useGroupAddResourcesLazyQuery
>;
export type GroupAddResourcesQueryResult = Apollo.QueryResult<
  GroupAddResourcesQuery,
  GroupAddResourcesQueryVariables
>;
export const GroupAddUsersDocument = gql`
  query GroupAddUsers($id: GroupId!, $searchQuery: String) {
    group(input: { id: $id }) {
      __typename
      ... on GroupResult {
        group {
          ...GroupPreviewWithUsers
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
    users(input: { maxNumEntries: 100, searchQuery: $searchQuery }) {
      __typename
      ... on UsersResult {
        users {
          ...UserPreviewSmall
        }
      }
    }
  }
  ${GroupPreviewWithUsersFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;

/**
 * __useGroupAddUsersQuery__
 *
 * To run a query within a React component, call `useGroupAddUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAddUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAddUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGroupAddUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupAddUsersQuery,
    GroupAddUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupAddUsersQuery, GroupAddUsersQueryVariables>(
    GroupAddUsersDocument,
    options
  );
}
export function useGroupAddUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupAddUsersQuery,
    GroupAddUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupAddUsersQuery, GroupAddUsersQueryVariables>(
    GroupAddUsersDocument,
    options
  );
}
export type GroupAddUsersQueryHookResult = ReturnType<
  typeof useGroupAddUsersQuery
>;
export type GroupAddUsersLazyQueryHookResult = ReturnType<
  typeof useGroupAddUsersLazyQuery
>;
export type GroupAddUsersQueryResult = Apollo.QueryResult<
  GroupAddUsersQuery,
  GroupAddUsersQueryVariables
>;
export const ImportItemsListColumnDocument = gql`
  query ImportItemsListColumn($id: UUID!, $access: AccessOption!) {
    app(id: $id) {
      ... on App {
        itemTypes(access: $access) {
          itemType
          displayText
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useImportItemsListColumnQuery__
 *
 * To run a query within a React component, call `useImportItemsListColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportItemsListColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportItemsListColumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *      access: // value for 'access'
 *   },
 * });
 */
export function useImportItemsListColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImportItemsListColumnQuery,
    ImportItemsListColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImportItemsListColumnQuery,
    ImportItemsListColumnQueryVariables
  >(ImportItemsListColumnDocument, options);
}
export function useImportItemsListColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportItemsListColumnQuery,
    ImportItemsListColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportItemsListColumnQuery,
    ImportItemsListColumnQueryVariables
  >(ImportItemsListColumnDocument, options);
}
export type ImportItemsListColumnQueryHookResult = ReturnType<
  typeof useImportItemsListColumnQuery
>;
export type ImportItemsListColumnLazyQueryHookResult = ReturnType<
  typeof useImportItemsListColumnLazyQuery
>;
export type ImportItemsListColumnQueryResult = Apollo.QueryResult<
  ImportItemsListColumnQuery,
  ImportItemsListColumnQueryVariables
>;
export const RemoteItemsListDocument = gql`
  query RemoteItemsList(
    $id: UUID!
    $resourceType: ResourceType
    $groupType: GroupType
    $searchQuery: String
  ) {
    app(id: $id) {
      ... on App {
        remoteItems(
          input: {
            resourceType: $resourceType
            groupType: $groupType
            filters: { searchQuery: $searchQuery }
          }
        ) {
          ... on RemoteAppItemsResult {
            items {
              ...RemoteAppItem
            }
          }
          ... on UserFacingError {
            message
          }
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
  ${RemoteAppItemFragmentDoc}
`;

/**
 * __useRemoteItemsListQuery__
 *
 * To run a query within a React component, call `useRemoteItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemoteItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemoteItemsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceType: // value for 'resourceType'
 *      groupType: // value for 'groupType'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useRemoteItemsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RemoteItemsListQuery,
    RemoteItemsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RemoteItemsListQuery, RemoteItemsListQueryVariables>(
    RemoteItemsListDocument,
    options
  );
}
export function useRemoteItemsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RemoteItemsListQuery,
    RemoteItemsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RemoteItemsListQuery,
    RemoteItemsListQueryVariables
  >(RemoteItemsListDocument, options);
}
export type RemoteItemsListQueryHookResult = ReturnType<
  typeof useRemoteItemsListQuery
>;
export type RemoteItemsListLazyQueryHookResult = ReturnType<
  typeof useRemoteItemsListLazyQuery
>;
export type RemoteItemsListQueryResult = Apollo.QueryResult<
  RemoteItemsListQuery,
  RemoteItemsListQueryVariables
>;
export const ItemsListSectionDocument = gql`
  query ItemsListSection(
    $id: UUID!
    $itemType: String
    $cursor: String
    $access: AccessOption!
    $parentResourceId: NullableResourceId
    $searchQuery: String
    $sortBy: AppItemsSortBy
  ) {
    app(id: $id) {
      ... on App {
        id
        name
        itemTypes(access: $access, parentResourceId: $parentResourceId) {
          itemType
          displayText
        }
        items(
          input: {
            itemType: $itemType
            cursor: $cursor
            access: $access
            parentResourceId: $parentResourceId
            searchQuery: $searchQuery
            sortBy: $sortBy
          }
        ) {
          items {
            key
            resource {
              ...ResourceForSelect
              currentUserAccess {
                resourceId
                resourceUsers {
                  accessLevel {
                    accessLevelRemoteId
                  }
                  resourceId
                  userId
                }
              }
              parentResource {
                id
                name
              }
            }
            group {
              ...GroupForSelect
              currentUserAccess {
                groupId
                groupUser {
                  userId
                  groupId
                }
              }
            }
          }
          cursor
        }
      }
    }
  }
  ${ResourceForSelectFragmentDoc}
  ${GroupForSelectFragmentDoc}
`;

/**
 * __useItemsListSectionQuery__
 *
 * To run a query within a React component, call `useItemsListSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsListSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsListSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemType: // value for 'itemType'
 *      cursor: // value for 'cursor'
 *      access: // value for 'access'
 *      parentResourceId: // value for 'parentResourceId'
 *      searchQuery: // value for 'searchQuery'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useItemsListSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ItemsListSectionQuery,
    ItemsListSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemsListSectionQuery, ItemsListSectionQueryVariables>(
    ItemsListSectionDocument,
    options
  );
}
export function useItemsListSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ItemsListSectionQuery,
    ItemsListSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ItemsListSectionQuery,
    ItemsListSectionQueryVariables
  >(ItemsListSectionDocument, options);
}
export type ItemsListSectionQueryHookResult = ReturnType<
  typeof useItemsListSectionQuery
>;
export type ItemsListSectionLazyQueryHookResult = ReturnType<
  typeof useItemsListSectionLazyQuery
>;
export type ItemsListSectionQueryResult = Apollo.QueryResult<
  ItemsListSectionQuery,
  ItemsListSectionQueryVariables
>;
export const ItemsListSectionImportDocument = gql`
  query ItemsListSectionImport(
    $id: UUID!
    $itemType: String
    $cursor: String
    $access: AccessOption!
    $parentResourceId: NullableResourceId
    $searchQuery: String
    $sortBy: AppItemsSortBy
  ) {
    app(id: $id) {
      ... on App {
        id
        name
        itemTypes(access: $access, parentResourceId: $parentResourceId) {
          itemType
          displayText
        }
        items(
          input: {
            itemType: $itemType
            cursor: $cursor
            access: $access
            parentResourceId: $parentResourceId
            searchQuery: $searchQuery
            sortBy: $sortBy
            includeOnlyUnmanaged: true
          }
        ) {
          items {
            ...AppItem
          }
          totalNumItems
          cursor
        }
      }
    }
  }
  ${AppItemFragmentDoc}
`;

/**
 * __useItemsListSectionImportQuery__
 *
 * To run a query within a React component, call `useItemsListSectionImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsListSectionImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsListSectionImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemType: // value for 'itemType'
 *      cursor: // value for 'cursor'
 *      access: // value for 'access'
 *      parentResourceId: // value for 'parentResourceId'
 *      searchQuery: // value for 'searchQuery'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useItemsListSectionImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ItemsListSectionImportQuery,
    ItemsListSectionImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ItemsListSectionImportQuery,
    ItemsListSectionImportQueryVariables
  >(ItemsListSectionImportDocument, options);
}
export function useItemsListSectionImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ItemsListSectionImportQuery,
    ItemsListSectionImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ItemsListSectionImportQuery,
    ItemsListSectionImportQueryVariables
  >(ItemsListSectionImportDocument, options);
}
export type ItemsListSectionImportQueryHookResult = ReturnType<
  typeof useItemsListSectionImportQuery
>;
export type ItemsListSectionImportLazyQueryHookResult = ReturnType<
  typeof useItemsListSectionImportLazyQuery
>;
export type ItemsListSectionImportQueryResult = Apollo.QueryResult<
  ItemsListSectionImportQuery,
  ItemsListSectionImportQueryVariables
>;
export const ResourceAddGroupsDocument = gql`
  query ResourceAddGroups($resourceId: ResourceId!) {
    resource(input: { id: $resourceId }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourcePreviewWithGroups
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
    connections(input: {}) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionSummary
        }
      }
    }
  }
  ${ResourcePreviewWithGroupsFragmentDoc}
  ${ConnectionSummaryFragmentDoc}
`;

/**
 * __useResourceAddGroupsQuery__
 *
 * To run a query within a React component, call `useResourceAddGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceAddGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceAddGroupsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useResourceAddGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceAddGroupsQuery,
    ResourceAddGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceAddGroupsQuery,
    ResourceAddGroupsQueryVariables
  >(ResourceAddGroupsDocument, options);
}
export function useResourceAddGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceAddGroupsQuery,
    ResourceAddGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceAddGroupsQuery,
    ResourceAddGroupsQueryVariables
  >(ResourceAddGroupsDocument, options);
}
export type ResourceAddGroupsQueryHookResult = ReturnType<
  typeof useResourceAddGroupsQuery
>;
export type ResourceAddGroupsLazyQueryHookResult = ReturnType<
  typeof useResourceAddGroupsLazyQuery
>;
export type ResourceAddGroupsQueryResult = Apollo.QueryResult<
  ResourceAddGroupsQuery,
  ResourceAddGroupsQueryVariables
>;
export const ResourceAddPrincipalsDocument = gql`
  query ResourceAddPrincipals($id: ResourceId!, $searchQuery: String) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourcePreviewWithRoleAssignments
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
    principals(input: { searchQuery: $searchQuery }) {
      __typename
      ... on PrincipalsResult {
        principals {
          ... on Resource {
            ...RoleAssignmentResource
          }
          ... on Group {
            ...RoleAssignmentGroup
          }
          ... on User {
            ...RoleAssignmentUser
          }
        }
      }
    }
  }
  ${ResourcePreviewWithRoleAssignmentsFragmentDoc}
  ${RoleAssignmentResourceFragmentDoc}
  ${RoleAssignmentGroupFragmentDoc}
  ${RoleAssignmentUserFragmentDoc}
`;

/**
 * __useResourceAddPrincipalsQuery__
 *
 * To run a query within a React component, call `useResourceAddPrincipalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceAddPrincipalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceAddPrincipalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useResourceAddPrincipalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceAddPrincipalsQuery,
    ResourceAddPrincipalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceAddPrincipalsQuery,
    ResourceAddPrincipalsQueryVariables
  >(ResourceAddPrincipalsDocument, options);
}
export function useResourceAddPrincipalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceAddPrincipalsQuery,
    ResourceAddPrincipalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceAddPrincipalsQuery,
    ResourceAddPrincipalsQueryVariables
  >(ResourceAddPrincipalsDocument, options);
}
export type ResourceAddPrincipalsQueryHookResult = ReturnType<
  typeof useResourceAddPrincipalsQuery
>;
export type ResourceAddPrincipalsLazyQueryHookResult = ReturnType<
  typeof useResourceAddPrincipalsLazyQuery
>;
export type ResourceAddPrincipalsQueryResult = Apollo.QueryResult<
  ResourceAddPrincipalsQuery,
  ResourceAddPrincipalsQueryVariables
>;
export const ResourceAddUsersDocument = gql`
  query ResourceAddUsers(
    $id: ResourceId!
    $searchQuery: String
    $cursor: String
    $sortBy: UsersSortBy
    $maxNumEntries: Int
  ) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourcePreviewWithUsers
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
    users(
      input: {
        maxNumEntries: $maxNumEntries
        searchQuery: $searchQuery
        cursor: $cursor
        sortBy: $sortBy
      }
    ) {
      __typename
      ... on UsersResult {
        users {
          ...UserPreviewSmall
        }
        cursor
        totalNumUsers
      }
    }
  }
  ${ResourcePreviewWithUsersFragmentDoc}
  ${UserPreviewSmallFragmentDoc}
`;

/**
 * __useResourceAddUsersQuery__
 *
 * To run a query within a React component, call `useResourceAddUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceAddUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceAddUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      searchQuery: // value for 'searchQuery'
 *      cursor: // value for 'cursor'
 *      sortBy: // value for 'sortBy'
 *      maxNumEntries: // value for 'maxNumEntries'
 *   },
 * });
 */
export function useResourceAddUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceAddUsersQuery,
    ResourceAddUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceAddUsersQuery, ResourceAddUsersQueryVariables>(
    ResourceAddUsersDocument,
    options
  );
}
export function useResourceAddUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceAddUsersQuery,
    ResourceAddUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceAddUsersQuery,
    ResourceAddUsersQueryVariables
  >(ResourceAddUsersDocument, options);
}
export type ResourceAddUsersQueryHookResult = ReturnType<
  typeof useResourceAddUsersQuery
>;
export type ResourceAddUsersLazyQueryHookResult = ReturnType<
  typeof useResourceAddUsersLazyQuery
>;
export type ResourceAddUsersQueryResult = Apollo.QueryResult<
  ResourceAddUsersQuery,
  ResourceAddUsersQueryVariables
>;
export const ResourceDetailColumnDocument = gql`
  query ResourceDetailColumn($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourceDetail
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceDetailFragmentDoc}
`;

/**
 * __useResourceDetailColumnQuery__
 *
 * To run a query within a React component, call `useResourceDetailColumnQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDetailColumnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDetailColumnQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceDetailColumnQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceDetailColumnQuery,
    ResourceDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceDetailColumnQuery,
    ResourceDetailColumnQueryVariables
  >(ResourceDetailColumnDocument, options);
}
export function useResourceDetailColumnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceDetailColumnQuery,
    ResourceDetailColumnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceDetailColumnQuery,
    ResourceDetailColumnQueryVariables
  >(ResourceDetailColumnDocument, options);
}
export type ResourceDetailColumnQueryHookResult = ReturnType<
  typeof useResourceDetailColumnQuery
>;
export type ResourceDetailColumnLazyQueryHookResult = ReturnType<
  typeof useResourceDetailColumnLazyQuery
>;
export type ResourceDetailColumnQueryResult = Apollo.QueryResult<
  ResourceDetailColumnQuery,
  ResourceDetailColumnQueryVariables
>;
export const ResourceDetailViewDocument = gql`
  query ResourceDetailView($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourceDetailView
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceDetailViewFragmentDoc}
`;

/**
 * __useResourceDetailViewQuery__
 *
 * To run a query within a React component, call `useResourceDetailViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDetailViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDetailViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceDetailViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceDetailViewQuery,
    ResourceDetailViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceDetailViewQuery,
    ResourceDetailViewQueryVariables
  >(ResourceDetailViewDocument, options);
}
export function useResourceDetailViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceDetailViewQuery,
    ResourceDetailViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceDetailViewQuery,
    ResourceDetailViewQueryVariables
  >(ResourceDetailViewDocument, options);
}
export type ResourceDetailViewQueryHookResult = ReturnType<
  typeof useResourceDetailViewQuery
>;
export type ResourceDetailViewLazyQueryHookResult = ReturnType<
  typeof useResourceDetailViewLazyQuery
>;
export type ResourceDetailViewQueryResult = Apollo.QueryResult<
  ResourceDetailViewQuery,
  ResourceDetailViewQueryVariables
>;
export const ResourceDetailPaginatedResourceUsersDocument = gql`
  query ResourceDetailPaginatedResourceUsers(
    $id: ResourceId!
    $resourceUsers: PaginatedResourceUsersInput!
  ) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          id
          paginatedResourceUsers(input: $resourceUsers) {
            ...PaginatedResourceUsers
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${PaginatedResourceUsersFragmentDoc}
`;

/**
 * __useResourceDetailPaginatedResourceUsersQuery__
 *
 * To run a query within a React component, call `useResourceDetailPaginatedResourceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceDetailPaginatedResourceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceDetailPaginatedResourceUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceUsers: // value for 'resourceUsers'
 *   },
 * });
 */
export function useResourceDetailPaginatedResourceUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceDetailPaginatedResourceUsersQuery,
    ResourceDetailPaginatedResourceUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceDetailPaginatedResourceUsersQuery,
    ResourceDetailPaginatedResourceUsersQueryVariables
  >(ResourceDetailPaginatedResourceUsersDocument, options);
}
export function useResourceDetailPaginatedResourceUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceDetailPaginatedResourceUsersQuery,
    ResourceDetailPaginatedResourceUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceDetailPaginatedResourceUsersQuery,
    ResourceDetailPaginatedResourceUsersQueryVariables
  >(ResourceDetailPaginatedResourceUsersDocument, options);
}
export type ResourceDetailPaginatedResourceUsersQueryHookResult = ReturnType<
  typeof useResourceDetailPaginatedResourceUsersQuery
>;
export type ResourceDetailPaginatedResourceUsersLazyQueryHookResult = ReturnType<
  typeof useResourceDetailPaginatedResourceUsersLazyQuery
>;
export type ResourceDetailPaginatedResourceUsersQueryResult = Apollo.QueryResult<
  ResourceDetailPaginatedResourceUsersQuery,
  ResourceDetailPaginatedResourceUsersQueryVariables
>;
export const ResourceEditViewDocument = gql`
  query ResourceEditView($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ResourceEditViewDetail
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourceEditViewDetailFragmentDoc}
`;

/**
 * __useResourceEditViewQuery__
 *
 * To run a query within a React component, call `useResourceEditViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceEditViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceEditViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceEditViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceEditViewQuery,
    ResourceEditViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceEditViewQuery, ResourceEditViewQueryVariables>(
    ResourceEditViewDocument,
    options
  );
}
export function useResourceEditViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceEditViewQuery,
    ResourceEditViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceEditViewQuery,
    ResourceEditViewQueryVariables
  >(ResourceEditViewDocument, options);
}
export type ResourceEditViewQueryHookResult = ReturnType<
  typeof useResourceEditViewQuery
>;
export type ResourceEditViewLazyQueryHookResult = ReturnType<
  typeof useResourceEditViewLazyQuery
>;
export type ResourceEditViewQueryResult = Apollo.QueryResult<
  ResourceEditViewQuery,
  ResourceEditViewQueryVariables
>;
export const ResourceAncestorsDocument = gql`
  query ResourceAncestors($id: ResourceId) {
    resourceAncestors(input: { id: $id }) {
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewTiny
        }
      }
    }
  }
  ${ResourcePreviewTinyFragmentDoc}
`;

/**
 * __useResourceAncestorsQuery__
 *
 * To run a query within a React component, call `useResourceAncestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceAncestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceAncestorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceAncestorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ResourceAncestorsQuery,
    ResourceAncestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceAncestorsQuery,
    ResourceAncestorsQueryVariables
  >(ResourceAncestorsDocument, options);
}
export function useResourceAncestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceAncestorsQuery,
    ResourceAncestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceAncestorsQuery,
    ResourceAncestorsQueryVariables
  >(ResourceAncestorsDocument, options);
}
export type ResourceAncestorsQueryHookResult = ReturnType<
  typeof useResourceAncestorsQuery
>;
export type ResourceAncestorsLazyQueryHookResult = ReturnType<
  typeof useResourceAncestorsLazyQuery
>;
export type ResourceAncestorsQueryResult = Apollo.QueryResult<
  ResourceAncestorsQuery,
  ResourceAncestorsQueryVariables
>;
export const UserAddResourcesDocument = gql`
  query UserAddResources($userId: UserId!) {
    user(input: { id: $userId }) {
      ... on UserResult {
        user {
          ...UserPreviewSmall
          userResources {
            ...UserResource
          }
        }
      }
    }
    connections(input: {}) {
      __typename
      ... on ConnectionsResult {
        connections {
          ...ConnectionSummary
        }
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
  ${UserResourceFragmentDoc}
  ${ConnectionSummaryFragmentDoc}
`;

/**
 * __useUserAddResourcesQuery__
 *
 * To run a query within a React component, call `useUserAddResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAddResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAddResourcesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAddResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAddResourcesQuery,
    UserAddResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAddResourcesQuery, UserAddResourcesQueryVariables>(
    UserAddResourcesDocument,
    options
  );
}
export function useUserAddResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAddResourcesQuery,
    UserAddResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserAddResourcesQuery,
    UserAddResourcesQueryVariables
  >(UserAddResourcesDocument, options);
}
export type UserAddResourcesQueryHookResult = ReturnType<
  typeof useUserAddResourcesQuery
>;
export type UserAddResourcesLazyQueryHookResult = ReturnType<
  typeof useUserAddResourcesLazyQuery
>;
export type UserAddResourcesQueryResult = Apollo.QueryResult<
  UserAddResourcesQuery,
  UserAddResourcesQueryVariables
>;
export const AppDetailsDocument = gql`
  query AppDetails($appId: UUID!) {
    app(id: $appId) {
      ... on App {
        id
        app {
          ... on ConnectionApp {
            connectionId
            connection {
              ...EndUserConnectionApp
            }
          }
          ... on OktaResourceApp {
            resourceId
            resource {
              ...EndUserOktaResourceApp
            }
          }
        }
      }
      ... on AppNotFoundError {
        message
      }
    }
  }
  ${EndUserConnectionAppFragmentDoc}
  ${EndUserOktaResourceAppFragmentDoc}
`;

/**
 * __useAppDetailsQuery__
 *
 * To run a query within a React component, call `useAppDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppDetailsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *   },
 * });
 */
export function useAppDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppDetailsQuery,
    AppDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppDetailsQuery, AppDetailsQueryVariables>(
    AppDetailsDocument,
    options
  );
}
export function useAppDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppDetailsQuery,
    AppDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppDetailsQuery, AppDetailsQueryVariables>(
    AppDetailsDocument,
    options
  );
}
export type AppDetailsQueryHookResult = ReturnType<typeof useAppDetailsQuery>;
export type AppDetailsLazyQueryHookResult = ReturnType<
  typeof useAppDetailsLazyQuery
>;
export type AppDetailsQueryResult = Apollo.QueryResult<
  AppDetailsQuery,
  AppDetailsQueryVariables
>;
export const AppEndUserResourcesDocument = gql`
  query AppEndUserResources(
    $id: UUID!
    $itemType: String
    $cursor: String
    $access: AccessOption!
    $parentResourceId: NullableResourceId
    $ancestorResourceId: NullableResourceId
    $searchQuery: String
    $sortBy: AppItemsSortBy
    $includeGroups: Boolean
    $hasV3: Boolean
  ) {
    app(id: $id) {
      ... on App {
        items(
          input: {
            itemType: $itemType
            cursor: $cursor
            access: $access
            parentResourceId: $parentResourceId
            ancestorResourceId: $ancestorResourceId
            searchQuery: $searchQuery
            sortBy: $sortBy
            includeGroups: $includeGroups
            hasV3: $hasV3
          }
        ) {
          items {
            key
            resource {
              ...AppEndUserResourceRow
            }
            group {
              ...AppEndUserGroupRow
            }
          }
          cursor
          totalNumItems
        }
        itemTypes(access: $access) {
          itemType
          displayText
          count
        }
      }
    }
  }
  ${AppEndUserResourceRowFragmentDoc}
  ${AppEndUserGroupRowFragmentDoc}
`;

/**
 * __useAppEndUserResourcesQuery__
 *
 * To run a query within a React component, call `useAppEndUserResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppEndUserResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppEndUserResourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemType: // value for 'itemType'
 *      cursor: // value for 'cursor'
 *      access: // value for 'access'
 *      parentResourceId: // value for 'parentResourceId'
 *      ancestorResourceId: // value for 'ancestorResourceId'
 *      searchQuery: // value for 'searchQuery'
 *      sortBy: // value for 'sortBy'
 *      includeGroups: // value for 'includeGroups'
 *      hasV3: // value for 'hasV3'
 *   },
 * });
 */
export function useAppEndUserResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppEndUserResourcesQuery,
    AppEndUserResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppEndUserResourcesQuery,
    AppEndUserResourcesQueryVariables
  >(AppEndUserResourcesDocument, options);
}
export function useAppEndUserResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppEndUserResourcesQuery,
    AppEndUserResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppEndUserResourcesQuery,
    AppEndUserResourcesQueryVariables
  >(AppEndUserResourcesDocument, options);
}
export type AppEndUserResourcesQueryHookResult = ReturnType<
  typeof useAppEndUserResourcesQuery
>;
export type AppEndUserResourcesLazyQueryHookResult = ReturnType<
  typeof useAppEndUserResourcesLazyQuery
>;
export type AppEndUserResourcesQueryResult = Apollo.QueryResult<
  AppEndUserResourcesQuery,
  AppEndUserResourcesQueryVariables
>;
export const SuggestionsDocument = gql`
  query Suggestions {
    suggestions {
      __typename
      ... on SuggestionsResult {
        suggestions {
          ...Suggestion
        }
      }
    }
  }
  ${SuggestionFragmentDoc}
`;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestionsQuery,
    SuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(
    SuggestionsDocument,
    options
  );
}
export function useSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestionsQuery,
    SuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(
    SuggestionsDocument,
    options
  );
}
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<
  typeof useSuggestionsLazyQuery
>;
export type SuggestionsQueryResult = Apollo.QueryResult<
  SuggestionsQuery,
  SuggestionsQueryVariables
>;
export const CreateBundleDocument = gql`
  mutation CreateBundle($input: CreateBundleInput!) {
    createBundle(input: $input) {
      __typename
      ... on CreateBundleResult {
        bundle {
          ...Bundle
        }
      }
      ... on BundleNameExistsError {
        message
      }
    }
  }
  ${BundleFragmentDoc}
`;
export type CreateBundleMutationFn = Apollo.MutationFunction<
  CreateBundleMutation,
  CreateBundleMutationVariables
>;

/**
 * __useCreateBundleMutation__
 *
 * To run a mutation, you first call `useCreateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleMutation, { data, loading, error }] = useCreateBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBundleMutation,
    CreateBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBundleMutation,
    CreateBundleMutationVariables
  >(CreateBundleDocument, options);
}
export type CreateBundleMutationHookResult = ReturnType<
  typeof useCreateBundleMutation
>;
export type CreateBundleMutationResult = Apollo.MutationResult<
  CreateBundleMutation
>;
export type CreateBundleMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleMutation,
  CreateBundleMutationVariables
>;
export const UpdateBundleDocument = gql`
  mutation UpdateBundle($input: UpdateBundleInput!) {
    updateBundle(input: $input) {
      __typename
      ... on UpdateBundleResult {
        bundle {
          ...Bundle
        }
      }
      ... on BundleNameExistsError {
        message
      }
    }
  }
  ${BundleFragmentDoc}
`;
export type UpdateBundleMutationFn = Apollo.MutationFunction<
  UpdateBundleMutation,
  UpdateBundleMutationVariables
>;

/**
 * __useUpdateBundleMutation__
 *
 * To run a mutation, you first call `useUpdateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleMutation, { data, loading, error }] = useUpdateBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBundleMutation,
    UpdateBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBundleMutation,
    UpdateBundleMutationVariables
  >(UpdateBundleDocument, options);
}
export type UpdateBundleMutationHookResult = ReturnType<
  typeof useUpdateBundleMutation
>;
export type UpdateBundleMutationResult = Apollo.MutationResult<
  UpdateBundleMutation
>;
export type UpdateBundleMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleMutation,
  UpdateBundleMutationVariables
>;
export const DeleteBundleDocument = gql`
  mutation DeleteBundle($input: DeleteBundleInput!) {
    deleteBundle(input: $input) {
      __typename
      ... on DeleteBundleOutput {
        bundle {
          ...Bundle
        }
      }
    }
  }
  ${BundleFragmentDoc}
`;
export type DeleteBundleMutationFn = Apollo.MutationFunction<
  DeleteBundleMutation,
  DeleteBundleMutationVariables
>;

/**
 * __useDeleteBundleMutation__
 *
 * To run a mutation, you first call `useDeleteBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleMutation, { data, loading, error }] = useDeleteBundleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundleMutation,
    DeleteBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBundleMutation,
    DeleteBundleMutationVariables
  >(DeleteBundleDocument, options);
}
export type DeleteBundleMutationHookResult = ReturnType<
  typeof useDeleteBundleMutation
>;
export type DeleteBundleMutationResult = Apollo.MutationResult<
  DeleteBundleMutation
>;
export type DeleteBundleMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundleMutation,
  DeleteBundleMutationVariables
>;
export const CreateBundleResourcesDocument = gql`
  mutation CreateBundleResources($input: BundleResourcesInput!) {
    createBundleResources(input: $input) {
      __typename
      ... on CreateBundleResourcesOutput {
        bundleResources {
          ...BundleResource
        }
      }
    }
  }
  ${BundleResourceFragmentDoc}
`;
export type CreateBundleResourcesMutationFn = Apollo.MutationFunction<
  CreateBundleResourcesMutation,
  CreateBundleResourcesMutationVariables
>;

/**
 * __useCreateBundleResourcesMutation__
 *
 * To run a mutation, you first call `useCreateBundleResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleResourcesMutation, { data, loading, error }] = useCreateBundleResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBundleResourcesMutation,
    CreateBundleResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBundleResourcesMutation,
    CreateBundleResourcesMutationVariables
  >(CreateBundleResourcesDocument, options);
}
export type CreateBundleResourcesMutationHookResult = ReturnType<
  typeof useCreateBundleResourcesMutation
>;
export type CreateBundleResourcesMutationResult = Apollo.MutationResult<
  CreateBundleResourcesMutation
>;
export type CreateBundleResourcesMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleResourcesMutation,
  CreateBundleResourcesMutationVariables
>;
export const DeleteBundleResourcesDocument = gql`
  mutation DeleteBundleResources($input: DeleteBundleResourcesInput!) {
    deleteBundleResources(input: $input)
  }
`;
export type DeleteBundleResourcesMutationFn = Apollo.MutationFunction<
  DeleteBundleResourcesMutation,
  DeleteBundleResourcesMutationVariables
>;

/**
 * __useDeleteBundleResourcesMutation__
 *
 * To run a mutation, you first call `useDeleteBundleResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleResourcesMutation, { data, loading, error }] = useDeleteBundleResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBundleResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundleResourcesMutation,
    DeleteBundleResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBundleResourcesMutation,
    DeleteBundleResourcesMutationVariables
  >(DeleteBundleResourcesDocument, options);
}
export type DeleteBundleResourcesMutationHookResult = ReturnType<
  typeof useDeleteBundleResourcesMutation
>;
export type DeleteBundleResourcesMutationResult = Apollo.MutationResult<
  DeleteBundleResourcesMutation
>;
export type DeleteBundleResourcesMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundleResourcesMutation,
  DeleteBundleResourcesMutationVariables
>;
export const CreateBundleGroupsDocument = gql`
  mutation CreateBundleGroups($input: BundleGroupsInput!) {
    createBundleGroups(input: $input) {
      __typename
      ... on CreateBundleGroupsOutput {
        bundleGroups {
          ...BundleGroup
        }
      }
    }
  }
  ${BundleGroupFragmentDoc}
`;
export type CreateBundleGroupsMutationFn = Apollo.MutationFunction<
  CreateBundleGroupsMutation,
  CreateBundleGroupsMutationVariables
>;

/**
 * __useCreateBundleGroupsMutation__
 *
 * To run a mutation, you first call `useCreateBundleGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBundleGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBundleGroupsMutation, { data, loading, error }] = useCreateBundleGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBundleGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBundleGroupsMutation,
    CreateBundleGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBundleGroupsMutation,
    CreateBundleGroupsMutationVariables
  >(CreateBundleGroupsDocument, options);
}
export type CreateBundleGroupsMutationHookResult = ReturnType<
  typeof useCreateBundleGroupsMutation
>;
export type CreateBundleGroupsMutationResult = Apollo.MutationResult<
  CreateBundleGroupsMutation
>;
export type CreateBundleGroupsMutationOptions = Apollo.BaseMutationOptions<
  CreateBundleGroupsMutation,
  CreateBundleGroupsMutationVariables
>;
export const DeleteBundleGroupsDocument = gql`
  mutation DeleteBundleGroups($input: DeleteBundleGroupsInput!) {
    deleteBundleGroups(input: $input)
  }
`;
export type DeleteBundleGroupsMutationFn = Apollo.MutationFunction<
  DeleteBundleGroupsMutation,
  DeleteBundleGroupsMutationVariables
>;

/**
 * __useDeleteBundleGroupsMutation__
 *
 * To run a mutation, you first call `useDeleteBundleGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBundleGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBundleGroupsMutation, { data, loading, error }] = useDeleteBundleGroupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBundleGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBundleGroupsMutation,
    DeleteBundleGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBundleGroupsMutation,
    DeleteBundleGroupsMutationVariables
  >(DeleteBundleGroupsDocument, options);
}
export type DeleteBundleGroupsMutationHookResult = ReturnType<
  typeof useDeleteBundleGroupsMutation
>;
export type DeleteBundleGroupsMutationResult = Apollo.MutationResult<
  DeleteBundleGroupsMutation
>;
export type DeleteBundleGroupsMutationOptions = Apollo.BaseMutationOptions<
  DeleteBundleGroupsMutation,
  DeleteBundleGroupsMutationVariables
>;
export const BundleDocument = gql`
  query Bundle($input: BundleInput!) {
    bundle(input: $input) {
      __typename
      ... on BundleResult {
        bundle {
          ...BundleDetail
        }
      }
      ... on BundleNotFoundError {
        message
      }
    }
  }
  ${BundleDetailFragmentDoc}
`;

/**
 * __useBundleQuery__
 *
 * To run a query within a React component, call `useBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBundleQuery(
  baseOptions: Apollo.QueryHookOptions<BundleQuery, BundleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleQuery, BundleQueryVariables>(
    BundleDocument,
    options
  );
}
export function useBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundleQuery, BundleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleQuery, BundleQueryVariables>(
    BundleDocument,
    options
  );
}
export type BundleQueryHookResult = ReturnType<typeof useBundleQuery>;
export type BundleLazyQueryHookResult = ReturnType<typeof useBundleLazyQuery>;
export type BundleQueryResult = Apollo.QueryResult<
  BundleQuery,
  BundleQueryVariables
>;
export const BundlesDocument = gql`
  query Bundles($input: BundlesInput!) {
    bundles(input: $input) {
      __typename
      ... on BundlesOutput {
        bundles {
          ...BundleDetail
        }
        totalNumBundles
        cursor
      }
    }
  }
  ${BundleDetailFragmentDoc}
`;

/**
 * __useBundlesQuery__
 *
 * To run a query within a React component, call `useBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundlesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<BundlesQuery, BundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundlesQuery, BundlesQueryVariables>(
    BundlesDocument,
    options
  );
}
export function useBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundlesQuery, BundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundlesQuery, BundlesQueryVariables>(
    BundlesDocument,
    options
  );
}
export type BundlesQueryHookResult = ReturnType<typeof useBundlesQuery>;
export type BundlesLazyQueryHookResult = ReturnType<typeof useBundlesLazyQuery>;
export type BundlesQueryResult = Apollo.QueryResult<
  BundlesQuery,
  BundlesQueryVariables
>;
export const BundleCatalogDocument = gql`
  query BundleCatalog($input: BundlesInput!) {
    bundles(input: $input) {
      __typename
      ... on BundlesOutput {
        bundles {
          ...BundleDetailForCatalog
        }
        totalNumBundles
        cursor
      }
    }
  }
  ${BundleDetailForCatalogFragmentDoc}
`;

/**
 * __useBundleCatalogQuery__
 *
 * To run a query within a React component, call `useBundleCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleCatalogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBundleCatalogQuery(
  baseOptions: Apollo.QueryHookOptions<
    BundleCatalogQuery,
    BundleCatalogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleCatalogQuery, BundleCatalogQueryVariables>(
    BundleCatalogDocument,
    options
  );
}
export function useBundleCatalogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BundleCatalogQuery,
    BundleCatalogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleCatalogQuery, BundleCatalogQueryVariables>(
    BundleCatalogDocument,
    options
  );
}
export type BundleCatalogQueryHookResult = ReturnType<
  typeof useBundleCatalogQuery
>;
export type BundleCatalogLazyQueryHookResult = ReturnType<
  typeof useBundleCatalogLazyQuery
>;
export type BundleCatalogQueryResult = Apollo.QueryResult<
  BundleCatalogQuery,
  BundleCatalogQueryVariables
>;
export const BundleItemsDocument = gql`
  query BundleItems($input: BundleItemsInput!) {
    bundleItems(input: $input) {
      __typename
      ... on BundleItemsOutput {
        items {
          ...BundleItem
        }
        totalNumItems
        totalNumResources
        totalNumGroups
        itemTypes {
          itemType
          displayText
        }
        cursor
      }
    }
  }
  ${BundleItemFragmentDoc}
`;

/**
 * __useBundleItemsQuery__
 *
 * To run a query within a React component, call `useBundleItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBundleItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BundleItemsQuery,
    BundleItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleItemsQuery, BundleItemsQueryVariables>(
    BundleItemsDocument,
    options
  );
}
export function useBundleItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BundleItemsQuery,
    BundleItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleItemsQuery, BundleItemsQueryVariables>(
    BundleItemsDocument,
    options
  );
}
export type BundleItemsQueryHookResult = ReturnType<typeof useBundleItemsQuery>;
export type BundleItemsLazyQueryHookResult = ReturnType<
  typeof useBundleItemsLazyQuery
>;
export type BundleItemsQueryResult = Apollo.QueryResult<
  BundleItemsQuery,
  BundleItemsQueryVariables
>;
export const ConnectSessionViewDocument = gql`
  query ConnectSessionView($id: ResourceId!) {
    resource(input: { id: $id }) {
      __typename
      ... on ResourceResult {
        resource {
          ...ConnectResourceSession
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ConnectResourceSessionFragmentDoc}
`;

/**
 * __useConnectSessionViewQuery__
 *
 * To run a query within a React component, call `useConnectSessionViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectSessionViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectSessionViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectSessionViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectSessionViewQuery,
    ConnectSessionViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectSessionViewQuery,
    ConnectSessionViewQueryVariables
  >(ConnectSessionViewDocument, options);
}
export function useConnectSessionViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectSessionViewQuery,
    ConnectSessionViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectSessionViewQuery,
    ConnectSessionViewQueryVariables
  >(ConnectSessionViewDocument, options);
}
export type ConnectSessionViewQueryHookResult = ReturnType<
  typeof useConnectSessionViewQuery
>;
export type ConnectSessionViewLazyQueryHookResult = ReturnType<
  typeof useConnectSessionViewLazyQuery
>;
export type ConnectSessionViewQueryResult = Apollo.QueryResult<
  ConnectSessionViewQuery,
  ConnectSessionViewQueryVariables
>;
export const ConnectionAppIconDocument = gql`
  query ConnectionAppIcon($connectionId: ConnectionId!) {
    connection(input: { id: $connectionId }) {
      ... on ConnectionResult {
        connection {
          id
          iconUrl
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useConnectionAppIconQuery__
 *
 * To run a query within a React component, call `useConnectionAppIconQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionAppIconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionAppIconQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useConnectionAppIconQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionAppIconQuery,
    ConnectionAppIconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectionAppIconQuery,
    ConnectionAppIconQueryVariables
  >(ConnectionAppIconDocument, options);
}
export function useConnectionAppIconLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionAppIconQuery,
    ConnectionAppIconQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectionAppIconQuery,
    ConnectionAppIconQueryVariables
  >(ConnectionAppIconDocument, options);
}
export type ConnectionAppIconQueryHookResult = ReturnType<
  typeof useConnectionAppIconQuery
>;
export type ConnectionAppIconLazyQueryHookResult = ReturnType<
  typeof useConnectionAppIconLazyQuery
>;
export type ConnectionAppIconQueryResult = Apollo.QueryResult<
  ConnectionAppIconQuery,
  ConnectionAppIconQueryVariables
>;
export const ConnectionUsersRowDocument = gql`
  query ConnectionUsersRow($input: ConnectionInput!) {
    connection(input: $input) {
      __typename
      ... on ConnectionResult {
        connection {
          ...ConnectionPreviewLarge
          connectionUsers {
            ...ConnectionUser
          }
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
  ${ConnectionPreviewLargeFragmentDoc}
  ${ConnectionUserFragmentDoc}
`;

/**
 * __useConnectionUsersRowQuery__
 *
 * To run a query within a React component, call `useConnectionUsersRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionUsersRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionUsersRowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectionUsersRowQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectionUsersRowQuery,
    ConnectionUsersRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectionUsersRowQuery,
    ConnectionUsersRowQueryVariables
  >(ConnectionUsersRowDocument, options);
}
export function useConnectionUsersRowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionUsersRowQuery,
    ConnectionUsersRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectionUsersRowQuery,
    ConnectionUsersRowQueryVariables
  >(ConnectionUsersRowDocument, options);
}
export type ConnectionUsersRowQueryHookResult = ReturnType<
  typeof useConnectionUsersRowQuery
>;
export type ConnectionUsersRowLazyQueryHookResult = ReturnType<
  typeof useConnectionUsersRowLazyQuery
>;
export type ConnectionUsersRowQueryResult = Apollo.QueryResult<
  ConnectionUsersRowQuery,
  ConnectionUsersRowQueryVariables
>;
export const EventsTableDocument = gql`
  query EventsTable($input: EventsInput!) {
    events(input: $input) {
      __typename
      ... on EventsResult {
        events {
          ...EventForTable
        }
        cursor
      }
    }
  }
  ${EventForTableFragmentDoc}
`;

/**
 * __useEventsTableQuery__
 *
 * To run a query within a React component, call `useEventsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventsTableQuery,
    EventsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsTableQuery, EventsTableQueryVariables>(
    EventsTableDocument,
    options
  );
}
export function useEventsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsTableQuery,
    EventsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsTableQuery, EventsTableQueryVariables>(
    EventsTableDocument,
    options
  );
}
export type EventsTableQueryHookResult = ReturnType<typeof useEventsTableQuery>;
export type EventsTableLazyQueryHookResult = ReturnType<
  typeof useEventsTableLazyQuery
>;
export type EventsTableQueryResult = Apollo.QueryResult<
  EventsTableQuery,
  EventsTableQueryVariables
>;
export const EventPreviewDocument = gql`
  query EventPreview($id: EventId!) {
    event(input: { id: $id }) {
      __typename
      ... on EventResult {
        event {
          ...EventForTable
        }
      }
      ... on EventNotFoundError {
        message
      }
    }
  }
  ${EventForTableFragmentDoc}
`;

/**
 * __useEventPreviewQuery__
 *
 * To run a query within a React component, call `useEventPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventPreviewQuery,
    EventPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventPreviewQuery, EventPreviewQueryVariables>(
    EventPreviewDocument,
    options
  );
}
export function useEventPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventPreviewQuery,
    EventPreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventPreviewQuery, EventPreviewQueryVariables>(
    EventPreviewDocument,
    options
  );
}
export type EventPreviewQueryHookResult = ReturnType<
  typeof useEventPreviewQuery
>;
export type EventPreviewLazyQueryHookResult = ReturnType<
  typeof useEventPreviewLazyQuery
>;
export type EventPreviewQueryResult = Apollo.QueryResult<
  EventPreviewQuery,
  EventPreviewQueryVariables
>;
export const EventDetailDocument = gql`
  query EventDetail($id: EventId!, $subEvents: PaginatedSubEventsInput) {
    event(input: { id: $id }) {
      __typename
      ... on EventResult {
        event {
          ...EventDetail
        }
      }
      ... on EventNotFoundError {
        message
      }
    }
  }
  ${EventDetailFragmentDoc}
`;

/**
 * __useEventDetailQuery__
 *
 * To run a query within a React component, call `useEventDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      subEvents: // value for 'subEvents'
 *   },
 * });
 */
export function useEventDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    EventDetailQuery,
    EventDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventDetailQuery, EventDetailQueryVariables>(
    EventDetailDocument,
    options
  );
}
export function useEventDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventDetailQuery,
    EventDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventDetailQuery, EventDetailQueryVariables>(
    EventDetailDocument,
    options
  );
}
export type EventDetailQueryHookResult = ReturnType<typeof useEventDetailQuery>;
export type EventDetailLazyQueryHookResult = ReturnType<
  typeof useEventDetailLazyQuery
>;
export type EventDetailQueryResult = Apollo.QueryResult<
  EventDetailQuery,
  EventDetailQueryVariables
>;
export const GroupBindingDetailsDocument = gql`
  query GroupBindingDetails($input: [GroupBindingId!]!) {
    groupBindings(input: { filters: { groupBindingIds: $input } }) {
      groupBindings {
        ...GroupBindingDetail
      }
    }
  }
  ${GroupBindingDetailFragmentDoc}
`;

/**
 * __useGroupBindingDetailsQuery__
 *
 * To run a query within a React component, call `useGroupBindingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupBindingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingDetailsQuery,
    GroupBindingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingDetailsQuery,
    GroupBindingDetailsQueryVariables
  >(GroupBindingDetailsDocument, options);
}
export function useGroupBindingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingDetailsQuery,
    GroupBindingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingDetailsQuery,
    GroupBindingDetailsQueryVariables
  >(GroupBindingDetailsDocument, options);
}
export type GroupBindingDetailsQueryHookResult = ReturnType<
  typeof useGroupBindingDetailsQuery
>;
export type GroupBindingDetailsLazyQueryHookResult = ReturnType<
  typeof useGroupBindingDetailsLazyQuery
>;
export type GroupBindingDetailsQueryResult = Apollo.QueryResult<
  GroupBindingDetailsQuery,
  GroupBindingDetailsQueryVariables
>;
export const GroupBindingSuggestionsTableDocument = gql`
  query GroupBindingSuggestionsTable($input: GroupBindingSuggestionsInput!) {
    groupBindingSuggestions(input: $input) {
      groupBindingSuggestions {
        ...GroupBindingSuggestion
      }
      totalNumItems
      cursor
    }
  }
  ${GroupBindingSuggestionFragmentDoc}
`;

/**
 * __useGroupBindingSuggestionsTableQuery__
 *
 * To run a query within a React component, call `useGroupBindingSuggestionsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingSuggestionsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingSuggestionsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupBindingSuggestionsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingSuggestionsTableQuery,
    GroupBindingSuggestionsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingSuggestionsTableQuery,
    GroupBindingSuggestionsTableQueryVariables
  >(GroupBindingSuggestionsTableDocument, options);
}
export function useGroupBindingSuggestionsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingSuggestionsTableQuery,
    GroupBindingSuggestionsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingSuggestionsTableQuery,
    GroupBindingSuggestionsTableQueryVariables
  >(GroupBindingSuggestionsTableDocument, options);
}
export type GroupBindingSuggestionsTableQueryHookResult = ReturnType<
  typeof useGroupBindingSuggestionsTableQuery
>;
export type GroupBindingSuggestionsTableLazyQueryHookResult = ReturnType<
  typeof useGroupBindingSuggestionsTableLazyQuery
>;
export type GroupBindingSuggestionsTableQueryResult = Apollo.QueryResult<
  GroupBindingSuggestionsTableQuery,
  GroupBindingSuggestionsTableQueryVariables
>;
export const CreateGroupBindingsDocument = gql`
  mutation CreateGroupBindings($input: CreateGroupBindingsInput!) {
    createGroupBindings(input: $input) {
      ... on CreateGroupBindingsResult {
        groupBindings {
          ...GroupBinding
        }
      }
      ... on GroupAlreadyBelongsToBindingError {
        message
        group {
          ...GroupPreviewTiny
        }
        groupBinding {
          ...GroupBinding
        }
      }
      ... on GroupBindingHasNoGroupsError {
        message
      }
    }
  }
  ${GroupBindingFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
`;
export type CreateGroupBindingsMutationFn = Apollo.MutationFunction<
  CreateGroupBindingsMutation,
  CreateGroupBindingsMutationVariables
>;

/**
 * __useCreateGroupBindingsMutation__
 *
 * To run a mutation, you first call `useCreateGroupBindingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupBindingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupBindingsMutation, { data, loading, error }] = useCreateGroupBindingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupBindingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupBindingsMutation,
    CreateGroupBindingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGroupBindingsMutation,
    CreateGroupBindingsMutationVariables
  >(CreateGroupBindingsDocument, options);
}
export type CreateGroupBindingsMutationHookResult = ReturnType<
  typeof useCreateGroupBindingsMutation
>;
export type CreateGroupBindingsMutationResult = Apollo.MutationResult<
  CreateGroupBindingsMutation
>;
export type CreateGroupBindingsMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupBindingsMutation,
  CreateGroupBindingsMutationVariables
>;
export const DismissGroupBindingSuggestionsDocument = gql`
  mutation DismissGroupBindingSuggestions(
    $input: DismissGroupBindingSuggestionsInput!
  ) {
    dismissGroupBindingSuggestions(input: $input) {
      ... on DismissGroupBindingSuggestionsResult {
        success
      }
      ... on GroupBindingSuggestionNotFoundError {
        message
      }
    }
  }
`;
export type DismissGroupBindingSuggestionsMutationFn = Apollo.MutationFunction<
  DismissGroupBindingSuggestionsMutation,
  DismissGroupBindingSuggestionsMutationVariables
>;

/**
 * __useDismissGroupBindingSuggestionsMutation__
 *
 * To run a mutation, you first call `useDismissGroupBindingSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissGroupBindingSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissGroupBindingSuggestionsMutation, { data, loading, error }] = useDismissGroupBindingSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissGroupBindingSuggestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissGroupBindingSuggestionsMutation,
    DismissGroupBindingSuggestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissGroupBindingSuggestionsMutation,
    DismissGroupBindingSuggestionsMutationVariables
  >(DismissGroupBindingSuggestionsDocument, options);
}
export type DismissGroupBindingSuggestionsMutationHookResult = ReturnType<
  typeof useDismissGroupBindingSuggestionsMutation
>;
export type DismissGroupBindingSuggestionsMutationResult = Apollo.MutationResult<
  DismissGroupBindingSuggestionsMutation
>;
export type DismissGroupBindingSuggestionsMutationOptions = Apollo.BaseMutationOptions<
  DismissGroupBindingSuggestionsMutation,
  DismissGroupBindingSuggestionsMutationVariables
>;
export const GroupBindingsCountsDocument = gql`
  query GroupBindingsCounts {
    groupBindings(input: { pageSize: 0 }) {
      totalNumItems
    }
    groupBindingSuggestions(input: { pageSize: 0 }) {
      totalNumItems
    }
  }
`;

/**
 * __useGroupBindingsCountsQuery__
 *
 * To run a query within a React component, call `useGroupBindingsCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingsCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingsCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupBindingsCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupBindingsCountsQuery,
    GroupBindingsCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingsCountsQuery,
    GroupBindingsCountsQueryVariables
  >(GroupBindingsCountsDocument, options);
}
export function useGroupBindingsCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingsCountsQuery,
    GroupBindingsCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingsCountsQuery,
    GroupBindingsCountsQueryVariables
  >(GroupBindingsCountsDocument, options);
}
export type GroupBindingsCountsQueryHookResult = ReturnType<
  typeof useGroupBindingsCountsQuery
>;
export type GroupBindingsCountsLazyQueryHookResult = ReturnType<
  typeof useGroupBindingsCountsLazyQuery
>;
export type GroupBindingsCountsQueryResult = Apollo.QueryResult<
  GroupBindingsCountsQuery,
  GroupBindingsCountsQueryVariables
>;
export const GroupBindingsTableDocument = gql`
  query GroupBindingsTable($input: GroupBindingsInput!) {
    groupBindings(input: $input) {
      groupBindings {
        ...GroupBinding
      }
      totalNumItems
      cursor
    }
  }
  ${GroupBindingFragmentDoc}
`;

/**
 * __useGroupBindingsTableQuery__
 *
 * To run a query within a React component, call `useGroupBindingsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupBindingsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingsTableQuery,
    GroupBindingsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingsTableQuery,
    GroupBindingsTableQueryVariables
  >(GroupBindingsTableDocument, options);
}
export function useGroupBindingsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingsTableQuery,
    GroupBindingsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingsTableQuery,
    GroupBindingsTableQueryVariables
  >(GroupBindingsTableDocument, options);
}
export type GroupBindingsTableQueryHookResult = ReturnType<
  typeof useGroupBindingsTableQuery
>;
export type GroupBindingsTableLazyQueryHookResult = ReturnType<
  typeof useGroupBindingsTableLazyQuery
>;
export type GroupBindingsTableQueryResult = Apollo.QueryResult<
  GroupBindingsTableQuery,
  GroupBindingsTableQueryVariables
>;
export const GroupBindingsFiltersDocument = gql`
  query GroupBindingsFilters(
    $groupIds: [GroupId!]!
    $connectionIds: [ConnectionId!]
  ) {
    connections(input: {}) {
      ... on ConnectionsResult {
        connections {
          ...ConnectionPreviewTiny
        }
      }
    }
    groups(input: { groupIds: $groupIds }) {
      ... on GroupsResult {
        groups {
          ...GroupPreviewTiny
        }
      }
    }
    groupTypesWithCounts(input: { connectionIds: $connectionIds }) {
      ... on GroupTypesWithCountsResult {
        groupTypesWithCounts {
          ...GroupTypeWithCount
        }
      }
    }
  }
  ${ConnectionPreviewTinyFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
  ${GroupTypeWithCountFragmentDoc}
`;

/**
 * __useGroupBindingsFiltersQuery__
 *
 * To run a query within a React component, call `useGroupBindingsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingsFiltersQuery({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      connectionIds: // value for 'connectionIds'
 *   },
 * });
 */
export function useGroupBindingsFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingsFiltersQuery,
    GroupBindingsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingsFiltersQuery,
    GroupBindingsFiltersQueryVariables
  >(GroupBindingsFiltersDocument, options);
}
export function useGroupBindingsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingsFiltersQuery,
    GroupBindingsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingsFiltersQuery,
    GroupBindingsFiltersQueryVariables
  >(GroupBindingsFiltersDocument, options);
}
export type GroupBindingsFiltersQueryHookResult = ReturnType<
  typeof useGroupBindingsFiltersQuery
>;
export type GroupBindingsFiltersLazyQueryHookResult = ReturnType<
  typeof useGroupBindingsFiltersLazyQuery
>;
export type GroupBindingsFiltersQueryResult = Apollo.QueryResult<
  GroupBindingsFiltersQuery,
  GroupBindingsFiltersQueryVariables
>;
export const GroupBindingCompareModalDocument = gql`
  query GroupBindingCompareModal($input: GroupBindingSuggestionId!) {
    groupBindingSuggestion(input: $input) {
      ... on GroupBindingSuggestion {
        ...GroupBindingSuggestionDetail
      }
      ... on GroupBindingSuggestionNotFoundError {
        message
      }
    }
  }
  ${GroupBindingSuggestionDetailFragmentDoc}
`;

/**
 * __useGroupBindingCompareModalQuery__
 *
 * To run a query within a React component, call `useGroupBindingCompareModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingCompareModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingCompareModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupBindingCompareModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingCompareModalQuery,
    GroupBindingCompareModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingCompareModalQuery,
    GroupBindingCompareModalQueryVariables
  >(GroupBindingCompareModalDocument, options);
}
export function useGroupBindingCompareModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingCompareModalQuery,
    GroupBindingCompareModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingCompareModalQuery,
    GroupBindingCompareModalQueryVariables
  >(GroupBindingCompareModalDocument, options);
}
export type GroupBindingCompareModalQueryHookResult = ReturnType<
  typeof useGroupBindingCompareModalQuery
>;
export type GroupBindingCompareModalLazyQueryHookResult = ReturnType<
  typeof useGroupBindingCompareModalLazyQuery
>;
export type GroupBindingCompareModalQueryResult = Apollo.QueryResult<
  GroupBindingCompareModalQuery,
  GroupBindingCompareModalQueryVariables
>;
export const DeleteGroupBindingsDocument = gql`
  mutation DeleteGroupBindings($input: DeleteGroupBindingsInput!) {
    deleteGroupBindings(input: $input) {
      ... on DeleteGroupBindingsResult {
        success
      }
      ... on GroupBindingNotFoundError {
        message
      }
    }
  }
`;
export type DeleteGroupBindingsMutationFn = Apollo.MutationFunction<
  DeleteGroupBindingsMutation,
  DeleteGroupBindingsMutationVariables
>;

/**
 * __useDeleteGroupBindingsMutation__
 *
 * To run a mutation, you first call `useDeleteGroupBindingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupBindingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupBindingsMutation, { data, loading, error }] = useDeleteGroupBindingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGroupBindingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupBindingsMutation,
    DeleteGroupBindingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGroupBindingsMutation,
    DeleteGroupBindingsMutationVariables
  >(DeleteGroupBindingsDocument, options);
}
export type DeleteGroupBindingsMutationHookResult = ReturnType<
  typeof useDeleteGroupBindingsMutation
>;
export type DeleteGroupBindingsMutationResult = Apollo.MutationResult<
  DeleteGroupBindingsMutation
>;
export type DeleteGroupBindingsMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupBindingsMutation,
  DeleteGroupBindingsMutationVariables
>;
export const GroupBindingGroupUserIdsDocument = gql`
  query GroupBindingGroupUserIds($input: [GroupId!]!) {
    groups(input: { groupIds: $input }) {
      ... on GroupsResult {
        groups {
          ...GroupBindingGroupWithUsers
        }
      }
    }
  }
  ${GroupBindingGroupWithUsersFragmentDoc}
`;

/**
 * __useGroupBindingGroupUserIdsQuery__
 *
 * To run a query within a React component, call `useGroupBindingGroupUserIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupBindingGroupUserIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupBindingGroupUserIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupBindingGroupUserIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupBindingGroupUserIdsQuery,
    GroupBindingGroupUserIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupBindingGroupUserIdsQuery,
    GroupBindingGroupUserIdsQueryVariables
  >(GroupBindingGroupUserIdsDocument, options);
}
export function useGroupBindingGroupUserIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupBindingGroupUserIdsQuery,
    GroupBindingGroupUserIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupBindingGroupUserIdsQuery,
    GroupBindingGroupUserIdsQueryVariables
  >(GroupBindingGroupUserIdsDocument, options);
}
export type GroupBindingGroupUserIdsQueryHookResult = ReturnType<
  typeof useGroupBindingGroupUserIdsQuery
>;
export type GroupBindingGroupUserIdsLazyQueryHookResult = ReturnType<
  typeof useGroupBindingGroupUserIdsLazyQuery
>;
export type GroupBindingGroupUserIdsQueryResult = Apollo.QueryResult<
  GroupBindingGroupUserIdsQuery,
  GroupBindingGroupUserIdsQueryVariables
>;
export const UpdateGroupBindingsDocument = gql`
  mutation UpdateGroupBindings($input: UpdateGroupBindingsInput!) {
    updateGroupBindings(input: $input) {
      ... on UpdateGroupBindingsResult {
        groupBindings {
          id
        }
      }
      ... on GroupAlreadyBelongsToBindingError {
        message
        group {
          ...GroupPreviewTiny
        }
        groupBinding {
          ...GroupBinding
        }
      }
      ... on GroupBindingHasNoGroupsError {
        message
      }
      ... on GroupBindingNotFoundError {
        message
      }
    }
  }
  ${GroupPreviewTinyFragmentDoc}
  ${GroupBindingFragmentDoc}
`;
export type UpdateGroupBindingsMutationFn = Apollo.MutationFunction<
  UpdateGroupBindingsMutation,
  UpdateGroupBindingsMutationVariables
>;

/**
 * __useUpdateGroupBindingsMutation__
 *
 * To run a mutation, you first call `useUpdateGroupBindingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupBindingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupBindingsMutation, { data, loading, error }] = useUpdateGroupBindingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupBindingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupBindingsMutation,
    UpdateGroupBindingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupBindingsMutation,
    UpdateGroupBindingsMutationVariables
  >(UpdateGroupBindingsDocument, options);
}
export type UpdateGroupBindingsMutationHookResult = ReturnType<
  typeof useUpdateGroupBindingsMutation
>;
export type UpdateGroupBindingsMutationResult = Apollo.MutationResult<
  UpdateGroupBindingsMutation
>;
export type UpdateGroupBindingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupBindingsMutation,
  UpdateGroupBindingsMutationVariables
>;
export const RemoveGroupResourcesAndGroupsDocument = gql`
  mutation RemoveGroupResourcesAndGroups(
    $groupResourcesToRemove: RemoveGroupResourcesInput!
    $groupGroupIdsToRemove: [GroupGroupId!]!
  ) {
    removeGroupGroups(input: { groupGroupsIds: $groupGroupIdsToRemove }) {
      ... on RemoveGroupGroupsResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
    removeGroupResources(input: $groupResourcesToRemove) {
      ... on RemoveGroupResourcesResult {
        taskId
      }
      ... on GroupNotFoundError {
        message
      }
      ... on CallToWebhookFailedError {
        message
      }
      ... on UserFacingError {
        message
      }
    }
  }
`;
export type RemoveGroupResourcesAndGroupsMutationFn = Apollo.MutationFunction<
  RemoveGroupResourcesAndGroupsMutation,
  RemoveGroupResourcesAndGroupsMutationVariables
>;

/**
 * __useRemoveGroupResourcesAndGroupsMutation__
 *
 * To run a mutation, you first call `useRemoveGroupResourcesAndGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupResourcesAndGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupResourcesAndGroupsMutation, { data, loading, error }] = useRemoveGroupResourcesAndGroupsMutation({
 *   variables: {
 *      groupResourcesToRemove: // value for 'groupResourcesToRemove'
 *      groupGroupIdsToRemove: // value for 'groupGroupIdsToRemove'
 *   },
 * });
 */
export function useRemoveGroupResourcesAndGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupResourcesAndGroupsMutation,
    RemoveGroupResourcesAndGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupResourcesAndGroupsMutation,
    RemoveGroupResourcesAndGroupsMutationVariables
  >(RemoveGroupResourcesAndGroupsDocument, options);
}
export type RemoveGroupResourcesAndGroupsMutationHookResult = ReturnType<
  typeof useRemoveGroupResourcesAndGroupsMutation
>;
export type RemoveGroupResourcesAndGroupsMutationResult = Apollo.MutationResult<
  RemoveGroupResourcesAndGroupsMutation
>;
export type RemoveGroupResourcesAndGroupsMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupResourcesAndGroupsMutation,
  RemoveGroupResourcesAndGroupsMutationVariables
>;
export const MetricsFiltersDocument = gql`
  query MetricsFilters($resourceIds: [ResourceId!]!, $groupIds: [GroupId!]!) {
    connections(input: {}) {
      ... on ConnectionsResult {
        connections {
          ...ConnectionPreviewTiny
        }
      }
    }
    tags(input: {}) {
      ... on TagsResult {
        tags {
          ...TagPreviewSmall
        }
      }
    }
    resources(input: { resourceIds: $resourceIds }) {
      ... on ResourcesResult {
        resources {
          ...ResourcePreviewTiny
        }
      }
    }
    groups(input: { groupIds: $groupIds }) {
      ... on GroupsResult {
        groups {
          ...GroupPreviewTiny
        }
      }
    }
  }
  ${ConnectionPreviewTinyFragmentDoc}
  ${TagPreviewSmallFragmentDoc}
  ${ResourcePreviewTinyFragmentDoc}
  ${GroupPreviewTinyFragmentDoc}
`;

/**
 * __useMetricsFiltersQuery__
 *
 * To run a query within a React component, call `useMetricsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsFiltersQuery({
 *   variables: {
 *      resourceIds: // value for 'resourceIds'
 *      groupIds: // value for 'groupIds'
 *   },
 * });
 */
export function useMetricsFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    MetricsFiltersQuery,
    MetricsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetricsFiltersQuery, MetricsFiltersQueryVariables>(
    MetricsFiltersDocument,
    options
  );
}
export function useMetricsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetricsFiltersQuery,
    MetricsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetricsFiltersQuery, MetricsFiltersQueryVariables>(
    MetricsFiltersDocument,
    options
  );
}
export type MetricsFiltersQueryHookResult = ReturnType<
  typeof useMetricsFiltersQuery
>;
export type MetricsFiltersLazyQueryHookResult = ReturnType<
  typeof useMetricsFiltersLazyQuery
>;
export type MetricsFiltersQueryResult = Apollo.QueryResult<
  MetricsFiltersQuery,
  MetricsFiltersQueryVariables
>;
export const BreakGlassUsersCountsDocument = gql`
  query BreakGlassUsersCounts($input: MetricsInput!) {
    breakGlassUsersCounts(input: $input) {
      lineData {
        ...LineGraphData
      }
    }
  }
  ${LineGraphDataFragmentDoc}
`;

/**
 * __useBreakGlassUsersCountsQuery__
 *
 * To run a query within a React component, call `useBreakGlassUsersCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreakGlassUsersCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreakGlassUsersCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBreakGlassUsersCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BreakGlassUsersCountsQuery,
    BreakGlassUsersCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BreakGlassUsersCountsQuery,
    BreakGlassUsersCountsQueryVariables
  >(BreakGlassUsersCountsDocument, options);
}
export function useBreakGlassUsersCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BreakGlassUsersCountsQuery,
    BreakGlassUsersCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BreakGlassUsersCountsQuery,
    BreakGlassUsersCountsQueryVariables
  >(BreakGlassUsersCountsDocument, options);
}
export type BreakGlassUsersCountsQueryHookResult = ReturnType<
  typeof useBreakGlassUsersCountsQuery
>;
export type BreakGlassUsersCountsLazyQueryHookResult = ReturnType<
  typeof useBreakGlassUsersCountsLazyQuery
>;
export type BreakGlassUsersCountsQueryResult = Apollo.QueryResult<
  BreakGlassUsersCountsQuery,
  BreakGlassUsersCountsQueryVariables
>;
export const BreakGlassUsersByGroupDocument = gql`
  query BreakGlassUsersByGroup($input: MetricsInput!) {
    breakGlassUsersByGroup(input: $input) {
      barData {
        ...BarGraphData
      }
    }
  }
  ${BarGraphDataFragmentDoc}
`;

/**
 * __useBreakGlassUsersByGroupQuery__
 *
 * To run a query within a React component, call `useBreakGlassUsersByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreakGlassUsersByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreakGlassUsersByGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBreakGlassUsersByGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    BreakGlassUsersByGroupQuery,
    BreakGlassUsersByGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BreakGlassUsersByGroupQuery,
    BreakGlassUsersByGroupQueryVariables
  >(BreakGlassUsersByGroupDocument, options);
}
export function useBreakGlassUsersByGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BreakGlassUsersByGroupQuery,
    BreakGlassUsersByGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BreakGlassUsersByGroupQuery,
    BreakGlassUsersByGroupQueryVariables
  >(BreakGlassUsersByGroupDocument, options);
}
export type BreakGlassUsersByGroupQueryHookResult = ReturnType<
  typeof useBreakGlassUsersByGroupQuery
>;
export type BreakGlassUsersByGroupLazyQueryHookResult = ReturnType<
  typeof useBreakGlassUsersByGroupLazyQuery
>;
export type BreakGlassUsersByGroupQueryResult = Apollo.QueryResult<
  BreakGlassUsersByGroupQuery,
  BreakGlassUsersByGroupQueryVariables
>;
export const RequestMetricsDocument = gql`
  query RequestMetrics($input: MetricsInput!) {
    requestMetrics(input: $input) {
      totalApproved
      totalCanceled
      totalDenied
      approvedLineData {
        ...LineGraphData
      }
      canceledLineData {
        ...LineGraphData
      }
      deniedLineData {
        ...LineGraphData
      }
    }
  }
  ${LineGraphDataFragmentDoc}
`;

/**
 * __useRequestMetricsQuery__
 *
 * To run a query within a React component, call `useRequestMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestMetricsQuery,
    RequestMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestMetricsQuery, RequestMetricsQueryVariables>(
    RequestMetricsDocument,
    options
  );
}
export function useRequestMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestMetricsQuery,
    RequestMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestMetricsQuery, RequestMetricsQueryVariables>(
    RequestMetricsDocument,
    options
  );
}
export type RequestMetricsQueryHookResult = ReturnType<
  typeof useRequestMetricsQuery
>;
export type RequestMetricsLazyQueryHookResult = ReturnType<
  typeof useRequestMetricsLazyQuery
>;
export type RequestMetricsQueryResult = Apollo.QueryResult<
  RequestMetricsQuery,
  RequestMetricsQueryVariables
>;
export const RequestApprovalTimesDocument = gql`
  query RequestApprovalTimes($input: MetricsInput!) {
    requestApprovalTimes(input: $input) {
      lineData {
        ...LineGraphData
      }
    }
  }
  ${LineGraphDataFragmentDoc}
`;

/**
 * __useRequestApprovalTimesQuery__
 *
 * To run a query within a React component, call `useRequestApprovalTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestApprovalTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestApprovalTimesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestApprovalTimesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestApprovalTimesQuery,
    RequestApprovalTimesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestApprovalTimesQuery,
    RequestApprovalTimesQueryVariables
  >(RequestApprovalTimesDocument, options);
}
export function useRequestApprovalTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestApprovalTimesQuery,
    RequestApprovalTimesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestApprovalTimesQuery,
    RequestApprovalTimesQueryVariables
  >(RequestApprovalTimesDocument, options);
}
export type RequestApprovalTimesQueryHookResult = ReturnType<
  typeof useRequestApprovalTimesQuery
>;
export type RequestApprovalTimesLazyQueryHookResult = ReturnType<
  typeof useRequestApprovalTimesLazyQuery
>;
export type RequestApprovalTimesQueryResult = Apollo.QueryResult<
  RequestApprovalTimesQuery,
  RequestApprovalTimesQueryVariables
>;
export const AccessGrantedTimesDocument = gql`
  query AccessGrantedTimes($input: MetricsInput!) {
    accessGrantedTimes(input: $input) {
      lineData {
        ...LineGraphData
      }
    }
  }
  ${LineGraphDataFragmentDoc}
`;

/**
 * __useAccessGrantedTimesQuery__
 *
 * To run a query within a React component, call `useAccessGrantedTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessGrantedTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessGrantedTimesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessGrantedTimesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccessGrantedTimesQuery,
    AccessGrantedTimesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccessGrantedTimesQuery,
    AccessGrantedTimesQueryVariables
  >(AccessGrantedTimesDocument, options);
}
export function useAccessGrantedTimesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccessGrantedTimesQuery,
    AccessGrantedTimesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccessGrantedTimesQuery,
    AccessGrantedTimesQueryVariables
  >(AccessGrantedTimesDocument, options);
}
export type AccessGrantedTimesQueryHookResult = ReturnType<
  typeof useAccessGrantedTimesQuery
>;
export type AccessGrantedTimesLazyQueryHookResult = ReturnType<
  typeof useAccessGrantedTimesLazyQuery
>;
export type AccessGrantedTimesQueryResult = Apollo.QueryResult<
  AccessGrantedTimesQuery,
  AccessGrantedTimesQueryVariables
>;
export const MostRequestedResourcesDocument = gql`
  query MostRequestedResources($input: MetricsInput!) {
    mostRequestedResources(input: $input) {
      barData {
        ...BarGraphData
      }
    }
  }
  ${BarGraphDataFragmentDoc}
`;

/**
 * __useMostRequestedResourcesQuery__
 *
 * To run a query within a React component, call `useMostRequestedResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRequestedResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRequestedResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMostRequestedResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MostRequestedResourcesQuery,
    MostRequestedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MostRequestedResourcesQuery,
    MostRequestedResourcesQueryVariables
  >(MostRequestedResourcesDocument, options);
}
export function useMostRequestedResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MostRequestedResourcesQuery,
    MostRequestedResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MostRequestedResourcesQuery,
    MostRequestedResourcesQueryVariables
  >(MostRequestedResourcesDocument, options);
}
export type MostRequestedResourcesQueryHookResult = ReturnType<
  typeof useMostRequestedResourcesQuery
>;
export type MostRequestedResourcesLazyQueryHookResult = ReturnType<
  typeof useMostRequestedResourcesLazyQuery
>;
export type MostRequestedResourcesQueryResult = Apollo.QueryResult<
  MostRequestedResourcesQuery,
  MostRequestedResourcesQueryVariables
>;
export const MostRequestedGroupsDocument = gql`
  query MostRequestedGroups($input: MetricsInput!) {
    mostRequestedGroups(input: $input) {
      barData {
        ...BarGraphData
      }
    }
  }
  ${BarGraphDataFragmentDoc}
`;

/**
 * __useMostRequestedGroupsQuery__
 *
 * To run a query within a React component, call `useMostRequestedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRequestedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRequestedGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMostRequestedGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MostRequestedGroupsQuery,
    MostRequestedGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MostRequestedGroupsQuery,
    MostRequestedGroupsQueryVariables
  >(MostRequestedGroupsDocument, options);
}
export function useMostRequestedGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MostRequestedGroupsQuery,
    MostRequestedGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MostRequestedGroupsQuery,
    MostRequestedGroupsQueryVariables
  >(MostRequestedGroupsDocument, options);
}
export type MostRequestedGroupsQueryHookResult = ReturnType<
  typeof useMostRequestedGroupsQuery
>;
export type MostRequestedGroupsLazyQueryHookResult = ReturnType<
  typeof useMostRequestedGroupsLazyQuery
>;
export type MostRequestedGroupsQueryResult = Apollo.QueryResult<
  MostRequestedGroupsQuery,
  MostRequestedGroupsQueryVariables
>;
export const ClosedRequestMetricsDocument = gql`
  query ClosedRequestMetrics($input: MetricsInput!) {
    closedRequestMetrics(input: $input) {
      numAdminApproved
      numAutoApproved
      numManualApproved
      numCanceled
      numDenied
    }
  }
`;

/**
 * __useClosedRequestMetricsQuery__
 *
 * To run a query within a React component, call `useClosedRequestMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosedRequestMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosedRequestMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClosedRequestMetricsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClosedRequestMetricsQuery,
    ClosedRequestMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClosedRequestMetricsQuery,
    ClosedRequestMetricsQueryVariables
  >(ClosedRequestMetricsDocument, options);
}
export function useClosedRequestMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClosedRequestMetricsQuery,
    ClosedRequestMetricsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClosedRequestMetricsQuery,
    ClosedRequestMetricsQueryVariables
  >(ClosedRequestMetricsDocument, options);
}
export type ClosedRequestMetricsQueryHookResult = ReturnType<
  typeof useClosedRequestMetricsQuery
>;
export type ClosedRequestMetricsLazyQueryHookResult = ReturnType<
  typeof useClosedRequestMetricsLazyQuery
>;
export type ClosedRequestMetricsQueryResult = Apollo.QueryResult<
  ClosedRequestMetricsQuery,
  ClosedRequestMetricsQueryVariables
>;
export const OwnersTableDocument = gql`
  query OwnersTable($input: OwnersInput!) {
    owners(input: $input) {
      __typename
      ... on OwnersResult {
        owners {
          id
          name
          createdAt
          ownerUsers {
            userId
          }
          sourceGroup {
            id
            name
            groupType
          }
          ownedResources {
            isAdmin
            isReviewer
          }
          ownedGroups {
            isAdmin
            isReviewer
          }
          ownedConfigurationTemplates {
            isAdmin
            isReviewer
          }
        }
        totalNumOwners
        cursor
      }
    }
  }
`;

/**
 * __useOwnersTableQuery__
 *
 * To run a query within a React component, call `useOwnersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnersTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOwnersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnersTableQuery,
    OwnersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OwnersTableQuery, OwnersTableQueryVariables>(
    OwnersTableDocument,
    options
  );
}
export function useOwnersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnersTableQuery,
    OwnersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OwnersTableQuery, OwnersTableQueryVariables>(
    OwnersTableDocument,
    options
  );
}
export type OwnersTableQueryHookResult = ReturnType<typeof useOwnersTableQuery>;
export type OwnersTableLazyQueryHookResult = ReturnType<
  typeof useOwnersTableLazyQuery
>;
export type OwnersTableQueryResult = Apollo.QueryResult<
  OwnersTableQuery,
  OwnersTableQueryVariables
>;
export const RiskScoreGraphDataDocument = gql`
  query RiskScoreGraphData {
    riskScoreGraphData {
      numEntitiesPerScore
      granularity
      overallRiskScore
    }
  }
`;

/**
 * __useRiskScoreGraphDataQuery__
 *
 * To run a query within a React component, call `useRiskScoreGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskScoreGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskScoreGraphDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useRiskScoreGraphDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RiskScoreGraphDataQuery,
    RiskScoreGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RiskScoreGraphDataQuery,
    RiskScoreGraphDataQueryVariables
  >(RiskScoreGraphDataDocument, options);
}
export function useRiskScoreGraphDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RiskScoreGraphDataQuery,
    RiskScoreGraphDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RiskScoreGraphDataQuery,
    RiskScoreGraphDataQueryVariables
  >(RiskScoreGraphDataDocument, options);
}
export type RiskScoreGraphDataQueryHookResult = ReturnType<
  typeof useRiskScoreGraphDataQuery
>;
export type RiskScoreGraphDataLazyQueryHookResult = ReturnType<
  typeof useRiskScoreGraphDataLazyQuery
>;
export type RiskScoreGraphDataQueryResult = Apollo.QueryResult<
  RiskScoreGraphDataQuery,
  RiskScoreGraphDataQueryVariables
>;
export const EntitiesForRiskScoreRangeDocument = gql`
  query EntitiesForRiskScoreRange($minRiskScore: Int!, $maxRiskScore: Int!) {
    entitiesForRiskScoreRange(
      minRiskScore: $minRiskScore
      maxRiskScore: $maxRiskScore
    ) {
      entities {
        ... on Resource {
          ...RiskyResourcePreviewTiny
        }
        ... on Group {
          ...RiskyGroupPreviewTiny
        }
      }
    }
  }
  ${RiskyResourcePreviewTinyFragmentDoc}
  ${RiskyGroupPreviewTinyFragmentDoc}
`;

/**
 * __useEntitiesForRiskScoreRangeQuery__
 *
 * To run a query within a React component, call `useEntitiesForRiskScoreRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitiesForRiskScoreRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitiesForRiskScoreRangeQuery({
 *   variables: {
 *      minRiskScore: // value for 'minRiskScore'
 *      maxRiskScore: // value for 'maxRiskScore'
 *   },
 * });
 */
export function useEntitiesForRiskScoreRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    EntitiesForRiskScoreRangeQuery,
    EntitiesForRiskScoreRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EntitiesForRiskScoreRangeQuery,
    EntitiesForRiskScoreRangeQueryVariables
  >(EntitiesForRiskScoreRangeDocument, options);
}
export function useEntitiesForRiskScoreRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntitiesForRiskScoreRangeQuery,
    EntitiesForRiskScoreRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EntitiesForRiskScoreRangeQuery,
    EntitiesForRiskScoreRangeQueryVariables
  >(EntitiesForRiskScoreRangeDocument, options);
}
export type EntitiesForRiskScoreRangeQueryHookResult = ReturnType<
  typeof useEntitiesForRiskScoreRangeQuery
>;
export type EntitiesForRiskScoreRangeLazyQueryHookResult = ReturnType<
  typeof useEntitiesForRiskScoreRangeLazyQuery
>;
export type EntitiesForRiskScoreRangeQueryResult = Apollo.QueryResult<
  EntitiesForRiskScoreRangeQuery,
  EntitiesForRiskScoreRangeQueryVariables
>;
export const RecommendationsMetricsGraphDocument = gql`
  query RecommendationsMetricsGraph {
    recommendationsMetricsGraph {
      perpetualAccessGraph {
        metricType
        value
        date
      }
      timeboundAccessGraph {
        metricType
        value
        date
      }
    }
  }
`;

/**
 * __useRecommendationsMetricsGraphQuery__
 *
 * To run a query within a React component, call `useRecommendationsMetricsGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsMetricsGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsMetricsGraphQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecommendationsMetricsGraphQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecommendationsMetricsGraphQuery,
    RecommendationsMetricsGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecommendationsMetricsGraphQuery,
    RecommendationsMetricsGraphQueryVariables
  >(RecommendationsMetricsGraphDocument, options);
}
export function useRecommendationsMetricsGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecommendationsMetricsGraphQuery,
    RecommendationsMetricsGraphQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecommendationsMetricsGraphQuery,
    RecommendationsMetricsGraphQueryVariables
  >(RecommendationsMetricsGraphDocument, options);
}
export type RecommendationsMetricsGraphQueryHookResult = ReturnType<
  typeof useRecommendationsMetricsGraphQuery
>;
export type RecommendationsMetricsGraphLazyQueryHookResult = ReturnType<
  typeof useRecommendationsMetricsGraphLazyQuery
>;
export type RecommendationsMetricsGraphQueryResult = Apollo.QueryResult<
  RecommendationsMetricsGraphQuery,
  RecommendationsMetricsGraphQueryVariables
>;
export const TopBulkSuggestionsDocument = gql`
  query TopBulkSuggestions(
    $appId: AppIdInput
    $minRiskScore: Int
    $maxRiskScore: Int
    $tagID: TagInput
    $riskTypes: [RecommendationsSubscoreType]
    $groupType: GroupType
    $resourceType: ResourceType
  ) {
    topBulkSuggestions(
      appId: $appId
      minRiskScore: $minRiskScore
      maxRiskScore: $maxRiskScore
      tagID: $tagID
      riskTypes: $riskTypes
      groupType: $groupType
      resourceType: $resourceType
    ) {
      suggestions {
        entity {
          ... on Resource {
            ...ResourceBulkSuggestionPreview
          }
          ... on Group {
            ...GroupBulkSuggestionPreview
          }
        }
        type
        targetUserCount
        totalUserCount
      }
    }
  }
  ${ResourceBulkSuggestionPreviewFragmentDoc}
  ${GroupBulkSuggestionPreviewFragmentDoc}
`;

/**
 * __useTopBulkSuggestionsQuery__
 *
 * To run a query within a React component, call `useTopBulkSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBulkSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBulkSuggestionsQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      minRiskScore: // value for 'minRiskScore'
 *      maxRiskScore: // value for 'maxRiskScore'
 *      tagID: // value for 'tagID'
 *      riskTypes: // value for 'riskTypes'
 *      groupType: // value for 'groupType'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useTopBulkSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TopBulkSuggestionsQuery,
    TopBulkSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TopBulkSuggestionsQuery,
    TopBulkSuggestionsQueryVariables
  >(TopBulkSuggestionsDocument, options);
}
export function useTopBulkSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopBulkSuggestionsQuery,
    TopBulkSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopBulkSuggestionsQuery,
    TopBulkSuggestionsQueryVariables
  >(TopBulkSuggestionsDocument, options);
}
export type TopBulkSuggestionsQueryHookResult = ReturnType<
  typeof useTopBulkSuggestionsQuery
>;
export type TopBulkSuggestionsLazyQueryHookResult = ReturnType<
  typeof useTopBulkSuggestionsLazyQuery
>;
export type TopBulkSuggestionsQueryResult = Apollo.QueryResult<
  TopBulkSuggestionsQuery,
  TopBulkSuggestionsQueryVariables
>;
export const AppThreatCountsDocument = gql`
  query appThreatCounts($appCategory: AppCategory) {
    apps(access: ALL, appCategory: $appCategory) {
      ... on AppsOutput {
        apps {
          ...AppThreatPreview
        }
      }
    }
  }
  ${AppThreatPreviewFragmentDoc}
`;

/**
 * __useAppThreatCountsQuery__
 *
 * To run a query within a React component, call `useAppThreatCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppThreatCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppThreatCountsQuery({
 *   variables: {
 *      appCategory: // value for 'appCategory'
 *   },
 * });
 */
export function useAppThreatCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AppThreatCountsQuery,
    AppThreatCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppThreatCountsQuery, AppThreatCountsQueryVariables>(
    AppThreatCountsDocument,
    options
  );
}
export function useAppThreatCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppThreatCountsQuery,
    AppThreatCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppThreatCountsQuery,
    AppThreatCountsQueryVariables
  >(AppThreatCountsDocument, options);
}
export type AppThreatCountsQueryHookResult = ReturnType<
  typeof useAppThreatCountsQuery
>;
export type AppThreatCountsLazyQueryHookResult = ReturnType<
  typeof useAppThreatCountsLazyQuery
>;
export type AppThreatCountsQueryResult = Apollo.QueryResult<
  AppThreatCountsQuery,
  AppThreatCountsQueryVariables
>;
export const EntityForRemediationDocument = gql`
  query EntityForRemediation($entityKey: RecommendationsEntityKey!) {
    entityForRemediation(entityKey: $entityKey) {
      ... on Resource {
        ...ResourceForRemediation
      }
      ... on Group {
        ...GroupForRemediation
      }
    }
  }
  ${ResourceForRemediationFragmentDoc}
  ${GroupForRemediationFragmentDoc}
`;

/**
 * __useEntityForRemediationQuery__
 *
 * To run a query within a React component, call `useEntityForRemediationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityForRemediationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityForRemediationQuery({
 *   variables: {
 *      entityKey: // value for 'entityKey'
 *   },
 * });
 */
export function useEntityForRemediationQuery(
  baseOptions: Apollo.QueryHookOptions<
    EntityForRemediationQuery,
    EntityForRemediationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EntityForRemediationQuery,
    EntityForRemediationQueryVariables
  >(EntityForRemediationDocument, options);
}
export function useEntityForRemediationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntityForRemediationQuery,
    EntityForRemediationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EntityForRemediationQuery,
    EntityForRemediationQueryVariables
  >(EntityForRemediationDocument, options);
}
export type EntityForRemediationQueryHookResult = ReturnType<
  typeof useEntityForRemediationQuery
>;
export type EntityForRemediationLazyQueryHookResult = ReturnType<
  typeof useEntityForRemediationLazyQuery
>;
export type EntityForRemediationQueryResult = Apollo.QueryResult<
  EntityForRemediationQuery,
  EntityForRemediationQueryVariables
>;
export const SuggestedAccessToRemediateForEntityDocument = gql`
  query SuggestedAccessToRemediateForEntity(
    $entityKey: RecommendationsEntityKey!
    $type: RecommendationsSubscoreType!
  ) {
    suggestedAccessToRemediateForEntity(entityKey: $entityKey, type: $type) {
      userRoleKeys {
        userId
        accessLevelRemoteId
      }
      resourceRoleKeys {
        resourceId
        accessLevelRemoteId
      }
      type
    }
  }
`;

/**
 * __useSuggestedAccessToRemediateForEntityQuery__
 *
 * To run a query within a React component, call `useSuggestedAccessToRemediateForEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedAccessToRemediateForEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedAccessToRemediateForEntityQuery({
 *   variables: {
 *      entityKey: // value for 'entityKey'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSuggestedAccessToRemediateForEntityQuery(
  baseOptions: Apollo.QueryHookOptions<
    SuggestedAccessToRemediateForEntityQuery,
    SuggestedAccessToRemediateForEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SuggestedAccessToRemediateForEntityQuery,
    SuggestedAccessToRemediateForEntityQueryVariables
  >(SuggestedAccessToRemediateForEntityDocument, options);
}
export function useSuggestedAccessToRemediateForEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestedAccessToRemediateForEntityQuery,
    SuggestedAccessToRemediateForEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestedAccessToRemediateForEntityQuery,
    SuggestedAccessToRemediateForEntityQueryVariables
  >(SuggestedAccessToRemediateForEntityDocument, options);
}
export type SuggestedAccessToRemediateForEntityQueryHookResult = ReturnType<
  typeof useSuggestedAccessToRemediateForEntityQuery
>;
export type SuggestedAccessToRemediateForEntityLazyQueryHookResult = ReturnType<
  typeof useSuggestedAccessToRemediateForEntityLazyQuery
>;
export type SuggestedAccessToRemediateForEntityQueryResult = Apollo.QueryResult<
  SuggestedAccessToRemediateForEntityQuery,
  SuggestedAccessToRemediateForEntityQueryVariables
>;
export const DismissRecommendationsSubscoresDocument = gql`
  mutation DismissRecommendationsSubscores(
    $input: DismissRecommendationsSubscoresInput!
  ) {
    dismissRecommendationsSubscores(input: $input) {
      success
    }
  }
`;
export type DismissRecommendationsSubscoresMutationFn = Apollo.MutationFunction<
  DismissRecommendationsSubscoresMutation,
  DismissRecommendationsSubscoresMutationVariables
>;

/**
 * __useDismissRecommendationsSubscoresMutation__
 *
 * To run a mutation, you first call `useDismissRecommendationsSubscoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissRecommendationsSubscoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissRecommendationsSubscoresMutation, { data, loading, error }] = useDismissRecommendationsSubscoresMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissRecommendationsSubscoresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissRecommendationsSubscoresMutation,
    DismissRecommendationsSubscoresMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DismissRecommendationsSubscoresMutation,
    DismissRecommendationsSubscoresMutationVariables
  >(DismissRecommendationsSubscoresDocument, options);
}
export type DismissRecommendationsSubscoresMutationHookResult = ReturnType<
  typeof useDismissRecommendationsSubscoresMutation
>;
export type DismissRecommendationsSubscoresMutationResult = Apollo.MutationResult<
  DismissRecommendationsSubscoresMutation
>;
export type DismissRecommendationsSubscoresMutationOptions = Apollo.BaseMutationOptions<
  DismissRecommendationsSubscoresMutation,
  DismissRecommendationsSubscoresMutationVariables
>;
export const RescoreEntityDocument = gql`
  mutation RescoreEntity($input: RecommendationsEntityKey!) {
    rescoreEntity(input: $input) {
      success
    }
  }
`;
export type RescoreEntityMutationFn = Apollo.MutationFunction<
  RescoreEntityMutation,
  RescoreEntityMutationVariables
>;

/**
 * __useRescoreEntityMutation__
 *
 * To run a mutation, you first call `useRescoreEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescoreEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescoreEntityMutation, { data, loading, error }] = useRescoreEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescoreEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescoreEntityMutation,
    RescoreEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescoreEntityMutation,
    RescoreEntityMutationVariables
  >(RescoreEntityDocument, options);
}
export type RescoreEntityMutationHookResult = ReturnType<
  typeof useRescoreEntityMutation
>;
export type RescoreEntityMutationResult = Apollo.MutationResult<
  RescoreEntityMutation
>;
export type RescoreEntityMutationOptions = Apollo.BaseMutationOptions<
  RescoreEntityMutation,
  RescoreEntityMutationVariables
>;
export const LogEntityRemediationDocument = gql`
  mutation LogEntityRemediation($input: LogEntityRemediationInput!) {
    logEntityRemediation(input: $input) {
      success
    }
  }
`;
export type LogEntityRemediationMutationFn = Apollo.MutationFunction<
  LogEntityRemediationMutation,
  LogEntityRemediationMutationVariables
>;

/**
 * __useLogEntityRemediationMutation__
 *
 * To run a mutation, you first call `useLogEntityRemediationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogEntityRemediationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logEntityRemediationMutation, { data, loading, error }] = useLogEntityRemediationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogEntityRemediationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogEntityRemediationMutation,
    LogEntityRemediationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LogEntityRemediationMutation,
    LogEntityRemediationMutationVariables
  >(LogEntityRemediationDocument, options);
}
export type LogEntityRemediationMutationHookResult = ReturnType<
  typeof useLogEntityRemediationMutation
>;
export type LogEntityRemediationMutationResult = Apollo.MutationResult<
  LogEntityRemediationMutation
>;
export type LogEntityRemediationMutationOptions = Apollo.BaseMutationOptions<
  LogEntityRemediationMutation,
  LogEntityRemediationMutationVariables
>;
export const NumRequestsToReviewDocument = gql`
  query NumRequestsToReview {
    numRequestsToReview
  }
`;

/**
 * __useNumRequestsToReviewQuery__
 *
 * To run a query within a React component, call `useNumRequestsToReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumRequestsToReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumRequestsToReviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useNumRequestsToReviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NumRequestsToReviewQuery,
    NumRequestsToReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NumRequestsToReviewQuery,
    NumRequestsToReviewQueryVariables
  >(NumRequestsToReviewDocument, options);
}
export function useNumRequestsToReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NumRequestsToReviewQuery,
    NumRequestsToReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NumRequestsToReviewQuery,
    NumRequestsToReviewQueryVariables
  >(NumRequestsToReviewDocument, options);
}
export type NumRequestsToReviewQueryHookResult = ReturnType<
  typeof useNumRequestsToReviewQuery
>;
export type NumRequestsToReviewLazyQueryHookResult = ReturnType<
  typeof useNumRequestsToReviewLazyQuery
>;
export type NumRequestsToReviewQueryResult = Apollo.QueryResult<
  NumRequestsToReviewQuery,
  NumRequestsToReviewQueryVariables
>;
export const RequestStatsDocument = gql`
  query RequestStats {
    currentUserStats {
      ... on CurrentUserStatsResult {
        currentUserStats {
          numResourcesAdmined
          numGroupsAdmined
          reviewsItems
          numRequestsSentPending
          numRequestsSentPendingOnBehalfOfUser
          numRequestsSent
          numRequestsSentOnBehalfOfUser
        }
      }
    }
  }
`;

/**
 * __useRequestStatsQuery__
 *
 * To run a query within a React component, call `useRequestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestStatsQuery,
    RequestStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestStatsQuery, RequestStatsQueryVariables>(
    RequestStatsDocument,
    options
  );
}
export function useRequestStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestStatsQuery,
    RequestStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestStatsQuery, RequestStatsQueryVariables>(
    RequestStatsDocument,
    options
  );
}
export type RequestStatsQueryHookResult = ReturnType<
  typeof useRequestStatsQuery
>;
export type RequestStatsLazyQueryHookResult = ReturnType<
  typeof useRequestStatsLazyQuery
>;
export type RequestStatsQueryResult = Apollo.QueryResult<
  RequestStatsQuery,
  RequestStatsQueryVariables
>;
export const RequestsTableDocument = gql`
  query RequestsTable($input: RequestsInput!) {
    requests(input: $input) {
      __typename
      ... on RequestsResult {
        requests {
          ...RequestTable
        }
        cursor
      }
    }
  }
  ${RequestTableFragmentDoc}
`;

/**
 * __useRequestsTableQuery__
 *
 * To run a query within a React component, call `useRequestsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestsTableQuery,
    RequestsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestsTableQuery, RequestsTableQueryVariables>(
    RequestsTableDocument,
    options
  );
}
export function useRequestsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestsTableQuery,
    RequestsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestsTableQuery, RequestsTableQueryVariables>(
    RequestsTableDocument,
    options
  );
}
export type RequestsTableQueryHookResult = ReturnType<
  typeof useRequestsTableQuery
>;
export type RequestsTableLazyQueryHookResult = ReturnType<
  typeof useRequestsTableLazyQuery
>;
export type RequestsTableQueryResult = Apollo.QueryResult<
  RequestsTableQuery,
  RequestsTableQueryVariables
>;
export const UserSkipManagerDocument = gql`
  query UserSkipManager($input: UserId!) {
    user(input: { id: $input }) {
      ... on UserResult {
        user {
          manager {
            managerId
          }
        }
      }
    }
  }
`;

/**
 * __useUserSkipManagerQuery__
 *
 * To run a query within a React component, call `useUserSkipManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSkipManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSkipManagerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserSkipManagerQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserSkipManagerQuery,
    UserSkipManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSkipManagerQuery, UserSkipManagerQueryVariables>(
    UserSkipManagerDocument,
    options
  );
}
export function useUserSkipManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSkipManagerQuery,
    UserSkipManagerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserSkipManagerQuery,
    UserSkipManagerQueryVariables
  >(UserSkipManagerDocument, options);
}
export type UserSkipManagerQueryHookResult = ReturnType<
  typeof useUserSkipManagerQuery
>;
export type UserSkipManagerLazyQueryHookResult = ReturnType<
  typeof useUserSkipManagerLazyQuery
>;
export type UserSkipManagerQueryResult = Apollo.QueryResult<
  UserSkipManagerQuery,
  UserSkipManagerQueryVariables
>;
export const EscalateRequestToSkipManagerDocument = gql`
  mutation EscalateRequestToSkipManager(
    $input: EscalateRequestToSkipManagerInput!
  ) {
    escalateRequestToSkipManager(input: $input) {
      __typename
      ... on EscalateRequestToSkipManagerResult {
        success
      }
      ... on ManagerHasAlreadyApprovedError {
        message
      }
      ... on NoManagerSetForRequestingUserError {
        message
      }
      ... on NoSkipManagerSetForTargetUserError {
        message
      }
      ... on RequestNotFoundError {
        message
      }
      ... on RequestAlreadyEscalatedToSkipManagerError {
        message
      }
    }
  }
`;
export type EscalateRequestToSkipManagerMutationFn = Apollo.MutationFunction<
  EscalateRequestToSkipManagerMutation,
  EscalateRequestToSkipManagerMutationVariables
>;

/**
 * __useEscalateRequestToSkipManagerMutation__
 *
 * To run a mutation, you first call `useEscalateRequestToSkipManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEscalateRequestToSkipManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [escalateRequestToSkipManagerMutation, { data, loading, error }] = useEscalateRequestToSkipManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEscalateRequestToSkipManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EscalateRequestToSkipManagerMutation,
    EscalateRequestToSkipManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EscalateRequestToSkipManagerMutation,
    EscalateRequestToSkipManagerMutationVariables
  >(EscalateRequestToSkipManagerDocument, options);
}
export type EscalateRequestToSkipManagerMutationHookResult = ReturnType<
  typeof useEscalateRequestToSkipManagerMutation
>;
export type EscalateRequestToSkipManagerMutationResult = Apollo.MutationResult<
  EscalateRequestToSkipManagerMutation
>;
export type EscalateRequestToSkipManagerMutationOptions = Apollo.BaseMutationOptions<
  EscalateRequestToSkipManagerMutation,
  EscalateRequestToSkipManagerMutationVariables
>;
export const GroupGroupAccessDocument = gql`
  query GroupGroupAccess($groupGroupId: GroupGroupId!) {
    groupGroupAccess(input: { groupGroupId: $groupGroupId }) {
      ... on GroupGroupAccessResult {
        access {
          ...GroupGroupAccess
        }
      }
    }
  }
  ${GroupGroupAccessFragmentDoc}
`;

/**
 * __useGroupGroupAccessQuery__
 *
 * To run a query within a React component, call `useGroupGroupAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupGroupAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupGroupAccessQuery({
 *   variables: {
 *      groupGroupId: // value for 'groupGroupId'
 *   },
 * });
 */
export function useGroupGroupAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupGroupAccessQuery,
    GroupGroupAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupGroupAccessQuery, GroupGroupAccessQueryVariables>(
    GroupGroupAccessDocument,
    options
  );
}
export function useGroupGroupAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupGroupAccessQuery,
    GroupGroupAccessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupGroupAccessQuery,
    GroupGroupAccessQueryVariables
  >(GroupGroupAccessDocument, options);
}
export type GroupGroupAccessQueryHookResult = ReturnType<
  typeof useGroupGroupAccessQuery
>;
export type GroupGroupAccessLazyQueryHookResult = ReturnType<
  typeof useGroupGroupAccessLazyQuery
>;
export type GroupGroupAccessQueryResult = Apollo.QueryResult<
  GroupGroupAccessQuery,
  GroupGroupAccessQueryVariables
>;
export const ImportResourcesBodyDocument = gql`
  query ImportResourcesBody($input: ConnectionInput!) {
    connection(input: $input) {
      __typename
      ... on ConnectionResult {
        connection {
          ...ConnectionForResourceImport
        }
      }
      ... on ConnectionNotFoundError {
        message
      }
    }
  }
  ${ConnectionForResourceImportFragmentDoc}
`;

/**
 * __useImportResourcesBodyQuery__
 *
 * To run a query within a React component, call `useImportResourcesBodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportResourcesBodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportResourcesBodyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportResourcesBodyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImportResourcesBodyQuery,
    ImportResourcesBodyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImportResourcesBodyQuery,
    ImportResourcesBodyQueryVariables
  >(ImportResourcesBodyDocument, options);
}
export function useImportResourcesBodyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportResourcesBodyQuery,
    ImportResourcesBodyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportResourcesBodyQuery,
    ImportResourcesBodyQueryVariables
  >(ImportResourcesBodyDocument, options);
}
export type ImportResourcesBodyQueryHookResult = ReturnType<
  typeof useImportResourcesBodyQuery
>;
export type ImportResourcesBodyLazyQueryHookResult = ReturnType<
  typeof useImportResourcesBodyLazyQuery
>;
export type ImportResourcesBodyQueryResult = Apollo.QueryResult<
  ImportResourcesBodyQuery,
  ImportResourcesBodyQueryVariables
>;
export const ResourceVisibilityRowDocument = gql`
  query ResourceVisibilityRow($input: ResourceInput!) {
    resource(input: $input) {
      ... on ResourceResult {
        resource {
          ...ResourcePreviewSmall
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
`;

/**
 * __useResourceVisibilityRowQuery__
 *
 * To run a query within a React component, call `useResourceVisibilityRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceVisibilityRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceVisibilityRowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResourceVisibilityRowQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceVisibilityRowQuery,
    ResourceVisibilityRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ResourceVisibilityRowQuery,
    ResourceVisibilityRowQueryVariables
  >(ResourceVisibilityRowDocument, options);
}
export function useResourceVisibilityRowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceVisibilityRowQuery,
    ResourceVisibilityRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ResourceVisibilityRowQuery,
    ResourceVisibilityRowQueryVariables
  >(ResourceVisibilityRowDocument, options);
}
export type ResourceVisibilityRowQueryHookResult = ReturnType<
  typeof useResourceVisibilityRowQuery
>;
export type ResourceVisibilityRowLazyQueryHookResult = ReturnType<
  typeof useResourceVisibilityRowLazyQuery
>;
export type ResourceVisibilityRowQueryResult = Apollo.QueryResult<
  ResourceVisibilityRowQuery,
  ResourceVisibilityRowQueryVariables
>;
export const GroupOnCallSchedulesDocument = gql`
  query GroupOnCallSchedules {
    groupOnCallSchedules {
      __typename
      ... on GroupOnCallSchedulesResult {
        groupOnCallSchedules {
          ...GroupOnCallSchedulePreviewSmall
        }
      }
    }
  }
  ${GroupOnCallSchedulePreviewSmallFragmentDoc}
`;

/**
 * __useGroupOnCallSchedulesQuery__
 *
 * To run a query within a React component, call `useGroupOnCallSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupOnCallSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupOnCallSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupOnCallSchedulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupOnCallSchedulesQuery,
    GroupOnCallSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GroupOnCallSchedulesQuery,
    GroupOnCallSchedulesQueryVariables
  >(GroupOnCallSchedulesDocument, options);
}
export function useGroupOnCallSchedulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupOnCallSchedulesQuery,
    GroupOnCallSchedulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GroupOnCallSchedulesQuery,
    GroupOnCallSchedulesQueryVariables
  >(GroupOnCallSchedulesDocument, options);
}
export type GroupOnCallSchedulesQueryHookResult = ReturnType<
  typeof useGroupOnCallSchedulesQuery
>;
export type GroupOnCallSchedulesLazyQueryHookResult = ReturnType<
  typeof useGroupOnCallSchedulesLazyQuery
>;
export type GroupOnCallSchedulesQueryResult = Apollo.QueryResult<
  GroupOnCallSchedulesQuery,
  GroupOnCallSchedulesQueryVariables
>;
export const CreateEventStreamDocument = gql`
  mutation CreateEventStream(
    $input: CreateEventStreamInput!
    $fetchFailedDeliveryCounts: Boolean = false
  ) {
    createEventStream(input: $input) {
      __typename
      ... on CreateEventStreamResult {
        eventStream {
          ...EventStream
        }
      }
    }
  }
  ${EventStreamFragmentDoc}
`;
export type CreateEventStreamMutationFn = Apollo.MutationFunction<
  CreateEventStreamMutation,
  CreateEventStreamMutationVariables
>;

/**
 * __useCreateEventStreamMutation__
 *
 * To run a mutation, you first call `useCreateEventStreamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventStreamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventStreamMutation, { data, loading, error }] = useCreateEventStreamMutation({
 *   variables: {
 *      input: // value for 'input'
 *      fetchFailedDeliveryCounts: // value for 'fetchFailedDeliveryCounts'
 *   },
 * });
 */
export function useCreateEventStreamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventStreamMutation,
    CreateEventStreamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventStreamMutation,
    CreateEventStreamMutationVariables
  >(CreateEventStreamDocument, options);
}
export type CreateEventStreamMutationHookResult = ReturnType<
  typeof useCreateEventStreamMutation
>;
export type CreateEventStreamMutationResult = Apollo.MutationResult<
  CreateEventStreamMutation
>;
export type CreateEventStreamMutationOptions = Apollo.BaseMutationOptions<
  CreateEventStreamMutation,
  CreateEventStreamMutationVariables
>;
export const ListEventStreamsDocument = gql`
  query ListEventStreams($fetchFailedDeliveryCounts: Boolean = true) {
    listEventStreams {
      __typename
      ... on ListEventStreamsResult {
        eventStreams {
          ...EventStream
        }
      }
    }
  }
  ${EventStreamFragmentDoc}
`;

/**
 * __useListEventStreamsQuery__
 *
 * To run a query within a React component, call `useListEventStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventStreamsQuery({
 *   variables: {
 *      fetchFailedDeliveryCounts: // value for 'fetchFailedDeliveryCounts'
 *   },
 * });
 */
export function useListEventStreamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEventStreamsQuery,
    ListEventStreamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListEventStreamsQuery, ListEventStreamsQueryVariables>(
    ListEventStreamsDocument,
    options
  );
}
export function useListEventStreamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEventStreamsQuery,
    ListEventStreamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListEventStreamsQuery,
    ListEventStreamsQueryVariables
  >(ListEventStreamsDocument, options);
}
export type ListEventStreamsQueryHookResult = ReturnType<
  typeof useListEventStreamsQuery
>;
export type ListEventStreamsLazyQueryHookResult = ReturnType<
  typeof useListEventStreamsLazyQuery
>;
export type ListEventStreamsQueryResult = Apollo.QueryResult<
  ListEventStreamsQuery,
  ListEventStreamsQueryVariables
>;
export const OidcProviderDocument = gql`
  query OidcProvider($input: OidcProviderInput!) {
    oidcProvider(input: $input) {
      __typename
      ... on OidcProviderResult {
        oidcProvider {
          clientId
          issuerUrl
        }
      }
      ... on OidcProviderNotFoundError {
        message
      }
    }
  }
`;

/**
 * __useOidcProviderQuery__
 *
 * To run a query within a React component, call `useOidcProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOidcProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOidcProviderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOidcProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    OidcProviderQuery,
    OidcProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OidcProviderQuery, OidcProviderQueryVariables>(
    OidcProviderDocument,
    options
  );
}
export function useOidcProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OidcProviderQuery,
    OidcProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OidcProviderQuery, OidcProviderQueryVariables>(
    OidcProviderDocument,
    options
  );
}
export type OidcProviderQueryHookResult = ReturnType<
  typeof useOidcProviderQuery
>;
export type OidcProviderLazyQueryHookResult = ReturnType<
  typeof useOidcProviderLazyQuery
>;
export type OidcProviderQueryResult = Apollo.QueryResult<
  OidcProviderQuery,
  OidcProviderQueryVariables
>;
export const InitOidcAuthFlowDocument = gql`
  mutation InitOidcAuthFlow($input: InitOidcAuthFlowInput!) {
    initOidcAuthFlow(input: $input) {
      __typename
      ... on InitOidcAuthFlowResult {
        authorizeUrl
      }
    }
  }
`;
export type InitOidcAuthFlowMutationFn = Apollo.MutationFunction<
  InitOidcAuthFlowMutation,
  InitOidcAuthFlowMutationVariables
>;

/**
 * __useInitOidcAuthFlowMutation__
 *
 * To run a mutation, you first call `useInitOidcAuthFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitOidcAuthFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initOidcAuthFlowMutation, { data, loading, error }] = useInitOidcAuthFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitOidcAuthFlowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitOidcAuthFlowMutation,
    InitOidcAuthFlowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitOidcAuthFlowMutation,
    InitOidcAuthFlowMutationVariables
  >(InitOidcAuthFlowDocument, options);
}
export type InitOidcAuthFlowMutationHookResult = ReturnType<
  typeof useInitOidcAuthFlowMutation
>;
export type InitOidcAuthFlowMutationResult = Apollo.MutationResult<
  InitOidcAuthFlowMutation
>;
export type InitOidcAuthFlowMutationOptions = Apollo.BaseMutationOptions<
  InitOidcAuthFlowMutation,
  InitOidcAuthFlowMutationVariables
>;
export const ValidateCodeAndSaveIdTokenDocument = gql`
  mutation ValidateCodeAndSaveIdToken(
    $input: ValidateCodeAndSaveIdTokenInput!
  ) {
    validateCodeAndSaveIdToken(input: $input)
  }
`;
export type ValidateCodeAndSaveIdTokenMutationFn = Apollo.MutationFunction<
  ValidateCodeAndSaveIdTokenMutation,
  ValidateCodeAndSaveIdTokenMutationVariables
>;

/**
 * __useValidateCodeAndSaveIdTokenMutation__
 *
 * To run a mutation, you first call `useValidateCodeAndSaveIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCodeAndSaveIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCodeAndSaveIdTokenMutation, { data, loading, error }] = useValidateCodeAndSaveIdTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCodeAndSaveIdTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateCodeAndSaveIdTokenMutation,
    ValidateCodeAndSaveIdTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateCodeAndSaveIdTokenMutation,
    ValidateCodeAndSaveIdTokenMutationVariables
  >(ValidateCodeAndSaveIdTokenDocument, options);
}
export type ValidateCodeAndSaveIdTokenMutationHookResult = ReturnType<
  typeof useValidateCodeAndSaveIdTokenMutation
>;
export type ValidateCodeAndSaveIdTokenMutationResult = Apollo.MutationResult<
  ValidateCodeAndSaveIdTokenMutation
>;
export type ValidateCodeAndSaveIdTokenMutationOptions = Apollo.BaseMutationOptions<
  ValidateCodeAndSaveIdTokenMutation,
  ValidateCodeAndSaveIdTokenMutationVariables
>;
export const InitOidcAuthFlowPresignedDocument = gql`
  mutation InitOidcAuthFlowPresigned($input: InitOidcAuthFlowInput!) {
    initOidcAuthFlowPresigned(input: $input) {
      __typename
      ... on InitOidcAuthFlowResult {
        authorizeUrl
      }
    }
  }
`;
export type InitOidcAuthFlowPresignedMutationFn = Apollo.MutationFunction<
  InitOidcAuthFlowPresignedMutation,
  InitOidcAuthFlowPresignedMutationVariables
>;

/**
 * __useInitOidcAuthFlowPresignedMutation__
 *
 * To run a mutation, you first call `useInitOidcAuthFlowPresignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitOidcAuthFlowPresignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initOidcAuthFlowPresignedMutation, { data, loading, error }] = useInitOidcAuthFlowPresignedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitOidcAuthFlowPresignedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitOidcAuthFlowPresignedMutation,
    InitOidcAuthFlowPresignedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitOidcAuthFlowPresignedMutation,
    InitOidcAuthFlowPresignedMutationVariables
  >(InitOidcAuthFlowPresignedDocument, options);
}
export type InitOidcAuthFlowPresignedMutationHookResult = ReturnType<
  typeof useInitOidcAuthFlowPresignedMutation
>;
export type InitOidcAuthFlowPresignedMutationResult = Apollo.MutationResult<
  InitOidcAuthFlowPresignedMutation
>;
export type InitOidcAuthFlowPresignedMutationOptions = Apollo.BaseMutationOptions<
  InitOidcAuthFlowPresignedMutation,
  InitOidcAuthFlowPresignedMutationVariables
>;
export const ValidateCodeAndSaveIdTokenPresignedDocument = gql`
  mutation ValidateCodeAndSaveIdTokenPresigned(
    $input: ValidateCodeAndSaveIdTokenInput!
  ) {
    validateCodeAndSaveIdTokenPresigned(input: $input)
  }
`;
export type ValidateCodeAndSaveIdTokenPresignedMutationFn = Apollo.MutationFunction<
  ValidateCodeAndSaveIdTokenPresignedMutation,
  ValidateCodeAndSaveIdTokenPresignedMutationVariables
>;

/**
 * __useValidateCodeAndSaveIdTokenPresignedMutation__
 *
 * To run a mutation, you first call `useValidateCodeAndSaveIdTokenPresignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateCodeAndSaveIdTokenPresignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCodeAndSaveIdTokenPresignedMutation, { data, loading, error }] = useValidateCodeAndSaveIdTokenPresignedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCodeAndSaveIdTokenPresignedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateCodeAndSaveIdTokenPresignedMutation,
    ValidateCodeAndSaveIdTokenPresignedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateCodeAndSaveIdTokenPresignedMutation,
    ValidateCodeAndSaveIdTokenPresignedMutationVariables
  >(ValidateCodeAndSaveIdTokenPresignedDocument, options);
}
export type ValidateCodeAndSaveIdTokenPresignedMutationHookResult = ReturnType<
  typeof useValidateCodeAndSaveIdTokenPresignedMutation
>;
export type ValidateCodeAndSaveIdTokenPresignedMutationResult = Apollo.MutationResult<
  ValidateCodeAndSaveIdTokenPresignedMutation
>;
export type ValidateCodeAndSaveIdTokenPresignedMutationOptions = Apollo.BaseMutationOptions<
  ValidateCodeAndSaveIdTokenPresignedMutation,
  ValidateCodeAndSaveIdTokenPresignedMutationVariables
>;
export const CreateOrUpdateOidcProviderDocument = gql`
  mutation CreateOrUpdateOidcProvider(
    $input: CreateOrUpdateOidcProviderInput!
  ) {
    createOrUpdateOidcProvider(input: $input) {
      __typename
      ... on CreateOrUpdateOidcProviderResult {
        oidcProvider {
          clientId
          issuerUrl
        }
      }
      ... on OidcIssuerNotValidError {
        message
      }
    }
  }
`;
export type CreateOrUpdateOidcProviderMutationFn = Apollo.MutationFunction<
  CreateOrUpdateOidcProviderMutation,
  CreateOrUpdateOidcProviderMutationVariables
>;

/**
 * __useCreateOrUpdateOidcProviderMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateOidcProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateOidcProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateOidcProviderMutation, { data, loading, error }] = useCreateOrUpdateOidcProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateOidcProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateOidcProviderMutation,
    CreateOrUpdateOidcProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateOidcProviderMutation,
    CreateOrUpdateOidcProviderMutationVariables
  >(CreateOrUpdateOidcProviderDocument, options);
}
export type CreateOrUpdateOidcProviderMutationHookResult = ReturnType<
  typeof useCreateOrUpdateOidcProviderMutation
>;
export type CreateOrUpdateOidcProviderMutationResult = Apollo.MutationResult<
  CreateOrUpdateOidcProviderMutation
>;
export type CreateOrUpdateOidcProviderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateOidcProviderMutation,
  CreateOrUpdateOidcProviderMutationVariables
>;
export const SetupStateDocument = gql`
  query SetupState {
    setupState {
      state {
        minStep
        idpConnectionType
        idpConnectionId
        connectionId
        connection {
          ...ConnectionPreviewSmall
        }
        entityCounts {
          ...SetupEntityCounts
        }
        importStatus
        importEntityCounts {
          ...SetupEntityCounts
        }
      }
    }
  }
  ${ConnectionPreviewSmallFragmentDoc}
  ${SetupEntityCountsFragmentDoc}
`;

/**
 * __useSetupStateQuery__
 *
 * To run a query within a React component, call `useSetupStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SetupStateQuery,
    SetupStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SetupStateQuery, SetupStateQueryVariables>(
    SetupStateDocument,
    options
  );
}
export function useSetupStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SetupStateQuery,
    SetupStateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SetupStateQuery, SetupStateQueryVariables>(
    SetupStateDocument,
    options
  );
}
export type SetupStateQueryHookResult = ReturnType<typeof useSetupStateQuery>;
export type SetupStateLazyQueryHookResult = ReturnType<
  typeof useSetupStateLazyQuery
>;
export type SetupStateQueryResult = Apollo.QueryResult<
  SetupStateQuery,
  SetupStateQueryVariables
>;
export const ImportAllDocument = gql`
  mutation ImportAll($input: ImportAllInput!) {
    importAll(input: $input) {
      ... on BulkUpdateItemsResult {
        __typename
        updatedResources {
          ...ResourcePreviewSmall
        }
        updatedGroups {
          ...GroupPreviewSmall
        }
        syncTask {
          ...SyncTask
        }
      }
    }
  }
  ${ResourcePreviewSmallFragmentDoc}
  ${GroupPreviewSmallFragmentDoc}
  ${SyncTaskFragmentDoc}
`;
export type ImportAllMutationFn = Apollo.MutationFunction<
  ImportAllMutation,
  ImportAllMutationVariables
>;

/**
 * __useImportAllMutation__
 *
 * To run a mutation, you first call `useImportAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAllMutation, { data, loading, error }] = useImportAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportAllMutation,
    ImportAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportAllMutation, ImportAllMutationVariables>(
    ImportAllDocument,
    options
  );
}
export type ImportAllMutationHookResult = ReturnType<
  typeof useImportAllMutation
>;
export type ImportAllMutationResult = Apollo.MutationResult<ImportAllMutation>;
export type ImportAllMutationOptions = Apollo.BaseMutationOptions<
  ImportAllMutation,
  ImportAllMutationVariables
>;
export const TagsTableDocument = gql`
  query TagsTable($input: TagsInput!) {
    tags(input: $input) {
      __typename
      ... on TagsResult {
        tags {
          __typename
          id
          key
          value
          createdAt
          tagResources {
            __typename
            resourceId
          }
          tagGroups {
            __typename
            groupId
          }
          tagUsers {
            __typename
            userId
          }
        }
        totalNumTags
        cursor
      }
    }
  }
`;

/**
 * __useTagsTableQuery__
 *
 * To run a query within a React component, call `useTagsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsTableQuery(
  baseOptions: Apollo.QueryHookOptions<TagsTableQuery, TagsTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsTableQuery, TagsTableQueryVariables>(
    TagsTableDocument,
    options
  );
}
export function useTagsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagsTableQuery,
    TagsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsTableQuery, TagsTableQueryVariables>(
    TagsTableDocument,
    options
  );
}
export type TagsTableQueryHookResult = ReturnType<typeof useTagsTableQuery>;
export type TagsTableLazyQueryHookResult = ReturnType<
  typeof useTagsTableLazyQuery
>;
export type TagsTableQueryResult = Apollo.QueryResult<
  TagsTableQuery,
  TagsTableQueryVariables
>;
export const ConfigurationTemplatesViewDocument = gql`
  query ConfigurationTemplatesView {
    configurationTemplates {
      ... on ConfigurationTemplatesResult {
        configurationTemplates {
          id
          name
          adminOwner {
            id
            name
          }
          visibility
          requireMfaToApprove
          requireMfaToConnect
        }
      }
    }
  }
`;

/**
 * __useConfigurationTemplatesViewQuery__
 *
 * To run a query within a React component, call `useConfigurationTemplatesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationTemplatesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationTemplatesViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigurationTemplatesViewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConfigurationTemplatesViewQuery,
    ConfigurationTemplatesViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConfigurationTemplatesViewQuery,
    ConfigurationTemplatesViewQueryVariables
  >(ConfigurationTemplatesViewDocument, options);
}
export function useConfigurationTemplatesViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConfigurationTemplatesViewQuery,
    ConfigurationTemplatesViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConfigurationTemplatesViewQuery,
    ConfigurationTemplatesViewQueryVariables
  >(ConfigurationTemplatesViewDocument, options);
}
export type ConfigurationTemplatesViewQueryHookResult = ReturnType<
  typeof useConfigurationTemplatesViewQuery
>;
export type ConfigurationTemplatesViewLazyQueryHookResult = ReturnType<
  typeof useConfigurationTemplatesViewLazyQuery
>;
export type ConfigurationTemplatesViewQueryResult = Apollo.QueryResult<
  ConfigurationTemplatesViewQuery,
  ConfigurationTemplatesViewQueryVariables
>;
export const GetConfigurationsTemplateDocument = gql`
  query GetConfigurationsTemplate($ID: ConfigurationId!) {
    configurationTemplate(input: { id: $ID }) {
      ... on ConfigurationTemplateResult {
        configurationTemplate {
          ...ConfigurationTemplate
        }
      }
    }
  }
  ${ConfigurationTemplateFragmentDoc}
`;

/**
 * __useGetConfigurationsTemplateQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsTemplateQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetConfigurationsTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConfigurationsTemplateQuery,
    GetConfigurationsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConfigurationsTemplateQuery,
    GetConfigurationsTemplateQueryVariables
  >(GetConfigurationsTemplateDocument, options);
}
export function useGetConfigurationsTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConfigurationsTemplateQuery,
    GetConfigurationsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConfigurationsTemplateQuery,
    GetConfigurationsTemplateQueryVariables
  >(GetConfigurationsTemplateDocument, options);
}
export type GetConfigurationsTemplateQueryHookResult = ReturnType<
  typeof useGetConfigurationsTemplateQuery
>;
export type GetConfigurationsTemplateLazyQueryHookResult = ReturnType<
  typeof useGetConfigurationsTemplateLazyQuery
>;
export type GetConfigurationsTemplateQueryResult = Apollo.QueryResult<
  GetConfigurationsTemplateQuery,
  GetConfigurationsTemplateQueryVariables
>;
export const ListConfigurationsTemplatesDocument = gql`
  query ListConfigurationsTemplates {
    configurationTemplates {
      ... on ConfigurationTemplatesResult {
        configurationTemplates {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useListConfigurationsTemplatesQuery__
 *
 * To run a query within a React component, call `useListConfigurationsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConfigurationsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConfigurationsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListConfigurationsTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListConfigurationsTemplatesQuery,
    ListConfigurationsTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListConfigurationsTemplatesQuery,
    ListConfigurationsTemplatesQueryVariables
  >(ListConfigurationsTemplatesDocument, options);
}
export function useListConfigurationsTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListConfigurationsTemplatesQuery,
    ListConfigurationsTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListConfigurationsTemplatesQuery,
    ListConfigurationsTemplatesQueryVariables
  >(ListConfigurationsTemplatesDocument, options);
}
export type ListConfigurationsTemplatesQueryHookResult = ReturnType<
  typeof useListConfigurationsTemplatesQuery
>;
export type ListConfigurationsTemplatesLazyQueryHookResult = ReturnType<
  typeof useListConfigurationsTemplatesLazyQuery
>;
export type ListConfigurationsTemplatesQueryResult = Apollo.QueryResult<
  ListConfigurationsTemplatesQuery,
  ListConfigurationsTemplatesQueryVariables
>;
export const RequestTemplatesViewDocument = gql`
  query RequestTemplatesView {
    requestTemplatesWithFields(input: { fetchAll: true }) {
      __typename
      ... on RequestTemplatesWithFieldsResult {
        requestTemplates {
          id
          name
          customFields {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useRequestTemplatesViewQuery__
 *
 * To run a query within a React component, call `useRequestTemplatesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestTemplatesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestTemplatesViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useRequestTemplatesViewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RequestTemplatesViewQuery,
    RequestTemplatesViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestTemplatesViewQuery,
    RequestTemplatesViewQueryVariables
  >(RequestTemplatesViewDocument, options);
}
export function useRequestTemplatesViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestTemplatesViewQuery,
    RequestTemplatesViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestTemplatesViewQuery,
    RequestTemplatesViewQueryVariables
  >(RequestTemplatesViewDocument, options);
}
export type RequestTemplatesViewQueryHookResult = ReturnType<
  typeof useRequestTemplatesViewQuery
>;
export type RequestTemplatesViewLazyQueryHookResult = ReturnType<
  typeof useRequestTemplatesViewLazyQuery
>;
export type RequestTemplatesViewQueryResult = Apollo.QueryResult<
  RequestTemplatesViewQuery,
  RequestTemplatesViewQueryVariables
>;
export const ConfigTemplateTagsDocument = gql`
  query ConfigTemplateTags {
    configTemplateTags {
      ... on ConfigTemplateTagsResult {
        configTemplateTags {
          id
          priority
          configTemplate {
            id
            name
          }
          tag {
            ...TagPreviewSmall
          }
        }
      }
    }
  }
  ${TagPreviewSmallFragmentDoc}
`;

/**
 * __useConfigTemplateTagsQuery__
 *
 * To run a query within a React component, call `useConfigTemplateTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigTemplateTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigTemplateTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigTemplateTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConfigTemplateTagsQuery,
    ConfigTemplateTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConfigTemplateTagsQuery,
    ConfigTemplateTagsQueryVariables
  >(ConfigTemplateTagsDocument, options);
}
export function useConfigTemplateTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConfigTemplateTagsQuery,
    ConfigTemplateTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConfigTemplateTagsQuery,
    ConfigTemplateTagsQueryVariables
  >(ConfigTemplateTagsDocument, options);
}
export type ConfigTemplateTagsQueryHookResult = ReturnType<
  typeof useConfigTemplateTagsQuery
>;
export type ConfigTemplateTagsLazyQueryHookResult = ReturnType<
  typeof useConfigTemplateTagsLazyQuery
>;
export type ConfigTemplateTagsQueryResult = Apollo.QueryResult<
  ConfigTemplateTagsQuery,
  ConfigTemplateTagsQueryVariables
>;
export const CreateConfigurationTemplateDocument = gql`
  mutation CreateConfigurationTemplate(
    $input: CreateConfigurationTemplateInput!
  ) {
    createConfigurationTemplate(input: $input) {
      ... on ConfigurationTemplateNameExistsError {
        message
      }
      ... on CreateConfigurationTemplateResult {
        configurationTemplate {
          ...ConfigurationTemplate
        }
      }
    }
  }
  ${ConfigurationTemplateFragmentDoc}
`;
export type CreateConfigurationTemplateMutationFn = Apollo.MutationFunction<
  CreateConfigurationTemplateMutation,
  CreateConfigurationTemplateMutationVariables
>;

/**
 * __useCreateConfigurationTemplateMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationTemplateMutation, { data, loading, error }] = useCreateConfigurationTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConfigurationTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConfigurationTemplateMutation,
    CreateConfigurationTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConfigurationTemplateMutation,
    CreateConfigurationTemplateMutationVariables
  >(CreateConfigurationTemplateDocument, options);
}
export type CreateConfigurationTemplateMutationHookResult = ReturnType<
  typeof useCreateConfigurationTemplateMutation
>;
export type CreateConfigurationTemplateMutationResult = Apollo.MutationResult<
  CreateConfigurationTemplateMutation
>;
export type CreateConfigurationTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateConfigurationTemplateMutation,
  CreateConfigurationTemplateMutationVariables
>;
export const DeleteConfigurationTemplateDocument = gql`
  mutation DeleteConfigurationTemplate($id: ConfigurationId!) {
    deleteConfigurationTemplate(input: { id: $id }) {
      ... on ConfigurationInUseError {
        message
      }
      ... on DeleteConfigurationTemplateResult {
        id
      }
    }
  }
`;
export type DeleteConfigurationTemplateMutationFn = Apollo.MutationFunction<
  DeleteConfigurationTemplateMutation,
  DeleteConfigurationTemplateMutationVariables
>;

/**
 * __useDeleteConfigurationTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteConfigurationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfigurationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfigurationTemplateMutation, { data, loading, error }] = useDeleteConfigurationTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConfigurationTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConfigurationTemplateMutation,
    DeleteConfigurationTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteConfigurationTemplateMutation,
    DeleteConfigurationTemplateMutationVariables
  >(DeleteConfigurationTemplateDocument, options);
}
export type DeleteConfigurationTemplateMutationHookResult = ReturnType<
  typeof useDeleteConfigurationTemplateMutation
>;
export type DeleteConfigurationTemplateMutationResult = Apollo.MutationResult<
  DeleteConfigurationTemplateMutation
>;
export type DeleteConfigurationTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteConfigurationTemplateMutation,
  DeleteConfigurationTemplateMutationVariables
>;
export const UpdateConfigTemplateTagsDocument = gql`
  mutation UpdateConfigTemplateTags($input: UpdateConfigTemplateTagsInput!) {
    updateConfigTemplateTags(input: $input) {
      ... on UpdateConfigTemplateTagsResult {
        success
      }
    }
  }
`;
export type UpdateConfigTemplateTagsMutationFn = Apollo.MutationFunction<
  UpdateConfigTemplateTagsMutation,
  UpdateConfigTemplateTagsMutationVariables
>;

/**
 * __useUpdateConfigTemplateTagsMutation__
 *
 * To run a mutation, you first call `useUpdateConfigTemplateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigTemplateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigTemplateTagsMutation, { data, loading, error }] = useUpdateConfigTemplateTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigTemplateTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigTemplateTagsMutation,
    UpdateConfigTemplateTagsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConfigTemplateTagsMutation,
    UpdateConfigTemplateTagsMutationVariables
  >(UpdateConfigTemplateTagsDocument, options);
}
export type UpdateConfigTemplateTagsMutationHookResult = ReturnType<
  typeof useUpdateConfigTemplateTagsMutation
>;
export type UpdateConfigTemplateTagsMutationResult = Apollo.MutationResult<
  UpdateConfigTemplateTagsMutation
>;
export type UpdateConfigTemplateTagsMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigTemplateTagsMutation,
  UpdateConfigTemplateTagsMutationVariables
>;
export const UpdateConfigurationTemplateDocument = gql`
  mutation UpdateConfigurationTemplate(
    $input: UpdateConfigurationTemplateInput!
  ) {
    updateConfigurationTemplate(input: $input) {
      ... on ConfigurationTemplateNameExistsError {
        message
      }
      ... on UpdateConfigurationTemplateResult {
        configurationTemplate {
          ...ConfigurationTemplate
        }
      }
    }
  }
  ${ConfigurationTemplateFragmentDoc}
`;
export type UpdateConfigurationTemplateMutationFn = Apollo.MutationFunction<
  UpdateConfigurationTemplateMutation,
  UpdateConfigurationTemplateMutationVariables
>;

/**
 * __useUpdateConfigurationTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationTemplateMutation, { data, loading, error }] = useUpdateConfigurationTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigurationTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigurationTemplateMutation,
    UpdateConfigurationTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateConfigurationTemplateMutation,
    UpdateConfigurationTemplateMutationVariables
  >(UpdateConfigurationTemplateDocument, options);
}
export type UpdateConfigurationTemplateMutationHookResult = ReturnType<
  typeof useUpdateConfigurationTemplateMutation
>;
export type UpdateConfigurationTemplateMutationResult = Apollo.MutationResult<
  UpdateConfigurationTemplateMutation
>;
export type UpdateConfigurationTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigurationTemplateMutation,
  UpdateConfigurationTemplateMutationVariables
>;
export const ManagersDocument = gql`
  query Managers($searchQuery: String, $cursor: String) {
    users(
      input: {
        searchQuery: $searchQuery
        includeOnlyManagers: true
        cursor: $cursor
      }
    ) {
      ... on UsersResult {
        users {
          id
          fullName
          avatarUrl
        }
        cursor
      }
    }
  }
`;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(
    ManagersDocument,
    options
  );
}
export function useManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagersQuery,
    ManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(
    ManagersDocument,
    options
  );
}
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<
  typeof useManagersLazyQuery
>;
export type ManagersQueryResult = Apollo.QueryResult<
  ManagersQuery,
  ManagersQueryVariables
>;
export const TeamsDocument = gql`
  query Teams($searchQuery: String, $cursor: String) {
    teams(input: { searchQuery: $searchQuery, cursor: $cursor }) {
      ... on TeamsResult {
        teams {
          name
          numUsers
        }
        cursor
      }
    }
  }
`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options
  );
}
export function useTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options
  );
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<
  TeamsQuery,
  TeamsQueryVariables
>;
export const TitlesDocument = gql`
  query Titles($searchQuery: String, $cursor: String) {
    titles(input: { searchQuery: $searchQuery, cursor: $cursor }) {
      ... on TitlesResult {
        titles {
          name
          numUsers
        }
        cursor
      }
    }
  }
`;

/**
 * __useTitlesQuery__
 *
 * To run a query within a React component, call `useTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitlesQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useTitlesQuery(
  baseOptions?: Apollo.QueryHookOptions<TitlesQuery, TitlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TitlesQuery, TitlesQueryVariables>(
    TitlesDocument,
    options
  );
}
export function useTitlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TitlesQuery, TitlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TitlesQuery, TitlesQueryVariables>(
    TitlesDocument,
    options
  );
}
export type TitlesQueryHookResult = ReturnType<typeof useTitlesQuery>;
export type TitlesLazyQueryHookResult = ReturnType<typeof useTitlesLazyQuery>;
export type TitlesQueryResult = Apollo.QueryResult<
  TitlesQuery,
  TitlesQueryVariables
>;
export const UserDetailDocument = gql`
  query UserDetail($id: UserId!) {
    user(input: { id: $id }) {
      ... on UserResult {
        user {
          ...UserOverview
        }
      }
      ... on UserNotFoundError {
        message
        email
      }
    }
    userAttributes: userTags(input: { userId: $id }) {
      ... on UserTagsResult {
        userTags {
          ...UserTag
        }
      }
    }
  }
  ${UserOverviewFragmentDoc}
  ${UserTagFragmentDoc}
`;

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserDetailQuery,
    UserDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserDetailQuery, UserDetailQueryVariables>(
    UserDetailDocument,
    options
  );
}
export function useUserDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserDetailQuery,
    UserDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(
    UserDetailDocument,
    options
  );
}
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<
  typeof useUserDetailLazyQuery
>;
export type UserDetailQueryResult = Apollo.QueryResult<
  UserDetailQuery,
  UserDetailQueryVariables
>;
export const UserResourcesDocument = gql`
  query UserResources($id: UserId!) {
    user(input: { id: $id }) {
      ... on UserResult {
        user {
          ...UserPreviewSmall
          userResources {
            ...UserResource
          }
        }
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
  ${UserResourceFragmentDoc}
`;

/**
 * __useUserResourcesQuery__
 *
 * To run a query within a React component, call `useUserResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserResourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserResourcesQuery,
    UserResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserResourcesQuery, UserResourcesQueryVariables>(
    UserResourcesDocument,
    options
  );
}
export function useUserResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserResourcesQuery,
    UserResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserResourcesQuery, UserResourcesQueryVariables>(
    UserResourcesDocument,
    options
  );
}
export type UserResourcesQueryHookResult = ReturnType<
  typeof useUserResourcesQuery
>;
export type UserResourcesLazyQueryHookResult = ReturnType<
  typeof useUserResourcesLazyQuery
>;
export type UserResourcesQueryResult = Apollo.QueryResult<
  UserResourcesQuery,
  UserResourcesQueryVariables
>;
export const UserGroupsDocument = gql`
  query UserGroups($id: UserId!) {
    user(input: { id: $id }) {
      ... on UserResult {
        user {
          ...UserPreviewSmall
          userGroups {
            ...UserGroup
          }
        }
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
  ${UserGroupFragmentDoc}
`;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options
  );
}
export function useUserGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserGroupsQuery,
    UserGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(
    UserGroupsDocument,
    options
  );
}
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<
  typeof useUserGroupsLazyQuery
>;
export type UserGroupsQueryResult = Apollo.QueryResult<
  UserGroupsQuery,
  UserGroupsQueryVariables
>;
export const UsersTableDocument = gql`
  query UsersTable($input: UsersInput!) {
    users(input: $input) {
      __typename
      ... on UsersResult {
        users {
          id
          email
          fullName
          avatarUrl
          isDeleted
          isSystemUser
          createdAt
          position
          teamAttr
          manager {
            ...UserPreviewSmall
          }
          hrIdpStatus
          numResources
          numGroups
        }
        cursor
        totalNumUsers
      }
    }
  }
  ${UserPreviewSmallFragmentDoc}
`;

/**
 * __useUsersTableQuery__
 *
 * To run a query within a React component, call `useUsersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersTableQuery,
    UsersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersTableQuery, UsersTableQueryVariables>(
    UsersTableDocument,
    options
  );
}
export function useUsersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersTableQuery,
    UsersTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersTableQuery, UsersTableQueryVariables>(
    UsersTableDocument,
    options
  );
}
export type UsersTableQueryHookResult = ReturnType<typeof useUsersTableQuery>;
export type UsersTableLazyQueryHookResult = ReturnType<
  typeof useUsersTableLazyQuery
>;
export type UsersTableQueryResult = Apollo.QueryResult<
  UsersTableQuery,
  UsersTableQueryVariables
>;
export const namedOperations = {
  Query: {
    AccessReviewConnection: "AccessReviewConnection",
    AccessReviewConnections: "AccessReviewConnections",
    AccessReviewFilteredConnections: "AccessReviewFilteredConnections",
    AccessReviewGroup: "AccessReviewGroup",
    AccessReviewGroups: "AccessReviewGroups",
    AccessReviewResource: "AccessReviewResource",
    AccessReviewResources: "AccessReviewResources",
    AccessReviewStats: "AccessReviewStats",
    OngoingAccessReviewTabStats: "OngoingAccessReviewTabStats",
    OngoingAccessReviewSubtabStats: "OngoingAccessReviewSubtabStats",
    AccessReviewTemplates: "AccessReviewTemplates",
    AccessReviewUser: "AccessReviewUser",
    AccessReviewUsers: "AccessReviewUsers",
    AccessReview: "AccessReview",
    AccessReviews: "AccessReviews",
    AccessReviewsNoStats: "AccessReviewsNoStats",
    AccessReviewRevocations: "AccessReviewRevocations",
    InitUnauthedApp: "InitUnauthedApp",
    SamlConnection: "SamlConnection",
    SignInMethod: "SignInMethod",
    FirstPartyTokens: "FirstPartyTokens",
    AuthSessionStatus: "AuthSessionStatus",
    Connections: "Connections",
    ConnectionPreview: "ConnectionPreview",
    EventFilters: "EventFilters",
    EventFilter: "EventFilter",
    Events: "Events",
    EventsFull: "EventsFull",
    Event: "Event",
    GroupAccessLevels: "GroupAccessLevels",
    MultipleGroupAccessLevels: "MultipleGroupAccessLevels",
    GroupsHome: "GroupsHome",
    Group: "Group",
    GroupPreview: "GroupPreview",
    GroupPreviewWithAccessLevels: "GroupPreviewWithAccessLevels",
    FilteredGroupsLarge: "FilteredGroupsLarge",
    IdpConnection: "IdpConnection",
    MessageChannels: "MessageChannels",
    ListFactors: "ListFactors",
    Notifications: "Notifications",
    OnCallSchedules: "OnCallSchedules",
    OrganizationSettings: "OrganizationSettings",
    WebhookInfo: "WebhookInfo",
    OwnersList: "OwnersList",
    Owner: "Owner",
    PushTask: "PushTask",
    RequestTemplates: "RequestTemplates",
    RequestTemplatesWithFields: "RequestTemplatesWithFields",
    RequestTemplate: "RequestTemplate",
    Requests: "Requests",
    Request: "Request",
    ResourceAccessLevels: "ResourceAccessLevels",
    MultipleResourceAccessLevels: "MultipleResourceAccessLevels",
    ResourceCustomAccessLevel: "ResourceCustomAccessLevel",
    ResourceCustomAccessLevels: "ResourceCustomAccessLevels",
    ResourceTags: "ResourceTags",
    ResourceUsage: "ResourceUsage",
    ResourcesHome: "ResourcesHome",
    NonHumanIdentityResources: "NonHumanIdentityResources",
    FilteredResourcesLarge: "FilteredResourcesLarge",
    Resource: "Resource",
    ResourcePreview: "ResourcePreview",
    ResourcePreviewWithAccessLevels: "ResourcePreviewWithAccessLevels",
    RemoteResources: "RemoteResources",
    Search: "Search",
    Sessions: "Sessions",
    VaultSessions: "VaultSessions",
    Tickets: "Tickets",
    TicketProjects: "TicketProjects",
    Ticket: "Ticket",
    SyncStatus: "SyncStatus",
    SyncTask: "SyncTask",
    Tags: "Tags",
    Tag: "Tag",
    ThirdPartyIntegration: "ThirdPartyIntegration",
    ThirdPartyIntegrations: "ThirdPartyIntegrations",
    UserSettings: "UserSettings",
    Users: "Users",
    UsersFull: "UsersFull",
    User: "User",
    UsersHome: "UsersHome",
    AccessReviewGroupsStats: "AccessReviewGroupsStats",
    AccessReviewResourcesStats: "AccessReviewResourcesStats",
    AccessReviewConnectionsStats: "AccessReviewConnectionsStats",
    AllowedToUseAldwin: "AllowedToUseAldwin",
    AllowedToCreateOrg: "AllowedToCreateOrg",
    AWSRoleCreation: "AWSRoleCreation",
    PaginatedAppDropdown: "PaginatedAppDropdown",
    PaginatedEntityDropdown: "PaginatedEntityDropdown",
    PaginatedGroupBindingDropdown: "PaginatedGroupBindingDropdown",
    PaginatedGroupDropdown: "PaginatedGroupDropdown",
    PaginatedResourceDropdown: "PaginatedResourceDropdown",
    PaginatedUserDropdown: "PaginatedUserDropdown",
    PaginatedUserOrGroupDropdown: "PaginatedUserOrGroupDropdown",
    UserOrGroupDropdown: "UserOrGroupDropdown",
    SearchUsers: "SearchUsers",
    SearchResources: "SearchResources",
    SearchGroups: "SearchGroups",
    SearchTypes: "SearchTypes",
    AppRow: "AppRow",
    GroupsPreview: "GroupsPreview",
    LeftSidebarRoutes: "LeftSidebarRoutes",
    UserMenu: "UserMenu",
    SlackIntegrationWorkspaces: "SlackIntegrationWorkspaces",
    GroupActiveRequestConfiguration: "GroupActiveRequestConfiguration",
    ResourceActiveRequestConfiguration: "ResourceActiveRequestConfiguration",
    GroupDetailsModal: "GroupDetailsModal",
    ResourceDetailsModal: "ResourceDetailsModal",
    AccessRequestGroupCard: "AccessRequestGroupCard",
    AccessRequestResourceCard: "AccessRequestResourceCard",
    AccessRequestOktaAppCard: "AccessRequestOktaAppCard",
    ConnectionsSummary: "ConnectionsSummary",
    GroupTypesWithCounts: "GroupTypesWithCounts",
    ResourceTypesWithCounts: "ResourceTypesWithCounts",
    VisualizationData: "VisualizationData",
    AppPreview: "AppPreview",
    RequestDefaults: "RequestDefaults",
    AccessReviewUserAssignments: "AccessReviewUserAssignments",
    AccessReviewGroupResourceAssignments:
      "AccessReviewGroupResourceAssignments",
    AccessReviewResourcePrincipalAssignments:
      "AccessReviewResourcePrincipalAssignments",
    AccessReviewChanges: "AccessReviewChanges",
    AccessReviewChangeDetails: "AccessReviewChangeDetails",
    AccessReviewChangesWithDetails: "AccessReviewChangesWithDetails",
    AccessReviewEvents: "AccessReviewEvents",
    AccessReviewGroupDetails: "AccessReviewGroupDetails",
    MyAccessReviewItemsV3: "MyAccessReviewItemsV3",
    AccessReviewReviewers: "AccessReviewReviewers",
    AccessReviewReviewer: "AccessReviewReviewer",
    MyAccessReviewItems: "MyAccessReviewItems",
    AccessReviewItems: "AccessReviewItems",
    ResourceMoreInfo: "ResourceMoreInfo",
    AccessReviewTemplate: "AccessReviewTemplate",
    AccessReviewProposedChanges: "AccessReviewProposedChanges",
    AWSAccountsColumn: "AWSAccountsColumn",
    AWSAllAccountsColumn: "AWSAllAccountsColumn",
    AccountDetailColumn: "AccountDetailColumn",
    AppDetailColumnHeader: "AppDetailColumnHeader",
    AppDetailColumn: "AppDetailColumn",
    AppDetailColumnForItems: "AppDetailColumnForItems",
    AppDetailColumnForResource: "AppDetailColumnForResource",
    AppDetailColumnForGroup: "AppDetailColumnForGroup",
    AppResources: "AppResources",
    AppsBulkEdit: "AppsBulkEdit",
    AppsListColumn: "AppsListColumn",
    BulkActiveResourceRequestConfigs: "BulkActiveResourceRequestConfigs",
    BulkActiveGroupRequestConfigs: "BulkActiveGroupRequestConfigs",
    BulkRequestColumnResources: "BulkRequestColumnResources",
    BulkRequestColumnGroups: "BulkRequestColumnGroups",
    GroupAddGroups: "GroupAddGroups",
    GroupAddResources: "GroupAddResources",
    GroupAddUsers: "GroupAddUsers",
    ImportItemsListColumn: "ImportItemsListColumn",
    RemoteItemsList: "RemoteItemsList",
    ItemsListSection: "ItemsListSection",
    ItemsListSectionImport: "ItemsListSectionImport",
    ResourceAddGroups: "ResourceAddGroups",
    ResourceAddPrincipals: "ResourceAddPrincipals",
    ResourceAddUsers: "ResourceAddUsers",
    ResourceDetailColumn: "ResourceDetailColumn",
    ResourceDetailView: "ResourceDetailView",
    ResourceDetailPaginatedResourceUsers:
      "ResourceDetailPaginatedResourceUsers",
    ResourceEditView: "ResourceEditView",
    ResourceAncestors: "ResourceAncestors",
    UserAddResources: "UserAddResources",
    AppDetails: "AppDetails",
    AppEndUserResources: "AppEndUserResources",
    Suggestions: "Suggestions",
    Bundle: "Bundle",
    Bundles: "Bundles",
    BundleCatalog: "BundleCatalog",
    BundleItems: "BundleItems",
    ConnectSessionView: "ConnectSessionView",
    ConnectionAppIcon: "ConnectionAppIcon",
    ConnectionUsersRow: "ConnectionUsersRow",
    EventsTable: "EventsTable",
    EventPreview: "EventPreview",
    EventDetail: "EventDetail",
    GroupBindingDetails: "GroupBindingDetails",
    GroupBindingSuggestionsTable: "GroupBindingSuggestionsTable",
    GroupBindingsCounts: "GroupBindingsCounts",
    GroupBindingsTable: "GroupBindingsTable",
    GroupBindingsFilters: "GroupBindingsFilters",
    GroupBindingCompareModal: "GroupBindingCompareModal",
    GroupBindingGroupUserIds: "GroupBindingGroupUserIds",
    MetricsFilters: "MetricsFilters",
    BreakGlassUsersCounts: "BreakGlassUsersCounts",
    BreakGlassUsersByGroup: "BreakGlassUsersByGroup",
    RequestMetrics: "RequestMetrics",
    RequestApprovalTimes: "RequestApprovalTimes",
    AccessGrantedTimes: "AccessGrantedTimes",
    MostRequestedResources: "MostRequestedResources",
    MostRequestedGroups: "MostRequestedGroups",
    ClosedRequestMetrics: "ClosedRequestMetrics",
    OwnersTable: "OwnersTable",
    RiskScoreGraphData: "RiskScoreGraphData",
    EntitiesForRiskScoreRange: "EntitiesForRiskScoreRange",
    RecommendationsMetricsGraph: "RecommendationsMetricsGraph",
    TopBulkSuggestions: "TopBulkSuggestions",
    appThreatCounts: "appThreatCounts",
    EntityForRemediation: "EntityForRemediation",
    SuggestedAccessToRemediateForEntity: "SuggestedAccessToRemediateForEntity",
    NumRequestsToReview: "NumRequestsToReview",
    RequestStats: "RequestStats",
    RequestsTable: "RequestsTable",
    UserSkipManager: "UserSkipManager",
    GroupGroupAccess: "GroupGroupAccess",
    ImportResourcesBody: "ImportResourcesBody",
    ResourceVisibilityRow: "ResourceVisibilityRow",
    GroupOnCallSchedules: "GroupOnCallSchedules",
    ListEventStreams: "ListEventStreams",
    OidcProvider: "OidcProvider",
    SetupState: "SetupState",
    TagsTable: "TagsTable",
    ConfigurationTemplatesView: "ConfigurationTemplatesView",
    GetConfigurationsTemplate: "GetConfigurationsTemplate",
    ListConfigurationsTemplates: "ListConfigurationsTemplates",
    RequestTemplatesView: "RequestTemplatesView",
    ConfigTemplateTags: "ConfigTemplateTags",
    Managers: "Managers",
    Teams: "Teams",
    Titles: "Titles",
    UserDetail: "UserDetail",
    UserResources: "UserResources",
    UserGroups: "UserGroups",
    UsersTable: "UsersTable",
  },
  Mutation: {
    UpdateConnectionReviewers: "UpdateConnectionReviewers",
    UpdateConnectionUserReviewers: "UpdateConnectionUserReviewers",
    UpdateGroupResourceReviewers: "UpdateGroupResourceReviewers",
    UpdateAccessReviewGroupReviewers: "UpdateAccessReviewGroupReviewers",
    UpdateGroupUserReviewers: "UpdateGroupUserReviewers",
    UpdateAccessReviewResourceReviewers: "UpdateAccessReviewResourceReviewers",
    UpdateResourceUserReviewers: "UpdateResourceUserReviewers",
    ConfirmEndSystemRevocation: "ConfirmEndSystemRevocation",
    UpdateAccessReview: "UpdateAccessReview",
    StopAccessReview: "StopAccessReview",
    SetNote: "SetNote",
    PerformReview: "PerformReview",
    SendAccessReviewReminder: "SendAccessReviewReminder",
    RegenerateAccessReviewReport: "RegenerateAccessReviewReport",
    ResetDataseed: "ResetDataseed",
    RescoreRecommendations: "RescoreRecommendations",
    ResetRemediations: "ResetRemediations",
    SetRoles: "SetRoles",
    AddBigData: "AddBigData",
    AuthCodeCallback: "AuthCodeCallback",
    MfaCallback: "MfaCallback",
    SignIn: "SignIn",
    SignOut: "SignOut",
    CreateSamlConnection: "CreateSamlConnection",
    UpdateSamlConnection: "UpdateSamlConnection",
    DeleteSamlConnection: "DeleteSamlConnection",
    CreateFirstPartyToken: "CreateFirstPartyToken",
    DeleteFirstPartyTokens: "DeleteFirstPartyTokens",
    AssumeImpersonation: "AssumeImpersonation",
    StopImpersonation: "StopImpersonation",
    CreateAuthProviderApplication: "CreateAuthProviderApplication",
    AddConnectionUsers: "AddConnectionUsers",
    RemoveConnectionUsers: "RemoveConnectionUsers",
    CreateConnection: "CreateConnection",
    CreateConnectionForIdp: "CreateConnectionForIdp",
    UpdateConnection: "UpdateConnection",
    DeleteConnection: "DeleteConnection",
    CreateEventFilter: "CreateEventFilter",
    DeleteEventFilter: "DeleteEventFilter",
    UseGroupBreakGlass: "UseGroupBreakGlass",
    AddGroupGroups: "AddGroupGroups",
    RemoveGroupGroups: "RemoveGroupGroups",
    AddGroupResources: "AddGroupResources",
    RemoveGroupResources: "RemoveGroupResources",
    AddGroupTags: "AddGroupTags",
    RemoveGroupTags: "RemoveGroupTags",
    AddGroupUsers: "AddGroupUsers",
    UpdateGroupUsers: "UpdateGroupUsers",
    RemoveGroupUsers: "RemoveGroupUsers",
    ForfeitGroup: "ForfeitGroup",
    CreateGroup: "CreateGroup",
    UpdateGroup: "UpdateGroup",
    DeleteGroup: "DeleteGroup",
    DeleteGroups: "DeleteGroups",
    RecordGroupView: "RecordGroupView",
    CreateIdpConnection: "CreateIdpConnection",
    CreateIdpConnectionUserAttributeImportMapping:
      "CreateIdpConnectionUserAttributeImportMapping",
    DeleteIdpConnectionUserAttributeImportMapping:
      "DeleteIdpConnectionUserAttributeImportMapping",
    DeleteIdpConnection: "DeleteIdpConnection",
    CreateRemoteMessageChannel: "CreateRemoteMessageChannel",
    LinkExistingRemoteMessageChannel: "LinkExistingRemoteMessageChannel",
    VerifyFactorSync: "VerifyFactorSync",
    VerifyFactorAsync: "VerifyFactorAsync",
    ReadUINotification: "ReadUINotification",
    ReadUINotifications: "ReadUINotifications",
    UpdateOrganizationSettings: "UpdateOrganizationSettings",
    CreateWebhookInfo: "CreateWebhookInfo",
    UpdateWebhookInfo: "UpdateWebhookInfo",
    RegenerateSigningSecret: "RegenerateSigningSecret",
    DeleteWebhookInfo: "DeleteWebhookInfo",
    CreateOrganization: "CreateOrganization",
    DeleteOrganization: "DeleteOrganization",
    CreateOwner: "CreateOwner",
    UpdateOwner: "UpdateOwner",
    DeleteOwner: "DeleteOwner",
    UpdateOwnerUsersForOwner: "UpdateOwnerUsersForOwner",
    RemediateAddResourceUsers: "RemediateAddResourceUsers",
    RemediateAddGroupResources: "RemediateAddGroupResources",
    RemediateAddGroupUsers: "RemediateAddGroupUsers",
    RemediateAddRoleAssignments: "RemediateAddRoleAssignments",
    CreateRequestTemplate: "CreateRequestTemplate",
    UpdateRequestTemplate: "UpdateRequestTemplate",
    DeleteRequestTemplate: "DeleteRequestTemplate",
    CreateRequest: "CreateRequest",
    ApproveRequest: "ApproveRequest",
    DenyRequest: "DenyRequest",
    CancelRequest: "CancelRequest",
    CreateRequestComment: "CreateRequestComment",
    SendManualRequestReminder: "SendManualRequestReminder",
    CreateResourceCustomAccessLevel: "CreateResourceCustomAccessLevel",
    CreateResourceCustomAccessLevels: "CreateResourceCustomAccessLevels",
    UpdateResourceCustomAccessLevel: "UpdateResourceCustomAccessLevel",
    DeleteResourceCustomAccessLevels: "DeleteResourceCustomAccessLevels",
    AddResourceTags: "AddResourceTags",
    RemoveResourceTags: "RemoveResourceTags",
    AddResourceUsers: "AddResourceUsers",
    RemoveResourceUsers: "RemoveResourceUsers",
    UpdateUserResources: "UpdateUserResources",
    RemoveUserResources: "RemoveUserResources",
    ForfeitResource: "ForfeitResource",
    CreateResource: "CreateResource",
    CreateResources: "CreateResources",
    UpdateResources: "UpdateResources",
    DeleteResources: "DeleteResources",
    ImportCustomResourcesDryRun: "ImportCustomResourcesDryRun",
    ImportCustomResources: "ImportCustomResources",
    UpdateResourceVisibilityGroups: "UpdateResourceVisibilityGroups",
    RecordResourceView: "RecordResourceView",
    AddRoleAssignments: "AddRoleAssignments",
    RemoveRoleAssignments: "RemoveRoleAssignments",
    CreateSession: "CreateSession",
    StartSync: "StartSync",
    DismissSyncErrors: "DismissSyncErrors",
    CreateTag: "CreateTag",
    DeleteTags: "DeleteTags",
    InitThirdPartyIntegrationAuthFlow: "InitThirdPartyIntegrationAuthFlow",
    CreateThirdPartyIntegration: "CreateThirdPartyIntegration",
    DeleteThirdPartyIntegration: "DeleteThirdPartyIntegration",
    InitThirdPartyIntegrationLinkToken: "InitThirdPartyIntegrationLinkToken",
    UpdateUserSettings: "UpdateUserSettings",
    AddUserTags: "AddUserTags",
    RemoveUserTags: "RemoveUserTags",
    DeleteUser: "DeleteUser",
    UpdateUser: "UpdateUser",
    UpdateUserManager: "UpdateUserManager",
    InviteUsers: "InviteUsers",
    ResetUserMFA: "ResetUserMFA",
    CreateRequestProposal: "CreateRequestProposal",
    ClearResourceUserPropagationStatus: "ClearResourceUserPropagationStatus",
    MarkGroupUserPropagationAsSuccess: "MarkGroupUserPropagationAsSuccess",
    UpdateAccessReviewReviewers: "UpdateAccessReviewReviewers",
    StartAccessReview: "StartAccessReview",
    CreateAccessReviewTemplate: "CreateAccessReviewTemplate",
    UpdateAccessReviewTemplate: "UpdateAccessReviewTemplate",
    DeleteAccessReviewTemplates: "DeleteAccessReviewTemplates",
    BulkImport: "BulkImport",
    BulkUpdate: "BulkUpdate",
    CreateBundle: "CreateBundle",
    UpdateBundle: "UpdateBundle",
    DeleteBundle: "DeleteBundle",
    CreateBundleResources: "CreateBundleResources",
    DeleteBundleResources: "DeleteBundleResources",
    CreateBundleGroups: "CreateBundleGroups",
    DeleteBundleGroups: "DeleteBundleGroups",
    CreateGroupBindings: "CreateGroupBindings",
    DismissGroupBindingSuggestions: "DismissGroupBindingSuggestions",
    DeleteGroupBindings: "DeleteGroupBindings",
    UpdateGroupBindings: "UpdateGroupBindings",
    RemoveGroupResourcesAndGroups: "RemoveGroupResourcesAndGroups",
    DismissRecommendationsSubscores: "DismissRecommendationsSubscores",
    RescoreEntity: "RescoreEntity",
    LogEntityRemediation: "LogEntityRemediation",
    EscalateRequestToSkipManager: "EscalateRequestToSkipManager",
    CreateEventStream: "CreateEventStream",
    InitOidcAuthFlow: "InitOidcAuthFlow",
    ValidateCodeAndSaveIdToken: "ValidateCodeAndSaveIdToken",
    InitOidcAuthFlowPresigned: "InitOidcAuthFlowPresigned",
    ValidateCodeAndSaveIdTokenPresigned: "ValidateCodeAndSaveIdTokenPresigned",
    CreateOrUpdateOidcProvider: "CreateOrUpdateOidcProvider",
    ImportAll: "ImportAll",
    CreateConfigurationTemplate: "CreateConfigurationTemplate",
    DeleteConfigurationTemplate: "DeleteConfigurationTemplate",
    UpdateConfigTemplateTags: "UpdateConfigTemplateTags",
    UpdateConfigurationTemplate: "UpdateConfigurationTemplate",
  },
  Fragment: {
    Access: "Access",
    AccessPoint: "AccessPoint",
    AccessLevel: "AccessLevel",
    AccessReviewConnectionUser: "AccessReviewConnectionUser",
    AccessReviewConnectionUserPreview: "AccessReviewConnectionUserPreview",
    AccessReviewUserConnectionPreview: "AccessReviewUserConnectionPreview",
    AccessReviewConnectionUserWarning: "AccessReviewConnectionUserWarning",
    AccessReviewConnectionPreview: "AccessReviewConnectionPreview",
    AccessReviewConnectionReviewerStatus:
      "AccessReviewConnectionReviewerStatus",
    AccessReviewConnection: "AccessReviewConnection",
    AccessReviewGroupResource: "AccessReviewGroupResource",
    AccessReviewGroupUser: "AccessReviewGroupUser",
    AccessReviewGroupUserPreview: "AccessReviewGroupUserPreview",
    AccessReviewUserGroupPreview: "AccessReviewUserGroupPreview",
    AccessReviewGroupUserWarning: "AccessReviewGroupUserWarning",
    AccessReviewGroupPreview: "AccessReviewGroupPreview",
    AccessReviewGroupReviewerStatus: "AccessReviewGroupReviewerStatus",
    AccessReviewGroup: "AccessReviewGroup",
    AccessReviewResourceResource: "AccessReviewResourceResource",
    AccessReviewResourceUser: "AccessReviewResourceUser",
    AccessReviewResourceUserPreview: "AccessReviewResourceUserPreview",
    AccessReviewUserResourcePreview: "AccessReviewUserResourcePreview",
    AccessReviewResourceUserWarning: "AccessReviewResourceUserWarning",
    ReviewerUser: "ReviewerUser",
    AccessReviewResourcePreview: "AccessReviewResourcePreview",
    AccessReviewResourceReviewerStatus: "AccessReviewResourceReviewerStatus",
    AccessReviewResource: "AccessReviewResource",
    AccessReviewStats: "AccessReviewStats",
    IncompleteAccessReviewer: "IncompleteAccessReviewer",
    OngoingAccessReviewTabStats: "OngoingAccessReviewTabStats",
    OngoingAccessReviewSubtabStats: "OngoingAccessReviewSubtabStats",
    AccessReviewTemplate: "AccessReviewTemplate",
    AccessReviewTemplatePreview: "AccessReviewTemplatePreview",
    AccessReviewUserPreview: "AccessReviewUserPreview",
    AccessReviewUser: "AccessReviewUser",
    AccessReviewBase: "AccessReviewBase",
    AccessReview: "AccessReview",
    AccessReviewPreview: "AccessReviewPreview",
    TagKeyValue: "TagKeyValue",
    AccessReviewItemStatusAndOutcome: "AccessReviewItemStatusAndOutcome",
    AccessReviewFilters: "AccessReviewFilters",
    AppInfo: "AppInfo",
    AuthUser: "AuthUser",
    FirstPartyToken: "FirstPartyToken",
    AuthProviderInfo: "AuthProviderInfo",
    EntityIdTuple: "EntityIdTuple",
    ErrorEntity: "ErrorEntity",
    ConnectionVisibilityGroup: "ConnectionVisibilityGroup",
    ConnectionPreviewTiny: "ConnectionPreviewTiny",
    ConnectionPreviewSmall: "ConnectionPreviewSmall",
    ConnectionPreviewLarge: "ConnectionPreviewLarge",
    ConnectionUser: "ConnectionUser",
    ADConnectionMetadata: "ADConnectionMetadata",
    AwsConnectionMetadata: "AwsConnectionMetadata",
    AWSSSOConnectionMetadata: "AWSSSOConnectionMetadata",
    GCPConnectionMetadata: "GCPConnectionMetadata",
    GitHubConnectionMetadata: "GitHubConnectionMetadata",
    GitLabConnectionMetadata: "GitLabConnectionMetadata",
    GoogleGroupsConnectionMetadata: "GoogleGroupsConnectionMetadata",
    GoogleWorkspaceConnectionMetadata: "GoogleWorkspaceConnectionMetadata",
    LDAPConnectionMetadata: "LDAPConnectionMetadata",
    MongoConnectionMetadata: "MongoConnectionMetadata",
    MySqlMariaDBConnectionMetadata: "MySqlMariaDBConnectionMetadata",
    PostgresConnectionMetadata: "PostgresConnectionMetadata",
    MongoAtlasConnectionMetadata: "MongoAtlasConnectionMetadata",
    OktaDirectoryConnectionMetadata: "OktaDirectoryConnectionMetadata",
    SalesforceConnectionMetadata: "SalesforceConnectionMetadata",
    TailscaleConnectionMetadata: "TailscaleConnectionMetadata",
    TeleportConnectionMetadata: "TeleportConnectionMetadata",
    DuoConnectionMetadata: "DuoConnectionMetadata",
    PropagationTicketConnectionMetadata: "PropagationTicketConnectionMetadata",
    CustomConnectorConnectionMetadata: "CustomConnectorConnectionMetadata",
    AzureADConnectionMetadata: "AzureADConnectionMetadata",
    SnowflakeConnectionMetadata: "SnowflakeConnectionMetadata",
    WorkdayConnectionMetadata: "WorkdayConnectionMetadata",
    ConnectionMetadataOutput: "ConnectionMetadataOutput",
    ConnectionPreview: "ConnectionPreview",
    EventFilter: "EventFilter",
    EntityDisplayInfo: "EntityDisplayInfo",
    Event: "Event",
    EventPreview: "EventPreview",
    SubEvent: "SubEvent",
    CurrentUserGroupAccessTiny: "CurrentUserGroupAccessTiny",
    CurrentUserGroupAccess: "CurrentUserGroupAccess",
    UserGroupAccess: "UserGroupAccess",
    GroupUserAccess: "GroupUserAccess",
    GroupUserAccessPoint: "GroupUserAccessPoint",
    IndirectGroupUserAccessPointPath: "IndirectGroupUserAccessPointPath",
    GroupResourceAccess: "GroupResourceAccess",
    GroupResourceAccessPoint: "GroupResourceAccessPoint",
    IndirectGroupResourceAccessPointPath:
      "IndirectGroupResourceAccessPointPath",
    IndirectGroupAccessPoint: "IndirectGroupAccessPoint",
    GroupGroupAccess: "GroupGroupAccess",
    GroupGroupQuickAccess: "GroupGroupQuickAccess",
    GroupGroupAccessPoint: "GroupGroupAccessPoint",
    IndirectGroupGroupAccessPointPath: "IndirectGroupGroupAccessPointPath",
    GroupAccessLevel: "GroupAccessLevel",
    GroupBreakGlassUser: "GroupBreakGlassUser",
    GroupGroup: "GroupGroup",
    GroupResourcePreview: "GroupResourcePreview",
    GroupResource: "GroupResource",
    ResourceGroup: "ResourceGroup",
    GroupTagPreview: "GroupTagPreview",
    GroupTag: "GroupTag",
    GroupUser: "GroupUser",
    GroupUserPreview: "GroupUserPreview",
    UserGroup: "UserGroup",
    GroupUserUARPreview: "GroupUserUARPreview",
    GroupVisibilityGroup: "GroupVisibilityGroup",
    GroupPreviewTiny: "GroupPreviewTiny",
    GroupPreviewSmall: "GroupPreviewSmall",
    GroupPreviewWithAccessLevels: "GroupPreviewWithAccessLevels",
    GroupPreviewLarge: "GroupPreviewLarge",
    ReviewerStage: "ReviewerStage",
    Group: "Group",
    RemoteGroup: "RemoteGroup",
    IdpConnection: "IdpConnection",
    MessageChannel: "MessageChannel",
    Factor: "Factor",
    OnCallSchedule: "OnCallSchedule",
    GroupOnCallSchedule: "GroupOnCallSchedule",
    OrganizationSettings: "OrganizationSettings",
    OrganizationWebhookInfo: "OrganizationWebhookInfo",
    Organization: "Organization",
    OrganizationSamlInfo: "OrganizationSamlInfo",
    OwnerUser: "OwnerUser",
    OwnerPreviewSmall: "OwnerPreviewSmall",
    Owner: "Owner",
    OwnedResource: "OwnedResource",
    OwnedGroup: "OwnedGroup",
    OwnedConfigurationTemplate: "OwnedConfigurationTemplate",
    PushTaskPending: "PushTaskPending",
    PushTaskComplete: "PushTaskComplete",
    ResourceUserPushTaskResult: "ResourceUserPushTaskResult",
    GroupUserPushTaskResult: "GroupUserPushTaskResult",
    GroupResourcePushTaskResult: "GroupResourcePushTaskResult",
    GroupGroupPushTaskResult: "GroupGroupPushTaskResult",
    ConnectionUserPushTaskResult: "ConnectionUserPushTaskResult",
    RoleAssignmentPushTaskResult: "RoleAssignmentPushTaskResult",
    ResourceUserPropagationMetadata: "ResourceUserPropagationMetadata",
    ResourcePropagationStatus: "ResourcePropagationStatus",
    GroupUserTicketPropagation: "GroupUserTicketPropagation",
    GroupPropagationStatus: "GroupPropagationStatus",
    GroupResourcePropagationStatus: "GroupResourcePropagationStatus",
    ConnectionUserPropagationStatus: "ConnectionUserPropagationStatus",
    PropagationStatus: "PropagationStatus",
    RequestTemplateSmall: "RequestTemplateSmall",
    RequestTemplate: "RequestTemplate",
    RequestTemplateCustomField: "RequestTemplateCustomField",
    RequestedGroup: "RequestedGroup",
    ResourceProposal: "ResourceProposal",
    RequestedResource: "RequestedResource",
    RequestedGroupPreviewSmall: "RequestedGroupPreviewSmall",
    RequestedResourcePreviewSmall: "RequestedResourcePreviewSmall",
    RequestComment: "RequestComment",
    RequestPreviewLarge: "RequestPreviewLarge",
    RequestPreviewSmall: "RequestPreviewSmall",
    RequestStage: "RequestStage",
    RequestReviewer: "RequestReviewer",
    RequestCustomMetadataValue: "RequestCustomMetadataValue",
    RequestCustomMetadata: "RequestCustomMetadata",
    Request: "Request",
    CurrentUserResourceAccessTiny: "CurrentUserResourceAccessTiny",
    CurrentUserResourceAccess: "CurrentUserResourceAccess",
    UserResourceAccess: "UserResourceAccess",
    ResourceUserAccess: "ResourceUserAccess",
    ResourceUserAccessPoint: "ResourceUserAccessPoint",
    ResourceAccessLevel: "ResourceAccessLevel",
    ResourceCustomAccessLevel: "ResourceCustomAccessLevel",
    ResourceTagPreview: "ResourceTagPreview",
    ResourceTag: "ResourceTag",
    ResourceUsageLog: "ResourceUsageLog",
    ResourceUser: "ResourceUser",
    UserResource: "UserResource",
    ResourceUserPreview: "ResourceUserPreview",
    AwsRoleMetadata: "AwsRoleMetadata",
    OktaDirectoryAppMetadata: "OktaDirectoryAppMetadata",
    ResourceVisibilityGroup: "ResourceVisibilityGroup",
    ResourcePreviewTiny: "ResourcePreviewTiny",
    ResourcePreviewSmall: "ResourcePreviewSmall",
    ResourcePreviewWithAccessLevels: "ResourcePreviewWithAccessLevels",
    ResourcePreviewLarge: "ResourcePreviewLarge",
    ChildResourcePreview: "ChildResourcePreview",
    ResourcePreviewWithContainingGroups: "ResourcePreviewWithContainingGroups",
    Resource: "Resource",
    ResourceMetadata: "ResourceMetadata",
    RemoteResource: "RemoteResource",
    RoleAssignmentResource: "RoleAssignmentResource",
    RoleAssignmentGroup: "RoleAssignmentGroup",
    RoleAssignmentUser: "RoleAssignmentUser",
    Entity: "Entity",
    Principal: "Principal",
    RoleAssignment: "RoleAssignment",
    RoleAssignmentPreview: "RoleAssignmentPreview",
    SearchResultEntry: "SearchResultEntry",
    AwsIamFederatedRoleSession: "AwsIamFederatedRoleSession",
    AwsIamFederatedSSMSession: "AwsIamFederatedSSMSession",
    AwsIamFederatedRdsSession: "AwsIamFederatedRdsSession",
    AwsIamFederatedEksSession: "AwsIamFederatedEksSession",
    TlsConfig: "TlsConfig",
    VaultMongoSession: "VaultMongoSession",
    VaultMongoAtlasSession: "VaultMongoAtlasSession",
    VaultMySQLMariaDBSession: "VaultMySQLMariaDBSession",
    VaultPostgresSession: "VaultPostgresSession",
    SessionMetadata: "SessionMetadata",
    SessionPreview: "SessionPreview",
    Session: "Session",
    SupportTicket: "SupportTicket",
    SupportTicketPreview: "SupportTicketPreview",
    SupportTicketsResult: "SupportTicketsResult",
    TicketProject: "TicketProject",
    SyncTask: "SyncTask",
    SyncError: "SyncError",
    SyncStep: "SyncStep",
    TagPreviewSmall: "TagPreviewSmall",
    TagPreviewLarge: "TagPreviewLarge",
    Tag: "Tag",
    ThirdPartyIntegration: "ThirdPartyIntegration",
    UserSettings: "UserSettings",
    UserTagPreview: "UserTagPreview",
    UserTag: "UserTag",
    UserPreviewSmall: "UserPreviewSmall",
    UserPreviewLarge: "UserPreviewLarge",
    User: "User",
    UserIdentity: "UserIdentity",
    UserIdentities: "UserIdentities",
    AppDropdownPreview: "AppDropdownPreview",
    GroupBindingDropdownPreview: "GroupBindingDropdownPreview",
    GroupDropdownPreview: "GroupDropdownPreview",
    ResourceDropdownPreview: "ResourceDropdownPreview",
    UserDropdownPreview: "UserDropdownPreview",
    SearchUserPreview: "SearchUserPreview",
    SearchResourcePreview: "SearchResourcePreview",
    SearchGroupPreview: "SearchGroupPreview",
    SearchGroupTypePreview: "SearchGroupTypePreview",
    SearchResourceTypePreview: "SearchResourceTypePreview",
    ActiveRequestConfiguration: "ActiveRequestConfiguration",
    EndUserGroupDetailsGroupResources: "EndUserGroupDetailsGroupResources",
    EndUserGroupDetailsGroupGroups: "EndUserGroupDetailsGroupGroups",
    EndUserGroupDetails: "EndUserGroupDetails",
    EndUserResourceDetails: "EndUserResourceDetails",
    ConnectionSummary: "ConnectionSummary",
    GroupTypeWithCount: "GroupTypeWithCount",
    ResourceTypeWithCount: "ResourceTypeWithCount",
    AccessLevelNode: "AccessLevelNode",
    EdgeMetadata: "EdgeMetadata",
    VisualizationDataResult: "VisualizationDataResult",
    AppPreview: "AppPreview",
    RequestMessage: "RequestMessage",
    RequestDefaults: "RequestDefaults",
    AccessReviewUserAssignment: "AccessReviewUserAssignment",
    AccessReviewGroupResourceAssignment: "AccessReviewGroupResourceAssignment",
    AccessReviewResourcePrincipalAssignment:
      "AccessReviewResourcePrincipalAssignment",
    AccessReviewChangeItemSmall: "AccessReviewChangeItemSmall",
    EventWithSubEventInfo: "EventWithSubEventInfo",
    AccessReviewGroupForDetail: "AccessReviewGroupForDetail",
    GroupResourceForContext: "GroupResourceForContext",
    AccessReviewGroupResourceForDetail: "AccessReviewGroupResourceForDetail",
    AccessReviewMyResourceV3: "AccessReviewMyResourceV3",
    AccessReviewMyGroupV3: "AccessReviewMyGroupV3",
    AccessReviewMyConnectionV3: "AccessReviewMyConnectionV3",
    AccessReviewReviewer: "AccessReviewReviewer",
    AccessReviewReviewerItem: "AccessReviewReviewerItem",
    AccessReviewReviewerDetail: "AccessReviewReviewerDetail",
    AccessReviewResourceSmall: "AccessReviewResourceSmall",
    AccessReviewGroupSmall: "AccessReviewGroupSmall",
    AccessReviewConnectionSmall: "AccessReviewConnectionSmall",
    AccessReviewItem: "AccessReviewItem",
    AccessReviewResourceProposedChange: "AccessReviewResourceProposedChange",
    AccessReviewGroupProposedChange: "AccessReviewGroupProposedChange",
    AppResourceRow: "AppResourceRow",
    AppGroupRow: "AppGroupRow",
    AppData: "AppData",
    GroupPreviewWithGroups: "GroupPreviewWithGroups",
    GroupPreviewWithResources: "GroupPreviewWithResources",
    GroupPreviewWithUsers: "GroupPreviewWithUsers",
    RemoteAppItem: "RemoteAppItem",
    ResourceForSelect: "ResourceForSelect",
    GroupForSelect: "GroupForSelect",
    ResourceForImport: "ResourceForImport",
    GroupForImport: "GroupForImport",
    AppItem: "AppItem",
    ResourcePreviewWithGroups: "ResourcePreviewWithGroups",
    ResourcePreviewWithRoleAssignments: "ResourcePreviewWithRoleAssignments",
    ResourcePreviewWithUsers: "ResourcePreviewWithUsers",
    RequestConfiguration: "RequestConfiguration",
    ResourceTicketPropagation: "ResourceTicketPropagation",
    ResourceDetail: "ResourceDetail",
    ResourceDetailView: "ResourceDetailView",
    DetailResourceUser: "DetailResourceUser",
    PaginatedResourceUsers: "PaginatedResourceUsers",
    ResourceEditViewDetail: "ResourceEditViewDetail",
    EndUserOktaResourceApp: "EndUserOktaResourceApp",
    EndUserConnectionApp: "EndUserConnectionApp",
    AppEndUserResourceRow: "AppEndUserResourceRow",
    AppEndUserGroupRow: "AppEndUserGroupRow",
    ResourceSuggestion: "ResourceSuggestion",
    GroupSuggestion: "GroupSuggestion",
    Suggestion: "Suggestion",
    Bundle: "Bundle",
    BundleDetail: "BundleDetail",
    BundleDetailForCatalog: "BundleDetailForCatalog",
    BundleResource: "BundleResource",
    BundleGroup: "BundleGroup",
    BundleItem: "BundleItem",
    ConnectResourceSession: "ConnectResourceSession",
    EventForTable: "EventForTable",
    EventDetail: "EventDetail",
    GroupBindingGroupWithUsers: "GroupBindingGroupWithUsers",
    GroupBindingDetailGroupUser: "GroupBindingDetailGroupUser",
    GroupBindingDetail: "GroupBindingDetail",
    GroupBindingSuggestion: "GroupBindingSuggestion",
    GroupBindingGroupPreview: "GroupBindingGroupPreview",
    GroupBinding: "GroupBinding",
    GroupBindingTiny: "GroupBindingTiny",
    GroupBindingSuggestionDetail: "GroupBindingSuggestionDetail",
    BarGraphData: "BarGraphData",
    LineGraphData: "LineGraphData",
    AppThreatPreview: "AppThreatPreview",
    ResourceForRemediation: "ResourceForRemediation",
    GroupForRemediation: "GroupForRemediation",
    RiskyResourcePreviewTiny: "RiskyResourcePreviewTiny",
    RiskyGroupPreviewTiny: "RiskyGroupPreviewTiny",
    ResourceBulkSuggestionPreview: "ResourceBulkSuggestionPreview",
    GroupBulkSuggestionPreview: "GroupBulkSuggestionPreview",
    RequestTable: "RequestTable",
    ConnectionForResourceImport: "ConnectionForResourceImport",
    GroupOnCallSchedulePreviewSmall: "GroupOnCallSchedulePreviewSmall",
    EventStream: "EventStream",
    SetupEntityCounts: "SetupEntityCounts",
    ConfigurationTemplate: "ConfigurationTemplate",
    UserOverview: "UserOverview",
  },
};
